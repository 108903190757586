import { Grid, GridItem } from "@chakra-ui/react";
import { Sidebar } from "./components/Sidebar";
import { Outlet } from "react-router-dom";

export function CheckoutLayout() {
  return (
    <Grid templateColumns="240px 1fr 320px" templateRows={"1fr"} gap={0} h={"100%"}>
      <GridItem bg="#18181A" borderRight={"1px solid #404247"} py="32px" px="16px">
        <Sidebar />
      </GridItem>
      <Outlet />
    </Grid>
  );
}
