import styled from "styled-components";

export const StyledTab = styled.div`
  margin-right: 16px;
  padding-bottom: 8px;
  min-width: 54px;
  color: ${({ theme }) => theme.textSeashellWhite};

  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;

  &.active {
    color: ${({ theme }) => theme.primaryColor};
  }

  :hover {
    color: ${({ theme }) => theme.primaryColor};
    cursor: pointer;
  }
`;
