import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  checkLoading,
  showToast,
  getResponseStatus,
  getRequestStatus,
  getErrorStatus,
  setStatusDefaults,
} from "@utils/redux";

import BuildersService from "./builderApi";

import { errorWrapper } from "@redux/http";

import STOREFRONT_SCHEMA from "./schemas/storefront";

import { copy } from "@utils/general";
import { PUBLISH } from "@src/consts/webshops/builder";

import { webshopsLoadingSelector } from "../webshopsSlice";
import { associationsLoadingSelector } from "../associations/associationsSlice";
import { catalogLoadingSelector } from "../catalog/catalogSlice";

export const builderThunks = {
  getStorefront: createAsyncThunk("webshops/getStorefront", async (webshopId, { rejectWithValue }) => {
    return errorWrapper(async () => {
      const res = await BuildersService.getStorefront(webshopId);
      return res.data;
    }, rejectWithValue);
  }),
  updateStorefront: createAsyncThunk("webshops/updateStorefront", async ({ data, webshopId }, { rejectWithValue }) => {
    return errorWrapper(async () => {
      const res = await BuildersService.updateStorefront(data, webshopId);
      return res.data;
    }, rejectWithValue);
  }),
  updateStorefrontTheme: createAsyncThunk(
    "webshops/updateStorefrontTheme",
    async ({ data, webshopId }, { rejectWithValue }) => {
      return errorWrapper(async () => {
        const res = await BuildersService.updateStorefrontTheme(data, webshopId);
        return res.data;
      }, rejectWithValue);
    },
  ),
  publishStorefront: createAsyncThunk("webshops/publishStorefront", async (webshopId, { rejectWithValue }) => {
    return errorWrapper(async () => {
      const res = await BuildersService.publishStorefront(webshopId);
      return res.data;
    }, rejectWithValue);
  }),
  createFile: createAsyncThunk("webshops/createFile", async ({ data, webshopId }, { rejectWithValue }) => {
    return errorWrapper(async () => {
      const res = await BuildersService.createFile(data, webshopId);
      return res.data;
    }, rejectWithValue);
  }),
  updateFile: createAsyncThunk("webshops/updateFile", async ({ data, webshopId }, { rejectWithValue }) => {
    return errorWrapper(async () => {
      const res = await BuildersService.updateFile(data, webshopId);
      return res.data;
    }, rejectWithValue);
  }),
  deleteFile: createAsyncThunk("webshops/deleteFile", async ({ fileId, webshopId }, { rejectWithValue }) => {
    return errorWrapper(async () => {
      await BuildersService.deleteFile(fileId, webshopId);
      return { fileId, webshopId };
    }, rejectWithValue);
  }),
  getSocialPlatforms: createAsyncThunk("webshops/getSocialPlatforms", async (_, { rejectWithValue }) => {
    return errorWrapper(async () => {
      const res = await BuildersService.getSocialPlatforms();
      return res.data;
    }, rejectWithValue);
  }),
};

export const builderSlice = createSlice({
  name: "builder",
  initialState: {
    storefront: {
      data: STOREFRONT_SCHEMA,
      requestStatus: setStatusDefaults(),
    },
    theme: {
      data: {},
      requestStatus: setStatusDefaults(),
    },
    publish: {
      requestStatus: setStatusDefaults(),
    },
    files: {
      requestStatus: setStatusDefaults(),
    },
    links: {
      data: {},
      requestStatus: setStatusDefaults(),
    },
    // Represents the data shown on the canvas
    // This isn't always a complete match to the data in the builder
    // It's what gets manipulated to shown on the canvas while edits
    // are being saved in the builder
    canvas: {
      data: STOREFRONT_SCHEMA,
    },
    view: {
      previewMode: { active: false, returnTo: "" },
      isMobile: false,
      showTypographyCustomizer: false,
    },
  },
  reducers: {
    updateStorefrontGlobal: (state, action) => {
      const newStorefront = action.payload;
      state.storefront.data.storefront = newStorefront;
      state.canvas.data.storefront = newStorefront;
    },
    updateStorefrontHeader: (state, action) => {
      state.canvas.data.layout.header = action.payload;
    },
    updateStorefrontSections: (state, action) => {
      state.canvas.data.layout.pages[0].sections = action.payload;
    },
    updateStorefrontFooter: (state, action) => {
      state.canvas.data.layout.footer = action.payload;
    },
    updateStorefrontLogin: (state, action) => {
      state.canvas.data.layout.login = action.payload;
    },
    updateStorefrontDeepLink: (state, action) => {
      state.canvas.data.layout.deepLink = action.payload;
    },
    updatePreviewMode: (state, { payload: previewMode }) => {
      state.view.previewMode = previewMode;
    },
    updateIsMobileMode: (state, { payload: isMobile }) => {
      state.view.isMobile = isMobile;
    },
    updateStorefrontTypography: (state, action) => {
      state.storefront.data.storefront.themeV2 = {
        ...state.storefront.data.storefront.themeV2,
        typography: action.payload,
      };
      state.canvas.data.storefront.themeV2 = {
        ...state.canvas.data.storefront.themeV2,
        typography: action.payload,
      };
    },
    toggleTypographyCustomizer: (state, { payload }) => {
      state.view.showTypographyCustomizer = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      /*******************************************************************************
       * Get Storefront
       *******************************************************************************/
      .addCase(builderThunks.getStorefront.pending, (state) => {
        state.storefront.requestStatus = getRequestStatus();
        // Reset the storefront data when loading
        state.storefront.data = copy(STOREFRONT_SCHEMA);
        state.canvas.data = copy(STOREFRONT_SCHEMA);
      })
      .addCase(builderThunks.getStorefront.fulfilled, (state, action) => {
        const { payload = {} } = action;
        const { storefront = {}, layout = {} } = payload;
        // Ensure the payload has a theme object
        if (!storefront.theme) storefront.theme = {};
        // Storefront and canvas should match when the builder is first loaded
        state.storefront.data = {
          storefront,
          layout,
        };
        state.canvas.data = copy(state.storefront.data);
        state.storefront.requestStatus = getResponseStatus();
      })
      .addCase(builderThunks.getStorefront.rejected, (state) => {
        state.storefront.requestStatus = getErrorStatus();
      })
      /*******************************************************************************
       * Update Storefront
       *******************************************************************************/
      .addCase(builderThunks.updateStorefront.pending, (state) => {
        state.storefront.requestStatus = getRequestStatus();
      })
      .addCase(builderThunks.updateStorefront.fulfilled, (state, action) => {
        const { payload = {} } = action;
        state.storefront.data = payload;
        state.storefront.requestStatus = getResponseStatus();
      })
      .addCase(builderThunks.updateStorefront.rejected, (state) => {
        state.storefront.requestStatus = getErrorStatus();
      })
      /*******************************************************************************
       * Update Storefront Theme
       *******************************************************************************/
      .addCase(builderThunks.updateStorefrontTheme.pending, (state) => {
        state.theme.requestStatus = getRequestStatus();
      })
      .addCase(builderThunks.updateStorefrontTheme.fulfilled, (state, action) => {
        const { payload = {} } = action;
        state.theme.data = payload;
        state.theme.requestStatus = getResponseStatus();
      })
      .addCase(builderThunks.updateStorefrontTheme.rejected, (state) => {
        state.theme.requestStatus = getErrorStatus();
      })
      /*******************************************************************************
       * Publish Storefront
       *******************************************************************************/
      .addCase(builderThunks.publishStorefront.pending, (state) => {
        state.publish.requestStatus = getRequestStatus();
      })
      .addCase(builderThunks.publishStorefront.fulfilled, (state) => {
        state.publish.requestStatus = getResponseStatus();
        const publish = PUBLISH["completed"];
        showToast(publish.message, publish.type);
      })
      .addCase(builderThunks.publishStorefront.rejected, (state) => {
        state.publish.requestStatus = getErrorStatus();
        const publish = PUBLISH["error"];
        showToast(publish.message, publish.type);
      })
      /*******************************************************************************
       * Create File
       *******************************************************************************/
      .addCase(builderThunks.createFile.pending, (state) => {
        state.files.requestStatus = getRequestStatus();
      })
      .addCase(builderThunks.createFile.fulfilled, (state, action) => {
        state.storefront.data.storefront.files = action.payload.files;
        state.files.requestStatus = getResponseStatus();
      })
      .addCase(builderThunks.createFile.rejected, (state) => {
        state.files.requestStatus = getErrorStatus();
      })
      /*******************************************************************************
       * Update File
       *******************************************************************************/
      .addCase(builderThunks.updateFile.pending, (state) => {
        state.files.requestStatus = getRequestStatus();
      })
      .addCase(builderThunks.updateFile.fulfilled, (state, action) => {
        state.storefront.data.storefront.files = action.payload.files;
        state.files.requestStatus = getResponseStatus();
      })
      .addCase(builderThunks.updateFile.rejected, (state) => {
        state.files.requestStatus = getErrorStatus();
      })
      /*******************************************************************************
       * Delete File
       *******************************************************************************/
      .addCase(builderThunks.deleteFile.pending, (state) => {
        state.files.requestStatus = getRequestStatus();
      })
      .addCase(builderThunks.deleteFile.fulfilled, (state, action) => {
        const imageId = action.payload.fileId;
        state.storefront.data.storefront.files = state.storefront.data.storefront.files.filter(
          (file) => file.id !== imageId,
        );
        state.files.requestStatus = getResponseStatus();
      })
      .addCase(builderThunks.deleteFile.rejected, (state) => {
        state.files.requestStatus = getErrorStatus();
      })
      /*******************************************************************************
       * Get Social Platforms
       *******************************************************************************/
      .addCase(builderThunks.getSocialPlatforms.pending, (state) => {
        state.links.requestStatus = getRequestStatus();
      })
      .addCase(builderThunks.getSocialPlatforms.fulfilled, (state, action) => {
        const { payload = [] } = action;
        state.links.data = payload;
        state.links.requestStatus = getResponseStatus();
      })
      .addCase(builderThunks.getSocialPlatforms.rejected, (state) => {
        state.links.requestStatus = getErrorStatus();
      });
  },
});

export const builderSelector = (state) => state.webshops.builder;
export const builderStorefrontSelector = (state) => state.webshops.builder.storefront.data.storefront;
export const builderLinksSelector = (state) => state.webshops.builder.links.data;

export const builderLoadingSelector = (state) => {
  const webshopsLoading = webshopsLoadingSelector(state);
  const associationsLoading = associationsLoadingSelector(state);
  const catalogLoading = catalogLoadingSelector(state);
  const isLoading = checkLoading(state.webshops.builder.storefront, {
    includeIdle: true,
  });
  return isLoading || associationsLoading || catalogLoading || webshopsLoading;
};

export const {
  updateStorefrontGlobal,
  updateStorefrontHeader,
  updateStorefrontSections,
  updateStorefrontFooter,
  updateStorefrontLogin,
  updateStorefrontDeepLink,
  updatePreviewMode,
  updateIsMobileMode,
  updateStorefrontTypography,
  toggleTypographyCustomizer,
} = builderSlice.actions;

export default builderSlice.reducer;
