import React from "react";

import { StepActionsContainer } from "@common/animated/FadeStepsContainer";
import SetupGuide from "@common/modals/integrations-modal/SetupGuide";
import { StyledGuideList } from "@components/common/modals/integrations-modal/StyledSetupGuide";

import { Loader } from "@components/common/loaders";
import { SanloButton, SanloInput, Link, Support } from "@inputs";
import { ErrorMessage } from "@components/integrations/connection-guide/ErrorMessage";
import { Callout } from "@components/integrations/connection-guide/Callout";

import symbols from "@utils/symbols";

export const AppsFlyerStep1 = ({
  platform = {
    id: "appsflyer",
    name: "AppsFlyer",
    platformId: "appsflyer",
  },
  apiToken = "",
  setApiToken = () => {},
  testApiToken = () => {},
  requestStatus = {},
  credentialsError = "",
}) => {
  return (
    <StepActionsContainer>
      <div className="fs-modal-actions">
        <ErrorMessage error={credentialsError} />

        <SanloInput
          hasFocus
          isJumbo
          label="API token"
          placeholder="Enter your API token"
          value={apiToken}
          setValue={setApiToken}
          onEnter={() => {
            if (!requestStatus?.testCredentials?.pending) {
              testApiToken();
            }
          }}
          style={{
            marginBottom: "24px",
          }}
          testId={`${platform.platformId}-api-token-input`}
        />

        <SanloButton
          isJumbo
          isDisabled={apiToken.length === 0}
          onClick={testApiToken}
          label={requestStatus?.testCredentials?.pending ? <Loader className="button-loader" /> : `Continue`}
          gtmEventTag={`tag-sanlo-${platform.name}-api-token-continue`}
          testId={`${platform.platformId}-api-token-submit`}
        />
      </div>

      <SetupGuide
        platformStep={"Appsflyer0"}
        step={{
          callout: (
            <Callout>
              {symbols.LightBulb()} Before you get started, please make sure that:
              <StyledGuideList>
                <li>
                  You're an{" "}
                  <Link
                    href="https://support.appsflyer.com/hc/en-us/articles/4409128270481#account-owner-hash"
                    className="modal-link setup-guide-link text-underline text-blue tag-sanlo-af-master-api-link"
                    isExternalLink
                    data-test-id={`${platform.platformId}-step-1-admin-link`}
                  >
                    Admin
                  </Link>{" "}
                  so you can retrieve your V2.0 token.
                </li>
                <li>
                  <Link
                    href="https://support.appsflyer.com/hc/en-us/articles/213223166-Master-API-user-acquisition-metrics-via-API"
                    className="modal-link setup-guide-link text-underline text-blue tag-sanlo-af-master-api-link"
                    isExternalLink
                    data-test-id={`${platform.platformId}-step-1-master-api-link`}
                  >
                    Master API
                  </Link>{" "}
                  and{" "}
                  <Link
                    href="https://support.appsflyer.com/hc/en-us/articles/360007530258-Using-Pull-API-raw-data"
                    className="modal-link setup-guide-link text-underline text-blue tag-sanlo-af-master-api-link"
                    isExternalLink
                    data-test-id={`${platform.platformId}-step-1-pull-api-link`}
                  >
                    Pull API Raw Data
                  </Link>{" "}
                  are enabled as part of your {platform.name} subscription.
                </li>
              </StyledGuideList>
              Have questions about {platform.name} connection? <Support className="setup-guide-link" />
            </Callout>
          ),
          title: "Copy API token",
          description: (
            <StyledGuideList>
              <li>
                Go to the{" "}
                <Link
                  href="https://hq1.appsflyer.com/account/api-tokens"
                  className="modal-link setup-guide-link tag-sanlo-af-api-tokens-page-link"
                  isExternalLink
                  data-test-id={`${platform.platformId}-step-1-api-token-link`}
                >
                  API tokens
                </Link>{" "}
                page.
              </li>
              <li>Once logged in, copy the API token V2.0 to your clipboard.</li>
              <li>Come back here and paste it on the left {symbols.PointLeft()}</li>
            </StyledGuideList>
          ),
          mediaSlider: [
            require("./assets/apps_flyer_guide_1_step_1.png"),
            require("./assets/apps_flyer_guide_2_step_1.png"),
            require("./assets/apps_flyer_guide_3_step_1.png"),
          ],
        }}
      />
    </StepActionsContainer>
  );
};
