import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Box, Button, Link, FormControl, FormLabel, HStack, Input, Text, VStack } from "@chakra-ui/react";

import { BaseCard } from "./BaseCard";

import { getSettingsPayload } from "../utils";
import { settingsSelector, settingsThunks } from "@redux/webshops/webshop/settings/settingsSlice";
import { useParams } from "react-router-dom";

export const EmailReceipt = () => {
  const dispatch = useDispatch();
  const { settings } = useSelector(settingsSelector);
  const { id } = useParams();

  const onSubmit = (data) => {
    dispatch(
      settingsThunks.setSiteDetails({
        data: getSettingsPayload(data, settings),
        webshopId: id,
      }),
    );
  };

  return (
    <EmailReceiptPresentation
      onSubmit={onSubmit}
      values={{
        supportLink: settings?.supportLink ?? "",
        supportLinkLabel: settings?.supportLinkLabel ?? "",
      }}
    />
  );
};

export const EmailReceiptPresentation = ({ onSubmit = () => {}, values = {} }) => {
  const {
    handleSubmit,
    register,
    formState: { errors, isDirty },
    watch,
  } = useForm({
    values,
  });

  const { supportLink, supportLinkLabel } = watch();

  const onSubmitForm = (data) => {
    onSubmit(data);
  };

  return (
    <BaseCard title={"Email Receipt"} id={"settings-email-receipt"}>
      <form id={"settings-email-receipt-form"} onSubmit={handleSubmit(onSubmitForm)}>
        <VStack gap={"16px"} alignItems={"flex-start"}>
          <FormControl>
            <FormLabel mb={"8px"}>
              <Text size={"small"} letterSpacing={"0.14px"} fontWeight={600} color={"textWhite.400"}>
                Support Link
              </Text>
            </FormLabel>
            <Input {...register("supportLink")} />
            {errors.supportLink && <Text color="red.500">{errors.supportLink.message}</Text>}
          </FormControl>

          <FormControl>
            <FormLabel mb={"8px"}>
              <Text size={"small"} letterSpacing={"0.14px"} fontWeight={600} color={"textWhite.400"}>
                Support Link Label
              </Text>
            </FormLabel>
            <Input {...register("supportLinkLabel")} placeholder={"Our Support Team"} />
            {errors.supportLinkLabel && <Text color="red.500">{errors.supportLinkLabel.message}</Text>}
          </FormControl>

          <Button
            type={"submit"}
            form={"settings-email-receipt-form"}
            border={"var(--border-dark-300)"}
            h={"32px"}
            p={"8px 12px"}
            color={"textWhite.400"}
            isDisabled={!isDirty}
            data-testid={"settings-email-receipt-save-button"}
          >
            Save
          </Button>

          <Box p="12px" bg="var(--bg-dark-400)" rounded="12px" w="full" border="var(--border-dark-300)">
            <Text size={"small"} color={"textWhite.400"} opacity={0.6} fontWeight={500}>
              The support link will appear on the emailed purchase receipt as follows:
            </Text>
            <HStack spacing={1} pt={2}>
              <Text size={"small"} color={"textWhite.400"}>
                If you have any questions, please contact
              </Text>
              <Text size={"small"} color={"brandRed.400"}>
                <Link href={supportLink} isExternal>
                  {supportLinkLabel}
                </Link>
              </Text>
            </HStack>
          </Box>
        </VStack>
      </form>
    </BaseCard>
  );
};

EmailReceiptPresentation.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  values: PropTypes.shape({
    supportLink: PropTypes.string,
    supportLinkLabel: PropTypes.string,
  }).isRequired,
};
