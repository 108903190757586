export const formatPlatformLinked = (platformsLinked, isBankingConnected) => {
  return {
    ...platformsLinked,
    banking: isBankingConnected ?? platformsLinked.banking,
  };
};

export const getConnectedIntegrations = (platformsLinked) => {
  let { banking, productAnalytics, accounting, inAppPurchases } = platformsLinked || {};

  return {
    banking,
    productAnalytics,
    accounting,
    inAppPurchases,
    all: banking && productAnalytics && accounting && inAppPurchases,
    allOptional: accounting,
    noneOptional: !accounting,
    oneOptional: accounting,
    allRequired: banking && productAnalytics && inAppPurchases,
    noneRequired: !banking && !productAnalytics && !inAppPurchases,
    oneRequired: banking || productAnalytics || inAppPurchases,
    none: !banking && !productAnalytics && !accounting && !inAppPurchases,
  };
};

export const seeBankingSection = () => {
  document.getElementById(`platforms-accordion-list`).scrollTop = 0;
  const elem = document.getElementById(`platform-banking-section-expanded`);
  if (!elem.classList.contains("is-expanded")) {
    document.getElementById(`platform-banking-section`).click();
  }
};
