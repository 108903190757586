import React from "react";
import {
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Heading,
  VStack,
  HStack,
} from "@chakra-ui/react";
import PropTypes from "prop-types";

/**
 * @prop {node} children - The content of the section.
 * @prop {string} name - The name of the section.
 * @prop {element} tooltip - The name of the section.
 */

export const Section = ({ children, name = "", tooltip: Tooltip }) => {
  return (
    <AccordionItem data-test-id={`accordion-${name.toLowerCase()}`}>
      <AccordionButton w={"full"}>
        <HStack gap={"5px"}>
          <Heading as="h3" size={"small"} fontWeight={600}>
            {name}
          </Heading>
          {React.isValidElement(Tooltip) && Tooltip}
        </HStack>
        <AccordionIcon ml={"auto"} w={"24px"} h="24px" />
      </AccordionButton>
      <AccordionPanel pb={4}>
        <VStack gap={"24px"} overflow={"visible"} alignItems={"stretch"}>
          {children}
        </VStack>
      </AccordionPanel>
    </AccordionItem>
  );
};

Section.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  tooltip: PropTypes.element,
};
