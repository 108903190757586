import React, { useState, useRef, useEffect } from "react";
import { Box, Button, VStack, Text, useDisclosure, useOutsideClick } from "@chakra-ui/react";
import { motion, AnimatePresence } from "framer-motion";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";

const MotionBox = motion(Box);

// Helper function to get the label of an option (handles both strings and objects)
const getOptionLabel = (option) => (typeof option === "string" ? option : option.label);

// Helper function to get the value of an option (handles both strings and objects)
const getOptionValue = (option) => (typeof option === "string" ? option : option.value);

const Select = React.forwardRef(({ options, onChange, defaultValue = "", ...rest }, ref) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const selectRef = useRef();

  useOutsideClick({
    ref: selectRef,
    handler: onClose,
  });

  useEffect(() => {
    setSelectedValue(defaultValue);
  }, [defaultValue]);

  const handleOptionSelect = (value) => {
    setSelectedValue(value);
    onChange(value);
    onClose();
  };

  return (
    <Box ref={selectRef} position="relative">
      <Button
        onClick={isOpen ? onClose : onOpen}
        rightIcon={isOpen ? <ChevronUpIcon w="16px" h="16px" /> : <ChevronDownIcon w="16px" h="16px" />}
        width="100%"
        aria-haspopup="listbox"
        aria-expanded={isOpen}
        color="textSubtext.400"
        boxShadow={"none"}
        justifyContent={"space-between"}
        {...rest}
      >
        {selectedValue ? getOptionLabel(selectedValue) : "Select an option"}
      </Button>

      <AnimatePresence>
        {isOpen && (
          <MotionBox
            position="absolute"
            width="100%"
            bg="dark.600"
            boxShadow="md"
            mt="2"
            zIndex="1"
            borderRadius="10px"
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            role="listbox"
            aria-activedescendant={selectedValue ? getOptionValue(selectedValue) : null}
            p={"8px"}
          >
            <VStack gap={"4px"} align="start">
              {options.map((option) => (
                <Text
                  key={getOptionValue(option)}
                  width="100%"
                  p="4px"
                  pl="8px"
                  cursor="pointer"
                  _hover={{ bg: "transparents.eBlue" }}
                  bg={getOptionValue(selectedValue) === getOptionValue(option) ? "transparents.eBlue" : "transparent"}
                  borderRadius={"8px"}
                  onClick={() => handleOptionSelect(option)}
                  role="option"
                  aria-selected={selectedValue && getOptionValue(selectedValue) === getOptionValue(option)}
                  id={`option-${getOptionValue(option)}`}
                  color={"textSubtext.400"}
                >
                  {getOptionLabel(option)}
                </Text>
              ))}
            </VStack>
          </MotionBox>
        )}
      </AnimatePresence>
    </Box>
  );
});

Select.displayName = "Select";
export default Select;
