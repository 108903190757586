import { StyledProgressTooltip } from "../../StyledAdvanceCard";
import moment from "moment";
import React from "react";
import TooltipRow from "./TooltipRow";
import advCopy from "@utils/text/advance";

const FixedTermUpcomingTooltip = ({
  state,
  repaymentAmount,
  repaymentDate,
  capitalFeePercentage,
  advanceCurrency,
  gotoTransactionsHistory,
}) => {
  return (
    <StyledProgressTooltip state={state} isFixed>
      <div className={"tooltip-content " + state}>
        <TooltipRow
          accordion
          strong
          color
          label="Scheduled Repayment"
          display={advCopy.fixedTermCurrencyDisplay(repaymentAmount, advanceCurrency)}
          hint={`Including ${capitalFeePercentage}% of capital fee`}
        />

        <TooltipRow
          accordion
          strong
          label="Est. Repayment Date"
          display={moment(repaymentDate).format("MMM DD, YYYY")}
          hint={
            <span>
              It may take up to 5 days to collect a repayment from your bank account. Once it’s done – you’ll see it as
              a completed transaction in the{" "}
              <span onClick={gotoTransactionsHistory} style={{ color: "#C5C5C5", cursor: "pointer" }}>
                Transactions History
              </span>
              .
            </span>
          }
        />
      </div>
    </StyledProgressTooltip>
  );
};

export default FixedTermUpcomingTooltip;
