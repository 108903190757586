import React from "react";

import AuthenticationContainer from "@pages/authentication/AuthenticationContainer";
import {
  SanloModal,
  SanloModalTitle,
  SanloModalDescription,
  Highlight,
} from "@components/common/modals/SanloModal/SanloModal";
import { SanloButton } from "@inputs";

import symbols from "@utils/symbols";
import { useLogout } from "@utils/custom-hooks/useAuth";

export const VerificationUnknownError = ({ email }) => {
  const appLogout = useLogout();

  return (
    <AuthenticationContainer hasLogout>
      <SanloModal>
        <SanloModalTitle>
          {symbols.FrowningFace()}
          Something went wrong...
        </SanloModalTitle>
        <SanloModalDescription>
          Something went wrong on our end. To access your account, please click on the verification link in the email we
          previously send to <Highlight>{email}</Highlight>.
        </SanloModalDescription>
        <SanloButton
          label="Go to Log In"
          onClick={() => {
            appLogout();
          }}
          style={{
            marginTop: "24px",
          }}
        />
      </SanloModal>
    </AuthenticationContainer>
  );
};
