import React, { useState } from "react";
import { ReactComponent as ArrowIcon } from "@assets/img/icons/common/accordion-arrow.svg";
import classnames from "classnames";
import { BodyMini12, BodySmallAlt, BodySmallPlus } from "@styled";
import { currencyFormat } from "@utils/currency";

export const TooltipRow = ({ label, strong = false, display, hint, color = false, accordion = false }) => {
  const [hintOpen, setHintOpen] = useState(false);

  const toggleHint = (e) => {
    setHintOpen(!hintOpen);

    // Stop onClick propagation so when click don't redirect to Growth capital page
    e.cancelBubble = true;
    if (e.stopPropagation) e.stopPropagation();
  };

  return (
    <div className="tooltip-content-row">
      <div className={"tooltip-row-main-content"}>
        <div className="row-label">
          {strong ? (
            <BodySmallPlus className="strong-label">{label}</BodySmallPlus>
          ) : (
            <BodySmallAlt>{label}</BodySmallAlt>
          )}
          {accordion && hint && (
            <ArrowIcon
              className={classnames("row-icon", {
                "hint-open": hintOpen,
              })}
              onClick={toggleHint}
            />
          )}
        </div>
        <BodySmallAlt
          className={classnames("row-display", {
            "display-color": color,
          })}
        >
          {typeof display === "number" && !isNaN(display) ? (
            <span className="highlighted-amount">{currencyFormat(display)}</span>
          ) : (
            display
          )}
        </BodySmallAlt>
      </div>
      {(accordion ? hintOpen : hint) && <BodyMini12 className="row-hint">{hint}</BodyMini12>}
    </div>
  );
};

export default TooltipRow;
