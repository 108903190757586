export const calculateFontSizes = (baseFontSize, mobileFontSizeBase = 14, desktopFontSizeBase = 16) => {
  const mobileFontSize =
    ((baseFontSize / desktopFontSizeBase) * mobileFontSizeBase) % 1 === 0
      ? Math.round((baseFontSize / desktopFontSizeBase) * mobileFontSizeBase)
      : ((baseFontSize / desktopFontSizeBase) * mobileFontSizeBase).toFixed(2);
  return {
    desktop: `${baseFontSize}px`,
    mobile: `${mobileFontSize}px`,
  };
};
