import styled from "styled-components";

export const StyledConnectionLoadingCard = styled.div`

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;

  max-width: 360px;
  min-height: 156px;
   margin-bottom: 2rem;

  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    width: 360px;
    min-height: 156px;
    padding: 16px;
    overflow: hidden;
    border-radius: 12px;
    background-color: ${({ theme }) => theme.grayscale20};

    .header {
      display: flex;
      flex-direction: row;
      height: 56px;
      margin-bottom: 16px;

      .description {
        height: 56px;
      }

      .img-placeholder {
        min-width: 56px;
        margin-right: 16px;
        border-radius: 10px;
        background-color: ${({ theme }) => theme.grayscale50};

      }
    }

    .description {
      height: 40px;
      width: 100%;
      border-radius: 10px;
      background-color: ${({ theme }) => theme.grayscale50};
    }
  }

  .animated-placeholder {
    animation: fade 3200ms infinite;
    -webkit-animation: fade 3200ms infinite;
  }

  @keyframes fade {
    from { opacity: 1.0; }
    50% { opacity: 0.4; }
    to { opacity: 1.0; }
  }

  @-webkit-keyframes fade {
    from { opacity: 1.0; }
    50% { opacity: 0.4; }
    to { opacity: 1.0; }
  }

  .blink {
    animation:fade 1000ms infinite;
    -webkit-animation:fade 1000ms infinite;

  @keyframes pulse {
    50% {
      opacity: .4;
    }

    100% {
      opacity: 1;
    }
  }
`;
