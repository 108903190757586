import React from "react";
import { integrationCardLabels, integrationStates, integrationToolTip } from "./IntegrationStates";

import { alphaTrim } from "@utils/general";
import { NO_PERMISSIONS } from "@utils/status";

import { BodyRegularPlus, BodySmall14 } from "@styled";
import StateButton from "@components/common/buttons/state-button/StateButton";
import { SanloTooltip } from "@components/common/tooltips/sanlo-tooltip/SanloTooltip";

// import { DisconnectModal } from "@components/common/modals/integrations-modal/DisconnectModal";
import StyledIntegrationStateCard, { StyledDescriptionLabel } from "./StyledIntegrationStateCard";
import { NEEDS_RECONNECTION } from "../../../utils/status";

// import {ReactComponent as ContextMenuIcon} from '@assets/img/icons/common/more.svg'

const BankingStateCard = ({ platform = {}, handlers = {}, status }) => {
  // NOTE:
  // Disabling the context menu at the momment since we don't have the
  // unlinking API in place yet, so there is no reason for it be visisble
  // const [contextMenu, setContextMenu] = useState(false);
  // const contextMenuRef = useRef()

  const {
    onClick = () => {},
    // onUnlink = () => {},
  } = handlers;

  // // On Component Mount
  // useEffect(() => {
  //   document.addEventListener('click', handleClickOutisde);
  //   // On Component Unmount
  //   return () => {
  //     document.removeEventListener('click', handleClickOutisde);
  //   }
  // }, []);

  // const handleClickOutisde = (event) => {
  //   if (contextMenuRef.current && !contextMenuRef.current.contains(event.target)) {
  //     setContextMenu(false)
  //   }
  // }

  const statusDescription = integrationCardLabels(platform, platform.lastUpdated)[status];
  const toolTip = integrationToolTip({
    lastUpdated: platform.lastUpdated,
  });

  return (
    <StyledIntegrationStateCard className="grid-card" data-test-id="integration-bank">
      <div className="card-content">
        <img className="card-image image-contain" src={platform.logo} alt={`${platform.name} logo`} />

        <div className="card-details">
          <div className="platform-headings">
            <BodyRegularPlus id={`tt_id_${alphaTrim(platform.name || "platform-name")}`} className="platform-name">
              {platform.name}
            </BodyRegularPlus>
            {platform.name?.length > 0 && [NO_PERMISSIONS, NEEDS_RECONNECTION].includes(platform.status) && (
              <SanloTooltip
                target={`tt_id_${alphaTrim(platform.name || "platform-name")}`}
                text={toolTip?.content || platform.name}
                heading={toolTip?.heading}
                placement="top"
                maxWidth={300}
              />
            )}
            <div className="platform-state-container">
              {statusDescription && (
                <StyledDescriptionLabel state={statusDescription.state}>
                  <BodySmall14
                    className="platform-state-description"
                    id={`tt_id_${alphaTrim(platform.name || "platform-name")}`}
                  >
                    {statusDescription.label}
                  </BodySmall14>
                </StyledDescriptionLabel>
              )}
            </div>
          </div>

          {/* <div className="context-menu-button">
            <ContextMenuIcon ref={contextMenuRef} onClick={() => setContextMenu(!contextMenu)}/>
            <ContextMenu
              platform={platform}
              open={contextMenu}
              toggle={() => setContextMenu(!contextMenu)}
              onUnlink={onUnlink}
            />
          </div> */}
        </div>
      </div>

      <ConnectionStatus status={status} onClick={onClick} />
    </StyledIntegrationStateCard>
  );
};

const ConnectionStatus = ({ status, onClick }) => {
  const { hover = false, state, buttonLabel } = integrationStates[status] ?? {};

  return (
    <div className="connection-state">
      <StateButton hover={hover} state={state} label={buttonLabel} onClick={onClick} />
    </div>
  );
};

// const ContextMenu = ({onUnlink, open, platform}) => {
//   const [openModal, setOpenModal] = useState(false)

//   return (
//     <div
//       onClick={() => {
//         setOpenModal(true);
//       }}
//       className={"unlink-container " + (open ? 'menu-open' : '')}
//     >
//       <BodySmallPlus className="unlink-integration">Unlink</BodySmallPlus>
//       <DisconnectModal
//         platform={platform}
//         openModal={openModal}
//         toggle={() => {
//           setOpenModal(!openModal);
//         }}
//         disconnect={() => onUnlink()}
//       />
//     </div>
//   )
// }

export default BankingStateCard;
