import {
  getErrorStatus,
  getRequestStatus,
  getResponseStatus,
  integrationInitialState,
  setStatusDefaults,
  stateSet,
  checkNetworkError,
  parseIntegration,
} from "@utils/redux";
import { PRODUCT_ANALYTICS } from "@utils/consts";
import { DRAFT_CONNECTION, NOT_CONNECTED, CONNECTOR_ERROR, NO_PERMISSIONS } from "@utils/status";

import { GET_INTEGRATIONS_CONNECTIONS } from "../base/actions";

import {
  APPSFLYER_GET_DRAFT_CREDENTIALS,
  APPSFLYER_SAVE_DRAFT_CREDENTIALS,
  APPSFLYER_SAVE_CREDENTIALS,
  APPSFLYER_TEST_CREDENTIALS,
  APPSFLYER_TEST_CREDENTIALS_ERRORS,
  APPSFLYER_GET_UA_SPEND,
  APPSFLYER_REFRESH_UA,
  APPSFLYER_UNLINK_INTEGRATION,
  APPSFLYER_CLEAR_CREDENTIALS_STATUS,
} from "./actions";

import { getHighestPriorityStatus } from "@utils/integrations";

const initialState = integrationInitialState({
  id: "appsflyer",
  name: "AppsFlyer",
  platformId: "appsflyer",
  type: PRODUCT_ANALYTICS,
  category: PRODUCT_ANALYTICS,
  image: require("@assets/img/icons/logos/AppsFlyerRoundedLogo.svg").default,
  canSaveProgress: false,
  isPlatform: true,
  isAdPlatform: true,
  hasUASpend: true,
  requestStatus: {
    getData: setStatusDefaults(),
    getDraftCredentials: setStatusDefaults(),
    saveDraftCredentials: setStatusDefaults(),
    saveCredentials: setStatusDefaults(),
    testCredentials: setStatusDefaults(),
    unlinkIntegration: setStatusDefaults(),
  },
  credentials: {
    status: "default", // default | valid | invalid
    draft: false,
    apiToken: "",
    appTokens: [""],
    appIds: "not-set",
  },
  steps: [
    {
      title: "Enter your API token",
      subtitle: "Enter your AppsFlyer API token to establish a connection.",
    },
    {
      title: "Enter your App ID",
      subtitle: "Enter your App ID(s) to start syncing data for your apps.",
    },
    {
      // Placeholder step for permissions information
      title: null,
      subtitle: null,
      hideProgress: true,
      isSetupDone: true,
    },
    {
      // Placeholder step for manual connection
      title: null,
      subtitle: null,
      hideProgress: true,
      isSetupDone: true,
    },
    {
      // Placeholder step for manual connection complete
      title: null,
      subtitle: null,
      hideProgress: true,
      isSetupDone: true,
    },
  ],
});

const appsFlyerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INTEGRATIONS_CONNECTIONS.RESPONSE: {
      return {
        ...state,
        ...parseIntegration("appsflyer", action.response, {
          currentStatus: state.status,
        }),
        name: state.name, // Keep the display name we have set in the intial state
      };
    }

    case APPSFLYER_GET_DRAFT_CREDENTIALS.REQUEST: {
      return stateSet(state, {
        "requestStatus.getDraftCredentials": getRequestStatus(),
      });
    }

    case APPSFLYER_GET_DRAFT_CREDENTIALS.RESPONSE: {
      return stateSet(state, {
        "requestStatus.getDraftCredentials": getResponseStatus(),
        "credentials": {
          draft: true,
          apiToken: action.response.apiToken,
          appTokens: action.response.appTokens,
        },
      });
    }

    case APPSFLYER_GET_DRAFT_CREDENTIALS.ERROR: {
      return stateSet(state, {
        "requestStatus.getDraftCredentials": getErrorStatus(),
      });
    }

    case APPSFLYER_SAVE_DRAFT_CREDENTIALS.REQUEST: {
      return stateSet(state, {
        "requestStatus.saveDraftCredentials": getRequestStatus(),
      });
    }

    case APPSFLYER_SAVE_DRAFT_CREDENTIALS.RESPONSE: {
      return stateSet(state, {
        "status": action.response.apiToken ? DRAFT_CONNECTION : NOT_CONNECTED,
        "requestStatus.saveDraftCredentials": getResponseStatus(),
        "requestStatus.testCredentials": {
          error: false,
        },
        "credentials": {
          draft: true,
          apiToken: action.response.apiToken,
          appTokens: action.response.appTokens,
        },
      });
    }

    case APPSFLYER_SAVE_DRAFT_CREDENTIALS.ERROR: {
      return stateSet(state, {
        "requestStatus.saveDraftCredentials": getErrorStatus(),
      });
    }

    case APPSFLYER_SAVE_CREDENTIALS.REQUEST: {
      return stateSet(state, {
        "requestStatus.saveCredentials": getRequestStatus(),
        "credentials.status": "default",
      });
    }

    case APPSFLYER_SAVE_CREDENTIALS.RESPONSE: {
      action.response.status = getHighestPriorityStatus(action.response);
      return stateSet(state, {
        "requestStatus.saveCredentials": getResponseStatus(),
        "credentials": {
          status: action.response.status === NO_PERMISSIONS ? "no_permissions" : "valid",
          draft: false,
          apiToken: "",
          appTokens: [""],
          appIds: "valid",
        },
        // 'credentials.status': action.response.status === NO_PERMISSIONS ? "no_permissions" : "valid",
        // 'credentials.appIds': 'valid',
      });
    }

    case APPSFLYER_SAVE_CREDENTIALS.ERROR: {
      return stateSet(state, {
        "requestStatus.saveCredentials": getErrorStatus(),
        "credentials.appIds": "invalid",
      });
    }

    case APPSFLYER_GET_UA_SPEND.REQUEST: {
      return stateSet(state, {
        "requestStatus.getData": getRequestStatus(),
      });
    }

    case APPSFLYER_GET_UA_SPEND.RESPONSE: {
      // The integrations base reducer listens for this event as well
      return stateSet(state, {
        "requestStatus.getData": getResponseStatus(),
      });
    }

    case APPSFLYER_GET_UA_SPEND.ERROR: {
      return stateSet(state, {
        "requestStatus.getData": getErrorStatus(),
        "status": checkNetworkError(action) ? CONNECTOR_ERROR : state.status,
      });
    }

    case APPSFLYER_TEST_CREDENTIALS.REQUEST: {
      return stateSet(state, {
        "requestStatus.testCredentials": getRequestStatus(),
      });
    }

    case APPSFLYER_TEST_CREDENTIALS.RESPONSE: {
      return stateSet(state, {
        "requestStatus.testCredentials": getResponseStatus(),
        "credentials": {
          apiToken: "valid",
          appIds: "not-set",
        },
      });
    }

    case APPSFLYER_TEST_CREDENTIALS.ERROR: {
      return stateSet(state, {
        "requestStatus.testCredentials": getErrorStatus(),
        "credentials": {
          apiToken: "invalid",
        },
      });
    }

    case APPSFLYER_REFRESH_UA.REQUEST: {
      return stateSet(state, {
        "requestStatus.refreshUASpend": getErrorStatus(),
      });
    }

    case APPSFLYER_REFRESH_UA.RESPONSE: {
      return stateSet(state, {
        "requestStatus.refreshUASpend": getErrorStatus(),
      });
    }

    case APPSFLYER_REFRESH_UA.ERROR: {
      return stateSet(state, {
        "requestStatus.refreshUASpend": getErrorStatus(),
      });
    }

    case APPSFLYER_UNLINK_INTEGRATION.REQUEST: {
      return stateSet(state, {
        "requestStatus.unlinkIntegration": getRequestStatus(),
      });
    }

    case APPSFLYER_UNLINK_INTEGRATION.RESPONSE: {
      return stateSet(state, {
        "requestStatus.unlinkIntegration": getResponseStatus(),
        "credentials.status": "default",
      });
    }

    case APPSFLYER_UNLINK_INTEGRATION.ERROR: {
      return stateSet(state, {
        "requestStatus.unlinkIntegration": getErrorStatus(),
      });
    }

    case APPSFLYER_TEST_CREDENTIALS_ERRORS.CLEAR: {
      return stateSet(state, {
        "requestStatus.testCredentials": setStatusDefaults(),
        "requestStatus.saveCredentials": setStatusDefaults(),
      });
    }

    case APPSFLYER_CLEAR_CREDENTIALS_STATUS.EVENT: {
      return stateSet(state, {
        "credentials.status": "default",
      });
    }

    default:
      return state;
  }
};

export default appsFlyerReducer;
