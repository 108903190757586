import React from "react";

import { BodySmall14 } from "@styled";

import { Link } from "@inputs";
import { COMMON_ACTION } from "@utils/gtm";

export const GuideMoreDetails = ({ platform = {}, href = "", trackEvent = () => {} }) => {
  // Other platforms use callouts as well, but only the ua spend ones follow the same structure
  // to where a reusable component was warranted.
  return (
    <BodySmall14>
      {`For more details, see following document from ${platform.name} `}
      <Link href={href} onClick={() => trackEvent(COMMON_ACTION.HERE_LINK)}>
        {` here.`}
      </Link>
    </BodySmall14>
  );
};
