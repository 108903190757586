import HTTP from "@redux/http";

const SANLO_WEB_API_URL = process.env.REACT_APP_SANLO_WEB_API_URL;
const BASE = `${SANLO_WEB_API_URL}/api/fairground/console/webshops`;

class AuthMethodsService {
  addAuthMethod(data, webshopId) {
    return HTTP.req.post(`${BASE}/${webshopId}/storefront/settings/auth/methods`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  updateAuthMethod(data, webshopId, methodId) {
    return HTTP.req.put(`${BASE}/${webshopId}/storefront/settings/auth/methods/${methodId}`, data);
  }

  deleteAuthMethod(webshopId, methodId) {
    return HTTP.req.delete(`${BASE}/${webshopId}/storefront/settings/auth/methods/${methodId}`);
  }

  updateAuthMethodFile(data, webshopId, methodId) {
    return HTTP.req.post(`${BASE}/${webshopId}/storefront/settings/auth/methods/${methodId}/file`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  deleteAuthMethodFile(webshopId, methodId) {
    return HTTP.req.delete(`${BASE}/${webshopId}/storefront/settings/auth/methods/${methodId}/file`);
  }

  getAuthMethods(webshopId) {
    return HTTP.req.get(`${BASE}/${webshopId}/storefront/settings/auth/methods`);
  }
}

export default new AuthMethodsService();
