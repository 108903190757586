import HTTP from "@redux/http";

const SANLO_WEB_API_URL = process.env.REACT_APP_SANLO_WEB_API_URL;
const BASE = `${SANLO_WEB_API_URL}/api/fairground/console/api-key`;

class DeveloperService {
  getApiKey() {
    return HTTP.req.get(BASE);
  }
  generateApiKey() {
    return HTTP.req.post(BASE);
  }
}

export default new DeveloperService();
