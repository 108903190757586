import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  VStack,
  useClipboard,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { webshopsSelector } from "@redux/webshops/webshopsSlice";
import { catalogThunks, resetDeepLinkAvailable, deepLinkAvailableSelector } from "@redux/webshops/catalog/catalogSlice";
import { showToast } from "@src/utils/redux";
import { useFormContext } from "react-hook-form";
import { SubsectionTitle } from "@components/catalog";
import { DeepLinkFieldTooltip } from "@components/catalog/tooltip";
import { addHttpsToUrl } from "@src/utils/string";
import { ReactComponent as CopyIcon } from "@assets/img/icons/common/copy.svg";

export const DeepLink = () => {
  const dispatch = useDispatch();
  const [deepLinkURL, setDeepLinkURL] = useState("");
  const { setValue: setValueClipboard, onCopy, hasCopied } = useClipboard("");
  const { formState, getValues, register, reset, watch } = useFormContext();

  const webshopsData = useSelector(webshopsSelector);
  const webshopDomain = webshopsData.selectedWebshop.domain;

  const { data: { available = true, alternatives = [] } = {}, requestStatus = {} } =
    useSelector(deepLinkAvailableSelector);

  const { deepLinkCode } = watch();

  useEffect(() => {
    let newUrl = addHttpsToUrl(webshopDomain);
    let webshopId = "";

    const urlObj = new URL(newUrl);
    const params = new URLSearchParams(urlObj.search);
    webshopId = params.get("webshopId");

    if (deepLinkCode) {
      const separator = webshopId ? "&" : "/?";
      newUrl += `${separator}deepLinkCode=${deepLinkCode}`;
    }

    setDeepLinkURL(newUrl);
    setValueClipboard(newUrl);
  }, [deepLinkCode, setValueClipboard, webshopDomain]);

  const verifyDeepLink = async () => {
    try {
      const { available } = await dispatch(catalogThunks.verifyDeepLink(deepLinkCode)).unwrap();
      if (available) reset({ ...getValues() });
    } catch (error) {
      showToast("There was an error verifying the code", "error");
    }
  };

  const selectSuggestion = (item) => {
    reset({ ...getValues(), deepLinkCode: item });
    dispatch(resetDeepLinkAvailable());
  };

  const validateDeepLinkCode = (value) => {
    return !value || (available && !formState.dirtyFields?.deepLinkCode);
  };

  useEffect(() => {
    if (!deepLinkCode) {
      dispatch(resetDeepLinkAvailable());
    }
  }, [deepLinkCode, dispatch]);

  return (
    <FormControl>
      <SubsectionTitle tooltip={<DeepLinkFieldTooltip />}>Deep Link URL</SubsectionTitle>
      <VStack alignItems={"flex-start"} gap={"8px"}>
        <Text size={"small"} color={"textSubtext.400"}>
          Copy the URL below to link your players directly to this item. Edit the url to make it easier to remember.
        </Text>

        <VStack w={"100%"} gap={"8px"} alignItems={"flex-start"}>
          <Box w={"100%"} p={"12px"} borderLeft={"2px solid"} borderColor={"brandSkyBlue.400"}>
            <Text size={"smallAlt"}>{deepLinkURL}</Text>
          </Box>

          <InputGroup>
            <Input
              {...register("deepLinkCode", {
                validate: validateDeepLinkCode,
              })}
              value={hasCopied ? "Copied!" : deepLinkCode}
              aria-label={"deep link code"}
              bgColor={!available ? "brandRedAlpha.400" : "default"}
            />
            {deepLinkCode && available && !formState.dirtyFields?.deepLinkCode && (
              <InputRightElement onClick={onCopy}>
                <Icon w="16px" h="16px" as={CopyIcon} cursor={"pointer"} />
              </InputRightElement>
            )}
          </InputGroup>
        </VStack>

        {!available && (
          <VStack w={"100%"} gap={"24px"}>
            <Text size={"small"} color={"brandRed.400"}>
              This deep link url is already being used for another item
            </Text>

            <VStack w={"100%"} gap={"8px"} alignItems={"flex-start"}>
              <Text size={"small"} color={"textWhite.400"} fontWeight={600}>
                Suggestions
              </Text>

              <VStack w={"100%"} wrap={"wrap"} gap={"8px"} alignItems={"flex-start"}>
                {alternatives.map((item) => (
                  <Button
                    key={`suggestion-${item}`}
                    w={"100%"}
                    color={"brandSkyBlue.400"}
                    justifyContent={"flex-start"}
                    onClick={() => selectSuggestion(item)}
                  >
                    {item}
                  </Button>
                ))}
              </VStack>
            </VStack>
          </VStack>
        )}
        {deepLinkCode && formState.dirtyFields?.deepLinkCode && (
          <Flex w={"100%"}>
            <Button mt={"16px"} px={"12px"} onClick={verifyDeepLink} isLoading={requestStatus.pending}>
              Verify
            </Button>
          </Flex>
        )}
      </VStack>
    </FormControl>
  );
};
