import symbols from "@src/utils/symbols";
import isEmpty from "lodash/isEmpty";

import { ONBOARDING_ACTIONS } from "@utils/gtm";

export const ONBOARDING_SUPERPOWERS_TYPE = {
  WEBSHOPS: "WEBSHOPS",
  UPCOMING_PLATFORM_PAYOUTS: "UPCOMING_PLATFORM_PAYOUTS",
  UPCOMING_AD_REVENUE_PAYOUTS: "UPCOMING_AD_REVENUE_PAYOUTS",
  DAILY_REVENUE: "DAILY_REVENUE",
  GROWTH_CAPITAL: "GROWTH_CAPITAL",
  EARLY_ACCESS: "EARLY_ACCESS",
  CASHFLOW_ANALYSIS: "CASHFLOW_ANALYSIS",
  ALL: "ALL",
  BROWSING: "BROWSING",
};

const TO_DASHBOARD_TYPES = {
  [ONBOARDING_SUPERPOWERS_TYPE.EARLY_ACCESS]: true,
  [ONBOARDING_SUPERPOWERS_TYPE.BROWSING]: true,
  [ONBOARDING_SUPERPOWERS_TYPE.WEBSHOPS]: true,
};

export const SUPERPOWER_BROWSING = {
  id: ONBOARDING_SUPERPOWERS_TYPE.BROWSING,
  title: "Not sure yet, just browsing.",
  isChecked: false,
};

export const SUPERPOWERS = [
  {
    id: ONBOARDING_SUPERPOWERS_TYPE.WEBSHOPS,
    title: `${symbols.shoppingBags} Webshops (Closed beta)`,
    content: "Boost your revenue, protect margins and own relationships with your players.",
    isChecked: false,
  },
  {
    id: ONBOARDING_SUPERPOWERS_TYPE.GROWTH_CAPITAL,
    title: `${symbols.moneyBag} Growth Capital`,
    content: "Unlock up to $1MM in non-dilutive capital with simple payment terms.",
    isChecked: false,
  },
];

export const markSuperpowersAsChecked = ({ preferences = [], browsingEnabled = false }) => {
  const newSuperpowers = SUPERPOWERS.map((superpower) => {
    if (preferences.includes(superpower.id)) {
      return { ...superpower, isChecked: true };
    }
    return superpower;
  });
  if (browsingEnabled) {
    newSuperpowers.push(SUPERPOWER_BROWSING);
  }
  // Our "safe list" here will just be browsing, since it goes straight to the dashboard
  return isEmpty(newSuperpowers) ? [SUPERPOWER_BROWSING] : newSuperpowers;
};

export const isToDashboardSuperpower = (superpowersIds = []) => {
  // When going to dashboard only one superpower is selected
  if (superpowersIds.length !== 1) return false;
  return TO_DASHBOARD_TYPES[superpowersIds[0]];
};

export const getOnboardingInfo = (superpowersIds = []) => {
  try {
    const action = superpowersIds.includes(ONBOARDING_SUPERPOWERS_TYPE.GROWTH_CAPITAL)
      ? ONBOARDING_ACTIONS.ALL_INTEGRATION
      : ONBOARDING_ACTIONS.PLATFORMS_INTEGRATION;
    return {
      goToDashboard: isToDashboardSuperpower(superpowersIds),
      onboardingAction: action,
    };
  } catch (error) {
    return {
      goToDashboard: false,
      onboardingAction: "",
    };
  }
};

export const toggleSuperpowerUtil = ({ superpowers, superpowerId }) => {
  const updatedSuperpowers = [...superpowers];
  const selectedOptionIndex = updatedSuperpowers.findIndex((option) => option.id === superpowerId);
  updatedSuperpowers[selectedOptionIndex].isChecked = !updatedSuperpowers[selectedOptionIndex].isChecked;
  return updatedSuperpowers;
};

export const getCheckedSuperpowersIds = (superpowers = []) => {
  return superpowers.filter((superpower) => superpower.isChecked).map((superpower) => superpower.id);
};
