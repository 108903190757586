import React from "react";
import styled from "styled-components";

import { BodySmall14, TitleLargePlus } from "@styled";

import { ReactComponent as CrossIcon } from "@assets/img/icons/common/modal-cross.svg";
import { ReactComponent as ArrowIcon } from "@assets/img/icons/common/arrow_left.svg";

const StyledNav = styled.div`
  width: 100%;
`;

const StyledNavControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.textEmphasis};
`;

const StyledNavBack = styled(ArrowIcon)`
  padding: 6.5px 8px 8px 8px;
  width: 40px;
  height: 40px;
  fill: ${({ theme }) => theme.textEmphasis};
  border-radius: 8px;

  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.iconHoverBackground};
  }

  &.hidden {
    visibility: hidden;
  }
`;

const StyledNavClose = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;

  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.iconHoverBackground};
  }

  svg {
    width: 24px;
    height: 24px;
    fill: ${({ theme }) => theme.textEmphasis};
  }
`;

const StyledNavHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 48px;
`;

export const Navigation = ({ onClose = () => {}, platformName = "" }) => {
  return (
    <StyledNav>
      <StyledNavControls>
        <StyledNavBack aria-hidden={true} onClick={onClose} />
        <StyledNavClose aria-label="Close" data-dismiss="modal" type="button" onClick={onClose}>
          <CrossIcon aria-hidden={true} />
        </StyledNavClose>
      </StyledNavControls>

      <StyledNavHeader>
        <TitleLargePlus>Provide access to {platformName}</TitleLargePlus>
        <BodySmall14
          style={{
            marginTop: "8px",
          }}
        >
          Grant permissions for {platformName} by following setup instructions below.
        </BodySmall14>
      </StyledNavHeader>
    </StyledNav>
  );
};
