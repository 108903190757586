import HTTP from "@redux/http";

const SANLO_WEB_API_URL = process.env.REACT_APP_SANLO_WEB_API_URL;
const BASE = `${SANLO_WEB_API_URL}/api/banking/v2/plaid`;

class PlaidService {
  getLinkToken() {
    return HTTP.req.get(`${BASE}/link-token`);
  }

  getUpdatedLinkToken(connectionId) {
    return HTTP.req.get(`${BASE}/${connectionId}/link-token/update`);
  }

  setPersistConnection(data) {
    return HTTP.req.post(`${BASE}/persist-connection-details`, data);
  }

  reAuthorize(data) {
    return HTTP.req.post(`${BASE}/reauthorize`, data);
  }
}

export default new PlaidService();
