import React from "react";

import { BetaTag } from "@components/common/banners/BetaTag";

import { ReactComponent as DashboardIcon } from "@assets/img/icons/pages/dashboard_outline.svg";
import { ReactComponent as DashboardFilledIcon } from "@assets/img/icons/pages/dashboard_filled.svg";
import { ReactComponent as WebshopsIcon } from "@assets/img/icons/pages/webshops_outline.svg";
import { ReactComponent as WebshopsFilledIcon } from "@assets/img/icons/pages/webshops_filled.svg";
import { ReactComponent as GrowthCapitalIcon } from "@assets/img/icons/pages/growth_capital_outline.svg";
import { ReactComponent as GrowthCapitalFilledIcon } from "@assets/img/icons/pages/growth_capital_filled.svg";
import { ReactComponent as DocumentsIcon } from "@assets/img/icons/pages/documents_outline.svg";
import { ReactComponent as DocumentsFilledIcon } from "@assets/img/icons/pages/documents_filled.svg";
import { ReactComponent as FinancialIntelligenceIcon } from "@assets/img/icons/pages/fa_outline.svg";
import { ReactComponent as FinancialIntelligenceFilledIcon } from "@assets/img/icons/pages/fa_filled.svg";
import { ReactComponent as IntegrationsIcon } from "@assets/img/icons/pages/integrations_outline.svg";
import { ReactComponent as IntegrationsFilledIcon } from "@assets/img/icons/pages/integrations_filled.svg";

const routes = [
  {
    flag: "",
    name: "User",
    icon: "Image",
  },
  {
    flag: "showDashboardPage",
    path: "/dashboard",
    name: "Dashboard",
    icon: <DashboardIcon />,
    iconFilled: <DashboardFilledIcon />,
  },
  {
    flag: "clientFinancialAnalysisPageEnabled",
    path: "/financial-analytics",
    name: "Financial Analytics",
    icon: <FinancialIntelligenceIcon />,
    iconFilled: <FinancialIntelligenceFilledIcon />,
    suffix: (
      <BetaTag
        style={{
          marginLeft: "6px",
        }}
      />
    ),
  },
  {
    flag: "clientWebshopsPageEnabled",
    path: "/webshops",
    name: "Webshops",
    icon: <WebshopsIcon />,
    iconFilled: <WebshopsFilledIcon />,
    suffix: (
      <BetaTag
        style={{
          marginLeft: "6px",
        }}
      />
    ),
  },
  {
    flag: "showGrowthCapital",
    path: "/growth-capital",
    name: "Growth Capital",
    icon: <GrowthCapitalIcon />,
    iconFilled: <GrowthCapitalFilledIcon />,
  },
  {
    split: true,
    flag: "showIntegrationsPage",
    path: "/integrations",
    name: "Integrations",
    icon: <IntegrationsIcon />,
    iconFilled: <IntegrationsFilledIcon />,
  },
  {
    flag: "clientDocumentsPageVisible",
    path: "/documents",
    name: "Documents",
    icon: <DocumentsIcon />,
    iconFilled: <DocumentsFilledIcon />,
  },
];

export default routes;
