import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getResponseStatus, getRequestStatus, getErrorStatus, setStatusDefaults } from "@utils/redux";

import DeveloperService from "./developerApi";

export const developerThunks = {
  getApiKey: createAsyncThunk("webshops/getApiKey", async (_, { rejectWithValue }) => {
    try {
      const res = await DeveloperService.getApiKey();
      return res.data;
    } catch (e) {
      return rejectWithValue({ error: e.response.data });
    }
  }),
  generateApiKey: createAsyncThunk("webshops/generateApiKey", async (_, { rejectWithValue }) => {
    try {
      const res = await DeveloperService.generateApiKey();
      return res.data;
    } catch (e) {
      return rejectWithValue({ error: e.response.data });
    }
  }),
};

export const developerSlice = createSlice({
  name: "developer",
  initialState: {
    apiKey: {
      getApiKey: { data: {}, requestStatus: setStatusDefaults() },
      postApiKey: { data: {}, requestStatus: setStatusDefaults() },
    },
  },
  reducers: {
    maskApiKey: (state) => {
      state.apiKey.postApiKey.requestStatus = setStatusDefaults();
      const keyMask = state.apiKey.postApiKey.data.keyMask;
      state.apiKey.getApiKey.data = { keyMask };
    },
  },
  extraReducers: (builder) => {
    builder
      /*******************************************************************************
       * Get Api Key
       *******************************************************************************/
      .addCase(developerThunks.getApiKey.pending, (state) => {
        state.apiKey.getApiKey.requestStatus = getRequestStatus();
      })
      .addCase(developerThunks.getApiKey.fulfilled, (state, action) => {
        const { payload = {} } = action;
        state.apiKey.getApiKey.data = payload;
        state.apiKey.getApiKey.requestStatus = getResponseStatus();
      })
      .addCase(developerThunks.getApiKey.rejected, (state, action) => {
        state.apiKey.getApiKey.requestStatus = getErrorStatus();
      })
      /*******************************************************************************
       * Generate Api Key
       *******************************************************************************/
      .addCase(developerThunks.generateApiKey.pending, (state) => {
        state.apiKey.postApiKey.requestStatus = getRequestStatus();
      })
      .addCase(developerThunks.generateApiKey.fulfilled, (state, action) => {
        const { payload = {} } = action;
        const { id = "", apiKey = "", keyMask = "" } = payload;
        state.apiKey.postApiKey.data = { id, key: apiKey, keyMask };
        state.apiKey.postApiKey.requestStatus = getResponseStatus();
      })
      .addCase(developerThunks.generateApiKey.rejected, (state) => {
        state.apiKey.postApiKey.requestStatus = getErrorStatus();
      });
  },
});

export const getApiKeySelector = (state) => state.webshops.developer.apiKey.getApiKey;
export const postApiKeySelector = (state) => state.webshops.developer.apiKey.postApiKey;

export const { maskApiKey } = developerSlice.actions;
export default developerSlice.reducer;
