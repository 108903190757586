import {
  STATUS_PRIORITIES,
  NO_PERMISSIONS,
  NEEDS_RECONNECTION,
  INVALID_API_TOKEN,
  INVALID_APP_ID,
  CONNECTOR_ERROR,
  PULLING_DATA,
  VERIFYING_CONNECTION,
  CONNECTED,
  DRAFT_CONNECTION,
  NOT_CONNECTED,
} from "@utils/status";

export const integrationStates = {
  connectionBroken: () => {
    return {
      integrationsButtonLabel: "We're identifying an issue...",
      state: "error",
    };
  },
  reconnect: () => {
    return {
      integrationsButtonLabel: "Reconnect",
      state: "reconnect",
      hasAction: true,
      hover: true,
    };
  },
  connect: () => {
    return {
      integrationsButtonLabel: "Connect",
      state: "connect",
      hasAction: true,
      hover: true,
    };
  },
  completeConnection: () => {
    return {
      integrationsButtonLabel: "Complete Connecting",
      state: "completeConnecting",
      hasAction: true,
      hover: true,
    };
  },
  connected: () => {
    return {
      integrationsButtonLabel: "Connected",
      state: "success",
    };
  },
  verifyingConnection: () => {
    return {
      integrationsButtonLabel: "Connecting...",
      state: "verifyingConnection",
    };
  },
};

export const getIntegrationState = (connectionStatus) => {
  switch (connectionStatus) {
    case NO_PERMISSIONS:
    case NEEDS_RECONNECTION:
    case INVALID_API_TOKEN:
    case INVALID_APP_ID:
      return integrationStates.reconnect();
    case CONNECTOR_ERROR:
      return integrationStates.connectionBroken();
    case CONNECTED:
    case PULLING_DATA:
      return integrationStates.connected();
    case DRAFT_CONNECTION:
      return integrationStates.completeConnection();
    case VERIFYING_CONNECTION:
      return integrationStates.verifyingConnection();
    default:
      return integrationStates.connect();
  }
};

export const getHighestPriorityStatus = (connections) => {
  if (!connections) return NOT_CONNECTED;
  // Go through connections and return highest priority status
  // out of all of them
  let generalStatus = null;

  if (Array.isArray(connections)) {
    connections.forEach((connectionData) => {
      const { status = "" } = connectionData;
      if (!STATUS_PRIORITIES[status]) return;
      if (!generalStatus || STATUS_PRIORITIES[status] > STATUS_PRIORITIES[generalStatus]) {
        generalStatus = status;
      }
    });
  } else {
    Object.keys(connections).forEach((connectionName) => {
      const connectionData = connections[connectionName];
      const { status = "" } = connectionData;
      if (!STATUS_PRIORITIES[status]) return;
      if (!generalStatus || STATUS_PRIORITIES[status] > STATUS_PRIORITIES[generalStatus]) {
        generalStatus = status;
      }
    });
  }

  return generalStatus || NOT_CONNECTED;
};

export const filterIntegrations = (integrations, integrationType) => {
  let filteredList = [];
  Object.keys(integrations).forEach((integrationID) => {
    if (integrationID === "base") return;
    const integration = integrations[integrationID];
    const { type = "", category = "" } = integration;
    if (type === "base") return;
    if (integrationType && type === integrationType) {
      filteredList.push(integration);
    } else if (integrationType && category === integrationType) {
      filteredList.push(integration);
    }
  });
  return filteredList;
};

export const INTEGRATIONS = {
  appsflyer: {
    id: "appsflyer",
    name: "AppsFlyer",
    platformId: "appsflyer",
  },
  adjust: {
    id: "adjust",
    name: "Adjust",
    platformId: "adjust",
  },
  appstore: {
    id: "appstore",
    name: "App store",
    platformId: "appstore",
  },
  googleplay: {
    id: "googleplay",
    name: "Google play",
    platformId: "googleplay",
  },
  steam: {
    id: "steam",
    name: "Steam",
    platformId: "steam",
  },
  firebase: {
    id: "firebase",
    name: "Firebase",
    platformId: "firebase",
  },
  singular: {
    id: "singular",
    name: "Singular",
    platformId: "singular",
  },
};
