import React from "react";

import FadeTransitionContainer from "@common/animated/FadeTransitionContainer";
import DismissableBanner from "@common/banners/DismissableBanner";
import text from "@utils/text";
import ConnectCallout from "./ConnectCallout";
import OnboardingStep3Container from "@pages/onboarding/step-3/OnboardingStep3Container";

import { onboardingTextMap } from "@utils/onboarding";
import symbols from "@utils/symbols";
import { useSelector } from "react-redux";
import { Box, Flex, HStack } from "@chakra-ui/react";
import { useFlags } from "launchdarkly-react-client-sdk";

const OnboardingPrimaryFlow = ({ userName, shouldShowPendingRequest, connectionInProgress }) => {
  const { clientSuperpowersOnboardingFlowEnabled = false } = useFlags();

  let onboardingText;
  connectionInProgress || (!connectionInProgress && clientSuperpowersOnboardingFlowEnabled)
    ? (onboardingText = "inProgress")
    : (onboardingText = "completed");

  const copies = onboardingTextMap.PRIMARY[onboardingText];
  const requestedCapitalActive = useSelector(
    (state) => state.integrations.advances.data.summary.requestedCapitalActive,
  );
  const showRequestPending = shouldShowPendingRequest || requestedCapitalActive;
  const { title = "", description = "" } = copies;

  return (
    <HStack mt={"24px"} gap={"32px"}>
      <Flex alignSelf={"start"} flex={1}>
        <FadeTransitionContainer
          absolutePosition={false}
          value={showRequestPending}
          firstComponent={
            <DismissableBanner
              title={`${symbols.pizza} Congrats, ${userName}!`}
              content={text.t("onboarding_primary_connection_success_desc")}
              dismissable={false}
            />
          }
          secondComponent={<ConnectCallout title={title} description={description} />}
        />
      </Flex>
      <Box flex={1} className="od-content-right-col">
        <OnboardingStep3Container />
      </Box>
    </HStack>
  );
};

export default OnboardingPrimaryFlow;
