import { useState } from "react";
import { usePlaidLink } from "react-plaid-link";

import PlaidService from "@services/integrations/bank/plaidApi";
import { sentryError } from "@utils/sentryError";
import { useDispatch } from "react-redux";
import actions from "@redux/actions";

export const useRequestPlaidLink = () => {
  const dispatch = useDispatch();
  let connectionId;
  let reAuthorize;

  const onSuccess = async (public_token) => {
    const setConnection = reAuthorize ? PlaidService.reAuthorize : PlaidService.setPersistConnection;
    await setConnection({ publicToken: public_token, connectionId });

    dispatch(actions.banking.status.request());
    dispatch(actions.integrations.plaid.status.request());
    getPlaidConfig();
  };

  const onExit = (error) => {
    getPlaidConfig();
    if (error) {
      // notify sentry when plaid link fails
      const {
        error_message: errorMessage,
        error_code: errorCode,
        error_type: errorType,
        display_message: displayMessage,
      } = error;
      sentryError(new Error(`Plaid Link Error - ${errorMessage}`), { displayMessage, errorCode, errorType });
    }
  };

  const getPlaidConfig = (token = "") => ({
    onSuccess,
    onExit,
    token,
  });

  const [plaidConfig, setPlaidConfig] = useState(getPlaidConfig());
  const { open, ready } = usePlaidLink(plaidConfig);

  const getPlaidLink = (id) => {
    if (id) {
      reAuthorize = true;
      connectionId = id;
      PlaidService.getUpdatedLinkToken(connectionId).then(({ data }) => {
        setPlaidConfig(getPlaidConfig(data.linkToken));
      });
    } else {
      reAuthorize = false;
      PlaidService.getLinkToken().then(({ data }) => {
        connectionId = data.connectionId;
        setPlaidConfig(getPlaidConfig(data.linkToken));
      });
    }
  };

  return [open, ready, plaidConfig, getPlaidLink];
};
