import { useEffect, useState } from "react";
import { chakra, HStack, Icon, IconButton, shouldForwardProp } from "@chakra-ui/react";
import { ReactComponent as LaptopIcon } from "@assets/img/icons/common/Laptop.svg";
import { ReactComponent as SmartphoneIcon } from "@assets/img/icons/common/Smartphone.svg";
import { isValidMotionProp, motion } from "framer-motion";

const MotionBox = chakra(motion.div, {
  shouldForwardProp: (prop) => isValidMotionProp(prop) || shouldForwardProp(prop),
});

const MotionIcon = motion(Icon);

const containerVariants = {
  selected: {
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.4,
    },
  },
  unselected: {
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.4,
    },
  },
};

const boxVariants = {
  selected: { left: "0%" },
  unselected: { left: "50%" },
};

const iconVariants = {
  selected: {
    color: "#fff",
    transition: { delay: 0.2 },
  },
  unselected: {
    color: "#BBBEC7",
    transition: { delay: 0.2 },
  },
};

export function ResolutionToggle({ onResolutionChange = () => {} }) {
  const [isDesktop, setIsDesktop] = useState(true);

  useEffect(() => {
    onResolutionChange(isDesktop);
  }, [isDesktop, onResolutionChange]);

  return (
    <MotionBox
      h="36px"
      p="2px"
      bg="#303033"
      borderRadius="8px"
      overflow="hidden"
      position="relative"
      variants={containerVariants}
      initial="desktop"
      animate={isDesktop ? "selected" : "unselected"}
    >
      <HStack h="100%" position="relative" zIndex={1}>
        <MotionBox
          position="absolute"
          top={0}
          bottom={0}
          width="50%"
          background="#000000"
          borderRadius="8px"
          border="1px solid"
          borderColor="brandPurple.400"
          variants={boxVariants}
          transition={{ type: "spring", stiffness: 300, damping: 30 }}
        />
        <IconButton
          variant="unstyled"
          h="100%"
          flex={1}
          icon={
            <MotionIcon
              as={LaptopIcon}
              variants={iconVariants}
              transition={{ duration: 0.3 }}
              animate={isDesktop ? "selected" : "unselected"}
            />
          }
          onClick={() => setIsDesktop(true)}
          aria-label="Desktop view"
          aria-pressed={isDesktop}
        />
        <IconButton
          variant="unstyled"
          h="100%"
          flex={1}
          icon={
            <MotionIcon
              as={SmartphoneIcon}
              variants={iconVariants}
              transition={{ duration: 0.3 }}
              animate={isDesktop ? "unselected" : "selected"}
            />
          }
          onClick={() => setIsDesktop(false)}
          aria-label="Mobile view"
          aria-pressed={!isDesktop}
        />
      </HStack>
    </MotionBox>
  );
}
