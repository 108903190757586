import styled from "styled-components";

export const StyledDescriptionLabel = styled.div`
  & {
    display: inline-flex;

    .show-loader {
      display: inline-block;
      margin-left: -8px;
    }

    .platform-state-description {
      color: ${({ theme, state }) => theme[state + "IntegrationCardDescriptionFontColor"]};
      display: flex;
      align-items: center;
      cursor: default;

      svg {
        margin-right: 8px;
        fill: ${({ theme, state }) => theme[state + "IntegrationCardDescriptionFontColor"]};
      }

      .platform-state-refresh {
        color: ${({ theme }) => theme.primaryColor};
        margin-left: 8px;
        cursor: pointer;
      }
    }
  }
`;

const StyledIntegrationStateCard = styled.div`
  height: var(--card-height);
  width: var(--card-width);
  border-radius: 12px;
  padding: 16px;
  background: ${(props) => props.theme.grayscale20};
  margin-bottom: 2rem;

  &:not(.grid-card) {
    display: flex;
    flex-direction: column;
  }

  &.grid-card {
    //grid-area: card;

    .card-content {
      .platform-name {
        max-width: 16rem;
      }
    }
  }

  .card-content {
    display: flex;
    padding: 0;
    margin-bottom: 16px;
  }

  .card-image {
    border-radius: 8.5px;
    width: 56px;
    height: 56px;

    &.image-contain {
      object-fit: contain;
    }
  }

  .card-details {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: 16px;

    .platform-headings {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;

      .platform-header {
        display: flex;
        justify-content: space-between;
      }

      .platform-name {
        margin-bottom: 4px;
        color: ${({ theme }) => theme.integrationCardTitleFontColor};
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .platform-state-container {
        display: flex;
        align-items: center;
      }

      .platform-state {
        color: ${(props) => props.theme.textDefault};
      }

      .platform-state-refresh {
        color: ${(props) => props.theme.primaryColor};
        margin-left: 8px;

        :hover {
          cursor: pointer;
          color: ${(props) => props.theme.primaryHover};
        }
      }
    }
  }

  .context-menu-button {
    z-index: 10;
    position: relative;
    max-height: 24px;
    max-width: 24px;
    padding: 2px 4px;
    border-radius: 4px;
    cursor: pointer;

    svg {
      margin-top: -8px;
      fill: ${({ theme }) => theme.primaryFontColor};
    }

    :hover {
      background-color: ${({ theme }) => theme.grayscale40};
    }

    .context-menu-dropdown {
      width: fit-content;
      background-color: ${({ theme }) => theme.grayscale40};
      padding: 8px;
      box-shadow: 0 0 48px ${({ theme }) => theme.unlinkShadow};
      visibility: hidden;
      border-radius: 10px;

      &.menu-open {
        visibility: visible;
      }
    }

    .context-menu-item {
      white-space: nowrap;
      padding: 8px;
      border-radius: 8px;

      &:not(:last-child) {
        margin-bottom: 8px;
      }

      .unlink-button {
        color: ${({ theme }) => theme.primaryColor};
      }

      :hover {
        color: ${({ theme }) => theme.grayscale0};
        background-color: ${({ theme }) => theme.primaryColor};

        .unlink-button {
          color: ${({ theme }) => theme.grayscale0};
        }
      }
    }
  }

  .connect-btn {
    width: 100%;
  }
`;

export default StyledIntegrationStateCard;
