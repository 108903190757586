import bankingServices from "./services";
import bankingActions, { BANKING_STATUS } from "./actions";

import { sentryError } from "../../utils/sentryError";

const bankingMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    switch (action.type) {
      case BANKING_STATUS.REQUEST: {
        bankingServices
          .status()
          .then((response) => {
            dispatch(bankingActions.status.response(response));
          })
          .catch((error) => {
            dispatch(bankingActions.status.error(error));
            sentryError(error, error?.response?.data);
          });
        break;
      }

      default:
        return;
    }
  };

export default bankingMiddleware;
