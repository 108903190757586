import HTTP from "@redux/http";

const SANLO_WEB_API_URL = process.env.REACT_APP_SANLO_WEB_API_URL;
const BASE = `${SANLO_WEB_API_URL}/api/integrations`;

export default {
  integrationsConnectionsStatus: () => {
    return HTTP.req.get(`${BASE}/connections/status`);
  },

  sendSuggestion: (data) => {
    return HTTP.req.post(`${BASE}/suggestions`, data);
  },
};
