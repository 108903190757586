import { Box, Button, Divider, HStack, Heading, Icon, Text, VStack } from "@chakra-ui/react";
import { InstructionItem, InstructionsList } from "@common/guided-instructions/GuidedInstructions";
import { GuideMedia } from "@components/integrations/connection-guide";
import appleInstruction5 from "../apple-instructions-media/apple-instruction-5.png";
import { Controller, useFormContext } from "react-hook-form";
import { FileDrop } from "@common/upload/FileDrop";
import { ReactComponent as UploadImageIcon } from "src/assets/img/icons/common/image_upload.svg";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listAppStoreAppsSelector, webshopsThunks } from "@redux/webshops/webshopsSlice";
import { showToast } from "@src/utils/redux";

export function AppStoreApiKey({ handleNextStep }) {
  const { control, watch, getValues } = useFormContext();
  const { requestStatus } = useSelector(listAppStoreAppsSelector);
  const dispatch = useDispatch();
  const [fileName, setFileName] = useState();

  const p8KeyFile = watch("p8KeyFile");

  async function handleLastStep() {
    const { keyId, issuerId, selectedPlatform, p8KeyFile } = getValues();
    const payload = {
      platform: selectedPlatform,
      keyId,
      issuerId,
      key: encodeURIComponent(p8KeyFile),
    };

    try {
      await dispatch(webshopsThunks.listAppStoreApps(payload)).unwrap();
      handleNextStep();
    } catch (e) {
      showToast("There was an error retrieving your apps from App Store. Check your credentials.", "error");
    }
  }

  const handleFile = (event) => {
    return new Promise((resolve, reject) => {
      const files = event.dataTransfer?.files || event.target.files;
      const p8file = files[0];

      // If there is no file or the file is not a .p8 file, reject the Promise
      if (!p8file?.name?.endsWith(".p8")) {
        resolve(null);
        return;
      }
      setFileName(p8file.name);
      // Read the contents of the file
      const reader = new FileReader();

      reader.onload = (event) => {
        resolve(event.target.result); // Resolve the Promise with the file content
      };

      reader.onerror = (error) => {
        resolve(null); // Reject the Promise in case of an error
      };

      reader.readAsText(p8file);
    });
  };

  return (
    <VStack mt="40px" gap="104px" alignItems={"stretch"}>
      <Heading size="large" fontWeight={600} mx={"auto"}>
        Connect via App Store
      </Heading>
      <HStack gap="24px" alignItems={"start"} justifyContent={"center"}>
        <Box maxW="420px" w={"full"}>
          <InstructionsList>
            <InstructionItem order={3}>
              <Text size={"regular"} fontWeight={500} color={"textWhite.400"}>
                Upload your API Key
              </Text>
              <Text size="small">3.1 Download your API Key.</Text>
              <Text size="small">3.2 Upload your key below</Text>
            </InstructionItem>
          </InstructionsList>
          <Divider />
          <Controller
            name="p8KeyFile"
            control={control}
            render={({ field: { onChange } }) => (
              <VStack
                alignItems={"center"}
                justifyContent={"center"}
                border="2px dashed"
                borderColor={"dark.100"}
                rounded={"8px"}
                bg="dark.400"
                py="24px"
                px="20px"
                w={"full"}
                pos={"relative"}
                h="180px"
              >
                <FileDrop onChange={async (event) => onChange(await handleFile(event))} accept=".p8">
                  <VStack gap="16px" my="auto">
                    <Icon w="62px" h="62px" as={UploadImageIcon} />
                    <Text>{fileName || "Click to upload or drag and drop here"}</Text>
                  </VStack>
                </FileDrop>
              </VStack>
            )}
          />
          <Button
            isDisabled={!p8KeyFile}
            isLoading={requestStatus.pending}
            onClick={handleLastStep}
            variant={"primary"}
            mt="24px"
            w="full"
          >
            Continue
          </Button>
        </Box>
        <Box maxW={"512px"} p="12px" rounded={"12px"} bg="dark.400" h="fit-content">
          <GuideMedia mediaSlider={[appleInstruction5]} />
        </Box>
      </HStack>
    </VStack>
  );
}
