import HTTP from "@redux/http";
import moment from "moment";
import { CASH_ACTIVITY_TIME_OPTIONS } from "@src/consts/financial-intelligence";
const SANLO_WEB_API_URL = process.env.REACT_APP_SANLO_WEB_API_URL;
const BASE = `${SANLO_WEB_API_URL}/api/financials`;

class FinancialsService {
  getClosingBalance() {
    return HTTP.req.get(`${BASE}/closing-balance`);
  }

  getBankingMetrics() {
    const url = new URL(`${BASE}/banking/metrics`);
    const params = new URLSearchParams();
    const fromDate = moment().subtract(30, "days").format("YYYY-MM-DD");
    const toDate = moment().format("YYYY-MM-DD");

    params.set("fromDate", fromDate);
    params.set("toDate", toDate);

    url.search = params;
    return HTTP.req.get(url.toString());
  }
  getUpcomingReport({ toDate } = {}) {
    const url = new URL(`${BASE}/cashflow/upcoming-report`);
    const params = new URLSearchParams();
    if (toDate) {
      params.set("toDate", toDate);
    }

    url.search = params;
    return HTTP.req.get(url.toString());
  }

  getMonthlyReport({ from, to } = {}) {
    let url = `${BASE}/cashflow/monthly-report`;
    if (from) url += `?from=${from}`;
    if (from && to) url += `&to=${to}`;
    return HTTP.req.get(url);
  }

  getCashflowBreakdown(query = `month=${moment().format("YYYY-MM")}`) {
    let URL = `${BASE}/cashflow/breakdown`;
    if (query) URL += `?${query}`;
    return HTTP.req.get(URL);
  }

  getCashActivity({
    fromDate = moment().subtract(10, "months").format("YYYY-MM"),
    toDate = moment().add(2, "months").format("YYYY-MM"),
    grouping = CASH_ACTIVITY_TIME_OPTIONS.MONTHLY.grouping,
  }) {
    const url = new URL(`${BASE}/cash-activity`);
    const params = new URLSearchParams();
    params.set("from", fromDate);
    params.set("to", toDate);
    params.set("grouping", grouping.toUpperCase());
    url.search = params;
    return HTTP.req.get(url.toString());
  }
}

export default new FinancialsService();
