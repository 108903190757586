import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import { Box, Text, HStack, Heading } from "@chakra-ui/react";
import { ThemeContext } from "styled-components";

import { RingLoader } from "@components/common/loaders";
import { Divider, BodyRegularPlus } from "@styled";
import {
  StyledPageContainer,
  StyleCashFlowBreakdownContainer,
  StyledCashFlowBreakdownCashIn,
  StyledCashFlowBreakdownCashOut,
  StyledCashFlowSourcesContainer,
  StyledCashFlowHeader,
} from "./CashFlowBreakdown.styled";
import { SimpleAccordionTooltip } from "@common/tooltips/simple-accordion-tooltip/SimpleAccordionTooltip";
import { DisplayAmount } from "./subcomponents/DisplayAmount";
import { getCashflowAccordionContent } from "./CashflowTooltips";
import { CashFlowSection } from "./subcomponents/CashFlowSection";
import { MonthlyDropdown } from "./subcomponents/MonthlyDropdown";
import { SanloScroll } from "@components/SanloScroll";
import { useTrackHover, FINANCIALS_ACTION, FINANCIALS_PATHNAME } from "@utils/gtm";
import { BlurryCard } from "@common/blurry-card";
import { getCashFlowBreakdownBlurryMessage } from "@src/consts/blur";
import { getBankingBlurData } from "@utils/blur";

import { ReactComponent as AlertCircle } from "@assets/img/icons/common/alertCircle.svg";

export const CashFlowBreakdown = () => {
  const theme = useContext(ThemeContext);
  const [addTracking, removeTracking] = useTrackHover();

  const integrations = useSelector((state) => state.integrations);

  const { isBlurred, isPullingData, isBroken } = getBankingBlurData(integrations.plaid.data.institutions);

  const cashFlowBreakdown = useSelector((state) => state.financials.getCashflowBreakdown);
  const { data, initialLoadComplete = false, requestStatus = {} } = cashFlowBreakdown;

  let { cashIn = {}, cashOut = {} } = data;

  const [cashInSelectedViews, setCashInSelectedViews] = useState({});
  const [cashOutSelectedViews, setCashOutSelectedViews] = useState({});

  const [cashInHighestAmount, setCashInHighestAmount] = useState(0);
  const [cashOutHighestAmount, setCashOutHighestAmount] = useState(0);

  useEffect(() => {
    const { sections: cashInSections = [] } = cashIn;
    const cashInViews = {};
    // Set the initial view for the Cash In section
    cashInSections.forEach((section) => {
      const { label = "", views = [] } = section;
      cashInViews[label] = views[0];
    });
    setCashInSelectedViews(cashInViews);
  }, [cashIn]);

  useEffect(() => {
    const { sections: cashOutSections = [] } = cashOut;
    const cashOutViews = {};
    // Set the initial view for the Cash Out section
    cashOutSections.forEach((section) => {
      const { label = "", views = [] } = section;
      cashOutViews[label] = views[0];
    });
    setCashOutSelectedViews(cashOutViews);
  }, [cashOut]);

  useEffect(() => {
    let highest = 0;
    Object.entries(cashInSelectedViews).forEach(([viewKey, view]) => {
      const { groupings = [] } = view;
      groupings.forEach((grouping) => {
        let { amountCents = 0 } = grouping;
        // This is only used for the bar width, so we want all values
        // to be positive so theyre easy to deal with
        amountCents = Math.abs(amountCents);
        if (amountCents > highest) highest = amountCents;
      });
    });
    setCashInHighestAmount(highest);
  }, [cashInSelectedViews]);

  useEffect(() => {
    let highest = 0;
    Object.entries(cashOutSelectedViews).forEach(([viewKey, view]) => {
      const { groupings = [] } = view;
      groupings.forEach((grouping) => {
        let { amountCents = 0 } = grouping;
        // This is only used for the bar width, so we want all values
        // to be positive so theyre easy to deal with
        amountCents = Math.abs(amountCents);
        if (amountCents > highest) highest = amountCents;
      });
    });
    setCashOutHighestAmount(highest);
  }, [cashOutSelectedViews]);

  const handleMouseEnter = (name) => {
    addTracking({
      event: FINANCIALS_ACTION.HOVER_TOOLTIP,
      name,
      pathname: FINANCIALS_PATHNAME,
    });
  };

  const handleMouseLeave = () => {
    removeTracking();
  };

  return (
    <CashFlowBreakdownPresentation
      initialLoadComplete={initialLoadComplete}
      isLoading={!initialLoadComplete || requestStatus.pending}
      isBlurred={isBlurred}
      isPullingData={isPullingData}
      isBroken={isBroken}
      cashIn={cashIn}
      cashOut={cashOut}
      cashInColor={theme.colorGreen}
      cashOutColor={theme.colorPurple}
      cashInHighestAmount={cashInHighestAmount}
      cashOutHighestAmount={cashOutHighestAmount}
      cashInSelectedViews={cashInSelectedViews}
      cashOutSelectedViews={cashOutSelectedViews}
      setCashInSelectedViews={setCashInSelectedViews}
      setCashOutSelectedViews={setCashOutSelectedViews}
      handleMouseEnter={handleMouseEnter}
      handleMouseLeave={handleMouseLeave}
    />
  );
};

export const CashFlowBreakdownPresentation = ({
  initialLoadComplete = false,
  isLoading = false,
  isBlurred = false,
  isPullingData = false,
  isBroken = false,
  cashIn = {},
  cashOut = {},
  cashInColor = "",
  cashOutColor = "",
  cashInHighestAmount = 0,
  cashOutHighestAmount = 0,
  cashInSelectedViews = {},
  cashOutSelectedViews = {},
  setCashInSelectedViews = () => {},
  setCashOutSelectedViews = () => {},
  handleMouseEnter = () => {},
  handleMouseLeave = () => {},
}) => {
  const Spinner = () => {
    if (!isLoading) return null;
    return (
      <Box position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
        <RingLoader />
        <Text>Loading data...</Text>
      </Box>
    );
  };

  return (
    <>
      <HStack justifyContent={"space-between"} mb="24px">
        <Heading size="small" fontWeight={600}>
          Historical cash flow details
        </Heading>
        {!isBlurred && <MonthlyDropdown />}
      </HStack>
      <BlurryCard
        isBlurred={isBlurred}
        imgSrc={require("@assets/img/blur/breakdownBlur.png")}
        message={getCashFlowBreakdownBlurryMessage({ isPullingData, isBroken })}
      >
        <StyledPageContainer
          isLoading={!initialLoadComplete}
          className={classnames({
            "loading-shine": !initialLoadComplete,
          })}
        >
          <StyleCashFlowBreakdownContainer data-test-id="cash-flow-breakdown-card">
            <StyledCashFlowBreakdownCashOut>
              <StyledCashFlowHeader>
                <BodyRegularPlus>
                  Cash out{" "}
                  <AlertCircle
                    id={`id-target-cashflow-breakdown-cashout`}
                    fill={"#C5C5C5"}
                    style={{
                      margin: "0 0 4px 4px",
                      transform: "rotate(180deg)",
                    }}
                    onMouseEnter={() => handleMouseEnter("Cash out")}
                    onMouseLeave={handleMouseLeave}
                  />
                </BodyRegularPlus>
                <SimpleAccordionTooltip
                  idTarget={`id-target-cashflow-breakdown-cashout`}
                  content="This is the total amount of money that moved out of your connected bank account(s)."
                  accordionContent={getCashflowAccordionContent("Cash out")}
                  trackData={{ name: "Cash out" }}
                  onMouseEnter={() => handleMouseEnter("Cash out")}
                  onMouseLeave={handleMouseLeave}
                />
                {!isLoading && <DisplayAmount data={cashOut} cashFlow="cashOut" />}
              </StyledCashFlowHeader>

              <Divider style={{ marginBottom: "12px" }} />

              <StyledCashFlowSourcesContainer>
                <Spinner />
                {!isLoading && (
                  <SanloScroll withShadow>
                    {(cashOut?.sections || []).map((section) => {
                      const { label = "" } = section;
                      return (
                        <CashFlowSection
                          key={`key-accordion-cashflow-breakdown-${label}`}
                          section={section}
                          selectedView={cashOutSelectedViews[label]}
                          highestAmount={cashOutHighestAmount}
                          onViewSelect={(view) => {
                            setCashOutSelectedViews({
                              ...cashOutSelectedViews,
                              [label]: view,
                            });
                          }}
                          barStyles={{
                            backgroundColor: cashOutColor,
                            float: "right",
                          }}
                        />
                      );
                    })}
                  </SanloScroll>
                )}
              </StyledCashFlowSourcesContainer>
            </StyledCashFlowBreakdownCashOut>

            <StyledCashFlowBreakdownCashIn>
              <StyledCashFlowHeader>
                <BodyRegularPlus>
                  Cash in{" "}
                  <AlertCircle
                    id={`id-target-cashflow-breakdown-cashin`}
                    fill={"#C5C5C5"}
                    style={{
                      margin: "0 0 4px 4px",
                      transform: "rotate(180deg)",
                    }}
                    onMouseEnter={() => handleMouseEnter("Cash in")}
                    onMouseLeave={handleMouseLeave}
                  />
                </BodyRegularPlus>
                <SimpleAccordionTooltip
                  idTarget={`id-target-cashflow-breakdown-cashin`}
                  content="This is the total amount of money that moved into your connected bank account(s)."
                  accordionContent={getCashflowAccordionContent("Cash in")}
                  trackData={{ name: "Cash in" }}
                  onMouseEnter={() => handleMouseEnter("Cash in")}
                  onMouseLeave={handleMouseLeave}
                />
                {!isLoading && <DisplayAmount data={cashIn} cashFlow="cashIn" />}
              </StyledCashFlowHeader>

              <Divider style={{ marginBottom: "12px" }} />

              <StyledCashFlowSourcesContainer>
                <Spinner />
                {!isLoading && (
                  <SanloScroll withShadow>
                    {(cashIn?.sections || []).map((section) => {
                      const { label = "" } = section;
                      return (
                        <CashFlowSection
                          key={`key-accordion-cashflow-breakdown-${section.label}`}
                          section={section}
                          selectedView={cashInSelectedViews[label]}
                          highestAmount={cashInHighestAmount}
                          onViewSelect={(view) => {
                            setCashInSelectedViews({
                              ...cashInSelectedViews,
                              [label]: view,
                            });
                          }}
                          barStyles={{
                            backgroundColor: cashInColor,
                            float: "left",
                          }}
                        />
                      );
                    })}
                  </SanloScroll>
                )}
              </StyledCashFlowSourcesContainer>
            </StyledCashFlowBreakdownCashIn>
          </StyleCashFlowBreakdownContainer>
        </StyledPageContainer>
      </BlurryCard>
    </>
  );
};
