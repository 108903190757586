import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import classnames from "classnames";

import { BodyRegular16, BodyRegularPlus, BodySmall14, TitleRegularPlus, BodySmallPlus } from "@styled";
import Sparklines from "./Sparklines";
import StyledBankBalanceDetails from "./StyledBankBalanceDetails";
import StateButton from "@common/buttons/state-button/StateButton";
import { SanloTooltip } from "@common/tooltips/sanlo-tooltip/SanloTooltip";
import IconTooltip from "@common/tooltips/icon-tooltip/IconTooltip";
import RefreshButton from "@common/buttons/RefreshButton";

import { currencyFormat } from "@utils/currency";
import { formatDateSince } from "@utils/format";
import symbols from "@utils/symbols";
import useShadowScroll from "@utils/custom-hooks/useShadowScroll";
import { useRequestPlaidLink } from "@utils/integrations/banking/useRequestPlaidLink";
import { NEEDS_RECONNECTION, PULLING_DATA } from "@utils/status";
import actions from "@redux/actions";

import { ReactComponent as WarningIcon } from "@assets/img/icons/common/alertCircle.svg";
import { ReactComponent as BackArrow } from "@assets/img/icons/common/arrow_left.svg";
import { moveReconnectAccountsToEndOfList } from "./utils";
import text from "@utils/text";
import { useDispatch } from "react-redux";
import isEmpty from "lodash/isEmpty";

const bankAccountsState = {
  NEEDS_RECONNECTION: {
    label: "Needs attention",
    icon: <WarningIcon />,
    buttonLabel: "Reconnect",
    state: "warning",
  },
  PULLING_DATA: {
    label: "This may take up to 24 hours",
    icon: symbols.Stopwatch(),
    buttonLabel: "Connecting...",
    state: "default",
  },
};

const BankBalanceDetails = ({
  isOpen = false,
  bankingData,
  integrations = {},
  closeModal = () => {},
  lastUpdated,
  handleDetails = () => {},
}) => {
  const navigate = useNavigate();
  const formatBankingData = moveReconnectAccountsToEndOfList(bankingData);

  const goToIntegrationsPage = () => {
    navigate("/integrations");
  };

  return (
    <StyledBankBalanceDetails isOpen={isOpen} fade={false} scrollable={true}>
      <div onClick={closeModal} className="bank-details-header" data-test-id="bank-balance-modal-close">
        <BackArrow fill="white" className="back-arrow-svg" />
        <BodyRegularPlus className="back-arrow-text">Back</BodyRegularPlus>
      </div>

      <DetailsContainer
        bankingData={formatBankingData}
        lastUpdated={lastUpdated}
        handleDetails={handleDetails}
        integrations={integrations}
      />

      <BodySmall14 className="integrations-page-redirect">
        {text.t("bb_manage_connections", {
          goToIntegrationsPage,
        })}
      </BodySmall14>
    </StyledBankBalanceDetails>
  );
};

export default BankBalanceDetails;

// Extracted from main components so refs are available
const DetailsContainer = ({ bankingData, lastUpdated, integrations, handleDetails }) => {
  const dispatch = useDispatch();
  const [wrapperRef, contentRef, shadowBottomRef, shadowTopRef] = useShadowScroll();
  const [bankDetailsRefreshed, setBankDetailsRefreshed] = useState(undefined);

  const [open, ready, plaidConfig, getPlaidLink] = useRequestPlaidLink();

  useEffect(() => {
    if (!isEmpty(plaidConfig.token) && ready) {
      open();
    }
  }, [plaidConfig, ready, open]);

  useEffect(() => {
    if (integrations.plaid.requestStatus.getData.completed) {
      setBankDetailsRefreshed(undefined);
    }
  }, [integrations.plaid.requestStatus.getData.completed]);

  const refreshingBankDetails = useMemo(
    () => integrations.plaid.requestStatus.getData.pending,
    [integrations.plaid.requestStatus.getData.pending],
  );

  const handleBankDetailsRefresh = (index) => {
    setBankDetailsRefreshed(index);
    dispatch(actions.integrations.plaid.closingBalance.request());
  };

  const renderAccountStatus = (account, tooltipText) => {
    const { includedInTotalBalance, accountId, status } = account;

    return bankAccountsState[status] ? (
      <span id={`tt-institution-status-${accountId}`}>
        <BodyRegular16> • </BodyRegular16>
        <BodyRegular16
          style={{ lineHeight: "20px" }}
          className={`bank-account-status ${bankAccountsState[status].state}`}
        >
          <span className="bank-account-status-icon">{bankAccountsState[status].icon}</span>
          <span className="bank-account-status-label">{bankAccountsState[status].label}</span>
        </BodyRegular16>
        {tooltipText && (
          <SanloTooltip
            id="tt-platform-needs-reconnection-tooltip"
            maxWidth={244}
            placement="top"
            target={`tt-institution-status-${accountId}`}
            text={tooltipText}
          />
        )}
      </span>
    ) : (
      !includedInTotalBalance && (
        <BodyRegular16 className="negative-account-balance"> {text.t("bb_not_included")}</BodyRegular16>
      )
    );
  };
  const renderRefreshButton = (index) =>
    refreshingBankDetails && index !== bankDetailsRefreshed ? null : (
      <RefreshButton
        style={{
          marginLeft: "8px",
        }}
        loading={refreshingBankDetails && index === bankDetailsRefreshed}
        onClick={() => handleBankDetailsRefresh(index)}
      />
    );

  return (
    <div ref={wrapperRef} className="shadow-wrapper">
      <div ref={shadowTopRef} className="shadow shadow--top" />
      <div ref={shadowBottomRef} className="shadow shadow--bottom" />
      <div ref={contentRef} className="shadow-container">
        {bankingData.map((currencyGroup, index) => {
          const { currency, historicalBalances, currentOverallBalanceCents, accounts } = currencyGroup;

          return (
            <div className="bank-details-container" key={`bank-details-${currency}-${index}`}>
              <div className="currency-details-container">
                <div className="currency-details-content">
                  <div className="current-balance-container">
                    <div className="cash-balance" data-test-id="bank-balance-modal-amount">
                      <TitleRegularPlus>{currencyFormat(currentOverallBalanceCents / 100, currency)} </TitleRegularPlus>
                      <BodyRegularPlus className="currency-abbreviation">{currency}</BodyRegularPlus>
                      <IconTooltip
                        className="credit-disclosure-tooltip"
                        id={`tt-test-icon-tooltip-${currency}`}
                        content={text.t("bb_total_info", {
                          currency,
                        })}
                        icon={"info"}
                        maxWidth={244}
                      />
                    </div>

                    <Sparklines
                      id={`tt-bank-balance-sparklines-${currency}`}
                      values={historicalBalances}
                      width={36}
                      height={24}
                    />
                    <SanloTooltip
                      maxWidth={260}
                      placement="top"
                      text={text.t("bb_daily_balance")}
                      target={`tt-bank-balance-sparklines-${currency}`}
                    />
                  </div>
                  <div className="currency-details-status">
                    <BodySmall14>
                      Last updated {formatDateSince(lastUpdated)}
                      {renderRefreshButton(index)}
                    </BodySmall14>
                  </div>
                </div>
              </div>
              {accounts.map((account, index) => {
                const {
                  accountId,
                  institution,
                  accountCurrency,
                  currentBalanceCents,
                  status,
                  accountType,
                  accountNumberMask = "",
                  accountName = "",
                  connectionId = "",
                } = account;

                let tooltipText = null;
                if (status === NEEDS_RECONNECTION) {
                  if (accountType === "UNKNOWN" && currentBalanceCents) {
                    tooltipText = (
                      <BodySmall14>
                        {`Something went wrong with some connection, please go to `}
                        <BodySmallPlus
                          className="red-label tooltip-active-text"
                          onClick={() => {
                            handleDetails();
                            const tooltipNode = document.getElementById("tt-platform-needs-reconnection-tooltip");
                            if (tooltipNode) tooltipNode.style.display = "none";
                          }}
                        >
                          View details
                        </BodySmallPlus>
                        {` to learn more.`}
                      </BodySmall14>
                    );
                  } else {
                    tooltipText = (
                      <BodySmall14>
                        Something went wrong with {institution?.name} connection, please reconnect your banking.
                      </BodySmall14>
                    );
                  }
                }

                return (
                  <div key={`bank-account-details-${accountId}-${index}`} className="bank-account-details">
                    <div className="bank-account-data">
                      <img
                        src={require("@assets/img/icons/common/bankAccount.svg").default}
                        className="bank-logo"
                        alt={`bank-logo`}
                      />
                      <div className="bank-account-content">
                        <div className="bank-name">
                          <BodyRegularPlus>{institution?.name}</BodyRegularPlus>
                          {renderAccountStatus(account, tooltipText)}
                        </div>
                        <BodySmall14 className="account-name">
                          {accountName} {accountNumberMask}
                        </BodySmall14>
                      </div>
                    </div>
                    <div className="amount-state-container">
                      {status === PULLING_DATA ? (
                        <StateButton label="Connecting..." state="success" icon={false} />
                      ) : (
                        <>
                          <BodyRegularPlus
                            className={classnames("bank-account-cash-balance", {
                              "needs-reconnection": status === NEEDS_RECONNECTION,
                            })}
                          >
                            {currencyFormat(currentBalanceCents / 100, accountCurrency)} ({accountCurrency})
                          </BodyRegularPlus>
                          {bankAccountsState[status] && (
                            <div className="state-button">
                              <StateButton
                                hover
                                icon={false}
                                label={bankAccountsState[status].buttonLabel}
                                state={bankAccountsState[status].state}
                                onClick={() => {
                                  if (status === NEEDS_RECONNECTION) {
                                    getPlaidLink(connectionId);
                                  }
                                }}
                              />
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};
