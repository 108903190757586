import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SuggestionService from "./suggestionsApi";

import { getErrorStatus, getRequestStatus, getResponseStatus, setStatusDefaults } from "@utils/redux";

export const suggestionsThunks = {
  sendSuggestion: createAsyncThunk("sugestion/sendSuggestion", async (payload, { rejectWithValue }) => {
    try {
      const res = await SuggestionService.sendSuggestion(payload);
      return res.data;
    } catch (e) {
      return rejectWithValue({ error: e.response.data });
    }
  }),
};

export const sugestionsSlice = createSlice({
  name: "suggestions",
  initialState: {
    sendSuggestion: {
      requestStatus: setStatusDefaults(),
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(suggestionsThunks.sendSuggestion.pending, (state) => {
      state.sendSuggestion.requestStatus = getRequestStatus();
    });
    builder.addCase(suggestionsThunks.sendSuggestion.fulfilled, (state) => {
      state.sendSuggestion.requestStatus = getResponseStatus();
    });
    builder.addCase(suggestionsThunks.sendSuggestion.rejected, (state) => {
      state.sendSuggestion.requestStatus = getErrorStatus();
    });
  },
});

export const sendSuggestionSelector = (state) => state.integrations.suggestions.sendSuggestion;

export default sugestionsSlice.reducer;
