import SanloWebApiService from "./services";

import actions from "@redux/actions";

import googlePlayActions, {
  GOOGLE_PLAY_RETRIEVE_SERVICE_ACCOUNT,
  GOOGLE_PLAY_SAVE_CREDENTIALS,
  GOOGLE_PLAY_UNLINK_INTEGRATION,
} from "./actions";

import { showToast } from "@utils/redux";

const googlePlayMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    switch (action.type) {
      case GOOGLE_PLAY_RETRIEVE_SERVICE_ACCOUNT.REQUEST:
        SanloWebApiService.googlePlayRetrieveServiceAccount()
          .then((response) => response.data)
          .then((serviceAccount) => dispatch(googlePlayActions.retrieveServiceAccount.response(serviceAccount)))
          .catch((error) => dispatch(googlePlayActions.retrieveServiceAccount.error(error)));
        break;

      case GOOGLE_PLAY_SAVE_CREDENTIALS.REQUEST:
        SanloWebApiService.googlePlaySaveCredentials(action.cloudStorageURI)
          .then((response) => response.data)
          .then((response) => {
            dispatch(googlePlayActions.saveCredentials.response(response));
            dispatch(actions.integrations.base.getIntegrationsConnections.request());
            if (action.callback) action.callback(1);
            showToast("You have successfully connected to your google play account!");
          })
          .catch((error) => dispatch(googlePlayActions.saveCredentials.error(error)));
        break;

      case GOOGLE_PLAY_UNLINK_INTEGRATION.REQUEST:
        SanloWebApiService.googlePlayDeleteCredentials()
          .then((response) => {
            dispatch(googlePlayActions.unlinkIntegration.response(response));
            dispatch(actions.integrations.base.getIntegrationsConnections.request());
            if (action.callback) action.callback();
          })
          .catch((error) => {
            if (action.error) action.error();
            dispatch(googlePlayActions.unlinkIntegration.error(error));
          });
        break;

      default:
        return;
    }
  };

export default googlePlayMiddleware;
