import { copy } from "@utils/general";
import { snakeToCamelCase } from "@utils/string";

import { BUILDER_SECTION_TYPES } from "@src/consts/webshops/builder";
export const SECTION_TYPE = BUILDER_SECTION_TYPES.GLOBAL;

export const generateThemePayloads = ({ storefront = {}, updates = {} }) => {
  const { theme = {} } = storefront;
  const requiredDefaults = {
    primary: theme.primary || {
      type: "COLOR",
      color: "#000000",
    },
    secondary: theme.secondary || {
      type: "COLOR",
      color: "#ffffff",
    },
    background: theme.background || {
      type: "COLOR",
      color: "#ffffff",
    },
  };

  let request = {};
  const targetKey = snakeToCamelCase(updates.targetColor);
  const newColorObj = {
    [targetKey]: {
      type: "COLOR",
      color: updates.value,
    },
  };

  Object.keys(requiredDefaults).forEach((key) => {
    if (Object.hasOwn(newColorObj, key)) {
      request[key] = copy(newColorObj[key]);
    } else {
      request[key] = copy(requiredDefaults[key]);
    }
    delete request[key].opacityHundredths;
    delete request[key].name;
    if (!request[key].type) request[key].type = "COLOR";
  });

  return copy({
    request,
    response: {
      ...storefront,
      theme: {
        ...theme,
        ...newColorObj,
      },
    },
  });
};
