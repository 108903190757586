import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  HStack,
  Input,
  Text,
  chakra,
  shouldForwardProp,
} from "@chakra-ui/react";
import { StyledLoader } from "@common/inputs/SanloButton/SanloButton";
import { sendSuggestionSelector, suggestionsThunks } from "@redux/integrations/suggestions/suggestionsSlice";
import { AD_NETWORKS } from "@src/consts/ad-networks";
import { isValidMotionProp, motion } from "framer-motion";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) => isValidMotionProp(prop) || shouldForwardProp(prop),
});

const variants = {
  visible: { opacity: 1, height: "auto" },
  hidden: { opacity: 0, height: 0 },
};

const congratsVariants = {
  in: {
    y: 0,
    opacity: 1,
    height: "auto",
    scale: 1,
    transitionEnd: {
      display: "block",
    },
  },
  out: {
    scale: 0.7,
    opacity: 0,
    height: 0,
    transitionEnd: {
      display: "none",
    },
  },
};

export function AdNetworkSuggestion() {
  const dispatch = useDispatch();
  const { requestStatus } = useSelector(sendSuggestionSelector);
  const [showSuggestionBox, setShowSuggestionBox] = useState(false);
  const [suggestionSubmitted, setSuggestionSubmitted] = useState(false);
  const { register, handleSubmit, watch } = useForm({
    defaultValues: {
      suggestion: "",
    },
  });
  const watchSuggestion = watch("suggestion");

  async function onSubmit(values) {
    if (values.suggestion) {
      const result = await dispatch(
        suggestionsThunks.sendSuggestion({
          suggestion: values.suggestion,
          platformType: AD_NETWORKS,
        }),
      );
      if (result.error) {
        return;
      }
      setSuggestionSubmitted(true);
    }
  }

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)}>
      <Text size="smallAlt">
        We are in the process of expanding our integration options. Is there a network you would like to integrate?{" "}
        <Text as="span" color={"brandRed.400"} cursor={"pointer"} onClick={() => setShowSuggestionBox(true)}>
          Let us know.
        </Text>
      </Text>
      <ChakraBox initial="hidden" animate={showSuggestionBox ? "visible" : "hidden"} variants={variants}>
        <ChakraBox initial={false} animate={suggestionSubmitted ? "out" : "in"} variants={congratsVariants}>
          <HStack mt={"16px"} gap={"10px"}>
            <Input placeholder="Enter name of your platform..." {...register("suggestion")} />
            <Button
              type="submit"
              spinner={<StyledLoader />}
              variant="primary"
              isDisabled={watchSuggestion.length === 0}
              isLoading={requestStatus.pending}
            >
              Submit
            </Button>
          </HStack>
        </ChakraBox>
        <ChakraBox mt={"16px"} initial="out" animate={suggestionSubmitted ? "in" : "out"} variants={congratsVariants}>
          <Alert status="success">
            <AlertIcon />
            <AlertTitle>
              We received your message. We’ll inform you once this platform is available in Sanlo app.
            </AlertTitle>
          </Alert>
        </ChakraBox>
      </ChakraBox>
    </Box>
  );
}
