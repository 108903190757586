import React from "react";
import { useSelector } from "react-redux";

import AuthenticationContainer from "./AuthenticationContainer";
import { CompleteProfile } from "./flows/CompleteProfile";

const SignUpFlow = () => {
  const user = useSelector((state) => state.session.user);
  const company = useSelector((state) => state.session.company);
  const isLoading = useSelector((state) => state.session.requestStatus.finishRegistration.pending);
  const partnerBranding = useSelector((state) => state.session.partnerBranding);

  return (
    <AuthenticationContainer hasLogout partnerBranding={partnerBranding}>
      <CompleteProfile company={company} user={user} isLoading={isLoading} />
    </AuthenticationContainer>
  );
};

export default SignUpFlow;
