export const sortHistoricalTransactions = (advances) => {
  const sortedAdvances = Object.assign({}, advances);
  Object.keys(sortedAdvances).forEach((type) => {
    if (type !== "platforms") {
      sortedAdvances[type].forEach((advance) => {
        advance.historicalTransactions.sort((a, b) => (a.payDate > b.payDate ? -1 : a.payDate < b.payDate ? 1 : 0));
      });
    }
  });
  return sortedAdvances;
};
