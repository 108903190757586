import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import classnames from "classnames";
import moment from "moment";

import actions from "@redux/actions";

import { StyledChart, StyledChartBody, StyledPlatformDisplay } from "./DailyRevenueDisplayStyles";
import { DailyRevenueHeader } from "./DailyRevenueHeader";

import PeriodPicker from "./PeriodPicker";
import YAxis from "./YAxis";
import XAxis from "./XAxis";
import XAxisPullingDataState from "./XAxis_PullingData";

import ChartBars from "../BodyStates/Body_ChartBars";
import NoGamesSelectedBodyState from "../BodyStates/Body_NoGamesSelected";
import NoPlatformSelectedBodyState from "../BodyStates/Body_NoPlatformSelected";
import PullingDataBodyState from "../BodyStates/Body_PullingData";
import NoPermissionBodyState from "../BodyStates/Body_NoPermission";
import ConnectionErrorBodyState from "../BodyStates/Body_ConnectionError";
import NeedsReconnectionBodyState from "../BodyStates/Body_NeedsReconnection";
import NoLiveAppsBodyState from "../BodyStates/Body_NoLiveApps";

import {
  areGamesSelected,
  getInitialPeriod,
  getInitialVisualRange,
  getViewedPeriodData,
  getYAxisData,
  isNoDataState,
} from "../../utils/dailyRevenueUtils";
import { parseEarningsData } from "../../utils/dailyRevenueUtilsParser";
import { getRangeOfDatesMoment } from "@utils/format";
import { copy } from "@utils/general";
import {
  isErrorStatus,
  isNoLiveAppsStatus,
  isNoPermissionStatus,
  isPullingStatus,
  isReconnectionStatus,
} from "@utils/status";

const DailyRevenueDisplay = ({
  selectedOption,
  isLoading = true,
  isNothingConnected = false,
  collapsed = false,
  dailyRevenueData = {},
  selectedOptionGameData,
  fullOptions,
  dispatch,
  theme,
  onOptionConnectClick,
}) => {
  if (!dailyRevenueData || typeof dailyRevenueData !== "object") {
    dailyRevenueData = {
      earnings: [],
    };
  }
  const { periodEarnings: earnings = [] } = dailyRevenueData;

  const DAYS_VISIBLE = collapsed ? 35 : 26;
  const CHART_BAR_WIDTH = 16;

  // Keep the mouse X position we are tracking out of the state to persist
  // the value and prevent pointless rerenders based on its change
  const mouseX = useRef(null);
  const allPeriodsLoaded = useRef(false);
  // HTML ELEMENT REFS
  const chartBodyRef = useRef(null);
  const chartWrapperRef = useRef(null);
  const fetchedPreviousRef = useRef({});
  const selectedOptionRef = useRef({});
  const dailyRevenueDataRef = useRef({});

  // const previousSelectedOption = useRef({});

  const resetScroll = useRef(false);

  // STATE VARIBLES
  const [chartData, setChartData] = useState(parseEarningsData(earnings));
  const [visualRange, setVisualRange] = useState(getInitialVisualRange(getInitialPeriod(earnings), DAYS_VISIBLE));
  const [visibleDays, setVisibleDays] = useState(null);
  const [barWidth, setBarWidth] = useState(CHART_BAR_WIDTH);
  const [yAxisInfo, setYAxisInfo] = useState({
    highest: 0,
    steps: [],
  });

  const loading = useRef(false);

  const [keepScroll, setKeepScroll] = useState(null);
  const [viewedPeriod, setViewedPeriod] = useState(null);
  const [viewedPeriodEarnings, setViewedPeriodEarnings] = useState({
    earnings: 0, // earnings from the visisble games
    total: 0, // the complete total regardless of whats not shown
  });

  const [size, setSize] = useState({
    width: 0,
    height: 0,
  });
  const [periodPicker, setPeriodPicker] = useState({
    prev: false,
    next: false,
  });
  const [hoveredDay, setHoveredDay] = useState(null);
  const refreshing = useRef(false);

  useLayoutEffect(() => {
    // useLayoutEffect is the same as useEffect basically, but runs
    // before the browser renders instead of after

    const updateSize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    updateSize();

    window.addEventListener("resize", updateSize);
    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, []);

  useLayoutEffect(() => {
    const node = chartBodyRef.current;

    let newBarWidth = node.clientWidth / DAYS_VISIBLE;
    if (newBarWidth < CHART_BAR_WIDTH) newBarWidth = CHART_BAR_WIDTH;
    setBarWidth(newBarWidth);

    node.style.setProperty("--bar-width", newBarWidth + "px");
    node.style.setProperty("--border-width", (newBarWidth - CHART_BAR_WIDTH) / 2 + "px");
    node.style.setProperty("--loading-color", theme.colorRed);

    // Get the index of the visualRange item
    for (let i = 0; i < chartData.order.length; ++i) {
      const data = chartData.map[chartData.order[i]];
      if (data.date === visualRange.start) {
        setKeepScroll(i * newBarWidth);
        break;
      }
    }
    // eslint-disable-next-line
  }, [size]);

  useLayoutEffect(() => {
    if (keepScroll === null) return;
    const node = chartBodyRef.current;
    node.scrollLeft = keepScroll;
    setKeepScroll(null);
  }, [keepScroll]);

  useEffect(() => {
    onScroll(null, true);
    // eslint-disable-next-line
  }, [chartData]);

  // useEffect(() => {
  //   if (!selectedOption || !selectedOption?.id) return;
  //   const earnings = selectedOption.earnings || {};
  //   if (selectedOption.id && Object.keys(earnings).length) {
  //     // A valid option is coming in, set it if its different and then scroll
  //     const prevID = previousSelectedOption.current.id;
  //     if (!prevID || (prevID && prevID !== selectedOption.id)) {
  //       previousSelectedOption.current = selectedOption;
  //     }
  //   }
  // }, [selectedOption]);

  useEffect(() => {
    const { periodEarnings = [] } = dailyRevenueData;
    const node = chartBodyRef.current;

    const prevSelectedOption = selectedOptionRef.current || {};
    const prevPeriodEarnings = (dailyRevenueDataRef.current || {}).periodEarnings || [];

    let periodEarningsID = periodEarnings[periodEarnings.length - 3]?.periodId;
    let prevPeriodEarningsID = prevPeriodEarnings[prevPeriodEarnings.length - 3]?.periodId;

    let newChartData = null;
    if (periodEarningsID !== prevPeriodEarningsID) {
      newChartData = parseEarningsData(periodEarnings);
      setChartData(newChartData);
    }

    if (resetScroll.current) {
      const newScroll = resetPlatformScroll(periodEarnings, newChartData);
      if (newScroll) {
        setKeepScroll(newScroll);
        resetScroll.current = false;
      }
    }

    // We only want to keep the scroll when the selectedOption stays the same
    // but the number of periods has changed, indicating a previous period was loaded
    if (
      selectedOption.id === prevSelectedOption.id &&
      periodEarnings.length &&
      periodEarnings.length !== prevPeriodEarnings.length
    ) {
      if (periodEarningsID === prevPeriodEarningsID) {
        // We want to get the scroll value here instead of the action callback
        // because the action callback happens too late after this chartData update
        // and a blip can sometimes be seen when the chart rerenders
        newChartData = parseEarningsData(periodEarnings);
        setChartData(newChartData);

        if (refreshing.current) {
          refreshing.current = false;
          const newScroll = resetPlatformScroll(periodEarnings, newChartData);
          if (newScroll) {
            setKeepScroll(newScroll);
          }
        } else {
          const newPeriod = periodEarnings[0];
          const { fiscalStartDate, fiscalToDate } = newPeriod;
          const periodRange = getRangeOfDatesMoment(fiscalStartDate, fiscalToDate);
          const newScroll = periodRange.length * barWidth + node.scrollLeft;
          setKeepScroll(newScroll);
        }
      }
    }

    if (selectedOption.id !== prevSelectedOption.id) {
      if (periodEarningsID !== prevPeriodEarningsID) {
        const newScroll = resetPlatformScroll(periodEarnings, newChartData);
        if (newScroll) setKeepScroll(newScroll);
      }
      allPeriodsLoaded.current = false;
    }

    dailyRevenueDataRef.current = copy(dailyRevenueData);
    selectedOptionRef.current = copy(selectedOption);
    // eslint-disable-next-line
  }, [dailyRevenueData]);

  const resetPlatformScroll = (periodEarnings = [], newChartData) => {
    if (!newChartData) return null;
    const visualRange = getInitialVisualRange(getInitialPeriod(periodEarnings), DAYS_VISIBLE);
    setVisualRange(visualRange);
    const today = moment().startOf("day").format("YYYY-MM-DD");
    for (let i = 0; i < newChartData.order.length; ++i) {
      const data = newChartData.map[newChartData.order[i]];
      if (data.date === today) return (i - 15) * barWidth;
    }
    return null;
  };

  useLayoutEffect(() => {
    if (!visibleDays || !visibleDays.length) return;
    const viewedPeriodData = getViewedPeriodData(visibleDays, selectedOptionGameData, chartData);
    setViewedPeriod(viewedPeriodData.period);
    setViewedPeriodEarnings({
      earnings: viewedPeriodData.visibleGameEarnings,
      total: viewedPeriodData.period.total,
    });
    setYAxisInfo(getYAxisData(viewedPeriodData));
    // eslint-disable-next-line
  }, [selectedOptionGameData]);

  // Checks the charts limits to handle the period picker's state
  useEffect(() => {
    if (viewedPeriod) {
      let allDates = chartData.order;

      if (viewedPeriod.fiscalToDate === allDates[allDates.length - 1]) setPeriodPicker({ prev: false, next: true });
      else if (allPeriodsLoaded.current && viewedPeriod.fiscalStartDate === allDates[0])
        setPeriodPicker({ prev: true, next: false });
      else setPeriodPicker({ prev: false, next: false });
    }
    // eslint-disable-next-line
  }, [viewedPeriod, allPeriodsLoaded.current]);

  const onChartScroll = (e) => {
    onScroll(e);
  };

  const onScroll = (e, forceUpdate = false) => {
    if (!barWidth) return;

    const node = chartBodyRef.current;
    const scroll = node.scrollLeft;

    const newVisibleDays = [];

    // The index of the first day visible on screen is determined by getting
    // the current scroll value of the container and dividing it by the width
    // of the width of the bars in the chart
    const firstVisualIndex = Math.round(scroll / barWidth);
    let endVisualIndex = firstVisualIndex;

    // This code just assume 35 days are always shown
    // No more pixel width rounding errors adding an extra day accidentally
    for (let i = 0; i < DAYS_VISIBLE; ++i) {
      const day = chartData.order[endVisualIndex];
      const data = chartData.map[day];
      if (!data) break;
      newVisibleDays.push(data);
      endVisualIndex += 1;
    }

    const newVisualRange = {
      index: firstVisualIndex,
      start: newVisibleDays[0]?.date,
      end: newVisibleDays[newVisibleDays.length - 1]?.date,
    };

    // If the visual range isn't different we can kill the update here
    const noRangeChange = visualRange.start === newVisualRange.start && visibleDays && visibleDays.length;
    if (!forceUpdate && noRangeChange) return;

    setVisualRange(newVisualRange);
    setVisibleDays(newVisibleDays);

    const viewdPeriodData = getViewedPeriodData(newVisibleDays, selectedOptionGameData, chartData);

    if (!viewdPeriodData.period) return;

    setViewedPeriod(viewdPeriodData.period);
    setViewedPeriodEarnings({
      earnings: viewdPeriodData.visibleGameEarnings,
      total: viewdPeriodData.period.earnings,
    });

    setYAxisInfo(getYAxisData(viewdPeriodData));

    const firstDay = chartData.map[chartData.order[0]];

    if (newVisualRange.start === firstDay.date) {
      fetchPreviousPeriod(firstDay.period.id);
    }
  };

  const fetchPreviousPeriod = (periodIdArray) => {
    if (isLoading || !selectedOption.initialLoadComplete) return;
    if (loading.current || allPeriodsLoaded.current) return;
    if (!selectedOption.requestStatus) return;

    if (selectedOption.isAll) {
      loading.current = true;
      return new Promise((resolve, reject) => {
        dispatch(
          actions.earnings.allGetPreviousEarnings.request(fullOptions.allViableIDsString, () => {
            loading.current = false;
            return resolve();
          }),
        );
      });
    }

    if (!Array.isArray(periodIdArray)) periodIdArray = [periodIdArray];
    const periodId = periodIdArray.shift();
    let actionName = `${selectedOption.id}GetPreviousPeriodEarnings`;
    if (selectedOption.isAdNetwork || selectedOption.isAdPlatform) {
      actionName = "adRevenueGetPreviousEarnings";
    }
    if (fetchedPreviousRef.current[periodId]) return;
    loading.current = true;
    return new Promise((resolve, reject) => {
      dispatch(
        actions.earnings[actionName].request(selectedOption.id, periodId, (newPeriod) => {
          fetchedPreviousRef.current[periodId] = true;
          loading.current = false;
          if (periodIdArray.length > 0) {
            return resolve(fetchPreviousPeriod(periodIdArray));
          } else {
            allPeriodsLoaded.current = newPeriod === "no_more_periods";
            return resolve();
          }
        }),
      );
    });
  };

  const handlePeriodChange = (mod = 0) => {
    // Keep track of the scroll left positions for each period
    const periodStartValues = {};
    const periodOrder = [];
    let onNext = false;

    const node = chartBodyRef.current;
    // Set the scroll to the first day of the paydate period
    for (let i = 0; i < chartData.order.length; ++i) {
      const data = chartData.map[chartData.order[i]];
      if (isNaN(periodStartValues[data.period.id])) {
        periodStartValues[data.period.id] = i * barWidth;
        periodOrder.push(data.period.id);

        if (onNext) {
          node.scrollTo({
            left: periodStartValues[data.period.id],
            behavior: "smooth",
          });
          break;
        }
      }

      if (mod === 0 && data.period.isCurrent) {
        node.scrollTo({
          left: i * barWidth,
          behavior: "smooth",
        });
        break;
      }

      if (mod === -1 && data.period.id === viewedPeriod.id) {
        // The current period is the last item in the array, we want two back
        // node.scrollLeft = periodStartValues[periodOrder[periodOrder.length - 2]];
        node.scrollTo({
          left: periodStartValues[periodOrder[periodOrder.length - 2]],
          behavior: "smooth",
        });
        break;
      }

      if (mod === 1 && data.period.id === viewedPeriod.id) {
        // When onNext is true and we hit a new period, we scroll to that point
        onNext = true;
      }
    }
  };

  const handleChartGrab = (trigger, e) => {
    // We only need to worry about non-down triggers when mouse down is true
    if ((!mouseX.current && trigger !== "down") || !areGamesSelected(selectedOptionGameData)) return;
    if (isPullingStatus(selectedOption)) return;

    switch (trigger) {
      case "down": {
        mouseX.current = e.pageX;
        break;
      }
      case "up":
      case "leave": {
        mouseX.current = null;
        break;
      }
      case "move": {
        const node = chartBodyRef.current;
        const mouseXDiff = mouseX.current - e.pageX;
        const currLeftScroll = node.scrollLeft;
        const newScroll = currLeftScroll + mouseXDiff;
        if (node.scrollLeft !== newScroll) {
          node.scrollLeft = currLeftScroll + mouseXDiff;
          mouseX.current = e.pageX;
        }
        break;
      }
      default: {
        break;
      }
    }
  };

  const onPaidOnClick = (payDatePeriod) => {
    const dayData = chartData.map[payDatePeriod.payDate];
    if (!dayData) return;

    const { period } = dayData;
    const { fiscalStartDate } = period;

    for (let i = 0; i < chartData.order.length; ++i) {
      const day = chartData.order[i];
      if (fiscalStartDate === day) {
        // We've found the start of the period that contains
        // the paydate we want to view.
        const node = chartBodyRef.current;
        node.scrollLeft = barWidth * i;
        break;
      }
    }
  };

  const renderChartXAxis = () => {
    // Render the sort-of-fake stubbed out x-axis
    // if the selectedOption is in a pulling data state
    if (isNoDataState(selectedOption, isNothingConnected)) {
      return <XAxisPullingDataState daysVisible={DAYS_VISIBLE} />;
    }

    // Render the standard x-axis
    return (
      <XAxis
        selectedOption={selectedOption}
        chartData={chartData}
        isLoading={isLoading}
        viewedPeriod={viewedPeriod}
        selectedOptionGameData={selectedOptionGameData}
        visualRange={visualRange}
        chartBodyRef={chartBodyRef}
        barWidth={barWidth}
        earningsData={earnings}
        setHoveredDay={setHoveredDay}
        fetchPreviousPeriod={(periodsToLoad) => {
          fetchPreviousPeriod(periodsToLoad).then(() => {
            // The scroll we want is just 0, as the period we want to view
            // will be the beginning of the chart. This will trigger loading the
            // period before this as well.
            const node = chartBodyRef.current;
            node.scrollLeft = 0;
          });
        }}
      />
    );
  };

  const renderChartBody = () => {
    if (!selectedOption.initialLoadComplete) {
      return null;
    }

    if (selectedOption.isNone) {
      return <NoPlatformSelectedBodyState selectedOption={selectedOption} />;
    }

    if (isPullingStatus(selectedOption)) {
      return <PullingDataBodyState selectedOption={selectedOption} />;
    }

    if (isNoLiveAppsStatus(selectedOption)) {
      return <NoLiveAppsBodyState selectedOption={selectedOption} />;
    }

    if (isNoPermissionStatus(selectedOption)) {
      return <NoPermissionBodyState selectedOption={selectedOption} onOptionConnectClick={onOptionConnectClick} />;
    }

    if (isErrorStatus(selectedOption)) {
      return <ConnectionErrorBodyState selectedOption={selectedOption} />;
    }

    if (isReconnectionStatus(selectedOption)) {
      return <NeedsReconnectionBodyState selectedOption={selectedOption} onOptionConnectClick={onOptionConnectClick} />;
    }

    if (!areGamesSelected(selectedOptionGameData)) {
      return <NoGamesSelectedBodyState collapsed={collapsed} />;
    }

    // Render the normal chart bars
    return (
      <ChartBars
        selectedOption={selectedOption}
        selectedOptionGameData={selectedOptionGameData}
        chartData={chartData}
        isLoading={isLoading}
        yAxisInfo={yAxisInfo}
        dailyRevenueData={dailyRevenueData}
        theme={theme}
        isNothingConnected={isNothingConnected}
        hoveredDay={hoveredDay}
        setHoveredDay={setHoveredDay}
        viewedPeriod={viewedPeriod}
      />
    );
  };

  return (
    <StyledPlatformDisplay>
      <DailyRevenueHeader
        selectedOption={selectedOption}
        isLoading={isLoading}
        isNothingConnected={isNothingConnected}
        viewedPeriodEarnings={viewedPeriodEarnings}
        viewedPeriod={viewedPeriod}
        fillColor={theme.tooltipFill}
        onPaidOnClick={onPaidOnClick}
        chartData={chartData}
        onRefresh={() => {
          if (selectedOption && selectedOption?.id) {
            // TODO: only clear on a guaranteed request success
            fetchedPreviousRef.current = {};
            refreshing.current = true;
            if (selectedOption?.isAll) {
              dispatch(actions.earnings.allGetEarnings.request(fullOptions.allViableIDsString));
            } else if (selectedOption?.isPlatform && !selectedOption.isAdPlatform) {
              dispatch(actions.earnings[`${selectedOption?.id}GetEarnings`].request());
            } else if (selectedOption?.isAdNetwork) {
              dispatch(actions.earnings.adRevenueGetEarnings.request(selectedOption?.id));
            }
          }
        }}
      />

      <PeriodPicker
        selectedOption={selectedOption}
        selectedOptionGameData={selectedOptionGameData}
        isLoading={isLoading}
        periodPicker={periodPicker}
        handlePeriodChange={handlePeriodChange}
      />

      <StyledChart ref={chartWrapperRef}>
        {(!isNoDataState(selectedOption, isNothingConnected) || selectedOption.isNone) && (
          <YAxis yAxisInfo={yAxisInfo} isLoading={isLoading} />
        )}

        <StyledChartBody
          ref={chartBodyRef}
          onScroll={onChartScroll}
          onMouseDown={(e) => {
            handleChartGrab("down", e);
          }}
          onMouseUp={(e) => {
            handleChartGrab("up", e);
          }}
          onMouseLeave={(e) => {
            handleChartGrab("leave", e);
          }}
          onMouseMove={(e) => {
            handleChartGrab("move", e);
          }}
          className={classnames("no-text-select", {
            "no-games-selected": !areGamesSelected(selectedOptionGameData),
            "is-showing-message": isNoDataState(selectedOption, isNothingConnected),
          })}
        >
          {renderChartXAxis()}
          {renderChartBody()}
        </StyledChartBody>
      </StyledChart>
    </StyledPlatformDisplay>
  );
};

export default DailyRevenueDisplay;
