import React from "react";
import { Box, Image, VStack } from "@chakra-ui/react";

export const BlurryCard = ({ children, imgSrc, message, isBlurred = false, ...rest }) => {
  return (
    <Box position={"relative"} {...rest}>
      {isBlurred && (
        <Box zIndex={1}>
          <VStack
            position={"absolute"}
            inset={0}
            backdropFilter={"blur(6px)"}
            background={"rgba(33, 33, 38, 0.78)"}
            borderRadius={"12px"}
            justifyContent={"center"}
            zIndex={3}
          >
            {message}
          </VStack>
          {imgSrc && <Image position={"absolute"} src={imgSrc} zIndex={2} />}
        </Box>
      )}
      {children}
    </Box>
  );
};
