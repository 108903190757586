import { connect } from "react-redux";
import Main from "./Main";
import actions from "./redux/actions";

const mapStateToProps = (state) => ({
  user: state.session.user,
});

const mapDispatchToProps = (dispatch) => ({
  userSummaryRequest: () => dispatch(actions.session.userSummary.request()),
  getIntegrationsStatus: () => dispatch(actions.integrations.base.getIntegrationsConnections.request()),
  getAdvancesRequest: () => dispatch(actions.integrations.advances.getAdvances.request()),
  featureFlagsRequest: () => dispatch(actions.session.featureFlags.request()),
  getAdvancesSummary: () => dispatch(actions.integrations.advances.getSummary.request()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
