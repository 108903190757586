import { GridItem } from "@chakra-ui/react";
import { Builder } from "./components/Builder";
import { BuilderProperties } from "./components/Properties";
import { useDispatch, useSelector } from "react-redux";
import { checkoutBuilderThunks, checkoutSelector } from "@redux/webshops/checkout-builder/checkoutSlice";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";

export function CheckoutBuilder() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { template } = useSelector(checkoutSelector);

  const logo = template?.files?.[0]?.url ?? "";

  const methods = useForm({
    values: {
      primary: template?.colorPalette?.primary?.color || "#000000",
      secondary: template?.colorPalette?.secondary?.color || "#000000",
      paymentSectionBgColor: template?.paymentSectionBgColor || "#000000",
      summarySectionBgColor: template?.summarySectionBgColor || "#000000",
    },
  });

  useEffect(() => {
    dispatch(checkoutBuilderThunks.getCheckoutTemplate({ webshopId: id }));
  }, [dispatch, id]);

  return (
    <FormProvider {...methods}>
      <GridItem bg="#777777" alignContent={"center"}>
        <Builder logo={logo} />
      </GridItem>
      <GridItem bg="#18181A" borderLeft={"1px solid #404247"}>
        <FormProvider {...methods}>
          <BuilderProperties template={template} />
        </FormProvider>
      </GridItem>
    </FormProvider>
  );
}
