const showCopyToast = (text) => {
  let toastBg = document.getElementById("toast-bg-fade");
  let toast = document.getElementById("bottom-toast");
  if (text?.length) {
    toast.textContent = text;
  }
  toastBg.className = "show";
  toast.className = "show";
  setTimeout(() => {
    toastBg.className = toastBg.className.replace("show", "");
  }, 3000);
  setTimeout(() => {
    toast.className = toast.className.replace("show", "");
  }, 3000);
};

const copyToClipboard = (text) => {
  if (!navigator?.clipboard) {
    showCopyToast("Copying not supported");
    return false;
  } else {
    navigator.clipboard.writeText(text);
    showCopyToast();
    return true;
  }
};

export default copyToClipboard;
