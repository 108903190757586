import React from "react";
import { TitleSmallPlus } from "@styled";

export const TooltipHeader = ({ logo, title }) => (
  <div className="tooltip-header">
    <img src={logo} alt="logo" className="tooltip-header-img" />
    <TitleSmallPlus className="tooltip-header-title">{title}</TitleSmallPlus>
  </div>
);

export default TooltipHeader;
