import { configureConsts, configureActions } from "@utils/redux";

export const GOOGLE_PLAY_RETRIEVE_SERVICE_ACCOUNT = configureConsts("GOOGLE_PLAY_RETRIEVE_SERVICE_ACCOUNT");
export const GOOGLE_PLAY_SAVE_CREDENTIALS = configureConsts("GOOGLE_PLAY_SAVE_CREDENTIALS");
export const GOOGLE_PLAY_UNLINK_INTEGRATION = configureConsts("GOOGLE_PLAY_UNLINK_INTEGRATION");

const googlePlayActions = {
  retrieveServiceAccount: configureActions(GOOGLE_PLAY_RETRIEVE_SERVICE_ACCOUNT),

  saveCredentials: configureActions(GOOGLE_PLAY_SAVE_CREDENTIALS, {
    request: (cloudStorageURI, callback) => ({
      type: GOOGLE_PLAY_SAVE_CREDENTIALS.REQUEST,
      cloudStorageURI,
      callback,
    }),
  }),

  unlinkIntegration: configureActions(GOOGLE_PLAY_UNLINK_INTEGRATION, {
    request: (callback, error) => ({
      type: GOOGLE_PLAY_UNLINK_INTEGRATION.REQUEST,
      callback,
      error,
    }),
  }),
};

export default googlePlayActions;
