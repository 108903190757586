import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { ReactComponent as Rocket } from "@assets/img/loading/rocket.svg";
import { TitleSmallPlus } from "../../styled";

export const LoadingScreen = ({ loaded, notifyParent, style, text }) => {
  const rocketRef = useRef(null);
  const blurredBackGroundRef = useRef(null);
  const orbit1Ref = useRef(null);
  const orbit2Ref = useRef(null);
  const orbit3Ref = useRef(null);
  const textRef = useRef(null);

  const finishAnimation = () => {
    rocketRef.current.style.animation = "launch 3.7s ease-in-out both";
    blurredBackGroundRef.current.style.animation = "fadeFromFaded 4s linear both";
    textRef.current.style.animation = "textAnimation 3s ease-in-out both";
    [orbit1Ref, orbit2Ref, orbit3Ref].forEach((orbit) => {
      orbit.current.style.animation = "whirlpool 3.5s ease-in-out both";
    });
    setTimeout(() => {
      notifyParent();
    }, 2000);
  };

  useEffect(() => {
    if (loaded) finishAnimation();
    // eslint-disable-next-line
  }, [loaded]);

  return (
    <StyledLoadingScreen style={style ?? {}}>
      <div className="solar-system-wrapper">
        <div className="solar-system-container">
          <div ref={blurredBackGroundRef} className="blurred-background" />

          <div ref={orbit3Ref} className="orbit-wrapper">
            <div className="orbit-container">
              <svg width="350" height="350">
                <defs>
                  <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="60%">
                    <stop offset="0%" stopColor="#FC5D80" />
                    <stop offset="100%" stopColor="#B664E1" />
                  </linearGradient>
                </defs>
                <circle cx="175" cy="175" r="170" stroke="url(#linear)" strokeWidth="1.5" fill="transparent" />
              </svg>
              <div className="planet p3" />
            </div>
          </div>

          <div ref={orbit2Ref} className="orbit-wrapper">
            <div className="orbit-container reverse">
              <svg width="256" height="256">
                <defs>
                  <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="60%">
                    <stop offset="0%" stopColor="#FC5D80" />
                    <stop offset="100%" stopColor="#B664E1" />
                  </linearGradient>
                </defs>
                <circle cx="128" cy="128" r="120" stroke="url(#linear)" strokeWidth="1.5" fill="transparent" />
              </svg>
              <div className="planet p2" />
            </div>
          </div>

          <div ref={orbit1Ref} className="orbit-wrapper">
            <div className="orbit-container">
              <svg width={128} height={128}>
                <defs>
                  <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="60%">
                    <stop offset="0%" stopColor="#FC5D80" />
                    <stop offset="100%" stopColor="#B664E1" />
                  </linearGradient>
                </defs>
                <circle cx={64} cy={64} r={60} stroke="url(#linear)" strokeWidth="1.5" fill="transparent" />
              </svg>
              <div className="planet p1" />
            </div>
          </div>

          <Rocket ref={rocketRef} className="rocket" />
          <TitleSmallPlus ref={textRef} className="text">
            {text ? text : "Launching your rocket..."}
          </TitleSmallPlus>
        </div>
      </div>
    </StyledLoadingScreen>
  );
};

const StyledLoadingScreen = styled.div`
  & {
    background-color: ${({ theme }) => theme.grayscale40};
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    //align-items: center;

    .solar-system-wrapper {
      position: relative;
      width: 500px;
      height: 80vh;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .solar-system-container {
      width: 500px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      .blurred-background {
        position: absolute;
        width: 50%;
        height: 30%;
        filter: blur(50px);
        transform: translateZ(0);
        background: #fe94ff;
        border-radius: 50%;
        opacity: 0.3;
      }

      .rocket {
        width: 70px;
        height: 70px;
        animation: rocket-vibration 0.1s linear infinite both;
      }

      .orbit-wrapper {
        position: absolute;

        .orbit-container {
          animation: rotation 5s linear infinite both;

          &.reverse {
            animation: reverse-rotation 4s linear infinite both;
          }

          .planet {
            background: radial-gradient(
              73.09% 73.09% at 13.89% 15.37%,
              #fb5d81 0%,
              #fe94ff 28.65%,
              #ff5c79 62.5%,
              #bf6bff 100%
            );
            position: absolute;
            border-radius: 50%;
            animation: rotation 4s linear infinite both;
          }

          .p1 {
            top: 58px;
            width: 8px;
            height: 8px;
          }

          .p2 {
            left: 3px;
            top: 125px;
            width: 10px;
            height: 10px;
          }

          .p3 {
            top: 140px;
            width: 15px;
            height: 15px;
          }

          .orbit {
            position: absolute;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: linear - gradient(89.96deg, #fc5d80 0.03%, #bf60f1 99.95%, #bf60f1 99.95%, #b664e1 99.95%);
            padding: 1px;
          }
        }
      }
    }

    .text {
      animation: glowingText 2s ease infinite;
      font-size: 20px;
      font-weight: 500;
      position: absolute;
      bottom: 50px;
      background: #fe94ff;
      background: linear-gradient(50deg, #fc5d80 10%, #bf60f1 40%, #bf60f1 90%, #b664e1 99.95%);
      background-size: 200% auto;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      display: block;
    }

    svg {
      display: block;
    }

    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }

    @keyframes reverse-rotation {
      from {
        transform: rotate(360deg);
      }
      to {
        transform: rotate(0);
      }
    }

    @keyframes rocket-vibration {
      from {
        transform: translateY(2px);
      }
      to {
        transform: translateY(0);
      }
    }

    @keyframes launch {
      0% {
        transform: translateY(0);
      }
      65% {
        transform: translateY(40px);
        opacity: 1;
      }
      70% {
        transform: translateY(40px);
      }
      100% {
        transform: translateY(-500px);
        opacity: 0;
      }
    }

    @keyframes whirlpool {
      0% {
        transform: scale(1);
      }
      33% {
        transform: scale(0.5) rotate(0);
      }
      66% {
        //opacity: 1;
        transform: scale(1.25) rotate(540deg);
      }
      100% {
        //opacity: 0;
        transform: scale(0);
      }
    }

    @keyframes fadeFromFaded {
      from {
        opacity: 0.3;
      }
      to {
        opacity: 0;
      }
    }

    @keyframes glowingText {
      to {
        background-position: 200% center;
      }
    }

    @keyframes textAnimation {
      to {
        opacity: 0;
      }
    }
  }
`;

export default LoadingScreen;
