import { cashActivityThunks, changeAMonth } from "../cashActivitySlice";
import { createListenerMiddleware } from "@reduxjs/toolkit";

import { CASH_ACTIVITY_TIME_OPTIONS } from "@src/consts/financial-intelligence";
import moment from "moment";

const changeMonthListener = createListenerMiddleware();

changeMonthListener.startListening({
  actionCreator: changeAMonth,
  effect: async (_, listenerApi) => {
    const state = listenerApi.getState().cashActivity.getCashActivity;
    const metadata = state.metadata;
    const grouping = state.selectedTime.grouping;
    const timeframe = state[grouping].data.timeframe;

    if (metadata.fromDate === timeframe.fromDate) {
      const date = {
        fromDate: moment(timeframe.fromDate).subtract(13, "month").format("YYYY-MM"),
        to: moment(timeframe.fromDate).subtract(1, "month").format("YYYY-MM"),
      };
      listenerApi.dispatch(cashActivityThunks.getCashActivity(date));
      listenerApi.dispatch(
        cashActivityThunks.getCashActivity({ ...date, grouping: CASH_ACTIVITY_TIME_OPTIONS.DAILY.grouping }),
      );
    }
  },
});

export const changeMonthStopListening = changeMonthListener.stopListening;
export default changeMonthListener.middleware;
