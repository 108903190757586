import { extendTheme } from "@chakra-ui/react";
import { theme } from "@sanlo/ui-components";
import { Button } from "../button/buttonTheme";
import { Tooltip } from "../tooltip/tooltipTheme";
import { Popover } from "../popover/popoverTheme";
import { Modal } from "../modal/modalTheme";
import { Menu } from "../menu/menuTheme";
import { Checkbox } from "../checkbox/checkboxTheme";
import { Accordion } from "../accordion/accordionTheme";
import { Divider } from "../divider/dividerTheme";
import { Input } from "../input/inputTheme";
import { Alert } from "../alert/alertTheme";
import { Drawer } from "../drawer/drawerTheme";
import { Radio } from "../radio/radioTheme";
import { Table } from "../table/tableTheme";
import { Tag } from "../tag/tagTheme";
import { Select } from "../select/selectTheme";
import { NumberInput } from "../numberInput/numberInputTheme";
import { Switch } from "../switch/switchTheme";
import { Tabs } from "../tabs/tabsTheme";
import { Progress } from "../progress/progressTheme";
import { Textarea } from "../textarea/textareaTheme";
import { Link } from "../link/linkTheme";
import { Slider } from "../slider/sliderTheme";

export const chakraTheme = extendTheme({
  ...theme,
  colors: {
    ...theme.colors,
    gradients: {
      maximumBluePurple: "#A4ADF8",
      eucalyptus: "#2CF9AF",
      ultramarineBlue: "#4A48FF",
      bananaYellow: "#FFD735",
      deepSaffron: "#FF8D36",
      ultraRed: "#FF6B86",
      heliotrope: "#BF6BFF",
    },
    transparents: {
      dark: "rgba(33, 33, 38, 0.78)",
      medium: "rgba(66, 66, 66, 0.6)",
      eBlue: "rgba(130, 128, 255, 0.16)",
      red: {
        100: "rgba(255, 92, 121, 0.10)",
        200: "rgba(255, 66, 100, 0.10)",
      },
      green: "rgba(44, 249, 175, 0.10)",
      yellow: "rgba(255, 215, 53, 0.10)",
      white: "rgba(250, 248, 248, 0.10)",
    },
  },
  fonts: {
    heading: `"Roobert", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    body: `"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
  },
  fontSizes: {
    "xs": "0.75rem", // 12px
    "sm": "0.875rem", // 14px
    "md": "1rem", // 16px
    "lg": "1.125rem", // 18px
    "xl": "1.25rem", // 20px
    "2xl": "1.5rem", // 24px
    "3xl": "2rem", // 32px
    "4xl": "2.5rem", // 40px
  },
  components: {
    Heading: {
      baseStyle: {
        fontWeight: 400,
        lineHeight: "32px",
      },
      sizes: {
        small: {
          fontSize: "xl",
          lineHeight: "24px",
        },
        regular: {
          fontSize: "2xl",
          lineHeight: "32px",
        },
        large: {
          fontSize: "3xl",
          lineHeight: "40px",
        },
        headline: {
          fontSize: "4xl",
          lineHeight: "48px",
        },
      },
      defaultProps: {
        size: "regular",
      },
    },
    Text: {
      baseStyle: {
        fontWeight: 400,
        color: "textDefault.400",
      },
      sizes: {
        mini: {
          fontSize: "xs",
          lineHeight: "16px",
        },
        smallAlt: {
          fontSize: "sm",
          lineHeight: "20px",
          letterSpacing: "0.01em",
        },
        small: {
          fontSize: "sm",
          lineHeight: "16px",
          letterSpacing: "0.01em",
        },
        regular: {
          fontSize: "md",
          lineHeight: "24px",
        },
        large: {
          fontSize: "lg",
          lineHeight: "24px",
        },
      },
      defaultProps: {
        size: "small",
      },
    },
    Button,
    Tooltip,
    Popover,
    Menu,
    Modal,
    Checkbox,
    Accordion,
    Divider,
    Input,
    Alert,
    Drawer,
    Radio,
    Table,
    Tag,
    Select,
    NumberInput,
    Switch,
    Tabs,
    Progress,
    Textarea,
    Link,
    Slider,
  },
});
