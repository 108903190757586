import text from "@utils/text";

export const stepsCopies = {
  step1: {
    title: "How much growth capital do you need?",
    description:
      "It will help us understand your expectations. We create growth capital offers personalized for you based on the data.",
    buttonLabel: text.t("continue"),
  },
  step2: {
    title: `What's your monthly revenue?`,
    description: "It will help us provide the most accurate and fair options that fit your unique business.",
    buttonLabel: text.t("continue"),
  },
  step3: {
    title: "Connect platforms",
    description:
      "Your offers will be generated based on your financial and product data. Your data is never shared with anyone.",
    buttonLabel: text.t("continue_to_dashboard"),
  },
  step4: {
    title: "Connect product analytics",
    description:
      "Securely connect your platforms to unlock product analytics. Start tracking your daily revenue and upcoming payments by platform.",
    buttonLabel: text.t("continue_to_dashboard"),
  },
};
