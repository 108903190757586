import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HStack, Icon, Text, VStack } from "@chakra-ui/react";
import { KeyGenerator } from "@common/key-generator/KeyGenerator";
import { BaseCard } from "./BaseCard";
import { ReactComponent as AlertCircle } from "@assets/img/icons/common/alertCircle.svg";

import {
  cleanHMACKeyRequestStatus,
  generatedHMACKeySelector,
  settingsSelector,
  settingsThunks,
} from "@redux/webshops/webshop/settings/settingsSlice";
import { useParams } from "react-router-dom";

export const Verification = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const {
    settings: { hmacKey: { value: secretValue } = {} },
  } = useSelector(settingsSelector);
  const {
    data: { value: generatedSecret },
    requestStatus = {},
  } = useSelector(generatedHMACKeySelector);

  useEffect(() => {
    return () => {
      dispatch(cleanHMACKeyRequestStatus());
    };
  }, [dispatch]);

  const success = requestStatus?.completed && !requestStatus?.error;

  return (
    <BaseCard title={"Verification"} id={"builder-settings-verification"}>
      <VStack
        p={"16px"}
        gap={"16px"}
        alignItems={"center"}
        justifyContent={"center"}
        alignSelf={"stretch"}
        borderRadius={"10px"}
        border={"1px solid"}
        borderColor={"dark.300"}
      >
        <KeyGenerator
          title={"HMAC"}
          subTitle={"Use this HMAC key to verify the integrity of webhook events."}
          keyValue={success ? generatedSecret : secretValue ?? ""}
          requestStatus={requestStatus}
          showCopy={success}
          onButtonClick={() => dispatch(settingsThunks.generateHMACKey({ webshopId: id }))}
        />
        {success && (
          <HStack
            w={"100%"}
            p={"8px 12px"}
            alignItems={"center"}
            bgColor={"#FFD73529"}
            borderRadius={"10px"}
            gap={"8px"}
          >
            <Icon
              as={AlertCircle}
              width={"16px"}
              height={"16px"}
              color={"brandYellow.400"}
              transform={"rotate(180deg)"}
            />
            <Text size={"smallAlt"} fontWeight={500} color={"brandYellow.400"}>
              PLEASE NOTE: This is the only time you will see your full HMAC key. Misplacement will require to you to
              generate a new HMAC key.
            </Text>
          </HStack>
        )}
      </VStack>
    </BaseCard>
  );
};
