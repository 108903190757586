import styled from "styled-components";

export const StyledConnectionFlow = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;

  .setup-guide-link {
    color: ${({ theme }) => theme.colorRed};
  }

  .step-input-wrapper {
    margin-bottom: 24px;
  }
  .jumbo-input-label {
    color: ${({ theme }) => theme.textEmphasis};
    margin-bottom: 8px;
  }

  .jumbo-input-group {
    margin-bottom: 24px;
  }

  #bottom-toast {
    visibility: hidden;
    min-width: 250px;
    margin-left: -125px;
    background-color: ${({ theme }) => theme.grayscale40};
    box-shadow: 0px 0px 48px rgba(0, 0, 0, 0.4);
    color: ${({ theme }) => theme.textEmphasis};
    text-align: center;
    border-radius: 12px;
    padding: 12px;
    position: fixed;
    z-index: 1000;
    left: 50%;
    bottom: 5%;
  }

  #bottom-toast.show {
    visibility: visible;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }

  @-webkit-keyframes fadein {
    from {
      bottom: 0;
      opacity: 0;
    }
    to {
      bottom: 5%;
      opacity: 1;
    }
  }

  @keyframes fadein {
    from {
      bottom: 0;
      opacity: 0;
    }
    to {
      bottom: 5%;
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeout {
    from {
      bottom: 5%;
      opacity: 1;
    }
    to {
      bottom: 0;
      opacity: 0;
    }
  }

  @keyframes fadeout {
    from {
      bottom: 5%;
      opacity: 1;
    }
    to {
      bottom: 0;
      opacity: 0;
    }
  }

  .fcf-home {
    margin-top: 242px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .template-cards-container {
      margin-top: 28px;
      display: flex;
    }
  }

  .fs-modal-actions {
    width: 420px;
    height: 100%;
    margin-right: 32px;
    display: flex;
    flex-direction: column;

    .integration-error-message {
      margin-bottom: 16px;
      display: none;

      & > div {
        justify-content: flex-start;
      }

      &.show-message {
        display: block;
      }
    }

    .fs-scrollable {
      overflow: auto;
    }

    .fs-modal-link-button-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      cursor: pointer;

      .fs-plus-circle-icon {
        fill: ${({ theme }) => theme.colorRed};
        margin-right: 4px;
      }

      .fs-modal-link-button {
        color: ${({ theme }) => theme.colorRed};
      }
    }

    .permission-checkbox {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;

      input {
        margin: 0 0.5rem;
        cursor: pointer;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: 2px solid rgba(197, 197, 197, 1);
        width: 16px;
        height: 14px;
        border-radius: 3px;
      }

      input:checked {
        background-color: rgba(197, 197, 197, 1);
      }

      label {
        margin: 0;
        line-height: 1rem !important;
        cursor: pointer;

        span {
          line-height: 1rem !important;
        }
      }
    }
  }

  .fs-link {
    color: ${({ theme }) => theme.colorRed};
  }

  .contact-us {
    margin-top: 1rem;
    color: rgba(255, 255, 255, 0.5);
  }

  &.done-step {
    .fs-modal-instructions-integrations {
      display: none;
    }

    .fs-modal-actions {
      margin-right: 0;
      /* justify-content: center; */
      align-items: center;
      width: 476px;
    }

    .done-title {
      margin-bottom: 8px;
      color: ${({ theme }) => theme.textEmphasis};
    }

    .done-description {
      text-align: center;
      color: ${({ theme }) => theme.grayFontColor};
      margin-bottom: 32px;
    }
  }

  &.align-left {
    .fs-modal-actions {
      align-items: flex-start;
    }
    .done-description {
      text-align: left;
    }
  }
`;
