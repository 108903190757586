import React, { useState } from "react";
import classnames from "classnames";

import { BodyRegularPlus, BodySmall14, TitleLargePlus } from "@styled";
import { StyledFullScreenModal } from "./StyledFullScreenModal";
import ConnectionFlowContainer from "@components/integrations/ConnectionFlowContainer";
import { INTEGRATIONS } from "@utils/integrations";

import { ReactComponent as CrossIcon } from "@assets/img/icons/common/modal-cross.svg";
import { ReactComponent as ArrowIcon } from "@assets/img/icons/common/arrow_left.svg";

// We don't want to put JSX in the store, so for now this is the best area
// to keep this until I actually try and figure out where this should go
export const MODAL_CONTENT = {
  appsflyer: <ConnectionFlowContainer platform={INTEGRATIONS.appsflyer} />,
  adjust: <ConnectionFlowContainer platform={INTEGRATIONS.adjust} />,
  appstore: <ConnectionFlowContainer platform={INTEGRATIONS.appstore} />,
  googleplay: <ConnectionFlowContainer platform={INTEGRATIONS.googleplay} />,
  firebase: <ConnectionFlowContainer platform={INTEGRATIONS.firebase} />,
  singular: <ConnectionFlowContainer platform={INTEGRATIONS.singular} />,
  steam: <ConnectionFlowContainer platform={INTEGRATIONS.steam} />,
};

const FullScreenModal = ({
  modalContent = {},
  openModal,
  closeModal,
  stepNumber = 0,
  showArrowIcon = true,
  ...rest
}) => {
  const { steps = [] } = modalContent;
  const step = steps[stepNumber];

  const [stepNbr, setStepNbr] = useState(stepNumber);
  const [stepData, setStepData] = useState(step);
  const [openSaveProgressModal, setOpenSaveProgressModal] = useState(false);

  // Get the total number of steps that show progress
  let totalSteps = 0;
  steps.forEach((step) => {
    if (!step.title || step.hideProgress) return;
    totalSteps += 1;
  });
  const value = ((stepNbr + 1) * 100) / totalSteps;

  const { title, subtitle, isSetupDone, hideProgress } = steps[stepNbr] ?? {};

  const handleToggleStep = (delta, set = false) => {
    const newStepNumber = set ? delta : stepNbr + delta;
    setStepNbr(newStepNumber);
    setStepData(steps[newStepNumber]);
  };

  const handleCloseSetupModal = () => {
    if (rest.saveProgress) {
      setOpenSaveProgressModal(true);
    } else {
      closeModalResetStep();
    }
  };

  const handleConfirmCloseModal = () => {
    setOpenSaveProgressModal(false);
    closeModalResetStep();
  };

  const closeModalResetStep = () => {
    setStepNbr(0);
    setStepData(steps[0]);
    closeModal();
  };

  // Just slightly better named functions provided alongside
  // the others for now.
  const goToNextStep = () => {
    handleToggleStep(1);
  };
  const goToStep = (stepNumber, opts = {}) => {
    const { closeAfter = false } = opts;
    // 1 indexed to match up steps easier
    handleToggleStep(stepNumber - 1, true);
    if (closeAfter) closeModal();
  };

  return (
    <StyledFullScreenModal isOpen={openModal} fade={false} scrollable={false}>
      <div className="leftGradModal" />
      <div className="rightGradModal" />
      <div className="fs-modal-container">
        <div className="fs-modal-top-navigation">
          {!hideProgress && (
            <div className="fs-modal-progress-bar">
              <div className="progress-bar-content">
                <div className="value" style={{ width: `${value}%` }} />
                <div className="empty" style={{ width: `${100 - value}%` }} />
              </div>
            </div>
          )}
          <div className="fs-modal-controls">
            {showArrowIcon ? (
              <ArrowIcon
                className={classnames(
                  "fs-modal-arrow-icon " + (isSetupDone ? "hidden" : ""),
                  `tag-sanlo-modal-back-arrow-${modalContent.id}-${stepNbr}`,
                )}
                aria-hidden={true}
                onClick={() => (!isSetupDone ? (stepNbr > 0 ? handleToggleStep(-1) : handleCloseSetupModal()) : null)}
              />
            ) : (
              <div></div>
            )}
            {!hideProgress && (
              <BodyRegularPlus>
                {stepNbr + 1} of {totalSteps}
              </BodyRegularPlus>
            )}
            {!isSetupDone && (
              <button
                aria-label="Close"
                className={classnames("fs-modal-close", `tag-sanlo-modal-close-${modalContent.id}-${stepNbr}`)}
                data-dismiss="modal"
                type="button"
                onClick={handleCloseSetupModal}
              >
                <CrossIcon className={`tag-sanlo-modal-close-${modalContent.id}-${stepNbr}`} aria-hidden={true} />
              </button>
            )}
          </div>
          {title && (
            <div className="fs-modal-header">
              <TitleLargePlus>{title}</TitleLargePlus>
              <BodySmall14 className="fs-modal-subtitle">{subtitle}</BodySmall14>
            </div>
          )}
        </div>
        <div className="fs-modal-body">
          {React.cloneElement(modalContent.content || MODAL_CONTENT[modalContent.id], {
            stepNbr: stepNbr,
            toggleStep: handleToggleStep,
            handleClick: rest.handleClick,
            closeModal: closeModalResetStep,
            goToNextStep: goToNextStep,
            goToStep: goToStep,
            stepData: stepData,
            saveProgress: {
              openSaveProgressModal: openSaveProgressModal,
              setOpenSaveProgressModal: setOpenSaveProgressModal,
              handleConfirmCloseModal: handleConfirmCloseModal,
            },
          })}
        </div>
      </div>
      <div id="toast-bg-fade" />
    </StyledFullScreenModal>
  );
};

export default FullScreenModal;
