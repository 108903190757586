import { configureConsts, configureActions } from "@utils/redux";

export const PLAID_STATUS = configureConsts("PLAID_STATUS");
export const PLAID_CONNECT = configureConsts("PLAID_CONNECT");
export const PLAID_CLOSING_BALANCE = configureConsts("PLAID_CLOSING_BALANCE");
export const PLAID_UNLINK_INTEGRATION = configureConsts("PLAID_UNLINK_INTEGRATION");

const plaidActions = {
  status: configureActions(PLAID_STATUS),

  connect: configureActions(PLAID_CONNECT, {
    request: (linkType, redirectTo) => ({
      type: PLAID_CONNECT.REQUEST,
      linkType,
      redirectTo,
    }),
  }),

  closingBalance: configureActions(PLAID_CLOSING_BALANCE),

  unlinkIntegration: configureActions(PLAID_UNLINK_INTEGRATION),
};

export default plaidActions;
