import styled from "styled-components";

export const StyledUpcomingPayoutsContainer = styled.div`
  --card-width: 16.375rem;
  --card-height: 8.6875rem;

  position: relative;
  overflow: hidden;
`;

export const StyledUpcomingPaymentsLoadingContent = styled.div`
  display: flex;
  overflow: hidden;
  position: relative;
  border-radius: 12px;
`;

export const StyledUpcomingPayoutsContent = styled.div`
  position: relative;
  border-radius: 12px;
  min-height: var(--card-height);

  .up-cards-scroll-area {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 3.5rem;
    z-index: 10;
    transition: all 0.15s ease;
    cursor: pointer;

    svg {
      fill: ${({ theme }) => theme.rowCrossColor};
      position: absolute;
      top: 52%;
      /* transform: translateX(-50%) translateY(-50%); */
    }

    &.up-cards-left {
      left: 0;
      background: linear-gradient(270deg, rgba(26, 26, 31, 0) 0%, #1a1a1f 100%);

      .up-cards-prev {
        left: 20%;
        transform: scale(1.5) translateY(-50%) rotate(270deg);
      }
    }

    &.up-cards-right {
      right: 0;
      background: linear-gradient(90deg, rgba(26, 26, 31, 0) 0%, #1a1a1f 100%);

      .up-cards-next {
        right: 20%;
        transform: scale(1.5) translateY(-50%) rotate(90deg);
      }
    }

    &.up-cards-scrolled-left {
      left: -3.5rem;
    }

    &.up-cards-scrolled-right {
      right: -3.5rem;
    }

    &:hover svg {
      fill: ${({ theme }) => theme.textSeashellWhite};
    }
  }

  .up-cards-wrapper {
    position: relative;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .up-cards {
    position: relative;
    border-radius: 0.75rem;
    display: flex;
    width: fit-content;
  }
`;
