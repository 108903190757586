import { Spinner } from "@components/common/loaders";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export function Redirect({ path }) {
  const navigate = useNavigate();

  useEffect(() => {
    if (path) {
      return navigate(path);
    }
    navigate("/");
  }, [path, navigate]);

  return <Spinner />;
}
