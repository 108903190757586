import React from "react";

import { BodySmallAlt } from "@styled";

import symbols from "@utils/symbols";

const ConnectionErrorBodyState = ({ selectedOption }) => {
  return (
    <div className="dr-message">
      <BodySmallAlt className="dr-message-text">
        We've detected an error on {selectedOption.name} connection and will attempt reconnecting in the next 24 hours.
        Thank you for your patience! {symbols.PrayingHands()}
      </BodySmallAlt>
    </div>
  );
};

export default ConnectionErrorBodyState;
