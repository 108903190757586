import { Box, Button, Heading, Image, SimpleGrid, Skeleton, Text, VStack } from "@chakra-ui/react";
import { authMethodsSelector } from "@redux/webshops/webshop/settings/auth-methods/authMethodsSlice";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useSelector } from "react-redux";

export function AuthMethods({ handleNextStep }) {
  const { authProviders } = useSelector(authMethodsSelector);
  const { webshopGoogleAuthentication } = useFlags();
  const showSkeleton = !authProviders?.length || !authProviders;
  return (
    <VStack gap="24px" alignItems={"stretch"}>
      <Box>
        <Heading size="regular" fontWeight={600}>
          Choose a method
        </Heading>
        <Text size="small" mt="16px">
          Choose a way for your customers to authenticate their ID
        </Text>
      </Box>
      <SimpleGrid columns={2} spacing={"16px"}>
        {showSkeleton ? (
          <>
            <Skeleton startColor="dark.100" endColor="dark.400" height="48px" borderRadius={"none"} />
            <Skeleton startColor="dark.100" endColor="dark.400" height="48px" borderRadius={"none"} />
            <Skeleton startColor="dark.100" endColor="dark.400" height="48px" borderRadius={"none"} />
          </>
        ) : (
          authProviders.map((provider) => {
            if (provider.name === "GOOGLE" && !webshopGoogleAuthentication) return null;
            return (
              <Button
                key={provider.name}
                px="16px"
                py="12px"
                bg="dark.400"
                borderRadius={0}
                size={"lg"}
                gap={"8px"}
                h="60px"
                justifyContent={"start"}
                onClick={() => handleNextStep(provider)}
              >
                <Image w="32px" height="32px" borderRadius={"4px"} src={provider.iconUrl} />
                <VStack alignItems={"flex-start"} gap={0}>
                  <Text textTransform={"capitalize"} fontWeight={500} color={"textWhite.400"} lineHeight={"24px"}>
                    {provider.label}
                  </Text>
                  {provider.data && (
                    <Text fontWeight={500} color={"brandRed.400"}>
                      Edit
                    </Text>
                  )}
                </VStack>
              </Button>
            );
          })
        )}
      </SimpleGrid>
    </VStack>
  );
}
