import React from "react";
import { ThreeDots, ColorRing } from "react-loader-spinner";

const Loader = ({ className, color = "white" }) => (
  <ThreeDots className={className} color={color} height="22" width="22" />
);

export const RingLoader = ({ className, color = "#FF5C79" }) => {
  // colors is the color repeated 5 times, its a ColorRing thing...
  let colors = [color, color, color, color, color];
  return <ColorRing className={className} colors={colors} height="80" width="90" />;
};

export default Loader;
