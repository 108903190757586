import styled from "styled-components";

const StyledTooltipRow = styled.div`
  & {
    width: 100%;

    .tt-row-header {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 4px;

      &.font-strong > span {
        font-weight: 700;
      }

      .tt-row-title {
        color: ${({ theme }) => theme.textSeashellWhite};
        display: flex;
        align-items: center;
        white-space: nowrap;

        &.tt-row-title-highlighted {
          margin-top: 8px;
          color: ${({ theme }) => theme.colorRed};

          > svg {
            fill: ${({ theme }) => theme.colorRed};
            width: 20px;
            height: 20px;
          }
        }
      }

      .tt-row-icon {
        width: 24px;
        height: 24px;
        margin-left: 8px;
        transform: rotate(180deg);
        transition: transform 0.4s ease;
        fill: ${({ theme }) => theme.textSeashellWhite};

        &.icon-left {
          margin-right: 8px;
          margin-left: 0;
        }

        &.tt-hint-open {
          transform: rotate(0deg);
        }
      }
    }

    .tt-row-hint {
      width: 100%;
    }

    .tt-hint-content {
      margin-top: 8px;
      color: ${({ theme }) => theme.textSubtext};

      .tt-hint-p:not(:last-child) {
        margin-bottom: 10px;
      }

      .tt-hint-highlighted {
        color: ${({ theme }) => theme.textSeashellWhite};
        font-weight: 500;
      }

      .tt-hint-highlighted-red {
        color: ${({ theme }) => theme.colorRed};
        font-weight: 500;
      }

      .tt-hint-link {
        color: ${({ theme }) => theme.textSubtext};
        text-decoration: underline;
      }

      .tt-link {
        cursor: pointer;
      }
    }
  }
`;

export default StyledTooltipRow;
