import React from "react";

import { PLATFORMS, PRODUCT_ANALYTICS, BANKING, IN_APP_PURCHASES, AD_NETWORKS } from "@utils/consts";
import text from "@utils/text";
import { snakeToCamelCase } from "@utils/string";

import {
  BankingLinkingAccordion,
  ProductAnalyticsLinkingAccordion,
  InAppPurchasesLinkingAccordion,
  AdNetworksLinkingAccordion,
} from "@components/onboarding/platform-accordion/platforms-linking-flows";

const onboardingStep = 3;

const accordionBanking = (fromDashboard) => ({
  id: BANKING,
  title: text.t("banking"),
  filterMatch: `${BANKING}`,
  content: (
    <BankingLinkingAccordion
      description={text.t("banking_accordion_desc", { fromDashboard })}
      onboardingStep={onboardingStep}
    />
  ),
});

const accordionProductsAnalytics = () => ({
  id: PRODUCT_ANALYTICS,
  title: text.t("product_analytics"),
  suggestionType: PRODUCT_ANALYTICS,
  filterMatch: `${PRODUCT_ANALYTICS}`,
  content: (
    <ProductAnalyticsLinkingAccordion
      description={text.t("pa_accordion_desc_primary")}
      note={text.t("pa_accordion_note_primary")}
      onboardingStep={onboardingStep}
    />
  ),
});

const accordionAppPurchase = (fromDashboard) => ({
  id: IN_APP_PURCHASES,
  title: text.t("platforms"),
  suggestionType: IN_APP_PURCHASES,
  // IN_APP_PURCHASES and PLATFORMS are basically the same thing
  filterMatch: `${IN_APP_PURCHASES} ${PLATFORMS}`,
  content: (
    <InAppPurchasesLinkingAccordion
      description={text.t("iap_accordion_desc")}
      onboardingStep={onboardingStep}
      fromDashboard={fromDashboard}
    />
  ),
});

const accordionAdNetworks = (fromDashboard) => ({
  id: AD_NETWORKS,
  title: text.t("ad_networks"),
  suggestionType: AD_NETWORKS,
  filterMatch: `${AD_NETWORKS}`,
  content: (
    <AdNetworksLinkingAccordion
      description={text.t("ad_networks_accordion_desc")}
      onboardingStep={onboardingStep}
      fromDashboard={fromDashboard}
    />
  ),
});

export const PRIMARY_FLOW_ACCORDION_DATA = ({ fromDashboard = false, filterPlatforms }) => {
  let accordionData = [
    accordionBanking(fromDashboard),
    accordionProductsAnalytics(),
    accordionAppPurchase(fromDashboard),
  ];

  if (filterPlatforms && !fromDashboard) {
    accordionData = [
      accordionAppPurchase(fromDashboard),
      accordionAdNetworks(fromDashboard),
      accordionBanking(fromDashboard),
      accordionProductsAnalytics(),
    ];

    const filteredPlatforms = [];
    accordionData.forEach((accordion) => {
      const filterMatch = accordion.filterMatch;
      for (let i = 0; i < filterPlatforms.length; i++) {
        const filterId = snakeToCamelCase(filterPlatforms[i]);
        if (filterMatch.includes(filterId)) {
          filteredPlatforms.push(accordion);
          break;
        }
      }
    });

    return filteredPlatforms;
  }

  return accordionData;
};
