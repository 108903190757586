import React, { useEffect, useState } from "react";
import classnames from "classnames";

import SanloChip from "@common/chips/SanloChip";
import { SanloInput, SanloButton } from "@inputs";
import { BodySmallAltPlus } from "@styled";
import StyledSuggestIntegration from "./SuggestIntegration.styled";

import { toSnakeCase } from "@utils/string";
import text from "@utils/text";
import { useFlags } from "launchdarkly-react-client-sdk";

import { BANKING, PRODUCT_ANALYTICS, IN_APP_PURCHASES } from "@utils/consts";
import { trackGTMEvent, ONBOARDING_ACTIONS } from "@utils/gtm";

const SuggestIntegration = ({
  suggestionStatus,
  onSuggestionSend = () => {},
  label = "Can't see your platform?",
  suggestionType,
  className,
  lastSuggestion,
}) => {
  const [inputVisible, setInputVisible] = useState(false);
  const [messageVisible, setMessageVisible] = useState(false);
  const [suggestedIntegration, setSuggestedIntegration] = useState("");

  const { clientSuggestPlatformsFlow = false } = useFlags();

  useEffect(() => {
    const { suggestion = null, platformType = null } = lastSuggestion;

    if (!suggestion) return;

    // Hide the input when the request is successful and show the success message
    if (suggestionStatus.completed && suggestion && platformType === toSnakeCase(suggestionType, true)) {
      setInputVisible(false);
      setMessageVisible(true);
      setSuggestedIntegration("");
    }
  }, [suggestionStatus, suggestionType, lastSuggestion]);

  useEffect(() => {
    // Dissolve success modal the first time component renders
    setMessageVisible(false);
  }, []);

  const suggestPlatforms = [PRODUCT_ANALYTICS, BANKING];

  if (clientSuggestPlatformsFlow) suggestPlatforms.push(IN_APP_PURCHASES);

  if (!suggestPlatforms.includes(suggestionType)) return null;

  return (
    <StyledSuggestIntegration className={className}>
      <BodySmallAltPlus className="cant-see-platform">
        {label}
        <span
          onClick={() => {
            trackGTMEvent({
              event: ONBOARDING_ACTIONS.CONNECT_SOURCE_SUGGESTION,
              type: suggestionType,
            });

            if (messageVisible) {
              // If the message is visible, hide it and show the input
              setMessageVisible(false);
              setInputVisible(true);
            } else if (inputVisible) {
              // ...otherwise toggle the input off if its showing
              setInputVisible(false);
            } else {
              // ...otherwise show the input
              setInputVisible(true);
            }
          }}
          className={classnames(
            "red-label",
            `tag-sanlo-onboarding-integrations-cant-see-platform-let-us-know-${suggestionType}`,
          )}
        >
          Let us know
        </span>
      </BodySmallAltPlus>

      {messageVisible && (
        <SanloChip showIcon state="success" content={text.p("platform_suggestion_confirmation_message")} />
      )}

      {inputVisible && (
        <div className="suggest-platform-input-action">
          <SanloInput
            value={suggestedIntegration}
            setValue={setSuggestedIntegration}
            placeholder="Enter the name of your platform..."
            style={{
              marginRight: "16px",
            }}
          />

          <SanloButton
            isDisabled={!suggestedIntegration.trim().length}
            label={"Send"}
            onClick={() => {
              trackGTMEvent({
                event: ONBOARDING_ACTIONS.CONNECT_SOURCE_SUGGESTION_SEND,
                type: suggestionType,
              });
              onSuggestionSend({
                suggestion: suggestedIntegration,
                platformType: toSnakeCase(suggestionType, true),
              });
            }}
          />
        </div>
      )}
    </StyledSuggestIntegration>
  );
};

export default SuggestIntegration;
