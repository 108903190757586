import React from "react";
import styled from "styled-components";
import { AbsoluteAllCSS } from "@styled";

const StyledBlocker = styled.div`
  ${AbsoluteAllCSS};
  z-index: 100;
`;

const StyledDisabledBlocker = styled(StyledBlocker)`
  cursor: not-allowed;
`;

export const DisabledBlocker = ({ style = {} }) => {
  return <StyledDisabledBlocker style={style} />;
};
