import { Link, Text } from "@chakra-ui/react";
import { StatCard } from "@common/stat-card";
import { financialsSelector, financialsThunks } from "@redux/financials/financialsSlice";
import { FINANCIAL_INTELLIGENCE_BALANCE_TYPE } from "@src/consts/financial-intelligence";
import { scrollToTarget } from "@src/utils/general";
import { FINANCIALS_ACTION, trackGTMEvent } from "@src/utils/gtm";
import symbols from "@src/utils/symbols";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { FINANCIAL_INTELLIGENCE_CARD_ID } from "@src/consts/financial-intelligence";
import { ReconnectTooltip } from "@utils/financial-intelligence";
import { getBlurredData } from "@utils/blur";

export function UpcomingCashInCard() {
  const dispatch = useDispatch();
  const { getUpcomingCashIn } = useSelector(financialsSelector);
  const integrations = useSelector((state) => state.integrations);
  const platform = getUpcomingCashIn?.data?.platforms ?? [];
  const { isPartiallyHealthy } = getBlurredData(platform, integrations);
  const currentBalance = Number(getUpcomingCashIn.data?.estimatedCashInflow?.totalAmount ?? 0);
  const isLoading = !getUpcomingCashIn.data && getUpcomingCashIn.requestStatus.pending;

  const PREDICTED_REVENUE = "Upcoming revenue (next 3 months)";

  useEffect(() => {
    const twoMonthsFromNow = moment().add(2, "months").format("YYYY-MM-DD");
    dispatch(financialsThunks.getUpcomingCashIn({ toDate: twoMonthsFromNow }));
  }, [dispatch]);

  const upcomingRevenueInfo = {
    id: FINANCIAL_INTELLIGENCE_CARD_ID.UPCOMING_CASH_IN,
    title: `${symbols.upTrend} ${PREDICTED_REVENUE}`,
    currentBalance,
    status: FINANCIAL_INTELLIGENCE_BALANCE_TYPE.INFLOW,
    onClick: () => {
      trackGTMEvent({
        event: FINANCIALS_ACTION.VIEW_DETAILS,
        card: PREDICTED_REVENUE,
      });
      scrollToTarget("upcoming-payments");
    },
    tooltip: {
      id: "stat-card-upcoming-cash-in-tooltip",
      content: (
        <>
          <Text size="mini">
            This is the estimated net revenue from your connected platforms earned to date and that are yet to be paid
            out to you by the platforms. Some of the revenue included here could take up to 80 days to be paid out to
            you.
          </Text>
          <Text size="mini" mt="4px">
            To connect additional platform accounts, please visit the{" "}
            <Link as={RouterLink} to="/integrations" color="brandRed.400">
              Integrations page
            </Link>
            .
          </Text>
        </>
      ),
    },
    unhealthyTooltip: isPartiallyHealthy
      ? {
          id: "unhealthy-upcoming-cash-in-tooltip",
          content: <ReconnectTooltip />,
        }
      : null,
  };

  return <StatCard data-test-id="upcoming-cash-in-card" isLoading={isLoading} {...upcomingRevenueInfo} />;
}
