import { radioAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(radioAnatomy.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  control: {
    border: "1.333px solid rgba(255, 255, 255, 0.50)",
    bg: "none",
    color: "brandRed.400",
    boxShadow: "none",
    _focusVisible: {
      boxShadow: "none",
      outline: "none",
    },
    _checked: {
      borderWidth: "1.333px",
      borderColor: "brandRed.400",
      color: "brandRed.400",
      bg: "none",
      _hover: {
        borderColor: "brandRed.400",
        bg: "none",
      },
    },
  },
});

export const Radio = defineMultiStyleConfig({ baseStyle });
