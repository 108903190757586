import styled from "styled-components";
import { Modal } from "reactstrap";

const StyledModal = styled(Modal)`
  & {
    .modal-content {
      width: ${({ width }) => `${width}px`};
      background-color: ${({ theme }) => theme.grayscale10};
      box-shadow: 0 0 48px rbga(0, 0, 0, 0.4);
      padding: 28px 32px 32px 32px;
      border-radius: 12px;

      .modal-header {
        margin-bottom: 8px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: none;

        .close {
          padding: 0 1rem 3rem 1.25rem;
        }

        .close > svg {
          fill: ${({ theme }) => theme.textSeashellWhite};
        }
      }
      &.has-chip .modal-header {
        margin-bottom: 24px;
      }
      .modal-content-wrapper {
        display: flex;
        flex-direction: column;

        .modal-description {
          color: ${({ theme }) => theme.textGray};
        }

        .modal-button {
          margin-top: 32px;
        }

        .modal-aside-buttons {
          margin-top: 32px;
          display: flex;
          justify-content: flex-end;
          align-self: flex-end;

          > button:first-child {
            margin-right: 8px;
          }

          > button:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
`;

export default StyledModal;
