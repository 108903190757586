import React from "react";
import { useSelector } from "react-redux";
import styled, { useTheme } from "styled-components";

import { ChartHeader } from "@components/charts";
import { LineChart } from "@components/charts";
import { StyledPageCard } from "@components/page";
import { financialsSelector } from "@redux/financials/financialsSlice";

import useChartVerticalLine from "@utils/custom-hooks/useChartVerticalLine";
import { BlurryCard } from "@common/blurry-card";
import { getNetBurnBlurryMessage } from "@src/consts/blur";
import { getBankingBlurData } from "@utils/blur";

export const CashBurnChart = ({ halfSize }) => {
  const verticalLine = useChartVerticalLine();
  const theme = useTheme();

  const { getMonthlyReport, chartsData } = useSelector(financialsSelector);
  const { requestStatus } = getMonthlyReport;

  const integrations = useSelector((state) => state.integrations);
  const { isBlurred, isPullingData, isBroken } = getBankingBlurData(integrations.plaid.data.institutions);

  const title = "Net Monthly Burn";

  // Card title tooltip body text
  const tooltipTextInfo =
    "Net Monthly Burn is your net change in cash from business activity. It’s your total revenue minus any operating expense and sales related costs. Non-operating cash activity like debt financing or new equity investments are not included in the calculation.";

  // SVG Circle tooltip data
  const tooltipText = {
    insights: "Net monthly burn",
    inProgressMonthBody:
      "Net Burn is based on all transactions from connected bank accounts, up to the specified date. The final figures for the full calendar month is available on the 1st of the following calendar month.",
  };

  if (!requestStatus.completed) {
    return <StyledCashBurnChart half={halfSize} className="loading-shine" />;
  }

  return (
    <BlurryCard
      isBlurred={!requestStatus.pending && isBlurred}
      imgSrc={require("@assets/img/blur/netMonthlyBlur.png")}
      message={getNetBurnBlurryMessage({ isPullingData, isBroken })}
    >
      <StyledCashBurnChart half={halfSize} data-test-id="cash-burn-chart-card">
        {halfSize && <ChartHeader title={title} tooltipText={tooltipTextInfo} type="line-burn-chart" />}
        <LineChart
          noTitle={!halfSize}
          chartName={title}
          chartId="cash-burn"
          data={chartsData.burn}
          tooltipText={tooltipText}
          svgColor={theme.lineChartBurn}
          withNegYAxis
          hasBckGrndColor
          dotLastLine
          verticalLine={verticalLine}
        />
      </StyledCashBurnChart>
    </BlurryCard>
  );
};

const StyledCashBurnChart = styled(StyledPageCard)`
  height: 400px;
  position: relative;
  height: 337px;
`;
