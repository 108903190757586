import React, { Fragment } from "react";
import classnames from "classnames";
import StyledStateButton from "./StyledStateButton";
import { ReactComponent as ErrorIcon } from "@assets/img/icons/common/alertTriangle.svg";
import { ReactComponent as WarningIcon } from "@assets/img/icons/common/alertCircle.svg";
import { ReactComponent as SuccessIcon } from "@assets/img/icons/common/alertCheck.svg";
import { BodySmall14, BodySmallPlus } from "@styled";
import symbols from "@utils/symbols";

const StateButton = ({
  state,
  label,
  onClick,
  regular,
  integration = false,
  hover,
  icon = true,
  disabled = false,
  comingSoon = false,
  testId = "",
  ...props
}) => {
  const stateIcon = {
    success: <SuccessIcon />,
    warning: <WarningIcon />,
    error: <ErrorIcon />,
  };

  const showIcon = (!integration || state === "success") && icon && stateIcon[state];

  return (
    <StyledStateButton
      id={props.id}
      hover={hover}
      state={state}
      onClick={!disabled && !comingSoon ? onClick : null}
      className={classnames("state-button", {
        "button-disabled": disabled || comingSoon,
        "reconnect-button": state === "reconnect",
        "dashboard-state-button": !integration,
      })}
      data-test-id={testId}
    >
      {comingSoon && (
        <Fragment>
          <BodySmall14 className="emoji-mr">{symbols.Eyes()} Coming soon</BodySmall14>
        </Fragment>
      )}

      {!comingSoon && (
        <Fragment>
          {showIcon && <div className="state-icon">{stateIcon[state]}</div>}

          <span className="state-button-label">
            {regular ? <BodySmall14>{label}</BodySmall14> : <BodySmallPlus>{label}</BodySmallPlus>}
          </span>

          <span className="reconnect-circle" />
        </Fragment>
      )}
    </StyledStateButton>
  );
};

export default StateButton;
