import { copy } from "@utils/general";

import { BUILDER_SECTION_TYPES } from "@src/consts/webshops/builder";
export const SECTION_TYPE = BUILDER_SECTION_TYPES.FEATURED_ITEM;

export const generatePayloads = ({ data, updates }) => {
  const combinedSectionData = {
    type: SECTION_TYPE,
    ...copy(data),
    ...copy(updates),
  };

  // If something like the title changed, the current itemId/seriesId
  // is still needed for the request, so we have to copy it from the item/series
  const { series, item } = combinedSectionData;

  combinedSectionData.itemId = series ? "" : item?.id || "";
  combinedSectionData.seriesId = series?.id || "";

  // Other info in the section will cause errors
  // like "id" and "items"/"series" so we need to remove them
  const requiredDefaults = {
    type: SECTION_TYPE,
    order: 0,
    title: "",
    itemId: "",
    seriesId: "",
  };

  const request = {};
  Object.keys(requiredDefaults).forEach((key) => {
    if (Object.hasOwn(combinedSectionData, key)) {
      request[key] = combinedSectionData[key];
    } else {
      request[key] = requiredDefaults[key];
    }
  });

  return copy({
    request,
    response: combinedSectionData,
  });
};

export const getButtonLabel = ({ item = null, series = null }) => {
  const labels = {
    itemButtonLabel: "Item",
    seriesButtonLabel: "Series",
  };

  if (!series && item) {
    labels.itemButtonLabel = "Change item";
    labels.seriesButtonLabel = "Change to series";
  } else if (series) {
    labels.itemButtonLabel = "Change to item";
    labels.seriesButtonLabel = "Change series";
  }

  return labels;
};
