import { copy } from "@utils/general";

import { getRequestStatus, getResponseStatus, getErrorStatus, setStatusDefaults, stateSet } from "@utils/redux";

import {
  ALL_GET_EARNINGS,
  ALL_GET_PREVIOUS_EARNINGS,
  AD_REVENUE_GET_EARNINGS,
  AD_REVENUE_GET_PREVIOUS_EARNINGS,
  APP_STORE_GET_EARNINGS,
  APP_STORE_REFRESH_EARNINGS,
  APP_STORE_GET_PREVIOUS_PERIOD_EARNINGS,
  GOOGLE_PLAY_GET_EARNINGS,
  GOOGLE_PLAY_GET_PREVIOUS_PERIOD_EARNINGS,
} from "./actions";

const BASIC_STRUCTURE = {
  earnings: {},
  requestStatus: setStatusDefaults(),
  initialLoadComplete: false,
};

const initialState = {
  none: {
    ...copy(BASIC_STRUCTURE),
    initialLoadComplete: true,
  },
  all: {
    ...copy(BASIC_STRUCTURE),
    monthlyPeriods: "",
  },
  appstore: copy(BASIC_STRUCTURE),
  googleplay: copy(BASIC_STRUCTURE),
};

const earningsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_GET_EARNINGS.REQUEST: {
      return stateSet(state, {
        "all.requestStatus": getRequestStatus(),
      });
    }

    case ALL_GET_EARNINGS.RESPONSE: {
      return stateSet(state, {
        all: {
          monthlyPeriods: action.response.monthlyPeriods,
          earnings: action.response.earnings,
          requestStatus: getResponseStatus(),
          initialLoadComplete: true,
        },
      });
    }

    case ALL_GET_EARNINGS.ERROR: {
      return stateSet(state, {
        "all.requestStatus": getErrorStatus(),
        "all.initialLoadComplete": true,
      });
    }

    case ALL_GET_PREVIOUS_EARNINGS.REQUEST: {
      return stateSet(state, {
        "all.requestStatus": getRequestStatus(),
      });
    }

    case ALL_GET_PREVIOUS_EARNINGS.RESPONSE: {
      return stateSet(state, {
        "all.monthlyPeriods": `${action.response.previousMonthlyPeriods},${state.all.monthlyPeriods}`,
        "all.earnings.periodEarnings": [
          ...action.response.earnings.periodEarnings,
          ...state.all.earnings.periodEarnings,
        ],
        "all.requestStatus": getResponseStatus(),
      });
    }

    case ALL_GET_PREVIOUS_EARNINGS.ERROR: {
      return stateSet(state, {
        "all.requestStatus": getErrorStatus(),
      });
    }

    case AD_REVENUE_GET_EARNINGS.REQUEST: {
      if (!state[action.networkId]) {
        return stateSet(state, {
          [action.networkId]: copy(BASIC_STRUCTURE),
        });
      } else {
        return stateSet(state, {
          [`${action.networkId}.requestStatus`]: getRequestStatus(),
        });
      }
    }

    case AD_REVENUE_GET_EARNINGS.RESPONSE: {
      return stateSet(state, {
        [`${action.response.networkId}`]: {
          earnings: action.response.data,
          requestStatus: getResponseStatus(),
          initialLoadComplete: true,
        },
      });
    }

    case AD_REVENUE_GET_EARNINGS.ERROR: {
      return stateSet(state, {
        [`${action.networkId}.requestStatus`]: getErrorStatus(),
        [`${action.networkId}.initialLoadComplete`]: true,
      });
    }

    case AD_REVENUE_GET_PREVIOUS_EARNINGS.REQUEST: {
      return stateSet(state, {
        [`${action.networkId}.requestStatus`]: getRequestStatus(),
      });
    }

    case AD_REVENUE_GET_PREVIOUS_EARNINGS.RESPONSE: {
      return stateSet(state, {
        [`${action.response.networkId}.earnings.periodEarnings`]: [
          action.response.previousPeriod,
          ...state[action.response.networkId].earnings.periodEarnings,
        ],
        [`${action.response.networkId}.requestStatus`]: getResponseStatus(),
      });
    }

    case AD_REVENUE_GET_PREVIOUS_EARNINGS.ERROR: {
      return stateSet(state, {
        [`${action.networkId}.requestStatus`]: getErrorStatus(),
      });
    }

    case APP_STORE_GET_EARNINGS.REQUEST: {
      return stateSet(state, {
        "appstore.requestStatus": getRequestStatus(),
      });
    }

    case APP_STORE_GET_EARNINGS.RESPONSE: {
      return stateSet(state, {
        appstore: {
          earnings: action.response,
          requestStatus: getResponseStatus(),
          initialLoadComplete: true,
        },
      });
    }

    case APP_STORE_GET_EARNINGS.ERROR: {
      return stateSet(state, {
        "appstore.requestStatus": getErrorStatus(),
        "appstore.initialLoadComplete": true,
      });
    }

    case APP_STORE_REFRESH_EARNINGS.REQUEST: {
      return stateSet(state, {
        "appstore.requestStatus": getRequestStatus(),
      });
    }

    case APP_STORE_REFRESH_EARNINGS.RESPONSE: {
      return stateSet(state, {
        "appstore.requestStatus": getResponseStatus(),
      });
    }

    case APP_STORE_REFRESH_EARNINGS.ERROR: {
      return stateSet(state, {
        "appstore.requestStatus": getErrorStatus(),
      });
    }

    case APP_STORE_GET_PREVIOUS_PERIOD_EARNINGS.REQUEST: {
      return stateSet(state, {
        "appstore.requestStatus": getRequestStatus(),
      });
    }

    case APP_STORE_GET_PREVIOUS_PERIOD_EARNINGS.RESPONSE: {
      return stateSet(state, {
        "appstore.earnings.periodEarnings": [action.response, ...state.appstore.earnings.periodEarnings],
        "appstore.requestStatus": getResponseStatus(),
      });
    }

    case APP_STORE_GET_PREVIOUS_PERIOD_EARNINGS.ERROR: {
      return stateSet(state, {
        "appstore.requestStatus": getErrorStatus(),
      });
    }

    case GOOGLE_PLAY_GET_EARNINGS.REQUEST: {
      return stateSet(state, {
        "googleplay.requestStatus": getRequestStatus(),
      });
    }

    case GOOGLE_PLAY_GET_EARNINGS.RESPONSE: {
      return stateSet(state, {
        googleplay: {
          earnings: action.response,
          requestStatus: getResponseStatus(),
          initialLoadComplete: true,
        },
      });
    }

    case GOOGLE_PLAY_GET_EARNINGS.ERROR: {
      return stateSet(state, {
        "googleplay.requestStatus": getErrorStatus(),
        "googleplay.initialLoadComplete": true,
      });
    }

    case GOOGLE_PLAY_GET_PREVIOUS_PERIOD_EARNINGS.REQUEST: {
      return stateSet(state, {
        "googleplay.requestStatus": getRequestStatus(),
      });
    }

    case GOOGLE_PLAY_GET_PREVIOUS_PERIOD_EARNINGS.RESPONSE: {
      return stateSet(state, {
        "googleplay.earnings.periodEarnings": [action.response, ...state.googleplay.earnings.periodEarnings],
        "googleplay.requestStatus": getResponseStatus(),
      });
    }

    case GOOGLE_PLAY_GET_PREVIOUS_PERIOD_EARNINGS.ERROR: {
      return stateSet(state, {
        "googleplay.requestStatus": getErrorStatus(),
      });
    }

    default:
      return state;
  }
};

export default earningsReducer;
