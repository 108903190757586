import React from "react";
import { BodySmallPlus } from "../../../styled";
import StyledLabelButton from "./StyledLabelButton";

const LabelButton = ({ handleClick, label }) => (
  <StyledLabelButton>
    <BodySmallPlus onClick={handleClick}>{label}</BodySmallPlus>
  </StyledLabelButton>
);

export default LabelButton;
