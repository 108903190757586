import React from "react";

import symbols from "@utils/symbols";

const PullingDataBodyState = ({ selectedOption }) => {
  return (
    <div className="dr-message">
      <div className="dr-message-symbol">{symbols.unlock}</div>
    </div>
  );
};

export default PullingDataBodyState;
