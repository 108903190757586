import { sliderAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  thumb: {
    bg: "brandSkyBlue.400",
    outline: "2px solid white",
    outlineOffset: "0px",
    h: "10px",
    w: "10px",
  },
  track: {
    h: "6px",
    borderRadius: "3px",
    bg: "dark.200",
  },
  filledTrack: {
    bg: "brandSkyBlue.400",
  },
});

export const Slider = defineMultiStyleConfig({ baseStyle });
