import styled from "styled-components";

const StyledOnboardingDashboard = styled.div`
  & {
    .od-wrapper {
      .od-content-right-col {
        .platform-accordion-wrapper {
          margin-bottom: 16px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
`;
export default StyledOnboardingDashboard;
