// ACTIONS
export const COMMON_ACTION = {
  PRIVACY_POLICY: "Privacy Policy",
  CONTINUE: "Continue",
  COMPLETE: "Complete",
  CONNECT: "Connect",
  GO_BACK: "Go Back",
  CLOSE: "Close",
  HERE_LINK: "Here Link",
  CONTACT_US: "Contact us",
  GET_IN_TOUCH: "Get in touch",
  LOG_IN: "Log in",
  SUBSCRIPTION: "Subscription",
  PREVIOUS_IMAGE: "Previous image carousel",
  NEXT_IMAGE: "Next image carousel",
};

export const BANK_ACTION = {
  UPLOAD_STATEMENT: "Upload Statement",
  CONTINUE_TO_BANKING: "Continue to Banking",
  CONTINUE_TO_DASHBOARD: "Continue to Dashboard",
  CHECK_ACKNOWLEDGE_MODAL_BOX: "Checks Box",
  UNCHECK_ACKNOWLEDGE_MODAL_BOX: "Unchecks Box",
};

export const INTEGRATION_ACTION = {
  HOW_CAN_FIX: "How can I fix it",
  WHY_HAPPEN: "Why did this happen",
  HOW_AFFECT: "How it affects my experience",
  LINK: "Link",
  COPY_FULLNAME: "Copy Full Name",
  COPY_EMAIL: "Copy Email",
  CHECKBOX_GRANTED_ACCESS: "Checkbox granted Sanlo access",
  CONTINUE: "Continue to instructions",
  SAVE_PROGRESS: "Save Progress",
  START_FROM_SCRATCH: "Start from scratch",
};

export const APPSFLYER_ACTION = {
  MASTER_API: "Master API",
  PULL_API_RAW: "Pull API Raw Data",
};

export const APPSTORE_ACTION = {
  ADMIN_OR_FINANCIAL_LINK: "Admin or Finance Link",
};

export const GOOGLE_ACTION = {
  USER_PERMISSION: "User and Permissions Link",
  GRANTED_PERMISSION_YES: "Granted Permission - Yes, continue",
  GRANTED_PERMISSION_NO: "Granted Permission - Mo, show me how",
};

export const ADJUST_ACTION = {
  ADJUST_ACCOUNT: "Adjust Account Link",
  API_TOKEN: "Api Token Instructions Link",
  APPS: "Apps Link",
  APP_TOKEN: "App Token Instructions Link",
  ADD_APP_TOKEN: "Add App Token",
  REMOVE_ADD_APP_TOKEN: "Remove Add App Token",
  CANCEL_REMOVE_ADD_APP_TOKEN: "Cancel Remove Add App Token",
};

export const INTEGRATION = {
  BANK_ACKNOWLEDGE_DATA_MODAL: "Integration - Bank - Acknowledge Data Modal - ",
  BANK_PRE_PLAID_MODAL: "Integration - Bank - Pre Plaid Modal - ",
  APPSFLYER_MANUAL_INTEGRATION: "Integration - Platform - AppsFlyer - Manual Integration - ",
};

export const manualIntegrationTag = (platform, action) =>
  `Integration - Platform - ${platform.name} - Manual Integration - ${action}`;

export const FINANCIALS_ACTION = {
  VIEW_DETAILS: `Financial Intelligence - View Details - Click`,
  EXPAND_TOOLTIP_ACCORDION: "Financial Intelligence - Tooltip - Accordion - Click",
  INTEGRATION_PAGE_LINK: "Financial Intelligence - Tooltip - Integration Page Link - Click",
  CASH_FLOW_BAR_FILTER: "Financial Intelligence - Inflows & Outflows - Bar Filter - Click",
  CASH_ACTIVITY_FLOW_BAR_FILTER: "Financial Intelligence - Monthly Cash flow - Bar Filter - Click",
  EXPAND_ACCORDION: "Financial Intelligence - Cash Flow Breakdown - Accordion - Expand - Click",
  VIEW_BY_DROPDOWN: "Financial Intelligence - Cash Flow Breakdown - Revenue - View By Dropdown - Click",
  BREAKDOWN_MONTHLY_DROPDOWN: "Financial Intelligence - Cash Flow Breakdown - Monthly Dropdown - Click",
  BREAKDOWN_MONTHLY_PAYLOAD: "Financial Intelligence - Cash Flow Breakdown - Monthly Dropdown - Data Load",
  VIEW_BY_OPTION: "Financial Intelligence - Cash Flow Breakdown - Revenue - View By Dropdown - Option Selected",
  HOVER_TOOLTIP: "Financial Intelligence - Tooltip - Hover",
  HOVER_CHART: "Financial Intelligence - Chart - Hover Month",
};

export const FINANCIALS_PATHNAME = "/financial-analytics";

export const DASHBOARD_ACTION = {
  SUMMARY_CARD: "Dashboard - Summary Card - Click",
  FINANCIALS_VIEW_DETAILS: "Dashboard - Financial Intelligence [Beta] section - View Details - Click",
  FINANCIALS_LEARN_MORE: "Dashboard - Financial Intelligence [Beta] section - Learn More - Click",
  FINANCIALS_JOIN_NOW: "Dashboard - Financial Intelligence [Beta] section - Join Now - Click",
  FINANCIALS_THANKS_MSG: "Dashboard - Financial Intelligence [Beta] section - Thank you for your interest message",
};

export const ONBOARDING_ACTIONS = {
  PROFILE_MARKETING_CHECKBOX: "Onboarding - Complete Profile - Marketing Checkbox - Click",
  PROFILE_NEXT: "Onboarding - Complete Profile - Next Button - Click",
  PLATFORMS_INTEGRATION: "Onboarding - Connect Source - Ad Network and Platforms Only - View",
  ALL_INTEGRATION: "Onboarding - Connect Source - All integration - View",
  SUPERPOWERS_MODAL_NEXT: "Onboarding - Superpowers Modal - Next Button - Click",
  CONNECT_SOURCE_EXPANDED: "Onboarding - Connect Source - Accordion - Click",
  CONNECT_SOURCE_ICON: "Onboarding - Connect Source - Integration Icon - Click",
  CONNECT_SOURCE_SUGGESTION: "Onboarding - Connect Source - Integration Suggestion - Click",
  CONNECT_SOURCE_SUGGESTION_SEND: "Onboarding - Connect Source - Integration Suggestion - Send - Click",
  CONNECT_SOURCE_BANKING_ADD: "Onboarding - Connect Source - Integration Banking - Add - Click",
  CONNECT_SOURCE_BANKING_UPLOAD_STATEMENT:
    "Onboarding - Connect Source - Integration Banking - Upload Statement - Click",
  CONNECT_SOURCE_BANKING_UPLOAD_FILE: "Onboarding - Connect Source - Integration Banking - Add File - Click",
  CONNECT_SOURCE_BANKING_UPLOAD_MORE_FILE: "Onboarding - Connect Source - Integration Banking - Add More File - Click",
  CONNECT_SOURCE_PRODUCT_ANALYTIC_DATA:
    "Onboarding - Connect Source - Integration Product Analytics - Product Analytics Data - Hover",
  CONNECT_SOURCE_DATA_LINK: "Onboarding - Connect Source - Data Link - Click",
};

export const ADD_NETWORKS_ACTION = {
  ADD: "Integrations - Ad Network - Add - Click",
  MODAL: "Integrations - Ad Network - Modal",
  MODAL_NEXT: "Integrations - Ad Network - Modal - Next - Click",
  MODAL_BACK: "Integrations - Ad Network - Modal - Back - Click",
  MODAL_CLOSE: "Integrations - Ad Network - Modal - Close - Click",
};
