import HTTP from "@redux/http";
const BASE = `/api/ad-networks`;

class AdNetworksService {
  getAvailableNetworks() {
    return HTTP.req.get(`${BASE}/connection/archetypes`);
  }

  createAdNetworkConnection(archetypeId = "") {
    return HTTP.req.post(`${BASE}/connection`, { archetypeId });
  }

  addCredentials(credentials = {}) {
    return HTTP.req.post(`${BASE}/connection/credentials`, { ...credentials });
  }

  getNetworkByConnectionId(connectionId = "") {
    return HTTP.req.get(`${BASE}/connection/${connectionId}/archetype`);
  }

  credentialsCallback(credentials = {}) {
    return HTTP.req.post(`${BASE}/connection/credentials/callback`, { ...credentials });
  }
}

export default new AdNetworksService();
