import { isAnyOf, createListenerMiddleware } from "@reduxjs/toolkit";
import { builderThunks } from "@redux/webshops/builder/builderSlice";
import { catalogThunks, setItems, updateUnassignedItems } from "../catalogSlice";
import { seriesThunks, setSeries } from "../series/seriesSlice";

const catalogListenerMiddleware = createListenerMiddleware();

let catalogListeners = [];
let publishListener = null;

const startListener = (listener) => {
  const stopListener = catalogListenerMiddleware.startListening(listener);
  catalogListeners.push(stopListener);
};

export const startCatalogListeners = () => {
  startListener({
    matcher: isAnyOf(
      seriesThunks.createSeries.fulfilled,
      seriesThunks.updateSeries.fulfilled,
      seriesThunks.deleteSeries.fulfilled,
    ),
    effect: async (action, { dispatch, getState }) => {
      const { type, payload } = action;
      const items = getState().webshops.catalog.catalog.data.items;
      const isCreateSeries = type === "series/createSeries/fulfilled";
      const isDeleteSeries = type === "series/deleteSeries/fulfilled";
      const seriesId = type === isDeleteSeries ? payload : payload.id;
      let updatedItems = items;

      // If a series is created, it is not necessary to reset seriesId in the items.
      if (!isCreateSeries) {
        updatedItems = items.map((item) => {
          if (item.seriesId === seriesId) {
            return { ...item, seriesId: null };
          }
          return item;
        });
      }

      // If a series is deleted, it is not necessary to update the items with seriesId
      if (!isDeleteSeries) {
        updatedItems = updatedItems.map((item) => {
          const existsInSeries = payload.items.some((seriesItem) => seriesItem.id === item.id);
          if (existsInSeries) {
            return { ...item, seriesId: payload.id };
          }
          return item;
        });
      }

      dispatch(setItems(updatedItems));
      const webshopId = getState().webshops.webshops.selectedWebshop.id;
      dispatch(catalogThunks.unassignedSeriesItems(webshopId));
    },
  });

  startListener({
    actionCreator: catalogThunks.updateItem.fulfilled,
    effect: async (action, { dispatch, getState }) => {
      const { payload = {} } = action;
      const { id } = payload;
      const state = getState();
      const unassignedItems = state.webshops.catalog.catalog.unassignedItems.data;

      // Update unassigned items if the item is found
      const unassignedItemsUpdated = unassignedItems.map((item) => (item.id === id ? payload : item));
      if (unassignedItemsUpdated.some((item) => item.id === id)) {
        dispatch(updateUnassignedItems(unassignedItemsUpdated));
        return;
      }

      // Update series if the item is not found in unassigned items
      const allSeries = state.webshops.series.data;
      const updatedSeries = allSeries.map((series) => ({
        ...series,
        items: series.items.map((item) => (item.id === id ? payload : item)),
      }));
      dispatch(setSeries(updatedSeries));
    },
  });
};

export const stopCatalogListeners = () => {
  catalogListeners.forEach((stopListener) => stopListener());
  catalogListeners = [];
};

export const startPublishListener = () => {
  publishListener = catalogListenerMiddleware.startListening({
    actionCreator: builderThunks.publishStorefront.fulfilled,
    effect: async (action, { dispatch, getState }) => {
      const seriesList = getState().webshops.series.data;
      const updatedSeriesList = seriesList.map((series) =>
        series.publishedAt ? series : { ...series, publishedAt: new Date().toISOString() },
      );
      dispatch(setSeries(updatedSeriesList));
    },
  });
};

export const stopPublishListener = () => {
  if (publishListener) {
    publishListener();
    publishListener = null;
  }
};

export default catalogListenerMiddleware.middleware;
