import classnames from "classnames";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import actions from "@redux/actions";

import AdvanceCard from "@components/advance/AdvanceCard";
import DismissableBanner from "@components/common/banners/DismissableBanner";
import FullScreenModal from "@components/common/modals/fullscreen-modal/FullScreenModal";
import { Tabs } from "@components/common/tabs/Tabs";
import { BodyRegular16, BodySmall14, BodySmallAlt, StyledMessageCard, TitleSmallPlus } from "@styled";
import GrowthCapitalSummary from "./GrowthCapitalSummary";
import { StyledPageContainer, PageHeader } from "@components/page";

import { alphaTrim } from "@utils/general";
import symbols from "@utils/symbols";

import { StyledGcOffers } from "@components/gc-offers/GcOffers.styled";
import OfferTabView from "@components/gc-offers/OfferTab";
import text from "@utils/text";
import GcContainer from "../../components/gc-offers/GcContainer";
import WaitingCard from "../../components/gc-offers/WaitingCard";
import { currencyFormat } from "@utils/currency";
import { formatReadableDate } from "../../utils/format";
import CapitalButton from "@components/growth-capital/CapitalButton";

import ErrorBoundary from "@components/ErrorBoundary";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as CancelIcon } from "@assets/img/icons/common/cancel-icon.svg";
import { CapitalRequestForm } from "./capital-request-form/CapitalRequestForm";

export let TABS = [
  {
    id: "activeTab",
    name: "Active",
  },
  {
    id: "offerTab",
    name: "Offer",
  },
  {
    id: "repaidTab",
    name: "Repaid",
  },
];

const offerType = {
  FIXED_TERMS: "Fixed-term",
  REVENUE_BASED: "Revenue-based",
};

const GrowthCapitalPage = () => {
  const userFirstName = useSelector((state) => state.session.user.firstName) || "User";
  const { growthCapitalSummaryVisible = true, clientGrowthCapitalOffersVisible = false } = useFlags();

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const advances = useSelector((state) => state.integrations.advances.data.lists);

  // Growth capital offers
  const offers = useSelector((state) => state.offers);
  const data = useSelector((state) => state.offers.data);
  const requestStatus = useSelector((state) => state.offers.requestStatus);

  const noOffers = offers.capitalOffers.length === 0;
  const availableOffer = offers.capitalOffers?.find((offer) => offer.state === "WAITING_FOR_OFFER_SELECTION");
  const isOfferSelected = offers.capitalOffers?.find((offer) => offer.state === "OFFER_SELECTED");
  const isOfferActive = offers.capitalOffers?.find((offer) => offer.state === "COMPLETED");

  const selectedOffer = isOfferSelected?.options?.find((option) => option.selectedAmount !== null);

  const { activeAdvances = [], repaidAdvances = [], platforms = {} } = advances;

  const advanceRequestStatus = useSelector((state) => state.integrations.advances.requestStatus.getAdvances);
  const advanceSummaryStatus = useSelector((state) => state.integrations.advances.requestStatus.getSummary);
  const clonedCapitalRequestStatus = useSelector(
    (state) => state.integrations.advances.requestStatus.capitalRequestClone,
  );

  const advanceSummary = useSelector((state) => state.integrations.advances.data.summary);
  const {
    requestedCapitalActive,
    requestStage,
    // rejectedRecently,
    rejectedCardDismissed,
  } = advanceSummary;
  // This means the user hasn't done anything with the offers available
  const offerExpired = requestStage === "EXPIRED" || offers.capitalOffers?.find((offer) => offer.state === "EXPIRED");

  const isRejected = requestStage === "REJECTED";

  const [getCapitalModal, setGetCapitalModal] = useState(false);
  const [activeTab, setActiveTab] = useState(TABS[1].id);

  const handlerCapitalFormSubmit = () => {
    setGetCapitalModal(false);
    setActiveTab(TABS[0].id);
  };

  const capitalRequestModalContent = {
    id: "GetCapitalRequest",
    content: <CapitalRequestForm onSubmit={handlerCapitalFormSubmit} />,
    steps: [
      {
        title: "Share a few details about your business",
        subtitle:
          "It will help us understand your expectations. We create your personalized funding offers based on the data.",
      },
    ],
  };

  // Used to determine if the page has scrolled using the advance ID in the window hash.
  // We only want this to happen once, and its possible it needs to be delayed if the company
  // has an "Active USD" tab that the advance belongs to.
  const hasScrolled = useRef(false);
  const capitalRequestClonnedFromDashboard = location.state?.capitalRequestClonnedFromDashboard;
  const userHasAdvances = (type, minimum = 1) => type.length >= minimum;
  const onDismissRejectedCapitalRequest = () => {
    dispatch(actions.integrations.advances.capitalRequestDismissStatus.request());
  };

  useEffect(() => {
    if (!clientGrowthCapitalOffersVisible) {
      TABS = TABS.filter((tab) => tab.name !== "Offer");
      setActiveTab(TABS[1].id);
    }
  }, [clientGrowthCapitalOffersVisible]);

  const handleTabChange = (newTab) => {
    setActiveTab(newTab);
  };

  useEffect(() => {
    // This entire effect is for scrolling down to an advance that was selected
    // on the dashboard page, if there is no advanceId in the hash, we can ignore it
    const advanceId = window.location.hash;
    if (!advanceId || hasScrolled.current) return;

    if (document.querySelector(advanceId)) {
      hasScrolled.current = true;
      const el = document.querySelector(advanceId);
      el.scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
      el.classList.add("selected-card");
    }
    // eslint-disable-next-line
  }, [window.location.hash]);

  useEffect(() => {
    if (clientGrowthCapitalOffersVisible && !userHasAdvances(activeAdvances) && offers.capitalOffers.length > 0) {
      setActiveTab(TABS[1].id);
    }
  }, [offers.capitalOffers, clientGrowthCapitalOffersVisible, activeAdvances]);

  useEffect(() => {
    if (userHasAdvances(activeAdvances) || clonedCapitalRequestStatus.isCloned || capitalRequestClonnedFromDashboard) {
      setActiveTab(TABS[0].id);
    }
  }, [clonedCapitalRequestStatus.isCloned, capitalRequestClonnedFromDashboard, activeAdvances]);

  const onTrackAdvance = () => {
    navigate("/dashboard", { scrollToUpcoming: true });
  };

  const ActiveTab = () => (
    <ErrorBoundary name="growth-capital-active-tab">
      <ErrorBoundary name="growth-capital-active-tab-available-offer-banner">
        {requestedCapitalActive && !isOfferSelected && !userHasAdvances(activeAdvances) && !isRejected && (
          <DismissableBanner
            id={"capitalRequested"}
            content={
              <BodySmallAlt className="text-center">
                {symbols.pizza} {text.t("active_tab_active_request")}
              </BodySmallAlt>
            }
            dismissable={false}
          />
        )}
      </ErrorBoundary>
      <ErrorBoundary name="growth-capital-active-tab-last-step-component">
        {(data.selectedAmount || isOfferSelected) && clientGrowthCapitalOffersVisible && !availableOffer && (
          <StyledGcOffers>
            <WaitingCard
              header={<TitleSmallPlus>{text.t("last_step_header")}</TitleSmallPlus>}
              body={
                <div className="waiting-body">
                  <ul className="list">
                    <li>
                      <BodyRegular16>{text.t("last_step_list_0")}</BodyRegular16>
                    </li>
                    <li>
                      <BodyRegular16>{text.t("last_step_list_3")}</BodyRegular16>
                    </li>
                  </ul>
                  <div className="blue-line" />
                  <div className="selected-details">
                    <TitleSmallPlus>
                      {symbols.moneyBag} {currencyFormat(selectedOffer.selectedAmount)} •
                    </TitleSmallPlus>{" "}
                    <BodyRegular16>{offerType[selectedOffer.type]}</BodyRegular16>
                    <BodySmall14>Confirmed on {formatReadableDate(isOfferSelected.updatedAt)}(UTC)</BodySmall14>
                  </div>
                </div>
              }
              footer={<BodyRegular16 className="confirmed-contact">{text.t("last_step_footer")}</BodyRegular16>}
              showBorder
            />
          </StyledGcOffers>
        )}
      </ErrorBoundary>

      <ErrorBoundary name="growth-capital-active-tab-rejected-offer-component">
        {clientGrowthCapitalOffersVisible && isRejected && !rejectedCardDismissed && (
          <StyledGcOffers>
            <WaitingCard
              header={
                <div className="gc-offer-title">
                  <TitleSmallPlus>{text.t("rejected_header")}</TitleSmallPlus>
                  <CancelIcon className="cancel" onClick={onDismissRejectedCapitalRequest} />
                </div>
              }
              body={
                <div className="card-text">
                  <BodySmallAlt>{text.t("rejected_list_1")}</BodySmallAlt>
                  <br />
                  <BodySmallAlt>{text.t("rejected_list_2", { onTrackAdvance })}</BodySmallAlt>
                </div>
              }
              footer={<BodySmallAlt className="card-text">{text.t("rejected_footer")}</BodySmallAlt>}
            />
          </StyledGcOffers>
        )}
      </ErrorBoundary>

      <ErrorBoundary name="growth-capital-active-tab-summary-component">
        {userHasAdvances(activeAdvances, 2) && growthCapitalSummaryVisible && (
          <Fragment>
            <GrowthCapitalSummary advances={activeAdvances} platforms={platforms} handleTabChange={handleTabChange} />
            <TitleSmallPlus className="mb1">Capital details</TitleSmallPlus>
          </Fragment>
        )}
      </ErrorBoundary>

      <ErrorBoundary name="growth-capital-active-tab-advances-list-component">
        {userHasAdvances(activeAdvances) && (
          <AdvanceTabList
            userFirstName={userFirstName}
            type={"active"}
            advances={activeAdvances}
            advanceStatus={advanceRequestStatus}
            advancePlatforms={advances.platforms}
          />
        )}
      </ErrorBoundary>
      <ErrorBoundary name="growth-capital-active-tab-no-active-capital-component">
        {!userHasAdvances(activeAdvances) && noOffers && !isOfferActive && !isRejected && (
          <NoActiveCapital
            advanceSummaryStatus={advanceSummaryStatus}
            hasRequestedCapital={requestedCapitalActive}
            openCapitalRequest={setGetCapitalModal}
          />
        )}
      </ErrorBoundary>
    </ErrorBoundary>
  );

  const RepaidTab = () => (
    <ErrorBoundary name="growth-capital-repaid-tab">
      {userHasAdvances(repaidAdvances) && (
        <AdvanceTabList
          type={"repaid"}
          advances={repaidAdvances}
          advanceStatus={advanceRequestStatus}
          advancePlatforms={advances.platforms}
        />
      )}
      {!userHasAdvances(repaidAdvances) && (
        <StyledMessageCard>
          <BodySmallAlt>You have no repaid capital yet.</BodySmallAlt>
        </StyledMessageCard>
      )}
    </ErrorBoundary>
  );

  const OfferTab = () => {
    if (requestStatus.pending) {
      return (
        <div
          className={classnames("gc-container", {
            "loading-shine": requestStatus.pending,
          })}
        />
      );
    }
    if (noOffers && requestedCapitalActive && !isOfferSelected) {
      return (
        <ErrorBoundary name="growth-capital-active-tab-available-offer-banner">
          <DismissableBanner
            id={"capitalRequested"}
            content={
              <BodySmallAlt className="text-center">
                {symbols.pizza} {text.t("offer_active_request")}
              </BodySmallAlt>
            }
            dismissable={false}
          />
        </ErrorBoundary>
      );
    }

    if (noOffers && !isRejected) {
      return (
        <ErrorBoundary name="growth-capital-offer-tab-no-offer-component">
          <StyledGcOffers>
            <GcContainer
              offers={{}}
              fixedBased={{}}
              showBlur
              blurContent={
                <div id="not_allowed_to_create_request">
                  {symbols.eyes}
                  <BodySmallAlt>Apply for capital to see a personalized offer.</BodySmallAlt>
                  <CapitalButton isNewCapital section="no-offers" openCapitalRequest={setGetCapitalModal} />
                </div>
              }
            />
          </StyledGcOffers>
        </ErrorBoundary>
      );
    }

    // Offer is active
    if (isOfferActive && !isOfferSelected) {
      return (
        <ErrorBoundary name="growth-capital-page-offer-tab-active-offer-component">
          <StyledGcOffers>
            <GcContainer
              offers={{}}
              fixedBased={{}}
              showBlur
              blurContent={
                <div>
                  {symbols.eyes}
                  {<BodySmallAlt>{text.t("active_offer", { onSetTab: () => setActiveTab(TABS[0].id) })}</BodySmallAlt>}
                </div>
              }
            />
          </StyledGcOffers>
        </ErrorBoundary>
      );
    }

    // Offer available
    if (availableOffer) {
      return (
        <ErrorBoundary name="growth-capital-offer-tab-available-offer-component">
          <StyledGcOffers>
            <OfferTabView setActiveTab={setActiveTab} />
          </StyledGcOffers>
        </ErrorBoundary>
      );
    }

    // offer selected
    if (isOfferSelected) {
      return (
        <ErrorBoundary name="growth-capital-offer-tab-selected-offer-component">
          <StyledGcOffers>
            <WaitingCard
              header={<TitleSmallPlus>{text.t("last_step_header")}</TitleSmallPlus>}
              body={
                <div className="waiting-body">
                  <ul className="list">
                    <li>
                      <BodyRegular16>{text.t("last_step_list_0")}</BodyRegular16>
                    </li>
                    <li>
                      <BodyRegular16>{text.t("last_step_list_3")}</BodyRegular16>
                    </li>
                  </ul>
                  <div className="blue-line" />
                  <div className="selected-details">
                    <TitleSmallPlus>
                      {symbols.moneyBag} {currencyFormat(selectedOffer.selectedAmount)} •
                    </TitleSmallPlus>{" "}
                    <BodyRegular16>{offerType[selectedOffer.type]}</BodyRegular16>
                    <BodySmall14>Confirmed on {formatReadableDate(isOfferSelected.updatedAt)}(UTC)</BodySmall14>
                  </div>
                </div>
              }
              footer={<BodyRegular16 className="confirmed-contact">{text.t("last_step_footer")}</BodyRegular16>}
              showBorder
            />
          </StyledGcOffers>
        </ErrorBoundary>
      );
    }
    // rejected but rejected card not dismissed
    if (isRejected && !rejectedCardDismissed) {
      return (
        <ErrorBoundary name="growth-capital-offer-tab-rejected-offer-component">
          <StyledGcOffers>
            <WaitingCard
              header={
                <div className="gc-offer-title">
                  <TitleSmallPlus>{text.t("rejected_header")}</TitleSmallPlus>
                  <CancelIcon className="cancel" onClick={onDismissRejectedCapitalRequest} />
                </div>
              }
              body={
                <div className="card-text">
                  <BodySmallAlt>{text.t("rejected_list_1")}</BodySmallAlt>
                  <br />
                  <BodySmallAlt>{text.t("rejected_list_2", { onTrackAdvance })}</BodySmallAlt>
                </div>
              }
              footer={<BodySmallAlt className="card-text">{text.t("rejected_footer")}</BodySmallAlt>}
            />
          </StyledGcOffers>
        </ErrorBoundary>
      );
    }

    // Offer expired
    if (offerExpired) {
      return offers.capitalOffers.map((offer) => {
        // return nothing if offer is dismissed
        if (offer.isDismissed) return <></>;

        const fixedBased = offer.options.find((opt) => opt.type === "FIXED_TERMS");
        return (
          <ErrorBoundary key={offer} name={`growth-capital-offer-${offer.id}`}>
            <StyledGcOffers>
              <GcContainer offer={offer} fixedBased={fixedBased} isFromDashboard />
            </StyledGcOffers>
          </ErrorBoundary>
        );
      });
    }

    return (
      <NoActiveCapital
        advanceSummaryStatus={advanceSummaryStatus}
        hasRequestedCapital={requestedCapitalActive}
        openCapitalRequest={setGetCapitalModal}
      />
    );
  };

  const getCurrentTabContents = () => {
    switch (activeTab) {
      case "activeTab":
        return ActiveTab();
      case "repaidTab":
        return RepaidTab();
      case "offerTab":
        return OfferTab();
      default:
        return null;
    }
  };

  return (
    <ErrorBoundary name="growth-capital-page-container">
      <StyledPageContainer>
        <PageHeader
          header="Growth capital"
          subheader="Unlock access to growth capital when you need it and manage it easily in one place."
        >
          <CapitalButton isNewCapital section="header" openCapitalRequest={setGetCapitalModal} />
        </PageHeader>

        <Tabs onTabChange={handleTabChange} activeTab={activeTab} items={TABS} loaded={true}>
          {getCurrentTabContents()}
        </Tabs>
      </StyledPageContainer>

      <FullScreenModal
        handleClick={() => setGetCapitalModal(false)}
        closeModal={() => setGetCapitalModal(false)}
        modalContent={capitalRequestModalContent}
        openModal={getCapitalModal}
        showArrowIcon={false}
      />
    </ErrorBoundary>
  );
};

const AdvanceTabList = ({ advances, advanceStatus, type, userFirstName, advancePlatforms }) => {
  return (
    <>
      {advanceStatus.completed &&
        advances.map((advance) => {
          const id = "id" + alphaTrim(advance.createdAt);
          return (
            <AdvanceCard
              advanceStatus={type}
              noHeader
              key={`key_atl_${advance.createdAt}`}
              id={id}
              advance={advance}
              userFirstName={userFirstName}
              expanded={window.location.hash === `#${id}`}
              advancePlatforms={advancePlatforms}
            />
          );
        })}
    </>
  );
};

const NoActiveCapital = ({ advanceSummaryStatus, hasRequestedCapital, openCapitalRequest }) => {
  return (
    advanceSummaryStatus.completed &&
    !hasRequestedCapital && (
      <StyledMessageCard>
        <BodySmallAlt>You have no capital just yet.</BodySmallAlt>
        <CapitalButton
          isNewCapital
          section="no-active"
          className="action-button"
          openCapitalRequest={openCapitalRequest}
        />
      </StyledMessageCard>
    )
  );
};

export default GrowthCapitalPage;
