import React, { Fragment } from "react";
import classnames from "classnames";

import { TitleSmallPlus, BodySmall14, BodyRegular16 } from "@styled";
import symbols from "@utils/symbols";
import { ReactComponent as ToolTipIcon } from "@assets/img/icons/common/tooltip.svg";
import { ReactComponent as CancelIcon } from "@assets/img/icons/common/cancel-icon.svg";

import { SanloTooltip } from "@components/common/tooltips/sanlo-tooltip/SanloTooltip";
import { currencyFormat } from "@utils/currency";
import { formatReadableDate } from "../../utils/format";
import { getMaxValue } from "./data";
import text from "@utils/text";
import { useDispatch } from "react-redux";
import actions from "@redux/actions";
import CapitalButton from "../growth-capital/CapitalButton";

const GcContainer = ({ offer, isFromDashboard, showBlur, blurContent = "" }) => {
  const dispatch = useDispatch();

  const isExpired = offer?.options?.find((opt) => opt.state === "EXPIRED");

  const onDelete = () => {
    dispatch(actions.offers.dismissCapitalOffer.request(offer.id));
  };

  return (
    <Fragment>
      <div className="gc-container">
        <div className={classnames("gc-offer", { "gc-offer-expired": isFromDashboard || isExpired, "blur": showBlur })}>
          <div className="gc-offer-title">
            <TitleSmallPlus> {isExpired ? text.t("capital_expired") : text.t("offer_ready")}</TitleSmallPlus>
            {isExpired ? (
              <CancelIcon className="cancel" onClick={onDelete} />
            ) : (
              !isFromDashboard && (
                <BodySmall14>
                  Expires on {formatReadableDate(offer?.expiresAt)} (UTC)
                  <ToolTipIcon id="expires-at" className="gc-offer-tooltip" />
                </BodySmall14>
              )
            )}
            {!isFromDashboard && (
              <SanloTooltip
                maxWidth={400}
                target="expires-at"
                text={isExpired ? text.t("offer_expires_in") : text.t("offer_will_expire")}
                placement="top"
              />
            )}
          </div>

          <div className="main-subtitle">
            <BodyRegular16>
              {isExpired ? text.t("offer_has_expired") : text.t("created_personalized_offer")}
            </BodyRegular16>
            <br />
            <BodyRegular16>
              {isExpired ? text.t("for_a_new_offer") : isFromDashboard ? "" : text.t("have_full_flexibility")}
              {!isFromDashboard && symbols.pointDown}
            </BodyRegular16>
          </div>

          {isExpired ? (
            <BodyRegular16 className="subtitle">{text.t("have_any_question")}</BodyRegular16>
          ) : isFromDashboard ? (
            ""
          ) : (
            <BodyRegular16 className="subtitle">{text.t("ques_how_it_works")}</BodyRegular16>
          )}
        </div>
        {(isFromDashboard || isExpired) && (
          <div className="gc-offer-bottom">
            <div className="gc-offer-bottom-left">
              <TitleSmallPlus className="gc-offer-bottom-left-title">{symbols.MoneyBag()} Your offer</TitleSmallPlus>
              <BodySmall14 className={classnames("gc-offer-bottom-left-time", { "is-expired": isExpired })}>
                Expire{isExpired ? "d" : "s"} on {formatReadableDate(offer?.expiresAt)} (UTC)
                <ToolTipIcon id="gc-offer-tooltip" className="gc-offer-tooltip" />
              </BodySmall14>
              <SanloTooltip
                maxWidth={400}
                target="gc-offer-tooltip"
                text={isExpired ? text.t("offer_expires_in") : text.t("offer_will_expire")}
                placement="top"
              />
            </div>

            <div className="gc-offer-bottom-right">
              <div className="gc-offer-bottom-right-item">
                <BodySmall14 className="gc-offer-bottom-left-time">
                  {text.t("max_amount")}
                  <ToolTipIcon id="max-amount" className="gc-offer-tooltip" />
                </BodySmall14>
                <TitleSmallPlus className="gc-offer-bottom-left-title">
                  {currencyFormat(getMaxValue(offer?.options, "maxAmountToSelect"))}
                </TitleSmallPlus>
                <SanloTooltip maxWidth={400} target="max-amount" text={text.t("max_amt_tooltip")} placement="top" />
              </div>

              {isExpired ? (
                <div className="gc-offer-bottom-right-item">
                  <CapitalButton className="tag-sanlo-reapply-for-capital" isReapply section="gc-offer-reapply" />
                </div>
              ) : isFromDashboard ? (
                <CapitalButton className="tag-sanlo-view-capital-offer" isFromDashboard section="gc-offer-dashboard" />
              ) : (
                ""
              )}
            </div>
          </div>
        )}
        {showBlur && blurContent && <div className="blurry-bg" />}
      </div>
      {showBlur && blurContent && <div className="blurry-content">{blurContent}</div>}
    </Fragment>
  );
};

export default GcContainer;
