export const getFormattedForm = (item = {}) => {
  const formatTime = (time) => (time ? new Date(time + "Z") : "");

  const {
    startTime,
    endTime,
    id = "",
    name = "",
    externalId = "",
    pricingTierLevelId = "",
    imageUrl: image = "",
    available = true,
    badge = null,
    category = null,
    description = "",
    recurrenceInterval = null,
    deepLinkCode = "",
    seriesId = null,
  } = item;

  const totalQuantity = item?.totalQuantity ?? "";
  const remainingQuantity = item?.remainingQuantity ?? "";
  const quantityPerPlayer = item?.quantityPerPlayer ?? "";
  const badgeId = badge?.id ?? "";
  const categoryId = category?.id ?? "";
  const isFormDisabled = !!seriesId;

  return {
    startTime: formatTime(startTime),
    endTime: formatTime(endTime),
    id,
    name,
    externalId,
    pricingTierLevelId,
    image,
    available,
    totalQuantity,
    remainingQuantity,
    quantityPerPlayer,
    recurrenceInterval,
    badgeId,
    categoryId,
    description,
    deepLinkCode,
    seriesId,
    isFormDisabled,
  };
};

export const createFormData = (data) => {
  const { startTime, endTime } = data;

  const newData = {
    ...data,
    startTime: startTime ? startTime.toISOString().slice(0, -1) : "",
    endTime: endTime ? endTime.toISOString().slice(0, -1) : "",
  };

  const filteredData = Object.keys(newData).reduce((acc, key) => {
    const value = newData[key];
    if (value !== null && value !== undefined && value !== "") {
      acc[key] = value;
    }
    return acc;
  }, {});
  const { image, ...rest } = filteredData;

  const formData = new FormData();
  if (image?.length) {
    formData.append("image", image[0]);
  }
  formData.append("item", new Blob([JSON.stringify({ ...rest })], { type: "application/json" }));

  return formData;
};
