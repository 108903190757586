/**
 * Calculate the elapsed hours between the current UTC time and a given UTC timestamp.
 *
 * @param {string} lastTimeUpdated - A UTC timestamp in the format 'YYYY-MM-DDTHH:mm:ss.ssssssZ'.
 * @returns {number} The number of elapsed hours from the current UTC time to the given timestamp.
 */
export function getAsOfHoursAgo(lastUpdate) {
  const lastUpdatedTime = new Date(lastUpdate);
  const currentTime = new Date();

  const elapsedTimeMillis = currentTime.getTime() - lastUpdatedTime.getTime();

  const elapsedHours = Math.abs(Math.round(elapsedTimeMillis / (1000 * 60 * 60)));
  return elapsedHours;
}

/**
 * Formats a given date as "Month, Day Ordinal" using the Intl.DateTimeFormat object.
 *
 * @param {Date} date - The date to be formatted.
 * @returns {string} The formatted date in the "Month, Day Ordinal" format (e.g., "August 4th").
 */
export function formatToMonthDay(date = new Date(), dateTimeFormatOptions = {}) {
  const options = { month: "long", day: "numeric", ...dateTimeFormatOptions };
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);

  const day = dateTimeFormatOptions?.timeZone === "UTC" ? date.getUTCDate() : date.getDate();
  const ordinalSuffix = getOrdinalSuffix(day);

  return formattedDate.replace(new RegExp(`\\b${day}\\b`), `${day}${ordinalSuffix}`);
}

/**
 * Formats a given date as "day/month" (e.g., "29/08") using Intl.DateTimeFormat.
 *
 * @param {Date} date - The date to be formatted.
 * @returns {string} The formatted date in the "day/month" format.
 */
export function formatTwoDigitMonthDay(date = new Date(), dateTimeFormatOptions = {}) {
  const day = new Intl.DateTimeFormat("en-US", { day: "2-digit", ...dateTimeFormatOptions }).format(date);
  const month = new Intl.DateTimeFormat("en-US", { month: "2-digit", ...dateTimeFormatOptions }).format(date);

  return `${month}/${day}`;
}

function getOrdinalSuffix(day) {
  if (day >= 11 && day <= 13) {
    return "th";
  }

  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}
