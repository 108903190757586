import styled from "styled-components";

const StyledOnboardingStep1 = styled.div`
  & {
    &.animated-container {
      animation: fadeIn 0.8s ease;
    }

    .os-funding-slider {
      margin-bottom: 32px;

      .os-funding-display {
        display: flex;
        justify-content: center;
        align-items: center;

        .os-current-cash {
          margin: 0 16px;
        }

        .os-previous-cash,
        .os-next-cash {
          min-width: 90px;
          position: relative;
          transition: opacity 0.75s linear;

          .fade-shadow {
            height: 32px;
            width: 100px;
            position: absolute;
            z-index: 10;
            top: 0;

            &.right-side {
              right: 0;
              background: linear-gradient(270deg, rgba(26, 26, 31, 0.5) 4.86%, rgba(26, 26, 31, 0) 100%);
            }

            &.left-side {
              left: 0;
              background: linear-gradient(90deg, rgba(26, 26, 31, 0.8) 4.86%, rgba(26, 26, 31, 0) 100%);
            }
          }
        }

        .os-gradient-cash {
          margin-bottom: 24px;
          text-align: center;
          background: #c56bf5;
          background: -webkit-linear-gradient(to right, #c56bf5 0%, #ff5e7d 100%);
          background: -moz-linear-gradient(to right, #c56bf5 0%, #ff5e7d 100%);
          background: linear-gradient(to right, #c56bf5 0%, #ff5e7d 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          display: block;
        }
      }

      .os-slider-x-axis {
        display: flex;
        justify-content: space-between;
        color: ${({ theme }) => theme.textGray};
      }
    }
  }
`;

export default StyledOnboardingStep1;
