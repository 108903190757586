import React from "react";
import { useSelector } from "react-redux";
import { financialsSelector } from "@redux/financials/financialsSlice";
import { FinancialCustomer } from "./FinancialCustomer";
import { getBlurredData, getBankingBlurData } from "@utils/blur";

export const FinancialIntelligenceCards = () => {
  const { getUpcomingCashIn } = useSelector(financialsSelector);

  const integrations = useSelector((state) => state.integrations);
  const platform = getUpcomingCashIn?.data?.platforms ?? [];

  const bankingBlurData = getBankingBlurData(integrations.plaid.data.institutions);
  const platformBlurData = getBlurredData(platform, integrations);

  return <FinancialCustomer blurState={{ banking: bankingBlurData, platform: platformBlurData }} />;
};
