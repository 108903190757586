import React, { useEffect, useRef } from "react";
import styled from "styled-components";

import { BodyRegularPlus } from "@styled";
import { StyledTab } from "./StyledTabs";

/**
 * @param items - Array of objects: {id, name, content}
 * @param activeTab
 * @param loaded
 * @param onTabChange
 * @param children
 */
export const Tabs = ({ items, activeTab, loaded = true, onTabChange, children }) => {
  const lineRef = useRef(null);

  const setLineStyle = (tab) => {
    const line = lineRef.current;
    line.style.left = tab.offsetLeft + "px";
    line.style.width = tab.firstChild.offsetWidth + "px";
  };

  const handleClick = (e, tabId) => {
    // setLineStyle(e.target);
    onTabChange(tabId);
  };

  useEffect(() => {
    setLineStyle(document.querySelector(`#tab_${activeTab}`));
  }, [activeTab]);

  return (
    <StyledContainer>
      <div className="tabs-container">
        {items.map((tab) => {
          return (
            <StyledTab
              id={`tab_${tab.id}`}
              key={`key_tab_${tab.id}`}
              className={`tab no-text-select ${tab.id === activeTab ? "active" : ""}`}
              active={tab.id === activeTab}
              onClick={(e) => handleClick(e, tab.id)}
            >
              <BodyRegularPlus>{tab.name}</BodyRegularPlus>
            </StyledTab>
          );
        })}
        <div ref={lineRef} className="line" />
      </div>
      {loaded && <div className="tabs-content">{children}</div>}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  .tabs-container {
    display: flex;
    align-items: center;
    position: relative;
  }

  .tabs-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 16px;
  }

  .line {
    position: absolute;
    bottom: 0;
    transition: left 0.5s ease-in-out, width 0.5s 0.1s;
    height: 2px;
    background-color: #ff5c79;
    left: 0;
    pointer-events: none;
  }
`;
