import { Heading, VStack } from "@chakra-ui/react";
import { AdNetworkSuggestion } from "../ad-network-suggestion/AdNetworkSuggestion";

export function AllNetworksConnected() {
  return (
    <VStack gap="8px" py="92px" px="24px" justifyContent={"center"}>
      <Heading size="large">All networks connected</Heading>
      <AdNetworkSuggestion />
    </VStack>
  );
}
