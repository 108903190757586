import styled from "styled-components";

export const StyledDragAndDropZone = styled.div`
  margin-top: 8px;
  border-radius: 8px;
  padding: 50px 24px 24px 24px;
  background-color: ${({ theme }) => theme.grayscale40};
  border: 2px dashed ${({ theme }) => theme.grayscale70};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .add-files-icon-cta {
    cursor: pointer;
  }

  .drag-files-text {
    max-width: 422px;
    text-align: center;
    margin-top: 32px;
    color: ${({ theme }) => theme.textSubtext};

    .highlighted-text {
      color: ${({ theme }) => theme.textEmphasis};
    }
  }

  &.uploading-files {
    border-color: ${({ theme }) => theme.colorGreen};
    background-color: ${({ theme }) => theme.colorGreen}14;

    > * {
      pointer-events: none;
    }

    .cloud-upload-icon {
      animation: bounce 1.5s linear infinite both;
    }

    @keyframes bounce {
      0% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-10px);
      }
    }

    .circle-progress-container {
      width: 50px;
      height: 50px;

      .circle-progress {
        stroke: ${({ theme }) => theme.colorGreen};
        fill: none;
      }

      .cancel-upload-icon {
        fill: ${({ theme }) => theme.textSeashellWhite};
        cursor: pointer;
        width: 50px;
        height: 50px;
      }

      .percentage-count {
        color: ${({ theme }) => theme.textSeashellWhite};
      }
    }
  }
`;
