import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import baseActions from "./actions";

import { getResponseStatus, getRequestStatus, getErrorStatus, setStatusDefaults } from "@utils/redux";

import ManualConnectionService from "./manualApi";

const initialState = {
  allConnectionsList: [],
  allConnectionsMap: {},
  emailMap: {},
  connectionStatusMap: {},
  requestStatus: {
    getAll: setStatusDefaults(),
    getPlatforms: setStatusDefaults(),
    createConnection: setStatusDefaults(),
    completeConnection: setStatusDefaults(),
    deleteConnection: setStatusDefaults(),
    getEmail: setStatusDefaults(),
  },
};

export const manualConnectionThunks = {
  getAll: createAsyncThunk("manualConnection/getAll", async (_, { getState, dispatch }) => {
    const res = await ManualConnectionService.getAll();

    // Start update of intergrations with manual connection data
    const { content = [] } = res.data;
    const integrationsList = content.map((manualConnection) => {
      const { integrationPlatform = {}, status = "" } = manualConnection;
      const { platformId = "", type = "" } = integrationPlatform;

      return {
        name: platformId,
        platformId,
        status,
        type,
        connected: true,
      };
    });
    dispatch(baseActions.getIntegrationsConnections.response(integrationsList));
    // End manual connection weirdness

    return res.data;
  }),

  getPlatforms: createAsyncThunk("manualConnection/getPlatforms", async () => {
    const res = await ManualConnectionService.getPlatforms();
    return res.data;
  }),

  createConnection: createAsyncThunk("manualConnection/create", async (platformId = "") => {
    const res = await ManualConnectionService.create(platformId);
    return res.data;
  }),

  completeConnection: createAsyncThunk("manualConnection/complete", async (platformId = "", { getState, dispatch }) => {
    const res = await ManualConnectionService.complete(platformId);
    dispatch(baseActions.getIntegrationsConnections.request());
    return res.data;
  }),

  deleteConnection: createAsyncThunk("manualConnection/delete", async (platformId = "", { getState, dispatch }) => {
    const res = await ManualConnectionService.delete(platformId);
    dispatch(manualConnectionThunks.getAll());
    return res.data;
  }),

  getEmail: createAsyncThunk("manualConnection/getEmail", async (platformId = "") => {
    const res = await ManualConnectionService.getEmail(platformId);
    return res.data;
  }),
};

export const manualConnectionSlice = createSlice({
  name: "manualConnection",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(manualConnectionThunks.getAll.fulfilled, (state, action) => {
        // Content is paginated, keep in mind when manual connections list
        // grows that page size might need to be updated.
        const { content = [] } = action.payload;
        state.allConnectionsList = content;
        const connectionsMap = {};
        state.allConnectionsMap = content.forEach((manualConnection) => {
          const { integrationPlatform = {} } = manualConnection;
          const { platformId = "" } = integrationPlatform;
          connectionsMap[platformId] = manualConnection;
        });
        state.allConnectionsMap = connectionsMap;
      })
      .addCase(manualConnectionThunks.getPlatforms.fulfilled, (state, action) => {
        // console.log(action);
      })

      /*********************************************************************************
       * CREATE CONNECTION
       *********************************************************************************/
      .addCase(manualConnectionThunks.createConnection.pending, (state, action) => {
        state.requestStatus.createConnection = getRequestStatus();
      })
      .addCase(manualConnectionThunks.createConnection.fulfilled, (state, action) => {
        state.requestStatus.createConnection = getResponseStatus();
        const platformId = action.meta.arg;
        state.allConnectionsMap = {
          ...state.allConnectionsMap,
          [platformId]: action.payload,
        };
        // On create connection we can put the platform Id in the status map to start watching
        state.connectionStatusMap[platformId] = "idle";
      })
      .addCase(manualConnectionThunks.createConnection.rejected, (state, action) => {
        state.requestStatus.createConnection = getErrorStatus();
        const platformId = action.meta.arg;
        state.connectionStatusMap[platformId] = "error";
      })

      /*********************************************************************************
       * COMPLETE CONNECTION
       *********************************************************************************/
      .addCase(manualConnectionThunks.completeConnection.pending, (state, action) => {
        state.requestStatus.completeConnection = getRequestStatus();
      })
      .addCase(manualConnectionThunks.completeConnection.fulfilled, (state, action) => {
        state.requestStatus.completeConnection = getResponseStatus();
        const platformId = action.meta.arg;
        state.connectionStatusMap[platformId] = "complete";
      })
      .addCase(manualConnectionThunks.completeConnection.rejected, (state, action) => {
        state.requestStatus.completeConnection = getErrorStatus();
        const platformId = action.meta.arg;
        state.connectionStatusMap[platformId] = "error";
      })

      /*********************************************************************************
       * DELETE CONNECTION
       *********************************************************************************/
      .addCase(manualConnectionThunks.deleteConnection.pending, (state, action) => {
        state.requestStatus.deleteConnection = getRequestStatus();
      })
      .addCase(manualConnectionThunks.deleteConnection.fulfilled, (state, action) => {
        state.requestStatus.deleteConnection = getResponseStatus();
        const platformId = action.meta.arg;
        state.connectionStatusMap[platformId] = "idle";
      })
      .addCase(manualConnectionThunks.deleteConnection.rejected, (state, action) => {
        state.requestStatus.deleteConnection = getErrorStatus();
        const platformId = action.meta.arg;
        state.connectionStatusMap[platformId] = "idle";
      })

      /*********************************************************************************
       * EMAIL
       *********************************************************************************/
      .addCase(manualConnectionThunks.getEmail.fulfilled, (state, action) => {
        const platformId = action.meta.arg;
        state.emailMap[platformId] = action.payload;
        state.connectionStatusMap[platformId] = "idle";
      })
      .addCase(manualConnectionThunks.getEmail.rejected, (state, action) => {
        const platformId = action.meta.arg;
        state.connectionStatusMap[platformId] = "error";
      });
  },
});

export const manualConnectionSelector = (state) => state.integrations.manualConnection;

export default manualConnectionSlice.reducer;
