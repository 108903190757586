import { useState } from "react";

export function useLocalStorage(key, defaultValue) {
  if (!key) {
    throw new Error("You must pass a key to useLocalStorage");
  }

  // This immediate set helps appease the Cypress gods
  if (window?.Cypress && defaultValue)
    window.localStorage.setItem(key, typeof defaultValue === "object" ? JSON.stringify(defaultValue) : defaultValue);

  const [state, setState] = useState(() => {
    let value;
    try {
      value = JSON.parse(window.localStorage.getItem(key));
      if (!value) {
        value = defaultValue;
      }
    } catch (e) {
      value = defaultValue;
    }
    return value;
  });

  function setValue(value) {
    try {
      // Allow value to be a function to match useState API
      const valueToStore = typeof value === "object" ? JSON.stringify(value) : value;
      // Save state
      setState(value);
      // Save to localStorage
      window.localStorage.setItem(key, valueToStore);
    } catch (error) {
      // Catch errors saving value to localStorage
      console.error("Error saving value to localStorage:", error);
    }
  }

  return [state, setValue];
}
