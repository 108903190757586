import styled from "styled-components";

export const StyledPageContainer = styled.div`
  --card-width: 360px;
  --card-height: 144px;
  --card-spacing: 32px;
  --card-container-width: calc((var(--card-width) * 2) + var(--card-spacing));

  width: 1094px;
  margin: 2.5rem auto;

  ${({ isCardContainer }) =>
    isCardContainer &&
    `
    max-width: var(--card-container-width);;
  `};
`;
