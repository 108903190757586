import { numberInputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(numberInputAnatomy.keys);

const baseStyle = definePartsStyle({
  field: {
    borderRadius: "10px",
    padding: "12px",
    fontFamily: "body",
    color: "textWhite.400",
  },
});

const variants = {
  default: definePartsStyle(() => ({
    field: {
      background: "dark.400",
      border: "2px solid transparent",
      fontSize: "sm",
      _placeholder: {
        fontSize: "sm",
        color: "textSubtext.400",
      },
      _hover: {
        border: "2px solid",
        borderColor: "dark.100",
      },
      _focus: {
        border: "2px solid",
        borderColor: "brandRed.400",
      },
      _invalid: {
        bg: "brandRedAlpha.400",
        color: "brandRed.400",
      },
    },
  })),
  unstyled: definePartsStyle(() => ({
    field: {
      background: "transparent",
      border: "none",
      color: "textDefault.400",
      fontSize: "sm",
      padding: 0,
      _placeholder: {
        fontSize: "sm",
        color: "textSubtext.400",
      },
    },
  })),
};

export const NumberInput = defineMultiStyleConfig({
  baseStyle,
  variants,
  defaultProps: {
    variant: "default",
  },
});
