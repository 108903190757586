import { authMethodsSelector, authMethodsThunks } from "@redux/webshops/webshop/settings/auth-methods/authMethodsSlice";
import { generateFormWithFilePayloads } from "@src/utils/webshop";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export function useHandleAuthMethod() {
  const { authProvidersMap } = useSelector(authMethodsSelector);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { id: webshopId } = useParams();

  async function handleAuthMethod(data) {
    setIsLoading(true);

    const instructionImageUrl = data.instructionImageUrl instanceof File && data.instructionImageUrl;
    delete data.instructionImageUrl;
    const { data: authMethodData } = authProvidersMap[data?.authMethod];
    if (authMethodData) {
      const { instructionImageUrl: authMethodImage, ...restAuthMethodData } = authMethodData;
      const { id, ...rest } = data;
      //update the authMethod
      await dispatch(
        authMethodsThunks.updateAuthMethod({ data: { ...restAuthMethodData, ...rest }, webshopId }),
      ).unwrap();
      // first we delete the image file from the server and the store
      const shouldRemoveFile = authMethodImage && instructionImageUrl;
      if (shouldRemoveFile) {
        await dispatch(authMethodsThunks.deleteAuthMethodFile({ webshopId, authMethod: data })).unwrap();
      }

      const { requestForm } = await generateFormWithFilePayloads({
        filePropertyName: "file",
        file: instructionImageUrl,
      });

      // then we add the new image file if exists
      if (instructionImageUrl) {
        await dispatch(
          authMethodsThunks.updateAuthMethodFile({ data: requestForm, webshopId, authMethodId: data.id }),
        ).unwrap();
      }
    } else {
      // add
      const { id, ...rest } = data;
      const { requestForm } = await generateFormWithFilePayloads({
        dataPropertyName: "data",
        data: rest,
        filePropertyName: "file",
        file: instructionImageUrl,
      });

      await dispatch(authMethodsThunks.addAuthMethod({ data: requestForm, webshopId })).unwrap();
    }

    setIsLoading(false);
  }

  return { handleAuthMethod, isLoading };
}
