import { Button, Heading, HStack, Icon, Input, List, ListItem, Text, VStack } from "@chakra-ui/react";
import { ReactComponent as PlusIcon } from "@assets/img/icons/common/plus.svg";
import { ReactComponent as TrashIcon } from "@assets/img/icons/common/trash.svg";
import { useEffect, useImperativeHandle, useRef, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  recentlyAddedCustomFontsSelector,
  removeRecentlyAddedCustomFont,
  setFontToRemove,
  setSelectedCustomFont,
  typographySelector,
  uploadedCustomFontsSelector,
} from "@redux/webshops/builder/typographySlice";
import { useUploadCustomFont } from "../../hooks/useUploadCustomFont";
import { RemoveFontConfirmation } from "./RemoveFontConfirmation";
import { AnimatePresence, motion } from "framer-motion";
import { slideInFromLeft } from "@src/utils/motion-variants";

export function FontUpload({ handleNextStep, onClose }) {
  const dispatch = useDispatch();
  const fileRef = useRef(null);
  const [isRemovingFont, setIsRemovingFont] = useState(false);
  const { register, handleSubmit, control, resetField } = useForm();
  const { ref, ...fileRegisterRest } = register("file");
  const file = useWatch({ control, name: "file" });
  const recentlyAddedCustomFonts = useSelector(recentlyAddedCustomFontsSelector);
  const uploadedCustomFonts = useSelector(uploadedCustomFontsSelector);
  const { getFontName, submitFont, removeFont } = useUploadCustomFont();
  const { addCustomFontBulk } = useSelector(typographySelector);

  useImperativeHandle(ref, () => fileRef.current);

  useEffect(() => {
    async function retrieveFontMetadata(file) {
      const fontName = await getFontName(file);
      dispatch(setSelectedCustomFont({ file: file[0], fontName }));
      handleNextStep();
    }
    if (file) {
      retrieveFontMetadata(file);
    }
  }, [file, handleNextStep, dispatch, resetField, getFontName]);

  async function onSubmit() {
    const { failures } = await submitFont();
    if (!failures?.length) {
      onClose();
    }
  }

  async function handleRemoveFont() {
    await removeFont();
    setIsRemovingFont(false);
  }

  return (
    <AnimatePresence mode="popLayout" initial={false}>
      {isRemovingFont ? (
        <motion.div
          style={{ flex: "1 0 448px" }}
          variants={slideInFromLeft}
          transition={{ x: { type: "spring", stiffness: 300, damping: 30 }, opacity: { duration: 0.5 } }}
          initial="enter"
          animate="center"
          exit="exit"
          key={`font_upload_removing_font`}
        >
          <RemoveFontConfirmation removeFont={handleRemoveFont} onClose={() => setIsRemovingFont(false)} />
        </motion.div>
      ) : (
        <motion.div
          style={{ flex: "1 0 448px" }}
          variants={slideInFromLeft}
          transition={{ x: { type: "spring", stiffness: 300, damping: 30 }, opacity: { duration: 0.3 } }}
          initial="enter"
          animate="center"
          exit="exit"
          key={`font_upload`}
        >
          <VStack alignItems={"stretch"} as="form" onSubmit={handleSubmit(onSubmit)} h="full">
            <Heading size="regular" fontWeight={600}>
              Add or Edit Fonts
            </Heading>
            <Text mt="8px">
              Fonts can have an impact on your page speed. We recommend using 2 fonts max, one for headlines and another
              for paragraphs.
            </Text>
            <Input
              {...fileRegisterRest}
              type="file"
              accept=".ttf,.otf,.woff,.woff2"
              display={"none"}
              ref={fileRef}
              data-testid="font-upload-input"
            />
            <Button
              width={"fit-content"}
              h="32px"
              mt="32px"
              mb="24px"
              rightIcon={<Icon as={PlusIcon} />}
              onClick={() => fileRef.current.click()}
            >
              Add More Fonts
            </Button>
            {recentlyAddedCustomFonts.length ? (
              <VStack alignItems={"stretch"} pb="16px">
                <Text size="small" color="textWhite.400" fontWeight={600}>
                  Fonts Added:
                </Text>
                <List borderColor={"dark.300"} maxH="144px" flexShrink={0} overflow={"scroll"}>
                  {recentlyAddedCustomFonts.map((font) => (
                    <ListItem key={font.fontName} px="24px" py="16px">
                      <HStack gap="32px">
                        <Icon
                          w={"16px"}
                          h={"16px"}
                          cursor={"pointer"}
                          as={TrashIcon}
                          _hover={{ color: "brandRed.400" }}
                          _active={{ color: "brandRed.400" }}
                          aria-label={`Remove ${font.fontName} font`}
                          onClick={() => dispatch(removeRecentlyAddedCustomFont(font.fontName))}
                        />
                        <Text size="small" color="brandSkyBlue.400">
                          {font.fontName}
                        </Text>
                      </HStack>
                    </ListItem>
                  ))}
                </List>
              </VStack>
            ) : null}
            {uploadedCustomFonts.length ? (
              <VStack alignItems={"stretch"} pb="16px">
                <Text size="small" color="textWhite.400" fontWeight={600}>
                  Previous Fonts:
                </Text>
                <List borderColor={"dark.300"} maxH="144px" flexShrink={0} overflow={"scroll"}>
                  {uploadedCustomFonts.map((font) => (
                    <ListItem key={font.name} px="24px" py="16px">
                      <HStack gap="32px">
                        <Icon
                          w={"16px"}
                          h={"16px"}
                          cursor={"pointer"}
                          as={TrashIcon}
                          aria-label={`Remove ${font.name} font`}
                          _hover={{ color: "brandRed.400" }}
                          _active={{ color: "brandRed.400" }}
                          onClick={() => {
                            dispatch(setFontToRemove(font.id));
                            setIsRemovingFont(true);
                          }}
                        />
                        <Text size="small" color="brandSkyBlue.400">
                          {font.name}
                        </Text>
                      </HStack>
                    </ListItem>
                  ))}
                </List>
              </VStack>
            ) : null}
            {addCustomFontBulk.error?.length ? (
              <VStack gap={"4px"} mb={"8px"}>
                {addCustomFontBulk.error.map((error) => (
                  <Text key={error.name} color="brandRed.400">
                    There was an error while adding the {error.name} font. Please check if it has been uploaded
                    previously, or try again.
                  </Text>
                ))}
              </VStack>
            ) : null}
            <HStack mt="auto" justifyContent={"space-between"}>
              <Button onClick={onClose} h="32px" variant="primary">
                Cancel
              </Button>
              <Button
                type="submit"
                h="32px"
                isLoading={addCustomFontBulk.requestStatus.pending}
                isDisabled={!recentlyAddedCustomFonts.length}
              >
                Save
              </Button>
            </HStack>
          </VStack>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
