import HTTP from "@redux/http";
const BASE = `/api/integrations/connections`;

class IntegrationsConnectionsService {
  getIntegrationsConnections() {
    return HTTP.req.get(`${BASE}/status`);
  }
}

export default new IntegrationsConnectionsService();
