import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { adNetworksThunks } from "@redux/integrations/ad-networks/adNetworksSlice";
import { OAUTH_FLOW_ARCHETYPE_ID } from "@src/consts/ad-networks";

export const useParamsForOAuthConnection = () => {
  const dispatch = useDispatch();
  const location = window.location;

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const state = urlParams.get("state");
    const code = urlParams.get("code");
    const fromPartnerSite = urlParams.get("fromPartnerSite");
    const archetypeId = localStorage.getItem(OAUTH_FLOW_ARCHETYPE_ID);

    const callCallback =
      !fromPartnerSite &&
      code &&
      state &&
      archetypeId &&
      (location.pathname === "/" || location.pathname === "/integrations");

    if (callCallback) {
      dispatch(adNetworksThunks.credentialsCallback({ state, code, archetypeId }));
    }
    localStorage.removeItem(OAUTH_FLOW_ARCHETYPE_ID);
  }, [dispatch, location]);
};
