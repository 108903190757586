import React from "react";
import {
  Box,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Text,
  VStack,
} from "@chakra-ui/react";

import { BUILDER_SELECTABLE_SECTION_TYPES } from "@src/consts/webshops/builder";

export const SectionSelectModal = ({ isOpen = false, onClose = () => {}, onSelect = () => {}, ...rest }) => {
  return (
    <Modal size={"md"} variant={"confirmation"} onClose={onClose} isOpen={isOpen} isCentered {...rest}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Select a section type</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={"16px"}>
            {BUILDER_SELECTABLE_SECTION_TYPES.map((section) => {
              return (
                <Box
                  key={`key_section_${section.type}`}
                  w={"full"}
                  onClick={() => {
                    onSelect(section);
                    onClose();
                  }}
                  cursor={"pointer"}
                  p={"16px"}
                  borderRadius={"md"}
                  border={"1px solid rgba(255, 255, 255, 0.1)"}
                >
                  <Text fontSize={"lg"} fontWeight={600} color={"white"}>
                    {section.label}
                  </Text>
                  <Text fontSize={"sm"} color={"gray.500"} mt={"8px"}>
                    {section.description}
                  </Text>
                </Box>
              );
            })}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
