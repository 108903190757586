import { Input } from "@chakra-ui/react";
import { runIfFn } from "@src/utils/run-if-function/runIfFunction";
import { useState } from "react";

import PropTypes from "prop-types";

// Very simple input to handle file drops
export const FileDrop = (props) => {
  const { onChange, inputRef, children, acceptedFileTypes, ...rest } = props;
  const [isDragOver, setIsDragOver] = useState(false);

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragOver(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { files } = e.dataTransfer;
    // Only allows 1 file at a time
    if (files?.length > 1) return;
    handleChange(e);
  };

  const handleChange = (e) => {
    if (!onChange) {
      throw new Error("You need to provide an onChange function to handle the file upload");
    }

    onChange(e);
  };

  return (
    <>
      {runIfFn(children, { isDragOver })}
      <Input
        type="file"
        accept={acceptedFileTypes || "*"}
        pos={"absolute"}
        inset={0}
        h={"auto"}
        opacity={0}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onChange={handleChange}
        ref={inputRef}
        {...rest}
      />
    </>
  );
};

FileDrop.propTypes = {
  onChange: PropTypes.func,
  inputRef: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  acceptedFileTypes: PropTypes.string,
};
