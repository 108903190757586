import React from "react";
import Lottie from "lottie-react";
import loader from "@assets/img/icons/common/loader.json";
import grayLoader from "@assets/img/icons/common/gray-loader.json";

const CustomLoader = ({ color }) => {
  const getAnimation = () => {
    switch (color) {
      case "gray":
        return grayLoader;
      default:
        return loader;
    }
  };

  return <Lottie className="custom-loader" animationData={getAnimation()} />;
};

export default CustomLoader;
