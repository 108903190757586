import React, { useState } from "react";
import { useSelector } from "react-redux";

import StyledOnboardingStep3 from "./StyledOnboardingStep3";
import PlatformAccordion from "@components/onboarding/platform-accordion/PlatformAccordion";
import EncourageMessage from "@components/common/banners/EncourageMessage";
import FullScreenModal from "@components/common/modals/fullscreen-modal/FullScreenModal";

import symbols from "@utils/symbols";
import useIntegrationsStatus from "@utils/custom-hooks/useIntegrationsStatus";
import { useFlags } from "launchdarkly-react-client-sdk";

import { PRIMARY_FLOW_ACCORDION_DATA } from "./utils";
import text from "@utils/text";
import { trackGTMEvent, ONBOARDING_ACTIONS } from "@utils/gtm";

const OnboardingStep3 = ({
  fromDashboard = false,
  connectedIntegrations = {},
  expandedAccordions = [],
  setExpandedAccordion = () => {},
}) => {
  const { clientSuperpowersOnboardingFlowEnabled = false } = useFlags();
  const integrations = useSelector((state) => state.integrations);
  const suggestedPlatforms = useSelector((state) => state.session.company.onboarding.suggestedOnboardingIntegrations);
  const integrationsStatus = useIntegrationsStatus();

  const filterPlatforms = clientSuperpowersOnboardingFlowEnabled ? suggestedPlatforms : null;

  // Connection modal
  const [selectedPlatform, setSelectedPlatform] = useState(integrations.appsflyer);
  const [selectedPlatformModalOpen, setSelectedPlatformModalOpen] = useState(false);

  const handleSelectPlatform = (platform) => {
    setSelectedPlatform(platform);
    setSelectedPlatformModalOpen(true);
  };

  const handleToggle = (selected, isExpanded) => {
    setExpandedAccordion(selected, isExpanded);
    trackGTMEvent({
      event: ONBOARDING_ACTIONS.CONNECT_SOURCE_EXPANDED,
      name: selected,
      expanded: !isExpanded,
    });

    if (window.location.pathname.includes(selected)) {
      const newUrl = window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/"));
      window.history.replaceState(null, "Sanlo", newUrl);
    }
  };

  const renderEncourageMessage = () => {
    if (!fromDashboard || connectedIntegrations.all) return null;

    const message = {
      emoji: `${symbols.lightBulb}`,
      content: "",
      color: "ElectricBlue",
    };

    if (connectedIntegrations.oneRequired && !connectedIntegrations.allRequired) {
      message.emoji = `${symbols.flyingStar}`;
      message.content = text.t("cism_keep_up");
    } else if (connectedIntegrations.allRequired) {
      message.emoji = `${symbols.clappingHands}`;
      message.color = "SkyBlue";
      message.content = text.t("cism_well_done");
    }

    // Render nothing if there is no message content
    if (!message.content) return null;

    return <EncourageMessage emoji={message.emoji} color={message.color} description={message.content} />;
  };

  return (
    <StyledOnboardingStep3 className="animated-container">
      {renderEncourageMessage()}

      {PRIMARY_FLOW_ACCORDION_DATA({ fromDashboard, filterPlatforms }).map((accordionSection, index) => {
        const { id, title, content, suggestionType } = accordionSection;
        const isRedirect = window.location.pathname.includes(id);

        return (
          <div key={`platform-accordion-${id}-${index}`} className="platform-accordion-wrapper">
            <PlatformAccordion
              fromDashboard={fromDashboard}
              platformStatus={integrationsStatus[id]}
              onAccordionToggle={(isExpanded) => handleToggle(id, isExpanded)}
              expanded={expandedAccordions.includes(id) || isRedirect}
              title={title}
              category={id}
              content={content}
              suggestionType={suggestionType}
              onSelectPlatform={handleSelectPlatform}
            />
          </div>
        );
      })}

      <FullScreenModal
        saveProgress={selectedPlatform.canSaveProgress}
        modalContent={selectedPlatform}
        openModal={selectedPlatformModalOpen}
        closeModal={() => setSelectedPlatformModalOpen(false)}
      />
    </StyledOnboardingStep3>
  );
};

export default OnboardingStep3;
