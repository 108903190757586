import React from "react";
import styled from "styled-components";

import { BodyRegularPlus } from "@styled";

export const StyledStepTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  p {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 21px;
    color: #faf8f8;
    margin: 8px 16px 8px auto;
    background: #414145;
    border: 1.5px solid rgba(255, 255, 255, 0.5);
  }
`;

export const StepTitle = ({ stepNumber = 1, children }) => {
  return (
    <StyledStepTitle>
      <p>{stepNumber}</p>
      <BodyRegularPlus>{children}</BodyRegularPlus>
    </StyledStepTitle>
  );
};
