import React from "react";
import { Flex } from "@chakra-ui/react";

export const BarContainer = ({
  index = 0,
  chartId = "",
  columnWidth = "",
  bothFlows = false,
  withNegYAxis = false,
  onMouseEnter,
  onMouseLeave,
  children,
}) => {
  return (
    <Flex direction={"column"} key={`key-bfrag-${chartId}-${index}`} w={columnWidth} justifyContent={"space-between"}>
      <Flex
        data-test-id={`${chartId}-bar-wrapper`}
        id={`${chartId}-${index}`}
        key={`key-bwrap-${chartId}-${index}`}
        position={"relative"}
        height={"100%"}
        minWidth={"24px"}
        justifyContent={"center"}
        alignItems={"flex-end"}
      >
        {/* BarPair - div that "groups" the two bars, for tooltip handling and hover styles
      TODO: Leaving as reference, is it better to just use the wrapper even though its the full height
      This makes it easier to see tooltips for the smaller items  */}
        {/* <Box
        id={`target-${chartId}-${index}`}
        data-test-id={`${chartId}-bar-pair`}
        position={"absolute"}
        height={`${barContainer.height}%`}
        minHeight={barContainer.minHeight}
        top={barContainer.top}
        zIndex={"4"}
        width={barContainer.width}
        onMouseEnter={() => addBarTracking(index)}
        onMouseLeave={() => removeBarTracking()}
        />
          {TooltipContent && (
            <SanloTooltip
              width={tooltipWidth}
              target={`target-${chartId}-${index}`}
              placement={placementTooltip}
              id={`bar-tooltip-${index}`}
            >
            <TooltipContent
              item={item}
              inProgressMonth={inProgressMonth}
              inflowColor={inflowColor}
              outflowColor={outflowColor}
            />
          </SanloTooltip>
        s</Box>
      )}  */}
        <Flex
          id={bothFlows ? `target-${chartId}-${index}` : ""}
          data-test-id={`${chartId}-bar-pair`}
          position={withNegYAxis ? "absolute" : "relative"}
          top={withNegYAxis ? "50%" : ""}
          left={withNegYAxis ? "50%" : ""}
          height={"100%"}
          transform={withNegYAxis ? "translate(-50%, -50%)" : ""}
          flexDirection={withNegYAxis ? "column" : "row"}
          justifyContent={"center"}
          width={withNegYAxis ? "24px" : "48px"}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {children}
        </Flex>
      </Flex>
    </Flex>
  );
};
