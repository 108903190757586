import styled from "styled-components";

const StyledAuthenticationContainer = styled.div`
  position: absolute;
  /* overflow-x: hidden; */
  /* overflow-y: auto; */
  overflow: hidden;
  width: 100%;
  height: 100vh;

  .authentication-container-header {
    position: relative;
    height: 5rem;
    width: 100%;

    .main-sanlo-logo {
      position: absolute;
      top: 32px;
      left: 64px;
      .sanlo-logo {
        width: 100px;
      }
    }

    .partner-site-link {
      z-index: 501;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #c5c5c5;
      position: absolute;
      top: 32px;
      left: 64px;
      text-decoration: none;
      &.hide {
        visibility: hidden;
      }
    }

    .branding-container {
      position: absolute;
      top: 0;
      z-index: 500;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .partner-branding-img {
        height: 2.25rem;
      }

      .branding-divider {
        color: white;
        font-size: 36px;
        font-weight: 100;
        margin: 0 1rem;
      }

      .sanlo-branding-img {
        height: 1.5rem;
      }
    }

    .logout-button {
      position: absolute;
      top: 32px;
      right: 64px;
      z-index: 501;
      cursor: pointer;
      color: ${({ theme }) => theme.textSeashellWhite};
      font-weight: 500;
      transition: opacity 0.2s linear;
      &:hover {
        opacity: 0.5;
      }
    }

    @keyframes partnerLogoAnim {
      0% {
        opacity: 0;
        margin-right: 5rem;
      }
      33% {
        opacity: 1;
        margin-right: 0;
      }
      66% {
      }
      100% {
      }
    }

    @keyframes dividerAnim {
      0% {
        opacity: 0;
      }
      33% {
        opacity: 1;
      }
      66% {
      }
      100% {
      }
    }

    @keyframes sanloLogoAnim {
      0% {
        opacity: 0;
        margin-left: 5rem;
      }
      33% {
        opacity: 1;
        margin-left: 0;
      }
      66% {
      }
      100% {
      }
    }

    @keyframes headerAnim {
      0% {
        transform: translateY(-50%);
        top: 50%;
      }
      33% {
      }
      66% {
        transform: translateY(-50%);
        top: 50%;
      }
      100% {
        transform: translateY(0%);
        top: 0;
      }
    }

    &.partner-intro {
      animation: headerAnim 1.5s ease 0s forwards;

      .partner-branding-img {
        animation: partnerLogoAnim 1.5s ease 0s forwards;
      }
      .branding-divider {
        animation: dividerAnim 1.5s ease 0s forwards;
      }
      .sanlo-branding-img {
        animation: sanloLogoAnim 1.5s ease 0s forwards;
      }
    }
  }

  #leftGrad {
    z-index: 1;
    position: absolute;
    width: 752px;
    height: 752px;
    left: -400px;
    top: 70px;
    background: linear-gradient(291.19deg, rgba(191, 107, 255, 0.5) 10.65%, rgba(255, 92, 121, 0.5) 89.36%);
    filter: blur(143px);
    -webkit-filter: blur(143px);
    transform: translateZ(0);
    border-radius: 50%;

    &.error {
      background: linear-gradient(291.19deg, rgba(255, 66, 100, 0.5) 54.11%, rgba(191, 107, 255, 0.5) 89.36%);
    }
  }

  #rightGrad {
    z-index: 2;
    position: absolute;
    width: 752px;
    height: 752px;
    right: -200px;
    top: 326px;
    background: linear-gradient(291.19deg, rgba(45, 249, 176, 0.5) 10.65%, rgba(191, 107, 255, 0.5) 89.36%);
    filter: blur(143px);
    -webkit-filter: blur(143px);
    transform: translateZ(0);
    border-radius: 50%;

    &.error {
      background: linear-gradient(291.19deg, rgba(130, 128, 255, 0.5) 10.65%, rgba(255, 66, 100, 0.5) 39.35%);
    }
  }

  .authentication-children {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    width: 100%;

    &.hide {
      visibility: hidden;
    }
  }
`;

export default StyledAuthenticationContainer;
