import styled from "styled-components";

export const StyledTooltipStack = styled.div`
  display: flex;
  flex-direction: column;
  width: 324px;
  color: ${({ theme }) => theme.textSeashellWhite};

  &.tt-blank-day {
    width: 187px;
  }

  .tt-others-wrapper {
    position: relative;
    margin-top: 0.5rem;

    .tt-row-title {
      color: ${({ theme }) => theme.colorPink};
    }

    &.selected::before {
      content: "";
      width: 0.25rem;
      height: 1rem;
      background-color: ${({ theme }) => theme.colorPink};
      position: absolute;
      top: 0.25rem;
      left: -0.75rem;
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }
  }

  .tt-game-wrapper {
    margin-top: 10px;
    justify-content: space-between;
    display: flex;
    position: relative;

    .tt-game-name {
      position: relative;
      white-space: nowrap;
    }

    &.color .tt-game-name {
      color: var(--game-color);
    }

    &.selected::before {
      content: "";
      width: 0.25rem;
      height: 1rem;
      background-color: var(--game-color);
      position: absolute;
      top: 0;
      left: -0.75rem;
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }

    .tt-game-earning {
      color: ${({ theme }) => theme.textDefault};
      padding-left: 1rem;
    }
  }

  .tt-total-earned-wrapper {
    margin-bottom: 0.5rem;
    justify-content: space-between;
    display: flex;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid ${({ theme }) => theme.grayscale50};
  }
`;
