import styled from "styled-components";

export const StyledGcOffers = styled.div`

    .gc-container {
      margin-top: 24px;
      background-color: ${({ theme }) => theme.grayscale20};
      border-radius: 12px;
      padding: 20px 24px;
      position: relative;

      .gc-offer-expired {
        margin-bottom: 20px;
        border-bottom: 1px solid ${({ theme }) => theme.grayscale50};
        padding: 16px 0 20px 0;
      }
      .blur {
        filter: blur(12px);
      }
      .gc-offer {

        &-title {
          margin-bottom: 16px;
          display: flex;
          justify-content: space-between;

          .cancel {
            cursor: pointer;
          }

          > div {
            color: ${({ theme }) => theme.textDefault};
          }
        }

        .main-subtitle {
          color: ${({ theme }) => theme.textDefault};
          margin-bottom: 16px;

        }
        .subtitle {
          color: ${({ theme }) => theme.textDefault};
        }

        &-tooltip {
            margin-left: 0.5rem;
            fill: ${({ theme }) => theme.grayFontColor};
        }
      }
      .card-text {
        color: ${({ theme }) => theme.textDefault};
        margin: 5px 0;
      }
      .learn-more {
        color: ${({ theme }) => theme.primaryColor};
        text-decoration: none;
        cursor: pointer;
      }

      .blurry-bg {
        position: absolute;
        inset: 0;
        background-color: rgba(33, 33, 38, 0.5);
        padding: 20px 24px;
        filter: blur(12px);
        border-radius: 12px;
      }

      .gc-offer-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-left {
            &-title {
                color: ${({ theme }) => theme.textSeashellWhite};
            }
            &-time {
                color: ${({ theme }) => theme.primaryFontColor};
                opacity: .5;
                margin-top: 8px;

            }
        }

        &-right {
            display: flex;
            align-items: center;

            &-item:not(:last-child) {
                border-right: 2px solid ${({ theme }) => theme.grayscale70};
                margin-right: 2.2rem;
                padding-right: 1.2rem;
            }
        }
      }

      .is-expired {
        color: ${({ theme }) => theme.colorYellow};
        opacity: 1;
        .gc-offer-tooltip {
          fill: ${({ theme }) => theme.colorYellow};
        }
      }

      .list {
        list-style: disc;
        color: ${({ theme }) => theme.grayFontColor};
        margin-left: 20px;
        margin-top: 16px;
        margin-bottom: 10px;

        > li {
          line-height: 32px;
        }
      }

      .waiting-body {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .list {
          width: 709px;
          list-style: disc;
          color: ${({ theme }) => theme.grayFontColor};
          margin-left: 20px;
          margin-top: 16px;
          margin-bottom: 10px;

          > li {
            line-height: 32px;
          }
        }

        .blue-line {
          height: 130px;
          width: 2px;
          background-color: #3868FF;
          position: absolute;
          right: 18rem;
        }

        .selected-details {
          > div {
            text-align: center;
            color: ${({ theme }) => theme.grayFontColor};
            margin-top: 5px;
          }
        }
      }

      .confirmed-contact {
        color: ${({ theme }) => theme.grayFontColor};
        position: absolute;
        bottom: 1rem;
      }

    }

    .gradient-border {
      position: relative;
      height: 200px;
      &::before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 12px;
        padding: 2px;
        background: linear-gradient(to left, #494BFF, #0FA7FF);
        pointer-events: auto;
        -webkit-mask:
           linear-gradient(#fff 0 0) content-box,
           linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
                mask-composite: exclude;
      }
    }

    .blurry-content {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: -9rem;
      margin-bottom: 7rem;
      flex-direction: column;
      gap: .5rem;

      > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 16px;
        width: 50%;
        color: ${({ theme }) => theme.grayFontColor};

        > span  {
          text-align: center;

          .learn-more {
            color: ${({ theme }) => theme.primaryColor};
            cursor: pointer;
          }
        }

      }
    }

    .offer-type-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 24px;
      div {
        .blurry-content {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          top: 3rem;
          margin-top: -13rem;

          > div {
            width: 80%;
            > div {
              text-align: center;
            }
          }
        }
      }
    }
  }
`;

export const StyledOfferTypeSummary = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 24px;
  gap: 1.5rem;
  height: 300px;

  .left {
    &-subtitle {
      color: ${({ theme }) => theme.textGray};
    }

    .chart-layout {
      background-color: ${({ theme }) => `${theme.textGray}1A`};
      border-radius: 12px;
      margin-top: 10px;
      padding: 16px;

      > div {
        .rounded-top {
          border-top-left-radius: 4px !important;
          border-top-right-radius: 4px !important;
        }

        .rounded-bottom {
          border-bottom-left-radius: 4px !important;
          border-bottom-right-radius: 4px !important;
        }
      }

      &-tag {
        position: relative;
        top: 6rem;
        margin-left: 12rem;
        width: 150px;
        color: ${({ theme }) => theme.grayscale0};
        display: flex;
        align-items: center;
        gap: 8px;
        background-color: rgba(250, 248, 248, 0.3);
        backdrop-filter: blur(10px);
        padding: 12px 16px;
        border-top-right-radius: 24px;
        border-bottom-right-radius: 24px;
        border-bottom-left-radius: 24px;
      }

      &-legend {
        --dimension: 8px;

        display: flex;
        justify-content: flex-start;
        gap: 1rem;

        .item {
          display: flex;
          align-items: center;

          .legend-tooltip {
            margin-left: 0.5rem;
            margin-bottom: 0.25rem;
            fill: ${({ theme }) => theme.grayFontColor};
          }
          &-revenue {
            display: inline-flex;
            align-items: center;
            width: var(--dimension);
            height: var(--dimension);
            background-color: #ffffff80;
            margin-right: 5px;
            border-radius: 2px;
          }

          &-repayment {
            display: inline-flex;
            align-items: center;
            width: var(--dimension);
            height: var(--dimension);
            background-color: ${({ theme }) => theme.textGray};
            margin-right: 5px;
            border-radius: 2px;
          }
        }
      }

      &-body {
        display: grid;
        grid-template-areas:
          "y_axis bar"
          "y_axis bar";
        grid-template-columns: 3.5rem 1fr;
        margin-top: -1.5rem;
        height: 200px;
      }

      &-bar {
        display: flex;
        justify-content: space-between;
        grid-area: bar;
      }
    }
  }

  .right {
    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 1rem 0;

      > span:first-of-type {
        color: ${({ theme }) => theme.textGray};

        > .offer-tooltip {
          margin-left: 0.5rem;
          margin-bottom: 0.25rem;
          fill: ${({ theme }) => theme.grayFontColor};
        }
      }
    }

    .slider-value {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: ${({ theme }) => theme.textGray};
      margin-bottom: 1.5rem;
    }

    .horizontal-line {
      border-bottom: 1px solid ${({ theme }) => theme.grayscale50};
      margin: 1rem 0;
    }

    .bottom-text {
      margin-top: 1rem;
      color: #ffffff80;
    }
  }
`;
