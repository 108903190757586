import { FINANCIALS_ACTION, FINANCIALS_PATHNAME, trackGTMEvent } from "@src/utils/gtm";

export function trackCollapseContent({ name = "", label = "Don’t see all your data?", isOpen }) {
  const state = isOpen ? "Open" : "Close";
  return trackGTMEvent({
    event: FINANCIALS_ACTION.EXPAND_TOOLTIP_ACCORDION,
    tooltip: name,
    state,
    name: label,
    pathname: FINANCIALS_PATHNAME,
  });
}
