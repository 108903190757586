import SanloWebApiService from "./services";

import { trackGTMEvent } from "@utils/gtm";

import HTTP from "@redux/http";

import actions from "@redux/actions";

import { appLogin } from "@utils/custom-hooks/useAuth";

import sessionActions, {
  AUTH0_UPDATE,
  FEATURE_FLAGS,
  FINISH_ONBOARDING,
  DISMISS_ONBOARDING_CARD,
  FINISH_REGISTRATION,
  GET_ONBOARDING_PROGRESS,
  LOGIN,
  PARTNER_BRANDING,
  REQUEST_CAPITAL_ONBOARDING,
  SAVE_ONBOARDING_PROGRESS,
  SEND_FEEDBACK,
  USER_SUMMARY,
  SET_ONBOARDING_SUPERPOWERS,
} from "./actions";

// prettier-ignore
const sessionMiddleware = ({ dispatch, getState }) => next => action => {
  next(action);

  switch (action.type) {
    case LOGIN.REQUEST:
      appLogin(action);
      HTTP.setAuthorization(action.token);
      dispatch(sessionActions.setAccessToken(action.token));
      dispatch(sessionActions.userSummary.request());
      dispatch(sessionActions.getOnboardingProgress.request());
      break;

    case USER_SUMMARY.REQUEST:
      SanloWebApiService.userSummary()
        .then(response => {
          dispatch(sessionActions.userSummary.response(response.data))
        })
        .catch(error => {
          dispatch(sessionActions.userSummary.error(error))
        })
      break;

    case FEATURE_FLAGS.REQUEST:
      SanloWebApiService.featureFlags()
        .then(response => response.data)
        .then(flags => {
          dispatch(sessionActions.featureFlags.response(flags))
        })
        .catch(error => dispatch(sessionActions.featureFlags.error(error)))
      break;

    case FINISH_REGISTRATION.REQUEST: {
      SanloWebApiService.finishRegistration(action.user)
        .then(response => response.data)
        .then(userSummary => {
          dispatch(sessionActions.finishRegistration.response(userSummary));
        })
        .catch(error => {
          dispatch(sessionActions.finishRegistration.error(error));
        })
      break;
    }

    case GET_ONBOARDING_PROGRESS.REQUEST:
      SanloWebApiService.getOnboardingProgress()
        .then(response => {
          let onboardingProgress = response.data;

          // Change null to 0 to avoid undefined destructure property
          if (!response.data.currentStep) {
            onboardingProgress = {
              ...response.data,
              currentStep: 0,
              fundingRequested: 35000,
              userMonthlyRevenue: 2,
            }
          }

          dispatch(sessionActions.getOnboardingProgress.response(onboardingProgress));
        })
        .catch(error => {
          dispatch(sessionActions.getOnboardingProgress.error(error));
        });
      break;

    case SAVE_ONBOARDING_PROGRESS.REQUEST:
      SanloWebApiService.saveOnboardingProgress(action.onboarding)
        .then(response => {
          dispatch(sessionActions.saveOnboardingProgress.response(response.data));
          dispatch(sessionActions.userSummary.request());
        })
        .catch(error => {
          dispatch(sessionActions.saveOnboardingProgress.error(error));
        });
      break;

    case REQUEST_CAPITAL_ONBOARDING.REQUEST:
      SanloWebApiService.requestCapitalFromOnboarding(action.amount)
        .then(response => {
          dispatch(sessionActions.requestCapitalOnboarding.response(response.data));
          dispatch(sessionActions.getOnboardingProgress.request());
          // Refetch advances summary data
          dispatch(actions.integrations.advances.getSummary.request());
          dispatch(sessionActions.userSummary.request());
        })
        .catch(error => {
          dispatch(sessionActions.requestCapitalOnboarding.error(error));
        });
      break;

    case FINISH_ONBOARDING.REQUEST:
      SanloWebApiService.finishOnboarding(action.onboarding)
        .then(response => {
          dispatch(sessionActions.finishOnboarding.response(response.data));
          dispatch(sessionActions.userSummary.request());
        })
        .catch(error => {
          dispatch(sessionActions.finishOnboarding.error(error));
        });
      break;

    case DISMISS_ONBOARDING_CARD.REQUEST:
      SanloWebApiService.dismissOnboardingCard()
        .then(() => {
          dispatch(sessionActions.dismissOnboardingCard.response());
          dispatch(sessionActions.userSummary.request());
        })
        .catch(() => {
          dispatch(sessionActions.dismissOnboardingCard.error());
        });
      break;

    case PARTNER_BRANDING.REQUEST:
      SanloWebApiService.partnerBranding().then(response => {
          dispatch(sessionActions.partnerBranding.response({
            ...response.data,
            logo: response.data.logoUrl,
            site: response.data.redirectUrl,
          }));
        })
        .catch(error => {
          dispatch(sessionActions.partnerBranding.error(error));
        });
      break;

    case AUTH0_UPDATE.REQUEST:
      dispatch(sessionActions.auth0Update.response());
      break;

    case SEND_FEEDBACK.REQUEST:
      SanloWebApiService.sendFeedback({
        feedback: action.feedback,
        userEmail: action.userEmail,
      }).then(response => {
          dispatch(sessionActions.sendFeedback.response(response.data));
          // Tag Manager Sigh Up Event
          trackGTMEvent({
            'event': 'Sign Up',
            'email': action.userEmail,
            'domain': action.userEmail.split('@')[1]
          });
        })
        .catch(error => {
          dispatch(sessionActions.sendFeedback.error(error));
        });
      break;

      case SET_ONBOARDING_SUPERPOWERS.REQUEST:
        SanloWebApiService.setOnboardingSuperpowers(action.superpowers)
          .then(response => {
            dispatch(sessionActions.setOnboardingSuperpowers.response(response.data));
            dispatch(sessionActions.setSuggestedIntegrationsCompleted(true));
          })
          .catch(error => {
            dispatch(sessionActions.setOnboardingSuperpowers.error(error));
          });
        break;

    default:
      break;
  }
}

export default sessionMiddleware;
