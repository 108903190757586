import styled from "styled-components";

export const GuideActions = styled.div`
  border-top: 1px solid #3a3a3e;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  gap: 16px;
`;
