import React from "react";
import {
  Button,
  HStack,
  Input,
  Icon,
  ListItem,
  UnorderedList,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Tooltip,
  VStack,
  OrderedList,
  useDisclosure,
  useClipboard,
} from "@chakra-ui/react";
import { StyledLoader } from "@common/inputs/SanloButton/SanloButton";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { ConfirmationModal } from "@common/modals/confirmation-modal";
import { BaseCard } from "../BaseCard";
import { CUSTOM_DOMAIN_STATUS } from "@src/consts/webshops/settings";
import { addHttpsToUrl, domainRules } from "@src/utils/string";
import { CustomLink } from "./utils";

import { ReactComponent as TrashIcon } from "@assets/img/icons/common/trash-icon.svg";
import { ReactComponent as CopyIcon } from "@assets/img/icons/common/copy.svg";
import { ReactComponent as RefreshIcon } from "@assets/img/icons/common/refresh.svg";
import { ReactComponent as AlertCircle } from "@assets/img/icons/common/alertCircle.svg";

import { useParams } from "react-router-dom";
import {
  customDomainSelector,
  customDomainThunks,
} from "@redux/webshops/webshop/settings/custom-domain/customDomainSlice";
import { showToast } from "@src/utils/redux";

export const CustomDomain = () => {
  const dispatch = useDispatch();
  const customDomains = useSelector(customDomainSelector);

  const {
    data: { status = CUSTOM_DOMAIN_STATUS.NOT_CONNECTED, records = [], customDomain },
    setDomainRequestStatus,
    verifyRequestStatus,
    removeRequestStatus,
  } = customDomains;

  const { onCopy, hasCopied } = useClipboard(records[0]?.data ?? "");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { id } = useParams();

  const {
    handleSubmit,
    register,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    criteriaMode: "all",
    values: {
      domain: customDomain ?? "",
    },
  });
  const { domain: domainError } = errors || {};
  const domain = watch("domain");

  function handleVerifyAndSave() {
    dispatch(customDomainThunks.setCustomDomain({ data: { domain, useCustomDomain: true }, webshopId: id }));
  }

  function verifyDomain() {
    dispatch(customDomainThunks.verifyCustomDomain({ webshopId: id }));
  }

  async function handleRemoveDomain() {
    reset();
    try {
      await dispatch(customDomainThunks.deleteCustomDomain({ webshopId: id })).unwrap();
    } catch (e) {
      showToast("There was an error removing the domain. Please try again", "error");
    }
    onClose();
  }

  return (
    <>
      <BaseCard title={"Custom Domain"} id={"builder-settings-custom-domain"}>
        {!status || status === CUSTOM_DOMAIN_STATUS.NOT_CONNECTED || status === CUSTOM_DOMAIN_STATUS.ERROR ? (
          // Status NOT_CONNECTED or ERROR
          <VStack gap={"8px"} alignItems={"flex-start"}>
            <form id={"settings-custom-domains"} onSubmit={handleSubmit(handleVerifyAndSave)} style={{ width: "100%" }}>
              <VStack w={"100%"} alignItems={"flex-start"} gap={"16px"}>
                <VStack w={"100%"} alignItems={"flex-start"} gap={"8px"}>
                  <Text size={"small"} letterSpacing={"0.14px"} fontWeight={600} color={"textWhite.400"}>
                    Enter your custom domain
                  </Text>
                  <Input
                    {...register("domain", {
                      validate: domainRules,
                    })}
                    placeholder={"store.yoursite.com"}
                    isInvalid={!!domainError?.types}
                  />
                  {domainError && !!domainError?.types && (
                    <HStack alignItems={"flex-start"} alignSelf={"stretch"} gap={"4px"}>
                      <Icon
                        as={AlertCircle}
                        width={"16px"}
                        height={"16px"}
                        mt={"2px"}
                        color={"brandRed.400"}
                        transform={"rotate(180deg)"}
                      />
                      <VStack alignItems={"flex-start"} m={0} gap={0}>
                        <Text size={"smallAlt"} color={"brandRed.400"} letterSpacing={"0.24px"}>
                          Your custom domain must:
                        </Text>
                        <UnorderedList fontSize={"sm"} color={"brandRed.400"}>
                          {Object.keys(domainError.types).map((rule) => {
                            return <ListItem key={rule}>{domainError?.types[rule]}</ListItem>;
                          })}
                        </UnorderedList>
                        <Text size={"smallAlt"} color={"brandRed.400"} letterSpacing={"0.24px"}>
                          Please review your input and try again.
                        </Text>
                      </VStack>
                    </HStack>
                  )}
                </VStack>

                <VStack w={"100%"} alignItems={"flex-start"} gap={"2px"}>
                  <Text size={"small"} letterSpacing={"0.14px"} fontWeight={600} color={"#C5C5C5"}>
                    To connect your custom domain.{" "}
                  </Text>
                  <OrderedList fontSize={"sm"} color={"#FFFFFF80"}>
                    <ListItem>Go to your DNS provider.</ListItem>;<ListItem>Create a new CNAME record.</ListItem>;
                    <ListItem>Paste the provided CNAME value into the "Data/Answer" field.</ListItem>;
                  </OrderedList>
                </VStack>

                <VStack w={"100%"} gap={"8px"}>
                  <Tooltip label={"Copy"} p={"12px"} bgColor={"dark.400"}>
                    <HStack
                      w={"full"}
                      p={"12px"}
                      borderRadius={"10px"}
                      justifyContent={"space-between"}
                      border={"var(--border-dark-300)"}
                      onClick={onCopy}
                    >
                      <HStack gap={"4px"}>
                        <Text size={"small"} color={"textWhite.400"}>
                          CNAME:
                        </Text>
                        <Text size={"small"} color={"textSubtext.400"}>
                          {records[0]?.data ?? ""}
                        </Text>
                      </HStack>
                      {hasCopied ? (
                        <Text size={"small"} color={"textSubtext.400"}>
                          Copied!
                        </Text>
                      ) : (
                        <Icon as={CopyIcon} w={"16px"} h={"16px"} cursor={"pointer"} onClick={onCopy} />
                      )}
                    </HStack>
                  </Tooltip>
                </VStack>

                <VStack w={"100%"} alignItems={"flex-start"} gap={"5px"}>
                  <Text size={"smallAlt"} color={"#FFFFFF80"}>
                    Reference the example record below to set up your new CNAME record.
                  </Text>

                  <TableContainer>
                    <Table variant={"customDomain"}>
                      <Thead>
                        <Tr>
                          <Th>Host</Th>
                          <Th>Type</Th>
                          <Th>TLL</Th>
                          <Th>Data</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        <Tr>
                          <Td>Store</Td>
                          <Td>CNAME</Td>
                          <Td>300</Td>
                          <Td>webshop-cd.sanlo.ai</Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </TableContainer>
                </VStack>

                <Button
                  type={"submit"}
                  form={"settings-custom-domains"}
                  variant={"primary"}
                  border={"var(--border-dark-300)"}
                  h={"32px"}
                  p={"8px 12px"}
                  data-test-id={"builder-settings-custom-domain-save-button"}
                  isDisabled={!domain || (domainError && !!domainError?.types)}
                  isLoading={setDomainRequestStatus?.pending}
                  spinner={<StyledLoader />}
                >
                  Verify & Save
                </Button>
              </VStack>
            </form>
            {setDomainRequestStatus?.error || status === CUSTOM_DOMAIN_STATUS.ERROR ? (
              <Text size={"smallAlt"} color={"brandRed.400"} letterSpacing={"0.24px"}>
                Verification failed: Please review your custom domain.
              </Text>
            ) : (
              <Text size={"smallAlt"} color={"textSubtext.400"} letterSpacing={"0.24px"}>
                Note: Verifying custom domain can take up to 24hrs.
              </Text>
            )}
          </VStack>
        ) : (
          // Status PENDING or CONNECTED
          <VStack w={"100%"} gap={"16px"}>
            <CustomLink url={addHttpsToUrl(customDomain)} status={status} isLoading={verifyRequestStatus?.pending} />
            <VStack alignItems={"flex-start"} w={"100%"} gap={"5px"}>
              <HStack w={"100%"} gap={"8px"} justifyContent={"space-between"}>
                <Button
                  leftIcon={<Icon as={RefreshIcon} w={"16px"} h={"17px"} />}
                  isDisabled={verifyRequestStatus?.pending}
                  onClick={verifyDomain}
                >
                  Refresh domain
                </Button>
                <Button
                  leftIcon={<Icon as={TrashIcon} w={"16px"} h={"17px"} />}
                  isDisabled={verifyRequestStatus?.pending}
                  onClick={onOpen}
                >
                  Remove domain
                </Button>
              </HStack>
              <Text size={"smallAlt"} color={"textSubtext.400"} letterSpacing={"0.24px"}>
                Note: Verifying custom domain can take up to 24hrs.
              </Text>
            </VStack>
          </VStack>
        )}
      </BaseCard>

      <ConfirmationModal
        isOpen={isOpen}
        onClose={onClose}
        onAccept={handleRemoveDomain}
        title={"Remove custom domain?"}
        acceptText={"Yes, remove it."}
        cancelText={"Cancel"}
        isLoading={removeRequestStatus?.pending}
      >
        <Text size={"smallAlt"} letterSpacing={"0.14px"}>
          Your players won't be able to reach your webshop using this domain anymore. Do you want to proceed?
        </Text>
      </ConfirmationModal>
    </>
  );
};
