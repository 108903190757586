import styled from "styled-components";
import { BodySmallPlusCSS } from "@styled";

export const StyledWrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const StyledLabel = styled.label`
  ${BodySmallPlusCSS}

  color: ${({ theme }) => theme.textSeashellWhite};
  margin-bottom: 8px;
`;

export const StyledInputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const StyledInput = styled.input`
  font-family: Inter, "Helvetica", sans-serif;
  background-color: ${({ theme }) => theme.inputBackgroundColor};
  color: ${({ theme }) => theme.inputFontColor};
  box-shadow: none;
  transition: none;
  border-radius: 10px;
  padding: 10px 12px;
  height: 40px;
  border: 2px solid transparent;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;
  caret-color: ${({ theme }) => theme.inputFontColor};
  max-width: 100%;
  width: 100%;
  cursor: default !important;

  &::placeholder {
    color: ${({ theme }) => theme.textSubtext};
    font-size: 1rem;
  }

  ${({ isJumbo }) =>
    isJumbo &&
    `
    border-radius: 12px;
    padding: 20px;
    height: 64px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
  `};

  ${({ hasError, theme }) =>
    hasError &&
    `
    background-color: ${theme.colorRed}19;
    color: ${theme.colorRed};
    border-color: transparent;
    &::placeholder {
      color: ${theme.colorRed};
      opacity: 0.5;
    }
  `};

  &:hover {
    border-color: rgba(255, 255, 255, 0.15);
  }

  &:focus {
    border-color: ${({ theme }) => theme.colorRed};
  }

  &:disabled,
  &:read-only {
    // Argon or some reset CSS sets disabled bkg to whitesmoke
    background-color: ${({ theme }) => theme.inputBackgroundColor};
    border-color: transparent !important;
    color: rgba(255, 255, 255, 0.5);
  }

  &:disabled {
    cursor: not-allowed !important;
  }

  &::selection,
  &::-moz-selection {
    color: #000;
    background: ${({ theme }) => theme.colorRed};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    border: 2px solid ${({ theme }) => theme.inputBackgroundColor};
    -webkit-border-radius: 8px;
    -webkit-text-fill-color: ${({ theme }) => theme.grayFontColor} !important;
    -webkit-box-shadow: 0 0 0 30px ${({ theme }) => theme.grayscale20} inset !important;
    transition: background-color 5000s ease-in-out 0s;
  }
`;

export const StyledInputButton = styled.button`
  ${BodySmallPlusCSS}

  box-sizing: border-box;
  border: 4px solid ${({ theme }) => theme.grayscale40};
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  background-color: ${({ theme }) => theme.grayscale50};
  color: ${({ theme }) => theme.textEmphasis};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 16px;
  padding: 0.5rem;
  border-radius: 10px;

  ${({ isJumbo }) =>
    isJumbo &&
    `
    padding: 1.25rem;
    border-radius: 14px;
  `};

  :hover {
    background: ${(props) => (props.bgColorHover ? props.bgColorHover : props.theme.buttonSecondaryHover)};
  }

  svg {
    margin-right: 8px;
    fill: ${(props) => (props.textColor ? props.textColor : props.theme.textEmphasis)};
  }
`;

export const StyledError = styled.div`
  ${BodySmallPlusCSS}

  position: absolute;
  color: ${({ theme }) => theme.colorRed};
  margin-top: 8px;

  svg {
    vertical-align: top;
    margin-right: 4px;
    transform: rotate(180deg);
    fill: ${({ theme }) => theme.colorRed};
  }
`;
