import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { Collapse } from "reactstrap";
import { BodyRegular16 } from "@styled";

import { BodyLargePlus } from "../styled";
import StyledAdvanceCard from "./StyledAdvanceCard";
import AdvanceHeader from "./AdvanceHeader";
import DynamicProgressBar from "./dynamic-progress-bar/DynamicProgressBar";
import AccordionRow from "../dashboard/card-components/DetailsRow";
import TransactionsHistory from "./transactions-history/TransactionsHistory";
import DashboardCard from "../common/cards/dashboard-card/DashboardCard";
import DismissableBanner from "../common/banners/DismissableBanner";

import { ReactComponent as ArrowIcon } from "@assets/img/icons/common/accordion-arrow.svg";
import { uniq } from "lodash";
import ErrorBoundary from "@components/ErrorBoundary";
import { currencyFormat, bankersRounding } from "@utils/currency";
import text from "@utils/text";
import symbols from "@utils/symbols";

const AdvanceCard = ({
  advance = {},
  advancePlatforms = {},
  noHeader,
  id = "",
  userFirstName,
  advanceStatus,
  showDismissBanner = true,
  expanded = false,
}) => {
  const offers = useSelector((state) => state.offers.capitalOffers);
  const isOfferSelected = offers?.find((offer) => offer.state === "OFFER_SELECTED");
  const isOfferActive = offers?.find((offer) => offer.state === "COMPLETED");
  // TEMP: Hide repayment rate only for two companies until proper fixed term UI is in
  const company = useSelector((state) => state.session.company);
  const { id: companyId } = company;
  const hideRepaymentRate =
    companyId === "8ce4b10a-4fa3-4eb8-9d73-5e8b9ba0b490" || // Conchware (prod)
    companyId === "e0fb339a-131d-4ca6-b7f6-420bd065cd15" || // Fiado (prod)
    companyId === "279f365a-c851-4f7a-9eea-48e3d0df5c6a"; // Midway (staging)
  // /TEMP

  const [accordions, setAccordions] = useState([]);

  const transactionHistoryRef = useRef(null);
  const gotoTransactionsHistory = () => {
    // eslint-disable-next-line no-unused-expressions
    transactionHistoryRef.current?.scrollIntoView({ behavior: "smooth" });
    setAccordions(uniq([...accordions, "history"]));
  };
  const {
    gameIcon = null,
    gameTitle,
    maskedDisbursementAccount,
    capitalAmount,
    capitalFee,
    capitalFeePercentage,
    totalAmount,
    createdAt,
    advanceCurrency,
    repaymentRate,
    numberOfRepayments,
    advanceType,
  } = advance;

  const toggleAccordion = (selected) => {
    const isOpen = accordions.includes(selected);
    setAccordions((prevState) => {
      if (isOpen) return accordions.filter((item) => item !== selected);
      return [...prevState, selected];
    });
  };

  useEffect(() => {
    if (expanded) {
      toggleAccordion("details");
      toggleAccordion("history");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <DashboardCard
      id={id}
      noHeader={noHeader}
      header={{
        title: "Growth Capital",
        logo: require("@assets/img/icons/logos/advance-logo.svg").default,
      }}
    >
      <StyledAdvanceCard>
        {isOfferActive && !isOfferSelected && showDismissBanner && (
          <ErrorBoundary name="growth-capital-active-tab-active-offer-banner">
            <DismissableBanner
              id={id}
              title={`${symbols.pizza} Congrats, ${userFirstName}!`}
              content={
                <div className="left-align">
                  <BodyRegular16>
                    {text.t("active_offer_with_amount", { amount: isOfferActive.advanceAmount })}
                  </BodyRegular16>
                </div>
              }
              dismissable
              allowMarginTop
            />
          </ErrorBoundary>
        )}
        <AdvanceHeader advance={advance} status={advanceStatus} />

        <AdvanceInfo
          createdAt={createdAt}
          accountNumber={maskedDisbursementAccount}
          gameIcon={gameIcon}
          gameTitle={gameTitle}
        />

        <DynamicProgressBar
          advancePlatforms={advancePlatforms}
          advance={advance}
          advanceId={id}
          gotoTransactionsHistory={gotoTransactionsHistory}
        />

        <AdvanceAccordion
          title="View details"
          accordionId="details"
          expanded={accordions.includes("details")}
          toggleAccordion={toggleAccordion}
        >
          <div className="accordion-content">
            <AccordionRow label="Capital Amount" amount={currencyFormat(capitalAmount, advanceCurrency)} />

            <AccordionRow
              label={`Capital Fee (${capitalFeePercentage}%)`}
              amount={currencyFormat(capitalFee, advanceCurrency)}
              id="capital-fee-tooltip"
              tooltip={{
                title: "📜 Capital Fee",
                text: "This is a flat fee that is included in your total repayment amount. We don’t charge any other fees like interest, royalties, points or warrants. ",
              }}
            />

            <AccordionRow highlighted label="Total to Repay" amount={currencyFormat(totalAmount, advanceCurrency)} />
          </div>

          {/* Non revenue based rows */}
          {advanceType === "NON_REVENUE_FIXED_TERMS_ADVANCE" ? (
            <div className="accordion-content">
              <AccordionRow
                label="Repayment amount"
                message={`${currencyFormat(totalAmount / numberOfRepayments)}/mo`}
              />

              <AccordionRow label="Number of repayments" message={numberOfRepayments} />
            </div>
          ) : (
            !hideRepaymentRate && (
              <div className="accordion-content">
                <AccordionRow
                  label="Repayment Rate"
                  id="repayment-rate-tooltip"
                  tooltip={{
                    title: "📜 Repayment Rate",
                    text: "We automatically collect repayments from your linked bank account on the next day after you receive payment from the platform.",
                  }}
                  message={`${bankersRounding(repaymentRate, {
                    output: "string",
                  })}% of platform's payout`}
                />
              </div>
            )
          )}
        </AdvanceAccordion>

        {/* For monitoring go to transaction history */}
        <div ref={transactionHistoryRef} />
        <AdvanceAccordion
          title="Transactions History"
          accordionId="history"
          expanded={accordions.includes("history")}
          toggleAccordion={toggleAccordion}
        >
          <div className="accordion-content">
            <TransactionsHistory
              advance={advance}
              advanceId={id}
              historicalTransactions={advance.historicalTransactions}
            />
          </div>
        </AdvanceAccordion>
      </StyledAdvanceCard>
    </DashboardCard>
  );
};

const AdvanceAccordion = ({ title, accordionId, expanded, toggleAccordion, children }) => {
  return (
    <div className={"advance-details-container " + (expanded ? "expanded" : "")}>
      <div className="accordion-header" onClick={() => toggleAccordion(accordionId)}>
        <BodyLargePlus className="header-title">{title}</BodyLargePlus>
        <ArrowIcon className={"dropdown-arrow " + (expanded ? "expanded" : "")} />
      </div>
      <Collapse isOpen={expanded}>{children}</Collapse>
    </div>
  );
};

const AdvanceInfo = ({ gameIcon, gameTitle, accountNumber, createdAt }) => (
  <div className="advance-info-container">
    <img src={gameIcon} alt="" className="game-logo" />
    <span className="advance-info-label">
      {gameTitle} • Disbursed to account *{accountNumber} on {moment(createdAt).format("MMM DD, YYYY")}
    </span>
  </div>
);

export default AdvanceCard;
