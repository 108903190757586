import { useState, useRef } from "react";

/*
HOW TO USE THE HOOK?

- Import custom hook, in the component that you will have BarChart/LineChart
import useChartVerticalLine from "@utils/custom-hooks/useChartVerticalLine";

- Use Custom Hook
const verticalLine = useChartVerticalLine();

Pass to the verticalLine prop of BarChart/LineChart the entire constant of the custom hook usage

For a chart
<BarChart
    chartId="cash-flow-chart"
    data={data}
    verticalLine={verticalLine}  <----------
/>

--------------------------------------------
For two or more charts
<BarChart
    chartId="cash-flow-chart"
    data={data}
    verticalLine={verticalLine}  <----------
/>
<LineChart
    chartId="cash-burn"
    data={data}
    verticalLine={verticalLine}  <----------
/>

*/

const useChartVerticalLine = () => {
  const chartWrapperRef = useRef(null);
  const [mouseX, setMouseX] = useState(null);
  const [showVerticalLine, setShowVerticalLine] = useState(false);

  const handleMouseEnter = () => {
    setShowVerticalLine(true);
  };

  const handleMouseLeave = () => {
    setShowVerticalLine(false);
  };

  const handleMouseMove = (event) => {
    // Get the left margin of the ChartWrapper
    const chartWrapperLeft = chartWrapperRef.current.getBoundingClientRect().left;

    // Obtain the X position of the mouse relative to the Chart component
    const mouseXPos = event.clientX - chartWrapperLeft;
    if (mouseXPos >= 0) {
      // 2 pixels to the right of the mouse pointer so the vertical line
      // is not constantly under the mouse pointer, block any hover effects or tooltips
      setMouseX(mouseXPos + 2);
      setShowVerticalLine(true);
    } else {
      setShowVerticalLine(false);
    }
  };

  return { chartWrapperRef, mouseX, showVerticalLine, handleMouseEnter, handleMouseLeave, handleMouseMove };
};

export default useChartVerticalLine;
