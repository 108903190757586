import { configureConsts, configureActions } from "@utils/redux";

export const APP_STORE_SAVE_CREDENTIALS = configureConsts("APP_STORE_SAVE_CREDENTIALS");
export const APP_STORE_UNLINK_INTEGRATION = configureConsts("APP_STORE_UNLINK_INTEGRATION");

const appStoreActions = {
  saveCredentials: configureActions(APP_STORE_SAVE_CREDENTIALS, {
    request: (credentials, callback) => ({
      type: APP_STORE_SAVE_CREDENTIALS.REQUEST,
      credentials,
      callback,
    }),
  }),

  unlinkIntegration: configureActions(APP_STORE_UNLINK_INTEGRATION, {
    request: (callback, error) => ({
      type: APP_STORE_UNLINK_INTEGRATION.REQUEST,
      callback,
      error,
    }),
  }),
};

export default appStoreActions;
