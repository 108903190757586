import React from "react";
import { useDispatch } from "react-redux";
import { VerticalScaleAdjuster } from "@components/charts";
import { changeBarScaleSelected } from "@redux/financials/cashActivitySlice";
import { CASH_ACTIVITY_ZOOM_SCALE } from "@src/consts/financial-intelligence";

export const BarScaleSelector = ({ chartId = "", barScaleSelected = {} }) => {
  const dispatch = useDispatch();

  const handleClick = (option) => {
    dispatch(changeBarScaleSelected({ scaleSelected: option }));
  };

  return (
    <VerticalScaleAdjuster
      chartId={chartId}
      scale={CASH_ACTIVITY_ZOOM_SCALE}
      selectedScale={barScaleSelected}
      changeSelection={(option) => handleClick(option)}
      mb={"30px"}
    />
  );
};
