import React, { useCallback, useEffect, useState } from "react";
import { useTransition, animated, config } from "react-spring";
import OfferType from "./OfferType";
import { fixedData, revenueData, amountToShow } from "./data";
import OfferSummary from "./OfferSummary";
import { currencyFormat, bankersRounding } from "@utils/currency";
import { useSelector } from "react-redux";
import useDebounce from "../../utils/hooks/useDebounce";
import { LoadingScreen } from "@components/common/loaders";
import GcContainer from "./GcContainer";
import WaitingCard from "./WaitingCard";
import { TitleSmallPlus, BodyRegular16 } from "@styled";
import symbols from "@utils/symbols";
import { BodySmallPlus } from "@components/styled";
import text from "@utils/text";

const OfferTabView = () => {
  const offers = useSelector((state) => state.offers.capitalOffers);
  const data = useSelector((state) => state.offers.data);

  const availableOffer = offers?.find((offer) => offer.state === "WAITING_FOR_OFFER_SELECTION");

  const [revenueBased, setRevenueBased] = useState({});
  const [fixedBased, setFixedBased] = useState({});

  const [revenueFunding, setRevenueFunding] = useState(0);
  const [fixedFunding, setFixedFunding] = useState(0);

  const [revSliderValue, setRevSliderValue] = useState(0);
  const [fixSliderValue, setFixSliderValue] = useState(0);

  const [offerType, setOfferType] = useState("");

  const [showSideValues, setShowSideValues] = useState(false);
  const [finishLoading, setFinishLoading] = useState(false);

  useEffect(() => {
    if (availableOffer) {
      const revBased = availableOffer?.options?.find((option) => option.type === "REVENUE_BASED");
      const fixBased = availableOffer?.options?.find((option) => option.type === "FIXED_TERMS");
      setRevenueFunding(amountToShow(revBased) ?? 0);
      setFixedFunding(amountToShow(fixBased) ?? 0);
      setRevenueBased(revBased);
      setFixedBased(fixBased);
    }
  }, [availableOffer]);

  const handleSliderDrag = (value) => {
    setShowSideValues(true);
    if (offerType === "revenue") {
      // retain the slider value for fixed offer while update the slider value for revenue. This will
      // help in preserving the fixed offer requested amount when we switch to revenue type and vice-versa.
      setFixSliderValue(fixedFunding);
      setRevSliderValue(value);
    }

    if (offerType === "fixed") {
      setRevSliderValue(revenueFunding);
      setFixSliderValue(value);
    }
  };

  useEffect(() => {
    let timer;
    if (showSideValues) {
      timer = setTimeout(() => {
        setShowSideValues(false);
      }, 800);
    }

    return () => clearTimeout(timer);
  }, [showSideValues]);

  useEffect(() => {
    let timer;
    if (data.isAccepting) {
      timer = setTimeout(() => {
        setFinishLoading(true);
      }, 2000);
    }
    return () => clearTimeout(timer);
  }, [data.isAccepting]);

  //use this to control the rate at which we hit the server when the slider value changes.
  const debouncedValue = useDebounce(offerType === "revenue" ? revSliderValue : fixSliderValue, 0);

  const getSliderValue = useCallback(() => {
    if (offerType === "revenue") {
      setRevenueFunding(revSliderValue);
      setRevenueBased((prevState) => ({
        ...prevState,
        requestedAmount: revSliderValue,
        baselineRepaymentRate: prevState.baselineRepaymentRate * (revSliderValue / amountToShow(revenueBased)),
      }));
    }
    if (offerType === "fixed") {
      setFixedFunding(fixSliderValue);
      setFixedBased((prevState) => ({
        ...prevState,
        requestedAmount: fixSliderValue,
        fixedMonthlyRepayment: (fixSliderValue + fixSliderValue * prevState.oneTimeFee) / prevState.maxTermsInMonths,
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  useEffect(() => {
    getSliderValue();
  }, [getSliderValue]);

  const transitions = useTransition(data.isAccepting, {
    from: { opacity: 0, transform: "translateY(0%)" },
    enter: { opacity: 1 },
    leave: { opacity: 0, transform: "translateY(-100%)" },
    config: {
      ...config.molasses,
    },
    exitBeforeEnter: true,
  });

  if (data.selectedAmount) {
    return (
      <WaitingCard
        header={<TitleSmallPlus>{text.t("last_step_header")}</TitleSmallPlus>}
        body={
          <ul className="list">
            <li>
              <BodyRegular16>{text.t("last_step_list_1")}</BodyRegular16>
            </li>
            <li>
              <BodyRegular16>{text.t("last_step_list_3")}</BodyRegular16>
            </li>
          </ul>
        }
        footer={<BodyRegular16 className="confirmed-contact">{text.t("last_step_footer")}</BodyRegular16>}
      />
    );
  }

  return transitions((props) => {
    return (
      <animated.div
        style={{
          opacity: props.opacity.to({ range: [0.0, 1.0], output: [0, 1] }),
          ...props,
        }}
      >
        {data.isAccepting && (
          <LoadingScreen
            style={{ height: "90%", position: "absolute", zIndex: 99, opacity: 0.4, left: "0%" }}
            notifyParent={() => {}}
            loaded={finishLoading}
          />
        )}

        <GcContainer offer={availableOffer} fixedBased={fixedBased} />

        <div className="offer-type-container">
          <OfferType
            type="revenue"
            offerType={offerType}
            setOfferType={setOfferType}
            header="Revenue-based"
            amount={currencyFormat(amountToShow(revenueBased) ?? 0)}
            fee={`${currencyFormat((amountToShow(revenueBased) ?? 0) * (revenueBased?.oneTimeFee ?? 0))} one-time fee`}
            repayment={`${bankersRounding(
              (revenueBased?.baselineRepaymentRate ?? 0) * 100,
            )}% of future revenue toward repayment`}
            key="revenue-card"
            tooltipText={text.t("revenue_highest_amount_tooltip")}
            showBlur={!revenueBased}
            blurContent={
              <div>
                {symbols.lock}
                <BodySmallPlus>{text.t("revenue_blur_text")}.</BodySmallPlus>
              </div>
            }
          />
          <OfferType
            type="fixed"
            offerType={offerType}
            setOfferType={setOfferType}
            header="Fixed term-based"
            amount={currencyFormat(amountToShow(fixedBased) ?? 0)}
            fee={`${currencyFormat((amountToShow(fixedBased) ?? 0) * (fixedBased?.oneTimeFee ?? 0))} one-time fee`}
            repayment={`${currencyFormat(fixedBased?.fixedMonthlyRepayment ?? 0)} fixed monthly repayment`}
            key="fixed-term-card"
            tooltipText={text.t("fixed_highest_amount_tooltip")}
            showBlur={!fixedBased}
            blurContent={
              <div>
                {symbols.lock}
                <BodySmallPlus>{text.t("fixed_blur_text")}</BodySmallPlus>
              </div>
            }
          />
        </div>

        {offerType === "revenue" && revenueBased && (
          <OfferSummary
            offerType={offerType}
            data={revenueData}
            funding={revenueFunding}
            handleSliderDrag={handleSliderDrag}
            {...revenueBased}
            showLoader={data.isAccepting}
          />
        )}
        {offerType === "fixed" && fixedBased && (
          <OfferSummary
            offerType={offerType}
            data={fixedData}
            funding={fixedFunding}
            handleSliderDrag={handleSliderDrag}
            {...fixedBased}
            showLoader={data.isAccepting}
          />
        )}
      </animated.div>
    );
  });
};

export default OfferTabView;
