import React from "react";

import { BodyRegular16, TitleLargePlus } from "@styled";
import { StepActionsContainer } from "@common/animated/FadeStepsContainer";
import SetupGuide from "@components/common/modals/integrations-modal/SetupGuide";
import { SanloButton } from "@inputs";

export const GooglePlayStep3 = ({
  platform = {
    id: "googleplay",
    name: "Google play",
    platformId: "googleplay",
  },
  onClose = () => {},
}) => {
  return (
    <StepActionsContainer>
      <div className="fs-modal-actions">
        <TitleLargePlus className="done-title">
          <span role="img" aria-label="party">
            🎉
          </span>{" "}
          Congrats! You are done!
        </TitleLargePlus>

        <BodyRegular16 className="done-description">
          It'll take up to 24 hours to validate and establish the connection. Hold tight and once it's done, you'll be
          able to track {platform.name} daily earnings and upcoming payments on the Dashboard page.
        </BodyRegular16>

        <SanloButton isJumbo label="Done" onClick={onClose} testId="googleplay-complete-button" />
      </div>

      <div className="fs-modal-instructions-integrations">
        <SetupGuide
          platformStep={`${platform.name}1`}
          step={{
            title: null,
            description: null,
            images: [],
          }}
        />
      </div>
    </StepActionsContainer>
  );
};
