import { Box, CloseButton, HStack, Icon, Text } from "@chakra-ui/react";
import { ReactComponent as AlertOctagon } from "@assets/img/icons/common/alert_octagon.svg";
import { ReactComponent as CheckCircle } from "@assets/img/icons/common/check_circle.svg";

// TODO: add color in components Library
const toastStatus = {
  success: {
    bgColor: "rgba(44, 249, 175, 0.1)",
    color: "brandGreen.400",
    icon: <Icon as={CheckCircle} color={"brandGreen.400"} w="16px" h="16px" />,
    defaultText: "Success!",
  },
  error: {
    bgColor: "brandRedAlpha.400",
    color: "brandRed.400",
    icon: <Icon as={AlertOctagon} color={"brandRed.400"} w="16px" h="16px" />,
    defaultText: "There was an error on our servers, please try again later",
  },
};

export function Toast(props) {
  const { title, status = "error", onClose } = props;
  const { bgColor, color, icon, defaultText } = toastStatus[status];

  return (
    <HStack p="12px" bg={bgColor} borderRadius={"10px"}>
      <HStack gap="8px">
        {icon}
        <Text color={color} size={"small"} fontWeight={500} maxW="240px">
          {title ?? defaultText}
        </Text>
      </HStack>

      <Box borderRight={"1px solid"} borderRightColor={bgColor} minH={"32px"} h="full" ml="auto" />
      <CloseButton size="sm" color="textDefault.400" onClick={onClose} />
    </HStack>
  );
}
