import React from "react";
import Support from "@components/support/Support";
import { Heading, Text } from "@chakra-ui/react";

const ConnectCallout = ({ title, description }) => (
  <>
    <Heading size="small" fontWeight={"semibold"} mb="16px">
      {title}
    </Heading>
    <Text size={"small"} mb="16px" lineHeight={"20px"}>
      {description}
    </Text>
    <Text size="small">
      Have any questions?{" "}
      <Text as="span" size={"small"} fontWeight={"semibold"}>
        <Support red />
      </Text>
    </Text>
  </>
);

export default ConnectCallout;
