import React from "react";

import {
  StyledCashFlowSource,
  StyledSourceData,
  StyledIcon,
  StyledName,
  StyledCashAmount,
  StyledProgressBarContainer,
  StyledProgressBar,
} from "../CashFlowBreakdown.styled";

import { MonthlyBurn } from "../../monthly-burn";

import { currencyFormat } from "@utils/currency";

export const CashFlowBreakDownSource = ({ source = {}, highestAmount = 0, barStyles = {} }) => {
  const { name = "", amountCents = 0, momBps = null, iconUrl: icon = null } = source;

  return (
    <StyledCashFlowSource>
      <StyledSourceData>
        <StyledIcon src={icon} />
        <StyledName>{name}</StyledName>
        <StyledCashAmount>
          {currencyFormat(amountCents / 100, {
            forceShowDecimals: true,
            addPrefix: true,
          })}
        </StyledCashAmount>
        <MonthlyBurn value={momBps} />
      </StyledSourceData>
      <StyledProgressBarContainer>
        <StyledProgressBar
          style={{
            width: `${(Math.abs(amountCents) / highestAmount) * 100}%`,
            ...barStyles,
          }}
        />
      </StyledProgressBarContainer>
    </StyledCashFlowSource>
  );
};
