export const parseOptions = (options = [], initialOption = null) => {
  let parsedInitialOption = null;

  const parsedOptions = options.map((option, index) => {
    const optionType = typeof option;
    let newOption = null;

    if (optionType === "string") {
      // The option is just a string, so we need to transform it
      newOption = {
        optionType,
        optionIndex: `(N-${index})`,
        label: option,
      };
      if (initialOption && option === initialOption) {
        parsedInitialOption = newOption;
      }
    } else {
      // Allow some variant in whats allow so data
      // transformation isn't always needed
      const { label = "", name = "", text = "" } = option;
      const newLabel = label || name || text || "";
      newOption = {
        ...option,
        optionType,
        optionIndex: `(N-${index})`,
        label: newLabel,
      };
      // If an initial option was passed in, we need to check its label in the
      // same way we built the new one
      if (initialOption) {
        const { label: initialLabel = "", name: initialName = "", text: initialText = "" } = initialOption;
        const initialOptionLabel = initialLabel || initialName || initialText || "";
        if (initialOptionLabel === newLabel) {
          parsedInitialOption = newOption;
        }
      }
    }

    return newOption;
  });

  return {
    options: parsedOptions,
    initialOption: parsedInitialOption,
  };
};

export const findValidOption = (options = []) => {
  let validOption = null;

  for (let i = 0; i < options.length; ++i) {
    const option = options[i];
    if (!option) continue;
    const { disabled = false } = option;
    if (!disabled) {
      validOption = option;
      break;
    }
  }

  // If nothing was found, go with the first option
  return validOption || options[0];
};
