import { useEffect, useRef } from "react";

export const useEnterKeyPress = () => {
  const buttonRef = useRef(null);
  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "Enter" && !buttonRef.current.disabled) {
        buttonRef.current.click();
      }
    }

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return [buttonRef];
};
