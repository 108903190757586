import { useCallback, useState } from "react";
import { confettiColors } from "../../components/dashboard/onboarding/onboardingDashboardUtils";

const useConfetti = (animationInstanceRef) => {
  const [confettiTriggered, setConfettiTriggered] = useState(false);

  // Confetti Logic
  const getConfettiInstance = useCallback(
    (instance) => {
      animationInstanceRef.current = instance;
    },
    [animationInstanceRef],
  );

  const makeShot = useCallback(
    (particleRatio, opts) => {
      animationInstanceRef.current &&
        animationInstanceRef.current({
          ...opts,
          origin: { x: 0.5, y: 1 },
          colors: confettiColors,
          gravity: 0.7,
          particleCount: Math.floor(200 * particleRatio),
        });
    },
    [animationInstanceRef],
  );

  const fireConfetti = useCallback(() => {
    makeShot(1, {
      spread: 139,
      scalar: 2.5,
      ticks: 250,
      shapes: ["circle", "square"],
      startVelocity: 55,
      decay: 0.91,
    });
    setConfettiTriggered(true);
  }, [makeShot]);

  return [getConfettiInstance, fireConfetti, confettiTriggered];
};

export default useConfetti;
