import { Image, Text, VStack } from "@chakra-ui/react";
import DefaultImg from "@assets/img/icons/adNetworkDefault.png";

export function AdNetworkItem({ name, iconUrl, isSelected, handleSelectedItem }) {
  return (
    <VStack
      _hover={{ borderColor: "brandRed.400", borderWidth: "2px" }}
      _focus={{ borderColor: "brandRed.400", borderWidth: "2px" }}
      as="button"
      h="128px"
      py="24px"
      px="8px"
      gap="16px"
      borderRadius={"8px"}
      border={isSelected ? "2px solid" : "1px solid"}
      borderColor={isSelected ? "brandRed.400" : "dark.100"}
      alignItems={"center"}
      justifyContent={"center"}
      onClick={handleSelectedItem}
    >
      <Image src={iconUrl} fallbackSrc={DefaultImg} w="40px" h="40px" borderRadius="6px" />
      <Text textTransform={"capitalize"} fontWeight={500} color="textWhite.400">
        {name}
      </Text>
    </VStack>
  );
}
