export const PRODUCT_ANALYTICS = "PRODUCT_ANALYTICS";
export const BANKING = "BANKING";
export const AD_NETWORKS = "AD_NETWORKS";

export const AD_NETWORK_STATUS = {
  PULLING_DATA: "PULLING_DATA",
  CONNECTED: "CONNECTED",
  NO_PERMISSIONS: "NO_PERMISSIONS",
  CONNECTOR_ERROR: "CONNECTOR_ERROR",
};

export const AD_NETWORKS_OAUTH_SLUG = {
  facebook: "facebook",
  google: "admob",
};

export const OAUTH_FLOW_ARCHETYPE_ID = "oauth_flow_archetype_id";
