import React, { useRef, useState } from "react";
import { useDragControls } from "framer-motion";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
  Textarea,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { DragHandle } from "@components/builder/DragHandle";

import { reduceInputValues, checkInputsValid } from "./utils";

import { ReactComponent as Trash } from "@assets/img/icons/common/trash-icon.svg";

export const ToggleInput = ({
  draggable = false,
  asTitle = false,
  addButton = false,
  label = "",
  title = "",
  showToggle = true,
  toggled = false,
  inputs = [
    {
      label: "",
      name: "",
      value: "",
      textarea: false,
      validateMsg: "Invalid input",
      validate: () => {},
    },
  ],
  onChange = () => {},
  onDelete = null,
}) => {
  const controls = useDragControls();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const inputRef = useRef(); // Create a reference to the first input for focus

  const [isHovered, setIsHovered] = useState(false);
  const [isToggled, setIsToggled] = useState(toggled);
  const [inputValues, setInputValues] = useState(reduceInputValues(inputs));
  const [isValid, setIsValid] = useState(checkInputsValid(inputValues));

  const id = (label || title || "").toLowerCase().replace(" ", "-") + "-toggle-id";

  const handleOpen = () => {
    onOpen();
    // Focus the first input after the modal opens
    setTimeout(() => {
      if (inputRef.current) {
        return inputRef.current.focus();
      }
    }, 0);
  };

  const handleClose = (opts = {}) => {
    const { reset = false } = opts;
    if (reset || addButton) {
      const initialInputValues = reduceInputValues(inputs);
      setInputValues(initialInputValues);
      setIsValid(checkInputsValid(initialInputValues));
    }
    onClose();
  };

  const sendData = (newValues = {}) => {
    const reducedInputValues = Object.entries(inputValues).reduce((values, [name, inputValue]) => {
      values[name] = inputValue.value;
      return values;
    }, {});
    const updatedValues = {
      ...reducedInputValues,
      ...newValues,
    };
    if (updatedValues.visible) {
      setIsToggled(true);
    } else {
      setIsToggled(false);
    }
    onChange(updatedValues);
  };

  return (
    <>
      {addButton && (
        <Box w="100%" mt="8px" pl="12px" pr="12px">
          <Button
            leftIcon={<AddIcon h="12px" w="12px" />}
            iconSpacing="12px"
            color="white"
            bg="transparent"
            border={"var(--border-dark-300)"}
            width="100%"
            onClick={() => {
              handleOpen();
            }}
          >
            {label}
          </Button>
        </Box>
      )}

      {!addButton && (
        <FormControl
          pr={"12px"}
          _hover={{ background: "transparents.dark" }}
          onMouseEnter={() => {
            setIsHovered(true);
          }}
          onMouseLeave={() => {
            setIsHovered(false);
          }}
        >
          <HStack alignItems="center" gap={0}>
            {onDelete && (
              <Box
                maxWidth={isHovered ? "24px" : "0"}
                ml={isHovered ? "4px" : "0"}
                overflow="hidden"
                transition="all 0.3s ease"
              >
                <IconButton
                  variant={"unstyled"}
                  p={"0"}
                  minW={"0"}
                  w={"24px"}
                  h={"24px"}
                  size={"sm"}
                  onClick={onDelete}
                  aria-label="Delete"
                  _hover={{ background: "transparents.medium" }}
                >
                  <Box mt="-3px">
                    <Icon as={Trash} w={"16px"} h={"16px"} />
                  </Box>
                </IconButton>
              </Box>
            )}

            <Button
              p={"12px 0 12px 12px"}
              m="0"
              variant={"link"}
              flexGrow={1}
              justifyContent="start"
              onClick={() => {
                handleOpen();
              }}
            >
              <Text
                fontWeight={asTitle && 600}
                color={asTitle ? "textWhite.400" : "textDefault.400"}
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                pr={"6px"}
              >
                {label}
              </Text>
            </Button>

            {showToggle && (
              <Switch
                size="sm"
                id={id}
                isChecked={isToggled}
                onChange={(e) => {
                  if (!isValid) {
                    handleOpen();
                    setIsToggled(false);
                    return;
                  }
                  if (e.target.checked) {
                    sendData({ visible: true });
                  } else {
                    sendData({ visible: false });
                  }
                }}
              />
            )}

            {draggable && <DragHandle controls={controls} />}
          </HStack>
        </FormControl>
      )}

      <Modal
        size="md"
        isCentered
        isOpen={isOpen}
        onClose={() => {
          handleClose({ reset: true });
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton
            onClick={() => {
              handleClose({ reset: true });
            }}
          />
          <ModalHeader>{title || label}</ModalHeader>
          <ModalBody>
            <VStack spacing={"16px"}>
              {inputs.map((input, index) => {
                const {
                  label = "",
                  name = "",
                  textarea = false,
                  validateMsg = "",
                  validate = () => {
                    return true;
                  },
                } = input;

                const invalid = !inputValues[name].valid && inputValues[name].touched;

                const onInputChange = (value) => {
                  const newInputValues = {
                    ...inputValues,
                    [name]: {
                      value,
                      valid: validate(value),
                      touched: true,
                    },
                  };
                  setInputValues(newInputValues);
                  setIsValid(checkInputsValid(newInputValues));
                };

                return (
                  <FormControl key={index}>
                    <FormLabel mb="8px" htmlFor={`${id}-${name}`}>
                      <Text fontSize="sm" fontWeight="600">
                        {label}
                      </Text>
                    </FormLabel>
                    {textarea ? (
                      <Textarea
                        ref={index === 0 ? inputRef : null}
                        variant={"builder"}
                        borderColor={invalid && "brandRed.400"}
                        value={inputValues[name].value}
                        onChange={(e) => onInputChange(e.target.value)}
                      />
                    ) : (
                      <Input
                        ref={index === 0 ? inputRef : null}
                        borderColor={invalid && "brandRed.400"}
                        size="sm"
                        id={`${id}-${name}`}
                        value={inputValues[name].value}
                        onChange={(e) => onInputChange(e.target.value)}
                      />
                    )}
                    {invalid && (
                      <Text mt={"5px"} ml={"5px"} color={"brandRed.400"}>
                        {validateMsg}
                      </Text>
                    )}
                  </FormControl>
                );
              })}
            </VStack>
          </ModalBody>

          <ModalFooter justifyContent={"space-between"} gap={"16px"}>
            <Button
              flex="1"
              variant="solid"
              onClick={() => {
                handleClose({ reset: true });
              }}
            >
              Cancel
            </Button>
            <Button
              flex="1"
              variant="primary"
              isDisabled={!isValid}
              onClick={() => {
                sendData({
                  visible: true,
                });
                handleClose();
              }}
            >
              Connect
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
