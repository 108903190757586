import { useLocation, useNavigate } from "react-router-dom";
import { scrollToTarget } from "@src/utils/general";
import { Text, VStack } from "@chakra-ui/react";

const PLATFORMS = "platforms";

export const ReconnectTooltip = ({ source = PLATFORMS }) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <VStack gap={"8px"}>
      <Text size={"smallAlt"} color={"textWhite.400"} fontWeight={400}>
        One or more of your {source} have lost connection.
      </Text>
      <Text
        alignSelf={"flex-end"}
        size={"mini"}
        fontWeight={500}
        cursor={"pointer"}
        color={"brandRed.400"}
        onClick={() => {
          navigate("/integrations", { state: { prevPath: location.pathname } });
          setTimeout(() => {
            scrollToTarget(source === PLATFORMS ? "integration-platforms-target" : "integration-banking-target");
          }, 250);
        }}
      >
        Reconnect -&gt;
      </Text>
    </VStack>
  );
};
