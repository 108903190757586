import { progressAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(progressAnatomy.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  filledTrack: {
    bg: "brandElectricBlue.400",
    borderRadius: "full",
  },
  track: {
    borderRadius: "full",
    bg: "dark.400",
  },
});

export const Progress = defineMultiStyleConfig({ baseStyle });
