import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

// This function creates a set of function that helps us create multipart component styles.
const helpers = createMultiStyleConfigHelpers(["menu", "list", "item"]);

export const Menu = helpers.defineMultiStyleConfig({
  baseStyle: {
    menu: {},
    list: {
      borderRadius: "10px",
      padding: "8px",
      cursor: "default",
      border: "none",
      background: "dark.600",
      boxShadow: "0px 0px 48px 0px rgba(0, 0, 0, 0.4)",
    },
    item: {
      minWidth: "0",
      padding: "8px",
      fontWeight: "500",
      fontSize: "sm",
      lineHeight: "16px",
      letterSpacing: "0.01em",
      background: "dark.600",
      cursor: "pointer",
      borderRadius: "8px",
      _notFirst: { marginTop: "4px" },
    },
  },
  sizes: {},
  variants: {
    appMetricFilter: {
      list: { width: "242px", alignItems: "flex-start" },
    },
    dropdown: {
      list: { minWidth: "0", width: "min-content" },
    },
  },
  defaultProps: {
    colorScheme: "dark",
  },
});
