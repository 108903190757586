import styled from "styled-components";

import { BodySmallAltCSS } from "@styled";

export const StepDescription = styled.div`
  ${BodySmallAltCSS}

  margin-left: 16px;
  margin-top: 8px;
  color: ${({ theme }) => theme.accordionDescriptionFontColor};
  display: block;
`;
