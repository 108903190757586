import styled from "styled-components";

const StyledPagination = styled.div`
  & {
    margin: 16px 0;
    display: flex;
    justify-content: flex-end;

    .current-page {
      height: 24px;
      display: flex;
      align-items: center;
      margin: 0 8px;
      padding: 4px 8px;
      border-radius: 4px;
      cursor: pointer;

      :hover {
        background-color: ${({ theme }) => theme.grayscale70};
      }
    }

    .pagination-number {
      display: flex;
      align-items: center;
      height: 24px;
      color: ${({ theme }) => theme.textSubtext};
      border-radius: 4px;
      margin: 0 8px;
      padding: 4px 8px;
      cursor: pointer;

      &.current-number {
        color: ${({ theme }) => theme.textSeashellWhite};
      }

      :hover {
        background-color: ${({ theme }) => theme.grayscale70};
      }
    }

    .pagination-arrow {
      width: 24px;
      height: 24px;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      > svg {
        width: 24px;
        height: 24px;
        fill: ${({ theme }) => theme.textGray};
      }

      :hover {
        background-color: ${({ theme }) => theme.grayscale70};
      }

      &.pagination-disabled {
        cursor: not-allowed;

        > svg {
          fill: ${({ theme }) => theme.textSubtext};
        }

        :hover {
          background-color: transparent;
        }
      }
    }

    .prev-page-icon {
      margin-right: 2px;
      transform: rotate(270deg);
    }
    .next-page-icon {
      margin-left: 2px;
      transform: rotate(90deg);
    }
  }
`;

export default StyledPagination;
