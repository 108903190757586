import styled from "styled-components";

export const StyledDailyRevenueGames = styled.div`
  grid-area: games;
  padding-top: 0.5rem;
  padding-right: 1rem;
  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    height: 0;
    width: 0.5rem;
    cursor: default !important;
    transform: translateX(10px);
  }

  &::-webkit-scrollbar-track {
    visibility: hidden;
    background-color: ${({ theme }) => theme.inputBackgroundColor};
    opacity: 0.5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(51, 51, 56, 1);
    border-radius: 1.25rem;
    opacity: 0.5;
  }

  .pg-game {
    height: 40px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    border: 2px solid transparent;
    border-radius: 0.5rem;
    margin-bottom: 0.5rem;

    .pg-game-drag-handle {
      position: relative;
      display: inline-block;
      width: 0.875rem;
      height: 0.875rem;
      margin-left: 0.5rem;
      border-radius: 0.25rem;
      flex-shrink: 0;

      .pg-game-drag-handle-1,
      .pg-game-drag-handle-2,
      .pg-game-drag-handle-3 {
        position: absolute;
        top: 50%;
        left: 50%;
        height: 3px;
        width: 8px;
        transform: translateX(-50%) translateY(-50%);

        &::before,
        &::after {
          content: "";
          width: 2px;
          height: 2px;
          border-radius: 100%;
          position: absolute;
          background-color: white;
          background-color: rgba(255, 255, 255, 0.5);
        }
        &::before {
          left: 0;
        }
        &::after {
          right: 0;
        }
      }
      .pg-game-drag-handle-1 {
        transform: translateX(-50%) translateY(-6px);
      }
      .pg-game-drag-handle-3 {
        transform: translateX(-50%) translateY(3px);
      }
    }

    .pg-game-checkbox {
      position: relative;
      display: inline-block;
      width: 0.875rem;
      height: 0.875rem;
      margin-left: 0.5rem;
      border-radius: 0.25rem;
      border: 2px solid var(--game-color);
      flex-shrink: 0;
      cursor: pointer;

      .pg-game-checkbox-mark {
        display: none;
        position: relative;
        width: 100%;
        height: 100%;
        transform: rotateZ(45deg);

        &::before {
          content: "";
          position: absolute;
          width: 2px;
          height: 9px;
          background-color: #000;
          left: 7px;
          top: 2px;
        }

        &::after {
          content: "";
          position: absolute;
          width: 3px;
          height: 2px;
          background-color: #000;
          left: 4px;
          top: 9px;
        }
      }

      &.pg-game-checkbox-checked {
        background-color: var(--game-color);
        border: none;

        .pg-game-checkbox-mark {
          display: block;
        }
      }
    }

    .pg-game-data {
      display: inline-flex;
      align-items: center;
      overflow: hidden;

      .pg-game-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 0.5rem;
        overflow: hidden;

        .pg-game-name {
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .pg-game-platform {
          opacity: 0.75;
          text-transform: capitalize;
        }
      }

      .pg-game-icon {
        display: inline-block;
        height: 1.5rem;
        width: 1.5rem;
        border-radius: 0.25rem;
        vertical-align: top;
        margin-left: 0.5rem;
        &.pg-game-icon-placeholder {
          background-color: rgba(255, 255, 255, 0.05);
        }
      }
    }

    &.pg-temp-game {
      opacity: 0.6;
      border: 2px dashed #ffffff20;
      background-color: #ffffff20;
    }

    &.pg-other {
      padding-left: 1.375rem;
      margin-bottom: 0;
      &.collapsed {
        padding-left: 0;
      }
    }

    &.pg-game-drop-target {
      &.pg-temp-game,
      &.pg-top-game {
        opacity: 0.6;
        border: 2px dashed var(--game-color);
        background-color: var(--game-color-weak);
      }
      &.pg-other,
      &.pg-other-game {
        border-radius: 0;
        border-bottom: 2px solid #ffffff80;
      }
    }

    &:not(.pg-other):hover {
      background-color: ${({ theme }) => theme.grayscale40};
      cursor: grab;
    }

    &:not(.pg-other):active {
      cursor: grabbing;
    }

    &.dr-loading {
      color: transparent;
      background-color: gray;
      * {
        visibility: hidden;
      }
    }
  }

  .pg-others-wrapper {
    border-top: 1px solid ${({ theme }) => theme.grayscale70};
    margin-top: 0.5rem;
  }
`;
