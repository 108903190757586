import { useRef, useEffect } from "react";
import { trackGTMEvent } from "./index";

export const useTrackHover = () => {
  const timeoutRef = useRef(null);

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  const addTracking = ({ event, name, state, tooltip, card, pathname }) => {
    timeoutRef.current = setTimeout(() => {
      trackGTMEvent({
        event: event || "",
        name: name || "",
        state: state || "",
        tooltip: tooltip || "",
        card: card || "",
        pathname: pathname || "",
      });
    }, 2500);
  };

  const removeTracking = () => {
    clearTimeout(timeoutRef.current);
  };

  return [addTracking, removeTracking];
};
