import React, { useState } from "react";
import { Button, Box, Flex, Icon, Image, Menu, MenuButton, MenuList, MenuItem, Text } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { gameMetricsThunks } from "@redux/game-metrics/gameMetricsSlice";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";

import { ReactComponent as AccordionArrow } from "@assets/img/icons/common/accordion-arrow.svg";
import { ReactComponent as ListUnordered } from "@assets/img/icons/common/list-unordered.svg";

const DEFAULT_IMAGE = require("@assets/img/icons/adNetworkDefault.png");

const AllApps = ({ isSelected, onClick }) => {
  return (
    <Flex
      size={"3xl"}
      alignItems={"center"}
      borderRadius={"10px"}
      w={"100%"}
      padding={"8px"}
      cursor={"pointer"}
      backgroundColor={isSelected ? "brandRed.400" : "transparent"}
      onClick={onClick}
    >
      <Box mr={"8px"} padding={"4px"} background={"dark.300"} borderRadius={"4px"}>
        <Icon as={ListUnordered} h={"25px"} w={"25px"} />
      </Box>
      <Text
        size={"small"}
        fontWeight={"500"}
        lineHeight={"16px"}
        letterSpacing={"0.14"}
        align={"center"}
        color={isSelected ? "dark.900" : "textWhite.400"}
      >
        All Platforms
      </Text>
    </Flex>
  );
};

const AppOption = ({ app = {}, imgSize = "32px", color = "textWhite.400", secondColor = "textDefault.400" }) => {
  const { icon = "", name = "", platform = {} } = app;
  return (
    <Flex alignItems={"center"}>
      <Image
        h={imgSize}
        w={imgSize}
        borderRadius={"4px"}
        mr={"8px"}
        src={icon || DEFAULT_IMAGE}
        alt={name}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = DEFAULT_IMAGE;
        }}
      />
      <Flex direction={"column"} spacing={"0"}>
        <Text noOfLines={1} align={"left"} size={"small"} letterSpacing={"0.14px"} fontWeight={"500"} color={color}>
          {name}
        </Text>
        <Text align={"left"} size={"mini"} letterSpacing={"0.24px"} color={secondColor}>
          {platform?.name}
        </Text>
      </Flex>
    </Flex>
  );
};

export const AppFilter = ({ filterOptions = [], appliedFilters = {} }) => {
  const dispatch = useDispatch();
  const [appSelected, setAppSelected] = useState({});

  const handleSelectApp = (app = {}) => {
    if (!isEqual(app, appSelected)) {
      setAppSelected(app);
      dispatch(gameMetricsThunks.getGameMetrics({ ...appliedFilters, appIds: app.id ? [app.id] : [] }));
    }
  };

  const quantity = filterOptions.length;
  const moreFiveApps = quantity > 5;
  const hasFilter = !isEmpty(appSelected);

  return (
    <Menu variant={"appMetricFilter"} offset={[0, 8]} placement="bottom-end">
      {({ isOpen, onClose }) => (
        <>
          <MenuButton
            as={Button}
            data-test-id="filter-menu-button"
            pr={"12px"}
            pl={hasFilter ? "8px" : "12px"}
            _hover={{}}
            _active={{}}
            rightIcon={
              <Icon
                as={AccordionArrow}
                width={"16px"}
                height={"16px"}
                opacity={"0.7"}
                fill={"textDefault.400"}
                transform={isOpen ? "rotate(0)" : "rotate(180deg)"}
                transition={"transform 0.2s ease"}
              />
            }
          >
            {hasFilter ? (
              <AppOption app={appSelected} imgSize={"28px"} />
            ) : (
              <Text size={"small"} color={"textWhite.400"} fontWeight={"500"} letterSpacing={"0.14px"}>
                {`All Platforms (${quantity})`}
              </Text>
            )}
          </MenuButton>
          <MenuList
            data-test-id="filter-menu-list"
            maxHeight={moreFiveApps ? "325px" : ""}
            overflowY={moreFiveApps ? "scroll" : ""}
            sx={
              moreFiveApps
                ? {
                    "&::-webkit-scrollbar": {
                      width: "20px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      borderRight: "6px solid transparent",
                      borderLeft: "6px solid transparent",
                      backgroundColor: "dark.300",
                      backgroundClip: "padding-box",
                      borderRadius: "20px",
                    },
                  }
                : {}
            }
          >
            <AllApps
              isSelected={!hasFilter}
              onClick={() => {
                handleSelectApp();
                onClose();
              }}
            />
            {filterOptions.map((app) => {
              const { id } = app;
              const isSelected = app.id === appSelected.id;
              return (
                <MenuItem
                  key={`key-filter-${id}`}
                  data-test-id={`filter-menu-item-${id}`}
                  backgroundColor={isSelected ? "brandRed.400" : "transparent"}
                  onClick={() => {
                    handleSelectApp(app);
                  }}
                >
                  <AppOption
                    key={id}
                    app={app}
                    color={isSelected ? "dark.900" : "textWhite.400"}
                    secondColor={isSelected ? "dark.900" : "textDefault.400"}
                  />
                </MenuItem>
              );
            })}
          </MenuList>
        </>
      )}
    </Menu>
  );
};
