import { Button, Heading, HStack, Icon, Text, VStack } from "@chakra-ui/react";
import { ReactComponent as WarningIcon } from "@assets/img/icons/common/alertTriangle.svg";
import { useSelector } from "react-redux";
import { typographySelector } from "@redux/webshops/builder/typographySlice";

export function RemoveFontConfirmation({ onClose, removeFont }) {
  const { removeCustomFont } = useSelector(typographySelector);
  return (
    <VStack alignItems="stretch" h="full">
      <Heading size="regular" fontWeight={600} pb="16px">
        Delete Font?
      </Heading>
      <HStack bg="transparents.red.200" p="12px" borderRadius="10px">
        <Icon as={WarningIcon} color="brandRed.400" />
        <Text color={"brandRed.400"} size={"smallAlt"} fontWeight={500}>
          WARNING: If you’re using this font in your shop, this will delete it and any custom type styles, resetting to
          the defaults.
        </Text>
      </HStack>
      <HStack mt="auto" justifyContent={"space-between"}>
        <Button h="32px" onClick={onClose}>
          Cancel
        </Button>
        <Button h="32px" variant="primary" onClick={removeFont} isLoading={removeCustomFont.requestStatus.pending}>
          Delete Font
        </Button>
      </HStack>
    </VStack>
  );
}
