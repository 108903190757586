import SanloWebApiService from "./services";

import earningsActions, {
  ALL_GET_EARNINGS,
  ALL_GET_PREVIOUS_EARNINGS,
  AD_REVENUE_GET_EARNINGS,
  AD_REVENUE_GET_PREVIOUS_EARNINGS,
  APP_STORE_GET_EARNINGS,
  APP_STORE_GET_PREVIOUS_PERIOD_EARNINGS,
  APP_STORE_REFRESH_EARNINGS,
  GOOGLE_PLAY_GET_EARNINGS,
  GOOGLE_PLAY_GET_PREVIOUS_PERIOD_EARNINGS,
} from "./actions";

import { checkNoContent } from "@utils/redux";

import { getEndOfPeriodResponse, getMonthlyPeriods, getPreviousMonthlyPeriods } from "./utils";

const earningsMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    switch (action.type) {
      case ALL_GET_EARNINGS.REQUEST: {
        const { platformIds } = action;
        const monthlyPeriods = getMonthlyPeriods();
        if (!platformIds) {
          dispatch(earningsActions.allGetEarnings.error());
          return;
        }
        SanloWebApiService.allGetEarnings(platformIds, monthlyPeriods)
          .then((response) => response.data)
          .then((response) => {
            dispatch(
              earningsActions.allGetEarnings.response({
                monthlyPeriods,
                earnings: {
                  periodEarnings: response,
                },
              }),
            );
          })
          .catch((error) => {
            dispatch(earningsActions.allGetEarnings.error(error));
          });
        break;
      }

      case ALL_GET_PREVIOUS_EARNINGS.REQUEST: {
        const { platformIds, callback } = action;
        const monthlyPeriods = getState().earnings.all.monthlyPeriods;
        const previousMonthlyPeriods = getPreviousMonthlyPeriods(monthlyPeriods);
        if (!platformIds) {
          dispatch(earningsActions.allGetPreviousEarnings.error());
          return;
        }
        SanloWebApiService.allGetEarnings(platformIds, previousMonthlyPeriods)
          .then((response) => response.data)
          .then((response) => {
            dispatch(
              earningsActions.allGetPreviousEarnings.response({
                previousMonthlyPeriods,
                earnings: {
                  periodEarnings: response,
                },
              }),
            );
            callback();
          })
          .catch((error) => {
            dispatch(earningsActions.allGetPreviousEarnings.error(error));
          });
        break;
      }

      case AD_REVENUE_GET_EARNINGS.REQUEST:
        SanloWebApiService.adRevenueGetEarnings(action.networkId)
          .then((response) => {
            dispatch(
              earningsActions.adRevenueGetEarnings.response({
                networkId: action.networkId,
                data: {
                  timestamp: null,
                  periodEarnings: response.data,
                },
              }),
            );
          })
          .catch((error) => {
            dispatch(
              earningsActions.adRevenueGetEarnings.error({
                networkId: action.networkId,
                data: error,
              }),
            );
          });
        break;

      case AD_REVENUE_GET_PREVIOUS_EARNINGS.REQUEST: {
        SanloWebApiService.adRevenueGetPreviousEarnings(action.networkId, action.periodId)
          .then((response) => {
            if (checkNoContent(response)) {
              // If there is no content we don't need to send anything to the reducer. We still
              // need to send the no_more_periods data back to the component though
              dispatch(earningsActions.adRevenueGetPreviousEarnings.response({}));
              action.callback("no_more_periods");
            } else {
              const periodData = {
                networkId: action.networkId,
                previousPeriod: response.data,
              };
              dispatch(earningsActions.adRevenueGetPreviousEarnings.response(periodData));
              action.callback(periodData);
            }
          })
          .catch((error) => {
            dispatch(
              earningsActions.adRevenueGetPreviousEarnings.error({
                networkId: action.networkId,
                data: error,
              }),
            );
            action.callback(null);
          });
        break;
      }

      case APP_STORE_REFRESH_EARNINGS.REQUEST:
        SanloWebApiService.appStoreRefreshEarnings()
          .then((response) => response.data)
          .then((response) => {
            dispatch(earningsActions.appStoreRefreshEarnings.response(response));
            dispatch(earningsActions.appstoreGetEarnings.request());
          })
          .catch((error) => {
            dispatch(earningsActions.appStoreRefreshEarnings.error(error));
          });
        break;

      case APP_STORE_GET_EARNINGS.REQUEST: {
        const appstore = getState().integrations.appstore;
        const { connected = false } = appstore;
        if (!connected) return dispatch(earningsActions.appstoreGetEarnings.error());

        SanloWebApiService.appStoreGetEarnings()
          .then((response) => {
            const earningsData = checkNoContent(response) ? {} : response.data;
            dispatch(earningsActions.appstoreGetEarnings.response(earningsData));
          })
          .catch((error) => {
            dispatch(earningsActions.appstoreGetEarnings.error(error));
          });
        break;
      }

      case APP_STORE_GET_PREVIOUS_PERIOD_EARNINGS.REQUEST:
        SanloWebApiService.appStoreGetPreviousEarnings(action.fiscalPeriodId)
          .then((response) => response.data)
          .then((previousPeriod) => {
            dispatch(earningsActions.appstoreGetPreviousPeriodEarnings.response(previousPeriod));
            action.callback(previousPeriod);
          })
          .catch((error) => {
            dispatch(earningsActions.appstoreGetPreviousPeriodEarnings.error(error));
            action.callback(getEndOfPeriodResponse(error));
          });
        break;

      case GOOGLE_PLAY_GET_EARNINGS.REQUEST: {
        const googleplay = getState().integrations.googleplay;
        const { connected = false } = googleplay;
        if (!connected) return dispatch(earningsActions.googleplayGetEarnings.error());

        SanloWebApiService.googlePlayGetEarnings()
          .then((response) => {
            if (checkNoContent(response)) {
              dispatch(earningsActions.googleplayGetEarnings.response({}));
            } else {
              dispatch(earningsActions.googleplayGetEarnings.response(response.data));
            }
          })
          .catch((error) => {
            dispatch(earningsActions.googleplayGetEarnings.error(error));
          });
        break;
      }

      case GOOGLE_PLAY_GET_PREVIOUS_PERIOD_EARNINGS.REQUEST:
        SanloWebApiService.googlePlayGetPreviousEarnings(action.fiscalPeriodId)
          .then((response) => response.data)
          .then((previousPeriod) => {
            dispatch(earningsActions.googleplayGetPreviousPeriodEarnings.response(previousPeriod));
            action.callback(previousPeriod);
          })
          .catch((error) => {
            dispatch(earningsActions.googleplayGetPreviousPeriodEarnings.error(error));
            action.callback(getEndOfPeriodResponse(error));
          });
        break;

      default:
        return;
    }
  };

export default earningsMiddleware;
