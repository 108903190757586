import React, { useRef, useState } from "react";
import classnames from "classnames";

import { BodyMini12, BodySmallPlus } from "@styled";
import { StyledDailyRevenueGames } from "./DailyRevenueGamesStyles";
import { SanloTooltip } from "@components/common/tooltips/sanlo-tooltip/SanloTooltip";

import { swapGames } from "../../utils/dailyRevenueUtils";
import { isConnectedStatus } from "@utils/status";
import { camelToSplit } from "@utils/string";

const transparentPixel = require("@assets/img/icons/transparent.png");

export const DailyRevenueGames = (props) => {
  const {
    selectedOption = {},
    isLoading = true,
    collapsed = false,
    theme = {},
    selectedOptionGameData,
    onToggle,
    onOrderChange,
    isNothingConnected = false,
  } = props;
  const [dragTarget, setDragTarget] = useState({});
  const movingGameRef = useRef(null);
  const otherEnabled = selectedOptionGameData.other.enabled;

  const DragHandle = () => (
    <div className="pg-game-drag-handle">
      <div className="pg-game-drag-handle-1" />
      <div className="pg-game-drag-handle-2" />
      <div className="pg-game-drag-handle-3" />
    </div>
  );

  const topSectionItem = (game) => {
    if (game.other) return null;
    if (game.temp) return topSectionTemp(game);
    return topSectionsGame(game);
  };

  const topSectionTemp = (game) => {
    return (
      <div
        style={{
          "--game-color": game.color,
          "--game-color-weak": game.color + "20",
        }}
        className={classnames("pg-game pg-temp-game", {
          "pg-game-drop-target": dragTarget.gameId === game.gameId,
        })}
        onDragOver={(e) => {
          e.preventDefault();
          setDragTarget(game);
        }}
      >
        <div className="pg-game-checkbox cursor-not-allowed" />
        <div className="pg-game-data">
          <div className="pg-game-text">
            <BodySmallPlus className="pg-game-name">{!collapsed ? "Drop item here" : "..."}</BodySmallPlus>
          </div>
        </div>
      </div>
    );
  };

  const GameIconName = ({ game }) => {
    return (
      <div className="pg-game-data" id={`tt-target-drg-${game.gameId}`}>
        {/* eslint-disable-next-line */}
        <img
          className={classnames("pg-game-icon", {
            "pg-game-icon-placeholder": !game.gameIcon,
          })}
          src={game.gameIcon || transparentPixel}
          onError={(e) => {
            e.currentTarget.classList.add("pg-game-icon-placeholder");
            if (e.currentTarget.src !== transparentPixel) {
              e.currentTarget.src = transparentPixel;
            }
          }}
        />
        {!collapsed && (
          <div className="pg-game-text">
            <BodySmallPlus className="pg-game-name">{game.name}</BodySmallPlus>
            {game.platform && <BodyMini12 className="pg-game-platform">{camelToSplit(game.platform)}</BodyMini12>}
          </div>
        )}

        <SanloTooltip target={`tt-target-drg-${game.gameId}`} text={game.name} placement="right" />
      </div>
    );
  };

  const topSectionsGame = (game) => {
    return (
      <div
        key={`dr-game-${game.gameId}`}
        style={{
          "--game-color": game.color,
          "--game-color-weak": game.color + "20",
        }}
        className={classnames("pg-game pg-top-game", {
          "pg-game-drop-target": dragTarget.gameId === game.gameId,
          "dr-loading": isLoading,
        })}
        draggable="true"
        onDragStart={(e) => {
          movingGameRef.current = game;
        }}
        onDragOver={(e) => {
          e.preventDefault();
          setDragTarget(game);
        }}
        onDragEnd={(e) => {
          onOrderChange(swapGames(selectedOptionGameData, movingGameRef.current, dragTarget));
          setDragTarget({});
        }}
      >
        {otherEnabled && !collapsed && <DragHandle />}
        <div
          className={classnames("pg-game-checkbox", {
            "pg-game-checkbox-checked": game.selected,
          })}
          onClick={() => {
            onToggle(game.gameId, !game.selected);
          }}
        >
          <div className="pg-game-checkbox-mark" />
        </div>

        <GameIconName game={game} />
      </div>
    );
  };

  // Unconnected platforms shouldn't have any data to show games in the first place
  // but we'll add a check here anyway. We want this to pass when nothing is connected
  // show the fake games show up as part of the blurred background
  if (!selectedOption.isAll && !isConnectedStatus(selectedOption) && !isNothingConnected) return null;

  return (
    <StyledDailyRevenueGames>
      <div className="pg-wrapper">
        {selectedOptionGameData.order.map((game) => {
          return topSectionItem(game);
        })}

        {(() => {
          if (!otherEnabled) return null;

          return (
            <div className="pg-others-wrapper">
              <div
                key={`dr-game-other-category`}
                className={classnames("pg-game pg-other", {
                  "collapsed": collapsed,
                  "pg-game-drop-target": dragTarget.gameId === "other",
                })}
                onDragOver={(e) => {
                  e.preventDefault();
                  setDragTarget({
                    name: "other",
                    gameId: "other",
                  });
                }}
                onDragEnd={(e) => {
                  onOrderChange(swapGames(selectedOptionGameData, movingGameRef.current, "other"));
                  setDragTarget({});
                }}
              >
                <div
                  style={{
                    "--game-color": theme.colorPink,
                  }}
                  className={classnames("pg-game-checkbox", {
                    "pg-game-checkbox-checked": selectedOptionGameData.other.selected,
                  })}
                  onClick={() => {
                    onToggle("other", !selectedOptionGameData.other.selected);
                  }}
                >
                  <div className="pg-game-checkbox-mark" />
                </div>

                <div className="pg-game-data">
                  <div className="pg-game-text">
                    <BodySmallPlus className="pg-game-name">Other</BodySmallPlus>
                  </div>
                </div>
              </div>

              {selectedOptionGameData.order.map((game) => {
                if (!game.other) return null;
                return (
                  <div
                    key={`dr-game-${game.gameId}`}
                    className={classnames("pg-game pg-other-game", {
                      "pg-game-drop-target": dragTarget.gameId === game.gameId,
                    })}
                    draggable="true"
                    onDragStart={(e) => {
                      movingGameRef.current = game;
                    }}
                    onDragOver={(e) => {
                      e.preventDefault();
                      setDragTarget(game);
                    }}
                    onDragEnd={(e) => {
                      onOrderChange(swapGames(selectedOptionGameData, movingGameRef.current, dragTarget));
                      setDragTarget({});
                    }}
                  >
                    <DragHandle />
                    <GameIconName game={game} />
                  </div>
                );
              })}
            </div>
          );
        })()}
      </div>
    </StyledDailyRevenueGames>
  );
};
