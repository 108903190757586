import { accordionAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(accordionAnatomy.keys);

const cashActivityCategories = definePartsStyle({
  root: {
    width: "100%",
  },
  container: {
    padding: "8px",
    border: "none",
    width: "100%",
    _hover: { bg: "dark.500", borderRadius: "8px" },
  },
  button: {
    width: "auto",
    padding: "0",
  },
  panel: {
    p: 0,
    pl: "8px",
    _first: {
      marginTop: "8px",
    },
  },
});

const catalog = definePartsStyle({
  root: {
    width: "100%",
  },
  container: {
    "border": "none",
    "width": "100%",
    ".chakra-collapse": {
      overflow: "visible !important",
    },
  },
  button: {
    width: "auto",
    padding: "24px 0 24px 0",
  },
  panel: {
    p: 0,
    paddingBottom: "24px",
  },
});

export const Accordion = defineMultiStyleConfig({
  variants: { cashActivityCategories, catalog },
});
