import { useMemo } from "react";

export const isFirefox = navigator.userAgent.indexOf("Firefox") > 0;

export const useQuery = (search) => {
  return useMemo(() => new URLSearchParams(search), [search]);
};
// Refer here
// https://www.geeksforgeeks.org/move-all-values-equal-to-k-to-the-end-of-the-array/

export const moveElementsOfPropertyValueToEndOfArray = (array, propertyValue, property) => {
  // Mark left pointer
  let i = 0;
  const arrayPropByIndex = (indx, prop) => (prop ? array[indx][prop] : array[indx]);

  // Mark the right pointer
  let j = array.length - 1;

  // Iterate until left pointer
  // crosses the right pointer
  while (i < j) {
    while (i < j && arrayPropByIndex(j, property) === propertyValue)
      // Decrement right pointer
      j--;

    if (arrayPropByIndex(i, property) === propertyValue)
      // Swap the two elements
      // in the array
      swap(array, i, j);

    // Increment left pointer
    i++;
  }

  // Return the result
  return array;
};

const swap = (arr, i, j) => {
  let temp = arr[i];
  arr[i] = arr[j];
  arr[j] = temp;
  return arr;
};
