import React from "react";
import moment from "moment";
import classnames from "classnames";
import { useFlags } from "launchdarkly-react-client-sdk";

import { BodySmallAlt, BodySmallPlus } from "@styled";
import { StyledTooltipStack } from "./DailyRevenueTooltipsStyles";
import TooltipRow from "@common/tooltips/data-tooltip/TooltipRow";

import { currencyFormat } from "@utils/currency";
import { parseDataSourceStatus } from "../../utils/dailyRevenueUtils";
import tooltipsCopies from "@utils/text/cashEarningsChart";
import symbols from "@utils/symbols";

const joinHintContent = (hint) => {
  if (!hint) return;
  const { header, description } = hint;
  return (
    <div className="tt-hint-content">
      {header}
      {description}
    </div>
  );
};

export const DailyEarningsTooltip = ({
  dayData,
  selectedOptionGameData,
  hoveredGame,
  dataSourceStatus,
  selectedOption,
}) => {
  const { connectBankAccountCopyVisible } = useFlags();
  const { games: dayGamesData } = dayData;

  const periodStatus = parseDataSourceStatus(dataSourceStatus, false, connectBankAccountCopyVisible);
  const tooltipInfo = tooltipsCopies[selectedOption.id]?.dailyEarnings[periodStatus];

  if (!dayGamesData.order.length) {
    let tooltipText = "No Earnings";
    if (selectedOption.isAdNetwork && !dayData.isComplete) {
      tooltipText = "We're still synchronizing revenue for this day";
    }
    return (
      <StyledTooltipStack className="tt-blank-day">
        <BodySmallPlus>{tooltipText}</BodySmallPlus>
      </StyledTooltipStack>
    );
  }

  let visibleGamesTotal = 0;
  selectedOptionGameData.order.forEach((game) => {
    if (!game.selected) return;
    const dayGameData = dayGamesData.byId[game.gameId] || {};
    visibleGamesTotal += dayGameData.earning || 0;
  });

  return (
    <StyledTooltipStack>
      <div className="tt-total-earned-wrapper">
        <TooltipRow
          title={`${symbols.calendar} ${dayData.isToday ? "Today " : ""} ${moment(dayData.date).format(
            "MMMM DD, YYYY",
          )}`}
          value={currencyFormat(visibleGamesTotal)}
          hint={joinHintContent(tooltipInfo)}
        />
      </div>

      {selectedOptionGameData.order.map((game) => {
        // Only show selected games in the daily tooltip
        if (!game.selected || game.other) return null;
        const dayGameData = dayGamesData.byId[game.gameId] || {};
        // TODO: Check to see if this truncation can be done through css and tooltip sizing
        const displayName = game.name.length > 26 ? game.name.slice(0, 22) + "..." : game.name;

        return (
          <div
            style={{ "--game-color": game.color }}
            key={`tooltip_row_key_${game.gameId}`}
            className={classnames("tt-game-wrapper", {
              color: Boolean(selectedOptionGameData.map[game.gameId].color),
              selected: hoveredGame && hoveredGame.gameId === game.gameId,
            })}
          >
            <BodySmallAlt className="tt-game-name">{displayName}</BodySmallAlt>
            <BodySmallAlt className="tt-game-earning">{currencyFormat(dayGameData.earning)}</BodySmallAlt>
          </div>
        );
      })}

      {(() => {
        if (!selectedOptionGameData.other.enabled) return null;

        let otherGamesTotal = 0;
        selectedOptionGameData.order.forEach((game) => {
          if (!game.other) return;
          const dayGameData = dayGamesData.byId[game.gameId] || {};
          otherGamesTotal += dayGameData.earning || 0;
        });

        return (
          <div
            className={classnames("tt-others-wrapper", {
              selected: hoveredGame && hoveredGame.gameId === "Other",
            })}
          >
            <TooltipRow
              title="Other"
              value={currencyFormat(otherGamesTotal)}
              hint={(() => {
                return (
                  <>
                    {selectedOptionGameData.order.map((game) => {
                      // Only show selected games in the daily tooltip
                      if (!game.other) return null;
                      const dayGameData = dayGamesData.byId[game.gameId] || {};
                      // TODO: Check to see if this truncation can be done through css and tooltip sizing
                      const displayName = game.name.length > 26 ? game.name.slice(0, 22) + "..." : game.name;

                      return (
                        <div key={`tooltip_row_key_${game.name}`} className="tt-game-wrapper">
                          <BodySmallAlt className="tt-game-name">{displayName}</BodySmallAlt>
                          <BodySmallAlt className="tt-game-earning">{currencyFormat(dayGameData.earning)}</BodySmallAlt>
                        </div>
                      );
                    })}
                  </>
                );
              })()}
            />
          </div>
        );
      })()}
    </StyledTooltipStack>
  );
};
