import styled from "styled-components";

const StyledUpload = styled.div`
  bacground-color: red;
  .upload-statements-label {
    color: ${({ theme }) => theme.textGray};

    .red-label {
      cursor: pointer;
    }
  }

  .is-uploading {
    opacity: 0.5;
  }

  .upload-statements-cta-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 24px;

    .button-text {
      display: flex;
      justify-content: center;
      align-items: center;
      .button-icon {
        margin-right: 4px;
        fill: ${({ theme }) => theme.grayscale0};
      }
    }

    .drop-files-label {
      margin-top: 8px;
      color: ${({ theme }) => theme.textSubtext};
      text-align: center;
    }
  }

  .upload-statements-state-chip {
    margin-top: 16px;
  }
`;

export const StyledFileSizeWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 8px;
  color: ${({ theme }) => theme.textSubtext};
`;

export const StyledFileList = styled.div`
  .add-more-files-button {
    margin: 5px 0 24px 0;
    cursor: pointer;

    .plus-circle-icon {
      fill: ${({ theme }) => theme.colorRed};
      margin-right: 8px;
    }

    .add-files-label {
      color: ${({ theme }) => theme.colorRed};
    }
  }
`;

export default StyledUpload;
