import React from "react";
import styled from "styled-components";
import { BodySmallPlus } from "../../styled";

const EncourageMessage = ({ color, description, emoji }) => (
  <StyledEncourageConnectionBanner color={color}>
    <BodySmallPlus>
      <span className="light-bulb">{emoji}</span>
      {description}
    </BodySmallPlus>
  </StyledEncourageConnectionBanner>
);

export default EncourageMessage;

const StyledEncourageConnectionBanner = styled.div`
  & {
    margin-top: 16px;
    background: ${({ theme, color }) => theme[`color${color}`]}29;
    color: ${({ theme, color }) => theme[`color${color}`]};
    padding: 12px;
    border-radius: 10px;
    margin-bottom: 16px;

    .light-bulb {
      margin-right: 8px;
    }
  }
`;
