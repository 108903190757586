import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import actions from "@redux/actions";

import { BodyRegular16, BodySmall14, BodySmallAlt, BodySmallPlus, TitleSmallPlus } from "@styled";
import { StyledOfferTypeSummary } from "./GcOffers.styled";
import { ReactComponent as ToolTipIcon } from "@assets/img/icons/common/tooltip.svg";
import PrimaryButton from "@components/common/buttons/primary-button/PrimaryButton";
import { SanloTooltip } from "@components/common/tooltips/sanlo-tooltip/SanloTooltip";
import GradientSlider from "@components/onboarding/gradient-slider/GradientSlider";
import { StackedChart } from "@components/charts";
import YAxis from "../dashboard/daily-revenue/components/DailyRevenueDisplay/YAxis";

import { currencyFormat, bankersRounding } from "@utils/currency";
import text from "@utils/text";
import symbols from "@utils/symbols";

import { legends, yAxisInfo } from "./data";

const legendTooltipText = {
  revenue: text.t("revenue_legend"),
  repayment: text.t("fixed_legend"),
};
const OfferSummary = ({
  funding,
  offerType,
  data,
  maxAmountToSelect,
  minAmountToSelect,
  handleSliderDrag,
  oneTimeFee,
  requestedAmount,
  baselineRepaymentRate,
  maxTermsInMonths,
  fixedMonthlyRepayment,
  id,
  showLoader,
}) => {
  const barRef = useRef(null);
  const dispatch = useDispatch();
  useEffect(() => {
    if (offerType) {
      barRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [offerType]);

  const updatedFunding = funding > maxAmountToSelect ? maxAmountToSelect : funding;

  const calculatedRequestedAmount = () => {
    return requestedAmount < maxAmountToSelect ? requestedAmount : maxAmountToSelect;
  };

  const totalToRepay = calculatedRequestedAmount() + calculatedRequestedAmount() * oneTimeFee;

  const acceptOffer = () => {
    let acceptedData = {
      offerOptionId: id,
      selectedAmount: funding,
      oneTimeFee: bankersRounding(oneTimeFee),
      totalToRepay: bankersRounding(totalToRepay),
    };

    if (offerType === "revenue") {
      acceptedData = {
        ...acceptedData,
        baselineRepaymentRate: bankersRounding(baselineRepaymentRate * 100),
      };
    }

    if (offerType === "fixed") {
      acceptedData = {
        ...acceptedData,
        fixedMonthlyRepayment: bankersRounding(fixedMonthlyRepayment),
      };
    }

    dispatch(actions.offers.acceptCapitalOffer.request(acceptedData));
  };

  return (
    <StyledOfferTypeSummary ref={barRef}>
      <div className="left">
        <TitleSmallPlus>Repayments example</TitleSmallPlus>
        <div className="left-subtitle">
          <BodySmallAlt>
            {offerType === "revenue" ? text.t("revenue_repayment_text") : text.t("fixed_repayment_text")}
          </BodySmallAlt>
        </div>
        <div className="chart-layout">
          <div className="chart-layout-legend">
            {legends.map((legend) => (
              <div key={legend} className="item">
                <span className={`item-${legend}`} />
                <BodySmall14>
                  {legend}
                  <ToolTipIcon id={legend} className="legend-tooltip" />
                </BodySmall14>
                <SanloTooltip maxWidth={400} target={legend} text={legendTooltipText[legend]} placement="top" />
              </div>
            ))}
          </div>
          <div className="chart-layout-tag">
            {symbols.eyes}
            <BodySmallPlus>Sample Data</BodySmallPlus>
          </div>
          <div className="chart-layout-body">
            <YAxis yAxisInfo={yAxisInfo} />
            <StackedChart data={data} legends={legends} />
          </div>
        </div>
      </div>
      <div className="right">
        <TitleSmallPlus>{text.t("offer_summary")}</TitleSmallPlus>
        <div className="right-item">
          <BodySmallAlt>Total you receive</BodySmallAlt>
          <TitleSmallPlus>{currencyFormat(updatedFunding)}</TitleSmallPlus>
        </div>

        <GradientSlider
          onSliderDrag={handleSliderDrag}
          value={updatedFunding}
          max={maxAmountToSelect}
          min={minAmountToSelect}
          isGrowthOffer
          interval={500}
        />
        <div className="slider-value">
          <BodySmall14>Min {currencyFormat(minAmountToSelect)}</BodySmall14>
          <BodySmall14>Max {currencyFormat(maxAmountToSelect)}</BodySmall14>
        </div>
        <div className="horizontal-line" />
        <div className="right-item">
          <BodySmallAlt>
            {text.t("one_time_fee")}(
            {bankersRounding(oneTimeFee * 100, {
              output: "string",
            })}
            %)
            <ToolTipIcon id="one-time-fee-summary" className="offer-tooltip" />
            <SanloTooltip
              maxWidth={400}
              target="one-time-fee-summary"
              text={text.t("one_time_tooltip")}
              placement="top"
            />
          </BodySmallAlt>
          <BodyRegular16>{currencyFormat(calculatedRequestedAmount() * oneTimeFee)}</BodyRegular16>
        </div>
        <div className="right-item">
          <BodySmallAlt>Total to repay</BodySmallAlt>
          <BodyRegular16>{currencyFormat(totalToRepay)}</BodyRegular16>
        </div>
        <div className="horizontal-line" />
        {offerType === "revenue" ? (
          <div className="right-item">
            <BodySmallAlt>
              Repayment rate
              <ToolTipIcon id="repayment-rate" className="offer-tooltip" />
              <SanloTooltip
                maxWidth={400}
                target="repayment-rate"
                text={text.t("repayment_rate_tooltip")}
                placement="top"
              />
            </BodySmallAlt>
            <BodyRegular16>{bankersRounding(baselineRepaymentRate * 100)}%</BodyRegular16>
          </div>
        ) : (
          <div className="right-item">
            <BodySmallAlt>
              {text.t("repayment")}
              <ToolTipIcon id="repayment-rate" className="offer-tooltip" />
              <SanloTooltip
                maxWidth={400}
                target="repayment-rate"
                text={text.t("repayment_rate_tooltip")}
                placement="top"
              />
            </BodySmallAlt>
            <BodyRegular16>{currencyFormat(fixedMonthlyRepayment)}/mo</BodyRegular16>
          </div>
        )}

        {offerType === "fixed" && (
          <div className="right-item">
            <BodySmallAlt>{text.t("no_of_repayments")}</BodySmallAlt>
            <BodyRegular16>{maxTermsInMonths}</BodyRegular16>
          </div>
        )}

        <PrimaryButton
          className="tag-sanlo-confirm-capital-offer"
          label="Confirm offer"
          onClick={acceptOffer}
          disabled={showLoader}
          loading={showLoader}
        />
        <div className="bottom-text tag-sanlo-contact-us-capital-offers">
          <BodySmallPlus className="tag-sanlo-contact-us-capital-offers">{text.t("not_seeing")}</BodySmallPlus>
        </div>
      </div>
    </StyledOfferTypeSummary>
  );
};

export default OfferSummary;
