import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import actions from "@redux/actions";

import { BodySmallAlt } from "@styled";
import FadeStepsContainer from "@common/animated/FadeStepsContainer";
import { StyledConnectionFlow } from "@components/integrations/StyledConnectionFlow";
import RegularModal from "@common/modals/regular-modal/RegularModal";
import LabelButton from "@common/buttons/label-button/LabelButton";
import { SanloButton } from "@inputs";

import { AdjustStep1 } from "./AdjustStep1";
import { AdjustStep2 } from "./AdjustStep2";
import { AdjustStep3 } from "./AdjustStep3";

import { ReactComponent as CrossIcon } from "@assets/img/icons/common/modal-cross.svg";
import {
  SaveProgressContainer,
  SaveProgressButton,
} from "@components/integrations/connection-guide/SaveProgressButton";

import { INTEGRATION_ACTION } from "@utils/gtm";

export const AdjustConnectSetup = ({
  platform = {
    id: "adjust",
    name: "Adjust",
    platformId: "adjust",
  },
  stepNbr = 0,
  stepData = {},
  goToNextStep = () => {},
  goToStep = () => {},
  saveProgress = () => {},
  trackEvent = () => {},
}) => {
  const dispatch = useDispatch();
  const { isSetupDone = false } = stepData;

  const { credentials, requestStatus } = useSelector((state) => state.integrations[platform.platformId]);

  const [apiToken, setApiToken] = useState(credentials.apiToken);
  const [appTokens, setAppTokens] = useState(credentials.appTokens);

  useEffect(() => {
    if (credentials.status === "valid") {
      goToStep(1, {
        closeAfter: true,
      }); // Go back to the first step
    }
    if (credentials.status === "no_permissions") {
      goToStep(3); // Show permissions info screen again
    }
    // eslint-disable-next-line
  }, [credentials.status]);

  const testApiToken = () => {
    const credentials = {
      apiToken: apiToken,
      appTokens: appTokens,
      considerAppTokens: false,
    };
    dispatch(actions.integrations[platform.platformId].testCredentials.request(credentials, goToNextStep));
  };

  const handleSaveDraftCredentials = () => {
    const credentials = {
      apiToken: apiToken,
      appTokens: appTokens,
    };
    dispatch(actions.integrations[platform.platformId].saveDraftCredentials.request(credentials));
    saveProgress.handleConfirmCloseModal();
  };

  const handleStartFromScratch = () => {
    if (credentials.draft) {
      const credentials = {
        apiToken: "",
        appTokens: [""],
      };
      dispatch(actions.integrations[platform.platformId].saveDraftCredentials.request(credentials));
    }
    setApiToken("");
    setAppTokens([""]);
    dispatch(actions.integrations[platform.platformId].clearTestCredentialsErrors());
    saveProgress.handleConfirmCloseModal();
  };

  const connectPlatform = () => {
    const lastAppToken = appTokens[appTokens.length - 1];
    if (lastAppToken.length === 0) appTokens.pop();
    const credentials = {
      apiToken: apiToken,
      appTokens: appTokens,
    };
    dispatch(actions.integrations[platform.platformId].saveCredentials.request(credentials));
  };

  const getError = (errorMsg) => {
    const { testCredentials, saveCredentials } = requestStatus;
    if (testCredentials.error || saveCredentials.error) {
      return errorMsg;
    }
    return "";
  };

  return (
    <Fragment>
      <StyledConnectionFlow
        className={classnames("fs-modal-content", {
          "done-step": isSetupDone,
          "align-left": isSetupDone,
        })}
      >
        {stepNbr === 2 && (
          <SaveProgressContainer>
            <SaveProgressButton
              aria-label="Close"
              type="button"
              onClick={() => {
                if (saveProgress && saveProgress.handleCloseSetupModal) {
                  saveProgress.handleCloseSetupModal();
                } else {
                  goToStep(1, {
                    closeAfter: true,
                  });
                }
              }}
            >
              <CrossIcon aria-hidden={true} />
            </SaveProgressButton>
          </SaveProgressContainer>
        )}

        <FadeStepsContainer
          step={stepNbr}
          stepComponents={[
            <AdjustStep1
              key="AdjustStep1"
              apiToken={apiToken}
              setApiToken={setApiToken}
              testApiToken={testApiToken}
              requestStatus={requestStatus}
              credentialsError={getError("Please enter a valid API token")}
              trackEvent={trackEvent}
            />,

            <AdjustStep2
              key="AdjustStep2"
              connectPlatform={connectPlatform}
              requestStatus={requestStatus}
              appTokens={appTokens}
              credentialsError={getError("There are some App token(s) that require your attention.")}
              setAppTokens={setAppTokens}
              trackEvent={trackEvent}
            />,

            <AdjustStep3
              key="AdjustStep3"
              onContinue={() => {
                goToStep(1, {
                  closeAfter: true,
                }); // Go back to the first step
                dispatch(actions.integrations[platform.platformId].clearCredentialsStatus());
              }}
              trackEvent={trackEvent}
            />,
          ]}
        />
      </StyledConnectionFlow>

      {saveProgress && (
        <RegularModal
          width={500}
          isOpen={saveProgress.openSaveProgressModal}
          handleToggle={() => saveProgress.setOpenSaveProgressModal(false)}
          title="Do you want to save your progress?"
          content={
            <>
              <BodySmallAlt className="modal-description">
                We'll save your progress so you can finish linking {platform.name} from where you left off.
              </BodySmallAlt>

              <SanloButton
                className="modal-button"
                label="Save Progress"
                onClick={() => {
                  trackEvent(INTEGRATION_ACTION.START_FROM_SCRATCH);
                  handleSaveDraftCredentials();
                }}
              />

              <LabelButton
                label="No thanks, I'll start from scratch"
                handleClick={() => {
                  trackEvent(INTEGRATION_ACTION.START_FROM_SCRATCH);
                  handleStartFromScratch();
                }}
              />
            </>
          }
        />
      )}
    </Fragment>
  );
};
