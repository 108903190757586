export const UI_STAGES = {
  LOADING: {
    key: "loading",
    createDisabled: true,
  },
  NOT_REQUESTED: {
    key: "not-requested",
    createDisabled: true,
  },
  NO_ACCESS: {
    key: "no-access",
    createDisabled: true,
  },
  EMPTY: {
    key: "empty",
    createDisabled: false,
  },
  PROJECTS: {
    key: "projects",
    createDisabled: false,
  },
};

export const loadingStage = (uiStage) => uiStage.key === UI_STAGES.LOADING.key;
export const notRequestedStage = (uiStage) => uiStage.key === UI_STAGES.NOT_REQUESTED.key;
export const noAccessStage = (uiStage) => uiStage.key === UI_STAGES.NO_ACCESS.key;
export const isEmptyStage = (uiStage) => uiStage.key === UI_STAGES.EMPTY.key;
