import styled from "styled-components";
import { StyledFullScreenModal } from "../fullscreen-modal/StyledFullScreenModal";
import StyledSetupGuide from "../integrations-modal/StyledSetupGuide";

export const StyledPermissionModal = styled(StyledFullScreenModal)`
  #toast-bg-fade {
    visibility: hidden;
    background: linear-gradient(180deg, rgba(26, 26, 31, 0) 0%, #1a1a1f 51.42%);
    height: 200px;
    top: 80%;
    width: -webkit-fill-available;
    position: fixed;
  }

  #toast-bg-fade.show {
    visibility: visible;
    -webkit-animation: fadeinBg 0.5s, fadeoutBg 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }
  @-webkit-keyframes fadeinBg {
    from {
      bottom: -200px;
      opacity: 0;
    }
    to {
      bottom: 0;
      opacity: 1;
    }
  }

  @keyframes fadeinBg {
    from {
      bottom: -200px;
      opacity: 0;
    }
    to {
      bottom: 0;
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeoutBg {
    from {
      bottom: 0;
      opacity: 1;
    }
    to {
      bottom: -200px;
      opacity: 1;
    }
  }

  @keyframes fadeoutBg {
    from {
      bottom: 0;
      opacity: 1;
    }
    to {
      bottom: -200px;
      opacity: 1;
    }
  }

  #bottom-toast {
    visibility: hidden;
    min-width: 250px;
    margin-left: -125px;
    background-color: ${({ theme }) => theme.grayscale40};
    box-shadow: 0px 0px 48px rgba(0, 0, 0, 0.4);
    color: ${({ theme }) => theme.textEmphasis};
    text-align: center;
    border-radius: 12px;
    padding: 12px;
    position: fixed;
    z-index: 1000;
    left: 50%;
    bottom: 5%;
  }

  #bottom-toast.show {
    visibility: visible;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }

  @-webkit-keyframes fadein {
    from {
      bottom: 0;
      opacity: 0;
    }
    to {
      bottom: 5%;
      opacity: 1;
    }
  }

  @keyframes fadein {
    from {
      bottom: 0;
      opacity: 0;
    }
    to {
      bottom: 5%;
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeout {
    from {
      bottom: 5%;
      opacity: 1;
    }
    to {
      bottom: 0;
      opacity: 0;
    }
  }

  @keyframes fadeout {
    from {
      bottom: 5%;
      opacity: 1;
    }
    to {
      bottom: 0;
      opacity: 0;
    }
  }
`;

export const StyledPermissionGuideContainer = styled(StyledSetupGuide)`
  display: grid;
  grid-template-columns: 480px 530px;
  column-gap: 50px;
  justify-items: center;

  .step-wrapper {
    background-color: transparent;
  }

  .step-container {
    padding: 0px 16px 16px;
  }
`;
