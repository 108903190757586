import React, { useEffect, useState } from "react";
import classnames from "classnames";

import { BodySmallPlus } from "@styled";

import StyledMediaDisplay from "./MediaDisplay.styled";

import { ReactComponent as DownArrow } from "@assets/img/icons/common/shape-rounded-triangle-down.svg";

import GifPlayer from "./GifPlayer";
import VideoPlayer from "./VideoPlayer";

import { alphaTrim, tagTrim } from "@utils/general";
import symbols from "@utils/symbols";
import { Carousel } from "../carousel";

export const isVideo = (path = "") => {
  const type = path.split(".").pop();
  return type === "mp4";
};

export const isGif = (path = "") => {
  const type = path.split(".").pop();
  return type === "gif";
};

export const isImage = (path = "") => {
  return !isGif(path) && !isVideo(path);
};

const MediaDisplay = ({
  platformName = "",
  platformStep = "",
  title = "",
  media = [],
  controls = false,
  header = "",
  collapsable = true,
  collapsed = true,
  mediaSlider = [],
  expand,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(Boolean(header) && collapsable && collapsed);

  const mediaTitle = tagTrim(title);
  const tagClassName = mediaTitle ? `tag-sanlo-show-me-how-${platformStep}-${mediaTitle}` : "";

  useEffect(() => {
    if (expand) {
      setIsCollapsed(false);
    }
  }, [expand]);
  const renderHeader = () => {
    if (!header) return null;
    return (
      <BodySmallPlus
        className={classnames("media-section-header no-text-select", tagClassName, {
          collapsable: collapsable,
          collapsed: collapsable && isCollapsed,
        })}
        onClick={() => {
          setIsCollapsed(!isCollapsed);
        }}
      >
        {/* {header} */}
        {symbols.Video()}
        <span className={tagClassName}>{header}</span>
        {collapsable && <DownArrow className={classnames("collapse-arrow", tagClassName)} />}
      </BodySmallPlus>
    );
  };

  const renderMedia = (mediaSrc = "") => {
    if (isVideo(mediaSrc)) {
      return <VideoPlayer key={alphaTrim(mediaSrc)} media={mediaSrc} controls={controls} />;
    }

    if (isGif(mediaSrc) && controls) {
      return <GifPlayer key={alphaTrim(mediaSrc)} media={mediaSrc} controls={controls} />;
    }

    return <img key={alphaTrim(mediaSrc)} src={mediaSrc} alt="setup" />;
  };

  if ((!media || !media.length) && !mediaSlider.length) return null;

  return (
    <StyledMediaDisplay>
      {renderHeader()}

      <div
        className={classnames("media-section-content", {
          collapsed: collapsable && isCollapsed,
        })}
      >
        {mediaSlider.length ? <Carousel platformName={platformName} images={mediaSlider} /> : null}
        {media.map((mediaSrc) => {
          if (!mediaSrc) return null;

          if (typeof mediaSrc === "string") {
            return renderMedia(mediaSrc);
          }

          // Images can also have a title associated with them
          const { title, source } = mediaSrc;

          return (
            <>
              <div className="media-display-image-title">{title}</div>
              {renderMedia(source)}
            </>
          );
        })}
      </div>
    </StyledMediaDisplay>
  );
};

export default MediaDisplay;
