import { store } from "../../store";
import actions from "@redux/actions";

import { PRODUCT_ANALYTICS, BANKING, PLATFORMS, AD_NETWORKS } from "@utils/consts";

import { NOT_CONNECTED } from "@utils/status";

import { manualConnectionThunks } from "@redux/integrations/base/manualSlice";

export const unlinkMMP = (integration) => {
  const { id, status, platformId } = integration;
  if (status !== NOT_CONNECTED) {
    console.log(`Unlinking MMP ${id}`);
    if (actions?.integrations[id]?.unlinkIntegration) {
      store.dispatch(actions.integrations[id].unlinkIntegration.request());
    } else {
      store.dispatch(manualConnectionThunks.deleteConnection(platformId));
    }
  }
};

export const checkPlatformConflict = (selectedIntegration, integrations) => {
  if (!selectedIntegration || !integrations) return;
  const { adjust = {}, appsflyer = {} } = integrations;
  // Only conflicting platforms right now are appsflyer and adjust
  if (!selectedIntegration?.platformId.match(/appsflyer|adjust/)) return false;
  if (selectedIntegration?.platformId === "appsflyer" && adjust.connected) return adjust;
  if (selectedIntegration?.platformId === "adjust" && appsflyer.connected) return appsflyer;
  return false;
};

export const categories = [
  {
    id: AD_NETWORKS,
  },
  {
    id: PLATFORMS,
    name: "Platforms",
    description: "Stay up to date on your most recent earnings and get visibility on upcoming payments.",
    target: "integration-platforms-target",
  },
  {
    id: PRODUCT_ANALYTICS,
    name: "Product analytics",
    description: "Gain valuable insights into your product analytics and identify growth opportunities.",
  },
  {
    id: BANKING,
    name: "Banking",
    description: "Track your estimated runway and burn rate.",
  },
];
