import { Box, Button, Divider, Flex, Heading, SimpleGrid, Text, useDisclosure } from "@chakra-ui/react";
import { AdNetworksStepper } from "./components/ad-networks-stepper/AdNetworksStepper";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { adNetworksThunks, resetSelectedAdNetwork } from "@redux/integrations/ad-networks/adNetworksSlice";
import { adNetworkConnectionsSelector } from "@redux/integrations/base/baseSlice";
import { AdNetwork } from "./components/ad-network/AdNetwork";

export function AdNetworks() {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const adNetworkConnections = useSelector(adNetworkConnectionsSelector);
  const dispatch = useDispatch();
  const [initialStep, setInitialStep] = useState(0);
  const noAdNetworksConnected = adNetworkConnections && adNetworkConnections.length === 0;

  useEffect(() => {
    dispatch(adNetworksThunks.getAvailableNetworks());
  }, [dispatch]);

  function handleCredentialsRetry() {
    setInitialStep(1);
    // Trick to hide the first step of the stepper
    setTimeout(() => {
      onOpen();
    }, 50);
  }

  return (
    <Box flex={1} mb="24px">
      <Flex
        flexDir={noAdNetworksConnected ? "column" : "row"}
        justifyContent={"space-between"}
        alignItems={"self-start"}
      >
        <Box>
          <Heading size="small" fontWeight={600}>
            Ad networks
          </Heading>
          <Text mt="8px" size={"small"}>
            Track all your advertising revenue and UA spend in one place.
          </Text>
        </Box>
        <Button
          mt={noAdNetworksConnected ? "16px" : 0}
          variant="solid"
          size={noAdNetworksConnected ? "jumbo" : "md"}
          maxW="100%"
          w={noAdNetworksConnected ? "100%" : "fit-content"}
          onClick={onOpen}
          data-test-id="add-ad-network-button"
        >
          Add
        </Button>
      </Flex>
      {noAdNetworksConnected && <Divider my="40px" borderBottomWidth={0} height={"1px"} bg="dark.200" />}
      <SimpleGrid columns={2} gap={"16px"} mt="16px">
        {adNetworkConnections.map((adNetwork) => (
          <AdNetwork key={adNetwork.id} handleCredentialsRetry={handleCredentialsRetry} {...adNetwork} />
        ))}
      </SimpleGrid>
      <AdNetworksStepper
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setInitialStep(0);
        }}
        onCloseComplete={() => {
          dispatch(resetSelectedAdNetwork());
        }}
        initialStep={initialStep}
      />
    </Box>
  );
}
