import { modalAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/styled-system";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const dialogMdSize = defineStyle({
  maxWidth: "480px",
});

const dialogLgSize = defineStyle({
  maxWidth: "548px",
});

const dialogFullSize = defineStyle({
  p: "48px",
});

const dialogFitContent = defineStyle({
  maxWidth: "fit-content",
});

const sizes = {
  md: definePartsStyle({ dialog: dialogMdSize }),
  lg: definePartsStyle({ dialog: dialogLgSize }),
  full: definePartsStyle({ dialog: dialogFullSize }),
  fitContent: definePartsStyle({ dialog: dialogFitContent }),
};

const baseStyle = definePartsStyle({
  // define the part you're going to style
  overlay: {
    bg: "rgba(0, 0, 0, 0.6)",
  },
  dialog: {
    borderRadius: "xl",
    bg: "dark.700",
    padding: "28px 32px 32px 32px",
  },
  header: {
    fontFamily: "heading",
    fontSize: "2xl",
    padding: 0,
  },
  body: {
    padding: "16px 0 24px 0",
    fontFamily: "body",
    color: "textDefault.400",
    fontSize: "sm",
  },
  footer: {
    padding: 0,
  },
  closeButton: {
    top: "32px",
    right: "32px",
    color: "textDefault.400",
  },
});

const confirmationVariant = definePartsStyle({
  footer: {
    padding: 0,
    gap: "16px",
  },
  closeButton: {
    top: "24px",
    right: "28px",
    color: "textDefault.400",
  },
});

export const Modal = defineMultiStyleConfig({
  baseStyle,
  variants: { confirmation: confirmationVariant },
  sizes,
});
