import { tableAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tableAnatomy.keys);

// common styles
const commonTableStyles = {
  color: "textWhite.400",
  borderWidth: "1px",
  borderStyle: "solid",
  borderRadius: "12px",
  overflow: "hidden",
  borderCollapse: "separate",
};

const commonThStyles = {
  fontFamily: "body",
  fontSize: "md",
  fontWeight: 500,
  textTransform: "none",
  padding: "16px 24px",
  color: "textWhite.400",
};

const commonTdStyles = {
  fontFamily: "body",
  fontSize: "sm",
  fontWeight: 400,
  color: "textDefault.400",
  verticalAlign: "middle",
};

const commonHoverStyles = {
  backgroundImage: "linear-gradient(269deg, rgba(191, 107, 255, 0.10) -4.84%, rgba(255, 92, 121, 0.10) 100.08%)",
};

// Base style
const baseStyle = definePartsStyle({
  table: {
    ...commonTableStyles,
    borderColor: "dark.600",
  },
  thead: {
    fontFamily: "body",
    bg: "dark.600",
    zIndex: 1,
  },
  th: commonThStyles,
  td: commonTdStyles,
  tr: {
    padding: "16px 24px",
  },
  caption: {
    padding: "16px 24px",
    bg: "dark.600",
    margin: 0,
  },
});

const defaultVariant = definePartsStyle({
  th: commonThStyles,
  td: commonTdStyles,
  tbody: {
    tr: {
      _hover: commonHoverStyles,
    },
  },
});

const itemListCatalog = definePartsStyle({
  table: {
    ...commonTableStyles,
    overflowY: "auto",
  },
  thead: {
    borderBottom: "1px solid",
    borderColor: "dark.400",
    th: {
      bg: "dark.500",
      position: "sticky",
      top: 0,
      zIndex: 1,
    },
  },
  td: {
    ...commonTdStyles,
    "&:first-of-type": {
      whiteSpace: "normal",
    },
    "&:nth-of-type(4)": {
      whiteSpace: "normal",
    },
  },
  tbody: {
    tr: {
      borderBottom: "1px solid",
      borderColor: "dark.600",
      bg: "dark.700",
      _hover: commonHoverStyles,
    },
  },
});

const addItemSeries = definePartsStyle({
  table: {
    ...commonTableStyles,
  },
  td: {
    ...commonTdStyles,
    "&:first-of-type": {
      whiteSpace: "normal",
    },
  },
  tbody: {
    tr: {
      cursor: "pointer",
      borderBottom: "1px solid",
      borderColor: "dark.600",
      _hover: commonHoverStyles,
    },
  },
});

const editItemSeries = definePartsStyle({
  table: {
    ...commonTableStyles,
  },
  td: {
    ...commonTdStyles,
    "&:nth-of-type(2)": {
      whiteSpace: "normal",
    },
  },
  tbody: {
    tr: {
      borderBottom: "1px solid",
      borderColor: "dark.600",
      bg: "dark.700",
      _hover: commonHoverStyles,
    },
  },
});

const customDomain = definePartsStyle({
  table: {
    ...commonTableStyles,
    borderColor: "dark.300",
    tableLayout: "fixed",
    w: "100%",
  },
  thead: {
    bg: "dark.500",
  },
  th: {
    ...commonThStyles,
    fontSize: "xs",
  },
  td: {
    ...commonTdStyles,
    fontSize: "xs",
  },
});

const settings = definePartsStyle({
  table: {
    borderRadius: "10px",
    borderWidth: "1px",
    borderColor: "dark.300",
    borderStyle: "solid",
    tableLayout: "fixed",
    w: "100%",
  },
  thead: {
    bg: "dark.500",
    py: "12px",
    pl: "16px",
    pr: 0,
  },
  th: {
    ...commonThStyles,
    fontSize: "xs",
  },
  tr: {
    py: "12px",
    pl: "16px",
    pr: 0,
  },
  td: {
    ...commonTdStyles,
    fontSize: "xs",
    color: "textWhite.400",
  },
});

export const Table = defineMultiStyleConfig({
  baseStyle,
  variants: {
    default: defaultVariant,
    itemListCatalog,
    addItemSeries,
    editItemSeries,
    customDomain,
    settings,
  },
  defaultProps: {
    variant: "default",
  },
});
