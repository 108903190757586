import styled from "styled-components";

export const StyledPieChart = styled.div`
  position: relative;

  .piechart-tooltip {
    color: ${({ theme }) => theme.textSeashellWhite};
    position: absolute;
    background-color: ${({ theme }) => theme.grayscale40};
    z-index: 10;
    padding: 0.75rem;
    border-radius: 0.75rem;
    transform: translateX(-50%) translateY(-120%);

    &.total {
      width: 16rem;
    }
  }

  .pie_chart_wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;

    .pie_chart {
      width: var(--pie-chart-size);
      height: var(--pie-chart-size);
      background-color: transparent;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }

    .pie_chart_percentage {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      font-size: var(--pie-chart-center-text-size);
      font-weight: 500;
      text-align: center;
    }
  }
`;

export const StyledPieChartLegend = styled.div`
  --legend-size: 0.5rem;

  .details {
    border-spacing: 0;
    border-collapse: separate;
    width: 100%;

    tr {
      height: 2rem;

      td {
        white-space: nowrap;
        text-align: left;
        text-align-last: left;

        &:last-child {
          padding-left: 1rem;
          text-align: right;
          text-align-last: right;
        }

        div {
          display: inline-block;
        }
      }
    }

    .pie_chart_legend_td {
      width: var(--legend-size);
      padding-right: var(--legend-size);

      .pie_chart_legend {
        width: var(--legend-size);
        height: var(--legend-size);
        border-radius: 25%;
      }
    }

    .label {
      text-align: left;
      color: white;
    }
  }
`;
