import { copy } from "@utils/general";
import moment from "moment";

const getDateInfo = (elem) => {
  const dateObj = new Date(elem.year, elem.month - 1, 1);
  const month = {
    full: dateObj.toLocaleString("default", { month: "long" }),
    short: dateObj.toLocaleString("default", { month: "short" }),
  };
  const shortDate = `${month.short}'${elem.year.toString().substring(2)}`;
  return {
    shortDate,
    month,
  };
};

export const parseInflowChartData = (payload) => {
  try {
    // to get the current month + last 5
    const data = copy(payload);
    data.pop();
    const newData = data.map((elem) => {
      const { shortDate, month } = getDateInfo(elem);
      return {
        shortDate,
        fullDate: (inProgressMonth) =>
          `${inProgressMonth ? `${month.full} ${new Date(elem.lastUpdatedDay).getUTCDate()}` : month.full}, ${
            elem.year
          }`,
        notCompleted: !elem.completed,
        inflow: {
          amount: elem.inflowInCents / 100,
        },
        outflow: {
          amount: elem.outflowInCents / 100,
        },
      };
    });
    return newData.reverse();
  } catch (e) {
    return [];
  }
};

export const parseBalanceChartData = (payload) => {
  try {
    // to get the current month + last 5
    const data = copy(payload);
    data.pop();
    const newData = data.map((elem) => {
      const { shortDate, month } = getDateInfo(elem);
      return {
        shortDate,
        fullDate: (inProgressMonth) =>
          `${inProgressMonth ? month.full : month.short} ${new Date(elem.lastUpdatedDay).getUTCDate()}, ${elem.year}`,
        shortFullDate: `${month.full}, ${elem.year}`,
        monthlyAmount: elem.endOfMonthBalanceInCents / 100,
      };
    });
    return newData.reverse();
  } catch (e) {
    return [];
  }
};

export const parseBurnChartData = (payload = []) => {
  try {
    // to get the current month + last 5
    let data = Object.hasOwn(payload[0], "netMonthlyBurnCents") ? payload : [];
    data.pop();
    const newData = data.map((elem) => {
      const { shortDate, month } = getDateInfo(elem);
      return {
        shortDate,
        fullDate: (inProgressMonth) =>
          inProgressMonth
            ? `${month.full} ${new Date(elem.lastUpdatedDay).getUTCDate()}, ${elem.year}`
            : `${month.full} ${elem.year}`,
        monthlyAmount: elem.netMonthlyBurnCents / 100,
      };
    });
    return newData.reverse();
  } catch (e) {
    return [];
  }
};

export const getLastMonthNetBurn = (data = []) => {
  try {
    const lastMonth = data.filter((month) => {
      if (month.month === moment().subtract(1, "months").month() + 1 && month.completed) {
        return month;
      } else if (month.month === moment().subtract(2, "months").month() + 1) {
        return month;
      }
      return null;
    })[0];

    return { ...lastMonth };
  } catch (e) {
    return {};
  }
};

export const parseBreakdownData = (payload = {}) => {
  try {
    let data = copy(payload);

    let { cashIn, cashOut } = data;

    if (!cashIn || !Object.keys(cashIn).length) {
      cashIn = {
        sections: [],
      };
    }
    if (!cashOut || !Object.keys(cashOut).length) {
      cashOut = {
        sections: [],
      };
    }

    if (!cashIn.sections) cashIn.sections = [];
    if (!cashOut.sections) cashOut.sections = [];

    return {
      ...data,
      cashIn,
      cashOut,
    };
  } catch (e) {
    return {
      metadata: {
        availableFilters: [],
      },
      cashIn: {
        sections: [],
      },
      cashOut: {
        sections: [],
      },
    };
  }
};

export const getUSDClosingBalance = (data = []) => {
  return data.find((elem) => elem.currency === "USD");
};
