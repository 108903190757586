import { Text, HStack, Icon, VStack, Tooltip, Box } from "@chakra-ui/react";
import { ReactComponent as AlertCircle } from "@assets/img/icons/common/alertCircle.svg";
import { currencyFormat } from "@src/utils/currency";
import { formatToMonthDay, formatTwoDigitMonthDay } from "@src/utils/time";
import isEmpty from "lodash/isEmpty";
import moment from "moment";

import PropTypes from "prop-types";

const RECURRENCE_DIFF = {
  DAILY: "day",
  WEEKLY: "week",
  MONTHLY: "month",
};

export const ERRORS = {
  DATE_RANGE: "invalidRangeDate",
  RECURRENCE: "invalidRecurrence",
};

export const validateError = (condition, errorName, message, methods) => {
  const { setError, clearErrors } = methods;
  if (condition) {
    setError(errorName, {
      type: "manual",
      message,
    });
  } else {
    clearErrors(errorName);
  }
};

// validate that the end date is after the start date
export const validateDateRange = (startTime, endTime, methods) => {
  const isInvalidRange = moment(endTime).isSameOrBefore(moment(startTime));
  validateError(isInvalidRange, ERRORS.DATE_RANGE, "Oops! End date should be later than the start date.", methods);
};

// validate that the recurrence interval is valid
export const validateRecurrence = (startTime, endTime, recurrenceInterval, methods) => {
  if (!recurrenceInterval) {
    validateError(false, ERRORS.RECURRENCE, "", methods);
    return;
  }
  const duration = moment(endTime).diff(moment(startTime), RECURRENCE_DIFF[recurrenceInterval]);
  const isInvalidRecurrence = duration <= 0 || isNaN(duration);

  validateError(isInvalidRecurrence, ERRORS.RECURRENCE, "This item cannot recur with the current dates.", methods);
};

const formatDate = (date) => (date ? moment(date).format("MMMM D YYYY [at] hh:mmA") : "");

function generateText({ startTime, endTime, available }) {
  const formattedStartDate = startTime ? formatDate(startTime) : null;
  const formattedEndDate = endTime ? formatDate(endTime) : null;

  if (!available) {
    if (formattedStartDate) {
      const availabilityPeriod = formattedEndDate ? ` until ${formattedEndDate}` : "";
      return `This item is not available. It will be available from ${formattedStartDate}${availabilityPeriod}.`;
    }
    return "This item is not available.";
  }

  if (formattedStartDate) {
    const availabilityPeriod = formattedEndDate ? ` to ${formattedEndDate}` : "";
    return `This item is available starting ${formattedStartDate}${availabilityPeriod}.`;
  }

  return "This item is available.";
}

export const ItemSummary = ({ watch }) => {
  const { recurrenceInterval, available, startTime, endTime, totalQuantity, quantityPerPlayer } = watch();

  if (!recurrenceInterval && !quantityPerPlayer && !totalQuantity) return null;

  const availabilityText = generateText({
    startTime,
    endTime,
    available,
  });

  return (
    <HStack w="100%" p="8px 12px" alignItems="center" bgColor={"dark.100"} borderRadius="10px" gap="8px">
      <Icon as={AlertCircle} width="16px" height="16px" color={"textWhite.400"} transform={"rotate(180deg)"} />
      <VStack alignItems={"flex-start"} gap={0}>
        <Text size="smallAlt" fontWeight={500} color={"textWhite.400"}>
          {availabilityText}
        </Text>
        <Text size="smallAlt" fontWeight={500} color={"textWhite.400"}>
          {totalQuantity ? `${totalQuantity} item(s)` : "Unlimited item(s)"}
        </Text>
        {quantityPerPlayer && (
          <Text size="smallAlt" fontWeight={500} color={"textWhite.400"}>
            {recurrenceInterval
              ? `${quantityPerPlayer} per customer per ${RECURRENCE_DIFF[recurrenceInterval]}`
              : `${quantityPerPlayer} per customer`}
          </Text>
        )}
      </VStack>
    </HStack>
  );
};

ItemSummary.propTypes = {
  watch: PropTypes.func.isRequired,
};

export const pricingTiersLabel = (pricingTier = {}) => {
  if (isEmpty(pricingTier)) return "";
  const amount = pricingTier?.price?.amountCents ?? 0;
  if (amount === 0) return "Free"; // Free tier - level = 0
  return `${currencyFormat(amount / 100, { forceShowDecimals: true })}`;
};

export const getAvailabilityWindowLabel = (startDate, endDate, formatOptions = {}) => {
  const defaultLabel = "--";
  function formattedDate(date) {
    const dateToFormat = date ? new Date(date + "Z") : new Date(new Date().toISOString());
    return formatToMonthDay(dateToFormat, formatOptions);
  }

  if (!startDate && !endDate) return defaultLabel;
  if ((startDate && typeof startDate !== "string") || (endDate && typeof endDate !== "string")) return defaultLabel;

  const startLabel = formattedDate(startDate);
  const endLabel = endDate ? formattedDate(endDate) : "Continuous";

  return `${startLabel} - ${endLabel}`;
};

export const getAvailabilityLabel = (startDate, endDate, formatOptions = {}) => {
  const defaultLabel = "—";
  function formattedDate(date) {
    const dateToFormat = date ? new Date(date + "Z") : new Date(new Date().toISOString());
    return formatTwoDigitMonthDay(dateToFormat, formatOptions);
  }

  if (!startDate && !endDate) return defaultLabel;
  if ((startDate && typeof startDate !== "string") || (endDate && typeof endDate !== "string")) return defaultLabel;

  const startLabel = formattedDate(startDate);
  const endLabel = endDate ? `- ${formattedDate(endDate)}` : "—";

  return `${startLabel} ${endLabel}`;
};

export const getSegmentsLabel = (playerSegmentIds = [], segments = {}) => {
  try {
    const { bySegmentId } = segments;
    return bySegmentId[playerSegmentIds[0]].name;
  } catch (e) {
    return "--";
  }
};

export const getSegmentsCell = (playerSegmentIds = [], segments = {}) => {
  try {
    if (!playerSegmentIds || !playerSegmentIds.length) return "--";
    const { bySegmentId } = segments;
    const segmentNames = playerSegmentIds.map((id) => bySegmentId[id].name);
    let label = segmentNames[0];
    if (segmentNames.length > 1) {
      label = `${label} (+${segmentNames.length - 1})`;
    }

    return (
      <Tooltip
        size={"maxContent"}
        label={
          <VStack spacing={2} align="start">
            {segmentNames.map((name, index) => (
              <Box key={index} bg="rgba(255, 255, 255, 0.1)" p="6px 12px" width="100%" borderRadius="4px">
                <Text size="small">{name}</Text>
              </Box>
            ))}
          </VStack>
        }
      >
        <Text size="small" color={"textWhite.400"}>
          {label}
        </Text>
      </Tooltip>
    );
  } catch (e) {
    return "--";
  }
};

export const WarningMessage = ({ children }) => {
  if (!children) return null;
  return (
    <HStack w={"100%"} p={"8px 12px"} alignItems={"center"} bgColor={"#FFD73529"} borderRadius={"10px"} gap={"8px"}>
      <Icon as={AlertCircle} width={"16px"} height={"16px"} color={"brandYellow.400"} transform={"rotate(180deg)"} />
      <Text size={"smallAlt"} fontWeight={500} color={"brandYellow.400"}>
        PLEASE NOTE: {children}
      </Text>
    </HStack>
  );
};
