import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { storefrontSectionsSelector } from "@redux/webshops/builder/sectionsSlice";
import { getSectionTheme, COLOR_TYPES } from "@utils/webshop/section";
import { useSectionTheme } from "@utils/custom-hooks/useSectionTheme";
import { useSectionFile } from "@utils/custom-hooks/useSectionFile";

export const withSectionData = (WrappedComponent, useAdditionalHooks = () => ({})) => {
  const HOC = ({ webshop = {}, sectionId = "", storefront = {}, ...props }) => {
    const storefrontSections = useSelector(storefrontSectionsSelector);
    const data = storefrontSections.byId[sectionId];

    const [onThemeUpdate, onThemeReset] = useSectionTheme(webshop.id, sectionId, data);
    const [onFileCreate, onFileUpdate, onFileRemove] = useSectionFile(webshop.id, sectionId, data);

    const additionalHookValues = useAdditionalHooks(webshop.id, sectionId, data);

    const theme = getSectionTheme(storefront, data, {
      exclude: {
        [COLOR_TYPES.BACKGROUND]: true,
      },
    });

    if (!data) return null;

    return (
      <WrappedComponent
        data={data}
        theme={theme}
        webshop={webshop}
        onThemeChange={onThemeUpdate}
        onThemeReset={onThemeReset}
        onFileCreate={onFileCreate}
        onFileUpdate={onFileUpdate}
        onFileRemove={onFileRemove}
        {...additionalHookValues}
        {...props}
      />
    );
  };

  HOC.propTypes = {
    webshop: PropTypes.object,
    sectionId: PropTypes.string,
    storefront: PropTypes.object,
  };

  return HOC;
};
