import { popoverAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);
const baseStyle = definePartsStyle({
  // define the part you're going to style
  body: {
    bg: "inherit",
    padding: "12px",
    borderRadius: "12px",
  },
  content: {
    bg: "dark.600",
    fontFamily: "body",
    boxShadow: "0px 0px 48px 0px rgba(0, 0, 0, 0.40)",
    color: "textDefault.400",
    border: "none",
    _focusVisible: {
      boxShadow: "0px 0px 48px 0px rgba(0, 0, 0, 0.40)",
    },
  },
});

const warnFinancialCardVariant = definePartsStyle({
  body: baseStyle.body,
  content: {
    ...baseStyle.content,
    width: "250px",
  },
});

export const Popover = defineMultiStyleConfig({
  baseStyle,
  variants: {
    warnFinancialCard: warnFinancialCardVariant,
  },
});
