import React, { useEffect, useRef, useState } from "react";
import { Box, Input, Text, useOutsideClick } from "@chakra-ui/react";
import { DropdownContent, DropdownItem } from "@common/dropdown/Dropdown";
import isEqual from "lodash/isEqual";

export const Autocomplete = ({
  id = "",
  value = "",
  itemSelected = null,
  placeholder = "",
  onChange = () => {},
  options = [],
  getOptionLabel,
  required = false,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(() => options);
  const [selectedItem, setSelectedItem] = useState(() => itemSelected);
  const [inputValue, setInputValue] = useState(value);
  const [invalidInput, setInvalidInput] = useState(false);

  const dropdownRef = useRef(null);
  const optionsHasObject = options.some((item) => typeof item === "object");

  if (optionsHasObject && !getOptionLabel) {
    throw new Error(
      "You must provide a getOptionLabel function when using objects as options. getOptionLabel receives the option as a parameter and must return a string.",
    );
  }

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setIsDropdownOpen(true);
    setInvalidInput(false);
    setInputValue(newValue);
    const filtered = options.filter((option) => getOptionLabel(option).toLowerCase().includes(newValue.toLowerCase()));
    setFilteredOptions(filtered);

    if (filtered.length === 0) {
      setInvalidInput(true);
    }
  };

  const handleSelectedItem = (item) => {
    setSelectedItem(item);
    setInputValue(getOptionLabel ? getOptionLabel(item) : item);
    onChange(item);

    setInvalidInput(false);
    setIsDropdownOpen(false);
  };

  useOutsideClick({
    ref: dropdownRef,
    handler: () => {
      setIsDropdownOpen(false);
      setInvalidInput(false);
      if (selectedItem) {
        setInputValue(getOptionLabel ? getOptionLabel(selectedItem) : selectedItem);
      } else {
        setInputValue("");
      }
    },
  });

  function handleOnEnterPressed(e) {
    if (e.key === "Enter" && filteredOptions.length === 1) {
      e.preventDefault();
      e.stopPropagation();
      handleSelectedItem(filteredOptions[0]);
    }
  }

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  return (
    <Box ref={dropdownRef} pos={"relative"}>
      <Input
        variant={invalidInput ? "autocompleteError" : "default"}
        placeholder={placeholder}
        value={inputValue}
        p={"12px 16px"}
        borderColor={required ? "brandRed.400" : "transparent"}
        onKeyDown={handleOnEnterPressed}
        onChange={handleInputChange}
        onClick={() => {
          setIsDropdownOpen((prev) => !prev);
          setFilteredOptions(options);
        }}
        _focus={{ backgroundColor: "transparent", border: "2px solid", borderColor: "brandRed.400" }}
        _hover={{ p: "12px 16px" }}
        data-test-id={`autocomplete-${id}`}
      />
      {filteredOptions.length ? (
        <DropdownContent
          isOpen={isDropdownOpen}
          handleSelectedItem={handleSelectedItem}
          zIndex={2}
          data-test-id={"autocomplete-dropdown"}
        >
          {filteredOptions.map((option, index) => (
            <DropdownItem
              key={optionsHasObject ? `${option.id ?? index}-option-${id}` : `${option}-${index}-${id}`}
              value={option}
              onClick={(event) => {
                event.preventDefault();
                handleSelectedItem(option);
              }}
              getOptionLabel={getOptionLabel}
              selected={isEqual(selectedItem, option)}
            />
          ))}
        </DropdownContent>
      ) : null}
      {invalidInput && (
        <Text size={"smallAlt"} mt={"8px"} color={"brandRed.400"}>
          Invalid input
        </Text>
      )}
    </Box>
  );
};
