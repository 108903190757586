import HTTP from "@redux/http";

const SANLO_WEB_API_URL = process.env.REACT_APP_SANLO_WEB_API_URL;
const BASE = `${SANLO_WEB_API_URL}/api/integrations/platforms/googleplay`;

export default {
  googlePlayRetrieveServiceAccount: () => {
    return HTTP.req.get(`${BASE}/retrieve-service-account`);
  },

  googlePlaySaveCredentials: (cloudStorageURI) => {
    return HTTP.req.post(`${BASE}/save-credentials`, cloudStorageURI);
  },

  googlePlayDeleteCredentials: () => {
    return HTTP.req.delete(`${BASE}/delete-credentials`);
  },
};
