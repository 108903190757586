export default {
  themeName: "dark",
  body: "#0F0F12",
  backgroundColor: "#1A1A1F",
  primaryFontColor: "#FFFFFF",
  grayFontColor: "#C5C5C5",
  primaryColor: "#FF5C79",
  primaryHover: "#FF4264",
  primaryActive: "#CC2042",
  secondaryColor: "#C561FF",
  tertiaryColor: "#7370FF",
  subTextDark: "#FFFFFF80",

  // Colors
  colorGreen: "#2DF9B0",
  colorOrange: "#FF892E",
  colorYellow: "#FFD735",
  colorRed: "#FF5C79",
  colorSkyBlue: "#66C8FF",
  colorElectricBlue: "#8280FF",
  colorPink: "#FE94FF",
  colorPurple: "#BF6BFF",
  colorBlack: "#000",
  colorDarkGreen: "rgba(44, 249, 175, 0.16)",
  colorDarkYellow: "rgba(255, 215, 53, 0.16)",
  colorDarkPurple: "rgba(191,108,255, 0.16)",

  // Text
  textSubtext: "rgba(255,255,255,0.5)",
  textDefault: "rgba(197,197,197,1)",
  textEmphasis: "#FAF8F8",
  textGray: "#C5C5C5",
  textSeashellWhite: "#FAF8F8",

  // Theme
  grayscale0: "#020202",
  grayscale5: "#111114",
  grayscale10: "#1A1A1F",
  grayscale20: "#212126",
  grayscale30: "#25252A",
  grayscale40: "#2A2A2F",
  grayscale50: "#333338",
  grayscale60: "#3A3A3E",
  grayscale70: "#414145",

  // Box shadow
  boxShadowColor: "0 0 48px rgba(0, 0, 0, 0.4)",

  // Gradients
  gradPurpleRed: "linear-gradient(100.48deg, #BF6BFF 0%, #FF5C79 100%)",

  editedTagColor: "#7370FF",
  darkBtnBg: "#25252A",
  editedColor: "rgba(115,112,255,0,2)",
  cardBg: "#1A1A1F",
  searchBorder: "#2A2A2F",
  dividerLine: "#2A2A2F",

  iconHoverBackground: "rgba(255, 255, 255, 0.16)",

  // Toast
  toastContainerBg: "rgba(26,26,31,.16)",
  // Firefox's opacity renders lightly. We need to make it 1 for the background color to be a bit darker
  toastContainerBgFirefox: "rgba(26,26,31,1)",
  successToastBg: "rgba(44,249,175,0.16)",
  warningToastBg: "rgba(255,215,53,0.16)",
  errorToastBg: "rgba(255,92,121,0.16)",

  // Sidebar
  linkColor: "#FFF",
  dividerColor: "#25252A",

  // Editor Panel
  inputBackgroundColor: "#25252A",
  inputBackgroundColorFocus: "#1A1A1F",
  inputBackgroundColorEdited: "rgba(115, 112, 255, 0.1)",
  inputBackgroundColorError: "rgba(255, 92, 121, 0.1)",

  inputFontColorDisabled: "rgba(255, 255, 255, 0.5)",
  inputFontColor: "#C5C5C5",
  inputFontColorHover: "#FAF8F8",
  inputFontColorFocus: "#FAF8F8",
  inputFontColorEdited: "#8280FF",
  inputFontColorError: "#FF5C79",

  inputBorderColorHover: "#333338",
  inputBorderColorFocus: "#FF5C79",

  rowInputBackgroundColor: "#333338",
  rowCrossColor: "#C5C5C5",
  rowCrossHoverColor: "#FAF8F8",

  inputBackgroundActive: "#333338",
  tooltipFill: "#C5C5C5",
  labelFontColor: "#C5C5C5",
  rowBackground: "#25252A",
  editedFontColor: "#7370FF",
  borderDivider: "#2A2A2F",

  // Top indicators
  titleColor: "#FAF8F8",
  grayBgColor: "#333338",

  // Chart
  primaryPointColor: "#FF4264",
  secondaryPointColor: "#7370FF",
  tertiaryPointColor: "#7370FF",
  tooltipBg: "#25252A",
  markerColor: "rgba(65,65,69,1)",
  gridLineColor: "rgba(42,42,47,1)",

  // Calendar
  oldDateColor: "rgba(255, 255, 255, 0.25)",
  calendarTopBarFontColor: "#FAF8F8",
  calendarFocusColor: "#FF5C79",
  calendarDateFontColorFocus: "#000",
  calendarFontColor: "#C5C5C5",
  calendarFontColorDisabled: "rgba(255, 255, 255, 0.25)",

  // Table
  tableTitleColor: "#FAF8F8",
  tableSubTitleColor: "#C5C5C5",
  tableButtonBackgroundColor: "#25252A",
  tableRowDividerColor: "#2A2A2F",

  // Dropdown
  hoverFontColor: "#FAF8F8",
  checkboxBackgroundColor: "#FAF8F8",
  checkboxTickColor: "#333338",

  //Buttons
  buttonPrimaryBackground: "#FF5C79",
  buttonPrimaryHover: "#FF4264",
  buttonPrimaryColor: "#000",
  buttonSecondaryBackground: "#2A2A2F",
  buttonSecondaryHover: "#3A3A3E",
  buttonSecondaryColor: "#FAF8F8",

  // Toggler
  togglerBackgroundColor: "#25252A",
  togglerActiveBackgroundColor: "#333338",
  togglerFontColor: "#C5C5C5",
  togglerFontColorActive: "#FAF8F8",

  tagBackgroundColor: "#25252A",
  tagFontColor: "#FAF8F8",

  // View only mode
  viewOnlyLabelFontColor: "#FF5C79",
  viewOnlyLabelBackgroundColor: "rgba(255, 92, 121, 0.2)",

  // User Initials
  firstUserInitials: "#8280FF",
  secondUserInitials: "#FF892E",
  thirdUserInitials: "#FFD735",
  userInitialsFontColor: "#020202",

  modalBackgroundColor: "#1A1A1F",
  modalTitleColor: "#FAF8F8",
  modalDescriptionColor: "#C5C5C5",

  // Error Message
  errorMessageBg: "rgba(255,92,121,0.1)",

  // Integrations modal
  integrationsSetupBackgroundColor: "#25252A",
  accordionCardBackgroundColor: "#212126",
  accordionTitle: "#FAF8F8",
  accordionStepNumberBackgroundColor: "#FF5C79",
  accordionDescriptionFontColor: "#C5C5C5",
  accordionDividerColor: "#333338",
  accordionCollapsedBackgroundColor: "#3A3A3E",
  accordionCollapsedFontColor: "#FAF8F8",
  accordionCollapsedBorderColor: "rgba(255, 255, 255, 0.5)",

  integrationCardBorderColor: "#2A2A2F",
  integrationCardTitleFontColor: "#E8E8E8",
  defaultIntegrationCardDescriptionFontColor: "#C5C5C5",
  warningIntegrationCardDescriptionFontColor: "#FFD735",
  errorIntegrationCardDescriptionFontColor: "#FF5C79",
  successIntegrationCardDescriptionFontColor: "#2DFAB0",

  integrationActiveStepBackground: "rgba(255, 92, 121, .1)",
  integrationActiveBorderColor: "#FF5C79",

  // Dashboard Card
  dashboardCardBackground: "#212126",
  dashboardCardTitleFont: "#FFFFFF",
  dashboardCardSubtitleFont: "#C5C5C5",
  dashboardCardDivider: "#2A2A2F",
  dashboardCardLabel: "#C5C5C5",
  dashboardCardAmount: "#FFFFFF",
  dashboardPullingDataCardBackground: "linear-gradient(92.1deg, #0EA7FE 0%, #4A48FF 100%)",

  dashboardCardStateBackground: "#2A2A2F",

  // Account Balance Card
  accountBalanceBackgroundColor: "#212126",
  accountBalanceTitleColor: "#FFFFFF",
  accountBalanceSubtitleColor: "#C5C5C5",
  accountBalanceDetailsColor: "#FAF8F8",
  accountBalanceDividerColor: "#2A2A2F",

  //Platform Earnings Card
  platformCardBackgroundColor: "#212126",
  platformCardTitleColor: "#FAFAF8",
  platformCardSubtitleColor: "#C5C5C5",
  platformCardDividerColor: "#2A2A2F",
  platformExpandedGameBackgroundColor: "rgba(33, 33, 38, 0.55)",
  progressBarBackgroundColor: "#2DFAB0",

  // State buttons
  defaultButtonBackgroundColor: "#2A2A2F",
  defaultButtonBackgroundColorHover: "#333338",
  defaultButtonFontColor: "#FAF8F8",
  warningButtonBackgroundColor: "#FFD73533",
  warningButtonFontColor: "#FFD735",
  reconnectButtonBackgroundColor: "#FFD73533",
  reconnectButtonFontColor: "#FFD735",
  verifyingConnectionButtonBackgroundColor: "#2A2A2F",
  verifyingConnectionButtonFontColor: "#C5C5C5",
  completeConnectingButtonBackgroundColor: "#2DFAB033",
  completeConnectingButtonFontColor: "#2DFAB0",
  connectButtonBackgroundColor: "#333338",
  connectButtonBackgroundColorHover: "#333338",
  connectButtonFontColor: "#FAF8F8",

  successButtonBackgroundColor: "#2A2A2F",
  successButtonFontColor: "#C5C5C5",
  lackingPermissionButtonBackgroundColor: "#2A2A2F",
  lackingPermissionButtonFontColor: "#C5C5C5",
  errorDashboardButtonBackgroundColor: "#FF5C7933",
  errorDashboardButtonFontColor: "#FF5C79",
  errorButtonBackgroundColor: "#2A2A2F",
  errorButtonFontColor: "#C5C5C5",

  // Unlink Button
  unlinkShadow: "rgba(0, 0, 0, 0.4)",
  shadowColor: "rgba(0, 0, 0, 0.4)",

  repaidBackgroundColor: "#2DFAB0",
  repaidPaymentFontColor: "#2DFAB0",
  upcomingBackgroundColor: "#FFD735",
  upcomingPaymentFontColor: "#FFD735",
  leftBackgroundColor: "#333338",
  leftPaymentFontColor: "#333338",

  failedPaymentBackgroundColor: "#FF5C7933",
  failedPaymentFontColor: "#FF5C79",
  pendingPaymentBackgroundColor: "#FF892E33",
  pendingPaymentFontColor: "#FF892E",
  completedPaymentBackgroundColor: "#2DFAB033",
  completedPaymentFontColor: "#2DFAB0",
  disbursedPaymentBackgroundColor: "#8280FF33",
  disbursedPaymentFontColor: "#8280FF",

  errorBackgroundColor: "#FF5C7919",
  errorFontColor: "#FF5C79",
  pendingBackgroundColor: "#FF892E19",
  pendingFontColor: "#FF892E",
  successBackgroundColor: "#2DFAB019",
  successFontColor: "#2DFAB0",

  //charts
  barChartInflow: "#2DFAB0",
  barChartInflowGradient: "repeating-linear-gradient(135deg, #2DFAB0 0 2px,#3f886e 2px 9px)",
  barChartInflowPredictedGradient: "repeating-linear-gradient(135deg, #2DFAB0 0 2px,#2dfab029 2px 9px)",
  barChartOutflow: "#BF6BFF",
  barChartOutflowGradient: "repeating-linear-gradient(135deg, #BF6BFF 0 2px,#6b428a 2px 9px)",
  barChartOutflowPredictedGradient: "repeating-linear-gradient(135deg, #BF6BFF 0 2px,#bf6bff29 2px 9px)",
  lineChartBalance: "#66C8FF",
  lineChartBurn: "#8280FF",
};
