import React, { useState } from "react";
import { ReactComponent as AlertCircle } from "@assets/img/icons/common/alertCircle.svg";
import { InternalLink } from "@inputs";
import styled from "styled-components";
import { trackGTMEvent, useTrackHover, FINANCIALS_ACTION, FINANCIALS_PATHNAME } from "@utils/gtm";

import { SimpleAccordionTooltip } from "@common/tooltips/simple-accordion-tooltip/SimpleAccordionTooltip";
import { Heading, Text, Box } from "@chakra-ui/react";

export function ChartHeader({ title, tooltipText, type, filters = [], dataTestId = "" }) {
  const [addTracking, removeTracking] = useTrackHover();

  const handleMouseEnter = () => {
    addTracking({ event: FINANCIALS_ACTION.HOVER_TOOLTIP, name: title, pathname: FINANCIALS_PATHNAME });
  };

  const handleMouseLeave = () => {
    removeTracking();
  };

  return (
    <Box w={"792px"}>
      <HeaderWrapper style={{ marginBottom: "24px" }} data-test-id={dataTestId}>
        <Heading size="small" fontWeight={600}>
          {title}{" "}
          {tooltipText && (
            <>
              <AlertCircle
                id={`id-target-${type}`}
                fill={"#C5C5C5"}
                style={{
                  transform: "rotate(180deg)",
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              />
              <SimpleAccordionTooltip
                idTarget={`id-target-${type}`}
                content={tooltipText}
                accordionContent={
                  <>
                    {`For a complete picture of your finances, please visit the `}
                    <InternalLink
                      to="/integrations"
                      data-test-id={"integration-page-link"}
                      onClick={() =>
                        trackGTMEvent({
                          event: FINANCIALS_ACTION.INTEGRATION_PAGE_LINK,
                          tooltip: title,
                        })
                      }
                    >
                      Integrations page
                    </InternalLink>
                    {` and ensure you've connected all bank accounts associated with your business.`}
                  </>
                }
                trackData={{ name: title }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              />
            </>
          )}
        </Heading>
        <HeaderWrapper>
          {filters &&
            filters.map((filter, index) => (
              <HeaderFilter
                key={`key-chart-filter-${filter.title}-${index}`}
                bColor={filter.color}
                filter={filter.title}
                design={filter.design}
                onClick={filter.onClick}
              />
            ))}
        </HeaderWrapper>
      </HeaderWrapper>
    </Box>
  );
}

const HeaderFilter = ({ bColor = "#000000", filter = "Filter", design = "square", onClick = null }) => {
  // Designs: square, solid-line, dashed-line
  const [isDisabled, setIsDisabled] = useState(false);

  const handlerOnClick = () => {
    if (typeof onClick == "function") {
      setIsDisabled(!isDisabled);
      onClick(!isDisabled);
    }
  };

  return (
    <FilterContainer notActionable={!onClick} isDisabled={isDisabled} bColor={bColor} onClick={handlerOnClick}>
      <div className={design}></div> <Text size={"smallAlt"}>{filter}</Text>
    </FilterContainer>
  );
};

const HeaderWrapper = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FilterContainer = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  filter: ${(props) => (props.isDisabled ? "brightness(50%)" : "brightness(1)")};
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  ${(props) => props.notActionable && "cursor: default;"}

  &:not(:last-child) {
    margin-right: 1rem;
  }

  .square {
    height: 12px;
    width: 12px;
    border-radius: 2px;
    margin-right: 8px;
    background-color: ${(props) => props.bColor};
  }

  .solid-line {
    height: 2px;
    width: 24px;
    margin-right: 8px;
    background-color: ${(props) => props.bColor};
  }

  .dashed-line {
    height: 2px;
    width: 24px;
    margin-right: 8px;
    background-color: transparent;
    border-bottom: 2px dashed ${(props) => props.bColor};
  }
`;
