import React from "react";
import { Box, FormControl, Icon, Input, Text, VStack } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useFormContext } from "react-hook-form";
import { FileDrop } from "@common/upload/FileDrop";
import { Autocomplete } from "@common/autocomplete/Autocomplete";
import { useFlags } from "launchdarkly-react-client-sdk";
import { SubsectionTitle, pricingTiersLabel } from "@components/catalog";
import { Categories } from "./categories/Categories";
import { DeepLink } from "./deepLink/DeepLink";
import {
  NameFieldTooltip,
  PricingTierTooltip,
  ImageFieldTooltip,
  CategoryFieldTooltip,
  ItemIdFieldTooltip,
} from "@components/catalog/tooltip";
import { catalogThunks, catalogSelector, pricingTiersSelector } from "@redux/webshops/catalog/catalogSlice";
import { ReactComponent as UploadImageIcon } from "src/assets/img/icons/common/image_upload.svg";

export const General = () => {
  const { deepLinks } = useFlags();
  const dispatch = useDispatch();
  const { register, control, formState } = useFormContext();
  const { errors } = formState;
  const { selectedItem } = useSelector(catalogSelector);
  const { tiers: pricingTiers } = useSelector(pricingTiersSelector);

  function handleOnImageChange(event) {
    const files = event.dataTransfer?.files || event.target.files;
    if (files.length > 0) {
      if (selectedItem) {
        const formData = new FormData();
        formData.append("image", files[0]);
        dispatch(catalogThunks.updateImage(formData));
      }
      return files;
    }
    return null;
  }

  return (
    <>
      <FormControl>
        <SubsectionTitle isRequired tooltip={<NameFieldTooltip />}>
          Name
        </SubsectionTitle>

        <Input
          {...register("name", { required: true })}
          placeholder={"Pick a good name"}
          borderColor={errors?.name ? "brandRed.400" : "transparent"}
        />
      </FormControl>

      <FormControl>
        <SubsectionTitle>Description</SubsectionTitle>
        <Input {...register("description")} placeholder={"Enter a detailed description"} borderColor={"transparent"} />
      </FormControl>

      <FormControl>
        <SubsectionTitle isRequired tooltip={<PricingTierTooltip />}>
          Price
        </SubsectionTitle>

        <Controller
          control={control}
          name={"pricingTierLevelId"}
          rules={{ required: true }}
          render={({ field: { value, onChange } }) => {
            const selected = pricingTiers ? pricingTiers.find((tier) => tier.id === value) : null;
            const selectedValue = selected ? pricingTiersLabel(selected) : "";
            return (
              <Autocomplete
                id={"pricingTier"}
                itemSelected={selected}
                value={selectedValue}
                placeholder={"$0.00"}
                options={pricingTiers}
                required={
                  formState.errors.pricingTierLevelId && formState.errors.pricingTierLevelId.type === "required"
                }
                onlySelectableOptions
                getOptionLabel={(pricingTier) => pricingTiersLabel(pricingTier)}
                onChange={(pricingTier) => {
                  onChange(pricingTier?.id ? pricingTier.id : "");
                }}
              />
            );
          }}
        />
      </FormControl>

      <VStack alignItems={"flex-start"} w={"full"}>
        <SubsectionTitle isRequired tooltip={<ImageFieldTooltip />}>
          Item image
        </SubsectionTitle>

        <Controller
          name={"image"}
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange }, fieldState }) => {
            let image = "";
            if (typeof value === "string") {
              image = value;
            } else if (value?.length > 0) {
              image = URL.createObjectURL(value[0]);
            }
            return (
              <VStack
                alignItems={"center"}
                justifyContent={"center"}
                border="2px dashed"
                borderColor={fieldState.error ? "brandRed.400" : "dark.100"}
                rounded={"8px"}
                bg="dark.400"
                py="24px"
                px="20px"
                w={"full"}
                pos={"relative"}
                h="180px"
                bgSize={"cover"}
                bgImage={image ? `url(${image})` : ""}
                bgPos={"center"}
              >
                <FileDrop
                  acceptedFileTypes={"image/*"}
                  onChange={(event) => {
                    onChange(handleOnImageChange(event));
                  }}
                />
                {image ? (
                  <Box py="16px" px="24px" bg="rgba(33, 33, 38, 0.78)" borderRadius={"8px"}>
                    <Text color="textWhite.400" fontWeight={500} size="regular">
                      Change image
                    </Text>
                  </Box>
                ) : (
                  <VStack gap="16px" my="auto">
                    <Icon w="62px" h="62px" as={UploadImageIcon} />
                    <Text>Click to upload PNG, JPEG, or drag and drop here</Text>
                  </VStack>
                )}
              </VStack>
            );
          }}
        />
      </VStack>

      <FormControl>
        <SubsectionTitle isRequired tooltip={<CategoryFieldTooltip />}>
          Category
        </SubsectionTitle>
        <Categories />
      </FormControl>

      <FormControl>
        <SubsectionTitle tooltip={<ItemIdFieldTooltip />}>Item ID</SubsectionTitle>

        <Input
          {...register("externalId", { required: true })}
          placeholder={"Pick a good ID"}
          borderColor={errors?.externalId ? "brandRed.400" : "transparent"}
        />
      </FormControl>

      {deepLinks && <DeepLink />}
    </>
  );
};
