export const getSettingsPayload = (data = {}, settings = {}) => {
  try {
    return {
      title: data?.title || settings?.title || "",
      description: data?.description || settings?.description || "",
      supportLink: data?.supportLink || settings?.supportLink || "",
      supportLinkLabel: data?.supportLinkLabel || settings?.supportLinkLabel || "",
    };
  } catch (error) {
    return {
      title: "",
      description: "",
      supportLink: "",
      supportLinkLabel: "",
    };
  }
};
