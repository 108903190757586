export function formatNumber({ number, minimumCompactDelimiter = 100000, options = {} }) {
  const intlNumFormat = new Intl.NumberFormat("en-US", {
    style: "decimal",
    maximumFractionDigits: 1,
    notation: Math.abs(number) > minimumCompactDelimiter ? "compact" : "standard",
    compactDisplay: "short",
    unitDisplay: "narrow",
    // roundingMode: "floor", Changed behaviod with new version of Node
    ...options,
  });

  return intlNumFormat.format(number);
}

export function formatPercentage(number) {
  if (typeof number !== "number") {
    throw new Error("formatPercentage: number must be a number");
  }
  if (Math.abs(number) < 1) return "< 1%";
  return `${formatNumber({ number: Math.abs(Math.round(number)) })}%`;
}
