export const parseUploadedFiles = (data = {}) => {
  let { manualConnectionState = {} } = data;
  // If manualConnectionState actually comes through as null, it
  // wont be set to an empty object by default.
  if (!manualConnectionState) manualConnectionState = {};
  const {
    // status,
    files = [],
  } = manualConnectionState;

  const uploadedFiles = {};
  files.forEach((file) => {
    const { status, fileName } = file;
    if (status !== "UPLOADED_CLEAN") return;
    uploadedFiles[fileName] = file;
  });

  return uploadedFiles;
};
