import { Flex, Icon, Link, Spinner, Text } from "@chakra-ui/react";
import { CUSTOM_DOMAIN_STATUS, CUSTOM_DOMAIN_STATUS_PILL } from "@src/consts/webshops/settings";
import { ReactComponent as ExternalLink } from "@assets/img/icons/common/ExternalLink.svg";

import PropTypes from "prop-types";

export const CustomLink = ({ url, status, isLoading = false }) => {
  const isConnected = status === CUSTOM_DOMAIN_STATUS.CONNECTED;
  const Wrapper = isConnected ? Link : Flex;
  const cursor = isConnected ? "pointer" : "not-allowed";

  return (
    <Wrapper
      href={url}
      display={"flex"}
      flexDirection={"row"}
      p={"12px"}
      border={"1px solid"}
      borderColor={"dark.300"}
      borderRadius={"10px"}
      justifyContent={"space-between"}
      alignItems={"center"}
      target={"_blank"}
      _hover={{ textDecoration: "none", bgColor: isConnected && "dark.500" }}
      w={"100%"}
      cursor={cursor}
    >
      <Text size={"smallAlt"} maxW={"400px"} letterSpacing={"0.14px"} fontWeight={600} color={"textWhite.400"}>
        {url}
        <Icon as={ExternalLink} ml={"8px"} h={"16px"} w={"16px"} fill={"textWhite.400"} />
      </Text>

      {status && (
        <Flex
          p={"4px 8px"}
          borderRadius={"20px"}
          backgroundColor={CUSTOM_DOMAIN_STATUS_PILL[status].bgColor}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {isLoading ? (
            <Spinner size={"sm"} color={CUSTOM_DOMAIN_STATUS_PILL[status].color} />
          ) : (
            <Text
              size={"smallAlt"}
              letterSpacing={"0.14px"}
              fontWeight={600}
              color={CUSTOM_DOMAIN_STATUS_PILL[status].color}
            >
              {CUSTOM_DOMAIN_STATUS_PILL[status].label}
            </Text>
          )}
        </Flex>
      )}
    </Wrapper>
  );
};

CustomLink.propTypes = {
  url: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
};
