import React from "react";

import { BodyRegular16, BodySmallAlt } from "@styled";
import { StyledCardInfoTooltip } from "../../advance/StyledAdvanceCard";

import { currencyFormat } from "@utils/currency";

import { ReactComponent as TooltipIcon } from "@assets/img/icons/common/tooltip.svg";

const AccordionRow = ({ highlighted, amount, message, label, tooltip }) => {
  if (typeof amount === "number" && !isNaN(amount)) amount = currencyFormat(amount);

  return (
    <div className={"accordion-row " + (highlighted ? "total" : "partial")}>
      <BodyRegular16 className="row-label">
        <span>{label}</span>
        {tooltip && <CardInfoTooltip title={tooltip.title} text={tooltip.text} />}
      </BodyRegular16>
      <BodyRegular16 className="row-amount">{amount || message}</BodyRegular16>
    </div>
  );
};

const CardInfoTooltip = ({ title, text }) => (
  <StyledCardInfoTooltip>
    <TooltipIcon className="card-tooltip-icon" />
    <div className="card-tooltip">
      <div className="tooltip-header">
        <span className="tooltip-title">{title}</span>
      </div>
      <div className="tooltip-content">
        <BodySmallAlt>{text}</BodySmallAlt>
      </div>
    </div>
  </StyledCardInfoTooltip>
);

export default AccordionRow;
