import React from "react";
import symbols from "@utils/symbols";
import { Flex, Text, Image, HStack, Box, useTheme } from "@chakra-ui/react";
import { parseActivities } from "./utils";

export const TooltipContent = ({ item = {}, inflowColor = "", outflowColor = "", dailyView }) => {
  const theme = useTheme();
  const tooltipHeader = item.fullDate();
  const [totalInflow, inflowActivities] = parseActivities({ item, type: "inflow" });
  const [totalOutflow, outflowActivities] = parseActivities({ item, type: "outflow" });
  return (
    <Flex w={"100%"} direction={"column"} gap={"10px"}>
      <Box borderBottom={`1px solid ${theme.colors.dark[300]}`}>
        <Text size={"smallAlt"} fontSize={"16px"} fontWeight={"600"} color={"textWhite.400"} mb={"10px"}>
          {`${symbols.calendar} ${tooltipHeader}`}
        </Text>
      </Box>

      {item.isPredicted && dailyView ? (
        <>
          {!!inflowActivities.length && (
            <FutureActivityBody
              activities={inflowActivities}
              title={"Cash in"}
              color={inflowColor}
              total={totalInflow}
            />
          )}
          {!!outflowActivities.length && (
            <FutureActivityBody
              activities={outflowActivities}
              title={"Cash out"}
              color={outflowColor}
              total={totalOutflow}
            />
          )}
        </>
      ) : (
        <>
          {!!inflowActivities.length && (
            <ActivityBody activities={inflowActivities} title={"Cash in"} color={inflowColor} total={totalInflow} />
          )}
          {!!outflowActivities.length && (
            <ActivityBody activities={outflowActivities} title={"Cash out"} color={outflowColor} total={totalOutflow} />
          )}
        </>
      )}
    </Flex>
  );
};

const CashTypeHeader = ({ title = "", color = "", total = "" }) => {
  return (
    <Flex justifyContent={"space-between"} mb={"5px"}>
      <Text size={"regular"} color={color} fontWeight={"500"}>
        {title}
      </Text>
      {!!total && (
        <Text size={"regular"} color={"textWhite.400"} fontWeight={"500"}>
          {total}
        </Text>
      )}
    </Flex>
  );
};

const ActivityBody = ({ activities = [], title = "", color = "", total = "" }) => {
  return (
    <>
      <CashTypeHeader title={title} color={color} total={total} />
      {activities.map(({ id, image, name, amount }) => {
        return (
          <Flex key={`activity-${id}`} justifyContent={"space-between"} gap={"75px"}>
            <HStack>
              <Image
                h={"15px"}
                w={"15px"}
                borderRadius={"4px"}
                src={image?.icon || image.defaultImage}
                alt={name}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = image.defaultImage;
                }}
              />
              <Text size={"small"} letterSpacing={"0.009em"}>
                {name}
              </Text>
            </HStack>
            <Text size={"small"} letterSpacing={"0.009em"}>
              {amount}
            </Text>
          </Flex>
        );
      })}
    </>
  );
};

const FutureActivityBody = ({ activities = [], title = "", color = "", total = "" }) => {
  const theme = useTheme();
  return (
    <>
      <CashTypeHeader title={title} color={color} total={total} />
      {activities.map(({ id, image, name, amount, source, fiscalPeriod, estimatedDate }) => {
        return (
          <Flex
            key={`future-activity-${id}`}
            direction={"column"}
            gap={"8px"}
            borderBottom={`1px solid ${theme.colors.dark[300]}`}
            pb={"8px"}
            _last={{ borderBottom: "0px" }}
          >
            <HStack>
              <Image
                h={"19px"}
                w={"19px"}
                borderRadius={"4px"}
                src={image?.icon || image.defaultImage}
                alt={name}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = image.defaultImage;
                }}
              />
              <Text size={"regular"} fontWeight={"500"}>
                {name}
              </Text>
            </HStack>
            <Flex justifyContent={"space-between"} alignItems={"center"} gap={"50px"}>
              <Text size={"regular"} color={"textWhite.400"} fontWeight={"500"}>
                {amount}
              </Text>
              <Text size={"mini"} letterSpacing={" 0.24px;"}>
                Source: {source}
              </Text>
            </Flex>
            <Flex justifyContent={"space-between"} alignItems={"center"}>
              <Text size={"mini"} letterSpacing={" 0.24px;"}>
                {`${symbols.calendar}${estimatedDate ? " Est." : ""} Fiscal Period:`}
              </Text>
              <Text size={"mini"} letterSpacing={" 0.24px;"}>
                {fiscalPeriod}
              </Text>
            </Flex>
          </Flex>
        );
      })}
    </>
  );
};
