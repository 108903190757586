export const AUTH_METHODS = {
  PASSWORD: "PASSWORD",
  MANUAL: "MANUAL",
  GOOGLE: "GOOGLE",
  FACEBOOK: "FACEBOOK",
  APPLE: "APPLE",
};

export const CUSTOM_DOMAIN_STATUS = {
  NOT_CONNECTED: "NOT_CONNECTED",
  PENDING: "PENDING",
  CONNECTED: "CONNECTED",
  ERROR: "ERROR",
};

export const CUSTOM_DOMAIN_STATUS_PILL = {
  [CUSTOM_DOMAIN_STATUS.PENDING]: { label: "Verifying domain", bgColor: "#FFD73529", color: "brandYellow.400" },
  [CUSTOM_DOMAIN_STATUS.CONNECTED]: { label: "Connected", bgColor: "#2DFAB029", color: "gradients.eucalyptus" },
};
