export const LINK_TYPES = {
  SOCIAL_PLATFORM: "SOCIAL_PLATFORM",
  TEXT: "TEXT",
};

export const TERMS_LABEL = "Terms of Service";
export const PRIVACY_LABEL = "Privacy Policy";

export const parseLink = (link, index) => {
  // Some v1 to v2 conversion
  const { name, label, url, linkUrl, position } = link;
  const parseLink = {
    ...link,
    name: name || label,
    label: name || label,
    url: linkUrl || url,
    linkUrl: linkUrl || url,
  };
  if (typeof position !== "number" && typeof index === "number") {
    parseLink.position = index;
  }
  return parseLink;
};

export const extractLinks = (links = {}, data = {}) => {
  try {
    const { platforms = [] } = links;
    let { externalLinks = [], socialLinks = [] } = data;

    if (!Array.isArray(externalLinks)) externalLinks = [];
    if (!Array.isArray(socialLinks)) socialLinks = [];

    externalLinks = externalLinks.map(parseLink);
    socialLinks = socialLinks.map(parseLink);

    const termsOfService = externalLinks.find((link) => link.name === TERMS_LABEL) || {};
    const privacyPolicy = externalLinks.find((link) => link.name === PRIVACY_LABEL) || {};
    // link lists needs position updated after removing terms and privacy
    const linksList = externalLinks
      .filter((link) => link.name !== TERMS_LABEL && link.name !== PRIVACY_LABEL)
      .map((link, index) => {
        return {
          ...link,
          position: link?.position || index,
        };
      });

    linksList.sort((a, b) => a.position - b.position);
    socialLinks.sort((a, b) => a.position - b.position);

    // links.platforms has all the social platform options that can be connected
    // socialLinks has the actual social links that are connected
    // Merge the two to get a more convenient rendering list
    const socialsList = platforms.map((platform, index) => {
      const socialLink = socialLinks.find((link) => link.platform.id === platform.id) || {};
      return {
        ...socialLink,
        platform,
      };
    });

    return {
      termsOfService,
      privacyPolicy,
      linksList,
      socialsList,
    };
  } catch (error) {
    console.log("Error extracting links", error);
    return {
      termsOfService: {},
      privacyPolicy: {},
      linksList: [],
      socialsList: [],
    };
  }
};
