import React, { useEffect } from "react";

import { BodySmall14, TitleRegularPlus } from "@components/styled";
import StyledAuthCard from "@pages/authentication/flows/StyledAuthCard";
import AuthenticationContainer from "../authentication/AuthenticationContainer";
import { LogOutIcon } from "./StyledErrorPage";
import { SanloButton } from "@inputs";

import { trackGTMEvent } from "@utils/gtm";
import { useLogout } from "@utils/custom-hooks/useAuth";

const ErrorPage = ({ ctaLabel, description }) => {
  const appLogout = useLogout();

  // Tag Manager ErrorPage Component View Event
  useEffect(() => {
    trackGTMEvent({
      "event": "Component View",
      "Component Name": "ErrorPage",
    });
  }, []);

  return (
    <AuthenticationContainer hasLogout logOutText={<LogOutIcon />} error>
      <StyledAuthCard>
        <div className="auth-card-header">
          <TitleRegularPlus className="auth-card-title">
            <span role="img" aria-label="sorry">
              😕
            </span>{" "}
            Something went wrong...
          </TitleRegularPlus>

          <div className="auth-card-description">
            <BodySmall14>{description}</BodySmall14>
          </div>
        </div>

        <div className="auth-card-footer">
          <SanloButton
            label={ctaLabel}
            onClick={() => {
              appLogout();
            }}
            style={{
              marginTop: "8px",
            }}
          />
        </div>
      </StyledAuthCard>
    </AuthenticationContainer>
  );
};

export default ErrorPage;
