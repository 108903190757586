import { toLower } from "lodash";
import text from "@utils/text";

const URL_REGEX =
  /^(https?:\/\/|www\.)((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(#[-a-z\d_]*)?$/i;

export const VALIDATORS = {
  URL: {
    regex: URL_REGEX,
    message: "Please enter a valid URL",
  },
  EMAIL: {
    regex: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/,
    message: "Please enter a valid email",
  },
  TIKTOK: {
    regex: /^(https?:\/\/)?(www\.)?tiktok\.com\/@[^/?&]+$/i,
    message: "Please enter a valid TikTok URL (e.g. https://www.tiktok.com/@username)",
  },
};

export const LOGOS = {
  sanlo: require("@assets/img/icons/logos/SanloRoundedLogo.svg").default,
  bank: require("@assets/img/icons/common/bankAccount.svg").default,
};

export const alphaTrim = (string) => {
  return string
    ?.replace(/[\W_]+/g, "")
    .replace(/ /g, "")
    .toLowerCase()
    .trim();
};

export const tagTrim = (string = "") => {
  try {
    return string
      .replace(/[^a-zA-Z0-9]/g, "-")
      .replace(/[\])}[{(]/g, "")
      .toLowerCase();
  } catch (e) {
    return "";
  }
};

export const hideSidebar = (location = {}) => {
  try {
    const { pathname } = location;
    const hiddenRoutes = ["/builder", "/checkout-builder"];
    return hiddenRoutes.some((route) => pathname.includes(route));
  } catch (e) {
    return false;
  }
};

export const copy = (objToCopy) => {
  // Object.assign only does a shallow copy
  return JSON.parse(JSON.stringify(objToCopy));
};

export const objSort = (arrayOfObjs, key, opts = {}) => {
  const { descending = false } = opts;
  if (descending) {
    arrayOfObjs.sort((a, b) => (a[key] < b[key] ? 1 : b[key] < a[key] ? -1 : 0));
  } else {
    arrayOfObjs.sort((a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0));
  }
};

export const getQueryParameters = (queryParameters) => {
  let queryParams = queryParameters || window.location.search;
  if (!queryParams) return {};
  queryParams = queryParams.substring(1);

  const splitParams = queryParams.split("&");
  const params = {};

  for (let i = 0; i < splitParams.length; i++) {
    const param = splitParams[i].split("=");
    let [key, value] = param;
    if (!key) continue;

    if (value === "true" || value === "false") {
      value = value === "true";
    } else {
      value = decodeURIComponent(value);
    }

    params[key] = value;
  }

  return params;
};

export const parseAuth0Params = (params = {}) => {
  const { message = "", error = "", success = false, error_description = "" } = params;

  // TODO: Maybe have a better param to key off instead of a error string
  if (error_description.toLowerCase().match(/please provide a business email address/)) {
    params.invalid_email = true;
  }
  if (error.toLowerCase().match(/access_denied/)) {
    params.access_denied = true;
  }

  // The Auth0 query param parsing requires both a message and a success key
  if (!Object.hasOwn(params, "success") && !Object.hasOwn(params, "message")) {
    if (toLower(error_description).includes(text.t("non_whitelisted_email"))) {
      if (error_description.indexOf(":") !== -1) {
        const user = {};
        const information = error_description.split(":");
        user.email = information[0];
        params.user = user;
      } else {
        params.user = {
          email: null,
        };
      }
    }
    return params;
  }

  if (!success && message) {
    if (message.match(/expired/)) {
      // Check for "Access expired."
      params.expired = true;
    } else if (message.match(/used once/)) {
      // Check for "This URL can only be used once."
      params.verified = true;
    } else if (message.match(/only once/)) {
      // Check for "This URL can be used only once."
      params.verified = true;
    } else {
      params.unknownError = true;
    }
  }

  if (success && message) {
    // Check for "Your email was verified. You can continue using the application."
    if (message.match(/was verified/)) {
      params.verified = true;
    }
  }

  return params;
};

export const addQueryParams = (url, params = {}, opts = {}) => {
  const { encode = false } = opts;
  let connector = "?";
  let newURL = url;
  Object.keys(params).forEach((key) => {
    const value = params[key];
    if (value === undefined || value === null || typeof value === "object" || value === "") return;
    newURL += `${connector}${key}=${value}`;
    // After anything is added, change the connector symbol
    // It'll set each time, but that doesn't matter
    connector = "&";
  });
  if (encode) newURL = encodeURI(newURL);
  return newURL;
};

export const verify = (args = []) => {
  if (!Array.isArray(args)) args = [args];
  if (!args.length) return false;
  for (let i = 0; i < args.length; ++i) {
    const arg = args[i];
    // All numbers should be good (0s are falsey)
    if (typeof arg === "number") continue;
    // The arg is generally falsey
    if (!arg) return false;
    // The arg is an array and is empty
    if (Array.isArray(arg) && !arg.length) return false;
    // The arg is an object and is empty
    if (typeof arg === "object" && !Object.keys(arg).length) return false;
  }
  return true;
};

export const randomBetween = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const generateUUID = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    // eslint-disable-next-line
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const getBrowserName = () => {
  let userAgent = navigator.userAgent;
  let browserName;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = "chrome";
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = "firefox";
  } else if (userAgent.match(/safari/i)) {
    browserName = "safari";
  } else if (userAgent.match(/opr\//i)) {
    browserName = "opera";
  } else if (userAgent.match(/edg/i)) {
    browserName = "edge";
  } else {
    browserName = "No browser detection";
  }

  return browserName;
};

export const scrollToTarget = (target) => {
  try {
    document.querySelector(`[data-scroll-target="${target}"]`).scrollIntoView({ behavior: "smooth" });
  } catch (e) {
    // Node wasn't found or something, should be fine
  }
};

export const getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const validateURL = (input = "", validator = {}) => {
  const { regex = URL_REGEX } = validator;
  return regex.test(input);
};

export const getValidator = (opts = {}) => {
  const { platform = "" } = opts;
  const platformKey = typeof platform === "string" ? platform.toUpperCase() : "";
  const validator = VALIDATORS[platformKey] || VALIDATORS.URL;
  return {
    validate: (input) => validateURL(input, validator),
    validateMsg: validator.message,
  };
};
