import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { changeCashActivityTime, cashActivitySelector } from "@redux/financials/cashActivitySlice";
import { CASH_ACTIVITY_TIME_OPTIONS } from "@src/consts/financial-intelligence";

export const TimeSwitch = () => {
  const {
    getCashActivity: { selectedTime },
  } = useSelector(cashActivitySelector);
  const dispatch = useDispatch();

  return (
    <Flex
      p={"4px"}
      alignItems={"center"}
      gap={"2px"}
      borderRadius={"10px"}
      backgroundColor={"dark.500"}
      data-test-id={"cash-time-switch"}
    >
      {Object.keys(CASH_ACTIVITY_TIME_OPTIONS).map((option) => {
        return (
          <SwitchButton
            id={`switch-button-${CASH_ACTIVITY_TIME_OPTIONS[option].grouping}`}
            key={`switch-button-${option}`}
            option={CASH_ACTIVITY_TIME_OPTIONS[option]}
            selected={selectedTime}
            onClick={() => {
              dispatch(changeCashActivityTime({ time: CASH_ACTIVITY_TIME_OPTIONS[option] }));
            }}
          />
        );
      })}
    </Flex>
  );
};

const SwitchButton = ({ id, option, selected, onClick, isDisabled }) => {
  const activeOption = option.grouping === selected.grouping;
  const color = activeOption ? "textWhite.400" : "textDefault.400";
  const backgroundColor = activeOption ? "dark.300" : "dark.500";
  return (
    <Flex
      id={id}
      p={"8px"}
      minW={"68px"}
      borderRadius={"8px"}
      backgroundColor={backgroundColor}
      cursor={isDisabled ? "not-allowed" : "pointer"}
      justifyContent={"center"}
      onClick={onClick}
    >
      <Text size={"small"} color={color} letterSpacing={"0.14px"} userSelect={"none"}>
        {option.label}
      </Text>
    </Flex>
  );
};
