import styled from "styled-components";

const StyledTransactionsHistory = styled.div`
  & {
    .transactions-history-header {
      margin-bottom: 16px;
      display: flex;
      justify-content: space-between;

      .header-col {
        width: 28.3%;

        :last-child {
          width: 15%;
        }

        .header-label {
          font-family: "Inter", "Helvetica", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.01em;
          color: ${({ theme }) => theme.textEmphasis};
        }

        .right-aligned {
          text-align: end;
          margin-right: 28px;
        }
      }
    }

    .transactions-pagination-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .transactions-history-container {
      // Height is capped to 6 transactions
      max-height: 364px;

      &.max-page-size {
        height: 364px;
      }
    }
  }
`;

export const StyledTransactionRow = styled.div`
  &.transaction-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
    margin: 8px 0;

    .row-content {
      width: 28.3%;
      color: ${({ theme }) => theme.textGray};

      :last-child {
        width: 15%;
      }

      .status {
        text-align: right;

        .chip {
          cursor: pointer;
          width: 98px;
          display: inline-block;
          text-align: center;
          border-radius: 14px;
          padding: 6px 12px;
          background: ${({ theme, status }) => theme[`${status}PaymentBackgroundColor`]};
          color: ${({ theme, status }) => theme[`${status}PaymentFontColor`]};
        }
      }

      .amount-container {
        display: flex;
        align-items: center;

        img {
          border-radius: 4px;
          margin-right: 8px;
          width: 24px;
          height: 24px;
        }

        .transaction-tooltip {
          margin-left: 6px;
          fill: ${({ theme }) => theme.textGray};
        }
      }
    }
  }
`;

export default StyledTransactionsHistory;
