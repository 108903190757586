import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import actions from "@redux/actions";
import { useFlags } from "launchdarkly-react-client-sdk";
import { showToast } from "@utils/redux";

import { manualConnectionThunks, manualConnectionSelector } from "@redux/integrations/base/manualSlice";

import { StyledConnectionFlow } from "@components/integrations/StyledConnectionFlow";

import { AppsFlyerStep1 } from "./AppsFlyerStep1";
import { AppsFlyerStep2 } from "./AppsFlyerStep2";
import { AppsFlyerStep3 } from "./AppsFlyerStep3";
import { AppsFlyerStep4 } from "./AppsFlyerStep4";

import FadeStepsContainer from "@common/animated/FadeStepsContainer";
import { ReactComponent as CrossIcon } from "@assets/img/icons/common/modal-cross.svg";
import {
  SaveProgressContainer,
  SaveProgressButton,
} from "@components/integrations/connection-guide/SaveProgressButton";
import { PermissionCompleted } from "@components/integrations/connection-guide";

import { INTEGRATION_ACTION, COMMON_ACTION } from "@utils/gtm";

export const AppsFlyerConnectSetup = ({
  platform = {
    id: "appsflyer",
    name: "AppsFlyer",
    platformId: "appsflyer",
  },
  stepNbr = 0,
  stepData = {},
  goToNextStep = () => {},
  goToStep = () => {},
  closeModal = () => {},
  trackEvent = () => {},
}) => {
  const dispatch = useDispatch();
  const { isSetupDone = false } = stepData;

  const {
    clientAppsFlyerManualConnectionEnabled: manualEnabled = false,
    clientAppsFlyerApiConnectionEnabled: apiEnabled = true,
  } = useFlags();

  const { emailMap, allConnectionsMap, connectionStatusMap } = useSelector(manualConnectionSelector);
  const manualConnectionStatus = connectionStatusMap[platform.platformId];
  const { credentials, requestStatus } = useSelector((state) => state.integrations[platform.id]);

  const [apiToken, setApiToken] = useState(credentials.apiToken);
  const [appTokens, setAppTokens] = useState(credentials.appTokens);

  useEffect(() => {
    // Make 1 based like step function
    // TODO: Make more consistent across all guides
    const step = stepNbr + 1;
    if (!apiEnabled && manualEnabled && step <= 3) {
      goToStep(4); // Manual Connection step 1
    }
    // eslint-disable-next-line
  }, [apiEnabled, manualEnabled]);

  useEffect(() => {
    if (credentials.status === "valid") {
      goToStep(1, {
        closeAfter: true,
      }); // Go back to the first step
    }
    if (credentials.status === "no_permissions") {
      goToStep(3); // Show permissions info screen again
    }
    // eslint-disable-next-line
  }, [credentials.status]);

  useEffect(() => {
    // manual connections and emails are only needed if
    // the manual connection flow is enabled
    if (!manualEnabled) return;
    const connection = allConnectionsMap[platform.platformId];
    if (!connection) {
      dispatch(manualConnectionThunks.createConnection(platform.platformId));
    } else {
      dispatch(manualConnectionThunks.getEmail(platform.platformId));
    }
    // eslint-disable-next-line
  }, [allConnectionsMap]);

  useEffect(() => {
    // We can ignore manual connections if the manual flow is not enabled
    // and any subsequent step logic
    if (!manualEnabled) return;
    trackEvent(COMMON_ACTION.COMPLETE);
    if (manualConnectionStatus === "error") {
      showToast("An error has occurred with the connection. Please try again or contact us for assistance.", "error");
      if (apiEnabled) {
        // Show the permissions modals only if the API flow is enabled, as its technically part of that flow
        goToStep(3);
      } else {
        // Otherwise keep/put the user on the menual connection flow
        goToStep(4);
      }
    }
    if (manualConnectionStatus === "complete") {
      showToast("Your connection is now complete!");
      goToNextStep();
    }
    // eslint-disable-next-line
  }, [manualConnectionStatus]);

  const getError = (errorMsg) => {
    const { testCredentials, saveCredentials } = requestStatus;
    if (testCredentials.error || saveCredentials.error) {
      return errorMsg;
    }
    return "";
  };

  return (
    <StyledConnectionFlow
      className={classnames("fs-modal-content", {
        "done-step": isSetupDone,
        "align-left": isSetupDone,
      })}
    >
      {stepNbr === 2 && (
        <SaveProgressContainer>
          <SaveProgressButton aria-label="Close" type="button" onClick={closeModal}>
            <CrossIcon aria-hidden={true} />
          </SaveProgressButton>
        </SaveProgressContainer>
      )}

      <FadeStepsContainer
        step={stepNbr}
        stepComponents={[
          // API Step 1
          <AppsFlyerStep1
            key="AppsFlyerStep1"
            apiToken={apiToken}
            setApiToken={setApiToken}
            testApiToken={() => {
              dispatch(
                actions.integrations[platform.id].testCredentials.request(
                  {
                    apiToken: apiToken,
                    appTokens: appTokens,
                    considerAppTokens: false,
                  },
                  goToNextStep,
                ),
              );
            }}
            requestStatus={requestStatus}
            credentialsError={getError("Please enter a valid API ID")}
            trackEvent={trackEvent}
          />,

          // API Step 2
          <AppsFlyerStep2
            key="AppsFlyerStep2"
            connectPlatform={() => {
              const lastAppToken = appTokens[appTokens.length - 1];
              if (lastAppToken.length === 0) appTokens.pop();
              dispatch(
                actions.integrations[platform.id].saveCredentials.request({
                  apiToken: apiToken,
                  appTokens: appTokens,
                }),
              );
            }}
            requestStatus={requestStatus}
            appTokens={appTokens}
            credentialsError={getError("There are some App ID(s) that require your attention.")}
            setAppTokens={setAppTokens}
            trackEvent={trackEvent}
          />,

          // Permissions Warning
          <AppsFlyerStep3
            key="AppsFlyerStep3"
            clientAppsFlyerManualConnectionEnabled={manualEnabled}
            onContinue={() => {
              if (manualEnabled) {
                // Move on to manual connection if its enabled
                trackEvent(INTEGRATION_ACTION.CONTINUE);
                goToNextStep();
              } else {
                // Go back to step 1 and close the modal after
                // TODO: Setting to step 1 is a legacy issue from the fullscreenmodal
                // being shared between guides that should hopefully disappear soon
                goToStep(1, {
                  closeAfter: true,
                });
              }
              dispatch(actions.integrations[platform.id].clearCredentialsStatus());
            }}
            trackEvent={trackEvent}
          />,

          // Manual Connection Step 1
          <AppsFlyerStep4
            key="AppsFlyerStep4"
            email={emailMap[platform.platformId]}
            clientAppsFlyerApiConnectionEnabled={apiEnabled}
            onBack={() => {
              trackEvent(COMMON_ACTION.GO_BACK);
              goToStep(1);
            }}
            onClose={() => {
              trackEvent(COMMON_ACTION.CLOSE);
              closeModal();
            }}
            onComplete={() => {
              trackEvent(COMMON_ACTION.COMPLETE);
              dispatch(manualConnectionThunks.completeConnection(platform.platformId));
            }}
            trackEvent={trackEvent}
          />,

          // Complete
          <PermissionCompleted key="PermissionCompleted" handleDone={closeModal} />,
        ]}
      />
    </StyledConnectionFlow>
  );
};
