import { useSelector } from "react-redux";

import symbols from "@utils/symbols";
import { BANKING } from "@src/utils/consts";
import BankBalanceDetails from "@components/dashboard/company-finances/BankBalanceDetails";
import { checkForUKBankAccount } from "@components/dashboard/company-finances/companyFinancesUtils";
import { useNavigate, useLocation } from "react-router-dom";
import { DRAFT_CONNECTION, NEEDS_RECONNECTION, NOT_CONNECTED, sFirst, sIgnore } from "@src/utils/status";

export const BankingDetailsPage = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const integrations = useSelector((state) => state.integrations);
  // const integrationsStatus = integrations.base.requestStatus.getData;

  const bankingData = integrations.plaid.data.accounts;

  const handleGoBack = () => {
    if (location.state) {
      const { prevPath } = location.state;
      if (prevPath) {
        return navigate(prevPath, { replace: true });
      }
    }
    const oldPath = `/${location.pathname.split("/")[1]}`;
    navigate(oldPath, { replace: true });
  };

  const integration = {
    id: "plaid",
    name: "Plaid",
    icon: symbols.Bank(),
    hasDetails: true,
    title: `Bank Balance`,
    connectDescription: "Always know your up-to-date Bank Balance and how it's been trending over time.",
    categories: [BANKING],
    type: BANKING,
    data: bankingData,
  };

  // if containsUKBankAccount is true, should redirect to dashboard
  const containsUKBankAccount = checkForUKBankAccount(bankingData);

  if (containsUKBankAccount || !bankingData?.length) {
    handleGoBack();
  }

  const { id } = integration;
  const institutions = integrations[id].data.institutions;
  // let status = NOT_CONNECTED;

  if (institutions.length) {
    // Ignore any unconnected or draft institutions
    const connectedInstitutions = sIgnore(institutions, [NOT_CONNECTED, DRAFT_CONNECTION]);
    // Check for a connection error to show in dashboard
    let lastConnection = sFirst(connectedInstitutions, NEEDS_RECONNECTION);

    // If no connection error, look for the most recent institution updated
    if (!lastConnection) {
      lastConnection = connectedInstitutions.reduce(
        (institution, acc) => (institution.lastSync > acc.lastSync ? institution : acc),
        {},
      );
    }

    // status = lastConnection?.status || NOT_CONNECTED;
    integration.lastConnectionName = lastConnection.institution;
    integration.lastUpdated = lastConnection.lastSyncedAt;
    integration.handleDetails = handleGoBack;
  }

  // We want isLoading to initialize to true while the integration statuses are being fetched, after
  // they're fetched, we can rely on the pending status of the individual API calls
  // let isLoading = integrationsStatus.pending;
  // if (integrations[id].requestStatus.getData.pending) isLoading = true;
  // if (integrations[id].requestStatus.status.pending) isLoading = true;

  return (
    <BankBalanceDetails
      closeModal={handleGoBack}
      isOpen={true}
      bankingData={bankingData}
      handleDetails={handleGoBack}
      lastUpdated={integration.lastUpdated}
      integrations={integrations}
    />
  );
};
