import HTTP from "@redux/http";
const SANLO_WEB_API_URL = process.env.REACT_APP_SANLO_WEB_API_URL;
const BASE = `${SANLO_WEB_API_URL}/api/integrations/payments`;

class PaymentsService {
  getUpcomingPayments() {
    return HTTP.req.get(`${BASE}/upcoming`);
  }
}

export default new PaymentsService();
