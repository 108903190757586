import SanloWebApiService from "./services";

import offerActions, { ACCEPT_OFFER, DISMISS_CAPITAL_OFFER, GET_CAPITAL_OFFERS } from "./actions";

// prettier-ignore
const offersMiddleware = ({ dispatch }) => next => action => {
  next(action);

  switch (action.type) {
    case GET_CAPITAL_OFFERS.REQUEST:
      SanloWebApiService.getCapitalOffers()
        .then(response => response.data)
        .then(data => {
          dispatch(offerActions.getCapitalOffers.response(data))
        })
        .catch(error => {
          dispatch(offerActions.getCapitalOffers.error(error));
        })
      break;

      case ACCEPT_OFFER.REQUEST: {
        SanloWebApiService.acceptCapitalOffer(action.data)
          .then(() => {
            dispatch(offerActions.acceptCapitalOffer.response(action.data));
          }).then(() => {
            // We need to re-call the getCapitalOffers to update the state since BE doesn't
            // return a response when an offer is confirmed
            SanloWebApiService.getCapitalOffers()
            .then(response => response.data)
            .then(data => {
              dispatch(offerActions.getCapitalOffers.response(data))
            })
          })
          .catch((error) => {
            dispatch(offerActions.acceptCapitalOffer.error(error))
          });
        break;
      }

      case DISMISS_CAPITAL_OFFER.REQUEST: {
        SanloWebApiService.dismissCapitalOffer(action.data)
          .then(() => {
            dispatch(offerActions.dismissCapitalOffer.response(action.data));
          })
          .catch((error) => {
            dispatch(offerActions.dismissCapitalOffer.error(error))
          });
        break;
      }


    default:
      return;
  }
}

export default offersMiddleware;
