import { Auth0Provider } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createBrowserRouter, RouterProvider, redirect } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";

import actions from "@redux/actions";

import { AUTH0_API_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DOMAIN } from "./services/SanloWebApiService";

import { ThemeProvider } from "styled-components";
import { StyledToastContainer } from "./components/styled";
import { GlobalStyles } from "./styles/globalStyles";
import SanloTheme from "@styles/theme";

import Authentication from "./Authentication";
import { ResetPasswordFlow } from "./pages/authentication/flows/ResetPasswordFlow";
import ErrorPage from "./pages/error-page/ErrorPage";
import LogOut from "./LogOut";

import { getBrandingInfo } from "@utils/branding";
import { addQueryParams, getQueryParameters } from "./utils/general";

import { isFirefox } from "./utils";
import scopes from "./utils/session/scopes";

import ErrorBoundary from "@components/ErrorBoundary";
import { chakraTheme } from "./styles/chakra/theme";
import { useFlags } from "launchdarkly-react-client-sdk";
import DashboardPage from "@pages/dashboard-page/DashboardPage";
import { BankingDetailsPage } from "@pages/banking-details/BankingDetailsPage";
import GrowthCapitalPage from "@pages/growth-capital-page/GrowthCapitalPage";
import IntegrationsPage from "@pages/integrations/IntegrationsPage";
import { FinancialIntelligencePage } from "@pages/financial-intelligence/FinancialIntelligencePage";
import { OAUTH_FLOW_ARCHETYPE_ID } from "@src/consts/ad-networks";
import { useParamsForOAuthConnection } from "@utils/integrations/useParamsForOAuthConnection";

// Webshops (Catalog, Builder, etc)
import { ProjectsPage } from "@pages/webshops/projects";
import { CatalogPage } from "@pages/webshops/webshop/catalog/CatalogPage";
import { Builder } from "@pages/webshops/webshop/builder";

import DocumentsPage from "@pages/documents-page/DocumentsPage";
import { NewProject } from "@pages/webshops/new-project/NewProject";
import { APP_STORE } from "./consts/webshops/new-project";
import { Redirect } from "@components/redirect/Redirect";
import { Settings } from "@pages/webshops/webshop/settings";
import { WebshopLayout } from "@pages/webshops/webshop/webshop-layout/WebshopLayout";
import { CheckoutBuilder } from "@pages/webshops/webshop/checkout/builder";
import { CheckoutLayout } from "@pages/webshops/webshop/checkout/layout";
const WEB_API_URL = process.env.REACT_APP_SANLO_WEB_API_URL;
let AUTHO_STORAGE = "memory";
if (WEB_API_URL.match(/localhost/)) {
  // Configure the Auth0 storage for localstorage when running locally
  // to make use of the programmatic login for Cypress tests, which is faster
  // then the UI login for running larger test suites.
  AUTHO_STORAGE = "localstorage";
}

function App() {
  const dispatch = useDispatch();
  useParamsForOAuthConnection();
  const {
    clientDocumentsPageVisible = false,
    clientWebshopsPageEnabled = true,
    clientWebshopsAccessGranted = false,
    clientFinancialAnalysisPageEnabled = false,
    showCheckoutBuilder = false,
    showDashboardPage = false,
    showGrowthCapital = false,
    showIntegrationsPage = false,
  } = useFlags();
  const onRedirectCallback = (appState) => {
    window.location.replace(appState?.returnTo || window.location.pathname);
  };
  const onboarding = useSelector((state) => state.session.onboarding);
  const hasCompletedOnboarding = useSelector((state) => state.session.company.onboarding.isComplete);

  const { requiredPlatforms, data } = onboarding;

  const completedConnecting = requiredPlatforms.every((p) => data.platformsLinked[p]);
  const isOAuthFlowActive = localStorage.getItem(OAUTH_FLOW_ARCHETYPE_ID) || false;
  const pathname = window.location.pathname;

  const skipRedirect = (pathname === "/" || pathname === "/integrations") && isOAuthFlowActive;
  const showWebshops = clientWebshopsPageEnabled;
  const showCapital =
    clientDocumentsPageVisible ||
    showDashboardPage ||
    showGrowthCapital ||
    showIntegrationsPage ||
    clientFinancialAnalysisPageEnabled;

  useEffect(() => {
    if (
      !hasCompletedOnboarding &&
      completedConnecting &&
      !data.capitalRequestStage &&
      data.onboardingType === "PRIMARY"
    ) {
      dispatch(actions.session.requestCapitalOnboarding.request());
    }

    if (
      !hasCompletedOnboarding &&
      !completedConnecting &&
      !data.capitalRequestStage &&
      data.onboardingType === "PRIMARY"
    ) {
      dispatch(actions.session.setCanUpdateOnboardingProcess(true));
    }
  }, [hasCompletedOnboarding, dispatch, completedConnecting, data.onboardingType, data.capitalRequestStage]);

  const getRedirectUri = () => {
    // Read the partner branding data from the localstorage, its need to be present on
    // immediate load of the app since Auth0Provider redirectUri cannot be altered once set.
    // Also only pass the logo when a clientId is present, indicating a partner
    // branded experience. The clientId doesn't need to be sent, it will stored in localStorage
    // until it us used in the /finish-registration call.
    const storedBrandingData = JSON.parse(localStorage.getItem("partnerBranding") || "{}");
    const { name, logo, site } = storedBrandingData;

    const { sanlo_flow } = getQueryParameters();
    const { isBranded, fromPartnerSite, clientId } = getBrandingInfo();

    return addQueryParams(
      window.location.origin,
      {
        sanlo_flow: sanlo_flow,
        partnerName: isBranded && name ? name : null,
        partnerLogo: isBranded && logo ? logo : null,
        partnerSite: isBranded && site ? site : null,
        fromPartnerSite: fromPartnerSite,
        partnerAttributionClientId: clientId,
      },
      {
        encode: true,
      },
    );
  };

  const indexPage = showWebshops
    ? {
        index: true,
        loader: () => {
          return redirect("/webshops/projects");
        },
      }
    : {
        index: true,
        loader: () => {
          return redirect("/dashboard");
        },
      };
  const capitalRoutes = [
    {
      path: "dashboard",
      element: (
        <ErrorBoundary name="dashboard-page">
          <DashboardPage />
        </ErrorBoundary>
      ),
      children: [
        {
          path: "banking-details",
          element: (
            <ErrorBoundary name="banking-details-page">
              <BankingDetailsPage />
            </ErrorBoundary>
          ),
        },
      ],
    },
    {
      path: "growth-capital",
      loader: () => {
        if (!showGrowthCapital) return redirect("/dashboard");
        return null;
      },
      element: (
        <ErrorBoundary name="growth-capital-page">
          <GrowthCapitalPage />
        </ErrorBoundary>
      ),
    },
    {
      path: "integrations",
      loader: () => {
        if (!showIntegrationsPage) return redirect("/dashboard");
        return null;
      },
      element: (
        <ErrorBoundary name="integrations-page">
          <IntegrationsPage />
        </ErrorBoundary>
      ),
    },
    {
      path: "financial-analytics",
      loader: () => {
        if (!clientFinancialAnalysisPageEnabled) return redirect("/dashboard");
        return null;
      },
      children: [
        {
          index: true,
          element: (
            <ErrorBoundary name="financial-intelligence-page">
              <FinancialIntelligencePage />
            </ErrorBoundary>
          ),
        },
        {
          path: "banking-details",
          element: (
            <ErrorBoundary name="banking-details-page">
              <BankingDetailsPage />
            </ErrorBoundary>
          ),
        },
      ],
    },
    {
      path: "documents",
      loader: () => {
        if (!clientDocumentsPageVisible) redirect("/dashboard");
        return null;
      },
      element: (
        <ErrorBoundary name="documents-page">
          <DocumentsPage />
        </ErrorBoundary>
      ),
    },
  ];

  const webshopRoutes = [
    {
      path: "webshops",
      children: [
        {
          index: true,
          loader: async () => {
            return redirect("/webshops/projects");
          },
        },
        {
          path: "projects",
          children: [
            {
              index: true,
              element: (
                <ErrorBoundary name="webshops-projects-page">
                  <ProjectsPage />
                </ErrorBoundary>
              ),
            },
            {
              path: ":id",
              loader: () => {
                if (!clientWebshopsAccessGranted) return redirect("/webshops/projects");
                return null;
              },
              element: (
                <ErrorBoundary name="webshop-layout">
                  <WebshopLayout />
                </ErrorBoundary>
              ),
              children: [
                {
                  path: "catalog",
                  element: (
                    <ErrorBoundary name="catalog-page">
                      <CatalogPage />
                    </ErrorBoundary>
                  ),
                },
                {
                  path: "builder",
                  element: (
                    <ErrorBoundary name="builder-page">
                      <Builder />
                    </ErrorBoundary>
                  ),
                },
                {
                  path: "checkout",
                  loader: async () => {
                    if (!showCheckoutBuilder) return redirect("/webshops/projects");
                    return null;
                  },
                  element: <CheckoutLayout />,
                  children: [
                    {
                      index: true,
                      path: "builder",
                      element: (
                        <ErrorBoundary name="checkout-builder-page">
                          <CheckoutBuilder />
                        </ErrorBoundary>
                      ),
                    },
                  ],
                },
                {
                  path: "settings",
                  element: (
                    <ErrorBoundary name="settings-page">
                      <Settings />
                    </ErrorBoundary>
                  ),
                },
                {
                  path: "*",
                  element: <Redirect path="/webshops/projects" />,
                },
              ],
            },
            {
              path: "new",
              loader: async ({ request }) => {
                if (!clientWebshopsAccessGranted) return redirect("/webshops/projects");
                const url = new URL(request.url);
                const code = url.searchParams.get("code");
                if (code) {
                  // this is to keep the same logic for app_store just only that we don't care about returning the code
                  if (code === APP_STORE) return null;
                  return { code };
                } else {
                  return redirect("/webshops/projects");
                }
                /*
                  Uncomment this code when https://linear.app/sanlo/issue/FG-411/onboarding-selectors-causing-multiple-renders is resolved
                  and add the proper logic to the NewProject component
                */
                // const urlParams = new URLSearchParams(window.location.search);
                // const code = urlParams.get("code");
                // const newProjectData = JSON.parse(localStorage.getItem(NEW_PROJECT));
                // if (code) {
                //   console.log("code", code);
                //   if (Object.keys(newProjectData).length > 0) {
                //     const response = await dispatch(
                //       webshopsThunks.createWebshops({
                //         source: newProjectData?.selectedPlatform,
                //         appId: newProjectData?.appId,
                //         name: newProjectData?.name,
                //         auth: {
                //           code,
                //         },
                //       }),
                //     ).unwrap();

                //     if (response) {
                //       console.log(response);
                //       return { ...response };
                //     }
                //     return null;
                //   }
                // }
              },
              element: (
                <ErrorBoundary name="webshops-projects-page">
                  <NewProject />
                </ErrorBoundary>
              ),
            },
          ],
        },
      ],
    },
  ];

  function getRoutes() {
    const basicRoutes = [
      {
        path: "/partner",
        element: <Authentication themeSelected={SanloTheme} />,
      },
      {
        path: "/",
        element: <Authentication themeSelected={SanloTheme} />,
        children: [{ ...indexPage }],
      },
      {
        path: "/change-password",
        element: <ResetPasswordFlow />,
      },
      {
        path: "/error",
        element: <ErrorPage ctaLabel="Log In" description="Please try logging in again or reload the page." />,
      },
      {
        path: "/logout",
        element: <LogOut />,
      },
    ];

    const updateRootRoute = (routes, additionalRoutes) => {
      return routes.map((route) =>
        route.path === "/" ? { ...route, children: [...(route.children || []), ...additionalRoutes] } : route,
      );
    };

    if (showWebshops && showCapital) {
      return updateRootRoute(basicRoutes, [
        ...webshopRoutes,
        ...capitalRoutes,
        { path: "*", element: <Redirect path="/dashboard" /> },
      ]);
    } else if (showWebshops) {
      return updateRootRoute(basicRoutes, [
        ...webshopRoutes,
        { path: "*", element: <Redirect path="/webshops/projects" /> },
      ]);
    } else if (showCapital) {
      return updateRootRoute(basicRoutes, [...capitalRoutes, { path: "*", element: <Redirect path="/dashboard" /> }]);
    }

    return basicRoutes;
  }
  const routes = getRoutes();

  const router = createBrowserRouter(routes);

  return (
    <ChakraProvider
      resetCss={false}
      theme={chakraTheme}
      toastOptions={{
        defaultOptions: {
          position: "top-right",
        },
      }}
    >
      <ErrorBoundary name="app-container">
        <Auth0Provider
          domain={AUTH0_DOMAIN}
          clientId={AUTH0_CLIENT_ID}
          redirectUri={getRedirectUri()}
          onRedirectCallback={onRedirectCallback}
          cacheLocation={AUTHO_STORAGE}
          audience={AUTH0_API_AUDIENCE}
          scope={scopes}
          skipRedirectCallback={skipRedirect}
        >
          <ThemeProvider theme={SanloTheme}>
            <GlobalStyles />
            <StyledToastContainer isFirefox={isFirefox} />
            <RouterProvider router={router} />
          </ThemeProvider>
        </Auth0Provider>
      </ErrorBoundary>
    </ChakraProvider>
  );
}

export default App;
