// FI Early Access Request Statuses
export const FINANCIAL_INTELLIGENCE_REQUEST_STATUS = {
  ELIGIBLE: "ELIGIBLE",
  INELIGIBLE: "INELIGIBLE",
  PENDING: "PENDING",
  ACCEPTED: "ACCEPTED",
  REJECTED: "REJECTED",
};

export const FINANCIAL_INTELLIGENCE_BALANCE_TYPE = {
  INFLOW: "inflow",
  EXPENSE: "expense",
};

export const CASH_ACTIVITY_TIME_OPTIONS = {
  MONTHLY: {
    label: "Monthly",
    grouping: "monthly",
  },
  DAILY: {
    label: "Daily",
    grouping: "daily",
  },
};

export const FINANCIAL_INTELLIGENCE_CARD_ID = {
  UPCOMING_CASH_IN: "stat-card-upcoming-cash-in",
  UPCOMING_CASH_OUT: "stat-card-upcoming-cash-out",
  CURRENT_BANK_BALANCE: "stat-card-current-bank-balance",
  UPCOMING_CASH_INFLOW: "stat-card-upcoming-cash-inflows",
  AD_SPEND_RUN_RATE: "stat-card-ad-spend-run-rate",
  NET_BURN_LAST_MONTH: "stat-card-net-burn-last-month",
};

export const CASH_ACTIVITY_ZOOM_SCALE = [
  { id: "zoom-mod-one", label: "1x", percentZoom: 0 },
  { id: "zoom-mod-one-point-five", label: "1.5x", percentZoom: 1.5 },
  { id: "zoom-mod-two", label: "2x", percentZoom: 2 },
  { id: "zoom-mod-four", label: "4x", percentZoom: 4 },
];
