import React, { useEffect, useRef, useState } from "react";
import { StyledRocketProgressBar } from "../OnboardingExperienceStyles";
import { ReactComponent as RocketIcon } from "@assets/img/icons/common/rocket-icon.svg";
import classnames from "classnames";

const RocketProgressBar = ({ currentProgress, pillAmount, fromDashboard }) => {
  const [fakeProgress, setFakeProgress] = useState(85);
  const [isCompleted, setIsCompleted] = useState(currentProgress.combined >= 100);

  const rocketRef = useRef(null);
  const timer = useRef(null);
  const timer2 = useRef(null);

  const visibleProgress = isCompleted && !fromDashboard ? fakeProgress : currentProgress.combined;

  useEffect(() => {
    setIsCompleted(currentProgress.combined >= 100);
  }, [currentProgress]);

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
      clearTimeout(timer2.current);
    };
  }, []);

  useEffect(() => {
    if (isCompleted && fromDashboard && rocketRef.current) {
      rocketRef.current.style.display = "none";
    }
  }, [isCompleted, fromDashboard, rocketRef]);

  useEffect(() => {
    if (!rocketRef.current) return;

    if (isCompleted) {
      if (rocketRef.current) {
        timer.current = setTimeout(() => {
          setFakeProgress(100);
        }, 1000);
        timer2.current = setTimeout(() => {
          if (!rocketRef || !rocketRef.current) return;
          rocketRef.current.style.animationDelay = "1.2";
          rocketRef.current.style.animation = "toMoon .3s ease-out both";
        }, 3000);
      }
    } else {
      rocketRef.current.style.animation = "fadeOutIn 1.7s linear both";
      timer.current = setTimeout(() => {
        if (!rocketRef || !rocketRef.current) return;
        rocketRef.current.style.animation = "none";
      }, 1700);
    }
  }, [isCompleted, rocketRef]);

  return (
    <StyledRocketProgressBar>
      <RocketIcon
        ref={rocketRef}
        className={classnames("progress-bar-rocket", {
          "is-flying": visibleProgress < 100,
          "to-moon": visibleProgress >= 100,
        })}
        style={{
          left: `${visibleProgress}%`,
        }}
      />

      <div className="progress-bar-track">
        <div
          className="progress-bar-completed"
          style={{
            width: `${currentProgress.completed}%`,
          }}
        />
        {Boolean(currentProgress.pending) && (
          <div
            className="progress-bar-pending"
            style={{
              width: `${currentProgress.combined}%`,
            }}
          />
        )}
      </div>

      {/*Progress bar separators*/}
      {[...Array(pillAmount - 1)].map((e, i) => (
        <ProgressBarSectionSeparator
          key={`progress-pill-${i}`}
          amount={pillAmount}
          position={i}
          progress={currentProgress}
        />
      ))}
    </StyledRocketProgressBar>
  );
};

const ProgressBarSectionSeparator = ({ amount, position, progress }) => {
  const getPercentagePosition = () => {
    return (100 / amount) * (position + 1);
  };

  const isCompleted = progress.combined >= getPercentagePosition();

  return (
    <div
      className={classnames("progress-bar-section", {
        completed: isCompleted,
      })}
      style={{
        left: `${getPercentagePosition()}%`,
      }}
    />
  );
};

export default RocketProgressBar;
