import { selectAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(selectAnatomy.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  field: {
    bg: "dark.400",
    borderColor: "transparent",
    color: "textWhite.400",
  },
});

const variants = {
  default: definePartsStyle(() => ({
    field: {
      fontSize: "sm",
      _hover: { borderColor: "dark.100" },
      _focus: { boxShadow: "none", border: "2px solid", borderColor: "brandRed.400" },
    },
  })),
};

export const Select = defineMultiStyleConfig({
  baseStyle,
  variants,
  defaultProps: {
    variant: "default",
  },
});
