import React from "react";

import { AppStoreConnectSetup } from "@pages/integrations/connection-guides/app-store/AppStoreConnectSetup";
import { GooglePlayConnectSetup } from "@pages/integrations/connection-guides/google-play/GooglePlayConnectSetup";
import { AdjustConnectSetup } from "@pages/integrations/connection-guides/adjust/AdjustConnectSetup";
import { AppsFlyerConnectSetup } from "@pages/integrations/connection-guides/appsflyer/AppsFlyerConnectSetup";
import { FirebaseConnectSetup } from "@pages/integrations/connection-guides/firebase/FirebaseConnectSetup";
import { SingularConnectSetup } from "@pages/integrations/connection-guides/singular/SingularConnectSetup";

import { trackGTMEvent, COMMON_ACTION, manualIntegrationTag } from "@utils/gtm";
import { SteamConnectSetup } from "@pages/integrations/connection-guides/steam/SteamConnectSetup";

const ConnectionFlowContainer = ({ platform = {}, closeModal = () => {}, ...rest }) => {
  const trackEvent = (action) => {
    trackGTMEvent({
      event: `${manualIntegrationTag(platform.name, action)} - Click`,
    });
  };

  if (platform.id === "appstore") {
    return (
      <AppStoreConnectSetup
        platform={platform}
        closeModal={() => {
          closeModal();
        }}
        trackEvent={trackEvent}
        {...rest}
      />
    );
  }

  if (platform.id === "googleplay") {
    return (
      <GooglePlayConnectSetup
        platform={platform}
        closeModal={() => {
          closeModal();
        }}
        trackEvent={trackEvent}
        {...rest}
      />
    );
  }

  if (platform.id === "adjust") {
    return (
      <AdjustConnectSetup
        platform={platform}
        closeModal={() => {
          closeModal();
        }}
        trackEvent={trackEvent}
        {...rest}
      />
    );
  }

  if (platform.id === "appsflyer") {
    return (
      <AppsFlyerConnectSetup
        platform={platform}
        closeModal={() => {
          trackEvent(COMMON_ACTION.CLOSE);
          closeModal();
        }}
        trackEvent={trackEvent}
        {...rest}
      />
    );
  }

  if (platform.id === "firebase") {
    return (
      <FirebaseConnectSetup
        platform={platform}
        closeModal={() => {
          trackEvent(COMMON_ACTION.CLOSE);
          closeModal();
        }}
        trackEvent={trackEvent}
        {...rest}
      />
    );
  }

  if (platform.id === "singular") {
    return (
      <SingularConnectSetup
        platform={platform}
        closeModal={() => {
          trackEvent(COMMON_ACTION.CLOSE);
          closeModal();
        }}
        {...rest}
      />
    );
  }

  if (platform.id === "steam") {
    return (
      <SteamConnectSetup
        platform={platform}
        closeModal={() => {
          trackEvent(COMMON_ACTION.CLOSE);
          closeModal();
        }}
        trackEvent={trackEvent}
        {...rest}
      />
    );
  }

  return <div>No Connect Setup found for platform</div>;
};

export default ConnectionFlowContainer;
