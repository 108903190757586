import React from "react";
import classnames from "classnames";
import { yAxisInfo } from "../../gc-offers/data";

const colors = ["#555559", "#C5C5C5"];
// Helps to determine the highest bar height
const heightPercentage = 100;

const Bar = ({ bars, width }) => {
  return (
    <div
      style={{ height: `${heightPercentage}%`, display: "flex", justifyContent: "flex-end", flexDirection: "column" }}
    >
      {bars.map((bar, index) => {
        const highestStep = yAxisInfo.highest || 0;
        const barHeight = (bar.value / highestStep) * heightPercentage;
        return (
          <div
            className={classnames({ "rounded-top": index === 0, "rounded-bottom": index === bars.length - 1 })}
            style={{ backgroundColor: bar.color, width, height: `${barHeight}%` }}
            key={`bar-${index}`}
            width={width}
            height={barHeight}
          />
        );
      })}
    </div>
  );
};

export const StackedChart = ({ data, legends }) => {
  const barWidth = 18;
  const entries = data.map((d) => ({
    bars: legends
      .map((key, i) => ({
        value: d[key],
        color: colors[i],
      }))
      .filter((bar) => bar.value),
  }));

  return (
    <div className="chart-layout-bar">
      {entries.map((entry, index) => (
        <Bar key={`bar_${index}`} {...entry} width={barWidth} />
      ))}
    </div>
  );
};
