import React from "react";
import styled from "styled-components";

import { StyledCashFlowSources, StyledSectionHeader } from "../CashFlowBreakdown.styled";
import { Accordion } from "@common/accordions/Accordion";
import { Dropdown } from "./Dropdown";
import { CashFlowBreakDownSource } from "./CashFlowSources";
import { Tooltip } from "@chakra-ui/react";

import { ReactComponent as AlertCircle } from "@assets/img/icons/common/alertCircle.svg";
import { useTrackHover, FINANCIALS_ACTION, FINANCIALS_PATHNAME } from "@utils/gtm";

import { alphaTrim } from "@utils/general";

export const CashFlowSection = ({
  section = {},
  selectedView = {},
  highestAmount = 0,
  onViewSelect = () => {},
  barStyles = {},
}) => {
  const [addTracking, removeTracking] = useTrackHover();

  const { label = "", viewOptions = [], views = [], description } = section;
  const { groupings = [] } = selectedView;
  // Only show the dropdown if there are more than 2 views
  const showDropdown = Boolean(viewOptions.length >= 2);

  return (
    <StyledSection data-testid={`cashflow-breakdown-section-${alphaTrim(label)}`}>
      <Accordion
        key={`key-accordion-cashflow-breakdown-${label}`}
        isExpanded
        headerContent={
          <>
            <StyledSectionHeader>{`${label} (${groupings.length})`}</StyledSectionHeader>
            {description && (
              <Tooltip label={description} placement="right" ml={"10px"}>
                <AlertCircle
                  fill={"#C5C5C5"}
                  style={{
                    marginBottom: "3px",
                    opacity: 0.7,
                    transform: "rotate(180deg)",
                  }}
                  onMouseEnter={() =>
                    addTracking({ event: FINANCIALS_ACTION.HOVER_TOOLTIP, name: label, pathname: FINANCIALS_PATHNAME })
                  }
                  onMouseLeave={() => removeTracking()}
                />
              </Tooltip>
            )}
            {showDropdown && (
              <Dropdown
                section={section}
                selectedView={selectedView}
                viewOptions={viewOptions}
                views={views}
                onSelect={(selectedOption, selectedView) => {
                  onViewSelect(selectedView);
                }}
              />
            )}
          </>
        }
        trackData={{ name: label }}
      >
        <StyledCashFlowSources>
          {groupings.map((item, index) => {
            return (
              <CashFlowBreakDownSource
                key={`key-cashflow-source-${item.name || index}`}
                source={item}
                highestAmount={highestAmount}
                barStyles={barStyles}
              />
            );
          })}
        </StyledCashFlowSources>
      </Accordion>
    </StyledSection>
  );
};

export const StyledSection = styled.div`
  position: relative;
  padding-bottom: 8px;
  &:not(:last-child) {
    border-bottom: 1px solid #333338;
  }
`;
