import React, { useState } from "react";
import styled from "styled-components";

import { SanloTooltip } from "@components/common/tooltips/sanlo-tooltip/SanloTooltip";
import { trackGTMEvent, FINANCIALS_ACTION, FINANCIALS_PATHNAME } from "@utils/gtm";

import { ReactComponent as AccordionArrow } from "@assets/img/icons/common/accordion-arrow.svg";
import { Text } from "@chakra-ui/react";

const StyledAccordionText = styled.div`
  position: relative;
`;

const StyledHeader = styled.div`
  margin: 10px 0px;
  display: flex;
  align-items: center;
  color: ${({ labelColor }) => labelColor};
  cursor: pointer;
`;

const StyledIcon = styled(({ isExpanded, labelColor, ...rest }) => <AccordionArrow {...rest} />)`
  margin-left: 9px;
  transform: scale(1.25) rotate(180deg);
  transition: transform 0.2s ease;
  fill: ${({ labelColor }) => labelColor};
  ${({ isExpanded }) =>
    isExpanded &&
    `
     transform: scale(1.25) rotate(0);
   `};
`;

const StyledContent = styled.div`
  overflow: hidden;
  transition: max-height 0.25s linear;
  max-height: 0;
  ${({ isExpanded }) =>
    isExpanded &&
    `
    max-height: fit-content;
  `};
`;

export const SimpleAccordionTooltip = ({
  idTarget = "",
  content = "",
  accordionLabel = "Don’t see all your data?",
  accordionContent = "",
  labelColor = "#FF5C79",
  trackData,
  ...rest
}) => {
  const [expanded, setexpanded] = useState(false);

  const handleExpandContent = () => {
    const state = expanded ? "Close" : "Open";
    trackGTMEvent({
      event: FINANCIALS_ACTION.EXPAND_TOOLTIP_ACCORDION,
      tooltip: trackData?.name || "",
      state,
      name: trackData?.accordionLabel || accordionLabel,
      pathname: FINANCIALS_PATHNAME,
    });
    setexpanded(!expanded);
  };

  return (
    <SanloTooltip width={300} target={idTarget} placement={"right"} {...rest}>
      {content}
      {accordionContent && (
        <StyledAccordionText>
          <StyledHeader
            labelColor={labelColor}
            id={`learn-more-${idTarget}`}
            onClick={() => {
              handleExpandContent();
            }}
          >
            <Text size={"mini"} fontWeight={"medium"} color={labelColor || "brandRed.400"}>
              {accordionLabel}
            </Text>
            <StyledIcon labelColor={labelColor} isExpanded={expanded} />
          </StyledHeader>
          <StyledContent isExpanded={expanded}>
            <Text size="mini">{accordionContent}</Text>
          </StyledContent>
        </StyledAccordionText>
      )}
    </SanloTooltip>
  );
};
