import { tabsAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

// define custom sizes
const sizes = {
  md: definePartsStyle({
    // define the parts that will change for each size
    tab: {
      fontSize: "sm",
      lineHeight: "16px",
      py: "8px",
      px: "8px",
      border: "none",
    },
    tablist: {
      border: "none",
      borderBottom: "1px solid red",
    },
  }),
};

const baseStyle = definePartsStyle({});

const sanloWay = definePartsStyle({
  tab: {
    borderColor: "unset",
    borderBottom: "none",
    _selected: {
      color: "brandRed.400",
    },
    _active: {
      bg: "none",
    },
  },
  tablist: {
    borderBottom: "1px solid transparent",
    borderColor: "dark.300",
    px: "8px",
  },
});

const catalogPage = definePartsStyle({
  tab: {
    borderColor: "unset",
    borderBottom: "none",
    _selected: {
      borderTop: "2px solid transparent",
      borderColor: "brandPurple.400",
      color: "brandPurple.400",
      bg: "dark.900",
    },
    _active: {
      bg: "none",
    },
  },
  tabpanel: {
    bg: "dark.900",
    borderTopRightRadius: "8px",
    borderBottomRadius: "8px",
  },
});

export const Tabs = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants: {
    sanloWay,
    catalogPage,
  },
  defaultProps: {
    size: "md",
    variant: "sanloWay",
  },
});
