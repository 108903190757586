import styled from "styled-components";

const StyledAdvanceCard = styled.div`
  & {
    .earned-amount {
      display: flex;
      align-items: center;

      .earned-subtitle {
        margin: auto 0 0.35rem 0.35rem;
      }
    }

    .advance-info-container {
      margin-top: 8px;
      margin-bottom: 24px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .game-logo {
        border-radius: 4px;
        width: 24px;
        height: 24px;
      }

      .advance-info-label {
        margin-left: 8px;
        color: ${({ theme }) => theme.textGray};
      }
    }

    .advance-details-container {
      border-bottom: 1px solid ${({ theme }) => theme.borderDivider};

      :last-child {
        border-bottom: none;
      }

      .accordion-header {
        padding: 24px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        .header-title {
        }

        svg.dropdown-arrow {
          fill: ${({ theme }) => theme.textGray};
          transition: 0.3s ease;
          transform: rotate(180deg) scale(1.5);

          &.expanded {
            transform: scale(1.5);
          }
        }
      }

      .accordion-content {
        border-bottom: 1px solid ${({ theme }) => theme.borderDivider};

        :last-child {
          border-bottom: none;
        }

        .accordion-row {
          margin: 16px 0;
          display: flex;
          justify-content: space-between;
          align-items: center;

          &.partial {
            color: ${({ theme }) => theme.textGray};
          }

          &.repayment {
            margin-top: 16px;
            padding-top: 16px;
            border-top: 1px solid ${({ theme }) => theme.borderDivider};
          }

          .row-label {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
`;

export const StyledCardInfoTooltip = styled.div`
  & {
    position: relative;
    display: flex;

    .card-tooltip-icon:hover + .card-tooltip {
      display: block;
    }

    svg.card-tooltip-icon {
      width: 16px;
      height: 16px;
      margin-left: 4px;
      cursor: pointer;
      fill: ${({ theme }) => theme.textGray};
    }

    .card-tooltip {
      display: none;
      position: absolute;
      top: -50px;
      left: 30px;
      width: 324px;
      background-color: ${({ theme }) => theme.grayscale40};
      color: ${({ theme }) => theme.textEmphasis};
      padding: 12px;
      box-shadow: 0 0 48px ${({ theme }) => theme.shadowColor};
      border-radius: 12px;

      .tooltip-header {
        padding-bottom: 12px;
        border-bottom: 1px solid ${({ theme }) => theme.grayscale50};
        font-family: "Inter", "Helvetica", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.01em;
      }

      .tooltip-content {
        margin-top: 10px;
      }
    }
  }
`;

export const StyledDynamicAdvance = styled.div`
  & {
    width: 100%;
    padding-bottom: 24px;
    border-bottom: 1px solid ${({ theme }) => theme.searchBorder};

    .bar-wrapper {
      width: 100%;
      display: flex;
      align-items: flex-start;
    }

    .progress-bar-label {
      display: block;
      margin-top: 8px;
      white-space: nowrap;

      &.repaid {
        color: ${({ theme }) => theme.repaidBackgroundColor};
      }

      &.upcoming {
        color: ${({ theme }) => theme.upcomingBackgroundColor};
      }
    }
  }
`;

export const StyledProgressTooltip = styled.div`
  & {
    margin-bottom: ${({ scroll }) => (scroll ? "0px" : "12px")};
    padding-bottom: 12px;
    border-bottom: 1px solid ${({ theme }) => theme.grayscale50};

    .tooltip-header {
      display: flex;
      align-items: center;
      margin-bottom: 12px;

      .tooltip-header-img {
        width: 36px;
        height: 36px;
        border-radius: 4px;
      }

      .tooltip-header-title {
        margin-left: 10px;
        white-space: nowrap;
      }
    }

    .tooltip-content {
      .tooltip-content-row {
        margin-bottom: 12px;

        .tooltip-row-main-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 4px;
        }

        .row-label {
          display: flex;
          align-items: center;
          white-space: nowrap;

          .strong-label {
            color: ${({ theme }) => theme.textSeashellWhite};
          }

          .row-icon {
            cursor: pointer;
            margin-left: 8px;
            fill: ${({ theme }) => theme.textSeashellWhite};
            transform: rotate(180deg);
            transition: transform 0.4s ease;

            &.hint-open {
              transform: rotate(0deg);
            }
          }
        }

        &:last-child {
          margin-bottom: 0;
        }

        .row-display {
          color: ${({ theme }) => theme.textGray};
          white-space: nowrap;
          margin-left: 3rem;

          &.display-color {
            .highlighted-amount {
              color: ${({ theme, state }) => theme[`${state}PaymentFontColor`]};
            }
          }
        }

        .tooltip-link {
          text-decoration: underline;
          color: ${({ theme }) => theme.textGray};
        }
      }

      .row-hint {
        transition: all 1s ease;
        color: ${({ theme }) => theme.textSubtext};
      }

      .fixed-term-row {
        display: flex;
      }
    }

    .divider {
      border-bottom: 1px solid rgba(51, 51, 51, 1);
      margin: 1rem 0;
      &:last-child {
        display: none;
      }
    }
  }

  ${({ scroll }) =>
    scroll &&
    `
      position: relative;
      height: 380px;
  `};
`;

export const StyledProgressBarContent = styled.div`
  & {
    width: ${({ width }) => width};
    min-width: 0.5rem;

    &.is-fixed-advance {
      .progress-bar-content {
        opacity: ${({ isNext }) => (isNext ? 1 : 0.3)};
      }
    }

    &:first-child {
      .progress-bar-content {
        margin-left: 0;
        border-radius: 10px 0 0 10px;
      }
    }

    &:last-child {
      .progress-bar-content {
        margin-right: 0;
        border-radius: 0 10px 10px 0;
      }
    }

    &:only-child {
      .progress-bar-content {
        margin-right: 0;
        border-radius: 10px;
      }
    }

    .progress-bar-content {
      margin: 0 1px 0 1px;
      background-color: ${({ theme, state }) => theme[state + "BackgroundColor"]};
      height: 16px;
      transition: opacity 0.3s ease;

      &:hover {
        opacity: 0.5;
      }
    }

    .progress-bar-label {
      display: inline-block;
      margin-top: 8px;
      white-space: nowrap;
      color: ${({ theme, state }) => theme[state + "BackgroundColor"]};
    }
  }
`;

export default StyledAdvanceCard;
