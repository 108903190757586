import React, { Fragment } from "react";
import classnames from "classnames";

const WaitingCard = ({ header, body, footer, showBorder }) => (
  <Fragment>
    <div className={classnames("gc-container", { "gradient-border": showBorder })}>
      {header}
      {body}
      {footer}
    </div>
  </Fragment>
);

export default WaitingCard;
