import HTTP from "@redux/http";

import { getBrandingInfo, attributePayload } from "@utils/branding";

const SANLO_WEB_API_URL = process.env.REACT_APP_SANLO_WEB_API_URL;

export default {
  finishRegistration: (updatedUser) => {
    return HTTP.req.post(
      `${SANLO_WEB_API_URL}/api/users/finish-registration`,
      attributePayload(updatedUser, {
        customer: true,
      }),
    );
  },

  userSummary: () => {
    return HTTP.req.get(`${SANLO_WEB_API_URL}/api/users/summary`);
  },

  featureFlags: () => {
    return HTTP.req.get(`${SANLO_WEB_API_URL}/api/environment/features`);
  },

  getOnboardingProgress: () => {
    return HTTP.req.get(`${SANLO_WEB_API_URL}/api/companies/onboarding`);
  },

  saveOnboardingProgress: (onboarding) => {
    return HTTP.req.put(`${SANLO_WEB_API_URL}/api/companies/onboarding`, onboarding);
  },

  finishOnboarding: (onboarding) => {
    return HTTP.req.post(`${SANLO_WEB_API_URL}/api/companies/onboarding/finish`, onboarding);
  },

  dismissOnboardingCard: () => {
    return HTTP.req.post(`${SANLO_WEB_API_URL}/api/companies/onboarding/status-card/dismiss`);
  },

  requestCapitalFromOnboarding: () => {
    return HTTP.req.post(`${SANLO_WEB_API_URL}/api/companies/onboarding/request-capital`, attributePayload());
  },

  partnerBranding: () => {
    const { clientId } = getBrandingInfo();
    return HTTP.req.get(`${SANLO_WEB_API_URL}/api/partner/${clientId}/information`);
  },

  sendFeedback: (feedback) => {
    return HTTP.req.post(`${SANLO_WEB_API_URL}/api/integrations/feedbacks`, {
      ...feedback,
      feedbackType: "FEEDBACK",
    });
  },

  setOnboardingSuperpowers: (superpowers) => {
    return HTTP.req.post(`${SANLO_WEB_API_URL}/api/onboarding/v2/superpowers`, superpowers);
  },
};
