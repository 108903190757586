import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getResponseStatus, getRequestStatus, getErrorStatus, setStatusDefaults } from "@utils/redux";

import SeriesService from "./seriesApi";
import moment from "moment";

export const seriesThunks = {
  createSeries: createAsyncThunk("series/createSeries", async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const {
        webshops: {
          webshops: { selectedWebshop },
        },
      } = getState();
      const res = await SeriesService.createSeries(payload, selectedWebshop.id);
      return res.data;
    } catch (e) {
      return rejectWithValue({ error: e.response.data });
    }
  }),

  updateSeries: createAsyncThunk("series/updateSeries", async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const {
        webshops: {
          webshops: { selectedWebshop },
        },
      } = getState();
      const res = await SeriesService.updateSeries(payload, selectedWebshop.id);
      return res.data;
    } catch (e) {
      return rejectWithValue({ error: e.response.data });
    }
  }),

  deleteSeries: createAsyncThunk("series/deleteSeries", async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const {
        webshops: {
          webshops: { selectedWebshop },
        },
      } = getState();
      // The service returns a 200 only
      await SeriesService.deleteSeries(payload, selectedWebshop.id);
      // We want to return the deleted series so we can update the catalog
      return payload;
    } catch (e) {
      return rejectWithValue({ error: e.response.data });
    }
  }),
};

const SERIES_DEFAULT_VALUES = {
  available: true,
  itemIds: [],
  name: "",
  endingStrategy: "LOOP",
  endTime: null,
  startTime: new Date(moment().minutes(0).toDate()),
  recurrenceInterval: null,
  isFormDisabled: false,
};

export const seriesSlice = createSlice({
  name: "series",
  initialState: {
    data: [],
    defaultSeries: { ...SERIES_DEFAULT_VALUES },
    selectedSeries: null,
    createSeries: {
      data: null,
      requestStatus: setStatusDefaults(),
    },
    updateSeries: {
      requestStatus: setStatusDefaults(),
    },
    deleteSeries: {
      requestStatus: setStatusDefaults(),
    },
  },
  reducers: {
    setSeries: (state, action) => {
      const { payload = [] } = action;
      state.data = payload;
    },
    setSelectedSeries: (state, action) => {
      const { payload = {} } = action;
      state.selectedSeries = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      /*******************************************************************************
       * Create Series
       *******************************************************************************/
      .addCase(seriesThunks.createSeries.pending, (state) => {
        state.createSeries.requestStatus = getRequestStatus();
      })
      .addCase(seriesThunks.createSeries.fulfilled, (state, action) => {
        const { payload = {} } = action;
        state.data = [payload, ...state.data];
        state.createSeries.requestStatus = getResponseStatus();
      })
      .addCase(seriesThunks.createSeries.rejected, (state, action) => {
        state.createSeries.requestStatus = getErrorStatus();
      })

      /*******************************************************************************
       * Update Series
       *******************************************************************************/
      .addCase(seriesThunks.updateSeries.pending, (state) => {
        state.updateSeries.requestStatus = getRequestStatus();
      })
      .addCase(seriesThunks.updateSeries.fulfilled, (state, action) => {
        const { payload = {} } = action;
        const updatedItems = state.data.map((series) => (series.id === payload?.id ? payload : series));

        state.data = updatedItems;
        state.updateSeries.requestStatus = getResponseStatus();
      })
      .addCase(seriesThunks.updateSeries.rejected, (state) => {
        state.updateSeries.requestStatus = getErrorStatus();
      })

      /*******************************************************************************
       * Delete Series
       *******************************************************************************/
      .addCase(seriesThunks.deleteSeries.pending, (state) => {
        state.deleteSeries.requestStatus = getRequestStatus();
      })
      .addCase(seriesThunks.deleteSeries.fulfilled, (state, action) => {
        const { payload = {} } = action;
        const series = [...state.data];
        const removedItemIndex = series.findIndex((series) => series.id === payload);
        if (removedItemIndex > -1) {
          series.splice(removedItemIndex, 1);
        }
        state.data = series;
        state.deleteSeries.requestStatus = getResponseStatus();
      })
      .addCase(seriesThunks.deleteSeries.rejected, (state) => {
        state.deleteSeries.requestStatus = getErrorStatus();
      });
  },
});

export const seriesSelector = (state) => state.webshops.series;

export const { setSelectedSeries, setSeries } = seriesSlice.actions;
export default seriesSlice.reducer;
