import React, { useEffect, useState } from "react";
import classnames from "classnames";
import styled, { css } from "styled-components";
import { BodySmallPlusCSS } from "@styled";
import { SanloTooltip } from "@components/common/tooltips/sanlo-tooltip/SanloTooltip";
import { SanloButton } from "@inputs";

import { generateUUID } from "@utils/general";
import symbols from "@utils/symbols";
import text from "@utils/text";

export const OfferButtonCSS = css`
  text-align: center;
  height: 40px;
  border: none;
  border-radius: 10px;
  padding: 12px 16px;
  color: ${({ theme }) => theme.grayscale0};
  background: ${({ theme }) => theme.gradPurpleRed};
  width: 160px;
  &.disabled {
    pointer-events: none;
    opacity: 0.4;
  }
  &.large {
    width: 180px;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const StyledOfferButton = styled(SanloButton)`
  ${BodySmallPlusCSS}
  ${OfferButtonCSS}
`;

const GetCapital = ({
  id = "",
  className = "",
  hasExpiredOffer = false,
  requestedCapitalActive = false,
  allowedToCreateRequest = false,
  handleClick = () => {},
  isReapply = false,
  isNewCapital = false,
  isFromDashboard = false,
  style = {},
}) => {
  id = id || `id-${generateUUID()}`;

  const [tooltipMessage, setTooltipMessage] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(true);

  useEffect(() => {
    let tooltipMessage = "";
    if (!isNewCapital) {
      // Currently Get Capital have no tooltips
      tooltipMessage = "";
    } else if (requestedCapitalActive) {
      tooltipMessage = text.t("already_requested");
    } else if (!allowedToCreateRequest) {
      tooltipMessage = text.t("not_allowed_to_create_request");
    }
    setTooltipMessage(tooltipMessage);
  }, [requestedCapitalActive, allowedToCreateRequest, isNewCapital]);

  useEffect(() => {
    if (isReapply || isFromDashboard) {
      // isFromDashboard is always enabled to let the user view details
      // isReapply is always enabled so the user can reapply (not a new request)
      setButtonDisabled(false);
    } else {
      setButtonDisabled(hasExpiredOffer || !allowedToCreateRequest);
    }
  }, [allowedToCreateRequest, isReapply, isFromDashboard, hasExpiredOffer]);

  const getLabel = () => {
    if (isReapply) {
      return text.t("reapply_for_capital");
    }
    if (isFromDashboard) {
      return text.t("view_details");
    }
    return `Get capital ${symbols.rocket}`;
  };

  return (
    <div id={`tt-${id}`}>
      <StyledOfferButton
        id={id}
        disabled={buttonDisabled}
        className={classnames(className, {
          disabled: buttonDisabled,
          large: isReapply, // Re-apply text is longer
        })}
        onClick={handleClick}
        label={getLabel()}
        style={style}
      />

      {tooltipMessage && (
        <SanloTooltip
          target={`tt-${id}`}
          text={tooltipMessage}
          placement={requestedCapitalActive ? "bottom-end" : "top"}
          width={requestedCapitalActive ? 244 : 216}
        />
      )}
    </div>
  );
};

export default GetCapital;
