import HTTP from "@redux/http";

const SANLO_WEB_API_URL = process.env.REACT_APP_SANLO_WEB_API_URL;
const BASE = `${SANLO_WEB_API_URL}/api/fairground/console/webshops`;

class LinksService {
  createLink(link, webshopId, sectionId) {
    return HTTP.req.post(`${BASE}/${webshopId}/storefront/sections/${sectionId}/link/v2`, link);
  }

  updateLink(link, webshopId, sectionId) {
    return HTTP.req.put(`${BASE}/${webshopId}/storefront/sections/${sectionId}/link/v2/${link.id}`, link);
  }

  deleteLink(link, webshopId, sectionId) {
    return HTTP.req.delete(`${BASE}/${webshopId}/storefront/sections/${sectionId}/link/v2/${link.id}`);
  }
}

export default new LinksService();
