import React, { useRef } from "react";
import classnames from "classnames";

import { BodyRegularPlus, BodySmall14, BodySmallPlus } from "@styled";
import StyledPlatformAccordion from "./StyledPlatformAccordion";

import SuggestIntegrationContainer from "@common/integrations/SuggestIntegrationContainer";
import { SanloTooltip } from "@common/tooltips/sanlo-tooltip/SanloTooltip";
import { syncingTime } from "../../integrations/integration-state/IntegrationStates";

import generalCopies from "@utils/text/general";
import { DRAFT_CONNECTION, NOT_CONNECTED, VERIFYING_CONNECTION, CONNECTED } from "@utils/status";

import { ReactComponent as AccordionArrow } from "@assets/img/icons/common/accordion-arrow.svg";
import { ReactComponent as FlagIconOutlined } from "@assets/img/icons/common/flag-outline.svg";
import { ReactComponent as FlagIconFilled } from "@assets/img/icons/common/flag-filled.svg";

import ErrorBoundary from "@components/ErrorBoundary";
import { useFlags } from "launchdarkly-react-client-sdk";

const tooltipCopies = (title, platform) => ({
  required: "This connection is required so you can unlock Growth Capital",
  optional:
    "This connection is optional but it unlocks Dashboard so you can track revenue from all connected platforms.",
  connected: `${title} is successfully connected.`,
  reconnect: "We're not able to establish the connection. Please, re-enter credentials in order to reconnect.",
  brokenConnection: "We were unable to connect to the platform. We'll try to reconnect in next 24 hours.",
  inProgress: `It may take up to ${syncingTime[platform]} hours to validate credentials and establish the connection.`,
  noPermissions: generalCopies.phrase.needs_attention_clarification,
});

const PlatformAccordion = ({
  platformStatus = {},
  title = "",
  expanded = false,
  onAccordionToggle = () => {},
  content,
  suggestionType = "",
  fromDashboard = false,
  onSelectPlatform = () => {},
  category = "",
}) => {
  const { clientSuperpowersOnboardingFlowEnabled = false } = useFlags();
  const accordionRef = useRef(null);
  const contentRef = useRef(null);
  const platformDataRef = useRef(null);

  const { id: statusId, status, label, platformId, requestStatus } = platformStatus;

  const inProgressOrConnected = status === VERIFYING_CONNECTION || status === CONNECTED;
  const showStatus =
    !clientSuperpowersOnboardingFlowEnabled ||
    fromDashboard ||
    (!fromDashboard && clientSuperpowersOnboardingFlowEnabled && inProgressOrConnected);

  const isLoading = requestStatus?.pending || requestStatus === "loading";

  return (
    <ErrorBoundary name={`eb-platform-accordion-${platformId}`}>
      <StyledPlatformAccordion
        status={statusId}
        onboardingV2={clientSuperpowersOnboardingFlowEnabled && !fromDashboard}
      >
        <div
          ref={accordionRef}
          className={classnames("gradient-border", {
            "gray-border": fromDashboard,
            "is-expanded": expanded,
          })}
          id={`platform-${category}-section-expanded`}
        >
          <div className="accordion-wrapper">
            <div
              className={classnames("accordion-header", {
                "is-collapsed": !expanded,
              })}
              id={`platform-${category}-section`}
              onClick={(e) => {
                onAccordionToggle(accordionRef.current.classList.contains("is-expanded"));
              }}
            >
              <div className="header-left">
                {[NOT_CONNECTED, DRAFT_CONNECTION].includes(status) ? (
                  <FlagIconOutlined className="accordion-flag" />
                ) : (
                  <FlagIconFilled className={`accordion-flag`} />
                )}
                <BodyRegularPlus className="accordion-title">{title}</BodyRegularPlus>
              </div>
              <div className="header-right" id={`accordion-header-right-${platformId}`}>
                {showStatus && (
                  <>
                    <div
                      id={`accordion-platform-type-${statusId}-${platformId}`}
                      className={classnames("accordion-platform-type", {
                        "loading-shine": isLoading,
                        "loading-placeholder": isLoading,
                      })}
                    >
                      <BodySmallPlus>{!isLoading && label}</BodySmallPlus>
                    </div>
                    <SanloTooltip
                      maxWidth={200}
                      target={`accordion-platform-type-${statusId}-${platformId}`}
                      text={
                        <BodySmall14 style={{ color: "#C5C5C5" }}>
                          {tooltipCopies(title, platformId)[statusId]}
                        </BodySmall14>
                      }
                    />
                  </>
                )}

                <AccordionArrow
                  className={classnames("accordion-icon", {
                    "is-expanded": expanded,
                  })}
                />
              </div>
            </div>

            <div ref={contentRef} className="accordion-content" style={{ maxHeight: expanded ? "fit-content" : 0 }}>
              <div ref={platformDataRef} className="accordion-content-padding">
                {content &&
                  React.cloneElement(content, {
                    onSelectPlatform: onSelectPlatform,
                    status: status,
                    label,
                  })}
              </div>

              <SuggestIntegrationContainer suggestionType={suggestionType} />
            </div>
          </div>
        </div>
      </StyledPlatformAccordion>
    </ErrorBoundary>
  );
};

export default PlatformAccordion;
