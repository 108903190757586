import styled from "styled-components";

const StyledSetupGuide = styled.div`
  position: relative;
  height: 100%;

  .step-wrapper {
    width: 540px;
    border-radius: 10px;
    background-color: ${({ theme }) => theme.integrationsSetupBackgroundColor};
    position: relative;
    height: fit-content;
    overflow: scroll;
    max-height: 100%;

    ::-webkit-scrollbar-corner {
      background: rgba(0, 0, 0, 0);
    }
  }

  .shadow {
    box-shadow: none !important;
    border-radius: 10px;
    position: absolute;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 36px;
    display: block;
    pointer-events: none;
    opacity: 0;
  }

  .shadow--top {
    top: 0;
    background: linear-gradient(180deg, #1a1a1f 4.86%, rgba(26, 26, 31, 0) 100%);
  }

  .shadow--bottom {
    bottom: 0;
    background: linear-gradient(180deg, rgba(26, 26, 31, 0) 4.86%, #1a1a1f 100%);
  }

  .step-container {
    height: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;

    &.active-step {
      border-left: 2px solid ${({ theme }) => theme.integrationActiveBorderColor};
      background-color: ${({ theme }) => theme.integrationActiveStepBackground};
    }

    .guide-callout {
      background-color: rgba(130, 128, 255, 0.16);
      padding: 0.75rem;
      border-radius: 0.75rem;
      margin-bottom: 0.75rem;
      color: ${({ theme }) => theme.colorElectricBlue};
      line-height: 1rem;

      > span:first-child {
        margin-bottom: 1rem;
        display: block;
      }
      > span:last-child {
        margin-bottom: 0 !important;
      }
    }

    .guide-list {
      list-style: disc;
      margin-left: 24px;

      div {
        margin: 0.25rem;
        font-size: 12px;
        opacity: 0.7;
      }
    }

    .extra-steps-container {
      padding-top: 16px;
    }

    .extra-step {
      padding: 12px 0px 16px 0px;
    }

    .step-description {
      margin-top: 8px;
      color: ${({ theme }) => theme.accordionDescriptionFontColor};
      display: block;
    }

    .step-image {
      margin-top: 16px;

      img,
      canvas,
      video {
        border-radius: 10px;
      }
    }

    .text-bold {
      font-weight: bold;
    }

    .text-blue {
      color: ${({ theme }) => theme.colorElectricBlue} !important;
    }

    .text-underline {
      text-decoration: underline !important;
    }
  }
`;

export const StyledGuideList = styled.ul`
  list-style: disc;
  margin-left: 24px;

  font-weight: ${(props) => props.fontWeight || "inherit"};

  div {
    margin: 0.25rem;
    font-size: 12px;
    opacity: 0.7;
  }
`;

export const StyledScrollableList = styled.div`
  margin: ${({ margin }) => margin};
  border-radius: ${({ borderRadius }) => borderRadius};

  &.scroll-wrap-content {
    max-height: ${({ maxHeightScroll }) => maxHeightScroll};
    overflow-y: auto;
    margin-bottom: 15px;

    &::-webkit-scrollbar {
      width: 20px;
    }

    &::-webkit-scrollbar-thumb {
      padding: 0 6px;
      border-right: 6px solid transparent;
      border-left: 6px solid transparent;
      background-color: rgba(51, 51, 56, 1);
      background-clip: padding-box;
      border-radius: 20px;
    }
  }
`;

export default StyledSetupGuide;
