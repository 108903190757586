import React from "react";
import StyledDashboardCard from "./StyledDashboardCard";
import { BodySmall14, TitleRegularPlus } from "../../../styled";

const DashboardCard = ({ header, children, noHeader, id }) => {
  return (
    <StyledDashboardCard id={id}>
      {!noHeader && <DashboardCardHeader title={header.title} subtitle={header.subtitle} logo={header.logo} />}
      <div className="dashboard-card-content">{children}</div>
    </StyledDashboardCard>
  );
};

const DashboardCardHeader = ({ title, subtitle, logo }) => (
  <div className="dashboard-card-header">
    {logo && <img src={logo} alt="logo" className="dashboard-card-logo" />}
    <div className="dashboard-card-heading">
      <TitleRegularPlus className="dashboard-card-title">{title}</TitleRegularPlus>
      {subtitle && <BodySmall14 className="dashboard-card-subtitle">{subtitle}</BodySmall14>}
    </div>
  </div>
);

export default DashboardCard;
