import { ACCEPT_OFFER, DISMISS_CAPITAL_OFFER, GET_CAPITAL_OFFERS } from "./actions";

import { getRequestStatus, getResponseStatus, getErrorStatus, setStatusDefaults, stateSet } from "@utils/redux";

import { hasExpiredOffer } from "./utils";

const initialState = {
  capitalOffers: [],
  requestStatus: setStatusDefaults(),
  data: {
    hasExpiredOffer: false,
    isAccepting: false,
    error: false,
    isDismissing: false,
  },
};

const capitalOffersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CAPITAL_OFFERS.REQUEST: {
      return stateSet(state, {
        requestStatus: getRequestStatus(),
      });
    }

    case GET_CAPITAL_OFFERS.RESPONSE: {
      return stateSet(state, {
        capitalOffers: action.response,
        data: {
          hasExpiredOffer: hasExpiredOffer(action.response),
        },
        requestStatus: getResponseStatus(),
      });
    }

    case GET_CAPITAL_OFFERS.ERROR: {
      return stateSet(state, {
        requestStatus: getErrorStatus(),
      });
    }

    case ACCEPT_OFFER.REQUEST: {
      return stateSet(state, {
        data: {
          isAccepting: true,
          error: false,
        },
      });
    }

    case ACCEPT_OFFER.RESPONSE: {
      return stateSet(state, {
        data: {
          isAccepting: false,
          ...action.response,
          error: false,
        },
      });
    }

    case ACCEPT_OFFER.ERROR: {
      return stateSet(state, {
        data: {
          isAccepting: false,
          error: true,
        },
      });
    }

    case DISMISS_CAPITAL_OFFER.REQUEST: {
      return stateSet(state, {
        data: {
          isDismissing: true,
          error: false,
        },
      });
    }

    case DISMISS_CAPITAL_OFFER.RESPONSE: {
      const { capitalOffers } = state;
      const filtered = capitalOffers.filter((offer) => offer.id !== action.response);
      const newState = { ...state, capitalOffers: filtered };

      return stateSet(newState, {
        data: {
          isDismissing: false,
          hasExpiredOffer: hasExpiredOffer(filtered),
          ...action.response,
          error: false,
        },
      });
    }

    case DISMISS_CAPITAL_OFFER.ERROR: {
      return stateSet(state, {
        data: {
          isDismissing: false,
          error: true,
        },
      });
    }

    default:
      return state;
  }
};

export default capitalOffersReducer;
