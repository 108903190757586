import { Box, HStack, Icon, Text } from "@chakra-ui/react";
import { ReactComponent as AlertTriangle } from "@assets/img/icons/common/alertTriangle.svg";
import React from "react";
const BANNER_TYPES = {
  error: {
    color: "brandRed.400",
    bg: "brandRedAlpha.400",
    icon: AlertTriangle,
  },
};

/**
 * @param {Object} props
 * @param {React.ReactNode || React.ReactNode[]} props.children
 * @param {string} props.text
 * @param {keyof typeof BANNER_TYPES} [props.type="error"]
 */

export function Banner({ type = "error", text, children }) {
  const isSingleElement = React.isValidElement(children);
  const isArrayOfElements = Array.isArray(children) && children.every(React.isValidElement);

  return (
    <HStack bg={BANNER_TYPES[type].bg} p="12px" borderRadius={"10px"} gap={"8px"} alignItems={"center"}>
      <Icon as={BANNER_TYPES[type].icon} color={BANNER_TYPES[type].color} w="16px" h="16px" />
      <Box>
        {isSingleElement && React.cloneElement(children, { color: BANNER_TYPES[type].color })}
        {isArrayOfElements &&
          React.Children.map(children, (child) => React.cloneElement(child, { color: BANNER_TYPES[type].color }))}
        {!isSingleElement && !isArrayOfElements && (
          <Text color={BANNER_TYPES[type].color} size="small" fontWeight={500} lineHeight="20px">
            {text || children}
          </Text>
        )}
      </Box>
    </HStack>
  );
}
