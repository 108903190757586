import { useState } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionPanel,
  Checkbox,
  HStack,
  Heading,
  Icon,
  Image,
  Text,
  VStack,
  AccordionItem,
  AccordionIcon,
  Flex,
  Tooltip,
} from "@chakra-ui/react";
import { ReactComponent as ElementsIcon } from "@assets/img/icons/common/elements.svg";
import { useDispatch } from "react-redux";
import {
  toggleCashActivityAll,
  toggleCashActivityCategory,
  toggleCashActivityCategoryActivity,
} from "@redux/financials/cashActivitySlice";

export function Categories({ allChecked = false, activityCategories = {} }) {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState([]);

  const handleToggle = (id) => {
    setIsOpen((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  return (
    <VStack alignItems={"stretch"} data-test-id={"cash-categories"}>
      <HStack
        justifyContent={"start"}
        pb="16px"
        borderBottom={"1px"}
        borderBottomColor={"dark.300"}
        pl={"6px"}
        pr={"8px"}
      >
        <Icon as={ElementsIcon} w={"24px"} h={"24px"} fill={"textWhite.400"} />
        <Heading size="regular" color={"textWhite.400"} fontWeight={600}>
          Sources
        </Heading>
      </HStack>

      <VStack
        maxHeight={"655px"}
        overflowY={"auto"}
        alignItems={"stretch"}
        gap="16px"
        position={"absolute"}
        top={"74px"}
        left={"16px"}
        right={"8px"}
        sx={{
          "&::-webkit-scrollbar": {
            width: "8px",
          },
        }}
      >
        <Checkbox
          pt={"16px"}
          pl={"8px"}
          variant="skyBlue"
          isChecked={allChecked}
          onChange={() => {
            dispatch(toggleCashActivityAll());
          }}
        >
          <Text color={"textWhite.400"} fontWeight={500} size={"regular"}>
            Select all
          </Text>
        </Checkbox>
        {Object.entries(activityCategories).map(([cashFlowType, categories]) => (
          <VStack key={`${cashFlowType}-categories`} gap={"16px"} alignItems={"start"} maxW={"215px"}>
            <VStack gap="4px" alignItems={"stretch"} w={"full"}>
              <Text fontWeight={500} lineHeight={"24px"} color={"textWhite.400"} pl={"8px"} pr={"8px"}>
                {cashFlowType === "cashIn" ? "Cash in" : "Cash out"}
              </Text>
              {categories.map((category) => {
                const isAccordionOpen = isOpen[`category-${category.id}`];
                return (
                  <Tooltip
                    key={category.name}
                    label={category.name}
                    placement={isAccordionOpen ? "right-start" : "right"}
                    isDisabled={category.name.length < 20}
                  >
                    <Accordion
                      key={category.id}
                      id={`tt-target-${category.id}`}
                      variant={"cashActivityCategories"}
                      flex={1}
                      data-test-id={`category-${category.id}`}
                      w={"100%"}
                      bg={isAccordionOpen ? "dark.500" : ""}
                      borderRadius={"8px"}
                      allowMultiple
                    >
                      <AccordionItem w={"full"}>
                        <HStack justifyContent="space-between">
                          <Checkbox
                            variant={cashFlowType === "cashIn" ? "green" : "purple"}
                            isChecked={category.checked}
                            onChange={() => {
                              dispatch(
                                toggleCashActivityCategory({
                                  categoryId: category.id,
                                }),
                              );
                            }}
                          />
                          <Text
                            size={"small"}
                            textOverflow={"ellipsis"}
                            overflow={"hidden"}
                            whiteSpace={"nowrap"}
                            width={"140px"}
                          >
                            {category.name}
                          </Text>
                          <AccordionButton
                            ml={"auto"}
                            data-test-id={`accordion-button-category-${category.id}`}
                            onClick={() => handleToggle(`category-${category.id}`)}
                          >
                            <AccordionIcon />
                          </AccordionButton>
                        </HStack>
                        <AccordionPanel>
                          {category.activities.map((activity) => (
                            <Tooltip
                              key={activity.name}
                              label={activity.name}
                              placement="right"
                              isDisabled={activity.name.length < 20}
                            >
                              <HStack py="10px" key={activity.id} data-test-id={`activity-${activity.id}`}>
                                <Checkbox
                                  size={"sm"}
                                  variant={cashFlowType === "cashIn" ? "green" : "purple"}
                                  isChecked={activity.checked}
                                  onChange={() => {
                                    dispatch(
                                      toggleCashActivityCategoryActivity({
                                        activityId: activity.id,
                                        cashFlowTypeId: cashFlowType,
                                      }),
                                    );
                                  }}
                                >
                                  <Flex gap={"8px"} alignItems={"center"}>
                                    <Image
                                      h={"14px"}
                                      w={"14px"}
                                      borderRadius={"4px"}
                                      src={activity.icon || activity.defaultImage}
                                      alt={activity.name}
                                      onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = activity.defaultImage;
                                      }}
                                    />
                                    <Text
                                      size={"small"}
                                      textOverflow={"ellipsis"}
                                      overflow={"hidden"}
                                      whiteSpace={"nowrap"}
                                      width={"140px"}
                                    >
                                      {activity.name}
                                    </Text>
                                  </Flex>
                                </Checkbox>
                              </HStack>
                            </Tooltip>
                          ))}
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                  </Tooltip>
                );
              })}
            </VStack>
          </VStack>
        ))}
      </VStack>
    </VStack>
  );
}
