import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { SteamStep1 } from "./SteamStep1";
import { Flex } from "@chakra-ui/react";
import { NOT_CONNECTED } from "@src/utils/status";
import { manualConnectionThunks } from "@redux/integrations/base/manualSlice";
import FadeStepsContainer from "@common/animated/FadeStepsContainer";
import { PermissionCompleted } from "@components/integrations/connection-guide";

export const SteamConnectSetup = ({
  platform = {
    id: "steam",
    name: "Steam",
    platformId: "steam",
  },
  stepNbr,
  goToNextStep,
  closeModal,
}) => {
  const dispatch = useDispatch();
  const steamIntegrationStatus = useSelector((state) => state.integrations.steam.status);

  useEffect(() => {
    if (steamIntegrationStatus === NOT_CONNECTED) {
      dispatch(manualConnectionThunks.createConnection(platform.id));
    }
  }, [dispatch, platform, steamIntegrationStatus]);

  const handleSteamModal = () => {
    dispatch(manualConnectionThunks.completeConnection(platform.id));
    goToNextStep();
  };

  return (
    <Flex justifyContent={"center"} height={"full"} className="fs-modal-content">
      <FadeStepsContainer
        step={stepNbr}
        stepComponents={[
          <SteamStep1 key="SteamStep1" onComplete={handleSteamModal} />,
          <PermissionCompleted key="PermissionCompleted" handleDone={closeModal} />,
        ]}
      />
    </Flex>
  );
};
