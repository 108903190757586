import { useState } from "react";
import { Box, Button, Divider, Heading, SimpleGrid, Text, VStack, useToast } from "@chakra-ui/react";
import { AdNetworkItem } from "./components/ad-network-item/AdNetworkItem";
import { AdNetworkSuggestion } from "./components/ad-network-suggestion/AdNetworkSuggestion";
import { useDispatch, useSelector } from "react-redux";
import { useFlags } from "launchdarkly-react-client-sdk";
import {
  adNetworksSelector,
  adNetworksThunks,
  availableAdNetworksSelector,
  setSelectedAdNetwork,
} from "@redux/integrations/ad-networks/adNetworksSlice";
import { AD_NETWORKS_OAUTH_SLUG } from "@src/consts/ad-networks";
import { StyledLoader } from "@common/inputs/SanloButton/SanloButton";
import { AllNetworksConnected } from "./components/all-networks-connected/AllNetworksConnected";
import { Toast } from "@common/toast/Toast";
import { trackGTMEvent, ADD_NETWORKS_ACTION } from "@utils/gtm";

export function AdNetworksList({ handleNextStep }) {
  const { clientAdNetworkFacebookEnabled, clientAdNetworkGoogleEnabled } = useFlags();
  const { data: availableAdNetworks } = useSelector(availableAdNetworksSelector);
  const {
    connectionCreation: { requestStatus },
  } = useSelector(adNetworksSelector);
  const [selectedAdNetwork, setAdNetwork] = useState(null);
  const dispatch = useDispatch();
  const toast = useToast({
    render: Toast,
    isClosable: true,
    status: "error",
  });

  const trackEvent = (action) => {
    trackGTMEvent({
      event: action,
    });
  };

  async function handleContinue() {
    trackEvent(ADD_NETWORKS_ACTION.MODAL_NEXT);
    try {
      const result = await dispatch(adNetworksThunks.createAdNetworkConnection(selectedAdNetwork.id)).unwrap();
      if (result) {
        dispatch(setSelectedAdNetwork(selectedAdNetwork));
        handleNextStep();
      }
    } catch (e) {
      toast();
    }
  }

  const filteredAdNetworks = availableAdNetworks.filter((adNetwork) => {
    if (!clientAdNetworkFacebookEnabled && adNetwork.slug === AD_NETWORKS_OAUTH_SLUG.facebook) {
      return false;
    }
    if (!clientAdNetworkGoogleEnabled && adNetwork.slug === AD_NETWORKS_OAUTH_SLUG.google) {
      return false;
    }
    return true;
  });

  return (
    <Box minH={"580px"}>
      <VStack gap="4px" mb="32px" alignItems={"start"}>
        <Heading fontWeight={600}>Ad networks</Heading>
        <Text size="smallAlt" maxW={"390px"}>
          Connect ad networks to track advertising revenue and user acquisition spend (Coming soon)
        </Text>
      </VStack>
      <Box padding="16px" bg="dark.600" borderRadius={"10px"}>
        {filteredAdNetworks.length === 0 ? (
          <AllNetworksConnected />
        ) : (
          <>
            <SimpleGrid columns={3} gap={"16px"} data-test-id="ad-networks-container">
              {filteredAdNetworks.map((adNetwork) => (
                <AdNetworkItem
                  key={adNetwork.name}
                  name={adNetwork.name}
                  iconUrl={adNetwork.iconUrl}
                  isSelected={selectedAdNetwork?.name === adNetwork.name}
                  handleSelectedItem={() => {
                    trackEvent(`${ADD_NETWORKS_ACTION.MODAL} - ${adNetwork.name} - Selected`);
                    setAdNetwork(adNetwork);
                  }}
                />
              ))}
            </SimpleGrid>
            <Divider my="16px" />
            <AdNetworkSuggestion />
          </>
        )}
      </Box>
      <Button
        spinner={<StyledLoader />}
        w={"100%"}
        variant={"primary"}
        size={"lg"}
        mt={filteredAdNetworks.length > 3 ? "33px" : "175px"}
        isDisabled={!selectedAdNetwork}
        isLoading={requestStatus.pending}
        onClick={handleContinue}
      >
        Next
      </Button>
    </Box>
  );
}
