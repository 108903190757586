import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import isEmpty from "lodash/isEmpty";

import { hide, prePlaidModalSelector } from "@redux/integrations/plaid/prePlaidModalSlice";
import { BodyMini12Muted, BodySmallAlt } from "@styled";
import RegularModal from "../regular-modal/RegularModal";
import { SanloButton } from "@inputs";

import { trackGTMEvent, BANK_ACTION, COMMON_ACTION, INTEGRATION } from "@utils/gtm";
import { useRequestPlaidLink } from "@utils/integrations/banking/useRequestPlaidLink";

export function PrePlaidModal() {
  const { isOpen, onUploadStatements } = useSelector(prePlaidModalSelector);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [open, ready, plaidConfig, getPlaidLink] = useRequestPlaidLink();

  const trackEvent = (action) => {
    trackGTMEvent({
      event: `${INTEGRATION.BANK_PRE_PLAID_MODAL}${action}`,
    });
  };

  useEffect(() => {
    if (!isEmpty(plaidConfig.token) && ready) {
      open();
      dispatch(hide());
      setIsLoading(false);
    }
  }, [plaidConfig, ready, open, dispatch]);

  const handlerOnClick = () => {
    trackEvent(COMMON_ACTION.CONTINUE);
    setIsLoading(true);
    getPlaidLink();
  };

  return (
    <RegularModal
      isOpen={isOpen}
      title="Are you connecting a U.S. or Canadian bank?"
      handleToggle={() => {
        trackEvent(COMMON_ACTION.CLOSE);
        dispatch(hide());
      }}
      content={
        <>
          <BodySmallAlt>
            Sanlo uses Plaid to securely access financial data from most U.S. and Canadian banks. If your bank is
            registered in a different country, or if you don't see your bank listed in Plaid, please upload bank
            statements instead.
          </BodySmallAlt>
          <BodyMini12Muted style={{ marginTop: "8px" }}>
            *By uploading statements only you won't be able to track your bank balance.
          </BodyMini12Muted>
          <ButtonsWrapper>
            <SanloButton
              isSecondary
              label="Upload Statements"
              onClick={() => {
                trackEvent(BANK_ACTION.UPLOAD_STATEMENT);
                onUploadStatements();
              }}
              testId="preplaid-modal-upload-statements"
              style={{
                width: "auto",
              }}
            />
            <SanloButton
              label="Continue"
              onClick={handlerOnClick}
              isDisabled={isLoading}
              testId="preplaid-modal-continue"
              style={{
                marginRight: "0px",
                width: "auto",
              }}
            />
          </ButtonsWrapper>
        </>
      }
    />
  );
}

const ButtonsWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;
