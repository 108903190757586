import { copy } from "@utils/general";

export const getSaveProgressPayload = (onboardingData) => {
  const savePayload = copy(onboardingData);
  savePayload.currentProgress = 0;
  savePayload.currentStep = 0;
  savePayload.previousStep = 0;
  savePayload.onboardingType = "PRIMARY";

  return savePayload;
};
