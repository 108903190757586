import {
  getRequestStatus,
  getResponseStatus,
  getErrorStatus,
  setStatusDefaults,
  integrationInitialState,
  stateSet,
  parseIntegration,
} from "@utils/redux";

import { GET_INTEGRATIONS_CONNECTIONS } from "../base/actions";

import {
  GET_SUMMARY,
  GET_ADVANCES,
  CAPITAL_REQUEST_START,
  NEW_CAPITAL_REQUEST,
  CAPITAL_REQUEST_CLONE,
  CAPITAL_REQUEST_DISMISS_STATUS,
} from "./actions";

import { sortHistoricalTransactions } from "./utils";

const initialState = integrationInitialState({
  id: "advance",
  type: "advance",
  name: "Advance",
  data: {
    summary: {
      activeRequestsCount: 0,
      requestedCapitalActive: false,
      allowedToCreateRequest: false,
    },
    lists: {
      activeAdvances: [],
      repaidAdvances: [],
      platforms: {},
    },
  },
  requestStatus: {
    getSummary: setStatusDefaults(),
    getAdvances: setStatusDefaults(),
    capitalRequestStart: setStatusDefaults(),
    requestedCapital: setStatusDefaults(),
    newCapitalRequest: setStatusDefaults(),
    capitalRequestClone: setStatusDefaults(),
    capitalRequestDismissStatus: setStatusDefaults(),
  },
});

const advancesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INTEGRATIONS_CONNECTIONS.RESPONSE: {
      return {
        ...state,
        ...parseIntegration("advance", action.response),
        name: state.name, // Keep the display name we have set in the intial state
      };
    }

    case GET_SUMMARY.REQUEST: {
      return stateSet(state, {
        "requestStatus.getSummary": getRequestStatus(),
      });
    }

    case GET_SUMMARY.RESPONSE: {
      const { activeRequestsCount } = action.response || {};
      return stateSet(state, {
        "requestStatus.getSummary": getResponseStatus(),
        "data.summary": {
          ...action.response,
          requestedCapitalActive: Boolean(activeRequestsCount),
        },
      });
    }

    case GET_SUMMARY.ERROR: {
      return stateSet(state, {
        "requestStatus.getSummary": getErrorStatus(),
      });
    }

    case CAPITAL_REQUEST_START.REQUEST: {
      return stateSet(state, {
        "requestStatus.capitalRequestStart": getRequestStatus(),
      });
    }

    case CAPITAL_REQUEST_START.RESPONSE: {
      return stateSet(state, {
        "requestStatus.capitalRequestStart": getResponseStatus(),
      });
    }

    case CAPITAL_REQUEST_START.ERROR: {
      return stateSet(state, {
        "requestStatus.capitalRequestStart": getErrorStatus(),
      });
    }

    case GET_ADVANCES.REQUEST: {
      return stateSet(state, {
        "requestStatus.getAdvances": getRequestStatus(),
      });
    }

    case GET_ADVANCES.RESPONSE: {
      return stateSet(state, {
        "requestStatus.getAdvances": getResponseStatus(),
        "data.lists": sortHistoricalTransactions(action.response),
      });
    }

    case GET_ADVANCES.ERROR: {
      return stateSet(state, {
        "requestStatus.getAdvances": getErrorStatus(),
      });
    }

    case NEW_CAPITAL_REQUEST.REQUEST: {
      return stateSet(state, {
        "requestStatus.newCapitalRequest": getRequestStatus(),
      });
    }

    case NEW_CAPITAL_REQUEST.RESPONSE: {
      return stateSet(state, {
        "requestStatus.newCapitalRequest": getResponseStatus(),
      });
    }

    case NEW_CAPITAL_REQUEST.ERROR: {
      return stateSet(state, {
        "requestStatus.newCapitalRequest": getErrorStatus(),
      });
    }

    case CAPITAL_REQUEST_CLONE.REQUEST: {
      return stateSet(state, {
        "requestStatus.capitalRequestClone": { ...getRequestStatus(), isCloned: false },
      });
    }

    case CAPITAL_REQUEST_CLONE.RESPONSE: {
      return stateSet(state, {
        "requestStatus.capitalRequestClone": {
          ...getResponseStatus(),
          isCloned: true,
        },
      });
    }

    case CAPITAL_REQUEST_CLONE.ERROR: {
      return stateSet(state, {
        "requestStatus.capitalRequestClone": { ...getErrorStatus(), isCloned: false },
      });
    }

    case CAPITAL_REQUEST_DISMISS_STATUS.RESPONSE: {
      return stateSet(state, {
        "requestStatus.capitalRequestDismissStatus": getResponseStatus(),
        "isCapitalRequestDismissed": true,
      });
    }

    case CAPITAL_REQUEST_DISMISS_STATUS.ERROR: {
      return stateSet(state, {
        "requestStatus.capitalRequestDismissStatus": getErrorStatus(),
        "isCapitalRequestDismissed": false,
      });
    }

    default:
      return state;
  }
};

export default advancesReducer;
