import React, { useEffect, useRef } from "react";

import { BodyRegularPlus, BodySmallAlt } from "@styled";
import StyledSetupGuide from "./StyledSetupGuide";
import useShadowScroll from "@utils/custom-hooks/useShadowScroll";
import MediaDisplay from "../../media/MediaDisplay";

const SetupGuideMedia = ({ platformStep, title, media, expand, mediaSlider }) => {
  return (
    <MediaDisplay
      media={media}
      controls
      header="Show Me How"
      expand={expand}
      platformStep={platformStep}
      title={title}
      mediaSlider={mediaSlider}
    />
  );
};

const SetupGuide = ({ platformStep = "", step, expand = false }) => {
  const [wrapperRef, contentRef, shadowBottomRef, shadowTopRef] = useShadowScroll(false);
  const platformStepParam = platformStep.replace(" ", "");

  let extraImages = [];
  (step.multiple || []).forEach((extraStep) => {
    if (extraStep.images) extraImages = extraImages.concat(extraStep.images);
  });

  return (
    <StyledSetupGuide>
      <div ref={shadowTopRef} className="shadow shadow--top" />
      <div ref={shadowBottomRef} className="shadow shadow--bottom" />
      <div className="step-wrapper" ref={wrapperRef}>
        <div ref={contentRef} className={"step-container"}>
          {step.callout && step.callout}

          <BodyRegularPlus className="step-title">{step.title}</BodyRegularPlus>

          {step.description && <BodySmallAlt className="step-description">{step.description}</BodySmallAlt>}

          {(step.images || step.mediaSlider) && (
            <div key={`step-images`} className="step-image">
              <SetupGuideMedia
                platformStep={platformStepParam}
                title={step.title}
                media={step.images}
                mediaSlider={step.mediaSlider}
              />
            </div>
          )}
          {step.multiple && <ExtraSteps platformStep={platformStepParam} multiple={step.multiple} expand={expand} />}
        </div>
      </div>
    </StyledSetupGuide>
  );
};

const ExtraSteps = ({ platformStep, multiple, expand }) => {
  const ref = useRef(null);

  useEffect(() => {
    let timer;
    if (expand) {
      timer = setTimeout(() => {
        ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 500);
    }
    return () => clearTimeout(timer);
  }, [expand]);

  return (
    <div className="extra-steps-container" ref={ref}>
      {multiple.map((el, index) => (
        <div key={`extra-step-${index}`} className="extra-step">
          <BodyRegularPlus className="step-title">{el.title}</BodyRegularPlus>

          {el.description && <BodySmallAlt className="step-description">{el.description}</BodySmallAlt>}

          {el.images && (
            <div key={`extra-step-images`} className="step-image">
              <SetupGuideMedia platformStep={platformStep} title={el.title} media={el.images} expand={expand} />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default SetupGuide;
