import styled from "styled-components";

export const StyledFileWrapper = styled.div`
  position: relative;
  height: 100%;
  border-radius: 12px;

  .shadow {
    box-shadow: none !important;
    border-radius: 12px;
    position: absolute;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 35px;
    display: block;
    pointer-events: none;
    opacity: 0;
  }

  .shadow--top {
    top: 0;
    transform: matrix(1, 0, 0, -1, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 163%);
  }

  .shadow--bottom {
    bottom: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 163%);
  }
`;

export const StyledFileList = styled.div`
  margin-top: 16px;
  border-radius: 12px;

  &.is-uploading {
    opacity: 0.5;
  }

  .files-wrapper {
    display: flex;
    flex-direction: column;

    &.wrap-content {
      justify-content: space-between;
      flex-wrap: wrap;
      flex-direction: row;
    }

    &.scroll-wrap-content {
      max-height: ${({ isAccordion }) => (isAccordion ? "230px" : "270px")};
      overflow-y: auto;
      margin-bottom: 15px;

      &::-webkit-scrollbar {
        width: 20px;
      }

      &::-webkit-scrollbar-thumb {
        padding: 0 6px;
        border-right: 6px solid transparent;
        border-left: 6px solid transparent;
        background-color: rgba(51, 51, 56, 1);
        background-clip: padding-box;
        border-radius: 20px;
      }
    }

    &.is-dragging {
      background-color: ${({ theme }) => theme.colorGreen}14;
      border: 2px dashed ${({ theme }) => theme.colorGreen};
      border-radius: 8px;
      margin: 5px 0px;
      > * {
        pointer-events: none;
      }
    }
  }

  .selected-file-container {
    width: 100%;
    display: flex;
    margin-bottom: 1rem;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.75rem;
    background-color: ${({ theme }) => theme.grayscale50};
    padding: 1rem 1rem;

    &.error {
      background-color: rgba(255, 92, 121, 0.16);
      color: rgba(255, 92, 121, 1);

      svg {
        fill: rgba(255, 92, 121, 1);
        > path {
          fill: rgba(255, 92, 121, 1);
        }
      }
    }

    &.uploaded {
      .icon-name-wrapper::before {
        content: "";
        width: 0.5rem;
        height: 0.5rem;
        position: absolute;
        top: 0;
        left: 0;
        background-color: ${({ theme }) => theme.colorRed};
        border-radius: 50%;
      }
    }

    .icon-name-wrapper {
      width: 90%;
      display: flex;
      align-items: center;
      position: relative;
      cursor: default;

      .file-icon {
        width: 30px;
        height: 20px;
      }

      .selected-file-name {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 60%;
        width: 376px;
      }
      .selected-file-name::after {
        content: "";
        display: block;
      }

      .selected-file-size {
        margin-left: 4px;
      }
    }

    .cross-icon {
      width: 1.25rem;
      height: 1.25rem;
      transition: fill 0.15s linear;
      cursor: pointer;

      fill: ${({ theme }) => theme.textSubtext};
      > path {
        fill: ${({ theme }) => theme.textSubtext};
      }
      &:hover {
        fill: ${({ theme }) => theme.textSeashellWhite};
        > path {
          fill: ${({ theme }) => theme.textSeashellWhite};
        }
      }
    }

    &.is-wrapped {
      width: 49%;

      .selected-file-name {
        max-width: 39%;
      }

      .selected-file-size {
        margin-left: 0;
      }

      .icon-name-wrapper {
        .file-icon {
          width: ${({ isDocTab }) => (isDocTab ? "60px" : "20px")};
        }
      }
    }
  }
`;
