import SanloWebApiService from "./services";

import advancesActions, {
  GET_SUMMARY,
  GET_ADVANCES,
  CAPITAL_REQUEST_START,
  NEW_CAPITAL_REQUEST,
  CAPITAL_REQUEST_CLONE,
  CAPITAL_REQUEST_DISMISS_STATUS,
} from "./actions";
import offerActions from "../../offers/actions";

const advancesMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    switch (action.type) {
      case GET_SUMMARY.REQUEST:
        SanloWebApiService.getSummary()
          .then((response) => response.data)
          .then((response) => {
            dispatch(advancesActions.getSummary.response(response));
          })
          .catch((error) => {
            dispatch(advancesActions.getSummary.error(error));
          });
        break;

      case GET_ADVANCES.REQUEST:
        SanloWebApiService.advances()
          .then((response) => response.data)
          .then((advances) => {
            dispatch(advancesActions.getAdvances.response(advances));
          })
          .catch((error) => {
            dispatch(advancesActions.getAdvances.error(error));
          });
        break;

      case CAPITAL_REQUEST_START.REQUEST:
        SanloWebApiService.capitalRequestStart()
          .then(() => {
            dispatch(advancesActions.capitalRequestStart.response());
          })
          .catch((error) => {
            dispatch(advancesActions.capitalRequestStart.error(error));
          });
        break;

      case NEW_CAPITAL_REQUEST.REQUEST:
        SanloWebApiService.capitalRequest(action.data)
          .then((response) => response.data)
          .then((data) => {
            dispatch(advancesActions.newCapitalRequest.response(data));
            // Refetch advances summary data
            dispatch(advancesActions.getSummary.request());
            // refetch offers
            dispatch(offerActions.getCapitalOffers.request());
          })
          .catch((error) => {
            dispatch(advancesActions.newCapitalRequest.error(error));
          });
        break;

      case CAPITAL_REQUEST_CLONE.REQUEST:
        SanloWebApiService.capitalRequestClone()
          .then((response) => response.data)
          .then((data) => {
            dispatch(advancesActions.capitalRequestClone.response());
            // Refetch advances summary data
            dispatch(advancesActions.getSummary.request());
            // refetch offers
            dispatch(offerActions.getCapitalOffers.request());
          })
          .catch((error) => {
            dispatch(advancesActions.capitalRequestClone.error(error));
          });
        break;
      case CAPITAL_REQUEST_DISMISS_STATUS.REQUEST:
        SanloWebApiService.capitalRequestDismissStatus()
          .then((response) => response.data)
          .then((data) => {
            dispatch(advancesActions.capitalRequestDismissStatus.response());
            // Refetch advances summary data
            dispatch(advancesActions.getSummary.request());
          })
          .catch((error) => {
            dispatch(advancesActions.capitalRequestDismissStatus.error(error));
          });
        break;
      default:
        return;
    }
  };

export default advancesMiddleware;
