import styled from "styled-components";

export const StyledPlatformPickerModal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10000;

  .dr-modal {
    max-width: 34.25rem;
    max-height: 85vh;
    overflow-y: scroll;
    background-color: ${({ theme }) => theme.backgroundColor};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    border-radius: 0.75rem;
    padding: 1.5rem;

    &::-webkit-scrollbar {
      height: 0;
      width: 0.5rem;
      cursor: default !important;
      transform: translateX(10px);
    }

    &::-webkit-scrollbar-track {
      visibility: hidden;
      background-color: ${({ theme }) => theme.inputBackgroundColor};
      opacity: 0.5;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(51, 51, 56, 1);
      border-radius: 1.25rem;
      opacity: 0.5;
    }

    .dr-modal-close {
      width: 40px;
      height: 40px;
      border-radius: 8px;
      padding: 0;
      margin: 0;
      background-color: transparent;
      border: none;
      position: absolute;
      top: 1rem;
      right: 1rem;

      :hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.iconHoverBackground};
      }

      svg {
        width: 24px;
        height: 24px;
        fill: ${({ theme }) => theme.textEmphasis};
      }
    }

    .dr-modal-title {
      margin-bottom: 0.5rem;
    }

    .dr-modal-subtitle {
      color: ${({ theme }) => theme.textGray};
    }

    .dr-modal-category {
      background-color: ${({ theme }) => theme.grayscale30};
      margin-top: 1rem;
      border-radius: 0.75rem;

      .dr-modal-category-title {
        padding: 1rem;
        border-bottom: 1px solid ${({ theme }) => theme.grayscale50};
      }

      .dr-modal-options {
        padding: 1rem;

        .dr-modal-option {
          background-color: ${({ theme }) => theme.grayscale40};
          border-radius: 0.75rem;
        }

        .dr-option-body {
          margin-top: 1rem;
          display: flex;
          align-items: center;
          padding: 1rem;

          .dr-option-icon {
            width: 2rem;
            height: 2rem;
            margin-right: 1rem;
          }

          .dr-option-action {
            margin-left: auto;

            .state-button {
              background-color: ${({ theme }) => theme.grayscale50};
            }
          }
        }

        .dr-option-coming-soon {
          opacity: 0.5;
          border-top: 1px solid #333338;
          padding: 12px;
          line-height: 12px;
          & > span {
            display: inline-block;
          }
          .emoji {
            margin-right: 0.25rem;
          }
        }
      }

      .dr-missing-platforms {
        padding: 0 16px 16px 16px;

        .red-label {
          cursor: pointer;
          color: ${({ theme }) => theme.colorRed};
        }

        .suggest-platform-input-action {
          display: flex;
          align-items: center;
          margin-top: 16px;

          .button-wrapper {
            width: 148px;
          }
        }
      }
    }

    .dr-modal-footer {
      font-size: 14px;
      display: flex;
      align-items: center;
      padding-top: 1rem;
      color: rgba(255, 255, 255, 0.5);

      .dr-modal-done {
        margin-left: auto;
      }
    }

    .dr-modal-link {
      color: white;
      cursor: pointer;
      padding: 0 0.25rem;
    }
  }
`;
