import React from "react";
import { useSelector } from "react-redux";

import { TitleSmallPlus } from "@styled";
import StyledAdvanceList from "./StyledAdvanceList";
import DashboardCardWrapper from "../../dashboard/DashboardCardWrapper";
import AdvanceList from "./AdvanceList";
import { useFlags } from "launchdarkly-react-client-sdk";

const AdvanceListContainer = () => {
  const requestStatus = useSelector((state) => state.integrations.advances.requestStatus.getAdvances);
  const activeAdvances = useSelector((state) => state.integrations.advances.data.lists.activeAdvances);
  const advancePlatforms = useSelector((state) => state.integrations.advances.data.lists.platforms);
  const isVisible = requestStatus.completed;
  const isOnboardingStatusCardDismissed = useSelector(
    (state) => state.session.company.onboarding.isStatusCardDismissed,
  );

  const { onboardingExperienceVisible } = useFlags();

  if (!isVisible) return null;

  return (
    <DashboardCardWrapper>
      {/* Only show title if onboarding card is dismissed */}
      {onboardingExperienceVisible && isOnboardingStatusCardDismissed && (
        <div className="single-advance-title">
          <TitleSmallPlus>Your growth capital</TitleSmallPlus>
        </div>
      )}
      <StyledAdvanceList>
        <AdvanceList advancePlatforms={advancePlatforms} advances={activeAdvances} />
      </StyledAdvanceList>
    </DashboardCardWrapper>
  );
};

export default AdvanceListContainer;
