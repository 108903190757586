import React from "react";

import OnboardingStepAnalytics from "@pages/onboarding/step-analytics/OnboardingStepAnalytics";
import ConnectCallout from "./ConnectCallout";

import { onboardingTextMap } from "@utils/onboarding";
import { Box, Flex, VStack } from "@chakra-ui/react";

const OnboardingSecondaryFlow = ({ connectionInProgress, secondaryFlowCompleted }) => {
  const calloutCopyState = secondaryFlowCompleted ? "connected" : connectionInProgress ? "connecting" : "notConnected";

  return (
    <Flex mt={"24px"} gap={"32px"}>
      <Box flex={1}>
        <ConnectCallout
          title={onboardingTextMap.SECONDARY[calloutCopyState].title}
          description={onboardingTextMap.SECONDARY[calloutCopyState].description}
        />
      </Box>

      <VStack gap={"16px"} flex={1}>
        <OnboardingStepAnalytics fromDashboard />
      </VStack>
    </Flex>
  );
};

export default OnboardingSecondaryFlow;
