import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  integrationsThunks,
  integrationsSelector,
  appsFlyerSelector,
} from "@redux/webshops/webshop/settings/integration/integrationsSlice";

import { Text, VStack, FormControl, FormLabel, Input, Button, HStack, useDisclosure, Flex } from "@chakra-ui/react";
import { ConfirmationModal } from "@common/modals/confirmation-modal";
import { StyledLoader } from "@common/inputs/SanloButton/SanloButton";
import { BaseCard } from "../BaseCard";
import { useForm } from "react-hook-form";
import { DEFAULT, getFormattedForm } from "./utils";
import ErrorBoundary from "@components/ErrorBoundary";

export const AppsFlyer = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const appsFlyerData = useSelector(appsFlyerSelector);
  const {
    getIntegrations = {},
    setIntegrations = {},
    updateIntegrations = {},
    deleteIntegrations = {},
  } = useSelector(integrationsSelector);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { handleSubmit, register, formState, reset } = useForm();
  const { errors, isDirty } = formState;

  async function onSubmit(integration) {
    if (appsFlyerData) {
      dispatch(integrationsThunks.updateIntegrations({ integration, webshopId: id }));
    } else {
      dispatch(integrationsThunks.setIntegrations({ integration, webshopId: id }));
    }
  }

  async function handleRemoveIntegration() {
    await dispatch(integrationsThunks.deleteIntegrations({ integration: appsFlyerData, webshopId: id })).unwrap();
    onClose();
  }

  useEffect(() => {
    if (appsFlyerData) {
      reset(getFormattedForm(appsFlyerData));
    } else {
      reset(DEFAULT);
    }
  }, [appsFlyerData, reset]);

  return (
    <BaseCard title={"AppsFlyer"} id={"builder-settings-apps-flyer"}>
      <ErrorBoundary show={getIntegrations.completed}>
        <form id={"settings-apps-flyer"} onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
          <VStack gap={"16px"}>
            <FormControl display={"flex"} flexDirection={"column"} w={"100%"} alignItems={"flex-start"} gap={"8px"}>
              <FormLabel mb={"8px"}>
                <Text size={"small"} letterSpacing={"0.14px"} fontWeight={600} color={"textWhite.400"}>
                  AppsFlyer API KEY
                </Text>
              </FormLabel>
              <Input
                {...register("apiKey", { required: true })}
                borderColor={errors?.apiKey ? "brandRed.400" : "transparent"}
              />
            </FormControl>

            <FormControl display={"flex"} flexDirection={"column"} w={"100%"} alignItems={"flex-start"} gap={"8px"}>
              <FormLabel mb={"8px"}>
                <Text size={"small"} letterSpacing={"0.14px"} fontWeight={600} color={"textWhite.400"}>
                  Android App ID
                </Text>
              </FormLabel>
              <Input
                {...register("androidId", { required: true })}
                borderColor={errors?.androidId ? "brandRed.400" : "transparent"}
              />
            </FormControl>

            <FormControl display={"flex"} flexDirection={"column"} w={"100%"} alignItems={"flex-start"} gap={"8px"}>
              <FormLabel mb={"8px"}>
                <Text size={"small"} letterSpacing={"0.14px"} fontWeight={600} color={"textWhite.400"}>
                  iOS App ID
                </Text>
              </FormLabel>
              <Input
                {...register("iOSId", { required: true })}
                borderColor={errors?.iOSId ? "brandRed.400" : "transparent"}
              />
            </FormControl>

            {(setIntegrations?.error || updateIntegrations?.error || deleteIntegrations?.error) && (
              <Flex w={"100%"} justifyContent={"flex-start"}>
                <Text size={"smallAlt"} letterSpacing={"0.14px"} color={"brandRed.400"}>
                  Something went wrong. Please try again later.
                </Text>
              </Flex>
            )}

            <HStack w={"100%"} justifyContent={"flex-start"} gap={"16px"}>
              <Button
                type={"submit"}
                form={"settings-apps-flyer"}
                p={"8px 12px"}
                borderRadius={"8px"}
                border={"1px solid"}
                borderColor={"#FAF8F81A"}
                spinner={<StyledLoader />}
                isLoading={setIntegrations?.pending || updateIntegrations?.pending}
                isDisabled={!isDirty || deleteIntegrations?.pending}
              >
                Save
              </Button>
              {appsFlyerData && (
                <Button
                  variant={"primary"}
                  p={"8px 12px"}
                  borderRadius={"8px"}
                  border={"1px solid"}
                  borderColor={"#FAF8F81A"}
                  spinner={<StyledLoader />}
                  isLoading={deleteIntegrations?.pending}
                  isDisabled={setIntegrations?.pending || updateIntegrations?.pending}
                  onClick={onOpen}
                >
                  Delete
                </Button>
              )}
            </HStack>
          </VStack>
        </form>
      </ErrorBoundary>

      <ConfirmationModal
        isOpen={isOpen}
        onClose={onClose}
        onAccept={handleRemoveIntegration}
        title={"Delete AppsFlyer Integrations?"}
        acceptText={"Yes, delete"}
        cancelText={"Cancel"}
        isLoading={deleteIntegrations?.pending}
      >
        <Text size={"smallAlt"} letterSpacing={"0.14px"}>
          Are you sure you want to delete the AppsFlyer integration? This action cannot be undone.
        </Text>
      </ConfirmationModal>
    </BaseCard>
  );
};
