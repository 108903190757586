import { useCallback, useEffect, useState } from "react";
import { Reorder } from "framer-motion";
import styled from "styled-components";
import debounce from "lodash.debounce"; // Import debounce from lodash

export const ReorderGroup = styled(Reorder.Group)`
  width: 100%;
`;

export const ReorderItem = styled(Reorder.Item)`
  width: 100%;
`;

export const useReorderList = (listArray = [], key = "id") => {
  // Constantly updated so the UI can reflect the changes
  const [listOrder, setListOrder] = useState([]);
  // Debounced version of the listOrder state, more usefull for API calls
  const [debouncedListOrder, setDebouncedListOrder] = useState([]);

  useEffect(() => {
    if (listArray.length > 0 && !listOrder.length) {
      setListOrder(listArray.map((listItem) => listItem[key]));
    }
    if (listArray.length !== listOrder.length) {
      setListOrder(listArray.map((listItem) => listItem[key]));
    }
  }, [listArray, listOrder, key]);

  // eslint-disable-next-line
  const updateDebouncedListOrder = useCallback(
    debounce((newOrder) => {
      setDebouncedListOrder(newOrder);
    }, 600),
    [],
  );

  const setNewOrder = (newOrder) => {
    setListOrder(newOrder);
    // Debounce the final update order
    updateDebouncedListOrder(newOrder);
  };

  return [listOrder, setNewOrder, debouncedListOrder];
};
