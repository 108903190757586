import React from "react";
import styled from "styled-components";

import { BodySmall14CSS, BodySmallPlus, BodyRegularPlusCSS } from "@components/styled";

const DocumentsTable = ({ data }) => {
  return (
    <StyledContainer>
      <StyledTable>
        <StyledHeader>Uploaded files</StyledHeader>

        <StyledTableHeader>
          <BodySmallPlus>Name</BodySmallPlus>
          <BodySmallPlus>Upload date</BodySmallPlus>
        </StyledTableHeader>

        <StyledTableBody>
          {data.map((docItem) => {
            const { fileName = "", dateUploaded = "" } = docItem;
            return (
              <StyledTableRow key={dateUploaded}>
                <StyledTableCell>{fileName}</StyledTableCell>
                <StyledTableCell>{dateUploaded}</StyledTableCell>
              </StyledTableRow>
            );
          })}
        </StyledTableBody>
      </StyledTable>
    </StyledContainer>
  );
};

export default DocumentsTable;

const StyledContainer = styled.div`
  margin-top: 1.5rem;
`;

const StyledTable = styled.div`
  background-color: ${(props) => props.theme.grayscale20};
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  position: relative;
  word-wrap: break-word;
  padding: 1.25rem 1.25rem 0 1.25rem;
  display: block;
  width: 760px;
`;

const StyledHeader = styled.div`
  ${BodyRegularPlusCSS}
  padding-bottom: 1rem;
  border-bottom: 1px solid #2a2a2f !important;
  margin-bottom: 1rem;
`;

const StyledTableHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

const StyledTableBody = styled.div`
  max-height: 384px;
  display: block;
  overflow-y: scroll;
`;

const StyledTableRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 16px 0;

  &:not(:last-child) {
    border-bottom: 1px solid #2a2a2f !important;
  }
`;

const StyledTableCell = styled.div`
  ${BodySmall14CSS};
  color: ${(props) => props.theme.grayFontColor};
  width: 50%;
  &:last-child {
    text-align: right;
  }
`;
