import React from "react";
import classnames from "classnames";
import styled from "styled-components";
import text from "@utils/text";

import { BodySmallAlt } from "@styled";

import { ReactComponent as EmptyIcon } from "@assets/img/icons/common/barchart.svg";

export const StyledNoGamesSelected = styled.div`
  width: 44rem;
  height: 15.75rem;
  position: absolute;
  top: 4.3rem;
  left: 3.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
  justify-content: center;
  border-radius: 12px;
  color: ${({ theme }) => theme.textDefault};
  background: ${({ theme }) => theme.grayscale40};

  &.collapsed {
    width: 55.25rem;
  }

  :hover {
    cursor: default;
  }

  svg {
    fill: ${({ theme }) => theme.grayscale70};
    margin-bottom: 24px;
  }
`;

const NoGamesSelectedBodyState = ({ collapsed }) => {
  return (
    <StyledNoGamesSelected
      className={classnames({
        collapsed: collapsed,
      })}
    >
      <EmptyIcon />
      <BodySmallAlt>{text.t("no_game_selected")}</BodySmallAlt>
    </StyledNoGamesSelected>
  );
};

export default NoGamesSelectedBodyState;
