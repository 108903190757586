import styled from "styled-components";
import { LoadingShineCSS } from "@styled";

export const StyledBlocker = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 100;
  background-color: #212126d4;
`;

export const StyledCollapseToggle = styled.div`
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.grayscale20};
  border: 1px solid rgba(255, 255, 255, 0.5);
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 2.125rem;
  transform: translateX(50%);
  color: rgba(255, 255, 255, 0.5);
  box-sizing: border-box;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%) rotateZ(-45deg);
  }
  &::before {
    width: 6px;
    height: 6px;
  }
  &::after {
    width: 4px;
    height: 4px;
  }

  ${({ isCollapsed }) =>
    isCollapsed &&
    `
    &::before {
      border-top: 1px solid rgba(255, 255, 255, 0.5);
      border-left: 1px solid rgba(255, 255, 255, 0.5);
      left: 52%;
    }
    &::after {
      border-top: 1px solid rgba(255, 255, 255, 0.5);
      border-left: 1px solid rgba(255, 255, 255, 0.5);
      left: 62%;
    }
  `};

  ${({ isCollapsed }) =>
    !isCollapsed &&
    `
    &::before {
      border-right: 1px solid rgba(255, 255, 255, 0.5);
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      right: 16%;
    }
    &::after {
      border-right: 1px solid rgba(255, 255, 255, 0.5);
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      right: 38%;
    }
  `};
`;

export const StyledDailyRevenueLoadingCard = styled.div`
  position: relative;
  height: 496px;
  border-radius: 12px;
  margin-bottom: 24px;
  background-color: ${({ theme }) => theme.dashboardCardBackground};
  overflow: hidden;
  background-size: 100% 100%;
`;

export const StyledDailyRevenueCard = styled.div`
  position: relative;
  height: 496px;
  border-radius: 12px;
  margin-bottom: 24px;
  background-color: ${({ theme }) => theme.dashboardCardBackground};

  ${({ isLoading }) =>
    isLoading &&
    `
    ${LoadingShineCSS};
  `};

  .dr-message {
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    &.absolute-position {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
    }

    .dr-message-symbol {
      font-size: 24px;
    }

    .dr-message-text {
      width: 32rem;
      margin: 0.5rem 0 1.5rem 0;
      opacity: 0.75;
      text-align: center;
    }

    .dr-message-button {
      width: auto;

      &.yellow-button {
        background-color: rgba(255, 215, 53, 0.16);
        .button-label {
          color: rgba(255, 215, 53, 1);
        }
      }
    }
  }
`;

export const StyledDailyRevenueContent = styled.div`
  display: grid;
  grid-template-rows: 496px;
  grid-template-columns: 18.125rem 1fr;
  grid-template-areas: "options platformdisplay";
  width: 100%;
  height: 100%;
  /* transition: all 0.3s ease; */

  ${({ isCollapsed }) =>
    isCollapsed &&
    `
    grid-template-columns: 6.875rem 1fr;
  `};

  ${({ isBlurred }) =>
    isBlurred &&
    `
    filter: blur(8px);
  `};
`;

export const StyledOptions = styled.div`
  position: relative;
  grid-area: options;
  display: grid;
  grid-template-rows: 4rem 1fr;
  grid-template-columns: 1fr;
  grid-template-areas:
    "selector"
    "games";

  height: 100%;
  box-sizing: border-box;
  padding: 1.5rem;
  padding-right: 0;
  border-right: 1px solid ${({ theme }) => theme.grayscale70};
`;
