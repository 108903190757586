import React, { useLayoutEffect, useRef, useState } from "react";
import classnames from "classnames";

import { StyledDynamicAdvance, StyledProgressBarContent } from "../StyledAdvanceCard";
import { currencyFormat } from "@utils/currency";

import { SanloTooltip } from "@components/common/tooltips/sanlo-tooltip/SanloTooltip";
import { BodySmallPlus } from "@styled";
import RepaidTooltip from "./tooltips/RepaidTooltip";
import FixedTermUpcomingTooltip from "./tooltips/FixedTermUpcomingTooltip";
import UpcomingTooltip from "./tooltips/UpcomingTooltip";
import RemainingTooltip from "./tooltips/RemainingTooltip";
import FixedTermRepaidTooltip from "./tooltips/FixedTermRepaidTooltip";

const DynamicProgressBar = ({ advancePlatforms = {}, advance, simple, advanceId, gotoTransactionsHistory }) => {
  const {
    totalUpcomingRepayments = 0,
    repaidAmount: repaidAmountWithFees,
    repaymentAmountLeft,
    totalAmount,
    repaymentAmountLeftWithUpcoming = 0,
    platforms = {},
    advanceCurrency,
    advanceType,
    numberOfRepayments,
    numberOfRepaymentsCompleted = 0,
    historicalTransactions,
    capitalFeePercentage,
  } = advance;

  // The repaid amount from the top level of the advance included the wire fee
  // (not the capital fee) which we don't want to show in the progress bar
  let repaidAmount = 0;
  Object.keys(platforms).forEach((platform_key) => {
    repaidAmount += platforms[platform_key].repayments?.totalRepaidInAdvanceCurrency ?? 0;
  });

  // The way the repaid amount is calculated at the moment will never equal the total since
  // the wire fees are missing, so we need to explicitly check if the advance is paid off and
  // if it is, than set the repaidAmount to the totalAmount to ensure the numbers match up
  if (repaidAmountWithFees >= totalAmount || repaymentAmountLeft <= 0) {
    repaidAmount = totalAmount;
  }

  // Used to compare repaid bar width vs repaid label width
  const repaidBarRef = useRef(null);
  const repaidLabelRef = useRef(null);
  // inline = each label is directly under its corresponding bar
  // stacked = repaid is too small for its label, stack all labels under the combined progress bar
  const [labelType, setLabelType] = useState("inline");

  useLayoutEffect(() => {
    // If we've already set labelType to stacked we can ignore
    // everything else, as this will not change again
    if (labelType === "stacked") return;

    const bar = repaidBarRef.current;
    const label = repaidLabelRef.current;
    if (!bar || !label) return;

    const barWidth = bar.clientWidth;
    const labelWidth = label.clientWidth;

    if (labelWidth > barWidth) {
      setLabelType("stacked");
    }
    // eslint-disable-next-line
  }, []);

  const labels = {
    repaid: `${currencyFormat(repaidAmount, advanceCurrency)} repaid`,
    upcoming: `Upcoming repayment ~${currencyFormat(advance.totalUpcomingRepayments, advanceCurrency)}`,
  };

  // Spread the array to avoid mutation
  const historicalReversed = [...historicalTransactions].reverse().slice(1);

  const renderProgressBarContent = () => {
    if (advanceType === "NON_REVENUE_FIXED_TERMS_ADVANCE") {
      return (
        <>
          {[...Array(numberOfRepayments - numberOfRepaymentsCompleted)].map((v, i) => {
            const index = i + numberOfRepaymentsCompleted;
            if (!historicalReversed[index]) return null;

            return (
              <ProgressBarContent
                key={`key_fixed_dprup_${i}_${advanceId}`}
                simple
                id={`tt-fixed-term-${i}-${advanceId}`}
                amount={totalAmount / numberOfRepayments}
                total={totalAmount}
                state="upcoming"
                label={labels.upcoming}
                advance={advance}
                isFixed
                isNextUpcomingPayment={historicalReversed[index].nextUpcomingPayment}
                tooltip={
                  <FixedTermUpcomingTooltip
                    state="upcoming"
                    repaymentAmount={totalAmount / numberOfRepayments}
                    repaymentDate={historicalReversed[index].payDate}
                    capitalFeePercentage={capitalFeePercentage}
                    advanceCurrency={advanceCurrency}
                    gotoTransactionsHistory={gotoTransactionsHistory}
                  />
                }
              />
            );
          })}
        </>
      );
    }

    if (totalUpcomingRepayments) {
      return (
        <>
          {Object.keys(platforms).map((platform_key, index) => {
            const platform = platforms[platform_key];

            const { upcomingRepayments = {} } = platform;
            if (!upcomingRepayments || !Object.keys(upcomingRepayments).length) return null;

            const { repaymentAmountInAdvanceCurrency } = platform.upcomingRepayments;
            return (
              <ProgressBarContent
                key={`key_dprup_${platform.id}`}
                simple={simple}
                amount={repaymentAmountInAdvanceCurrency}
                total={advance.totalAmount}
                state="upcoming"
                id={`tt-upcoming-${platform_key}-${advanceId}`}
                label={labelType === "inline" && !index && labels.upcoming}
                advance={advance}
                tooltip={
                  <UpcomingTooltip
                    state="upcoming"
                    advance={advance}
                    platform={platform}
                    advancePlatforms={advancePlatforms}
                  />
                }
              />
            );
          })}
        </>
      );
    }

    return null;
  };

  return (
    <StyledDynamicAdvance>
      <div className="bar-wrapper">
        {Boolean(repaidAmount) && (
          <ProgressBarContent
            simple={simple}
            id={`tt-repaid-${advanceId}`}
            amount={repaidAmount}
            total={advance.totalAmount}
            state="repaid"
            label={labelType === "inline" && labels.repaid}
            advance={advance}
            tooltip={
              advanceType === "NON_REVENUE_FIXED_TERMS_ADVANCE" ? (
                <FixedTermRepaidTooltip
                  capitalFeePercentage={capitalFeePercentage}
                  repaidAmount={repaidAmount}
                  advanceCurrency={advanceCurrency}
                />
              ) : (
                <RepaidTooltip advancePlatforms={advancePlatforms} state="repaid" advance={advance} />
              )
            }
            barRef={repaidBarRef}
            labelRef={repaidLabelRef}
          />
        )}

        {renderProgressBarContent()}

        {Boolean(repaymentAmountLeftWithUpcoming) && (
          <ProgressBarContent
            simple={simple}
            amount={repaymentAmountLeftWithUpcoming}
            total={advance.totalAmount}
            id={`tt-left-${advanceId}`}
            state={"left"}
            advance={advance}
            tooltip={
              <RemainingTooltip
                repaymentAmountLeft={currencyFormat(repaymentAmountLeftWithUpcoming, advanceCurrency)}
              />
            }
          />
        )}
      </div>

      {labelType === "stacked" &&
        Object.keys(labels).map((label, index) => {
          const labelText = labels[label];
          return (
            <BodySmallPlus key={`${label}_${index}`} className={`progress-bar-label ${label}`}>
              {labelText}
            </BodySmallPlus>
          );
        })}
    </StyledDynamicAdvance>
  );
};

const ProgressBarContent = ({
  amount,
  total,
  state,
  label,
  id,
  advance,
  tooltip,
  simple,
  barRef,
  labelRef,
  isFixed,
  isNextUpcomingPayment,
}) => {
  const calculateBarWidth = () => {
    const width = (amount / total) * 100;
    return `${width}%`;
  };

  const currencyDiff = advance.advanceCurrency !== advance.originCurrency;

  return (
    <StyledProgressBarContent
      className={classnames({
        "is-fixed-advance": isFixed,
      })}
      state={state}
      width={calculateBarWidth()}
      ref={barRef}
      isNext={isNextUpcomingPayment}
    >
      <div id={id} className="progress-bar-content repaid" />
      <SanloTooltip width={currencyDiff ? 500 : 400} resetBottom target={id} text={tooltip} placement={"top"} />
      {!simple && label && (
        <BodySmallPlus className="progress-bar-label" ref={labelRef}>
          {label}
        </BodySmallPlus>
      )}
    </StyledProgressBarContent>
  );
};

export default DynamicProgressBar;
