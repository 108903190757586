import React from "react";
import { StyledProgressTooltip } from "../../StyledAdvanceCard";
import TooltipRow from "./TooltipRow";
import advCopy from "@utils/text/advance";

const FixedTermRepaidTooltip = ({ capitalFeePercentage, repaidAmount, advanceCurrency }) => {
  return (
    <StyledProgressTooltip state="repaid">
      <div className={"tooltip-content repaid"}>
        <TooltipRow
          label="Repaid Amount"
          hint={`Including ${capitalFeePercentage}% of capital fee`}
          color
          display={advCopy.fixedTermCurrencyDisplay(repaidAmount, advanceCurrency)}
        />
      </div>
    </StyledProgressTooltip>
  );
};

export default FixedTermRepaidTooltip;
