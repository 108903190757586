import styled from "styled-components";

export const SaveProgressContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 62px;
  top: 70px;
  background-color: transparent;
`;

export const SaveProgressButton = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.iconHoverBackground};
  }
  svg {
    width: 24px;
    height: 24px;
    fill: ${({ theme }) => theme.textEmphasis};
  }
`;
