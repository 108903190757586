import React from "react";
import { StyledConnectionLoadingCard } from "./StyledConnectionLoadingCard";

const ConnectionLoadingCard = () => {
  return (
    <StyledConnectionLoadingCard>
      <div className="wrapper">
        <div className="header">
          <div className="img-placeholder animated-placeholder" />
          <div className="description animated-placeholder" />
        </div>
        <div className="description animated-placeholder" />
      </div>
    </StyledConnectionLoadingCard>
  );
};

export default ConnectionLoadingCard;
