import styled from "styled-components";

const StyledOfferType = styled.div`
  position: relative;
  width: 539px;
  background-color: ${({ theme }) => theme.grayscale20};
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;
  perspective: 500px;

  &:hover {
    border: ${({ showBlur, theme }) => (showBlur ? "none" : `2px solid ${theme.grayscale70}}`)};
  }

  &.is-selected {
    border: 2px solid ${({ theme }) => theme.primaryColor};
  }

  &.flipped .content {
    transform: rotateX(180deg);
    transition: transform 0.5s;
  }
  .blurry-bg {
    position: absolute;
    inset: 0;
    background-color: rgba(33, 33, 38, 0.5);
    padding: 20px 24px;
    filter: blur(12px);
    border-radius: 12px;
  }
  .content {
    transition: transform 0.5s;
    transform-style: preserve-3d;
    .blur {
      filter: blur(12px);
    }
    .front,
    .back {
      height: 180px;
      backface-visibility: hidden;
      .offer-type-header {
        display: flex;
        align-items: center;
        padding-top: 24px;
        padding-bottom: 12px;
        margin-left: 24px;
        margin-right: 24px;
        justify-content: space-between;

        &-tooltip {
          margin-left: 0.5rem;
          margin-bottom: 0.25rem;
          fill: ${({ theme }) => theme.grayFontColor};
        }

        &-left {
          background-color: ${({ theme }) => `${theme.grayFontColor}29`};
          border-radius: 20px;
          padding: 8px 12px;
          cursor: pointer;
          display: flex;

          &.is-selected {
            color: ${({ theme }) => theme.primaryColor};
            background-color: ${({ theme }) => `${theme.primaryColor}29`};
          }

          input[type="radio"] {
            margin: 0 0.5rem;
            cursor: pointer;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border: 2px solid rgba(197, 197, 197, 0.5);
            width: 1rem;
            height: 1rem;
            border-radius: 50%;
            padding: 0.2rem;
            position: relative;
          }

          input:checked {
            border: 2px solid ${({ theme }) => theme.colorRed};
          }

          input:checked::before {
            content: "";
            position: absolute;
            width: 0.5rem;
            height: 0.5rem;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            background-color: ${({ theme }) => theme.colorRed};
            border-radius: 50%;
          }
        }
      }
      .offer-type-details {
        padding-top: 8px;
        padding-bottom: 8px;
        margin-left: 24px;
        margin-right: 24px;

        &-title {
          margin-bottom: 10px;
          display: flex;
          align-items: center;
        }

        &-tooltip {
          margin-left: 0.5rem;
          margin-bottom: 0.25rem;
          fill: ${({ theme }) => theme.grayFontColor};
        }

        &-subtitle {
          color: ${({ theme }) => theme.textDefault};
          margin-bottom: 10px;
        }
      }
    }

    .back {
      height: 180px;
      background: ${({ type }) =>
        type === "revenue"
          ? "linear-gradient(270deg, rgba(254, 93, 124, 0.22) 0%,  rgba(79, 77, 255, 0.22) 100%)"
          : "linear-gradient(92deg, rgba(28,26,33,1) 0%, rgba(75,88,115,1) 100%)"};
      transform: rotateX(-180deg);

      .offer-type-details {
        width: 464px;
      }
    }
  }
`;

export default StyledOfferType;
