import { copy } from "@utils/general";
import { toKebabCase, addHttpsToUrl } from "@src/utils/string";

import { BUILDER_SECTION_TYPES } from "@src/consts/webshops/builder";

export const SECTION_TYPE = BUILDER_SECTION_TYPES.ITEMS;

export const createSectionLink = (domain, sectionTitle) => {
  try {
    if (!domain || !sectionTitle) return "";
    const url = new URL(addHttpsToUrl(domain));
    url.searchParams.append("section", toKebabCase(sectionTitle));
    const sectionLink = url.toString();
    return sectionLink;
  } catch (error) {
    return "";
  }
};

export const generatePayloads = ({ data, updates }) => {
  // All of the current section data combined
  const payload = {
    type: SECTION_TYPE,
    ...copy(data),
    ...copy(updates),
  };
  // When selecting a category, both items and itemIds come in
  // through the updates. When just reordering, we only get the itemIds
  // so if there are items, they need to be reoreded to match

  // When sections come down the first time, there are only items
  // and no itemIds, so we need to add them
  if (payload.items && !payload.itemIds) {
    payload.itemIds = data.items.map((item) => item.id);
  }
  if (updates.itemIds && !updates.items && data.items) {
    payload.items = payload.itemIds.map((id) => data.items.find((item) => item.id === id));
  }

  // Other info in the section will cause errors
  // like "id" and "items" so we need to remove them
  const requiredDefaults = {
    type: SECTION_TYPE,
    order: 0,
    title: "",
    itemIds: [],
  };

  const request = {};
  Object.keys(requiredDefaults).forEach((key) => {
    if (Object.hasOwn(payload, key)) {
      request[key] = payload[key];
    } else {
      request[key] = requiredDefaults[key];
    }
  });

  return copy({
    request,
    response: payload,
  });
};
