import HTTP from "@redux/http";
import axios from "axios";
const SANLO_WEB_API_URL = process.env.REACT_APP_SANLO_WEB_API_URL;
const BASE = `${SANLO_WEB_API_URL}/api/documents`;

class UploadService {
  getDocuments(type = "") {
    if (type) return HTTP.req.get(`${BASE}?type=${type}`);
    return HTTP.req.get(BASE);
  }

  signedURL({ documentType, files }) {
    return HTTP.req.post(`${BASE}/${documentType}/signed-url/bulk/create`, files);
  }

  useSignedURL(url = "", data) {
    return axios.put(url, data.file, {
      headers: {
        "Content-Type": data.contentType,
      },
    });
  }

  saveToSanloDB({ documentType, data }) {
    return HTTP.req.post(`${BASE}/${documentType}/bulk/create`, data);
  }

  finishedUploading() {
    return HTTP.req.post(`${SANLO_WEB_API_URL}/api/integrations/banking/manual/finish-uploading`);
  }
}

export default new UploadService();
