import React, { Fragment, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ThemeContext } from "styled-components";

import actions from "@redux/actions";

import {
  StyledBlocker,
  StyledDailyRevenueCard,
  StyledDailyRevenueContent,
  StyledOptions,
  StyledCollapseToggle,
} from "./DailyRevenueCardStyles";
import { DailyRevenueSelector } from "./components/Selector/DailyRevenueSelector";
import { DailyRevenueGames } from "./components/Selector/DailyRevenueGames";
import DailyRevenueDisplay from "./components/DailyRevenueDisplay/DailyRevenueDisplay";
import PlatformPickerContainer from "@common/modals/platform-picker/PlatformPickerContainer";
import NothingConnectedBodyState from "./components/BodyStates/Body_NothingConnected";
import FullScreenModal from "@common/modals/fullscreen-modal/FullScreenModal";

import { getGameData } from "./utils/dailyRevenueUtils";
import { isConnectedStatus, isPullingStatus } from "@utils/status";
import { copy } from "@utils/general";
import { getIntegrationState } from "@utils/integrations";
import { generateFakeData } from "./utils/dailyRevenueBGJSON";

const DailyRevenueCard = ({
  isLoading = true,
  isNothingConnected = false,
  isPullingData = false,
  isBroken = false,
  user = {},
  selectedOption = {},
  setSelectedOption = () => {},
  fullOptions = {},
}) => {
  const dispatch = useDispatch();
  const theme = useContext(ThemeContext);

  // Reconnect modal state
  const [reconnectPlatform, setReconnectPlatform] = useState(null);
  const [reconnectModalOpen, setReconnectModalOpen] = useState(false);

  // There will only ever be 5 items maximum shown on the chart.
  // The order of the colors initially is top earners to bottom earners
  const colors = [theme.colorGreen, theme.colorSkyBlue, theme.colorElectricBlue, theme.colorPurple, theme.colorPink];

  const [displayEarnings, setDisplayEarnings] = useState(isNothingConnected ? {} : selectedOption.earnings);
  const [hasEarnings, setHasEarnings] = useState(Boolean(Object.keys(displayEarnings || {}).length));

  // Get the initial order of games for a given platform and assign them colors
  const [gameData, setGameData] = useState(
    getGameData(
      isNothingConnected ? generateFakeData() : displayEarnings,
      null,
      colors,
      selectedOption,
      isNothingConnected,
      user,
    ),
  );
  const [collapsed, setCollapsed] = useState(false);
  const [displayPlatformsModal, setDisplayPlatformsModal] = useState(false);
  const [displayPlatformsModalTypes, setDisplayPlatformsModalTypes] = useState(null);

  useEffect(() => {
    if (isPullingStatus(selectedOption)) {
      setDisplayEarnings(null);
      return;
    }

    // There are already earnings, or the selectOption is invalid
    const dontFetch = hasEarnings || !selectedOption || !selectedOption.id || !isConnectedStatus(selectedOption);

    const requestStatus = selectedOption.requestStatus;
    const requestStarted = requestStatus && (requestStatus.completed || requestStatus.pending);
    // Also don't fetch if the call is in progress
    if (dontFetch || requestStarted) return;

    if (selectedOption.isPlatform && !selectedOption.id.match(/appsflyer|adjust/)) {
      const requestAction = actions.earnings[`${selectedOption.id}GetEarnings`];
      if (requestAction) dispatch(requestAction.request());
    }
    if (selectedOption.isAdNetwork) {
      const requestAction = actions.earnings.adRevenueGetEarnings;
      if (requestAction) dispatch(requestAction.request(selectedOption.id));
    }
    // eslint-disable-next-line
  }, [selectedOption, hasEarnings]);

  useEffect(() => {
    const newDisplayEarnings = isNothingConnected ? generateFakeData() : selectedOption.earnings;
    const newHasEarnings = Boolean(Object.keys(newDisplayEarnings || {}).length);

    setHasEarnings(newHasEarnings);
    if (selectedOption.isNone) {
      setDisplayEarnings({});
    }
    if (newHasEarnings) {
      setDisplayEarnings(newDisplayEarnings);
    }
    if (isNothingConnected) {
      localStorage.setItem("chartGameOrder", "{}");
    }
    // eslint-disable-next-line
  }, [selectedOption.earnings, isNothingConnected]);

  useEffect(() => {
    const newGameData = getGameData(displayEarnings, gameData, colors, selectedOption, isNothingConnected, user);
    setGameData(newGameData);
    // eslint-disable-next-line
  }, [displayEarnings]);

  useEffect(() => {
    setGameDataInStorage(gameData);
    // eslint-disable-next-line
  }, [gameData]);

  const onOptionConnectClick = (option, hasAction) => {
    // We don't need to check for connectAction here because
    // banking isn't in this flow, and the individual ad networks
    // can't trigger this flow
    const { status } = option;

    if (!hasAction) {
      const integrationState = getIntegrationState(status);
      hasAction = integrationState.hasAction;
    }

    if (hasAction) {
      setReconnectPlatform(option);
      setReconnectModalOpen(true);
    }
  };

  const setGameDataInStorage = (gameData) => {
    if (!gameData || !gameData.order.length || !selectedOption.id || isNothingConnected || !user || !user.userId) {
      return;
    }
    const dataKey = "chartGameOrder";
    const existingStorageState = localStorage.getItem(dataKey) || "{}";
    const parsedExistingStorageState = JSON.parse(existingStorageState);
    if (!parsedExistingStorageState[user.userId]) parsedExistingStorageState[user.userId] = {};
    parsedExistingStorageState[user.userId][selectedOption.id] = gameData;
    localStorage.setItem(dataKey, JSON.stringify(parsedExistingStorageState));
  };

  return (
    <Fragment>
      <StyledDailyRevenueCard isLoading={isLoading}>
        {(isNothingConnected || isPullingData) && !isLoading && (
          <NothingConnectedBodyState isPullingData={isPullingData} isBroken={isBroken} />
        )}

        <StyledDailyRevenueContent isCollapsed={collapsed} isBlurred={isNothingConnected || isPullingData}>
          {(isNothingConnected || isPullingData) && <StyledBlocker />}

          <StyledOptions>
            <DailyRevenueSelector
              isLoading={isLoading}
              collapsed={collapsed}
              fullOptions={fullOptions}
              selectedOption={selectedOption}
              onOptionSelect={(newOption) => {
                setGameData(getGameData(null, null, colors, null, null, null));
                setSelectedOption(newOption);
              }}
              onOptionConnectClick={onOptionConnectClick}
            />

            <DailyRevenueGames
              theme={theme}
              isLoading={isLoading}
              collapsed={collapsed}
              selectedOption={selectedOption}
              selectedOptionGameData={gameData}
              isNothingConnected={isNothingConnected}
              onToggle={(gameId, checked) => {
                const gameDataCopy = copy(gameData);
                if (gameId === "other") {
                  gameDataCopy.other.selected = checked;
                } else {
                  gameDataCopy.order.forEach((game) => {
                    if (game.gameId === gameId) game.selected = checked;
                    gameDataCopy.map[game.gameId] = game;
                  });
                }
                setGameData(gameDataCopy);
              }}
              onOrderChange={(newGamesData) => {
                setGameData(newGamesData);
              }}
            />

            <StyledCollapseToggle
              isCollapsed={collapsed}
              onClick={() => {
                setCollapsed(!collapsed);
              }}
            />
          </StyledOptions>

          <DailyRevenueDisplay
            theme={theme}
            dispatch={dispatch}
            isLoading={isLoading}
            collapsed={collapsed}
            isNothingConnected={isNothingConnected}
            selectedOption={selectedOption}
            selectedOptionGameData={gameData}
            fullOptions={fullOptions}
            dailyRevenueData={isNothingConnected ? generateFakeData() : displayEarnings || {}}
            onOptionConnectClick={onOptionConnectClick}
          />
        </StyledDailyRevenueContent>
      </StyledDailyRevenueCard>

      <PlatformPickerContainer
        isOpen={displayPlatformsModal}
        setDisplayPlatformsModal={setDisplayPlatformsModal}
        selectableCategories={displayPlatformsModalTypes || ["ad-networks", "in-app-purchases"]}
        onModalClose={() => {
          setDisplayPlatformsModal(false);
          // Reset the types to show on close, this is set in the Pulling Data body state
          setDisplayPlatformsModalTypes(null);
        }}
      />

      {reconnectModalOpen && (
        <FullScreenModal
          saveProgress={reconnectPlatform.canSaveProgress}
          modalContent={reconnectPlatform}
          openModal={true}
          closeModal={() => setReconnectModalOpen(false)}
        />
      )}
    </Fragment>
  );
};

export default DailyRevenueCard;
