import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDisclosure } from "@chakra-ui/react";

import { adNetworksThunks, resetSelectedAdNetwork } from "@redux/integrations/ad-networks/adNetworksSlice";
import { adNetworkConnectionsSelector } from "@redux/integrations/base/baseSlice";
import { integrationsConnectionsThunks } from "@redux/integrations/base/baseSlice";

import { BodySmallAlt } from "@styled";
import { AdNetworksStepper } from "@components/integrations/ad-networks/components/ad-networks-stepper/AdNetworksStepper";

import StyledPlatformLinkingContent from "../StyledPlatformLinkingContent";
import ConnectedPlatformCard from "../ConnectedPlatformCard";
import { AddButton } from "../PlatformLinking.styled";
import { trackGTMEvent, ADD_NETWORKS_ACTION } from "@utils/gtm";

import { ReactComponent as Plus } from "@assets/img/icons/common/plus.svg";

const AdNetworkLinkingAccordion = ({ description = "" }) => {
  const dispatch = useDispatch();
  // const location = useLocation();

  const { isOpen, onClose, onOpen } = useDisclosure();
  const adNetworkConnections = useSelector(adNetworkConnectionsSelector);
  const [initialStep, setInitialStep] = useState(0);

  useEffect(() => {
    dispatch(integrationsConnectionsThunks.getIntegrationsConnections());
    dispatch(adNetworksThunks.getAvailableNetworks());
  }, [dispatch]);

  const handleCredentialsRetry = () => {
    setInitialStep(1);
    // Trick to hide the first step of the stepper
    setTimeout(() => {
      onOpen();
    }, 50);
  };

  const trackEvent = (action) => {
    trackGTMEvent({
      event: action,
    });
  };

  return (
    <>
      <StyledPlatformLinkingContent>
        <div className="description-container as-row">
          <BodySmallAlt className="platform-description">{description}</BodySmallAlt>
          <AddButton
            data-test-id="add-banking"
            className="inverted-button"
            onClick={() => {
              trackEvent(ADD_NETWORKS_ACTION.ADD);
              onOpen();
            }}
          >
            <Plus /> Add
          </AddButton>
        </div>
      </StyledPlatformLinkingContent>

      {adNetworkConnections.map((integration) => {
        const { status } = integration;
        return (
          <ConnectedPlatformCard
            key={`integration-${integration.id}-${status}`}
            data-test-id={`integration-${integration.id}`}
            platform={integration}
            status={status}
            handlers={{
              NEEDS_RECONNECTION: () => {
                dispatch(adNetworksThunks.getNetworkByConnectionId(integration.id));
                handleCredentialsRetry();
              },
              NO_PERMISSIONS: () => {
                dispatch(adNetworksThunks.getNetworkByConnectionId(integration.id));
                handleCredentialsRetry();
              },
            }}
          />
        );
      })}

      <AdNetworksStepper
        isOpen={isOpen}
        onClose={() => {
          trackEvent(ADD_NETWORKS_ACTION.MODAL_CLOSE);
          onClose();
          setInitialStep(0);
        }}
        onCloseComplete={() => {
          dispatch(resetSelectedAdNetwork());
        }}
        initialStep={initialStep}
      />
    </>
  );
};

export default AdNetworkLinkingAccordion;
