import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  p,
  ol,
  ul,
  li,
  dl,
  dt,
  dd,
  blockquote,
  figure,
  fieldset,
  legend,
  textarea,
  pre,
  iframe,
  hr,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 100%;
    font-weight: normal;
  }

  ul {
    list-style: none;
  }

  button,
  input,
  select,
  textarea {
    margin: 0;
  }

  img,
  video {
    height: auto;
    max-width: 100%;
  }

  iframe {
    border: 0;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  td,
  th {
    padding: 0;
  }

  td:not([align]),
  th:not([align]) {
    text-align: inherit;
  }

  article,
  aside,
  figure,
  footer,
  header,
  hgroup,
  section {
    display: block;
  }

  code,
  pre {
    -moz-osx-font-smoothing: auto;
    -webkit-font-smoothing: auto;
    font-family: monospace;
  }

  a {
    cursor: pointer !important;
    text-decoration: none;
    &:hover {
      color: currentColor;
    }
  }

  code {
    background-color: whitesmoke;
    color: #f14668;
    font-size: 0.875em;
    font-weight: normal;
    padding: 0.25em 0.5em 0.25em;
  }

  hr {
    background-color: whitesmoke;
    border: none;
    display: block;
    height: 1px;
    margin: 1.5rem 0;
  }

  img {
    height: auto;
    max-width: 100%;
  }

  input[type="checkbox"],
  input[type="radio"] {
    vertical-align: baseline;
  }

  small {
    font-size: 0.875em;
  }

  span {
    font-style: inherit;
    font-weight: inherit;
  }

  strong {
    color: #363636;
    font-weight: 700;
  }

  fieldset {
    border: none;
  }

  pre {
    -webkit-overflow-scrolling: touch;
    background-color: whitesmoke;
    color: #4a4a4a;
    font-size: 0.875em;
    overflow-x: auto;
    padding: 1.25rem 1.5rem;
    white-space: pre;
    word-wrap: normal;
  }

  pre code {
    background-color: transparent;
    color: currentColor;
    font-size: 1em;
    padding: 0;
  }

  table td,
  table th {
    vertical-align: top;
  }

  table td:not([align]),
  table th:not([align]) {
    text-align: inherit;
  }

  table th {
    color: #363636;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  // Hubspot chat bubble
  #hubspot-messages-iframe-container{
    z-index: 0 !important;
  }

  .modal {
    width:100vw;
  }

  .modal.show {
    z-index: 10000;
  }

  .modal-open {
    padding-right: 0px !important;
  }

  .modal-backdrop.show {
    opacity: 0.5 !important;
  }

  *, *::before, *::after {
    box-sizing: inherit;
  }

  &::-webkit-scrollbar {
    height: 8px;
    width: 0px;
    cursor: default !important;
  }

  &::-webkit-scrollbar-track {
    visibility: hidden;
    background-color: ${({ theme }) => theme.inputBackgroundColor};
    opacity: 0.5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(65, 65, 69, 1);
    border-radius: 20px;
    opacity: 0.5;
  }

  html, body {
    --loading-shine-skew: 155deg;

    height: 100%;
    width: 100%;
    /* overflow: hidden; */
    position: relative;
    margin: 0 !important;
    padding: 0 !important;
    box-sizing: border-box;

    background: ${({ theme }) => theme.backgroundColor} !important;
    color: ${({ theme }) => theme.primaryFontColor} !important;
    font-family: "Roobert", serif;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    text-size-adjust: 100%;
  }

  #root {
    position: absolute;
    height: 100%;
    width: 100%;
  }

  .nav-item a {
    color: ${({ theme }) => theme.linkColor};
  }

  .no-text-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
  }

  button:focus, input:focus, textarea:focus, select:focus {
    outline: none;
  }

  button[disabled], input[disabled], textarea[disabled], select[disabled] {
    cursor: not-allowed;
  }

  button:not(:disabled), input:not(:disabled), textarea:not(:disabled), select:not(:disabled) {
    cursor: pointer;
  }

  label {
    display: inline-block;
  }

  .icon {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    height: 1.5rem;
    width: 1.5rem;
  }

  img, svg {
    vertical-align: middle;
    display: inline-block;
  }

  .mb1 {
    margin-bottom: 1rem !important;
  }

  .mb2 {
    margin-bottom: 2rem !important;
  }

  .ml_half {
    margin-left: 0.5rem;
  }

  .ml1 {
    margin-left: 1rem;
  }

  .scrollY {
    overflow-y: scroll !important;
  }

  .cursor-not-allowed {
    cursor: not-allowed !important;
  }

  .emoji-mr {
    .emoji {
      margin-right: 4px;
    }
  }

  .p1-rbl {
    padding: 0 1rem 1rem 1rem;
  }

  .mt-16 {
    margin-top: 16px;
  }

  .support-link-red {
    color: ${({ theme }) => theme.colorRed};
    &:hover {
      color: ${({ theme }) => theme.colorRed};
    }
  }


  .red-label {
    color: #FF5C79;
    display: inline;
    font-weight: 500;
  }
  .tooltip-active-text {
    cursor: pointer;
    &:hover {
      filter: brightness(120%);
    }
  }


  .loading-shine {
    overflow: hidden;
    // Hack to make the overflow hidden work on Safari with border-radius
    transform: translateZ(0);

    &:after {
      content:'';
      position: absolute;
      top: 0;
      transform: translateX(150%) skewX(var(--loading-shine-skew));
      width: 100%;
      height: 100%;
      opacity: 0.25;
      z-index:1;
      border-radius: 1rem;
      animation: loading-shine-anim 1.5s infinite;
      background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 50%, rgba(128,186,232,0) 99%, rgba(125,185,232,0) 100%);
      background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,0)), color-stop(50%,rgba(255,255,255,0.8)), color-stop(99%,rgba(128,186,232,0)), color-stop(100%,rgba(125,185,232,0)));
      background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%);
      background: -o-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%);
      background: -ms-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%);
      background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%);
    }
    @keyframes loading-shine-anim {
      0% {
        transform:translateX(-150%) skewX(var(--loading-shine-skew));
      }
      100% {
        transform:translateX(150%) skewX(var(--loading-shine-skew));
      }
    }
  }

  @media
  not screen and (-webkit-min-device-pixel-ratio: 2),
  not screen and (   min--moz-device-pixel-ratio: 2),
  not screen and (     -o-min-device-pixel-ratio: 2/1),
  not screen and (        min-device-pixel-ratio: 2),
  not screen and (                min-resolution: 192dpi),
  not screen and (                min-resolution: 2dppx) {

    /* non-Retina-specific stuff here */
    .emoji {
      //margin-right: 8px;
    }
  }

  a.sanlo-email-label {
    color: #FF5C79;

    :hover {
      color: #FF5C79;
      text-decoration: underline;
    }
  }

  // ANIMATIONS

  @keyframes fadeIn {
    from {opacity: 0}
    to {opacity: 1;}
  }

  @keyframes fadeOut {
    from {opacity: 1}
    to {opacity: 0}
  }

  @keyframes fadeOutIn {
    0% {opacity: 1}
    30% {opacity: 0}
    95% {opacity: 0}
    100% {opacity: 1}
  }

  @keyframes pushInFromBottom {
    from {
      transform: translateY(100px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes pushInFromBottom25 {
    from {
      transform: translateY(25px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes pushInFromBottom50 {
    from {
      transform: translateY(50px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;
