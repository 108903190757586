import React from "react";
import { useNavigate } from "react-router";
import moment from "moment";

import { BodyLargePlus, BodySmall14 } from "@styled";
import StyledAdvanceRow from "./StyledAdvanceRow";
import DynamicProgressBar from "../dynamic-progress-bar/DynamicProgressBar";

import { currencyFormat } from "@utils/currency";

import { ReactComponent as ArrowIcon } from "@assets/img/icons/common/accordion-arrow.svg";

const AdvanceRow = ({ advance, id, advancePlatforms }) => {
  const navigate = useNavigate();

  const handleGotoGrowthCapitalCard = (id) => {
    navigate("/growth-capital#" + id);
  };

  const { capitalAmount, advanceCurrency, gameIcon, gameTitle, maskedDisbursementAccount, createdAt } = advance;

  return (
    <StyledAdvanceRow onClick={() => handleGotoGrowthCapitalCard(id)}>
      <div className="advance-row-left">
        <div className="advance-amount">
          <BodyLargePlus>{currencyFormat(capitalAmount, advanceCurrency)}</BodyLargePlus>
        </div>
        <div className="advance-game-info">
          <img src={gameIcon} alt="" />
          <BodySmall14 className="game-name">{gameTitle}</BodySmall14>
        </div>
      </div>
      <div className="advance-row-right">
        <div className="advance-account-info">
          <BodySmall14>
            Disbursed to account *{maskedDisbursementAccount} on {moment(createdAt).format("MMM Do")}
          </BodySmall14>
        </div>
        <div className="advance-progress-bar">
          <DynamicProgressBar
            advancePlatforms={advancePlatforms}
            advanceId={id}
            className="progress-bar-content"
            simple
            advance={advance}
          />
          <ArrowIcon className="arrow-icon" />
        </div>
      </div>
    </StyledAdvanceRow>
  );
};

export default AdvanceRow;
