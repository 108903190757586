import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { FormControl, FormLabel, Input, Text, Textarea, VStack } from "@chakra-ui/react";
import { BaseCard } from "./BaseCard";

import { DEFAULT_DEBOUNCE_TIME } from "@utils/consts";
import { getSettingsPayload } from "../utils";
import { settingsSelector, settingsThunks } from "@redux/webshops/webshop/settings/settingsSlice";
import { useParams } from "react-router-dom";
import { webshopsSelector } from "@redux/webshops/webshopsSlice";
import { useAutoSave } from "@src/utils/hooks/useAutoSave";

export const SiteDetails = () => {
  const dispatch = useDispatch();
  const { settings } = useSelector(settingsSelector);
  const { selectedWebshop } = useSelector(webshopsSelector);
  const { id } = useParams();
  const [title, setTitle] = useState(settings?.title ?? "");
  const [description, setDescription] = useState(settings?.description ?? "");
  const updateSiteDetails = useAutoSave((payload) => {
    dispatch(settingsThunks.setSiteDetails(payload));
  }, DEFAULT_DEBOUNCE_TIME);
  const handleOnChange = (updates) => {
    updateSiteDetails({
      data: getSettingsPayload(updates, settings),
      webshopId: id,
    });
  };

  return (
    <BaseCard title={"Site details"} id={"settings-site-details"}>
      <VStack gap={"16px"} alignItems={"flex-start"}>
        <FormControl>
          <FormLabel mb={"8px"}>
            <Text size={"small"} letterSpacing={"0.14px"} fontWeight={600} color={"textWhite.400"}>
              Title
            </Text>
          </FormLabel>
          <Input
            value={title}
            placeholder={`${selectedWebshop?.name ?? ""} webshop`}
            onChange={(event) => {
              handleOnChange({
                title: event.target.value,
                description,
              });
              setTitle(event.target.value);
            }}
            data-test-id={"site-details-title"}
          />
        </FormControl>

        <FormControl>
          <FormLabel mb={"8px"}>
            <Text size={"small"} letterSpacing={"0.14px"} fontWeight={600} color={"textWhite.400"}>
              Description
            </Text>
          </FormLabel>
          <Textarea
            variant={"builder"}
            h={"200px"}
            value={description}
            placeholder={"Made with Sanlo"}
            onChange={(event) => {
              handleOnChange({
                description: event.target.value,
                title,
              });
              setDescription(event.target.value);
            }}
            data-test-id={"site-details-description"}
          />
        </FormControl>
      </VStack>
    </BaseCard>
  );
};
