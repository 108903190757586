import styled from "styled-components";
import { Modal } from "reactstrap";

const StyledBankBalanceDetails = styled(Modal)`
  & {
    max-width: fit-content !important;

    &.modal-dialog {
      margin: 0 !important;
    }

    .modal-content {
      overflow: hidden;
      background-color: ${({ theme }) => theme.grayscale10};
      width: 100vw;
      height: 100vh;
      max-height: 100vh;
      padding: 48px 196px;
    }

    .bank-details-header {
      display: flex;
      align-items: center;
      margin-bottom: 32px;
      cursor: pointer;
      color: rgba(197, 197, 197, 0.75);

      .back-arrow-svg {
        margin-right: 16px;
        padding: 6.5px 8px 8px;
        width: 40px;
        height: 40px;
        fill: rgb(250, 248, 248);
        border-radius: 8px;
        transition: 0.25s all ease;

        path {
          fill: rgb(250, 248, 248);
        }
        &:hover {
          background-color: ${({ theme }) => theme.iconHoverBackground};
        }
      }

      .back-arrow-text {
        color: rgb(250, 248, 248);
      }
    }

    .shadow-wrapper {
      position: relative;
      overflow: hidden;
      max-height: 80%;

      .shadow {
        box-shadow: none !important;
        position: absolute;
        left: 0;
        z-index: 100;
        width: 100%;
        height: 100px;
        pointer-events: none;
      }

      .shadow--top {
        top: 0;
        background: linear-gradient(180deg, #1a1a1f 4.86%, rgba(26, 26, 31, 0) 100%);
        opacity: 0;
        display: block;
      }

      .shadow--bottom {
        display: block;
        bottom: 0;
        background: linear-gradient(180deg, rgba(26, 26, 31, 0) 4.86%, #1a1a1f 100%);
      }

      .shadow-container {
        height: 100%;
        overflow-y: scroll;
      }
    }

    .bank-details-container {
      .currency-details-container {
        display: flex;
        align-items: center;
        border-top: 1px solid ${({ theme }) => theme.grayscale40};
        border-bottom: 1px solid ${({ theme }) => theme.grayscale40};
        padding-top: 24px;
        padding-bottom: 24px;
        margin-bottom: 24px;

        .currency-details-content {
          display: flex;
          flex-direction: column;
          margin-bottom: 4px;

          .current-balance-container {
            display: flex;
            align-items: center;

            .cash-balance {
              margin-right: 16px;
              color: ${({ theme }) => theme.textSeashellWhite};

              .currency-abbreviation {
                margin-left: 4px;
                margin-right: 4px;
              }

              .credit-disclosure-tooltip {
                display: inline;

                > svg {
                  vertical-align: super;
                }
              }
            }
          }

          .currency-details-status {
            color: ${({ theme }) => theme.textGray};
          }
        }
      }

      .bank-account-details {
        border-bottom: 1px solid #2a2a2f;
        padding-bottom: 24px;
        margin-bottom: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:last-child {
          border-bottom: none;
          margin-bottom: 0;
        }

        .bank-account-data {
          display: flex;
          align-items: center;

          .bank-logo {
            width: 32px;
            height: 32px;
            margin-right: 16px;
          }

          .bank-account-content {
            .bank-name {
              color: ${({ theme }) => theme.textSeashellWhite};

              .negative-account-balance {
                color: ${({ theme }) => theme.textGray};
              }

              .bank-account-status {
                display: inline-flex;
                align-items: baseline;

                &.warning {
                  color: ${({ theme }) => theme.colorYellow};

                  svg {
                    fill: ${({ theme }) => theme.colorYellow};
                  }
                }

                &.default {
                  color: ${({ theme }) => theme.textGray};

                  .bank-account-status-icon {
                    margin-right: 4px;
                  }
                }

                .bank-account-status-icon {
                  width: 20px;
                  height: 20px;
                  line-height: 18px;
                }

                .bank-account-status-label {
                  line-height: 20px;
                  cursor: default;
                }
              }
            }

            .account-name {
              color: ${({ theme }) => theme.textSubtext};
            }
          }
        }

        .amount-state-container {
          display: flex;
          align-items: center;

          .bank-account-cash-balance {
            color: ${({ theme }) => theme.textGray};

            &.needs-reconnection {
              color: ${({ theme }) => theme.textSubtext};
            }
          }

          .state-button {
            margin-left: 8px;
          }
        }
      }
    }

    .integrations-page-redirect {
      position: absolute;
      bottom: 40px;
      left: 196px;
      color: ${({ theme }) => theme.textGray};
      z-index: 5;

      .label-button {
        cursor: pointer;
        font-weight: 500;
        color: ${({ theme }) => theme.textSeashellWhite};
      }
    }
  }
`;

export default StyledBankBalanceDetails;
