import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Input } from "@chakra-ui/react";
import { debounce } from "lodash";

import { StylePicker } from "../../StylePicker";
import { BackgroundStylePicker } from "../BackgroundStylePicker";

import { sectionsThunks, storefrontSectionsSelector, updateHeader } from "@redux/webshops/builder/sectionsSlice";

import { generatePayloads } from "./utils";
import {
  PropertiesHeader,
  SectionWrapper,
  getSectionTheme,
  COLOR_TYPES,
  FILE_TYPES,
  generateFilePayloads,
  generateFileRemovePayloads,
  generateThemePayloads,
  generateThemeResetPayloads,
} from "@utils/webshop/section";
import { DEFAULT_DEBOUNCE_TIME } from "@utils/consts";
import PropTypes from "prop-types";

export const HeaderPropertiesContainer = ({ webshop = {}, storefront = {} }) => {
  const dispatch = useDispatch();

  const data = useSelector(storefrontSectionsSelector).header;
  const theme = getSectionTheme(storefront, data);

  // eslint-disable-next-line
  const debouncedUpdate = useCallback(
    debounce((data) => {
      dispatch(sectionsThunks.updateSection(data));
    }, DEFAULT_DEBOUNCE_TIME),
    [dispatch],
  );

  // eslint-disable-next-line
  const debouncedThemeUpdate = useCallback(
    debounce((data) => {
      dispatch(sectionsThunks.createSectionThemeOverride(data));
    }, DEFAULT_DEBOUNCE_TIME),
    [dispatch],
  );

  const onChange = (updates) => {
    const payloads = generatePayloads({
      data,
      updates,
    });
    debouncedUpdate({
      data: payloads.request,
      webshopId: webshop.id,
      sectionId: data.id,
    });
    dispatch(updateHeader(payloads.response));
  };

  const onThemeChange = (themeObj) => {
    const payloads = generateThemePayloads({
      data,
      themeObj,
    });
    debouncedThemeUpdate({
      data: payloads.request,
      webshopId: webshop.id,
      sectionId: data.id,
    });
    dispatch(updateHeader(payloads.response));
  };

  const onThemeReset = () => {
    const payloads = generateThemeResetPayloads({
      data,
    });
    dispatch(
      sectionsThunks.deleteSectionThemeOverride({
        webshopId: webshop.id,
        sectionId: data.id,
      }),
    );
    dispatch(updateHeader(payloads.response));
  };

  const onFileCreate = async (fileObj) => {
    const payloads = await generateFilePayloads({
      data,
      fileObj,
    });
    dispatch(
      sectionsThunks.createSectionFile({
        data: payloads.request,
        webshopId: webshop.id,
        sectionId: data.id,
      }),
    );
    dispatch(updateHeader(payloads.response));
  };

  const onFileRemove = (fileObj) => {
    const payloads = generateFileRemovePayloads({
      data,
      fileObj,
    });
    dispatch(
      sectionsThunks.deleteSectionFile({
        data: payloads.request,
        webshopId: webshop.id,
        sectionId: data.id,
      }),
    );
    dispatch(updateHeader(payloads.response));
  };

  useEffect(() => {
    // Flush all debounced updates on unmount
    return () => {
      debouncedUpdate.flush();
      debouncedThemeUpdate.flush();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <HeaderProperties
      webshop={webshop}
      data={data}
      storefront={storefront}
      theme={theme}
      onChange={onChange}
      onFileCreate={onFileCreate}
      onFileRemove={onFileRemove}
      onThemeChange={onThemeChange}
      onThemeReset={onThemeReset}
    />
  );
};

HeaderPropertiesContainer.propTypes = {
  webshop: PropTypes.object,
  storefront: PropTypes.object,
};

export const HeaderProperties = ({
  webshop = {},
  data = {},
  storefront = {},
  theme = {},
  onChange = () => {},
  onFileCreate = () => {},
  onFileRemove = () => {},
  onThemeChange = () => {},
  onThemeReset = () => {},
}) => {
  const [title, setTitle] = useState(data?.title || "");

  useEffect(() => {
    setTitle(data?.title || "");
  }, [data?.title]);

  return (
    <Box>
      <PropertiesHeader />

      <SectionWrapper title="Styles" onReset={onThemeReset}>
        <StylePicker
          target="header_theme_primary"
          title="Primary"
          presets={theme.presets}
          initialColor={theme.primary}
          onColorChange={(colorObj) => {
            onThemeChange({
              ...colorObj,
              targetColor: COLOR_TYPES.PRIMARY,
            });
          }}
        />
        <BackgroundStylePicker
          target="header_theme_background"
          theme={theme}
          onThemeChange={onThemeChange}
          onFileCreate={onFileCreate}
        />
      </SectionWrapper>

      <SectionWrapper title="Title">
        <Box px="12px" pt="8px" mb="12px">
          <Input
            borderRadius={"10px"}
            value={title}
            placeholder="Store Name"
            onChange={(event) => {
              onChange({
                title: event.target.value,
              });
              setTitle(event.target.value);
            }}
          />
        </Box>
        <StylePicker
          target="header_title_color"
          title="Color"
          presets={theme.presets}
          initialColor={theme.title}
          onColorChange={(colorObj) => {
            onThemeChange({
              ...colorObj,
              targetColor: COLOR_TYPES.TITLE,
            });
          }}
        />
      </SectionWrapper>

      <SectionWrapper title="Logo">
        <StylePicker
          imageOnly
          noOverlay
          target="header_theme_logo"
          title="Image"
          initialImage={(data?.files || [])[0]?.url}
          onImageChange={(file) => {
            onFileCreate({
              ...file,
              target: FILE_TYPES.MAIN,
            });
          }}
          onImageRemove={() => {
            onFileRemove({
              target: FILE_TYPES.MAIN,
            });
          }}
        />
      </SectionWrapper>
    </Box>
  );
};

HeaderProperties.propTypes = {
  data: PropTypes.object,
  theme: PropTypes.object,
  onChange: PropTypes.func,
  onFileCreate: PropTypes.func,
  onFileRemove: PropTypes.func,
  onThemeChange: PropTypes.func,
  onThemeReset: PropTypes.func,
};
