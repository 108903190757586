export const toSnakeCase = (text = "", upperCase = false) => {
  if (!text || typeof text !== "string") return "";
  let snakeCase = text
    .split(/(?=[A-Z])/)
    .join("_")
    .toLowerCase();
  if (upperCase) snakeCase = snakeCase.toUpperCase();
  return snakeCase;
};

export const camelToSplit = (text = "") => {
  if (!text || typeof text !== "string") return "";
  let splitCase = text
    .split(/(?=[A-Z])/)
    .join(" ")
    .toLowerCase();
  return splitCase;
};

export const toHyphenCase = (text = "") => {
  if (!text || typeof text !== "string") return "";
  return toSnakeCase(text).replace(/_/g, "-") || "";
};

export const hypenToCamelCase = (text = "") => {
  if (!text || typeof text !== "string") return "";
  return (
    text.replace(/-([a-z])/g, (g) => {
      return g[1].toUpperCase();
    }) || ""
  );
};

export function snakeToCamelCase(input) {
  if (!input) return;
  const words = input.toLowerCase().split("_");
  const camelCaseString =
    words[0] +
    words
      .slice(1)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join("");
  return camelCaseString;
}

export function addHttpsToUrl(url) {
  if (!url) return "";
  if (!url.startsWith("http")) {
    return `https://${url}`;
  }
  return url;
}

export const domainRules = {
  lessThan256: (v) => !/^.{257,}$/.test(v) || "Be shorter than 256 characters",
  noBeIP: (v) => !/^(?:\d{1,3}\.){3}\d{1,3}$/.test(v) || "Not be an IP address",
  noSpaces: (v) => !/\s/.test(v) || "Have no spaces",
  noSpecialCharacters: (v) => !/[~`!@#$%^&*()=+{}[\]|;:'",<>/?]/.test(v) || "Contain no special characters",
  noStartEndDash: (v) => !/^(-.*|.*-)$/.test(v) || "Not start or end with a '-' character",
};

export function toKebabCase(str) {
  if (!str || typeof str !== "string") return "";
  return str
    .toLowerCase() // Convert to lowercase
    .replace(/\s+/g, "-") // Replace spaces with hyphens
    .replace(/_/g, "-") // Replace underscores with hyphens
    .replace(/[^a-z0-9-]/g, "") // Remove non-alphanumeric characters (excluding hyphens)
    .replace(/(-)+/g, "-") // Replace multiple hyphens with a single hyphen
    .replace(/(^-+)|(-+$)/g, ""); // Remove leading and trailing hyphens
}
