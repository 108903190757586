import { configureConsts, configureActions } from "@utils/redux";

export const BANKING_STATUS = configureConsts("BANKING_STATUS");

const bankingActions = {
  // Returns manual banking status and plaid banking status
  // TODO: See if this can replace the plaid status call, put status
  // for banking in reducer instead of using the custom-hook
  status: configureActions(BANKING_STATUS),
};

export default bankingActions;
