import React from "react";
import styled from "styled-components";

import StateButton from "@common/buttons/state-button/StateButton";

const StyledErrorMessage = styled.div`
  margin-bottom: 16px;
  display: none;

  & > div {
    justify-content: flex-start;
  }

  ${({ show }) =>
    show &&
    `
    display: block;
  `};
`;

export const ErrorMessage = ({ error = "" }) => {
  return (
    <StyledErrorMessage show={Boolean(error)}>
      <StateButton regular state="error" label={error} />
    </StyledErrorMessage>
  );
};
