import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import RegularModal from "@common/modals/regular-modal/RegularModal";
import { BodySmallPlus } from "@components/styled";
import { SanloButton, SanloCheckbox } from "@inputs";
import { hide, acknowledgeBankModalSelector } from "@redux/integrations/bank/acknowledgeBankModalSlice";

import { trackGTMEvent, BANK_ACTION, COMMON_ACTION, INTEGRATION } from "@utils/gtm";
import text from "@utils/text";

export const AcknowledgeBankModal = () => {
  const dispatch = useDispatch();

  const [acknowledgeBankData, setAcknowledgeBankData] = useState(false);
  const { openModal, continueToBanking, continueToDashboard } = useSelector(acknowledgeBankModalSelector);

  const trackEvent = (action) => {
    trackGTMEvent({
      event: `${INTEGRATION.BANK_ACKNOWLEDGE_DATA_MODAL}${action}`,
    });
  };

  const handlerContinueToBanking = (toBanking) => {
    if (toBanking) {
      continueToBanking();
      trackEvent(BANK_ACTION.CONTINUE_TO_BANKING);
    }
    dispatch(hide());
  };

  const handlerContinueToDashboard = () => {
    continueToDashboard();
    trackEvent(BANK_ACTION.CONTINUE_TO_DASHBOARD);
    dispatch(hide());
  };

  return (
    <RegularModal
      width={500}
      isOpen={openModal}
      handleToggle={() => {
        trackEvent(COMMON_ACTION.CLOSE);
        handlerContinueToBanking();
      }}
      title={text.t("bank_provided_info_title")}
      content={
        <>
          <ContentModalWrapper>
            <BodySmallPlus className="bank-info-small-text">{text.t("bank_provided_info_text")}</BodySmallPlus>
            <ul className="bank-info-list">
              <li>
                Securely link all bank accounts that you use to manage your business finances to the Sanlo platform.
              </li>
              <li>
                If you don’t see any of your banks as an option to link, upload the last 6 months of statements for them
                (or as many as are available).
              </li>
            </ul>
            <div className="bank-info-checkbox">
              <SanloCheckbox
                checked={acknowledgeBankData}
                toggleCheckbox={(e, isChecked) => {
                  setAcknowledgeBankData(isChecked);
                  if (isChecked) {
                    trackEvent(BANK_ACTION.CHECK_ACKNOWLEDGE_MODAL_BOX);
                  } else {
                    trackEvent(BANK_ACTION.UNCHECK_ACKNOWLEDGE_MODAL_BOX);
                  }
                }}
                text={`I have provided all banking information for my business.`}
                testId="banking-confirm-checkbox"
              />
            </div>
          </ContentModalWrapper>

          <div className="modal-aside-buttons">
            <SanloButton
              isSecondary
              label="Continue to Banking"
              onClick={() => handlerContinueToBanking(true)}
              style={{ margin: "0 16px 0 0" }}
              testId="continue-to-banking"
            />
            <SanloButton
              isDisabled={!acknowledgeBankData}
              label={"Continue to Dashboard"}
              onClick={handlerContinueToDashboard}
              style={{ width: "auto" }}
              testId="continue-to-dashboard"
            />
          </div>
        </>
      }
    />
  );
};

const ContentModalWrapper = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #c5c5c5;

  .bank-info-small-text {
    width: 416px;
    height: 60px;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  .bank-info-list {
    width: 392px;
    list-style: disc;
    margin-left: 24px;
    li {
      margin-bottom: 16px;
    }
  }

  .bank-info-checkbox {
    width: 412px;
    justify-content: center;
    padding: 18px 0 24px 0;
    border-top: 1px solid ${({ theme }) => theme.grayscale40};
    border-bottom: 1px solid ${({ theme }) => theme.grayscale40};
  }
`;
