import { copy } from "@utils/general";
import { filterIntegrations } from "@utils/integrations";
import { PRODUCT_ANALYTICS } from "@utils/consts";
import { isPendingStatus, isConnectedStatus } from "@utils/status";

import text from "@utils/text";

export const COMPLETE_PROFILE_ROLE_OPTIONS = [
  {
    id: "LEADERSHIP",
    label: "Leadership",
  },
  {
    id: "MARKETING",
    label: "Marketing",
  },
  {
    id: "PRODUCT_MANAGEMENT",
    label: "Product Management",
  },
  {
    id: "DATA_SCIENCE",
    label: "Data Science",
  },
  {
    id: "ENGINEERING",
    label: "Engineering",
  },
  {
    id: "ART_DESIGN",
    label: "Art & Design",
  },
  {
    id: "OTHER",
    label: "Other",
  },
];

export const onboardingTextMap = {
  PRIMARY: {
    inProgress: {
      title: text.t("onboarding_primary_inprogress_title"),
      description: text.t("onboarding_primary_inprogress_desc"),
    },
    completed: {
      title: text.t("onboarding_primary_completed_title"),
      description: text.t("onboarding_primary_completed_desc"),
    },
  },
  SECONDARY: {
    notConnected: {
      title: text.t("onboarding_secondary_notconnected_title"),
      description: text.t("onboarding_secondary_notconnected_desc"),
    },
    connecting: {
      title: text.t("onboarding_secondary_connecting_title"),
      description: text.t("onboarding_secondary_connecting_desc"),
    },
    connected: {
      title: text.t("onboarding_secondary_connected_title"),
      description: text.t("onboarding_secondary_connected_desc"),
    },
  },
};

const checkInstitutions = (institutions) => {
  let connected = false;
  let temp = false;

  for (let i = 0; i < institutions.length; ++i) {
    const { status = "" } = institutions[i];
    if (isPendingStatus(status)) temp = true;
    if (isConnectedStatus(status)) connected = true;
  }

  return {
    connected,
    temp,
  };
};

const checkIntegrations = (integrations, integrationType = null) => {
  let connected = false;
  let temp = false;

  filterIntegrations(integrations).forEach((integration) => {
    const { type = "", status = "" } = integration;
    // Ignore integrations not of the type specified
    if (integrationType && type !== integrationType) return;
    if (isPendingStatus(status)) temp = true;
    if (isConnectedStatus(status)) connected = true;
  });

  return {
    connected,
    temp,
  };
};

export const getRocketProgress = (step, integrations, institutions, onboardingType) => {
  /*
    Rocket Progress Logic:
      Primary Flow (banking and platform required):
        0%: Nothing
        34%: Being on step 2
        67%: Being on step 3 (platform linking)
        84%: One of either banking of platform is connected
        100%: Both banking and platform are connected

      Secondary Flow (platform and ad revenue optional):
        50%: either one has something in progress
        100%: both have something in progress
  */

  // Both flows initialize at 0%
  let connected_progress = 0;
  // The yellow bar that shows for pending connections
  let pending_percentage = 0;

  if (!step)
    return {
      completed: 0,
      pending: 0,
      combined: 0,
    };

  const isPrimary = onboardingType === "PRIMARY";
  const PLATFORMS = "platforms";

  if (isPrimary) {
    // PRIMARY FLOW
    const stepIncrease = 17;

    // Increase progress based on step the user is on
    if (step === 1) connected_progress = 0;
    if (step === 2) connected_progress = 34;
    if (step === 3 || step === "dashboard") {
      connected_progress = 67;
      // Check institutions for pending and connected
      const checkedInstitutions = checkInstitutions(institutions);
      if (checkedInstitutions.temp && !checkedInstitutions.connected) pending_percentage += stepIncrease;
      if (checkedInstitutions.connected) connected_progress += stepIncrease;
      // Check integrations (platforms) for pending and connected
      const checkedPlatforms = checkIntegrations(integrations, PRODUCT_ANALYTICS);
      if (checkedPlatforms.temp && !checkedPlatforms.connected) pending_percentage += stepIncrease;
      if (checkedPlatforms.connected) connected_progress += stepIncrease;
    }
    // Clamp possible pending to 33 to match up with 67% progress
    if (pending_percentage > 33) pending_percentage = 33;
  } else {
    // SECONDARY FLOW
    const stepIncrease = 50;
    // Check platforms for something connected
    const checkedPlatforms = checkIntegrations(integrations, PLATFORMS);
    if (checkedPlatforms.temp && !checkedPlatforms.connected) pending_percentage += stepIncrease;
    if (checkedPlatforms.connected) connected_progress += stepIncrease;
    // Check ad revenue for something connected
    const checkedAdrevenue = checkIntegrations(integrations, PRODUCT_ANALYTICS);
    if (checkedAdrevenue.temp && !checkedAdrevenue.connected) pending_percentage += stepIncrease;
    if (checkedAdrevenue.connected) connected_progress += stepIncrease;
    // Seconday flow should not need to clamp values because of even step increases
  }

  // Clamp all values as a precaution
  if (connected_progress > 100) connected_progress = 100;
  let combined_percentage = connected_progress + pending_percentage;
  if (combined_percentage > 100) combined_percentage = 100;

  const percentages = {
    completed: connected_progress,
    pending: pending_percentage,
    combined: combined_percentage,
  };

  return percentages;
};

export const getSaveProgressPayload = (onboarding, steps, stepChangeData = {}) => {
  if (!onboarding || !steps) return null;
  // Kill the progress call if bad stepChangeData were passed in
  if (stepChangeData && typeof stepChangeData !== "object") return null;

  const { newStep = null, switchingToSecondary = false } = stepChangeData;

  const savePayload = copy(onboarding);
  // Mark the previous step, mainly important for knowing which primary flow
  // step to go back to if exiting the secondary flow
  savePayload.previousStep = onboarding.currentStep;
  // Initialize completion to true and get the current onboarding type
  savePayload.completedModalOnboarding = true;
  savePayload.onboardingType = getOnboardingType(onboarding.currentStep);
  // Data for the step the user is currently on
  const currentStepData = steps[onboarding.currentStep];

  if (newStep !== null) {
    const forward = onboarding.currentStep < newStep && !switchingToSecondary;
    const isCompleteOnboarding = currentStepData.lastStep && forward;
    if (!isCompleteOnboarding) {
      // Secondary flow (step 4) has two options, completing or exiting (back to primary flow)
      // For primary flow we can just accept the new value
      savePayload.currentStep = onboarding.currentStep === 4 ? onboarding.previousStep : newStep;
      savePayload.onboardingType = getOnboardingType(newStep);
    }
    savePayload.completedModalOnboarding = isCompleteOnboarding || false;
  }

  return savePayload;
};

export const getOnboardingType = (step) => {
  // The secondary flow is the last in the list
  return step <= 3 ? "PRIMARY" : "SECONDARY";
};
