import {
  getErrorStatus,
  getRequestStatus,
  getResponseStatus,
  integrationInitialState,
  setStatusDefaults,
  stateSet,
  parseIntegration,
} from "@utils/redux";

import { GET_INTEGRATIONS_CONNECTIONS } from "../base/actions";

import { APP_STORE_SAVE_CREDENTIALS, APP_STORE_UNLINK_INTEGRATION } from "./actions";

const initialState = integrationInitialState({
  id: "appstore",
  name: "App store",
  platformId: "appstore",
  type: "in-app-purchases",
  category: "platforms",
  company: "Apple",
  image: require("@assets/img/icons/logos/AppStoreRoundedLogo.svg").default,
  isPlatform: true,
  isAdPlatform: false,
  requestStatus: {
    getData: setStatusDefaults(),
    unlinkIntegration: setStatusDefaults(),
    saveCredentials: setStatusDefaults(),
  },
  credentials: {
    accountCredentials: "not-set",
  },
  steps: [
    {
      title: "Enter your apple ID & app-specific password",
      subtitle: "Enter your apple ID & app-specific password to establish a connection.",
    },
  ],
});

const appStoreReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INTEGRATIONS_CONNECTIONS.RESPONSE: {
      return {
        ...state,
        ...parseIntegration("appstore", action.response),
        name: state.name, // Keep the display name we have set in the intial state
      };
    }

    case APP_STORE_SAVE_CREDENTIALS.REQUEST: {
      return stateSet(state, {
        "requestStatus.saveCredentials": getRequestStatus(),
      });
    }

    case APP_STORE_SAVE_CREDENTIALS.RESPONSE: {
      return stateSet(state, {
        "requestStatus.saveCredentials": getResponseStatus(),
        "credentials.accountCredentials": "valid",
      });
    }

    case APP_STORE_SAVE_CREDENTIALS.ERROR: {
      return stateSet(state, {
        "requestStatus.saveCredentials": getErrorStatus(),
        "credentials.accountCredentials": "invalid",
      });
    }

    case APP_STORE_UNLINK_INTEGRATION.REQUEST: {
      return stateSet(state, {
        "requestStatus.unlinkIntegration": getRequestStatus(),
      });
    }

    case APP_STORE_UNLINK_INTEGRATION.RESPONSE: {
      return stateSet(state, {
        "requestStatus.unlinkIntegration": getResponseStatus(),
      });
    }

    case APP_STORE_UNLINK_INTEGRATION.ERROR: {
      return stateSet(state, {
        "requestStatus.unlinkIntegration": getErrorStatus(),
      });
    }

    default:
      return state;
  }
};

export default appStoreReducer;
