import React from "react";

import { StepActionsContainer } from "@common/animated/FadeStepsContainer";
import SetupGuide from "@common/modals/integrations-modal/SetupGuide";
import { StyledGuideList } from "@components/common/modals/integrations-modal/StyledSetupGuide";
import { Loader } from "@components/common/loaders";
import { Callout } from "@components/integrations/connection-guide/Callout";
import { SanloButton, SanloInput, Link, Support } from "@inputs";
import { ErrorMessage } from "@components/integrations/connection-guide/ErrorMessage";
import { COMMON_ACTION, ADJUST_ACTION } from "@utils/gtm";

import generalCopies from "@utils/text/general";
import symbols from "@utils/symbols";

export const AdjustStep1 = ({
  platform = {
    id: "adjust",
    name: "Adjust",
    platformId: "adjust",
  },
  apiToken = "",
  setApiToken = () => {},
  testApiToken = () => {},
  trackEvent = () => {},
  requestStatus = {},
  credentialsError = "",
}) => {
  const {
    step_guide_links: {
      adjust: { adjust_step_subscription, adjust_step_here, adjust_step_1_adjust_account, adjust_step_1_api_token },
    },
  } = generalCopies;
  return (
    <StepActionsContainer>
      <div className="fs-modal-actions">
        <ErrorMessage error={credentialsError} />

        <SanloInput
          hasFocus
          isJumbo
          label="API token"
          placeholder="Enter your API token"
          value={apiToken}
          setValue={setApiToken}
          onEnter={() => {
            if (!requestStatus?.testCredentials?.pending) {
              testApiToken();
            }
          }}
          style={{
            marginBottom: "24px",
          }}
          testId={`${platform.platformId}-api-token-input`}
        />

        <SanloButton
          isJumbo
          isDisabled={apiToken.length === 0}
          onClick={() => {
            trackEvent(COMMON_ACTION.CONTINUE);
            testApiToken();
          }}
          label={requestStatus?.testCredentials?.pending ? <Loader className="button-loader" /> : `Continue`}
          gtmEventTag={`tag-sanlo-${platform.platformId}-api-token-continue`}
          testId={`${platform.platformId}-api-token-submit-button`}
        />
      </div>

      <SetupGuide
        platformStep={`${platform.name}0`}
        step={{
          callout: (
            <Callout>
              You may need to have the KPI Service API enabled as part of your {platform.name}{" "}
              <Link
                data-test-id={`${platform.platformId}-step-1-subscription-link`}
                href={adjust_step_subscription}
                className={`modal-link setup-guide-link text-underline text-blue tag-sanlo-${platform.platformId}-subscription-link`}
                isExternalLink
                label="subscription"
                onClick={() => {
                  trackEvent(COMMON_ACTION.SUBSCRIPTION);
                }}
              />
              . Please see instructions{" "}
              <Link
                data-test-id={`${platform.platformId}-step-1-here-link`}
                href={adjust_step_here}
                className={`modal-link setup-guide-link text-underline text-blue tag-sanlo-${platform.platformId}-see-instructions-here-link`}
                isExternalLink
                label="here."
                onClick={() => {
                  trackEvent(COMMON_ACTION.HERE_LINK);
                }}
              />
              <br />
              <br />
              {symbols.LightBulb()} Access to the right set of data provides the most delightful experience for tracking
              User Acquisition Spend. Have questions about Adjust connection?{""}{" "}
              <Support
                red
                className={`tag-sanlo-contact-us-${platform.platformId}-step-1`}
                onClick={() => {
                  trackEvent(COMMON_ACTION.CONTACT_US);
                }}
              />
            </Callout>
          ),
          title: "Copy API token",
          description: (
            <StyledGuideList>
              <li>
                Log into your{" "}
                <Link
                  data-test-id={`${platform.platformId}-step-1-adjust-account-link`}
                  href={adjust_step_1_adjust_account}
                  className={`modal-link setup-guide-link tag-sanlo-${platform.platformId}-onboarding-log-into-account-link`}
                  isExternalLink
                  label="Adjust Account"
                  onClick={() => {
                    trackEvent(ADJUST_ACTION.ADJUST_ACCOUNT);
                  }}
                />
                {"."}
              </li>
              <li>
                Go to{" "}
                <Link
                  data-test-id={`${platform.platformId}-step-1-api-token-link`}
                  href={adjust_step_1_api_token}
                  className={`modal-link setup-guide-link tag-sanlo-${platform.platformId}-onboarding-log-into-api-token-instructions-link`}
                  isExternalLink
                  label="API token instructions"
                  onClick={() => {
                    trackEvent(ADJUST_ACTION.API_TOKEN);
                  }}
                />{" "}
                to see how to find and copy API token.
              </li>
              <li>Paste your API token on the {symbols.PointLeft()}</li>
            </StyledGuideList>
          ),
        }}
      />
    </StepActionsContainer>
  );
};
