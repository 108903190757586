import styled from "styled-components";

import { TitleRegularPlusCSS, BodySmall14CSS, BodySmallPlusCSS } from "@styled";

export const SanloModal = styled.div`
  width: ${({ isJumbo }) => (isJumbo ? "532px" : "480px")};
  background-color: ${({ theme }) => theme.grayscale10};
  padding: 32px 40px 40px 40px;
  border-radius: 12px;
  box-shadow: 0 0 48px 0 rgba(0, 0, 0, 0.4);

  .auth-label-button {
    color: ${({ theme }) => theme.colorRed};
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
  }

  .auth-card-columns-wrapper {
    .auth-card-row {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 24px;

      > * {
        width: 48%;
      }
    }

    .auth-card-columns-hint {
      margin-top: 8px;
      display: flex;
      justify-content: flex-end;
      color: ${({ theme }) => theme.textGray};
    }
  }
`;

export const SanloModalTitle = styled.div`
  ${TitleRegularPlusCSS}
  color: ${({ theme }) => theme.textSeashellWhite};
`;

export const SanloModalDescription = styled.div`
  ${BodySmall14CSS}
  margin-top: 8px;
  color: ${({ theme }) => theme.textGray};
`;

export const SanloModalContent = styled.div`
  margin-top: 24px;
  .auth-card-note {
    background-color: rgba(245, 54, 92, 0.1);
    color: ${({ theme }) => theme.colorRed};
    padding: 0.5rem 0.75rem;
    border-radius: 0.5rem;
    margin-bottom: 24px;
  }
`;

export const SanloModalDivider = styled.div`
  height: 0;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.grayscale40};
  margin: 24px 0;
`;

export const SanloModalFooter = styled.div`
  ${BodySmallPlusCSS}
  color: ${({ theme }) => theme.textSubtext};
  ${({ center }) =>
    center &&
    `
    text-align: center;
  `};
`;

export const Highlight = styled.span`
  font-weight: 500;
  color: ${({ theme }) => theme.textSeashellWhite};
`;
