export const getEndOfPeriodResponse = (error = {}) => {
  try {
    if (error.response.data.message.match(/There are no/)) {
      return "no_more_periods";
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
};

export const getMonthlyPeriods = () => {
  const date = new Date();
  const months = [];
  // Current month
  months.push(`${date.getFullYear()}-${date.getMonth() + 1}`);
  // Previous Month
  date.setDate(1);
  date.setMonth(date.getMonth() - 1);
  months.push(`${date.getFullYear()}-${date.getMonth() + 1}`);
  // Previouser Month
  date.setDate(1);
  date.setMonth(date.getMonth() - 1);
  months.push(`${date.getFullYear()}-${date.getMonth() + 1}`);
  return months.reverse().join(",");
};

export const getPreviousMonthlyPeriods = (monthlyPeriods) => {
  const earliestPeriodSplit = monthlyPeriods.split(",")[0].split("-");
  const year = parseInt(earliestPeriodSplit[0]);
  const month = parseInt(earliestPeriodSplit[1]);
  const date = new Date();
  date.setDate(1);
  // Minus 2, -1 to get back to the same zero as JS dates, and -1 again for previous
  date.setFullYear(year);
  date.setMonth(month - 2);
  return `${date.getFullYear()}-${date.getMonth() + 1}`;
};
