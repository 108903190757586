import styled from "styled-components";

export const StyledDailyRevenueSelector = styled.div`
  --option-height: 2.5rem;
  --option-width: 15.125rem;
  --selected-border: 0.125rem;

  ${({ isCollapsed }) =>
    isCollapsed &&
    `
    --option-width: 4rem;
  `};

  grid-area: selector;
  border-bottom: 2px solid ${({ theme }) => theme.grayscale50};
  margin-right: 1.5rem;

  .ps-wrapper {
    height: var(--option-height);
    width: var(--option-width);

    .ps-select-all {
      display: flex;
      height: var(--option-height);
      width: var(--option-width);
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem 1rem;

      > span {
        white-space: nowrap;
      }
    }
  }

  .color-red {
    color: ${({ theme }) => theme.colorRed};
    svg {
      fill: ${({ theme }) => theme.colorRed};
    }
  }

  .color-yellow {
    color: ${({ theme }) => theme.colorYellow};
    svg {
      fill: ${({ theme }) => theme.colorYellow};
    }
  }
`;

export const StyledSearch = styled.div`
  position: relative;
  padding-top: 0.5rem;
  padding-bottom: 0.25rem;
  padding-left: 2rem;
  border-bottom: 2px solid ${({ theme }) => theme.grayscale40};
  cursor: default !important;

  .search-icon {
    width: 1rem;
    height: 1rem;
    position: absolute;
    left: 0.6rem;
    bottom: 0.5rem;

    &:before {
      content: "";
      display: block;
      height: 1rem;
      width: 1rem;
      border-radius: 100%;
      border: 1px solid rgba(255, 255, 255, 0.5);
    }

    &:after {
      content: "";
      display: block;
      height: 0.25rem;
      width: 1px;
      position: absolute;
      right: 0;
      bottom: 0;
      background-color: rgba(255, 255, 255, 0.5);
      transform: rotateZ(-45deg);
    }
  }

  .ps-search-input {
    background-color: transparent;
    margin: 0;
    border: 0;
    color: white;
    cursor: default;
  }
`;

export const StyledOption = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: var(--option-height);
  margin-top: 0.5rem;
  border-radius: 0.5rem;
  line-height: var(--option-height);
  margin-bottom: 0.5rem;

  .ps-option-icon {
    --icon-size: 1.5rem;
    --icon-padding: calc((var(--option-height) - var(--icon-size)) / 2);
    height: var(--icon-size);
    width: var(--icon-size);
    margin: var(--icon-padding);
    display: inline-block;
    vertical-align: top;
    border-radius: 0.25rem;
    background-color: rgba(0, 0, 0, 0.2);
  }

  .ps-option-subtitle {
    display: inline-flex;
    flex-direction: row;
    margin-top: 0.1rem;
    align-items: center;

    svg {
      height: 0.85rem;
      width: 0.85rem;
      margin-right: 0.2rem;
    }
  }

  ${({ isCollapsed }) =>
    isCollapsed &&
    `
    padding-left: 0.45rem;
  `};

  ${({ isDisabled }) =>
    isDisabled &&
    `
    color: rgba(255, 255, 255, 0.6);
  `};

  ${({ isDisabled, theme }) =>
    !isDisabled &&
    `
    cursor: pointer;
    &:hover {
      background-color: ${theme.grayscale40};
    }
  `};

  ${({ isSelected, theme }) =>
    isSelected &&
    `
    color: rgba(2, 2, 2, 1);
    background: ${theme.colorRed};
    &:hover {
      background: ${theme.colorRed};
    }
  `};

  ${({ isLoading }) =>
    isLoading &&
    `
    color: transparent;
    background-color: gray;
    * {
      visibility: hidden;
    }
  `};

  ${({ isRed, theme }) =>
    isRed &&
    `
    color: ${theme.colorRed};
    svg {
      fill: ${theme.colorRed};
    }
  `};
`;

export const StyledOptionTitle = styled.div`
  display: inline-flex;
  vertical-align: top;
  line-height: inherit;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ isSelected }) =>
    isSelected &&
    `
    margin-left: 0.5rem;
  `};
`;

export const StyledSelectedOption = styled(StyledOption)`
  background-color: ${({ theme }) => theme.grayscale40};
  width: var(--option-width);
  border: var(--selected-border) solid transparent;
  color: white;

  &:hover {
    border: var(--selected-border) solid ${({ theme }) => theme.grayscale70};
  }

  ${({ isFocused, theme }) =>
    isFocused &&
    `
    border: var(--selected-border) solid ${theme.colorRed};
  `};

  .ps-option-icon {
    --icon-size: 2rem;
    --icon-padding: calc(((var(--option-height) - (2 * var(--selected-border))) - var(--icon-size)) / 2);
    height: var(--icon-size);
    width: var(--icon-size);
    margin-left: var(--icon-padding);
    border-radius: 0.25rem;
  }
`;

export const StyledOptions = styled.div`
  max-height: 25.5rem;
  position: relative;
  background: ${({ theme }) => theme.grayscale20};
  box-shadow: 0px 0px 3rem rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  z-index: 10;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-right: 1rem;

  &::-webkit-scrollbar {
    height: 0;
    width: 0.5rem;
    cursor: default !important;
  }

  &::-webkit-scrollbar-track {
    visibility: hidden;
    background-color: ${({ theme }) => theme.inputBackgroundColor};
    opacity: 0.5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(51, 51, 56, 1);
    border-radius: 1.25rem;
    opacity: 0.5;
  }

  display: none;
  ${({ isVisible }) =>
    isVisible &&
    `
    display: block;
  `};
`;

export const StyledCategoryHeader = styled.div`
  display: flex;
  align-items: center;
  height: var(--option-height);
  cursor: pointer;
  background: ${({ theme }) => theme.grayscale20};
  border-radius: 0.5rem;
  position: relative;
  padding-left: 0.5rem;

  .emoji {
    padding-right: 0.75rem;
  }

  ${({ isOpen, theme }) =>
    isOpen &&
    `
    background: ${theme.grayscale40};
  `};
`;

export const StyledCategory = styled.div`
  padding: 0.5rem 0;

  ${({ isCollapsed }) =>
    isCollapsed &&
    `
    padding-left: 0;
  `};
`;
