import React from "react";
import AdvanceRow from "./AdvanceRow";
import { alphaTrim } from "@utils/general";

const AdvanceList = ({ advances, advancePlatforms }) => {
  return (
    <div className="advance-list-container">
      {advances.map((advance) => {
        const advanceId = "id" + alphaTrim(advance.createdAt);
        return <AdvanceRow advancePlatforms={advancePlatforms} key={advanceId} id={advanceId} advance={advance} />;
      })}
    </div>
  );
};

export default AdvanceList;
