import { copy } from "@utils/general";

import { BUILDER_SECTION_TYPES } from "@src/consts/webshops/builder";
export const SECTION_TYPE = BUILDER_SECTION_TYPES.HEADER;

export const generatePayloads = ({ data, updates }) => {
  const payload = {
    ...copy(data),
    type: SECTION_TYPE,
    ...copy(updates),
  };

  const requiredDefaults = {
    type: SECTION_TYPE,
    title: "",
  };

  const request = {};
  Object.keys(requiredDefaults).forEach((key) => {
    if (Object.hasOwn(payload, key)) {
      request[key] = payload[key];
    } else {
      request[key] = requiredDefaults[key];
    }
  });

  return copy({
    request,
    response: payload,
  });
};
