import HTTP from "@redux/http";

const SANLO_WEB_API_URL = process.env.REACT_APP_SANLO_WEB_API_URL;
const BASE = `${SANLO_WEB_API_URL}/api/integrations/marketing/adjust`;

export default {
  adjustGetDraftCredentials: () => {
    return HTTP.req.get(`${BASE}/credentials/draft`);
  },

  adjustSaveDraftCredentials: (credentials) => {
    return HTTP.req.post(`${BASE}/save-credentials/draft`, credentials);
  },

  adjustTestCredentials: (credentials) => {
    return HTTP.req.post(`${BASE}/credentials/test`, credentials);
  },

  adjustSaveCredentials: (credentials) => {
    return HTTP.req.post(`${BASE}/save-credentials`, credentials);
  },

  adjustDisconnectIntegration: () => {
    return HTTP.req.delete(`${BASE}/delete-credentials`);
  },

  adjustGetUASpend: () => {
    return HTTP.req.get(`${BASE}/ua-spend`);
  },
};
