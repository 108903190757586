import React, { useEffect, useState, useRef } from "react";

import { BodyRegular16, BodySmallPlus, TitleLargePlus } from "@components/styled";
import StyledPartnerLandingPage from "./PartnerLandingPage.styled";
import EmojiPlaceholder from "@components/onboarding/EmojiPlaceholder";
import PlatformFeatureCard from "@components/onboarding/platform-feature-card/PlatformFeatureCard";
import AuthenticationContainer from "../authentication/AuthenticationContainer";

import symbols from "@utils/symbols";
import text from "@utils/text";
import { getBrandingInfo, removeBrandingInfo } from "@utils/branding";

import { ReactComponent as CheckCircleIcon } from "@assets/img/icons/common/check_circle.svg";

const PartnerLandingPage = () => {
  const featuresRef = useRef();
  const featuresSet = useRef(false);

  const [showCard, setShowCard] = useState(false);

  const { clientId, isBranded, fromMarketing } = getBrandingInfo();

  useEffect(() => {
    if (fromMarketing) removeBrandingInfo();
  }, [fromMarketing]);

  // Add delay for each feature card
  useEffect(() => {
    if (featuresRef.current && !featuresSet.current) {
      featuresSet.current = true;
      const cards = featuresRef.current.children;
      Array.from(cards).forEach((card, index) => {
        card.style.animationDelay = 0.35 * index + 1.75 + "s";
      });
    }
    // eslint-disable-next-line
  }, [featuresRef.current, showCard]);

  let buttonTextKey = "get_started";
  if (isBranded && clientId) {
    const hasStarted = localStorage.getItem(`${clientId}-started`);
    if (hasStarted) buttonTextKey = "continue";
  }

  return (
    <AuthenticationContainer
      partnerIntro={true}
      onShowChildren={() => {
        setShowCard(true);
      }}
    >
      {showCard && (
        <StyledPartnerLandingPage>
          <div
            className="partner-landing-card animated"
            style={{
              width: "460px",
            }}
          >
            <TitleLargePlus className="partner-landing-card-headline">
              {text.t("get_financing_in_minutes")}
            </TitleLargePlus>
            <BodyRegular16 className="ob-card-description">
              {text.t("sanlo_makes_a_decision")}{" "}
              <a
                className="support-link-red"
                target="_blank"
                href="https://www.sanlo.ai/#faq"
                rel="noopener noreferrer"
              >
                {text.t("learn_more")}
              </a>
            </BodyRegular16>

            <div className="steps-container">
              <div className="step step-item-1">
                <CheckCircleIcon className="step-marker" />
                <BodyRegular16 className="ml_half">{text.t("sync_your_data")}</BodyRegular16>
              </div>
              <div className="step-spacer step-item-2" />
              <div className="step step-item-3">
                <CheckCircleIcon className="step-marker" />
                <BodyRegular16 className="ml_half">{text.t("review_your_offer")}</BodyRegular16>
              </div>
              <div className="step-spacer step-item-4" />
              <div className="step step-item-5">
                <CheckCircleIcon className="step-marker" />
                <BodyRegular16 className="ml_half">{text.t("get_funded")}</BodyRegular16>
              </div>
            </div>

            <div
              className="get-started-button"
              onClick={() => {
                window.location = window.location.origin + "?sanlo_flow=signup";
              }}
            >
              <span className="button-label">{text.t(buttonTextKey)}</span>
            </div>

            <div className="divider" />

            <BodySmallPlus className="analytics-message">
              {text.t("already_have_an_account")}
              <a className="support-link-red ml_half" href={`${window.location.origin}?sanlo_flow=login`}>
                {text.t("log_in")}
              </a>
            </BodySmallPlus>
          </div>

          <div ref={featuresRef} className="platform-features-container">
            {[
              {
                icon: <EmojiPlaceholder color="#66C8FF29" emoji={symbols.ShakingHand()} />,
                title: text.t("transparent_pricing"),
                description: text.t("choose_between_revenue_and_fixed"),
              },
              {
                icon: <EmojiPlaceholder color="#FFD73529" emoji={symbols.MoneyBag()} />,
                title: text.t("fast_capital"),
                description: text.t("get_the_funding_you_need"),
              },
              {
                icon: <EmojiPlaceholder color="#2DFAB029" emoji={symbols.Seeding()} />,
                title: text.t("growth_acceleration"),
                description: text.t("get_more_funding_as_you_scale"),
              },
            ].map((feature, index) => (
              <PlatformFeatureCard
                key={`platform-feature-${feature.title}-${index}`}
                className="partner-landing-feature-card"
                animated
                animationDuration={"0.5s"}
                feature={feature}
                regular
              />
            ))}
          </div>
        </StyledPartnerLandingPage>
      )}
    </AuthenticationContainer>
  );
};

export default PartnerLandingPage;
