import React from "react";
import classnames from "classnames";

import { StyledPeriodPicker } from "./DailyRevenueDisplayStyles";
import { BodySmallPlus } from "@styled";

import { areGamesSelected } from "../../utils/dailyRevenueUtils";
import { isConnectedStatus } from "@utils/status";

import { ReactComponent as ArrowIcon } from "@assets/img/icons/common/accordion-arrow.svg";

const PeriodPicker = ({ selectedOption, selectedOptionGameData, isLoading, periodPicker, handlePeriodChange }) => {
  return (
    <StyledPeriodPicker>
      <div
        className={classnames("period-arrow", {
          disabled:
            periodPicker.prev ||
            !areGamesSelected(selectedOptionGameData) ||
            (!isConnectedStatus(selectedOption) && !selectedOption.isAll),
        })}
        onClick={() => !periodPicker.prev && handlePeriodChange(-1)}
      >
        <ArrowIcon className="prev-p" />
      </div>

      <div
        className={classnames("period-actual no-text-select", {
          "disabled":
            !areGamesSelected(selectedOptionGameData) || (!isConnectedStatus(selectedOption) && !selectedOption.isAll),
          "dr-loading": isLoading,
        })}
        onClick={() => handlePeriodChange(0)}
      >
        <BodySmallPlus>Today</BodySmallPlus>
      </div>

      <div
        className={classnames("period-arrow", {
          disabled:
            periodPicker.next ||
            !areGamesSelected(selectedOptionGameData) ||
            (!isConnectedStatus(selectedOption) && !selectedOption.isAll),
        })}
        onClick={() => !periodPicker.next && handlePeriodChange(1)}
      >
        <ArrowIcon className="next-p" />
      </div>
    </StyledPeriodPicker>
  );
};

export default PeriodPicker;
