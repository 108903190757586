import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button, Icon, Text, HStack, VStack, Spinner, Flex } from "@chakra-ui/react";
import { FileDrop } from "@common/upload/FileDrop";
import { builderStorefrontSelector, builderThunks } from "@redux/webshops/builder/builderSlice";
import { BaseCard } from "./BaseCard";
import { ReactComponent as TrashIcon } from "@assets/img/icons/common/trash.svg";

import DefaultSocialSharingImage from "@assets/img/webshops/default-social-sharing.png";
import DefaultFavicon from "@assets/img/webshops/default-app-icon.png";
import { SETTINGS_IMAGES_TYPE } from "@src/consts/webshops/builder";
import { FILE_TYPES, getImage } from "@utils/webshop/section";
import { useParams } from "react-router-dom";
import ErrorBoundary from "@components/ErrorBoundary";

import PropTypes from "prop-types";

const SETTINGS_IMAGES = {
  [SETTINGS_IMAGES_TYPE.FAVICON]: {
    id: "favicon",
    title: "Favicon",
    label: "favicon",
    fileType: FILE_TYPES.FAVICON,
    description: "Appears in your browser tab, helping your players easily navigate on the web",
    recommendedSize: "32px x 32px",
    defaultImage: DefaultFavicon,
  },
  [SETTINGS_IMAGES_TYPE.SOCIAL_SHARING_IMAGE]: {
    id: "social-images",
    title: "Social Sharing Image",
    label: "socialSharingImage",
    fileType: FILE_TYPES.SOCIAL,
    description: "Appears when your webshop is shared on social media, helping your players easily navigate on the web",
    recommendedSize: "1200px x 630px",
    defaultImage: DefaultSocialSharingImage,
  },
};

export const SiteImages = () => {
  const storefront = useSelector(builderStorefrontSelector);
  const faviconImage = getImage(FILE_TYPES.FAVICON, storefront.files);
  const socialImage = getImage(FILE_TYPES.SOCIAL, storefront.files);
  const { id } = useParams();

  return (
    <BaseCard title={"Site images"} id={"settings-site-images"}>
      <VStack gap={"16px"}>
        {/* Social Sharing Image */}
        <ImageDetailsCard type={SETTINGS_IMAGES_TYPE.SOCIAL_SHARING_IMAGE} image={socialImage} webshopId={id} />

        {/* Favicon */}
        <ImageDetailsCard type={SETTINGS_IMAGES_TYPE.FAVICON} image={faviconImage} webshopId={id} />
      </VStack>
    </BaseCard>
  );
};

const ImageDetailsCard = ({ type, image, webshopId }) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    actionStatus: null,
    showError: false,
    hovered: false,
  });

  const updateState = (newState) => setState((prevState) => ({ ...prevState, ...newState }));

  const handlerHover = (isHover) => {
    if (state.actionStatus) return;
    updateState({ hovered: isHover });
  };

  const handlerRemove = async () => {
    try {
      updateState({
        actionStatus: "removing",
        showError: false,
      });
      await dispatch(builderThunks.deleteFile({ fileId: image.id, webshopId })).unwrap();
    } catch (error) {
      updateState({
        showError: true,
      });
    } finally {
      updateState({
        actionStatus: null,
      });
    }
  };

  const handlerChangeImage = async (event) => {
    const files = event.dataTransfer?.files || event.target.files;
    if (files.length > 0) {
      try {
        updateState({
          actionStatus: "loading",
          showError: false,
        });
        const formData = new FormData();
        formData.append("file", files[0]);
        formData.append(
          "metadata",
          new Blob(
            [
              JSON.stringify({
                type: "GLOBAL",
                target: SETTINGS_IMAGES[type].fileType,
              }),
            ],
            { type: "application/json" },
          ),
        );

        await dispatch(builderThunks.createFile({ data: formData, webshopId })).unwrap();
      } catch (error) {
        updateState({
          showError: true,
        });
      } finally {
        updateState({
          actionStatus: null,
        });
      }
    }
    return null;
  };

  return (
    <VStack
      p={"16px"}
      gap={"16px"}
      alignItems={"flex-start"}
      alignSelf={"stretch"}
      borderRadius={"10px"}
      border={"1px solid"}
      borderColor={"dark.300"}
    >
      <HStack gap={"16px"} alignItems={"center"} justifyContent={"center"}>
        <VStack flex={1} justifyContent={"space-between"} alignItems={"flex-start"} alignSelf={"stretch"}>
          <VStack alignItems={"flex-start"} gap={"4px"}>
            <Text size={"small"} letterSpacing={"0.14px"} fontWeight={600} color={"textWhite.400"}>
              {SETTINGS_IMAGES[type].title}
            </Text>
            <Text lineHeight={"20px"} letterSpacing={"0.14px"} color={"textSubtext.400"}>
              {SETTINGS_IMAGES[type].description}
            </Text>
          </VStack>
          <Button
            height={"32px"}
            colorScheme={"primary"}
            leftIcon={<Icon as={TrashIcon} fill={"textWhite.400"} w={"16px"} h={"16px"} />}
            isDisabled={!image.id || state.actionStatus === "removing"}
            isLoading={state.actionStatus === "removing"}
            onClick={async () => {
              await handlerRemove();
            }}
            data-test-id={`builder-settings-${SETTINGS_IMAGES[type].id}-remove-button`}
          >
            Remove
          </Button>
        </VStack>

        <VStack alignItems={"center"} alignSelf={"stretch"} gap={"4px"} rounded={"8px"}>
          <VStack
            w={"240px"}
            h={"128px"}
            alignItems={"center"}
            justifyContent={"center"}
            borderRadius={"10px"}
            bg={"dark.500"}
            pos={"relative"}
            bgImage={image?.url ? `url(${image.url})` : `url(${SETTINGS_IMAGES[type].defaultImage})`}
            bgPos={"center"}
            onMouseEnter={() => handlerHover(true)}
            onMouseLeave={() => handlerHover(false)}
            bgSize={type === SETTINGS_IMAGES_TYPE.FAVICON ? "32px 32px" : "cover"}
            p={type === SETTINGS_IMAGES_TYPE.FAVICON && "32px 0px"}
            bgRepeat={type === SETTINGS_IMAGES_TYPE.FAVICON && "no-repeat"}
            cursor={state.actionStatus === "loading" ? "not-allowed" : "pointer"}
          >
            <ErrorBoundary show={state.actionStatus !== "loading"}>
              <FileDrop
                onChange={async (event) => handlerChangeImage(event)}
                data-test-id={`builder-settings-${SETTINGS_IMAGES[type].id}-file-input`}
              />
            </ErrorBoundary>

            {state.hovered && (
              <Box py="16px" px="24px" bg="rgba(33, 33, 38, 0.78)" borderRadius={"8px"}>
                <Text color="textWhite.400" fontWeight={500} size="regular">
                  Change image
                </Text>
              </Box>
            )}
            {state.actionStatus === "loading" && (
              <Flex
                py={"16px"}
                px={"24px"}
                justifyContent={"center"}
                alignItems={"center"}
                borderRadius={"8px"}
                bg="rgba(33, 33, 38, 0.78)"
              >
                <Spinner size={"sm"} />
              </Flex>
            )}
          </VStack>
          <Text size={"mini"} letterSpacing={"0.24px"} color={"textSubtext.400"}>
            Recommended size: {SETTINGS_IMAGES[type].recommendedSize}
          </Text>
        </VStack>
      </HStack>
      {state.showError && (
        <Text size={"mini"} letterSpacing={"0.24px"} color={"brandRed.400"}>
          Something went wrong. Please try again.
        </Text>
      )}
    </VStack>
  );
};

ImageDetailsCard.propTypes = {
  type: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  webshopId: PropTypes.string.isRequired,
};
