import React from "react";
import styled from "styled-components";

export const StyledBetaTag = styled.span`
  padding: 4px 6px;
  width: 35px;
  height: 16px;
  background: rgba(130, 128, 255, 0.16);
  border-radius: 4px;
  position: relative;
  display: inline-block;
  vertical-align: top;

  span {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 8px;
    letter-spacing: 0.02em;
    background: linear-gradient(268.8deg, #4a48ff 0.08%, #a4adf8 103.54%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    position: absolute;
    top: 4px;
    left: 6px;
  }
`;

export const BetaTag = ({ style }) => {
  return (
    <StyledBetaTag style={style}>
      <span>Beta</span>
    </StyledBetaTag>
  );
};

export default BetaTag;
