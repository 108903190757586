import moment from "moment";

import symbols from "@utils/symbols";
import { copy, objSort, alphaTrim } from "@utils/general";
import { currencyFormatCentsData } from "@utils/currency";
import { isPullingStatus, isConnectedStatus, isNotConnectedStatus, CONNECTED } from "@utils/status";
import tooltipsCopies from "@utils/text/cashEarningsChart";

export const OPTIONS_SCHEMA = {
  isNothingConnected: false,
  isPullingData: false,
  all: [],
  allViableIDs: [],
  allViableIDsString: "",
  map: {},
  mobilePlatforms: [],
  platforms: [],
  allOption: {
    id: "all",
    type: "all",
    name: "connected platforms",
    displayName: "All Platforms",
    isAll: true,
  },
  noneOption: {
    id: "none",
    type: "none",
    name: "Select Platform",
    isNone: true,
  },
};

const UPCOMING_MESSAGE = {
  EXPENSE: {
    soon: "Payment due",
    inProgress: "Payment due",
  },
  PAYOUT: {
    soon: "Revenue payout",
    inProgress: "Revenue in-progress",
  },
};

export const PAYOUT = "PAYOUT";
export const EXPENSE = "EXPENSE";

const getCardDate = (onlyPayout, platform, displayDate) => {
  let date;

  if (onlyPayout) {
    date = platform.isPlatform ? `To be paid on ${displayDate}` : `Est. to be paid on ${displayDate}`;
  } else {
    date = `To be paid on ${displayDate}`;
  }

  return date;
};

export const getTooltipInfo = (onlyPayout, platform, type, inProgress) => {
  if (onlyPayout && !platform.isPlatform) {
    return inProgress
      ? tooltipsCopies.upcomingPayments.inProgress(platform)
      : tooltipsCopies.upcomingPayments.paidSoon(platform);
  }

  if (type === PAYOUT) {
    return inProgress
      ? tooltipsCopies.upcomingPayments.payoutInProgress
      : tooltipsCopies.upcomingPayments.payoutPaidSoon;
  }

  return inProgress
    ? tooltipsCopies.upcomingPayments.expenseInProgress(platform)
    : tooltipsCopies.upcomingPayments.expensePaidSoon(platform);
};

const getCardMessage = (onlyPayout, type, inProgress) => {
  let message;

  if (onlyPayout) {
    message = inProgress ? "Earnings in-progress" : "You'll be paid soon";
  } else {
    message = inProgress ? UPCOMING_MESSAGE[type].inProgress : UPCOMING_MESSAGE[type].soon;
  }

  return message;
};

export const getUpcomingPaymentCardData = (upcomings = [], filter = []) => {
  let cardData = [];
  const today = moment().startOf("day").format("YYYY-MM-DD");
  const onlyPayout = !!filter.find((elem) => elem === PAYOUT);

  upcomings.forEach((upcomingPayment) => {
    const { platform: upcomingPlatform, period, amountCents, date: payDate, type, dataSourceStatus } = upcomingPayment;

    const { id, startDate, endDate, inProgress } = period;

    if (!type) return;
    if (!payDate || payDate < today) return;

    const platform = {
      ...upcomingPlatform,
      isPlatform: type === "MOBILE_PLATFORMS",
    };
    const amount =
      !onlyPayout && type === PAYOUT
        ? `+${currencyFormatCentsData(amountCents).formattedAmount}`
        : currencyFormatCentsData(amountCents).formattedAmount;
    const moneyIcon = onlyPayout || type === EXPENSE ? symbols.flyingMoney : symbols.moneyBag;

    const displayDate = moment(payDate).format("MMM Do");
    const periodStart = moment(startDate).format("MMM Do ’YY");
    const periodEnd = moment(endDate).format("MMM Do ’YY");

    cardData.push({
      id: `card-${alphaTrim(id)}`,
      inProgress,
      periodDataSourceStatus: dataSourceStatus,
      amount,
      platform,
      type,
      moneyIcon,
      message: getCardMessage(onlyPayout, type, inProgress),
      upcomingDate: getCardDate(onlyPayout, platform, displayDate),
      tooltipInfo: getTooltipInfo(onlyPayout, platform, type, inProgress),
      periodId: id,
      periodDate: { periodStart, periodEnd },
      filter,
    });
  });

  objSort(cardData, "date");
  if (onlyPayout) cardData = cardData.filter((card) => card.type === PAYOUT);

  return cardData;
};

export const getDailyRevenueOptions = (integrations) => {
  // Some base information about the platforms in general
  let isNothingConnected = true;
  let isPullingData = false;
  let isOneConnected = false;

  const addPlatformDetails = (platform) => {
    const { status, isAdPlatform } = platform;

    // All non-ad platforms are always shown
    // Ad platforms are shown if none are connected or there is an issue with one.
    // If ad networks are coming through don't show either ad network option.
    const isVisible = !isAdPlatform || (isAdPlatform && status !== CONNECTED);

    if (isConnectedStatus(platform)) {
      isOneConnected = true;
      isPullingData = false;
    }
    if (!isNotConnectedStatus(platform)) {
      isNothingConnected = false;
    }
    if (isPullingStatus(platform) && !isOneConnected) {
      isPullingData = true;
    }

    return {
      ...platform,
      type: "platform",
      isVisible,
    };
  };

  const platformsMap = {
    appstore: addPlatformDetails(integrations.appstore),
    googleplay: addPlatformDetails(integrations.googleplay),
  };
  const { appstore, googleplay } = platformsMap;

  const all = [appstore, googleplay];

  const allViableIDs = [];
  all.forEach((platform) => {
    const { id, platformId, isVisible, isPlatform, isAdNetwork } = platform;
    if (!isVisible) return;
    if (isPlatform && isConnectedStatus(platform)) {
      allViableIDs.push(id);
    } else if (isAdNetwork) {
      allViableIDs.push(platformId);
    }
  });

  const outputData = {
    ...copy(OPTIONS_SCHEMA),
    isNothingConnected,
    isPullingData,
    all: all,
    allViableIDs: allViableIDs,
    allViableIDsString: allViableIDs.join(","),
    map: platformsMap,
    mobilePlatforms: [appstore, googleplay],
    platforms: [appstore, googleplay],
    allOption: {
      id: "all",
      type: "all",
      name: "connected platforms",
      displayName: "All Platforms",
      isAll: true,
    },
    noneOption: {
      id: "none",
      type: "none",
      name: "Select Platform",
      isNone: true,
    },
  };

  return outputData;
};

export const getFullOptions = (integrations, integrationsStatus) => {
  try {
    // We want to build our complete list of options when all relevant items have been fetched
    if (integrationsStatus.completed) {
      const newList = getDailyRevenueOptions(integrations);
      return {
        ...newList,
        isReady: true,
      };
    }
    return null;
  } catch (e) {
    return null;
  }
};

export const validateLocalStorage = (platformId, earningsData, user) => {
  // If all the earnings have loaded, do a quick pass through each of them and check to see
  // if the localStorage data is still valid, if there is a mismatch in the games we want to
  // clear the localStorage as a precaution

  const chartStorageData = localStorage.getItem("chartGameOrder") || "{}";
  const parsedChartStorageData = JSON.parse(chartStorageData);
  const userStorage = parsedChartStorageData[user.userId];
  if (!userStorage) return;

  const gameData = userStorage[platformId];
  // There is nothing saved for this platform and nothing to validate
  if (!gameData) return;

  // The earnings call completed (success or error) and there is no data, kill the storage
  const periodEarnings = earningsData.earnings.periodEarnings || [];
  if (!periodEarnings?.length) {
    delete parsedChartStorageData[user.userId][platformId];
    localStorage.setItem("chartGameOrder", JSON.stringify(parsedChartStorageData));
    return;
  }

  // Create a map of all the games from the earnings response to compare
  // to what is in the storage
  const gamesMap = {};
  periodEarnings.forEach((period) => {
    const { earningsByGame = [] } = period;
    earningsByGame.forEach((game) => {
      gamesMap[game.gameName] = true;
    });
  });

  const gamesList = Object.keys(gamesMap);
  const savedGamesList = gameData.order || [];

  // If the list is a different length, its different enough to reset the storage
  if (gamesList.length !== savedGamesList.length) {
    delete parsedChartStorageData[user.userId][platformId];
    localStorage.setItem("chartGameOrder", JSON.stringify(parsedChartStorageData));
    return;
  }

  // Otherwise they're the same and we need to compare games in the list
  let mismatch = false;
  savedGamesList.forEach((savedGame) => {
    if (!gamesMap[savedGame.gameName]) mismatch = true;
  });

  // The games in both lists didn't match up, so we need to reset the storage
  if (mismatch) {
    delete parsedChartStorageData[user.userId][platformId];
    localStorage.setItem("chartGameOrder", JSON.stringify(parsedChartStorageData));
  }

  // Getting to this point means the earnings response had data and the games in it
  // matched what was in the localStorage, so we don't need to do anything
};
