import { Box, FormControl, FormErrorMessage, FormLabel, HStack, Icon, Input, Text, VStack } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { ReactComponent as AlertCircle } from "@assets/img/icons/common/alertCircle.svg";
import { authMethodsSelector } from "@redux/webshops/webshop/settings/auth-methods/authMethodsSlice";
import { useSelector } from "react-redux";
import { RedirectUri } from "./components/RedirectUri";
import { AUTH_METHODS } from "@src/consts/webshops/settings";

export function FacebookForm() {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { authProvidersMap } = useSelector(authMethodsSelector);
  const authProvider = authProvidersMap[AUTH_METHODS.FACEBOOK];

  return (
    <VStack alignItems={"stretch"} gap="32px">
      <FormControl isInvalid={errors.clientId}>
        <FormLabel fontSize={"14px"}>App ID</FormLabel>
        <Input {...register("clientId", { required: true })} />
        {errors.appId && <FormErrorMessage>This field is required</FormErrorMessage>}
      </FormControl>
      {authProvider?.data && (
        <VStack gap="16px">
          <HStack bg="transparents.yellow" p="12px" borderRadius={"10px"} gap={"8px"} alignItems={"center"}>
            <Icon as={AlertCircle} color="brandYellow.400" w="16px" h="16px" />
            <Box>
              <Text color="brandYellow.400" size={"small"} fontWeight={500} lineHeight={"20px"}>
                PLEASE NOTE:
              </Text>
              <Text color={"textWhite.400"} size={"small"} lineHeight={"20px"}>
                To complete set up, add the Sanlo OAuth redirect URI below to your Facebook app configuration.
              </Text>
            </Box>
          </HStack>
          <RedirectUri oauthLandingUrl={authProvider?.data?.oauthLandingUrl} />
        </VStack>
      )}
    </VStack>
  );
}
