import numeral from "numeral";

/**
 * Parses a number to the default thousands format (shorthand)
 * 1000 -> 1K
 * 1050 -> 1.05K
 * 1005 -> 1K (rounded)
 * 10000 -> 10K
 * 1000000 -> 1M
 * @param input The number to format
 * @returns {string} The default SANLO number formatting for large numbers
 */
export const parseThousands = (input) => numeral(input).format("(0,0[.]00a)").toUpperCase();

export const getCurrencySymbol = (currency = "USD") => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
    .formatToParts(1)
    .find((x) => x.type === "currency").value;
};

export const bankersRounding = (value, opts = {}) => {
  const { output = null } = opts;
  const d = 2;

  if (typeof value !== "string" && typeof value !== "number") return "";

  const m = Math.pow(10, d);
  const n = +(d ? value * m : value).toFixed(8);
  const i = Math.floor(n);
  const diff = n - i; // getting the difference
  const e = 1e-8; // Rounding errors in var(diff)
  // Checking if the difference is less than or
  // greater than, based on that adding the 1 to it.
  const r = diff > 0.5 - e && diff < 0.5 + e ? (i % 2 === 0 ? i : i + 1) : Math.round(n);
  // if d != 0 then returning r/m else r
  let newValue = d ? r / m : r;

  if (output === "string") {
    newValue = ((Number(newValue) * 100) / 100).toFixed(2);
  }
  return newValue;
};

export function currencyFormat(value = 0) {
  let currency = "USD";
  let opts = {};

  // Second parameter can be the currency or options
  // Third parameter can only ever be options
  const argTwo = arguments[1];
  const argThree = arguments[2];
  if (argTwo && typeof argTwo === "string") currency = argTwo;
  if (argTwo && typeof argTwo === "object") opts = argTwo;
  if (argThree && typeof argThree === "object") opts = argThree;
  if (!opts || (opts && typeof opts !== "object")) opts = {};

  let {
    // This is just a nice way to pass currency when passing other opts
    currency: currencyOverride = null,
    // Return empty string instead of "$0"
    returnEmpty = false,
    useBankersRounding = true,
    shorthand = false,
    forceShowDecimals = false,
    addPrefix = false,
    notation = Math.abs(value) > 100000 ? "compact" : "standard",
  } = opts;

  let formattedCurrency = returnEmpty ? "" : "$0";
  try {
    // Default the currency to USD if something invalid was passed in
    if (!currency || typeof currency !== "string") currency = "USD";
    currency = currencyOverride || currency;

    let val = parseFloat(value, 10);
    if (!isNaN(val)) {
      if (useBankersRounding) {
        val = bankersRounding(val);
      }

      let decimals = 2; // initialize to always show two decimal places
      // Check for a value that doesnt need decimal places, $10.00/$10
      if (val % 1 === 0 && !forceShowDecimals) {
        decimals = 0;
      }

      if (shorthand === true || (typeof shorthand === "number" && val >= shorthand)) {
        return `${getCurrencySymbol(currency)}${parseThousands(val)}`;
      }

      const intlNumFormat = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency,
        minimumFractionDigits: decimals,
        notation,
        compactDisplay: "short",
      });
      formattedCurrency = intlNumFormat.format(val);
    }
  } catch (e) {
    formattedCurrency = returnEmpty ? "" : "$0";
  }

  const prefix = value > 0 ? "+" : "";
  if (addPrefix) {
    return `${prefix}${formattedCurrency}`;
  } else {
    return formattedCurrency;
  }
}

export const currencyFormatCentsData = (amount, options = {}) => {
  const formattedAmount = currencyFormat(amount / 100, {
    forceShowDecimals: true,
    ...options,
  });
  const dollars = formattedAmount.split(".")[0];
  const cents = formattedAmount.split(".")[1];
  return {
    amount,
    formattedAmount,
    dollars,
    cents,
  };
};

export const hasConversion = (advance) => {
  const { advanceCurrency, originCurrency } = advance;
  return advanceCurrency !== originCurrency;
};
