import { useSelector } from "react-redux";
import { pricingTiersSelector } from "@redux/webshops/catalog/catalogSlice";
import { SectionFieldTooltip } from "@components/catalog";
import { Icon, Image, Text, Popover, PopoverTrigger, PopoverContent, PopoverBody, VStack } from "@chakra-ui/react";
import { Button } from "@sanlo/ui-components";
import { ReactComponent as AlertCircle } from "@assets/img/icons/common/alertCircle.svg";
import { ReactComponent as Download } from "@assets/img/icons/common/import-file-two.svg";
import PricingTiersImage from "@assets/img/webshops/catalog/pricing-tiers.png";
import NameImage from "@assets/img/webshops/catalog/name.png";
import ImageTooltip from "@assets/img/webshops/catalog/image.png";
import CategoryImage from "@assets/img/webshops/catalog/category.png";
import BadgesImage from "@assets/img/webshops/catalog/badges.png";

export const PricingTierTooltip = () => {
  const pricingTiers = useSelector(pricingTiersSelector);
  const csvUrl = pricingTiers?.meta?.csvUrl || "";
  return (
    <Popover trigger={"hover"} placement={"bottom-end"}>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <Icon
              as={AlertCircle}
              ml={"5px"}
              width={"16px"}
              height={"16px"}
              transform={"rotate(180deg)"}
              color={"textWhite.400"}
            />
          </PopoverTrigger>
          <PopoverContent maxW={"320px"}>
            <PopoverBody>
              <VStack gap={"10px"}>
                <Image
                  src={PricingTiersImage}
                  bg={"lightgray"}
                  backgroundPosition={"50%"}
                  backgroundSize={"cover"}
                  backgroundRepeat={"no-repeat"}
                  borderRadius={"8px"}
                />

                <VStack gap={"10px"} alignItems={"flex-start"}>
                  <Text size={"smallAlt"} letterSpacing={"0.14px"} color={"textWhite.400"}>
                    Select the price that your player will see.
                  </Text>
                  <VStack pt={"8px"} borderTop={"1px solid"} borderColor={"dark.300"} gap={"10px"}>
                    <Text size={"mini"} letterSpacing={"0.24px"} color={"textSubtext.400"}>
                      Prices automatically adjust based on currency and region according to our pricing tiers.
                    </Text>
                    {csvUrl && (
                      <Button
                        w={"full"}
                        as={"a"}
                        href={csvUrl}
                        leftIcon={<Icon as={Download} width={"18px"} height={"17px"} color={"textWhite.400"} />}
                        onClick={onClose}
                      >
                        Download CSV
                      </Button>
                    )}
                  </VStack>
                </VStack>
              </VStack>
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};

export const NameFieldTooltip = () => {
  return (
    <SectionFieldTooltip imageSrc={NameImage}>
      <Text size={"smallAlt"} letterSpacing={"0.14px"} color={"textWhite.400"}>
        The Item name will be displayed on the webshop.
      </Text>
    </SectionFieldTooltip>
  );
};

export const ImageFieldTooltip = () => {
  return (
    <SectionFieldTooltip imageSrc={ImageTooltip}>
      <Text size={"smallAlt"} letterSpacing={"0.14px"} color={"textWhite.400"}>
        The item image will be displayed on the webshop.
      </Text>
    </SectionFieldTooltip>
  );
};

export const CategoryFieldTooltip = () => {
  return (
    <SectionFieldTooltip imageSrc={CategoryImage}>
      <Text size={"smallAlt"} letterSpacing={"0.14px"} color={"textWhite.400"}>
        Categories are used to add items to the webshop in the builder.
      </Text>
    </SectionFieldTooltip>
  );
};

export const ItemIdFieldTooltip = () => {
  return (
    <SectionFieldTooltip>
      <Text size={"smallAlt"} letterSpacing={"0.14px"} color={"textWhite.400"}>
        Your Item ID should be a unique and custom identifier within your system. This ensures accurate delivery of
        purchased items to your players.
      </Text>
    </SectionFieldTooltip>
  );
};

export const DeepLinkFieldTooltip = () => {
  return (
    <SectionFieldTooltip placement={"top"}>
      <Text size={"smallAlt"} letterSpacing={"0.14px"} color={"textWhite.400"}>
        Design the look and feel for your deep link modals in the builder.
      </Text>
    </SectionFieldTooltip>
  );
};

export const BadgesTooltip = () => {
  return (
    <SectionFieldTooltip dataTestid={"badges-tooltip"} imageSrc={BadgesImage} placement={"top-start"}>
      <Text size={"smallAlt"} letterSpacing={"0.14px"} color={"textWhite.400"} textAlign={"start"}>
        Badges can be used to communicate anything you want. e.g. Discount, Sale, Scarcity, etc.
      </Text>
    </SectionFieldTooltip>
  );
};
