import React from "react";
import styled from "styled-components";

import { BodySmallAltPlus } from "@styled";

const StyledCallout = styled.div`
  background-color: rgba(130, 128, 255, 0.16);
  padding: 0.75rem;
  border-radius: 0.75rem;
  margin-bottom: 0.75rem;
  color: ${({ theme }) => theme.colorElectricBlue};
  line-height: 1rem;

  > span:first-child {
    margin-bottom: 1rem;
    display: block;
  }

  > span:last-child {
    margin-bottom: 0 !important;
  }
`;

export const Callout = ({ style = {}, children }) => {
  // Other platforms use callouts as well, but only the ua spend ones follow the same structure
  // to where a reusable component was warranted.
  return (
    <StyledCallout style={style}>
      <BodySmallAltPlus>{children}</BodySmallAltPlus>
    </StyledCallout>
  );
};
