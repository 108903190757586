import { getRequestStatus, getResponseStatus, getErrorStatus, setStatusDefaults, stateSet } from "@utils/redux";

import { UA_SPEND_DATA, GET_INTEGRATIONS_CONNECTIONS, SEND_SUGGESTION } from "./actions";

import { ADJUST_GET_UA_SPEND } from "../adjust/actions";

import { APPSFLYER_GET_UA_SPEND } from "../appsFlyer/actions";

const initialState = {
  type: "base",

  requestStatus: {
    getData: setStatusDefaults(),
    sendSuggestion: setStatusDefaults(),
  },

  dashboard: {
    uaSpend: {},
  },

  lastSuggestion: {
    suggestion: null,
    platformType: null,
  },
};

const baseReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INTEGRATIONS_CONNECTIONS.REQUEST: {
      return stateSet(state, {
        "requestStatus.getData": getRequestStatus(),
      });
    }

    case GET_INTEGRATIONS_CONNECTIONS.RESPONSE: {
      return stateSet(state, {
        "requestStatus.getData": getResponseStatus(),
      });
    }

    case GET_INTEGRATIONS_CONNECTIONS.ERROR: {
      return stateSet(state, {
        "requestStatus.getData": getErrorStatus(),
      });
    }

    case UA_SPEND_DATA.REMOVE: {
      return stateSet(state, {
        "dashboard.uaSpend": initialState.dashboard.uaSpend,
      });
    }

    case ADJUST_GET_UA_SPEND.RESPONSE: {
      return stateSet(state, {
        "dashboard.uaSpend": action.response,
      });
    }

    case APPSFLYER_GET_UA_SPEND.RESPONSE: {
      return stateSet(state, {
        "dashboard.uaSpend": action.response,
      });
    }

    // INTEGRATION SUGGESTIONS

    case SEND_SUGGESTION.REQUEST: {
      return stateSet(state, {
        "requestStatus.sendSuggestion": getRequestStatus(),
      });
    }

    case SEND_SUGGESTION.RESPONSE: {
      return stateSet(state, {
        "requestStatus.sendSuggestion": getResponseStatus(),
        "lastSuggestion": action.response,
      });
    }

    case SEND_SUGGESTION.ERROR: {
      return stateSet(state, {
        "requestStatus.sendSuggestion": getErrorStatus(),
      });
    }

    default:
      return state;
  }
};

export default baseReducer;
