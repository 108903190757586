import React, { useEffect, useRef } from "react";

import { BodyRegular16, BodySmall14, BodySmallPlus } from "@components/styled";
import StyledOnboardingStep0 from "./StyledOnboardingStep0";
import EmojiPlaceholder from "@components/onboarding/EmojiPlaceholder";
import PlatformFeatureCard from "@components/onboarding/platform-feature-card/PlatformFeatureCard";
import Support from "@components/support/Support";
import { SanloButton } from "@inputs";

import { getBrandingInfo } from "@utils/branding";
import { trackGTMEvent } from "@utils/gtm";
import symbols from "@utils/symbols";

const features = [
  {
    icon: <EmojiPlaceholder color="#FFD73529" emoji={symbols.MoneyBag()} />,
    title: "Fast access to capital",
    description:
      "We act fast. Our job is to make funding you easier so you can focus on building awesome games and apps people love!",
  },
  {
    icon: <EmojiPlaceholder color="#66C8FF29" emoji={symbols.ShakingHand()} />,
    title: "Simple Pricing",
    description:
      "One flat fee so you know the full cost upfront. Repay with a percentage of your revenue - if revenue slows down, so do repayments. Or, repay in fixed amounts.",
  },
  {
    icon: <EmojiPlaceholder color="#2DFAB029" emoji={symbols.Seeding()} />,
    title: "Growth acceleration",
    description:
      "Access more capital as you scale. We care about your success and we'll support you with insights and capital.",
  },
];

const OnboardingStep0 = ({ companyName, onChangeStep }) => {
  const featuresRef = useRef();

  const { isBranded } = getBrandingInfo();

  useEffect(() => {
    // Tag Manager OnboardingStep0 Component View Event
    trackGTMEvent({
      "event": "Component View",
      "Component Name": "OnboardingStep0",
    });

    // Add delay for each feature card
    if (featuresRef.current) {
      const cards = featuresRef.current.children;
      Array.from(cards).forEach((card, index) => {
        card.style.animationDelay = 0.5 * index + 0.5 + "s";
      });
    }
  }, []);

  return (
    <StyledOnboardingStep0>
      <div className="ob-card animated" style={{ width: "617px" }}>
        <BodySmall14 className="ob-card-kicker">{`Welcome ${companyName}! ${symbols.wavingHand}`}</BodySmall14>
        <div className="ob-card-headline">
          <span className="gradient-text">Set up your profile</span>
          in minutes.
        </div>
        <BodyRegular16 className="ob-card-description">
          We'll guide you through easy steps where you'll answer several questions and connect platforms so we can get
          you the capital to grow!
        </BodyRegular16>

        <SanloButton
          onClick={() => {
            onChangeStep(1);
          }}
          className="get-started-button"
          gtmEventTag="tag-sanlo-onboarding-step0-continue"
          label="Get Started"
          testId="onboarding-step0-get-started"
        />

        <div className="divider" />

        {!isBranded && (
          <BodySmallPlus className="analytics-message">
            Just interested in Sanlo Analytics?
            <span
              data-test-id="onboarding-step0-analytics"
              className="label-button tag-sanlo-onboarding-step0-analytics"
              onClick={() => {
                onChangeStep(4);
              }}
            >
              Click here
            </span>
          </BodySmallPlus>
        )}
        {isBranded && (
          <BodySmallPlus className="analytics-message">
            Have any questions? <Support className="ml_half" red bold />
          </BodySmallPlus>
        )}
      </div>

      {!isBranded && (
        <div ref={featuresRef} className="platform-features-container">
          {features.map((feature, index) => (
            <PlatformFeatureCard key={`platform-feature-${feature.title}-${index}`} animated feature={feature} />
          ))}
        </div>
      )}
    </StyledOnboardingStep0>
  );
};

export default OnboardingStep0;
