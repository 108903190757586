import React, { useState } from "react";
import { useSelector } from "react-redux";

import PlatformAccordion from "@components/onboarding/platform-accordion/PlatformAccordion";
import FullScreenModal from "@components/common/modals/fullscreen-modal/FullScreenModal";

import { PRODUCT_ANALYTICS, IN_APP_PURCHASES } from "@utils/consts";
import useIntegrationsStatus from "@utils/custom-hooks/useIntegrationsStatus";
import useAccordion from "@utils/custom-hooks/useAccordion";

import { SECONDARY_FLOW_ACCORDIONS_DATA } from "./utils";
import { Box } from "@chakra-ui/react";

const OnboardingStepAnalytics = ({ fromDashboard, rejectedCapitalRequest }) => {
  const [expandedAccordions, handleToggleAccordion] = useAccordion(fromDashboard ? "" : IN_APP_PURCHASES);

  const integrations = useSelector((state) => state.integrations || {});
  const integrationsStatus = useIntegrationsStatus();

  // Connection modal
  const [selectedPlatform, setSelectedPlatform] = useState(integrations.appsflyer);
  const [selectedPlatformModalOpen, setSelectedPlatformModalOpen] = useState(false);

  const handleSelectPlatform = (platform) => {
    setSelectedPlatform(platform);
    setSelectedPlatformModalOpen(true);
  };

  return (
    <>
      {SECONDARY_FLOW_ACCORDIONS_DATA.map((accordionSection, index) => {
        const { id, title, content, suggestionType } = accordionSection;

        // Only show IAP in the dashboard when capital request was rejected
        if (fromDashboard && rejectedCapitalRequest && id === PRODUCT_ANALYTICS) return null;

        // Format add networks
        if (!integrationsStatus[id].connected) {
          integrationsStatus[id].id = "optional";
          integrationsStatus[id].label = rejectedCapitalRequest ? "Recommended" : "Optional";
        }

        return (
          <Box key={`platform-accordion-${id}-${index}`}>
            <PlatformAccordion
              fromDashboard={fromDashboard}
              platformStatus={{ ...integrationsStatus[id] }}
              onAccordionToggle={() => handleToggleAccordion(id)}
              expanded={expandedAccordions.includes(id)}
              title={title}
              content={content}
              suggestionType={suggestionType}
              onSelectPlatform={handleSelectPlatform}
            />
          </Box>
        );
      })}

      <FullScreenModal
        saveProgress={selectedPlatform.canSaveProgress}
        modalContent={selectedPlatform}
        openModal={selectedPlatformModalOpen}
        closeModal={() => setSelectedPlatformModalOpen(false)}
      />
    </>
  );
};

export default OnboardingStepAnalytics;
