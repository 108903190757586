import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import actions from "@redux/actions";
import { SanloButton, SanloInput, SanloTextarea } from "@inputs";
import { submitHubspotForm } from "@services/capital-request-hubspot";

import { toast } from "react-toastify";
import { toastOptions } from "@utils/defaults/toast";
import { SanloToast } from "@common/toasts/sanlo-toast/SanloToast";

const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 650px;
  margin: auto;
`;

export const CapitalRequestForm = ({ onSubmit = () => {} }) => {
  const dispatch = useDispatch();
  const email = useSelector((state) => state.session.user.email);
  const [financingAmount, setFinancingAmount] = useState("");
  const [financingPeriod, setFinancingPeriod] = useState("");
  const [notes, setNotes] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsDisabled(!financingAmount || !financingPeriod);
  }, [financingAmount, financingPeriod]);

  const requiredQuestions = [
    {
      label: "How much are you looking for (in USD)?*",
      placeholder: "E.g. 50,000 USD",
      value: financingAmount,
      setValue: setFinancingAmount,
      hasFocus: true,
      testId: "hubspot-desired-financing-amount",
    },
    {
      label: "By when are you looking to receive funds?*",
      placeholder: 'E.g. "in 2 weeks", "at the month end", etc',
      value: financingPeriod,
      setValue: setFinancingPeriod,
      testId: "hubspot-financing-period",
    },
  ];

  const optionalQuestion = {
    label: "What do you wish to use these funds for? (optional)",
    placeholder: "E.g. “for marketing”, “for general business expenses”, “as a supplement to an equity raise” etc.",
    value: notes,
    setValue: setNotes,
    testId: "hubspot-notes",
  };

  const handleSubmit = async () => {
    setIsDisabled(true);
    setIsLoading(true);
    const form = {
      email,
      financingAmount,
      financingPeriod,
      notes,
    };

    const hubspotResponse = await submitHubspotForm(form);
    if (hubspotResponse.status === 200) {
      dispatch(
        actions.integrations.advances.newCapitalRequest.request({
          source: "HUBSPOT",
        }),
      );
      onSubmit();
    } else {
      toast.error(<SanloToast icon="error" msg={"Something went wrong, please try again."} />, {
        ...toastOptions,
        toastId: `hubspot-api-error`,
      });
      setIsDisabled(false);
      setIsLoading(false);
    }
  };

  return (
    <StyledForm>
      {requiredQuestions.map((question, index) => {
        return (
          <SanloInput
            key={index}
            style={{ margin: "0 0 24px 0" }}
            label={question.label}
            placeholder={question.placeholder}
            value={question.value}
            setValue={question.setValue}
            hasFocus={question.hasFocus}
            testId={question.testId}
            isRequired
            isJumbo
          />
        );
      })}

      <SanloTextarea
        style={{ margin: "0 0 24px 0" }}
        label={optionalQuestion.label}
        placeholder={optionalQuestion.placeholder}
        value={optionalQuestion.value}
        setValue={optionalQuestion.setValue}
        testId={optionalQuestion.testId}
        isJumbo
      />

      <SanloButton
        label="Submit Request"
        onClick={() => handleSubmit()}
        testId="hubspot-submit-request"
        isDisabled={isDisabled}
        isLoading={isLoading}
        isJumbo
      />
    </StyledForm>
  );
};
