import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { seriesSelector, setSelectedSeries } from "@redux/webshops/catalog/series/seriesSlice";
import { Button, Heading, HStack, Icon, Input, Text, VStack, useDisclosure } from "@chakra-ui/react";

import { AddSeries } from "./components/add-series/AddSeries";
import { SeriesList } from "./components/series-list/SeriesList";

import { EmptyState } from "@components/catalog";
import ErrorBoundary from "@components/ErrorBoundary";
import { ReactComponent as PlusIcon } from "@assets/img/icons/common/plus.svg";

export function Series() {
  const dispatch = useDispatch();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [seriesNameFilter, setSeriesNameFilter] = useState("");

  const { data: series = [] } = useSelector(seriesSelector);

  const handleNewItem = () => {
    dispatch(setSelectedSeries(null));
    onOpen();
  };

  return (
    <>
      <ErrorBoundary
        name="catalog-page-series-list"
        showAltUI={!series?.length}
        altUI={<EmptyState openItemDrawer={handleNewItem} label={"series"} buttonLabel={"New series"} />}
      >
        <HStack justifyContent={"space-between"} pb={"16px"}>
          <Button
            leftIcon={<Icon as={PlusIcon} w="16px" h="16px" color={"white"} />}
            onClick={handleNewItem}
            _active={{}}
          >
            New Series
          </Button>
          <Input maxW={"246px"} placeholder="Search series" onChange={(e) => setSeriesNameFilter(e.target.value)} />
        </HStack>
        <VStack w={"100%"} alignItems={"flex-start"} gap={"8px"}>
          <Heading size={"regular"} fontWeight={600}>
            Series
          </Heading>
          <Text size={"small"} color={"textDefault.400"}>
            A series is a set of items that will be displayed one at a time in a sequence. Items in a series cannot
            appear anywhere else on your shop.
          </Text>
          <SeriesList openDrawer={onOpen} globalFilter={seriesNameFilter} />
        </VStack>
      </ErrorBoundary>
      <AddSeries isOpen={isOpen} onClose={onClose} />
    </>
  );
}
