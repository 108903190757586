import React, { useState } from "react";
import { BodyLargePlus, BodyRegular16 } from "@styled";
import { ReactComponent as CrossIcon } from "@assets/img/icons/common/modal-cross.svg";
import { StyledDismissibleBanner } from "./StyledDismissibleBanner";
import classnames from "classnames";

export const DismissableBanner = ({
  className,
  id,
  title = null,
  content = null,
  dismissable = true,
  allowMarginTop = false,
}) => {
  const [bannerDismissed, setBannerDismissed] = useState(window.localStorage.getItem(`${id}banner`));

  const dismissBanner = () => {
    setBannerDismissed("true");
    window.localStorage.setItem(`${id}banner`, "true");
  };

  return (
    !bannerDismissed && (
      <StyledDismissibleBanner id={id} className={className} allowMarginTop={allowMarginTop}>
        {Boolean(title) && (
          <BodyLargePlus className="banner-title">
            <span>{title}</span>
            {Boolean(dismissable) && <CrossIcon onClick={dismissBanner} aria-hidden={true} />}
          </BodyLargePlus>
        )}
        <BodyRegular16
          className={classnames("banner-content", {
            "no-title": !title,
          })}
        >
          {content}
        </BodyRegular16>
      </StyledDismissibleBanner>
    )
  );
};

export default DismissableBanner;
