import { Box } from "@chakra-ui/react";

export const DragHandle = ({ controls = {}, onMouseDown = () => {}, onMouseUp = () => {} }) => {
  return (
    <Box
      position="relative"
      height="40px"
      width="40px"
      flexShrink={0}
      className="reorder-handle"
      onPointerDown={(e) => controls.start(e)}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      cursor={"grab"}
      _active={{
        cursor: "grabbing",
      }}
    >
      {[-0.3, 0, 0.3].map((dot) => {
        return (
          <Box
            key={dot}
            width="0.4rem"
            height="0.125rem"
            borderRadius="50%"
            position="absolute"
            top="50%"
            left="50%"
            transform={`translateX(-50%) translateY(calc(-50% + ${dot}rem))`}
            sx={{
              "&:before": {
                content: '""',
                position: "absolute",
                top: "0",
                left: "0",
                width: "0.125rem",
                height: "0.125rem",
                bg: "rgba(255, 255, 255, 0.5)",
                borderRadius: "50%",
              },
              "&:after": {
                content: '""',
                position: "absolute",
                top: "0",
                right: "0",
                width: "0.125rem",
                height: "0.125rem",
                bg: "rgba(255, 255, 255, 0.5)",
                borderRadius: "50%",
              },
            }}
          />
        );
      })}
    </Box>
  );
};
