import styled from "styled-components";

const StyledOnboardingStep0 = styled.div`
  & {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 448px;

    .ob-card {
      background-color: ${({ theme }) => theme.grayscale10};
      border-radius: 12px;
      padding: 34px 32px;
      width: fill-available;

      &.animated {
        animation: pushInFromBottom 1s ease;
        animation-fill-mode: both;
      }

      .ob-card-kicker {
        color: ${({ theme }) => theme.textGray};
      }

      .ob-card-headline {
        font-family: "Roobert", "Helvetica", sans-serif;
        font-size: 52px;
        font-style: normal;
        font-weight: 600;
        line-height: 52px;
        letter-spacing: 0;
        text-align: left;
        margin-bottom: 24px;

        .gradient-text {
          background: #fe94ff;
          background: -webkit-radial-gradient(
            circle farthest-corner at center center,
            #fe94ff 0%,
            #ff5c79 68%,
            #bf6bff 100%
          );
          background: -moz-radial-gradient(
            circle farthest-corner at center center,
            #fe94ff 0%,
            #ff5c79 68%,
            #bf6bff 100%
          );
          background: radial-gradient(circle farthest-corner at center center, #fe94ff 0%, #ff5c79 68%, #bf6bff 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          display: block;
        }
      }

      .ob-card-header {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        .ob-card-icon {
          margin-right: 16px;
        }

        .ob-card-title {
          color: ${({ theme }) => theme.textSeashellWhite};
        }
      }

      .ob-card-description {
        color: ${({ theme }) => theme.textGray};
      }

      .get-started-button {
        background-image: linear-gradient(84.98deg, #ff5c79 0%, #bb60f7 100%);
        height: 64px;
        margin: 42px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        cursor: pointer;
        position: relative;
        z-index: 1;

        &.get-started-button::before {
          border-radius: 12px;
          position: absolute;
          content: "";
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          background-image: linear-gradient(84.98deg, #ff5c79 0%, #ff5c79 100%);
          z-index: -1;
          transition: opacity 0.3s linear;
          opacity: 0;
        }

        &.get-started-button:hover::before {
          opacity: 1;
        }

        .button-label {
          font-weight: 500;
          color: ${({ theme }) => theme.grayscale0};
        }
      }

      .divider {
        margin-bottom: 24px;
        border-bottom: 1px solid ${({ theme }) => theme.grayscale40};
      }

      .analytics-message {
        color: ${({ theme }) => theme.textGray};
        display: flex;
        align-items: center;

        .label-button {
          margin-left: 4px;
          color: ${({ theme }) => theme.colorRed};
          cursor: pointer;
        }
      }
    }

    .platform-features-container {
      margin-left: 16px;
      max-width: 326px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      > div {
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`;

export default StyledOnboardingStep0;
