import React, { cloneElement, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTransition, animated } from "react-spring";
import { useNavigate } from "react-router-dom";

import actions from "@redux/actions";

import { Box } from "@chakra-ui/react";
import AuthenticationContainer from "../../../../authentication/AuthenticationContainer";
import { LoadingScreen } from "@components/common/loaders";

import { ConnectSources } from "../connect-sources/ConnectSources";
import { SuperpowersModal } from "../superpowers-modal/SuperpowersModal";
import { getSaveProgressPayload } from "./utils";

const steps = [<SuperpowersModal key="SuperpowersModal" />, <ConnectSources key="ConnectSources" />];

export const SuperpowersStepper = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [step, setStep] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const [finishLoading, setFinishLoading] = useState(false);

  const partnerBranding = useSelector((state) => state.session.partnerBranding);
  const onboardingData = useSelector((state) => state.session.onboarding.data);

  const handleSetLoader = () => {
    setShowLoader(true);
    setTimeout(() => {
      setFinishLoading(true);
    }, 4000);
    navigate("/dashboard");
  };

  const prevStep = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  const nextStep = (goToDashboard = false) => {
    if (goToDashboard) {
      handleSetLoader();
      return;
    }
    if (step < steps.length - 1) {
      return setStep(step + 1);
    }
    handleSetLoader();
  };

  const saveProgress = () => {
    const savePayload = getSaveProgressPayload(onboardingData);
    dispatch(actions.session.finishOnboarding.request(savePayload));
  };

  const transitions = useTransition(showLoader, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: 300,
  });

  return transitions(({ opacity }, item) => {
    if (item) {
      return (
        <animated.div
          style={{
            opacity: opacity.to({ range: [0.0, 1.0], output: [0, 1] }),
          }}
        >
          <LoadingScreen
            onClick={() => setShowLoader(!showLoader)}
            notifyParent={saveProgress}
            loaded={finishLoading}
          />
        </animated.div>
      );
    }
    return (
      <animated.div
        style={{
          opacity: opacity.to({ range: [1.0, 0.0], output: [1, 0] }),
        }}
      >
        <AuthenticationContainer partnerBranding={partnerBranding} hasLogout>
          <Box maxH={"100vh"} p={"32px"} borderRadius={"12px"} backgroundColor={"dark.700"} overflowY={"auto"}>
            {cloneElement(steps[step], { handleNextStep: nextStep, handlePrevStep: prevStep })}
          </Box>
        </AuthenticationContainer>
      </animated.div>
    );
  });
};
