import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as ChevronLeft } from "@assets/img/icons/common/chevron-left.svg";
import { ReactComponent as ChevronRight } from "@assets/img/icons/common/chevron-right.svg";
import { trackGTMEvent, COMMON_ACTION, manualIntegrationTag } from "@utils/gtm";

const imagesMaxWidth = 468;
export const Carousel = ({ platformName = "", images = [] }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const isFirstImage = selectedIndex === 0;
  const isLastImage = selectedIndex === images?.length - 1;

  const trackEvent = (action) => {
    trackGTMEvent({
      event: manualIntegrationTag(platformName, action),
    });
  };

  function handleNextImage() {
    if (platformName) trackEvent(COMMON_ACTION.NEXT_IMAGE);
    if (selectedIndex < images.length - 1) {
      setSelectedIndex((prev) => prev + 1);
    }
  }

  function handlePreviousImage() {
    if (platformName) trackEvent(COMMON_ACTION.PREVIOUS_IMAGE);
    if (images.length >= selectedIndex && selectedIndex > 0) {
      setSelectedIndex((prev) => prev - 1);
    }
  }
  if (!images.length) {
    return null;
  }

  return (
    <CarouselWrapper>
      {!isFirstImage && (
        <PreviousButton onClick={handlePreviousImage}>
          <CarouselButton>
            <ChevronLeftIcon />
          </CarouselButton>
        </PreviousButton>
      )}
      <ImagesContainer selectedImageIndex={selectedIndex}>
        {images.map((image, index) => (
          <CarouselImage src={image} key={index} />
        ))}
      </ImagesContainer>
      {!isLastImage && (
        <NextButton onClick={handleNextImage}>
          <CarouselButton>
            <ChevronRightIcon />
          </CarouselButton>
        </NextButton>
      )}
    </CarouselWrapper>
  );
};

const CarouselWrapper = styled.div`
  position: relative;
  overflow: hidden;
  max-width: 506px;
  margin: auto;
  isolation: isolate;
`;

const ImagesContainer = styled.div`
  display: flex;
  gap: 8px;
  transition: transform 250ms;
  transform: ${({ selectedImageIndex }) =>
    `translateX(calc(${selectedImageIndex} * -${imagesMaxWidth}px ${selectedImageIndex ? "+ 22px" : "+ 0px"}))`};
`;

const CarouselImage = styled.img`
  display: block;
  width: auto;
  max-width: ${imagesMaxWidth}px;
  border-radius: 12px;
  flex: 1;
`;

const CarouselButtonWrapper = styled.div`
  position: absolute;
  box-sizing: content-box;
  width: 24px;
  z-index: 2;
  top: 0;
  bottom: 0;
  margin: auto;
`;

const PreviousButton = styled(CarouselButtonWrapper)`
  padding-left: 8px;
  padding-right: 4px;
  left: 0;
  background-image: ${({ theme }) =>
    `linear-gradient(270deg, rgba(26, 26, 31, 0) 36.11%, ${theme.grayscale10} 122.22%)`};
`;

const NextButton = styled(CarouselButtonWrapper)`
  padding-left: 4px;
  padding-right: 8px;
  z-index: 2;
  right: 0;
  background-image: ${({ theme }) =>
    `linear-gradient(90deg, rgba(26, 26, 31, 0) 36.11%, ${theme.grayscale10} 122.22%)`};
`;

const CarouselButton = styled.button`
  background-color: ${({ theme }) => theme.grayscale70};
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  margin: auto;
  width: 24px;
  height: 24px;
  border-radius: 20px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`;

const ChevronLeftIcon = styled(ChevronLeft)`
  margin-left: -2px;
`;

const ChevronRightIcon = styled(ChevronRight)`
  margin-left: 2px;
`;
