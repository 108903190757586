import styled from "styled-components";

export const StyledPlatformFeatureCard = styled.div`
  & {
    width: 326px;
    background-color: ${({ theme }) => theme.grayscale10};
    border-radius: 12px;
    padding: 16px 28px 16px 16px;

    animation: ${({ animated }) => (animated ? "pushInFromBottom" : "none")} 1s;
    animation-duration: ${({ animationDuration }) => animationDuration || "1s"};
    animation-fill-mode: both;

    .pf-header {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }

      .pf-icon {
        margin-right: 16px;
      }

      .pf-title {
        color: ${({ theme }) => theme.textSeashellWhite};
      }
    }

    .pf-description {
      display: block; // for line-height
      color: ${({ theme }) => theme.textGray};
    }

    @media not screen and (-webkit-min-device-pixel-ratio: 2),
      not screen and (min--moz-device-pixel-ratio: 2),
      not screen and (-o-min-device-pixel-ratio: 2/1),
      not screen and (min-device-pixel-ratio: 2),
      not screen and (min-resolution: 192dpi),
      not screen and (min-resolution: 2dppx) {
      /* non-Retina-specific stuff here */
      .pf-icon {
        span {
          width: 19px;
        }
      }
    }
  }
`;

export const StyledEmojiPlaceholder = styled.div`
  & {
    border-radius: 6px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const StyledRocketProgressBar = styled.div`
  & {
    position: relative;
    display: flex;
    align-items: center;
    margin: 8px 0;

    .progress-bar-track {
      height: 8px;
      width: 100%;
      border-radius: 6px;
      background: ${({ theme }) => theme.grayscale40};
      position: relative;

      .progress-bar-completed,
      .progress-bar-pending {
        position: absolute;
        border-radius: 6px;
        height: 8px;
        transition: width 0.75s ease-in;
      }
      .progress-bar-completed {
        left: 0;
        background-color: ${({ theme }) => theme.colorGreen};
      }
      .progress-bar-pending {
        background-color: ${({ theme }) => theme.colorGreen};
        opacity: 0.4;
      }
    }

    .progress-bar-section {
      top: 0;
      width: 20px;
      height: 8px;
      position: absolute;
      overflow: hidden;
      transform: translateX(-50%) translateZ(0);

      &::before {
        content: "";
        width: 20px;
        height: 20px;
        border-radius: 100px;
        background: rgba(0, 0, 0, 0);
        position: absolute;
        top: -6px;
        left: -7px;
        border: 5px solid ${({ theme }) => theme.grayscale10};
      }

      &::after {
        content: "";
        width: 20px;
        height: 20px;
        border-radius: 100px;
        background: rgba(0, 0, 0, 0);
        position: absolute;
        top: -6px;
        left: 10px;
        border: 5px solid ${({ theme }) => theme.grayscale10};
      }
    }

    .progress-bar-rocket {
      z-index: 200;
      width: 30px;
      height: 30px;
      position: absolute;
      margin-left: -7px;
      transition: left 0.75s ease-in;

      &.is-flying {
        transition: left 1.5s cubic-bezier(0.2, 1.13, 0.75, 0.04) 0.3s;
      }

      &.to-moon {
        //animation: toMoon .4s ease-out both;
        //animation-delay: 1.5s;
        transition: left 0.75s ease-in;
      }

      @keyframes toMoon {
        to {
          opacity: 0;
          transform: translateX(70px);
        }
      }
    }
  }
`;

export const StyledSlider = styled.div`
  & {
    width: 100%;
    height: 12px;
    border-radius: 52px;
    margin: 16px 0;
    background-color: ${({ theme }) => theme.grayscale40};
    cursor: pointer;

    .completed-bar {
      min-width: ${({ isGrowthOffer }) => (isGrowthOffer ? "1%" : "5%")};
      display: flex;
      align-items: center;
      position: relative;
      border-radius: 52px;
      height: inherit;
      background: ${({ isGrowthOffer }) =>
        isGrowthOffer
          ? "linear-gradient(91.54deg, #66C8FF 8.54%, #2CEEA8 82.49%)"
          : "linear-gradient(91.54deg, #C56AF4 8.54%, #FF5D7C 82.49%)"};

      .current-progress-indicator {
        width: 28px;
        height: 28px;
        position: absolute;
        right: 0;
        background: ${({ isGrowthOffer }) =>
          isGrowthOffer
            ? "linear-gradient(122.74deg, #66C8FF 11.48%, #34D9AC 86.75%)"
            : "linear-gradient(122.74deg, #C56BF5 11.48%, #F85C7A 86.75%)"};
        border: 1px solid #333338;
        box-shadow: 0 0 48px rgba(0, 0, 0, 0.4);
        border-radius: 200px;
        cursor: pointer;

        .current-progress-icon {
          display: flex;
          justify-content: center;
          align-items: center;

          .emoji {
            margin-right: 0 !important;
          }

          @media not screen and (-webkit-min-device-pixel-ratio: 2),
            not screen and (min--moz-device-pixel-ratio: 2),
            not screen and (-o-min-device-pixel-ratio: 2/1),
            not screen and (min-device-pixel-ratio: 2),
            not screen and (min-resolution: 192dpi),
            not screen and (min-resolution: 2dppx) {
            /* non-Retina-specific stuff here */
            span {
              width: 19px;
            }
          }
        }
      }
    }
  }
`;
