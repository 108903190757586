import { configureConsts, configureActions } from "@utils/redux";

export const ALL_GET_EARNINGS = configureConsts("ALL_GET_EARNINGS");
export const ALL_GET_PREVIOUS_EARNINGS = configureConsts("ALL_GET_PREVIOUS_EARNINGS");
export const AD_REVENUE_GET_EARNINGS = configureConsts("AD_REVENUE_GET_EARNINGS");
export const AD_REVENUE_GET_PREVIOUS_EARNINGS = configureConsts("AD_REVENUE_GET_PREVIOUS_EARNINGS");
export const APP_STORE_GET_EARNINGS = configureConsts("APP_STORE_GET_EARNINGS");
export const APP_STORE_REFRESH_EARNINGS = configureConsts("APP_STORE_REFRESH_EARNINGS");
export const APP_STORE_GET_PREVIOUS_PERIOD_EARNINGS = configureConsts("APP_STORE_GET_PREVIOUS_PERIOD_EARNINGS");
export const GOOGLE_PLAY_GET_EARNINGS = configureConsts("GOOGLE_PLAY_GET_EARNINGS");
export const GOOGLE_PLAY_GET_PREVIOUS_PERIOD_EARNINGS = configureConsts("GOOGLE_PLAY_GET_PREVIOUS_PERIOD_EARNINGS");

const earnings = {
  allGetEarnings: configureActions(ALL_GET_EARNINGS, {
    request: (platformIds) => ({
      type: ALL_GET_EARNINGS.REQUEST,
      platformIds,
    }),
  }),

  allGetPreviousEarnings: configureActions(ALL_GET_PREVIOUS_EARNINGS, {
    request: (platformIds, callback) => ({
      type: ALL_GET_PREVIOUS_EARNINGS.REQUEST,
      platformIds,
      callback,
    }),
  }),

  adRevenueGetEarnings: configureActions(AD_REVENUE_GET_EARNINGS, {
    request: (networkId) => ({
      type: AD_REVENUE_GET_EARNINGS.REQUEST,
      networkId,
    }),
  }),

  adRevenueGetPreviousEarnings: configureActions(AD_REVENUE_GET_PREVIOUS_EARNINGS, {
    request: (networkId, periodId, callback) => ({
      type: AD_REVENUE_GET_PREVIOUS_EARNINGS.REQUEST,
      networkId,
      periodId,
      callback,
    }),
  }),

  appstoreGetEarnings: configureActions(APP_STORE_GET_EARNINGS),

  appStoreRefreshEarnings: configureActions(APP_STORE_REFRESH_EARNINGS),

  appstoreGetPreviousPeriodEarnings: configureActions(APP_STORE_GET_PREVIOUS_PERIOD_EARNINGS, {
    request: (platformId, fiscalPeriodId, callback) => ({
      type: APP_STORE_GET_PREVIOUS_PERIOD_EARNINGS.REQUEST,
      platformId,
      fiscalPeriodId,
      callback,
    }),
  }),

  googleplayGetEarnings: configureActions(GOOGLE_PLAY_GET_EARNINGS),

  googleplayGetPreviousPeriodEarnings: configureActions(GOOGLE_PLAY_GET_PREVIOUS_PERIOD_EARNINGS, {
    request: (platformId, fiscalPeriodId, callback) => ({
      type: GOOGLE_PLAY_GET_PREVIOUS_PERIOD_EARNINGS.REQUEST,
      platformId,
      fiscalPeriodId,
      callback,
    }),
  }),
};

export default earnings;
