import React, { useMemo, useState, useCallback } from "react";
import {
  Box,
  FormLabel,
  HStack,
  Image,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { fuzzyFilter } from "@utils/table";
import { useDispatch, useSelector } from "react-redux";

import { seriesThunks, seriesSelector, setSelectedSeries } from "@redux/webshops/catalog/series/seriesSlice";

import { RECURRENCE_SERIES_PERIOD } from "@src/consts/webshops/catalog";
import PropTypes from "prop-types";

const columnHelper = createColumnHelper();

function NameCell(info) {
  const item = info.row.original.currentItem;
  const imageUrl = item?.imageUrl ?? null;
  return (
    <HStack>
      {imageUrl && <Image borderRadius={"3px"} w="32px" h="32px" src={imageUrl} />}
      <Text>{info.getValue()}</Text>,
    </HStack>
  );
}

function AvailabilityCell(info, handleOnStatusChange, isPending) {
  const recurrence = info.row.original.recurrenceInterval;
  const recurrenceObj = RECURRENCE_SERIES_PERIOD.find((period) => period.value === recurrence && recurrence !== null);
  return (
    <FormLabel
      minW={"124px"}
      m={0}
      display={"flex"}
      gap={"16px"}
      alignItems={"center"}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Switch
        isChecked={info.getValue()}
        onChange={() => handleOnStatusChange(info.row.original)}
        disabled={isPending}
        size={"sm"}
      />
      <HStack gap={"24px"}>
        <Text>Active</Text>
        {recurrenceObj && <Text>Reoccurs {recurrenceObj.label}</Text>}
      </HStack>
    </FormLabel>
  );
}

function QuantityCell(info) {
  const quantity = info.row.original.items.length ?? 0;
  return (
    <HStack>
      <Text>{quantity}</Text>,
    </HStack>
  );
}

export const SeriesList = ({ openDrawer = () => {}, globalFilter = "" }) => {
  const dispatch = useDispatch();
  const [sorting, setSorting] = useState([]);
  const [isPending, setIsPending] = useState(false);
  const { data: series = [] } = useSelector(seriesSelector);

  const handleSelectedSeries = (series) => {
    if (window.getSelection().toString()) {
      // Open the drawer only if there is no text selected
      return;
    }
    dispatch(setSelectedSeries(series));
    openDrawer();
  };

  const handleOnStatusChange = useCallback(
    async (series) => {
      setIsPending(true);
      try {
        const { items, ...rest } = series;
        const itemIds = items.map((item) => item.id);
        await dispatch(
          seriesThunks.updateSeries({
            ...rest,
            itemIds,
            available: !series.available,
          }),
        ).unwrap();
      } finally {
        setIsPending(false);
      }
    },
    [dispatch],
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        id: "name",
        header: () => "Name",
        cell: NameCell,
      }),
      columnHelper.accessor("quantity", {
        id: "quantity",
        header: () => "# of items",
        cell: QuantityCell,
      }),
      columnHelper.accessor("available", {
        id: "available",
        header: () => "Availability",
        cell: (info) => AvailabilityCell(info, handleOnStatusChange, isPending),
      }),
    ],
    [handleOnStatusChange, isPending],
  );

  const table = useReactTable({
    data: series,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: { sorting, globalFilter },
    onSortingChange: setSorting,
    sortDescFirst: false,
    enableSortingRemoval: false,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    globalFilterFn: "fuzzy",
  });

  return (
    <TableContainer
      mt={"16px"}
      h={"600px"}
      w={"100%"}
      border={"1px solid"}
      borderColor={"dark.600"}
      borderRadius={"12px"}
      position={"relative"}
    >
      {/* Scrollable until pagination implementation */}
      <Box maxH={"627px"} overflowY={"auto"}>
        <Table variant={"itemListCatalog"}>
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th key={header.id}>
                    <HStack>
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </HStack>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr key={row.id} onClick={() => handleSelectedSeries(row.original)}>
                {row.getVisibleCells().map((cell) => (
                  <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </TableContainer>
  );
};

SeriesList.propTypes = {
  openDrawer: PropTypes.func,
  globalFilter: PropTypes.string,
};
