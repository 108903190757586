// Function to implement that we should expand customer banking section on right
// If banking section already expanded collapse it and expand again
export const openBankingSection = () => {
  // The first accordion is generally the banking section
  const accordionIcon = document.querySelector(".accordion-icon");

  // Double check if it is the accordion for banking section which is also called plaid
  if (accordionIcon?.parentElement?.id.includes("plaid")) {
    const accordionContent = document.querySelector(".accordion-content");
    const accordionHeader = document.querySelector(".accordion-header");
    const gradientBorder = document.querySelector(".gradient-border");

    if (accordionIcon.classList.contains("is-expanded")) return;

    // expand customer banking section on right
    accordionContent.style.maxHeight = "fit-content";
    gradientBorder.classList.add("is-expanded");
    accordionHeader.classList.remove("is-collapsed");
    accordionIcon.classList.add("is-expanded");
  }
};

export const closeBankingSection = () => {
  // The first accordion is generally the banking section
  const accordionIcon = document.querySelector(".accordion-icon");

  // Double check if it is the accordion for banking section which is also called plaid
  if (accordionIcon?.parentElement?.id.includes("plaid")) {
    const accordionContent = document.querySelector(".accordion-content");
    const accordionHeader = document.querySelector(".accordion-header");
    const gradientBorder = document.querySelector(".gradient-border");

    accordionContent.style.maxHeight = 0;
    accordionIcon.classList.remove("is-expanded");
    accordionHeader.classList.add("is-collapsed");
    gradientBorder.classList.remove("is-expanded");
  }
};
