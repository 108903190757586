import { Icon, Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import { cloneElement, useLayoutEffect, useState } from "react";
import { AuthMethods } from "./components/AuthMethods";
import { ReactComponent as CrossIcon } from "@assets/img/icons/common/cross3.svg";
import { AuthMethodForm } from "./components/AuthMethodForm";

const variants = {
  enters: (direction) => ({
    x: direction > 0 ? "100%" : "-100%",
    opacity: 0,
  }),
  center: {
    x: 0,
    opacity: 1,
  },
  exit: (direction) => ({
    x: direction < 0 ? "100%" : "-100%",
    opacity: 0,
  }),
};

const steps = [<AuthMethods key="auth-methods" />, <AuthMethodForm key="auth-method-form" />];

export function AuthMethodsModal({ isOpen, onClose, selectedProvider }) {
  const [[step, direction], setStep] = useState(() => [0, 0]);
  const [selectedAuthMethod, setSelectedAuthMethod] = useState();

  const nextStep = (provider) => {
    setSelectedAuthMethod(provider);
    if (step < steps.length - 1) {
      return setStep([step + 1, 1]);
    }
    onClose();
  };

  const prevStep = () => {
    setSelectedAuthMethod(null);
    if (step > 0) {
      return setStep([step - 1, -1]);
    }
    onClose();
  };

  useLayoutEffect(() => {
    if (selectedProvider) {
      setSelectedAuthMethod(selectedProvider);
      setStep([1, 1]);
    }
  }, [selectedProvider]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      onCloseComplete={() => {
        setStep([0, 0]);
      }}
    >
      <ModalOverlay />
      <ModalContent pos={"relative"} overflow={"hidden"}>
        <AnimatePresence initial={false} custom={direction} mode="popLayout">
          <motion.div
            style={{ display: "flex", flexDirection: "column", flex: 1, position: "relative" }}
            variants={variants}
            transition={{ x: { type: "spring", stiffness: 300, damping: 30 }, opacity: { duration: 0.2 } }}
            initial="enters"
            animate="center"
            custom={direction}
            exit="exit"
            key={`multi_step_${step}`}
            layout
          >
            <Icon
              as={CrossIcon}
              onClick={onClose}
              w="24px"
              h="24px"
              cursor="pointer"
              color={"textSubtext.400"}
              pos={"absolute"}
              right={"-8px"}
            />
            {isOpen &&
              cloneElement(steps[step], {
                handleNextStep: nextStep,
                handlePrevStep: prevStep,
                selectedAuthMethod,
                onClose,
              })}
          </motion.div>
        </AnimatePresence>
      </ModalContent>
    </Modal>
  );
}
