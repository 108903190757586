import HTTP from "@redux/http";
const SANLO_WEB_API_URL = process.env.REACT_APP_SANLO_WEB_API_URL;
const BASE = `${SANLO_WEB_API_URL}/api/finance`;

class FinanceService {
  requestFinancialIntelligenceAccess() {
    return HTTP.req.post(`${BASE}/access/request`);
  }
}

export default new FinanceService();
