import React from "react";
import { useSelector } from "react-redux";

import { Box, Button, Heading, Icon, Link, Text, HStack, VStack } from "@chakra-ui/react";
import symbols from "@src/utils/symbols";
import useIntegrationsStatus from "@utils/custom-hooks/useIntegrationsStatus";
import StyledConnectSources from "./StyledConnectSources";
import OnboardingStep3Container from "../../../step-3/OnboardingStep3Container";
import { trackGTMEvent, ONBOARDING_ACTIONS } from "@utils/gtm";
import { ReactComponent as ArrowLeft } from "@src/assets/img/icons/common/arrow_left.svg";
import { isAnyIntegrationActive } from "./utils";

export const ConnectSources = ({ handlePrevStep, handleNextStep }) => {
  const integrationsStatus = useIntegrationsStatus();

  const integrationsSuggested = useSelector(
    (state) => state.session.company.onboarding.suggestedOnboardingIntegrations,
  );

  const showBanner = !isAnyIntegrationActive({ integrationsStatus, integrationsSuggested });

  return (
    <VStack w={"510px"} gap={"32px"} p={"0px 10px"}>
      <VStack alignItems={"flex-start"} gap={"16px"}>
        <Heading size={"regular"} fontWeight={"600"} color={"textWhite.400"}>
          One last step! Connect your data sources.
        </Heading>
        <Text size={"smallAlt"}>
          Connect your data sources to unlock the free superpowers you selected. You’ll get access to a wide range of
          our products for your company.
        </Text>
      </VStack>

      <VStack gap={"16px"}>
        {showBanner && (
          <Box w={"100%"} p={"12px"} backgroundColor={"#8280ff28"} borderRadius={"10px"}>
            <Text size={"smallAlt"} fontWeight={"500"} color={"brandElectricBlue.400"}>
              {symbols.lightBulb} Recommended: Connect at least one data source for better functionality.
            </Text>
          </Box>
        )}
        <StyledConnectSources>
          <OnboardingStep3Container />
        </StyledConnectSources>
      </VStack>

      <HStack gap="8px" w={"100%"}>
        <Button
          onClick={() => handlePrevStep()}
          flexBasis={"180px"}
          flexShrink={0}
          w={"100%"}
          size={"lg"}
          leftIcon={<Icon fill={"white"} as={ArrowLeft} w="16px" height="16px" />}
        >
          Back
        </Button>
        <Button
          w={"100%"}
          type="submit"
          variant={"primary"}
          size={"lg"}
          onClick={() => handleNextStep()}
          data-test-id={"onboarding-continue"}
        >
          Continue to dashboard
        </Button>
      </HStack>

      <Text size={"small"} pt={"24px"} alignSelf={"start"}>
        {symbols.lock} We never share or sell your{" "}
        <Link
          href="https://www.sanlo.ai/terms-of-use#non-disclosure-of-your-confidential-information"
          textDecoration={"underline"}
          isExternal
          onClick={() => {
            trackGTMEvent({
              event: ONBOARDING_ACTIONS.CONNECT_SOURCE_DATA_LINK,
            });
          }}
        >
          data
        </Link>
        .
      </Text>
    </VStack>
  );
};
