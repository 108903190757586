import {
  getErrorStatus,
  getRequestStatus,
  getResponseStatus,
  integrationInitialState,
  setStatusDefaults,
  stateSet,
  parseIntegration,
} from "@utils/redux";

import { GET_INTEGRATIONS_CONNECTIONS } from "../base/actions";

import {
  GOOGLE_PLAY_RETRIEVE_SERVICE_ACCOUNT,
  GOOGLE_PLAY_SAVE_CREDENTIALS,
  GOOGLE_PLAY_UNLINK_INTEGRATION,
} from "./actions";

const initialState = integrationInitialState({
  id: "googleplay",
  name: "Google play",
  platformId: "googleplay",
  type: "in-app-purchases",
  category: "platforms",
  company: "Google",
  image: require("@assets/img/icons/logos/GooglePlayRoundedLogo.svg").default,
  isPlatform: true,
  isAdPlatform: false,
  isDisabled: true,
  requestStatus: {
    getData: setStatusDefaults(),
    unlinkIntegration: setStatusDefaults(),
    retrieveServiceAccount: setStatusDefaults(),
    saveCredentials: setStatusDefaults(),
  },
  credentials: {
    companyId: null,
    serviceAccountName: "",
    bucketId: "not-set",
  },
  steps: [
    {
      title: "Connect to google play console",
      subtitle: "Start by copying the Sanlo generated service account and pasting it in google play console.",
    },
    {
      title: "Enter cloud storage URI",
      subtitle: "Enter your cloud storage URI to start syncing data your apps.",
    },
    {
      // Congrats step
      title: null,
      subtitle: null,
      hideProgress: true,
      isSetupDone: true,
    },
  ],
});

const googlePlayReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INTEGRATIONS_CONNECTIONS.RESPONSE: {
      return {
        ...state,
        ...parseIntegration("googleplay", action.response),
        name: state.name, // Keep the display name we have set in the intial state
      };
    }

    case GOOGLE_PLAY_RETRIEVE_SERVICE_ACCOUNT.REQUEST: {
      return stateSet(state, {
        "requestStatus.retrieveServiceAccount": getRequestStatus(),
      });
    }

    case GOOGLE_PLAY_RETRIEVE_SERVICE_ACCOUNT.RESPONSE: {
      return stateSet(state, {
        "requestStatus.retrieveServiceAccount": getResponseStatus(),
        "credentials": action.response,
        "isDisabled": false,
      });
    }

    case GOOGLE_PLAY_RETRIEVE_SERVICE_ACCOUNT.ERROR: {
      return stateSet(state, {
        "requestStatus.retrieveServiceAccount": getErrorStatus(),
      });
    }

    case GOOGLE_PLAY_SAVE_CREDENTIALS.REQUEST: {
      return stateSet(state, {
        "requestStatus.saveCredentials": getRequestStatus(),
      });
    }

    case GOOGLE_PLAY_SAVE_CREDENTIALS.RESPONSE: {
      return stateSet(state, {
        "requestStatus.saveCredentials": getResponseStatus(),
        "credentials.bucketId": "valid",
      });
    }

    case GOOGLE_PLAY_SAVE_CREDENTIALS.ERROR: {
      return stateSet(state, {
        "requestStatus.saveCredentials": getErrorStatus(),
        "credentials.bucketId": "invalid",
      });
    }

    case GOOGLE_PLAY_UNLINK_INTEGRATION.REQUEST: {
      return stateSet(state, {
        "requestStatus.unlinkIntegration": getRequestStatus(),
      });
    }

    case GOOGLE_PLAY_UNLINK_INTEGRATION.RESPONSE: {
      return stateSet(state, {
        "requestStatus.unlinkIntegration": getResponseStatus(),
      });
    }

    case GOOGLE_PLAY_UNLINK_INTEGRATION.ERROR: {
      return stateSet(state, {
        "requestStatus.unlinkIntegration": getErrorStatus(),
      });
    }

    default:
      return state;
  }
};

export default googlePlayReducer;
