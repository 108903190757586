import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const sizes = {
  maxContent: defineStyle({
    maxWidth: "max-content",
  }),
};
export const Tooltip = defineStyleConfig({
  baseStyle: {
    fontFamily: "body",
    fontWeight: 400,
    backgroundColor: "dark.400",
    color: "textDefault.400",
    padding: "12px",
    borderRadius: "12px",
    boxShadow: "0px 0px 48px 0px rgba(0, 0, 0, 0.40)",
  },
  sizes,
});
