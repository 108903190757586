import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getResponseStatus, getRequestStatus, getErrorStatus, setStatusDefaults } from "@utils/redux";

import UASpendService from "./uaSpendApi";

const initialState = {
  uaSpend: null,
  requestStatus: setStatusDefaults(),
};

// Since we are only getting Appsflyer UA Spend we want to use this for simplicity.
export const uaSpendThunk = {
  getUASpend: createAsyncThunk("uaSpend/getUASpend", async ({ fromDate, toDate }) => {
    const res = await UASpendService.appsFlyerUASpend({ fromDate, toDate });
    return res.data;
  }),
};

export const UASpendSlice = createSlice({
  name: "uaSpend",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(uaSpendThunk.getUASpend.pending, (state) => {
        state.requestStatus = getRequestStatus();
      })
      .addCase(uaSpendThunk.getUASpend.fulfilled, (state, action) => {
        state.requestStatus = getResponseStatus();
        state.uaSpend = action.payload;
      })
      .addCase(uaSpendThunk.getUASpend.rejected, (state) => {
        state.requestStatus = getErrorStatus();
      });
  },
});

export const uaSpendSelector = (state) => state.uaSpend;
export default UASpendSlice.reducer;
