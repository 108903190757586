import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { checkLoading, getResponseStatus, getRequestStatus, getErrorStatus, setStatusDefaults } from "@utils/redux";

import SettingsApi from "./settingsApi";

import { errorWrapper } from "@redux/http";

export const settingsThunks = {
  getSettings: createAsyncThunk("webshops/settings/getSettings", async ({ webshopId }, { rejectWithValue }) => {
    return errorWrapper(async () => {
      const res = await SettingsApi.getSettings(webshopId);
      return res.data;
    }, rejectWithValue);
  }),
  addWebhookUrl: createAsyncThunk(
    "webshops/settings/addWebhookUrl",
    async ({ data, webshopId }, { rejectWithValue }) => {
      return errorWrapper(async () => {
        const res = await SettingsApi.addWebhookUrl(data, webshopId);
        return res.data;
      }, rejectWithValue);
    },
  ),
  generateHMACKey: createAsyncThunk("webshops/generateHMACKey", async ({ webshopId }, { rejectWithValue }) => {
    return errorWrapper(async () => {
      const res = await SettingsApi.generateHMACKey(webshopId);
      return res.data;
    }, rejectWithValue);
  }),
  generateSDKToken: createAsyncThunk("webshops/generateSDKToken", async ({ webshopId }, { rejectWithValue }) => {
    return errorWrapper(async () => {
      const res = await SettingsApi.generateSDKToken(webshopId);
      return res.data;
    }, rejectWithValue);
  }),
  setSiteDetails: createAsyncThunk("webshops/setSiteDetails", async ({ data, webshopId }, { rejectWithValue }) => {
    return errorWrapper(async () => {
      const res = await SettingsApi.setSiteDetails(data, webshopId);
      return res.data;
    }, rejectWithValue);
  }),
  setPurchaseSettings: createAsyncThunk(
    "webshops/setPurchaseSettings",
    async ({ data, webshopId }, { rejectWithValue }) => {
      return errorWrapper(async () => {
        const res = await SettingsApi.setPurchaseSettings(data, webshopId);
        return res.data;
      }, rejectWithValue);
    },
  ),
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    settings: {
      authWebhookUrl: "",
    },
    hmacKey: {
      data: {
        metadata: null,
        value: null,
      },
      requestStatus: setStatusDefaults(),
    },
    sdkToken: {
      requestStatus: setStatusDefaults(),
    },
    setPurchaseSettingsStatus: setStatusDefaults(),
    setSiteDetailsStatus: setStatusDefaults(),
    getSettingsStatus: setStatusDefaults(),
    addWebhookUrlStatus: setStatusDefaults(),
  },
  reducers: {
    cleanHMACKeyRequestStatus: (state) => {
      state.hmacKey.requestStatus = setStatusDefaults();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(settingsThunks.getSettings.pending, (state) => {
        state.getSettingsStatus = getRequestStatus();
      })
      .addCase(settingsThunks.getSettings.fulfilled, (state, action) => {
        state.getSettingsStatus = getResponseStatus();
        state.settings = action.payload;
      })
      .addCase(settingsThunks.getSettings.rejected, (state) => {
        state.getSettingsStatus = getErrorStatus();
      })
      .addCase(settingsThunks.addWebhookUrl.pending, (state) => {
        state.addWebhookUrlStatus = getRequestStatus();
      })
      .addCase(settingsThunks.addWebhookUrl.fulfilled, (state, action) => {
        state.addWebhookUrlStatus = getResponseStatus();
        state.settings.authWebhookUrl = action.payload.authWebhookUrl;
      })
      .addCase(settingsThunks.addWebhookUrl.rejected, (state) => {
        state.addWebhookUrlStatus = getErrorStatus();
      })
      /*******************************************************************************
       * Generate Authentication Secret
       *******************************************************************************/
      .addCase(settingsThunks.generateHMACKey.pending, (state) => {
        state.hmacKey.requestStatus = getRequestStatus();
      })
      .addCase(settingsThunks.generateHMACKey.fulfilled, (state, action) => {
        const { payload = {} } = action;
        state.hmacKey.data = payload;
        state.hmacKey.requestStatus = getResponseStatus();
      })
      .addCase(settingsThunks.generateHMACKey.rejected, (state, action) => {
        state.hmacKey.requestStatus = getErrorStatus();
      })
      /*******************************************************************************
       * Generate SDK Client Token
       *******************************************************************************/
      .addCase(settingsThunks.generateSDKToken.pending, (state) => {
        state.sdkToken.requestStatus = getRequestStatus();
      })
      .addCase(settingsThunks.generateSDKToken.fulfilled, (state, action) => {
        const { payload = {} } = action;
        state.settings = payload;
        state.sdkToken.requestStatus = getResponseStatus();
      })
      .addCase(settingsThunks.generateSDKToken.rejected, (state, action) => {
        state.sdkToken.requestStatus = getErrorStatus();
      })
      /*******************************************************************************
       * Update Site Details Settings
       *******************************************************************************/
      .addCase(settingsThunks.setSiteDetails.pending, (state) => {
        state.setSiteDetailsStatus = getRequestStatus();
      })
      .addCase(settingsThunks.setSiteDetails.fulfilled, (state, action) => {
        const { payload = {} } = action;
        state.settings = payload;
        state.setSiteDetailsStatus = getResponseStatus();
      })
      .addCase(settingsThunks.setSiteDetails.rejected, (state) => {
        state.setSiteDetailsStatus = getErrorStatus();
      })
      /*******************************************************************************
       * Set Purchase Webhook
       *******************************************************************************/
      .addCase(settingsThunks.setPurchaseSettings.pending, (state) => {
        state.setPurchaseSettingsStatus = getRequestStatus();
      })
      .addCase(settingsThunks.setPurchaseSettings.fulfilled, (state, action) => {
        const { payload = {} } = action;
        state.settings = payload;
        state.setPurchaseSettingsStatus = getResponseStatus();
      })
      .addCase(settingsThunks.setPurchaseSettings.rejected, (state) => {
        state.setPurchaseSettingsStatus = getErrorStatus();
      });
  },
});

// bunch of settings
export const settingsSelector = (state) => state.webshops.settings.settings;
export const generatedHMACKeySelector = (state) => state.webshops.settings.settings.hmacKey;
export const sdkTokenSelector = (state) => state.webshops.settings.settings.sdkToken;

export const settingsLoadingSelector = (state) =>
  checkLoading(state.webshops.settings.settings, {
    includeIdle: true,
  });

export const { cleanHMACKeyRequestStatus } = settingsSlice.actions;

export default settingsSlice.reducer;
