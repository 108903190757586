const { useEffect, useState, useRef, useCallback } = require("react");

const MAX_CHECKS = 5;

export const useHubspotChat = () => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [activeConversation, setActiveConversation] = useState(false);

  const intervalRef = useRef(null);
  const eventRef = useRef(null);

  const clear = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = null;
  };

  useEffect(() => {
    // Add event listener.
    window.HubSpotConversations?.on("widgetLoaded", () => {
      setHasLoaded(true);
    });

    if (window.HubSpotConversations?.widget) {
      const status = window.HubSpotConversations.widget.status();
      if (status && status?.loaded) {
        setHasLoaded(true);
      }
    }

    return () => {
      window.hsConversationsOnReady = [];
    };
  }, []);

  // Subscripe to conversation events.
  useEffect(() => {
    eventRef.current = (payload) => {
      setActiveConversation(payload.conversation.conversationId);
    };

    if (hasLoaded) {
      window.HubSpotConversations?.on("conversationStarted", eventRef.current);
    }

    return () => {
      window.HubSpotConversations?.off("conversationStarted", eventRef.current);
    };
  }, [hasLoaded]);

  const openHandler = useCallback(() => {
    if (!hasLoaded) return;
    window.HubSpotConversations?.widget.open();
  }, [hasLoaded]);

  const closeHandler = useCallback(() => {
    if (!hasLoaded) return;
    window.HubSpotConversations?.widget.close();
  }, [hasLoaded]);

  const loadHandler = useCallback(() => {
    clear();
    window.HubSpotConversations?.widget.load();
  }, []);

  const removeHandler = useCallback(() => {
    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.remove();
      return;
    }
    let checks = 0;
    intervalRef.current = setInterval(() => {
      if (checks > MAX_CHECKS) {
        clear();
        return;
      }
      if (window.HubSpotConversations) {
        window.HubSpotConversations.widget.remove();
        clear();
      }
      checks += 1;
    }, 1000);
  }, []);

  return {
    hasLoaded,
    activeConversation,
    openHandler,
    closeHandler,
    loadHandler,
    removeHandler,
  };
};
