import session from "./session/actions";
import earnings from "./earnings/actions";
import banking from "./banking/actions";
import offers from "./offers/actions";

// Integrations
import baseActions from "./integrations/base/actions";
import adjustActions from "./integrations/adjust/actions";
import advancesActions from "./integrations/advances/actions";
import appsFlyerActions from "./integrations/appsFlyer/actions";
import appStoreActions from "./integrations/appStore/actions";
import googlePlayActions from "./integrations/googlePlay/actions";
import plaidActions from "./integrations/plaid/actions";

export default {
  session,
  earnings,
  banking,
  offers,
  integrations: {
    base: baseActions,
    adjust: adjustActions,
    advances: advancesActions,
    appsflyer: appsFlyerActions,
    appstore: appStoreActions,
    googleplay: googlePlayActions,
    plaid: plaidActions,
  },
};
