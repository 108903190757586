import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as UpArrow } from "@assets/img/icons/common/arrow-up.svg";
import { ReactComponent as DownArrow } from "@assets/img/icons/common/arrow-down.svg";
import { financialsThunks, lastMonthNetBurnSelector } from "@redux/financials/financialsSlice";
import symbols from "@src/utils/symbols";
import { Flex, Icon, Text, VStack } from "@chakra-ui/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { FINANCIALS_ACTION, trackGTMEvent } from "@src/utils/gtm";
import { scrollToTarget } from "@src/utils/general";
import { StatCard } from "@common/stat-card";
import { useEffect } from "react";
import { CollapsableTooltipBody } from "@common/tooltip/Tooltip";
import { trackCollapseContent } from "@src/events/financial-intelligence/tooltipEvents";
import { formatPercentage } from "@src/utils/numbers";
import { FINANCIAL_INTELLIGENCE_CARD_ID } from "@src/consts/financial-intelligence";
import { ReconnectTooltip } from "@utils/financial-intelligence";
import { getBankingBlurData } from "@utils/blur";

export function NetBurnLastMonthCard() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { cashBurnChartEnable = false } = useFlags();
  const integrations = useSelector((state) => state.integrations);
  const { isPartiallyHealthy } = getBankingBlurData(integrations.plaid.data.institutions);
  const lastMonthNetBurn = useSelector(lastMonthNetBurnSelector);
  const lastMonthNetBurnBalance = Number(lastMonthNetBurn?.netMonthlyBurnCents ?? 0) / 100;
  const isLoading = !lastMonthNetBurn;
  const isDashboard = location.pathname === "/dashboard";

  const NET_BURN_LAST_MONTH = "Net income / burn last month";

  useEffect(() => {
    dispatch(financialsThunks.getMonthlyReport());
  }, [dispatch]);

  const netBurnLastMonthInfo = {
    id: FINANCIAL_INTELLIGENCE_CARD_ID.NET_BURN_LAST_MONTH,
    title: `${symbols.flame} ${NET_BURN_LAST_MONTH}`,
    currentBalance: lastMonthNetBurnBalance,
    rightIcon: lastMonthNetBurn?.netMonthlyBurnDeltaBps ? (
      <Flex>
        {Math.sign(lastMonthNetBurn?.netMonthlyBurnDeltaBps) === -1 ? (
          <Icon as={DownArrow} width={"16px"} height={"16px"} color={"textDefault.400"} />
        ) : (
          <Icon as={UpArrow} width={"16px"} height={"16px"} color={"textDefault.400"} />
        )}
        <Text size="small">{formatPercentage(lastMonthNetBurn?.netMonthlyBurnDeltaBps / 100)}</Text>
      </Flex>
    ) : null,
    comingSoon: !cashBurnChartEnable,
    onClick: () => {
      trackGTMEvent({
        event: FINANCIALS_ACTION.VIEW_DETAILS,
        card: NET_BURN_LAST_MONTH,
      });
      if (isDashboard) {
        const newLocation = {
          pathname: "/financial-analytics",
          state: {
            prevPath: location.pathname,
          },
        };
        navigate(newLocation);
        setTimeout(() => {
          scrollToTarget("cash-burn-chart");
        }, 100);
      } else {
        scrollToTarget("cash-burn-chart");
      }
    },
    tooltip: {
      id: "stat-card-net-burn-last-month",
      content: ({ isCollapseOpen, onToggle }) => (
        <>
          <VStack alignItems={"flex-start"} gap={"10px"}>
            <Text size="mini">This is your net cash flow in the last completed calendar month.</Text>
            <Text size="mini">Formula = Revenue - Cash out</Text>
          </VStack>

          <CollapsableTooltipBody
            label={`${symbols.lightBulb} Why it matters?`}
            labelColor="brandElectricBlue.400"
            isCollapseOpen={isCollapseOpen}
            onToggle={() => {
              trackCollapseContent({
                name: NET_BURN_LAST_MONTH,
                isOpen: isCollapseOpen,
                label: "Why it matters?",
              });
              onToggle();
            }}
          >
            <Text size="mini">
              Net burn is a key metric that shows how quickly you are spending your cash reserves to cover your costs.
              This metric is often used to understand how your finances are trending, and if you need to focus on
              cutting costs, updating your revenue targets, or getting capital. A positive value indicates net income,
              while a negative value indicates net burn.
            </Text>
          </CollapsableTooltipBody>
        </>
      ),
    },
    unhealthyTooltip: isPartiallyHealthy
      ? {
          id: "unhealthy-net-burn-last-month-tooltip",
          content: <ReconnectTooltip source={"banks"} />,
        }
      : null,
  };

  return <StatCard data-test-id="net-burn-last-month-card" isLoading={isLoading} {...netBurnLastMonthInfo} />;
}
