import HTTP from "@redux/http";
const BASE = `/api/integrations/manual`;

class ManualConnectionService {
  getAll() {
    // Param needed for request success
    return HTTP.req.get(`${BASE}/?offset=0`);
  }

  getPlatforms() {
    return HTTP.req.get(`${BASE}/platforms`);
  }

  create(platformId = "") {
    return HTTP.req.post(BASE, { platformId });
  }

  complete(platformId = "") {
    return HTTP.req.patch(`${BASE}/${platformId}/complete`);
  }

  delete(platformId = "") {
    return HTTP.req.delete(`${BASE}/${platformId}`);
  }

  getEmail(platformId = "") {
    return HTTP.req.get(`${BASE}/${platformId}/invite-email`);
  }
}

export default new ManualConnectionService();
