import HTTP from "@redux/http";

const SANLO_WEB_API_URL = process.env.REACT_APP_SANLO_WEB_API_URL;
const BASE = `${SANLO_WEB_API_URL}/api/fairground/console/webshops`;

class CustomDomainService {
  setCustomDomain(data, webshopId) {
    return HTTP.req.post(`${BASE}/${webshopId}/storefront/settings/custom-domains`, data);
  }

  verifyCustomDomain(webshopId) {
    return HTTP.req.post(`${BASE}/${webshopId}/storefront/settings/custom-domains/verify`);
  }

  deleteCustomDomain(webshopId) {
    return HTTP.req.delete(`${BASE}/${webshopId}/storefront/settings/custom-domains`);
  }
}

export default new CustomDomainService();
