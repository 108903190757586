import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { BodyMini12, BodyLargePlus, BodySmall14, BodySmallPlus, TitleRegularPlus } from "@styled";
import SuggestIntegrationContainer from "@common/integrations/SuggestIntegrationContainer";
import { StyledPlatformPickerModal } from "./PlatformPicker.styled";
import PrimaryButton from "@common/buttons/primary-button/PrimaryButton";
import StateButton from "@components/common/buttons/state-button/StateButton";

import symbols from "@utils/symbols";
import { getIntegrationState } from "@utils/integrations";
import { filterIntegrations } from "@utils/integrations";

import { ReactComponent as CrossIcon } from "@assets/img/icons/common/modal-cross.svg";

const PlatformPickerModal = ({
  selectableCategories,
  onModalClose,
  onOptionConnectClick,
  integrations,
  categories,
}) => {
  const navigate = useNavigate();
  const mouseIn = useRef(false);

  const onWindowClick = (e) => {
    if (!mouseIn.current) {
      onModalClose();
    }
  };

  useEffect(() => {
    window.addEventListener("click", onWindowClick);
    return () => {
      window.removeEventListener("click", onWindowClick);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <StyledPlatformPickerModal>
      <div
        className="dr-modal"
        onMouseEnter={() => {
          mouseIn.current = true;
        }}
        onMouseLeave={() => {
          mouseIn.current = false;
        }}
      >
        <div className="dr-modal-body">
          <button
            aria-label="Close"
            className="dr-modal-close"
            data-dismiss="modal"
            type="button"
            onClick={() => {
              onModalClose();
            }}
          >
            <CrossIcon aria-hidden={true} />
          </button>
          <div className="dr-modal-title">
            <TitleRegularPlus>Select Platform to Connect</TitleRegularPlus>
          </div>
          <BodySmall14 className="dr-modal-subtitle">
            You can manage all connections on the{" "}
            <span
              className="dr-modal-link"
              onClick={() => {
                navigate("/integrations");
              }}
            >
              Integrations
            </span>{" "}
            page.
          </BodySmall14>

          {selectableCategories.map((category) => {
            const { title, description, categoryType } = categories[category];

            return (
              <div key={`picker-selected-category-${category}`} className="dr-modal-category">
                <div className="dr-modal-category-title">
                  <BodyLargePlus>{title}</BodyLargePlus>
                </div>
                <div className="dr-modal-options">
                  <div className="dr-modal-description">{description}</div>
                  {filterIntegrations(integrations, categoryType).map((integration) => {
                    const { id, name, image: icon, status } = integration;

                    const { integrationsButtonLabel, state, hasAction, hover } = getIntegrationState(status);

                    // TODO - Only Adjust is in the coming soon state right now so this check
                    // and the coming soon text below are Adjust specific right now
                    const comingSoon = id === "adjust";

                    return (
                      <div key={`${id}-platform-picker-button`} className="dr-modal-option">
                        <div className="dr-option-body">
                          <img className="dr-option-icon" src={icon} alt={name} />
                          <BodySmallPlus className="dr-option-title">{name}</BodySmallPlus>
                          <div className="dr-option-action">
                            <StateButton
                              comingSoon={comingSoon}
                              className="dr-option-action-button"
                              hover={hover}
                              integration
                              state={state}
                              label={integrationsButtonLabel}
                              onClick={() => onOptionConnectClick(integration, hasAction)}
                            />
                          </div>
                        </div>
                        {comingSoon && (
                          <div className="dr-option-coming-soon">
                            <BodyMini12>
                              {symbols.Eyes()} We are adding support for Adjust to enable ad revenue tracking. You can
                              still monitor user acquisition spend with your connected Adjust account.
                            </BodyMini12>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>

                <SuggestIntegrationContainer className="p1-rbl" suggestionType={categoryType} />
              </div>
            );
          })}

          <div className="dr-modal-footer">
            <PrimaryButton
              className="dr-modal-done"
              label="Done"
              onClick={(e) => {
                onModalClose();
              }}
            />
          </div>
        </div>
      </div>
    </StyledPlatformPickerModal>
  );
};

export default PlatformPickerModal;
