import styled from "styled-components";

const StyledPlatformLinkingContent = styled.div`
  & {
    .description-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 16px;

      &.as-row {
        flex-direction: row;

        .platform-description {
          width: 81%;
        }
      }

      .platform-description {
        color: ${({ theme }) => theme.textGray};
      }

      .secondary-description {
        margin-top: 8px;
        color: ${({ theme }) => theme.textSubtext};
      }

      .inverted-button {
        border: none;
        cursor: pointer;
        min-width: 84px;
        padding: 12px 16px;
        background-color: ${({ theme }) => theme.grayscale50};
        border-radius: 10px;
        color: ${({ theme }) => theme.colorRed};
        white-space: nowrap;

        > div {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
        }
      }
    }

    .platforms-container {
      margin-top: 16px;
      display: flex;

      .platform-logo-wrapper {
        position: relative;
        cursor: pointer;
        background-color: ${({ theme }) => theme.grayscale50};
        padding: 8px;
        border-radius: 12px;
        width: 58px;
        height: 58px;
        margin-right: 16px;

        &.is-disabled {
          opacity: 0.5;
          cursor: default;
        }

        &:last-child {
          margin-right: 0;
        }

        .platform-logo img {
          width: 42px;
          height: 42px;
          border-radius: 6px;
        }

        .disabled-logo-overlay {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background-color: ${({ theme }) => theme.grayscale20}66;

          .overlay-icon {
            opacity: 1;
            width: 20px;
            height: 20px;
            fill: ${({ theme }) => theme.colorYellow};
          }
        }
      }
    }

    .draft-connected-platform {
      margin-top: 16px;
    }

    .secondary-description-container {
      display: flex;
      margin-top: 16px;
      color: ${({ theme }) => theme.textSubtext};
    }

    .loading-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: ${({ theme }) => theme.textGray};

      .loading-spinner {
        width: 4rem;
        height: 4rem;

        svg,
        path,
        g {
          fill: ${({ theme }) => theme.colorRed} !important;
        }
      }
    }

    .divider {
      border: 1px solid ${({ theme }) => theme.grayscale60};
    }
  }
`;

export default StyledPlatformLinkingContent;
