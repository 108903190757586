import { HStack, Icon, IconButton, Text, Tooltip, useClipboard } from "@chakra-ui/react";
import { BaseCard } from "./BaseCard";
import { ReactComponent as CopyIcon } from "@assets/img/icons/common/copy.svg";
import { useParams } from "react-router-dom";

export function ProjectId() {
  const { id: projectId } = useParams();

  const { onCopy, hasCopied } = useClipboard(projectId);

  if (!projectId) return null;

  return (
    <BaseCard title={"Project ID"} id={"project_id"}>
      <Tooltip label={"Copy"} p={"12px"} bgColor={"dark.400"}>
        <HStack
          w={"full"}
          p={"12px"}
          borderRadius={"10px"}
          justifyContent={"space-between"}
          border={"1px solid"}
          borderColor={"dark.300"}
          onClick={onCopy}
        >
          <Text size={"small"} color={"textSubtext.400"}>
            {projectId}
          </Text>

          {hasCopied ? (
            <Text size={"small"} color={"textSubtext.400"}>
              Copied!
            </Text>
          ) : (
            <IconButton
              h={"fit-content"}
              minW={"fit-content"}
              lineHeight={"1"}
              icon={<Icon w={"16px"} h={"16px"} as={CopyIcon} />}
              variant={"unstyled"}
              onClick={onCopy}
            />
          )}
        </HStack>
      </Tooltip>
    </BaseCard>
  );
}
