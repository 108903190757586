import React from "react";
import { HStack } from "@chakra-ui/react";

import { BaseSection, SectionText, SectionIcon } from "@components/builder/SidebarTabs";

import { ReactComponent as AlignBottom } from "@assets/img/icons/common/alignbottom.svg";

export const FooterSidebar = ({ selected = false, onClick = () => {} }) => {
  return (
    <BaseSection
      selected={selected}
      style={{
        border: "1px solid transparent",
        borderTop: selected ? "1px solid inherit" : "none",
        borderRadius: 0,
        borderBottomRadius: "10px",
        borderColor: selected ? "brandPurple.400" : "dark.300",
      }}
    >
      <HStack padding="1rem" onClick={onClick}>
        <SectionIcon icon={AlignBottom} selected={selected} />
        <SectionText>Footer</SectionText>
      </HStack>
    </BaseSection>
  );
};
