import React from "react";
import { Flex } from "@chakra-ui/react";

export const BarWrapper = ({ id = "", isInflow = false, withNegYAxis = false, children }) => {
  const justifyContent = isInflow ? "end" : withNegYAxis ? "start" : "end";
  return (
    <Flex
      id={id}
      height={withNegYAxis ? "50%" : "100%"}
      flexDirection={"column"}
      justifyContent={justifyContent}
      position={"relative"}
      width={"24px"}
      marginRight={withNegYAxis ? "0" : "2px"}
    >
      {children}
    </Flex>
  );
};
