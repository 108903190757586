import React, { useMemo, useState } from "react";
import StyledTransactionsHistory from "./StyledTransactionsHistory";
import TransactionRow from "./TransactionRow";
import Pagination from "../../common/pagination/Pagination";

const PageSize = 6;

const TransactionsHistory = ({ advance, historicalTransactions, advanceId }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const visibleData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return historicalTransactions.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, historicalTransactions]);

  return (
    <StyledTransactionsHistory>
      <div className="transactions-history-header">
        <div className="header-col">
          <span className="header-label">Date</span>
        </div>
        <div className="header-col">
          <span className="header-label">Amount (USD)</span>
        </div>
        <div className="header-col">
          <span className="header-label">Bank Account</span>
        </div>
        <div className="header-col">
          <div className="right-aligned">
            <span className="header-label">Status</span>
          </div>
        </div>
      </div>

      <div className="transactions-pagination-container">
        <div
          className={
            "transactions-history-container " + (historicalTransactions.length > PageSize ? "max-page-size" : "")
          }
        >
          {visibleData.map((transaction, index) => {
            const { payDate, transactionFrom } = transaction;
            return (
              <TransactionRow
                key={`key_thr_${transactionFrom}_${payDate}_${currentPage}_${index}`}
                advance={advance}
                tooltipId={`tt-txs-${index}-${advanceId}`}
                transaction={transaction}
              />
            );
          })}
        </div>
        <Pagination
          currentPage={currentPage}
          totalCount={historicalTransactions.length}
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </StyledTransactionsHistory>
  );
};

export default TransactionsHistory;
