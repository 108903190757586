import React from "react";
import { ReactComponent as CrossIcon } from "@assets/img/icons/common/modal-cross.svg";
import StyledRegularModal from "./StyledRegularModal";
import { TitleRegularPlus } from "@styled";
import classnames from "classnames";
const RegularModal = ({ isOpen, handleToggle, title, content, width, hasChip }) => {
  return (
    <StyledRegularModal
      centered
      width={width}
      scrollable={false}
      isOpen={isOpen}
      toggle={handleToggle}
      fade={false}
      className={classnames("middle-aligned", {
        "has-chip": hasChip,
      })}
    >
      <div className="modal-header">
        {title && <TitleRegularPlus className="modal-title">{title}</TitleRegularPlus>}
        <button aria-label="Close" data-dismiss="close" type="button" className="close" onClick={() => handleToggle()}>
          <CrossIcon aria-hidden={true} />
        </button>
      </div>
      <div className="modal-content-wrapper">{content}</div>
    </StyledRegularModal>
  );
};

export default RegularModal;
