import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withSectionData } from "../withSectionData";
import { Box, FormControl, HStack, Icon, IconButton, Input, Text, Image, VStack, useClipboard } from "@chakra-ui/react";

import { StylePicker } from "../../StylePicker";
import { ItemSelector, SELECTOR_TYPES } from "@components/catalog";

import { BackgroundStylePicker } from "../BackgroundStylePicker";
import { PropertiesHeader, SectionWrapper, COLOR_TYPES } from "@utils/webshop/section";
import { generatePayloads, getButtonLabel } from "./utils";

import { useSectionUpdate } from "@src/utils/custom-hooks/useSectionUpdate";
import { createSectionLink } from "../Items/utils";
import { toKebabCase } from "@src/utils/string";
import { ReactComponent as CopyIcon } from "@src/assets/img/icons/common/copy.svg";
import { useFlags } from "launchdarkly-react-client-sdk";
import ErrorBoundary from "@components/ErrorBoundary";

const FeaturedPropertiesComponent = ({
  data,
  catalog,
  series,
  webshop,
  theme,
  onFileCreate,
  onFileUpdate,
  onFileRemove,
  onThemeChange,
  onThemeReset,
  onChange,
}) => (
  <FeaturedProperties
    data={data}
    catalog={catalog}
    series={series}
    webshop={webshop}
    theme={theme}
    onFileCreate={onFileCreate}
    onFileUpdate={onFileUpdate}
    onFileRemove={onFileRemove}
    onThemeChange={onThemeChange}
    onThemeReset={onThemeReset}
    onChange={onChange}
  />
);

FeaturedPropertiesComponent.propTypes = {
  data: PropTypes.object,
  catalog: PropTypes.object,
  series: PropTypes.object,
  webshop: PropTypes.object,
  theme: PropTypes.object,
  onFileCreate: PropTypes.func,
  onFileUpdate: PropTypes.func,
  onFileRemove: PropTypes.func,
  onThemeChange: PropTypes.func,
  onThemeReset: PropTypes.func,
  onChange: PropTypes.func,
};

const useFeaturedSectionUpdate = (webshopId, sectionId, data) => {
  const [onUpdate] = useSectionUpdate(webshopId, sectionId, data, generatePayloads);
  return { onChange: onUpdate };
};

export const FeaturedPropertiesContainer = withSectionData(FeaturedPropertiesComponent, useFeaturedSectionUpdate);

export const FeaturedProperties = ({
  data = {},
  catalog = {},
  series = {},
  webshop = {},
  theme = {},
  onChange = () => {},
  onFileCreate = () => {},
  onFileUpdate = () => {},
  onFileRemove = () => {},
  onThemeChange = () => {},
  onThemeReset = () => {},
}) => {
  const { offerSeries } = useFlags();
  const [title, setTitle] = useState(() => data?.title || "");
  const { setValue, onCopy, hasCopied } = useClipboard();

  const { item = null, series: sectionSeries = null } = data;
  const { itemButtonLabel, seriesButtonLabel } = getButtonLabel({ item, sectionSeries });
  const { data: { items = [], tiers: priceTiersMap = {} } = {}, unassignedItems: { data: unassignedItems = [] } = {} } =
    catalog;
  const { data: allSeries = [] } = series;

  useEffect(() => {
    const url = createSectionLink(webshop.domain, title);
    if (url) setValue(url);
    return () => {
      setValue("");
    };
  }, [setValue, title, webshop.domain]);

  return (
    <Box>
      <PropertiesHeader />

      <SectionWrapper title="Styles" onReset={onThemeReset}>
        <StylePicker
          target="featured_theme_primary"
          title="Primary"
          presets={theme.presets}
          initialColor={theme.primary}
          onColorChange={(colorObj) => {
            onThemeChange({
              ...colorObj,
              targetColor: COLOR_TYPES.PRIMARY,
            });
          }}
        />
        <StylePicker
          target="featured_theme_secondary"
          title="Secondary"
          presets={theme.presets}
          initialColor={theme.secondary}
          onColorChange={(colorObj) => {
            onThemeChange({
              ...colorObj,
              targetColor: COLOR_TYPES.SECONDARY,
            });
          }}
        />
        <BackgroundStylePicker
          checkLegacyBg
          image
          target="featured_theme_background"
          theme={theme}
          data={data}
          onThemeChange={onThemeChange}
          onFileCreate={onFileCreate}
          onFileUpdate={onFileUpdate}
          onFileRemove={onFileRemove}
        />
      </SectionWrapper>

      <SectionWrapper title="Title">
        <Box padding="12px">
          <FormControl>
            <Input
              value={title}
              placeholder="Featured Title"
              onChange={(event) => {
                onChange({
                  title: event.target.value,
                });
                setTitle(event.target.value);
              }}
            />
          </FormControl>
        </Box>

        <StylePicker
          target={`featured_${data.id}_title_color`}
          title="Color"
          presets={theme.presets}
          initialColor={theme.title}
          onColorChange={(colorObj) => {
            onThemeChange({
              ...colorObj,
              targetColor: COLOR_TYPES.TITLE,
            });
          }}
        />

        {title && (
          <Box p={"12px"}>
            <Text color={"textDefault.400"} mb="12px">
              Anchor link
            </Text>
            <HStack gap={"12px"} alignItems={"center"} p={"8px"} bg="dark.400" borderRadius={"10px"}>
              <Text flex={1} noOfLines={1} color={"textSubtext.400"}>
                {!hasCopied ? `#${toKebabCase(title)}` : "Copied!"}
              </Text>
              <IconButton
                aria-label="copy link"
                variant={"unstyled"}
                size={"sm"}
                color={hasCopied ? "textWhite.400" : "textSubtext.400"}
                icon={<Icon as={CopyIcon} />}
                onClick={onCopy}
              />
            </HStack>
          </Box>
        )}
      </SectionWrapper>

      <SectionWrapper title={"Content"}>
        <VStack with={"100%"} mt={"8px"} pl={"12px"} pr={"12px"} gap={"24px"}>
          <ErrorBoundary show={item}>
            <HStack w={"100%"} alignItems={"center"} justifyContent={"center"} gap={"8px"}>
              <Image borderRadius={"3px"} w={"32px"} h={"32px"} src={item?.imageUrl || ""} />
              <Text overflow={"hidden"} textOverflow={"ellipsis"} whiteSpace={"nowrap"} flex={1}>
                {sectionSeries ? sectionSeries.name : item?.name || ""}
              </Text>
            </HStack>
          </ErrorBoundary>

          {/* for items */}
          <ItemSelector
            type={SELECTOR_TYPES.ITEMS}
            data={{ items: offerSeries ? unassignedItems : items, priceTiersMap }}
            selected={sectionSeries ? null : item}
            onSelect={(item) => {
              onChange({
                item,
                series: null,
              });
            }}
            showFilter={true}
            buttonLabel={itemButtonLabel}
            buttonStyles={{ variant: "solid", color: "white", width: "100%" }}
          />

          {/* for series */}
          <ErrorBoundary show={offerSeries}>
            <ItemSelector
              type={SELECTOR_TYPES.SERIES}
              data={{ items: allSeries.filter((series) => series.items.length > 0) }}
              selected={sectionSeries}
              onSelect={(series) => {
                onChange({
                  item: series.currentItem,
                  series,
                });
              }}
              title={"Select series"}
              showFilter={true}
              buttonLabel={seriesButtonLabel}
              buttonStyles={{ variant: "solid", color: "white", width: "100%" }}
            />
          </ErrorBoundary>
        </VStack>
      </SectionWrapper>
    </Box>
  );
};

FeaturedProperties.propTypes = {
  data: PropTypes.object,
  catalog: PropTypes.object,
  series: PropTypes.object,
  webshop: PropTypes.object,
  theme: PropTypes.object,
  onChange: PropTypes.func,
  onFileCreate: PropTypes.func,
  onFileUpdate: PropTypes.func,
  onFileRemove: PropTypes.func,
  onThemeChange: PropTypes.func,
  onThemeReset: PropTypes.func,
};
