import { Button, useTheme } from "@chakra-ui/react";
import { motion } from "framer-motion";

export function GradientButton({ children, ...props }) {
  const theme = useTheme();

  const linearGradient = `
  linear-gradient(269deg,
  ${theme.colors.brandPurple[400]} -4.84%,
  ${theme.colors.brandRed[400]} 115%)
  `;

  return (
    <Button
      as={motion.button}
      fontWeight={600}
      borderRadius={"0.5rem"}
      height={"32px"}
      color={"black"}
      initial={{
        backgroundSize: "400% 100%",
        backgroundPosition: "0% 0%",
        backgroundImage: linearGradient,
      }}
      whileHover={
        props.isDisabled
          ? {}
          : {
              backgroundPosition: ["0% 0%", "100% 0%", "0% 0%"],
              transition: { duration: 1.25, repeat: Infinity, repeatType: "reverse" },
            }
      }
      {...props}
    >
      {children}
    </Button>
  );
}
