import React from "react";
import PropTypes from "prop-types";
import { withSectionData } from "../withSectionData";
import { Box } from "@chakra-ui/react";

import { StylePicker } from "../../StylePicker";
import { BackgroundStylePicker } from "../BackgroundStylePicker";
import { PropertiesHeader, SectionWrapper, getImage, FILE_TYPES } from "@utils/webshop/section";

const BannerPropertiesComponent = ({
  data,
  theme,
  onFileCreate,
  onFileUpdate,
  onFileRemove,
  onThemeChange,
  onThemeReset,
}) => (
  <BannerProperties
    data={data}
    theme={theme}
    onFileCreate={onFileCreate}
    onFileUpdate={onFileUpdate}
    onFileRemove={onFileRemove}
    onThemeChange={onThemeChange}
    onThemeReset={onThemeReset}
  />
);

BannerPropertiesComponent.propTypes = {
  data: PropTypes.object,
  theme: PropTypes.object,
  onFileCreate: PropTypes.func,
  onFileUpdate: PropTypes.func,
  onFileRemove: PropTypes.func,
  onThemeChange: PropTypes.func,
  onThemeReset: PropTypes.func,
};

export const BannerPropertiesContainer = withSectionData(BannerPropertiesComponent);

export const BannerProperties = ({
  data = {},
  theme = {},
  onFileCreate = () => {},
  onFileUpdate = () => {},
  onFileRemove = () => {},
  onThemeChange = () => {},
  onThemeReset = () => {},
}) => {
  const banner_image = getImage(FILE_TYPES.MAIN, data?.files);

  return (
    <Box>
      <PropertiesHeader />

      <SectionWrapper title="Styles" onReset={onThemeReset}>
        <BackgroundStylePicker
          image
          theme={theme}
          data={data}
          onThemeChange={onThemeChange}
          onFileCreate={onFileCreate}
          onFileUpdate={onFileUpdate}
          onFileRemove={onFileRemove}
        />

        <StylePicker
          imageOnly
          noOverlay
          target="banner_theme_primary"
          title="Banner"
          initialImage={banner_image.url}
          onImageChange={(file) => {
            onFileCreate({
              ...file,
              target: FILE_TYPES.MAIN,
            });
          }}
          onImageUpdate={(file) => {
            onFileUpdate({
              ...file,
              target: FILE_TYPES.MAIN,
            });
          }}
          onImageRemove={() => {
            onFileRemove({
              target: FILE_TYPES.MAIN,
            });
          }}
        />
      </SectionWrapper>
    </Box>
  );
};

BannerProperties.propTypes = {
  data: PropTypes.object,
  theme: PropTypes.object,
  onFileCreate: PropTypes.func,
  onFileUpdate: PropTypes.func,
  onFileRemove: PropTypes.func,
  onThemeChange: PropTypes.func,
  onThemeReset: PropTypes.func,
};
