import { Box } from "@chakra-ui/react";
import { viewSelector } from "@redux/webshops/checkout-builder/checkoutSlice";
import { SanloCheckoutPreview } from "@sanlo/checkout-web";
import { useFormContext, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
const ITEM = {
  id: "27957f0d-3b9f-43c5-aa9e-625f70bd2ef1",
  name: "Chicken Warrior",
  description: "code ZTW01EAywZ",
  externalId: "deep-link",
  internalId: "7272647b-4fa0-4071-9ca4-0a1bb433d6e8",
  pricingTierLevelId: "884b1608-5bd3-4330-bb39-694ee9629c13",
  price: {
    currency: "USD",
    symbol: "$",
    amount: "2.09",
    tax: "0.42",
    total: "2.51",
  },
  deepLinkCode: null,
  imageUrl:
    "https://storage.googleapis.com/sanlo-public-game-assets-dev/db469189-0645-401d-b27f-1e5ba75f89f6/788e7fc8-3a6a-4495-a8eb-241494370add/item/4f68b85c-ff3d-4c34-bbbf-b0625f4764b0/1721933627",
  available: true,
  startTime: "2024-07-25T18:00:52.464",
  endTime: null,
  totalQuantity: null,
  remainingQuantity: null,
  quantityPerPlayer: null,
  playerPurchaseCount: null,
  badge: null,
  category: {
    id: "af38d8eb-1d21-4f13-a318-8dfd4f2acf00",
    name: "web-offer",
  },
  seriesId: null,
  recurrenceInterval: null,
  playerSegmentIds: [],
  order: 0,
};

const PAYMENT_METHODS = [
  {
    name: "Cards",
    type: "scheme",
    paymentKey: "cards",
  },
  {
    name: "Affirm",
    type: "affirm",
    paymentKey: "affirm",
  },
  {
    name: "Cash App Pay",
    type: "cashapp",
    paymentKey: "cashapppay",
  },
  {
    name: "DOKU",
    type: "doku",
    paymentKey: "doku",
  },
  {
    name: "Dragonpay",
    type: "dragonpay",
    paymentKey: "dragonpay",
  },
  {
    name: "Google Pay",
    type: "googlepay",
    paymentKey: "googlepay",
  },
];

export function Builder({ logo }) {
  const { control } = useFormContext();
  const { primary, secondary, paymentSectionBgColor, summarySectionBgColor } = useWatch({ control });
  const { isMobile } = useSelector(viewSelector);

  return (
    <Box maxH={!isMobile ? "724px" : "100%"} h="100%" maxW={!isMobile ? "960px" : "414px"} m="auto" w="100%">
      <SanloCheckoutPreview
        initView={false}
        user={{
          email: "fake@email.com",
          zip: "12345",
        }}
        item={ITEM}
        priceData={{
          price: {
            label: "Sub Total:",
            amount: "$ 2.09",
          },
          tax: {
            label: " Tax:",
            amount: "$ 0.42",
          },
          vat: null,
          total: {
            label: " Total:",
            amount: "$ 2.51",
          },
        }}
        paymentMethods={PAYMENT_METHODS}
        style={{
          logo,
          colors: {
            primary,
            secondary,
          },
          backgrounds: {
            payments: paymentSectionBgColor,
            summary: summarySectionBgColor,
          },
        }}
      />
    </Box>
  );
}
