import styled from "styled-components";

const StyledPrimaryButton = styled.button`
  & {
    width: 100%;
    background-color: ${({ theme, secondary }) => (!secondary ? theme.primaryColor : theme.grayscale40)};
    padding: 12px 16px;
    border-radius: 10px;
    border: none;

    .button-label {
      text-align: center;
      color: ${({ theme, secondary }) => (!secondary ? theme.grayscale0 : theme.textSeashellWhite)};
      white-space: nowrap;
    }

    .button-loader {
      height: 16px;

      & > svg {
        fill: ${({ theme }) => theme.grayscale0};
        height: 16px;
      }
    }

    :hover:enabled {
      background-color: ${({ theme, secondary }) => (!secondary ? theme.primaryHover : theme.grayscale50)};
    }

    :disabled {
      cursor: not-allowed;
      opacity: 0.4;
    }
  }
`;

export default StyledPrimaryButton;
