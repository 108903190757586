import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { SanloDropdown } from "@inputs";

import { financialsThunks } from "@src/redux/financials/financialsSlice";

import { FINANCIALS_ACTION } from "@utils/gtm";

export const MonthlyDropdown = () => {
  const dispatch = useDispatch();

  const cashFlowBreakdown = useSelector((state) => state.financials.getCashflowBreakdown);
  const { data, initialLoadComplete = false } = cashFlowBreakdown;

  const { metadata = {} } = data;
  const { availableFilters = [] } = metadata;

  if (!initialLoadComplete) return null;

  // initialOption is set to index 1 because the first option is always
  // the current in progress month, and we always want the last full month initially,
  // which is what the second option always is. The base response with no
  // params returns the data for last full month.

  return (
    <SanloDropdown
      options={availableFilters}
      initialOption={availableFilters[0]}
      onSelect={(option) => {
        dispatch(financialsThunks.getCashflowBreakdown(option));
      }}
      gtmOptionEvent={FINANCIALS_ACTION.BREAKDOWN_MONTHLY_DROPDOWN}
      style={{
        flex: "1 0 140px",
        maxWidth: "fit-content",
      }}
    />
  );
};
