import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import classnames from "classnames";

import { ReactComponent as BackArrow } from "@assets/img/icons/common/arrow_left.svg";

import { AcknowledgeBankModal } from "@components/common/modals/acknowledge-bank-modal/AcknowledgeBankModal";
import RocketProgressBar from "@components/onboarding/rocket-progress-bar/RocketProgressBar";
import { BodyMini12, BodySmall14, BodySmallPlus, TitleRegularPlus } from "@components/styled";
import Support from "@components/support/Support";
import { SanloButton } from "@inputs";

import { show } from "@redux/integrations/bank/acknowledgeBankModalSlice";
import useIntegrationsStatus from "@utils/custom-hooks/useIntegrationsStatus";
import { getBrandingInfo } from "@utils/branding";

import { formatPlatformLinked, getConnectedIntegrations, seeBankingSection } from "./utils";
import StyledStepsProgressContainer from "./StyledStepsProgressContainer";

const StepsProgressContainer = ({
  stepNumber,
  previousStep,
  onChangeStep = () => {},
  companyName = "",
  progress = {},
  children,
  title = "",
  description = "",
  buttonLabel = "Continue",
  platformsLinked = {},
  onboarding = {},
  requestCompleted = false,
  stepZero,
  stepZeroContent,
}) => {
  const dispatch = useDispatch();

  const { isBranded } = getBrandingInfo();

  const { requiredPlatforms = [], requestStatus = {}, data = {} } = onboarding;
  const { saveData = {} } = requestStatus;
  const integrationsStatus = useIntegrationsStatus();
  const platformsLinkedUpdated = useMemo(
    () => formatPlatformLinked(platformsLinked, integrationsStatus.banking.connected),
    [integrationsStatus.banking.connected, platformsLinked],
  );
  const connectedIntegrations = getConnectedIntegrations(platformsLinkedUpdated);

  const canGotoDashboard =
    data.onboardingType === "PRIMARY"
      ? Object.keys(platformsLinkedUpdated).some((p) => platformsLinkedUpdated[p] && requiredPlatforms.includes(p))
      : true;

  const showAnalytics = () => {
    onChangeStep(4, true);
  };

  const goBack = () => {
    onChangeStep(stepNumber - 1);
  };

  const goForward = (toDashboard) => {
    // if(!toDashboard && canGotoDashboard && connectedIntegrations.oneRequired && !connectedIntegrations.allRequired){
    if (
      stepNumber === 3 &&
      !toDashboard &&
      canGotoDashboard &&
      connectedIntegrations.oneRequired &&
      !connectedIntegrations.allRequired
    ) {
      dispatch(show({ continueToBanking: seeBankingSection, continueToDashboard: () => goForward(true) }));
    } else {
      onChangeStep(stepNumber + 1);
    }
  };

  if (!requestCompleted) return null;

  if (stepZero) return stepZeroContent;

  return (
    <StyledStepsProgressContainer
      className={classnames("os-main-container", {
        "is-branded": isBranded,
      })}
    >
      <div className="os-top-actions">
        <BackArrow onClick={goBack} className="back-arrow" />
        <div className="os-progress-bar-content">
          <div className="os-progress-bar-description">
            <BodyMini12 className="os-company-profile-power">{`${companyName} profile power`}</BodyMini12>
            <BodyMini12 className="os-progress-bar-percentage">{progress.combined}%</BodyMini12>
          </div>
          <RocketProgressBar
            step={stepNumber}
            lastStep={previousStep}
            currentProgress={progress}
            pillAmount={data.onboardingType === "PRIMARY" ? 3 : 1}
          />
        </div>
      </div>

      <div className="os-header">
        <div className="os-title">
          <TitleRegularPlus>{title}</TitleRegularPlus>
        </div>
        <BodySmall14 className="os-description">{description}</BodySmall14>
      </div>

      <div className="os-content-wrapper" id="platforms-accordion-list">
        {children}
      </div>

      <SanloButton
        className="os-continue-button"
        label={buttonLabel}
        isLoading={saveData.pending}
        onClick={() => goForward(false)}
        isDisabled={!canGotoDashboard && stepNumber >= 3}
        gtmEventTag={`tag-sanlo-onboarding-step${stepNumber}-continue`}
        testId="onboarding-continue"
      />

      <div className="os-divider" />

      <div className="os-footer">
        {!isBranded && (
          <BodySmallPlus>
            {stepNumber !== 4 ? (
              <>
                Just interested in Sanlo Analytics?{" "}
                <span
                  onClick={showAnalytics}
                  className={`os-button-label tag-sanlo-onboarding-step${stepNumber}-analytics`}
                >
                  Click here
                </span>
              </>
            ) : (
              <>
                Have any questions? <Support className="os-button-label" />
              </>
            )}
          </BodySmallPlus>
        )}
        {isBranded && (
          <BodySmallPlus className="analytics-message">
            Have any questions? <Support className="ml_half" red bold />
          </BodySmallPlus>
        )}
      </div>

      {/* Acknowledge Bank Data Modal */}
      <AcknowledgeBankModal />
    </StyledStepsProgressContainer>
  );
};

export default StepsProgressContainer;
