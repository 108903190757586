import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getResponseStatus, getRequestStatus, getErrorStatus, setStatusDefaults } from "@utils/redux";

import PaymentsService from "./paymentsApi";

const initialState = {
  upcomings: [],
  requestStatus: setStatusDefaults(),
};

export const paymentsThunks = {
  getUpcomingPayments: createAsyncThunk("payments/getUpcomingPayments", async () => {
    const res = await PaymentsService.getUpcomingPayments();
    return res.data;
  }),
};

export const PaymentsSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(paymentsThunks.getUpcomingPayments.pending, (state) => {
        state.requestStatus = getRequestStatus();
      })
      .addCase(paymentsThunks.getUpcomingPayments.fulfilled, (state, action) => {
        state.requestStatus = getResponseStatus();
        state.upcomings = action.payload;
      })
      .addCase(paymentsThunks.getUpcomingPayments.rejected, (state) => {
        state.requestStatus = getErrorStatus();
      });
  },
});

export const paymentsSelector = (state) => state.payments;
export default PaymentsSlice.reducer;
