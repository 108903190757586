import React from "react";

import { StepActionsContainer } from "@common/animated/FadeStepsContainer";
import SetupGuide from "@components/common/modals/integrations-modal/SetupGuide";
import { StyledGuideList } from "@components/common/modals/integrations-modal/StyledSetupGuide";

import { SanloButton, SanloInput, Link, Support } from "@inputs";
import { ErrorMessage } from "@components/integrations/connection-guide/ErrorMessage";
import { Callout } from "@components/integrations/connection-guide/Callout";
import { COMMON_ACTION, APPSTORE_ACTION } from "@utils/gtm";

import symbols from "@utils/symbols";

export const AppStoreStep1 = ({
  platform = {
    id: "appstore",
    name: "App store",
    platformId: "appstore",
  },
  appleId = "",
  setAppleId = () => {},
  appSpecificPassword = "",
  setAppSpecificPassword = () => {},
  saveCredentialsRequestStatus = {},
  credentialsError = "",
  connectToAppStore = () => {},
  trackEvent = () => {},
}) => {
  return (
    <StepActionsContainer>
      <div className="fs-modal-actions">
        <ErrorMessage error={credentialsError} />

        <SanloInput
          hasFocus
          isJumbo
          label="Apple ID"
          placeholder="Enter your Apple ID"
          value={appleId}
          setValue={setAppleId}
          testId={`${platform.platformId}-apple-id-input`}
          style={{
            marginBottom: "24px",
          }}
        />

        <SanloInput
          isJumbo
          label="App-Specific password"
          placeholder="Enter app-specific password"
          value={appSpecificPassword}
          setValue={setAppSpecificPassword}
          testId={`${platform.platformId}-app-specific-password-input`}
          style={{
            marginBottom: "24px",
          }}
        />

        <SanloButton
          isJumbo
          isLoading={saveCredentialsRequestStatus?.pending}
          isDisabled={appleId.length === 0 || appSpecificPassword.length === 0}
          label="Continue"
          onClick={() => {
            trackEvent(COMMON_ACTION.CONTINUE);
            connectToAppStore();
          }}
          gtmEventTag={`tag-sanlo-${platform.platformId}-connect-to-${platform.platformId}-continue`}
          testId={`${platform.platformId}-connect-button`}
        />
      </div>

      <div className="fs-modal-instructions-integrations">
        <SetupGuide
          platformStep={`${platform.name}0`}
          step={{
            title: "1. Generate an app-specific password",
            description: (
              <StyledGuideList>
                <li>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://appleid.apple.com/"
                    className={`modal-link setup-guide-link tag-sanlo-setup-${platform.platformId}-link`}
                    onClick={() => {
                      trackEvent(COMMON_ACTION.LOG_IN);
                    }}
                  >
                    Log in
                  </a>{" "}
                  with your apple ID.
                </li>
                <li>
                  Once logged in, find the “app-specific passwords” section under security and click “Generate
                  Password...”
                </li>
              </StyledGuideList>
            ),
            callout: (
              <Callout>
                {symbols.LightBulb()} Before you get started, please make sure you have an{" "}
                <Link
                  href="https://appstoreconnect.apple.com/login"
                  textDecoration="underline"
                  onClick={() => trackEvent(APPSTORE_ACTION.ADMIN_OR_FINANCIAL_LINK)}
                  isExternalLink
                >
                  Admin or Finance
                </Link>{" "}
                role for your {platform.name} account. Have questions about connection?{" "}
                <Support
                  text="Get in touch"
                  className={`setup-guide-link tag-sanlo-contact-us-${platform.platformId}`}
                  onClick={() => {
                    trackEvent(COMMON_ACTION.GET_IN_TOUCH);
                  }}
                />{" "}
                with us.
              </Callout>
            ),
            images: [require("./assets/app_store_guide_1.gif")],
            multiple: [
              {
                title: "2. Confirm your password",
                description: (
                  <StyledGuideList>
                    <li>Before the ID is generated you need to confirm your App-Specific password.</li>
                  </StyledGuideList>
                ),
                images: [require("./assets/app_store_guide_2.gif")],
              },
              {
                title: "3. Copy your app-specific password",
                description: (
                  <StyledGuideList>
                    <li>Highlight your app-specific password and copy it to your clipboard.</li>
                    <li>
                      Paste the password on the left {symbols.PointLeft()} into App-Specific password input field.
                    </li>
                    <li>You should be all set, click continue on the left.</li>
                  </StyledGuideList>
                ),
                images: [require("./assets/app_store_guide_3.gif")],
              },
            ],
          }}
        />
      </div>
    </StepActionsContainer>
  );
};
