import React from "react";
import { ReactComponent as ArrowIcon } from "@assets/img/icons/common/accordion-arrow.svg";
import StyledPagination from "./StyledPagination";
import { usePagination, DOTS } from "../../../utils/custom-hooks/usePagination";
import { BodySmall14 } from "../../styled";

const Pagination = (props) => {
  const { onPageChange, totalCount, siblingCount = 1, currentPage, pageSize } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  const lastPage = paginationRange[paginationRange.length - 1];

  const previousDisabled = currentPage === 1;
  const nextDisabled = currentPage === lastPage;

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onPrevious = () => {
    if (!previousDisabled) {
      onPageChange(currentPage - 1);
    }
  };

  const onNext = () => {
    if (!nextDisabled) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <StyledPagination>
      <div onClick={onPrevious} className={"pagination-arrow " + (previousDisabled ? "pagination-disabled" : "")}>
        <ArrowIcon className="prev-page-icon" />
      </div>

      {paginationRange.map((pageNumber, index) => {
        // Render dots
        if (pageNumber === DOTS) {
          // Check for last pageNumber before dots to
          // get the next pageNumber that should display
          const dotPageNumber = paginationRange[index - 1];
          return (
            <BodySmall14
              key={`dots_${pageNumber}_${index}`}
              onClick={() => onPageChange(dotPageNumber + 1)}
              className="pagination-number"
            >
              &#8230;
            </BodySmall14>
          );
        }

        // Render numbers
        return (
          <div
            key={`page_${pageNumber}_${index}`}
            onClick={() => onPageChange(pageNumber)}
            className={"pagination-number " + (currentPage === pageNumber ? "current-number" : "")}
          >
            {pageNumber}
          </div>
        );
      })}

      <div onClick={onNext} className={"pagination-arrow " + (nextDisabled ? "pagination-disabled" : "")}>
        <ArrowIcon className="next-page-icon" />
      </div>
    </StyledPagination>
  );
};

export default Pagination;
