import React, { useState } from "react";

import { StepActionsContainer } from "@common/animated/FadeStepsContainer";
import { StyledPermissionGuideContainer } from "@components/common/modals/permission-modal/PermissionModal.styles";
import useShadowScroll from "@utils/custom-hooks/useShadowScroll";

import { StyledPermissionModal } from "@components/common/modals/permission-modal/PermissionModal.styles";
import { SanloButton, SanloCheckbox, Support, CopyButton } from "@inputs";
import {
  Step,
  Callout,
  Navigation,
  GuideList,
  StepTitle,
  GuideLink,
  GuideMedia,
  StepDescription,
  GuideActions,
  GuideMoreDetails,
  StepInfo,
} from "@components/integrations/connection-guide";

import symbols from "@utils/symbols";

export const FirebaseStep1 = ({
  platform = {
    id: "firebase",
    name: "Firebase",
    platformId: "firebase",
  },
  email = "",
  onClose = () => {},
  onComplete = () => {},
  trackEvent = () => {},
}) => {
  const [requiredAccess, setRequiredAccess] = useState(false);

  const [wrapperRef, contentRef, shadowBottomRef, shadowTopRef] = useShadowScroll(false);

  return (
    <StepActionsContainer>
      <StyledPermissionModal isOpen fade={false} scrollable={false}>
        <div className="leftGradModal" />
        <div className="rightGradModal" />
        <div className="fs-modal-container">
          <Navigation onClose={onClose} platformName={platform.name} />

          <div
            style={{
              height: "80%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <StyledPermissionGuideContainer>
              <div ref={shadowTopRef} className="shadow shadow--top" />
              <div ref={shadowBottomRef} className="shadow shadow--bottom" />

              <div className="step-wrapper" ref={wrapperRef}>
                <div ref={contentRef} className="step-container">
                  <Step>
                    <StepTitle stepNumber={1}>
                      Log into your{" "}
                      <GuideLink
                        label={platform.name}
                        href="https://console.firebase.google.com/u/0/project/fir-sharing-test/settings/iam?pli=1"
                      />{" "}
                      account and complete the steps below:
                    </StepTitle>

                    <StepDescription>
                      <GuideList>
                        <li>
                          1.1 Select the project to which you are giving Sanlo access.
                          <Callout
                            style={{
                              marginTop: "8px",
                            }}
                          >
                            {symbols.LightBulb()} It's important that you share with us all the apps that generate
                            significant revenue, as we need this information to make an informed decision on your
                            underwriting. <Support text="Contact us" red />
                          </Callout>
                        </li>
                        <li>
                          1.2 Click on settings icon next to <b>“Project Overview"</b>, and select{" "}
                          <b>"Users and permissions"</b>.
                        </li>
                        <li>
                          1.3 Click <b>"Add member"</b> and provide the following details:
                          <ol>
                            <li>
                              Email address: <b>{email}</b> <CopyButton text={email} />
                            </li>
                            <li>
                              Role: <b>Viewer</b>
                            </li>
                          </ol>
                        </li>
                        <li>
                          1.4 Click <b>"Add member"</b> to submit.
                        </li>
                        <li>
                          Optional: If you need to provide access to multiple projects, please repeat the above steps
                          for each project.
                        </li>
                      </GuideList>
                    </StepDescription>
                  </Step>

                  <Step>
                    <StepTitle stepNumber={2}>
                      Check the box below and click "Complete" button to finish setup {symbols.PointDown()}
                    </StepTitle>
                  </Step>

                  <GuideActions>
                    <SanloCheckbox
                      checked={requiredAccess}
                      toggleCheckbox={() => {
                        setRequiredAccess(!requiredAccess);
                      }}
                      text={`I have invited and granted Sanlo access to my ${platform.name} access.`}
                      testId={`${platform.id}-granted-checkbox`}
                    />

                    <SanloButton
                      isJumbo
                      isDisabled={!requiredAccess}
                      label="Complete"
                      onClick={onComplete}
                      testId={`${platform.id}-granted-complete-button`}
                    />

                    <GuideMoreDetails
                      platform={platform}
                      href="https://support.google.com/firebase/answer/7000272?hl=en"
                      trackEvent={trackEvent}
                    />
                  </GuideActions>
                </div>
              </div>

              <StepInfo platform={platform}>
                <GuideMedia
                  mediaSlider={[
                    require(`./assets/${platform.id}-step1-guide1.png`),
                    require(`./assets/${platform.id}-step1-guide2.png`),
                    require(`./assets/${platform.id}-step1-guide3.png`),
                  ]}
                  platformName={platform.name}
                />
              </StepInfo>
            </StyledPermissionGuideContainer>
          </div>
        </div>

        <div id="toast-bg-fade" />
        <div id="bottom-toast">Copied to Clipboard</div>
      </StyledPermissionModal>
    </StepActionsContainer>
  );
};
