import styled from "styled-components";

const StyledAdvanceRow = styled.div`
  & {
    margin-bottom: 16px;
    margin-left: -24px;
    margin-right: -24px;
    padding: 16px 24px;
    width: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.3s ease;
    cursor: pointer;

    :last-child {
      margin-bottom: 0;
    }

    :hover {
      background-color: ${({ theme }) => theme.grayscale40};
    }

    .advance-row-left,
    .advance-row-right {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .advance-row-left {
      padding-right: 24px;
      width: 30%;
    }

    .advance-row-right {
      width: 70%;
    }

    .advance-amount {
      width: fit-content;
      min-width: 85px;
    }

    .advance-game-info {
      display: flex;
      align-items: center;

      img {
        width: 25px;
        height: 25px;
        margin-right: 8px;
        border-radius: 4px;
      }

      .game-name {
        color: ${({ theme }) => theme.textGray};
        width: 120px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    .advance-account-info {
      width: fit-content;
      color: ${({ theme }) => theme.textGray};
    }

    .advance-progress-bar {
      display: flex;
      align-items: center;
      width: 59%;

      > :first-child {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
      }

      .arrow-icon {
        margin-left: 12px;
        transform: rotate(90deg);
        width: 20px;
        height: 20px;
        fill: ${({ theme }) => theme.textGray};
      }
    }
  }
`;

export default StyledAdvanceRow;
