import { Box } from "@chakra-ui/react";

import { LoadingScreen } from "@components/common/loaders";
import { webshopsThunks } from "@redux/webshops/webshopsSlice";
import { NEW_PROJECT } from "@src/consts/webshops/new-project";
import { useLocalStorage } from "@src/utils/custom-hooks/useLocalStorage";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Navigate, useLoaderData, useLocation, useNavigate } from "react-router-dom";

export function NewProject() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [createdWebshop, setCreatedWebshop] = useState(null);
  const { code: googleAuthCode } = useLoaderData();
  const [{ selectedPlatform, appId, name }] = useLocalStorage(NEW_PROJECT, {});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

  const createWebshop = useCallback(async () => {
    try {
      const newProjectData = { selectedPlatform, appId, name };
      let response;
      if (state) {
        response = await dispatch(
          webshopsThunks.createWebshops({
            source: state.selectedPlatform,
            appId: state.appId,
            name: state.name,
            auth: {
              issuerId: state.issuerId,
              keyId: state.keyId,
              key: encodeURIComponent(state.p8KeyFile),
            },
          }),
        ).unwrap();
      } else {
        response = await dispatch(
          webshopsThunks.createWebshops({
            source: newProjectData?.selectedPlatform,
            appId: newProjectData?.appId,
            name: newProjectData?.name,
            auth: {
              code: googleAuthCode,
            },
          }),
        ).unwrap();
      }
      if (response) {
        setIsLoaded(true);
        setCreatedWebshop(response);
      }
    } catch (e) {
      setHasError(true);
    }
  }, [googleAuthCode, dispatch, selectedPlatform, appId, name, state]);

  useEffect(() => {
    createWebshop();
  }, [createWebshop]);

  useEffect(() => {
    if (createdWebshop) {
      setTimeout(() => {
        navigate(`/webshops/projects/${createdWebshop.id}/builder`, { replace: true });
        // remove the code from local storage
        localStorage.removeItem(NEW_PROJECT);
      }, 1500);
    }
  }, [createdWebshop, navigate]);

  if (hasError) {
    return <Navigate to="/webshops/projects" replace />;
  }

  return (
    <Box pos={"fixed"} zIndex={2}>
      <LoadingScreen
        style={{ position: "fixed", zIndex: "2", inset: 0 }}
        notifyParent={() => {}}
        loaded={isLoaded}
        text="Launching your Webshop!"
      />
    </Box>
  );
}
