import { Box, FormControl, FormLabel, Textarea } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";

export function PasswordForm() {
  const { register } = useFormContext();

  return (
    <Box>
      <FormControl>
        <FormLabel fontSize={"14px"}>Instructions to customer</FormLabel>
        <Textarea {...register("instructionText")} variant={"builder"} h="72px" placeholder="Optional" />
      </FormControl>
    </Box>
  );
}
