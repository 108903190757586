import * as React from "react";
import moment from "moment";
import { toast } from "react-toastify";

import { SanloToast } from "@components/common/toasts/sanlo-toast/SanloToast";

import { toastOptions } from "@utils/defaults/toast";
import { copy } from "@utils/general";
import { getHighestPriorityStatus } from "@utils/integrations";

import { PRODUCT_ANALYTICS } from "@utils/consts";
import { getPriorityStatus, CONNECTED, NOT_CONNECTED, PULLING_DATA } from "@utils/status";

export const NAME_CONVERSION = {
  googleplay: "googleplay",
  appstore: "appstore",
  appsflyer: "appsflyer",
};

export const showToast = (message, type = "success", icon = null) => {
  if (!icon) icon = type;
  toast[type](() => <SanloToast icon={icon} msg={message} />, toastOptions);
};

export const checkLoading = (data = {}, opts = {}) => {
  const { requestStatus = {} } = data;
  const { includeIdle = false } = opts;
  const { idle = false, pending = false, completed = false } = requestStatus;
  let loading = false;
  if (pending && !completed) loading = true;
  if (includeIdle && idle) loading = true;
  return loading;
};

export const checkForStatus = (statuses = [], payload = {}) => {
  // Payload can either be response or error payload
  // - if it's an error, we need to make sure we move one layer to the response
  // Make sure the statuses are an array
  if (!Array.isArray(statuses)) statuses = [statuses];
  // Move one layer down if an error payload was passed in
  if (!payload.status && payload.response) payload = payload.response;
  if (!payload.status) return false;

  for (let i = 0; i < statuses.length; ++i) {
    if (payload.status === statuses[i]) return true;
  }

  return false;
};

export const checkNoContent = (response = {}) => {
  if (!response || typeof response !== "object") return false;
  if (response.status === 204 || response.statusText === "No Content") return true;
  return false;
};

export const checkNetworkError = (data = {}) => {
  const errorCode = Number(data.error?.response?.status);
  const errorMsg = data.error?.message;
  return errorCode >= 500 || errorCode === 401 || errorMsg === "Network Error";
};

export const configureConsts = (type) => {
  const obj = {};
  ["REQUEST", "RESPONSE", "ERROR", "END", "CLEAR", "ADD", "SET", "REMOVE", "RESET", "EVENT"].forEach((action) => {
    obj[action] = `${action}_${type}`;
  });
  return obj;
};

export const configureActions = (CONSTS, actions = {}) => {
  let request = actions.request;
  if (!request)
    request = () => {
      return {
        type: CONSTS.REQUEST,
      };
    };

  let response = actions.response;
  if (!response)
    response = (response) => {
      return {
        type: CONSTS.RESPONSE,
        response,
      };
    };

  let error = actions.error;
  if (!error)
    error = (error) => {
      return {
        type: CONSTS.ERROR,
        error,
      };
    };

  let set = actions.set;
  if (!set)
    set = () => {
      return {
        type: CONSTS.SET,
      };
    };

  let clear = actions.clear;
  if (!clear)
    clear = () => {
      return {
        type: CONSTS.CLEAR,
      };
    };

  return {
    request,
    response,
    error,
    set,
    clear,
  };
};

export const mutateState = (state, newState) => {
  if (!state) {
    console.error("CLIENT ERROR >> Bad state passed to mutateState");
    return state;
  }
  for (const [key, value] of Object.entries(newState)) {
    state = setNestedValue(state, key, value);
  }
  return state;
};

export const setNestedValue = (state, path, value) => {
  const path_array = path.split(".");
  let stateRef = state;

  for (let i = 0; i < path_array.length; ++i) {
    const field = path_array[i];
    // Is is the final item in the nexted string
    if (field === path_array[path_array.length - 1]) {
      // Check to make sure the item is a legit object
      if (value !== null && typeof value === "object" && !Array.isArray(value)) {
        stateRef[field] = {
          ...stateRef[field],
          ...value,
        };
      } else {
        stateRef[field] = value;
      }
    } else {
      // Else we go further...
      if (stateRef[field]) {
        stateRef = stateRef[field];
      } else {
        console.error("CLIENT ERROR >> Bad path passed to mutateState, printing state...");
        try {
          console.log(JSON.stringify(state, null, 2));
        } catch (e) {
          console.error("CLIENT ERROR >> Couldn't stringify state");
        }
        break; // return state
      }
    }
  }

  return state;
};

export const stateSet = (state, newState) => {
  let updatedState = copy(state);
  for (const [key, value] of Object.entries(newState)) {
    updatedState = setObjValue(updatedState, key, value);
  }
  return updatedState;
};

export const setObjValue = (state, path, value) => {
  const newState = { ...state };
  const path_array = path.split(".");
  let newStateRef = newState;
  let stateRef = state;

  path_array.forEach((field) => {
    if (field === path_array[path_array.length - 1]) {
      if (value !== null && typeof value === "object" && !Array.isArray(value)) {
        const valueCopy = copy(value);
        newStateRef[field] = {
          ...newStateRef[field],
          ...valueCopy,
        };
      } else {
        newStateRef[field] = value;
      }
    } else {
      newStateRef[field] = {
        ...stateRef[field],
      };
      newStateRef = newStateRef[field];
      stateRef = stateRef[field];
    }
  });

  return newState;
};

export const REQUEST_STATUS = {
  idle: false,
  completed: false,
  pending: true,
  error: false,
};

export const RESPONSE_STATUS = {
  idle: false,
  completed: true,
  pending: false,
  error: false,
};

export const ERROR_STATUS = {
  idle: false,
  completed: true,
  pending: false,
  error: true,
};

export const getRequestStatus = () => {
  return {
    ...REQUEST_STATUS,
  };
};

export const getResponseStatus = () => {
  return {
    ...RESPONSE_STATUS,
    lastUpdated: new moment(),
  };
};

export const getErrorStatus = () => {
  return {
    ...ERROR_STATUS,
    lastUpdated: new moment(),
  };
};

export const setStatusDefaults = (opts = {}) => {
  let defaultStatuses = {};
  ["completed", "pending", "error"].forEach((status) => {
    defaultStatuses[status] = opts[status] || false;
  });
  defaultStatuses.idle = true;
  return defaultStatuses;
};

export const integrationInitialState = (opts = {}) => {
  return {
    ...opts,

    id: opts.id || "",
    name: opts.name || "",
    type: opts.type || "-",

    // Info/data specific to the integration
    data: opts.data || {},

    connectionStatus: {
      isConnected: opts.connected || false,
      isPullingData: false,
      isNotConnected: true,
    },

    // MMP only at the moment and only adjust
    canSaveProgress: opts.canSaveProgress || false,

    // Any related "sub-apis" of the integration
    requestStatus: opts.requestStatus || setStatusDefaults(),

    // Any relevant credential data
    credentials: opts.credentials || {},

    // I don't think anything uses this but it comes through in the
    // integrations data, so it'll be there no matter what
    connected: opts.connected || false,

    status: opts.status || NOT_CONNECTED,

    subIntegrations: {
      exists: false,
      highestPriorityStatus: null,
      list: [], // The actual integration objects
    },
  };
};

export const parseIntegration = (integrationID, rawIntegrations = [], opts = {}) => {
  const { currentStatus = "" } = opts;

  // If this function was called with a single integration
  // We want to set it up to be processed the same.
  if (typeof integrationID === "object") {
    rawIntegrations = [copy(integrationID)];
    integrationID = integrationID.name;
  }

  let parsedIntegration = null;
  // A sub-integration is often a status for an important API
  // of an existing integration, but we can't really use one status
  // to represent the entire integration
  const subKey = integrationID + "-";
  const subIntegrations = [];

  rawIntegrations.forEach((integration) => {
    let { name, type, status } = integration;

    if (name.indexOf(subKey) !== -1) {
      subIntegrations.push(integration);
      return;
    }

    if (name !== integrationID) return;

    parsedIntegration = {
      ...integration,
      status: getPriorityStatus(currentStatus, status),
      id: NAME_CONVERSION[name] ? NAME_CONVERSION[name] : name,
      rawId: integration.id,
      platformId: name.toLowerCase().replace(/ /g, ""),
      type: type.match(/game-analytics|MMP/) ? PRODUCT_ANALYTICS : type,
      connectionStatus: {
        isConnected: status === CONNECTED,
        isPullingData: status === PULLING_DATA,
        isNotConnected: status === NOT_CONNECTED,
      },
    };
  });

  if (subIntegrations.length) {
    parsedIntegration = {
      ...parsedIntegration,
      subIntegrations: {
        exists: true,
        highestPriorityStatus: getHighestPriorityStatus(subIntegrations),
        list: copy(subIntegrations),
      },
    };
  }

  return parsedIntegration;
};
