export const hexFormatter = (color) => {
  const hexLess = color.replace(/#/g, "");
  // Services lowercase hexes so this is just for consistency
  const lowwerCased = hexLess.toLowerCase();
  return `#${lowwerCased}`;
};

export const isValidHex = (color) => {
  // Valid hexes can be 3 or 6 characters long, and must start with a #
  const regex = /^#[0-9A-F]{3}([0-9A-F]{3})?$/i;
  return regex.test(color);
};
