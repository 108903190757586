import React, { useState, useRef } from "react";
import styled from "styled-components";

import { BodyMiniPlus, BodyMiniPlusCSS, BodySmallPlusCSS } from "@components/styled";
import { ReactComponent as AccordionArrow } from "@assets/img/icons/common/accordion-arrow.svg";
import { trackGTMEvent, FINANCIALS_ACTION } from "@utils/gtm";

const StyledSelected = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background: #2a2a2f;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
`;

const StyledOptionList = styled(StyledSelected)`
  top: 45px;
  z-index: 1;
  width: ${({ width }) => `${width}px`};
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  cursor: default;
`;

const StyledOption = styled.button`
  ${BodySmallPlusCSS};
  background: #2a2a2f;
  border: none;
  padding: 8px;
  border-radius: 8px;
  color: #c5c5c5;
  cursor: pointer;
  white-space: nowrap;
  width: 100%;

  ${({ selected, theme }) =>
    selected &&
    `
    background-color: ${theme.colorRed};
    color: ${theme.grayscale0};
  `};

  ${({ disabled }) =>
    disabled &&
    `
    cursor: not-allowed;
  `};

  &:not(:first-child) {
    margin-top: 8px;
  }

  & > span {
    ${BodyMiniPlusCSS}
    font-size: 9px;
    padding: 6px;
    background: rgba(130, 128, 255, 0.16);
    border-radius: 4px;
    color: #8280ff;
    margin-left: 4px;
  }
`;

const StyledArrow = styled(({ isOpen, ...rest }) => <AccordionArrow {...rest} />)`
  transform: scale(1.5) rotate(180deg);
  transition: transform 0.2s ease;
  fill: ${({ theme }) => theme.textGray};
  margin-right: 16px;
  width: 12px;
  height: 12px;
  margin-right: 8px;
  margin-left: 8px;
  opacity: 0.7;
  ${({ isOpen }) =>
    isOpen &&
    `
    transform: scale(1.5) rotate(0);
  `};
`;

export const Dropdown = ({ section = {}, selectedView = {}, viewOptions = [], views = [], onSelect = () => {} }) => {
  const { label = "" } = section;

  const [isOpen, setIsOpen] = useState(false);
  const [listWidth, setListWidth] = useState(165);

  const timer = useRef(null);
  const targetId = `id-target-breakdown-${label}-dropdown`;
  const selectedRef = useRef(null);

  let selectedViewOption = {};
  viewOptions.forEach((viewOption) => {
    if (selectedView.id === viewOption.id) selectedViewOption = viewOption;
  });
  if (!selectedViewOption) selectedViewOption = viewOptions[0];

  const onMouseEnterHandler = () => {
    clearTimeout(timer.current);
    timer.current = null;
  };

  const onMouseLeaveHandler = () => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      setListWidth(selectedRef.current.clientWidth);
    }, 500);
  };

  return (
    <>
      <StyledSelected
        data-test-id="option-filter-dropdown"
        ref={selectedRef}
        id={targetId}
        onClick={() => {
          trackGTMEvent({ event: FINANCIALS_ACTION.VIEW_BY_DROPDOWN, state: isOpen ? "Close" : "Open" });
          setIsOpen((prevState) => !prevState);
        }}
        onMouseEnter={onMouseEnterHandler}
        onMouseLeave={onMouseLeaveHandler}
      >
        <BodyMiniPlus style={{ opacity: 0.5 }}>View by: </BodyMiniPlus>
        <BodyMiniPlus style={{ fontSize: "14px" }}>{selectedViewOption.name}</BodyMiniPlus>

        <StyledArrow isOpen={isOpen} />
      </StyledSelected>
      <StyledOptionList
        data-test-id="option-filter-dropdown-list"
        onMouseEnter={onMouseEnterHandler}
        isOpen={isOpen}
        width={listWidth}
      >
        {viewOptions.map((option) => {
          return (
            <StyledOption
              key={`key-dropdown-${option.id}`}
              selected={option.id === selectedViewOption.id}
              onClick={() => {
                let view = null;
                views.forEach((viewItem) => {
                  if (viewItem.id === option.id) view = viewItem;
                });
                trackGTMEvent({ event: FINANCIALS_ACTION.VIEW_BY_OPTION, name: option.name });
                onSelect(option, view);
                setIsOpen(false);
                onMouseLeaveHandler();
              }}
            >
              {option.name}
            </StyledOption>
          );
        })}
        {label === "Revenue" && (
          <StyledOption key={`key-dropdown-product`} disabled>
            Product <span>Coming Soon</span>
          </StyledOption>
        )}
      </StyledOptionList>
    </>
  );
};
