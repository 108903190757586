import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { Button, FormControl, FormLabel, Input, Text, VStack } from "@chakra-ui/react";
import { BaseCard } from "./BaseCard";
import { settingsSelector, settingsThunks } from "@redux/webshops/webshop/settings/settingsSlice";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams } from "react-router-dom";

export const Payments = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const {
    settings: { purchaseWebhookUrl = "", purchaseSuccessMessage = "" },
  } = useSelector(settingsSelector);
  const schema = yup.object({
    purchaseWebhookUrl: yup.string().url("Please enter a valid URL").required("You must enter a URL"),
  });

  const {
    handleSubmit,
    register,
    formState: { errors, isDirty },
  } = useForm({
    values: {
      purchaseWebhookUrl: purchaseWebhookUrl,
      purchaseSuccessMessage: purchaseSuccessMessage,
    },
    resolver: yupResolver(schema),
  });

  function onSubmit(data) {
    dispatch(settingsThunks.setPurchaseSettings({ webshopId: id, data }));
  }

  return (
    <BaseCard title={"Payments"} scrollTarget={"settings-payments"} id={"builder-settings-payments"}>
      <form id={"settings-payments"} onSubmit={handleSubmit(onSubmit)}>
        <VStack gap={"16px"} alignItems={"flex-start"}>
          <FormControl>
            <FormLabel mb={"8px"}>
              <Text size={"small"} letterSpacing={"0.14px"} fontWeight={600} color={"textWhite.400"}>
                URL
              </Text>
            </FormLabel>
            <Input {...register("purchaseWebhookUrl")} />
            {errors.purchaseWebhookUrl && (
              <Text m={"5px 0 0 5px"} color={"brandRed.400"}>
                {errors.purchaseWebhookUrl.message}
              </Text>
            )}
          </FormControl>

          <FormControl>
            <FormLabel mb={"8px"}>
              <Text size={"small"} letterSpacing={"0.14px"} fontWeight={600} color={"textWhite.400"}>
                Post purchase message
              </Text>
            </FormLabel>
            <Input {...register("purchaseSuccessMessage")} placeholder={"Your item has been delivered"} />
          </FormControl>

          <Button
            type={"submit"}
            form={"settings-payments"}
            border={"var(--border-dark-300)"}
            h={"32px"}
            p={"8px 12px"}
            color={"textWhite.400"}
            isDisabled={!isDirty}
            data-test-id={"builder-settings-payments-save-button"}
          >
            Save
          </Button>
        </VStack>
      </form>
    </BaseCard>
  );
};
