import React from "react";
import { useTransition, animated } from "react-spring";
import styled from "styled-components";
import { getBrowserName } from "@utils/general";

export const StepActionsContainer = styled.div`
  display: flex;
  flexdirection: row;
  height: 100%;
`;
const FadeStepsContainer = ({ step, stepComponents }) => {
  const transitions = useTransition(step, {
    initial: { opacity: 1 },
    from: { opacity: 0, transform: `translateX(${step === 1 ? "50%" : "-50%"})` },
    enter: {
      opacity: 1,
      transform: "translateX(0%)",
      animationTimingFunction: "ease-in",
      animationDuration: getBrowserName() === "safari" ? "375ms" : "600ms",
    },
    leave: {
      opacity: 0,
      transform: `translateX(${step === 1 ? "-50%" : "50%"})`,
      animationTimingFunction: "ease-out",
      animationDuration: getBrowserName() === "safari" ? "375ms" : "600ms",
    },
    config: {
      duration: getBrowserName() === "safari" ? 250 : 300,
    },
    exitBeforeEnter: true,
  });

  return transitions((style, step) => <animated.div style={style}>{stepComponents[step]}</animated.div>);
};

export default FadeStepsContainer;
