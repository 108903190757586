import * as Sentry from "@sentry/react";

const isDev = process.env.NODE_ENV === "development" || process.env.REACT_APP_ENV === "local";
export function sentryError(error, errorInfo = null) {
  // only log sentry when environment is not development or local
  if (isDev) {
    return;
  }

  Sentry.withScope((scope) => {
    // setExtras sets an object that will be merged and sent as extra data with the event.
    errorInfo && scope.setExtras(errorInfo);
    Sentry.captureException(error);
  });
}

const regexErrors = [
  /The target 'key_\d+_x_axis_earnings_tooltip_\d+' could not be identified in the dom/,
  /Failed to execute 'send' on 'XMLHttpRequest': Failed to load 'https:\/\/events\.launchdarkly\.com\/events\/bulk\/\d+': Synchronous XHR in page dismissal. See https:\/\/www\.chromestatus\.com\/feature\/\d+ for more details\./,
];

const regexWarnings = [/Network Error/];

export const shouldSendToSentry = (event) => {
  const { exception } = event;
  const hasException = exception?.values?.length > 0;
  return hasException && !regexErrors.some((regex) => regex.test(exception.values[0]?.value));
};

export const adjustEventSeverity = (event) => {
  const { exception } = event;
  const hasException = exception?.values?.length > 0;
  if (hasException && regexWarnings.some((regex) => exception.values.some((value) => regex.test(value.value)))) {
    event.level = "warning";
  }

  return event;
};
