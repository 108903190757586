import { HStack, Text, VStack, UnorderedList, ListItem } from "@chakra-ui/react";
import { TagInput } from "@components/common/tag-input/TagInput";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { associationsThunks, badgesSelector } from "@redux/webshops/associations/associationsSlice";
import { Controller, useFormContext } from "react-hook-form";
import { showToast } from "@src/utils/redux";
import { catalogSelector, setDraftItemProps } from "@redux/webshops/catalog/catalogSlice";

export function Badges() {
  const dispatch = useDispatch();
  const badges = useSelector(badgesSelector);
  const {
    selectedItem,
    draftItem: { badge },
  } = useSelector(catalogSelector);
  const { control } = useFormContext();
  const [options, setOptions] = useState(badges);

  useEffect(() => {
    if (selectedItem) {
      dispatch(setDraftItemProps({ badge: selectedItem.badge }));
    }
  }, [selectedItem, dispatch]);

  // We return the selected badge id to the onChange function to handle the result in the form submission
  async function handleOnChange(event, newValue, prevValue) {
    if (!newValue) {
      dispatch(setDraftItemProps({ badge: null }));
      return null;
    }
    if (typeof newValue === "object") {
      // Here a badge was selected from the dropdown
      dispatch(setDraftItemProps({ badge: newValue }));
      return newValue.id;
    }

    // this means a new badge was entered
    const badgeExists = badges.find((badge) => badge.label === newValue);
    if (badgeExists) {
      dispatch(setDraftItemProps({ badge: badgeExists }));
      return badgeExists.id;
    }

    // http call to create a new badge
    try {
      dispatch(setDraftItemProps({ badge: { label: newValue } }));
      setOptions([...options, { label: newValue }]);
      const newBadge = await dispatch(associationsThunks.createBadge({ label: newValue })).unwrap();
      dispatch(setDraftItemProps({ badge: newBadge }));
      return newBadge.id;
    } catch (error) {
      showToast("There was an error creating the badge", "error");
      dispatch(setDraftItemProps({ badge: null }));
      return null;
    }
  }

  async function handleOnUpdateSelected(item) {
    const { editedItem, newValue } = item;

    if (editedItem) {
      dispatch(setDraftItemProps({ badge: { id: editedItem.id, label: newValue } }));
      try {
        // http call to edit a badge
        await dispatch(associationsThunks.editBadge({ id: editedItem.id, label: newValue })).unwrap();
        // this is for updating the badgeId in the form
        return editedItem.id;
      } catch (error) {
        showToast("There was an error editing the badge", "error");
        return null;
      }
    }
  }

  return (
    <VStack w={"100%"} gap={"10px"}>
      <Controller
        name={"badgeId"}
        control={control}
        /*
				 Why dont we use the value from the controller?
				 because we want to have the badge id as the value of the registered input and the value that expects TagInput is an object
				 with the same signature of options prop
				*/
        render={({ field: { onChange } }) => (
          <TagInput
            id={"badges"}
            data-test-id="tag-input-badges"
            onChange={async (event, newValue, prevValue) => onChange(await handleOnChange(event, newValue, prevValue))}
            value={badge}
            options={options}
            displayField={"label"}
            updateSelectedItem={async (item) => onChange(await handleOnUpdateSelected(item))}
            placeholder={badge ? "Replace badge" : "Write a badge"}
            maxLength={28}
            renderOption={(option, state) => (
              <HStack
                p="4px"
                pl="8px"
                w="100%"
                borderRadius="8px"
                _hover={{ bg: "brandRedAlpha.400", cursor: "pointer" }}
                justifyContent={"space-between"}
                minH="32px"
              >
                <Text as="option">{option.label}</Text>
              </HStack>
            )}
          />
        )}
      />
      <UnorderedList>
        <ListItem fontSize="sm" color="brandGray.500">
          Badges containing 10 characters or fewer will neatly fit on a single line.
        </ListItem>
        <ListItem fontSize="sm" color="brandGray.500">
          Badges with a character count ranging from 11 to 20 will be formatted to span across two lines.
        </ListItem>
        <ListItem fontSize="sm" color="brandGray.500">
          Badges with a character count from 21 up to a maximum of 28 will be formatted to span across three lines.
        </ListItem>
      </UnorderedList>
    </VStack>
  );
}
