import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: false,
  onUploadStatements: () => {},
};
export const prePlaidModalSlice = createSlice({
  name: "prePlaidModal",
  initialState,
  reducers: {
    show: (_, { payload: { onContinue, onUploadStatements } }) => {
      return {
        isOpen: true,
        onContinue,
        onUploadStatements,
      };
    },
    hide: () => {
      return {
        isOpen: false,
        onUploadStatements: () => {},
      };
    },
  },
});

export const prePlaidModalSelector = (state) => state.integrations.prePlaidModal;

export const { show, hide } = prePlaidModalSlice.actions;

export default prePlaidModalSlice.reducer;
