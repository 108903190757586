import React from "react";
import { useSelector } from "react-redux";
import { financialsSelector } from "@redux/financials/financialsSlice";
import { Box, SimpleGrid } from "@chakra-ui/react";
import { BankingCard } from "@common/banking-card";
import { NetBurnLastMonthCard } from "@common/net-burn-last-month-card";
import { UpcomingRevenueCard } from "@common/upcoming-revenue-card";
import { UpcomingCashOutCard } from "@common/upcoming-cash-out-card";
import { AdSpendRunRateCard } from "@common/ad-spend-run-rate";
import { UpcomingCashInCard } from "@common/upcoming-cash-in-card/UpcomingCashInCard";
import { getFinancialCardsBlurryMessage } from "@src/consts/blur";
import { BlurryCard } from "@common/blurry-card";

export const FinancialCustomer = ({ blurState = {} }) => {
  const { getUpcomingCashIn, getBankingMetrics, lastMonthNetBurn, getClosingBalance } = useSelector(financialsSelector);
  const { banking, platform } = blurState;

  const { bankMessage, upcomingRevenueMessage, upcomingCashoutMessage } = getFinancialCardsBlurryMessage({
    banking,
    platform,
  });

  const isLoading =
    getClosingBalance.requestStatus.pending || getBankingMetrics.requestStatus.pending || !lastMonthNetBurn;

  return (
    <Box data-test-id="financial-intelligence-cards">
      <SimpleGrid position={"relative"} columns={2} spacing={4}>
        <BlurryCard
          isBlurred={!getUpcomingCashIn.requestStatus.pending && platform.isBlurred}
          imgSrc={require("@assets/img/blur/platformBlur.png")}
          message={upcomingRevenueMessage}
        >
          <UpcomingCashInCard />
        </BlurryCard>

        <BlurryCard
          isBlurred={platform.isBlurred}
          imgSrc={require("@assets/img/blur/platformBlur.png")}
          message={upcomingCashoutMessage}
        >
          <UpcomingCashOutCard />
        </BlurryCard>
      </SimpleGrid>

      <BlurryCard
        isBlurred={!isLoading && banking.isBlurred}
        imgSrc={require("@assets/img/blur/bankingBlur.png")}
        message={bankMessage}
        mt={"16px"}
      >
        <SimpleGrid columns={2} spacing={4}>
          <UpcomingRevenueCard />
          <AdSpendRunRateCard />
          <BankingCard />
          <NetBurnLastMonthCard />
        </SimpleGrid>
      </BlurryCard>
    </Box>
  );
};
