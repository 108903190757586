import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import classnames from "classnames";
import actions from "@redux/actions";

import { TitleSmallPlus, BodyRegular16, BodySmall14, BodySmallAlt } from "@styled";
import { StyledGcOffers } from "./GcOffers.styled";
import ErrorBoundary from "@components/ErrorBoundary";
import DismissableBanner from "@components/common/banners/DismissableBanner";
import GcContainer from "./GcContainer";
import WaitingCard from "./WaitingCard";

import text from "@utils/text";
import { currencyFormat } from "@utils/currency";
import { formatReadableDate } from "@utils/format";
import symbols from "@utils/symbols";

import { ReactComponent as CancelIcon } from "@assets/img/icons/common/cancel-icon.svg";

const offerType = {
  FIXED_TERMS: "Fixed-term",
  REVENUE_BASED: "Revenue-based",
};

const GcOffers = ({ onTrackAdvance, onboardingType }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const offers = useSelector((state) => state.offers.capitalOffers);
  const noOffers = offers.length === 0;
  const data = useSelector((state) => state.offers.data);
  const availableOffer = offers?.find((offer) => offer.state === "WAITING_FOR_OFFER_SELECTION");
  const isOfferSelected = offers?.find((offer) => offer.state === "OFFER_SELECTED");
  const isOfferExpired = offers?.find((offer) => offer.state === "EXPIRED");

  const selectedOffer = isOfferSelected?.options?.find((option) => option.selectedAmount !== null);
  const isOnboardingStatusCardDismissed = useSelector(
    (state) => state.session.company.onboarding.isStatusCardDismissed,
  );

  const advanceSummary = useSelector((state) => state.integrations.advances.data.summary);
  const { rejectedCardDismissed, requestStage, requestedCapitalActive } = advanceSummary;

  const isRejected = requestStage === "REJECTED";

  // Check expiration from advanceSummary or capital offer itself
  const offerExpired = requestStage === "EXPIRED" || !!isOfferExpired;

  const requestStatus = useSelector((state) => state.offers.requestStatus);
  const fixedBased = availableOffer?.options?.find((option) => option.type === "FIXED_TERMS");
  const clonedCapitalRequestStatus = useSelector(
    (state) => state.integrations.advances.requestStatus.capitalRequestClone,
  );

  const onDismissRejectedCapitalRequest = () => {
    dispatch(actions.integrations.advances.capitalRequestDismissStatus.request());
  };

  useEffect(() => {
    if (clonedCapitalRequestStatus.isCloned && offerExpired) {
      navigate("/growth-capital", { capitalRequestClonnedFromDashboard: clonedCapitalRequestStatus.isCloned });
    }
  }, [clonedCapitalRequestStatus.isCloned, navigate, offerExpired]);

  if (requestStatus.pending) {
    return <div className={classnames("gc-container", { "loading-shine": requestStatus.pending })} />;
  }

  if (availableOffer) {
    return (
      <>
        <TitleSmallPlus className="advance-list-title">{text.t("offer_title")}</TitleSmallPlus>
        <br />
        <GcContainer offer={availableOffer} fixedBased={fixedBased} isFromDashboard />
      </>
    );
  }

  if (isOfferSelected || data.selectedAmount) {
    return (
      <>
        <TitleSmallPlus className="advance-list-title">{text.t("offer_title")}</TitleSmallPlus>
        <br />
        <WaitingCard
          header={<TitleSmallPlus>{text.t("last_step_header")}</TitleSmallPlus>}
          body={
            <div className="waiting-body">
              <ul className="list">
                <li>
                  <BodyRegular16>{text.t("last_step_list_0")}</BodyRegular16>
                </li>
                <li>
                  <BodyRegular16>{text.t("last_step_list_3")}</BodyRegular16>
                </li>
              </ul>
              <div className="blue-line" />
              <div className="selected-details">
                <TitleSmallPlus>
                  {symbols.moneyBag} {currencyFormat(selectedOffer.selectedAmount)} •
                </TitleSmallPlus>{" "}
                <BodyRegular16>{offerType[selectedOffer.type]}</BodyRegular16>
                <BodySmall14>Confirmed on {formatReadableDate(isOfferSelected.updatedAt)}(UTC)</BodySmall14>
              </div>
            </div>
          }
          footer={<BodyRegular16 className="confirmed-contact">{text.t("last_step_footer")}</BodyRegular16>}
          showBorder
        />
      </>
    );
  }
  if (isRejected && !rejectedCardDismissed) {
    return (
      <>
        <TitleSmallPlus className="advance-list-title">{text.t("offer_title")}</TitleSmallPlus>
        <br />
        <WaitingCard
          header={
            <div className="gc-offer-title">
              <TitleSmallPlus>{text.t("rejected_header")}</TitleSmallPlus>
              <CancelIcon className="cancel" onClick={onDismissRejectedCapitalRequest} />
            </div>
          }
          body={
            <div className="card-text">
              <BodySmallAlt>{text.t("rejected_list_1")}</BodySmallAlt>
              <br />
              <BodySmallAlt>{text.t("rejected_list_2", { onTrackAdvance })}</BodySmallAlt>
            </div>
          }
          footer={<BodySmallAlt className="card-text">{text.t("rejected_footer")}</BodySmallAlt>}
        />
      </>
    );
  }

  if (
    noOffers &&
    requestedCapitalActive &&
    !isOfferSelected &&
    (isOnboardingStatusCardDismissed || onboardingType === "SECONDARY")
  ) {
    const title =
      onboardingType === "PRIMARY" ? (
        <TitleSmallPlus className="advance-list-title">{text.t("offer_title")}</TitleSmallPlus>
      ) : null;
    return (
      <ErrorBoundary name="growth-capital-active-tab-available-offer-banner">
        {title}
        <DismissableBanner
          id={"capitalRequested"}
          content={
            <BodySmallAlt className="text-center">
              {symbols.pizza} Congrats! You've requested Growth Capital. You'll see your personalized offer <br />
              in the Offer tab once we've processed your application.
            </BodySmallAlt>
          }
          dismissable={false}
          allowMarginTop
        />
      </ErrorBoundary>
    );
  }

  if (offerExpired) {
    return offers?.map((offer) => {
      // return nothing if offer is dismissed
      if (offer.isDismissed) return <></>;

      const fixedBased = offer.options.find((opt) => opt.type === "FIXED_TERMS");
      return (
        <ErrorBoundary name="growth-capital-offer-tab-expired-offer-component" key={offer.id}>
          <TitleSmallPlus className="advance-list-title">{text.t("offer_title")}</TitleSmallPlus>
          <br />
          <StyledGcOffers>
            <GcContainer offer={offer} fixedBased={fixedBased} isFromDashboard />
          </StyledGcOffers>
        </ErrorBoundary>
      );
    });
  }

  return null;
};

export default GcOffers;
