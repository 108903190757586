import { Button, Icon, Text, VStack } from "@chakra-ui/react";
import { ReactComponent as PlusCircle } from "src/assets/img/icons/common/plus-circle.svg";
export function ProjectsEmptyState({ onOpen }) {
  return (
    <VStack height={"473px"} gap="12px" alignItems={"center"} justifyContent={"center"}>
      <Text>You don't have any project yet</Text>
      <Button onClick={onOpen} leftIcon={<Icon as={PlusCircle} />} variant={"tertiary"}>
        New project
      </Button>
    </VStack>
  );
}
