import React from "react";
import { Button, HStack, Icon, Text, Tooltip, VStack, useClipboard } from "@chakra-ui/react";
import { StyledLoader } from "@common/inputs/SanloButton/SanloButton";
import { ReactComponent as CopyIcon } from "@assets/img/icons/common/copy.svg";
import PropTypes from "prop-types";

export const KeyGenerator = ({
  keyValue = "",
  title = "",
  subTitle = "",
  buttonLabel = "Generate",
  requestStatus = {},
  showCopy = true,
  onButtonClick = () => {},
}) => {
  const { onCopy, hasCopied } = useClipboard(keyValue);

  const error = requestStatus?.completed && requestStatus?.error;

  let iconContainer = null;
  if (hasCopied) {
    iconContainer = (
      <Text size={"small"} color={"textSubtext.400"}>
        Copied!
      </Text>
    );
  } else if (keyValue) {
    iconContainer = <Icon as={CopyIcon} w={"16px"} h={"16px"} />;
  }

  return (
    <VStack w={"100%"} gap={"16px"}>
      {title && (
        <VStack w={"100%"} alignItems={"flex-start"} gap={"4px"}>
          <Text size={"small"} color={"textWhite.400"} fontWeight={600}>
            {title}
          </Text>
          {subTitle && (
            <Text size={"smallAlt"} color={"textSubtext.400"}>
              {subTitle}
            </Text>
          )}
        </VStack>
      )}
      <HStack w={"100%"} gap={"16px"}>
        <Tooltip label={showCopy && keyValue ? "Copy" : ""} p={"12px"} bgColor={"dark.400"}>
          <HStack
            w={"100%"}
            p={"10px"}
            justifyContent={"space-between"}
            borderRadius={"10px"}
            border={"1px solid"}
            borderColor={"dark.300"}
            onClick={onCopy}
            cursor={showCopy && keyValue && "pointer"}
          >
            <Text size={"small"} minH={"16px"} color={"textSubtext.400"}>
              {keyValue}
            </Text>
            {showCopy && iconContainer}
          </HStack>
        </Tooltip>

        <Button
          p={"12px"}
          borderRadius={"8px"}
          border={"1px solid"}
          borderColor={"#FAF8F81A"}
          onClick={onButtonClick}
          isLoading={requestStatus?.pending}
          spinner={<StyledLoader />}
        >
          {buttonLabel}
        </Button>
      </HStack>
      {error && (
        <Text alignSelf={"flex-start"} color={"brandRed.400"}>
          Something went wrong, please try again!
        </Text>
      )}
    </VStack>
  );
};

KeyGenerator.propTypes = {
  keyValue: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  buttonLabel: PropTypes.string,
  requestStatus: PropTypes.object,
  showCopy: PropTypes.bool,
  onButtonClick: PropTypes.func,
};
