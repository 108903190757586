import React, { useState } from "react";

import { BodySmall14 } from "@styled";
import { StepActionsContainer } from "@common/animated/FadeStepsContainer";
import { StyledPermissionGuideContainer } from "@components/common/modals/permission-modal/PermissionModal.styles";
import useShadowScroll from "@utils/custom-hooks/useShadowScroll";

import { StyledPermissionModal } from "@components/common/modals/permission-modal/PermissionModal.styles";
import { SanloButton, SanloCheckbox, Support, Link, CopyButton } from "@inputs";
import {
  Step,
  Callout,
  Navigation,
  GuideList,
  StepTitle,
  GuideLink,
  GuideMedia,
  StepDescription,
  GuideActions,
  StepInfo,
} from "@components/integrations/connection-guide";

import symbols from "@utils/symbols";

import { INTEGRATION_ACTION, COMMON_ACTION } from "@utils/gtm";

export const AppsFlyerStep4 = ({
  platform = {
    id: "appsflyer",
    name: "AppsFlyer",
    platformId: "appsflyer",
  },
  email = "",
  clientAppsFlyerApiConnectionEnabled = false,
  onBack = () => {},
  onClose = () => {},
  onComplete = () => {},
  trackEvent = () => {},
}) => {
  const [requiredAccess, setRequiredAccess] = useState(false);

  const [wrapperRef, contentRef, shadowBottomRef, shadowTopRef] = useShadowScroll(false);

  return (
    <StepActionsContainer>
      <StyledPermissionModal isOpen fade={false} scrollable={false}>
        <div className="leftGradModal" />
        <div className="rightGradModal" />
        <div className="fs-modal-container">
          <Navigation
            onClose={() => {
              if (!clientAppsFlyerApiConnectionEnabled) {
                onClose();
              } else {
                onBack();
              }
            }}
            platformName={platform.name}
          />

          <div
            style={{
              height: "80%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <StyledPermissionGuideContainer>
              <div ref={shadowTopRef} className="shadow shadow--top" />
              <div ref={shadowBottomRef} className="shadow shadow--bottom" />

              <div className="step-wrapper" ref={wrapperRef}>
                <div ref={contentRef} className="step-container">
                  <Step>
                    <StepTitle stepNumber={1}>
                      Log into your{" "}
                      <GuideLink
                        label={platform.name}
                        href="https://hq1.appsflyer.com/auth/login/"
                        onClick={() => {
                          trackEvent(INTEGRATION_ACTION.LINK);
                        }}
                      />{" "}
                      account and complete the steps below:
                    </StepTitle>

                    <StepDescription>
                      <GuideList>
                        <li>
                          1.1 On the top right of the main {platform.name} page, click the dropdown menu that shows your
                          email.
                        </li>
                        <li>
                          1.2 Click <b>"User management"</b>-&gt;<b>"New user"</b> button, and fill out new user
                          details:
                        </li>
                        <li>
                          <ol>
                            <li>
                              Enter full name: <b>Sanlo Data Team</b>{" "}
                              <CopyButton
                                text="Sanlo Data Team"
                                onClick={() => {
                                  trackEvent(INTEGRATION_ACTION.COPY_FULLNAME);
                                }}
                              />
                            </li>
                            <li>
                              Enter email address: <b>{email}</b>{" "}
                              <CopyButton
                                text={email}
                                onClick={() => {
                                  trackEvent(INTEGRATION_ACTION.COPY_EMAIL);
                                }}
                              />
                            </li>
                            <li>
                              Select role: <b>Marketing Lead</b>
                            </li>
                            <li>
                              App access: check the <b>"All apps"</b> and <b>"Allow access to all future apps"</b>{" "}
                              boxes.
                            </li>
                            <li>
                              For Media sources and Geos select: <b>"All"</b>
                            </li>
                          </ol>
                        </li>
                        <li>
                          1.3 Click <b>"Save"</b>.
                        </li>
                      </GuideList>
                    </StepDescription>
                  </Step>

                  <Step>
                    <StepTitle stepNumber={2}>
                      Check the box below and click "Complete" button to finish setup {symbols.PointDown()}
                    </StepTitle>
                  </Step>

                  <GuideActions>
                    <SanloCheckbox
                      checked={requiredAccess}
                      toggleCheckbox={() => {
                        setRequiredAccess(!requiredAccess);
                      }}
                      text={`I have invited and granted Sanlo access to my ${platform.name} access.`}
                      testId={`${platform.platformId}-granted-checkbox`}
                    />

                    <SanloButton
                      isJumbo
                      isDisabled={!requiredAccess}
                      label="Complete"
                      onClick={onComplete}
                      testId={`${platform.platformId}-granted-complete-button`}
                    />

                    <BodySmall14>
                      For more details, see following document from AppsFlyer{" "}
                      <Link
                        href="https://support.appsflyer.com/hc/en-us/articles/4409128270481#creating-a-user"
                        onClick={() => trackEvent(COMMON_ACTION.HERE_LINK)}
                      >
                        here.
                      </Link>
                    </BodySmall14>
                  </GuideActions>
                </div>
              </div>

              <StepInfo platform={platform}>
                <Callout>
                  {symbols.LightBulb()} Granting us access to the right data will improve your in-app experience. Have
                  questions about data connection?{" "}
                  <Support red onClick={() => trackEvent(COMMON_ACTION.CONTACT_US)} text="Contact us" />
                </Callout>

                <GuideMedia
                  mediaSlider={[
                    require(`./assets/${platform.platformId}-permission-guide-1.png`),
                    require(`./assets/${platform.platformId}-permission-guide-2.png`),
                    require(`./assets/${platform.platformId}-permission-guide-3.png`),
                    require(`./assets/${platform.platformId}-permission-guide-4.png`),
                  ]}
                  platformName={platform.name}
                />
              </StepInfo>
            </StyledPermissionGuideContainer>
          </div>
        </div>

        <div id="toast-bg-fade" />
        <div id="bottom-toast">Copied to Clipboard</div>
      </StyledPermissionModal>
    </StepActionsContainer>
  );
};
