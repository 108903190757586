import React from "react";
import { Text, FormLabel, HStack } from "@chakra-ui/react";

import PropTypes from "prop-types";

/**
 * @prop {node} children - The content of the section.
 * @prop {boolean} isRequired - The name of the section.
 * @prop {element} tooltip - The name of the section.
 */
export const SubsectionTitle = ({ children, isRequired, tooltip: Tooltip }) => {
  return (
    <HStack gap={"4px"} alignItems={"center"} mb={"8px"}>
      <FormLabel m={"0"}>
        <Text size={"small"} color={"textWhite.400"}>
          {children}{" "}
          {isRequired && (
            <Text as={"span"} size={"small"} color={"textSubtext.400"}>
              (Required)
            </Text>
          )}
        </Text>
      </FormLabel>
      {React.isValidElement(Tooltip) && Tooltip}
    </HStack>
  );
};

SubsectionTitle.propTypes = {
  children: PropTypes.node.isRequired,
  isRequired: PropTypes.bool,
  tooltip: PropTypes.element,
};
