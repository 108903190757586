import { NEEDS_RECONNECTION } from "@utils/status";
import { moveElementsOfPropertyValueToEndOfArray } from "@utils";

export const moveReconnectAccountsToEndOfList = (bankingDetails) => {
  const res = bankingDetails.map((a) => {
    return {
      ...a,
      accounts: moveElementsOfPropertyValueToEndOfArray(a.accounts, NEEDS_RECONNECTION, "status"),
    };
  });
  return res;
};
