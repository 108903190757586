import React from "react";
import { HStack } from "@chakra-ui/react";

import { BaseSection, SectionText, SectionIcon } from "@components/builder/SidebarTabs";

import { ReactComponent as User } from "@assets/img/icons/common/user.svg";

export const LoginSidebar = ({ selected = false, onClick = () => {} }) => {
  return (
    <BaseSection
      selected={selected}
      style={{
        borderRadius: "8px",
      }}
    >
      <HStack padding="16px" gap="8px" onClick={onClick}>
        <SectionIcon icon={User} selected={selected} />
        <SectionText>Player Authentication</SectionText>
      </HStack>
    </BaseSection>
  );
};
