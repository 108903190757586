import React, { useContext } from "react";
import { ThemeContext } from "styled-components";

import { BodySmall14, TitleLargePlus, TitleSmall20 } from "@styled";
import { SanloTooltip } from "@components/common/tooltips/sanlo-tooltip/SanloTooltip";

import { currencyFormat } from "@utils/currency";
import { alphaTrim } from "@utils/general";

import { ReactComponent as ToolTipIcon } from "@assets/img/icons/common/tooltip.svg";

const EarnedRow = ({ label, amount = "-", subtitle, tooltipText = null, cryptoAsRepaymentSource }) => {
  const theme = useContext(ThemeContext);

  if (typeof amount === "number" && !isNaN(amount)) amount = currencyFormat(amount);

  return (
    <div className="earned-row">
      {label && <BodySmall14 className="earned-label">{label}</BodySmall14>}
      <TitleLargePlus className="earned-amount">
        {amount}
        {subtitle && <TitleSmall20 className="earned-subtitle">{subtitle}</TitleSmall20>}
        {Boolean(tooltipText) && (
          <>
            <ToolTipIcon
              className="tooltip-icon info-icon-charts"
              id={`tt_advance_card_currency_disclosure_${alphaTrim(subtitle)}`}
              fill={theme.tooltipFill}
            />
            <SanloTooltip
              width={cryptoAsRepaymentSource ? 250 : ""}
              placement={cryptoAsRepaymentSource ? "top" : "right"}
              target={`tt_advance_card_currency_disclosure_${alphaTrim(subtitle)}`}
              text={tooltipText}
            />
          </>
        )}
      </TitleLargePlus>
    </div>
  );
};

export default EarnedRow;
