import SanloWebApiService from "./services";

import actions from "@redux/actions";

import baseActions, { GET_INTEGRATIONS_CONNECTIONS, SEND_SUGGESTION } from "./actions";

import { manualConnectionThunks } from "./manualSlice";

import { getMMPData } from "./utils";

const baseMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    switch (action.type) {
      case GET_INTEGRATIONS_CONNECTIONS.REQUEST: {
        SanloWebApiService.integrationsConnectionsStatus()
          .then((response) => response.data)
          .then((platforms) => {
            // Call to get manual connections
            // dispatch(manualConnectionThunks.getPlatforms());
            dispatch(manualConnectionThunks.getAll());
            // Call to get banking statues, will have manual uploading information
            // and some plaid information
            dispatch(actions.banking.status.request());
            // After the main integrations call succeeds, we can then
            // call the individual banking integrations not part of it
            dispatch(actions.integrations.plaid.status.request());
            // Send the main integrations response, the individual platform reducers
            // listen for this action to get their data from it
            dispatch(baseActions.getIntegrationsConnections.response(platforms));
            // If onboarding hasn't been completed, we can fetch the onboarding data now
            if (!getState().session.company.onboarding.isComplete) {
              dispatch(actions.session.getOnboardingProgress.request());
            }
            // Retrieved the UASpend data for the connected MMP platforms
            // and sets the currently connected MMP platform
            getMMPData(platforms, dispatch);
          })
          .catch((error) => {
            dispatch(baseActions.getIntegrationsConnections.error(error));
          });
        break;
      }

      case SEND_SUGGESTION.REQUEST: {
        SanloWebApiService.sendSuggestion(action.data)
          .then((response) => {
            // On success, send back the original payload so we know what was successful
            dispatch(baseActions.sendSuggestion.response(action.data));
          })
          .catch((error) => {
            dispatch(baseActions.sendSuggestion.error(error));
          });
        break;
      }

      default:
        return;
    }
  };

export default baseMiddleware;
