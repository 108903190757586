import React, { useState } from "react";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";

import actions from "@redux/actions";
import googlePlayPermissionCheckImage from "./assets/google_play_permissions.svg";

import { BodySmallAltPlus } from "@styled";
import { StyledConnectionFlow } from "@components/integrations/StyledConnectionFlow";

import StyledLogoutModal from "@components/common/modals/StyledLogoutModal";
import { TitleRegularPlus } from "@components/styled";
import FadeStepsContainer from "@common/animated/FadeStepsContainer";
import { GOOGLE_ACTION } from "@utils/gtm";

import { SanloButton } from "@inputs";

import { GooglePlayStep1 } from "./GooglePlayStep1";
import { GooglePlayStep2 } from "./GooglePlayStep2";
import { GooglePlayStep3 } from "./GooglePlayStep3";

export const GooglePlayConnectSetup = ({
  platform = {
    id: "googleplay",
    name: "Google play",
    platformId: "googleplay",
  },
  stepNbr,
  stepData = {},
  goToNextStep = () => {},
  closeModal = () => {},
  trackEvent = () => {},
}) => {
  const dispatch = useDispatch();
  const { isSetupDone = false } = stepData;

  const saveCredentialsRequestStatus = useSelector(
    (state) => state.integrations[platform.id].requestStatus.saveCredentials,
  );
  const bucketIdStatus = useSelector((state) => state.integrations[platform.id].credentials.bucketId);
  const serviceAccountName = useSelector((state) => state.integrations[platform.id].credentials.serviceAccountName);

  const [cloudStorageURI, setCloudStorageURI] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [expand, setExpand] = useState(false);

  const getCredentialsError = () =>
    bucketIdStatus === "invalid" ? `Please check your ${platform.name} credentials.` : null;

  return (
    <StyledConnectionFlow
      className={classnames("fs-modal-content", {
        "done-step": isSetupDone,
      })}
    >
      <FadeStepsContainer
        step={stepNbr}
        stepComponents={[
          <GooglePlayStep1
            key="GooglePlayStep1"
            onContinue={() => {
              setExpand(false);
              setShowModal(true);
            }}
            serviceAccountName={serviceAccountName}
            expand={expand}
            credentialsError={getCredentialsError()}
            trackEvent={trackEvent}
          />,
          <GooglePlayStep2
            key="GooglePlayStep2"
            saveCredentialsRequestStatus={saveCredentialsRequestStatus}
            cloudStorageURI={cloudStorageURI}
            setCloudStorageURI={setCloudStorageURI}
            onConnect={() => {
              const credentials = {
                cloudStorageUri: cloudStorageURI,
              };
              dispatch(actions.integrations[platform.id].saveCredentials.request(credentials, goToNextStep));
            }}
            expand={expand}
            credentialsError={getCredentialsError()}
            trackEvent={trackEvent}
          />,
          <GooglePlayStep3 key="GooglePlayStep3" onClose={closeModal} />,
        ]}
      />

      <div id="bottom-toast">Copied to Clipboard</div>

      <StyledLogoutModal className="modal-dialog-centered" isOpen={showModal}>
        <div className="logout-modal-content">
          <TitleRegularPlus>Have you granted these two permissions?</TitleRegularPlus>
          <BodySmallAltPlus className="desc">
            Please verify that you've checked the following two sets of permissions in your Google account. This is how
            your Account permissions settings should look:
          </BodySmallAltPlus>
          <img src={googlePlayPermissionCheckImage} alt="permissions" />
          <div className="btns">
            <SanloButton
              isSecondary
              label="No, show me how"
              onClick={() => {
                trackEvent(GOOGLE_ACTION.GRANTED_PERMISSION_NO);
                setExpand(true);
                setShowModal(false);
              }}
              testId={`${platform.platformId}-granted-no-button`}
            />
            <SanloButton
              label="Yes, continue"
              onClick={() => {
                trackEvent(GOOGLE_ACTION.GRANTED_PERMISSION_YES);
                setShowModal(false);
                goToNextStep();
              }}
              testId={`${platform.platformId}-granted-yes-button`}
            />
          </div>
        </div>
      </StyledLogoutModal>
    </StyledConnectionFlow>
  );
};
