import moment from "moment";

import { copy, objSort } from "@utils/general";
import { currencyFormat } from "@utils/currency";

export const getAdvancesTotal = (advances = [], type = "active") => {
  if (!advances.length) return "";

  const firstAdvance = advances[0];
  const { advanceCurrency } = firstAdvance;

  let total = 0;
  advances.forEach((advance) => {
    const { capitalAmount = 0 } = advance;
    total += capitalAmount;
  });

  return currencyFormat(total, advanceCurrency);
};

export const getUpcomingRepayments = (advances = [], platforms = []) => {
  const upcoming = [];

  advances.forEach((advance) => {
    const { gameIcon = "", gameTitle = "", platforms: advancePlatforms = {}, advanceCurrency } = advance;

    Object.keys(advancePlatforms).forEach((platformKey) => {
      const platform = advancePlatforms[platformKey];
      const { id: platformId = "", name: platformName = "", upcomingRepayments = {} } = platform;
      if (!Object.keys(upcomingRepayments ?? {}).length) return;

      const { repaymentAmountInAdvanceCurrency, estimatedRepaymentDate } = upcomingRepayments;

      const repayment = {
        ...copy(upcomingRepayments),
        gameIcon,
        gameTitle,
        platformIcon: platforms[platformId]?.icon,
        platformName,
        displayDate: `${moment(estimatedRepaymentDate).format("MMM Do")}`,
        displayAmount: `~${currencyFormat(repaymentAmountInAdvanceCurrency, advanceCurrency)}`,
        uniqueKey: `${gameTitle.toLowerCase().replace(/[\W_]+/g, "")}_${estimatedRepaymentDate
          .toLowerCase()
          .replace(/[\W_]+/g, "")}`,
      };

      upcoming.push(repayment);
    });
  });

  objSort(upcoming, "estimatedRepaymentDate");

  return upcoming;
};

export const getAdvancesSummary = (advances = [], theme) => {
  if (!advances.length) return null;

  const firstAdvance = advances[0];
  const { advanceCurrency } = firstAdvance;

  const summary = {
    chunks: {
      repaid: {
        label: "Balance repaid",
        value: 0,
        display: "",
        color: theme.colorGreen,
      },
      remaining: {
        label: "Remaining balance",
        value: 0,
        display: "",
        color: theme.colorPurple,
      },
    },
    total: {
      value: 0,
      display: "",
    },
  };

  advances.forEach((advance) => {
    const { totalAmount = 0, repaidAmount = 0 } = advance;

    summary.chunks.repaid.value += repaidAmount;
    const remaining = totalAmount - repaidAmount;
    summary.chunks.remaining.value += remaining;
  });

  summary.total.value = summary.chunks.remaining.value + summary.chunks.repaid.value;

  summary.chunks.remaining.display = currencyFormat(summary.chunks.remaining.value, advanceCurrency);
  summary.chunks.repaid.display = currencyFormat(summary.chunks.repaid.value, advanceCurrency);
  summary.total.display = currencyFormat(summary.total.value, advanceCurrency);

  return summary;
};
