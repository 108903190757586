import { Box, Heading, Text, VStack } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { adNetworksSelector } from "@redux/integrations/ad-networks/adNetworksSlice";
import { AdNetworkCredentials } from "./components/AdNetworkCredentials";
import { AdNetworkOAuth } from "./components/AdNetworkOAuth";

const CREDENTIAL_TYPE = "CREDENTIALS";

export function AdNetworkConnections({ handleNextStep, handlePrevStep }) {
  const { selectedAdNetwork } = useSelector(adNetworksSelector);

  const Connection =
    selectedAdNetwork.data?.authorizationType === CREDENTIAL_TYPE ? AdNetworkCredentials : AdNetworkOAuth;

  return (
    <Box minH={"580px"}>
      <VStack gap="4px" mb="32px" alignItems={"start"}>
        <Heading fontWeight={600}>Platform integration</Heading>
        <Text size="smallAlt" maxW={"390px"}>
          Integrate ad networks to track generated revenue
        </Text>
      </VStack>
      <Connection handleNextStep={handleNextStep} handlePrevStep={handlePrevStep} />
    </Box>
  );
}
