import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./redux/reducers";
import middlewares from "./redux/middlewares";
import listenerMiddleware from "@redux/listenerMiddleware";

export const setupStore = (preloadedState, additionalMiddlewares = []) =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        // TODO: Disabling for now, as we move more towards slices we should
        // look into enabling this again
        serializableCheck: false,
      })
        .prepend(listenerMiddleware.middleware)
        .concat(middlewares, ...additionalMiddlewares),
    preloadedState,
  });

export const store = setupStore();
