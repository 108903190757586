import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTransition, animated } from "react-spring";
import { useSelector } from "react-redux";
import actions from "@redux/actions";

import OnboardingStep0 from "./step-0/OnboardingStep0";
import OnboardingStep1 from "./step-1/OnboardingStep1";
import OnboardingStep2 from "./step-2/OnboardingStep2";
import OnboardingStep3Container from "./step-3/OnboardingStep3Container";
import OnboardingStepAnalytics from "./step-analytics/OnboardingStepAnalytics";

import AuthenticationContainer from "../authentication/AuthenticationContainer";
import StepsProgressContainer from "./StepsProgressContainer";
import { LoadingScreen } from "@components/common/loaders";

import { stepsCopies } from "./stepsCopies";
import { getRocketProgress, getSaveProgressPayload, getOnboardingType } from "@utils/onboarding";
import { getBrandingInfo } from "@utils/branding";
import text from "@utils/text";

const OnboardingContainer = ({ companyName, onboardingProgress, hasCompletedOnboarding, dispatch }) => {
  const navigate = useNavigate();

  const [onboarding, setOnboarding] = useState(onboardingProgress.data);
  const [showLoader, setShowLoader] = useState(false);
  const [finishLoading, setFinishLoading] = useState(false);

  const partnerBranding = useSelector((state) => state.session.partnerBranding);
  // Integrations and institutions statuses help determine rocket progress
  const rp_integrations = useSelector((state) => state.integrations);
  const rp_institutions = useSelector((state) => state.integrations.plaid.data.institutions);
  const [rocketProgress, setRocketProgress] = useState(getRocketProgress());

  const [initialComplete, setInitialComplete] = useState(onboardingProgress.requestStatus.getData.completed);

  const { isBranded } = getBrandingInfo();

  useEffect(() => {
    if (onboardingProgress.requestStatus.getData.completed && !initialComplete) setInitialComplete(true);
    setOnboarding(onboardingProgress.data);
  }, [onboardingProgress, initialComplete]);

  useEffect(() => {
    const onboardingType = getOnboardingType(onboarding.currentStep);
    setRocketProgress(getRocketProgress(onboarding.currentStep, rp_integrations, rp_institutions, onboardingType));
  }, [onboarding.currentStep, rp_integrations, rp_institutions]);

  useEffect(() => {
    if (!hasCompletedOnboarding) {
      dispatch(actions.integrations.base.getIntegrationsConnections.request());
    }
  }, [dispatch, hasCompletedOnboarding]);

  const saveProgress = (saveOptions) => {
    const savePayload = getSaveProgressPayload(onboarding, steps, saveOptions);
    dispatch(actions.session.saveOnboardingProgress.request(savePayload));
  };

  const handleChangeStep = (newStep, switchingToSecondary = false) => {
    const currentStep = steps[onboarding.currentStep];
    if (!currentStep) return;

    // If we're on the last step and not switching to secondary flow then onboarding is done
    const forward = onboarding.currentStep < newStep && !switchingToSecondary;
    const isCompleteOnboarding = currentStep.lastStep && forward;

    if (isCompleteOnboarding) {
      setShowLoader(true);
      setTimeout(() => {
        setFinishLoading(true);
      }, 4000);
      // push the base URL onto the path, when the animation completes LoadingScreen will
      // call saveProgress to handle the final save and render the dashboard
      navigate("/dashboard");
    } else {
      saveProgress({
        newStep,
        switchingToSecondary,
      });
    }
  };

  const steps = [
    {
      id: "step0", // Landing, the progress isn't actually shown here
      content: <OnboardingStep0 companyName={companyName} onChangeStep={handleChangeStep} />,
    },
    {
      id: "step1", // Select Capital Amount (1%)
      content: (
        <OnboardingStep1
          funding={onboarding.fundingRequested}
          onFundingChange={(value) => {
            setOnboarding((prevState) => ({
              ...prevState,
              fundingRequested: value,
            }));
          }}
        />
      ),
    },
    {
      id: "step2", // Revenue (34%)
      content: (
        <OnboardingStep2
          monthlyRevenue={onboarding.userMonthlyRevenue}
          onMonthlyRevenueChange={(value) =>
            setOnboarding((prevState) => ({
              ...prevState,
              userMonthlyRevenue: value.key ?? value,
            }))
          }
        />
      ),
    },
    {
      id: "step3", // "Banking" (start of the platform modal)
      content: <OnboardingStep3Container />,
      lastStep: true,
      type: "primary",
    },
    {
      id: "step4", // Still basically but step 3 but more directed toward platforms
      content: <OnboardingStepAnalytics />,
      lastStep: true,
      type: "secondary",
    },
  ];

  const { id, content, lastStep } = steps[onboarding.currentStep] || {};

  let { title, description, buttonLabel } = stepsCopies[id] || {};

  if (lastStep && isBranded) {
    buttonLabel = text.t("continue_to_sanlo_dashboard");
  }

  const transitions = useTransition(showLoader, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: 300,
  });

  return transitions(({ opacity }, item) => {
    if (item) {
      return (
        <animated.div
          style={{
            opacity: opacity.to({ range: [0.0, 1.0], output: [0, 1] }),
          }}
        >
          <LoadingScreen
            onClick={() => setShowLoader(!showLoader)}
            notifyParent={saveProgress}
            loaded={finishLoading}
          />
        </animated.div>
      );
    }

    return (
      <animated.div
        style={{
          opacity: opacity.to({ range: [1.0, 0.0], output: [1, 0] }),
        }}
      >
        <AuthenticationContainer partnerBranding={partnerBranding} hasLogout>
          <StepsProgressContainer
            requestCompleted={initialComplete}
            stepZero={onboarding.currentStep === 0}
            stepZeroContent={steps[0].content}
            platformsLinked={onboarding.platformsLinked}
            onChangeStep={handleChangeStep}
            companyName={companyName}
            progress={rocketProgress}
            stepNumber={onboarding.currentStep}
            previousStep={onboarding.previousStep}
            title={title}
            description={description}
            buttonLabel={buttonLabel}
            onboarding={onboardingProgress}
          >
            {content}
          </StepsProgressContainer>
        </AuthenticationContainer>
      </animated.div>
    );
  });
};

export default OnboardingContainer;
