import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  openModal: false,
  continueToBanking: () => {},
  continueToDashboard: () => {},
};
export const acknowledgeBankModalSlice = createSlice({
  name: "acknowledgeBankModal",
  initialState,
  reducers: {
    show: (_, { payload: { continueToBanking, continueToDashboard } }) => {
      return {
        openModal: true,
        continueToBanking,
        continueToDashboard,
      };
    },
    hide: () => {
      return {
        openModal: false,
        continueToBanking: () => {},
        continueToDashboard: () => {},
      };
    },
  },
});

export const acknowledgeBankModalSelector = (state) => state.integrations.acknowledgeBankModal;

export const { show, hide } = acknowledgeBankModalSlice.actions;

export default acknowledgeBankModalSlice.reducer;
