import React from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import TooltipRow from "@common/tooltips/data-tooltip/TooltipRow";

import { parseDataSourceStatus } from "../../utils/dailyRevenueUtils";
import tooltipsCopies from "@utils/text/cashEarningsChart";
import symbols from "@utils/symbols";

import isEmpty from "lodash/isEmpty";
import { Flex, Text } from "@chakra-ui/react";

export const DailyRevenueHeaderTooltip = ({
  selectedOption,
  dataSourceStatus,
  tooltipInfo = null,
  trackData,
  pathname,
  filter = [],
  periodDate = {},
}) => {
  const { connectBankAccountCopy } = useFlags();

  if (!tooltipInfo) {
    const periodStatus = parseDataSourceStatus(dataSourceStatus, false, connectBankAccountCopy);
    const tooltipCopies = tooltipsCopies[selectedOption?.id] || tooltipsCopies.generic;
    tooltipInfo = tooltipCopies.payout[periodStatus];
  }

  if (!tooltipInfo) return null;
  const { header, description } = tooltipInfo;
  const { periodStart, periodEnd } = periodDate;

  return (
    <div style={{ width: "268px" }}>
      {isEmpty(filter) && (
        <Flex justify={"space-between"} mb={"10px"}>
          <Text size={"mini"} color={"textWhite.400"} fontWeight={"500"} letterSpacing={"0.02em"}>
            {symbols.calendar} Fiscal Period
          </Text>
          {periodStart && periodEnd && (
            <Text size={"mini"} color={"textDefault.400"} fontStyle={"normal"} letterSpacing={"0.02em"}>
              {`${periodStart} - ${periodEnd}`}
            </Text>
          )}
        </Flex>
      )}
      <Text size={"mini"} color={"textDefault.400"} fontStyle={"normal"} letterSpacing={"0.02em"}>
        {header}
      </Text>
      <TooltipRow trackData={trackData} pathname={pathname} highlighted title="Learn More" hint={description} />
    </div>
  );
};
