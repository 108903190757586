import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import SanloWebApiService from "@services/SanloWebApiService";

import AuthenticationContainer from "@pages/authentication/AuthenticationContainer";
import {
  SanloModal,
  SanloModalTitle,
  SanloModalDescription,
  SanloModalDivider,
  SanloModalFooter,
  Highlight,
} from "@components/common/modals/SanloModal/SanloModal";
import { SanloToast } from "@common/toasts/sanlo-toast/SanloToast";
import { SanloButton } from "@inputs";

import { toastOptions } from "@utils/defaults/toast";
import { trackGTMEvent } from "@utils/gtm";

export const VerifyNewAccount = ({ email }) => {
  const [resent, setResent] = useState(false);

  const handleSendVerificationEmail = () => {
    SanloWebApiService.sendVerificationEmail(email).then(() => {
      setResent(true);
      toast.success(() => <SanloToast icon="success" msg={`Email sent to ${email}`} />, toastOptions);
    });
  };

  // Tag Manager VerifyNewAccount Component View Event
  useEffect(() => {
    trackGTMEvent({
      "event": "Component View",
      "Component Name": "VerifyNewAccount",
    });
  }, []);

  return (
    <AuthenticationContainer hasLogout>
      <SanloModal>
        <SanloModalTitle>Verify your email</SanloModalTitle>
        <SanloModalDescription>
          We've {resent ? "re-" : ""}sent an email to <Highlight>{email}</Highlight>. To access your account, please
          click on a verification link in the email we sent you.
        </SanloModalDescription>
        <SanloModalDivider />
        <SanloModalFooter>
          Haven't received it?{" "}
          <SanloButton isText label="Resend Link" onClick={handleSendVerificationEmail} gtmEventTag="tag-resend-link" />
        </SanloModalFooter>
      </SanloModal>
    </AuthenticationContainer>
  );
};
