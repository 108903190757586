import moment from "moment";

export default [
  {
    platform: {
      name: "Iron Source",
      platformId: "ironsource",
      type: "AD_NETWORKS",
      icon: "https://storage.googleapis.com/sanlo-public/ad-networks/ironSource.svg",
    },
    period: {
      id: "period-id-uuid-1",
      startDate: moment().startOf("month").format("YYYY-MM-DD"),
      endDate: moment().endOf("month").format("YYYY-MM-DD"),
      inProgress: true,
    },
    dataSourceStatus: "FINANCIAL",
    estimated: false,
    date: moment().add(3, "days").format("YYYY-MM-DD"),
    amountCents: 150000,
    currency: "USD",
    type: "PAYOUT",
  },
  {
    platform: {
      name: "Vungle",
      platformId: "vungle",
      type: "AD_NETWORKS",
      icon: "https://storage.googleapis.com/sanlo-public/ad-networks/Vungle.svg",
    },
    period: {
      id: "period-id-uuid-2",
      startDate: moment().startOf("month").format("YYYY-MM-DD"),
      endDate: moment().endOf("month").format("YYYY-MM-DD"),
      inProgress: true,
    },
    dataSourceStatus: "FINANCIAL",
    estimated: false,
    date: moment().add(7, "days").format("YYYY-MM-DD"),
    amountCents: -130200,
    currency: "USD",
    type: "EXPENSE",
  },
  {
    platform: {
      name: "Iron Source",
      platformId: "ironsource",
      type: "AD_NETWORKS",
      icon: "https://storage.googleapis.com/sanlo-public/ad-networks/ironSource.svg",
    },
    period: {
      id: "period-id-uuid-3",
      startDate: moment().startOf("month").add(1, "month").format("YYYY-MM-DD"),
      endDate: moment().endOf("month").add(1, "month").format("YYYY-MM-DD"),
      inProgress: false,
    },
    dataSourceStatus: "FINANCIAL",
    estimated: false,
    date: moment().add(1, "month").add(3, "days").format("YYYY-MM-DD"),
    amountCents: 170000,
    currency: "USD",
    type: "PAYOUT",
  },
  {
    platform: {
      name: "Vungle",
      platformId: "vungle",
      type: "AD_NETWORKS",
      icon: "https://storage.googleapis.com/sanlo-public/ad-networks/Vungle.svg",
    },
    period: {
      id: "period-id-uuid-4",
      startDate: moment().startOf("month").add(1, "month").format("YYYY-MM-DD"),
      endDate: moment().endOf("month").add(1, "month").format("YYYY-MM-DD"),
      inProgress: false,
    },
    dataSourceStatus: "FINANCIAL",
    estimated: false,
    date: moment().add(1, "month").format("YYYY-MM-DD"),
    amountCents: -100200,
    currency: "USD",
    type: "EXPENSE",
  },
];
