import { useDispatch } from "react-redux";

import { sectionsThunks, updateSection } from "@redux/webshops/builder/sectionsSlice";

import { generateFilePayloads, generateFileUpdatePayloads, generateFileRemovePayloads } from "@utils/webshop/section";

export const useSectionFile = (webshopId, sectionId, data) => {
  const dispatch = useDispatch();

  const onFileCreate = async (fileObj) => {
    const payloads = await generateFilePayloads({
      data,
      fileObj,
    });
    dispatch(
      sectionsThunks.createSectionFile({
        data: payloads.request,
        webshopId: webshopId,
        sectionId: sectionId,
      }),
    );
    dispatch(updateSection(payloads.response));
  };

  const onFileUpdate = (fileObj) => {
    const payloads = generateFileUpdatePayloads({
      data,
      fileObj,
    });
    dispatch(
      sectionsThunks.updateSectionFile({
        data: payloads.request,
        webshopId: webshopId,
        sectionId: sectionId,
      }),
    );
    dispatch(updateSection(payloads.response));
  };

  const onFileRemove = (fileObj) => {
    const payloads = generateFileRemovePayloads({
      data,
      fileObj,
    });
    dispatch(
      sectionsThunks.deleteSectionFile({
        data: payloads.request,
        webshopId: webshopId,
        sectionId: sectionId,
      }),
    );
    dispatch(updateSection(payloads.response));
  };

  return [onFileCreate, onFileUpdate, onFileRemove];
};
