import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getResponseStatus, getRequestStatus, getErrorStatus, setStatusDefaults } from "@utils/redux";

import SectionsService from "./sectionsApi";
import { builderThunks } from "./builderSlice";
import { linksThunks } from "./linksSlice";

import { errorWrapper } from "@redux/http";

import { copy } from "@utils/general";
import { generateSectionData } from "./utils";

export const sectionsThunks = {
  createSection: createAsyncThunk("webshops/createSection", async ({ data, webshopId }, { rejectWithValue }) => {
    return errorWrapper(async () => {
      const res = await SectionsService.createSection(data, webshopId);
      return res.data;
    }, rejectWithValue);
  }),
  updateSection: createAsyncThunk(
    "webshops/updateSection",
    async ({ data, webshopId, sectionId }, { rejectWithValue }) => {
      return errorWrapper(async () => {
        const res = await SectionsService.updateSection(data, webshopId, sectionId);
        return res.data;
      }, rejectWithValue);
    },
  ),
  deleteSection: createAsyncThunk(
    "webshops/deleteSection",
    async ({ data, webshopId, sectionId }, { rejectWithValue }) => {
      return errorWrapper(async () => {
        await SectionsService.deleteSection(data, webshopId, sectionId);
        return {
          sectionId,
        };
      }, rejectWithValue);
    },
  ),
  createSectionFile: createAsyncThunk(
    "webshops/createSectionFile",
    async ({ data, webshopId, sectionId }, { rejectWithValue }) => {
      return errorWrapper(async () => {
        const res = await SectionsService.createSectionFile(data, webshopId, sectionId);
        return res.data;
      }, rejectWithValue);
    },
  ),
  updateSectionFile: createAsyncThunk(
    "webshops/updateSectionFile",
    async ({ data, webshopId, sectionId }, { rejectWithValue }) => {
      return errorWrapper(async () => {
        const res = await SectionsService.updateSectionFile(data, webshopId, sectionId);
        return res.data;
      }, rejectWithValue);
    },
  ),
  deleteSectionFile: createAsyncThunk(
    "webshops/deleteSectionFile",
    async ({ data, webshopId, sectionId }, { rejectWithValue }) => {
      return errorWrapper(async () => {
        const res = await SectionsService.deleteSectionFile(data, webshopId, sectionId);
        return res.data;
      }, rejectWithValue);
    },
  ),
  createSectionThemeOverride: createAsyncThunk(
    "webshops/createSectionThemeOverride",
    async ({ data, webshopId, sectionId }, { rejectWithValue }) => {
      return errorWrapper(async () => {
        const res = await SectionsService.createSectionThemeOverride(data, webshopId, sectionId);
        return res.data;
      }, rejectWithValue);
    },
  ),
  deleteSectionThemeOverride: createAsyncThunk(
    "webshops/deleteSectionThemeOverride",
    async ({ webshopId, sectionId }, { rejectWithValue }) => {
      return errorWrapper(async () => {
        const res = await SectionsService.deleteSectionThemeOverride(webshopId, sectionId);
        return res.data;
      }, rejectWithValue);
    },
  ),
};

export const sectionsSlice = createSlice({
  name: "sections",
  initialState: {
    sections: {
      byOrder: [],
      byId: {},
      header: {},
      footer: {},
      login: {},
      deepLink: {},
      canvasData: {
        sections: [],
        header: {},
        footer: {},
        login: {},
        deepLink: {},
      },
    },
    createSection: {
      // The last created section
      createdSection: {},
      requestStatus: setStatusDefaults(),
    },
    sectionUpdate: {
      requestStatusById: {},
    },
    fileUpload: {
      requestStatus: setStatusDefaults(),
    },
    themeOverride: {
      requestStatus: setStatusDefaults(),
    },
  },
  reducers: {
    updateHeader: (state, action) => {
      state.sections.header = action.payload;
      state.sections.canvasData.header = action.payload;
    },
    updateSection: (state, action) => {
      const sectionData = generateSectionData({
        section: action.payload,
        byId: copy(state.sections.byId),
        byOrder: copy(state.sections.byOrder),
      });
      // We wont update these for now, they control the builder
      // and its being weird with the drag lists at the moment with constant updates
      state.sections.byId = sectionData.byId;
      state.sections.byOrder = sectionData.byOrder;
      state.sections.canvasData.sections = sectionData.full;
    },
    updateFooter: (state, action) => {
      state.sections.footer = action.payload;
      state.sections.canvasData.footer = action.payload;
    },
    updateLogin: (state, action) => {
      state.sections.login = action.payload;
      state.sections.canvasData.login = action.payload;
    },
    updateDeepLink: (state, action) => {
      state.sections.deepLink = action.payload;
      state.sections.canvasData.deepLink = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      /*******************************************************************************
       * Create Section
       *******************************************************************************/
      .addCase(sectionsThunks.createSection.pending, (state, action) => {
        state.createSection.requestStatus = getRequestStatus();
      })
      .addCase(sectionsThunks.createSection.fulfilled, (state, { payload }) => {
        const sectionData = generateSectionData({
          section: payload,
          byId: copy(state.sections.byId),
          byOrder: copy(state.sections.byOrder),
        });
        state.sections.byId = sectionData.byId;
        state.sections.byOrder = sectionData.byOrder;
        state.sections.canvasData.sections = sectionData.full;
        state.createSection.createdSection = payload;
        state.createSection.requestStatus = getResponseStatus();
      })
      .addCase(sectionsThunks.createSection.rejected, (state) => {
        state.createSection.requestStatus = getErrorStatus();
      })
      /*******************************************************************************
       * Update Section
       *******************************************************************************/
      .addCase(sectionsThunks.updateSection.pending, (state, action) => {
        const sectionId = action?.meta?.arg?.sectionId;
        state.sectionUpdate.requestStatusById[sectionId] = getRequestStatus();
      })
      .addCase(sectionsThunks.updateSection.fulfilled, (state, { payload }) => {
        const { id } = payload;
        if (payload.type === "ITEMS") {
          const sectionData = generateSectionData({
            section: payload,
            byId: copy(state.sections.byId),
            byOrder: copy(state.sections.byOrder),
          });
          state.sections.byId = sectionData.byId;
          state.sections.byOrder = sectionData.byOrder;
          state.sections.canvasData.sections = sectionData.full;
        }
        state.sectionUpdate.requestStatusById[id] = getResponseStatus();
      })
      .addCase(sectionsThunks.updateSection.rejected, (state, action) => {
        const sectionId = action?.meta?.arg?.sectionId;
        state.sectionUpdate.requestStatusById[sectionId] = getErrorStatus();
      })
      /*******************************************************************************
       * Delete Section
       *******************************************************************************/
      .addCase(sectionsThunks.deleteSection.pending, (state) => {
        state.sections.requestStatus = getRequestStatus();
      })
      .addCase(sectionsThunks.deleteSection.fulfilled, (state, { payload }) => {
        const { sectionId } = payload;
        const updatedByOrder = state.sections.byOrder.filter((id) => id !== sectionId);
        const updatedById = { ...state.sections.byId };
        delete updatedById[sectionId];
        const sectionData = generateSectionData({
          section: payload,
          byId: updatedById,
          byOrder: updatedByOrder,
        });
        state.sections.byId = sectionData.byId;
        state.sections.byOrder = sectionData.byOrder;
        state.sections.canvasData.sections = sectionData.full;
      })
      .addCase(sectionsThunks.deleteSection.rejected, (state) => {
        state.sections.requestStatus = getErrorStatus();
      })
      /*******************************************************************************
       * Create Section File
       *******************************************************************************/
      .addCase(sectionsThunks.createSectionFile.pending, (state) => {
        state.sections.requestStatus = getRequestStatus();
      })
      .addCase(sectionsThunks.createSectionFile.fulfilled, (state, { payload }) => {
        state.sections.requestStatus = getResponseStatus();
      })
      .addCase(sectionsThunks.createSectionFile.rejected, (state) => {
        state.sections.requestStatus = getErrorStatus();
      })
      /*******************************************************************************
       * Update Section File
       *******************************************************************************/
      .addCase(sectionsThunks.updateSectionFile.pending, (state) => {
        state.sections.requestStatus = getRequestStatus();
      })
      .addCase(sectionsThunks.updateSectionFile.fulfilled, (state, { payload }) => {
        state.sections.requestStatus = getResponseStatus();
      })
      .addCase(sectionsThunks.updateSectionFile.rejected, (state) => {
        state.sections.requestStatus = getErrorStatus();
      })
      /*******************************************************************************
       * Create Section Theme Override
       *******************************************************************************/
      .addCase(sectionsThunks.createSectionThemeOverride.pending, (state) => {
        state.themeOverride.requestStatus = getRequestStatus();
      })
      .addCase(sectionsThunks.createSectionThemeOverride.fulfilled, (state) => {
        state.themeOverride.requestStatus = getResponseStatus();
      })
      .addCase(sectionsThunks.createSectionThemeOverride.rejected, (state) => {
        state.themeOverride.requestStatus = getErrorStatus();
      })
      /*******************************************************************************
       * Delete Section Theme Override
       *******************************************************************************/
      .addCase(sectionsThunks.deleteSectionThemeOverride.pending, (state) => {
        state.themeOverride.requestStatus = getRequestStatus();
      })
      .addCase(sectionsThunks.deleteSectionThemeOverride.fulfilled, (state) => {
        state.themeOverride.requestStatus = getResponseStatus();
      })
      .addCase(sectionsThunks.deleteSectionThemeOverride.rejected, (state) => {
        state.themeOverride.requestStatus = getErrorStatus();
      })
      /*******************************************************************************
       * Get Storefront
       *******************************************************************************/
      .addCase(builderThunks.getStorefront.fulfilled, (state, action) => {
        const { payload = {} } = action;
        const { layout = {} } = payload;

        const newSectionsById = {};
        const newSectionsByOrder = [];
        // Map all the sections to the storefrontSections
        layout.pages.forEach((page) => {
          page.sections.forEach((section) => {
            newSectionsByOrder.push(section.id);
            newSectionsById[section.id] = section;
          });
        });
        state.sections.byOrder = newSectionsByOrder;
        state.sections.byId = newSectionsById;
        state.sections.header = layout.header;
        state.sections.canvasData.header = layout.header;
        state.sections.footer = layout.footer;
        state.sections.canvasData.footer = layout.footer;
        state.sections.login = layout.login;
        state.sections.canvasData.login = layout.login;
        state.sections.deepLink = layout.deepLink;
        state.sections.canvasData.deepLink = layout.deepLink;
      })
      /*******************************************************************************
       * Section Link Updates
       *******************************************************************************/
      .addMatcher(
        isAnyOf(linksThunks.createLink.fulfilled, linksThunks.updateLink.fulfilled, linksThunks.deleteLink.fulfilled),
        (state, action) => {
          const { payload = {} } = action;
          const { type = "", externalLinks = [], socialLinks = [] } = payload;
          // Only footer has links right now
          if (type === "FOOTER") {
            state.sections.footer.externalLinks = externalLinks;
            state.sections.footer.socialLinks = socialLinks;
            state.sections.canvasData.footer = state.sections.footer;
          }
        },
      );
  },
});

export const { updateHeader, updateSection, updateFooter, updateLogin, updateDeepLink } = sectionsSlice.actions;

export const sectionsSelector = (state) => state.webshops.sections;
export const storefrontSectionsSelector = (state) => state.webshops.sections.sections;

export default sectionsSlice.reducer;
