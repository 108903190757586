import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { BodySmall14CSS } from "@styled";
import { GTM } from "@utils/gtm";

const SanloCheckBoxContainer = styled.div`
  position: relative;
`;

const StyledLabel = styled.label`
  margin-bottom: 0px;
  cursor: pointer;
`;

const CheckboxContainer = styled.div`
  height: 14px;
  width: 14px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  display: flex;
  width: 14px;
  height: 14px;
  background: ${(props) => (props.checked ? props.theme.checkboxBackgroundColor : "transparent")};
  border: 1.25px solid ${(props) => props.theme.labelFontColor};
  box-sizing: border-box;
  border-radius: 3px;
  transition: all 150ms;

  svg {
    width: 10px;
    height: 10px;
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
    margin: auto;
    display: block;
    fill: none;
    stroke: ${(props) => props.theme.checkboxTickColor};
    stroke-width: 3px;
  }
`;

const StyledText = styled.span`
  ${BodySmall14CSS};
  margin-top: 6px;
  margin-left: 8px;
  color: ${({ theme }) => theme.textGray};

  ${({ checked, theme }) =>
    checked &&
    `
     color: ${theme.textSeashellWhite};
  `};
`;

export const SanloCheckbox = ({
  checked = false,
  text = "",
  toggleCheckbox = () => {},
  gtmAction = "",
  gtmLocation = "",
  gtmEventTag = "tag-sanlo-revenue-checkbox",
  testId = "",
  style = {},
}) => {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <GTM action={gtmAction} location={gtmLocation} eventTag={gtmEventTag}>
      <SanloCheckBoxContainer className="no-text-select" style={style}>
        <StyledLabel data-test-id={testId} data-testid={testId}>
          <CheckboxContainer>
            <HiddenCheckbox
              checked={isChecked}
              onChange={(e) => {
                toggleCheckbox(e, e.currentTarget.checked);
                setIsChecked(e.currentTarget.checked);
              }}
              data-testid={`${testId}_checkbox`}
            />
            <StyledCheckbox checked={isChecked}>
              <svg viewBox="0 0 24 24">
                <polyline points="20 6 9 17 4 12" />
              </svg>
            </StyledCheckbox>
          </CheckboxContainer>

          {text && <StyledText checked={isChecked}>{text}</StyledText>}
        </StyledLabel>
      </SanloCheckBoxContainer>
    </GTM>
  );
};
