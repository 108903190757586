import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { getTag } from "./utils";

export const GTM = ({ action, location, eventTag, style = {}, children }) => {
  const wrapper = useCallback(
    (node) => {
      if (node?.hasChildNodes()) {
        const allNestedElem = node.getElementsByTagName("*");
        for (const elem of allNestedElem) {
          const tag = getTag({ action, location, eventTag });
          elem.classList.add(tag);
        }
      }
    },
    [action, location, eventTag],
  );

  if ((!action || !location) && !eventTag) {
    return children;
  }

  return (
    <div ref={wrapper} style={style}>
      {children}
    </div>
  );
};

GTM.propTypes = {
  action: PropTypes.string,
  location: PropTypes.string,
  eventTag: PropTypes.string,
  children: PropTypes.element.isRequired,
};
