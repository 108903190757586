import React from "react";
import styled from "styled-components";

import { Loader } from "@components/common/loaders";
import { BodySmallPlusCSS } from "@styled";
import { GTM } from "@utils/gtm";

const StyledButton = styled.button`
  ${BodySmallPlusCSS}

  width: 100%;
  background-color: ${({ theme }) => theme.primaryColor};
  padding: 12px 16px;
  border-radius: 10px;
  border: none;
  text-align: center;
  color: ${({ theme }) => theme.grayscale0};
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  justify-content: center;

  cursor: "pointer";
  ${({ isLoading }) =>
    isLoading &&
    `
    cursor: wait;
  `};

  &:hover:enabled {
    background-color: ${({ theme }) => theme.primaryHover};
  }

  &:disabled {
    cursor: not-allowed !important;
    opacity: 0.4;
  }

  ${({ isJumbo, theme }) =>
    isJumbo &&
    `
    font-family: Inter, "Helvetica", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    color: ${theme.colorBlack};
    border: none;
    height: 64px;
    width: -webkit-fill-available;
    width: -moz-available;
    background-color: ${theme.primaryColor};
    border-radius: 12px;
    padding: 20px;
  `};

  ${({ isSecondary, theme }) =>
    isSecondary &&
    `
    background-color: ${theme.buttonSecondaryBackground};
    color: ${theme.buttonSecondaryColor};
    &:hover {
      background-color: ${theme.buttonSecondaryHover} !important;
    }
  `};

  ${({ isText, theme }) =>
    isText &&
    `
    padding: 0;
    margin: 0;
    width: auto;
    height: auto;
    background-color: transparent;
    color: ${theme.primaryColor};
    &:hover {
      background-color: transparent !important;
    }
  `};
`;

export const StyledLoader = styled(Loader)`
  height: 16px;
  & > svg {
    fill: ${({ theme }) => theme.grayscale0};
    height: 16px;
  }
`;

export const SanloButton = ({
  className = "",
  label = "",
  isSecondary = false,
  isJumbo = false,
  isLoading = false,
  isDisabled = false,
  isText = false, // Is the button inline text
  onClick = () => {},
  gtmAction = "",
  gtmLocation = "",
  gtmEventTag = "",
  testId = "",
  style = {},
}) => {
  return (
    <GTM
      action={gtmAction}
      location={gtmLocation}
      eventTag={gtmEventTag}
      style={{
        width: isText ? "auto" : "100%",
        display: isText ? "inline-block" : "block",
      }}
    >
      <StyledButton
        style={style}
        className={className}
        isJumbo={isJumbo}
        isText={isText}
        isSecondary={isSecondary}
        isLoading={isLoading}
        disabled={isDisabled}
        onClick={() => {
          if (isDisabled || isLoading) return;
          onClick();
        }}
        data-test-id={testId}
        data-testid={testId}
      >
        {isLoading ? <StyledLoader /> : label}
      </StyledButton>
    </GTM>
  );
};
