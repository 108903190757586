import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Input } from "@chakra-ui/react";

import { sectionsThunks, storefrontSectionsSelector, updateLogin } from "@redux/webshops/builder/sectionsSlice";
import { DEFAULT_DEBOUNCE_TIME } from "@utils/consts";

import {
  PropertiesHeader,
  SectionWrapper,
  getSectionTheme,
  generateThemePayloads,
  generateThemeResetPayloads,
  COLOR_TYPES,
} from "@utils/webshop/section";
import { StylePicker } from "../../StylePicker";

import { generatePayloads } from "./utils";

import { useAutoSave } from "@src/utils/hooks/useAutoSave";
import PropTypes from "prop-types";

export const LoginPropertiesContainer = ({ webshop = {}, storefront = {} }) => {
  const dispatch = useDispatch();

  const data = useSelector(storefrontSectionsSelector).login;
  const theme = getSectionTheme(storefront, data);

  const updateLoginSection = useAutoSave(
    (payload) => dispatch(sectionsThunks.updateSection(payload)),
    DEFAULT_DEBOUNCE_TIME,
  );

  const updateLoginTheme = useAutoSave(
    (payload) => dispatch(sectionsThunks.createSectionThemeOverride(payload)),
    DEFAULT_DEBOUNCE_TIME,
  );

  const onChange = (updates) => {
    const { response, request } = generatePayloads({
      data,
      updates,
    });

    updateLoginSection({
      data: request,
      webshopId: webshop.id,
      sectionId: data.id,
    });
    dispatch(updateLogin(response));
  };

  const onThemeChange = (themeObj) => {
    const { response, request } = generateThemePayloads({
      data,
      themeObj,
    });
    updateLoginTheme({
      data: request,
      webshopId: webshop.id,
      sectionId: data.id,
    });
    dispatch(updateLogin(response));
  };

  const onThemeReset = () => {
    const payloads = generateThemeResetPayloads({
      data,
    });
    dispatch(
      sectionsThunks.deleteSectionThemeOverride({
        webshopId: webshop.id,
        sectionId: data.id,
      }),
    );
    dispatch(updateLogin(payloads.response));
  };

  useEffect(() => {
    // Flush all debounced updates on unmount
    return () => {
      updateLoginSection.flush();
      updateLoginTheme.flush();
    };
  }, [updateLoginSection, updateLoginTheme]);

  return (
    <LoginProperties
      data={data}
      theme={theme}
      onChange={onChange}
      onThemeChange={onThemeChange}
      onThemeReset={onThemeReset}
    />
  );
};

export const LoginProperties = ({
  data = {},
  theme = {},
  onChange = () => {},
  onThemeChange = () => {},
  onThemeReset = () => {},
}) => {
  const [modalTitle, setModalTitle] = useState(data?.title ?? "");

  useEffect(() => {
    setModalTitle(data?.modalTitle ?? "");
  }, [data?.modalTitle]);

  return (
    <Box>
      <PropertiesHeader />
      <SectionWrapper title="Styles" onReset={onThemeReset}>
        <StylePicker
          target="login_theme_primary"
          title="Primary"
          presets={theme.presets}
          initialColor={theme.primary}
          onColorChange={(colorObj) => {
            onThemeChange({
              ...colorObj,
              targetColor: COLOR_TYPES.PRIMARY,
            });
          }}
        />
        <StylePicker
          target="login_theme_background"
          title="Background"
          presets={theme.presets}
          initialColor={theme.background}
          onColorChange={(colorObj) => {
            onThemeChange({
              ...colorObj,
              targetColor: COLOR_TYPES.BACKGROUND,
            });
          }}
        />
      </SectionWrapper>
      <SectionWrapper title="Title">
        <Box px="12px" pt="8px" mb="12px">
          <Input
            borderRadius={"10px"}
            value={modalTitle}
            placeholder="Log in "
            onChange={(event) => {
              onChange({
                modalTitle: event.target.value,
              });
              setModalTitle(event.target.value);
            }}
          />
        </Box>
        <StylePicker
          target="login_title_color"
          title="Color"
          presets={theme.presets}
          initialColor={theme.title}
          onColorChange={(colorObj) => {
            onThemeChange({
              ...colorObj,
              targetColor: COLOR_TYPES.TITLE,
            });
          }}
        />
      </SectionWrapper>
    </Box>
  );
};

LoginProperties.propTypes = {
  data: PropTypes.object,
  theme: PropTypes.object,
  onChange: PropTypes.func,
  onThemeChange: PropTypes.func,
  onThemeReset: PropTypes.func,
};

LoginPropertiesContainer.propTypes = {
  webshop: PropTypes.object,
  storefront: PropTypes.object,
};
