import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import actions from "@redux/actions";

import { Box, Heading } from "@chakra-ui/react";
import DailyRevenueCard from "./DailyRevenueCard";
import DashboardCardWrapper from "@components/dashboard/DashboardCardWrapper";
import ErrorBoundary from "@components/ErrorBoundary";

import { OPTIONS_SCHEMA, getFullOptions } from "@utils/dashboard";
import { scrollToTarget } from "@utils/general";
import { getInitialOption } from "./utils/dailyRevenueUtils";
import { getIntegrationBlurData } from "@utils/blur";

export const DailyRevenueContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  // Daily revenue card knows all the platforms and the selected platform
  const [selectedOption, setSelectedOption] = useState(getInitialOption());
  // The base integrations appstore, googleplay, ...
  const integrations = useSelector((state) => state.integrations);
  const integrationsStatus = integrations.base.requestStatus.getData;
  // Earnings data, charts are ready when all platforms earnings is complete
  const allEarnings = useSelector((state) => state.earnings.all);

  const [chartsReady, setChartsReady] = useState(false);
  const [fullOptions, setFullOptions] = useState(OPTIONS_SCHEMA);

  const selectedOptionData = useSelector((state) => state.earnings[selectedOption.id]);
  const selectedOptionRequestStatus = selectedOptionData?.requestStatus;
  const selectedOptionEarnings = selectedOptionData?.earnings;
  const selectedOptionLoaded = selectedOptionData?.initialLoadComplete;
  const selectedOptionLastUpdated = selectedOptionData?.lastUpdated || selectedOptionRequestStatus?.lastUpdated;
  const user = useSelector((state) => state.session.user);

  const { isPullingData, isBroken } = getIntegrationBlurData(integrations);

  useEffect(() => {
    if (location?.state?.scrollToUpcoming) {
      setTimeout(() => scrollToTarget("upcoming-payments"), 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (integrationsStatus.completed) {
      // When integrations or networks change, update the full options
      const newOptions = getFullOptions(integrations, integrationsStatus);
      if (newOptions) setFullOptions(newOptions);
    }
  }, [integrations, integrationsStatus]);

  useEffect(() => {
    const { pending, completed } = allEarnings.requestStatus;
    if (fullOptions.isReady && !pending && !completed) {
      // When the options list is complete, call the all platform earnings call
      dispatch(actions.earnings.allGetEarnings.request(fullOptions.allViableIDsString));
    }
    // eslint-disable-next-line
  }, [fullOptions]);

  useEffect(() => {
    if (allEarnings.initialLoadComplete) {
      setChartsReady(true);
    }
  }, [allEarnings]);

  return (
    <ErrorBoundary name="daily-revenue-charts-component">
      <DashboardCardWrapper data-test-id="revenue-chart-wrapper">
        <Box>
          <Heading size={"small"} fontWeight={"semibold"} marginBottom={"24px"}>
            Daily revenue
          </Heading>

          {!chartsReady && (
            <DailyRevenueCard
              fullOptions={fullOptions}
              isLoading={!chartsReady}
              isNothingConnected={true}
              isPullingData={isPullingData}
              isBroken={isBroken}
              user={user}
              selectedOption={{
                ...selectedOption,
                earnings: selectedOptionEarnings,
                lastUpdated: selectedOptionLastUpdated,
                requestStatus: selectedOptionRequestStatus,
              }}
              setSelectedOption={setSelectedOption}
            />
          )}

          {chartsReady && (
            <DailyRevenueCard
              fullOptions={fullOptions}
              isLoading={!chartsReady}
              isNothingConnected={fullOptions.isNothingConnected}
              isPullingData={isPullingData}
              isBroken={isBroken}
              user={user}
              selectedOption={{
                ...selectedOption,
                earnings: selectedOptionEarnings,
                lastUpdated: selectedOptionLastUpdated,
                requestStatus: selectedOptionRequestStatus,
                initialLoadComplete: selectedOptionLoaded,
              }}
              setSelectedOption={setSelectedOption}
            />
          )}
        </Box>
      </DashboardCardWrapper>
    </ErrorBoundary>
  );
};
