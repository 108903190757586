import symbols from "@src/utils/symbols";

export const tooltipByLabel = {
  CPI: {
    content:
      "Indicates what it's costing you to get a new user to use your product. CPI only takes into account direct installs from ads, whereas eCPI also includes the benefit of organic installs.",
    source: "AppsFlyer",
  },
  INSTALLS: {
    content: `Indicates how many users downloaded and installed your product(s) for the products and timeframe selected above ${symbols.pointUp}.`,
    source: "AppsFlyer",
  },
  ROAS: {
    content: `Total revenue generated per dollar of ad spend for the products and timeframe selected above ${symbols.pointUp}.`,
    source: "AppsFlyer",
  },
};
