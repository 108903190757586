import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, HStack, Heading, Icon, Text, VStack, useClipboard } from "@chakra-ui/react";
import { ReactComponent as AlertCircle } from "@assets/img/icons/common/alertCircle.svg";
import { ReactComponent as AlertTriangle } from "@assets/img/icons/common/alertTriangle.svg";
import { ReactComponent as CopyIcon } from "@assets/img/icons/common/copy.svg";
import PropTypes from "prop-types";

export const Step2 = ({ title, handleClose, generateKey }) => {
  const dispatch = useDispatch();
  const { selector, clean } = generateKey;
  const { data: { key = "" } = {} } = useSelector(selector);

  const [hasCopiedKey, setHasCopiedKey] = useState(false);
  const [displayCopyWarning, setDisplayCopyWarning] = useState(false);
  const { hasCopied, onCopy } = useClipboard(key);

  const onClose = () => {
    if (hasCopiedKey) {
      handleClose();
      setHasCopiedKey(false);
      setDisplayCopyWarning(false);
      dispatch(clean());
    } else {
      setHasCopiedKey(true);
      setDisplayCopyWarning(true);
    }
  };

  let iconContainer = null;
  if (hasCopied) {
    if (!hasCopiedKey) setHasCopiedKey(true);
    iconContainer = (
      <Text size={"small"} color={"textSubtext.400"}>
        Copied!
      </Text>
    );
  } else if (key) {
    iconContainer = <Icon as={CopyIcon} w={"16px"} h={"16px"} />;
  }

  const copiedText = !displayCopyWarning
    ? `PLEASE NOTE: This is the only time you will see your full ${title}. Misplacement will require to you to generate a new ${title}.`
    : `ARE YOU SURE: This is the only time you will see your ${title}. Highly recommend you copy and save it`;

  return (
    <VStack alignItems={"flex-start"} gap={"24px"}>
      <VStack alignItems={"flex-start"} gap={"16px"}>
        <Heading color={"textWhite.400"}>{title}</Heading>
        <HStack
          w={"100%"}
          p={"8px 12px"}
          alignItems={"center"}
          bgColor={displayCopyWarning ? "rgba(255, 92, 121, 0.16)" : "#FFD73529"}
          borderRadius={"10px"}
          gap={"8px"}
        >
          <Icon
            as={displayCopyWarning ? AlertTriangle : AlertCircle}
            width={"16px"}
            height={"16px"}
            color={displayCopyWarning ? "brandRed.400" : "brandYellow.400"}
            transform={displayCopyWarning ? "" : "rotate(180deg)"}
          />
          <Text size={"smallAlt"} fontWeight={500} color={displayCopyWarning ? "brandRed.400" : "brandYellow.400"}>
            {copiedText}
          </Text>
        </HStack>
        <HStack
          w={"100%"}
          p={"10px"}
          justifyContent={"space-between"}
          borderRadius={"10px"}
          border={"1px solid"}
          borderColor={"dark.300"}
          onClick={onCopy}
          cursor={key && "pointer"}
        >
          <Text size={"small"} minH={"16px"} color={"textSubtext.400"}>
            {key}
          </Text>
          {iconContainer}
        </HStack>
      </VStack>
      <Button variant={"primary"} onClick={onClose}>
        Close
      </Button>
    </VStack>
  );
};

Step2.propTypes = {
  title: PropTypes.string,
  handleClose: PropTypes.func,
  generateKey: PropTypes.shape({
    thunk: PropTypes.func,
    selector: PropTypes.func,
    clean: PropTypes.func,
  }),
};
