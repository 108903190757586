import React from "react";
import { StylePicker } from "../../StylePicker/StylePicker";
import { getImage, FILE_TYPES, COLOR_TYPES } from "@utils/webshop/section";

import PropTypes from "prop-types";

export const BackgroundStylePicker = ({
  checkLegacyBg = false,
  target = "",
  theme = {},
  data = {},
  image = false,
  onThemeChange = () => {},
  onFileCreate = () => {},
  onFileUpdate = () => {},
  onFileRemove = () => {},
}) => {
  let imageProps = {};
  if (data) {
    let bg_image = getImage(FILE_TYPES.BACKGROUND, data?.files);
    if (checkLegacyBg && !bg_image.url) bg_image = getImage(FILE_TYPES.MAIN, data?.files);
    imageProps = { initialImage: bg_image.url, initialOverlay: bg_image.overlay };
  }

  return (
    <StylePicker
      image={image}
      target={target}
      title={"Background"}
      presets={theme.presets}
      initialColor={theme.background}
      onColorChange={(colorObj) => {
        onThemeChange({
          ...colorObj,
          targetColor: COLOR_TYPES.BACKGROUND,
        });
      }}
      onImageChange={(file) => {
        onFileCreate({
          ...file,
          target: FILE_TYPES.BACKGROUND,
        });
      }}
      onImageUpdate={(file) => {
        onFileUpdate({
          ...file,
          target: FILE_TYPES.BACKGROUND,
        });
      }}
      onImageRemove={() => {
        onFileRemove({
          target: FILE_TYPES.BACKGROUND,
        });
      }}
      {...imageProps}
    />
  );
};

BackgroundStylePicker.propTypes = {
  checkLegacyBg: PropTypes.bool,
  data: PropTypes.object,
  image: PropTypes.bool,
  target: PropTypes.string,
  onFileCreate: PropTypes.func,
  onFileUpdate: PropTypes.func,
  onFileRemove: PropTypes.func,
  onThemeChange: PropTypes.func,
  theme: PropTypes.object.isRequired,
};
