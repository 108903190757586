import { Modal, ModalCloseButton, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { AdNetworksList } from "./components/step-1/Step1";
import { AdNetworkConnections } from "./components/step-2/Step2";
import { AnimatePresence, motion } from "framer-motion";
import { cloneElement, useEffect, useState } from "react";
import { trackGTMEvent, ADD_NETWORKS_ACTION } from "@utils/gtm";

const steps = [<AdNetworksList key="AdNetworksList" />, <AdNetworkConnections key="AdNetworkConnections" />];

export function AdNetworksStepper({ isOpen, onClose, onCloseComplete, initialStep }) {
  const [[step, direction], setStep] = useState(() => [0, 0]);

  useEffect(() => {
    if (initialStep) {
      setStep([initialStep, 1]);
    }
  }, [initialStep]);

  const variants = {
    enters: (direction) => ({
      x: direction > 0 ? 1000 : -1000,
    }),
    center: {
      x: 0,
    },
    exit: (direction) => ({
      x: direction < 1 ? -1000 : 1000,
    }),
  };

  const trackEvent = (action) => {
    trackGTMEvent({
      event: action,
    });
  };

  const nextStep = () => {
    trackEvent(ADD_NETWORKS_ACTION.MODAL_NEXT);
    if (step < steps.length - 1) {
      return setStep([step + 1, 1]);
    }
    onClose();
  };
  const prevStep = () => {
    trackEvent(ADD_NETWORKS_ACTION.MODAL_BACK);
    if (step > 0) {
      setStep([step - 1, 0]);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={"lg"}
      onCloseComplete={() => {
        setStep([0, 0]);
        onCloseComplete();
      }}
    >
      <ModalOverlay />
      <ModalContent overflow={"hidden"}>
        <ModalCloseButton />
        <AnimatePresence initial={false} mode="popLayout">
          <motion.div
            style={{ display: "flex", flexDirection: "column", flex: 1 }}
            variants={variants}
            transition={{ x: { type: "spring", stiffness: 300, damping: 30 }, opacity: { duration: 0.2 } }}
            initial="enters"
            animate="center"
            custom={direction}
            exit="exit"
            key={step}
          >
            {cloneElement(steps[step], { handleNextStep: nextStep, handlePrevStep: prevStep })}
          </motion.div>
        </AnimatePresence>
      </ModalContent>
    </Modal>
  );
}
