import { Text } from "@chakra-ui/react";

import { copy } from "@utils/general";
import { dayWithSuffix } from "@utils/format";
import moment from "moment";
import { CASH_ACTIVITY_TIME_OPTIONS } from "@src/consts/financial-intelligence";
const DEFAULT_IMAGE = require("@assets/img/icons/adNetworkDefault.png");

// Calculate the date two months from now
const plusTwoMonths = new Date();
plusTwoMonths.setMonth(plusTwoMonths.getMonth() + 2);
// Calculate the last day of the calculated month
const lastDay = new Date(plusTwoMonths.getFullYear(), plusTwoMonths.getMonth() + 1, 0);

export const calculateIntersection = (A, B, C, D) => {
  var a1 = B.y - A.y;
  var b1 = A.x - B.x;
  var c1 = a1 * A.x + b1 * A.y;

  var a2 = D.y - C.y;
  var b2 = C.x - D.x;
  var c2 = a2 * C.x + b2 * C.y;

  var determinant = a1 * b2 - a2 * b1;

  if (determinant === 0) {
    // The lines are parallel. This is simplified
    // you can check for segments' touch or overlap
    return null;
  } else {
    var x = (b2 * c1 - b1 * c2) / determinant;
    var y = (a1 * c2 - a2 * c1) / determinant;
    return { x, y };
  }
};

const groupingParsers = {
  [CASH_ACTIVITY_TIME_OPTIONS.MONTHLY.grouping]: {
    filterActivities: (activities, month) => {
      return changeMonth(activities, month);
    },
    endOfBalanceShortDate: (date, shortDate, formatDate) => {
      const last_day_of_month = moment(date).endOf("month").format("DD");
      const day_suffix = dayWithSuffix(Number(last_day_of_month));
      return `${shortDate} ${day_suffix}`;
    },
    endOfBalanceData: (data) => {
      // We don't need to do anything for monthly
      return data;
    },
    endOfBalanceSVGPointParser: (opts = {}) => {
      const { point, dimensions, theme } = opts;
      const cPos = point.pos;
      const height = dimensions.height;
      const halfHeight = height / 2;
      const color = cPos.y < halfHeight ? theme.colors.green[400] : theme.colors.red[400];
      return { color };
    },
    endOfBalanceSVGLineParser: (opts = {}) => {
      const { point = {}, nextPoint, itemWidth, dimensions, theme } = opts;
      const { testData = {} } = point;
      const { todayDay: testTodayDay } = testData;

      if (!nextPoint) return null;

      const cPos = point.pos;
      const nPos = nextPoint.pos;

      // Do whatever we want with the point(s) before they are drawn
      // In this case we'll be checking for sub-points for more styled drawing
      let subLines = [];
      let strokeStyle = "solid";
      // Check if the path crosses the 0 line
      const height = dimensions.height;
      const halfHeight = height / 2;
      const yVariantOne = cPos.y <= halfHeight && nPos.y > halfHeight;
      const yVariantTwo = cPos.y >= halfHeight && nPos.y < halfHeight;
      if (yVariantOne || yVariantTwo) {
        const intersection = calculateIntersection(
          {
            x: cPos.x,
            y: cPos.y,
          },
          {
            x: nPos.x,
            y: nPos.y,
          },
          {
            x: 0,
            y: halfHeight,
          },
          {
            x: itemWidth,
            y: halfHeight,
          },
        );

        if (!isNaN(intersection.x) && !isNaN(intersection.y)) {
          const splitPoint = {
            x: intersection.x,
            y: intersection.y,
          };
          subLines.push({
            x1: cPos.x,
            y1: cPos.y,
            x2: splitPoint.x,
            y2: splitPoint.y,
            color: cPos.y < halfHeight ? theme.colors.green[400] : theme.colors.red[400],
            style: strokeStyle,
          });
          subLines.push({
            x1: splitPoint.x,
            y1: splitPoint.y,
            x2: nPos.x,
            y2: nPos.y,
            color: nPos.y < halfHeight ? theme.colors.green[400] : theme.colors.red[400],
            style: strokeStyle,
          });
        }
      } else {
        // return the original line
        subLines.push({
          x1: cPos.x,
          y1: cPos.y,
          x2: nPos.x,
          y2: nPos.y,
          color: cPos.y < halfHeight ? theme.colors.green[400] : theme.colors.red[400],
          style: strokeStyle,
        });
      }

      // Check if the path crosses today's date
      // Check if the month (rawDate 2023-05) contains today
      // The date is end of month, so the lines we are drawing represent the following month
      // We need to check if the next month contains today
      const rawDate = nextPoint.rawDate;
      if (moment(rawDate).isSame(moment(), "month")) {
        // The path crosses today's date
        // We need to get the number of days in the month
        const daysInMonth = moment(rawDate).daysInMonth();
        // Then get how wide a day is, and multiply that by the day it is in the month
        const dayWidth = itemWidth / daysInMonth;
        let today = moment().date();
        if (testTodayDay) today = testTodayDay;
        const todayX = cPos.x + today * dayWidth;
        // Now we can make a vertical "today" vector to split on
        // It has to be in regards to the overall SVG, not the line
        const todayVector = {
          px: todayX,
          py: 0,
          dx: todayX,
          dy: height,
        };

        // Now we can go through the current subLines and check if they cross the today vector
        // and split accordingly
        let splitFound = false;
        const newSubLines = [];
        subLines.forEach((line) => {
          // We can do initial checks against todayX to see if the lines cross
          // before getting the intersection
          const xVariantOne = line.x1 <= todayX && line.x2 > todayX;
          const xVariantTwo = line.x1 >= todayX && line.x2 < todayX;
          if (xVariantOne || xVariantTwo) {
            const intersection = calculateIntersection(
              {
                x: line.x1,
                y: line.y1,
              },
              {
                x: line.x2,
                y: line.y2,
              },
              {
                x: todayVector.px,
                y: todayVector.py,
              },
              {
                x: todayVector.dx,
                y: todayVector.dy,
              },
            );

            if (!isNaN(intersection.x) && !isNaN(intersection.y)) {
              // We have an intersection, we need to split at the intersection
              const splitPoint = {
                x: intersection.x,
                y: intersection.y,
              };
              newSubLines.push({
                x1: line.x1,
                y1: line.y1,
                x2: splitPoint.x,
                y2: splitPoint.y,
                color: line.color,
                style: strokeStyle,
              });
              strokeStyle = "dashed";
              newSubLines.push({
                x1: splitPoint.x,
                y1: splitPoint.y,
                x2: line.x2,
                y2: line.y2,
                color: line.color,
                style: strokeStyle,
              });
              splitFound = true;
            } else {
              // return the original line
              if (splitFound) line.style = strokeStyle;
              newSubLines.push(line);
            }
          } else {
            // return the original line
            if (splitFound) line.style = strokeStyle;
            newSubLines.push(line);
          }
        });

        // push the today vector, color partial transparent white
        newSubLines.push({
          x1: todayVector.px,
          y1: todayVector.py,
          x2: todayVector.dx,
          y2: todayVector.dy,
          color: "rgba(255, 255, 255, 0.5)",
          style: "solid",
          width: 1,
        });

        subLines = newSubLines;
      }

      // If the day in a month after today, we need to make the line dashed
      if (moment(rawDate).isAfter(moment(), "month")) {
        subLines.forEach((line) => {
          line.style = "dashed";
        });
      }

      return subLines.length ? subLines : null;
    },
    getActivityDate: ({ date, current, shortMonth, formatDate }) => {
      const currentDate = moment();
      return {
        shortDate: `${shortMonth}'${formatDate({ year: "2-digit" })}`,
        isPredicted: date.isAfter(currentDate) && !current,
        fullDate: () =>
          formatDate({
            month: "long",
            year: "numeric",
          }),
      };
    },
    getTimeframe: (activities, metadata) => {
      const { shortMonth: fromMonth, shortYear: fromShortYear } = dateFormatter(activities[0].date);
      const { shortMonth: toMonth, shortYear: toShortYear } = dateFormatter(activities[activities.length - 1].date);
      let timeframe = {
        fromDate: moment(activities[0].date).format("YYYY-MM-DD"),
        toDate: moment(activities[activities.length - 1].date)
          .endOf("month")
          .format("YYYY-MM-DD"),
      };
      const disabledNextMonth = timeframe.toDate === metadata.toDate;

      return {
        ...timeframe,
        label: `${`${fromMonth}'${fromShortYear}`} - ${`${toMonth}'${toShortYear}`}`, // Aug'23 - Oct'23
        disabledNextMonth,
      };
    },
  },
  [CASH_ACTIVITY_TIME_OPTIONS.DAILY.grouping]: {
    filterActivities: (activities, month) => {
      return activities.filter((elem) => isDateInMonth(elem.date, month));
    },
    endOfBalanceShortDate: (date, shortDate, formatDate) => {
      return formatDate({ day: "numeric" });
    },
    endOfBalanceData: (data) => {
      // Check the inflow and outflow activities length
      const inflowActivities = data.activity.cashIn.activities;
      const outflowActivities = data.activity.cashOut.activities;
      const hasActivities = inflowActivities.length || outflowActivities.length;
      data.hasActivities = hasActivities;
      if (data.isPredicted) {
        // We don't want to show any predicted data for daily
        data.isInvisible = true;
      }
      if (!data.hasActivities) {
        data.hideMarker = true;
      } else {
        data.showMarker = true;
      }
      return data;
    },
    endOfBalanceSVGPointParser: (opts = {}) => {
      const { point, dimensions, theme } = opts;
      const cPos = point.pos;
      const height = dimensions.height;
      const halfHeight = height / 2;
      const color = cPos.y < halfHeight ? theme.colors.green[400] : theme.colors.red[400];
      return { color };
    },
    endOfBalanceSVGLineParser: (opts) => {
      const { point, nextPoint, itemWidth, dimensions, theme } = opts;

      if (!nextPoint) return null;

      const cPos = point.pos;
      const nPos = nextPoint.pos;
      const height = dimensions.height;
      const halfHeight = height / 2;
      // Do whatever we want with the point(s) before they are drawn
      // In this case we'll be checking for sub-points for more styled drawing
      let subLines = [];

      // Check if the path crosses today's date
      // Check if the month (rawDate format 2023-05-23) is today
      const rawDate = point.rawDate;
      if (moment(rawDate).isSame(moment(), "day")) {
        // push the today vector, color partial transparent white
        subLines.push({
          x1: cPos.x,
          y1: 0,
          x2: cPos.x,
          y2: height,
          color: "rgba(255, 255, 255, 0.5)",
          style: "solid",
          width: 1,
        });
      }

      if (point.isInvisible || nextPoint.isInvisible) {
        // We still want to draw the line, but make it invisible
        return subLines.length ? subLines : null;
      }

      // Check if the path crosses the 0 line
      const yVariantOne = cPos.y < halfHeight && nPos.y > halfHeight;
      const yVariantTwo = cPos.y > halfHeight && nPos.y < halfHeight;
      if (yVariantOne || yVariantTwo) {
        const intersection = calculateIntersection(
          {
            x: cPos.x,
            y: cPos.y,
          },
          {
            x: nPos.x,
            y: nPos.y,
          },
          {
            x: 0,
            y: halfHeight,
          },
          {
            x: itemWidth,
            y: halfHeight,
          },
        );

        if (!isNaN(intersection.x) && !isNaN(intersection.y)) {
          const splitPoint = {
            x: intersection.x,
            y: intersection.y,
          };
          subLines.push({
            x1: cPos.x,
            y1: cPos.y,
            x2: splitPoint.x,
            y2: splitPoint.y,
            color: cPos.y < halfHeight ? theme.colors.green[400] : theme.colors.red[400],
            style: "solid",
          });
          subLines.push({
            x1: splitPoint.x,
            y1: splitPoint.y,
            x2: nPos.x,
            y2: nPos.y,
            color: nPos.y < halfHeight ? theme.colors.green[400] : theme.colors.red[400],
            style: "solid",
          });
        }
      } else {
        // return the original line
        subLines.push({
          x1: cPos.x,
          y1: cPos.y,
          x2: nPos.x,
          y2: nPos.y,
          color: cPos.y < halfHeight ? theme.colors.green[400] : theme.colors.red[400],
          style: "solid",
        });
      }

      return subLines.length ? subLines : null;
    },
    getActivityDate: ({ date, formatDate }) => {
      const currentDate = moment();
      return {
        shortDate: formatDate({ day: "numeric" }),
        isPredicted: date.isAfter(currentDate),
        fullDate: () =>
          formatDate({ day: "numeric", month: "long", year: "numeric" }).replace(/(\d+)(?=,)/, (_, day) =>
            dayWithSuffix(Number(day)),
          ),
      };
    },
    getTimeframe: (activities, metadata) => {
      const { fullMonth, shortYear: fromShortYear } = dateFormatter(activities[0].date);
      let timeframe = {
        fromDate: moment(activities[0].date).format("YYYY-MM-DD"),
        toDate: moment(activities[activities.length - 1].date)
          .endOf("month")
          .format("YYYY-MM-DD"),
      };
      const disabledNextMonth = timeframe.toDate === metadata.toDate;

      return {
        ...timeframe,
        label: `${fullMonth}'${fromShortYear}`, //August'23
        disabledNextMonth,
      };
    },
  },
};

export const filterActivities = ({
  activities = [],
  timeGrouping = CASH_ACTIVITY_TIME_OPTIONS.MONTHLY.grouping,
  month = "",
}) => {
  return groupingParsers[timeGrouping].filterActivities(activities, month);
};

export const changeMonth = (activities, pivotMonth) => {
  const month = moment(pivotMonth);

  const startDate = month.clone().subtract(5, "months");
  const endDate = month.clone().add(2, "months");

  return activities.filter((activity) => {
    const activityDate = moment(activity.date);
    return activityDate.isSameOrAfter(startDate) && activityDate.isSameOrBefore(endDate);
  });
};

export const isDateInMonth = (date, givenMonth = moment()) => {
  const currentDate = moment(date);
  const inputDate = moment(givenMonth);

  return inputDate.year() === currentDate.year() && inputDate.month() === currentDate.month();
};

export const dateFormatter = (date = new Date()) => {
  // Ensure the date is in the correct format for the javascript Date constructor
  const newDate = new Date(moment(date).format("YYYY/MM/DD"));
  const formatDate = (options) => newDate.toLocaleDateString("en-US", options);

  const shortMonth = formatDate({ month: "short" });
  const fullMonth = formatDate({ month: "long" });
  const shortYear = formatDate({ year: "2-digit" });

  return { shortMonth, fullMonth, shortYear, formatDate };
};

export const getMonthRange = (activities = []) => {
  return {
    fromDate: moment(activities[0]?.date).startOf("month").format("YYYY-MM-DD"),
    toDate: moment(activities[activities.length - 1]?.date)
      .endOf("month")
      .format("YYYY-MM-DD"),
  };
};

export const sortUniqueMonths = (activities = []) => {
  const newActivities = [];
  const seenDates = new Set();

  // sort activities by date from lowest to highest
  activities.sort((monthA, monthB) => {
    const dateA = moment(monthA.date, "YYYY-MM-DD");
    const dateB = moment(monthB.date, "YYYY-MM-DD");
    return dateA - dateB;
  });

  // remove duplicate activities, by date
  activities.forEach((activity) => {
    const date = activity.date;
    if (!seenDates.has(date)) {
      newActivities.push(activity);
      seenDates.add(date);
    }
  });

  return newActivities;
};

export const parseSummaryMetrics = (dailyActivities = [], monthlyActivities = []) => {
  const { formatDate } = dateFormatter();
  const { shortYear: twoMonthsFromNowShortYear } = dateFormatter(plusTwoMonths);

  const lastDayFormatted = formatDate({ day: "numeric" }).replace(/\d+/, lastDay.getDate());
  const twoMonthsFromNowLabel = plusTwoMonths.toLocaleDateString("en-US", { month: "short" });
  const summaryDate = `${twoMonthsFromNowLabel} ${lastDayFormatted}, '${twoMonthsFromNowShortYear}`; // Aug 31, '23

  const currentDay = dailyActivities.filter((activity) => activity.current === true)[0] || { endOfDateBalanceCents: 0 };

  const filteredMonthlyActivity = monthlyActivities.filter((activity) => {
    const activityDate = moment(activity.date, "YYYY-MM");
    return activityDate.isSame(plusTwoMonths, "month") && activityDate.isSame(plusTwoMonths, "year");
  });
  const twoFutureMonth = filteredMonthlyActivity[0] || { predictedEndOfDateBalanceCents: 0 };

  const amountInCents = twoFutureMonth.predictedEndOfDateBalanceCents - currentDay.endOfDateBalanceCents;
  return { date: summaryDate, amountInCents };
};

export const parseCashActivityCategories = (activityCategories = {}) => {
  // Hold all the activities in a map for easy lookup
  Object.entries(activityCategories).forEach(([cashFlowType, categoryArray]) => {
    // cashFlowType === "cashIn" || "cashOut"
    categoryArray.forEach((category) => {
      category.checked = true;
      // ensure unique ids by adding the cash flow type
      category.id = `${cashFlowType}-${category.id}`;
      category.activities.forEach((activity) => {
        activity.defaultImage = DEFAULT_IMAGE;
        activity.checked = true;
      });
    });
  });
  return activityCategories;
};

const getFiscalPeriodDate = (date, withYear = false) => {
  const { shortYear, formatDate } = dateFormatter(date);

  const monthDay = formatDate({ day: "numeric", month: "short" }).replace(/(\d+)/, (_, day) =>
    dayWithSuffix(Number(day)),
  );

  return `${monthDay}${withYear ? `, '${shortYear}` : ""}`;
};

export const getActivity = (activities = [], cashCategory = []) => {
  const activitiesMap = cashCategory.reduce((map, { activities }) => {
    activities.forEach((activity) => {
      if (activity.checked) {
        map[activity.id] = activity;
      }
    });
    return map;
  }, {});

  const flatActivities = activities.flatMap((activity) => {
    let fiscalPeriod;
    if (activity.period) {
      const { startDate, endDate, estimatedDate } = activity.period;
      fiscalPeriod = `${getFiscalPeriodDate(startDate, estimatedDate)} - ${getFiscalPeriodDate(
        endDate,
        estimatedDate,
      )}`;
    }
    return activitiesMap[activity.id]
      ? [
          {
            ...activity,
            ...activitiesMap[activity.id],
            defaultImage: DEFAULT_IMAGE,
            period: { ...activity?.period, fiscalPeriod },
          },
        ]
      : [];
  });

  // sort by amount, descending
  // use predictedAmountCents if it exists, otherwise use amountCents
  // use absolute values for sorting, so negative values are sorted correctly
  flatActivities.sort((a, b) => {
    const aAmount = a.predictedAmountCents || a.amountCents;
    const bAmount = b.predictedAmountCents || b.amountCents;
    return Math.abs(bAmount) - Math.abs(aAmount);
  });

  return flatActivities;
};

export const getCashFlowAmounts = (activities = [], activityCategories = []) => {
  // Go through activities and add up the amounts
  // We need to go through activities first because there may be activities
  // that are not in the activityCategories, this is a temporary bug
  let total = 0;
  let predictedTotal = 0;
  activities.forEach((activity) => {
    // We need to find the activity in activityCategories to see if it is checked
    let foundActivity = null;
    activityCategories.forEach((category) => {
      category.activities.forEach((categoryActivity) => {
        if (categoryActivity.id === activity.id) {
          foundActivity = categoryActivity;
        }
      });
    });

    if (!foundActivity) {
      // The activity was not found but we want to show it anyway
      foundActivity = activity;
      // We need to fake the name and icon, a full uppercase name will be used
      // to spot these activities
      foundActivity.name = foundActivity.id.toUpperCase();
      foundActivity.icon = DEFAULT_IMAGE;
      foundActivity.checked = true;
    }

    if (!foundActivity.checked) return;

    if (typeof activity.amountCents === "number") {
      total += Math.abs(activity.amountCents);
    }
    if (typeof activity.predictedAmountCents === "number") {
      predictedTotal += Math.abs(activity.predictedAmountCents);
    }
  });

  return {
    amount: total / 100 || 0,
    predictedAmount: predictedTotal / 100 || 0,
  };
};

export const getTimeframe = (activities, metadata, timeGrouping) => {
  return groupingParsers[timeGrouping].getTimeframe(activities, metadata);
};

export const getActivityDate = ({ date, current }, timeGrouping) => {
  return groupingParsers[timeGrouping].getActivityDate({
    date: moment(date),
    current,
    timeGrouping,
    ...dateFormatter(date),
  });
};

export const parseFlowOverviewChartData = ({
  activities = [],
  activityCategories = [],
  metadata = {},
  timeGrouping = CASH_ACTIVITY_TIME_OPTIONS.MONTHLY.grouping,
}) => {
  try {
    // These activities are the months or days, not the cash flow activities
    let data = copy(activities);

    const newActivities = data.map((elem) => {
      const { isPredicted, shortDate, fullDate } = getActivityDate(elem, timeGrouping);
      // Get the inflow and outflow activities
      const inflowActivities = getActivity(elem.cashIn.activities, activityCategories.cashIn);
      const outflowActivities = getActivity(elem.cashOut.activities, activityCategories.cashOut);
      // Use the activities and the categories to get the inflow and outflow amounts
      const inflowAmounts = getCashFlowAmounts(inflowActivities, activityCategories.cashIn);
      const outflowAmounts = getCashFlowAmounts(outflowActivities, activityCategories.cashOut);

      return {
        shortDate: shortDate,
        fullDate,
        isPredicted,
        isCurrent: elem.current,
        inflow: {
          ...inflowAmounts,
          activities: inflowActivities,
        },
        outflow: {
          ...outflowAmounts,
          activities: outflowActivities,
        },
        xAxisItemParser: (item, key = "") => {
          return (
            <Text
              key={key}
              size={"mini"}
              textAlign={"center"}
              letterSpacing={"0.02em"}
              color={"textWhite.100"}
              opacity={item.isPredicted ? 0.5 : 1}
            >
              {item.shortDate}
            </Text>
          );
        },
      };
    });

    return {
      timeframe: getTimeframe(data, metadata, timeGrouping),
      activities: newActivities,
    };
  } catch (e) {
    return [];
  }
};

export const parseEndOfDateBalanceData = ({
  activities = [],
  timeGrouping = CASH_ACTIVITY_TIME_OPTIONS.MONTHLY.grouping,
  testData = {},
}) => {
  try {
    const newData = activities.map((activity) => {
      const { current = false } = activity;
      let { shortMonth: shortDate, fullMonth, shortYear, formatDate } = dateFormatter(activity.date);
      shortDate = groupingParsers[timeGrouping].endOfBalanceShortDate(activity.date, shortDate, formatDate);
      const { endOfDateBalanceCents, predictedEndOfDateBalanceCents } = activity;
      const isPredicted = moment(activity.date).isAfter(moment()) && !current;
      let amount = isPredicted ? predictedEndOfDateBalanceCents : endOfDateBalanceCents;

      const parsedBalanceData = groupingParsers[timeGrouping].endOfBalanceData({
        activity,
        rawDate: activity.date,
        shortDate,
        fullDate: () => `${fullMonth} ${shortYear}`,
        monthlyAmount: amount ? amount / 100 : null,
        isPredicted,
        svgLineParser: groupingParsers[timeGrouping].endOfBalanceSVGLineParser,
        svgPointParser: groupingParsers[timeGrouping].endOfBalanceSVGPointParser,
        xAxisItemParser: (item, key = "") => {
          return (
            <Text
              key={key}
              size={"mini"}
              textAlign={"center"}
              letterSpacing={"0.02em"}
              color={"textWhite.100"}
              opacity={item.isPredicted ? 0.5 : 1}
            >
              {item.shortDate}
            </Text>
          );
        },
        testData,
      });
      return parsedBalanceData;
    });
    return newData;
  } catch (e) {
    return [];
  }
};

// Toggling logic

export const toggleCashActivity = ({
  monthlyActivities = {},
  dailyActivities = {},
  all = null,
  categoryId = "",
  activityId = "",
  cashFlowTypeId = "",
}) => {
  // TOGGLE MONTHLY
  const {
    data: monthlyData,
    activityCategories: monthlyActivityCategories,
    allChecked: monthlyAllChecked,
  } = toggleActivities({
    currentGrouping: monthlyActivities,
    timeGrouping: CASH_ACTIVITY_TIME_OPTIONS.MONTHLY.grouping,
    all,
    categoryId,
    activityId,
    cashFlowTypeId,
  });

  // TOGGLE DAILY
  const {
    data: dailyData,
    activityCategories: dailyActivityCategories,
    allChecked: dailyAllChecked,
  } = toggleActivities({
    currentGrouping: dailyActivities,
    timeGrouping: CASH_ACTIVITY_TIME_OPTIONS.DAILY.grouping,
    all,
    categoryId,
    activityId,
    cashFlowTypeId,
  });

  return {
    monthly: { data: monthlyData, activityCategories: monthlyActivityCategories, allChecked: monthlyAllChecked },
    daily: { data: dailyData, activityCategories: dailyActivityCategories, allChecked: dailyAllChecked },
  };
};

export const toggleActivities = ({
  currentGrouping = {},
  timeGrouping = CASH_ACTIVITY_TIME_OPTIONS.MONTHLY.grouping,
  all = null,
  categoryId = "",
  activityId = "",
  cashFlowTypeId = "",
}) => {
  let activityCategories = currentGrouping.activityCategories;
  let allChecked = null;

  const { newAllState, activityCategories: newActCats } = cashFlowToggle(activityCategories, {
    all,
    categoryId,
    activityId,
    cashFlowTypeId,
  });
  activityCategories = newActCats;

  if (typeof newAllState === "boolean") {
    allChecked = newAllState;
  }

  const newData = parseFlowOverviewChartData({
    activities: filterActivities({
      activities: currentGrouping.activities,
      timeGrouping,
      month: currentGrouping.pivot,
    }),
    activityCategories,
    timeGrouping,
  });

  const payload = {
    data: newData,
    activityCategories,
  };

  if (allChecked !== null) {
    payload.allChecked = allChecked;
  }

  return payload;
};

export const cashFlowToggle = (activityCategories, checkedOptions = {}) => {
  const { all, categoryId = "", activityId = "", cashFlowTypeId = "" } = checkedOptions;

  let forceAllCheck = null;
  if (all !== undefined && typeof all === "boolean") {
    forceAllCheck = all;
  }

  let areAllChecked = true;
  // Hold all the activities in a map for easy lookup
  Object.entries(activityCategories).forEach(([cashFlowType, categoryArray]) => {
    // cashFlowType === "cashIn" || "cashOut"

    categoryArray.forEach((category) => {
      let forceActivityCheck = null;
      // The category is specifically being toggled
      if (categoryId && category.id === categoryId) {
        category.checked = !category.checked;
        forceActivityCheck = category.checked;
      }
      // The category is being toggled by the "Select All" checkbox
      if (forceAllCheck !== null) {
        category.checked = forceAllCheck;
        forceActivityCheck = forceAllCheck;
      }

      if (!category.activities || !category.activities.length) {
        // Update the cash flow type checked state
        if (!category.checked) {
          areAllChecked = false;
        }
        return;
      }

      // Update all activities in the category
      let areAllActivitiesChecked = true;
      let areAllActivitiesUnchecked = true;
      category.activities.forEach((activity) => {
        if (activityId && activity.id === activityId && cashFlowType === cashFlowTypeId) {
          activity.checked = !activity.checked;
        }
        if (forceActivityCheck !== null) {
          activity.checked = forceActivityCheck;
        }
        if (activity.checked) {
          areAllActivitiesUnchecked = false;
        } else {
          areAllActivitiesChecked = false;
          areAllChecked = false;
        }
      });
      // Update the category checked state
      if (areAllActivitiesChecked) {
        category.checked = true;
      } else if (areAllActivitiesUnchecked) {
        category.checked = false;
      }

      // Update the cash flow type checked state
      if (!category.checked) {
        areAllChecked = false;
      }
    });
  });

  let newAllState = null;
  if (!areAllChecked) {
    newAllState = false;
  } else if (areAllChecked) {
    newAllState = true;
  }

  return {
    activityCategories,
    newAllState,
  };
};

export const getCurrentCashActivity = (cashActivity = {}) => {
  const newCashActivity = { ...cashActivity };
  const { data, allChecked, activityCategories, endOfDateBalanceData } = newCashActivity;

  return { data, allChecked, activityCategories, endOfDateBalanceData };
};
