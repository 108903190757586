import {
  Box,
  Button,
  HStack,
  Heading,
  Icon,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { addHttpsToUrl } from "@src/utils/string";
import { ReactComponent as ExternalLinkIcon } from "@assets/img/icons/common/ExternalLink.svg";
import { ReactComponent as GearIcon } from "@assets/img/icons/common/gear.svg";
import { ReactComponent as MenuIcon } from "@assets/img/icons/common/more.svg";
import { ReactComponent as AlertTriangle } from "@assets/img/icons/common/alertTriangle.svg";
import { ConfirmationModal } from "@common/modals/confirmation-modal";
import { useDispatch } from "react-redux";
import { webshopsThunks } from "@redux/webshops/webshopsSlice";
import { showToast } from "@src/utils/redux";

export function Project({ name = "", id = "", imageUrl = "", published = false, domain = "" }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isOpen, onClose, onOpen } = useDisclosure();

  function visitCatalog() {
    navigate(`${id}/catalog`);
  }

  function visitBuilder() {
    navigate(`${id}/builder`);
  }

  function visitSettings() {
    navigate(`${id}/settings`);
  }

  function handlePreview() {
    window.open(addHttpsToUrl(domain), "_blank");
  }

  async function confirmRemoveProject() {
    if (!id) return;
    try {
      await dispatch(webshopsThunks.deleteWebshop(id)).unwrap();
      onClose();
    } catch (e) {
      onClose();
      showToast("There was an error removing the project", "error");
    }
  }

  return (
    <>
      <Box pos="relative" borderRadius={"12px"} bg="dark.600" overflow={"auto"} data-test-id={"webshop-projects"}>
        <Image h={"240px"} w={"100%"} objectFit={"cover"} src={imageUrl} />
        <Menu placement="left-start">
          <MenuButton
            pos="absolute"
            top="4px"
            right="4.5px"
            as={IconButton}
            icon={<Icon w="24px" h="24px" as={MenuIcon} />}
            aria-label="Options"
            variant={"unstyled"}
          />
          <MenuList>
            <MenuItem fontSize={"md"} color={"brandRed.400"} onClick={onOpen}>
              Delete
            </MenuItem>
          </MenuList>
        </Menu>
        <VStack p="16px" alignItems={"stretch"} gap="24px">
          <HStack justifyContent={"space-between"}>
            <Heading size={"regular"} fontWeight={600}>
              {name}
            </Heading>
            <Button
              size={"md"}
              rightIcon={<Icon as={ExternalLinkIcon} />}
              onClick={handlePreview}
              isDisabled={!published}
            >
              View site
            </Button>
          </HStack>
          <HStack justifyContent={"space-between"}>
            <Button size={"lg"} w={"full"} onClick={visitSettings} rightIcon={<Icon as={GearIcon} />}>
              Settings
            </Button>
            <Button size={"lg"} w="full" onClick={visitCatalog}>
              Catalog
            </Button>
            <Button size={"lg"} w="full" onClick={visitBuilder}>
              Builder
            </Button>
          </HStack>
        </VStack>
      </Box>
      <ConfirmationModal
        isOpen={isOpen}
        onAccept={confirmRemoveProject}
        onClose={onClose}
        title="Delete project"
        acceptText="Yes, delete"
        cancelText="Cancel"
      >
        <HStack bg="brandRedAlpha.400" p="12px" borderRadius={"10px"} gap={"8px"} alignItems={"center"}>
          <Icon as={AlertTriangle} color="brandRed.400" w="16px" h="16px" />
          <Box>
            <Text color="brandRed.400" size={"small"} fontWeight={500} lineHeight={"20px"}>
              WARNING:
            </Text>
            <Text color={"textWhite.400"} size={"small"} lineHeight={"20px"}>
              Are you sure? This action cannot be undone.
            </Text>
          </Box>
        </HStack>
      </ConfirmationModal>
    </>
  );
}
