import { useState } from "react";
import { closeBankingSection } from "../OpenBankingSectionForCopy";

const useAccordion = (initialValue) => {
  const [expandedAccordions, setExpandedAccordions] = useState(initialValue ? [initialValue] : []);

  const handleToggleAccordion = (selected, opts = {}) => {
    const { isInitial = false, isExpanded = null } = opts;

    if (isExpanded && !expandedAccordions.includes(selected)) {
      // This situation happens when the link opens the banking section and it becomes out
      // of sync with the state (look into accordion refactor). In this scenario, we need to
      // close the section not utilizing the state as well.
      closeBankingSection();
    } else if (expandedAccordions.includes(selected) && !isInitial) {
      setExpandedAccordions(expandedAccordions.filter((p) => p !== selected));
    } else {
      setExpandedAccordions((prevState) => [...prevState, selected]);
    }
  };

  return [expandedAccordions, handleToggleAccordion];
};

export default useAccordion;
