import { useAuth0 } from "@auth0/auth0-react";

import * as Sentry from "@sentry/react";
import LogRocket from "logrocket";
import mixpanel from "mixpanel-browser";

import { removeBrandingInfo } from "@utils/branding";
import { trackGTMEvent } from "@utils/gtm";

const parseJWT = (jwt) => {
  try {
    const base64Url = jwt.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map((c) => {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(""),
    );
    return JSON.parse(jsonPayload);
  } catch (e) {
    return {};
  }
};

export function appLogin(action) {
  mixpanel.identify(action.user.email);
  // Tag Manager Login Event, the only one in the client potentially being
  // used by thing other than mixpanel, so we still want to send this as is
  // to GTM
  trackGTMEvent(
    {
      event: "Login",
      email: action.user.email,
      domain: action.user.email.split("@")[1],
      name: action.user.name,
    },
    {
      gtm: true,
    },
  );

  if (process.env.REACT_APP_LOGROCKET_ID) {
    LogRocket.identify(action.user.sub, {
      name: action.user.name ? action.user.name : "Sanlo User",
      email: action.user.email,
    });
  }

  if (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV !== "local") {
    const parsedToken = parseJWT(action.token);
    const userObj = {
      username: action.user.name ? action.user.name : "Sanlo User",
      email: action.user.email,
    };
    if (parsedToken.sub) {
      userObj.id = parsedToken.sub;
    }
    Sentry.setUser();
  }
}

export function useLogout() {
  const { logout } = useAuth0();

  const appLogout = () => {
    mixpanel.reset();
    removeBrandingInfo();
    logout({
      returnTo: process.env.REACT_APP_SANLO_WEB_API_URL,
    });
  };

  return appLogout;
}
