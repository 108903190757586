import moment from "moment";
import { copy, objSort, alphaTrim } from "@utils/general";
import { isConnectedStatus, isNoLiveAppsStatus } from "@utils/status";
import { OPTIONS_SCHEMA } from "@utils/dashboard";
import symbols from "@utils/symbols";

// Greater than this amount of games will trigger the "other" category
const OTHER_GAMES_LIMIT = 5;

export const getDate = (date, format = null) => {
  const momentDate = moment.utc(date).startOf("day").add(moment.duration(12, "hours"));
  if (!format) return momentDate;
  return momentDate.format(format);
};

export const getGameData = (
  dailyRevenueEarnings,
  existingState,
  colors = [],
  selectedOption,
  isNothingConnected = false,
  user = null,
) => {
  let sortByEarnings = false;

  if (!existingState || !existingState.order.length || existingState.order[0].isFake) {
    sortByEarnings = true;

    existingState = {
      map: {},
      order: [],
      other: {
        enabled: false,
        selected: false,
      },
    };

    if (!isNothingConnected && selectedOption && selectedOption.id && user && user.userId) {
      const existingStorageState = localStorage.getItem("chartGameOrder") || "{}";
      const parsedExistingStorageState = JSON.parse(existingStorageState);
      const savedState = (parsedExistingStorageState[user.userId] || {})[selectedOption.id];
      if (savedState) {
        sortByEarnings = false;
        existingState = savedState;
      }
    }
  }

  const games = copy(existingState);
  if (!dailyRevenueEarnings) return games;
  dailyRevenueEarnings = copy(dailyRevenueEarnings);
  const { periodEarnings = [] } = dailyRevenueEarnings;
  if (!periodEarnings.length) return games;

  periodEarnings.forEach((period) => {
    const { earningsByGame = [] } = period;

    earningsByGame.forEach((game) => {
      if (!game.gameId) {
        game.gameId = alphaTrim(`${game.gameName}-${game.platformId}`);
      }

      const { gameId, gameName, totalGameEarnings = 0, platform, platformId } = game;

      // If the same game is found in another period, we just want to
      // add its earnings that period to the ongoing total
      if (games.map[gameId]) {
        games.map[gameId].totalGameEarnings += totalGameEarnings;
        return;
      }

      const gamesAmount = games.order.length;

      // All games are initially selected
      game.selected = true;
      game.name = gameName;
      game.index = gamesAmount;
      game.totalGameEarnings = totalGameEarnings;
      game.color = colors[gamesAmount] || null;
      game.other = false;

      if (platform) {
        game.platform = platform === "ANDROID" ? "Google play" : "App store";
      }
      if (platformId) {
        game.platform = platformId;
      }

      games.order.push(game);
      games.map[gameId] = game;
    });
  });

  if (sortByEarnings) {
    // We dont want to sort by earnings everytime we get new earnings data
    // we only want to do it the first time when there is no existing state
    games.order = [];
    Object.keys(games.map).forEach((gameId) => {
      games.order.push(games.map[gameId]);
    });
    // Sort the games by their total earnings
    objSort(games.order, "totalGameEarnings", {
      descending: true,
    });
    games.order.forEach((game, index) => {
      game.index = index;
      game.color = colors[index] || null;
      game.other = false;
    });
  }

  // Create the "Other" section if the amount of games exceeds the limit
  if (games.order.length > OTHER_GAMES_LIMIT) {
    games.other.enabled = true;
    games.other.selected = true;
    games.order.forEach((game, index) => {
      if (index < OTHER_GAMES_LIMIT - 1) {
        game.other = false;
      } else {
        game.other = true;
      }
    });
  } else {
    games.other.enabled = false;
    games.other.selected = false;
  }

  return games;
};

export const getDayGameData = (selectedOptionGameData, dayData) => {
  const { games: dayGames } = dayData;
  if (!dayGames.order.length) return [];

  const dayGamesDiplayList = [];
  if (selectedOptionGameData.other.selected) {
    const otherGamesData = {
      gameId: "Other",
      name: "Other",
      earnings: 0,
    };
    selectedOptionGameData.order.forEach((game) => {
      const dayGameData = dayGames.byId[game.gameId];
      if (!game.other || !dayGameData) return;
      otherGamesData.earnings += dayGameData.earning;
    });
    dayGamesDiplayList.push(otherGamesData);
  }

  selectedOptionGameData.order.forEach((game) => {
    const dayGameData = dayGames.byId[game.gameId];
    if (!dayGameData || !game.selected || game.other) return;
    dayGamesDiplayList.push({
      ...game,
      earnings: dayGameData.earning,
    });
  });

  return dayGamesDiplayList.reverse();
};

export const areGamesSelected = (selectedOptionGameData) => {
  const { order, other } = selectedOptionGameData;

  if (!order.length) return true;
  // Quickly check the other section since it's readily
  // available on the object;
  if (other.enabled && other.selected) return true;

  for (let i = 0; i < order.length; ++i) {
    // Any of the "other" games are ignore here as the
    // "other" section itself is checked above
    if (order[i].selected && !order[i].other) return true;
  }

  return false;
};

export const swapGames = (selectedOptionGameData, movingGame, targetGame) => {
  const selectedOptionGameDataCopy = copy(selectedOptionGameData);

  // Ignore the temp object being moved around and just return the original list
  if (!targetGame || !Object.keys(targetGame).length) return selectedOptionGameDataCopy;
  if (movingGame.other && targetGame.gameId === "other") return selectedOptionGameDataCopy;
  if (movingGame.temp) return selectedOptionGameDataCopy;
  if (movingGame.other && targetGame.other) return selectedOptionGameDataCopy;

  // make copies of the game being moved and game in the target
  // position for easy referencing
  const movingGameCopy = copy(movingGame);
  const targetGameCopy = copy(targetGame);

  if (!movingGame.other && targetGame.gameId === "other") {
    // Check for moving from top to other (over other header and not a game)
    selectedOptionGameDataCopy.order[movingGameCopy.index] = {
      name: `temp ${movingGameCopy.index}`,
      gameId: `temp ${movingGameCopy.index}`,
      temp: true,
      color: movingGameCopy.color,
      index: movingGameCopy.index,
    };
    movingGameCopy.color = null;
    movingGameCopy.other = true;
    movingGameCopy.selected = true;
    selectedOptionGameDataCopy.order.splice(OTHER_GAMES_LIMIT, 0, movingGameCopy);
  } else if (!movingGame.other && targetGame.other) {
    // Check for moving from top to other (over an actual game)
    selectedOptionGameDataCopy.order[movingGameCopy.index] = {
      name: `temp ${movingGameCopy.index}`,
      gameId: `temp ${movingGameCopy.index}`,
      temp: true,
      color: movingGameCopy.color,
      index: movingGameCopy.index,
    };
    movingGameCopy.color = null;
    movingGameCopy.other = true;
    movingGameCopy.selected = true;
    selectedOptionGameDataCopy.order.splice(targetGame.index + 1, 0, movingGameCopy);
  } else if (movingGameCopy.other && !targetGameCopy.other) {
    // Moving from other to the top section
    movingGameCopy.color = targetGame.color;
    movingGameCopy.other = targetGame.other;
    targetGameCopy.color = movingGame.color;
    targetGameCopy.other = movingGame.other;
    if (targetGameCopy.temp) {
      selectedOptionGameDataCopy.order[targetGame.index] = movingGameCopy;
      selectedOptionGameDataCopy.order.splice(movingGame.index, 1);
    } else {
      selectedOptionGameDataCopy.order[targetGame.index] = movingGameCopy;
      selectedOptionGameDataCopy.order[movingGame.index] = targetGameCopy;
    }
  } else if (!movingGameCopy.other && !targetGameCopy.other) {
    // Top to top (changes colors of games))
    movingGameCopy.color = targetGame.color;
    targetGameCopy.color = movingGame.color;
    selectedOptionGameDataCopy.order[targetGame.index] = movingGameCopy;
    selectedOptionGameDataCopy.order[movingGame.index] = targetGameCopy;
  }

  selectedOptionGameDataCopy.order.forEach((newGame, index) => {
    newGame.index = index;
    selectedOptionGameDataCopy.map[newGame.gameId] = newGame;
  });

  return selectedOptionGameDataCopy;
};

export const getInitialOption = () => {
  return copy(OPTIONS_SCHEMA.allOption);
};

export const getInitialPeriod = (earnings = []) => {
  let chartStart = earnings[1] || earnings[0];
  earnings.forEach((period) => {
    const { fiscalStartDate, fiscalToDate } = period;

    const isCurrentPeriod = moment().startOf("day").isBetween(fiscalStartDate, fiscalToDate, "days", "[]");
    if (isCurrentPeriod) chartStart = period;
  });
  return chartStart;
};

export const getInitialVisualRange = (period, DAYS_VISIBLE = 35) => {
  if (!period) return {};
  return {
    start: period.fiscalStartDate,
    end: moment(period.fiscalStartDate).add(DAYS_VISIBLE, "days").format("YYYY-MM-DD"),
  };
};

export const getViewedPeriodData = (newVisibleDays, selectedOptionGameData, chartData = {}) => {
  // The highest earning day (visible on the chart, potentially across multiple period)
  // is needed to know the bounds for the Y-axis
  let highestEarning = null;
  // Used to determine the period that has the most days shown on screen
  let periodCounts = {};
  let currMaxPeriod = null;
  let currMaxPeriodDays = null;

  // Get the period that is most visible on screen
  // and also the highest earning of all visible days
  // highest earning can span periods
  for (let i = 0; i < newVisibleDays.length; ++i) {
    const day = newVisibleDays[i];
    const { period } = day;
    let dayEarnings = 0;

    for (let j = 0; j < day.games.order.length; ++j) {
      const game = day.games.byId[day.games.order[j]];

      if (!game || !selectedOptionGameData.map[game.gameId]?.selected) {
        continue;
      }

      if (selectedOptionGameData.map[game.gameId].other && !selectedOptionGameData.other.selected) {
        continue;
      }

      dayEarnings += game.earning;
    }

    if (dayEarnings > highestEarning) {
      highestEarning = dayEarnings;
    }

    if (!periodCounts[period.id]) {
      periodCounts[period.id] = 1;
      if (!currMaxPeriod) {
        currMaxPeriod = period;
        currMaxPeriodDays = 1;
      }
    } else {
      periodCounts[period.id] += 1;
      if (periodCounts[period.id] > currMaxPeriodDays) {
        currMaxPeriod = period;
        currMaxPeriodDays = periodCounts[period.id];
      }
    }
  }

  return {
    highestEarning: highestEarning || 0,
    visibleGameEarnings: getSelectedGameEarnings(currMaxPeriod, selectedOptionGameData, chartData),
    period: currMaxPeriod,
  };
};

export const getSelectedGameEarnings = (period, selectedOptionGameData, chartData) => {
  if (!period) return null;
  // Now we need to get the total earnings of the games that
  // are selected for the period that is most shown
  const fullPeriodData = chartData.periods.map[period.id];
  if (!fullPeriodData || !Object.keys(fullPeriodData).length) return 0;
  if (!fullPeriodData.earningsByGame || !fullPeriodData.earningsByGame.length) return 0;

  let visibleGameEarnings = 0;

  for (let i = 0; i < fullPeriodData.earningsByGame.length; ++i) {
    const game = fullPeriodData.earningsByGame[i];
    const gameData = selectedOptionGameData.map[game.gameId];
    if (!gameData || !gameData.selected) continue;
    if (gameData.other && !selectedOptionGameData.other.selected) continue;
    visibleGameEarnings += game.totalGameEarnings;
  }

  return parseFloat(visibleGameEarnings.toFixed(2), 10);
};

export const getYAxisData = (viewedPeriodData) => {
  // determine what size steps we want to use
  const highest = viewedPeriodData.highestEarning;

  let inc = 0;
  let breakpoints = [10000, 1000, 500, 200, 100, 20, 10];

  for (let i = 0; i < breakpoints.length; ++i) {
    if (highest > breakpoints[i]) {
      inc = breakpoints[i];
      break;
    }
  }

  // Lowest possible
  if (!inc) inc = 5;

  let nearestInc = Math.ceil(highest / inc) * inc || inc;
  let step = nearestInc / 5;

  return {
    highest: nearestInc,
    steps: [0, step, step * 2, step * 3, step * 4, step * 5],
  };
};

export const parseDataSourceStatus = (dataSourceStatus, bankConnected = false, noBankAccountVisible) => {
  if (noBankAccountVisible && !bankConnected && dataSourceStatus === "FINANCIAL")
    dataSourceStatus = "FINANCIAL_NO_BANK";
  return dataSourceStatus?.toLowerCase();
};

export const isNoDataState = (selectedOption, isNothingConnected) => {
  // The selected option is not connected, but something else is at least
  const check1 = !isConnectedStatus(selectedOption) && !isNothingConnected;
  // The selected option has no live apps currently
  const check2 = isNoLiveAppsStatus(selectedOption);
  // The selected option is not the all platforms option, which has no real status
  const check3 = !selectedOption.isAll;
  // Only kill rendering if check1 or check2 passes and it is NOT the all option
  if ((check1 || check2) && check3) return true;
  return false;
};

export const filterOptions = (options, searchString) => {
  try {
    return options
      .filter((option) => {
        const { isVisible, name = "" } = option;
        if (!isVisible) return false;
        if (!name.toLowerCase().match(searchString)) return false;
        return true;
      })
      .map((option) => {
        if (option.id === "appsflyer") {
          option.displayName = "Track Ad Revenue";
        }
        if (option.id === "adjust") {
          option.displayName = `${symbols.eyes} Coming Soon`;
          option.disabled = true;
        }
        return option;
      });
  } catch (e) {
    return [];
  }
};
