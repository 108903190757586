import React from "react";
import styled from "styled-components";

import { BodySmall } from "@styled";

import { ReactComponent as UpArrow } from "@assets/img/icons/common/arrow-up.svg";
import { formatPercentage } from "@src/utils/numbers";

const StyledMonthlyChange = styled.span`
  color: ${({ theme }) => theme.textSubtext};
  margin-left: 12px;
`;

const StyledArrow = styled(({ isNegative, ...rest }) => <UpArrow {...rest} />)`
  ${({ isNegative }) =>
    isNegative &&
    `
    transform: rotateX(180deg);
  `};
`;

export const MonthlyBurn = ({ className = "", value = 0 }) => {
  // Disregard null or 0, we don't want to show anything for that
  if (!value) return null;
  // The percentage is always shown as positive, the arrow indicates
  // positve/negative, the original value is used for that comparison
  const burnRate = Math.abs(value / 100);

  return (
    <StyledMonthlyChange className={className}>
      <StyledArrow isNegative={value < 0} width={"16px"} height={"16px"} />
      <BodySmall>{formatPercentage(burnRate)}</BodySmall>
    </StyledMonthlyChange>
  );
};
