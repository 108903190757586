import HTTP from "@redux/http";

const SANLO_WEB_API_URL = process.env.REACT_APP_SANLO_WEB_API_URL;
const BASE = `${SANLO_WEB_API_URL}/api/fairground/console/webshops`;

class IntegrationsService {
  getIntegrations(webshopId) {
    return HTTP.req.get(`${BASE}/${webshopId}/settings/mmps`);
  }

  setIntegrations(integration, webshopId) {
    return HTTP.req.post(`${BASE}/${webshopId}/settings/mmps`, integration);
  }

  updateIntegrations({ integration, mmpsId, webshopId }) {
    return HTTP.req.put(`${BASE}/${webshopId}/settings/mmps/${mmpsId}`, integration);
  }

  deleteIntegrations(integrationId, webshopId) {
    return HTTP.req.delete(`${BASE}/${webshopId}/settings/mmps/${integrationId}`);
  }
}

export default new IntegrationsService();
