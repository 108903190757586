import React, { useState, useEffect, useRef } from "react";

import { BodySmallPlus } from "@styled";

import {
  StyledWrapper,
  StyledLabel,
  StyledTextareaWrapper,
  StyledTextarea,
  StyledTextareaButton,
  StyledError,
} from "./SanloTextarea.styled";

import { ReactComponent as ErrorIcon } from "@assets/img/icons/common/alertCircle.svg";
import { ReactComponent as LinkIcon } from "@assets/img/icons/common/link.svg";

export const SanloTextarea = ({
  type = "text",
  hasFocus = false,
  isJumbo = false,
  isDisabled = false,
  isRequired = false,
  isReadOnly = false,
  hasCopyButton = false,
  label = "",
  placeholder = "",
  value,
  setValue = () => {},
  errorMessage = "",
  hideErrorMessage = false, // Used to show the error message, some inputs may still want to be in an error state
  pattern,
  testId = "",
  onEnter = () => {},
  onFocus = () => {},
  style = {},
}) => {
  const textareaRef = useRef();

  const [textareaValue, setTextareaValue] = useState(value || "");

  useEffect(() => {
    if (textareaRef?.current && hasFocus) {
      textareaRef.current.focus();
    }
  }, [hasFocus]);

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      onEnter(textareaValue);
    }
  };

  const handleChange = (e) => {
    setValue(e.target.value);
    setTextareaValue(e.target.value);
  };

  const handleCopy = () => {
    if (textareaValue) {
      navigator.clipboard.writeText(textareaValue);
      showCopyToast();
    }
  };

  const showCopyToast = () => {
    let toastBg = document.getElementById("toast-bg-fade");
    let toast = document.getElementById("bottom-toast");
    toastBg.className = "show";
    toast.className = "show";
    setTimeout(function () {
      toastBg.className = toastBg.className.replace("show", "");
    }, 3000);
    setTimeout(function () {
      toast.className = toast.className.replace("show", "");
    }, 3000);
  };

  return (
    <StyledWrapper style={style}>
      {label && <StyledLabel>{label}</StyledLabel>}

      <StyledTextareaWrapper>
        <StyledTextarea
          isJumbo={isJumbo}
          hasError={Boolean(errorMessage)}
          onKeyPress={onKeyPress}
          ref={textareaRef}
          disabled={isDisabled}
          readOnly={isReadOnly}
          onChange={handleChange}
          onFocus={onFocus}
          type={type}
          required={isRequired}
          pattern={pattern}
          placeholder={placeholder}
          value={textareaValue}
          data-test-id={testId}
        />

        {hasCopyButton && (
          <StyledTextareaButton isJumbo={isJumbo} onClick={handleCopy}>
            <LinkIcon />
            <BodySmallPlus>Copy</BodySmallPlus>
          </StyledTextareaButton>
        )}
      </StyledTextareaWrapper>

      {!hideErrorMessage && errorMessage && (
        <StyledError className="sanlo-textarea-error">
          <ErrorIcon />
          {errorMessage}
        </StyledError>
      )}
    </StyledWrapper>
  );
};
