import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import LogRocket from "logrocket";
import { Provider } from "react-redux";
import { store } from "./store";
import mixpanel from "mixpanel-browser";
import { shouldSendToSentry, adjustEventSeverity } from "@utils/sentryError";

// ### Plugins styles from node_modules ###
import "react-toastify/dist/ReactToastify.css";

import "@styles/index.css";
import "@styles/fonts.css";
import "@styles/animate.css";

import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import * as Sentry from "@sentry/react";

if (process.env.REACT_APP_MIXPANEL) {
  mixpanel.init(process.env.REACT_APP_MIXPANEL, {
    track_pageview: true,
    persistence: "localStorage",
  });
}

if (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV !== "local") {
  Sentry.init({
    ignoreErrors: [
      // Random plugins/extensions
      "top.GLOBALS",
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      "originalCreateNotification",
      "canvas.contentDocument",
      "MyApp_RemoveAllHighlights",
      "http://tt.epicplay.com",
      "Can't find variable: ZiteReader",
      "jigsaw is not defined",
      "ComboSearch is not defined",
      "http://loading.retry.widdit.com/",
      "atomicFindClose",
      // crypto extension
      "Cannot redefine property: ethereum",
      // Facebook borked
      "fb_xd_fragment",
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      "bmi_SafeAddOnload",
      "EBCallBackMessageReceived",
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      "conduitPage",
    ],
    denyUrls: [
      // LD events on beforeunload
      /events\.launchdarkly\.com\/events\/bulk/i,
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
    dsn: process.env.REACT_APP_SENTRY_URL,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    environment: process.env.REACT_APP_ENV,
    release: `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    beforeSend(event) {
      if (!shouldSendToSentry(event)) {
        return null;
      }
      const adjustedEvent = adjustEventSeverity(event);

      const logRocketSession = LogRocket.sessionURL;
      if (adjustedEvent && adjustedEvent.extra && logRocketSession !== null) {
        adjustedEvent.extra["LogRocketSessionOnError"] = logRocketSession;
        return adjustedEvent;
      } else {
        return adjustedEvent;
      }
    },
  });
}

window.simulateMouseOver = (elementID) => {
  const item = document.getElementById(elementID);
  const event = new MouseEvent("mouseover", {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  item.dispatchEvent(event);
};

if (process.env.REACT_APP_LOGROCKET_ID && process.env.REACT_APP_ENV !== "local") {
  LogRocket.init(process.env.REACT_APP_LOGROCKET_ID, {
    sendEvents: false,
  });
  LogRocket.getSessionURL((sessionURL) => {
    Sentry.configureScope((scope) => {
      scope.setExtra("LogRocketSessionInit", sessionURL);
    });
  });
}

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID,
  });

  const domNode = document.getElementById("root");
  const root = createRoot(domNode);

  root.render(
    <LDProvider>
      {/* <React.StrictMode> */}
      <Provider store={store}>
        <App />
      </Provider>
      {/* </React.StrictMode> */}
    </LDProvider>,
  );
})();
