import React, { useEffect, useState } from "react";
import styled from "styled-components";
import * as Sentry from "@sentry/react";
import { trackGTMEvent } from "@utils/gtm";
import { Box } from "@chakra-ui/react";

const StyledFallbackUIDefault = styled.div`
  background-color: #212126;
  border-radius: 12px;
  padding: 24px;
`;

const isValidFallback = (fallbackUI) => {
  if (React.isValidElement(fallbackUI)) return true;
  // null is valid element and can be passed to
  // not show anything for a fallback
  if (fallbackUI === null) return true;
  return false;
};

const FallbackComponent = () => {
  return <StyledFallbackUIDefault>An error has occured</StyledFallbackUIDefault>;
};

const ErrorBoundary = ({
  name,
  show = true,
  altUI = null,
  showAltUI = false,
  fallbackUI,
  children,
  track = false,
  trackHover = false,
}) => {
  const [hoverStartTime, setHoverStartTime] = useState(null);

  useEffect(() => {
    // Tracking is opt-in so it doesnt go crazy
    // The component must also be visible
    if (track && show) {
      // Use the unique name as the component name
      trackGTMEvent({
        "event": "Component Boundary",
        "Boundary Name": name,
      });
    }
    // eslint-disable-next-line
  }, []);

  // if nothing is passed in for fallback UI, set it to null so
  // nothing shows if there is an error. Otherwise use the fallbackUI
  // passed if valid or fallback to the generic component
  const fallbackBool = Boolean(fallbackUI);
  if (!fallbackBool) fallbackUI = null;
  fallbackUI = isValidFallback(fallbackUI) ? fallbackUI : FallbackComponent;

  const renderChildren = () => {
    if (showAltUI && altUI) return altUI;
    if (!show || !children) return <></>;

    // trackHover requires a wrapper around the child elements to put the event listeners on
    // This may not work for every component, but is available for any component that can use it.
    if (trackHover) {
      return (
        <Box
          data-boundary-name={name}
          data-test-id={`${name}-boundary`}
          onMouseEnter={() => {
            setHoverStartTime(Date.now());
          }}
          onMouseLeave={() => {
            const trackingPayload = {
              "event": "Component Hover",
              "Boundary Name": name,
              "Component Name": name,
            };
            if (hoverStartTime) {
              const hoverDurationMS = Date.now() - hoverStartTime;
              const hoverDurationS = hoverDurationMS / 1000;
              trackingPayload["Hover Duration"] = hoverDurationS;
            }
            // I think this is the only place we want to use trackGTMEvent
            // and we won't lose anything meaningful if we lose some hover events
            // to unmounts or page unloads.
            trackGTMEvent(trackingPayload);
          }}
        >
          {children}
        </Box>
      );
    }

    return children;
  };

  return (
    <Sentry.ErrorBoundary key={name} fallback={fallbackUI}>
      {renderChildren()}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
