import { configureConsts, configureActions } from "@utils/redux";

export const GET_CAPITAL_OFFERS = configureConsts("GET_CAPITAL_OFFERS");
export const ACCEPT_OFFER = configureConsts("ACCEPT_OFFER");
export const DISMISS_CAPITAL_OFFER = configureConsts("DISMISS_CAPITAL_OFFER");

const offerActions = {
  getCapitalOffers: configureActions(GET_CAPITAL_OFFERS),
  acceptCapitalOffer: configureActions(ACCEPT_OFFER, {
    request: (data) => ({
      type: ACCEPT_OFFER.REQUEST,
      data,
    }),
  }),
  dismissCapitalOffer: configureActions(DISMISS_CAPITAL_OFFER, {
    request: (data) => ({
      type: DISMISS_CAPITAL_OFFER.REQUEST,
      data,
    }),
  }),
};

export default offerActions;
