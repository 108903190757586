import styled from "styled-components";

const StyledOnboardingStep2 = styled.div`
  &.animated-container {
    animation: fadeIn 1s ease;
  }

  .os-revenue-picker {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
  }
`;

export default StyledOnboardingStep2;
