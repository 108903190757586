import classnames from "classnames";
import React, { useState } from "react";
import { BodySmallPlus, TitleLargePlus, TitleSmallPlus } from "@styled";
import StyledOfferType from "./OfferType.styled";
import { BodySmall14 } from "@styled";
import { ReactComponent as ToolTipIcon } from "@assets/img/icons/common/tooltip.svg";
import { ReactComponent as QuestionIcon } from "@assets/img/icons/common/question-circle.svg";
import { ReactComponent as CrossIcon } from "@assets/img/icons/common/cross-circle.svg";
import { BodySmallAlt } from "../styled";
import { SanloTooltip } from "@common/tooltips/sanlo-tooltip/SanloTooltip";
import { trackGTMEvent } from "@utils/gtm";
import text from "@utils/text";

const OfferType = ({
  type,
  offerType,
  setOfferType,
  header,
  amount,
  fee,
  repayment,
  tooltipText,
  showBlur,
  blurContent,
}) => {
  const [flipped, setFlipped] = useState(false);
  return (
    <StyledOfferType
      className={classnames({ "is-selected": offerType === type && !showBlur, "flipped": flipped })}
      onClick={() => {
        if (flipped || showBlur) return;
        setOfferType(type);
        trackGTMEvent({
          event: "Select Capital Offer",
          Type: type,
        });
      }}
      type={type}
      showBlur={showBlur}
    >
      <div className="content">
        {!flipped && (
          <div className={classnames("front", { blur: showBlur })}>
            <div className={"offer-type-header"}>
              <div className={classnames("offer-type-header-left", { "is-selected": offerType === type })}>
                <input
                  type="radio"
                  name="offerType"
                  checked={offerType === type}
                  onChange={() => {
                    if (flipped || showBlur) return;
                    setOfferType(type);
                  }}
                />
                <BodySmallPlus style={offerType === type ? { color: "#FF5C79" } : { color: "#C5C5C5" }}>
                  {header}
                </BodySmallPlus>
              </div>
              <QuestionIcon
                onClick={(e) => {
                  e.stopPropagation();
                  if (flipped) return;
                  setFlipped(true);
                }}
                className={classnames("offer-type-header-tooltip", `tag-sanlo-why-${type}-term-base`)}
              />
            </div>
            <div className="offer-type-details">
              <div className="offer-type-details-title">
                <TitleLargePlus>{amount}</TitleLargePlus>
                <ToolTipIcon id={`${type}-offer-type-details-tooltip`} className="offer-type-details-tooltip" />
              </div>
              <BodySmall14 className="offer-type-details-subtitle">{fee}</BodySmall14>
              <BodySmall14 className="offer-type-details-subtitle">{repayment}</BodySmall14>
            </div>
            <SanloTooltip
              maxWidth={450}
              target={`${type}-offer-type-details-tooltip`}
              text={tooltipText}
              placement="top"
            />
          </div>
        )}

        {/* flipped side */}
        {flipped && (
          <div className="back">
            <div className={"offer-type-header"}>
              <div className={classnames("offer-type-header-left", { "is-selected": offerType === type })}>
                <TitleSmallPlus style={type === "revenue" ? { color: "#BF6BFF" } : { color: "#8280FF" }}>
                  {type === "revenue" ? "Why revenue-based?" : "Why fixed term-based?"}
                </TitleSmallPlus>
              </div>
              <CrossIcon
                onClick={(e) => {
                  e.stopPropagation();
                  setFlipped(false);
                }}
                className="offer-type-header-tooltip"
              />
            </div>
            <div className="offer-type-details">
              <BodySmallAlt className="offer-type-details-subtitle">
                {type === "revenue" ? text.t("revenue_offer_details") : text.t("fixed_offer_details")}
              </BodySmallAlt>
            </div>
          </div>
        )}
        {showBlur && blurContent && <div className="blurry-bg" />}
      </div>
      {showBlur && blurContent && <div className="blurry-content">{blurContent}</div>}
    </StyledOfferType>
  );
};

export default OfferType;
