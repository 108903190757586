import React from "react";

import AuthenticationContainer from "@pages/authentication/AuthenticationContainer";
import {
  SanloModal,
  SanloModalTitle,
  SanloModalDescription,
  SanloModalDivider,
  SanloModalFooter,
  Highlight,
} from "@components/common/modals/SanloModal/SanloModal";
import Support from "@components/support/Support";
import { SanloButton } from "@inputs";

import symbols from "@utils/symbols";
import { useLogout } from "@utils/custom-hooks/useAuth";

export const EmailAlreadyVerifiedError = ({ email }) => {
  const appLogout = useLogout();

  return (
    <AuthenticationContainer error>
      <SanloModal>
        <SanloModalTitle>{symbols.check} Email Verified</SanloModalTitle>
        <SanloModalDescription>
          You've already verified this email <Highlight>{email}</Highlight>. To access your account, please{" "}
          <SanloButton
            isText
            label="login"
            onClick={() => {
              appLogout();
            }}
          />{" "}
          with your credentials.
        </SanloModalDescription>
        <SanloModalDivider />
        <SanloModalFooter>
          Have any questions?&nbsp;
          <Support red />
        </SanloModalFooter>
      </SanloModal>
    </AuthenticationContainer>
  );
};
