import { currencyFormatCentsData } from "@utils/currency";

export const formatAmountDisplay = (amountInCents) => {
  const amount = currencyFormatCentsData(amountInCents, { notation: "standard" });
  const prefix = Math.sign(amountInCents) === -1 ? "" : "+";
  const color = Math.sign(amountInCents) === -1 ? "brandPurple.400" : "brandGreen.400";

  return { amount, prefix, color };
};

export const isValidAmount = (value) => {
  if (typeof value !== "number") return false;
  if (isNaN(value)) return false;
  if (!value || value === 0) return false;
  return true;
};

export const getCurrencyFormat = (amountCents) => {
  return currencyFormatCentsData(Math.abs(amountCents), { notation: "standard" }).formattedAmount;
};

export const parseActivities = ({ item = {}, type = "" }) => {
  let total = 0;
  const isEstimated = item.isCurrent || item.isPredicted;
  const activitiesParse = item[type].activities.flatMap((activity) => {
    const {
      id,
      defaultImage,
      icon,
      name,
      amountCents,
      predictedAmountCents,
      source,
      period: { fiscalPeriod, estimatedDate },
    } = activity;
    const image = { defaultImage, icon };
    const activityName = name || (id || "missing").toUpperCase();

    isEstimated ? (total += predictedAmountCents) : (total += amountCents);

    let newActivities = [];
    // Check the actual amount
    if (isValidAmount(amountCents)) {
      newActivities.push({
        id: `${type}-${id}-actual`,
        image,
        name: activityName,
        amount: getCurrencyFormat(amountCents),
      });
    }
    // Check the predicted amount
    if (isValidAmount(predictedAmountCents)) {
      newActivities.push({
        id: `${type}-${id}-predicted`,
        image,
        name: activityName,
        amount: `Est. ${getCurrencyFormat(predictedAmountCents)}`,
        source,
        fiscalPeriod,
        estimatedDate,
      });
    }
    return newActivities;
  });

  total = total === 0 ? 0 : `${isEstimated ? "Est. " : ""}Total: ${getCurrencyFormat(total)}`;

  return [total, activitiesParse];
};
