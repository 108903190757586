import React, { useEffect, useRef, useState } from "react";

import { BodySmall14 } from "@styled";

import { StyledPieChart } from "./PieChart.styled";

const PI = Math.PI;
const TWO_PI = PI * 2;
const HALF_PI = PI / 2;

const PieChart = ({ className, data }) => {
  const { chunks, total } = data;

  const percentages = {};
  Object.keys(chunks).forEach((k) => (percentages[k] = chunks[k].value / total.value));

  const pieChartThickness = 20;

  let mouse = {};

  const [hoveredItem, setHoveredItem] = useState(null);
  const [canvasDimensions, setCanvasDimensions] = useState({
    width: null,
    height: null,
    bound: null,
  });

  const containerRef = useRef(null);
  const chartRef = useRef(null);

  useEffect(() => {
    drawPieChart();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    drawPieChart();
    // eslint-disable-next-line
  }, [hoveredItem]);

  const drawPieChart = () => {
    const canvas = chartRef.current;
    if (!canvas) return null;

    const width = canvas.clientWidth;
    const height = canvas.clientHeight;
    canvas.width = width;
    canvas.height = height;
    const bound = width > height ? height : width;

    setCanvasDimensions({
      width,
      height,
      bound,
    });

    // Clear out the canvas for drawing
    const c = canvas.getContext("2d");
    c.clearRect(0, 0, width, height);

    c.save();
    // Translate the drawing origin to the center of the canvas
    // Makes it easier to draw the arcs/slices
    c.globalCompositeOperation = "source-over";
    c.translate(width / 2, height / 2);

    // We want the first item to start at 12 o'clock so we need our starting
    // angle to be offset to negative half pi.
    let start = 0 - HALF_PI;
    let end = 0;

    // Draw the colored pie chart chunks
    const order = Object.keys(percentages).reverse();
    order.forEach((item) => {
      end = start + TWO_PI * percentages[item];
      c.lineWidth = pieChartThickness;
      c.strokeStyle = chunks[item].color;
      c.beginPath();
      // Adjust the opacity of the pie chart chunks to darken color
      // before drawing on hover
      if (Boolean(hoveredItem) && hoveredItem.type === item) {
        c.globalAlpha = 0.5;
      } else {
        c.globalAlpha = 1;
      }
      c.arc(0, 0, bound / 2 - pieChartThickness / 2, start, end);
      c.stroke();
      start = end;
    });
    c.restore();
  };

  const checkForPieChartHover = (e) => {
    let newHoveredItem = null;
    mouse = getMousePos(e);

    // Distance from center of pie chart to the mouse on the canvas
    const distance = Math.sqrt(mouse.x * mouse.x + mouse.y * mouse.y);
    const arcCenter = canvasDimensions.bound / 2 - pieChartThickness / 2;
    const innerRadius = arcCenter - pieChartThickness / 2;
    const outerRadius = arcCenter + pieChartThickness / 2;

    if (distance < innerRadius) {
      newHoveredItem = "total";
    } else if (distance >= innerRadius && distance <= outerRadius) {
      // We are hovering over the pie chart now and just need to determine the slice
      let angle = Math.atan2(mouse.y, mouse.x);
      // 12 o'clock on a unit circle is negative half pi, but to our pie chart items
      // its 0, so we need to adjust the mouse angle to match the same bounds
      if (angle < -(Math.PI / 2)) angle = Math.PI + (Math.PI + angle);

      let start = 0 - HALF_PI;
      let end = 0;
      Object.keys(percentages)
        .reverse()
        .forEach((item) => {
          end = start + Math.PI * 2 * percentages[item];
          if (angle >= start && angle <= end) newHoveredItem = item;
          start = end;
        });
    }

    const node = containerRef.current;
    const nodeRect = node.getBoundingClientRect();
    let pos = {
      x: e.clientX - nodeRect.left,
      y: e.clientY - nodeRect.top,
    };

    // We're hovering over the currently set item, we can return early
    if (hoveredItem && hoveredItem.type === newHoveredItem) return;

    setHoveredItem(
      newHoveredItem
        ? {
            type: newHoveredItem,
            pos: pos,
          }
        : null,
    );
  };

  const getMousePos = (e) => {
    const canvas = chartRef.current;
    const width = canvas.clientWidth;
    const height = canvas.clientHeight;
    const rect = canvas.getBoundingClientRect();

    return {
      x: e.clientX - rect.left - width / 2,
      y: e.clientY - rect.top - height / 2,
      original: {
        x: e.clientX - rect.left,
        y: e.clientY - rect.top,
      },
    };
  };

  const onPieChartMouseMove = (e) => {
    checkForPieChartHover(e);
  };

  const renderPieChart = () => {
    return (
      <div className="pie_chart_wrapper">
        <canvas ref={chartRef} className="pie_chart" onMouseMove={onPieChartMouseMove} />
        <BodySmall14 className="pie_chart_percentage">{total.display}</BodySmall14>
      </div>
    );
  };

  return (
    <StyledPieChart className={className} ref={containerRef}>
      {(() => {
        if (!hoveredItem) return null;
        const { type, pos } = hoveredItem;
        let text = "";

        const style = {
          left: pos.x + "px",
          top: pos.y + "px",
        };

        if (type === "total") {
          text = "Total Growth Capital received including one-time fees";
        } else {
          const data = chunks[type];
          style.color = data.color;
          text = data.display;
        }

        return (
          <BodySmall14
            style={style}
            className={`piechart-tooltip ${type}`}
            onMouseLeave={(e) => {
              checkForPieChartHover(e);
            }}
          >
            {text}
          </BodySmall14>
        );
      })()}

      {renderPieChart()}
    </StyledPieChart>
  );
};

export default PieChart;
