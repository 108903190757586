import { Button, HStack, Heading, Image, Text, VStack } from "@chakra-ui/react";

import { useMultiStepModal } from "@common/multi-step-modal/MultiStepModalContext";
import { useSelector } from "react-redux";
import { listAppStoreAppsSelector } from "@redux/webshops/webshopsSlice";
import { Controller, useFormContext } from "react-hook-form";
import defaultAppIcon from "@assets/img/webshops/default-app-icon.png";
import { useNavigate } from "react-router-dom";

export function AppStoreAppSelection({ handleNextStep }) {
  const { apps } = useSelector(listAppStoreAppsSelector);
  const navigate = useNavigate();
  const { watch, control } = useFormContext();
  const { onFormSubmit } = useMultiStepModal();

  function handleLastStep() {
    onFormSubmit((data) => {
      const searchParams = new URLSearchParams();
      searchParams.set("code", data.name);
      const path = `webshops/projects/new?${searchParams.toString()}`;
      navigate(path, { state: { ...data } });
      handleNextStep();
    });
  }
  const appId = watch("appId");

  return (
    <>
      <VStack mt="40px">
        <Heading size="large" fontWeight={600}>
          Pick an app for us to reference
        </Heading>
        {apps.length && (
          <HStack gap="24px" mt="104px">
            <Controller
              name="appId"
              control={control}
              render={({ field: { onChange } }) =>
                apps.map((app) => (
                  <AppContainer
                    key={app.externalId}
                    {...app}
                    onClick={() => {
                      onChange(app.externalId);
                    }}
                    isSelected={appId === app.externalId}
                  />
                ))
              }
            />
          </HStack>
        )}
        <Button isDisabled={!appId} onClick={handleLastStep} variant={"primary"} mt="24px" w="424px">
          Continue
        </Button>
      </VStack>
    </>
  );
}

function AppContainer({ externalId, name, iconUrl, onClick, isSelected }) {
  return (
    <VStack
      flexGrow={0}
      w={"200px"}
      cursor={"pointer"}
      tabIndex={0}
      bg="dark.600"
      outline={isSelected ? "2px solid transparent" : "1px solid transparent"}
      outlineColor={isSelected ? "brandRed.400" : "dark.100"}
      pt="32px"
      px={"24px"}
      pb="24px"
      gap="24px"
      margin={"0"}
      borderRadius={"10px"}
      onClick={onClick}
      _hover={{ outlineColor: "brandRed.400", outlineWidth: "2px" }}
      _focusWithin={{ outlineColor: "brandRed.400", outlineWidth: "2px" }}
      _active={{ outlineColor: "brandRed.400", outlineWidth: "2px" }}
    >
      <Image alt="" src={iconUrl} fallbackSrc={defaultAppIcon} w={"88px"} h={"88px"} borderRadius={"4px"} mx={"auto"} />
      <Text
        noOfLines={1}
        userSelect={"none"}
        size={"regular"}
        textAlign={"center"}
        fontWeight={500}
        color="textWhite.400"
      >
        {name}
      </Text>
    </VStack>
  );
}
