import styled from "styled-components";

const StyledPartnerLandingPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 448px;

  .partner-landing-card {
    border-radius: 12px;
    padding: 34px 32px;
    width: fill-available;

    &.animated {
      animation: pushInFromBottom 0.5s ease;
      animation-fill-mode: both;
    }

    .steps-container {
      margin-top: 1.75rem;

      .step {
        display: flex;
        flex-direction: row;
        align-items: center;

        .step-marker {
          width: 1.5rem;
          height: 1.5rem;
          border-radius: 1.25rem;
          fill: ${({ theme }) => theme.colorGreen};

          path {
            fill: ${({ theme }) => theme.colorGreen};
            color: white;
          }
        }
      }

      .step-spacer {
        position: relative;
        height: 1rem;
        width: 1.25rem;
        margin: 0.3rem 0;

        &::before {
          content: "";
          position: absolute;
          left: 50%;
          top: 0;
          bottom: 0;
          width: 1px;
          background: ${({ theme }) => theme.colorGreen};
          opacity: 0.5;
        }
      }

      .step-item-1 {
        opacity: 0;
        animation: pushInFromBottom25 0.25s ease 0.5s forwards;
      }
      .step-item-2 {
        opacity: 0;
        animation: pushInFromBottom25 0.25s ease 0.75s forwards;
      }
      .step-item-3 {
        opacity: 0;
        animation: pushInFromBottom25 0.25s ease 1s forwards;
      }
      .step-item-4 {
        opacity: 0;
        animation: pushInFromBottom25 0.25s ease 1.25s forwards;
      }
      .step-item-5 {
        opacity: 0;
        animation: pushInFromBottom25 0.25s ease 1.5s forwards;
      }
    }

    .partner-landing-card-headline {
      margin-bottom: 0.5rem;
      display: block;
    }

    .ob-card-header {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      .ob-card-icon {
        margin-right: 16px;
      }

      .ob-card-title {
        color: ${({ theme }) => theme.textSeashellWhite};
      }
    }

    .ob-card-description {
      color: ${({ theme }) => theme.textGray};
    }

    .get-started-button {
      font-family: Inter, "Helvetica", sans-serif;
      background-image: linear-gradient(84.98deg, #ff5c79 0%, #bb60f7 100%);
      height: 64px;
      margin: 1.75rem 0 1.5rem 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 12px;
      cursor: pointer;
      position: relative;
      z-index: 1;
      opacity: 0;
      animation: pushInFromBottom50 0.5s ease 3s forwards;

      &.get-started-button::before {
        border-radius: 12px;
        position: absolute;
        content: "";
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-image: linear-gradient(84.98deg, #ff5c79 0%, #ff5c79 100%);
        z-index: -1;
        transition: opacity 0.3s linear;
        opacity: 0;
      }

      &.get-started-button:hover::before {
        opacity: 1;
      }

      .button-label {
        font-weight: 500;
        color: ${({ theme }) => theme.grayscale0};
      }
    }

    .divider {
      margin-bottom: 1rem;
      border-bottom: 1px solid ${({ theme }) => theme.grayscale40};
      opacity: 0;
      animation: pushInFromBottom50 0.5s ease 3s forwards;
    }

    .analytics-message {
      color: ${({ theme }) => theme.textGray};
      display: flex;
      align-items: center;
      opacity: 0;
      animation: pushInFromBottom50 0.5s ease 3s forwards;

      .label-button {
        margin-left: 4px;
        color: ${({ theme }) => theme.colorRed};
        cursor: pointer;
      }
    }
  }

  .platform-features-container {
    margin-left: 16px;
    width: 460px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .partner-landing-feature-card {
      margin-bottom: 16px;
      width: 460px;
      background: rgba(37, 37, 42, 1);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export default StyledPartnerLandingPage;
