import { useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { debounce } from "lodash";

import { sectionsThunks, updateSection } from "@redux/webshops/builder/sectionsSlice";

import { generateThemePayloads, generateThemeResetPayloads } from "@utils/webshop/section";
import { DEFAULT_DEBOUNCE_TIME } from "@utils/consts";

export const useSectionTheme = (webshopId, sectionId, data) => {
  const dispatch = useDispatch();

  // eslint-disable-next-line
  const debouncedThemeUpdate = useCallback(
    debounce((data) => {
      dispatch(sectionsThunks.createSectionThemeOverride(data));
    }, DEFAULT_DEBOUNCE_TIME),
    [dispatch],
  );

  const onThemeUpdate = (themeObj) => {
    const payloads = generateThemePayloads({
      data,
      themeObj,
    });
    debouncedThemeUpdate({
      data: payloads.request,
      webshopId: webshopId,
      sectionId: sectionId,
    });
    dispatch(updateSection(payloads.response));
  };

  const onThemeReset = () => {
    const payloads = generateThemeResetPayloads({
      data,
    });
    dispatch(
      sectionsThunks.deleteSectionThemeOverride({
        webshopId: webshopId,
        sectionId: data.id,
      }),
    );
    dispatch(updateSection(payloads.response));
  };

  useEffect(() => {
    return () => {
      debouncedThemeUpdate.flush();
    };
    // eslint-disable-next-line
  }, []);

  return [onThemeUpdate, onThemeReset];
};
