import HTTP from "@redux/http";

const SANLO_WEB_API_URL = process.env.REACT_APP_SANLO_WEB_API_URL;
const BASE = `${SANLO_WEB_API_URL}/api/fairground/console/webshops`;

class CheckoutService {
  getCheckoutTemplate(webshopId) {
    return HTTP.req.get(`${BASE}/${webshopId}/checkout`);
  }
  addBrandingFile(webshopId, data) {
    return HTTP.req.post(`${BASE}/${webshopId}/checkout/file`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
  updateCheckoutTemplate(webshopId, data) {
    return HTTP.req.put(`${BASE}/${webshopId}/checkout`, data);
  }

  resetCheckoutTemplate(webshopId) {
    return HTTP.req.put(`${BASE}/${webshopId}/checkout/reset`);
  }
  deleteBrandingFile(webshopId) {
    return HTTP.req.delete(`${BASE}/${webshopId}/checkout/file`, {
      data: {
        type: "GLOBAL",
        target: "MAIN",
      },
    });
  }
}

export default new CheckoutService();
