import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Icon, SimpleGrid, Spinner, Text, VStack } from "@chakra-ui/react";
import { useFlags } from "launchdarkly-react-client-sdk";

import { PageHeader, StyledPageContainer } from "@components/page";
import { useMultiStepModal } from "@common/multi-step-modal/MultiStepModalContext";
import { ProjectsEmptyState } from "./components/projects-empty-state/ProjectsEmptyState";
import { Project } from "./components/project/Project";
import { ProjectName } from "./components/new-project/ProjectNameStep";
import { StoreSelection } from "./components/new-project/StoreSelectionStep";

import DismissableBanner from "@common/banners/DismissableBanner";
import ErrorBoundary from "@components/ErrorBoundary";

import {
  resetCreateWebshopRequestStatus,
  webshopsLoadingSelector,
  webshopAccessRequestSelector,
  webshopsListSelector,
  webshopCreationErrorSelector,
  webshopsThunks,
} from "@redux/webshops/webshopsSlice";

import { showToast } from "@utils/redux";
import symbols from "@utils/symbols";
import { UI_STAGES, loadingStage, notRequestedStage, noAccessStage, isEmptyStage } from "./utils";

import { ReactComponent as PlusIcon } from "@assets/img/icons/common/plus.svg";
import { GlobalSettings } from "./GlobalSettings";

import PropType from "prop-types";

const steps = [<StoreSelection key="StoreSelection" />, <ProjectName key="ProjectName" />];

export function ProjectsPage() {
  const dispatch = useDispatch();
  const { onOpen, setSteps } = useMultiStepModal();
  const { clientWebshopsAccessGranted = false } = useFlags();

  const loading = useSelector(webshopsLoadingSelector);
  const requested = useSelector(webshopAccessRequestSelector);
  const webshops = useSelector(webshopsListSelector);
  const creationError = useSelector(webshopCreationErrorSelector);

  const [uiStage, setUiStage] = useState(UI_STAGES.LOADING);

  useEffect(() => {
    setSteps(steps);
  }, [setSteps]);

  useEffect(() => {
    dispatch(webshopsThunks.getWebshops());
  }, [dispatch]);

  useEffect(() => {
    if (creationError) {
      showToast("There was an error creating your project", "error");
      dispatch(resetCreateWebshopRequestStatus());
    }
  }, [creationError, dispatch]);

  useEffect(() => {
    if (loading) {
      setUiStage(UI_STAGES.LOADING);
    } else if (!requested && !clientWebshopsAccessGranted) {
      // We only care about not requested if access is not granted
      setUiStage(UI_STAGES.NOT_REQUESTED);
    } else if (!clientWebshopsAccessGranted) {
      setUiStage(UI_STAGES.NO_ACCESS);
    } else if (webshops.length) {
      setUiStage(UI_STAGES.PROJECTS);
    } else {
      setUiStage(UI_STAGES.EMPTY);
    }
  }, [loading, requested, clientWebshopsAccessGranted, webshops]);

  const onRequestAccess = () => {
    dispatch(webshopsThunks.accessRequest());
  };

  return (
    <ProjectsPagePresentation uiStage={uiStage} webshops={webshops} onOpen={onOpen} onRequestAccess={onRequestAccess} />
  );
}

export const ProjectsPagePresentation = ({
  uiStage = {},
  webshops = [],
  onOpen = () => {},
  onRequestAccess = () => {},
}) => {
  return (
    <>
      {uiStage.key === UI_STAGES.PROJECTS.key && <GlobalSettings />}

      <StyledPageContainer>
        {uiStage.key === UI_STAGES.PROJECTS.key && <PageHeader header="Projects"></PageHeader>}
        {uiStage.key === UI_STAGES.PROJECTS.key && (
          <Button
            isDisabled={uiStage.createDisabled}
            alignSelf={"center"}
            onClick={onOpen}
            data-test-id={"webshop-new-project"}
            variant={"primary"}
            mb={"48px"}
          >
            <Icon as={PlusIcon} w="16px" h="16px" />
            New Project
          </Button>
        )}

        <ErrorBoundary
          name="webshop-main"
          showAltUI={loadingStage(uiStage)}
          altUI={
            <VStack p="24px 0">
              <Spinner size="xl" />
            </VStack>
          }
        >
          <ProjectsRequestAccess uiStage={uiStage} onRequestAccess={onRequestAccess}>
            <ErrorBoundary
              name="webshop-projects-list"
              showAltUI={isEmptyStage(uiStage)}
              altUI={<ProjectsEmptyState onOpen={onOpen} />}
            >
              <SimpleGrid columns={2} gap={"32px"}>
                {webshops.map((webshop) => {
                  return <Project key={webshop.id} {...webshop} />;
                })}
              </SimpleGrid>
            </ErrorBoundary>
          </ProjectsRequestAccess>
        </ErrorBoundary>
      </StyledPageContainer>
    </>
  );
};

export const ProjectsRequestAccess = ({ uiStage = {}, onRequestAccess = () => {}, children }) => {
  return (
    <ErrorBoundary
      name="webshop-request-access"
      showAltUI={notRequestedStage(uiStage)}
      altUI={
        <VStack mb="16px" p="24px 0" gap="8px" borderRadius={"12px"} background={"rgba(33, 33, 38, 0.78)"}>
          <Text size={"mini"} fontWeight={500} color="textWhite.400">
            Boost your revenue, protect margins and own relationships with your players.
          </Text>
          <Text
            as="span"
            cursor="pointer"
            size={"mini"}
            fontWeight={500}
            color="brandRed.400"
            onClick={onRequestAccess}
          >
            Request Webshop demo -&gt;
          </Text>
        </VStack>
      }
    >
      <ErrorBoundary
        name="webshop-access-granted"
        showAltUI={noAccessStage(uiStage)}
        altUI={
          <DismissableBanner
            dismissable={false}
            title={`${symbols.pizza} Congrats!`}
            content={
              <VStack color="white" gap="4px" align="start">
                <Text color="currentcolor">
                  We're excited you are here! We'll be in touch to show you the demo and get you onboarded.
                </Text>
              </VStack>
            }
          />
        }
      >
        {children}
      </ErrorBoundary>
    </ErrorBoundary>
  );
};

ProjectsPagePresentation.propTypes = {
  uiStage: PropType.object,
  webshops: PropType.array,
  onOpen: PropType.func,
  onRequestAccess: PropType.func,
  enabledGlobalSettings: PropType.bool,
};
