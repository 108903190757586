export const DEFAULT = {
  type: "APPSFLYER",
  apiKey: "",
  iOSId: "",
  androidId: "",
  active: true,
};

export function getFormattedForm(appsFlyerData = {}) {
  const { id, active, apiKey, appIds } = appsFlyerData;
  return {
    id,
    apiKey,
    active,
    type: DEFAULT.type,
    androidId: appIds?.find((app) => app.platform === "ANDROID")?.value || "",
    iOSId: appIds?.find((app) => app.platform === "IOS")?.value || "",
  };
}
