import React from "react";
import { SanloTooltip } from "../sanlo-tooltip/SanloTooltip";
import SanloIcon from "../../icons/sanlo-icon/SanloIcon";

const IconTooltip = ({
  content,
  icon,
  // state,
  id,
  color = "#C5C5C5",
  className,
  ...props
}) => {
  return (
    <div className={className}>
      <SanloIcon icon={icon} color={color} id={id} />
      <SanloTooltip maxWidth={props.maxWidth} target={id} text={content} />
    </div>
  );
};

export default IconTooltip;
