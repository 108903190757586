import { drawerAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

// Drawer has the same Anatomy than Modal
const baseStyle = definePartsStyle({
  // define the part you're going to style
  overlay: {
    bg: "rgba(0, 0, 0, 0.6)",
  },
  dialog: {
    bg: "dark.700",
    borderWidth: "1px",
    borderColor: "dark.600",
    borderStyle: "solid",
    boxShadow: "-4px 0px 24px 8px rgba(0, 0, 0, 0.12)",
  },
  header: {
    fontFamily: "heading",
    fontSize: "2xl",
    padding: 0,
  },
  body: {
    padding: "16px 0 24px 0",
    fontFamily: "body",
    color: "textDefault.400",
    fontSize: "sm",
  },
  footer: {
    padding: 0,
  },
  closeButton: {
    top: "32px",
    right: "32px",
    color: "textDefault.400",
  },
});

export const Drawer = defineMultiStyleConfig({
  baseStyle,
});
