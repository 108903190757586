import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Icon,
  Input,
  InputRightElement,
  Tag,
  TagCloseButton,
  TagLabel,
  useOutsideClick,
  InputGroup,
  HStack,
} from "@chakra-ui/react";
import { DropdownContent, DropdownItem } from "@common/dropdown/Dropdown";
import { ReactComponent as EnterIcon } from "@assets/img/icons/common/enter-icon.svg";
import propTypes from "prop-types";

/**
 * TagInput component is used to select multiple items from a dropdown list.
 * @prop {boolean} isMultiple - Determines whether multiple items can be selected.
 * @prop {function} onChange - Callback function that is called when the selection changes. Returns the event, the new value, and the previous value. New value can be a string or an object.
 * @prop {Array} options - An array of objects representing the selectable options. Defaults to an empty array.
 * @prop {Object} defaultOption - The option that is selected by default.
 * @prop {function} renderOption - Function to render each option in a custom way.
 * @prop {string} placeholder - A short hint that describes the expected value of the input field.
 * @prop {function} updateSelectedItem - Callback function to update the state of the selected item. Returns an object with the edited item, the new value, and the selected items.
 * @prop {string} displayField - The name of the field from the options objects used to display the text.
 * @prop {string} optionType - Determines the type of the options. Can be "object","string", etc. Defaults to "object".
 * @prop {string} value - The current value of the selection. Defaults to an empty string.
 * @prop {boolean} hasError - Determines whether the input has an error. Defaults to false.
 * @prop {string|number} maxLength - Determines the maximum number of characters that can be entered in the input field.
 */

export function TagInput({
  isMultiple = false,
  readOnly = false,
  onChange,
  options = [],
  renderOption,
  placeholder,
  updateSelectedItem,
  displayField,
  optionType = "object",
  value = "",
  hasError = false,
  maxLength = null,
  ...rest
}) {
  if (!Array.isArray(options)) {
    throw new Error("options must be an array");
  }
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(() => options);
  const [selectedItems, setSelectedItems] = useState(() => (isMultiple ? [] : null));
  const [inputActive, setInputActive] = useState(false);
  const [newValue, setNewValue] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [itemToEdit, setItemToEdit] = useState();
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);
  const isOptionTypeObject = optionType === "object";

  if (isOptionTypeObject && !displayField) {
    throw new Error("You must provide the displayField prop when using objects as options");
  }
  if (isOptionTypeObject && typeof displayField !== "string") {
    throw new Error("displayField prop is used to access the object property and it must be a string");
  }

  function getInputValue() {
    if (isMultiple || inputActive) {
      return newValue;
    }

    if (isOptionTypeObject && typeof value === "object") {
      return value ? value[displayField] : "";
    }

    return value;
  }

  function resetStates() {
    setIsDropdownOpen(false);
    setInputActive(false);
    setNewValue("");
    setIsEditing(false);
    setItemToEdit(null);
  }

  useOutsideClick({
    ref: dropdownRef,
    handler: () => {
      resetStates();
    },
  });

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  useEffect(() => {
    if (value) {
      setSelectedItems(value);
    }
  }, [value]);

  function handleOnChange(e) {
    setNewValue(e.target.value);
    const filtered = options.filter((option) => {
      if (displayField) {
        return option[displayField].toLowerCase().includes(e.target.value.toLowerCase());
      }
      return option.toLowerCase().includes(e.target.value.toLowerCase());
    });
    setFilteredOptions(filtered);
  }

  function handleSelectedItem(event, item) {
    if (isMultiple) {
      const itemExists = selectedItems.find((selectedItem) => selectedItem === item);
      if (itemExists) {
        setSelectedItems((prev) => prev.filter((selectedItem) => selectedItem !== item));
        onChange(
          event,
          selectedItems.filter((selectedItem) => selectedItem !== item),
        );
      } else {
        setSelectedItems((prev) => [...prev, item]);
        onChange(event, [...selectedItems, item]);
      }
    } else {
      setSelectedItems(item);
      onChange(event, item);
      setNewValue("");
    }
    setIsDropdownOpen(false);
    setInputActive(false);
    setIsEditing(false);
  }

  function handleOnEnterPressed(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
      if (isEditing && itemToEdit) {
        // check if is not multiple
        if (!Array.isArray(selectedItems)) {
          updateSelectedItem({ editedItem: itemToEdit, newValue, selectedItems: newValue });
        } else {
          const updatedSelectedItems = selectedItems.map((item) => {
            if (item[displayField] === itemToEdit[displayField]) {
              item[displayField] = newValue;
              return { ...item };
            }
            return item;
          });
          // to review this behavior
          updateSelectedItem({ editedItem: itemToEdit, newValue: e.target.value, selectedItems: updatedSelectedItems });
        }
      } else if (isMultiple) {
        onChange(e, [...selectedItems, e.target.value], selectedItems);
        setSelectedItems((prev) => [...prev, e.target.value]);
      } else {
        onChange(e, e.target.value, selectedItems);
        // we want to clean the selected item
        setSelectedItems(null);
      }
      resetStates();

      setFilteredOptions(options);
      inputRef.current.blur();
    }
  }

  function handleEditSelectedItem(e, item) {
    e.preventDefault();
    e.stopPropagation();
    setIsEditing(true);
    setNewValue(isOptionTypeObject ? item[displayField] : item);
    setItemToEdit(item);
    inputRef.current.focus();
  }

  return (
    <Box w={"100%"} ref={dropdownRef} pos={"relative"}>
      <Box
        borderRadius={"10px"}
        display={"flex"}
        border={"2px solid transparent"}
        // this is to show the border when the input is active by clicking on a label or has an error
        borderColor={inputActive || hasError ? "brandRed.400" : "transparent"}
        bg={inputActive ? "dark.700" : "dark.400"}
        _hover={{ border: "2px solid", borderColor: "dark.100" }}
        _active={{ border: "2px solid", borderColor: "brandRed.400" }}
        _focusWithin={{ border: "2px solid", borderColor: "brandRed.400" }}
        overflow={"hidden"}
        h={"40px"}
        {...rest}
      >
        {selectedItems && value && !isMultiple && inputActive && !isEditing ? (
          <Tag
            borderRadius={"full"}
            flexShrink={0}
            alignSelf={"center"}
            ml="12px"
            onClick={(e) => handleEditSelectedItem(e, selectedItems)}
          >
            <TagLabel>{isOptionTypeObject ? value[displayField] : value}</TagLabel>
            <TagCloseButton
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setSelectedItems(isMultiple ? [] : null);
                onChange(e, isMultiple ? [] : null);
                inputRef.current.focus();
              }}
            />
          </Tag>
        ) : null}
        <InputGroup>
          <Input
            ref={inputRef}
            readOnly={readOnly}
            h="inherit"
            variant={"unstyled"}
            py="12px"
            px="16px"
            alignSelf={"center"}
            value={getInputValue()}
            onChange={handleOnChange}
            placeholder={placeholder || ""}
            maxLength={maxLength}
            onKeyDown={handleOnEnterPressed}
            onClick={() => {
              setIsDropdownOpen((prev) => !prev);
              setInputActive((prev) => !prev);
              if (inputActive) {
                inputRef.current.blur();
              }
            }}
          />
          <InputRightElement>
            <Icon w="16px" h="16px" as={EnterIcon} />
          </InputRightElement>
        </InputGroup>
      </Box>
      {filteredOptions.length ? (
        <DropdownContent
          isOpen={isDropdownOpen}
          handleSelectedItem={handleSelectedItem}
          data-test-id={`tag-input-dropdown`}
          zIndex={2}
        >
          {filteredOptions.map((option, index) => (
            <DropdownItem
              key={isOptionTypeObject ? `${option[displayField]}-option-${index}` : option}
              value={option}
              onClick={(e) => {
                handleSelectedItem(e, option);
              }}
              displayField={displayField}
              renderOption={renderOption}
              selected={selectedItems?.id === option?.id}
            />
          ))}
        </DropdownContent>
      ) : null}
      {isMultiple && selectedItems?.length ? (
        <HStack mt="24px" borderRadius="8px" p="8px" border="2px dashed" borderColor={"dark.100"} flexWrap={"wrap"}>
          {selectedItems.map((item, index) => (
            <Tag
              key={isOptionTypeObject ? `${value[displayField]}-option-${index}` : item}
              borderRadius={"full"}
              flexShrink={0}
              alignSelf={"center"}
              onClick={(e) => handleEditSelectedItem(e, item)}
            >
              <TagLabel>{isOptionTypeObject ? item[displayField] : item}</TagLabel>
              <TagCloseButton
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (isMultiple) {
                    const selectedItemsCopy = [...selectedItems];
                    const itemToRemoveIndex = selectedItems.findIndex((itemCopy) => itemCopy === item);
                    if (itemToRemoveIndex > -1) {
                      selectedItemsCopy.splice(itemToRemoveIndex, 1);
                    }
                    setSelectedItems(selectedItemsCopy);
                    onChange(e, selectedItemsCopy);
                  } else {
                    selectedItems(null);
                    onChange(e, null);
                  }

                  // remove the item from the selected items
                  inputRef.current.focus();
                }}
              />
            </Tag>
          ))}
        </HStack>
      ) : null}
    </Box>
  );
}

TagInput.propTypes = {
  isMultiple: propTypes.bool,
  readOnly: propTypes.bool,
  onChange: propTypes.func,
  options: propTypes.array,
  renderOption: propTypes.func,
  placeholder: propTypes.string,
  updateSelectedItem: propTypes.func,
  displayField: propTypes.string,
  optionType: propTypes.string,
  value: propTypes.oneOfType([propTypes.string, propTypes.object, propTypes.array]),
  hasError: propTypes.bool,
  maxLength: propTypes.oneOfType([propTypes.string, propTypes.number]),
};
