import React from "react";

import { useSelector } from "react-redux";

import { BaseCard } from "../BaseCard";
import { CUSTOM_DOMAIN_STATUS } from "@src/consts/webshops/settings";
import { CustomLink } from "./utils";
import { customDomainSelector } from "@redux/webshops/webshop/settings/custom-domain/customDomainSlice";

export const BaseDomain = () => {
  const { data } = useSelector(customDomainSelector);
  const { internalDomain = "" } = data;

  return (
    <BaseCard title={"Base Domain"} id={"builder-settings-base-domain"}>
      <CustomLink status={CUSTOM_DOMAIN_STATUS.CONNECTED} url={internalDomain} />
    </BaseCard>
  );
};
