export const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 10000000;

export const MEMORY_UNITS = {
  KB: 1000,
  MB: 1000000,
};

export const parseFileName = (fileName) => {
  const splitFileName = fileName.split(".");
  splitFileName.pop();
  return splitFileName.join("-");
};

export const shouldWrap = (files) => {
  return Object.keys(files).length > 4;
};

export const formatSize = (size) => {
  const unit = size >= MEMORY_UNITS.MB ? "MB" : "KB";
  const result = (size / MEMORY_UNITS[unit]).toFixed(2);
  return `${result} ${unit}`;
};

export const checkAllSuccess = (files = {}, fileUploaded = {}) => {
  if (!files || !Object.keys(files).length) return false;
  let allSuccess = true;
  Object.keys(files).forEach((fileName) => {
    const fileUploadedData = fileUploaded[fileName] || {};
    if (!fileUploadedData.success) allSuccess = false;
  });
  return allSuccess;
};

export const fileTypeValidation = (fileName) =>
  !fileName.match(/.(jpg|jpeg|png|pdf|bai2|doc|docx|csv|oth|odm|odg|otg|txt|ofx|xls|xlsx|xlsm|xlsb)$/i);
