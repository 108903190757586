import styled, { css } from "styled-components";
import { Input, Label, Navbar, NavItem, InputGroup } from "reactstrap";
import { ToastContainer } from "react-toastify";

export const StorybookWrapper = styled.div`
  width: 1094px !important;
  margin: 0 auto !important;
  padding: 2.5rem 0;
`;

export const LoadingShineCSS = css`
  overflow: hidden;
  // Hack to make the overflow hidden work on Safari with border-radius
  transform: translateZ(0);

  * {
    visibility: hidden;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    transform: translateX(150%) skewX(var(--loading-shine-skew));
    width: 100%;
    height: 100%;
    opacity: 0.25;
    z-index: 1;
    border-radius: 1rem;
    animation: loading-shine-anim 1.5s infinite;
    background: -moz-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(128, 186, 232, 0) 99%,
      rgba(125, 185, 232, 0) 100%
    );
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(0%, rgba(255, 255, 255, 0)),
      color-stop(50%, rgba(255, 255, 255, 0.8)),
      color-stop(99%, rgba(128, 186, 232, 0)),
      color-stop(100%, rgba(125, 185, 232, 0))
    );
    background: -webkit-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(128, 186, 232, 0) 99%,
      rgba(125, 185, 232, 0) 100%
    );
    background: -o-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(128, 186, 232, 0) 99%,
      rgba(125, 185, 232, 0) 100%
    );
    background: -ms-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(128, 186, 232, 0) 99%,
      rgba(125, 185, 232, 0) 100%
    );
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(128, 186, 232, 0) 99%,
      rgba(125, 185, 232, 0) 100%
    );
  }
  @keyframes loading-shine-anim {
    0% {
      transform: translateX(-150%) skewX(var(--loading-shine-skew));
    }
    100% {
      transform: translateX(150%) skewX(var(--loading-shine-skew));
    }
  }
`;

export const StyledContainer = styled.div`
  padding: 24px;
  background-color: ${({ theme }) => theme.backgroundColor};
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #333338;
`;

export const AbsoluteAllCSS = css`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const StyledHr = styled.hr`
  background-color: ${({ theme }) => theme.dividerColor};
  margin: 9px 0;
`;

export const StyledAccordion = styled.div`
  border-top: 1px solid ${({ theme }) => theme.borderDivider};
`;

export const StyledJumboInput = styled(Input)`
  background-color: transparent;
  color: ${({ theme }) => theme.inputFontColor};
  box-shadow: none;
  transition: none;
  border-radius: 12px;
  padding: 20px;
  height: 64px;
  border: none;
  font-family: Inter, "Helvetica", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  cursor: default !important;

  /* Change the white to any color ;) */

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    border: 2px solid ${({ theme }) => theme.inputBackgroundColor};
    -webkit-border-radius: 8px;
    -webkit-text-fill-color: ${({ theme }) => theme.grayFontColor} !important;
    -webkit-box-shadow: 0 0 0 30px ${({ theme }) => theme.grayscale20} inset !important;
    transition: background-color 5000s ease-in-out 0s;
  }

  caret-color: ${({ theme }) => theme.inputFontColor};

  ::-moz-selection {
    color: #000;
    background: ${({ theme }) => theme.colorRed};
  }

  ::selection {
    color: #000;
    background: ${({ theme }) => theme.colorRed};
  }

  :disabled,
  &:read-only {
    background-color: transparent;
  }

  :hover {
    color: ${({ theme }) => theme.inputFontColorHover};
  }

  :focus {
    background-color: transparent;
    color: ${({ theme }) => theme.inputFontColorFocus};
  }

  &.has-button-append {
    padding-right: 0px;
  }
`;

export const StyledJumboInputGroup = styled(InputGroup)`
  max-height: 64px;
  background-color: ${({ theme }) => theme.inputBackgroundColor};
  box-shadow: none;
  border-radius: 10px;
  border: 2px solid ${({ theme }) => theme.inputBackgroundColor};
  display: flex;
  align-items: center;

  :hover {
    border-color: ${({ theme }) => theme.inputBorderColorHover};
  }

  :focus-within {
    border-color: ${({ theme }) => theme.inputBorderColorFocus};
    background-color: ${({ theme }) => theme.inputBackgroundColorFocus};
  }

  &.is-edited:not(:focus-within) {
    background-color: ${({ theme }) => theme.inputBackgroundColorEdited};
    border-color: transparent;

    input {
      color: ${({ theme }) => theme.inputFontColorEdited};
    }
  }

  &.is-input-invalid.is-edited:not(:focus-within) {
    background-color: ${({ theme }) => theme.inputBackgroundColorError};
    border-color: transparent;

    input,
    input::placeholder {
      color: ${({ theme }) => theme.inputFontColorError};
    }
  }

  .input-group-prepend {
    max-height: 40px;

    .input-group-text {
      background-color: transparent;
      border: none;
    }
  }

  .jumbo-button-append {
    margin-right: 12px;
  }

  .input-remove {
    margin-right: 16px;
    fill: ${({ theme }) => theme.textSubtext};
    width: 16px;
    height: 16px;
    cursor: pointer;
    align-self: center;
  }
`;

export const StyledLabel = styled(Label)`
  color: ${({ theme }) => theme.labelFontColor};
  font-family: "Inter", "Helvetica", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;
`;

export const StyledNavLink = styled.span`
  margin-left: 12px;
  position: relative;
  color: ${({ theme }) => theme.primaryFontColor};
  transition: 0.4s ease;
`;

export const StyledNavBar = styled(Navbar)`
  &&& {
    background: ${({ theme }) => theme.grayscale30};
    border-right: 1px solid ${(props) => props.theme.borderDivider} !important;
  }
`;

export const StyledNavItem = styled(NavItem)`
  &&& {
    margin-top: 0 !important;

    &.active-route ${StyledNavLink} {
      color: ${({ theme }) => theme.primaryColor};
    }

    &:hover ${StyledNavLink} {
      color: ${({ theme }) => theme.primaryColor};
    }

    &.logout-button {
      position: absolute;
      bottom: 10px;
    }
  }
`;

// Typography

export const TitleHeadline44 = styled.span`
  font-family: "Roobert", "Helvetica", sans-serif;
  font-size: 44px;
  font-style: normal;
  font-weight: 400;
  line-height: 52px;
  letter-spacing: 0;
  text-align: left;
`;
export const TitleHeadlinePlus = styled.span`
  font-family: "Roobert", "Helvetica", sans-serif;
  font-size: 44px;
  font-style: normal;
  font-weight: 600;
  line-height: 52px;
  letter-spacing: 0;
  text-align: left;
`;

export const TitleLarge36 = styled.span`
  font-family: "Roobert", "Helvetica", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: 0;
  text-align: left;
`;
export const TitleLargePlus = styled.span`
  font-family: "Roobert", "Helvetica", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0;
  text-align: left;
`;

export const TitleRegular24 = styled.span`
  font-family: "Roobert", "Helvetica", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0;
  text-align: left;
`;

export const TitleRegularPlusCSS = css`
  font-family: "Roobert", "Helvetica", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0;
  text-align: left;
`;
export const TitleRegularPlus = styled.span`
  ${TitleRegularPlusCSS}
`;

export const TitleSmall20 = styled.span`
  font-family: "Roobert", "Helvetica", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
`;

export const TitleSmallPlusCSS = css`
  font-family: "Roobert", "Helvetica", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
`;
export const TitleSmallPlus = styled.span`
  ${TitleSmallPlusCSS}
`;

export const BodyLarge28 = styled.span`
  font-family: "Inter", "Helvetica", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
`;
export const BodyLargePlus = styled.span`
  font-family: "Inter", "Helvetica", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
`;

export const BodyRegular16 = styled.span`
  font-family: "Inter", "Helvetica", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
`;
export const BodyRegularPlusCSS = css`
  font-family: "Inter", "Helvetica", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
`;
export const BodyRegularPlus = styled.span`
  ${BodyRegularPlusCSS}
`;

export const BodySmall14CSS = css`
  font-family: "Inter", "Helvetica", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;
`;
export const BodySmall = styled.span`
  ${BodySmall14CSS}
`;
export const BodySmall14 = styled.div`
  ${BodySmall14CSS}
`;

export const BodySmallPlusCSS = css`
  font-family: "Inter", "Helvetica", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;
`;
export const BodySmallPlus = styled.div`
  ${BodySmallPlusCSS}
`;

export const BodySmallAltCSS = css`
  font-family: Inter, "Helvetica", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: left;
`;
export const BodySmallAlt = styled.span`
  ${BodySmallAltCSS}
`;

export const BodySmallAltPlusCSS = css`
  font-family: Inter, "Helvetica", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: left;
`;
export const BodySmallAltPlus = styled.span`
  ${BodySmallAltPlusCSS}
`;

export const BodyMini12CSS = css`
  font-family: "Inter", "Helvetica", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
`;
export const BodyMini12 = styled.span`
  ${BodyMini12CSS}
`;
export const BodyMini12Muted = styled(BodyMini12)`
  color: ${({ theme }) => theme.subTextDark};
`;

export const BodyMiniPlusCSS = css`
  font-family: "Inter", "Helvetica", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
`;
export const BodyMiniPlus = styled.span`
  ${BodyMiniPlusCSS}
`;

export const StyledAvatar = styled.a`
  margin-right: -0.3rem;
  position: relative;
  z-index: 2;
  height: 36px;
  width: 36px;
  border-radius: 50%;

  &:hover {
    z-index: 3;
  }
`;

export const StyledAvatarInitials = styled.div`
  &&& {
    height: 36px;
    width: 36px;
    min-width: 36px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.firstUserInitials};
    color: ${(props) => props.theme.userInitialsFontColor};
    border: 1px solid ${(props) => props.theme.scenarioBorder};
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: "Inter", "Helvetica", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    line-height: 16px;
    letter-spacing: 0.01em;

    &.avatar-0 {
      background-color: ${({ theme }) => theme.firstUserInitials};
    }

    &.avatar-1 {
      background-color: ${({ theme }) => theme.secondUserInitials};
    }

    &.avatar-2 {
      background-color: ${({ theme }) => theme.thirdUserInitials};
    }

    &.sidebar-avatar {
      height: 24px;
      width: 24px;
      min-width: 24px;
      border-radius: 50%;
      color: ${(props) => props.theme.userInitialsFontColor};
      border: 1px solid ${(props) => props.theme.scenarioBorder};
      padding: 3px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.02em;
      text-align: left;
    }
  }
`;

export const StyledToastContainer = styled(ToastContainer)`
  &&.Toastify__toast-container {
    background: ${(props) => (props.isFirefox ? props.theme.toastContainerBgFirefox : props.theme.toastContainerBg)};
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    min-width: 342px;
    min-height: 38px;
    width: fit-content;
    max-width: 600px;
    backdrop-filter: blur(32px);
    border-radius: 10px;
  }

  .Toastify__toast {
    align-items: center;
    padding: 12px;
    border-radius: 10px;
    min-height: 32px;
    margin: 8px;
    font-family: Inter, "Helvetica", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.01em;
    text-align: left;
  }

  .Toastify__toast--success {
    background: ${(props) => props.theme.successToastBg};
    color: ${(props) => props.theme.colorGreen};
  }

  .Toastify__toast--warning {
    background: ${(props) => props.theme.warningToastBg};
    color: ${(props) => props.theme.colorYellow};
  }

  .Toastify__toast--error {
    background: ${(props) => props.theme.errorToastBg};
    color: ${(props) => props.theme.colorRed};
  }
`;

export const StyledMessageCard = styled.div`
  width: 100%;
  padding: 28px 32px 32px 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 12px;
  background: ${({ theme }) => theme.grayscale40};
  color: ${({ theme }) => theme.textDefault};

  .action-button {
    margin-top: 24px;
  }
`;

export const StyledAvailableGCO = styled.div`
  position: absolute;
  width: 6px;
  height: 6px;
  background-color: ${({ theme }) => theme.primaryColor};
  border-radius: 50%;
  top: 0;
  right: -8px;
`;
