import HTTP from "@redux/http";

import { attributeUrl, attributePayload } from "@utils/branding";

const SANLO_WEB_API_URL = process.env.REACT_APP_SANLO_WEB_API_URL;
const BASE = `${SANLO_WEB_API_URL}/api/integrations/advance`;
const CAPITAL_REQUEST_BASE = `${SANLO_WEB_API_URL}/api/company/capital-request`;

export default {
  getSummary: () => {
    return HTTP.req.get(attributeUrl(`${CAPITAL_REQUEST_BASE}/summary`));
  },

  advances: () => {
    return HTTP.req.get(`${BASE}/track-advance`);
  },

  capitalRequest: (data) => {
    return HTTP.req.post(`${CAPITAL_REQUEST_BASE}/create`, attributePayload(data));
  },

  capitalRequestStart: () => {
    return HTTP.req.post(`${CAPITAL_REQUEST_BASE}/start`);
  },

  capitalRequestClone: () => {
    return HTTP.req.post(`${CAPITAL_REQUEST_BASE}/clone`, {});
  },

  capitalRequestDismissStatus: () => {
    return HTTP.req.patch(`${CAPITAL_REQUEST_BASE}/dismiss`);
  },
};
