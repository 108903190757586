import styled from "styled-components";
import { Modal } from "reactstrap";

export const StyledFullScreenModal = styled(Modal)`
  & {
    max-width: fit-content !important;

    &.modal-dialog {
      margin: 0 !important;
    }

    .modal-content {
      overflow: hidden;
      background-color: ${(props) => props.theme.grayscale10};
      width: 100vw;
      height: 100vh;
      max-height: 100vh;

      .leftGradModal {
        position: absolute;
        width: 652px;
        height: 652px;
        left: -24%;
        top: 86px;
        background: linear-gradient(291.19deg, rgba(191, 107, 255, 0.5) 10.65%, rgba(255, 92, 121, 0.5) 89.36%);
        filter: blur(130px);
        border-radius: 50%;

        &.error {
          background: linear-gradient(291.19deg, rgba(255, 66, 100, 0.5) 54.11%, rgba(191, 107, 255, 0.5) 89.36%);
        }
      }

      .rightGradModal {
        position: absolute;
        width: 652px;
        height: 652px;
        right: -24%;
        top: 106px;
        background: linear-gradient(291.19deg, rgba(45, 249, 176, 0.5) 10.65%, rgba(191, 107, 255, 0.5) 89.36%);
        filter: blur(100px);
        border-radius: 50%;

        &.error {
          background: linear-gradient(291.19deg, rgba(130, 128, 255, 0.5) 10.65%, rgba(255, 66, 100, 0.5) 39.35%);
        }
      }

      #toast-bg-fade {
        visibility: hidden;
        background: linear-gradient(180deg, rgba(26, 26, 31, 0) 0%, #1a1a1f 51.42%);
        height: 200px;
        top: 80%;
        width: -webkit-fill-available;
        position: fixed;
      }

      #toast-bg-fade.show {
        visibility: visible;
        -webkit-animation: fadeinBg 0.5s, fadeoutBg 0.5s 2.5s;
        animation: fadein 0.5s, fadeout 0.5s 2.5s;
      }

      @-webkit-keyframes fadeinBg {
        from {
          bottom: -200px;
          opacity: 0;
        }
        to {
          bottom: 0;
          opacity: 1;
        }
      }

      @keyframes fadeinBg {
        from {
          bottom: -200px;
          opacity: 0;
        }
        to {
          bottom: 0;
          opacity: 1;
        }
      }

      @-webkit-keyframes fadeoutBg {
        from {
          bottom: 0;
          opacity: 1;
        }
        to {
          bottom: -200px;
          opacity: 1;
        }
      }

      @keyframes fadeoutBg {
        from {
          bottom: 0;
          opacity: 1;
        }
        to {
          bottom: -200px;
          opacity: 1;
        }
      }

      .fs-modal-container {
        z-index: 1;
        padding: 48px 48px 0 48px;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        overflow-y: scroll;

        .fs-modal-subtitle {
          margin-top: 8px;
        }

        .fs-modal-top-navigation {
          width: 100%;

          .progress-bar-content {
            width: 100%;
            border-radius: 21px;
            display: flex;
            align-items: center;
            background-color: ${({ theme }) => theme.grayscale40};
            margin-bottom: 24px;

            .value {
              height: 16px;
              width: 50%;
              background-color: ${({ theme }) => theme.colorElectricBlue};
              border-radius: 21px;
              z-index: 1;
              transition: width 600ms linear;
            }

            .empty {
              background-color: ${({ theme }) => theme.grayscale40};
              border-radius: 0px 100px 100px 0px;
              width: 100%;
              height: 16px;
              z-index: 0;
              transition: width 600ms linear;
            }
          }

          .fs-modal-controls {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: ${({ theme }) => theme.textEmphasis};

            .fs-modal-arrow-icon {
              padding: 6.5px 8px 8px 8px;
              width: 40px;
              height: 40px;
              fill: ${({ theme }) => theme.textEmphasis};
              border-radius: 8px;

              :hover {
                cursor: pointer;
                background-color: ${({ theme }) => theme.iconHoverBackground};
              }

              &.hidden {
                visibility: hidden;
              }
            }

            .fs-modal-close {
              width: 40px;
              height: 40px;
              border-radius: 8px;
              padding: 0;
              margin: 0;
              background-color: transparent;
              border: none;

              :hover {
                cursor: pointer;
                background-color: ${({ theme }) => theme.iconHoverBackground};
              }

              svg {
                width: 24px;
                height: 24px;
                fill: ${({ theme }) => theme.textEmphasis};
              }
            }
          }

          .fs-modal-header {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 40px;
            margin-bottom: 48px;
          }
        }

        .fs-modal-body {
          max-width: -webkit-fill-available;
          max-width: -moz-fill-available;
          height: 65%;

          .jumbo-input-label {
            color: ${({ theme }) => theme.textEmphasis};
            margin-bottom: 8px;
          }

          .jumbo-input-group {
            margin-bottom: 24px;
          }

          .fs-modal-content {
            display: flex;
            justify-content: center;
            height: 100%;

            #bottom-toast {
              visibility: hidden;
              min-width: 250px;
              margin-left: -125px;
              background-color: ${({ theme }) => theme.grayscale40};
              box-shadow: 0px 0px 48px rgba(0, 0, 0, 0.4);
              color: ${({ theme }) => theme.textEmphasis};
              text-align: center;
              border-radius: 12px;
              padding: 12px;
              position: fixed;
              z-index: 1000;
              left: 50%;
              bottom: 5%;
            }

            #bottom-toast.show {
              visibility: visible;
              -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
              animation: fadein 0.5s, fadeout 0.5s 2.5s;
            }

            @-webkit-keyframes fadein {
              from {
                bottom: 0;
                opacity: 0;
              }
              to {
                bottom: 5%;
                opacity: 1;
              }
            }

            @keyframes fadein {
              from {
                bottom: 0;
                opacity: 0;
              }
              to {
                bottom: 5%;
                opacity: 1;
              }
            }

            @-webkit-keyframes fadeout {
              from {
                bottom: 5%;
                opacity: 1;
              }
              to {
                bottom: 0;
                opacity: 0;
              }
            }

            @keyframes fadeout {
              from {
                bottom: 5%;
                opacity: 1;
              }
              to {
                bottom: 0;
                opacity: 0;
              }
            }

            .fcf-home {
              margin-top: 242px;
              display: flex;
              flex-direction: column;
              align-items: center;

              .template-cards-container {
                margin-top: 28px;
                display: flex;
              }
            }

            .fs-modal-actions {
              width: 420px;
              height: 100%;
              margin-right: 32px;
              display: flex;
              flex-direction: column;

              .integration-error-message {
                margin-bottom: 16px;
                display: none;

                & > div {
                  justify-content: flex-start;
                }

                &.show-message {
                  display: block;
                }
              }

              .fs-scrollable {
                overflow: auto;
              }

              .fs-modal-link-button {
                color: ${({ theme }) => theme.colorRed};
                cursor: pointer;
              }
            }

            .fs-link {
              color: ${({ theme }) => theme.colorRed};
            }

            .contact-us {
              margin-top: 1rem;
              color: rgba(255, 255, 255, 0.5);
            }

            &.done-step {
              .fs-modal-instructions-integrations {
                display: none;
              }

              .fs-modal-actions {
                margin-right: 0;
                /* justify-content: center; */
                align-items: center;
                width: 476px;
              }

              .done-title {
                margin-bottom: 8px;
                color: ${({ theme }) => theme.textEmphasis};
              }

              .done-description {
                text-align: center;
                color: ${({ theme }) => theme.grayFontColor};
                margin-bottom: 32px;
              }
            }

            &.align-left {
              .fs-modal-actions {
                align-items: flex-start;
              }
              .done-description {
                text-align: left;
              }
            }
          }
        }
      }
    }

    // Single message container
    .fs-modal-body {
      .single-message-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 120px;

        .content-container {
          margin: 0 30px;
          display: flex;
          flex-direction: column;
        }

        .done-title {
          margin-bottom: 8px;
        }

        .done-description {
          text-align: center;
          color: ${({ theme }) => theme.textGray};

          :last-child {
            margin-bottom: 24px;
          }
        }
      }
    }

    .step-input-wrapper {
      margin-bottom: 24px;
    }

    // scroll shadow
    .scroll-shadow-wrapper {
      position: relative;
      height: fit-content;
      overflow: hidden;

      .shadow {
        box-shadow: none !important;
        pointer-events: none;
        opacity: 0;
        position: absolute;
        left: 0;
        z-index: 100;
        width: 100%;
        height: 30px;
      }

      .shadow--top {
        display: none;
        top: 0;
        background: linear-gradient(360deg, rgba(26, 26, 31, 0) 0%, #1a1a1f 100%);
      }

      .shadow--bottom {
        display: none;
        bottom: 0;
        background: linear-gradient(180deg, rgba(26, 26, 31, 0) 0%, #1a1a1f 100%);
      }

      &.active-shadow-scroll {
        .shadow--bottom {
          display: block;
        }
        .shadow--top {
          display: block;
        }
      }

      .scroll-shadow-content {
        height: 100%;
        overflow-y: scroll;

        display: flex;
        flex-direction: column;
      }
    }
  }
`;
