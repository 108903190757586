export const getUserName = (user = {}) => {
  try {
    const { firstName = "", lastName = "" } = user;
    let username = "Sanlo User";
    if (firstName) {
      username = `${firstName} ${lastName}`;
    }
    return username;
  } catch (e) {
    return "Sanlo User";
  }
};

export const isCypressUser = (email) => {
  return email && email.includes("sanlo-cypress");
};
