import React, { useState, useRef } from "react";
import classnames from "classnames";

import { BodyRegularPlus, BodySmallPlus, StyledJumboInput, StyledJumboInputGroup, BodyMini12Muted } from "@styled";
import { StepActionsContainer } from "@common/animated/FadeStepsContainer";
import SetupGuide from "@common/modals/integrations-modal/SetupGuide";
import { Loader } from "@components/common/loaders";
import RegularModal from "@common/modals/regular-modal/RegularModal";
import SanloChip from "@common/chips/SanloChip";
import { SanloButton, Link, Support } from "@inputs";
import { ErrorMessage } from "@components/integrations/connection-guide/ErrorMessage";
import { Callout } from "@components/integrations/connection-guide/Callout";
import { StyledScrollableList } from "@components/common/modals/integrations-modal/StyledSetupGuide";

import useToggle from "@utils/custom-hooks/useToggle";
import symbols from "@utils/symbols";

import { ReactComponent as CrossIcon } from "@assets/img/icons/common/modal-cross.svg";
import { ReactComponent as PlusCircle } from "@assets/img/icons/common/plus-circle.svg";
import { ReactComponent as ErrorIcon } from "@assets/img/icons/common/alertTriangle.svg";

export const AppsFlyerStep2 = ({
  platform = {
    id: "appsflyer",
    name: "AppsFlyer",
    platformId: "appsflyer",
  },
  connectPlatform = () => {},
  requestStatus = {},
  appTokens = [""],
  credentialsError = "",
  setAppTokens = () => {},
}) => {
  const contentRef = useRef(null);

  const [removeAppTokenModalVisible, toggleRemoveAppTokenModal] = useToggle(false);
  const [appTokenToDelete, setAppTokenToDelete] = useState(null);

  const handleAddAppToken = (listRef) => {
    if (appTokens[appTokens.length - 1]?.length !== 0) {
      setAppTokens([...appTokens, ""]);
    } else if (listRef) {
      const lastChild = listRef.current.childNodes.length - 1;
      const input = listRef.current.childNodes[lastChild].childNodes[0];
      input.focus();
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleAddAppToken(contentRef);
    }
  };

  const handleDeleteAppToken = (index) => {
    let appTokensClone = appTokens.slice(0);
    appTokensClone.splice(index ? index : appTokenToDelete, 1);
    setAppTokens(appTokensClone);
    toggleRemoveAppTokenModal(false);
  };

  return (
    <StepActionsContainer>
      <div className="fs-modal-actions">
        <ErrorMessage error={credentialsError} />

        <div ref={contentRef}>
          <BodySmallPlus className="jumbo-input-label">{`App ID(s)`}</BodySmallPlus>
          <StyledScrollableList
            margin="16px 0"
            borderRadius="10px"
            maxHeightScroll="375px"
            className={classnames({
              "scroll-wrap-content": appTokens.length > 4,
            })}
            data-test-id={`${platform.platformId}-app-id-list`}
          >
            {appTokens.map((appId, index) => (
              <StyledJumboInputGroup key={`app-token-input-${index}`} className="jumbo-input-group">
                <StyledJumboInput
                  onKeyPress={handleKeyPress}
                  autoFocus={true}
                  value={appId}
                  placeholder="Enter your App ID"
                  type="text"
                  onChange={(event) => {
                    let clone = appTokens.slice(0);
                    clone[index] = event.target.value;
                    setAppTokens(clone);
                  }}
                  data-test-id={`${platform.platformId}-app-id-input-${index + 1}`}
                />
                {appTokens.length > 1 && (
                  <CrossIcon
                    className="input-remove"
                    onClick={() => {
                      if (appTokens.at(index).length !== 0) {
                        setAppTokenToDelete(index);
                        toggleRemoveAppTokenModal();
                      } else {
                        handleDeleteAppToken(index);
                      }
                    }}
                  />
                )}
              </StyledJumboInputGroup>
            ))}
          </StyledScrollableList>
        </div>

        <div className="fs-modal-link-button-wrapper" onClick={() => handleAddAppToken(contentRef)}>
          <PlusCircle className={classnames("fs-plus-circle-icon", `tag-sanlo-${platform.name}-add-app-id`)} />
          <BodyRegularPlus
            className={`fs-modal-link-button tag-sanlo-${platform.name}-add-app-id`}
            data-test-id={`${platform.platformId}-add-app-token`}
          >
            Add App ID
          </BodyRegularPlus>
        </div>

        <SanloButton
          isJumbo
          isDisabled={appTokens.length >= 1 && appTokens[0].length === 0}
          onClick={connectPlatform}
          label={
            requestStatus?.saveCredentials?.pending ? (
              <Loader className="button-loader" />
            ) : (
              `Connect to ${platform.name}`
            )
          }
          gtmEventTag={`tag-sanlo-${platform.name}-step2-connect-to-${platform.name}`}
          testId={`${platform.platformId}-app-id-submit-button`}
        />
      </div>

      <SetupGuide
        platformStep={`${platform.name}1`}
        step={{
          callout: (
            <Callout>
              {symbols.LightBulb()} Access to the right set of data provides the most delightful experience for tracking
              ad revenue and user acquisition spend. Have questions about {platform.name} connection?{" "}
              <Support className="setup-guide-link" />
            </Callout>
          ),
          title: "Copy your App ID(s)",
          description: (
            <>
              <ol className="guide-list">
                <li>
                  Navigate to the{" "}
                  <Link
                    href="https://hq1.appsflyer.com/apps/myapps"
                    className="modal-link setup-guide-link tag-sanlo-af-my-apps-page-link"
                    isExternalLink
                    data-test-id={`${platform.platformId}-step-2-my-apps-link`}
                  >
                    My Apps
                  </Link>{" "}
                  page.
                </li>
                <li>Click the “•••” icon and go to “App Settings”.</li>
                <li>
                  Copy your app ID from the URL address.
                  <div>
                    {symbols.LightBulb()}{" "}
                    <BodyMini12Muted>
                      It will have the reverse URL format for Android apps (e.g. com.example.app) and an ID for iPhone
                      apps (e.g. id123456789).
                    </BodyMini12Muted>
                  </div>
                </li>
                <li>Paste this App ID(s) on the left {symbols.PointLeft()}</li>
              </ol>
              <div style={{ margin: "5px 0 0 7px" }}>
                Don't have any apps on {platform.name} yet?{" "}
                <Link
                  href="https://support.appsflyer.com/hc/en-us/articles/207377436-Adding-a-new-app"
                  className="modal-link setup-guide-link tag-sanlo-af-no-apps-link"
                  isExternalLink
                  data-test-id={`${platform.platformId}-step-2-click-here-link`}
                >
                  Click Here
                </Link>{" "}
              </div>
            </>
          ),
          mediaSlider: [
            require("./assets/apps_flyer_guide_1_step_2.png"),
            require("./assets/apps_flyer_guide_2_step_2.png"),
            require("./assets/apps_flyer_guide_3_step_2.png"),
          ],
        }}
      />

      <RegularModal
        width={500}
        isOpen={removeAppTokenModalVisible}
        handleToggle={toggleRemoveAppTokenModal}
        title={`Remove App ID?`}
        content={
          <>
            <SanloChip
              state="error"
              content={
                <>
                  <ErrorIcon className="chip-icon" />
                  <span>{`Are you sure you want to remove this App ID? You will no longer be able to get insights, such as tracking your user acquistion spend and ad revenue.`}</span>
                </>
              }
            />
            <div className="modal-aside-buttons">
              <SanloButton isSecondary label="Cancel" onClick={toggleRemoveAppTokenModal} />
              <SanloButton label="Remove" onClick={handleDeleteAppToken} />
            </div>
          </>
        }
      />
    </StepActionsContainer>
  );
};
