import React from "react";

import { Box, Flex, Text, Grid } from "@chakra-ui/react";
import isEmpty from "lodash/isEmpty";

export const Chart = ({
  noTitle = false,
  chartId = "",
  data = [],
  children,
  yAxisSteps = {},
  withNegYAxis = false,
  hasBckGrndColor = false,
  verticalLine = {},
  backgroundLines = true,
  yAxisAlign = "flex-end",
  yAxisML = "10px",
  chartMaxWidth = "100%",
  yAxisWidth = "auto",
}) => {
  const addVerticalLine = !isEmpty(verticalLine);

  const {
    chartWrapperRef,
    mouseX = 0,
    showVerticalLine = false,
    handleMouseEnter = () => {},
    handleMouseLeave = () => {},
    handleMouseMove = () => {},
  } = verticalLine;

  return (
    // Wrapper
    <Grid
      boxSizing={"border-box"}
      gridTemplateColumns={"auto 1fr"}
      gridTemplateRows={"1fr auto"}
      gridTemplateAreas={`
        "yaxis chart"
        "empty xaxis"
      `}
      h={noTitle ? "100%" : "85%"}
      w={"100%"}
      maxW={chartMaxWidth}
      backgroundColor={"dark.600"}
      onMouseEnter={addVerticalLine ? handleMouseEnter : null}
      onMouseLeave={addVerticalLine ? handleMouseLeave : null}
      onMouseMove={addVerticalLine ? handleMouseMove : null}
    >
      {/* YAxis */}
      <Flex
        gridArea={"yaxis"}
        flexDirection={"column-reverse"}
        justifyContent={"space-between"}
        alignItems={yAxisAlign}
        pl={yAxisML}
        pr={"5px"}
        cursor={"default"}
        w={yAxisWidth}
      >
        {yAxisSteps.steps.map((stepData, index) => (
          // YAxisText
          <Flex
            key={`key-yaxis-${chartId}-${index}`}
            fontSize={"12px"}
            fontStyle={"normal"}
            fontWeight={"400"}
            lineHeight={"16px"}
            alignItems={"center"}
            letterSpacing={"0.02em"}
            textAlign={"left"}
            color={"textWhite.400"}
            transform={`translateY(${stepData.chartLineOffsetPercent}%)`}
          >
            {stepData.display}
          </Flex>
        ))}
      </Flex>

      {/* ChartWrapper */}
      <Grid
        ref={chartWrapperRef}
        position={"relative"}
        gridArea={"chart"}
        gridTemplateColumns={`repeat(${data.length || 1}, 1fr)`}
        gridTemplateRows={"1fr"}
      >
        {withNegYAxis && hasBckGrndColor && (
          // BlockColorWrapper
          <Flex position={"absolute"} flexDirection={"column"} top={"0"} bottom={"0"} right={"0"} left={"0"}>
            {/* BlockColor */}
            <Box w={"100%"} h={"50%"} backgroundColor={"rgba(45, 250, 176, 0.04)"}></Box>
            <Box w={"100%"} h={"50%"} backgroundColor={"rgba(255, 92, 121, 0.04)"}></Box>
          </Flex>
        )}

        {/* BGLines */}
        {backgroundLines && (
          <Flex
            position={"absolute"}
            flexDirection={"column"}
            justifyContent={"space-between"}
            top={"0"}
            bottom={"0"}
            right={"0"}
            left={"0"}
          >
            {yAxisSteps.steps.map((_, index) => {
              const isCenterLine = withNegYAxis && index === Math.floor(yAxisSteps.steps.length / 2);
              if (!isCenterLine) {
                return <Box key={`key-bgline-${chartId}-${index}`} w={"100%"} h={"1px"} backgroundColor={"dark.400"} />;
              } else {
                // The center line of a chart with negative values is dotted
                // But we cant use border because the dashes arent controllable
                // So we use a background image instead
                return (
                  <Box
                    key={`key-bgline-${chartId}-${index}`}
                    w={"100%"}
                    h={"1px"}
                    backgroundImage={"linear-gradient(to right, #333338 50%, transparent 50%)"}
                    backgroundSize={"10px 1px"}
                    backgroundPosition={"0 0"}
                    backgroundRepeat={"repeat-x"}
                  />
                );
              }
            })}
          </Flex>
        )}
        {/* Show one single centered horizontal line */}
        {!backgroundLines && withNegYAxis && (
          <Box position={"absolute"} top={"50%"} bottom={"0"} w={"100%"} h={"1px"} backgroundColor={"dark.400"} />
        )}

        {/* Vertical Line */}
        {addVerticalLine && showVerticalLine && (
          <Box
            position={"absolute"}
            top={"0"}
            bottom={"0"}
            w={"1px"}
            cursor={"default"}
            zIndex={"3"}
            left={`${mouseX}px`}
            backgroundColor={"dark.200"}
          />
        )}

        {children}
      </Grid>

      {/* XAxis */}
      <Grid
        gridArea={"xaxis"}
        position={"relative"}
        mt={"16px"}
        gridTemplateColumns={`repeat(${data.length || 1}, 1fr)`}
        gridTemplateRows={"1fr"}
        textAlign={"center"}
        cursor={"default"}
      >
        {data.map((item, index, arr) => {
          const key = `key-xaxis-${chartId}-${index}`;

          if (item.xAxisItemParser) {
            return item.xAxisItemParser(item, key);
          }

          return (
            <Text
              key={key}
              size={"mini"}
              textAlign={"center"}
              letterSpacing={"0.02em"}
              color={"textWhite.400"}
              opacity={index === arr.length - 1 ? 1 : 0.75}
            >
              {item.shortDate}
            </Text>
          );
        })}
      </Grid>
    </Grid>
  );
};
