import {
  getErrorStatus,
  getRequestStatus,
  getResponseStatus,
  integrationInitialState,
  setStatusDefaults,
  stateSet,
  checkNetworkError,
  parseIntegration,
} from "@utils/redux";
import { PRODUCT_ANALYTICS } from "@utils/consts";
import { DRAFT_CONNECTION, NOT_CONNECTED, CONNECTOR_ERROR, NO_PERMISSIONS } from "@utils/status";

import { GET_INTEGRATIONS_CONNECTIONS } from "../base/actions";

import {
  ADJUST_CLEAR_CREDENTIALS_STATUS,
  ADJUST_GET_DRAFT_CREDENTIALS,
  ADJUST_GET_UA_SPEND,
  ADJUST_SAVE_CREDENTIALS,
  ADJUST_SAVE_DRAFT_CREDENTIALS,
  ADJUST_TEST_CREDENTIALS,
  ADJUST_TEST_CREDENTIALS_ERRORS,
  ADJUST_UNLINK_INTEGRATION,
} from "./actions";

import { getHighestPriorityStatus } from "@utils/integrations";

const initialState = integrationInitialState({
  id: "adjust",
  name: "Adjust",
  platformId: "adjust",
  type: PRODUCT_ANALYTICS,
  category: PRODUCT_ANALYTICS,
  image: require("@assets/img/icons/logos/AdjustRoundedLogo.svg").default,
  canSaveProgress: true,
  isPlatform: true,
  isAdPlatform: true,
  hasUASpend: true,
  requestStatus: {
    getData: setStatusDefaults(),
    getDraftCredentials: setStatusDefaults(),
    saveDraftCredentials: setStatusDefaults(),
    saveCredentials: setStatusDefaults(),
    testCredentials: setStatusDefaults(),
    unlinkIntegration: setStatusDefaults(),
  },
  credentials: {
    status: "default", // default | valid | invalid
    draft: false,
    apiToken: "",
    appTokens: [""],
  },
  steps: [
    {
      title: "Enter your API token",
      subtitle: "Enter your Adjust API token to establish a connection.",
    },
    {
      title: "Enter your App token(s)",
      subtitle: "Enter your App token(s) to start syncing data for your apps.",
    },
    {
      // Placeholder step for permissions information
      title: null,
      subtitle: null,
      hideProgress: true,
      isSetupDone: true,
    },
  ],
});

const adjustReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INTEGRATIONS_CONNECTIONS.RESPONSE: {
      return {
        ...state,
        ...parseIntegration("adjust", action.response),
        name: state.name, // Keep the display name we have set in the intial state
      };
    }

    case ADJUST_GET_DRAFT_CREDENTIALS.REQUEST: {
      return stateSet(state, {
        "requestStatus.getDraftCredentials": getRequestStatus(),
      });
    }

    case ADJUST_GET_DRAFT_CREDENTIALS.RESPONSE: {
      return stateSet(state, {
        "requestStatus.getDraftCredentials": getResponseStatus(),
        "credentials": {
          draft: true,
          apiToken: action.response.apiToken,
          appTokens: action.response.appTokens,
        },
      });
    }

    case ADJUST_GET_DRAFT_CREDENTIALS.ERROR: {
      return stateSet(state, {
        "requestStatus.getDraftCredentials": getErrorStatus(),
      });
    }

    case ADJUST_SAVE_DRAFT_CREDENTIALS.REQUEST: {
      return stateSet(state, {
        "requestStatus.saveDraftCredentials": getRequestStatus(),
      });
    }

    case ADJUST_SAVE_DRAFT_CREDENTIALS.RESPONSE: {
      return stateSet(state, {
        "status": action.response.apiToken ? DRAFT_CONNECTION : NOT_CONNECTED,
        "requestStatus.saveDraftCredentials": getResponseStatus(),
        "requestStatus.testCredentials": {
          error: false,
        },
        "credentials": {
          draft: true,
          apiToken: action.response.apiToken,
          appTokens: action.response.appTokens,
        },
      });
    }

    case ADJUST_SAVE_DRAFT_CREDENTIALS.ERROR: {
      return stateSet(state, {
        "requestStatus.saveDraftCredentials": getErrorStatus(),
      });
    }

    case ADJUST_TEST_CREDENTIALS.REQUEST: {
      return stateSet(state, {
        "requestStatus.testCredentials": getRequestStatus(),
      });
    }

    case ADJUST_TEST_CREDENTIALS.RESPONSE: {
      return stateSet(state, {
        "requestStatus.testCredentials": getResponseStatus(),
      });
    }

    case ADJUST_TEST_CREDENTIALS.ERROR: {
      return stateSet(state, {
        "requestStatus.testCredentials": getErrorStatus(),
      });
    }

    case ADJUST_SAVE_CREDENTIALS.REQUEST: {
      return stateSet(state, {
        "requestStatus.saveCredentials": getRequestStatus(),
        "credentials.status": "default",
      });
    }

    case ADJUST_SAVE_CREDENTIALS.RESPONSE: {
      action.response.status = getHighestPriorityStatus(action.response);
      return stateSet(state, {
        "requestStatus.saveCredentials": getResponseStatus(),
        "credentials": {
          status: action.response.status === NO_PERMISSIONS ? "no_permissions" : "valid",
          draft: false,
          apiToken: "",
          appTokens: [""],
        },
      });
    }

    case ADJUST_SAVE_CREDENTIALS.ERROR: {
      return stateSet(state, {
        "requestStatus.saveCredentials": getErrorStatus(),
      });
    }

    case ADJUST_UNLINK_INTEGRATION.REQUEST: {
      return stateSet(state, {
        "requestStatus.unlinkIntegration": getRequestStatus(),
      });
    }

    case ADJUST_UNLINK_INTEGRATION.RESPONSE: {
      return stateSet(state, {
        "requestStatus.unlinkIntegration": getResponseStatus(),
        "credentials": {
          status: "default",
          draft: false,
          apiToken: "",
          appTokens: [""],
        },
      });
    }

    case ADJUST_UNLINK_INTEGRATION.ERROR: {
      return stateSet(state, {
        "requestStatus.unlinkIntegration": getErrorStatus(),
      });
    }

    case ADJUST_GET_UA_SPEND.REQUEST: {
      return stateSet(state, {
        "requestStatus.getData": getRequestStatus(),
      });
    }

    case ADJUST_GET_UA_SPEND.RESPONSE: {
      // The base reducer listens for this event as well
      return stateSet(state, {
        "requestStatus.getData": getResponseStatus(),
      });
    }

    case ADJUST_GET_UA_SPEND.ERROR: {
      return stateSet(state, {
        "requestStatus.getData": getErrorStatus(),
        "status": checkNetworkError(action) ? CONNECTOR_ERROR : state.status,
      });
    }

    case ADJUST_TEST_CREDENTIALS_ERRORS.CLEAR: {
      return stateSet(state, {
        "requestStatus.testCredentials": setStatusDefaults(),
        "requestStatus.saveCredentials": setStatusDefaults(),
      });
    }

    case ADJUST_CLEAR_CREDENTIALS_STATUS.EVENT: {
      return stateSet(state, {
        "credentials.status": "default",
      });
    }

    default:
      return state;
  }
};

export default adjustReducer;
