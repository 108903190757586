import { configureConsts, configureActions } from "@utils/redux";

export const LOGIN = configureConsts("LOGIN");
export const USER_SUMMARY = configureConsts("USER_SUMMARY");
export const FEATURE_FLAGS = configureConsts("FEATURE_FLAGS");
export const FINISH_REGISTRATION = configureConsts("FINISH_REGISTRATION");
export const GET_ONBOARDING_PROGRESS = configureConsts("GET_ONBOARDING_PROGRESS");
export const SAVE_ONBOARDING_PROGRESS = configureConsts("SAVE_ONBOARDING_PROGRESS");
export const REQUEST_CAPITAL_ONBOARDING = configureConsts("REQUEST_CAPITAL_ONBOARDING");
export const FINISH_ONBOARDING = configureConsts("FINISH_ONBOARDING");
export const DISMISS_ONBOARDING_CARD = configureConsts("DISMISS_ONBOARDING_CARD");
export const PARTNER_BRANDING = configureConsts("PARTNER_BRANDING");
export const AUTH0_UPDATE = configureConsts("AUTH0_UPDATE");
export const SEND_FEEDBACK = configureConsts("SEND_FEEDBACK");
export const CAN_UPDATE_ONBOARDING_PROCESS = configureConsts("CAN_UPDATE_ONBOARDING_PROCESS");
export const SUGGESTED_INTEGRATION_COMPLETED = configureConsts("SUGGESTED_INTEGRATION_COMPLETED");
export const SET_ONBOARDING_SUPERPOWERS = configureConsts("SET_ONBOARDING_SUPERPOWERS");
export const SET_DEV_ACCESS_TOKEN = configureConsts("SET_DEV_ACCESS_TOKEN");

const sessionActions = {
  login: configureActions(LOGIN, {
    request: (username, password) => ({
      type: LOGIN.REQUEST,
      username,
      password,
    }),
  }),

  userSummary: configureActions(USER_SUMMARY, {
    request: () => ({
      type: USER_SUMMARY.REQUEST,
    }),
  }),

  featureFlags: configureActions(FEATURE_FLAGS),

  finishRegistration: configureActions(FINISH_REGISTRATION, {
    request: (user) => ({
      type: FINISH_REGISTRATION.REQUEST,
      user,
    }),
  }),

  auth0LoginResponse: (token, user) => ({
    type: LOGIN.REQUEST,
    token,
    user,
  }),

  setAccessToken: (token) => ({
    type: SET_DEV_ACCESS_TOKEN.SET,
    payload: token,
  }),

  setCanUpdateOnboardingProcess: (value) => ({
    type: CAN_UPDATE_ONBOARDING_PROCESS.SET,
    payload: value,
  }),

  getOnboardingProgress: configureActions(GET_ONBOARDING_PROGRESS),

  saveOnboardingProgress: configureActions(SAVE_ONBOARDING_PROGRESS, {
    request: (onboarding) => ({
      type: SAVE_ONBOARDING_PROGRESS.REQUEST,
      onboarding,
    }),
  }),

  requestCapitalOnboarding: configureActions(REQUEST_CAPITAL_ONBOARDING, {
    request: (amount) => ({
      type: REQUEST_CAPITAL_ONBOARDING.REQUEST,
      amount,
    }),
  }),

  finishOnboarding: configureActions(FINISH_ONBOARDING, {
    request: (onboarding) => ({
      type: FINISH_ONBOARDING.REQUEST,
      onboarding,
    }),
  }),

  dismissOnboardingCard: configureActions(DISMISS_ONBOARDING_CARD),

  partnerBranding: configureActions(PARTNER_BRANDING),

  auth0Update: configureActions(AUTH0_UPDATE),

  sendFeedback: configureActions(SEND_FEEDBACK, {
    request: (feedback, userEmail) => ({
      type: SEND_FEEDBACK.REQUEST,
      feedback,
      userEmail,
    }),
  }),

  setOnboardingSuperpowers: configureActions(SET_ONBOARDING_SUPERPOWERS, {
    request: (superpowers) => ({
      type: SET_ONBOARDING_SUPERPOWERS.REQUEST,
      superpowers,
    }),
  }),

  setSuggestedIntegrationsCompleted: (value) => ({
    type: SUGGESTED_INTEGRATION_COMPLETED.SET,
    payload: value,
  }),
};

export default sessionActions;
