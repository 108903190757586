import React from "react";
import { Box, Divider, HStack, Heading, Text } from "@chakra-ui/react";

export const PageHeader = ({ header = "", subheader = "", children }) => {
  // Other platforms use callouts as well, but only the ua spend ones follow the same structure
  // to where a reusable component was warranted.
  return (
    <>
      <Box pt="16px" id="page-header">
        <HStack justifyContent={"space-between"} alignItems={"end"}>
          <Box>
            <Heading size="headline" fontWeight={600} mb="8px">
              {header}
            </Heading>
            {subheader && <Text size="small">{subheader}</Text>}
          </Box>
          {children}
        </HStack>
      </Box>
      <Divider borderBottomWidth={0} height={"1px"} bg={"dark.300"} />
    </>
  );
};
