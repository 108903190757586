import { currencyFormat } from "@utils/currency";

export const getYAxisSteps = ({ highest, withNeg = false, shorthand = 10000, stepCount = null, zoomInScale = 0 }) => {
  let inc = 0;
  let breakpoints = [100000, 10000, 1000, 500, 200, 100, 20, 10];
  if (withNeg) breakpoints = [150000, 60000, 30000, 15000, 6000, 3000, 1500, 600, 300, 150, 60, 30, 15, 5];

  for (let i = 0; i < breakpoints.length; ++i) {
    if (highest > breakpoints[i]) {
      inc = breakpoints[i];
      break;
    }
  }
  // Lowest possible increment
  if (!inc) inc = 5;
  // Give a little more space to the charts in some instances of not "whole" numbers
  // highest = 55000 / 10000 = 5.5 -> 6 -> 60000
  let nearestInc = Math.ceil(highest / inc) * inc || inc;

  let step = null;
  let steps = null;
  let halfStepCount = null;

  // Apply zoom if it's greater than 0
  if (zoomInScale > 0) {
    nearestInc = nearestInc / zoomInScale;
  }

  if (!withNeg) {
    stepCount = stepCount ? stepCount : 5;
    step = nearestInc / stepCount;
    steps = [];
    for (let i = 0; i < stepCount + 1; ++i) {
      steps.push(step * i);
    }
  } else {
    stepCount = stepCount ? stepCount : 7;
    halfStepCount = Math.floor(stepCount / 2);
    step = Math.ceil(nearestInc / halfStepCount);
    nearestInc = step * halfStepCount;
    steps = [];
    for (let i = 1; i <= stepCount / 2; ++i) {
      steps.push(step * i);
    }
    const reversedSteps = [...steps].reverse();
    steps = [...reversedSteps, 0, ...steps];
  }

  return {
    highest: nearestInc,
    steps: steps.map((step, index) => {
      const value = currencyFormat(step, {
        shorthand: shorthand,
      });

      // number of yaxis points - 1 (the middle step that gets a transform of 0)
      const offSet = withNeg ? 100 / 6 : 100 / 5;
      // const offSet = withNeg ? 100 / 8 : 100 / 5;

      return {
        value: step,
        display: index < halfStepCount && withNeg ? `-${value}` : value,
        chartLineOffsetPercent: 50 - offSet * index,
      };
    }),
  };
};
