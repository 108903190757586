import { Box, FormControl, FormLabel, Icon, Input, Text, Textarea, Tooltip, VStack } from "@chakra-ui/react";
import { FileDrop } from "@common/upload/FileDrop";
import { useHandleImageFile } from "@src/utils/hooks/useHandleImageFile";
import { Controller, useFormContext } from "react-hook-form";
import { ReactComponent as UploadImageIcon } from "@assets/img/icons/common/image_upload_v2.svg";
import { ReactComponent as TooltipIcon } from "@assets/img/icons/common/tooltip.svg";

export function ManualForm() {
  const { register, control, getValues } = useFormContext();
  const { handleOnImageChange, imageURL } = useHandleImageFile(getValues("instructionImageUrl"));

  return (
    <>
      <FormControl>
        <Tooltip placement="right" label="Brand your player ID to match the verbiage you use in-game">
          <FormLabel fontSize={"14px"} w="fit-content">
            ID Label
            <Icon as={TooltipIcon} w="16px" h="16px" ml="4px" />
          </FormLabel>
        </Tooltip>

        <Input {...register("idLabel")} />
      </FormControl>
      <FormControl>
        <Tooltip
          placement="right"
          label="Add text to help your players find their player ID. Player must click “Can't find your ID?” to view this text."
        >
          <FormLabel fontSize={"14px"} w="fit-content">
            Instructions to customer
            <Icon as={TooltipIcon} w="16px" h="16px" ml="4px" />
          </FormLabel>
        </Tooltip>
        <Textarea {...register("instructionText")} variant={"builder"} h="72px" placeholder="Optional" />
      </FormControl>
      <FormControl>
        <Tooltip
          placement="right"
          label="Add an image to help your players find the information to use this specific method. Recommend a tall vertical image that is at least 300px wide with a max file size of 3 mb"
        >
          <FormLabel fontSize={"14px"} w="fit-content">
            Add image{" "}
            <Text color="textSubtext.400" size="small" as={"span"}>
              (Optional)
            </Text>
            <Icon as={TooltipIcon} w="16px" h="16px" ml="4px" />
          </FormLabel>
        </Tooltip>
        <VStack
          alignItems={"center"}
          justifyContent={"center"}
          border="2px dashed"
          borderColor={"dark.100"}
          rounded={"8px"}
          bg="dark.400"
          py="24px"
          px="20px"
          w={"full"}
          pos={"relative"}
          h="180px"
          bgSize={"cover"}
          bgImage={imageURL ? `url(${imageURL})` : ""}
          bgPos={"center"}
        >
          <Controller
            name="instructionImageUrl"
            control={control}
            render={({ field: { onChange } }) => (
              <FileDrop onChange={(e) => onChange(handleOnImageChange(e))} acceptedFileTypes={"images/*"}>
                {imageURL ? (
                  <Box py="16px" px="24px" bg="rgba(33, 33, 38, 0.78)" borderRadius={"8px"}>
                    <Text color="textWhite.400" fontWeight={500} size="regular">
                      Change image
                    </Text>
                  </Box>
                ) : (
                  <VStack gap="16px" my="auto" alignItems={"center"}>
                    <Icon w="62px" h="62px" as={UploadImageIcon} color={"textSubtext.400"} />
                    <Text>Click to upload PNG, JPEG, or drag and drop here</Text>
                  </VStack>
                )}
              </FileDrop>
            )}
          />
        </VStack>
      </FormControl>
    </>
  );
}
