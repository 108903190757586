import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";

import { TitleSmallPlus, BodySmallAlt, BodySmallAltPlus } from "@styled";
import { StyledBankingCategory } from "./StyledIntegrationCategory";
import BankingStateCard from "./integration-state/BankingStateCard";
import { FullscreenLoader } from "@components/common/loaders";
import StateButton from "@components/common/buttons/state-button/StateButton";
import DismissableBanner from "@common/banners/DismissableBanner";
import ConnectionLoadingCard from "@common/cards/connection-loading/ConnectionLoadingCard";

import symbols from "@utils/symbols";

import { sMatch, NOT_CONNECTED, DRAFT_CONNECTION, PULLING_DATA } from "@utils/status";
import text from "@utils/text";
import { useNavigate } from "react-router-dom";
import { hide, show } from "@redux/integrations/plaid/prePlaidModalSlice";

import { useRequestPlaidLink } from "@utils/integrations/banking/useRequestPlaidLink";
import isEmpty from "lodash/isEmpty";

const BankingCategory = ({ category }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const plaid = useSelector((state) => state.integrations.plaid);
  const [showSpinner, setShowSpinner] = useState(false);

  const { name, description } = category;

  let { requestStatus, data: plaidData, status: plaidStatus } = plaid;

  let { institutions } = plaidData;

  const hasIntegrations = institutions?.length >= 1;
  const categoryDescriptionClass = classnames("category-description", {
    "add-button-showing": hasIntegrations,
  });

  const toDocuments = () => navigate("/documents");

  const [open, ready, plaidConfig, getPlaidLink] = useRequestPlaidLink();

  const bankingHandlers = {
    NEEDS_RECONNECTION: (id) => {
      setShowSpinner(true);
      getPlaidLink(id);
    },
    DRAFT_CONNECTION: () => {
      setShowSpinner(true);
      getPlaidLink();
    },
  };

  useEffect(() => {
    if (!isEmpty(plaidConfig.token) && ready) {
      open();
    }
    setShowSpinner(false);
  }, [plaidConfig, ready, open]);

  useEffect(() => {
    // We shouldn't have to hide the spinner on complete since the user will
    // navigated away from the page.
    if (plaid.requestStatus.connect.pending) {
      setShowSpinner(true);
    }
    if (plaid.requestStatus.connect.error) {
      setShowSpinner(false);
    }
  }, [plaid.requestStatus.connect]);

  return (
    <Fragment>
      {showSpinner && <FullscreenLoader />}
      <StyledBankingCategory data-test-id="category-bank" data-scroll-target={"integration-banking-target"}>
        <div className="integration-category-header">
          <TitleSmallPlus className="category-title">{name}</TitleSmallPlus>
          {hasIntegrations || sMatch(plaid, PULLING_DATA) ? (
            <BodySmallAlt className={categoryDescriptionClass}>{description}</BodySmallAlt>
          ) : (
            <BodySmallAlt>
              {text.t("integration_bank_text")} {text.t("cant_find_your_bank")}
              <BodySmallAltPlus onClick={toDocuments} className="red-label">
                {" "}
                {text.t("upload_statements")}
              </BodySmallAltPlus>
            </BodySmallAlt>
          )}

          {(hasIntegrations || sMatch(plaid, PULLING_DATA)) && (
            <div className="add-account">
              <StateButton
                state="default"
                label="+ Add"
                onClick={() => {
                  dispatch(
                    show({
                      onUploadStatements: () => {
                        dispatch(hide());
                        toDocuments();
                      },
                    }),
                  );
                }}
              />
            </div>
          )}

          {sMatch(plaid, PULLING_DATA) && (
            <div className="pulling-banner">
              <DismissableBanner
                content={
                  <BodySmallAltPlus>
                    {symbols.pizza} Congrats! You've successfully connected your Bank account(s)! Within several
                    minutes, you'll see your bank(s') details. Meanwhile you can connect more bank accounts.
                  </BodySmallAltPlus>
                }
              />
            </div>
          )}
        </div>

        <div className="integration-category-container">
          {requestStatus.status.pending && (
            <>
              <ConnectionLoadingCard />
              <ConnectionLoadingCard />
            </>
          )}

          {(() => {
            let basePlatform = {
              name: "Bank Account",
              logo: require("@assets/img/icons/common/bankAccount.svg").default,
              status: NOT_CONNECTED,
            };

            if (!hasIntegrations && requestStatus.status.completed) {
              // For the Banking platform, there will only be a generic banking card if there
              // are no codat banking integrations. If there are, then there will be cards
              // generated for each banking integration and a separate "add" button for adding
              // more at a later time
              basePlatform.status = NOT_CONNECTED;
              const onClickEnabled = sMatch(plaid, [DRAFT_CONNECTION, NOT_CONNECTED]);
              return (
                <BankingStateCard
                  status={plaidStatus || NOT_CONNECTED}
                  platform={basePlatform}
                  handlers={{
                    onClick: () => {
                      if (onClickEnabled)
                        dispatch(
                          show({
                            onUploadStatements: () => {
                              dispatch(hide());
                              toDocuments();
                            },
                          }),
                        );
                    },
                  }}
                />
              );
            }

            if (hasIntegrations) {
              return (
                <>
                  {institutions.map((bankInstitution) => {
                    const { id, institution, status, lastSyncedAt = "" } = bankInstitution;

                    return (
                      <BankingStateCard
                        key={`key_${id}_${institution?.name}`}
                        status={status}
                        platform={{
                          ...bankInstitution,
                          lastUpdated: lastSyncedAt,
                          name: institution?.name || "Bank Institution",
                          logo: basePlatform.logo,
                          status: status,
                        }}
                        handlers={{
                          onClick: () => {
                            if (bankingHandlers[status]) {
                              bankingHandlers[status](id);
                            }
                          },
                        }}
                      />
                    );
                  })}
                </>
              );
            }
          })()}
        </div>
      </StyledBankingCategory>
    </Fragment>
  );
};

export default BankingCategory;
