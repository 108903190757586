import { snakeToCamelCase } from "@src/utils/string";
import { VERIFYING_CONNECTION, CONNECTED } from "@utils/status";

const serverIntegrationSnakeToCamelCase = (integrationsSuggested = []) => {
  return integrationsSuggested.map((suggestion) => snakeToCamelCase(suggestion));
};

const isStatusConnectedOrInProgress = (integration) => {
  return integration.status === CONNECTED || integration.status === VERIFYING_CONNECTION;
};

// filter integrations by suggested integrations
const filterIntegration = (integrations, integrationsSuggested) => {
  const filteredIntegrations = {};
  for (const key of integrationsSuggested) {
    if (integrations[key]) {
      filteredIntegrations[key] = integrations[key];
    }
  }
  return filteredIntegrations;
};

// check if an integration have status verifying_connection or connected
export const isAnyIntegrationActive = ({ integrationsStatus = {}, integrationsSuggested = [] }) => {
  const suggested = serverIntegrationSnakeToCamelCase(integrationsSuggested);
  const filteredIntegrations = filterIntegration(integrationsStatus, suggested);

  for (const key in filteredIntegrations) {
    if (Object.hasOwn(filteredIntegrations, key)) {
      const integration = filteredIntegrations[key];
      if (isStatusConnectedOrInProgress(integration)) {
        return true;
      }
    }
  }
  return false;
};
