import React from "react";
import { StyledAvatarInitials } from "@components/styled";
import * as moment from "moment";
import { extendMoment } from "moment-range";
const momentRange = extendMoment(moment);

export const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

/**
 * Parses UTC month and year from date
 * @param date Date to parse
 * @returns {string} Default SANLO Month and year parsing
 */
export const monthAndYear = (date) => {
  const monthName = months[date.getUTCMonth()];
  const yearName = date.getUTCFullYear();
  return `${monthName} ${yearName}`;
};

export const formatDate = (date, forceFirstOfMonth = true) => {
  let d = new Date(date),
    year = d.getFullYear(),
    month = ("0" + (d.getMonth() + 1)).slice(-2),
    day = forceFirstOfMonth ? "01" : ("0" + d.getDate()).slice(-2);

  return [year, month, day].join("-");
};

export const userInitials = (user, isSidebar) => (
  <StyledAvatarInitials className={`avatar-${getRandomColor(user)} ` + (isSidebar ? "sidebar-avatar" : "")}>
    {user.firstName ? user.firstName.charAt(0) + user.lastName.charAt(0) : "SU"}
  </StyledAvatarInitials>
);

const getRandomColor = (user) => {
  let initials;
  initials = user.firstName ? user.firstName.charAt(0) : "";
  initials.concat(user.lastName ? user.lastName.charAt(0) : "");
  const charIndex = `${initials.length > 0 ? initials : "SU"}`.charCodeAt(0) - 65;
  return charIndex % 3;
};

export const getRangeOfDatesMoment = (startDate, endDate) => {
  const currentDate = moment.utc(startDate).startOf("day").add(moment.duration(12, "hours"));
  const lastDate = moment.utc(endDate).startOf("day").add(moment.duration(12, "hours"));

  let range = momentRange.range(currentDate, lastDate);
  let dateArray = [];

  for (let day of range.by("days")) {
    dateArray.push(day._d);
  }
  return dateArray;
};

export const getMomentLocalT0 = (date) => {
  return moment.utc(date).local().startOf("day")._d;
};

export const dateInRange = (date, minDate, maxDate) => {
  return moment(date).isBetween(minDate, maxDate, null, "[]");
};

export const capitalizeString = (string) => {
  const firstLetter = string[0].toUpperCase();
  const rest = string.substring(1).toLowerCase();
  return firstLetter + rest;
};

export const isValidEmail = (email) => {
  return email?.match(/^[+a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$/i);
};

export const formatDateSince = (date) => {
  if (!date) return "";
  return moment.utc(date).local().fromNow();
};

export const formatReadableDate = (date) => {
  if (!date) return "";
  return new Date(date).toLocaleDateString("en-us", { year: "numeric", month: "short", day: "numeric" });
};

export const isDateExpired = (date) => {
  if (!date) return false;
  const today = moment.utc();
  return moment.utc(date) < today;
};

export const dayWithSuffix = (day) => {
  if (day >= 11 && day <= 13) {
    return `${day}th`;
  }
  switch (day % 10) {
    case 1:
      return `${day}st`;
    case 2:
      return `${day}nd`;
    case 3:
      return `${day}rd`;
    default:
      return `${day}th`;
  }
};
