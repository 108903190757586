import React, { useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";

import { AbsoluteAllCSS } from "@styled";

const StyledOuter = styled.div`
  ${AbsoluteAllCSS};
`;

const StyledShadowUpper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(0deg, rgba(33, 33, 38, 0) -25%, #212126 100%);
  height: 32px;
  z-index: 100;
  opacity: 0;

  ${({ isVisible }) =>
    isVisible &&
    `
    opacity: 1;
  `};
`;

const StyledInner = styled.div`
  ${AbsoluteAllCSS};
  overflow-y: scroll;

  ${({ seeBar }) =>
    seeBar &&
    `&::-webkit-scrollbar {
    width: 20px;
    margin-left: 10px;
    }
    &::-webkit-scrollbar-thumb {
      padding: 0 6px;
      border-right: 6px solid transparent;
      border-left: 6px solid transparent;
      background-color: rgba(65, 65, 69, 1);
      background-clip: padding-box;
      border-radius: 20px;
    }opacity: 1;
`};
`;

const StyledShadowLower = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(180deg, rgba(33, 33, 38, 0) -25%, #212126 100%);
  height: 32px;
  z-index: 100;
  opacity: 0;

  ${({ isVisible }) =>
    isVisible &&
    `
    opacity: 1;
  `};
`;

const StyledContent = styled.div`
  position: relative;
`;

/*
  More shadow styles (or allow passing in a shadow style), it changes
  throughout the app in different spots.
*/

export const SanloScroll = ({ children, withShadow = false, seeBar = false }) => {
  const [isUpperShadowVisible, setIsUpperShadowVisible] = useState(false);
  const [isLowerShadowVisible, setIsLowerShadowVisible] = useState(false);

  const outerRef = useRef(null);
  const innerRef = useRef(null);
  const contentRef = useRef(null);

  useLayoutEffect(() => {
    if (withShadow) {
      const heightDiff = contentRef.current.clientHeight - innerRef.current.clientHeight;
      if (heightDiff) setIsLowerShadowVisible(true);
    }
  }, [withShadow]);

  const handleOnScroll = (e) => {
    if (withShadow) {
      const scrollTop = e.currentTarget.scrollTop;
      const heightDiff = contentRef.current.clientHeight - innerRef.current.clientHeight;
      setIsUpperShadowVisible(scrollTop !== 0);
      setIsLowerShadowVisible(scrollTop !== heightDiff);
    }
  };

  return (
    <StyledOuter ref={outerRef} data-tag="shadow-scroll-outer">
      {withShadow && <StyledShadowUpper isVisible={isUpperShadowVisible} />}
      <StyledInner seeBar={seeBar} ref={innerRef} data-tag="shadow-scroll-inner" onScroll={handleOnScroll}>
        <StyledContent ref={contentRef} data-tag="shadow-scroll-content">
          {children}
        </StyledContent>
      </StyledInner>
      {withShadow && <StyledShadowLower isVisible={isLowerShadowVisible} />}
    </StyledOuter>
  );
};
