import { VStack } from "@chakra-ui/react";
import { SectionWrapper } from "@src/utils/webshop/section";

import { Controller, useFormContext, useWatch } from "react-hook-form";
import { checkoutBuilderThunks, checkoutSelector } from "@redux/webshops/checkout-builder/checkoutSlice";
import { useDispatch, useSelector } from "react-redux";
import { StylePicker, ColorPicker } from "@pages/webshops/webshop/builder/components/StylePicker";
import { useEffect } from "react";
import { generateFormWithMultipleFilePayloads } from "@src/utils/webshop";
import { useParams } from "react-router-dom";
import { useAutoSave } from "@src/utils/hooks/useAutoSave";

export function BuilderProperties() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { template } = useSelector(checkoutSelector);
  const updateCheckoutTemplate = useAutoSave(
    (data) => dispatch(checkoutBuilderThunks.updateCheckoutTemplate({ webshopId: id, data })),
    1500,
  );

  const { control, formState } = useFormContext();

  const values = useWatch({ control });
  const { primary, secondary, paymentSectionBgColor, summarySectionBgColor } = values;

  useEffect(() => {
    if (!formState.isDirty) return;
    const payload = {
      colorPalette: {
        primary: {
          type: "COLOR",
          color: primary,
        },
        secondary: {
          type: "COLOR",
          color: secondary,
        },
      },
      paymentSectionBgColor: paymentSectionBgColor,
      summarySectionBgColor: summarySectionBgColor,
    };
    updateCheckoutTemplate(payload);
  }, [formState.isDirty, updateCheckoutTemplate, primary, secondary, paymentSectionBgColor, summarySectionBgColor]);

  async function handleAddBrandingFile(image) {
    const { requestForm } = await generateFormWithMultipleFilePayloads({
      filePropertyName: "file",
      files: [image.file],
      dataPropertyName: "data",
      data: {
        type: "GLOBAL",
        target: "MAIN",
        overlay: image.overlay,
      },
    });

    dispatch(checkoutBuilderThunks.addBrandingFile({ webshopId: id, data: requestForm }));
  }

  return (
    <VStack gap={0} alignItems="stretch" as="form">
      <SectionWrapper
        title="Colors"
        onReset={() => {
          dispatch(checkoutBuilderThunks.resetCheckoutTemplate({ webshopId: id }));
        }}
      >
        <Controller
          name="primary"
          control={control}
          render={({ field: { onChange, value } }) => (
            <ColorPicker title="Primary button" color={value} onColorChange={onChange} />
          )}
        />
        <Controller
          name="secondary"
          control={control}
          render={({ field: { onChange, value } }) => (
            <ColorPicker title="Secondary" color={value} onColorChange={onChange} />
          )}
        />
        <Controller
          name="paymentSectionBgColor"
          control={control}
          render={({ field: { onChange, value } }) => (
            <ColorPicker title="Background - payments" color={value} onColorChange={onChange} />
          )}
        />
        <Controller
          name="summarySectionBgColor"
          control={control}
          render={({ field: { onChange, value } }) => (
            <ColorPicker title="Background - summary" color={value} onColorChange={onChange} />
          )}
        />
      </SectionWrapper>
      <SectionWrapper title="Branding" pt="0px" style={{ paddingTop: 0 }}>
        <StylePicker
          title="Add Asset"
          imageOnly
          image
          initialImage={template?.files?.filter((file) => file.name === "MAIN")[0]?.url}
          onImageChange={(image) => handleAddBrandingFile(image)}
          onImageRemove={() => dispatch(checkoutBuilderThunks.deleteBrandingFile({ webshopId: id }))}
        />
      </SectionWrapper>
    </VStack>
  );
}
