import styled from "styled-components";

const StyledAuthCard = styled.div`
  & {
    width: ${({ jumbo }) => (jumbo ? "532px" : "480px")};
    background-color: ${({ theme }) => theme.grayscale10};
    padding: 32px 40px 40px 40px;
    border-radius: 12px;
    box-shadow: 0 0 48px 0 rgba(0, 0, 0, 0.4);

    .auth-card-header {
      margin-bottom: 24px;
      &.header-only {
        margin-bottom: 0;
      }

      .auth-card-disclaimer {
        margin-bottom: 24px;
      }

      .auth-card-title {
        color: ${({ theme }) => theme.textSeashellWhite};
      }

      .auth-card-description {
        margin-top: 8px;
        color: ${({ theme }) => theme.textGray};

        .highlighted-description {
          font-weight: 500;
          color: ${({ theme }) => theme.textSeashellWhite};
        }

        .auth-description-highlighted {
          color: ${({ theme }) => theme.colorRed};
          cursor: pointer;

          :hover {
            text-decoration: underline;
          }
        }
      }
    }

    .auth-card-content {
      .auth-card-note {
        background-color: rgba(245, 54, 92, 0.1);
        color: ${({ theme }) => theme.colorRed};
        padding: 0.5rem 0.75rem;
        border-radius: 0.5rem;
        margin-bottom: 24px;
      }
    }

    .auth-card-footer {
      color: ${({ theme }) => theme.textSubtext};

      .auth-label-primary {
        margin-top: 24px;
        color: ${({ theme }) => theme.textGray};
      }

      .auth-footer-highlighted {
        cursor: pointer;
        color: ${({ theme }) => theme.colorRed};
        font-weight: 500;
      }

      .centered-footer-text {
        text-align: center;
      }

      .auth-card-signup {
        margin: 24px 0;
      }
    }

    .auth-label-button {
      color: ${({ theme }) => theme.colorRed};
      cursor: pointer;

      :hover {
        text-decoration: underline;
      }
    }

    .auth-card-divider {
      height: 0;
      background-color: transparent;
      border: 1px solid ${({ theme }) => theme.grayscale40};
    }

    .auth-card-columns-wrapper {
      .auth-card-row {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 24px;

        > * {
          width: 48%;
        }
      }

      .auth-card-columns-hint {
        margin-top: 8px;
        display: flex;
        justify-content: flex-end;
        color: ${({ theme }) => theme.textGray};
      }
    }
  }
`;

export default StyledAuthCard;
