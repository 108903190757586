import moment from "moment";
import { getRangeOfDatesMoment } from "@utils/format";
import { alphaTrim, copy } from "@utils/general";

const generatePeriodId = (period) => {
  const { periodId, fiscalStartDate, fiscalToDate, totalPeriodEarnings } = period;

  if (periodId) return periodId;

  let newPeriodId = alphaTrim(`${fiscalStartDate}-${fiscalToDate}-${totalPeriodEarnings}`);
  return newPeriodId;
};

const parsePeriod = (period, chartData) => {
  const {
    periodId,
    earningsByGame = [],
    fiscalStartDate,
    fiscalToDate,
    payDate,
    dataSourceStatus,
    periodPayDates,
    totalPeriodEarnings,
  } = period;

  const today = moment().startOf("day").format("YYYY-MM-DD");
  const periodRange = getRangeOfDatesMoment(fiscalStartDate, fiscalToDate);
  const isCurrentPeriod = moment().startOf("day").isBetween(fiscalStartDate, fiscalToDate, "days", "[]");
  const isFuturePeriod = today < fiscalStartDate;

  const daysWithData = {};
  let noEarningsToShow = true;

  for (let ebgIndex = 0; ebgIndex < earningsByGame.length; ++ebgIndex) {
    const game = earningsByGame[ebgIndex];
    const { earningsByDay = {} } = game;
    const earningsByDayKeys = Object.keys(earningsByDay);
    if (earningsByDayKeys.length) noEarningsToShow = false;

    for (let ebdIndex = 0; ebdIndex < earningsByDayKeys.length; ++ebdIndex) {
      const day = earningsByDayKeys[ebdIndex];
      daysWithData[day] = true;
    }
  }

  for (let i = 0; i < periodRange.length; ++i) {
    const date = periodRange[i];
    const parsedDate = moment(date).format("YYYY-MM-DD");
    const startOfMonth = moment(date).clone().startOf("month").format("YYYY-MM-DD");
    const endOfMonth = moment(date).clone().endOf("month").format("YYYY-MM-DD");

    // Initialize the day object
    if (!chartData[parsedDate]) {
      chartData[parsedDate] = {
        date: parsedDate,
        monthAbbr: moment(date).format("MMM"),
        dayNum: parseInt(moment(date).format("D"), 10),
        isToday: parsedDate === today,
        isBeforeToday: parsedDate < today,
        isAfterToday: parsedDate > today,
        isPayDate: false,
        isFiscalStart: false,
        isFiscalEnd: false,
        isMonthStart: parsedDate === startOfMonth,
        isMonthEnd: parsedDate === endOfMonth,
        isComplete: Boolean(daysWithData[parsedDate]),
        period: {
          id: periodId,
          earnings: totalPeriodEarnings,
          total: totalPeriodEarnings,
          fiscalStartDate,
          fiscalToDate,
          payDate,
          isCurrent: isCurrentPeriod,
          isFuture: isFuturePeriod,
          dataSourceStatus: dataSourceStatus || "ONGOING",
          periodPayDates: periodPayDates,
          noEarningsToShow,
        },
        games: {
          byName: {},
          byId: {},
          order: [],
        },
        totalGameEarnings: 0,
      };
    }
  }

  for (let ebgIndex = 0; ebgIndex < earningsByGame.length; ++ebgIndex) {
    const game = earningsByGame[ebgIndex];
    if (!game.gameId) {
      game.gameId = alphaTrim(`${game.gameName}-${game.platformId}`);
    }
    const { earningsByDay = {}, gameName, gameIcon, gameId } = game;

    const earningsByDayKeys = Object.keys(earningsByDay);
    for (let ebdIndex = 0; ebdIndex < earningsByDayKeys.length; ++ebdIndex) {
      const day = earningsByDayKeys[ebdIndex];
      const earning = earningsByDay[day];
      if (!earning || !chartData[day]) continue;
      chartData[day].totalGameEarnings += earning;

      const gamePayload = {
        name: gameName,
        icon: gameIcon,
        earning: earning,
        gameId,
        gameName,
      };

      // Add the game
      chartData[day].games.byName[gamePayload.name] = gamePayload;
      chartData[day].games.byId[gamePayload.gameId] = gamePayload;
      chartData[day].games.order.push(gamePayload.gameId);
    }
  }
};

export const parseEarningsData = (earningsData = []) => {
  // Avoid mutation error, this needs to be redone and moved to a higher level
  // preferably already parsed and in the store.
  earningsData = copy(earningsData);
  if (!earningsData || !Array.isArray(earningsData)) earningsData = [];

  let chartData = {};
  let periods = {};
  let periodsDataSourceStatusMap = {};
  let isDataEmpty = true;

  for (let edIndex = 0; edIndex < earningsData.length; ++edIndex) {
    const period = earningsData[edIndex] || {};
    const { periodPayDates = [] } = period;
    period.periodId = generatePeriodId(period);

    parsePeriod(period, chartData);

    // Add the period to the periods array
    periods[period.periodId] = period;
    periods[period.periodId].isLoaded = true;

    // Go through the periodPaydates and add periods
    // for the ones we have paydates for but arent in the actual data
    if (periodPayDates && periodPayDates.length) {
      for (let pdIndex = 0; pdIndex < periodPayDates.length; ++pdIndex) {
        const payDateData = periodPayDates[pdIndex] || {};

        const { fiscalPeriodId, fiscalStartDate, fiscalToDate, totalPeriodEarnings, dataSourceStatus } = payDateData;

        // Make a map of all payDates to show specific tooltip information
        // depending on dataSourceStatus
        periodsDataSourceStatusMap[fiscalPeriodId] = dataSourceStatus;

        if (periods[fiscalPeriodId]) continue;

        periods[fiscalPeriodId] = {
          periodId: fiscalPeriodId,
          fiscalStartDate: fiscalStartDate,
          fiscalToDate: fiscalToDate,
          totalPeriodEarnings: totalPeriodEarnings,
          isLoaded: false,
        };
      }
    }
  }

  // Add periods data source status for all periods
  earningsData.periodsDataSourceStatusMap = periodsDataSourceStatusMap;

  // maybe not the most efficient, but chartData is now populated
  // and we can easily add paydate and fiscal data to each day
  earningsData.forEach((period) => {
    period.periodId = generatePeriodId(period);

    const {
      payDate,
      fiscalStartDate,
      fiscalToDate,
      totalPeriodEarnings,
      periodPayDates = [],
      earningsByGame = [],
    } = period;

    if (earningsByGame.length) isDataEmpty = false;

    // Add in the periods paydate
    if (chartData[payDate]) {
      chartData[payDate].isPayDate = true;
      chartData[payDate].payDatePeriod = {
        id: generatePeriodId(period),
        fiscalStartDate,
        fiscalToDate,
        totalPeriodEarnings,
      };
    }
    // Mark the period fiscal start and end dates
    if (chartData[fiscalStartDate]) {
      chartData[fiscalStartDate].isFiscalStart = true;
    }
    if (chartData[fiscalToDate]) {
      chartData[fiscalToDate].isFiscalEnd = true;
    }
    // Go through the list of paydates that occur during the period and
    // add its data. We don't have to check for existing data, we can just
    // blindly override the paydate data there
    if (periodPayDates) {
      periodPayDates.forEach((payDateData) => {
        if (!chartData[payDateData.payDate]) return;
        chartData[payDateData.payDate].isPayDate = true;
        chartData[payDateData.payDate].payDatePeriod = {
          id: payDateData.fiscalPeriodId,
          fiscalStartDate: payDateData.fiscalStartDate,
          fiscalToDate: payDateData.fiscalToDate,
          totalPeriodEarnings: payDateData.totalPeriodEarnings,
        };
      });
    }
  });

  // They should be ordered already, this is just playing it safe
  const orderedKeys = Object.keys(chartData).sort();

  // Create an ordered list of the periods. This will include the periods
  // only referenced in periodPayDates
  const orderedPeriods = Object.values(periods);
  // There will be way less periods than days so I'm not concerned about the order field being full objects instead of IDs
  orderedPeriods.sort((a, b) =>
    a.fiscalStartDate > b.fiscalStartDate ? 1 : b.fiscalStartDate > a.fiscalStartDate ? -1 : 0,
  );

  return {
    map: chartData,
    order: orderedKeys,
    periods: {
      map: periods,
      order: orderedPeriods,
    },
    isEmpty: isDataEmpty,
  };
};
