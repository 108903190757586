import React, { useState } from "react";

import StyledOnboardingStep2 from "./StyledOnboardingStep2";
import { SanloCheckbox } from "@inputs";
import JumboPicker from "@components/onboarding/jumbo-picker/JumboPicker";

const options = [
  { key: "LESS_25", label: "Less than $25k" },
  { key: "BETWEEN_25_100", label: "$25k - $100k" },
  { key: "MORE_100", label: "$100k+" },
];

const OnboardingStep2 = ({ monthlyRevenue, onMonthlyRevenueChange }) => {
  const [checked, setChecked] = useState(monthlyRevenue === "NO_REVENUE");
  const [lastPicked, setLastPicked] = useState(options.find((o) => o.key === monthlyRevenue));

  const handleClick = (index) => {
    onMonthlyRevenueChange(options[index].key);
    setLastPicked(options[index]);
    setChecked(false);
  };

  const handleChecked = () => {
    if (!checked) {
      onMonthlyRevenueChange({ key: "NO_REVENUE", label: "No revenue yet" });
    } else {
      onMonthlyRevenueChange(lastPicked.key);
    }
    setChecked(!checked);
  };

  return (
    <StyledOnboardingStep2 className="animated-container">
      <div className="os-revenue-picker">
        {options.map((option, index) => (
          <JumboPicker
            onClick={() => handleClick(index)}
            key={`jumbo-picker-${option.key}-${index}`}
            label={option.label}
            selected={monthlyRevenue === option.key}
            testId={`option-${option.key}-${index}`}
          />
        ))}
      </div>

      <SanloCheckbox
        checked={checked}
        text="I don't have revenue yet"
        toggleCheckbox={handleChecked}
        testId="no-revenue-checkbox"
        style={{
          marginBottom: "32px",
        }}
      />
    </StyledOnboardingStep2>
  );
};

export default OnboardingStep2;
