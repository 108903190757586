import React, { useState } from "react";
import classnames from "classnames";
import { ReactComponent as ArrowIcon } from "@assets/img/icons/common/accordion-arrow.svg";
import { BodyMini12, BodySmallAlt } from "@styled";
import { currencyFormat } from "@utils/currency";
import StyledTooltipRow from "./StyledTooltipRow";
import { trackGTMEvent, FINANCIALS_ACTION } from "@utils/gtm";

const TooltipRow = ({
  title,
  value,
  hint,
  iconLeft = false,
  strong = false,
  highlighted = false,
  trackData,
  pathname,
}) => {
  const [hintOpen, setHintOpen] = useState(false);

  const toggleHint = () => {
    const state = hintOpen ? "Close" : "Open";
    trackData &&
      trackGTMEvent({
        event: FINANCIALS_ACTION.EXPAND_TOOLTIP_ACCORDION,
        tooltip: trackData?.name || "",
        state,
        name: title,
        pathname,
      });
    setHintOpen(!hintOpen);
  };

  return (
    <StyledTooltipRow>
      <div
        className={classnames("tt-row-header", {
          "font-strong": strong,
        })}
        onClick={hint ? toggleHint : null}
      >
        <BodySmallAlt
          className={classnames("tt-row-title", {
            "tt-row-title-highlighted": highlighted,
          })}
        >
          {iconLeft && hint && (
            <ArrowIcon
              className={classnames("tt-row-icon icon-left", {
                "tt-hint-open": hintOpen,
              })}
            />
          )}
          {title}
          {!iconLeft && hint && (
            <ArrowIcon
              className={classnames("tt-row-icon", {
                "tt-hint-open": hintOpen,
              })}
            />
          )}
        </BodySmallAlt>
        <BodySmallAlt className="tt-row-value">
          {typeof value === "number" && !isNaN(value) ? currencyFormat(value) : value}
        </BodySmallAlt>
      </div>
      {hint && hintOpen && <BodyMini12 className="tt-row-hint">{hint}</BodyMini12>}
    </StyledTooltipRow>
  );
};

export default TooltipRow;
