const revenueData = [
  {
    revenue: 22000,
    repayment: 5000,
  },
  {
    revenue: 30000,
    repayment: 9000,
  },
  {
    revenue: 40000,
    repayment: 11000,
  },
  {
    revenue: 43000,
    repayment: 12000,
  },
  {
    revenue: 55000,
    repayment: 16000,
  },
  {
    revenue: 0,
    repayment: 0,
  },
  {
    revenue: 30000,
    repayment: 8000,
  },
  {
    revenue: 40000,
    repayment: 11000,
  },
  {
    revenue: 45000,
    repayment: 14000,
  },
  {
    revenue: 55000,
    repayment: 17000,
  },
  {
    revenue: 28000,
    repayment: 5000,
  },
  {
    revenue: 38000,
    repayment: 9000,
  },
];

const fixedData = [
  {
    revenue: 18000,
    repayment: 10000,
  },
  {
    revenue: 32000,
    repayment: 10000,
  },
  {
    revenue: 40000,
    repayment: 10000,
  },
  {
    revenue: 45000,
    repayment: 10000,
  },
  {
    revenue: 48000,
    repayment: 10000,
  },
  {
    revenue: 0,
    repayment: 10000,
  },
  {
    revenue: 32000,
    repayment: 10000,
  },
  {
    revenue: 40000,
    repayment: 10000,
  },
  {
    revenue: 45000,
    repayment: 12000,
  },
  {
    revenue: 48000,
    repayment: 10000,
  },
  {
    revenue: 32000,
    repayment: 10000,
  },
  {
    revenue: 40000,
    repayment: 10000,
  },
];

const legends = ["revenue", "repayment"];

const yAxisInfo = { highest: 60000, steps: [0, 10000, 20000, 30000, 40000, 50000] };

export { revenueData, fixedData, legends, yAxisInfo };

export const getMaxValue = (options, value) => {
  const valueArray = options?.map((option) => option[value]) ?? [];
  return Math.max(...valueArray);
};

export const getMaxTermInMonths = (options) => {
  const maxMonth = getMaxValue(options, "maxTermsInMonths");
  if (maxMonth <= 1) return `${maxMonth} month`;
  return `${maxMonth} months`;
};

export const amountToShow = (option) => {
  return option?.maxAmountToSelect < option?.requestedAmount ? option?.maxAmountToSelect : option?.requestedAmount;
};
