import React, { useCallback, useEffect, useRef } from "react";
import ReactCanvasConfetti from "react-canvas-confetti";

import StyledOnboardingDashboard from "./StyledOnboardingDashboard";
import OnboardingPrimaryFlow from "./onboarding-flows/OnboardingPrimaryFlow";
import OnboardingSecondaryFlow from "./onboarding-flows/OnboardingSecondaryFlow";

import { confettiCanvasStyles } from "./onboardingDashboardUtils";
import useConfetti from "@utils/custom-hooks/useConfetti";

import { useFlags } from "launchdarkly-react-client-sdk";
import { useSelector } from "react-redux";
import { Flex, Heading } from "@chakra-ui/react";

const onboardingTitle = {
  PRIMARY: "Apply for your custom growth capital options",
  SECONDARY: "Getting Started",
};

const OnboardingDashboard = ({
  onSelectPlatform,
  onboardingData,

  userName,
  dashboardIsLoading,
  connectingPrimaryFlow,
  connectingSecondaryFlow,
  shouldShowPendingRequest,
  setShouldShowPendingRequest,
  secondaryFlowCompleted,
}) => {
  const animationInstanceRef = useRef(null);
  const [getInstance, fire] = useConfetti(animationInstanceRef);

  const { clientGrowthCapitalOffersVisible, confettiTriggerControl = false } = useFlags();

  const { onboardingType } = onboardingData;

  const isPrimaryFlow = onboardingType === "PRIMARY";

  const advanceSummary = useSelector((state) => state.integrations.advances.data.summary);
  const { requestStage } = advanceSummary;

  const triggerConfetti = useCallback(() => {
    const isTrigger = localStorage.getItem("confettiTriggered");

    if (confettiTriggerControl && !isTrigger) {
      localStorage.setItem("confettiTriggered", true);
      return setTimeout(() => {
        fire();
      }, 2000);
    }
  }, [confettiTriggerControl, fire]);

  // Primary Flow Confetti Logic
  useEffect(() => {
    let timerOn, timerOff;
    if (isPrimaryFlow && connectingPrimaryFlow && !dashboardIsLoading && requestStage && !shouldShowPendingRequest) {
      timerOn = triggerConfetti();
    }
    timerOff = setTimeout(() => {
      if (requestStage === "IN_REVIEW" && clientGrowthCapitalOffersVisible) {
        setShouldShowPendingRequest(true);
      }
    }, 8000);

    return () => {
      clearTimeout(timerOn);
      clearTimeout(timerOff);
    };
  }, [
    clientGrowthCapitalOffersVisible,
    connectingPrimaryFlow,
    dashboardIsLoading,
    isPrimaryFlow,
    requestStage,
    setShouldShowPendingRequest,
    shouldShowPendingRequest,
    triggerConfetti,
  ]);

  // Secondary Flow Confetti Logic
  useEffect(() => {
    let confettiTimer;

    if (!isPrimaryFlow && secondaryFlowCompleted && !dashboardIsLoading) {
      confettiTimer = triggerConfetti();
    }

    return () => {
      clearTimeout(confettiTimer);
    };
  }, [shouldShowPendingRequest, dashboardIsLoading, isPrimaryFlow, secondaryFlowCompleted, triggerConfetti]);

  return (
    <StyledOnboardingDashboard data-test-id="dashboard-onboarding-card">
      <Heading size={"small"} fontWeight={"semibold"} mb="24px">
        {onboardingTitle[onboardingType] || ""}
      </Heading>
      {/*
      Can't remove this className since we have to change OnboardingStep3 component but it's used in other
      component so if I remove the className from the OnboardingStep3 component it will break the other component
      */}
      <Flex
        flexDir="column"
        bgColor={"dark.600"}
        padding={"28px 32px 32px 32px"}
        rounded={"xl"}
        className="od-wrapper"
        data-test-id="onboarding-card-content"
      >
        {isPrimaryFlow ? (
          <OnboardingPrimaryFlow
            onSelectPlatform={onSelectPlatform}
            connectionInProgress={connectingPrimaryFlow}
            userName={userName}
            shouldShowPendingRequest={shouldShowPendingRequest}
          />
        ) : (
          <OnboardingSecondaryFlow
            secondaryFlowCompleted={secondaryFlowCompleted}
            onSelectPlatform={onSelectPlatform}
            connectionInProgress={connectingSecondaryFlow}
          />
        )}
        <ReactCanvasConfetti refConfetti={getInstance} style={confettiCanvasStyles} />
      </Flex>
    </StyledOnboardingDashboard>
  );
};

export default OnboardingDashboard;
