import { useEffect, useRef, useState } from "react";

export function useHandleImageFile(initialImage) {
  const [currentImage, setCurrentImage] = useState(initialImage ?? null);
  const [currentImageURL, setCurrentImageURL] = useState(initialImage ?? "");
  const prevImage = useRef(initialImage);

  useEffect(() => {
    if (!currentImage && initialImage) {
      setCurrentImage(initialImage);
      setCurrentImageURL(initialImage);
      return;
    }
    if (currentImage && !currentImageURL) {
      setCurrentImageURL(URL.createObjectURL(currentImage));
    }
  }, [initialImage, currentImage, currentImageURL]);

  function handleOnImageChange(event) {
    const files = event.dataTransfer?.files || event.target.files;
    if (files.length > 0) {
      setCurrentImage(files[0]);
      setCurrentImageURL(URL.createObjectURL(files[0]));
      return files[0];
    }
  }

  function handleImageRemove() {
    prevImage.current = null;
    setCurrentImage("");
    setCurrentImageURL("");
  }

  return { image: currentImage, imageURL: currentImageURL, handleOnImageChange, handleImageRemove };
}
