import React from "react";
import styled from "styled-components";
import { BodySmallPlus } from "@styled";
import { Loader } from "@components/common/loaders";

const RefreshButton = ({ onClick, loading = false, style }) => {
  return (
    <StyledRefreshButton style={style}>
      {loading ? (
        <Loader className="refresh-loading" />
      ) : (
        <BodySmallPlus className="refresh-button" onClick={onClick}>
          Refresh
        </BodySmallPlus>
      )}
    </StyledRefreshButton>
  );
};

const StyledRefreshButton = styled.div`
  & {
    display: inline;
    margin: 0 4px;
    cursor: pointer;

    div {
      display: inline;
    }

    .refresh-button {
      display: inline;
      color: ${({ theme }) => theme.primaryColor};
    }

    .refresh-loading {
      svg {
        fill: ${({ theme }) => theme.primaryColor};
      }
    }
  }
`;

export default RefreshButton;
