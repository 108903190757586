import React from "react";
import { useSelector } from "react-redux";

import { gameMetricsSelector } from "@redux/game-metrics/gameMetricsSlice";
import { financialsSelector } from "@redux/financials/financialsSlice";

import { GameMetric } from "./game-metric/GameMetric";
import { Box, Heading, Flex, HStack } from "@chakra-ui/react";
import { AppFilter } from "./components/AppFilter";
import { DateFilter } from "./components/DateFilter";
import { BlurryCard } from "@common/blurry-card";
import { CardText } from "@common/cards/blur-card-text";
import { getBlurredData } from "@utils/blur";
import isEmpty from "lodash/isEmpty";

export const GameMetrics = () => {
  const { getUpcomingCashIn } = useSelector(financialsSelector);
  const integrations = useSelector((state) => state.integrations);
  const platform = getUpcomingCashIn?.data?.platforms ?? [];

  const { isBlurred, isBroken } = getBlurredData(platform, integrations);

  const { getGameMetrics } = useSelector(gameMetricsSelector);
  const { data, initialLoadComplete = false, appliedFilters = {}, lastUpdated = "" } = getGameMetrics;
  const { metrics = [], metadata = {} } = data;
  const { availableApps = [] } = metadata;

  const showAppFilter = initialLoadComplete && availableApps.length > 1;

  if (!isBlurred && isEmpty(metrics)) {
    return null;
  }

  return (
    <Box mb="90px">
      <Flex alignItems={"center"} justifyContent={"space-between"}>
        <Heading size={"small"} fontWeight={"semibold"}>
          Product metrics
        </Heading>
        <HStack>
          {showAppFilter && <AppFilter filterOptions={availableApps} appliedFilters={appliedFilters} />}
          {initialLoadComplete && !isBlurred && <DateFilter appliedFilters={appliedFilters} />}
        </HStack>
      </Flex>
      <BlurryCard
        isBlurred={isBlurred}
        imgSrc={require("@assets/img/blur/gameMetricsBlur.png")}
        message={
          <CardText
            text={
              isBroken ? "Oops, it looks like your data sources have lost connection." : "Track key product metrics."
            }
            link={isBroken ? "Reconnect" : "Connect data sources"}
          />
        }
        mt={"24px"}
      >
        <HStack gap="16px" minH={"127px"}>
          {metrics.map((metric, index) => {
            const key_base = `key_gms_${metric.id || index}_${lastUpdated}`;
            return (
              <GameMetric
                key={key_base}
                key_base={key_base}
                metric={metric}
                initialLoadComplete={initialLoadComplete}
                lastUpdated={metadata.lastSynced}
              />
            );
          })}
        </HStack>
      </BlurryCard>
    </Box>
  );
};
