import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Heading, VStack, Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";

import { catalogPageLoadingSelector, catalogThunks } from "@redux/webshops/catalog/catalogSlice";
import { associationsThunks } from "@redux/webshops/associations/associationsSlice";
import { startCatalogListeners, stopCatalogListeners } from "@redux/webshops/catalog/middleware";

import { Item } from "./components/item/Item";
import { Series } from "./components/series/Series";

import { Spinner } from "@common/loaders";
import ErrorBoundary from "@components/ErrorBoundary";
import { useFlags } from "launchdarkly-react-client-sdk";

export function CatalogPage() {
  const { offerSeries } = useFlags();
  const dispatch = useDispatch();
  const isLoading = useSelector(catalogPageLoadingSelector);

  useEffect(() => {
    dispatch(catalogThunks.getPricingTiers());
    dispatch(associationsThunks.getAssociations());
    if (offerSeries) startCatalogListeners();

    return () => {
      stopCatalogListeners();
    };
  }, [dispatch, offerSeries]);

  return (
    <>
      <VStack alignItems={"stretch"} py={"16px"}>
        <Heading size="headline" fontWeight={"600"}>
          Catalog
        </Heading>
      </VStack>

      <ErrorBoundary
        name="catalog-page-content"
        showAltUI={isLoading}
        altUI={
          <Spinner
            style={{
              pt: "40px",
            }}
          />
        }
      >
        {offerSeries ? (
          <Tabs size={"lg"} variant={"catalogPage"}>
            <TabList>
              <Tab>Items</Tab>
              <Tab>Series</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Item />
              </TabPanel>
              <TabPanel>
                <Series />
              </TabPanel>
            </TabPanels>
          </Tabs>
        ) : (
          <Item />
        )}
      </ErrorBoundary>
    </>
  );
}
