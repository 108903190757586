import { useLayoutEffect } from "react";
import { Box, Button, FormControl, FormHelperText, Heading, Input } from "@chakra-ui/react";
import { useMultiStepModal } from "@common/multi-step-modal/MultiStepModalContext";
import { useEnterKeyPress } from "@src/utils/custom-hooks/useEnterKeyPress";
import { useFormContext } from "react-hook-form";

export function ProjectName({ handleNextStep }) {
  const { setShowProgress, setShowStepsLeft } = useMultiStepModal();
  const { register, formState, watch } = useFormContext();
  const [continueButton] = useEnterKeyPress();

  const name = watch("name");

  useLayoutEffect(() => {
    setShowProgress(true);
    setShowStepsLeft(true);
  }, [setShowProgress, setShowStepsLeft]);

  return (
    <Box mt="40px" maxW="424px" w={"100%"} mx="auto">
      <Heading textAlign={"center"} mb="80px" size={"large"} fontWeight={600}>
        Name your new project
      </Heading>
      <FormControl>
        <Input
          {...register("name", { required: true })}
          borderRadius={"10px"}
          mb={formState?.errors?.name ? "4px" : "24px"}
          placeholder="New Webshop"
        />
        {formState?.errors?.name && (
          <FormHelperText mb="24px" color="brandRed.400">
            We need your super duper webshop name!
          </FormHelperText>
        )}
      </FormControl>
      <Button ref={continueButton} isDisabled={name?.length < 1} variant="primary" w={"full"} onClick={handleNextStep}>
        Continue
      </Button>
    </Box>
  );
}
