import { alertAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(alertAnatomy.keys);

const baseStyle = definePartsStyle((props) => {
  const { status } = props;
  const statusVariables = {
    success: "brandGreen.400",
  };

  return {
    title: {
      fontSize: "sm",
      color: statusVariables[status] || "textWhite.400",
      fontWeight: 500,
      opacity: 1,
    },
    container: {
      p: "12px",
      borderRadius: "10px",
      bg: "transparent",
      _before: {
        opacity: "16%",
        content: '""',
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: statusVariables[status],
      },
    },
    icon: {
      color: statusVariables[status] || "textWhite.400",
      w: "16px",
      h: "16px",
    },
  };
});

export const Alert = defineMultiStyleConfig({ baseStyle });
