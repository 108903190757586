import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";

import actions from "@redux/actions";

import { BodyMini12, BodySmallAlt } from "@styled";
import StyledPlatformLinkingContent from "../StyledPlatformLinkingContent";
import ConnectedPlatformCard from "../ConnectedPlatformCard";
import { SanloTooltip } from "@common/tooltips/sanlo-tooltip/SanloTooltip";
import Support from "@components/support/Support";

import { PLATFORMS } from "@utils/consts";
import { filterIntegrations } from "@utils/integrations";
import { sMatch, NOT_CONNECTED, DRAFT_CONNECTION } from "@utils/status";

import { ReactComponent as WarningIcon } from "@assets/img/icons/common/alertCircle.svg";
import { trackGTMEvent, ONBOARDING_ACTIONS } from "@utils/gtm";

const InAppPurchasesLinkingAccordion = ({ description, note, onSelectPlatform, onboardingStep, fromDashboard }) => {
  const dispatch = useDispatch();

  const integrations = useSelector((state) => state.integrations);

  const [unconnectedIntegrations, setUnconnectedIntegrations] = useState([]);
  const [connectedIntegrations, setConnectedIntegrations] = useState([]);

  useEffect(() => {
    if (!fromDashboard) {
      // Main container makes this call as well, so we don't need to do it if
      // we're outside of the modal onboarding flow.
      dispatch(actions.integrations.googleplay.retrieveServiceAccount.request());
    }
  }, [fromDashboard, dispatch]);

  useEffect(() => {
    const unconnected = [];
    const connected = [];
    filterIntegrations(integrations, PLATFORMS).forEach((integration) => {
      const { status = "" } = integration;
      if (!sMatch(status, [NOT_CONNECTED, DRAFT_CONNECTION])) {
        connected.push(integration);
      } else {
        unconnected.push(integration);
      }
    });
    setUnconnectedIntegrations(unconnected);
    setConnectedIntegrations(connected);
  }, [integrations]);

  return (
    <StyledPlatformLinkingContent>
      <div className="description-container">
        <BodySmallAlt className="platform-description">{description}</BodySmallAlt>
        <BodyMini12 className="secondary-description">{note}</BodyMini12>
      </div>

      <div className="platforms-container">
        {unconnectedIntegrations.map((integration) => {
          const { id, image, isDisabled, platformId, name, type } = integration;
          return (
            <div
              key={`product-analytics-platform-${id}`}
              onClick={() => {
                trackGTMEvent({
                  event: ONBOARDING_ACTIONS.CONNECT_SOURCE_ICON,
                  type,
                  name,
                });
                return !isDisabled && onSelectPlatform(integration);
              }}
              className={classnames("platform-logo-wrapper", {
                "is-disabled": isDisabled,
              })}
              id={`${id}-error-tooltip`}
              data-test-id={`${platformId}-connect-button`}
            >
              <div className={`platform-logo tag-sanlo-onboarding-step${onboardingStep}-add-${id}`}>
                <img
                  className={`tag-sanlo-onboarding-step${onboardingStep}-add-${id}`}
                  src={image}
                  alt="platform-logo"
                />
              </div>

              {isDisabled && (
                <>
                  <div className="disabled-logo-overlay">
                    <WarningIcon className="overlay-icon" />
                  </div>
                  <SanloTooltip
                    maxWidth={300}
                    target={`${id}-error-tooltip`}
                    text={
                      <>
                        We're sorry something went wrong on our end. We're working on solving this. Please come back
                        later and connect later. If you have any questions, please contact us at{" "}
                        <Support email className="sanlo-email-label" />.
                      </>
                    }
                  />
                </>
              )}
            </div>
          );
        })}
      </div>

      {connectedIntegrations.map((integration) => {
        const { status } = integration;
        return (
          <div
            className="draft-connected-platform"
            key={`integration-${integration.platformId}-${status}`}
            data-test-id={`integration-${integration.platformId}`}
          >
            <ConnectedPlatformCard
              platform={integration}
              status={status}
              handlers={{
                NEEDS_RECONNECTION: () => onSelectPlatform(integration),
                NO_PERMISSIONS: () => onSelectPlatform(integration),
              }}
            />
          </div>
        );
      })}
    </StyledPlatformLinkingContent>
  );
};

export default InAppPurchasesLinkingAccordion;
