import React from "react";
import styled from "styled-components";
import classnames from "classnames";

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  position: relative;
  width: 1.75rem;
  height: 1rem;
  background-color: ${(props) => (props.checked ? props.theme.colorGreen : "rgba(58, 58, 62, 1)")};
  border-radius: 1.5rem;
  box-sizing: border-box;
  transition: all 150ms;

  .toggle-bobble {
    position: absolute;
    top: 0.1rem;
    left: 0.1rem;
    height: 0.8rem;
    width: 0.8rem;
    background-color: rgba(17, 17, 20, 1);
    border-radius: 1rem;
    transition: all 150ms;

    &.toggled {
      left: auto;
      right: 0.1rem;
    }
  }
`;

const CheckboxContainer = styled.div`
  width: 1.75rem;
  height: 1rem;
`;

const Checkbox = ({ className, checked, ...props }) => (
  <CheckboxContainer className={className}>
    <HiddenCheckbox checked={checked} {...props} />
    <StyledCheckbox checked={checked}>
      <div
        className={classnames("toggle-bobble", {
          toggled: checked,
        })}
      />
    </StyledCheckbox>
  </CheckboxContainer>
);

export const SanloToggle = ({ checked = false, toggleCheckbox }) => {
  return (
    <label
      style={{
        width: "1.75rem",
        height: "1rem",
        cursor: "pointer",
        marginBottom: "0",
      }}
    >
      <Checkbox checked={checked} onChange={toggleCheckbox} />
    </label>
  );
};
