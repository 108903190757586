import axios from "axios";

const SANLO_WEB_API_URL = process.env.REACT_APP_SANLO_WEB_API_URL;
const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;
const AUTH0_TOKEN_PATH = `@@auth0spajs@@::${AUTH0_CLIENT_ID}::default::openid profile email`;

const isUserLoggedIn = () => {
  const token = JSON.parse(localStorage.getItem(AUTH0_TOKEN_PATH))?.body.id_token;
  return token !== null;
};

const createJWTToken = (token) => {
  return "Bearer " + token;
};

// A very simple wrapper for basic catch error handling
export const errorWrapper = async (fn, rejectWithValue) => {
  try {
    return fn();
  } catch (e) {
    return rejectWithValue({
      error: e.response.data,
    });
  }
};

class HTTP {
  constructor() {
    this.req = axios.create({
      baseURL: SANLO_WEB_API_URL,
      headers: {
        "Content-type": "application/json",
      },
    });

    this.token = null;
  }

  setAuthorization(token) {
    // console.log(`AUTH >> token: ${token}`);
    this.token = createJWTToken(token);
    // console.log(`AUTH >> JWT token: ${this.token}`);

    this.req.interceptors.request.use((config) => {
      if (isUserLoggedIn()) {
        config.headers["Authorization"] = this.token;
      }
      return config;
    });
  }
}

export default new HTTP();
