import React from "react";
import StyledSanloChip from "./StyledSanloChip";
import { BodySmall14 } from "@styled";

import { ReactComponent as ErrorIcon } from "@assets/img/icons/common/alertTriangle.svg";
import { ReactComponent as WarningIcon } from "@assets/img/icons/common/alertCircle.svg";
import { ReactComponent as SuccessIcon } from "@assets/img/icons/common/alertCheck.svg";

const SanloChip = ({ content, state, showIcon, style }) => {
  const iconStates = {
    error: <ErrorIcon className="chip-icon" />,
    warning: <WarningIcon className="chip-icon" />,
    success: <SuccessIcon className="chip-icon" />,
  };

  return (
    <StyledSanloChip state={state} style={style}>
      {state && showIcon && iconStates[state]}
      <BodySmall14 className="chip-description">{content}</BodySmall14>
    </StyledSanloChip>
  );
};

export default SanloChip;
