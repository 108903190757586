import styled from "styled-components";

const StyledStepsProgressContainer = styled.div`
  & {
    max-height: 100vh;
    background-color: ${({ theme }) => theme.grayscale10};
    border-radius: 12px;
    padding: 32px 42px;
    width: 617px;
    animation: pushInFromBottom 1s ease;
    position: absolute;
    top: -21rem;

    &.is-branded {
      max-height: 80vh;
      overflow: scroll;
    }

    .os-top-actions {
      display: flex;
      margin-bottom: 36px;

      .back-arrow {
        fill: ${({ theme }) => theme.textGray};
        border-radius: 8px;
        padding: 8px;
        width: 40px;
        height: 40px;
        cursor: pointer;
        margin-right: 16px;
        transition: background-color 0.25s ease;

        :hover {
          background-color: ${({ theme }) => theme.grayscale40};
        }
      }

      .os-progress-bar-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        color: ${({ theme }) => theme.textGray};

        .os-progress-bar-description {
          display: flex;
          justify-content: space-between;
        }
      }
    }

    .os-header {
      margin-bottom: 32px;

      .os-title {
        margin-bottom: 16px;
        color: ${({ theme }) => theme.textSeashellWhite};
      }

      .os-description {
        color: ${({ theme }) => theme.textGray};
      }
    }

    .os-content-wrapper {
      max-height: calc(100vh - 400px);
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 20px;
      }

      &::-webkit-scrollbar-thumb {
        padding: 0 6px;
        border-right: 6px solid transparent;
        border-left: 6px solid transparent;
        background-color: rgba(51, 51, 56, 1);
        background-clip: padding-box;
        border-radius: 20px;
      }

      .platform-accordion-wrapper {
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .os-continue-button {
      margin-top: 16px;
      width: 100%;
    }

    .os-divider {
      border-bottom: 1px solid ${({ theme }) => theme.grayscale40};
      margin-top: 32px;
      margin-bottom: 24px;
    }

    .os-footer {
      color: ${({ theme }) => theme.textGray};

      .os-button-label {
        color: ${({ theme }) => theme.colorRed};
        cursor: pointer;
      }
    }
  }
`;

export default StyledStepsProgressContainer;
