import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { ReactComponent as TriangleIcon } from "@assets/img/icons/common/alertTriangle.svg";
import { ReactComponent as CircleIcon } from "@assets/img/icons/common/alertCircle.svg";
import { ReactComponent as CheckIcon } from "@assets/img/icons/common/alertCheck.svg";

export const SanloToast = ({ icon, msg }) => {
  const theme = useContext(ThemeContext);
  const icons = {
    error: <TriangleIcon fill={theme.colorRed} className="icon-toast" style={{ marginRight: 8 }} />,
    warning: <CircleIcon fill={theme.colorYellow} className="icon-toast" style={{ marginRight: 8 }} />,
    success: <CheckIcon fill={theme.colorGreen} className="icon-toast" style={{ marginRight: 8 }} />,
    noPermissions: <CheckIcon fill={theme.colorYellow} className="icon-toast" style={{ marginRight: 8 }} />,
  };

  return (
    <>
      {icons[icon]}
      {msg}
    </>
  );
};
