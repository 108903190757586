import React from "react";
import { KeyGeneratorModal } from "./KeyGeneratorModal";
import { Box, Button, Text, VStack, useDisclosure } from "@chakra-ui/react";
import { StyledLoader } from "@common/inputs/SanloButton/SanloButton";
import PropTypes from "prop-types";

export const KeyGeneratorCard = ({ keyValue = "", title = "", subTitle = "", requestStatus = {}, generateKey }) => {
  if (!generateKey?.thunk) {
    throw new Error("thunk prop is required");
  }
  if (!generateKey?.selector) {
    throw new Error("selector prop is required");
  }

  if (!generateKey?.clean) {
    throw new Error("clean prop is required");
  }

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <VStack
        w={"100%"}
        p={"16px"}
        alignItems={"flex-start"}
        justifyContent={"center"}
        borderRadius={"10px"}
        border={"1px solid"}
        borderColor={"dark.300"}
        gap={"16px"}
      >
        {title && (
          <VStack w={"100%"} alignItems={"flex-start"} gap={"16px"}>
            <Box>
              <Text size={"small"} color={"textWhite.400"} fontWeight={600}>
                {title}
              </Text>
              {subTitle && (
                <Text size={"smallAlt"} color={"textSubtext.400"}>
                  {subTitle}
                </Text>
              )}
            </Box>
            {keyValue && (
              <Text size={"small"} color={"textWhite.400"}>
                {keyValue}
              </Text>
            )}
          </VStack>
        )}
        <Button
          p={"12px"}
          borderRadius={"8px"}
          border={"1px solid"}
          borderColor={"#FAF8F81A"}
          onClick={onOpen}
          isLoading={requestStatus?.pending}
          spinner={<StyledLoader />}
          variant={keyValue ? "primary" : ""}
        >
          {keyValue ? "Create new key" : "Create key"}
        </Button>
      </VStack>
      <KeyGeneratorModal
        title={title}
        keyValue={keyValue}
        isOpen={isOpen}
        onClose={onClose}
        generateKey={generateKey}
      />
    </>
  );
};

KeyGeneratorCard.propTypes = {
  keyValue: PropTypes.string,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  requestStatus: PropTypes.object,
  generateKey: PropTypes.shape({
    thunk: PropTypes.func.isRequired,
    selector: PropTypes.func.isRequired,
    clean: PropTypes.func.isRequired,
  }).isRequired,
};
