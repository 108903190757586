import { Flex, Icon, Text } from "@chakra-ui/react";
import { ReactComponent as UpArrow } from "@assets/img/icons/common/arrow-up.svg";
import { ReactComponent as DownArrow } from "@assets/img/icons/common/arrow-down.svg";
import { StatCard } from "@common/stat-card";
import { FINANCIAL_INTELLIGENCE_BALANCE_TYPE } from "@src/consts/financial-intelligence";
import symbols from "@src/utils/symbols";

import { useSelector } from "react-redux";
import { formatPercentage } from "@src/utils/numbers";
import { financialsSelector } from "@redux/financials/financialsSlice";
import { FINANCIAL_INTELLIGENCE_CARD_ID } from "@src/consts/financial-intelligence";
import { ReconnectTooltip } from "@utils/financial-intelligence";
import { getBankingBlurData } from "@utils/blur";

export function AdSpendRunRateCard() {
  const integrations = useSelector((state) => state.integrations);
  const { isPartiallyHealthy } = getBankingBlurData(integrations.plaid.data.institutions);
  const { getBankingMetrics } = useSelector(financialsSelector);
  const data = getBankingMetrics.data;
  const isLoading = !data?.ua && getBankingMetrics.requestStatus.pending;

  const adSpendRunRateInfo = {
    id: FINANCIAL_INTELLIGENCE_CARD_ID.AD_SPEND_RUN_RATE,
    title: `${symbols.flyingMoney} User acquisition spend (Last 30 days)`,
    currentBalance: data?.ua,
    status: FINANCIAL_INTELLIGENCE_BALANCE_TYPE.EXPENSE,
    rightIcon: data?.uaSpendDeltaBps ? (
      <Flex>
        {Math.sign(data?.uaSpendDeltaBps) === -1 ? (
          <Icon as={DownArrow} width={"16px"} height={"16px"} color={"textDefault.400"} />
        ) : (
          <Icon as={UpArrow} width={"16px"} height={"16px"} color={"textDefault.400"} />
        )}
        <Text size="small">{formatPercentage(data?.uaSpendDeltaBps / 100)}</Text>
      </Flex>
    ) : null,
    tooltip: {
      id: "stat-card-ad-spend-run-rate-tooltip",
      content: (
        <Text size="mini">
          This is what you paid to ad networks from your bank account in the last 30 days, to cover your user
          acquisition expenses
        </Text>
      ),
    },
    unhealthyTooltip: isPartiallyHealthy
      ? {
          id: "unhealthy-ad-spend-run-rate-tooltip",
          content: <ReconnectTooltip source={"banks"} />,
        }
      : null,
  };

  return <StatCard data-test-id="ad-spend-run-rate-card" isLoading={isLoading} {...adSpendRunRateInfo} />;
}
