import React from "react";

import { Tooltip } from "@common/tooltip/Tooltip";
import { Divider, HStack, Icon, Text } from "@chakra-ui/react";
import { ReactComponent as History } from "@assets/img/icons/common/history.svg";
import { getAsOfHoursAgo } from "@src/utils/time";

const tooltipBody = (content) => (
  <>
    <Text size="mini">{content.content}</Text>
    <Divider my="8px" bgColor={"dark.300"} borderBottomWidth={0} />
    <HStack justifyContent={"space-between"}>
      <Text size="mini">
        <Text
          as="span"
          fontWeight={500}
          bgGradient={"linear(226deg, gradients.heliotrope 0%, gradients.ultraRed 100%)"}
          bgClip={"text"}
        >
          Source:
        </Text>{" "}
        {content.source}
      </Text>
      <HStack gap="4px">
        <Icon as={History} />
        <Text size={"mini"}>{getAsOfHoursAgo(content.lastUpdated)}hs ago</Text>
      </HStack>
    </HStack>
  </>
);
export const InfoTooltip = ({ children = "", content = null }) => {
  const tooltipContent = tooltipBody(content);

  return <Tooltip content={tooltipContent}>{children}</Tooltip>;
};
