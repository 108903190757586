import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";

import { TitleRegularPlusCSS } from "@styled";
import { MonthlyBurn } from "../../monthly-burn";

import { currencyFormatCentsData } from "@utils/currency";

const StyledAmount = styled.span`
  position: relative;
  text-align: right;
`;

const StyledAmountDollars = styled.span`
  ${TitleRegularPlusCSS};
`;

export const DisplayAmount = ({ data = {}, cashFlow = "cashIn" }) => {
  const theme = useContext(ThemeContext);

  const { totalAmountCents = 0, momBps = null } = data;

  const amountData = currencyFormatCentsData(totalAmountCents);
  // The negative is already there from the formatting
  const prefix = cashFlow === "cashIn" ? "+" : "";

  return (
    <StyledAmount
      style={{
        color: cashFlow === "cashIn" ? theme.colorGreen : theme.colorPurple,
      }}
    >
      <StyledAmountDollars>
        {prefix}
        {amountData.dollars}.{amountData.cents}
      </StyledAmountDollars>
      <MonthlyBurn value={momBps} />
    </StyledAmount>
  );
};
