import axios from "axios";
import HTTP from "@redux/http";

export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;
export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN_URL;
export const AUTH0_API_AUDIENCE = process.env.REACT_APP_AUTH0_API_AUDIENCE;
export const AUTH0_TOKEN_PATH = `@@auth0spajs@@::${AUTH0_CLIENT_ID}::default::openid profile email`;

const SANLO_WEB_API_URL = process.env.REACT_APP_SANLO_WEB_API_URL;

class SanloWebApiService {
  sendChangePasswordLink(email) {
    const payload = {
      email: email,
      connection: "Username-Password-Authentication",
      client_id: AUTH0_CLIENT_ID,
    };
    return axios.post(`https://${AUTH0_DOMAIN}/dbconnections/change_password`, payload);
  }

  sendVerificationEmail() {
    return HTTP.req.post(`${SANLO_WEB_API_URL}/api/users/resend-email-verification`);
  }

  getCapitalOfferDetail(offerOptionId, amount) {
    return HTTP.req.get(`${SANLO_WEB_API_URL}/api/capital-offer/details/${offerOptionId}/${amount}`);
  }
}

export default new SanloWebApiService();
