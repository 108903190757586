import styled from "styled-components";

import { BodySmall14CSS, BodySmallPlusCSS } from "@styled";
import { StyledPageCard } from "@components/page";

export const StyledPageContainer = styled(StyledPageCard)`
  position: relative;
`;

export const StyleCashFlowBreakdownContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "cashout cashin";
`;

const StyledCashFlowBreakdown = styled.div`
  position: relative;
`;

export const StyledCashFlowBreakdownCashIn = styled(StyledCashFlowBreakdown)`
  grid-area: cashin;
  padding-left: 24px;
`;

export const StyledCashFlowBreakdownCashOut = styled(StyledCashFlowBreakdown)`
  grid-area: cashout;
  padding-right: 24px;
  border-right: 1px solid #333338;
`;

export const StyledCashFlowHeader = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const StyledCashFlowSourcesContainer = styled.div`
  position: relative;
  height: 575px;
  /* overflow-y: scroll; */
  padding-right: 14px;
`;

export const StyledCashFlowSources = styled.div`
  position: relative;
  margin: 24px 0;
`;

export const StyledCashFlowSource = styled.div`
  position: relative;
  margin-top: 16px;
`;

export const StyledSourceData = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr 1fr auto;
  grid-template-rows: 1fr;
  & > * {
    line-height: 22px !important;
  }
`;

export const StyledIcon = styled.img`
  width: 22px;
  height: 22px;
  border-radius: 6px;
  margin-right: 7px;
`;

export const StyledName = styled.span`
  ${BodySmall14CSS};
  color: ${({ theme }) => theme.grayFontColor};
`;

export const StyledCashAmount = styled.span`
  ${BodySmallPlusCSS};
  color: ${({ theme }) => theme.grayFontColor};
  text-align: right;
`;

export const StyledSectionHeader = styled.span`
  ${BodySmallPlusCSS};
  margin-right: 10px;
  text-transform: capitalize;
`;

export const StyledProgressBarContainer = styled.div`
  height: 8px;
  margin-top: 16px;
  width: 100%;
  position: relative;
`;

export const StyledProgressBar = styled.div`
  height: 8px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colorGreen};
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #333338;
`;
