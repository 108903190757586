import HTTP from "@redux/http";

const SANLO_WEB_API_URL = process.env.REACT_APP_SANLO_WEB_API_URL;
const BASE = `${SANLO_WEB_API_URL}/api/integrations/`;

export default {
  allGetEarnings: (platformIds, monthlyPeriods) => {
    return HTTP.req.get(`${BASE}/platforms/earnings?platformIds=${platformIds}&monthlyPeriods=${monthlyPeriods}`);
  },

  appStoreGetEarnings: () => {
    return HTTP.req.get(`${BASE}/platforms/appstore/earnings`);
  },

  appStoreGetPreviousEarnings: (fiscalPeriodId) => {
    return HTTP.req.get(`${BASE}/platforms/appstore/earnings/previous/${fiscalPeriodId}`);
  },

  appStoreRefreshEarnings: () => {
    return HTTP.req.post(`${BASE}/platforms/appstore/refresh-company-data`);
  },

  googlePlayGetEarnings: () => {
    return HTTP.req.get(`${BASE}/platforms/googleplay/earnings`);
  },

  googlePlayGetPreviousEarnings: (fiscalPeriodId) => {
    return HTTP.req.get(`${BASE}/platforms/googleplay/earnings/previous/${fiscalPeriodId}`);
  },

  adRevenueGetEarnings: (networkId) => {
    return HTTP.req.get(`${BASE}/ad-revenue/earnings/${networkId}`);
  },

  adRevenueGetPreviousEarnings: (networkId, periodId) => {
    return HTTP.req.get(`${BASE}/ad-revenue/earnings/previous/${periodId}`);
  },
};
