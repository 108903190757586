import React, { useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Box, Text, Flex, useTheme } from "@chakra-ui/react";

import { SanloTooltip } from "@components/common/tooltips/sanlo-tooltip/SanloTooltip";
import { Chart } from "@components/charts";

import { currencyFormat } from "@utils/currency";
import symbols from "@utils/symbols";
import { getYAxisSteps } from "@utils/chart";
import { useTrackHover, FINANCIALS_ACTION, getMonthIndex } from "@utils/gtm";
import { getItemPos, isIntermediatePoint } from "./utils";

export const LineChart = ({
  chartName = "",
  chartId = "",
  columnWidth = "",
  data = [],
  noTitle = false,
  withNegYAxis = false,
  yAxisStepCount = null,
  verticalLine = {},
  backgroundLines = true,
  hasBckGrndColor = false,
  bicolorLine = false,
  bicolorScheme: { positiveColor = "#2DFAB0", negativeColor = "#FF4264" } = {},
  tooltipText: { title = (date) => `${date}`, insights = "", inProgressMonthBody = "" } = {},
  svgColor = "#66C8FF",
  dotLastLine = false,
  yAxisWidth = "auto",
  checkIntermediates = false,
  tooltipContent,
  hideFirstAndLastNode = false,
}) => {
  const theme = useTheme();

  // These dimensions (of the lineContainerRef) are used to size the SVG
  // which in combo with preserveAspectRatio="none" means we can do calculations
  // with simpler numbers.
  const lineContainerRef = useRef(null);
  const [dimensions, setDimensions] = useState(null);
  const [svgPoints, setSvgPoints] = useState([]);
  const [addTracking, removeTracking] = useTrackHover();

  const highestValue = Math.max(...data.map(({ monthlyAmount }) => Math.abs(monthlyAmount)));
  const yAxisSteps = getYAxisSteps({
    highest: isFinite(highestValue) ? highestValue : 10,
    withNeg: withNegYAxis,
    stepCount: yAxisStepCount,
  });
  const maxValue = yAxisSteps.highest;

  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (!lineContainerRef || !lineContainerRef.current) return;
      const newDimensions = {
        width: lineContainerRef.current.clientWidth,
        height: lineContainerRef.current.clientHeight,
      };
      setDimensions(newDimensions);
    });
    resizeObserver.observe(lineContainerRef.current);
  }, []);

  useLayoutEffect(() => {
    if (!dimensions || !data || !data.length) return;
    const itemWidth = dimensions.width / data.length;
    const itemHalfWidth = itemWidth / 2;

    const newSvgPoints = data.map((item, i, arr) => {
      const pos = getItemPos(
        item,
        i,
        {
          ...dimensions,
          itemWidth,
          itemHalfWidth,
        },
        maxValue,
        withNegYAxis,
      );
      const newPoint = {
        ...item,
        pos,
      };
      // Get the next point, if it exists
      const nextIndex = i + 1;
      const nextItem = arr[nextIndex];
      const nextPos = getItemPos(
        nextItem,
        nextIndex,
        {
          ...dimensions,
          itemWidth,
          itemHalfWidth,
        },
        maxValue,
        withNegYAxis,
      );
      let nextPoint = null;
      if (nextPos) {
        nextPoint = {
          ...nextItem,
          pos: nextPos,
        };
        newPoint.nextPoint = nextPoint;
      }
      // Get the previous point, if it exists
      const prevIndex = i - 1;
      const prevItem = arr[prevIndex];
      const prevPos = getItemPos(
        arr[prevIndex],
        prevIndex,
        {
          ...dimensions,
          itemWidth,
          itemHalfWidth,
        },
        maxValue,
        withNegYAxis,
      );
      // if (prevPos) newPoint.prevPos = prevPos;
      if (prevPos) {
        newPoint.prevPoint = {
          ...prevItem,
          pos: prevPos,
        };
      }

      if (item.svgLineParser) {
        const subLines = item.svgLineParser({
          point: newPoint,
          nextPoint,
          itemWidth,
          itemHalfWidth,
          dimensions,
          theme,
        });
        if (subLines) {
          newPoint.subLines = subLines;
        }
      }

      if (item.svgPointParser) {
        const overrides = item.svgPointParser({
          point: newPoint,
          nextPoint,
          itemWidth,
          itemHalfWidth,
          dimensions,
          theme,
        });
        if (overrides) {
          newPoint.overrides = overrides;
        }
      }

      // If we have a previous and next point, check if this is an intermediate point
      if (checkIntermediates) {
        newPoint.isIntermediate = prevPos && nextPos && isIntermediatePoint(prevPos, pos, nextPos);
      }
      return newPoint;
    });
    setSvgPoints(newSvgPoints);
    // eslint-disable-next-line
  }, [data, dimensions, checkIntermediates]);

  return (
    <Chart
      noTitle={noTitle}
      chartId={chartId}
      data={data}
      yAxisSteps={yAxisSteps}
      withNegYAxis={withNegYAxis}
      hasBckGrndColor={hasBckGrndColor}
      verticalLine={verticalLine}
      backgroundLines={backgroundLines}
      yAxisWidth={yAxisWidth}
    >
      <Box zIndex={"0"} position={"absolute"} top={"0"} bottom={"0"} left={0} right={"0"} ref={lineContainerRef}>
        {Boolean(svgPoints.length) && (
          <Svg
            preserveAspectRatio="none"
            viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
            data-test-id={`${chartId}-line-chart-svg`}
          >
            {svgPoints.map((point, i, arr) => {
              if (point.subLines) {
                return point.subLines.map((subLine, j) => {
                  const { x1, y1, x2, y2, color, style, width } = subLine;
                  let strokeWidth = withNegYAxis ? "2" : "1";
                  if (width) strokeWidth = width;
                  return (
                    <line
                      key={`line-chart-subline-${i}-${j}`}
                      x1={x1}
                      y1={y1}
                      x2={x2}
                      y2={y2}
                      stroke={color}
                      strokeWidth={strokeWidth}
                      strokeDasharray={style === "dashed" ? "8" : null}
                    />
                  );
                });
              } else {
                const { pos, nextPoint, monthlyAmount } = point;
                const nextIndex = i + 1;
                if (!pos || !nextPoint) return null;
                const isLastSegment = nextIndex === data.length - 1;
                const isPredictedToPredicted = point.isPredicted && nextPoint.isPredicted;
                let strokeColor = svgColor;
                if (withNegYAxis && bicolorLine) {
                  strokeColor = monthlyAmount >= 0 ? positiveColor : negativeColor;
                }

                if (point.isInvisible || nextPoint.isInvisible) {
                  // We still want to draw the line, but make it invisible
                  strokeColor = "transparent";
                }

                let isDotted = false;
                if (dotLastLine && isLastSegment) isDotted = true;
                if (isPredictedToPredicted) isDotted = true;
                if (isDotted) {
                  return (
                    <line
                      key={`line-chart-line-${i}`}
                      x1={pos.x}
                      y1={pos.y}
                      x2={nextPoint.pos.x}
                      y2={nextPoint.pos.y}
                      stroke={strokeColor}
                      strokeWidth={withNegYAxis ? "2" : "1"}
                      strokeDasharray="8"
                    />
                  );
                } else {
                  return (
                    <line
                      key={`line-chart-line-${i}`}
                      x1={pos.x}
                      y1={pos.y}
                      x2={nextPoint.pos.x}
                      y2={nextPoint.pos.y}
                      stroke={strokeColor}
                      strokeWidth={withNegYAxis ? "2" : "1"}
                    />
                  );
                }
              }
            })}
          </Svg>
        )}
      </Box>

      {svgPoints.map((svgPoint, i) => {
        const { shortDate, fullDate, monthlyAmount } = svgPoint;
        const inProgressMonth = data.length - 1 === i;

        // Percentage of max, for normal line charts this is the end value
        let percentagePosition = (Math.abs(monthlyAmount) / maxValue) * 100;
        let circlePos = `${percentagePosition}%`;
        if (withNegYAxis) {
          // Relative percentages are halved on a negative/double chart
          percentagePosition /= 2;
          // Start in the middle
          circlePos = 50;
          if (monthlyAmount >= 0) {
            circlePos += percentagePosition;
          } else {
            circlePos -= percentagePosition;
          }
          circlePos = `${circlePos}%`;
        }

        // Some default color assumptions
        let strokeColor = svgColor;
        if (withNegYAxis && bicolorLine) {
          strokeColor = monthlyAmount >= 0 ? positiveColor : negativeColor;
        }

        // Check for an override color
        if (svgPoint.overrides && svgPoint.overrides.color) {
          strokeColor = svgPoint.overrides.color;
        }

        let transparent = false;
        if (hideFirstAndLastNode && (i === 0 || i === svgPoints.length - 1)) {
          transparent = true;
        }
        if (svgPoint.hideMarker) {
          transparent = svgPoint.hideMarker;
        }
        if (svgPoint.isInvisible) {
          transparent = true;
        }
        if (transparent) {
          strokeColor = "transparent";
        }

        return (
          <Box
            id={`${chartId}-svg-circle-${svgPoint.rawDate || i}`}
            key={`line-chart-marker-${i}`}
            data-test-id={`${chartId}-svg-circle-wrapper`}
            className="line-chart-tooltip-line-marker"
            position={"relative"}
            style={{
              color: strokeColor,
              width: columnWidth,
            }}
          >
            {!svgPoint.isIntermediate && (
              <Box
                data-test-id={`${chartId}-svg-circle`}
                key={shortDate}
                position={"absolute"}
                height={"8px"}
                width={"8px"}
                backgroundColor={strokeColor}
                left={"50%"}
                borderRadius={"8px"}
                transform={"translateX(-50%) translateY(50%)"}
                transition={"all 0.2s linear"}
                bottom={circlePos}
                zIndex={"2"}
                _hover={{ width: "12px", height: "12px", border: "white 2px solid" }}
                onMouseEnter={() =>
                  addTracking({
                    event: FINANCIALS_ACTION.HOVER_CHART,
                    name: `${chartName} - Line Chart`,
                    state: getMonthIndex(i, withNegYAxis),
                  })
                }
                onMouseLeave={() => removeTracking()}
              />
            )}
            {tooltipContent && tooltipContent(svgPoint, i, data)}
            {!tooltipContent && (
              <SanloTooltip
                width={withNegYAxis ? 240 : 220}
                target={`${chartId}-svg-circle-${i}`}
                placement={"top"}
                id={`${chartId}-line-tooltip-${i}`}
              >
                <Box width={"100%"}>
                  <Text
                    size={"small"}
                    fontWeight={"500"}
                    letterSpacing={"0.01em"}
                    textAlign={"left"}
                    fontStyle={"normal"}
                  >{`${symbols.calendar} ${
                    inProgressMonth ? `Up to ${fullDate(inProgressMonth)}` : title(fullDate())
                  }`}</Text>
                  <TooltipBody>
                    <TooltipContent size={"small"} color={strokeColor}>
                      {insights}
                    </TooltipContent>
                    <TooltipContent size={"small"}>{currencyFormat(monthlyAmount)}</TooltipContent>
                  </TooltipBody>
                  {inProgressMonth && (
                    <TooltipBody>
                      <TooltipContent letterSpacing={"0.02em"}>{inProgressMonthBody}</TooltipContent>
                    </TooltipBody>
                  )}
                </Box>
              </SanloTooltip>
            )}
          </Box>
        );
      })}
    </Chart>
  );
};

const TooltipBody = ({ children }) => (
  <Flex mt={"10px"} justifyContent={"space-between"} borderTop={"1px solid #333338"} padding={"0px"} pt={"10px"}>
    {children}
  </Flex>
);

const TooltipContent = ({ children, color = "textDefault.400", size = "mini", letterSpacing = "0.01em" }) => (
  <Text size={size} letterSpacing={letterSpacing} textAlign={"left"} fontStyle={"normal"} color={color}>
    {children}
  </Text>
);

const Svg = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
