import React, { useState } from "react";

import { MonthlyBurn } from "../../monthly-burn";

import { currencyFormatCentsData } from "@utils/currency";
import { Dropdown } from "../components/Dropdown";
import { Heading, Text, Flex, Box, HStack } from "@chakra-ui/react";
import { formatNumber } from "@src/utils/numbers";
import { ReactComponent as AlertCircle } from "@assets/img/icons/common/alertCircle.svg";
import { tooltipByLabel } from "./GameMetric.tooltips";
import { InfoTooltip } from "@common/tooltips/info-tooltip";

export const GameMetric = ({ key_base = "", metric = {}, initialLoadComplete = false, lastUpdated }) => {
  const { views = [], id = "" } = metric;
  const [view, setView] = useState(views[0] || {});
  const hasViewOptions = views?.length > 1;

  const { label = "-", items = [] } = view;
  // Empty object so that component populates with items that still give it the proper
  // height as compared to empty strings.
  if (!initialLoadComplete) items.push({ value: "-" });

  return (
    <Box
      mt="24px"
      flex={1}
      borderRadius={"12px"}
      p="24px"
      bg="dark.600"
      id={`game-metric-${id}`}
      className={initialLoadComplete ? "" : "loading-shine"}
    >
      <HStack>
        {hasViewOptions ? (
          <Dropdown
            id={`metric-dropdown-${id}`}
            viewOptions={views}
            selectedView={view}
            onSelect={(view) => setView(view)}
          />
        ) : (
          <Text data-test-id={`metric-label-${id}`} size={"small"} letterSpacing={"0.01em"}>
            {label}
          </Text>
        )}
        <InfoTooltip content={{ ...tooltipByLabel[id], lastUpdated }}>
          <AlertCircle data-test-id={`tooltip-trigger-${id}`} color={"#C5C5C5"} />
        </InfoTooltip>
      </HStack>
      {items.map((item) => (
        <GameMetricItem key={`${key_base}_${id}_${item.id}`} key_base={`${key_base}_${id}_${item.id}`} item={item} />
      ))}
    </Box>
  );
};

const GameMetricItem = ({ key_base = "", item = {} }) => {
  const { value = null, type = null, momBps = null } = item;

  let formattedDisplay = value;

  if (type === "CURRENCY") formattedDisplay = currencyFormatCentsData(value).formattedAmount;
  if (type === "PERCENTAGE") formattedDisplay = `${value}%`;
  if (type === "INTEGER") formattedDisplay = formatNumber({ number: Number(value) });

  return (
    <Flex key={key_base} justify={"space-between"} alignItems={"baseline"} mt={"8px"}>
      <Flex alignItems={"center"}>
        <Heading size={"regular"} fontWeight={"600"} mr={"8px"} display={"inline-block"}>
          {formattedDisplay}
        </Heading>
      </Flex>
      <MonthlyBurn value={momBps} />
    </Flex>
  );
};
