import React, { useCallback, useEffect, useState } from "react";
import { HStack, Text, VStack } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { Reorder } from "framer-motion";
import styled from "styled-components";
import { debounce } from "lodash";

import { sectionsThunks, storefrontSectionsSelector, updateSection } from "@redux/webshops/builder/sectionsSlice";

import { Sections } from "../sections";

import { generatePayloads } from "../sections/Items/utils";
import { DEFAULT_DEBOUNCE_TIME, DEFAULT_DEBOUNCE_TIME_LOCAL } from "@utils/consts";
import { useFlags } from "launchdarkly-react-client-sdk";

const StyledDragAndDrop = styled(Reorder.Group)`
  width: 100%;
  & > * > :first-child {
    border-bottom: 1px solid var(dark-300);
  }
`;

export const SidebarContainer = ({ webshop = {}, selectedSection = {}, onSectionClick = () => {} }) => {
  const dispatch = useDispatch();

  const sectionsData = useSelector(storefrontSectionsSelector);
  const headerData = useSelector(storefrontSectionsSelector).header;
  const footerData = useSelector(storefrontSectionsSelector).footer;
  const loginData = useSelector(storefrontSectionsSelector).login;
  const deepLinkData = useSelector(storefrontSectionsSelector).deepLink;

  // eslint-disable-next-line
  const debouncedUpdate = useCallback(
    debounce((data) => {
      dispatch(sectionsThunks.updateSection(data));
    }, DEFAULT_DEBOUNCE_TIME),
    [dispatch],
  );

  // eslint-disable-next-line
  const debouncedLocalUpdate = useCallback(
    debounce((data) => {
      dispatch(updateSection(data));
    }, DEFAULT_DEBOUNCE_TIME_LOCAL),
    [dispatch],
  );

  const onSectionReorder = (sectionId, newOrder) => {
    const section = sectionsData.byId[sectionId];
    const payloads = generatePayloads({
      data: section,
      updates: {
        order: newOrder.indexOf(sectionId),
      },
    });
    debouncedUpdate({
      data: payloads.request,
      webshopId: webshop.id,
      sectionId,
    });
    debouncedLocalUpdate(payloads.response);
  };

  const onSectionDeleteClick = (section) => {
    dispatch(
      sectionsThunks.deleteSection({
        data: {
          type: section.type,
        },
        sectionId: section.id,
        webshopId: webshop.id,
      }),
    );
  };

  useEffect(() => {
    // Flush the debounced function
    return () => {
      debouncedUpdate.flush();
      debouncedLocalUpdate.cancel();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Sidebar
      sectionsData={sectionsData}
      headerData={headerData}
      footerData={footerData}
      loginData={loginData}
      deepLinkData={deepLinkData}
      selectedSection={selectedSection}
      onSectionClick={onSectionClick}
      onSectionReorder={onSectionReorder}
      onSectionDeleteClick={onSectionDeleteClick}
    />
  );
};

export const Sidebar = ({
  sectionsData = {},
  headerData = {},
  footerData = {},
  loginData = {},
  deepLinkData = {},
  selectedSection = {},
  onSectionClick = () => {},
  onSectionReorder = () => {},
  onSectionDeleteClick = () => {},
}) => {
  const { deepLinks } = useFlags();
  const [items, setItems] = useState([]);
  const [grabbedSectionId, setGrabbedSectionId] = useState(null);

  useEffect(() => {
    if (sectionsData.byOrder.length > 0 && !items.length) {
      // Set the initial items
      setItems(sectionsData.byOrder);
    }
    if (sectionsData.byOrder.length !== items.length) {
      // Update the items if the sectionsData changes
      setItems(sectionsData.byOrder);
    }
  }, [sectionsData, items]);

  return (
    <VStack gridArea="builder_sections" bg="dark.700" gap="0" flex={0} overflow={"hidden"}>
      <HStack width={"100%"} padding={"8px 12px"} borderBottom={"var(--border-dark-500)"} gap={"12px"}>
        <Text size={"regular"} fontWeight={500} color={"textSubtext.400"}>
          Sections
        </Text>
      </HStack>

      <VStack width="100%" padding="12px" overflow={"hidden"}>
        <Sections.GLOBAL.Sidebar
          selected={selectedSection.type === Sections.GLOBAL.type}
          onClick={() => {
            onSectionClick({
              type: Sections.GLOBAL.type,
            });
          }}
        />

        <Sections.LOGIN.Sidebar
          selected={selectedSection.type === Sections.LOGIN.type}
          onClick={() => {
            onSectionClick(loginData);
          }}
        />

        {deepLinks && (
          <Sections.DEEPLINK.Sidebar
            selected={selectedSection.type === Sections.DEEPLINK.type}
            onClick={() => {
              onSectionClick(deepLinkData);
            }}
          />
        )}

        <VStack overflowY="auto" width="100%">
          <Text fontWeight={500} size="regular" my="12px">
            Sections
          </Text>
          <VStack gap={0} w={"full"}>
            <Sections.HEADER.Sidebar
              selected={selectedSection.type === Sections.HEADER.type}
              onClick={() => {
                onSectionClick(headerData);
              }}
            />

            {sectionsData.byOrder.length > 0 && (
              <VStack
                width="100%"
                gap={0}
                style={{ userSelect: "none" }} // Disable user selection
              >
                <StyledDragAndDrop
                  values={items}
                  onReorder={(newOrder) => {
                    setItems(newOrder);
                    onSectionReorder(grabbedSectionId, newOrder);
                  }}
                >
                  {items.map((sectionId) => {
                    const sectionData = sectionsData.byId[sectionId];
                    if (!sectionData) return null;

                    const SidebarComponent = Sections[sectionData.type].Sidebar;
                    return (
                      <SidebarComponent
                        key={`key-sidebar-section_${sectionData.id}`}
                        sectionId={sectionId}
                        section={sectionData}
                        title={sectionData.title}
                        selected={selectedSection.data.id === sectionData.id}
                        onClick={() => {
                          onSectionClick(sectionData);
                        }}
                        onDeleteClick={() => {
                          onSectionDeleteClick(sectionData);
                        }}
                        onMouseDown={() => {
                          setGrabbedSectionId(sectionId);
                        }}
                        onMouseUp={() => {
                          setGrabbedSectionId(null);
                        }}
                      />
                    );
                  })}
                </StyledDragAndDrop>
              </VStack>
            )}

            <Sections.FOOTER.Sidebar
              selected={selectedSection.type === Sections.FOOTER.type}
              onClick={() => {
                onSectionClick(footerData);
              }}
            />
          </VStack>
        </VStack>
      </VStack>
    </VStack>
  );
};
