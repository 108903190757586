import { useState } from "react";

export default function useToggle(initialValue) {
  const [value, setValue] = useState(initialValue);

  const toggleValue = (customValue) => {
    if (typeof customValue !== "boolean") {
      return setValue((prevValue) => !prevValue);
    }
    setValue(customValue);
  };

  return [value, toggleValue];
}
