import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import actions from "@redux/actions";

import { BodySmall14 } from "@styled";
import { SanloDropdown } from "@inputs";
import SanloChip from "@components/common/chips/SanloChip";
import isEmpty from "lodash/isEmpty";

import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  Text,
  VStack,
  Input,
  FormControl,
  FormLabel,
  chakra,
} from "@chakra-ui/react";

import { COMPLETE_PROFILE_ROLE_OPTIONS } from "@utils/onboarding";
import { trackGTMEvent, ONBOARDING_ACTIONS } from "@utils/gtm";
import { useForm } from "react-hook-form";
import { useFlags } from "launchdarkly-react-client-sdk";

const Form = chakra("form");

export const CompleteProfile = ({ user, company = {}, isLoading = false }) => {
  const dispatch = useDispatch();
  const { clientMarketingEmailCheckboxEnabled = false } = useFlags();
  const { register, handleSubmit, watch } = useForm();

  const [teamAtCompany, setTeamAtCompany] = useState(null);
  const [isFormReady, setIsFormReady] = useState(false);

  // Tag Manager CompleteProfile Component View Event
  useEffect(() => {
    trackGTMEvent({
      "event": "Component View",
      "Component Name": "CompleteProfile",
    });
    setIsFormReady(true);
  }, []);

  const finishRegistration = (updatedUser) => {
    if (teamAtCompany) {
      updatedUser.teamAtCompany = teamAtCompany.id;
    }
    if (!clientMarketingEmailCheckboxEnabled) delete updatedUser.marketingSubscription;

    trackGTMEvent({
      event: ONBOARDING_ACTIONS.PROFILE_NEXT,
    });
    dispatch(actions.session.finishRegistration.request(updatedUser));
  };

  function formIsDisabled() {
    const allFields = watch();
    delete allFields.marketingSubscription;
    if (isEmpty(allFields)) return true;
    for (let field in allFields) {
      if (Object.hasOwn(allFields, field)) {
        if (isEmpty(allFields[field])) {
          return true;
        }
      }
    }
    return false;
  }

  return (
    <Form
      w={"478px"}
      p={"32px"}
      backgroundColor={"dark.700"}
      borderRadius={"12px"}
      boxShadow={"0 0 48px 0 rgba(0, 0, 0, 0.4)"}
      onSubmit={handleSubmit(finishRegistration)}
    >
      <SanloChip
        content={`Your email has been verified. Let's get going!`}
        state="success"
        style={{
          marginBottom: "24px",
        }}
      />
      <Heading size={"regular"} fontWeight={"600"} color={"textWhite.400"} letterSpacing={"0"}>
        Welcome!
      </Heading>
      <Text size={"small"} mt={"8px"} letterSpacing={"0.14"}>
        Please add additional profile information
      </Text>
      <Box mt={"24px"}>
        <VStack gap={"16px"} mb={"24px"}>
          <FormControl key={"firstName"}>
            <FormLabel display={"flex"} justifyContent={"space-between"} fontSize="sm" color={"textWhite.400"}>
              First Name*
            </FormLabel>
            <Input
              defaultValue={user.firstName || ""}
              borderRadius={"10px"}
              {...register("firstName")}
              data-test-id={"complete-profile-first-name"}
              style={{
                cursor: "auto",
              }}
            />
          </FormControl>

          <FormControl key={"lastName"}>
            <FormLabel display={"flex"} justifyContent={"space-between"} fontSize="sm" color={"textWhite.400"}>
              Last Name*
            </FormLabel>
            <Input
              defaultValue={user.lastName || ""}
              borderRadius={"10px"}
              {...register("lastName")}
              data-test-id={"complete-profile-last-name"}
              style={{
                cursor: "auto",
              }}
            />
          </FormControl>
        </VStack>

        <Flex justifyContent={"space-between"} alignItems={"flex-end"}>
          <FormControl w={"48%"}>
            <FormLabel display={"flex"} justifyContent={"space-between"} fontSize="sm" color={"textWhite.400"}>
              Company*
            </FormLabel>
            <Input
              defaultValue={company.companyName || ""}
              readOnly={Boolean(company.companyName)}
              borderRadius={"10px"}
              maxLength={50}
              {...register("companyName")}
              data-test-id={"complete-profile-company"}
              style={{
                opacity: company.companyName ? 0.4 : 1,
                cursor: company.companyName ? "not-allowed" : "auto",
              }}
            />
          </FormControl>

          <FormControl w={"48%"}>
            <FormLabel display={"flex"} justifyContent={"space-between"} fontSize="sm" color={"textWhite.400"}>
              Role
            </FormLabel>
            <SanloDropdown
              id="complete-profile-role"
              placeholder="Select..."
              options={COMPLETE_PROFILE_ROLE_OPTIONS}
              onSelect={(selectedOption) => {
                setTeamAtCompany(selectedOption);
              }}
              testId="complete-profile-role"
            />
          </FormControl>
        </Flex>
        <Flex mt={"8px"} justifyContent={"flex-end"} color={"textDefault.400"}>
          <BodySmall14>*Required fields</BodySmall14>
        </Flex>
      </Box>

      <VStack gap={"16px"} mt={"80px"}>
        {clientMarketingEmailCheckboxEnabled && (
          <Checkbox
            variant={"red"}
            {...register("marketingSubscription")}
            data-test-id={"complete-profile-marketing-subscription"}
            onChange={(e) => {
              trackGTMEvent({
                event: ONBOARDING_ACTIONS.PROFILE_MARKETING_CHECKBOX,
                action: e.target.checked ? "checked" : "unchecked",
              });
            }}
          >
            <Text size={"small"} letterSpacing={"0.14"}>
              Yes, I want to receive exclusive updates about new Sanlo products and features.
            </Text>
          </Checkbox>
        )}
        <Button
          type="submit"
          variant={"primary"}
          width={"100%"}
          isDisabled={formIsDisabled() || !isFormReady}
          isLoading={isLoading}
          data-test-id="complete-profile-button"
        >
          Next
        </Button>
      </VStack>
    </Form>
  );
};
