import HTTP from "@redux/http";

const SANLO_WEB_API_URL = process.env.REACT_APP_SANLO_WEB_API_URL;
const BASE = `${SANLO_WEB_API_URL}/api/fairground/console/webshops`;

class AssociationsService {
  getAssociations(webshopId) {
    return HTTP.req.get(`${BASE}/${webshopId}/catalog/item/associations`);
  }

  createCategory(data, webshopId) {
    return HTTP.req.post(`${BASE}/${webshopId}/catalog/item/category`, data);
  }

  deleteCategory(categoryId, webshopId) {
    return HTTP.req.delete(`${BASE}/${webshopId}/catalog/item/category/${categoryId}`);
  }

  editCategory(data, webshopId) {
    const { id, name } = data;
    return HTTP.req.put(`${BASE}/${webshopId}/catalog/item/category/${id}`, name);
  }

  createBadge(data, webshopId) {
    return HTTP.req.post(`${BASE}/${webshopId}/catalog/item/badge`, data);
  }

  editBadge(data, webshopId) {
    const { id, label } = data;
    return HTTP.req.put(`${BASE}/${webshopId}/catalog/item/badge/${id}`, label);
  }
}

export default new AssociationsService();
