import SanloWebApiService from "./services";

import actions from "@redux/actions";

import plaidActions, { PLAID_CONNECT, PLAID_STATUS, PLAID_UNLINK_INTEGRATION, PLAID_CLOSING_BALANCE } from "./actions";

import { getRedirectUrl } from "./utils";

const plaidMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    switch (action.type) {
      case PLAID_CONNECT.REQUEST:
        SanloWebApiService.plaidConnect()
          .then((response) => response.data)
          .then((response) => {
            dispatch(plaidActions.connect.response(response));
            window.location.href = getRedirectUrl(action, response);
          })
          .catch((error) => {
            dispatch(plaidActions.connect.error(error));
          });
        break;

      case PLAID_STATUS.REQUEST:
        SanloWebApiService.plaidStatus()
          .then((response) => response.data)
          .then((response) => {
            dispatch(plaidActions.status.response(response));
            dispatch(plaidActions.closingBalance.request());
          })
          .catch((error) => {
            dispatch(plaidActions.status.error(error));
          });
        break;

      case PLAID_UNLINK_INTEGRATION.REQUEST:
        SanloWebApiService.plaidDisconnect()
          .then((response) => response.data)
          .then((response) => {
            dispatch(plaidActions.unlinkIntegration.response(response));
            dispatch(actions.integrations.base.getIntegrationsConnections.request());
          })
          .catch((error) => {
            dispatch(plaidActions.unlinkIntegration.error(error));
          });
        break;

      case PLAID_CLOSING_BALANCE.REQUEST:
        SanloWebApiService.plaidClosingBalance()
          .then((response) => {
            dispatch(plaidActions.closingBalance.response(response.data || {}));
          })
          .catch((error) => {
            dispatch(plaidActions.closingBalance.error(error));
          });
        break;

      default:
        return;
    }
  };

export default plaidMiddleware;
