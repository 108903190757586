import React, { useState } from "react";

import { ResetPasswordRecovery } from "@pages/authentication/flows/ResetPasswordRecovery";
import { EmailSent } from "@pages/authentication/flows/EmailSent";
import AuthenticationContainer from "../../AuthenticationContainer";

export const ResetPasswordFlow = () => {
  const [step, setStep] = useState(0);
  const [email, setEmail] = useState("");

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const passwordResetSteps = [
    <ResetPasswordRecovery key="ResetPasswordRecovery" onNextStep={handleNextStep} email={email} setEmail={setEmail} />,
    <EmailSent key="EmailSent" email={email} />,
  ];

  return <AuthenticationContainer>{passwordResetSteps[step]}</AuthenticationContainer>;
};
