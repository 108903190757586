import React from "react";
import moment from "moment";

import generalCopies from "@utils/text/general";

import { ReactComponent as SandClockIcon } from "@assets/img/icons/common/sandClock.svg";
import { ReactComponent as ErrorIcon } from "@assets/img/icons/common/alertTriangle.svg";
import { ReactComponent as WarningIcon } from "@assets/img/icons/common/alertCircle.svg";
import symbols from "@utils/symbols";

export const integrationStates = {
  CONNECTOR_ERROR: {
    buttonLabel: "We're identifying an issue...",
    state: "error",
  },
  NEEDS_RECONNECTION: {
    buttonLabel: "Reconnect",
    state: "reconnect",
    hasAction: true,
    hover: true,
  },
  INVALID_API_TOKEN: {
    buttonLabel: "Reconnect",
    state: "reconnect",
    hasAction: true,
    hover: true,
  },
  INVALID_APP_ID: {
    buttonLabel: "Reconnect",
    state: "reconnect",
    hasAction: true,
    hover: true,
  },
  NOT_CONNECTED: {
    buttonLabel: "Connect",
    state: "connect",
    hasAction: true,
    hover: true,
  },
  DRAFT_CONNECTION: {
    buttonLabel: "Complete Connecting",
    state: "completeConnecting",
    hasAction: true,
    hover: true,
  },
  CONNECTED: {
    buttonLabel: "Connected",
    state: "success",
  },
  PULLING_DATA: {
    buttonLabel: "Connecting...",
    state: "verifyingConnection",
  },
  VERIFYING_CONNECTION: {
    buttonLabel: "Connecting...",
    state: "verifyingConnection",
  },
  NO_PERMISSIONS: {
    buttonLabel: "Reconnect",
    state: "reconnect",
    hasAction: true,
    hover: true,
  },
};

export const syncingTime = {
  adjust: 24,
  appsflyer: 24,
  googleplay: 48,
  appstore: 24,
  plaid: 24,
};

export const integrationCardLabels = (platform, lastUpdated) => ({
  CONNECTED: {
    label: `Updated ${moment
      .utc(lastUpdated || new moment())
      .local()
      .fromNow()}`,
    state: "default",
  },
  NEEDS_RECONNECTION: {
    label: (
      <>
        <WarningIcon />
        Please re-enter credentials
      </>
    ),
    state: "warning",
  },
  INVALID_APP_ID: {
    label: (
      <>
        <WarningIcon />
        Please re-enter credentials
      </>
    ),
    state: "warning",
  },
  INVALID_API_TOKEN: {
    label: (
      <>
        <WarningIcon />
        Please re-enter credentials
      </>
    ),
    state: "warning",
  },
  CONNECTOR_ERROR: {
    label: (
      <>
        <ErrorIcon />
        Connection broken
      </>
    ),
    state: "error",
  },
  VERIFYING_CONNECTION: {
    label: "This may take 24 hours",
    state: "default",
  },
  PULLING_DATA: {
    label: `This may take up to ${syncingTime[platform.id] || 24} hours`,
    state: "default",
  },
  DRAFT_CONNECTION: {
    label: (
      <>
        <SandClockIcon />
        You're almost there!
      </>
    ),
    state: "success",
  },
  NO_PERMISSIONS: {
    label: (
      <>
        <WarningIcon />
        Needs attention
      </>
    ),
    state: "warning",
  },
});

export const integrationToolTip = ({ lastUpdated }) => ({
  heading: `${symbols.lastUpdate} Last Updated ${moment
    .utc(lastUpdated || new moment())
    .local()
    .fromNow()}`,
  content: generalCopies.phrase.needs_attention,
});
