import React from "react";
import { HStack, Icon, IconButton, Image, Link, Text } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { builderThunks, builderSelector, updateIsMobileMode } from "@redux/webshops/builder/builderSlice";

// The resolution icons
import { ReactComponent as LaptopIcon } from "@assets/img/icons/common/Laptop.svg";
import { ReactComponent as SmartphoneIcon } from "@assets/img/icons/common/Smartphone.svg";
import { ReactComponent as HomeIcon } from "@assets/img/icons/common/home.svg";

// import { ReactComponent as PlayIcon } from "@assets/img/icons/common/Play.svg";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { webshopsSelector } from "@redux/webshops/webshopsSlice";
import { GradientButton } from "@common/gradient-button/GradientButton";
import { useFlags } from "launchdarkly-react-client-sdk";

export const Header = () => {
  const { id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const { showCheckoutBuilder = false } = useFlags();
  const {
    publish: { requestStatus },
    view: { isMobile, previewMode },
  } = useSelector(builderSelector);
  const { selectedWebshop: webshop } = useSelector(webshopsSelector);

  const { name = "", icon = "", id: webshopId = "" } = webshop || {};

  if (previewMode.active) return null;
  return (
    <HStack bg="dark.700" borderBottom={"1px solid transparent"} borderBottomColor={"dark.600"} px={"24px"} py="16px">
      <IconButton as={NavLink} icon={<Icon as={HomeIcon} />} to="/webshops/projects" />
      <HStack marginLeft={"16px"}>
        <Image src={icon} width={"32px"} height={"32px"} rounded={"full"} objectFit={"cover"} alt={`${name} icon`} />
        <Text size={"lg"} fontWeight={500} color={"textWhite.400"}>
          {name}
        </Text>
      </HStack>
      <HStack gap="16px">
        <Link variant={"primary"} as={NavLink} to={`/webshops/projects/${id}/settings`}>
          {({ isActive }) => <Text color={isActive ? "brandPurple.400" : "textWhite.400"}>Settings</Text>}
        </Link>
        <Link variant={"primary"} as={NavLink} to={`/webshops/projects/${id}/catalog`}>
          {({ isActive }) => <Text color={isActive ? "brandPurple.400" : "textWhite.400"}>Catalog</Text>}
        </Link>
        <Link variant={"primary"} as={NavLink} to={`/webshops/projects/${id}/builder`}>
          {({ isActive }) => <Text color={isActive ? "brandPurple.400" : "textWhite.400"}>Shop Builder</Text>}
        </Link>
        {showCheckoutBuilder && (
          <Link variant={"primary"} as={NavLink} to={`/webshops/projects/${id}/checkout/builder`}>
            {({ isActive }) => <Text color={isActive ? "brandPurple.400" : "textWhite.400"}>Checkout</Text>}
          </Link>
        )}
        {location.pathname.includes("builder") && (
          <HStack border="1px solid #333338" borderRadius="8px" gap="1px" padding="2px">
            <IconButton
              icon={<Icon as={LaptopIcon} />}
              minW={"32px"}
              h={"32px"}
              onClick={() => dispatch(updateIsMobileMode(false))}
              aria-label="desktop screen"
              style={{
                bg: isMobile ? "transparent" : "dark.400",
              }}
            />
            <IconButton
              icon={<Icon as={SmartphoneIcon} />}
              minW={"32px"}
              h={"32px"}
              aria-label="mobile screen"
              onClick={() => dispatch(updateIsMobileMode(true))}
              style={{
                bg: isMobile ? "dark.400" : "transparent",
              }}
            />
          </HStack>
        )}
      </HStack>
      <HStack gap={"24px"} ml="auto">
        {/*
        TODO: Uncomment this when we have a preview mode
        <IconButton
          minW="32px"
          h="32px"
          icon={<Icon as={PlayIcon} />}
          onClick={() => {
            navigate(`/webshops/projects/${id}/builder`, { state: { from: location.pathname } });
            dispatch(updatePreviewMode({ active: true }));
          }}
        /> */}
        <GradientButton
          isDisabled={requestStatus.pending}
          onClick={() => dispatch(builderThunks.publishStorefront(webshopId))}
        >
          Publish
        </GradientButton>
      </HStack>
    </HStack>
  );
};
