import { Text, VStack } from "@chakra-ui/react";
import { SectionFieldTooltip } from "../SectionFieldTooltip";
import EndDateImage from "@assets/img/webshops/catalog/end-date.png";
import QuantityPerPlayerImage from "@assets/img/webshops/catalog/quantity-per-player.png";
import TotalQuantityImage from "@assets/img/webshops/catalog/total-quantity.png";

export const ItemStatusField = () => {
  return (
    <SectionFieldTooltip placement={"top-end"}>
      <VStack gap={"15px"}>
        <Text size={"smallAlt"} letterSpacing={"0.14px"} color={"textWhite.400"}>
          Indicates if the item is available for purchase on your webshop.
        </Text>
        <Text size={"smallAlt"} letterSpacing={"0.14px"} color={"textSubtext.400"}>
          <Text as={"span"} color={"textWhite.400"}>
            Note:
          </Text>{" "}
          Items will be available by default, but item status will change based on the Start date, End date, and Total
          quantity.
        </Text>
      </VStack>
    </SectionFieldTooltip>
  );
};

export const ItemStartDateField = () => {
  return (
    <SectionFieldTooltip placement={"top-end"}>
      <Text size={"smallAlt"} letterSpacing={"0.14px"} color={"textWhite.400"}>
        Items will be unavailable for purchase prior to the date selected here.
      </Text>
    </SectionFieldTooltip>
  );
};

export const ItemEndDateField = () => {
  return (
    <SectionFieldTooltip imageSrc={EndDateImage} placement={"top-end"}>
      <Text size={"smallAlt"} letterSpacing={"0.14px"} color={"textWhite.400"}>
        Items will be unavailable for purchase after the date selected here.
      </Text>
    </SectionFieldTooltip>
  );
};

export const ItemRecurrence = () => {
  return (
    <SectionFieldTooltip placement={"top-end"}>
      <Text size={"smallAlt"} letterSpacing={"0.14px"} color={"textWhite.400"}>
        Determines if, and how often, an item being available with reoccur. An end date will override any recurrence.
        Any quantity limit will reset per the chosen recurrence.
      </Text>
    </SectionFieldTooltip>
  );
};

export const ItemTotalQuantityField = () => {
  return (
    <SectionFieldTooltip imageSrc={TotalQuantityImage} placement={"top-end"}>
      <Text size={"smallAlt"} letterSpacing={"0.14px"} color={"textWhite.400"}>
        Specifies the total number of items available for purchase by your player base.
      </Text>
    </SectionFieldTooltip>
  );
};

export const ItemQuantityPerPlayer = () => {
  return (
    <SectionFieldTooltip imageSrc={QuantityPerPlayerImage} placement={"top-end"}>
      <Text size={"smallAlt"} letterSpacing={"0.14px"} color={"textWhite.400"}>
        Specifies the maximum number of times an individual player can purchase this item.
      </Text>
    </SectionFieldTooltip>
  );
};

export const TOOLTIPS = {
  ITEM: {
    STATUS: <ItemStatusField />,
    START_TIME: <ItemStartDateField />,
    END_TIME: <ItemEndDateField />,
    RECURRENCE: <ItemRecurrence />,
    TOTAL_QUANTITY: <ItemTotalQuantityField />,
    QUANTITY: <ItemQuantityPerPlayer />,
  },
  //for the time being we will use the same tooltips
  SERIES: {
    STATUS: <ItemStatusField />,
    START_TIME: <ItemStartDateField />,
    END_TIME: <ItemEndDateField />,
    RECURRENCE: <ItemRecurrence />,
    END_PARAMETER: null,
    TOTAL_QUANTITY: <ItemTotalQuantityField />,
  },
};
