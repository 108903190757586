import React, { useState } from "react";

import SanloWebApiService from "@services/SanloWebApiService";

import { BodySmall14 } from "@styled";
import AuthenticationContainer from "@pages/authentication/AuthenticationContainer";
import {
  SanloModal,
  SanloModalTitle,
  SanloModalContent,
  SanloModalDivider,
  SanloModalFooter,
} from "@components/common/modals/SanloModal/SanloModal";
import { SanloButton, SanloInput } from "@inputs";

export const ResetPasswordExpired = () => {
  const [requested, setRequested] = useState(false);
  const [email, setEmail] = useState();

  const handleSendResetPasswordEmail = () => {
    setRequested(true);
    SanloWebApiService.sendChangePasswordLink(email);
  };

  const handleGoToSignup = () => {
    window.location.href = process.env.REACT_APP_SANLO_WEB_API_URL;
  };

  return (
    <AuthenticationContainer error>
      <SanloModal>
        <SanloModalTitle>Reset your password</SanloModalTitle>
        <SanloModalContent>
          <div className="auth-card-note">
            {!requested && <BodySmall14>This password reset link has expired.</BodySmall14>}
            {requested && <BodySmall14>New link sent to {email}</BodySmall14>}
          </div>
          <SanloInput hasFocus value={email} setValue={setEmail} label="Email" placeholder="Enter your email" />
          <SanloButton
            isDisabled={requested}
            onClick={handleSendResetPasswordEmail}
            label="Request New Reset Link"
            style={{
              marginTop: "24px",
            }}
          />
        </SanloModalContent>
        <SanloModalDivider />
        <SanloModalFooter center>
          <div
            style={{
              textAlign: "left",
              marginBottom: "24px",
            }}
          >
            Don't have an account? <SanloButton isText onClick={handleGoToSignup} label="Sign Up" />
          </div>
          Powered by <img src={require("@assets/img/logos/auth0-logo.png")} alt="" />
        </SanloModalFooter>
      </SanloModal>
    </AuthenticationContainer>
  );
};
