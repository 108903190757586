import React from "react";
import classnames from "classnames";

import { BodySmallPlus } from "@styled";
import StyledPrimaryButton from "./StyledPrimaryButton";
import { Loader } from "@components/common/loaders";

const PrimaryButton = ({
  className = "",
  tagTarget = "",
  label = "",
  loading = false,
  onClick = () => {},
  disabled = false,
  secondary = false,
  tagClassName = "",
}) => {
  return (
    <StyledPrimaryButton
      className={classnames(className, tagTarget)}
      secondary={secondary}
      onClick={onClick}
      disabled={disabled}
    >
      <BodySmallPlus className={classnames("button-label", tagTarget, tagClassName)}>
        {loading ? <Loader className="button-loader" /> : label}
      </BodySmallPlus>
    </StyledPrimaryButton>
  );
};

export default PrimaryButton;
