import { GlobalSection } from "./Global";
import { HeaderSection } from "./Header";
import { FooterSection } from "./Footer";
import { ItemsSection } from "./Items";
import { BannerSection } from "./Banner";
import { FeaturedSection } from "./Featured";
import { LoginSection } from "./Login";
import { NavigationSection } from "./Navigation";
import { DeepLinkSection } from "./DeepLink";

export const Sections = {
  GLOBAL: GlobalSection,
  HEADER: HeaderSection,
  FOOTER: FooterSection,
  ITEMS: ItemsSection,
  BANNER: BannerSection,
  FEATURED_ITEM: FeaturedSection,
  LOGIN: LoginSection,
  NAVIGATION: NavigationSection,
  DEEPLINK: DeepLinkSection,
};
