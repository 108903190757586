import { useEffect, useMemo, useRef } from "react";
import debounce from "lodash.debounce";

export function useAutoSave(
  saveFunction,
  delay,
  debounceOptions = { leading: false, trailing: true },
  flushOnUnmount = true,
) {
  // useRef to store the saveFunction. saveFuntion shouldn't change on re-renders that's why we use useRef.
  const saveFunctionRef = useRef(saveFunction);
  // Update saveFunctionRef on each render
  saveFunctionRef.current = saveFunction;
  const debounceOptionsRef = useRef(debounceOptions);
  debounceOptionsRef.current = debounceOptions;

  /*
   * At this point you might be wondering why we are using useRef for saveFunction and debounceOptions. No worries, I don't know either.
   * This comes to understand how React works. When you pass a function to a component, it's a new reference on every render and the
   * same for objects. So, whenever the component using the useAutoSave hook re-renders, the saveFunction and debounceOptions will be
   * new references. We want to keep the same reference for saveFunction and debounceOptions so that the debouncedSave function
   * doesn't change on every render. That's why we use useRef to keep the same reference on every render. And if you think about it, it's weird
   * that the saveFunction and debounceOptions change on every render.
   */

  const debouncedSave = useMemo(
    () => debounce((args) => saveFunctionRef.current(args), delay, debounceOptionsRef.current),
    [delay],
  );

  useEffect(() => {
    return () => {
      if (flushOnUnmount) {
        debouncedSave.flush();
        return;
      }
      debouncedSave.cancel();
    };
  }, [debouncedSave, flushOnUnmount]);

  return debouncedSave;
}
