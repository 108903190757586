import styled from "styled-components";

const StyledSanloChip = styled.div`
  & {
    width: 100%;
    background-color: ${({ theme, state }) => theme[state + "BackgroundColor"]};
    color: ${({ theme, state }) => theme[state + "FontColor"]};
    padding: 12px;
    border-radius: 10px;
    display: flex;

    .chip-icon {
      fill: ${({ theme, state }) => theme[state + "FontColor"]};
      margin-right: 8px;
    }

    .chip-description {
      width: fill-available;
    }
  }
`;

export default StyledSanloChip;
