import { useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { debounce } from "lodash";

import { sectionsThunks, updateSection } from "@redux/webshops/builder/sectionsSlice";

import { DEFAULT_DEBOUNCE_TIME } from "@utils/consts";

export const useSectionUpdate = (webshopId, sectionId, data, generatePayloads) => {
  const dispatch = useDispatch();

  // eslint-disable-next-line
  const debouncedUpdate = useCallback(
    debounce((data) => {
      dispatch(sectionsThunks.updateSection(data));
    }, DEFAULT_DEBOUNCE_TIME),
    [dispatch],
  );

  const onUpdate = (updates) => {
    const payloads = generatePayloads({
      data,
      updates,
    });
    debouncedUpdate({
      data: payloads.request,
      webshopId: webshopId,
      sectionId: sectionId,
    });
    dispatch(updateSection(payloads.response));
  };

  useEffect(() => {
    return () => {
      debouncedUpdate.flush();
    };
    // eslint-disable-next-line
  }, []);

  return [onUpdate];
};
