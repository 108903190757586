import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFlags } from "launchdarkly-react-client-sdk";
import isEmpty from "lodash/isEmpty";
import actions from "@redux/actions";

import Upload from "./Upload";

import { parseFileName, checkAllSuccess } from "./utils";

import { setFileList, clearFileData, clearAllFileData, uploadThunks } from "@redux/uploads/uploadSlice";

const UploadContainer = ({ isBankUpload = false, isAccordion, label, toggleUpload, handleToggleUpload }) => {
  const dispatch = useDispatch();

  const { clientStatementUploadingEnabled } = useFlags();

  const {
    uploadedFiles,
    signedURL,
    useSignedURL,
    fileUploaded,
    successfulFiles,
    errorFiles,
    isUploading,
    useSignedComplete,
  } = useSelector((state) => state.uploads.data);
  const {
    signedURL: signedURLStatus,
    useSignedURL: useSignedURLStatus,
    fileUploaded: fileUploadedStatus,
  } = useSelector((state) => state.uploads.requestStatus);

  const [files, setFiles] = useState({});
  const [allFilesSuccessful, setAllFilesSuccessful] = useState(false);

  const lastAction = useRef("none");

  useEffect(() => {
    if (lastAction.current !== "remove") {
      const allSuccess = checkAllSuccess(files, fileUploaded);
      setAllFilesSuccessful(allSuccess);
    }

    const filesKeys = Object.keys(files);
    if (!filesKeys.length) return;

    dispatch(setFileList(filesKeys));

    let arrFiles = [];
    // go through all the files and generated signedURLs
    filesKeys.forEach((fileName) => {
      const file = files[fileName];
      // Ignore any already succeeded files
      const fileUploadedData = fileUploaded[fileName] || {};
      if (fileUploadedData.success) return;
      // Check to see if the file is already being handled
      const status = signedURLStatus[fileName] || {};
      if (status.pending || status.completed || status.error) return;

      arrFiles.push({
        fileName,
        contentType: file.type,
        documentType: "BANK_STATEMENT",
      });
    });

    if (!isEmpty(arrFiles)) dispatch(uploadThunks.signedURL({ documentType: "BANK_STATEMENT", files: arrFiles }));

    // eslint-disable-next-line
  }, [files]);

  useEffect(() => {
    Object.keys(signedURL).forEach((fileName) => {
      const signedURLData = signedURL[fileName];
      const { error = false, contentType, signedUrl: URL } = signedURLData;

      if (error) return;
      // Check to see if the file is already being handled
      const status = useSignedURLStatus[fileName] || {};
      if (status.pending || status.completed || status.error) return;

      dispatch(
        uploadThunks.useSignedURL({
          url: URL,
          data: {
            fileName,
            contentType,
            file: files[fileName],
          },
        }),
      );
    });
    // eslint-disable-next-line
  }, [signedURL]);

  useEffect(() => {
    if (useSignedComplete) {
      let arrUseSignedURL = [];

      Object.keys(useSignedURL).forEach((fileName) => {
        const useSignedURLData = useSignedURL[fileName];
        const { error = false } = useSignedURLData;

        if (error) return;
        // Check to see if the file is already being handled
        const status = fileUploadedStatus[fileName] || {};
        if (status.pending || status.completed || status.error) return;

        // Grab the object name from the signedURL data
        const signedURLData = signedURL[fileName];
        const { objectName } = signedURLData;

        arrUseSignedURL.push({ fileName, objectName, documentType: "BANK_STATEMENT" });
      });

      if (!isEmpty(arrUseSignedURL))
        dispatch(uploadThunks.saveToSanloDB({ documentType: "BANK_STATEMENT", data: arrUseSignedURL }));
    }

    // eslint-disable-next-line
  }, [useSignedComplete]);

  useEffect(() => {
    if (lastAction.current !== "remove") {
      const allSuccess = checkAllSuccess(files, fileUploaded);
      setAllFilesSuccessful(allSuccess);
    }
    // eslint-disable-next-line
  }, [fileUploaded]);

  useEffect(() => {
    if (!allFilesSuccessful) return;
    // clear all files from the store
    dispatch(clearAllFileData());
    // fetch all documents after successfully uploading one
    dispatch(uploadThunks.getDocuments("BANK_STATEMENT"));
    // tell Sanlo uploading is finished (noticiation to looks at documents)
    dispatch(uploadThunks.finishedUploading());
    // eslint-disable-next-line
  }, [allFilesSuccessful]);

  useEffect(() => {
    dispatch(actions.banking.status.request());
    // eslint-disable-next-line
  }, []);

  if (!clientStatementUploadingEnabled) return null;

  return (
    <Upload
      toggleUpload={toggleUpload}
      handleToggleUpload={handleToggleUpload}
      isUploading={isUploading}
      allFilesSuccessful={allFilesSuccessful}
      onUpload={(files) => {
        if (!files) return;
        if (!Object.keys(files).length) return;
        const newFiles = {};
        Object.keys(files).forEach((fileName) => {
          newFiles[parseFileName(fileName)] = files[fileName];
        });
        lastAction.current = "add";
        setFiles(newFiles);
      }}
      successfulFiles={successfulFiles}
      errorFiles={errorFiles}
      uploadedFiles={uploadedFiles}
      onRemoveFile={(fileName) => {
        const parsedName = parseFileName(fileName);
        delete files[parsedName];
        lastAction.current = "remove";
        setFiles({ ...files });
        dispatch(clearFileData(parsedName));
      }}
      onReset={() => {
        lastAction.current = "none";
        setFiles({});
        dispatch(clearFileData());
      }}
      isBankUpload={isBankUpload}
      isAccordion={isAccordion}
      label={label}
    />
  );
};

export default UploadContainer;
