import HTTP from "@redux/http";

const SANLO_WEB_API_URL = process.env.REACT_APP_SANLO_WEB_API_URL;
const BASE = `${SANLO_WEB_API_URL}/api/fairground/console/webshops`;

class SettingsService {
  getSettings(webshopId) {
    return HTTP.req.get(`${BASE}/${webshopId}/storefront/settings`);
  }

  addWebhookUrl(data, webshopId) {
    return HTTP.req.post(`${BASE}/${webshopId}/storefront/settings/auth/webhook`, data);
  }

  generateHMACKey(webshopId) {
    return HTTP.req.post(`${BASE}/${webshopId}/storefront/settings/authentication/secret`);
  }

  generateSDKToken(webshopId) {
    return HTTP.req.post(`${BASE}/${webshopId}/storefront/settings/sdk-client-token`);
  }

  setSiteDetails(data, webshopId) {
    return HTTP.req.post(`${BASE}/${webshopId}/storefront/settings`, data);
  }

  setPurchaseSettings(data, webshopId) {
    return HTTP.req.post(`${BASE}/${webshopId}/storefront/settings/purchase-webhook`, data);
  }
}

export default new SettingsService();
