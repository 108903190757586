import styled from "styled-components";

const StyledSuggestIntegration = styled.div`
  margin-bottom: 1rem;

  .cant-see-platform {
    color: ${({ theme }) => theme.textGray};

    .red-label {
      margin-left: 0.5rem;
      cursor: pointer;
      color: ${({ theme }) => theme.colorRed};
    }
  }

  .suggest-platform-input-action {
    display: grid;
    grid-template-columns: 3fr 0.9fr;
    grid-column-gap: 32px;
    margin-top: 0.5rem;
  }
`;

export default StyledSuggestIntegration;
