import { Box, Button, FormControl, FormLabel, Heading, HStack, Switch, Text, VStack } from "@chakra-ui/react";
import {
  addRecentlyAddedCustomFont,
  resetSelectedCustomFont,
  selectedCustomFontSelector,
} from "@redux/webshops/builder/typographySlice";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export function FontRights({ handleNextStep, handlePrevStep }) {
  const selectedCustomFont = useSelector(selectedCustomFontSelector);
  const dispatch = useDispatch();
  const [hasAccepted, setHasAccepted] = useState(false);
  if (!selectedCustomFont) {
    dispatch(resetSelectedCustomFont());
    handlePrevStep();
  }

  function handleContinue() {
    dispatch(addRecentlyAddedCustomFont(selectedCustomFont));
    handleNextStep();
  }

  return (
    <VStack alignItems={"stretch"} flex="1 0 448px">
      <VStack alignItems={"stretch"} gap={"8px"} mt={"8px"} mb="16px" pb="16px">
        <Heading size={"small"} fontWeight={600}>
          Rights to Font Files
        </Heading>
        <Text size="small">
          Please confirm that you have obtained the font file from a legitimate source and possess the appropriate
          rights and permissions for its intended use on a website.
        </Text>
      </VStack>
      <Box py="16px" mb="64px">
        <Text fontWeight={600} size="small" mb="16px" color="textWhite.400">
          Accept and Confirm
        </Text>
        <FormControl display={"flex"} gap="16px">
          <Switch id="font-rights" size="sm" isChecked={hasAccepted} onChange={() => setHasAccepted(!hasAccepted)} />
          <FormLabel htmlFor="font-rights" fontSize={"small"} fontWeight={400} color={"textDefault.400"}>
            Yes, I acknowledge I do have the legal rights to use the web font I am about to upload.
          </FormLabel>
        </FormControl>
      </Box>
      <HStack justifyContent={"space-between"} mt="auto">
        <Button h="32px" variant="primary" onClick={handlePrevStep}>
          Cancel
        </Button>
        <Button h="32px" onClick={handleContinue} isDisabled={!hasAccepted}>
          Continue
        </Button>
      </HStack>
    </VStack>
  );
}
