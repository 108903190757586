import React from "react";

import { InternalLink } from "@inputs";
import { trackGTMEvent, FINANCIALS_ACTION } from "@utils/gtm";

export const getCashflowAccordionContent = (label) => (
  <>
    {`For a complete picture of your finances, please visit the `}
    <InternalLink
      to="/integrations"
      data-test-id={"integration-page-link"}
      onClick={() =>
        trackGTMEvent({
          event: FINANCIALS_ACTION.INTEGRATION_PAGE_LINK,
          tooltip: label,
        })
      }
    >
      Integrations page
    </InternalLink>
    {` and ensure you've connected all bank accounts associated with your business.`}
  </>
);
