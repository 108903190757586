import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFlags } from "launchdarkly-react-client-sdk";

import { TitleSmallPlus } from "@styled";
import DashboardCardWrapper from "@components/dashboard/DashboardCardWrapper";
import FadeSingleTransition from "@components/common/animated/FadeSingleTransition";
import OnboardingDashboard from "@components/dashboard/onboarding/OnboardingDashboardContainer";
import { FinancialIntelligenceCards } from "@components/dashboard/company-finances/financialIntelligence";
import AdvanceListContainer from "@components/advance/advance-list/AdvanceListContainer";
import AdvanceCard from "@components/advance/AdvanceCard";
import { DailyRevenueContainer } from "@components/dashboard/daily-revenue/DailyRevenueContainer";
import { StyledPageContainer, PageHeader } from "@components/page";
import OfferContainer from "@components/gc-offers/OfferContainer";
import ErrorBoundary from "@components/ErrorBoundary";
import UpcomingPayoutsContainer from "@components/dashboard/upcoming-payouts/UpcomingPayoutsContainer";
import { ProjectsRequestAccess } from "@pages/webshops/projects/ProjectsPage";
import { UI_STAGES } from "@pages/webshops/projects/utils";

import { scrollToTarget } from "@utils/general";
import { Flex } from "@chakra-ui/react";
import { paymentsThunks } from "@redux/payments/paymentsSlice";
import { formatToMonthDay } from "@utils/time";

import { webshopAccessRequestSelector, webshopsThunks } from "@redux/webshops/webshopsSlice";

import { trackGTMEvent } from "@utils/gtm";

const DashboardPage = () => {
  const dispatch = useDispatch();
  const {
    onboardingExperienceVisible,
    clientGrowthCapitalOffersVisible,
    clientWebshopsAccessGranted = false,
    clientWebshopsPageEnabled = false,
  } = useFlags();

  const user = useSelector((state) => state.session.user);
  const firstName = useSelector((state) => state.session.user.firstName) || "User";
  const company = useSelector((state) => state.session.company);

  const integrations = useSelector((state) => state.integrations);
  const integrationsStatus = integrations.base.requestStatus.getData;

  const onboarding = useSelector((state) => state.session.onboarding);
  const onboardingStatus = onboarding.requestStatus.getData;

  const activeAdvances = useSelector((state) => state.integrations.advances.data.lists.activeAdvances);
  const advanceSummary = useSelector((state) => state.integrations.advances.data.summary);
  const { requestedCapitalActive } = advanceSummary;
  const requestStatus = useSelector((state) => state.offers.requestStatus);

  const webshopsRequested = useSelector(webshopAccessRequestSelector);

  const isOnboardingStatusCardDismissed = useSelector(
    (state) => state.session.company.onboarding.isStatusCardDismissed,
  );

  useEffect(() => {
    dispatch(paymentsThunks.getUpcomingPayments());
  }, [dispatch]);

  useEffect(() => {
    trackGTMEvent({
      "event": "Component View",
      "Component Name": "Dashboard Page",
    });
  }, []);

  return (
    <ErrorBoundary name="dashboard-page-container">
      <StyledPageContainer>
        <PageHeader
          header={`Welcome ${firstName || "User"}!`}
          subheader={`Here's what's going on with your finances at ${company.companyName} as of ${formatToMonthDay()}!`}
        />

        <Flex maxW={"1370px"} flexDir={"column"}>
          {!clientWebshopsAccessGranted && clientWebshopsPageEnabled && (
            <ProjectsRequestAccess
              uiStage={webshopsRequested ? UI_STAGES.NO_ACCESS : UI_STAGES.NOT_REQUESTED}
              onRequestAccess={() => {
                dispatch(webshopsThunks.accessRequest());
              }}
            />
          )}

          <ErrorBoundary track name="dashboard-financial-intelligence-cards-section">
            <DashboardCardWrapper>
              <FinancialIntelligenceCards />
            </DashboardCardWrapper>
          </ErrorBoundary>

          <ErrorBoundary track name="dashboard-advance-card-section" show={activeAdvances.length >= 2}>
            <AdvanceListContainer />
          </ErrorBoundary>

          <ErrorBoundary track name="dashboard-upcoming-payouts-section">
            <DashboardCardWrapper data-scroll-target="upcoming-payments" data-test-id="upcoming-payments-wrapper">
              <UpcomingPayoutsContainer />
            </DashboardCardWrapper>
          </ErrorBoundary>

          <ErrorBoundary track name="dashboard-daily-revenue-section">
            <DailyRevenueContainer />
          </ErrorBoundary>

          <>
            <ErrorBoundary
              track
              name="dashboard-onboarding-section"
              show={onboardingExperienceVisible && !requestStatus.pending}
            >
              <FadeSingleTransition show={!isOnboardingStatusCardDismissed}>
                <OnboardingDashboard
                  userName={user.firstName}
                  dashboardLoading={integrationsStatus.completed && onboardingStatus.completed}
                  companyName={company.companyName}
                  companyId={company.id}
                  onboarding={onboarding}
                />
              </FadeSingleTransition>
            </ErrorBoundary>

            <ErrorBoundary track name="dashboard-growth-capital-offers-section" show={clientGrowthCapitalOffersVisible}>
              {/* Growth Capital */}
              <OfferContainer
                onTrackAdvance={() => {
                  scrollToTarget("upcoming-payments");
                }}
                onboardingType={onboarding.data.onboardingType}
              />
            </ErrorBoundary>

            <ErrorBoundary track name="dashboard-advance-card-section" show={activeAdvances.length === 1}>
              <DashboardCardWrapper>
                {/* Only show title if there are no offers */}
                {onboardingExperienceVisible && isOnboardingStatusCardDismissed && !requestedCapitalActive && (
                  <div className="single-advance-title">
                    <TitleSmallPlus>Your growth capital</TitleSmallPlus>
                  </div>
                )}
                <AdvanceCard
                  noHeader
                  userFirstName={firstName}
                  advance={integrations.advances.data.lists.activeAdvances[0]}
                  advancePlatforms={integrations.advances.data.lists.platforms}
                />
              </DashboardCardWrapper>
            </ErrorBoundary>
          </>
        </Flex>
      </StyledPageContainer>
    </ErrorBoundary>
  );
};

export default DashboardPage;
