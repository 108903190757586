import { switchAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/styled-system";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const containerMdSize = defineStyle({
  w: "28px",
});

const thumbMdSize = defineStyle({
  w: "16px",
  h: "16px",
});

const sizes = {
  md: definePartsStyle({ container: containerMdSize, thumb: thumbMdSize }),
};

const baseStyle = definePartsStyle({
  thumb: {
    bg: "dark.800",
  },
  track: {
    bg: "dark.200",
    _checked: {
      bg: "brandGreen.400",
    },
    border: "0.5px solid rgba(0, 0, 0, 0.04)",
  },
});

export const Switch = defineMultiStyleConfig({
  baseStyle,
  sizes,
});
