import {
  getErrorStatus,
  getRequestStatus,
  getResponseStatus,
  integrationInitialState,
  setStatusDefaults,
  stateSet,
} from "@utils/redux";
import { BANKING } from "@utils/consts";
import { PLAID_CLOSING_BALANCE, PLAID_CONNECT, PLAID_STATUS, PLAID_UNLINK_INTEGRATION } from "./actions";

const initialState = integrationInitialState({
  id: "plaid",
  name: "Plaid",
  type: BANKING,
  data: {
    institutions: [],
    accounts: [
      {
        currency: "",
        currentBalance: "",
        accounts: [],
        historicalBalances: [],
      },
    ],
  },
  requestStatus: {
    connect: setStatusDefaults(),
    status: setStatusDefaults(),
    getData: setStatusDefaults(),
    unlinkIntegration: setStatusDefaults(),
  },
});

const plaidReducer = (state = initialState, action) => {
  switch (action.type) {
    case PLAID_CONNECT.REQUEST: {
      return stateSet(state, {
        "requestStatus.connect": getRequestStatus(),
      });
    }

    case PLAID_CONNECT.RESPONSE: {
      return stateSet(state, {
        "requestStatus.connect": getResponseStatus(),
      });
    }

    case PLAID_CONNECT.ERROR: {
      return stateSet(state, {
        "requestStatus.connect": getErrorStatus(),
      });
    }

    case PLAID_STATUS.REQUEST: {
      return stateSet(state, {
        "requestStatus.status": getRequestStatus(),
      });
    }

    case PLAID_STATUS.RESPONSE: {
      return stateSet(state, {
        "requestStatus.status": getResponseStatus(),
        "data.institutions": action.response,
      });
    }

    case PLAID_STATUS.ERROR: {
      return stateSet(state, {
        "requestStatus.status": getErrorStatus(),
      });
    }

    case PLAID_UNLINK_INTEGRATION.REQUEST: {
      return stateSet(state, {
        "requestStatus.unlinkIntegration": getRequestStatus(),
      });
    }

    case PLAID_UNLINK_INTEGRATION.RESPONSE: {
      return stateSet(state, {
        "requestStatus.unlinkIntegration": getResponseStatus(),
      });
    }

    case PLAID_UNLINK_INTEGRATION.ERROR: {
      return stateSet(state, {
        "requestStatus.unlinkIntegration": getErrorStatus(),
      });
    }

    case PLAID_CLOSING_BALANCE.REQUEST: {
      return stateSet(state, {
        "requestStatus.getData": getRequestStatus(),
      });
    }

    case PLAID_CLOSING_BALANCE.RESPONSE: {
      const accounts = action.response;
      const usdIndex = accounts.findIndex((c) => c.currency === "USD");
      const usdAccounts = accounts[usdIndex];
      if (usdIndex !== -1) {
        accounts.splice(usdIndex, 1);
        accounts.unshift(usdAccounts);
      }

      return stateSet(state, {
        "requestStatus.getData": getResponseStatus(),
        "data.accounts": accounts,
      });
    }

    case PLAID_CLOSING_BALANCE.ERROR: {
      return stateSet(state, {
        "requestStatus.getData": getErrorStatus(),
      });
    }

    default:
      return state;
  }
};

export default plaidReducer;
