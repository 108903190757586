import React from "react";
import { BetaTag } from "@components/common/banners/BetaTag";

import { PRODUCT_ANALYTICS, PLATFORMS } from "@utils/consts";

import symbols from "@utils/symbols";

export const PLATFORM_PICKER_CATEGORIES = {
  "ad-networks": {
    title: (
      <>
        {"Ad networks Revenue "}
        <BetaTag />
      </>
    ),
    description: `${symbols.lightBulb} To see ad networks revenue and upcoming payouts connect platforms below.`,
    categoryType: PRODUCT_ANALYTICS,
  },
  "in-app-purchases": {
    title: "Platform Revenue",
    description: `${symbols.lightBulb} Stay up to date on your earnings and get visibility on upcoming payments across platforms.`,
    categoryType: PLATFORMS,
  },
  "ua-spend": {
    title: "User Acquisition Spend",
    description: `${symbols.lightBulb} To see your historical spend on user acquisition, please connect one of the analytics tools below.`,
    categoryType: PRODUCT_ANALYTICS,
  },
};
