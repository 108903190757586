import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getResponseStatus, getRequestStatus, getErrorStatus, setStatusDefaults, mutateState } from "@utils/redux";

import FinancialsService from "./financialsApi";

import { trackGTMEvent, FINANCIALS_ACTION } from "@utils/gtm";
import { copy } from "@src/utils/general";

import {
  parseInflowChartData,
  parseBalanceChartData,
  parseBurnChartData,
  getLastMonthNetBurn,
  parseBreakdownData,
  getUSDClosingBalance,
} from "./utils/financial";

const INITIAL_BREAKDOWN_DATA = {
  metadata: {
    availableFilters: [],
  },
  cashIn: {
    sections: [],
  },
  cashOut: {
    sections: [],
  },
};

const initialState = {
  getClosingBalance: {
    data: null,
    requestStatus: setStatusDefaults(),
  },
  getBankingMetrics: {
    data: null,
    requestStatus: setStatusDefaults(),
  },
  getUpcomingCashIn: {
    data: null,
    requestStatus: setStatusDefaults(),
  },
  getMonthlyReport: {
    data: [],
    requestStatus: setStatusDefaults(),
  },
  getCashflowBreakdown: {
    data: copy(INITIAL_BREAKDOWN_DATA),
    initialLoadComplete: false,
    requestStatus: setStatusDefaults(),
  },
  chartsData: {
    inflow: [],
    balance: [],
    burn: [],
  },
  lastMonthNetBurn: null,
};

export const financialsThunks = {
  getClosingBalance: createAsyncThunk("financials/getClosingBalance", async () => {
    const res = await FinancialsService.getClosingBalance();
    return res.data;
  }),

  getBankingMetrics: createAsyncThunk("financials/getBankingMetrics", async () => {
    const res = await FinancialsService.getBankingMetrics();
    return res.data;
  }),

  getUpcomingCashIn: createAsyncThunk("financials/getUpcomingCashIn", async ({ toDate }) => {
    if (!toDate) {
      throw new Error("toDate is required");
    }
    const res = await FinancialsService.getUpcomingReport({ toDate });
    return res.data;
  }),

  getMonthlyReport: createAsyncThunk("financials/getMonthlyReport", async () => {
    const res = await FinancialsService.getMonthlyReport();
    return res.data;
  }),

  getCashflowBreakdown: createAsyncThunk("financials/getCashflowBreakdown", async (option) => {
    const res = await FinancialsService.getCashflowBreakdown(option?.query);
    return {
      option,
      data: res.data,
    };
  }),
};

export const financialsSlice = createSlice({
  name: "financials",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(financialsThunks.getClosingBalance.pending, (state) => {
        state.getClosingBalance.requestStatus = getRequestStatus();
      })
      .addCase(financialsThunks.getClosingBalance.fulfilled, (state, action) => {
        mutateState(state?.getClosingBalance, {
          data: getUSDClosingBalance(action.payload),
          requestStatus: getResponseStatus(),
        });
      })
      .addCase(financialsThunks.getClosingBalance.rejected, (state) => {
        state.getClosingBalance.requestStatus = getErrorStatus();
      })

      .addCase(financialsThunks.getBankingMetrics.pending, (state) => {
        state.getBankingMetrics.requestStatus = getRequestStatus();
      })
      .addCase(financialsThunks.getBankingMetrics.fulfilled, (state, action) => {
        state.getBankingMetrics.data = {
          revenue: Math.abs(action.payload?.revenueCents / 100) || 0,
          ua: Math.abs(action.payload?.uaSpendCents / 100) || 0,
          ...action.payload,
        };
        state.getBankingMetrics.requestStatus = getResponseStatus();
      })
      .addCase(financialsThunks.getBankingMetrics.rejected, (state) => {
        state.getBankingMetrics.requestStatus = getErrorStatus();
      })
      .addCase(financialsThunks.getUpcomingCashIn.pending, (state) => {
        state.getUpcomingCashIn.requestStatus = getRequestStatus();
      })
      .addCase(financialsThunks.getUpcomingCashIn.fulfilled, (state, action) => {
        const amountCents = action.payload?.estimatedCashInflow?.totalAmountInCents || 0;
        const platforms = action.payload?.estimatedCashInflow?.platforms || [];

        state.getUpcomingCashIn.data = {
          estimatedCashInflow: {
            totalAmount: amountCents / 100,
          },
          platforms,
        };
        state.getUpcomingCashIn.requestStatus = getResponseStatus();
      })
      .addCase(financialsThunks.getUpcomingCashIn.rejected, (state) => {
        state.getUpcomingCashIn.requestStatus = getErrorStatus();
      })

      .addCase(financialsThunks.getMonthlyReport.pending, (state) => {
        state.getMonthlyReport.requestStatus = getRequestStatus();
      })
      .addCase(financialsThunks.getMonthlyReport.fulfilled, (state, action) => {
        state.getMonthlyReport.requestStatus = getResponseStatus();
        state.chartsData.inflow = parseInflowChartData(action.payload);
        state.chartsData.balance = parseBalanceChartData(action.payload);
        state.chartsData.burn = parseBurnChartData(action.payload);
        state.lastMonthNetBurn = getLastMonthNetBurn(action.payload);
      })
      .addCase(financialsThunks.getMonthlyReport.rejected, (state) => {
        state.lastMonthNetBurn = {};
        state.getMonthlyReport.requestStatus = getErrorStatus();
      })

      .addCase(financialsThunks.getCashflowBreakdown.pending, (state) => {
        state.getCashflowBreakdown.requestStatus = getRequestStatus();
        // Reset data on new request
        state.getCashflowBreakdown.data = copy(INITIAL_BREAKDOWN_DATA);
      })
      .addCase(financialsThunks.getCashflowBreakdown.fulfilled, (state, action) => {
        const { payload = {} } = action;
        const { data, option } = payload;

        if (option) {
          trackGTMEvent({
            event: FINANCIALS_ACTION.BREAKDOWN_MONTHLY_PAYLOAD,
            name: `${option.optionIndex} - ${option.label}`,
          });
        }

        state.getCashflowBreakdown.data = parseBreakdownData(data);
        state.getCashflowBreakdown.initialLoadComplete = true;
        state.getCashflowBreakdown.requestStatus = getResponseStatus();
      })
      .addCase(financialsThunks.getCashflowBreakdown.rejected, (state) => {
        state.getCashflowBreakdown.initialLoadComplete = true;
        state.getCashflowBreakdown.requestStatus = getErrorStatus();
        // Reset data on error
        state.getCashflowBreakdown.data = copy(INITIAL_BREAKDOWN_DATA);
      });
  },
});

export const financialsSelector = (state) => state.financials;
export const lastMonthNetBurnSelector = (state) => state.financials.lastMonthNetBurn;

export default financialsSlice.reducer;
