import symbols from "@src/utils/symbols";
import { Text } from "@chakra-ui/react";
import { StatCard } from "@common/stat-card";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {
  FINANCIAL_INTELLIGENCE_REQUEST_STATUS,
  FINANCIAL_INTELLIGENCE_CARD_ID,
} from "@src/consts/financial-intelligence";
import { financeThunks } from "@redux/finance/financeSlice";

export function UpcomingCashOutCard() {
  const location = useLocation();
  const isDashboard = location.pathname === "/dashboard";

  const comingSoon = isDashboard ? (
    <EarlyAccessMessage />
  ) : (
    <Text
      color={"brandElectricBlue.400"}
      size="mini"
      fontWeight={500}
      p="6px"
      borderRadius={"4px"}
      bg="rgba(130, 128, 255, 0.16)"
      width={"fit-content"}
    >
      Coming Soon: Upcoming user acquisition spend (next 3 months)
    </Text>
  );
  const upcomingCashOutCardInfo = {
    id: FINANCIAL_INTELLIGENCE_CARD_ID.UPCOMING_CASH_OUT,
    title: `${symbols.downTrend} Upcoming user acquisition spend (next 3 months)`,
    currentBalance: 100000,
    comingSoon,
  };

  return <StatCard data-test-id="upcoming-cash-out-card" {...upcomingCashOutCardInfo} />;
}

function EarlyAccessMessage() {
  const dispatch = useDispatch();
  const {
    company: { financialIntelligenceRequestStatus },
  } = useSelector((state) => state.session);

  const [askedForEarlyAccess, setAskedForEarlyAccess] = useState(() =>
    [FINANCIAL_INTELLIGENCE_REQUEST_STATUS.PENDING].includes(financialIntelligenceRequestStatus),
  );

  function requestFinancialIntelligenceAccess() {
    dispatch(financeThunks.requestFinancialIntelligenceAccess());
    setAskedForEarlyAccess(true);
  }

  if (askedForEarlyAccess) {
    return (
      <Text size={"mini"} fontWeight={500} color="textWhite.400" maxW="379px" textAlign={"center"}>
        Thank you for signing up for the beta. We will review your account and be in touch with next steps.
      </Text>
    );
  }

  return (
    <Text size={"mini"} fontWeight={500} color="textWhite.400">
      Unlock more functionality.{" "}
      <Text
        as="span"
        cursor="pointer"
        size={"mini"}
        fontWeight={500}
        color="brandRed.400"
        onClick={requestFinancialIntelligenceAccess}
      >
        Sign up for beta -&gt;
      </Text>
    </Text>
  );
}
