import React from "react";
import styled from "styled-components";

import symbols from "@utils/symbols";

export const StyledStepInfo = styled.div`
  background: #2a2a2f;
  border-radius: 10px;
  padding: 12px;
  height: max-content;

  & > p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #faf8f8;
    margin-bottom: 12px;
  }
`;

export const StepInfo = ({ platform = {}, children }) => {
  return (
    <StyledStepInfo key={`${platform.platformId}-step-additional-information`}>
      <p>
        {symbols.Video()}
        {` View how to connect ${platform.name}`}
      </p>
      {children}
    </StyledStepInfo>
  );
};
