import HTTP from "@redux/http";
const BASE = `/api/integrations/suggestions`;

class SuggestionService {
  sendSuggestion(suggestion) {
    return HTTP.req.post(BASE, suggestion);
  }
}

export default new SuggestionService();
