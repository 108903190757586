import styled from "styled-components";

const StyledAdvanceList = styled.div`
  & {
    .advance-list-title {
      color: ${({ theme }) => theme.textSeashellWhite};
    }

    .advance-list-container {
      margin-top: 24px;
      background-color: ${({ theme }) => theme.grayscale20};
      border-radius: 12px;
      padding: 24px;

      .growth-capital-offer {
        margin-bottom: 42px;
        border-bottom: 1px solid ${({ theme }) => theme.grayscale50};
        padding: 16px 0 42px 0;

        &-title {
          margin-bottom: 8px;
        }

        .main-subtitle {
          color: ${({ theme }) => theme.textDefault};
        }
      }

      :last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export default StyledAdvanceList;
