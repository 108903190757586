import React, { useEffect } from "react";

import AuthenticationContainer from "@pages/authentication/AuthenticationContainer";

import { useLogout } from "@utils/custom-hooks/useAuth";

const LogOut = () => {
  const appLogout = useLogout();

  useEffect(() => {
    // This route is only used for tests, which use localStorage as
    // the auth0 storage, so we can safely clear it out here just for fun
    localStorage.clear();
    appLogout();
    // eslint-disable-next-line
  }, []);

  return <AuthenticationContainer />;
};

export default LogOut;
