import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getResponseStatus, getRequestStatus, getErrorStatus, setStatusDefaults } from "@utils/redux";

import CustomDomainService from "./customDomainApi";

import { errorWrapper } from "@redux/http";
import { settingsThunks } from "../settingsSlice";

export const customDomainThunks = {
  setCustomDomain: createAsyncThunk("webshops/setCustomDomain", async ({ data, webshopId }, { rejectWithValue }) => {
    return errorWrapper(async () => {
      const res = await CustomDomainService.setCustomDomain(data, webshopId);
      return res.data;
    }, rejectWithValue);
  }),
  verifyCustomDomain: createAsyncThunk("webshops/verifyCustomDomain", async ({ webshopId }, { rejectWithValue }) => {
    return errorWrapper(async () => {
      const res = await CustomDomainService.verifyCustomDomain(webshopId);
      return res.data;
    }, rejectWithValue);
  }),
  deleteCustomDomain: createAsyncThunk("webshops/deleteCustomDomain", async ({ webshopId }, { rejectWithValue }) => {
    return errorWrapper(async () => {
      const res = await CustomDomainService.deleteCustomDomain(webshopId);
      return res.data;
    }, rejectWithValue);
  }),
};

export const customDomainSlice = createSlice({
  name: "customDomain",
  initialState: {
    setDomainRequestStatus: setStatusDefaults(),
    verifyRequestStatus: setStatusDefaults(),
    removeRequestStatus: setStatusDefaults(),
    requestStatus: setStatusDefaults(),
    data: {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(settingsThunks.getSettings.fulfilled, (state, action) => {
        state.data = action.payload.customDomains;
      })
      /*******************************************************************************
       * Set Custom Domain
       *******************************************************************************/
      .addCase(customDomainThunks.setCustomDomain.pending, (state) => {
        state.setDomainRequestStatus = getRequestStatus();
      })
      .addCase(customDomainThunks.setCustomDomain.fulfilled, (state, action) => {
        const { payload = {} } = action;
        state.data = payload;
        state.setDomainRequestStatus = getResponseStatus();
      })
      .addCase(customDomainThunks.setCustomDomain.rejected, (state) => {
        state.setDomainRequestStatus = getErrorStatus();
      })
      /*******************************************************************************
       * Verify Custom Domain
       *******************************************************************************/
      .addCase(customDomainThunks.verifyCustomDomain.pending, (state) => {
        state.verifyRequestStatus = getRequestStatus();
      })
      .addCase(customDomainThunks.verifyCustomDomain.fulfilled, (state, action) => {
        const { payload = {} } = action;
        state.data = payload;
        state.verifyRequestStatus = getResponseStatus();
      })
      .addCase(customDomainThunks.verifyCustomDomain.rejected, (state) => {
        state.verifyRequestStatus = getErrorStatus();
      })
      /*******************************************************************************
       * Delete Custom Domain
       *******************************************************************************/
      .addCase(customDomainThunks.deleteCustomDomain.pending, (state) => {
        state.removeRequestStatus = getRequestStatus();
      })
      .addCase(customDomainThunks.deleteCustomDomain.fulfilled, (state, action) => {
        const { payload = {} } = action;
        state.data = payload;
        state.removeRequestStatus = getResponseStatus();
      })
      .addCase(customDomainThunks.deleteCustomDomain.rejected, (state) => {
        state.removeRequestStatus = getErrorStatus();
      });
  },
});

export const customDomainSelector = (state) => state.webshops.settings.customDomain;

export default customDomainSlice.reducer;
