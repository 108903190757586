import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getResponseStatus, getRequestStatus, getErrorStatus, setStatusDefaults } from "@utils/redux";

import LinksService from "./linksApi";

import { errorWrapper } from "@redux/http";

export const linksThunks = {
  createLink: createAsyncThunk("webshops/createLink", async ({ link, webshopId, sectionId }, { rejectWithValue }) => {
    return errorWrapper(async () => {
      const res = await LinksService.createLink(link, webshopId, sectionId);
      return res.data;
    }, rejectWithValue);
  }),
  updateLink: createAsyncThunk("webshops/updateLink", async ({ link, webshopId, sectionId }, { rejectWithValue }) => {
    return errorWrapper(async () => {
      const res = await LinksService.updateLink(link, webshopId, sectionId);
      return res.data;
    }, rejectWithValue);
  }),
  deleteLink: createAsyncThunk("webshops/deleteLink", async ({ link, webshopId, sectionId }, { rejectWithValue }) => {
    return errorWrapper(async () => {
      const res = await LinksService.deleteLink(link, webshopId, sectionId);
      return res.data;
    }, rejectWithValue);
  }),
};

export const linksSlice = createSlice({
  name: "links",
  initialState: {
    createLink: {
      requestStatus: setStatusDefaults(),
    },
    updateLink: {
      requestStatus: setStatusDefaults(),
    },
    deleteLink: {
      requestStatus: setStatusDefaults(),
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(linksThunks.createLink.pending, (state) => {
        state.createLink.requestStatus = getRequestStatus();
      })
      .addCase(linksThunks.createLink.fulfilled, (state, { payload }) => {
        state.createLink.requestStatus = getResponseStatus();
      })
      .addCase(linksThunks.createLink.rejected, (state) => {
        state.createLink.requestStatus = getErrorStatus();
      })
      .addCase(linksThunks.updateLink.pending, (state) => {
        state.updateLink.requestStatus = getRequestStatus();
      })
      .addCase(linksThunks.updateLink.fulfilled, (state, { payload }) => {
        state.updateLink.requestStatus = getResponseStatus();
      })
      .addCase(linksThunks.updateLink.rejected, (state) => {
        state.updateLink.requestStatus = getErrorStatus();
      })
      .addCase(linksThunks.deleteLink.pending, (state) => {
        state.deleteLink.requestStatus = getRequestStatus();
      })
      .addCase(linksThunks.deleteLink.fulfilled, (state, { payload }) => {
        state.deleteLink.requestStatus = getResponseStatus;
      })
      .addCase(linksThunks.deleteLink.rejected, (state) => {
        state.deleteLink.requestStatus = getErrorStatus();
      });
  },
});

export default linksSlice.reducer;
