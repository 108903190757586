import React from "react";
import {
  Box,
  Button,
  Heading,
  HStack,
  Icon,
  IconButton,
  Image,
  Text,
  VStack,
  TableContainer,
  Table,
  Tbody,
  Tr,
  Td,
} from "@chakra-ui/react";
import { Reorder, useDragControls } from "framer-motion";

import { pricingTiersLabel } from "../utils";
import { ReactComponent as ChevronLeft } from "@assets/img/icons/common/chevron-left.svg";
import { ReactComponent as TrashIcon } from "@assets/img/icons/common/trash-icon.svg";
import { DragHandleIcon } from "@chakra-ui/icons";
import isEmpty from "lodash/isEmpty";

import PropTypes from "prop-types";

export const EditStep = ({
  selectedItem = [],
  priceTiersMap = {},
  onSelect = () => {},
  setSelectedItem = () => {},
  handlePrevStep = () => {},
  onClose = () => {},
}) => {
  const controls = useDragControls();

  const handleRemoveItem = (clickedItem) => {
    setSelectedItem((prevItems) => {
      const nextItems = prevItems.filter((item) => item.id !== clickedItem.id);
      if (isEmpty(nextItems)) {
        onSelect([]);
        handlePrevStep();
      }
      return nextItems;
    });
  };

  return (
    <VStack gap={"24px"} alignItems={"stretch"} aria-label={"edit item step"}>
      <HStack gap={"8px"} p={"4px 8px 0 8px"} onClick={handlePrevStep} cursor={"pointer"} w={"fit-content"}>
        <Icon cursor={"pointer"} as={ChevronLeft} />
        <Text size={"small"} fontWeight={600} textColor={"textWhite.400"}>
          Add items
        </Text>
      </HStack>
      <VStack alignItems={"flex-start"}>
        <Heading size={"regular"} textColor={"textWhite.400"} fontWeight={600}>
          Edit Series
        </Heading>
        <Text size={"small"} color={"textSubtext.400"}>
          Click and drag the items into your desired order. Click the trash can to remove an item.
        </Text>
      </VStack>
      <VStack>
        <TableContainer w={"100%"} h={"510px"} position={"relative"}>
          <Box
            h={"510px"}
            overflowY={"scroll"}
            overflowX={"hidden"}
            sx={{
              "&::-webkit-scrollbar": {
                width: "4px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#FFF",
                borderRadius: "24px",
              },
            }}
          >
            <Table variant={"editItemSeries"}>
              <Reorder.Group as={Tbody} axis={"y"} values={selectedItem} onReorder={setSelectedItem}>
                {selectedItem.map((item) => {
                  const { id, name, category, imageUrl, pricingTierLevelId } = item;
                  const formattedPrice = pricingTiersLabel(priceTiersMap[pricingTierLevelId]);

                  return (
                    <Reorder.Item
                      key={id}
                      value={item}
                      as={Tr}
                      style={{ position: "relative", zIndex: 1 }}
                      whileDrag={{
                        zIndex: 2,
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                        scale: 1.03,
                      }}
                      cursor={"grab"}
                      _active={{ cursor: "grabbing" }}
                      dragControls={controls}
                    >
                      <Td>
                        <IconButton
                          aria-label={`remove item ${name}`}
                          minW={"fit-content"}
                          p={"4px"}
                          h={"fit-content"}
                          borderRadius={"5px"}
                          icon={<Icon w={"18px"} h={"18px"} as={TrashIcon} />}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRemoveItem(item);
                          }}
                        />
                      </Td>
                      <Td>
                        <HStack>
                          <Image borderRadius={"3px"} w={"32px"} h={"32px"} src={imageUrl} />
                          <Text ml={"8px"}>{name}</Text>
                        </HStack>
                      </Td>
                      <Td>{category?.name ?? "-"}</Td>
                      <Td>{formattedPrice}</Td>

                      <Td>
                        <Icon aria-label={"Drag handle"} as={DragHandleIcon} />
                      </Td>
                    </Reorder.Item>
                  );
                })}
              </Reorder.Group>
            </Table>
          </Box>
        </TableContainer>
      </VStack>
      <HStack justifyContent={"space-between"}>
        <Button h={"32px"} variant={"primary"} onClick={onClose}>
          Cancel
        </Button>
        <Button
          h={"32px"}
          aria-label={"Save and close"}
          onClick={() => {
            onSelect(selectedItem);
            onClose();
          }}
        >
          Save & close
        </Button>
      </HStack>
    </VStack>
  );
};

EditStep.propTypes = {
  selectedItem: PropTypes.array,
  priceTiersMap: PropTypes.object,
  onSelect: PropTypes.func,
  setSelectedItem: PropTypes.func,
  handlePrevStep: PropTypes.func,
  onClose: PropTypes.func,
};
