import React, { useState, useRef, useCallback, useEffect } from "react";
import { Box, Text } from "@chakra-ui/react";

export const Slider = ({ value, onChange, ...rest }) => {
  const [sliderValue, setSliderValue] = useState(value ?? 0); // Slider value state
  const sliderRef = useRef(null); // Reference to the slider track
  const thumb = useRef(null); // Reference to the thumb

  // Function to calculate the slider value based on mouse position
  const calculateValue = useCallback((clientX) => {
    const rect = sliderRef.current.getBoundingClientRect();
    const thumbRect = thumb.current.getBoundingClientRect();

    const deadZoneWidth = thumbRect.width; // Width of the dead zone
    const x = clientX - rect.left; // Adjusted x position

    let percentage;
    if (x < deadZoneWidth) {
      percentage = 0; // Keep value at 0 if within the dead zone
    } else {
      percentage = ((x - deadZoneWidth) / (rect.width - deadZoneWidth)) * 100;
    }

    return Math.min(Math.max(0, percentage), 100); // Ensure the value is between 0 and 100
  }, []);

  useEffect(() => {
    if (onChange) {
      onChange(Math.round(sliderValue));
    }
  }, [onChange, sliderValue]);

  const startDragging = useCallback(
    (e) => {
      const updateValue = (e) => {
        setSliderValue(calculateValue(e.clientX));
      };

      // Update the value while moving the mouse
      const onMouseMove = (e) => {
        updateValue(e);
      };

      // Remove event listeners on mouse up
      const onMouseUp = () => {
        document.removeEventListener("mousemove", onMouseMove);
        document.removeEventListener("mouseup", onMouseUp);
      };

      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);

      // Set initial value
      updateValue(e);
    },
    [calculateValue],
  );

  let thumbOffset = 32;
  // thumboffset goes to zero as the value goes to 100
  thumbOffset = thumbOffset - (sliderValue / 100) * thumbOffset;

  const absoluteWidth = `calc(${sliderValue}% + ${thumbOffset}px + 2px)`;

  return (
    <Box
      ref={sliderRef}
      bg="dark.500"
      h="32px"
      borderRadius={"8px"}
      border="1px solid transparent"
      borderColor="dark.400"
      position="relative"
      onMouseDown={startDragging}
      onTouchStart={startDragging}
      display={"flex"}
      {...rest}
    >
      <Box
        h={"calc(100% + 2px)"}
        pos={"absolute"}
        top={"calc(0% - 1px)"}
        left={"calc(0% - 1px)"}
        bottom={0}
        borderRadius={"inherit"}
        bg="dark.400"
        border="1px solid transparent"
        borderColor="dark.100"
        minW="32px"
        w={absoluteWidth}
        userSelect={"none"}
      >
        <Box
          w="32px"
          h="100%"
          ref={thumb}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"end"}
          pos={"absolute"}
          right={0}
          pr="6px"
        >
          <Text userSelect="none" size={"small"}>
            {Math.round(sliderValue)}
          </Text>
          <Box
            as="span"
            height={"calc(100% - 16px)"}
            ml="4px"
            mr={"2px"}
            borderLeft={"1px solid transparent"}
            borderColor={"textDefault.400"}
          />
          <Box
            as="span"
            height={"calc(100% - 20px)"}
            borderLeft={"1px solid transparent"}
            borderColor={"textDefault.400"}
          />
        </Box>
      </Box>
    </Box>
  );
};
