import { Icon, Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import { cloneElement, useState } from "react";
import { ReactComponent as CrossIcon } from "@assets/img/icons/common/cross3.svg";

const variants = {
  enters: (direction) => ({
    x: direction > 0 ? "100%" : "-100%",
    opacity: 0,
  }),
  center: {
    x: 0,
    opacity: 1,
  },
  exit: (direction) => ({
    x: direction < 0 ? "100%" : "-100%",
    opacity: 0,
  }),
};

const MotionModalContent = motion(ModalContent);

export function MultiStepModal({ isOpen, onClose, closeOnLastStep, steps, initialStep = 0, ...rest }) {
  const [[step, direction], setStep] = useState(() => [initialStep, 0]);
  const [data, setData] = useState();

  const nextStep = () => {
    if (step < steps.length - 1) {
      return setStep([step + 1, 1]);
    }

    if (closeOnLastStep) {
      onClose();
    } else {
      setStep([0, 0]);
    }
  };

  const prevStep = () => {
    if (step > 0) {
      return setStep([step - 1, -1]);
    }
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      onCloseComplete={() => {
        setStep([0, 0]);
        setData();
      }}
    >
      <ModalOverlay />
      <MotionModalContent pos={"relative"} overflow={"hidden"} {...rest}>
        <AnimatePresence initial={false} custom={direction} mode="popLayout">
          <motion.div
            style={{ display: "flex", flexDirection: "column", flex: 1, position: "relative" }}
            variants={variants}
            transition={{ x: { type: "spring", stiffness: 300, damping: 30 }, opacity: { duration: 0.2 } }}
            initial="enters"
            animate="center"
            custom={direction}
            exit="exit"
            key={`multi_step_${step}`}
            layout
          >
            <Icon
              as={CrossIcon}
              onClick={onClose}
              w="24px"
              h="24px"
              cursor="pointer"
              color={"textSubtext.400"}
              ml={"auto"}
            />
            {isOpen &&
              cloneElement(steps[step], {
                handleNextStep: nextStep,
                handlePrevStep: prevStep,
                data,
                setData,
                onClose,
              })}
          </motion.div>
        </AnimatePresence>
      </MotionModalContent>
    </Modal>
  );
}
