import isEmpty from "lodash/isEmpty";

export const getItemPos = (item = {}, index = 0, dimensions = {}, maxValue = 0, withNegYAxis = false) => {
  if (isEmpty(item)) return null;
  let posY;
  // SVG origin is opposite, so 0 will be the top and dimensions.height the bottom
  // Items are max value return 0, while $0 would be the container height
  if (withNegYAxis) {
    // With a negative axis, the items value is always "half" relative to the graph
    const half = dimensions.height / 2;
    posY = half - (item.monthlyAmount / maxValue) * half;
  } else {
    posY = dimensions.height - (item.monthlyAmount / maxValue) * dimensions.height;
  }
  return {
    x: index * dimensions.itemWidth + dimensions.itemHalfWidth,
    y: posY,
  };
};

export const isIntermediatePoint = (p1, p2, p3) => {
  // Check if the magnitudes of the two vectors are equal
  const x1 = p1.x - p2.x;
  const y1 = p1.y - p2.y;
  const x2 = p3.x - p2.x;
  const y2 = p3.y - p2.y;
  const mag1 = Math.sqrt(x1 * x1 + y1 * y1);
  const mag2 = Math.sqrt(x2 * x2 + y2 * y2);
  const magDiff = Math.abs(mag1) - Math.abs(mag2);
  const epsilon = 0.000000001;
  return Math.abs(magDiff) < epsilon;
};
