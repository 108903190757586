import React from "react";
import { StyledEmojiPlaceholder } from "./OnboardingExperienceStyles";

const EmojiPlaceholder = ({ emoji, color }) => (
  <StyledEmojiPlaceholder className="emoji-placeholder" style={{ background: color }}>
    {emoji}
  </StyledEmojiPlaceholder>
);

export default EmojiPlaceholder;
