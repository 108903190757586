import styled from "styled-components";

const DashboardCardWrapper = styled.div`
  & {
    margin-bottom: ${({ smallMargin }) => (smallMargin ? "24px" : "42px")};

    &:last-child {
      margin-bottom: 0;
    }
  }
  & .single-advance-title {
    margin-bottom: 24px;
  }
`;

export default DashboardCardWrapper;
