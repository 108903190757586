import React, { Fragment } from "react";
import moment from "moment";
import classnames from "classnames";

import { StyledChartBars } from "../DailyRevenueDisplay/DailyRevenueDisplayStyles";
import { DailyEarningsTooltip } from "../Tooltips/DailyRevenueTooltips";
import { SanloTooltip } from "@components/common/tooltips/sanlo-tooltip/SanloTooltip";

import { alphaTrim } from "@utils/general";
import { getDayGameData } from "../../utils/dailyRevenueUtils";

const ChartBars = ({
  selectedOption,
  chartData,
  selectedOptionGameData,
  isLoading,
  yAxisInfo,
  dailyRevenueData,
  theme,
  isNothingConnected,
  hoveredDay,
  setHoveredDay,
  viewedPeriod,
}) => {
  const today = moment().startOf("day").format("YYYY-MM-DD");
  return (
    <StyledChartBars
      className={classnames({
        "drd-nothing-connected": isNothingConnected && !isLoading,
      })}
    >
      {chartData.order.map((day) => {
        const dayData = chartData.map[day];
        let dayGamesDiplayList = getDayGameData(selectedOptionGameData, dayData);
        const barClassname = classnames("bar", {
          hovered: !isLoading && hoveredDay === day,
          today: !isLoading && today === day,
        });

        const remainingID = "remaining_bar_space_div";
        const placeHolderID = "placeholder";

        if (!dayGamesDiplayList.length && !dayData.isComplete && day < today) {
          dayGamesDiplayList = [
            {
              name: placeHolderID,
            },
          ];
        }

        return (
          <div
            key={`key_game_${day}`}
            className={barClassname}
            onMouseEnter={(e) => {
              setHoveredDay(day);
            }}
            onMouseLeave={(e) => {
              setHoveredDay(null);
            }}
          >
            {hoveredDay === day && <div className="hovered-bkg" />}
            <div className="games-wrapper">
              {[{ name: remainingID }, ...dayGamesDiplayList]
                .filter((dayGame, index) => {
                  // Placeholders always pass
                  if (dayGame.name === remainingID || dayGame.name === placeHolderID) return true;
                  if (dayGame.earnings >= 0) return true;
                  return false;
                })
                .map((dayGame, index, arr) => {
                  let style = {};

                  // Check and make sure the "game" we're adding a bar for is a legit
                  // game and not a placeholder or the div for the remaining space
                  if (dayGame.name !== remainingID && dayGame.name !== placeHolderID) {
                    let gameData = selectedOptionGameData.map[dayGame.gameId] || {};
                    if (dayGame.name === "Other") gameData = selectedOptionGameData.other;

                    const highestStep = yAxisInfo.steps[yAxisInfo.steps.length - 1] || 0;
                    let heightPercentage = (dayGame.earnings / highestStep) * 100;

                    style = {
                      backgroundColor: gameData.color || theme.colorPink,
                      height: `${heightPercentage}%`,
                    };
                  }

                  const id = `key_${index}_game_${day}_${alphaTrim(dayGame.name)}`;
                  return (
                    <Fragment key={`${id}_fragment`}>
                      <div
                        key={`${id}_game`}
                        id={id}
                        style={style}
                        className={classnames("game", {
                          "rounded-top": index === 1,
                          "rounded-bottom": index === arr.length - 1,
                          "remaining-space": dayGame.name === remainingID,
                          "is-placeholder": dayGame.name === placeHolderID,
                          "dr-loading": isLoading && !(dayGame.name === remainingID),
                        })}
                      />
                      {day <= today && (
                        <SanloTooltip
                          key={`${id}_tooltip`}
                          target={id}
                          placement={"top"}
                          text={
                            <DailyEarningsTooltip
                              key={`${id}_sanlo_tooltip`}
                              dayData={dayData}
                              hoveredGame={dayGame.name !== remainingID ? dayGame : null}
                              selectedOptionGameData={selectedOptionGameData}
                              selectedOption={selectedOption}
                              dataSourceStatus={viewedPeriod?.dataSourceStatus}
                            />
                          }
                        />
                      )}
                    </Fragment>
                  );
                })}
            </div>
          </div>
        );
      })}
    </StyledChartBars>
  );
};

export default ChartBars;
