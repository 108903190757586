import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { ReactComponent as TooltipIcon } from "@assets/img/icons/common/tooltip.svg";
import { SectionWrapper } from "@src/utils/webshop/section";
import { useEffect } from "react";
import { useAutoSave } from "@src/utils/hooks/useAutoSave";
import { DEFAULT_DEBOUNCE_TIME } from "@src/utils/consts";
import { useDispatch, useSelector } from "react-redux";
import {
  availableFontsSelector,
  resetCustomFontsErrors,
  resetRecentlyAddedCustomFonts,
  resetSelectedCustomFont,
  selectedFontsSelector,
  typographyThunks,
} from "@redux/webshops/builder/typographySlice";
import { useParams } from "react-router-dom";
import Select from "@common/select/Select";
import { TYPOGRAPHY_FONT_VARIANTS } from "@src/consts/webshops/builder";
import { showToast } from "@utils/redux";
import { ReactComponent as PlusIcon } from "@assets/img/icons/common/plus.svg";
import { MultiStepModal } from "@common/multistep-modal";
import { FontUpload } from "./components/custom-fonts/FontUpload";
import { FontRights } from "./components/custom-fonts/FontRights";
import { ConfirmationModal } from "@common/modals/confirmation-modal";
import { Banner } from "@common/banner";
import { toggleTypographyCustomizer } from "@redux/webshops/builder/builderSlice";

const steps = [<FontUpload key={"font-upload"} />, <FontRights key={"font-rights"} />];

export function TypographyProperties() {
  const availableFonts = useSelector(availableFontsSelector);
  const selectedFonts = useSelector(selectedFontsSelector);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isResetModalOpen, onOpen: onResetModalOpen, onClose: onResetModalClose } = useDisclosure();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { control, handleSubmit, formState } = useForm({
    values: {
      headlineFont: selectedFonts.headline?.id,
      regularFont: selectedFonts.regular?.id,
    },
  });
  const values = useWatch({ control });
  const updateTypography = useAutoSave((payload) => handleFormSubmit(payload), DEFAULT_DEBOUNCE_TIME);

  useEffect(() => {
    if (!formState.isDirty) return;
    updateTypography(values);
  }, [formState.isDirty, values, updateTypography]);

  useEffect(() => {
    dispatch(typographyThunks.getFonts({ webshopId: id }));
  }, [dispatch, id]);

  async function handleFormSubmit(data) {
    try {
      if (data.headlineFont && formState.dirtyFields.headlineFont) {
        await dispatch(
          typographyThunks.updateSelectedFont({
            webshopId: id,
            data: { selectionType: TYPOGRAPHY_FONT_VARIANTS.HEADLINE, id: data.headlineFont },
          }),
        ).unwrap();
      }
      if (data.regularFont && formState.dirtyFields.regularFont) {
        await dispatch(
          typographyThunks.updateSelectedFont({
            webshopId: id,
            data: { selectionType: TYPOGRAPHY_FONT_VARIANTS.REGULAR, id: data.regularFont },
          }),
        ).unwrap();
      }
    } catch (e) {
      showToast("There was a problem updating the fonts", "error");
    }
  }

  function handleOnClose() {
    dispatch(resetSelectedCustomFont());
    dispatch(resetRecentlyAddedCustomFonts());
    dispatch(resetCustomFontsErrors());
    onClose();
  }

  function handleResetTypography() {
    dispatch(typographyThunks.resetSelectedFont({ webshopId: id }));
    onResetModalClose();
  }

  return (
    <>
      <SectionWrapper title="Typography" onReset={onResetModalOpen}>
        <VStack py={"8px"} px="12px" as="form" onSubmit={handleSubmit(handleFormSubmit)}>
          <HStack justifyContent={"space-between"} w="full">
            <Text fontSize={"14px"} color="textSubtext.400">
              Add & Edit Font(s)
            </Text>
            <IconButton
              variant={"ghost"}
              color="textSubtext.400"
              icon={<Icon as={PlusIcon} w="24px" h="24px" />}
              aria-label="Add Font"
              onClick={onOpen}
            />
          </HStack>
          <FormControl>
            <FormLabel
              mb="12px"
              fontSize={"14px"}
              fontWeight={400}
              color="textSubtext.400"
              htmlFor="headline-font-select"
            >
              Select Headline Font
              <Tooltip label="Choose a headline font that matches the tone and brand of your game." placement="auto">
                <Icon ml="4px" mb="-1px" verticalAlign={"baseline"} as={TooltipIcon} color={"textWhite.400"} />
              </Tooltip>
            </FormLabel>
            <Controller
              name="headlineFont"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  defaultValue={availableFonts.find((font) => font.id === selectedFonts.headlineId)}
                  options={availableFonts}
                  onChange={({ value }) => field.onChange(value)}
                  id="headline-font-select"
                />
              )}
            />
          </FormControl>
          <FormControl>
            <FormLabel
              mb="12px"
              fontWeight={400}
              fontSize={"14px"}
              color="textSubtext.400"
              htmlFor="regular-font-select"
            >
              Select Regular Font
              <Tooltip label="Choose a headline font that matches the tone and brand of your game." placement="auto">
                <Icon ml="4px" mb="-1px" verticalAlign={"baseline"} as={TooltipIcon} color={"textWhite.400"} />
              </Tooltip>
            </FormLabel>
            <Controller
              name="regularFont"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  defaultValue={availableFonts.find((font) => font.id === selectedFonts.regularId)}
                  options={availableFonts}
                  onChange={({ value }) => field.onChange(value)}
                  aria-label="Select Regular Font"
                  id="regular-font-select"
                />
              )}
            />
          </FormControl>
          <FormControl>
            <FormLabel mb="12px" fontSize={"14px"} color="textSubtext.400">
              Type Styles
            </FormLabel>
            <Button h="32px" onClick={() => dispatch(toggleTypographyCustomizer(true))}>
              Customize
            </Button>
          </FormControl>
        </VStack>
      </SectionWrapper>
      <MultiStepModal isOpen={isOpen} onClose={handleOnClose} steps={steps} maxWidth="692px" />
      <ConfirmationModal
        isOpen={isResetModalOpen}
        title="Reset all type?"
        acceptText="Reset to defaults"
        cancelText="Cancel"
        onAccept={handleResetTypography}
        onClose={onResetModalClose}
      >
        <Banner type="error">
          <Text>WARNING: This will delete any custom type styles and reset to using the default font</Text>
        </Banner>
      </ConfirmationModal>
    </>
  );
}
