import {
  Box,
  Button,
  HStack,
  Icon,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState, useCallback } from "react";
import { ReactComponent as LockFontsIcon } from "@assets/img/icons/common/lock_fonts.svg";

import { StylePicker } from "../../../StylePicker";
import { calculateFontSizes } from "../../utils";
import { debounce } from "lodash";

const sliderMaxValue = 40;

const calculateLimitPosition = (limitValue, minValue) => {
  if (!limitValue) return null;
  const range = sliderMaxValue - minValue;
  const position = ((parseInt(limitValue) - minValue) / range) * 100;
  return `${position}%`;
};

export function FontStyler({
  label,
  fontFamily,
  fontProperties,
  minValue,
  limitValue,
  onChange,
  ariaLabel = "Font controller",
}) {
  const [currentFontProperties, setCurrentFontProperties] = useState(fontProperties);

  const handleChange = useCallback(
    (param, value) => {
      let newFontProperties = { ...currentFontProperties };

      if (param === "fontSize") {
        newFontProperties.fontSize = calculateFontSizes(value);
      }
      if (param === "color") {
        newFontProperties.color = value;
      }
      if (param === "italic") {
        newFontProperties.italic = value;
      }

      setCurrentFontProperties(newFontProperties);
      onChange(newFontProperties);
    },
    [currentFontProperties, onChange],
  );

  useEffect(() => {
    if (currentFontProperties?.fontSize?.desktop > limitValue) {
      handleChange("fontSize", parseInt(limitValue));
    }
  }, [currentFontProperties?.fontSize?.desktop, handleChange, limitValue]);

  useEffect(() => {
    setCurrentFontProperties(fontProperties);
  }, [fontProperties]);

  return (
    <Box py="8px" px="16px" aria-label={ariaLabel}>
      <VStack minH={"54px"} alignItems={"stretch"} justifyContent={"center"}>
        <Text
          fontSize={currentFontProperties?.fontSize?.desktop}
          fontWeight={currentFontProperties?.weight}
          color={currentFontProperties?.color}
          fontStyle={currentFontProperties?.italic ? "italic" : "normal"}
          lineHeight={"1.36"}
          noOfLines={1}
          textOverflow={"ellipsis"}
          fontFamily={fontFamily}
        >
          {label}
        </Text>
      </VStack>
      <HStack mt="8px" bg="dark.700" px="16px" py="8px" borderRadius="4px" gap="48px" justifyContent={"space-between"}>
        <Box w="240px">
          <Text lineHeight={"24px"}>{currentFontProperties?.fontSize?.desktop} Desktop</Text>
          <Slider
            min={minValue}
            max={sliderMaxValue}
            my="4px"
            aria-label="font-controller"
            value={parseInt(currentFontProperties?.fontSize?.desktop)}
            onChange={(value) => {
              if (value > parseInt(limitValue)) {
                return;
              }
              handleChange("fontSize", value);
            }}
          >
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb />

            {limitValue && (
              <Box
                position="absolute"
                h="30px"
                borderRight={"1px solid transparent"}
                borderRightColor={"brandOrange.400"}
                borderRightStyle={"dashed"}
                transform="translateY(-25%)"
                left={calculateLimitPosition(limitValue, minValue)}
              >
                <Icon
                  as={LockFontsIcon}
                  w="16px"
                  h="16px"
                  pos="absolute"
                  zIndex={2}
                  top="-16px"
                  left="-8px"
                  color="brandOrange.400"
                />
              </Box>
            )}
          </Slider>
          <Text lineHeight={"24px"}>{currentFontProperties?.fontSize.mobile} Mobile</Text>
        </Box>

        <Button
          h="24px"
          borderRadius="12px"
          fontWeight={600}
          fontSize={"14px"}
          px="12px"
          isActive={currentFontProperties?.italic}
          _active={{ bg: "brandPurple.400", color: "dark.900" }}
          onClick={() => handleChange("italic", !currentFontProperties?.italic)}
        >
          Italic
        </Button>
        <StylePicker
          initialColor={currentFontProperties?.color}
          onColorChange={debounce((color) => handleChange("color", color.value), 150)}
        />
      </HStack>
    </Box>
  );
}
