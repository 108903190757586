import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import actions from "@redux/actions";

import DashboardCardWrapper from "../DashboardCardWrapper";
import OnboardingDashboard from "./OnboardingDashboard";
import RejectedCapitalRequestCard from "./rejected/RejectedCapitalRequestCard";

import useIntegrationsStatus from "@utils/custom-hooks/useIntegrationsStatus";
import { getRocketProgress } from "@utils/onboarding";

import { sMatch, CONNECTED, DRAFT_CONNECTION, NOT_CONNECTED } from "@utils/status";
import { getConnectedIntegrations } from "@pages/onboarding/utils";
import { useFlags } from "launchdarkly-react-client-sdk";

const OnboardingDashboardContainer = ({ companyName, dashboardLoading = true, userName, onboarding }) => {
  const dispatch = useDispatch();

  const integrations = useIntegrationsStatus();
  const { clientGrowthCapitalOffersVisible } = useFlags();

  const isOnboardingStatusCardDismissed = useSelector(
    (state) => state.session.company.onboarding.isStatusCardDismissed,
  );

  // Integrations and institutions statuses help determine rocket progress
  const rp_integrations = useSelector((state) => state.integrations);
  const rp_institutions = useSelector((state) => state.integrations.plaid.data.institutions);
  const advanceSummary = useSelector((state) => state.integrations.advances.data.summary);
  const [rocketProgress, setRocketProgress] = useState(getRocketProgress());

  const { data } = onboarding;

  const { platformsLinked, rejectedCardDismissed, onboardingType } = data;
  const [shouldShowPendingRequest, setShouldShowPendingRequest] = useState(isOnboardingStatusCardDismissed);
  const connectedIntegrations = getConnectedIntegrations(platformsLinked);

  const connectingPrimaryFlow = connectedIntegrations.allRequired || connectedIntegrations.oneRequired;
  const connectingSecondaryFlow = connectedIntegrations.allOptional || connectedIntegrations.oneOptional;

  // Primary and secondary flow should dismiss when required platforms are CONNECTED
  const bankingConnected = integrations.banking.status === CONNECTED;
  const productAnalyticsConnected = integrations.productAnalytics.status === CONNECTED;
  const primaryFlowCompleted = bankingConnected && productAnalyticsConnected;

  const IAPStatus = integrations.inAppPurchases.status;
  const IAPConnected = IAPStatus === CONNECTED;
  const adNetworksStatus = integrations.productAnalytics.status;
  const adNetworksConnected = adNetworksStatus === CONNECTED;

  const secondaryFlowCompleted =
    (IAPConnected && adNetworksConnected) || // Both connected
    (IAPConnected && sMatch(adNetworksStatus, [NOT_CONNECTED, DRAFT_CONNECTION])) || // IAP connected and adNetworks not connected (showing optional)
    (adNetworksConnected && sMatch(IAPStatus, [NOT_CONNECTED, DRAFT_CONNECTION])); // adNetworks connected and IAP not connected (showing optional)

  const dismissOnboardingCard = () => {
    dispatch(
      actions.session.finishOnboarding.request({
        ...data,
        rejectedCardDismissed: true, // Dismissing the onboarding card auto-dismisses the rejected card
      }),
    );
    dispatch(actions.session.dismissOnboardingCard.request());
  };

  useEffect(() => {
    dispatch(actions.session.getOnboardingProgress.request());
  }, [dispatch]);

  useEffect(() => {
    setRocketProgress(getRocketProgress("dashboard", rp_integrations, rp_institutions, onboardingType));
  }, [rp_integrations, rp_institutions, onboardingType]);

  // Checks if the onboarding was completed to dismiss the card
  useEffect(() => {
    let timer;
    if (!dashboardLoading && primaryFlowCompleted && isOnboardingStatusCardDismissed && onboardingType === "PRIMARY") {
      timer = setTimeout(() => {
        dispatch(actions.session.finishOnboarding.request(data));
      }, 10000);
    }
    return () => clearTimeout(timer);
  }, [dashboardLoading, primaryFlowCompleted, dispatch, data, onboardingType, isOnboardingStatusCardDismissed]);

  // Only show the rejected capital request card when offers aren't visible
  if (!clientGrowthCapitalOffersVisible && advanceSummary?.rejectedRecently) {
    return !rejectedCardDismissed && <RejectedCapitalRequestCard onCardDismiss={dismissOnboardingCard} />;
  }

  return (
    <DashboardCardWrapper>
      <OnboardingDashboard
        onboardingData={{
          ...data,
          currentProgress: rocketProgress,
        }}
        companyName={companyName}
        userName={userName}
        dashboardIsLoading={dashboardLoading}
        connectingPrimaryFlow={connectingPrimaryFlow}
        connectingSecondaryFlow={connectingSecondaryFlow}
        shouldShowPendingRequest={shouldShowPendingRequest}
        setShouldShowPendingRequest={setShouldShowPendingRequest}
        secondaryFlowCompleted={secondaryFlowCompleted}
        dismissCard={dismissOnboardingCard}
      />
    </DashboardCardWrapper>
  );
};

export default OnboardingDashboardContainer;
