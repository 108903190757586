import React from "react";
import styled from "styled-components";

import { BodyRegularPlus } from "@styled";

import { integrationStates } from "@components/integrations/integration-state/IntegrationStates";
import StateButton from "@common/buttons/state-button/StateButton";

import { DescriptionLabel } from "../../../integrations/integration-state/IntegrationStateCard";

import { PULLING_DATA, CONNECTED } from "@utils/status";
import { tagTrim } from "@utils/general";

const ConnectedPlatformCard = ({ platform, status, handlers }) => {
  const { buttonLabel, state, hover } = integrationStates[status];

  const { id, name, image, iconUrl } = platform ?? {};
  const imageSrc = image ?? iconUrl;

  return (
    <StyledConnectedPlatformCard data-test-id={`platform-${id}-${tagTrim(name)}`}>
      <div className="linking-left-side">
        <div className="card-logo">
          <img src={imageSrc} alt={"card-logo"} />
        </div>

        <div className="card-name-wrapper">
          <BodyRegularPlus className="card-name">{name}</BodyRegularPlus>
          {platform && status !== CONNECTED && (
            <DescriptionLabel integration={platform} integrationStatus={status} pullingData={status === PULLING_DATA} />
          )}
        </div>
      </div>

      <div className="linking-right-side">
        <StateButton
          hover={hover}
          label={buttonLabel}
          state={state}
          onClick={() => {
            if (handlers[status]) handlers[status]();
          }}
        />
      </div>
    </StyledConnectedPlatformCard>
  );
};

export const StyledConnectedPlatformCard = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  border-top: ${({ theme }) => `1px solid  ${theme.grayscale60}`};
  padding-top: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  .linking-left-side {
    display: flex;
    align-items: center;

    .card-logo {
      margin-right: 16px;
      border-radius: 8px;
      width: 32px;
      height: 32px;
      background: ${({ theme }) => theme.grayscale50};

      & > img {
        border-radius: 8px;
      }
    }

    .card-name-wrapper {
      display: flex;
      flex-direction: column;

      .card-name {
        color: ${({ theme }) => theme.textSeashellWhite};
      }

      .pulling-data-label {
        display: flex;
        align-items: center;
        color: ${({ theme }) => theme.textGray};
        height: 16px;

        .custom-loader {
          width: 24px;
          margin-left: -10px;
        }
      }
    }
  }
`;

export default ConnectedPlatformCard;
