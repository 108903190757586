import React, { useEffect } from "react";

import { BodySmallAlt, BodySmall14 } from "@styled";
import { StepActionsContainer } from "@common/animated/FadeStepsContainer";
import SetupGuide from "@components/common/modals/integrations-modal/SetupGuide";
import { StyledGuideList } from "@components/common/modals/integrations-modal/StyledSetupGuide";
import { SanloButton, SanloInput } from "@inputs";
import { ErrorMessage } from "@components/integrations/connection-guide/ErrorMessage";
import { Callout } from "@components/integrations/connection-guide/Callout";
import { COMMON_ACTION, GOOGLE_ACTION } from "@utils/gtm";

import symbols from "@utils/symbols";
import text from "@utils/text";

export const GooglePlayStep1 = ({
  platform = {
    id: "googleplay",
    name: "Google play",
    platformId: "googleplay",
  },
  onContinue = () => {},
  serviceAccountName = "",
  expand = false,
  credentialsError = "",
  trackEvent = () => {},
}) => {
  const keyHandler = (key) => {
    if (key && key.ctrlKey && key.key === "c") {
      handleCopy();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", keyHandler);
    return () => {
      window.removeEventListener("keydown", keyHandler);
    };
  });

  const handleCopy = () => {
    if (serviceAccountName !== "") {
      navigator.clipboard.writeText(serviceAccountName);
      showCopyToast();
    }
  };

  const showCopyToast = () => {
    let toastBg = document.getElementById("toast-bg-fade");
    let toast = document.getElementById("bottom-toast");
    toastBg.className = "show";
    toast.className = "show";
    setTimeout(function () {
      toastBg.className = toastBg.className.replace("show", "");
    }, 3000);
    setTimeout(function () {
      toast.className = toast.className.replace("show", "");
    }, 3000);
  };

  return (
    <StepActionsContainer>
      <div className="fs-modal-actions">
        <ErrorMessage error={credentialsError} />

        <SanloInput
          hasFocus
          isJumbo
          isReadOnly
          hasCopyButton
          label="Copy Sanlo-generated google service account"
          placeholder="random-name@sanlo-production.iam.gserv"
          value={serviceAccountName}
          onFocus={(event) => event.target.select()}
          testId={`${platform.platformId}-service-account-input`}
          style={{
            marginBottom: "24px",
          }}
        />

        <SanloButton
          isJumbo
          label="Continue"
          onClick={() => {
            trackEvent(COMMON_ACTION.CONTINUE);
            onContinue();
          }}
          gtmEventTag={`tag-sanlo-${platform.platformId}-connect-to-${platform.platformId}-continue`}
          testId={`${platform.platformId}-service-account-continue`}
        />
      </div>

      <div className="fs-modal-instructions-integrations">
        <SetupGuide
          expand={expand}
          platformStep={`${platform.name}0`}
          step={{
            callout: (
              <Callout>
                {text.t("callout_header_1")}
                <br />
                <br />
                {text.t("callout_header_2")}
              </Callout>
            ),
            title: "1. Invite Sanlo-generated google service account",
            description: (
              <>
                <StyledGuideList fontWeight={"500"}>
                  <li>
                    Navigate to the{" "}
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://play.google.com/console/developers/users-and-permissions"
                      className={`modal-link setup-guide-link tag-sanlo-${platform.platformId}-user-permissions-link`}
                      onClick={() => trackEvent(GOOGLE_ACTION.USER_PERMISSION)}
                    >
                      User and permissions
                    </a>{" "}
                    tab.
                  </li>
                  <li>
                    Click “<span>Invite new users</span>”.
                  </li>
                  <li>
                    Paste the Sanlo-generated google service account in the “Email address field” that you copied from
                    the left side.
                  </li>
                </StyledGuideList>
                <BodySmall14
                  style={{
                    margin: "8px 0",
                  }}
                >
                  {symbols.Exclamation()} Do NOT set an “expiry date” so that Sanlo can keep monitoring your data for
                  all active advances in a timely manner.{" "}
                </BodySmall14>
                <BodySmall14>
                  {symbols.Exclamation()} Make sure you set up all account permissions as described below{" "}
                  {symbols.PointDown()}
                </BodySmall14>
              </>
            ),
            images: [require("./assets/google_play_guide_1_a.gif")],
            multiple: [
              {
                title: "2. On the same page scroll down to Permissions section",
                description: (
                  <StyledGuideList fontWeight={"600"}>
                    <li>Click on “Account permissions” tab</li>
                    <li>{symbols.Exclamation()} Enable “View app information and download bulk reports”.</li>
                    <li>{symbols.Exclamation()} Enable ”View financial data and orders”.</li>
                    <li>Click on “Invite user” in the bottom right corner.</li>
                  </StyledGuideList>
                ),
                images: [
                  require("./assets/google_play_guide_1_b.gif"),
                  {
                    title: (
                      <BodySmallAlt>
                        This is what <span className="text-bold">Account permissions</span> will look like:
                      </BodySmallAlt>
                    ),
                    source: require("./assets/google_play_guide_2.png"),
                  },
                ],
              },
            ],
          }}
        />
      </div>
    </StepActionsContainer>
  );
};
