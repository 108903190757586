import styled from "styled-components";

const StyledMediaDisplay = styled.div`
  canvas {
    cursor: pointer;
  }

  .media-section-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* position: relative; */
    color: ${({ theme }) => theme.colorElectricBlue};
    cursor: pointer;

    &:not(.collapsed) {
      margin-bottom: 16px;
    }

    .emoji {
      margin-right: 0.5rem;
    }

    &:not(.collapsable) {
      color: white;
      cursor: default;
    }

    .collapse-arrow {
      margin-left: 0.5rem;
      transition: 0.3s all ease;
    }

    &:not(.collapsed) .collapse-arrow {
      transform: rotateZ(180deg);
    }
  }

  .media-section-content {
    position: relative;

    &.collapsed {
      display: none;
    }

    .media-display-image-title {
      margin-bottom: 1rem;
    }

    .gif-player {
      position: relative;
      margin-bottom: 1rem;
      &:last-child {
        margin-bottom: 0;
      }
    }

    .gif-controls {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      cursor: pointer;
      transition: 0.5s all ease;

      &.show-controls {
        opacity: 1;
      }

      .media-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }

      .gif-duration {
        position: absolute;
        right: 1rem;
        bottom: 0.5rem;
        color: black;
      }

      .gif-progress-bar-wrapper {
        position: absolute;
        bottom: 0;
        left: 1rem;
        right: 4rem;
        height: 2rem;

        .gif-progress-bar-outer {
          position: absolute;
          height: 0.375rem;
          background-color: rgba(197, 197, 197, 0.75);
          bottom: 0.75rem;
          left: 0;
          right: 0;
          border-radius: 0.375rem;
          /* overflow: hidden; */

          .gif-progress-bar-inner {
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba(58, 58, 62, 1);
            height: 0.375rem;
            border-radius: 0.375rem;
            /* transition: 0.2s all linear; */

            &:after {
              opacity: 0;
              content: "";
              position: absolute;
              top: -0.1875rem;
              right: 0;
              height: 0.75rem;
              width: 0.25rem;
              border-radius: 0.25rem;
              background-color: rgba(58, 58, 62, 1);
              transition: 0.25s all ease;
            }
          }

          &:hover .gif-progress-bar-inner::after {
            opacity: 1;
          }
        }
      }
    }
  }
`;

export default StyledMediaDisplay;
