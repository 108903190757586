import React from "react";
import { Box, Flex, Image, Text, Icon } from "@chakra-ui/react";
import { ReactComponent as DialogBox } from "@assets/img/icons/common/dialog-box.svg";

export const Bar = ({
  id,
  activities = [],
  color,
  height,
  styles = {},
  hideBar = false,
  notHoverClass = false,
  isPositive = false,
  isZero = false,
  withNegYAxis = false,
  isDaily = false,
  barScaling = false,
}) => {
  if (isZero) return;
  const hoverEffect = notHoverClass;
  const showIcon = withNegYAxis && isDaily;
  const showBoxDialog = showIcon && height < 15;
  const minHeightBar = height < 5;
  const overHeightBar = barScaling && height > 100;

  let stylesObj = {
    ...styles,
    display: hideBar ? "none" : withNegYAxis ? "flex" : "",
    borderRadius: overHeightBar ? "0px" : styles.borderRadius,
  };

  const boxDialogStyles = barScaling
    ? {
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        left: "50%",
        transform: "translateX(-50%)",
      }
    : { justifyContent: "center", alignItems: "center" };

  if (withNegYAxis) {
    if (isDaily) stylesObj.alignItems = isPositive ? "start" : "end";
  }

  return (
    <>
      {isPositive && showBoxDialog && (
        <Flex style={boxDialogStyles} mb={"3px"} bottom={barScaling ? (minHeightBar ? "4%" : `${height}%`) : ""}>
          <Icon as={DialogBox} width={"20px"} height={"23px"} />
          <BarIcon activities={activities} showIcon={showIcon} position={"absolute"} mb={"2px"} isOutsideBar />
        </Flex>
      )}
      <Box
        id={id}
        style={stylesObj}
        justifyContent={"center"}
        height={`${height}%`}
        maxH={barScaling && "105%"}
        minHeight={isZero ? "none" : "6px"}
        width={"24px"}
        padding={"2px"}
        backgroundColor={color}
        filter={hoverEffect && !isDaily ? "brightness(50%)" : ""}
        _last={{ marginRight: "0" }}
      >
        {!showBoxDialog && !overHeightBar && <BarIcon activities={activities} showIcon={showIcon} />}

        {!showBoxDialog && overHeightBar && (
          <Flex justifyContent={"center"} alignItems={"center"} mt={isPositive && "2px"} mb={!isPositive && "2px"}>
            <Icon as={DialogBox} width={"20px"} height={"23px"} transform={isPositive && "rotate(180deg)"} />
            <BarIcon
              activities={activities}
              showIcon={showIcon}
              position={"absolute"}
              mt={isPositive && "3px"}
              mb={!isPositive && "3px"}
            />
          </Flex>
        )}
      </Box>
      {!isPositive && showBoxDialog && (
        <Flex style={boxDialogStyles} mt={"3px"} top={barScaling ? (minHeightBar ? "4%" : `${height}%`) : ""}>
          <Icon as={DialogBox} width={"20px"} height={"23px"} transform={"rotate(180deg)"} />
          <BarIcon activities={activities} showIcon={showIcon} position={"absolute"} mt={"2px"} isOutsideBar />
        </Flex>
      )}
    </>
  );
};

const BarIcon = ({ activities = [], showIcon = false, isOutsideBar = false, ...rest }) => {
  const showActivityIcon = showIcon && activities.length === 1;
  const showActivitiesNum = showIcon && activities.length > 1;
  const size = isOutsideBar ? "16px" : "20px";
  const padding = isOutsideBar ? "4px" : "6px";
  const paddingAlong = isOutsideBar ? "0px" : "2px";
  const borderRadius = isOutsideBar ? "2px" : "4px";

  return (
    <Flex {...rest}>
      {showActivityIcon && (
        <Image
          h={size}
          w={size}
          borderRadius={borderRadius}
          src={activities[0]?.icon || activities[0]?.defaultImage}
          alt={activities[0]?.name}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = activities[0]?.defaultImage;
          }}
          background={"#212126c6"}
        />
      )}
      {showActivitiesNum && (
        <Text
          size={"mini"}
          letterSpacing={"0.02em"}
          color={"textWhite.400"}
          background={"#212126c6"}
          h={size}
          p={padding}
          pt={paddingAlong}
          pb={paddingAlong}
          borderRadius={borderRadius}
        >
          {activities.length}
        </Text>
      )}
    </Flex>
  );
};
