import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Lottie from "lottie-react";
import { useLocation } from "react-router-dom";
import classnames from "classnames";

import { BodySmallAlt } from "@styled";
import StyledPlatformLinkingContent from "../StyledPlatformLinkingContent";
import animationData from "@common/loaders/fullscreen/anim.json";
import ConnectedPlatformCard from "../ConnectedPlatformCard";
import UploadContainer from "@common/upload/UploadContainer";
import { AddButton } from "../PlatformLinking.styled";
import { ReactComponent as Plus } from "@assets/img/icons/common/plus.svg";
import { trackGTMEvent, ONBOARDING_ACTIONS } from "@utils/gtm";

import { BANKING } from "@utils/consts";

import { hide, show } from "@redux/integrations/plaid/prePlaidModalSlice";
import { useRequestPlaidLink } from "@utils/integrations/banking/useRequestPlaidLink.js";
import isEmpty from "lodash/isEmpty";

const BankingLinkingAccordion = ({ description, label, onboardingStep }) => {
  const [toggleUpload, setToggleUpload] = useState(false);
  const dispatch = useDispatch();

  const plaid = useSelector((state) => state.integrations.plaid);

  const location = useLocation();
  // Keep the state to wait for url redirect
  const [showLoadingState, setShowLoadingState] = useState(false);

  const { data: plaidData, requestStatus } = plaid;

  const { institutions } = plaidData;

  const [open, ready, plaidConfig, getPlaidLink] = useRequestPlaidLink();

  function handleUploadStatements() {
    dispatch(hide());
    setToggleUpload(true);
  }

  useEffect(() => {
    if (!isEmpty(plaidConfig.token) && ready) {
      open();
    }
    setShowLoadingState(false);
  }, [plaidConfig, ready, open]);

  useEffect(() => {
    // We shouldn't have to hide the spinner on complete since the user will
    // navigated away from the page.
    if (plaid.requestStatus.connect.pending) {
      setShowLoadingState(true);
    }
    if (plaid.requestStatus.connect.error) {
      setShowLoadingState(false);
    }
  }, [plaid]);

  return (
    <StyledPlatformLinkingContent>
      <div className="description-container as-row">
        <BodySmallAlt className="platform-description">{description}</BodySmallAlt>
        <AddButton
          data-test-id="add-banking"
          onClick={() => {
            trackGTMEvent({
              event: ONBOARDING_ACTIONS.CONNECT_SOURCE_BANKING_ADD,
            });
            return dispatch(show({ onUploadStatements: handleUploadStatements }));
          }}
          className={classnames(`inverted-button`, `tag-sanlo-onboarding-step${onboardingStep}-add-banking`)}
        >
          <Plus /> Add
        </AddButton>
      </div>

      {(showLoadingState || plaid.requestStatus.status.pending) && (
        <div className="loading-container">
          <Lottie className="loading-spinner" animationData={animationData} />
          <BodySmallAlt>Syncing banking data...</BodySmallAlt>
        </div>
      )}
      {requestStatus.status.completed &&
        institutions &&
        institutions.map((bankInstitution, index) => {
          const { id, institution, status } = bankInstitution;

          return (
            <ConnectedPlatformCard
              platform={{
                id: BANKING,
                name: institution?.name || "Bank Institution",
                image: require("@assets/img/icons/common/bankAccount.svg").default,
              }}
              key={`banking-linking-card-${institution?.name}-${index}`}
              status={status}
              handlers={{
                NEEDS_RECONNECTION: () => {
                  setShowLoadingState(true);
                  getPlaidLink(id);
                },
                DRAFT_CONNECTION: () => {
                  setShowLoadingState(true);
                  getPlaidLink();
                },
              }}
            />
          );
        })}
      {/* Differentiate this container used in the dashboard and during onboarding */}
      <UploadContainer
        toggleUpload={toggleUpload}
        handleToggleUpload={setToggleUpload}
        isBankUpload
        isAccordion={location.pathname !== "/dashboard"}
        label={label}
      />
    </StyledPlatformLinkingContent>
  );
};

export default BankingLinkingAccordion;
