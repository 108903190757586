import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getResponseStatus, getRequestStatus, getErrorStatus, setStatusDefaults } from "@utils/redux";
import { errorWrapper } from "@redux/http";

import CheckoutService from "./checkoutApi";

export const checkoutBuilderThunks = {
  getCheckoutTemplate: createAsyncThunk(
    "checkoutBuilder/getCheckoutTemplate",
    async ({ webshopId }, { rejectWithValue }) => {
      return errorWrapper(async () => {
        const response = await CheckoutService.getCheckoutTemplate(webshopId);
        return response.data;
      }, rejectWithValue);
    },
  ),
  addBrandingFile: createAsyncThunk(
    "checkoutBuilder/addBrandingFile",
    async ({ webshopId, data }, { rejectWithValue }) => {
      return errorWrapper(async () => {
        const response = await CheckoutService.addBrandingFile(webshopId, data);
        return response.data;
      }, rejectWithValue);
    },
  ),
  updateCheckoutTemplate: createAsyncThunk(
    "checkoutBuilder/updateCheckoutTemplate",
    async ({ webshopId, data }, { rejectWithValue }) => {
      return errorWrapper(async () => {
        const response = await CheckoutService.updateCheckoutTemplate(webshopId, data);
        return response.data;
      }, rejectWithValue);
    },
  ),
  resetCheckoutTemplate: createAsyncThunk(
    "checkoutBuilder/resetCheckoutTemplate",
    async ({ webshopId }, { rejectWithValue }) => {
      return errorWrapper(async () => {
        const response = await CheckoutService.resetCheckoutTemplate(webshopId);
        return response.data;
      }, rejectWithValue);
    },
  ),
  deleteBrandingFile: createAsyncThunk(
    "checkoutBuilder/deleteBrandingFile",
    async ({ webshopId }, { rejectWithValue }) => {
      return errorWrapper(async () => {
        const response = await CheckoutService.deleteBrandingFile(webshopId);
        return response.data;
      }, rejectWithValue);
    },
  ),
};

export const checkoutBuilderSlice = createSlice({
  name: "checkoutBuilder",
  initialState: {
    template: null,
    view: {
      isMobile: false,
    },
    getCheckoutTemplate: {
      requestStatus: setStatusDefaults(),
    },
    addBrandingFile: {
      requestStatus: setStatusDefaults(),
    },
    updateCheckoutTemplate: {
      requestStatus: setStatusDefaults(),
    },
    resetCheckoutTemplate: {
      requestStatus: setStatusDefaults(),
    },
    deleteBrandingFile: {
      requestStatus: setStatusDefaults(),
    },
  },
  reducers: {
    setIsMobile: (state, action) => {
      state.view.isMobile = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkoutBuilderThunks.getCheckoutTemplate.pending, (state) => {
        state.getCheckoutTemplate.requestStatus = getRequestStatus();
      })
      .addCase(checkoutBuilderThunks.getCheckoutTemplate.fulfilled, (state, action) => {
        const { payload = {} } = action;
        state.template = payload;
        state.getCheckoutTemplate.requestStatus = getResponseStatus();
      })
      .addCase(checkoutBuilderThunks.getCheckoutTemplate.rejected, (state) => {
        state.getCheckoutTemplate.requestStatus = getErrorStatus();
      })
      .addCase(checkoutBuilderThunks.addBrandingFile.pending, (state) => {
        state.addBrandingFile.requestStatus = getRequestStatus();
      })
      .addCase(checkoutBuilderThunks.addBrandingFile.fulfilled, (state, action) => {
        state.template = action.payload;
        state.addBrandingFile.requestStatus = getResponseStatus();
      })
      .addCase(checkoutBuilderThunks.addBrandingFile.rejected, (state) => {
        state.addBrandingFile.requestStatus = getErrorStatus();
      })
      .addCase(checkoutBuilderThunks.updateCheckoutTemplate.pending, (state) => {
        state.updateCheckoutTemplate.requestStatus = getRequestStatus();
      })
      .addCase(checkoutBuilderThunks.updateCheckoutTemplate.fulfilled, (state, action) => {
        state.template = action.payload;
        state.updateCheckoutTemplate.requestStatus = getResponseStatus();
      })
      .addCase(checkoutBuilderThunks.updateCheckoutTemplate.rejected, (state) => {
        state.updateCheckoutTemplate.requestStatus = getErrorStatus();
      })
      .addCase(checkoutBuilderThunks.resetCheckoutTemplate.pending, (state) => {
        state.resetCheckoutTemplate.requestStatus = getRequestStatus();
      })
      .addCase(checkoutBuilderThunks.resetCheckoutTemplate.fulfilled, (state, action) => {
        state.template = action.payload;
        state.resetCheckoutTemplate.requestStatus = getResponseStatus();
      })
      .addCase(checkoutBuilderThunks.resetCheckoutTemplate.rejected, (state) => {
        state.resetCheckoutTemplate.requestStatus = getErrorStatus();
      })
      .addCase(checkoutBuilderThunks.deleteBrandingFile.pending, (state) => {
        state.deleteBrandingFile.requestStatus = getRequestStatus();
      })
      .addCase(checkoutBuilderThunks.deleteBrandingFile.fulfilled, (state, action) => {
        state.template.files = [];
        state.deleteBrandingFile.requestStatus = getResponseStatus();
      })
      .addCase(checkoutBuilderThunks.deleteBrandingFile.rejected, (state) => {
        state.deleteBrandingFile.requestStatus = getErrorStatus();
      });
  },
});

export const checkoutSelector = (state) => state.webshops.checkout;
export const viewSelector = (state) => state.webshops.checkout.view;

export const { setIsMobile } = checkoutBuilderSlice.actions;

export default checkoutBuilderSlice.reducer;
