import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, HStack, Heading, Icon, Text, VStack } from "@chakra-ui/react";

import { ReactComponent as AlertTriangle } from "@assets/img/icons/common/alertTriangle.svg";
import PropTypes from "prop-types";

const modals = {
  create: {
    title: "Generate",
    button: "Generate",
    content: () => (
      <>
        <Text size={"small"} color={"textWhite.400"}>
          A random 32 chars string will be generated and displayed. You will have to store this string yourself, it can
          only be viewed this one time. Be prepared to save it somewhere safe.
        </Text>
        <Text size={"small"} color={"textWhite.400"}>
          If lost, you can generate a new one, but you will have to replace the string everywhere it has been used.
        </Text>
      </>
    ),
  },
  replace: {
    title: "Generate new",
    button: "Generate new key",
    content: () => (
      <HStack
        w={"100%"}
        p={"8px 12px"}
        alignItems={"center"}
        bgColor={"rgba(255, 92, 121, 0.16)"}
        borderRadius={"10px"}
        gap={"8px"}
      >
        <Icon as={AlertTriangle} width={"16px"} height={"16px"} color={"brandRed.400"} />
        <Text size={"smallAlt"} fontWeight={500} color={"brandRed.400"}>
          {"WARNING: If you generate a new key, you will have to replace the string everywhere it has been used."}
        </Text>
      </HStack>
    ),
  },
};

export const Step1 = ({ title, keyValue, handleClose, handleNextStep, generateKey }) => {
  const stepType = keyValue ? "replace" : "create";

  const dispatch = useDispatch();
  const { thunk, selector } = generateKey;
  const { requestStatus = {} } = useSelector(selector);

  useEffect(() => {
    if (requestStatus.completed && !requestStatus.error) {
      handleNextStep();
    }
  }, [handleNextStep, requestStatus]);

  return (
    <VStack gap={"24px"}>
      <VStack alignItems={"flex-start"} gap={"16px"}>
        <Heading color={"textWhite.400"}>{`${modals[stepType].title} ${title}`}</Heading>
        {modals[stepType].content()}
      </VStack>
      <HStack w={"100%"} gap={"16px"} justifyContent={"center"}>
        <Button w={"100%"} onClick={handleClose}>
          Cancel
        </Button>
        <Button w={"100%"} onClick={() => dispatch(thunk())} isLoading={requestStatus.pending}>
          {modals[stepType].button}
        </Button>
      </HStack>
    </VStack>
  );
};

Step1.propTypes = {
  title: PropTypes.string,
  keyValue: PropTypes.string,
  handleClose: PropTypes.func,
  handleNextStep: PropTypes.func,
  generateKey: PropTypes.shape({
    thunk: PropTypes.func,
    selector: PropTypes.func,
    clean: PropTypes.func,
  }),
};
