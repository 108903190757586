import moment from "moment";

const year = moment().year();
const lastMonth = moment().month() - 1;

export const FILTER_DATE_OPTIONS = [
  {
    type: "OPTION",
    name: "Today",
    disabled: false,
    fromDate: `${moment().format("YYYY-MM-DD")}`,
    toDate: `${moment().format("YYYY-MM-DD")}`,
  },
  {
    type: "OPTION",
    name: "Yesterday",
    disabled: false,
    fromDate: `${moment().subtract(1, "days").format("YYYY-MM-DD")}`,
    toDate: `${moment().subtract(1, "days").format("YYYY-MM-DD")}`,
  },
  {
    type: "OPTION",
    name: "Last 7 days",
    disabled: false,
    fromDate: `${moment().subtract(7, "days").format("YYYY-MM-DD")}`,
    toDate: `${moment().subtract(1, "days").format("YYYY-MM-DD")}`,
  },
  {
    type: "OPTION",
    label: "Last calendar month",
    disabled: false,
    fromDate: `${moment([year, lastMonth]).format("YYYY-MM-DD")}`,
    toDate: `${moment([year, lastMonth]).endOf("month").format("YYYY-MM-DD")}`,
  },
];
