import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";

import actions from "@redux/actions";

import { BodyRegularPlus, BodySmall14, BodySmallPlus } from "@styled";
import StyledIntegrationStateCard, { StyledDescriptionLabel } from "./StyledIntegrationStateCard";
import { SanloTooltip } from "@common/tooltips/sanlo-tooltip/SanloTooltip";
import StateButton from "@components/common/buttons/state-button/StateButton";
import Support from "@components/support/Support";
import { integrationCardLabels, integrationStates, integrationToolTip } from "./IntegrationStates";

import {
  sMatch,
  NO_PERMISSIONS,
  PULLING_DATA,
  VERIFYING_CONNECTION,
  CONNECTED,
  DRAFT_CONNECTION,
  NEEDS_RECONNECTION,
  NOT_CONNECTED,
} from "@utils/status";

import { ReactComponent as ContextMenuIcon } from "@assets/img/icons/common/more.svg";

import PropType from "prop-types";

const IntegrationStateCard = ({ integration, lastUpdated, handleToggleConnectModal, handleToggleDisconnectModal }) => {
  const contextMenuRef = useRef();
  const [contextMenu, setContextMenu] = useState(false);
  const dispatch = useDispatch();

  const {
    id = "",
    name = "",
    status = "",
    image = "",
    platformId = "",
    connectionStatus = {},
    requestStatus = {},
  } = integration;
  const pullingData = connectionStatus?.isPullingData;
  let googlePlayFailedToRetrieveServiceAccount = false;

  if (id === "googleplay") {
    googlePlayFailedToRetrieveServiceAccount = requestStatus?.retrieveServiceAccount?.error;
  }

  const handleClickOutside = (event) => {
    if (contextMenuRef.current && !contextMenuRef.current.contains(event.target)) {
      setContextMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <StyledIntegrationStateCard data-test-id={`integration-${platformId}`}>
      <div className="card-content">
        <img className="card-image" src={image} alt="integration" />
        <div className="card-details">
          <div className="platform-headings" style={pullingData ? { justifyContent: "center" } : null}>
            <div className="platform-header">
              <BodyRegularPlus className="platform-name">{name}</BodyRegularPlus>

              {sMatch(status, [CONNECTED, VERIFYING_CONNECTION, NO_PERMISSIONS, PULLING_DATA]) && (
                <div className="context-menu-button" data-test-id={`integration-${platformId}-context-menu`}>
                  <ContextMenuIcon ref={contextMenuRef} onClick={() => setContextMenu(!contextMenu)} />
                  <ContextMenu
                    integration={integration}
                    handleToggleDisconnectModal={() => {
                      handleToggleDisconnectModal(integration);
                    }}
                    handleToggleConnectModal={() => {
                      if (actions?.integrations[id]?.connect) {
                        dispatch(actions.integrations[id].connect.request());
                      } else {
                        handleToggleConnectModal(integration);
                      }
                    }}
                    open={contextMenu}
                    integrationStatus={status}
                  />
                </div>
              )}
            </div>

            <div className="platform-state-container">
              <DescriptionLabel
                pullingData={pullingData}
                integrationStatus={status}
                lastUpdated={lastUpdated}
                integration={integration}
                onRefreshClick={() => {
                  if (actions.integrations[id].refresh) {
                    dispatch(actions.integrations[id].refresh.request());
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <IntegrationCardButton
        integrationStatus={status}
        onClick={() => {
          if (actions?.integrations[id]?.connect) {
            dispatch(actions.integrations[id].connect.request());
          } else {
            handleToggleConnectModal(integration);
          }
        }}
        disabled={googlePlayFailedToRetrieveServiceAccount}
        integrationID={id}
      />
    </StyledIntegrationStateCard>
  );
};

IntegrationStateCard.propTypes = {
  integration: PropType.object,
  lastUpdated: PropType.string,
  handleToggleConnectModal: PropType.func,
  handleToggleDisconnectModal: PropType.func,
};
export const IntegrationCardButton = ({ integrationStatus, onClick, disabled, integrationID }) => {
  const { buttonLabel, state, hasAction, hover } = integrationStates[integrationStatus];

  return (
    <div className="connection-state">
      <StateButton
        hover={hover}
        integration
        state={state}
        disabled={disabled}
        label={buttonLabel}
        onClick={hasAction ? onClick : null}
        id={disabled ? "google-play-retrieve-service-account-error-tooltip" : `state-button-${integrationID}`}
        testId={`${(integrationID || "").toLowerCase()}-connect-button`}
      />

      {disabled && (
        <SanloTooltip
          width={300}
          target="google-play-retrieve-service-account-error-tooltip"
          text={
            <>
              We're sorry something went wrong on our end. We're working on solving this. Please come back later and
              connect later. If you have any questions, please contact us at{" "}
              <Support email className="sanlo-email-label" />
            </>
          }
        />
      )}
    </div>
  );
};

IntegrationCardButton.propTypes = {
  integrationStatus: PropType.string,
  onClick: PropType.func,
  disabled: PropType.bool,
  integrationID: PropType.string,
};

export const ContextMenu = ({
  integration = {},
  open,
  handleToggleConnectModal,
  handleToggleDisconnectModal,
  integrationStatus,
}) => {
  return (
    <div className={"context-menu-dropdown " + (open ? "menu-open" : "")}>
      {/* {sMatch(integrationStatus, [CONNECTED, VERIFYING_CONNECTION]) && ( */}
      {!sMatch(integrationStatus, [NOT_CONNECTED, DRAFT_CONNECTION]) && (
        <button
          onClick={handleToggleDisconnectModal}
          className="context-menu-item"
          data-test-id={`integration-${integration.platformId}-unlink`}
        >
          <BodySmallPlus className="unlink-button">Unlink</BodySmallPlus>
        </button>
      )}
      {integrationStatus === NO_PERMISSIONS && (
        <button
          onClick={handleToggleConnectModal}
          className="context-menu-item"
          data-test-id={`integration-${integration.platformId}-reconnect`}
        >
          <BodySmallPlus>Reconnect</BodySmallPlus>
        </button>
      )}
    </div>
  );
};

ContextMenu.propTypes = {
  integration: PropType.object,
  open: PropType.bool,
  handleToggleConnectModal: PropType.func,
  handleToggleDisconnectModal: PropType.func,
  integrationStatus: PropType.string,
};

export const DescriptionLabel = ({ lastUpdated, integrationStatus, pullingData, integration, onRefreshClick }) => {
  const description = integrationCardLabels(integration, lastUpdated)[pullingData ? PULLING_DATA : integrationStatus];
  const toolTip = integrationToolTip({
    lastUpdated,
  });

  const { state, label } = description ?? {};

  const checkLastUpdated = () => {
    const last = new moment(lastUpdated);
    const now = new moment();
    return moment.duration(now.diff(last)).as("hours") > 24;
  };
  return (
    <StyledDescriptionLabel state={state}>
      <BodySmall14 id={`tt_${integration.platformId}_integration_card`} className="platform-state-description">
        {label}
      </BodySmall14>
      {[NO_PERMISSIONS, NEEDS_RECONNECTION].includes(integrationStatus) && (
        <SanloTooltip
          maxWidth={300}
          target={`tt_${integration.platformId}_integration_card`}
          placement={"top"}
          text={toolTip?.content}
          heading={toolTip?.heading}
        />
      )}
      {checkLastUpdated() && integrationStatus === CONNECTED && !pullingData && (
        <BodySmallPlus onClick={onRefreshClick} className="platform-state-refresh">
          Refresh
        </BodySmallPlus>
      )}
    </StyledDescriptionLabel>
  );
};

DescriptionLabel.propTypes = {
  lastUpdated: PropType.string,
  integrationStatus: PropType.string,
  pullingData: PropType.bool,
  integration: PropType.object,
  onRefreshClick: PropType.func,
};

export default IntegrationStateCard;
