import SanloWebApiService from "./services";

import actions from "@redux/actions";

import adjustActions, {
  ADJUST_GET_DRAFT_CREDENTIALS,
  ADJUST_SAVE_DRAFT_CREDENTIALS,
  ADJUST_SAVE_CREDENTIALS,
  ADJUST_TEST_CREDENTIALS,
  ADJUST_GET_UA_SPEND,
  ADJUST_UNLINK_INTEGRATION,
} from "./actions";

import { showToast } from "@utils/redux";
import { NO_PERMISSIONS } from "@utils/status";

const adjustMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    switch (action.type) {
      case ADJUST_GET_DRAFT_CREDENTIALS.REQUEST:
        SanloWebApiService.adjustGetDraftCredentials()
          .then((response) => response.data)
          .then((data) => {
            dispatch(adjustActions.getDraftCredentials.response(data));
          })
          .catch((error) => {
            dispatch(adjustActions.getDraftCredentials.error(error));
          });
        break;

      case ADJUST_SAVE_DRAFT_CREDENTIALS.REQUEST:
        SanloWebApiService.adjustSaveDraftCredentials(action.credentials)
          .then(() => {
            dispatch(adjustActions.saveDraftCredentials.response(action.credentials));
            if (action.credentials.apiToken) {
              showToast("Your Adjust connection progress was saved! ");
            }
          })
          .catch((error) => {
            dispatch(adjustActions.saveDraftCredentials.error(error));
          });
        break;

      case ADJUST_TEST_CREDENTIALS.REQUEST:
        SanloWebApiService.adjustTestCredentials(action.credentials)
          .then((response) => {
            dispatch(adjustActions.testCredentials.response(response));
            if (action.callback) action.callback(1);
          })
          .catch((error) => {
            dispatch(adjustActions.testCredentials.error(error));
          });
        break;

      case ADJUST_SAVE_CREDENTIALS.REQUEST:
        SanloWebApiService.adjustSaveCredentials(action.credentials)
          .then((response) => response.data)
          .then((response) => {
            dispatch(adjustActions.saveCredentials.response(response));
            dispatch(actions.integrations.base.getIntegrationsConnections.request());
            dispatch(adjustActions.getUASpend.request());

            if (action.callback) action.callback();
            if (action.toggleStep) action.toggleStep(-1);

            if (response.status === NO_PERMISSIONS) {
              showToast("Adjust is connected but we're missing some data", "warning", "noPermissions");
            } else {
              showToast("You have successfully connected to your Adjust account! ");
            }
          })
          .catch((error) => {
            dispatch(adjustActions.saveCredentials.error(error));
          });
        break;

      case ADJUST_UNLINK_INTEGRATION.REQUEST:
        SanloWebApiService.adjustDisconnectIntegration()
          .then((response) => {
            dispatch(adjustActions.unlinkIntegration.response(response));
            dispatch(actions.integrations.base.getIntegrationsConnections.request());
            if (action.callback) action.callback();
            showToast("You have successfully disconnected Adjust!");
          })
          .catch((error) => {
            dispatch(adjustActions.unlinkIntegration.error(error));
          });
        break;

      case ADJUST_GET_UA_SPEND.REQUEST:
        SanloWebApiService.adjustGetUASpend()
          .then((response) => {
            dispatch(adjustActions.getUASpend.response(response.data || {}));
          })
          .catch((error) => {
            dispatch(adjustActions.getUASpend.error(error));
          });
        break;

      default:
        return;
    }
  };

export default adjustMiddleware;
