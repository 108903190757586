import React from "react";
import { animated, config, easings, useTransition } from "react-spring";

const FadeSingleTransition = ({ show, children }) => {
  const transitions = useTransition(show, {
    from: { opacity: 0, maxHeight: "0px" },
    enter: { opacity: 1, maxHeight: "2000px" },
    leave: { opacity: 0, maxHeight: "0px" },
    config: {
      ...config.molasses,
      duration: 1000,
      easing: easings.easeOutQuint,
    },
  });
  return transitions((styles, item) => item && <animated.div style={styles}>{children}️</animated.div>);
};

export default FadeSingleTransition;
