import styled from "styled-components";

const StyledDashboardCard = styled.div`
  & {
    background-color: ${({ theme }) => theme.dashboardCardBackground};
    border: 2px solid transparent;
    border-radius: 12px;
    padding: 18px 24px 0 24px;
    margin-bottom: 24px;

    .dashboard-card-header {
      display: flex;
      align-items: center;
      padding-bottom: 24px;
      border-bottom: 1px solid ${({ theme }) => theme.dashboardCardDivider};
      margin-bottom: 24px;

      .dashboard-card-logo {
        width: 36px;
        height: 36px;
        background-color: ${({ theme }) => theme.grayscale40};
        border-radius: 8px;
        margin-right: 12px;
      }

      .dashboard-card-heading {
        .dashboard-card-title {
          color: ${({ theme }) => theme.dashboardCardTitleFont};
        }

        .dashboard-card-subtitle {
          color: ${({ theme }) => theme.dashboardCardSubtitleFont};
        }
      }
    }

    &.selected-card {
      animation: highlight 3s;
      transition: all 0.5s ease;
    }
  }

  @keyframes highlight {
    0% {
      border-color: ${({ theme }) => theme.primaryColor};
    }

    100% {
      border-color: transparent;
    }
  }
`;

export default StyledDashboardCard;
