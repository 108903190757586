import { Box, Button, Heading, HStack, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { Header } from "@sanlo/ui-components";
import { FontStyler } from "./components/FontStyler";
import { Controller, useForm, useWatch } from "react-hook-form";
import { GradientButton } from "@common/gradient-button/GradientButton";
import { useDispatch } from "react-redux";
import { toggleTypographyCustomizer } from "@redux/webshops/builder/builderSlice";
import { typographyThunks } from "@redux/webshops/builder/typographySlice";
import { useParams } from "react-router-dom";
import { ConfirmationModal } from "@common/modals/confirmation-modal";
import { Banner } from "@common/banner";
import { useSetCustomFonts } from "../../hooks/useSetCustomFonts";
import { showToast } from "@src/utils/redux";
import { hasBadContrast } from "color2k";

const typographyElements = [
  {
    type: "headline",
    element: "heading1",
    label: "Heading 1 - The Cat Jumped over the Moon",
    ariaLabel: "Heading 1 font controller",
    minValue: 20,
    previousElement: null,
  },
  {
    type: "headline",
    element: "heading2",
    label: "Heading 2 - The Cat Jumped over the Moon",
    ariaLabel: "Heading 2 font controller",
    minValue: 18,
    previousElement: "heading1",
  },
  {
    type: "regular",
    element: "callToAction",
    label: "Call to action",
    ariaLabel: "Call to action font controller",
    minValue: 16,
    previousElement: "heading2",
  },
  {
    type: "regular",
    element: "normal",
    label: "Normal - The Cat Jumped over the Moon",
    ariaLabel: "Normal font controller",
    minValue: 14,
    previousElement: "callToAction",
  },
];

export function TypographyCustomizer({ storefront }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { isOpen: isResetModalOpen, onOpen: onResetModalOpen, onClose: onResetModalClose } = useDisclosure();
  const { typography } = storefront?.storefront?.themeV2 ?? {};
  const { headlineFont } = useSetCustomFonts(typography);

  const { control, handleSubmit, formState, reset } = useForm({
    values: {
      heading1: typography?.headline?.heading1,
      heading2: typography?.headline?.heading2,
      callToAction: typography?.regular?.callToAction,
      normal: typography?.regular?.normal,
    },
  });
  const watch = useWatch({ control });

  const headerProps = {
    logo: storefront?.layout?.header?.files[0]?.url ?? "",
    title: storefront?.layout?.header?.title ?? "",
    backgroundColor:
      storefront?.layout?.header?.themeOverride?.background.color ??
      storefront?.storefront?.themeV2?.color?.background?.color,
    primary:
      storefront?.layout?.header?.themeOverride?.primary?.color ??
      storefront?.storefront?.themeV2?.color?.primary?.color,
    headerColor:
      storefront?.layout?.header?.themeOverride?.title?.color ?? storefront?.storefront?.themeV2?.color?.title?.color,
  };

  const backgroundColor = storefront?.storefront?.theme?.background?.color;

  const onSubmit = async (data) => {
    try {
      await dispatch(typographyThunks.updateTypographyProperties({ webshopId: id, data })).unwrap();
    } catch (e) {
      showToast("There was a problem updating the fonts", "error");
    }
  };

  return (
    <>
      <VStack
        as="form"
        onSubmit={handleSubmit(onSubmit)}
        alignItems={"stretch"}
        flex={1}
        h={"fit-content"}
        bg={backgroundColor}
        gap={0}
      >
        <Header
          {...headerProps}
          sx={{ "fontFamily": `${headlineFont.name} !important`, "& h1": { fontWeight: 600 } }}
          pos="relative"
        />
        <Box mt="32px" w="633px" mx="auto">
          <Heading
            size="regular"
            color={hasBadContrast(backgroundColor, "aaa", "#000000") ? "textWhite.400" : "dark.900"}
            fontWeight={600}
          >
            Customize Typography
          </Heading>
          <Text
            size="small"
            mt="8px"
            mb="32px"
            color={hasBadContrast(backgroundColor, "aaa", "#000000") ? "textWhite.400" : "dark.300"}
          >
            Edit basic text styles for your webshop. Click ‘apply’ to save and see your edits in the Builder. You must
            ‘publish’ your webshop to see them live.
          </Text>
          <HStack mt="32px" justifyContent={"space-between"}>
            <Button variant="primary" h="32px" onClick={onResetModalOpen}>
              Reset to default
            </Button>
            <HStack gap={"24px"}>
              <Button h="32px" onClick={() => dispatch(toggleTypographyCustomizer(false))}>
                Close
              </Button>
              <GradientButton h="32px" type="submit" isDisabled={!Object.keys(formState.dirtyFields).length}>
                Apply
              </GradientButton>
            </HStack>
          </HStack>
          {typographyElements.map(({ type, previousElement, element, label, minValue, ariaLabel }) => {
            const limitValue = watch[previousElement]?.fontSize?.desktop;

            return (
              <Controller
                name={element}
                key={`${type}-${element}`}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FontStyler
                    fontProperties={value}
                    label={label}
                    minValue={minValue}
                    limitValue={limitValue}
                    fontFamily={typography?.[type]?.name}
                    onChange={onChange}
                    ariaLabel={ariaLabel}
                  />
                )}
              />
            );
          })}
        </Box>
      </VStack>
      <ConfirmationModal
        isOpen={isResetModalOpen}
        title="Reset all type?"
        acceptText="Reset to defaults"
        cancelText="Cancel"
        onAccept={() => {
          reset();
          dispatch(typographyThunks.resetSelectedFont({ webshopId: id }));
          onResetModalClose();
        }}
        onClose={onResetModalClose}
      >
        <Banner type="error">
          <Text>WARNING: This will delete any custom type styles and reset to using the default font</Text>
        </Banner>
      </ConfirmationModal>
    </>
  );
}
