import React, { cloneElement, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Modal, ModalContent, ModalCloseButton, ModalOverlay } from "@chakra-ui/react";
import { Step1 } from "./Step1";
import { Step2 } from "./Step2";

import PropTypes from "prop-types";

const steps = [<Step1 key="1" />, <Step2 key="2" />];

const variants = {
  enters: (direction) => ({
    x: direction > 0 ? 1000 : -1000,
  }),
  center: {
    x: 0,
  },
  exit: (direction) => ({
    x: direction < 1 ? -1000 : 1000,
  }),
};

export const KeyGeneratorModal = ({ title, keyValue, isOpen, onClose, generateKey }) => {
  const [[step, direction], setStep] = useState([0, 0]);

  const handleClose = () => {
    setStep([0, 0]);
    onClose();
  };

  const handleNextStep = () => {
    setStep([1, 1]);
  };

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={handleClose} isCentered size={"lg"}>
      <ModalOverlay />
      <ModalContent
        overflow={"hidden"}
        bg={"dark.700"}
        borderRadius={"12px"}
        boxShadow={"rgba(0, 0, 0, 0.1) 0px 10px 15px -3px"}
      >
        <ModalCloseButton onClick={handleClose} />
        <AnimatePresence initial={false} mode="popLayout">
          <motion.div
            variants={variants}
            transition={{
              x: { type: "spring", stiffness: 300, damping: 30 },
              opacity: { duration: 0.2 },
            }}
            initial={"enters"}
            animate={"center"}
            custom={direction}
            exit={"exit"}
            key={step}
          >
            {cloneElement(steps[step], { handleNextStep, handleClose, title, keyValue, generateKey })}
          </motion.div>
        </AnimatePresence>
      </ModalContent>
    </Modal>
  );
};

KeyGeneratorModal.propTypes = {
  title: PropTypes.string.isRequired,
  keyValue: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  generateKey: PropTypes.shape({
    thunk: PropTypes.func.isRequired,
    selector: PropTypes.func.isRequired,
    clean: PropTypes.func.isRequired,
  }).isRequired,
};
