import React from "react";
import { ChartHeader, LineChart } from "@components/charts";
import { Box, Text, useTheme } from "@chakra-ui/react";

import { SanloTooltip } from "@components/common/tooltips/sanlo-tooltip/SanloTooltip";
import { currencyFormat } from "@utils/currency";

// import { TooltipContent } from "./tooltip";

export const CashEndOfDate = ({ data = [], verticalLine = {}, isDaily = false, columnWidth = "" }) => {
  const theme = useTheme();
  const title = isDaily ? "Cash balance" : "End of month cash balance";

  return (
    <Box h={"220px"} mb={"32px"}>
      <ChartHeader
        title={title}
        filters={[
          {
            title: "Actual",
            color: "#FFFFFF",
            design: "solid-line",
          },
          {
            title: "Estimated",
            color: "#FFFFFF",
            design: "dashed-line",
          },
        ]}
        type="bar-chart"
        dataTestId="cash-balance-header"
      />

      <LineChart
        chartName={"cash-end-of-date"}
        columnWidth={columnWidth}
        chartId="cash-end-of-date"
        data={data}
        tooltipText={"tooltipText"}
        svgColor={theme.colors.green[400]}
        withNegYAxis={true}
        yAxisStepCount={5}
        verticalLine={verticalLine}
        backgroundLines={false}
        yAxisWidth={"60px"}
        checkIntermediates={isDaily ? true : false}
        tooltipContent={(item) => {
          const { rawDate = "", monthlyAmount, isPredicted = false } = item;
          const formattedAmount = currencyFormat(monthlyAmount, {
            notation: "standard",
          });

          const showPredictedText = isPredicted && isDaily;
          const predictedText =
            "Estimated daily cash balance is currently not available. You can still see your estimated end of month cash balances in the Monthly view.";

          // Green text for positive values, red text for negative values
          return (
            <SanloTooltip
              width={showPredictedText ? 236 : null}
              target={`cash-end-of-date-svg-circle-${rawDate}`}
              placement={"top"}
              id={`cash-end-of-date-line-tooltip-${rawDate}`}
            >
              <Box width={"100%"}>
                <Text
                  size={showPredictedText ? "mini" : "small"}
                  fontWeight={showPredictedText ? "400" : "600"}
                  letterSpacing={showPredictedText ? "0.02em" : "0.01em"}
                  textAlign={"left"}
                  fontStyle={"normal"}
                  color={showPredictedText ? "textWhite.400" : monthlyAmount >= 0 ? "green.400" : "red.400"}
                >
                  {showPredictedText ? predictedText : formattedAmount}
                </Text>
              </Box>
            </SanloTooltip>
          );
        }}
      />
    </Box>
  );
};
