import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { withTheme } from "styled-components";

import { StyledPageContainer, StyledPageSection, PageHeader } from "@components/page";
import { SanloTooltip } from "@components/common/tooltips/sanlo-tooltip/SanloTooltip";
import { InternalLink } from "@inputs";

import { StatsSection } from "./components/stats-section";
import { GameMetrics } from "./components/game-metrics";
import { CashBurnChart } from "./components/cash-burn-chart";
import UpcomingPayoutsContainer from "@components/dashboard/upcoming-payouts/UpcomingPayoutsContainer";
import { DailyRevenueContainer } from "@components/dashboard/daily-revenue/DailyRevenueContainer";
import { CashFlowBreakdown } from "./components/cash-flow-breakdown";
import { CashActivity } from "./components/cash-activity";
import { ChartHeader } from "@components/charts";

import { ReactComponent as AlertCircle } from "@assets/img/icons/common/alertCircle.svg";

import { financialsThunks } from "@redux/financials/financialsSlice";
import { gameMetricsThunks } from "@redux/game-metrics/gameMetricsSlice";
import { paymentsThunks } from "@redux/payments/paymentsSlice";

import ErrorBoundary from "@components/ErrorBoundary";
import { useFlags } from "launchdarkly-react-client-sdk";
import { trackGTMEvent, useTrackHover, FINANCIALS_ACTION, FINANCIALS_PATHNAME } from "@utils/gtm";
import { Box, Divider, HStack, Heading, Link, Text, VStack } from "@chakra-ui/react";

export const FinancialIntelligencePage = withTheme(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    cashBurnChartEnable = false,
    clientFinancialsCashFlowBreakdownEnabled = false,
    clientFinancialsAppMetricsEnabled = false,
  } = useFlags();
  const [addTracking, removeTracking] = useTrackHover();

  useEffect(() => {
    dispatch(financialsThunks.getMonthlyReport());
    dispatch(paymentsThunks.getUpcomingPayments());
    if (clientFinancialsCashFlowBreakdownEnabled) {
      dispatch(financialsThunks.getCashflowBreakdown());
    }
    if (clientFinancialsAppMetricsEnabled) {
      dispatch(gameMetricsThunks.getGameMetrics());
    }
    // eslint-disable-next-line
  }, []);

  const handleMouseEnter = () => {
    addTracking({
      event: FINANCIALS_ACTION.HOVER_TOOLTIP,
      name: "Last Updated",
      pathname: FINANCIALS_PATHNAME,
    });
  };

  const handleMouseLeave = () => {
    removeTracking();
  };

  return (
    <StyledPageContainer>
      <PageHeader
        header="Financial analytics"
        subheader={
          <>
            Link your relevant business accounts from the{" "}
            <Link
              borderBottom={"1px solid"}
              onClick={() => {
                navigate("/integrations", { state: { prevPath: location.pathname } });
              }}
              _hover={{}}
            >
              integrations
            </Link>{" "}
            page to view your insights.
          </>
        }
      >
        <HStack>
          <Text size="small" opacity={0.6} data-test-id="lastupdated">
            Updated within the last 24 hrs
          </Text>
          <AlertCircle
            id="tooltip-target-fin-os-lastupdated"
            color={"#C5C5C5"}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{
              transform: "rotate(180deg)",
              marginTop: "-2px",
            }}
          />
        </HStack>
        <SanloTooltip
          width={260}
          target="tooltip-target-fin-os-lastupdated"
          placement="bottom"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Text size={"small"} fontWeight={400} color="textWhite.400" lineHeight={"20px"}>
            {`The displayed data is aggregated from all connected accounts. New accounts' data will
            be available in 24 hours after it's fully connected. To manage your integrations, please visit the `}
            <InternalLink
              to="/integrations"
              onClick={() =>
                trackGTMEvent({
                  event: FINANCIALS_ACTION.INTEGRATION_PAGE_LINK,
                  tooltip: "Last Updated",
                })
              }
            >
              Integrations page
            </InternalLink>
            .
          </Text>
        </SanloTooltip>
      </PageHeader>

      <Text size={"mini"} mt={"-16px"} mb={"24px"} id={"disclaimer-financial-analytics"} color={"textSubtext.400"}>
        Note: Features that rely on banking information, such as current balance, net income/burn, and historic cashflow
        analyses, are currently available with{" "}
        <Text size={"mini"} as={"span"} borderBottom={"1px solid"} color={"textSubtext.400"}>
          linked US bank accounts only
        </Text>
        .
      </Text>

      <ErrorBoundary name="financials-page-content">
        <ErrorBoundary track trackHover name="financials-stats-section">
          <StatsSection />
        </ErrorBoundary>

        <ErrorBoundary
          track
          trackHover
          name="financials-product-metrics-section"
          show={clientFinancialsAppMetricsEnabled}
        >
          <GameMetrics />
        </ErrorBoundary>

        <ErrorBoundary name="financials-cash-activity-section">
          <Box alignItems={"stretch"} gap="24px" mb="24px">
            <Heading size={"large"} fontWeight={600} data-test-id={"cash-activity-title"}>
              Cash activity
            </Heading>
            <Text size="small" mt="4px" data-test-id={"cash-activity-sub-title"}>
              A detailed analysis of your cash flows - both past and upcoming.
            </Text>
            <Divider borderBottomWidth={0} height={"1px"} bg="dark.300" />
          </Box>
        </ErrorBoundary>

        <ErrorBoundary track trackHover name="financials-net-monthly-burn-section" show={cashBurnChartEnable}>
          <StyledPageSection data-scroll-target="cash-burn-chart">
            <ChartHeader
              title={"Net monthly income/burn"}
              tooltipText={
                <VStack alignItems={"flex-start"} gap={"10px"}>
                  <Text size={"mini"}>
                    This is your net cash flow in the last completed calendar month. A positive value indicates net
                    income, while a negative value indicates net burn.
                  </Text>
                  <Text size={"mini"}>Formula = Revenue - Cash out</Text>
                </VStack>
              }
              type="line-burn-chart"
              dataTestId="net-monthly-burn-header"
            />
            <CashBurnChart />
          </StyledPageSection>
        </ErrorBoundary>

        <ErrorBoundary track trackHover name="financials-cash-flows-overview-section">
          <StyledPageSection>
            <CashActivity />
          </StyledPageSection>
        </ErrorBoundary>

        <ErrorBoundary
          track
          trackHover
          name="financials-historical-cash-flow-details-section"
          show={clientFinancialsCashFlowBreakdownEnabled}
        >
          <StyledPageSection data-scroll-target="cash-flow-breakdown">
            <CashFlowBreakdown />
          </StyledPageSection>
        </ErrorBoundary>

        <ErrorBoundary track trackHover name="financials-upcoming-cash-flow-details-section">
          <StyledPageSection data-scroll-target="upcoming-payments" data-test-id="upcoming-payments-wrapper">
            <UpcomingPayoutsContainer title={"Upcoming cash flow details"} />
          </StyledPageSection>
        </ErrorBoundary>

        <ErrorBoundary track trackHover name="financials-daily-revenue-section">
          <StyledPageSection>
            <DailyRevenueContainer />
          </StyledPageSection>
        </ErrorBoundary>
      </ErrorBoundary>
    </StyledPageContainer>
  );
});
