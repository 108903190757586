import React from "react";
import { BetaTag } from "@components/common/banners/BetaTag";

import { PRODUCT_ANALYTICS, IN_APP_PURCHASES } from "@utils/consts";
import text from "@utils/text";

import InAppPurchasesLinkingAccordion from "@components/onboarding/platform-accordion/platforms-linking-flows/in-app-purchases/InAppPurchasesLinkingAccordion";
import ProductAnalyticsLinkingAccordion from "@components/onboarding/platform-accordion/platforms-linking-flows/product-analytics/ProductAnalyticsLinkingAccordion";

const onboardingStep = 4;

export const SECONDARY_FLOW_ACCORDIONS_DATA = [
  {
    id: IN_APP_PURCHASES,
    title: text.t("platforms"),
    suggestionType: IN_APP_PURCHASES,
    content: (
      <InAppPurchasesLinkingAccordion description={text.t("iap_accordion_desc")} onboardingStep={onboardingStep} />
    ),
  },
  {
    id: PRODUCT_ANALYTICS,
    title: (
      <>
        {`${text.t("ad_networks_and_user_acq_spend")} `}
        <BetaTag />
      </>
    ),
    suggestionType: PRODUCT_ANALYTICS,
    content: (
      <ProductAnalyticsLinkingAccordion
        description={text.t("pa_accordion_desc_secondary")}
        note={text.t("pa_accordion_note_secondary")}
        onboardingStep={onboardingStep}
      />
    ),
  },
];
