import React, { useEffect } from "react";

import AuthenticationContainer from "@src/pages/authentication/AuthenticationContainer";
import { SanloModal, SanloModalTitle } from "@components/common/modals/SanloModal/SanloModal";
import { SanloButton } from "@inputs";

import { trackGTMEvent } from "@utils/gtm";
import { useLogout } from "@utils/custom-hooks/useAuth";

export const InvalidEmailError = () => {
  const appLogout = useLogout();

  // Tag Manager InvalidEmailError Component View Event
  useEffect(() => {
    trackGTMEvent({
      "event": "Component View",
      "Component Name": "InvalidEmailError",
    });
  }, []);

  return (
    <AuthenticationContainer error>
      <SanloModal>
        <SanloModalTitle>Please provide a work email address in order to sign up.</SanloModalTitle>
        <SanloButton
          label="Sign up"
          onClick={() => {
            appLogout();
          }}
          style={{
            marginTop: "24px",
          }}
        />
      </SanloModal>
    </AuthenticationContainer>
  );
};
