import React from "react";
import { getDailyRevenueBlurryMessage } from "@src/consts/blur";

const NothingConnectedBodyState = ({ isPullingData, isBroken }) => {
  return (
    <div className="dr-message absolute-position">
      <div className="dr-message-text">{getDailyRevenueBlurryMessage({ isPullingData, isBroken })}</div>
    </div>
  );
};

export default NothingConnectedBodyState;
