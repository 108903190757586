import { StyledProgressTooltip } from "../../StyledAdvanceCard";
import React from "react";
import { BodySmallAlt } from "@styled";
import TooltipRow from "./TooltipRow";
import text from "@utils/text";

const RemainingTooltip = ({ repaymentAmountLeft }) => {
  return (
    <StyledProgressTooltip>
      <div className={"tooltip-content"}>
        <TooltipRow label={text.t("remaining_balance")} display={repaymentAmountLeft} />
        <BodySmallAlt>{text.t("remaining_balance_hint")}</BodySmallAlt>
      </div>
    </StyledProgressTooltip>
  );
};

export default RemainingTooltip;
