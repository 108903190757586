import React from "react";
import { useSelector } from "react-redux";

import { BodySmall14, BodySmallPlus, TitleSmallPlus } from "@styled";
import StyledOnboardingDashboard from "../StyledOnboardingDashboard";
import OnboardingStepAnalytics from "@pages/onboarding/step-analytics/OnboardingStepAnalytics";
import EncourageMessage from "@common/banners/EncourageMessage";
import Support from "@components/support/Support";

import symbols from "@utils/symbols";
import useIntegrationsStatus from "@utils/custom-hooks/useIntegrationsStatus";
import { CONNECTED } from "@utils/status";

import { ReactComponent as CrossIcon } from "@assets/img/icons/common/modal-cross.svg";

const RejectedCapitalRequestCard = ({ onCardDismiss }) => {
  const inAppPurchasesConnected = useIntegrationsStatus().inAppPurchases.status === CONNECTED;
  const inAppPurchasesInProgress = useSelector((state) => state.session.onboarding.data.platformsLinked);

  return (
    <StyledOnboardingDashboard>
      <div className="od-wrapper">
        <div className="od-header-with-cta">
          <TitleSmallPlus className="od-content-title">
            {symbols.drum} We can't offer you Growth Capital just yet
          </TitleSmallPlus>
          <div className="dismiss-icon">
            <CrossIcon onClick={onCardDismiss} />
          </div>
        </div>

        <div className="od-content">
          <div
            className="od-content-left-col"
            style={{
              width: inAppPurchasesConnected ? "100%" : "48%",
            }}
          >
            <BodySmall14 className="od-content-description">
              <div className="od-callout-paragraph">
                We are not able to offer Growth Capital to you today. We’ve sent you an email with further details.
              </div>
              <div className="od-callout-paragraph">
                However, we know that things can change rapidly. Now that you are part of the Sanlo eco-system, our
                platform continues monitoring your data so we can act fast and offer funding to you the moment
                performance changes.
              </div>
            </BodySmall14>
            <BodySmall14 className="od-questions-label">
              Please{" "}
              <BodySmallPlus className="od-label-button">
                <Support text="get in touch with us" />
              </BodySmallPlus>{" "}
              if you have any questions.
            </BodySmall14>
          </div>

          {!inAppPurchasesConnected && (
            <div className="od-content-right-col">
              {!inAppPurchasesInProgress && (
                <EncourageMessage
                  color="ElectricBlue"
                  description={`We have great news! Connect Platforms in addition to already connected ad networks so you can be on track with your historical revenue and upcoming payments.`}
                  emoji={symbols.lightBulb}
                />
              )}
              <OnboardingStepAnalytics fromDashboard rejectedCapitalRequest />
            </div>
          )}
        </div>
      </div>
    </StyledOnboardingDashboard>
  );
};

export default RejectedCapitalRequestCard;
