import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import actions from "@redux/actions";

import ErrorBoundary from "@components/ErrorBoundary";
import CapitalButton from "./CapitalButton";

const CapitalButtonContainer = ({
  className = "",
  section = "growth-capital",
  isReapply = false,
  isNewCapital = false,
  isFromDashboard = false,
  openCapitalRequest,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const hasExpiredOffer = useSelector((state) => state.offers.data.hasExpiredOffer);
  const advanceSummary = useSelector((state) => state.integrations.advances.data.summary);
  const { requestedCapitalActive, allowedToCreateRequest } = advanceSummary;

  const handleNewCapital = () => {
    openCapitalRequest && openCapitalRequest(true);
    dispatch(actions.integrations.advances.capitalRequestStart.request());
  };

  const handleReapply = () => {
    dispatch(actions.integrations.advances.capitalRequestClone.request({}));
  };

  const handleFromDashboard = () => {
    navigate("/growth-capital");
  };

  const handleClick = () => {
    if (isNewCapital) {
      return handleNewCapital();
    }
    if (isReapply) {
      return handleReapply();
    }
    if (isFromDashboard) {
      return handleFromDashboard();
    }
  };

  const id = `${section}-get-capital`;
  return (
    <ErrorBoundary name={`${id}-key`}>
      <CapitalButton
        id={id}
        className={className}
        hasExpiredOffer={hasExpiredOffer}
        requestedCapitalActive={requestedCapitalActive}
        allowedToCreateRequest={allowedToCreateRequest}
        handleClick={handleClick}
        isReapply={isReapply}
        isNewCapital={isNewCapital}
        isFromDashboard={isFromDashboard}
      />
    </ErrorBoundary>
  );
};

export default CapitalButtonContainer;
