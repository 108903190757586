import styled from "styled-components";

export const StyledDismissibleBanner = styled.div`
  flex-direction: column;
  padding: 20px 24px;
  margin-bottom: 24px;
  margin-top: ${({ allowMarginTop }) => (allowMarginTop ? "24px" : 0)};
  border-radius: 12px;
  background: ${({ theme }) => theme.dashboardPullingDataCardBackground};

  .banner-title {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      cursor: pointer;
      fill: ${({ theme }) => theme.textEmphasis};
    }
  }

  .banner-content {
    display: block;

    &.no-title {
      text-align: center;

      > .left-align {
        text-align: left;
      }
    }
  }
`;
