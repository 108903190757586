import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFlags } from "launchdarkly-react-client-sdk";

import { BodyMini12, BodySmallAlt } from "@styled";
import StyledPlatformLinkingContent from "../StyledPlatformLinkingContent";
import ConnectedPlatformCard from "../ConnectedPlatformCard";
import { SanloTooltip } from "@components/common/tooltips/sanlo-tooltip/SanloTooltip";
import { RemoveMMPModal } from "@components/integrations/RemoveMMPModal";

import { INTEGRATIONS, filterIntegrations } from "@utils/integrations";
import { unlinkMMP, checkPlatformConflict } from "@utils/defaults/platforms";
import { PRODUCT_ANALYTICS } from "@utils/consts";
import { sMatch, NOT_CONNECTED, DRAFT_CONNECTION } from "@utils/status";
import { trackGTMEvent, ONBOARDING_ACTIONS } from "@utils/gtm";

const ProductAnalyticsLinkingAccordion = ({ description, note, onSelectPlatform, onboardingStep }) => {
  const { clientAdjustShow } = useFlags();

  const integrations = useSelector((state) => state.integrations);

  const [unconnectedIntegrations, setUnconnectedIntegrations] = useState([]);
  const [connectedIntegrations, setConnectedIntegrations] = useState([]);

  const [removeMMPModalVisible, toggleRemoveMMPModal] = useState(false);
  const [clickedPlatform, setClickedPlatform] = useState(null);

  useEffect(() => {
    const unconnected = [];
    const connected = [];
    filterIntegrations(integrations, PRODUCT_ANALYTICS).forEach((integration) => {
      const { id = "", status = "" } = integration;
      if (!clientAdjustShow && id === INTEGRATIONS.adjust.id) return;
      if (!sMatch(status, [NOT_CONNECTED, DRAFT_CONNECTION])) {
        connected.push(integration);
      } else {
        unconnected.push(integration);
      }
    });
    setUnconnectedIntegrations(unconnected);
    setConnectedIntegrations(connected);
  }, [integrations, clientAdjustShow]);

  return (
    <>
      <StyledPlatformLinkingContent>
        <div className="description-container">
          <BodySmallAlt className="platform-description">{description}</BodySmallAlt>
        </div>

        <div className="platforms-container">
          {unconnectedIntegrations.map((integration) => {
            const { id = "", platformId = "", image = "", name = "", type = "" } = integration;
            return (
              <Fragment key={`key-frag-product-analytics-platform-${id}`}>
                <div
                  key={`product-analytics-platform-${id}`}
                  id={`product-analytics-platform-${id}-tooltip`}
                  onClick={() => {
                    trackGTMEvent({
                      event: ONBOARDING_ACTIONS.CONNECT_SOURCE_ICON,
                      type,
                      name,
                    });
                    if (checkPlatformConflict(integration, integrations)) {
                      setClickedPlatform(integration);
                      toggleRemoveMMPModal(true);
                      return;
                    }
                    onSelectPlatform(integration);
                  }}
                  className="platform-logo-wrapper"
                  data-test-id={`${platformId}-connect-button`}
                >
                  <div className={`platform-logo tag-sanlo-onboarding-step${onboardingStep}-add-${id}`}>
                    <img
                      className={`tag-sanlo-onboarding-step${onboardingStep}-add-${id}`}
                      src={image}
                      alt="platform-logo"
                    />
                  </div>
                </div>
                <SanloTooltip target={`product-analytics-platform-${id}-tooltip`} text={name} placement={"top"} />
              </Fragment>
            );
          })}
        </div>

        {connectedIntegrations.map((integration) => {
          const { status } = integration;
          return (
            <div
              className="draft-connected-platform"
              key={`integration-${integration.platformId}-${status}`}
              data-test-id={`integration-${integration.platformId}`}
            >
              <ConnectedPlatformCard
                platform={integration}
                status={status}
                handlers={{
                  DRAFT_CONNECTION: () => onSelectPlatform(integration),
                  NEEDS_RECONNECTION: () => onSelectPlatform(integration),
                  NO_PERMISSIONS: () => onSelectPlatform(integration),
                  INVALID_API_TOKEN: () => onSelectPlatform(integration),
                  INVALID_APP_ID: () => onSelectPlatform(integration),
                }}
              />
            </div>
          );
        })}

        <div className="secondary-description-container">
          <BodyMini12 className="secondary-description">{note}</BodyMini12>
        </div>
      </StyledPlatformLinkingContent>

      <RemoveMMPModal
        toggleRemoveMMPModal={toggleRemoveMMPModal}
        removeMMPModalVisible={removeMMPModalVisible}
        integration={checkPlatformConflict(clickedPlatform, integrations)}
        handleRemoveMMPIntegration={() => {
          unlinkMMP(checkPlatformConflict(clickedPlatform, integrations));
          toggleRemoveMMPModal(false);
          onSelectPlatform(clickedPlatform);
        }}
      />
    </>
  );
};

export default ProductAnalyticsLinkingAccordion;
