import React from "react";
import { StyledGcOffers } from "@components/gc-offers/GcOffers.styled";
import GcOffers from "./GcOffers";
import DashboardCardWrapper from "@components/dashboard/DashboardCardWrapper";

const OfferContainer = ({ onTrackAdvance, onboardingType }) => {
  return (
    <DashboardCardWrapper smallMargin>
      <StyledGcOffers>
        <GcOffers onTrackAdvance={onTrackAdvance} onboardingType={onboardingType} />
      </StyledGcOffers>
    </DashboardCardWrapper>
  );
};

export default OfferContainer;
