import styled, { css } from "styled-components";
import { UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";

import { BodySmallPlusCSS } from "@styled";

import { ReactComponent as ArrowIcon } from "@assets/img/icons/common/accordion-arrow.svg";

const StyledButtonCSS = css`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 !important;
  padding: 12px;
  background-color: transparent;
  border: none;
  box-shadow: none;
  background-color: transparent !important;

  &:focus,
  &:active,
  &:hover {
    outline: none !important;
    box-shadow: none !important;
  }

  &:focus,
  &:active {
    background-color: transparent !important;
    color: ${({ theme }) => theme.textSeashellWhite} !important;
  }
`;

export const StyledDropdown = styled(UncontrolledDropdown)`
  position: relative;
  width: 100%;
  height: 40px;
  box-shadow: none;
  border-radius: 10px;

  background-color: ${({ theme }) => theme.grayscale40} !important;
  color: ${({ theme }) => theme.textSeashellWhite};
  border: 2px solid ${({ theme }) => theme.grayscale40};

  ::placeholder {
    color: ${({ theme, error }) => (error ? theme.colorRed : theme.textSubtext)};
  }

  :hover {
    border-color: ${({ theme }) => theme.grayscale70};
  }

  :focus,
  :focus-within {
    border-color: ${({ theme }) => theme.colorRed};
    background-color: ${({ theme }) => theme.grayscale10};
  }
`;

export const StyledToggle = styled(({ isSelected, ...rest }) => <DropdownToggle {...rest} />)`
  ${StyledButtonCSS};
  ${BodySmallPlusCSS};
  color: ${({ theme }) => theme.textSeashellWhite};
`;

export const StyledArrow = styled(ArrowIcon)`
  margin-left: 6px;
  fill: ${({ theme }) => theme.grayFontColor};
  transform: rotate(180deg);
`;

export const StyledMenu = styled(DropdownMenu)`
  width: auto !important;
  min-width: 100% !important;
  transform: none !important;
  top: 36px !important;
  background-color: ${({ theme }) => theme.grayscale20};
  padding: 8px;
  border-radius: 10px;
  box-shadow: 0 0 48px 0 ${({ theme }) => theme.shadowColor};
`;

export const StyledOption = styled(({ isSelected, isDisabled, ...rest }) => <DropdownItem {...rest} />)`
  ${StyledButtonCSS};
  ${BodySmallPlusCSS};

  padding: 8px;
  color: ${({ theme }) => theme.textGray};
  border-radius: 8px;

  &:hover {
    background-color: ${({ theme }) => theme.colorRed} !important;
    color: ${({ theme }) => theme.grayscale0} !important;
  }

  &:not(:last-child) {
    margin-bottom: 4px;
  }

  ${({ isSelected, theme }) =>
    isSelected &&
    `
    background-color: ${theme.colorRed} !important;
    color: ${theme.grayscale0} !important;
  `};

  ${({ isDisabled }) =>
    isDisabled &&
    `
    cursor: not-allowed !important;
    opacity: 0.5;
  `};
`;
