import React, { useState } from "react";
import { ChartHeader, BarChart } from "@components/charts";
import { Box, useTheme } from "@chakra-ui/react";
import { trackGTMEvent, FINANCIALS_ACTION } from "@utils/gtm";
import { TooltipContent } from "./tooltip";
import { BarScaleSelector } from "./BarScaleSelector";

export const CashFlow = ({
  cashFlowData = [],
  verticalLine = {},
  isDaily = false,
  columnWidth = "",
  barScaleSelected = {},
}) => {
  const theme = useTheme();
  const [hideBarIn, setHideBarIn] = useState(false);
  const [hideBarOut, setHideBarOut] = useState(false);
  const { percentZoom } = barScaleSelected;

  const handleBar = (bar, value) => {
    const state = value ? "Hide" : "Display";
    let barLabel;

    if (bar === "cash-in") {
      barLabel = "Cash in";
      setHideBarIn(value);
    } else {
      barLabel = "Cash out";
      setHideBarOut(value);
    }

    trackGTMEvent({
      event: FINANCIALS_ACTION.CASH_ACTIVITY_FLOW_BAR_FILTER,
      name: barLabel,
      state,
    });
  };

  const chartId = "activity-cash-flow-chart";

  const filters = [
    { title: "Cash in", color: theme.colors.brandGreen[400], onClick: (value) => handleBar("cash-in", value) },
    { title: "Cash out", color: theme.colors.brandPurple[400], onClick: (value) => handleBar("cash-out", value) },
  ];

  return (
    <Box h={"350px"} mb={isDaily ? "80px" : "32px"}>
      <ChartHeader
        title={`${isDaily ? "Daily" : "Monthly"} cash flow`}
        filters={filters}
        type="bar-chart"
        dataTestId="cash-flow-header"
      />
      {isDaily && <BarScaleSelector chartId={chartId} barScaleSelected={barScaleSelected} />}
      <BarChart
        chartId={chartId}
        columnWidth={columnWidth}
        data={cashFlowData}
        hideBarIn={hideBarIn}
        hideBarOut={hideBarOut}
        withNegYAxis
        withPredictions
        dailyView={isDaily}
        verticalLine={verticalLine}
        tooltipWidth={"fit-content"}
        TooltipContent={TooltipContent}
        yAxisShorthand={1000}
        chartMaxWidth={"816px"}
        yAxisWidth={"60px"}
        zoomInScale={isDaily ? percentZoom : 0}
        barScaling
      />
    </Box>
  );
};
