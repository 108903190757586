import styled from "styled-components";
import { Modal } from "reactstrap";

const StyledLogoutModal = styled(Modal)`
  & {
    .modal-content {
      width: 480px;
      background-color: ${({ theme }) => theme.grayscale10};
      border-radius: 12px;
      box-shadow: 0 0 48px ${({ theme }) => theme.shadowColor};
      padding: 28px 32px 32px 32px;

      .logout-modal-content {
        color: ${({ theme }) => theme.textSeashellWhite};
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .desc {
          color: ${({ theme }) => theme.textGray};
          margin-top: 8px;
        }
        > img {
          margin-top: 16px;
        }

        .btns {
          gap: 1.5rem;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          margin-top: 2rem;
          margin-left: 5rem;

          button:last-child {
            margin-right: inherit;
          }
        }

        .custom-loader {
          width: 50px;
          height: 50px;
          margin-top: 24px;
          margin-bottom: -24px;
        }
      }
    }
  }
`;

export default StyledLogoutModal;
