import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { ThemeContext } from "styled-components";

import { BodyRegularPlus, BodySmall14, TitleLargePlus } from "@styled";
import { StyledGrowthCapitalSummary, StyledGoToRepaidButton } from "./GrowthCapitalSummary.styled";
import { SanloTooltip } from "@components/common/tooltips/sanlo-tooltip/SanloTooltip";
import { ReactComponent as ToolTipIcon } from "@assets/img/icons/common/tooltip.svg";
import PieChart from "./PieChart";
import PieChartLegend from "./PieChartLegend";

import symbols from "@utils/symbols";
import { getAdvancesTotal, getUpcomingRepayments, getAdvancesSummary } from "@utils/growth-capital";
import { TABS } from "./GrowthCapitalPage";

const GrowthCapitalSummary = ({ advances, platforms, handleTabChange }) => {
  const theme = useContext(ThemeContext);
  const companyName = useSelector((state) => state.session.company.companyName);
  const upcomingRepayments = getUpcomingRepayments(advances, platforms);
  const gcsSizeClass = upcomingRepayments.length >= 3 ? "three-plus" : "";
  // Way easier than dealing with pixel height/perfection showing/hiding the scrollbar
  const scrollable = upcomingRepayments.length > 3;

  return (
    <StyledGrowthCapitalSummary className={gcsSizeClass}>
      <div className="gcs-container gcs-received">
        <BodyRegularPlus className="title">{symbols.MoneyBag()} Total Capital Received</BodyRegularPlus>
        <TitleLargePlus className="amount">{getAdvancesTotal(advances)}</TitleLargePlus>
        <ToolTipIcon id="gcs-tt-total-capital-received" className="gcs-tooltip received-tooltip" />
        <SanloTooltip
          width={400}
          target="gcs-tt-total-capital-received"
          text={
            <TotalCapitalReceivedTTtext
              companyName={companyName}
              handleTabChange={handleTabChange}
              tab={TABS.find((tab) => tab.name === "Repaid")?.id}
            />
          }
          placement="top"
        />
      </div>

      <div className="gcs-container gcs-repayments">
        <BodyRegularPlus className="title">Est. Upcoming Repayments</BodyRegularPlus>
        <div className={`list-wrapper ${scrollable ? "scrollY" : ""}`}>
          <table className="list">
            <tbody>
              {upcomingRepayments.map((repayment, index) => {
                const { uniqueKey, platformIcon, platformName, gameIcon, gameTitle, displayDate, displayAmount } =
                  repayment;

                return (
                  <tr key={`key_${uniqueKey}_${index}`} className="listRow">
                    <td className="platformCell">
                      <img src={platformIcon} alt="platformIcon" className="icon" />
                      <BodyRegularPlus className="amount">{displayAmount}</BodyRegularPlus>
                    </td>
                    <td className="gameCell">
                      <img src={gameIcon} alt="gameIcon" className="icon" />
                      <BodySmall14 id={`id_title_${index}`} className="name">
                        {gameTitle}
                      </BodySmall14>
                      <SanloTooltip target={`id_title_${index}`} text={gameTitle} placement="top" />
                    </td>
                    <td className="dateCell">
                      <BodySmall14 className="date">~{displayDate}</BodySmall14>
                      <ToolTipIcon className="gcs-tooltip" id={`gcs-tt-${uniqueKey}`} />
                      <SanloTooltip
                        maxWidth={280}
                        target={`gcs-tt-${uniqueKey}`}
                        text={`
                          We collect repayment the day after you receive earnings
                          from ${platformName} (est. on ${displayDate})
                        `}
                        placement="top"
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <div className="gcs-container gcs-chart">
        <BodyRegularPlus className="gcs-chart-title">Repayments Summary</BodyRegularPlus>
        <PieChart className={`gcs-pie-chart ${gcsSizeClass}`} data={getAdvancesSummary(advances, theme)} />
        <PieChartLegend className="gcs-chart-legend" data={getAdvancesSummary(advances, theme)} />
      </div>
    </StyledGrowthCapitalSummary>
  );
};

const TotalCapitalReceivedTTtext = ({ companyName, handleTabChange, tab }) => (
  <span>
    This is how much capital Sanlo has sent to {companyName}'s bank account. This only includes your active Growth
    Capital. To see total capital received and already fully repaid, please check
    <StyledGoToRepaidButton onClick={() => handleTabChange(tab)}>Repaid</StyledGoToRepaidButton> tab.
  </span>
);

export default GrowthCapitalSummary;
