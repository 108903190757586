import { Box, Button, Icon, VStack } from "@chakra-ui/react";
import { ResolutionToggle } from "./resolution-toggle";
import { ReactComponent as GlobeIcon } from "@assets/img/icons/common/globe.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setIsMobile } from "@redux/webshops/checkout-builder/checkoutSlice";

function isActive(pathname, path) {
  return pathname.split("/").pop() === path;
}

export function Sidebar() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function handleResolutionChange(isDesktop) {
    dispatch(setIsMobile(!isDesktop));
  }

  return (
    <Box>
      <ResolutionToggle onResolutionChange={handleResolutionChange} />
      <VStack alignItems={"stretch"} gap={"16px"} mt="32px">
        <Button
          variant="builder"
          h="48px"
          isActive={isActive(pathname, "builder")}
          justifyContent={"flex-start"}
          leftIcon={
            <Icon w="16px" h="16px" color={isActive(pathname, "builder") ? "#ffffff" : "#BBBEC7"} as={GlobeIcon} />
          }
          onClick={() => navigate("builder")}
        >
          Design
        </Button>
      </VStack>
    </Box>
  );
}
