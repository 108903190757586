import React from "react";

import { BodySmallAlt } from "@styled";

import symbols from "@utils/symbols";

const NoPermissionBodyState = ({ selectedOption, onOptionConnectClick }) => {
  return (
    <div className="dr-message">
      <BodySmallAlt className="dr-message-text emoji-mr">
        {symbols.Drums()}{" "}
        {`You've successfully connected your ${selectedOption.name} account! But, it looks like we don't have access to some data. We'll reach out to you shortly with next steps.`}
      </BodySmallAlt>
    </div>
  );
};

export default NoPermissionBodyState;
