import { Flex, Spinner as ChakraSpinner } from "@chakra-ui/react";
import PropTypes from "prop-types";

export const Spinner = ({ style = {} }) => {
  return (
    <Flex width="100%" height="100%" justifyContent="center" alignItems="center" {...style}>
      <ChakraSpinner size="xl" />
    </Flex>
  );
};

Spinner.propTypes = {
  style: PropTypes.object,
};
