import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { VStack, Text } from "@chakra-ui/react";

export const CardText = ({ children, text, link, maxW = "" }) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <VStack inset={0} justifyContent={"center"} alignItems={"center"}>
      <Text maxW={maxW} textAlign={"center"} size={"mini"} fontWeight={500} color={"textWhite.400"}>
        {children || text}
      </Text>

      <Text
        as="span"
        cursor="pointer"
        size={"mini"}
        fontWeight={500}
        color="brandRed.400"
        onClick={() => {
          const newLocation = {
            pathname: "/integrations",
            state: {
              prevPath: location.pathname,
            },
          };

          navigate(newLocation);
        }}
      >
        {link} -&gt;
      </Text>
    </VStack>
  );
};
