import { Box, Button, FormControl, FormErrorMessage, Input, Text, VStack, useDisclosure } from "@chakra-ui/react";
import { BaseCard } from "../BaseCard";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AuthMethodsModal } from "./components/auth-methods-modal/AuthMethodsModal";
import { showToast } from "@src/utils/redux";
import { settingsSelector, settingsThunks } from "@redux/webshops/webshop/settings/settingsSlice";
import { useEffect, useState } from "react";
import { authMethodsThunks } from "@redux/webshops/webshop/settings/auth-methods/authMethodsSlice";
import { ProvidersTable } from "./components/providers-table/ProvidersTable";

const schema = yup.object({
  authWebhookUrl: yup.string().url("You must enter a valid URL").required("You must enter a URL"),
});

export function PlayerAuthentication() {
  const {
    settings: { authWebhookUrl },
  } = useSelector(settingsSelector);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [providerToEdit, setProviderToEdit] = useState();
  const dispatch = useDispatch();
  const { id } = useParams();
  const {
    handleSubmit,
    register,
    formState: { errors, isValid, dirtyFields, isSubmitting },
  } = useForm({
    values: {
      authWebhookUrl: authWebhookUrl ?? "",
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    dispatch(authMethodsThunks.getAuthMethods({ webshopId: id }));
  }, [dispatch, id]);

  async function handleWebhookUrl(data) {
    if (data && dirtyFields?.authWebhookUrl) {
      await dispatch(settingsThunks.addWebhookUrl({ data, webshopId: id })).unwrap();
    }
  }

  async function handleFormSubmit(data) {
    try {
      await handleWebhookUrl(data);
    } catch (e) {
      return showToast("There was an error setting up the webhook. Please try again later.", "error");
    }
    onOpen();
  }

  function handleEditProvider(provider) {
    setProviderToEdit(provider);
    onOpen();
  }

  function handleOnClose() {
    setProviderToEdit(null);
    onClose();
  }

  return (
    <>
      <BaseCard title="Player Authentication">
        <VStack
          as="form"
          onSubmit={handleSubmit(handleFormSubmit)}
          onBlur={handleSubmit(handleWebhookUrl)}
          p="16px"
          border="1px solid transparent"
          borderColor={"dark.300"}
          borderRadius={"10px"}
          gap={"16px"}
        >
          <Box>
            <Text size={"small"} fontWeight={600} color={"textWhite.400"}>
              Set up Methods and Providers
            </Text>
            <Text mt="4px" size={"small"} color={"textSubtext.400"}>
              Authentication is the process of identifying users to ensure they receive the items they’ve purchased
              in-game
            </Text>
          </Box>
          <Box>
            <Text size={"small"} fontWeight={600} color={"textWhite.400"}>
              Start by adding you authentication webhook
            </Text>
            <Text mt="4px" size={"small"} color={"textSubtext.400"}>
              Once you’ve added your webhook below, create the method(s) you wish to use
            </Text>
          </Box>
          <FormControl isInvalid={errors.authWebhookUrl?.message}>
            <Input aria-label="Webhook URL" {...register("authWebhookUrl")} placeholder="Webhook URL" />
            <FormErrorMessage color={"brandRed.400"}>{errors.authWebhookUrl?.message}</FormErrorMessage>
          </FormControl>
          <Button isDisabled={!isValid} isLoading={isSubmitting} type="submit" h="32px" alignSelf={"start"}>
            Create Method
          </Button>
        </VStack>

        <ProvidersTable editProvider={handleEditProvider} />
      </BaseCard>
      <AuthMethodsModal isOpen={isOpen} onClose={handleOnClose} selectedProvider={providerToEdit} />
    </>
  );
}
