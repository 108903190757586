import React, { Fragment } from "react";
import { useSelector } from "react-redux";

import { TitleSmallPlus, BodySmallAlt } from "@styled";
import { StyledIntegrationCategory } from "./StyledIntegrationCategory";

import IntegrationStateCard from "./integration-state/IntegrationStateCard";
import ConnectionLoadingCard from "../common/cards/connection-loading/ConnectionLoadingCard";

import { filterIntegrations } from "@utils/integrations";
import { PRODUCT_ANALYTICS } from "@utils/consts";

const IntegrationCategory = ({
  availablePlatforms,
  category,
  handleToggleConnectModal,
  handleToggleDisconnectModal,
}) => {
  const { id, name, description, target = "" } = category;
  const integrations = useSelector((state) => state.integrations);
  const integrationsStatus = integrations.base.requestStatus.getData;
  const earnings = useSelector((state) => state.earnings);

  return (
    <Fragment>
      <StyledIntegrationCategory data-scroll-target={target}>
        <div className="integration-category-header">
          <TitleSmallPlus className="category-title">{name}</TitleSmallPlus>
          <BodySmallAlt className="category-description">{description}</BodySmallAlt>
        </div>
        <div className="integration-category-container">
          {!integrationsStatus.completed && (
            <>
              <ConnectionLoadingCard />
              <ConnectionLoadingCard />
            </>
          )}

          {integrationsStatus.completed && (
            <>
              {filterIntegrations(integrations, id).map((integration) => {
                const { id, category, status } = integration;

                if (!availablePlatforms[id]) return null;

                let newIntegrationID = id;
                // Need this for timestamp because marketing product analytics use the same data (uaSpend)
                // and there is no dashboard.adjust or dashboard.appsFlyer in the reducer
                if (category === PRODUCT_ANALYTICS) newIntegrationID = "uaSpend";
                // Try and get the lastUpdated time, for uaSpend this will be on the integrations base dashboard
                // Every other platform will most likely have its lastUpdated in its earnings call status
                // or its generic data call "getData"
                let lastUpdated = earnings[newIntegrationID]?.requestStatus?.lastUpdated;
                if (!lastUpdated) {
                  const platformData = integrations[newIntegrationID] || integrations.base.dashboard[newIntegrationID];
                  lastUpdated = platformData.timestamp || platformData?.requestStatus?.getData?.lastUpdated;
                }

                return (
                  <IntegrationStateCard
                    key={`integration-card-${id}`}
                    integration={integration}
                    integrationStatus={status}
                    lastUpdated={lastUpdated}
                    handleToggleConnectModal={handleToggleConnectModal}
                    handleToggleDisconnectModal={handleToggleDisconnectModal}
                  />
                );
              })}
            </>
          )}
        </div>
      </StyledIntegrationCategory>
    </Fragment>
  );
};

export default IntegrationCategory;
