import React from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { StyledLoader } from "@common/inputs/SanloButton/SanloButton";

export const ConfirmationModal = ({
  children,
  isOpen,
  onClose,
  onAccept,
  title = "",
  acceptText = "Yes",
  cancelText = "No",
  isLoading = false,
  ...rest
}) => {
  return (
    <Modal size={"md"} variant={"confirmation"} onClose={onClose} isOpen={isOpen} isCentered {...rest}>
      <ModalOverlay />
      <ModalContent data-test-id={"confirmation-modal-content"}>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
          <Button w={"full"} onClick={onClose}>
            {cancelText}
          </Button>
          <Button w={"full"} variant={"primary"} onClick={onAccept} isLoading={isLoading} spinner={<StyledLoader />}>
            {acceptText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
