import React from "react";
import classnames from "classnames";

import { BodyMini12 } from "@styled";
import { StyledYAxis } from "./DailyRevenueDisplayStyles";

import { currencyFormat } from "@utils/currency";

const YAxis = ({ yAxisInfo, isLoading }) => {
  return (
    <StyledYAxis>
      {yAxisInfo.steps.map((step, index) => {
        const heightPercentage = index * 20; // 0/20/40/60/80/100
        const style = {
          bottom: `${heightPercentage}%`,
          transform: `translateY(${heightPercentage}%)`,
        };
        return (
          <BodyMini12
            key={`key_step_${step}`}
            style={style}
            className={classnames("item no-text-select", {
              "dr-loading": isLoading,
            })}
          >
            {currencyFormat(step, {
              shorthand: 10000,
            })}
          </BodyMini12>
        );
      })}
    </StyledYAxis>
  );
};

export default YAxis;
