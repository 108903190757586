import HTTP from "@redux/http";

const SANLO_WEB_API_URL = process.env.REACT_APP_SANLO_WEB_API_URL;
const BASE = `${SANLO_WEB_API_URL}/api/integrations/banking/plaid`;

export default {
  plaidConnect: () => {
    return HTTP.req.get(`${BASE}/connect`);
  },

  plaidDisconnect: () => {
    return HTTP.req.delete(`${BASE}/disconnect`);
  },

  plaidStatus: () => {
    return HTTP.req.get(`${SANLO_WEB_API_URL}/api/banking/v2/plaid/status`);
  },

  plaidClosingBalance: () => {
    return HTTP.req.get(`${SANLO_WEB_API_URL}/api/finance/v2/closing-balance`);
  },
};
