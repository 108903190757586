import { Text } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { StatCard } from "@common/stat-card";
import { financialsSelector, financialsThunks } from "@redux/financials/financialsSlice";
import { FINANCIAL_INTELLIGENCE_BALANCE_TYPE } from "@src/consts/financial-intelligence";
import { scrollToTarget } from "@src/utils/general";
import { FINANCIALS_ACTION, trackGTMEvent } from "@src/utils/gtm";
import symbols from "@src/utils/symbols";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FINANCIAL_INTELLIGENCE_CARD_ID } from "@src/consts/financial-intelligence";
import { ReconnectTooltip } from "@utils/financial-intelligence";
import { getBankingBlurData } from "@utils/blur";

export function UpcomingRevenueCard() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { getBankingMetrics } = useSelector(financialsSelector);
  const integrations = useSelector((state) => state.integrations);
  const { isPartiallyHealthy } = getBankingBlurData(integrations.plaid.data.institutions);
  const currentBalance = getBankingMetrics.data?.revenue;
  const isLoading = !getBankingMetrics.data && getBankingMetrics.requestStatus.pending;
  const isDashboard = location.pathname === "/dashboard";

  const REVENUE_RECEIVED = "Revenue received (Last 30 days)";

  useEffect(() => {
    dispatch(financialsThunks.getBankingMetrics());
  }, [dispatch]);

  const upcomingRevenueInfo = {
    id: FINANCIAL_INTELLIGENCE_CARD_ID.UPCOMING_CASH_INFLOW,
    title: `${symbols.moneyBag} ${REVENUE_RECEIVED}`,
    currentBalance,
    status: FINANCIAL_INTELLIGENCE_BALANCE_TYPE.INFLOW,
    onClick: () => {
      trackGTMEvent({
        event: FINANCIALS_ACTION.VIEW_DETAILS,
        card: REVENUE_RECEIVED,
      });
      if (isDashboard) {
        const newLocation = {
          pathname: "/financial-analytics",
          state: {
            prevPath: location.pathname,
          },
        };
        navigate(newLocation);
        setTimeout(() => {
          scrollToTarget("cash-flow-breakdown");
        }, 100);
      } else {
        scrollToTarget("cash-flow-breakdown");
      }
    },
    tooltip: {
      id: "stat-card-upcoming-cash-inflows-tooltip",
      content: (
        <Text size="mini">
          This is the sum of all revenue payments received in your bank account in the last 30 days, for your products
          and services. The revenue may originate from various sources, including on-platform sales (in-app purchases,
          premium downloads, or subscriptions), ads, and other sources.
        </Text>
      ),
    },
    unhealthyTooltip: isPartiallyHealthy
      ? {
          id: "unhealthy-upcoming-cash-inflows-tooltip",
          content: <ReconnectTooltip source={"banks"} />,
        }
      : null,
  };

  return <StatCard data-test-id="upcoming-revenue-card" isLoading={isLoading} {...upcomingRevenueInfo} />;
}
