import React from "react";

const frowningFace = "😕 ";
const pizza = "🎉 ";
const rocket = "🚀";
const flyingMoney = "💸 ";
const moneyBag = "💰 ";
const wavingHand = "👋 ";
const calendar = "📆";
const downTrend = "📉 ";
const upTrend = "📈 ";
const lock = "🔒";
const unlock = "🔓";
const lightBulb = "💡";
const bank = "🏦";
const pointDown = "👇";
const pointLeft = "👈";
const pointRight = "👉";
const pointUp = "👆";
const chatBubble = "💬";
const prayingHands = "🙏";
const check = "✅";
const shakingHand = "🤝";
const seeding = "🌱";
const clappingHands = "👏";
const stopwatch = "⏱";
const drum = "🥁";
const video = "📺";
const drums = "🥁";
const eyes = "👀";
const flyingStar = "💫";
const showPower = "💪";
const loudSpeaker = "📢";
const mobilePhone = "📱";
const lastUpdate = "🚨";
const exclamation = "❗️";
const flame = "🔥";
const lockWithKey = "🔐";
const spaceInvader = "👾";
const magnifier = "🔍";
const laptop = "💻";
const shoppingBags = "🛍";

export default {
  frowningFace,
  pizza,
  rocket,
  flyingMoney,
  moneyBag,
  wavingHand,
  calendar,
  downTrend,
  upTrend,
  lock,
  unlock,
  lightBulb,
  bank,
  pointDown,
  pointLeft,
  pointRight,
  pointUp,
  chatBubble,
  prayingHands,
  check,
  shakingHand,
  seeding,
  clappingHands,
  stopwatch,
  drum,
  video,
  drums,
  eyes,
  flyingStar,
  showPower,
  loudSpeaker,
  mobilePhone,
  lastUpdate,
  flame,
  lockWithKey,
  spaceInvader,
  magnifier,
  laptop,
  shoppingBags,

  FrowningFace: (style) => {
    return (
      <span className="emoji" style={style} role={"img"} aria-label={"FrowningFace Emoji"}>
        {frowningFace}
      </span>
    );
  },

  Pizza: (style) => {
    return (
      <span className="emoji" style={style} role={"img"} aria-label={"Congrats Emoji"}>
        {pizza}
      </span>
    );
  },

  Rocket: (style) => {
    return (
      <span className="emoji" style={style} role="img" aria-label="Rocket Emoji">
        {rocket}
      </span>
    );
  },

  FlyingMoney: (style) => {
    return (
      <span className="emoji" style={style} role="img" aria-label="FlyingMoney Emoji">
        {flyingMoney}
      </span>
    );
  },

  MoneyBag: (style) => {
    return (
      <span className="emoji tag-sanlo-moneybag-slider" style={style} role="img" aria-label="MoneyBag Emoji">
        {moneyBag}
      </span>
    );
  },

  WavingHand: (style) => {
    return (
      <span className="emoji" style={style} role="img" aria-label="WavingHand Emoji">
        {wavingHand}
      </span>
    );
  },

  DownTrend: (style) => {
    return (
      <span className="emoji" style={style} role="img" aria-label="DownTrend Emoji">
        {downTrend}
      </span>
    );
  },

  Lock: (style) => {
    return (
      <span className="emoji" style={style} role="img" aria-label="Lock Emoji">
        {lock}
      </span>
    );
  },

  Unlock: (style) => {
    return (
      <span className="emoji" style={style} role="img" aria-label="Unlock Emoji">
        {unlock}
      </span>
    );
  },

  LightBulb: (style) => {
    return (
      <span className="emoji" style={style} role="img" aria-label="LightBulb Emoji">
        {lightBulb}
      </span>
    );
  },

  Bank: (style) => {
    return (
      <span className="emoji" style={style} role="img" aria-label="Bank Emoji">
        {bank}
      </span>
    );
  },

  PointDown: (style) => {
    return (
      <span className="emoji" style={style} role="img" aria-label="PointDown Emoji">
        {pointDown}
      </span>
    );
  },

  PointLeft: (style) => {
    return (
      <span className="emoji" style={style} role="img" aria-label="PointLeft Emoji">
        {pointLeft}
      </span>
    );
  },

  PointRight: (style) => {
    return (
      <span className="emoji" style={style} role="img" aria-label="PointRight Emoji">
        {pointRight}
      </span>
    );
  },

  ChatBubble: (style) => {
    return (
      <span className="emoji" style={style} role="img" aria-label="ChatBubble Emoji">
        {chatBubble}
      </span>
    );
  },

  PrayingHands: (style) => {
    return (
      <span className="emoji" style={style} role="img" aria-label="PrayingHands Emoji">
        {prayingHands}
      </span>
    );
  },

  Check: (style) => {
    return (
      <span className="emoji" style={style} role="img" aria-label="Check Emoji">
        {check}
      </span>
    );
  },

  ShakingHand: (style) => {
    return (
      <span className="emoji" style={style} role="img" aria-label="ShakingHand Emoji">
        {shakingHand}
      </span>
    );
  },

  Seeding: (style) => {
    return (
      <span className="emoji" style={style} role="img" aria-label="Seeding Emoji">
        {seeding}
      </span>
    );
  },

  ClappingHands: (style) => {
    return (
      <span className="emoji" style={style} role="img" aria-label="ClappingHands Emoji">
        {clappingHands}
      </span>
    );
  },

  Stopwatch: (style) => {
    return (
      <span className="emoji" style={style} role="img" aria-label="Stopwatch Emoji">
        {stopwatch}
      </span>
    );
  },

  Video: (style) => {
    return (
      <span className="emoji" style={style} role="img" aria-label="Video Emoji">
        {video}
      </span>
    );
  },

  Drums: (style) => {
    return (
      <span className="emoji" style={style} role="img" aria-label="Drums Emoji">
        {drums}
      </span>
    );
  },

  Drum: (style) => {
    return (
      <span className="emoji" style={style} role="img" aria-label="Drum Emoji">
        {drum}
      </span>
    );
  },

  Eyes: (style) => {
    return (
      <span className="emoji" style={style} role="img" aria-label="Eyes Emoji">
        {eyes}
      </span>
    );
  },

  LoudSpeaker: (style) => {
    return (
      <span className="emoji" style={style} role="img" aria-label="LoudSpeaker Emoji">
        {loudSpeaker}
      </span>
    );
  },

  MobilePhone: (style) => {
    return (
      <span className="emoji" style={style} role="img" aria-label="MobilePhone Emoji">
        {mobilePhone}
      </span>
    );
  },
  Exclamation: (style) => {
    return (
      <span className="emoji" style={style} role="img" aria-label="Exclamation Mark Emoji">
        {exclamation}
      </span>
    );
  },
  Flame: (style) => {
    return (
      <span className="emoji" style={style} role="img" aria-label="Flame Emoji">
        {flame}
      </span>
    );
  },
  SpaceInvader: (style) => {
    return (
      <span className="emoji" style={style} role="img" aria-label="Space Invader Emoji">
        {spaceInvader}
      </span>
    );
  },
  Magnifier: (style) => {
    return (
      <span className="emoji" style={style} role="img" aria-label="Magnifier Emoji">
        {magnifier}
      </span>
    );
  },
  Laptop: (style) => {
    return (
      <span className="emoji" style={style} role="img" aria-label="Laptop Emoji">
        {laptop}
      </span>
    );
  },
  ShoppingBags: (style) => {
    return (
      <span className="emoji" style={style} role="img" aria-label="Shopping Bags Emoji">
        {shoppingBags}
      </span>
    );
  },
};
