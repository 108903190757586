import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { errorWrapper } from "@redux/http";
import { getResponseStatus, getRequestStatus, getErrorStatus, setStatusDefaults } from "@utils/redux";

import IntegrationsService from "./integrationsApi";

export const integrationsThunks = {
  getIntegrations: createAsyncThunk("webshops/settings/getIntegrations", async ({ webshopId }, { rejectWithValue }) => {
    return errorWrapper(async () => {
      const res = await IntegrationsService.getIntegrations(webshopId);
      return res.data;
    }, rejectWithValue);
  }),

  setIntegrations: createAsyncThunk(
    "webshops/settings/setIntegration",
    async ({ integration, webshopId }, { rejectWithValue }) => {
      return errorWrapper(async () => {
        const res = await IntegrationsService.setIntegrations(integration, webshopId);
        return res.data;
      }, rejectWithValue);
    },
  ),

  updateIntegrations: createAsyncThunk(
    "webshops/settings/updateIntegrations",
    async ({ integration, webshopId }, { rejectWithValue }) => {
      return errorWrapper(async () => {
        const { id, ...rest } = integration;
        const res = await IntegrationsService.updateIntegrations({ integration: rest, mmpsId: id, webshopId });
        return res.data;
      }, rejectWithValue);
    },
  ),

  deleteIntegrations: createAsyncThunk(
    "webshops/settings/deleteIntegrations",
    async ({ integration, webshopId }, { rejectWithValue }) => {
      return errorWrapper(async () => {
        const res = await IntegrationsService.deleteIntegrations(integration?.id, webshopId);
        return res.data;
      }, rejectWithValue);
    },
  ),
};

export const integrationsSlice = createSlice({
  name: "integrations",
  initialState: {
    getIntegrations: setStatusDefaults(),
    setIntegrations: setStatusDefaults(),
    updateIntegrations: setStatusDefaults(),
    deleteIntegrations: setStatusDefaults(),
    data: [],
  },
  extraReducers: (builder) => {
    builder
      /*******************************************************************************
       * Get Integrations (MMPs)
       *******************************************************************************/
      .addCase(integrationsThunks.getIntegrations.pending, (state) => {
        state.getIntegrations = getRequestStatus();
      })
      .addCase(integrationsThunks.getIntegrations.fulfilled, (state, action) => {
        const { payload = {} } = action;
        state.data = payload.mmps;
        state.getIntegrations = getResponseStatus();
      })
      .addCase(integrationsThunks.getIntegrations.rejected, (state) => {
        state.getIntegrations = getErrorStatus();
      })
      /*******************************************************************************
       * Set Integrations (MMP)
       *******************************************************************************/
      .addCase(integrationsThunks.setIntegrations.pending, (state) => {
        state.setIntegrations = getRequestStatus();
      })
      .addCase(integrationsThunks.setIntegrations.fulfilled, (state, action) => {
        const { payload = {} } = action;
        const { mmp = {} } = payload;

        const updatedIntegrations = state.data.map((integration) => {
          if (integration.type === mmp.type) {
            return {
              ...integration,
              data: mmp,
            };
          } else {
            return integration;
          }
        });
        state.data = updatedIntegrations;
        state.setIntegrations = getResponseStatus();
      })
      .addCase(integrationsThunks.setIntegrations.rejected, (state) => {
        state.setIntegrations = getErrorStatus();
      })
      /*******************************************************************************
       * Update Integrations (MMP)
       *******************************************************************************/
      .addCase(integrationsThunks.updateIntegrations.pending, (state) => {
        state.updateIntegrations = getRequestStatus();
      })
      .addCase(integrationsThunks.updateIntegrations.fulfilled, (state, action) => {
        const { payload = {} } = action;
        const { mmp = {} } = payload;

        const updatedIntegrations = state.data.map((integration) => {
          if (integration.type === mmp.type) {
            return {
              ...integration,
              data: mmp,
            };
          } else {
            return integration;
          }
        });
        state.data = updatedIntegrations;
        state.updateIntegrations = getResponseStatus();
      })
      .addCase(integrationsThunks.updateIntegrations.rejected, (state) => {
        state.updateIntegrations = getErrorStatus();
      })
      /*******************************************************************************
       * Delete Integrations (MMP)
       *******************************************************************************/
      .addCase(integrationsThunks.deleteIntegrations.pending, (state) => {
        state.deleteIntegrations = getRequestStatus();
      })
      .addCase(integrationsThunks.deleteIntegrations.fulfilled, (state, action) => {
        const { integration: deletedIntegration = {} } = action.meta.arg;

        const updatedIntegrations = state.data.map((integration) => {
          if (integration.type === deletedIntegration.type) {
            return {
              ...integration,
              data: null,
            };
          } else {
            return integration;
          }
        });

        state.data = updatedIntegrations;
        state.deleteIntegrations = getResponseStatus();
      })
      .addCase(integrationsThunks.deleteIntegrations.rejected, (state) => {
        state.deleteIntegrations = getErrorStatus();
      });
  },
});

export const integrationsSelector = (state) => state.webshops.settings.integrations;

export const appsFlyerSelector = (state) => {
  const data = state.webshops.settings.integrations.data;
  const appsFlyer = data.find((integration) => integration.type === "APPSFLYER");
  return appsFlyer?.data ? appsFlyer.data : null;
};

export default integrationsSlice.reducer;
