export const hasTooltipContent = (activity = {}) => {
  // Check the activities length in both inflow and outflow
  let hasContent = false;
  const { inflow = {}, outflow = {} } = activity;
  const { activities: inflowActivities } = inflow;
  const { activities: outflowActivities } = outflow;
  // If activities are defined, check their length
  if (Array.isArray(inflowActivities) || Array.isArray(outflowActivities)) {
    hasContent = inflowActivities.length > 0 || outflowActivities.length > 0;
  }
  return hasContent;
};
