import { Icon, Input, InputGroup, InputRightElement, useClipboard } from "@chakra-ui/react";
import { ReactComponent as CopyIcon } from "@assets/img/icons/common/copy.svg";

export function RedirectUri({ oauthLandingUrl, color = "brandYellow.400" }) {
  const { onCopy, hasCopied } = useClipboard(decodeURIComponent(oauthLandingUrl));

  return (
    <InputGroup>
      <Input
        aria-label="OAuth redirect URI"
        value={hasCopied ? "Copied!" : decodeURIComponent(oauthLandingUrl)}
        readOnly
        borderColor={color}
        borderWidth={"1px"}
        _hover={{ borderColor: color }}
        _focus={{ borderColor: color }}
        _active={{ borderColor: color }}
        pr={"40px"}
      />
      <InputRightElement onClick={onCopy}>
        <Icon
          role="button"
          aria-label="Copy OAuth redirect URI"
          as={CopyIcon}
          w="16px"
          h="16px"
          cursor={"pointer"}
          color={color}
        />
      </InputRightElement>
    </InputGroup>
  );
}
