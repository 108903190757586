import React from "react";

import { StepActionsContainer } from "@common/animated/FadeStepsContainer";
import SetupGuide from "@components/common/modals/integrations-modal/SetupGuide";
import { StyledGuideList } from "@components/common/modals/integrations-modal/StyledSetupGuide";

import { SanloButton, SanloInput } from "@inputs";
import { ErrorMessage } from "@components/integrations/connection-guide/ErrorMessage";
import { COMMON_ACTION } from "@utils/gtm";

import symbols from "@utils/symbols";

export const GooglePlayStep2 = ({
  platform = {
    id: "googleplay",
    name: "Google play",
    platformId: "googleplay",
  },
  saveCredentialsRequestStatus = "",
  cloudStorageURI = "",
  setCloudStorageURI = () => {},
  onConnect = () => {},
  trackEvent = () => {},
  expand = false,
  credentialsError = "",
}) => {
  return (
    <StepActionsContainer>
      <div className="fs-modal-actions">
        <ErrorMessage error={credentialsError} />

        <SanloInput
          hasFocus
          isJumbo
          label="Paste cloud storage URI"
          placeholder="Paste Cloud storage URI"
          value={cloudStorageURI}
          setValue={setCloudStorageURI}
          style={{
            marginBottom: "24px",
          }}
          testId={`${platform.platformId}-cloud-storage-uri-input`}
        />

        <SanloButton
          label={`Connect to ${platform.name} Store`}
          isJumbo
          isLoading={saveCredentialsRequestStatus.pending}
          isDisabled={cloudStorageURI.length === 0}
          onClick={() => {
            trackEvent(COMMON_ACTION.CONNECT);
            onConnect();
          }}
          gtmEventTag="tag-sanlo-google-connect-to-google-play"
          testId={`${platform.platformId}-cloud-storage-uri-button`}
        />
      </div>

      <div className="fs-modal-instructions-integrations">
        <SetupGuide
          expand={expand}
          platformStep={`${platform.name}1`}
          step={{
            title: "Copy cloud storage URI",
            description: (
              <StyledGuideList fontWeight={"500"}>
                <li>
                  Navigate to the{" "}
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://play.google.com/console/developers/download-reports/financial"
                    className="modal-link setup-guide-link tag-sanlo-google-finances-link"
                  >
                    Finances
                  </a>{" "}
                  tab.
                </li>
                <li>Copy the cloud storage URI to your clipboard.</li>
                <li>Go back to Sanlo app and paste the URI on the left. {symbols.PointLeft()}</li>
              </StyledGuideList>
            ),
            images: [require("./assets/google_play_guide_3.png")],
          }}
        />
      </div>
    </StepActionsContainer>
  );
};
