import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFlags } from "launchdarkly-react-client-sdk";

import actions from "@redux/actions";

import { Button, Heading, HStack, Text, VStack, Checkbox, useTheme } from "@chakra-ui/react";
import { trackGTMEvent, ONBOARDING_ACTIONS } from "@utils/gtm";
import {
  getOnboardingInfo,
  markSuperpowersAsChecked,
  getCheckedSuperpowersIds,
  toggleSuperpowerUtil,
} from "./utils/utils";
import { onboardingLoadingSelector, superpowerPreferencesSelector } from "@redux/session/reducer";

export const SuperpowersModal = ({ handleNextStep = () => {} }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { clientSuperpowersBrowsingEnabled = false } = useFlags();

  const suggestedIntegrationsCompleted = useSelector((state) => state.session.suggestedIntegrationsCompleted);
  const isLoading = useSelector(onboardingLoadingSelector);
  const superpowerPreferences = useSelector(superpowerPreferencesSelector);

  const [superpowers, setSuperpowers] = useState([
    ...markSuperpowersAsChecked({
      preferences: superpowerPreferences,
      browsingEnabled: clientSuperpowersBrowsingEnabled,
    }),
  ]);
  const [superpowersIds, setSuperpowersIds] = useState([]);

  const linearGradient = `linear-gradient(to right, ${theme.colors.brandRed[400]}, ${theme.colors.brandPurple[400]})`;

  useEffect(() => {
    trackGTMEvent({
      "event": "Component View",
      "Component Name": "SuperpowersModal",
    });
  }, []);

  useEffect(() => {
    if (suggestedIntegrationsCompleted) {
      const { goToDashboard, onboardingAction } = getOnboardingInfo(superpowersIds);
      trackGTMEvent({
        event: "Component View",
        action: onboardingAction,
      });
      handleNextStep(goToDashboard);
      dispatch(actions.session.setSuggestedIntegrationsCompleted(false));
    }
  }, [dispatch, handleNextStep, suggestedIntegrationsCompleted, superpowersIds]);

  const isAnyOptionChecked = () => {
    return !superpowers.some((superpower) => superpower.isChecked);
  };

  const toggleSuperpower = (e, superpowerId) => {
    e.preventDefault();
    setSuperpowers(
      toggleSuperpowerUtil({
        superpowers,
        superpowerId,
      }),
    );
  };

  const onHandleNextStep = () => {
    const superpowersIds = getCheckedSuperpowersIds(superpowers);
    trackGTMEvent({
      event: ONBOARDING_ACTIONS.SUPERPOWERS_MODAL_NEXT,
      value: superpowersIds,
    });
    setSuperpowersIds(superpowersIds);
    dispatch(actions.session.setOnboardingSuperpowers.request({ preferences: superpowersIds }));
  };

  return (
    <VStack gap={"24px"} alignItems={"flex-start"}>
      <Heading size={"headline"} fontWeight={"600"}>
        What products are you <br />
        interested in?
      </Heading>
      <VStack gap={"8px"} alignItems={"flex-start"}>
        <Text size={"regular"} fontWeight={"500"} color={"textWhite.400"} ml={"4px"}>
          Select the products you are interested in:
        </Text>
        {superpowers.map((superpower) => {
          return (
            <HStack
              key={`wrapper-superpower-${superpower.id}`}
              id={`wrapper-superpower-${superpower.id}`}
              w={"100%"}
              p={"2px"}
              bgGradient={superpower.isChecked ? linearGradient : null}
              borderRadius={"10px"}
              cursor={"pointer"}
              onClick={(e) => toggleSuperpower(e, superpower.id)}
            >
              <HStack
                id={`superpower-${superpower.id}`}
                p={"14px"}
                w={"100%"}
                backgroundColor={"dark.700"}
                borderRadius={"10px"}
                _hover={{ background: "dark.500" }}
              >
                <Checkbox variant={"onboarding"} isChecked={superpower.isChecked} />
                <VStack
                  w={"100%"}
                  alignItems={"flex-start"}
                  borderLeft={"1px solid  #2A2A2F"}
                  pl={"16px"}
                  ml={"10px"}
                  gap={"4px"}
                  data-test-id={`superpower-${superpower.id}-clickable`}
                >
                  <Text size={"smallAlt"} fontWeight={"500"} color={"textWhite.400"} userSelect={"none"}>
                    {superpower.title}
                  </Text>
                  {superpower.content && (
                    <Text size={"smallAlt"} color={"textSubtext.400"} userSelect={"none"}>
                      {superpower.content}
                    </Text>
                  )}
                </VStack>
              </HStack>
            </HStack>
          );
        })}
      </VStack>

      <Button
        w={"100%"}
        type="submit"
        variant={"primary"}
        size={"lg"}
        isDisabled={isAnyOptionChecked()}
        onClick={() => onHandleNextStep()}
        isLoading={isLoading}
        data-test-id={`superpower-modal-next-button`}
      >
        Next
      </Button>
    </VStack>
  );
};
