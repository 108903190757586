import React from "react";
import { Box, HStack, Icon, Text } from "@chakra-ui/react";
import { ReactComponent as ZoomIn } from "@assets/img/icons/common/zoom-in.svg";

export const VerticalScaleAdjuster = ({ chartId = "", scale = [], selectedScale = {}, changeSelection, ...rest }) => {
  const handleClick = (scale) => {
    if (changeSelection) changeSelection(scale);
  };

  return (
    <HStack
      w={"fit-content"}
      border={"1px solid"}
      borderColor={"dark.300"}
      borderRadius={"6px"}
      background={"dark.300"}
      gap={"4px"}
      data-test-id={`scale-adjuster-${chartId}`}
      {...rest}
    >
      <Box pl={"5px"}>
        <Icon as={ZoomIn} width={"15px"} height={"15px"} color={"textDefault.400"} />
      </Box>

      <HStack borderRadius={"6px"} background={"dark.500"} gap={0}>
        {scale.map((scaleElement, index) => {
          const isActive = scaleElement.id === selectedScale?.id;
          return (
            <Box
              key={`scale-${index}`}
              background={isActive ? "dark.300" : ""}
              borderRadius={isActive ? "6px" : "4px"}
              padding={"4px 8px"}
              border={isActive ? "1.5px solid" : ""}
              borderColor={isActive ? "dark.500" : ""}
              _hover={{ bgColor: isActive ? "" : "dark.400", borderRadius: isActive ? "6px" : "4px" }}
              cursor={"pointer"}
              onClick={() => handleClick(scaleElement)}
            >
              <Text
                size={"mini"}
                color={isActive ? "textWhite.400" : "textSubtext.400"}
                letterSpacing={"0.24px"}
                userSelect={"none"}
              >
                {scaleElement.label}
              </Text>
            </Box>
          );
        })}
      </HStack>
    </HStack>
  );
};
