import { StyledProgressTooltip } from "../../StyledAdvanceCard";
import React, { Fragment } from "react";
import { SanloScroll } from "@components/SanloScroll";
import advCopy from "@utils/text/advance";
import { hasConversion } from "@utils/currency";

import TooltipHeader from "./TooltipHeader";
import TooltipRow from "./TooltipRow";

import text from "@utils/text";

const RepaidTooltip = ({ advancePlatforms, state, advance }) => {
  const scroll = advance.platforms.length > 3;

  const platforms = (
    <Fragment>
      {advance.platforms.map((platform, index) => {
        const { id, repayments } = platform;
        const name = (advancePlatforms[id] || {}).name;
        const {
          totalEarnedInAdvanceCurrency,
          totalRepaidInAdvanceCurrency,
          totalEarnedInOriginCurrency,
          totalRepaidInOriginCurrency,
        } = repayments ?? {};

        if (!repayments) return null;

        return (
          <Fragment key={`repaid_tooltip_${id}_${state}_${index}`}>
            <TooltipHeader logo={advancePlatforms[id]?.icon} title={`Repaid from ${name}`} />
            <div className={"tooltip-content " + state}>
              <TooltipRow
                label={text.t("total_earnings")}
                display={advCopy.currencyDisplay(advance, {
                  inAdvance: totalEarnedInAdvanceCurrency,
                  inOrigin: totalEarnedInOriginCurrency,
                })}
              />
              <TooltipRow
                label={text.t("repayment_amount")}
                display={advCopy.currencyDisplay(
                  advance,
                  {
                    inAdvance: totalRepaidInAdvanceCurrency,
                    inOrigin: totalRepaidInOriginCurrency,
                  },
                  true,
                )}
                hint={advCopy.upcomingRepaymentHint(advance, state)}
                accordion
                color
              />
            </div>
            <div className="divider" />
          </Fragment>
        );
      })}
      {hasConversion(advance) && (
        <div className={"tooltip-content"}>
          <TooltipRow llabel={text.t("exchange_rate")} hint={advCopy.exchangeDisclosures.repaid()} />
        </div>
      )}
    </Fragment>
  );

  return (
    <StyledProgressTooltip state={state} scroll={scroll}>
      {scroll ? <SanloScroll seeBar>{platforms}</SanloScroll> : <Fragment>{platforms}</Fragment>}
    </StyledProgressTooltip>
  );
};

export default RepaidTooltip;
