import HTTP from "@redux/http";
import isEmpty from "lodash/isEmpty";
const SANLO_WEB_API_URL = process.env.REACT_APP_SANLO_WEB_API_URL;
const BASE = `${SANLO_WEB_API_URL}/api/financials`;

class GameMetricsService {
  getAppMetrics({ appIds, fromDate, toDate }) {
    const url = new URL(`${BASE}/app-metrics`);
    const params = new URLSearchParams();
    if (!isEmpty(appIds)) {
      params.set("appIds", appIds);
    }
    if (fromDate) {
      params.set("fromDate", fromDate);
    }
    if (toDate) {
      params.set("toDate", toDate);
    }
    url.search = params;
    return HTTP.req.get(url.toString());
  }
}

export default new GameMetricsService();
