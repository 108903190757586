import React from "react";
import { useDispatch } from "react-redux";

import { SanloDropdown } from "@inputs";
import { FILTER_DATE_OPTIONS } from "./utils";
import { gameMetricsThunks } from "@redux/game-metrics/gameMetricsSlice";

export const DateFilter = ({ appliedFilters = {} }) => {
  const dispatch = useDispatch();

  return (
    <SanloDropdown
      defaultSelectedOption={1}
      options={FILTER_DATE_OPTIONS}
      onSelect={(option) => {
        dispatch(
          gameMetricsThunks.getGameMetrics({ ...appliedFilters, fromDate: option.fromDate, toDate: option.toDate }),
        );
      }}
      style={{
        width: "auto",
        border: "none",
      }}
    />
  );
};
