import React from "react";

import symbols from "@utils/symbols";
import { Button, Flex, Heading, Text } from "@chakra-ui/react";

export const PermissionCompleted = ({ handleDone }) => {
  return (
    <Flex flexDir={"column"} alignItems={"center"} h="full" justifyContent={"center"} gap={"8px"}>
      <Heading size="large" fontWeight={600}>
        {symbols.pizza} Congrats! You are finished.
      </Heading>
      <Text size="regular">We will reach out in 24 hours with further instructions.</Text>
      <Button
        onClick={handleDone}
        size={"lg"}
        w="full"
        variant={"primary"}
        data-test-id="permission-complete-button"
        mt="16px"
      >
        Close
      </Button>
    </Flex>
  );
};
