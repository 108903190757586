import { Flex, Text } from "@chakra-ui/react";
import React, { cloneElement } from "react";

export function InstructionsList({ children, ...rest }) {
  return (
    <Flex flexDir={"column"} gap={"24px"} {...rest}>
      {React.Children.map(children, (child, index) => {
        return cloneElement(child, { order: child.props.order ? child.props.order : index + 1 });
      })}
    </Flex>
  );
}

export function InstructionItem({ order, children, ...rest }) {
  return (
    <Flex gap={"16px"}>
      <Flex
        flexShrink={0}
        w="24px"
        h="24px"
        bg={"dark.200"}
        border={"1.5px solid"}
        borderColor={"textDefault.400"}
        justifyContent={"center"}
        alignItems={"center"}
        borderRadius={"full"}
      >
        <Text size="mini" fontSize={"xs"} color={"textWhite.400"}>
          {order}
        </Text>
      </Flex>
      <Flex flexDir={"column"} gap={"16px"} {...rest}>
        {children}
      </Flex>
    </Flex>
  );
}
