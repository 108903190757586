import React, { useState } from "react";
import styled from "styled-components";
import classnames from "classnames";

import { BodyRegularPlus, BodySmallAlt } from "@styled";

import { ReactComponent as AlertCircle } from "@assets/img/icons/common/alertCircle.svg";
import { ReactComponent as ArrowIcon } from "@assets/img/icons/common/accordion-arrow.svg";

export const StyledCollapsableText = styled.div`
  margin-bottom: 2rem;
  width: 100%;

  .c-question-header-left {
    svg {
      margin-right: 0.75rem;
      width: 18px;
      height: 18px;
    }
  }

  svg {
    fill: ${({ theme }) => theme.textSeashellWhite};
  }

  .done-description {
    margin-bottom: 0;
  }

  .c-question-header {
    margin-bottom: 0.5rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .c-question-arrow {
      width: 16px;
      height: 16px;
      margin-left: 8px;
      transform: rotate(180deg);
      transition: transform 0.4s ease;
      fill: ${({ theme }) => theme.textSeashellWhite};

      &.c-question-open {
        transform: rotate(0deg);
      }
    }
  }
`;

export const CollapsableText = ({ header, description, handleToggle }) => {
  const [descOpen, setDescOpen] = useState(false);

  return (
    <StyledCollapsableText>
      <div
        className="c-question-header"
        onClick={() => {
          setDescOpen(!descOpen);
          if (handleToggle) handleToggle();
        }}
      >
        <BodyRegularPlus className="c-question-header-left">
          <AlertCircle /> {header}
        </BodyRegularPlus>
        <ArrowIcon
          className={classnames("c-question-arrow", {
            "c-question-open": descOpen,
          })}
        />
      </div>
      {descOpen && <BodySmallAlt className="done-description">{description}</BodySmallAlt>}
    </StyledCollapsableText>
  );
};
