import { Box, HStack, Icon, IconButton, Modal, ModalContent, ModalOverlay, Progress, Text } from "@chakra-ui/react";
import { cloneElement, useContext, useEffect, useState } from "react";
import { MultiStepModalContext } from "./MultiStepModalContext";
import { AnimatePresence, motion } from "framer-motion";
import { ReactComponent as ArrowLeftIcon } from "@assets/img/icons/common/arrow_left.svg";
import { ReactComponent as CrossIcon } from "@assets/img/icons/common/cross3.svg";
import { clamp } from "lodash";
import { slideInAndOutWithDirection } from "@src/utils/motion-variants";

export function MultiStepModal() {
  const { isOpen, onClose, initialStep, steps, showProgress, showStepsLeft } = useContext(MultiStepModalContext);
  const [[step, direction], setStep] = useState(() => [0, 0]);
  const stepIndex = clamp(step, 0, steps.length);

  useEffect(() => {
    if (initialStep) {
      setStep([initialStep, 1]);
    }
  }, [initialStep]);

  const nextStep = () => {
    if (step < steps.length - 1) {
      return setStep([step + 1, 1]);
    }
    onClose();
  };

  const prevStep = () => {
    if (step > 0) {
      return setStep([step - 1, -1]);
    }
    onClose();
  };

  const hasValidSteps = Array.isArray(steps) && steps.length > 0;
  const progressValue = ((step + 1) / steps.length) * 100;
  return (
    <Modal
      isOpen={isOpen}
      size={"full"}
      onClose={onClose}
      onCloseComplete={() => {
        setStep([0, 0]);
      }}
    >
      <ModalOverlay />
      <ModalContent pos={"relative"} overflow={"hidden"}>
        <Box
          pos="absolute"
          w="652px"
          h="652px"
          left="-24%"
          top="86px"
          bg="linear-gradient(291.19deg, rgba(191, 107, 255, 0.5) 10.65%, rgba(255, 92, 121, 0.5) 89.36%)"
          filter="blur(130px)"
          borderRadius={"50%"}
        />
        <Box
          pos="absolute"
          w="652px"
          h="652px"
          right="-24%"
          top="134px"
          background="linear-gradient(291.19deg, rgba(45, 249, 176, 0.5) 10.65%, rgba(191, 107, 255, 0.5) 89.36%)"
          filter="blur(100px)"
          borderRadius={"50%"}
        />
        <Box zIndex={2}>
          {showProgress && <Progress value={progressValue} />}
          <HStack justifyContent={"space-between"} mt={showProgress ? "24px" : "36px"}>
            <IconButton variant={"unstyled"} icon={<Icon w="24px" h="24px" as={ArrowLeftIcon} />} onClick={prevStep} />
            {showStepsLeft && hasValidSteps && steps.length > 1 && (
              <Text fontWeight={500} color={"textWhite.400"}>
                {Number(step + 1)} of {steps.length}
              </Text>
            )}
            <IconButton variant={"unstyled"} icon={<Icon w="24px" h="24px" as={CrossIcon} />} onClick={onClose} />
          </HStack>
          <Box as="form" onSubmit={(e) => e.preventDefault()}>
            <AnimatePresence initial={false} custom={direction} mode="popLayout">
              <motion.div
                style={{ display: "flex", flexDirection: "column", flex: 1 }}
                variants={slideInAndOutWithDirection}
                transition={{ x: { type: "spring", stiffness: 300, damping: 30 }, opacity: { duration: 0.2 } }}
                initial="enters"
                animate="center"
                custom={direction}
                exit="exit"
                key={`multi_step_${step}`}
              >
                {isOpen
                  ? hasValidSteps &&
                    cloneElement(steps[stepIndex], { handleNextStep: nextStep, handlePrevStep: prevStep })
                  : null}
              </motion.div>
            </AnimatePresence>
          </Box>
        </Box>
      </ModalContent>
    </Modal>
  );
}
