import React, { useState } from "react";

import AuthenticationContainer from "@src/pages/authentication/AuthenticationContainer";
import { BodySmall14 } from "@styled";
import {
  SanloModal,
  SanloModalTitle,
  SanloModalContent,
  SanloModalFooter,
} from "@components/common/modals/SanloModal/SanloModal";
import { SanloButton } from "@inputs";

import SanloWebApiService from "@services/SanloWebApiService";

export const VerificationExpired = ({ email }) => {
  const [requested, setRequested] = useState(false);

  const handleSendVerificationEmail = () => {
    setRequested(true);
    SanloWebApiService.sendVerificationEmail();
  };

  return (
    <AuthenticationContainer hasLogout>
      <SanloModal>
        <SanloModalTitle>Verify your email</SanloModalTitle>
        <SanloModalContent>
          <div className="auth-card-note">
            {!requested && <BodySmall14>This email verification link has expired</BodySmall14>}
            {requested && <BodySmall14>New link sent to {email}</BodySmall14>}
          </div>
        </SanloModalContent>
        <SanloButton
          isDisabled={requested}
          onClick={handleSendVerificationEmail}
          label="Request New Verification Link"
          style={{
            marginBottom: "24px",
          }}
        />
        <SanloModalFooter center>
          Powered by <img src={require("@assets/img/logos/auth0-logo.png")} alt="" />
        </SanloModalFooter>
      </SanloModal>
    </AuthenticationContainer>
  );
};
