import HTTP from "@redux/http";

const SANLO_WEB_API_URL = process.env.REACT_APP_SANLO_WEB_API_URL;
const BASE = `${SANLO_WEB_API_URL}/api/fairground/console/webshops`;

class BuilderService {
  getStorefront(webshopId) {
    return HTTP.req.get(`${BASE}/${webshopId}/storefront`);
  }

  updateStorefront(data, webshopId) {
    return HTTP.req.put(`${BASE}/${webshopId}/storefront`, data);
  }

  updateStorefrontTheme(data, webshopId) {
    return HTTP.req.put(`${BASE}/${webshopId}/storefront/theme/global`, data);
  }

  publishStorefront(webshopId) {
    return HTTP.req.post(`${BASE}/${webshopId}/storefront/publish`);
  }

  createFile(data, webshopId) {
    return HTTP.req.post(`${BASE}/${webshopId}/storefront/file`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  updateFile(data, webshopId) {
    return HTTP.req.put(`${BASE}/${webshopId}/storefront/file`, data);
  }

  deleteFile(fileId, webshopId) {
    return HTTP.req.delete(`${BASE}/${webshopId}/storefront/file/${fileId}`);
  }
  getSocialPlatforms() {
    return HTTP.req.get(`${BASE}/storefront/platforms/social`);
  }
}

export default new BuilderService();
