import styled from "styled-components";

const StyledStateButton = styled.div`
  & {
    padding: 8px 12px;
    height: 40px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme, state }) => theme[state + "ButtonBackgroundColor"]};
    color: ${({ theme, state }) => theme[state + "ButtonFontColor"]};
    cursor: ${(props) => props.hover && "pointer"};

    &.button-disabled {
      cursor: not-allowed;

      &:hover {
        background-color: ${({ theme, state }) => theme[state + "ButtonBackgroundColor"]};
      }

      * {
        opacity: 0.6;
      }
    }

    .state-icon {
      width: 16px;
      height: 16px;
      position: relative;
      margin-right: 10px;

      svg {
        fill: ${({ theme, state }) => theme[state + "ButtonFontColor"]};
        position: absolute;
      }
    }

    :hover {
      background-color: ${({ theme, state }) => theme[state + "ButtonBackgroundColorHover"]};
    }

    &.reconnect-button {
      background-color: ${({ theme }) => theme.warningButtonBackgroundColor};
      color: ${({ theme }) => theme.warningButtonFontColor};
    }

    &.dashboard-state-button {
      background-color: ${({ theme, state }) => theme[state + "DashboardButtonBackgroundColor"]};
      color: ${({ theme, state }) => theme[state + "DashboardButtonFontColor"]};

      svg {
        fill: ${({ theme, state }) => theme[state + "DashboardButtonFontColor"]};
      }
    }
  }
`;

export default StyledStateButton;
