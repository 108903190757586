import React from "react";

import { StyledUpcomingPayoutsContainer, StyledUpcomingPaymentsLoadingContent } from "./UpcomingPayoutsContainerStyles";
import UpcomingPayoutsContent from "./UpcomingPayoutsContent";

import { getUpcomingPaymentCardData } from "@utils/dashboard";
import { Card } from "@common/cards/card/Card";
import { useSelector } from "react-redux";
import { paymentsSelector } from "@redux/payments/paymentsSlice";
import upcomingMocks from "@redux/payments/mocks/getUpcomings";
import { Heading } from "@chakra-ui/react";
import { BlurryCard } from "@common/blurry-card";
import { getBlurredData } from "@utils/blur";
import { getUpcomingPayoutsBlurryMessage } from "@src/consts/blur";

const UpcomingPayoutsContainer = ({ filter = [], onCardSelect = null, title = "Upcoming cash flow details" }) => {
  const { upcomings, requestStatus } = useSelector(paymentsSelector);
  const integrations = useSelector((state) => state.integrations);
  const { isBlurred, isPullingData, isBroken } = getBlurredData(upcomings, integrations);

  let cards = getUpcomingPaymentCardData(upcomings, filter);

  const renderBody = () => {
    if (!requestStatus.completed) return null;

    if (requestStatus.pending || isBlurred) {
      return (
        <BlurryCard isBlurred={isBlurred} message={getUpcomingPayoutsBlurryMessage({ isPullingData, isBroken })}>
          <UpcomingPayoutsContent
            isPullingData={requestStatus.pending}
            cards={getUpcomingPaymentCardData(upcomingMocks)}
          />
        </BlurryCard>
      );
    }

    return <UpcomingPayoutsContent onCardSelect={onCardSelect} cards={cards} />;
  };

  return (
    <StyledUpcomingPayoutsContainer>
      <Heading size={"small"} fontWeight={"semibold"} style={{ marginBottom: "24px" }}>
        {title}
      </Heading>
      {!requestStatus.completed && (
        <StyledUpcomingPaymentsLoadingContent className="loading-shine">
          <Card />
          <Card />
          <Card />
          <Card />
        </StyledUpcomingPaymentsLoadingContent>
      )}

      {renderBody()}
    </StyledUpcomingPayoutsContainer>
  );
};

export default UpcomingPayoutsContainer;
