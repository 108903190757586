import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { ConfirmationModal } from "@common/modals/confirmation-modal";
import { authMethodsSelector, authMethodsThunks } from "@redux/webshops/webshop/settings/auth-methods/authMethodsSlice";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ReactComponent as AlertTriangle } from "@assets/img/icons/common/alertTriangle.svg";
import { useHandleAuthMethod } from "@pages/webshops/webshop/settings/hooks/useHandleAuthMethod";

export function ProvidersTable({ editProvider }) {
  const dispatch = useDispatch();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { handleAuthMethod, isLoading } = useHandleAuthMethod();
  const { authProviders } = useSelector(authMethodsSelector);
  const { id } = useParams();
  const [selectedProvider, setSelectedProvider] = useState();

  function handleDeleteProvider(provider) {
    setSelectedProvider(provider);
    onOpen();
  }

  function confirmRemoveProvider() {
    dispatch(authMethodsThunks.deleteAuthMethod({ webshopId: id, authMethod: selectedProvider }));
    setSelectedProvider(null);
    onClose();
  }

  function dismissRemoveProvider() {
    setSelectedProvider(null);
    onClose();
  }

  async function handleChangeActiveStatus(provider) {
    setSelectedProvider(provider);
    const {
      data: { authCategory, ...providerData },
    } = provider;
    handleAuthMethod({ ...providerData, visible: !provider.data.visible });
  }
  if (!authProviders || !authProviders.length || !authProviders.some((provider) => provider.active === true))
    return null;
  return (
    <>
      <VStack as="form" mt="16px" gap="8px" alignItems={"stretch"}>
        <Text color={"textSubtext.400"}>Current Methods</Text>
        {authProviders?.length && (
          <TableContainer>
            <Table variant={"settings"}>
              <Thead>
                <Tr>
                  <Th>Method</Th>
                  <Th>Status</Th>
                  <Th>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {authProviders.map((provider) => {
                  if (!provider.active) return null;
                  return (
                    <Tr key={provider.name}>
                      <Td>{provider.label}</Td>
                      <Td>
                        <FormControl>
                          <FormLabel m={0}>
                            <HStack>
                              <Switch
                                isDisabled={isLoading && provider.name === selectedProvider?.name}
                                size={"sm"}
                                isChecked={provider.data.visible}
                                onChange={() => handleChangeActiveStatus(provider)}
                              />
                              <Text size={"mini"}>{provider.data.visible ? "Active" : "Deactivated"}</Text>
                            </HStack>
                          </FormLabel>
                        </FormControl>
                      </Td>
                      <Td>
                        <HStack gap="32px">
                          <Button size={"sm"} p={0} variant={"tertiary"} onClick={() => editProvider(provider)}>
                            Edit
                          </Button>
                          <Button size={"sm"} p={0} variant={"tertiary"} onClick={() => handleDeleteProvider(provider)}>
                            Delete
                          </Button>
                        </HStack>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        )}
      </VStack>
      <ConfirmationModal
        isOpen={isOpen}
        onAccept={confirmRemoveProvider}
        onClose={dismissRemoveProvider}
        title="Delete method"
        acceptText="Yes, delete"
        cancelText="Cancel"
      >
        <HStack bg="brandRedAlpha.400" p="12px" borderRadius={"10px"} gap={"8px"} alignItems={"center"}>
          <Icon as={AlertTriangle} color="brandRed.400" w="16px" h="16px" />
          <Box>
            <Text color="brandRed.400" size={"small"} fontWeight={500} lineHeight={"20px"}>
              WARNING:
            </Text>
            <Text color={"textWhite.400"} size={"small"} lineHeight={"20px"}>
              Are you sure? This action cannot be undone. Consider setting this method to Deactivated.
            </Text>
          </Box>
        </HStack>
      </ConfirmationModal>
    </>
  );
}
