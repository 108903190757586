import React from "react";

import symbols from "@utils/symbols";
import { hasConversion, currencyFormat, bankersRounding } from "@utils/currency";
import text from "@utils/text";

export const paymentProviderLink = (
  <a className="tooltip-link" rel="noopener noreferrer" target="_blank" href="https://gocardless.com/en-us/">
    payment provider
  </a>
);
export const currencyLink = (
  <a className="tooltip-link" rel="noopener noreferrer" target="_blank" href="https://wise.com/gb/currency-converter/">
    Wise
  </a>
);

export default {
  // These need to be in spans in order for the link to be included properly
  exchangeDisclosures: {
    fee: () => <span>We use exchange rate from {currencyLink} to estimate upcoming repayment in USD.</span>,
    repaid: () => (
      <span>
        We use exchange rates from {currencyLink}. The rate we apply is as of the date the funds are disbursed to
        Sanlo's bank account.
      </span>
    ),
    pending: ({ cryptoAsRepaymentSource = null, cryptoCurrency = "" }) =>
      text.t("exchange_disclosure_pending", { cryptoAsRepaymentSource, cryptoCurrency }),
    completed: () => (
      <span>
        We collect repayments in USD currency only and use exchange rate as of the date we process repayment amount. The
        rates are from our {paymentProviderLink}.
      </span>
    ),
  },

  transactionTooltips: {
    failed: () => "We've not received repayment. Please check your bank connection.",
    pending: () =>
      "We're processing your repayment. You'll see this amount collected by us within the next 3-5 business days.",
    completed: () => "Thanks, we received repayment.",
    disbursed: () => `${symbols.flyingMoney} We sent funds to your bank account.`,
  },

  upcomingRepaymentHint: (advance = {}, state) => {
    if (!advance || typeof advance !== "object") return "";

    const { advanceCurrency, repaymentRate, capitalFeePercentage } = advance;

    if (!repaymentRate || !capitalFeePercentage) return "";

    const conversionDisclaimer = hasConversion(advance) ? ` converted to ${advanceCurrency}.` : ".";
    let copy = `Up to ${repaymentRate}% of your earnings to-date ${conversionDisclaimer}`;

    return (
      <span>
        {copy}
        {state === "upcoming" && hasConversion(advance) && (
          <span>
            {" "}
            This is an estimated amount based on the most recent exchange rate from {currencyLink}. The final amount may
            differ based on the most recent conversion rate from our {paymentProviderLink} on the date that we
            collection this repayment.
          </span>
        )}
      </span>
    );
  },

  repaymentAmountHint: (advance = {}, transaction = {}) => {
    if (!advance || !transaction) return "";
    if (typeof advance !== "object" || typeof transaction !== "object") return "";

    const { advanceCurrency, repaymentRate, capitalFeePercentage, cryptoAsRepaymentSource } = advance;
    let { status = "" } = transaction;
    status = status.toLowerCase();

    if (!repaymentRate || !capitalFeePercentage) return "";

    const conversionDisclaimer = hasConversion(advance)
      ? ` converted to ${advanceCurrency}.`
      : cryptoAsRepaymentSource
      ? `including ${capitalFeePercentage}% of capital fee.`
      : ".";
    let copy = `Up to ${bankersRounding(repaymentRate, {
      output: "string",
    })}% of your earnings to-date ${conversionDisclaimer}`;
    if (status === "pending" && hasConversion(advance))
      copy += " The final amount may differ based on the most recent conversion rate.";

    return copy;
  },

  exchangeDisplay: (advance, opts = {}) => {
    const { advanceCurrency, originCurrency, originToAdvanceRate, cryptoAsRepaymentSource, cryptoCurrency } = advance;
    const { status, originToAdvanceRateOverride } = opts;

    if (advanceCurrency === originCurrency) {
      // This condition should not be applicable to a crypto user
      if (!cryptoAsRepaymentSource) {
        return "";
      }
    }

    // pending transaction don't have a guaranteed exchange rate
    // do we want to use the tilda/approximation symbol
    let symbol = "~";
    if (status !== "pending") symbol = "=";
    if (cryptoAsRepaymentSource) symbol = "↔︎";

    const advanceToOriginRate = 1 / (originToAdvanceRateOverride || originToAdvanceRate);

    const formattedAdvanceRate = currencyFormat(1, advanceCurrency);
    const formattedOriginRate = currencyFormat(advanceToOriginRate, originCurrency);

    // confirm if it is going to be 1 always i.e 1 USDT ~ 1 USD
    const text = cryptoAsRepaymentSource
      ? `1 ${cryptoCurrency} ${symbol} 1 ${originCurrency}`
      : `${formattedAdvanceRate} ${advanceCurrency} ${symbol} ${formattedOriginRate} ${originCurrency}`;

    return text;
  },

  currencyDisplay: (advance = {}, currency = {}, color = false) => {
    if (!advance || !currency) return "";
    if (typeof advance !== "object" || typeof currency !== "object") return "";

    const { advanceCurrency, originCurrency, cryptoAsRepaymentSource, cryptoCurrency } = advance;

    let { inAdvance, inOrigin } = currency;

    const formattedAdvance = currencyFormat(inAdvance, advanceCurrency);
    const formattedOrigin = currencyFormat(inOrigin, originCurrency);

    const advanceText = `${formattedAdvance} ${advanceCurrency}`;
    const originText = `${formattedOrigin} ${originCurrency}`;

    // If the currencies are different, the advanceAmount goes on the left-hand side of the conversion
    // advance amount (advance currency symbol) -> origin amount (origin currency symbol)
    let leftText = "";
    let rightText = advanceText;
    if (advanceCurrency !== originCurrency) {
      leftText = `${advanceText} → `;
      rightText = originText;
    }

    const showColor = color ? "highlighted-amount" : "";

    if (cryptoAsRepaymentSource) {
      rightText = inOrigin.toFixed(2);
      return (
        <span className={showColor}>
          {rightText} {cryptoCurrency}
        </span>
      );
    }
    return (
      <span>
        {leftText}
        <span className={showColor}>{rightText}</span>
      </span>
    );
  },

  fixedTermCurrencyDisplay: (amount, currencySymbol) => {
    const text = `${currencyFormat(amount, currencySymbol)} ${currencySymbol}`;
    return <span className="highlighted-amount">{text}</span>;
  },
};
