import React, { useRef } from "react";
import classnames from "classnames";

import { BodySmall14 } from "@styled";
import { StyledFileWrapper, StyledFileList } from "./FileList.styled";
import { SanloTooltip } from "@common/tooltips/sanlo-tooltip/SanloTooltip";

import { alphaTrim } from "@utils/general";
import useShadowScroll from "@utils/custom-hooks/useShadowScroll";
import { parseFileName, shouldWrap, formatSize } from "./utils";

import { ReactComponent as FilePlus } from "@assets/img/icons/common/file-plus.svg";
import { ReactComponent as CrossIcon } from "@assets/img/icons/common/cross2.svg";

const FileList = ({
  isUploading = false,
  files,
  onRemoveFile,
  successfulFiles = {},
  errorFiles = {},
  uploadedFiles = {},
  addFiles,
  isDocTab,
  isAccordion = false,
}) => {
  const dragDropZone = useRef(null);
  const [wrapperRef, contentRef, shadowBottomRef, shadowTopRef] = useShadowScroll(true);

  const toggleDragStyle = (value) => {
    const dropZoneClassList = dragDropZone.current.classList;
    if (value) {
      dropZoneClassList.add("is-dragging");
    } else {
      dropZoneClassList.remove("is-dragging");
    }
  };

  const dragOver = (e) => {
    e.preventDefault();
  };
  const dragEnter = (e) => {
    e.preventDefault();
    toggleDragStyle(true);
  };

  const dragLeave = (e) => {
    e.preventDefault();
    toggleDragStyle(false);
  };

  const dropFile = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    addFiles(files);
    toggleDragStyle(false);
  };

  const fileListScrollable =
    (Object.keys(files).length > 6 && isAccordion) || (Object.keys(files).length > 8 && !isAccordion);

  return (
    <StyledFileWrapper>
      {fileListScrollable && (
        <>
          <div ref={shadowTopRef} className="shadow shadow--top" />
          <div ref={shadowBottomRef} className="shadow shadow--bottom" />
        </>
      )}
      <StyledFileList
        ref={wrapperRef}
        className={classnames({
          "is-uploading": isUploading,
        })}
        isDocTab={isDocTab}
        isAccordion={isAccordion}
      >
        <div
          ref={(node) => {
            dragDropZone.current = node;
            contentRef.current = node;
          }}
          onDragEnter={dragEnter}
          onDragLeave={dragLeave}
          onDragOver={dragOver}
          onDrop={dropFile}
          className={classnames("files-wrapper", {
            "wrap-content": shouldWrap(files),
            "scroll-wrap-content": fileListScrollable,
          })}
        >
          {Object.keys(files).map((fileName, index) => {
            const file = files[fileName];
            const parsedName = parseFileName(fileName);

            const isUploaded = uploadedFiles[parsedName];
            const error = errorFiles[parsedName];
            const id = `tt-id-file-${alphaTrim(parsedName)}`;

            const isSuccessful = successfulFiles[parsedName];

            return (
              <>
                <div
                  key={`file-${fileName}-${index}`}
                  id={`${id}-file`}
                  className={classnames("selected-file-container", {
                    "is-wrapped": shouldWrap(files),
                    "success": isSuccessful,
                    "error": error,
                    "uploaded": !isSuccessful && isUploaded,
                  })}
                >
                  <div className="icon-name-wrapper">
                    <FilePlus id={`${id}-icon`} className="file-icon" />
                    <BodySmall14 id={`${id}-name`} className="selected-file-name">
                      {fileName}
                    </BodySmall14>
                    <BodySmall14 className="selected-file-size">• {formatSize(file.size)}</BodySmall14>
                  </div>
                  <SanloTooltip target={`${id}-name`} text={fileName} placement="top" />
                  {!isSuccessful && isUploaded && (
                    <SanloTooltip
                      maxWidth={300}
                      target={`${id}-icon`}
                      text={"A file with this name is already uploaded and will be replaced once you confirm uploading"}
                      placement="top"
                    />
                  )}
                  <CrossIcon className="cross-icon" onClick={() => onRemoveFile(fileName)} />
                </div>
                {!isSuccessful && !isUploaded && error && typeof error === "string" && (
                  <SanloTooltip maxWidth={300} target={`${id}-file`} text={error} placement="top" />
                )}
              </>
            );
          })}
        </div>
      </StyledFileList>
    </StyledFileWrapper>
  );
};

export default FileList;
