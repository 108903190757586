import styled from "styled-components";

export const StyledIntegrationCategory = styled.div`
  & {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    width: var(--card-container-width);

    border-bottom: 1px solid #2a2a2f;

    .integration-category-header {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;

      .category-title {
        margin-bottom: 8px;
        color: ${({ theme }) => theme.integrationCardTitleFontColor};
      }

      .category-description {
        color: ${({ theme }) => theme.integrationCardDescriptionFontColor};
      }
    }

    .integration-category-container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    &:last-child {
      border-bottom: none;
    }
  }
`;

export const StyledBankingCategory = styled.div`
  position: relative;
  margin-bottom: 2rem;
  width: var(--card-container-width);
  border-bottom: 1px solid #2a2a2f;
  .integration-category-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    .red-label {
      cursor: pointer;
    }

    .category-title {
      margin-bottom: 8px;
      color: ${({ theme }) => theme.integrationCardTitleFontColor};
    }

    .category-description {
      color: ${({ theme }) => theme.integrationCardDescriptionFontColor};

      &.add-button-showing {
        padding-right: 12rem;
      }
    }

    .add-account {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
    }

    .pulling-banner {
      margin-top: 16px;

      > div {
        padding: 28px 32px 32px 32px;
        margin-bottom: 16px;
      }
    }
  }

  .integration-category-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "card card";
    grid-column-gap: 2rem;
  }

  &:last-child {
    border-bottom: none;
  }
`;
