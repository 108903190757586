import styled from "styled-components";

const StyledConnectSources = styled.div`
  & {
    max-height: calc(100vh - 425px);
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 20px;
    }

    &::-webkit-scrollbar-thumb {
      padding: 0 6px;
      border-right: 6px solid transparent;
      border-left: 6px solid transparent;
      background-color: rgba(51, 51, 56, 1);
      background-clip: padding-box;
      border-radius: 20px;
    }

    .platform-accordion-wrapper {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export default StyledConnectSources;
