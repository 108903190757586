import React from "react";
import classnames from "classnames";
import styled from "styled-components";

import { BodyLargePlus } from "@styled";

const StyledJumboPicker = styled.div`
  cursor: pointer;

  .gradient-border {
    width: fit-content;
    border-radius: 12px;
    padding: 2px;

    &.selected {
      background: linear-gradient(to right, #c56bf5 0%, #ff5e7d 100%);

      .gradient-text {
        background: #c56bf5;
        background: -webkit-linear-gradient(to right, #c56bf5 0%, #ff5e7d 100%);
        background: -moz-linear-gradient(to right, #c56bf5 0%, #ff5e7d 100%);
        background: linear-gradient(to right, #c56bf5 0%, #ff5e7d 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .gradient-picker {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 150px;
      height: 80px;
      padding: 18px;
      background-color: ${({ theme }) => theme.grayscale40};
      border-radius: 12px;

      .gradient-text {
        color: ${({ theme }) => theme.textGray};
        white-space: nowrap;
      }
    }
  }
`;

const JumboPicker = ({ selected, onClick, label, testId = "" }) => {
  const pickerLabel = label.replaceAll(/[^a-zA-Z0-9]/g, "").toLowerCase();
  return (
    <StyledJumboPicker onClick={onClick} data-test-id={testId}>
      <div
        className={classnames(`gradient-border tag-sanlo-gradient-picker-${pickerLabel}`, {
          selected: selected,
        })}
      >
        <div className={`gradient-picker tag-sanlo-gradient-picker-${pickerLabel}`}>
          <BodyLargePlus className={`gradient-text tag-sanlo-gradient-picker-${pickerLabel}`}>{label}</BodyLargePlus>
        </div>
      </div>
    </StyledJumboPicker>
  );
};

export default JumboPicker;
