import styled from "styled-components";

export const GuideList = styled.ul`
  list-style-type: none;
  list-style-position: inside;

  li {
    margin: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  ol,
  ul {
    list-style-position: inside;
    list-style-type: disc;
    li {
      margin: 8px;
    }
  }
`;
