import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames";

import { BodySmallAltPlus, BodySmallAlt, BodySmall14, BodyRegularPlus } from "@styled";
import StyledUpload, { StyledFileSizeWrapper, StyledFileList } from "./Upload.styled";
import FileList from "./FileList";
import DragAndDropZone from "./DragAndDropZone";
import { SanloButton } from "@inputs";

import { DEFAULT_MAX_FILE_SIZE_IN_BYTES, fileTypeValidation } from "./utils";
import text from "@utils/text";

import { trackGTMEvent, ONBOARDING_ACTIONS } from "@utils/gtm";
import { ReactComponent as LockIcon } from "@assets/img/icons/common/lock.svg";
import { ReactComponent as PlusCircle } from "@assets/img/icons/common/plus-circle.svg";
import { toast } from "react-toastify";
import { toastOptions } from "@utils/defaults/toast";
import { SanloToast } from "@common/toasts/sanlo-toast/SanloToast";
import { useNavigate } from "react-router-dom";
import SanloChip from "@components/common/chips/SanloChip";
import { useSelector } from "react-redux";

const uploadingErrors = {
  SIZE_LIMIT: text.t("upload_size_limit"),
  // SERVER_ERROR: text.t("upload_server_error"), // Unused at the moment
  // UNSUPPORTED_FORMAT: text.t("upload_unsupported_format"),
};

const chipMessage = {
  "Connected": (length, date) => text.t("statements_approved"),
  "In Progress": (length, date) =>
    text.t("documents_received", {
      fileLength: length,
      date: date,
    }),
};

const Upload = ({
  isUploading = false,
  allFilesSuccessful = false,
  onUpload = () => {},
  successfulFiles = {},
  errorFiles = {},
  uploadedFiles = {},
  onRemoveFile = () => {},
  onReset = () => {},
  isBankUpload = true,
  isAccordion,
  label,
  toggleUpload,
  handleToggleUpload,
}) => {
  const inputFileRef = useRef(null);
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(isBankUpload ?? false);
  const [files, setFiles] = useState({});
  const hasSelectedFiles = Boolean(Object.keys(files).length);
  const uploadedFilesObj = useSelector((state) => state.banking.data.status);
  const lastUploadedFiles = uploadedFilesObj?.manualConnectionState?.files ?? [];

  const [invalidErrors, setInvalidErrors] = useState([]);
  const [invalidFiles, setInvalidFiles] = useState([]);

  const handleToggleFilePicker = () => {
    if (allFilesSuccessful) {
      // The file area is open and everything is successful. The user is
      // closing the area and we can reset it completely
      setFiles({});
      onReset();
      return;
    }
    if (isAccordion) {
      setIsOpen(!isOpen);
      handleToggleUpload(false);
    } else {
      navigate("/documents");
    }
  };

  useEffect(() => {
    if (toggleUpload) {
      if (isAccordion) {
        setIsOpen(false);
      } else {
        navigate("/documents");
      }
    }
  }, [toggleUpload, isAccordion, navigate]);

  const handleRemoveFile = (fileName) => {
    delete files[fileName];
    setFiles({ ...files });
    onRemoveFile(fileName);
  };

  const openFileExplorer = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const handleFiles = (event) => {
    const files = event.target.files;
    if (files.length) {
      let newFiles = addNewFiles(files);
      setFiles(newFiles);
    }
  };

  const addFiles = (files) => {
    let newFiles = addNewFiles(files);
    setFiles(newFiles);
  };

  const addNewFiles = (newFiles) => {
    for (let file of newFiles) {
      const exceedsSize = file.size > DEFAULT_MAX_FILE_SIZE_IN_BYTES;
      const isVideo = file.type.match(/video/);
      const isInvalidFile = fileTypeValidation(file.name);

      if (!exceedsSize && !isVideo && !isInvalidFile) {
        files[file.name] = file;
      }
      if (exceedsSize) {
        invalidErrors.push("SIZE_LIMIT");
        setInvalidErrors(invalidErrors);
      }

      if (isInvalidFile) {
        if (!invalidFiles.includes(file.name)) {
          invalidFiles.push(file.name);
          setInvalidFiles(invalidFiles);
        }
      }
    }
    return { ...files };
  };

  const uploadFiles = () => {
    onUpload(files);
  };
  useEffect(() => {
    if (invalidFiles.length > 0 || invalidErrors.length > 0) {
      // This is done to ensure the invalidFiles and invalidErrors arrays are not persisted
      setTimeout(() => {
        setInvalidFiles([]);
        setInvalidErrors([]);
      }, 5000);
    }
  }, [invalidErrors.length, invalidFiles.length]);

  useEffect(() => {
    if (allFilesSuccessful) {
      // set the files to empty object after 1 sec
      setTimeout(() => {
        setFiles({});
      }, 1000);
    }
  }, [allFilesSuccessful]);

  const renderBody = () => {
    if (allFilesSuccessful) {
      if (!isBankUpload) {
        //   toast.success(<SanloToast icon="success" msg={text.t("statements_received")} />, {
        //     ...toastOptions,
        //     toastId: `bank-upload-success-message`,
        //   });
        // } else {
        toast.success(
          <SanloToast
            icon="success"
            msg={text.t("documents_received", {
              fileLength: Object.keys(files).length,
              date: new Date().toUTCString(),
            })}
          />,
          {
            ...toastOptions,
            toastId: `documents-upload-success-message`,
          },
        );
      }
      return <DragAndDropZone isUploading={isUploading} openFileExplorer={openFileExplorer} addFiles={addFiles} />;
    }

    if (isOpen) return null;

    if (!hasSelectedFiles) {
      return (
        <>
          <DragAndDropZone isUploading={isUploading} openFileExplorer={openFileExplorer} addFiles={addFiles} />
          <StyledFileSizeWrapper>
            <BodySmallAlt>{text.t("drop_statements_file_size")}</BodySmallAlt>
          </StyledFileSizeWrapper>
        </>
      );
    }
    if (hasSelectedFiles) {
      return (
        <StyledFileList>
          <FileList
            isUploading={isUploading}
            files={files}
            onRemoveFile={handleRemoveFile}
            openFileExplorer={openFileExplorer}
            successfulFiles={successfulFiles}
            errorFiles={errorFiles}
            uploadedFiles={uploadedFiles}
            addFiles={addFiles}
            isDocTab={!isBankUpload}
            isAccordion={isAccordion}
          />

          <div
            className="add-more-files-button"
            onClick={() => {
              trackGTMEvent({
                event: ONBOARDING_ACTIONS.CONNECT_SOURCE_BANKING_UPLOAD_MORE_FILE,
              });
              if (isUploading) return;
              openFileExplorer();
            }}
          >
            <PlusCircle className="plus-circle-icon tag-sanlo-add-more-files-banking-statements" />
            <BodyRegularPlus className="add-files-label tag-sanlo-add-more-files-banking-statements">
              Add More Files
            </BodyRegularPlus>
          </div>

          <div className="upload-statements-cta-container">
            <SanloButton
              isDisabled={(Boolean(Object.keys(errorFiles).length) && !isUploading) || isUploading}
              isLoading={isUploading}
              label={
                <span className="button-text">
                  <LockIcon className="button-icon" />
                  {text.t("upload")}
                </span>
              }
              onClick={uploadFiles}
              gtmEventTag="tag-sanlo-upload-files-banking-statements"
              testId="upload-files-button"
            />
            <BodySmall14 className="drop-files-label">{text.t("drop_statements_file_size")}</BodySmall14>
          </div>
        </StyledFileList>
      );
    }
  };
  return (
    <StyledUpload>
      <input
        type="file"
        multiple
        hidden
        value=""
        ref={inputFileRef}
        onChange={handleFiles}
        data-test-id="file-upload-input"
      />

      {isBankUpload && (
        <div className="upload-statements-label">
          <BodySmallAlt>
            {text.t("cant_find_your_bank")}
            <BodySmallAltPlus
              onClick={() => {
                trackGTMEvent({
                  event: ONBOARDING_ACTIONS.CONNECT_SOURCE_BANKING_UPLOAD_STATEMENT,
                });
                handleToggleFilePicker();
              }}
              className="red-label tag-sanlo-upload-bank-statements"
              data-test-id="upload-statements"
            >
              {" "}
              {text.t("upload_statements")}
            </BodySmallAltPlus>
          </BodySmallAlt>
        </div>
      )}
      <div className={classnames({ "is-uploading": isUploading })}>
        {isBankUpload && (label === "In Progress" || label === "Connected") && lastUploadedFiles?.length > 0 && (
          <SanloChip
            state="success"
            content={
              <BodySmall14>
                {chipMessage?.[label](
                  lastUploadedFiles?.length,
                  lastUploadedFiles?.[lastUploadedFiles?.length - 1]?.dateUploaded,
                )}{" "}
              </BodySmall14>
            }
            showIcon
          />
        )}
      </div>
      {invalidFiles?.forEach((fileName) =>
        toast.error(<SanloToast icon="error" msg={text.t("invalid_file_extension", { fileName })} />, {
          ...toastOptions,
          toastId: `invalid-${fileName}`,
        }),
      )}
      {invalidErrors?.forEach((error, i) =>
        toast.error(<SanloToast icon="error" msg={uploadingErrors[error]} />, {
          ...toastOptions,
          toastId: `upload-error-${error}-${i}`,
        }),
      )}
      {Object.values(errorFiles)?.forEach((error, i) =>
        toast.error(<SanloToast icon="error" msg={error} />, {
          ...toastOptions,
          toastId: `server-error-${error}-${i}`,
        }),
      )}
      {renderBody()}
    </StyledUpload>
  );
};

export default Upload;
