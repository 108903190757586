import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames";
// https://github.com/sibnerian/sibgif
// sibgif is a fork of libgif with the addition of the get_frames() method
// which allows us to find the gif length using the frame delays
import SuperGif from "sibgif";

import { BodyMiniPlus } from "@styled";

import { ReactComponent as PlayIcon } from "@assets/img/icons/media/media-play.svg";
import { ReactComponent as PauseIcon } from "@assets/img/icons/media/media-pause.svg";
import { ReactComponent as ReplayIcon } from "@assets/img/icons/media/media-replay.svg";

const formatGifDuration = (seconds) => {
  // This function kinda cheats a little and assumes no gif will go over 59 minutes long
  return new Date(seconds * 1000).toISOString().substring(11, 19).replace("00:", "");
};

const GifPlayer = ({ media = "", controls = false }) => {
  // ToDo/Note: For this first iteration and possibly forever until otherwise needed, you
  // cannot have something be collapsable without a header.

  // ToDo: Add functionality to start in a collapsed state?
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [showControls, setShowControls] = useState(false);

  const [isGifLoaded, setIsGifLoaded] = useState(false);
  const [isGifPlaying, setIsGifPlaying] = useState(null);

  const [hasBeenStarted, setHasBeenStarted] = useState(false);
  const [hasEnded, setHasEnded] = useState(false);

  const [gifProgress, setGifProgress] = useState(0);
  const [gifDuration, setGifDuration] = useState(null);
  const [gifDimensions, setGifDimensions] = useState({});

  const gifRef = useRef(null);
  const superGifRef = useRef(null);
  const gifTimerRef = useRef(null);
  const controlsTimerRef = useRef(null);

  useEffect(() => {
    // When the component loads, check to see if it was gif
    checkForGif();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const sgif = superGifRef.current;
    if (!sgif) return;

    // Call play or pause to match the current state of isGifPlaying
    if (isGifPlaying) {
      sgif.play();
      startGifProgressTimer();
      if (hasEnded) setHasEnded(false);
      if (!hasBeenStarted) setHasBeenStarted(true);
    } else {
      sgif.pause();
      stopGifProgressTimer();
    }
    // eslint-disable-next-line
  }, [isGifPlaying]);

  const checkForGif = () => {
    if (!gifRef.current || !media || !controls) return;

    // Create our new SuperGif element and pass it the existing img tag with the gif
    const newSuperGif = new SuperGif({
      // required
      gif: gifRef.current,
      // optional
      auto_play: false,
      loop_mode: false,
      // loading styles
      progressbar_height: 10,
      progressbar_background_color: "rgba(0, 0, 0, 0.5)",
      progressbar_foreground_color: "#FF5C79",
      // events
      on_end: () => {
        setGifProgress(100);
        setIsGifPlaying(false);
        setHasEnded(true);
      },
    });

    newSuperGif.load(() => {
      superGifRef.current = newSuperGif;

      // Calculate the gif time from the frame delays
      const frameData = newSuperGif.get_frames();

      let totaltime = 0;
      for (let i = 0; i < frameData.length; ++i) {
        totaltime += frameData[i].delay;
      }

      setGifDimensions({
        width: frameData[0].data.width,
        height: frameData[0].data.height,
      });
      setGifDuration(Math.round(totaltime / 100));
      setIsGifLoaded(true);
      setIsGifPlaying(false);
    });
  };

  const startGifProgressTimer = () => {
    gifTimerRef.current = setInterval(() => {
      setGifProgress((superGifRef.current.get_current_frame() / superGifRef.current.get_length()) * 100);
    }, 100);
  };

  const stopGifProgressTimer = () => {
    window.clearInterval(gifTimerRef.current);
    gifTimerRef.current = null;
  };

  const getMediaIcon = () => {
    // This function only gets called once the gif has been properly loaded
    // If the gif has ended, we want to show the replay icon
    if (hasEnded) return <ReplayIcon className="media-icon" />;
    // If the gif has not been started, we want to show the play icon
    if (!hasBeenStarted || !isGifPlaying) return <PlayIcon className="media-icon" />;
    // If the gif is playing, we want to show the pause icon
    if (isGifPlaying) return <PauseIcon className="media-icon" />;
  };

  const setProgress = (e) => {
    e.stopPropagation();
    const sgif = superGifRef.current;

    const nodeRect = e.currentTarget.getBoundingClientRect();
    let xPos = e.clientX - nodeRect.left;
    const percentage = (xPos / nodeRect.width) * 100;
    const selectedTime = percentage * gifDuration;

    const frameData = sgif.get_frames();
    let startFrame = 0;
    let endFrame = 0;
    for (let i = 0; i < frameData.length; ++i) {
      endFrame = startFrame + frameData[i].delay;
      if (selectedTime >= startFrame && selectedTime <= endFrame) {
        sgif.move_to(i);
        setGifProgress(percentage);
        break;
      }
      startFrame = endFrame;
    }
  };

  return (
    <div
      className="gif-player"
      onClick={() => {
        const sgif = superGifRef.current;
        if (!sgif) return;
        const isPlaying = sgif.get_playing();
        setIsGifPlaying(!isPlaying);
      }}
    >
      <img ref={gifRef} src={media} alt="setup" />

      {isGifLoaded && (
        <div
          className={classnames("gif-controls", {
            "show-controls": showControls,
          })}
          style={{
            maxWidth: gifDimensions.width ? `${gifDimensions.width}px` : "none",
            maxHeight: gifDimensions.height ? `${gifDimensions.height}px` : "none",
          }}
          onMouseEnter={() => {
            window.clearTimeout(controlsTimerRef.current);
            controlsTimerRef.current = null;
            setShowControls(true);
          }}
          onMouseLeave={() => {
            controlsTimerRef.current = setTimeout(() => {
              setShowControls(false);
            }, 2500);
          }}
          onMouseMove={() => {
            window.clearTimeout(controlsTimerRef.current);
            controlsTimerRef.current = null;
            setShowControls(true);
            controlsTimerRef.current = setTimeout(() => {
              setShowControls(false);
            }, 2500);
          }}
        >
          {getMediaIcon()}

          <div
            className="gif-progress-bar-wrapper"
            onClick={(e) => {
              e.stopPropagation();
            }}
            onMouseDown={(e) => {
              e.stopPropagation();
              setIsMouseDown(true);
            }}
            onMouseUp={(e) => {
              e.stopPropagation();
              setIsMouseDown(false);
              setProgress(e);
              startGifProgressTimer();
            }}
            onMouseEnter={() => {
              window.clearTimeout(controlsTimerRef.current);
              controlsTimerRef.current = null;
              setShowControls(true);
            }}
            onMouseLeave={(e) => {
              e.stopPropagation();

              controlsTimerRef.current = setTimeout(() => {
                setShowControls(false);
              }, 2500);

              if (!isMouseDown) return;
              setIsMouseDown(false);
              setProgress(e);
              startGifProgressTimer();
            }}
            onMouseMove={(e) => {
              e.stopPropagation();

              window.clearTimeout(controlsTimerRef.current);
              controlsTimerRef.current = null;
              setShowControls(true);
              controlsTimerRef.current = setTimeout(() => {
                setShowControls(false);
              }, 2500);

              if (!isMouseDown) return;
              stopGifProgressTimer();
              setProgress(e);
            }}
          >
            <div className="gif-progress-bar-outer">
              <div
                className="gif-progress-bar-inner"
                style={{
                  width: `${gifProgress}%`,
                }}
              />
            </div>
          </div>

          <BodyMiniPlus className="gif-duration">{formatGifDuration(gifDuration)}</BodyMiniPlus>
        </div>
      )}
    </div>
  );
};

export default GifPlayer;
