export const generateSectionData = ({
  section = {},
  byId = {},
  byOrder = [], // The old order
}) => {
  // If the section is new, add it to the byId object
  if (section && section.id && !byId[section.id]) {
    byId[section.id] = section;
  }
  // Remove the section id being updated
  // Section creation shouldnt affect the order at all
  const newOrder = byOrder.filter((id) => id !== section.id);
  // Splice it back into the new order, order on the object is incorrect now
  // Or add the new section at its order
  if (section.id) {
    newOrder.splice(section.order, 0, section.id);
  }
  // Go through and update the order to match the new order
  newOrder.forEach((id, index) => {
    byId[id].order = index;
    // Update the incoming section
    if (id === section.id) {
      byId[id] = {
        ...byId[id],
        ...section,
      };
    }
  });
  return {
    byId,
    byOrder: newOrder,
    full: newOrder.map((id) => byId[id]),
  };
};
