import { createSelector } from "reselect";
import * as Sentry from "@sentry/browser";

import {
  LOGIN,
  USER_SUMMARY,
  FEATURE_FLAGS,
  FINISH_REGISTRATION,
  GET_ONBOARDING_PROGRESS,
  SAVE_ONBOARDING_PROGRESS,
  REQUEST_CAPITAL_ONBOARDING,
  FINISH_ONBOARDING,
  PARTNER_BRANDING,
  AUTH0_UPDATE,
  SEND_FEEDBACK,
  CAN_UPDATE_ONBOARDING_PROCESS,
  SET_ONBOARDING_SUPERPOWERS,
  SUGGESTED_INTEGRATION_COMPLETED,
  SET_DEV_ACCESS_TOKEN,
} from "./actions";

import { PRODUCT_ANALYTICS, BANKING, IN_APP_PURCHASES } from "@utils/consts";
import { getRequestStatus, getResponseStatus, getErrorStatus, setStatusDefaults, stateSet } from "@utils/redux";

const initialState = {
  initialized: false,
  completedRegister: false,
  suggestedIntegrationsCompleted: false,
  user: {
    email: "",
    firstName: "",
    lastName: "",
    fullName: "",
    roleAtCompany: "",
    subscription: "",
    devAccessToken: "",
  },
  company: {
    companyName: "",
    onboarding: {
      isComplete: false,
      isStatusCardDismissed: false,
      isEarlyAccessBannerDismissed: false,
      onboardingSuperpowerPreferences: [],
      suggestedOnboardingIntegrations: [],
    },
  },
  theme: "dark",
  requestStatus: {
    userSummary: setStatusDefaults(),
    // Authentication listens to initialUserSummary
    initialUserSummary: setStatusDefaults(),
    login: setStatusDefaults(),
    featureFlags: setStatusDefaults(),
    finishRegistration: setStatusDefaults(),
  },
  featureFlags: {
    charts: false,
  },
  isChecked: false,
  companyUsers: [],
  onboarding: {
    requestStatus: {
      getData: setStatusDefaults(),
      saveData: setStatusDefaults(),
      requestCapitalOnboarding: setStatusDefaults(),
      setSuperpowers: setStatusDefaults(),
    },
    data: {
      capitalRequestStage: null,
      completedModalOnboarding: false,
      fundingRequested: 35000,
      userMonthlyRevenue: "LESS_25",
      currentStep: 0,
      currentProgress: 0,
      platformsLinked: {
        banking: false,
        productAnalytics: false,
        inAppPurchases: false,
        adNetworks: false,
      },
      onboardingType: "PRIMARY",
      rejectedCardDismissed: false,
    },
    requiredPlatforms: [BANKING, IN_APP_PURCHASES, PRODUCT_ANALYTICS],
    canUpdateOnboardingProcess: false,
    initialLoadComplete: false,
  },
  partnerBranding: {
    requestStatus: setStatusDefaults(),
    data: {}, // JSON.parse(localStorage.getItem("partnerBranding") || "{}"), can cause issues with previously cached logos
  },
  sendFeedback: {
    requestStatus: setStatusDefaults(),
    data: {},
  },
  auth0Updated: false,
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN.REQUEST: {
      return stateSet(state, {
        "requestStatus.login": getRequestStatus(),
      });
    }

    case LOGIN.RESPONSE: {
      return stateSet(state, {
        "requestStatus.login": getResponseStatus(),
      });
    }

    case LOGIN.ERROR: {
      return stateSet(state, {
        "requestStatus.login": getErrorStatus(),
      });
    }

    case USER_SUMMARY.REQUEST: {
      let newState = stateSet(state, {
        "requestStatus.userSummary": getRequestStatus(),
      });
      if (!state.initialized) {
        newState = stateSet(newState, {
          "requestStatus.initialUserSummary": getRequestStatus(),
        });
      }
      return newState;
    }

    case USER_SUMMARY.RESPONSE: {
      Sentry.setUser({ email: action.response.user.email, id: action.response.user.userId });
      let newState = stateSet(state, {
        "requestStatus.userSummary": getResponseStatus(),
        "completedRegister": action.response.completedRegister,
        "user": action.response.user ? { ...state.user, ...action.response.user } : state.user,
        "company": action.response.company || state.company,
        "initialized": true,
      });
      if (!state.initialized) {
        newState = stateSet(newState, {
          "requestStatus.initialUserSummary": getResponseStatus(),
        });
      }
      return newState;
    }

    case USER_SUMMARY.ERROR: {
      let newState = stateSet(state, {
        "requestStatus.userSummary": getErrorStatus(),
      });
      if (!state.initialized) {
        newState = stateSet(newState, {
          "requestStatus.initialUserSummary": getErrorStatus(),
        });
      }
      return newState;
    }

    case FEATURE_FLAGS.REQUEST: {
      return stateSet(state, {
        "requestStatus.featureFlags": getRequestStatus(),
      });
    }

    case FEATURE_FLAGS.RESPONSE: {
      return stateSet(state, {
        "requestStatus.featureFlags": getResponseStatus(),
        "featureFlags": action.response,
      });
    }

    case FEATURE_FLAGS.ERROR: {
      return stateSet(state, {
        "requestStatus.featureFlags": getErrorStatus(),
        "featureFlags": "error",
      });
    }

    case FINISH_REGISTRATION.REQUEST: {
      return stateSet(state, {
        "requestStatus.finishRegistration": getRequestStatus(),
      });
    }

    case FINISH_REGISTRATION.RESPONSE: {
      return stateSet(state, {
        "requestStatus.finishRegistration": getResponseStatus(),
        "completedRegister": action.response.completedRegister,
        "company": action.response.company,
        "user": {
          firstName: action.response.user.firstName,
          lastName: action.response.user.lastName,
        },
      });
    }

    case FINISH_REGISTRATION.ERROR: {
      return stateSet(state, {
        "requestStatus.finishRegistration": getErrorStatus(),
      });
    }

    case GET_ONBOARDING_PROGRESS.REQUEST: {
      return stateSet(state, {
        "onboarding.requestStatus.getData": getRequestStatus(),
      });
    }

    case GET_ONBOARDING_PROGRESS.RESPONSE: {
      return stateSet(state, {
        "onboarding.requestStatus.getData": getResponseStatus(),
        "onboarding.data": action.response,
        "onboarding.initialLoadComplete": true,
      });
    }

    case GET_ONBOARDING_PROGRESS.ERROR: {
      return stateSet(state, {
        "onboarding.requestStatus.getData": getErrorStatus(),
        "onboarding.initialLoadComplete": true,
      });
    }

    case SAVE_ONBOARDING_PROGRESS.REQUEST: {
      return stateSet(state, {
        "onboarding.requestStatus.saveData": getRequestStatus(),
        "onboarding.data": action.onboarding,
      });
    }

    case SAVE_ONBOARDING_PROGRESS.RESPONSE: {
      return stateSet(state, {
        "onboarding.requestStatus.saveData": getResponseStatus(),
        "onboarding.data": action.response,
      });
    }

    case SAVE_ONBOARDING_PROGRESS.ERROR: {
      return stateSet(state, {
        "onboarding.requestStatus.saveData": getErrorStatus(),
      });
    }

    case REQUEST_CAPITAL_ONBOARDING.REQUEST: {
      return stateSet(state, {
        "onboarding.requestStatus.createCapitalRequest": getRequestStatus(),
      });
    }

    case REQUEST_CAPITAL_ONBOARDING.RESPONSE: {
      return stateSet(state, {
        "onboarding.requestStatus.createCapitalRequest": getResponseStatus(),
      });
    }

    case REQUEST_CAPITAL_ONBOARDING.ERROR: {
      return stateSet(state, {
        "onboarding.requestStatus.createCapitalRequest": getErrorStatus(),
      });
    }

    case FINISH_ONBOARDING.REQUEST: {
      return stateSet(state, {
        "onboarding.requestStatus.finishOnboarding": getRequestStatus(),
      });
    }

    case FINISH_ONBOARDING.RESPONSE: {
      return stateSet(state, {
        "onboarding.requestStatus.finishOnboarding": getResponseStatus(),
        "onboarding.data.rejectedCardDismissed": true,
        "company.onboarding.isComplete": true,
      });
    }

    case FINISH_ONBOARDING.ERROR: {
      return stateSet(state, {
        "onboarding.requestStatus.finishOnboarding": getErrorStatus(),
      });
    }

    case PARTNER_BRANDING.REQUEST: {
      return stateSet(state, {
        "partnerBranding.requestStatus": getRequestStatus(),
      });
    }

    case PARTNER_BRANDING.RESPONSE: {
      return stateSet(state, {
        "partnerBranding.requestStatus": getResponseStatus(),
        "partnerBranding.data": action.response,
      });
    }

    case PARTNER_BRANDING.ERROR: {
      return stateSet(state, {
        "partnerBranding.requestStatus": getErrorStatus(),
      });
    }

    case AUTH0_UPDATE.RESPONSE: {
      return stateSet(state, {
        auth0Updated: true,
      });
    }

    case SEND_FEEDBACK.REQUEST: {
      return stateSet(state, {
        "sendFeedback.requestStatus": getRequestStatus(),
      });
    }

    case SEND_FEEDBACK.RESPONSE: {
      return stateSet(state, {
        "sendFeedback.requestStatus": getResponseStatus(),
        "sendFeedback.data": action.response,
      });
    }

    case SEND_FEEDBACK.ERROR: {
      return stateSet(state, {
        "sendFeedback.requestStatus": getErrorStatus(),
      });
    }

    case CAN_UPDATE_ONBOARDING_PROCESS.SET: {
      return stateSet(state, {
        "onboarding.canUpdateOnboardingProcess": action.payload,
      });
    }

    case SET_ONBOARDING_SUPERPOWERS.REQUEST: {
      return stateSet(state, {
        "onboarding.requestStatus.setSuperpowers": getRequestStatus(),
      });
    }

    case SET_ONBOARDING_SUPERPOWERS.RESPONSE: {
      const { suggestedIntegrations = [] } = action.response;
      return stateSet(state, {
        "onboarding.requestStatus.setSuperpowers": getResponseStatus(),
        "company.onboarding.suggestedOnboardingIntegrations": suggestedIntegrations,
      });
    }

    case SET_ONBOARDING_SUPERPOWERS.ERROR: {
      return stateSet(state, {
        "onboarding.requestStatus.setSuperpowers": getErrorStatus(),
      });
    }

    case SUGGESTED_INTEGRATION_COMPLETED.SET: {
      return stateSet(state, {
        suggestedIntegrationsCompleted: action.payload,
      });
    }

    case SET_DEV_ACCESS_TOKEN.SET: {
      return stateSet(state, {
        user: { ...state.user, devAccessToken: action.payload },
      });
    }

    default:
      return state;
  }
};

export const sessionSelector = (state) => state.session;

export const superpowerPreferencesSelector = createSelector(sessionSelector, (session) => {
  return session.company.onboarding.onboardingSuperpowerPreferences || [];
});

export const onboardingLoadingSelector = createSelector(sessionSelector, (session) => {
  const superpowerRequest = session.onboarding.requestStatus.setSuperpowers;
  const userSummaryRequest = session.requestStatus.userSummary;
  return superpowerRequest.pending || userSummaryRequest.pending;
});

export default sessionReducer;
