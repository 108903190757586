import { useSelector } from "react-redux";
import { adNetworkConnectionsSelector } from "@redux/integrations/base/baseSlice";

import { BANKING, PRODUCT_ANALYTICS, IN_APP_PURCHASES, AD_NETWORKS, PLATFORMS } from "@utils/consts";
import { getMostPrioritized, NOT_CONNECTED, DRAFT_CONNECTION } from "@utils/status";

const statusLabels = (isRequired) => ({
  NOT_CONNECTED: isRequired ? { id: "required", label: "Required" } : { id: "optional", label: "Optional" },
  CONNECTED: {
    id: "connected",
    label: "Connected",
  },
  NEEDS_RECONNECTION: {
    id: "reconnect",
    label: "Needs Attention",
  },
  INVALID_API_TOKEN: {
    id: "reconnect",
    label: "Needs Attention",
  },
  INVALID_APP_ID: {
    id: "reconnect",
    label: "Needs Attention",
  },
  NO_PERMISSIONS: {
    id: "noPermissions",
    label: "Needs attention",
  },
  CONNECTOR_ERROR: {
    id: "brokenConnection",
    label: "Broken Connection",
  },
  PULLING_DATA: {
    id: "inProgress",
    label: "In Progress",
  },
  DRAFT_CONNECTION: isRequired ? { id: "required", label: "Required" } : { id: "optional", label: "Optional" },
  VERIFYING_CONNECTION: {
    id: "inProgress",
    label: "In Progress",
  },
});

const integrationsRequired = {
  banking: true,
  productAnalytics: true,
  inAppPurchases: true,
  adNetworks: false,
};

const notConnectedStatuses = [NOT_CONNECTED, DRAFT_CONNECTION];

const filterIntegrationsByCategory = (integrations = {}, filterCategory = "") => {
  const filteredIntegrations = [];
  Object.keys(integrations).forEach((key) => {
    const integration = integrations[key];
    const { category = "", integrationCategory = "" } = integration;

    if (category && filterCategory.includes(category)) {
      filteredIntegrations.push(integration);
    } else if (integrationCategory && filterCategory.includes(integrationCategory)) {
      filteredIntegrations.push(integration);
    }
  });
  return filteredIntegrations;
};

const useIntegrationsStatus = () => {
  // NOTE: This is a copy of useIntegrationsStatus, but trying to rely more on
  // using categories instead of explicit pulls from the integrations object.

  const integrations = useSelector((state) => state.integrations);
  const integrationsRequestStatus = integrations.base.requestStatus.getData;
  const banking = useSelector((state) => state.banking);
  const adNetworkConnections = useSelector(adNetworkConnectionsSelector);

  const statuses = {
    banking: {},
    productAnalytics: {},
    inAppPurchases: {},
    adNetworks: {},
  };

  // BANKING
  // NOTE: Plaid status is per plaid connector, so there's no global status.
  // Check all of the statuses and return the most critical
  const manualBankingStatus = banking.data?.status?.manualConnectionState?.status;
  const { plaid } = integrations;
  const plaidStatus = plaid.requestStatus.status;
  const institutionsStatus = plaid.data.institutions?.map((i) => i.status);
  const bankingStatus = manualBankingStatus || getMostPrioritized(institutionsStatus) || NOT_CONNECTED;
  statuses[BANKING] = {
    platformId: "plaid",
    status: bankingStatus,
    ...statusLabels(integrationsRequired[BANKING])[bankingStatus],
    connected: !notConnectedStatuses.includes(bankingStatus),
    requestStatus: plaidStatus,
  };

  const addIntegrationStatus = (category = "", categoryFilter = null, opts = {}) => {
    let { filteredIntegrations = [] } = opts;
    if (!filteredIntegrations.length) {
      categoryFilter = categoryFilter || category;
      filteredIntegrations = filterIntegrationsByCategory(integrations, categoryFilter);
    }
    const connectedIntegration = filteredIntegrations.find((integration) => integration.status !== NOT_CONNECTED);
    const integrationStatus = connectedIntegration?.status || NOT_CONNECTED;
    statuses[category] = {
      platformId: connectedIntegration?.id || category,
      status: integrationStatus,
      ...statusLabels(integrationsRequired[category])[integrationStatus],
      connected: !notConnectedStatuses.includes(integrationStatus),
      requestStatus: integrationsRequestStatus,
    };
  };

  addIntegrationStatus(PRODUCT_ANALYTICS);
  addIntegrationStatus(IN_APP_PURCHASES, `${IN_APP_PURCHASES} ${PLATFORMS}`);
  addIntegrationStatus(AD_NETWORKS, null, {
    filteredIntegrations: adNetworkConnections,
  });

  return statuses;
};

// const useIntegrationsStatus = () => {
//   const integrations = useSelector((state) => state.integrations);
//   const banking = useSelector((state) => state.banking);

//   const {
//     // banking
//     plaid,

//     // productAnalytics
//     appsflyer,
//     adjust,
//     firebase,
//     singular,

//     // IAP platforms
//     googleplay,
//     appstore,

//     // Request status for MMPs
//     // statusRequest
//   } = integrations;

//   let statuses = {};

//   // Status for MMPs and IAPs
//   const integrationsRequestStatus = integrations.base.requestStatus.getData;

//   // Banking status
//   // Plaid status is per plaid connector, so there's no global status.
//   // Check all of the statuses and return the most critical
//   const manualBankingStatus = banking.data?.status?.manualConnectionState?.status;
//   const plaidStatus = plaid.requestStatus.status;
//   const institutionsStatus = plaid.data.institutions?.map((i) => i.status);
//   const bankingStatus = manualBankingStatus || getMostPrioritized(institutionsStatus) || NOT_CONNECTED;
//   statuses.banking = {
//     platformId: "plaid",
//     status: bankingStatus,
//     ...statusLabels(integrationsRequired.banking)[bankingStatus],
//     connected: !notConnectedStatuses.includes(bankingStatus),
//     requestStatus: plaidStatus,
//   };

//   // Product productAnalytics status
//   // Only one MMP can be connected, so we return the one that's not disconnected
//   const mmpPlatform = [appsflyer, adjust, firebase, singular].find((platform) => platform.status !== NOT_CONNECTED);
//   const mmpStatus = mmpPlatform?.status || NOT_CONNECTED;
//   statuses.productAnalytics = {
//     platformId: mmpPlatform?.id || PRODUCT_ANALYTICS,
//     status: mmpStatus,
//     ...statusLabels(integrationsRequired.productAnalytics)[mmpStatus],
//     connected: !notConnectedStatuses.includes(mmpStatus),
//     requestStatus: integrationsRequestStatus,
//   };

//   // IAP Platforms status
//   const iapPlatform = [googleplay, appstore].find((platform) => platform.status !== NOT_CONNECTED);
//   const iapStatus = iapPlatform?.status || NOT_CONNECTED;
//   statuses.inAppPurchases = {
//     platformId: iapPlatform?.id || IN_APP_PURCHASES,
//     status: iapStatus,
//     ...statusLabels(integrationsRequired.inAppPurchases)[iapStatus],
//     connected: !notConnectedStatuses.includes(iapStatus),
//     requestStatus: integrationsRequestStatus,
//   };

//   // Ad Networks status
//   const adNetworksPlatform = [appsflyer, adjust, firebase, singular].find(
//     (platform) => platform.status !== NOT_CONNECTED,
//   );
//   const adNetworksStatus = adNetworksPlatform?.status || NOT_CONNECTED;
//   statuses.adNetworks = {
//     platformId: adNetworksPlatform?.id || AD_NETWORKS,
//     status: adNetworksStatus,
//     ...statusLabels(integrationsRequired.adNetworks)[adNetworksStatus],
//     connected: !notConnectedStatuses.includes(adNetworksStatus),
//     requestStatus: integrationsRequestStatus,
//   };

//   return statuses;
// };

export default useIntegrationsStatus;
