import React from "react";
import { Icon, Image, Popover, PopoverContent, PopoverTrigger, PopoverBody, VStack } from "@chakra-ui/react";
import { ReactComponent as AlertCircle } from "@assets/img/icons/common/alertCircle.svg";

import PropTypes from "prop-types";

/**
 * @prop {node} children - Children components.
 * @prop {string} placement - Tooltip placement.
 * @prop {string} imageSrc - Image source.
 * @prop {string} dataTestid - Data test id.
 */
export const SectionFieldTooltip = ({ children, dataTestid = "", placement = "bottom-end", imageSrc }) => {
  return (
    <Popover trigger={"hover"} placement={placement}>
      <PopoverTrigger>
        <Icon
          as={AlertCircle}
          ml={"5px"}
          width={"16px"}
          height={"16px"}
          transform={"rotate(180deg)"}
          color={"textWhite.400"}
          data-testid={dataTestid}
        />
      </PopoverTrigger>
      <PopoverContent maxW={"320px"}>
        <PopoverBody>
          <VStack gap={"10px"}>
            {imageSrc && (
              <Image
                src={imageSrc}
                bg={"lightgray"}
                backgroundPosition={"50%"}
                backgroundSize={"cover"}
                backgroundRepeat={"no-repeat"}
                borderRadius={"8px"}
              />
            )}
            {children}
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

SectionFieldTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  placement: PropTypes.string,
  imageSrc: PropTypes.string,
  dataTestid: PropTypes.string,
};
