import React from "react";
import { BodyRegularPlus, BodySmallAlt, BodyRegular16 } from "@styled";
import { StyledPlatformFeatureCard } from "../OnboardingExperienceStyles";

const PlatformFeatureCard = ({ feature, animated, animationDuration = "1s", className, regular }) => {
  const { icon, title, description } = feature;

  return (
    <StyledPlatformFeatureCard className={className} animated={animated} animationDuration={animationDuration}>
      <div className="pf-header">
        <div className="pf-icon">{icon}</div>
        <BodyRegularPlus className="pf-title">{title}</BodyRegularPlus>
      </div>
      {regular && <BodyRegular16 className="pf-description">{description}</BodyRegular16>}
      {!regular && <BodySmallAlt className="pf-description">{description}</BodySmallAlt>}
    </StyledPlatformFeatureCard>
  );
};

export default PlatformFeatureCard;
