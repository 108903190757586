import React from "react";
import styled from "styled-components";

import { Link } from "@inputs";

export const StyledGuideLink = styled(Link)`
  color: ${({ theme }) => theme.primaryColor};
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.primaryColor};
  }
`;

export const GuideLink = ({ label = "", href = "", onClick = () => {} }) => {
  return (
    <StyledGuideLink href={href} isExternalLink onClick={onClick}>
      {label}
    </StyledGuideLink>
  );
};
