import styled from "styled-components";

export const StyledGrowthCapitalSummary = styled.div`
  width: 100%;
  height: 19.75rem;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 6.75rem 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  grid-template-areas:
    "received chart"
    "repayments chart";
  margin-bottom: 2rem;
  margin-top: 2rem;

  .gcs-tooltip {
    margin-left: 0.5rem;
    margin-bottom: 0.25rem;
    fill: ${({ theme }) => theme.grayFontColor};
  }

  .gcs-container {
    background-color: ${({ theme }) => theme.grayscale20};
    border-radius: 0.75rem;
    padding: 1.25rem 1.5rem;
  }

  .gcs-received {
    grid-area: received;

    .title {
      display: block;
      color: white;
      margin-bottom: 0;
    }

    .amount {
      color: ${({ theme }) => theme.colorElectricBlue};
    }

    .received-tooltip {
      width: 1.125rem;
      height: 1.125rem;
      margin-bottom: 1rem;
    }
  }

  .gcs-repayments {
    grid-area: repayments;
    overflow: hidden;
    display: flex;
    flex-flow: column;
    padding-right: 0;

    .title {
      color: white;
      margin-bottom: 1rem;
    }

    .list-wrapper {
      flex: 1;
      padding-right: 1.5rem;

      &::-webkit-scrollbar {
        height: 0;
        width: 0.5rem;
        cursor: default !important;
      }

      &::-webkit-scrollbar-track {
        visibility: hidden;
        background-color: ${({ theme }) => theme.inputBackgroundColor};
        opacity: 0.5;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(51, 51, 56, 1);
        border-radius: 1.25rem;
        opacity: 0.5;
      }

      .list {
        width: 100%;

        td {
          padding: 1rem 0;
          text-align: left;
          text-align-last: left;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          height: 1.5rem;

          div,
          span {
            vertical-align: top;
            height: 1.5rem;
            line-height: 1.5rem;
          }
        }

        .icon {
          border-radius: 4px;
          margin-right: 0.5rem;
        }

        .platformCell {
          .amount {
            color: ${({ theme }) => theme.colorYellow};
            cursor: default;
          }
        }

        .gameCell {
          .name {
            display: inline-block;
            max-width: 8.75rem;
            text-overflow: ellipsis;
            overflow: hidden;
            cursor: default;
          }
        }

        .dateCell {
          text-align: right;
          text-align-last: right;

          .date {
            display: inline-block;
            cursor: default;
          }
        }
      }
    }
  }

  .gcs-chart {
    grid-area: chart;
    position: relative;
    display: grid;
    height: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: min-content 1fr min-content;
    grid-template-areas:
      "title"
      "piechart"
      "table";

    .gcs-chart-title {
      grid-area: title;
      color: white;
      margin-bottom: 0;
    }

    .gcs-pie-chart {
      grid-area: piechart;
      --pie-chart-size: 7.75rem;
      --pie-chart-center-text-size: 0.75rem;
      &.three-plus {
        --pie-chart-size: 9.125rem;
        --pie-chart-center-text-size: 0.875rem;
      }
    }

    .gcs-chart-legend {
      grid-area: table;
    }
  }

  &.three-plus {
    height: 23.25rem;
  }
`;

export const StyledGoToRepaidButton = styled.button(({ theme }) => ({
  border: "none",
  outline: "none",
  background: "transparent",
  color: theme.colorRed,
}));
