import React from "react";
import { VStack } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { KeyGenerator } from "@common/key-generator/KeyGenerator";
import { BaseCard } from "./BaseCard";

import { sdkTokenSelector, settingsSelector, settingsThunks } from "@redux/webshops/webshop/settings/settingsSlice";
import { useParams } from "react-router-dom";

export const ClientToken = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { requestStatus = {} } = useSelector(sdkTokenSelector);
  const {
    settings: { sdkClientToken },
  } = useSelector(settingsSelector);
  let token = sdkClientToken?.token || "";

  return (
    <BaseCard title={"Unity SDK"} id={"builder-settings-client-token"}>
      <VStack
        p={"16px"}
        gap={"16px"}
        alignItems={"center"}
        justifyContent={"center"}
        alignSelf={"stretch"}
        borderRadius={"10px"}
        border={"1px solid"}
        borderColor={"dark.300"}
      >
        <KeyGenerator
          title={"Client token"}
          subTitle={"You will need this token in order to configure your Unity SDK."}
          keyValue={token}
          requestStatus={requestStatus}
          onButtonClick={() => dispatch(settingsThunks.generateSDKToken({ webshopId: id }))}
        />
      </VStack>
    </BaseCard>
  );
};
