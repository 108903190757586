import React, { Fragment, useRef, useState } from "react";
import styled from "styled-components";
import classnames from "classnames";

import { BodyMiniPlus, TitleRegularPlus, BodySmall14CSS } from "@styled";
import { StyledUpcomingPayoutsContent } from "./UpcomingPayoutsContainerStyles";
import { DailyRevenueHeaderTooltip } from "../daily-revenue/components/Tooltips/DailyRevenueHeaderTooltip";
import { SanloTooltip } from "@components/common/tooltips/sanlo-tooltip/SanloTooltip";

import { ReactComponent as ArrowIcon } from "@assets/img/icons/common/accordion-arrow.svg";
import { ReactComponent as CheckCircleIcon } from "@assets/img/icons/common/check_circle.svg";
import { ReactComponent as TimeIcon } from "@assets/img/icons/common/time-wire.svg";
import { Card } from "@common/cards/card/Card";
import { useTrackHover, FINANCIALS_ACTION } from "@utils/gtm";
import { useLocation } from "react-router-dom";
import isEmpty from "lodash/isEmpty";

const DEFAULT_IMAGE = require("@assets/img/icons/adNetworkDefault.png");

const UpcomingPayoutsContent = ({ isPullingData = false, onCardSelect = null, cards }) => {
  const [scrollPosition, setScrollPosition] = useState("left");
  const [addTracking, removeTracking] = useTrackHover();

  const location = useLocation();

  const contentRef = useRef(null);
  const wrapperRef = useRef(null);
  const cardsRef = useRef(null);

  const showScroll = !isPullingData && cards.length > 5;

  const handleMouseEnter = (platformName, message) => {
    addTracking({
      event: FINANCIALS_ACTION.HOVER_TOOLTIP,
      card: "Upcoming Payments",
      name: platformName,
      state: message,
      pathname: location.pathname,
    });
  };

  const handleMouseLeave = () => {
    removeTracking();
  };

  return (
    <StyledUpcomingPayoutsContent ref={contentRef} className="up-cards-content">
      {showScroll && (
        <div
          className={classnames("up-cards-scroll-area up-cards-left", {
            "up-cards-scrolled-left": scrollPosition === "left",
          })}
          onClick={() => {
            const cardRefs = cardsRef.current.getElementsByClassName("up-card");
            const scrollAmount = contentRef.current.clientWidth;
            const currentScroll = wrapperRef.current.scrollLeft;
            let newScroll = currentScroll - scrollAmount;

            for (let i = 0; i < cardRefs.length; ++i) {
              if (cardRefs[i].offsetLeft > newScroll) {
                if (cardRefs[i - 1]) {
                  newScroll = cardRefs[i - 1].offsetLeft;
                  break;
                }
              }
            }

            wrapperRef.current.scrollTo({
              left: newScroll,
              behavior: "smooth",
            });
          }}
        >
          <ArrowIcon className="up-cards-prev" />
        </div>
      )}

      <div
        ref={wrapperRef}
        className="up-cards-wrapper"
        onScroll={(e) => {
          const scrollPos = e.currentTarget.scrollLeft;
          const rightBound = cardsRef.current.clientWidth - contentRef.current.clientWidth;
          if (scrollPos === 0) {
            setScrollPosition("left");
          } else if (scrollPos >= rightBound - 2) {
            setScrollPosition("right");
          } else {
            setScrollPosition(null);
          }
        }}
      >
        <div ref={cardsRef} className="up-cards">
          {cards.map((card, index) => {
            const {
              id,
              message,
              amount,
              inProgress,
              platform = {},
              periodDataSourceStatus,
              type,
              upcomingDate,
              periodDate,
              tooltipInfo,
              moneyIcon,
              filter,
            } = card;

            return (
              <Fragment key={`key_up_card_${id}_${index}`}>
                <Card
                // onClick={
                //   onCardSelect
                //     ? () => {
                //         onCardSelect(card);
                //       }
                //     : null
                // }
                >
                  <UpcomingPaymentCardMessage
                    id={`${id}_message`}
                    type={type}
                    isCurrent={inProgress}
                    filter={filter}
                    onMouseEnter={() => {
                      handleMouseEnter(platform.name, message);
                    }}
                    onMouseLeave={handleMouseLeave}
                  >
                    {inProgress ? <TimeIcon /> : <CheckCircleIcon />}
                    {message}
                  </UpcomingPaymentCardMessage>

                  <UpcomingPaymentCardPlatform>
                    <img
                      src={platform.icon || DEFAULT_IMAGE}
                      alt={platform.name}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = DEFAULT_IMAGE;
                      }}
                    />
                    <UpcomingPaymentCardAmount filter={filter} isCurrent={inProgress} type={type}>
                      {!isEmpty(filter) && "~"}
                      {amount}
                    </UpcomingPaymentCardAmount>
                  </UpcomingPaymentCardPlatform>

                  <UpcomingPaymentCardDate>
                    {moneyIcon}
                    {<span>{upcomingDate}</span>}
                  </UpcomingPaymentCardDate>
                </Card>
                <SanloTooltip
                  id={`up-card-${index}-tooltip`}
                  target={`${id}_message`}
                  text={
                    <DailyRevenueHeaderTooltip
                      tooltipInfo={tooltipInfo}
                      platformId={platform.id}
                      dataSourceStatus={periodDataSourceStatus}
                      trackData={{ name: `Upcoming Payments - ${platform.name} - ${message}` }}
                      pathname={location.pathname}
                      filter={filter}
                      periodDate={periodDate}
                      onMouseEnter={() => {
                        handleMouseEnter(platform.name, message);
                      }}
                      onMouseLeave={handleMouseLeave}
                    />
                  }
                  clickable={true}
                />
              </Fragment>
            );
          })}
        </div>
      </div>

      {showScroll && (
        <div
          className={classnames("up-cards-scroll-area up-cards-right", {
            "up-cards-scrolled-right": scrollPosition === "right",
          })}
          onClick={(e) => {
            const cardRefs = cardsRef.current.getElementsByClassName("up-card");
            const scrollAmount = contentRef.current.clientWidth;
            const currentScroll = wrapperRef.current.scrollLeft;
            let newScroll = currentScroll + scrollAmount;

            for (let i = 0; i < cardRefs.length; ++i) {
              if (cardRefs[i].offsetLeft > newScroll) {
                newScroll = cardRefs[i].offsetLeft;
                break;
              }
            }

            wrapperRef.current.scrollTo({
              left: newScroll,
              behavior: "smooth",
            });
          }}
        >
          <ArrowIcon className="up-cards-next" />
        </div>
      )}
    </StyledUpcomingPayoutsContent>
  );
};

export default UpcomingPayoutsContent;

const UpcomingPaymentCardMessage = styled(BodyMiniPlus)`
  height: 1.75rem;
  display: inline-flex;
  align-items: center;
  padding: 0 0.5rem;
  border-radius: 0.5rem;

  svg {
    height: 1rem;
    width: 1rem;
    margin-right: 0.5rem;
  }

  ${({ theme, type, isCurrent, filter }) =>
    type === "PAYOUT" &&
    isCurrent &&
    `
    color:  ${!isEmpty(filter) ? theme.colorYellow : theme.colorGreen};
    background-color: ${!isEmpty(filter) ? theme.colorDarkYellow : theme.colorDarkGreen};

    svg path {
      fill: ${!isEmpty(filter) ? theme.colorYellow : theme.colorGreen};
     }
`};

  ${({ theme, type, isCurrent }) =>
    type === "PAYOUT" &&
    !isCurrent &&
    `
    color: ${theme.colorGreen};
    background-color: ${theme.colorDarkGreen};
    svg path {
      fill: ${theme.colorGreen};
    }
  `};

  ${({ theme, type }) =>
    type === "EXPENSE" &&
    `
    color: ${theme.colorPurple};
    background-color: ${theme.colorDarkPurple};
    svg path {
      fill: ${theme.colorPurple};
    }
  `};
`;

const UpcomingPaymentCardPlatform = styled.div`
  display: flex;
  padding: 1.25rem 0 0.75rem 0;

  & img {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 0.25rem;
  }
`;

const UpcomingPaymentCardAmount = styled(TitleRegularPlus)`
  margin-left: 0.5rem;
  line-height: 1.5rem;
  ${({ theme, filter, type, isCurrent }) =>
    isEmpty(filter) &&
    `
    color: ${type === "PAYOUT" ? theme.colorGreen : theme.colorPurple};
    opacity: ${isCurrent ? "0.5" : "none"}
  `};
`;

const UpcomingPaymentCardDate = styled.div`
  font-size: 14px;
  font-weight: 400;
  span {
    ${BodySmall14CSS};
    color: #c5c5c5cc;
    padding-left: 0.25rem;
  }
`;
