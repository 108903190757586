import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import OnboardingStep3 from "./OnboardingStep3";

import useAccordion from "@utils/custom-hooks/useAccordion";
import { PRODUCT_ANALYTICS, BANKING } from "@utils/consts";
import { formatPlatformLinked, getConnectedIntegrations } from "../utils";
import useIntegrationsStatus from "@utils/custom-hooks/useIntegrationsStatus";
import { useLocation } from "react-router-dom";

const OnboardingStep3Container = () => {
  const location = useLocation();
  const isDashboard = location.pathname === "/dashboard" || location.pathname === "/";
  const integrationsStatus = useIntegrationsStatus();
  const platformsLinked = useSelector((state) => state.session.onboarding.data.platformsLinked);
  const platformsLinkedUpdated = useMemo(
    () => formatPlatformLinked(platformsLinked, integrationsStatus.banking.connected),
    [integrationsStatus.banking.connected, platformsLinked],
  );

  const [expandedAccordions, handleToggleAccordion] = useAccordion("");

  useEffect(() => {
    handleToggleAccordion(defaultExpandedAccordions(platformsLinkedUpdated), {
      isInitial: true,
    });
    // eslint-disable-next-line
  }, [platformsLinkedUpdated]);

  const defaultExpandedAccordions = (platformsLinked) => {
    if (!isDashboard) return "";
    const connectedIntegrations = getConnectedIntegrations(platformsLinked);
    // if nothing expand banking
    if (connectedIntegrations.none || !connectedIntegrations.banking) return BANKING;
    // if banking expand product analytics
    if (connectedIntegrations.banking && !connectedIntegrations.productAnalytics) return PRODUCT_ANALYTICS;
    // If all are connected collapse all
    return "";
  };

  return (
    <OnboardingStep3
      fromDashboard={isDashboard}
      connectedIntegrations={getConnectedIntegrations(platformsLinkedUpdated)}
      expandedAccordions={expandedAccordions}
      setExpandedAccordion={(category, isExpanded) => {
        handleToggleAccordion(category, {
          isExpanded,
        });
      }}
    />
  );
};

export default OnboardingStep3Container;
