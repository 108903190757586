export const NO_PERMISSIONS = "NO_PERMISSIONS";
export const NEEDS_RECONNECTION = "NEEDS_RECONNECTION";
export const INVALID_API_TOKEN = "INVALID_API_TOKEN";
export const INVALID_APP_ID = "INVALID_APP_ID";
export const CONNECTOR_ERROR = "CONNECTOR_ERROR";
export const PULLING_DATA = "PULLING_DATA";
export const VERIFYING_CONNECTION = "VERIFYING_CONNECTION";
export const CONNECTED = "CONNECTED";
export const DRAFT_CONNECTION = "DRAFT_CONNECTION";
export const NOT_CONNECTED = "NOT_CONNECTED";

export const STATUS_PRIORITIES = {
  NO_PERMISSIONS: 5,
  NEEDS_RECONNECTION: 5,
  INVALID_API_TOKEN: 5,
  INVALID_APP_ID: 5,
  CONNECTOR_ERROR: 4,
  PULLING_DATA: 3,
  VERIFYING_CONNECTION: 3,
  CONNECTED: 2,
  DRAFT_CONNECTION: 1,
  NOT_CONNECTED: 0,
};

export const sMatch = (status, checkList) => {
  if (status && typeof status === "object") {
    status = status.status;
  }
  if (!status) return false;
  if (!Array.isArray(checkList)) checkList = [checkList];
  for (let i = 0; i < checkList.length; ++i) {
    if (checkList[i] === status) return true;
  }
  return false;
};

export const sFilter = (list, checkList) => {
  if (!Array.isArray(list)) {
    console.log("sFilter >> list is not array");
    return;
  }
  if (!Array.isArray(checkList)) checkList = [checkList];
  return list.filter((i) => checkList.includes(i.status));
};

export const sFirst = (list, checkList) => {
  if (!Array.isArray(list)) {
    console.log("sFirst >> list is not array");
    return;
  }
  if (!Array.isArray(checkList)) checkList = [checkList];
  return list.find((i) => checkList.includes(i.status));
};

export const sIgnore = (list, checkList) => {
  if (!Array.isArray(list)) {
    console.log("sIgnore >> list is not array");
    return;
  }
  if (!Array.isArray(checkList)) checkList = [checkList];
  return list.filter((i) => !checkList.includes(i.status));
};

// Helpers I may not really need

export const isPullingStatus = (platform) => {
  return sMatch(platform, [PULLING_DATA, VERIFYING_CONNECTION]);
};

export const isNoLiveAppsStatus = (platform) => {
  if (!platform.status) return false;
  return platform.status === CONNECTED && !platform.earnings;
};

export const isReconnectionStatus = (platform) => {
  return sMatch(platform, [NEEDS_RECONNECTION, INVALID_API_TOKEN, INVALID_APP_ID]);
};

export const isConnectedStatus = (platform) => {
  return sMatch(platform, [CONNECTED]);
};

export const isNotConnectedStatus = (platform) => {
  return sMatch(platform, [NOT_CONNECTED]);
};

export const isErrorStatus = (platform) => {
  return sMatch(platform, [CONNECTOR_ERROR]);
};

export const isNoPermissionStatus = (platform) => {
  return sMatch(platform, [NO_PERMISSIONS]);
};

export const isPendingStatus = (platform) => {
  return sMatch(platform, [PULLING_DATA, VERIFYING_CONNECTION, NO_PERMISSIONS]);
};

export const getMostPrioritized = (statuses) => {
  let withPriority = {};
  statuses.forEach((status) => (withPriority[status] = STATUS_PRIORITIES[status]));
  return Object.keys(withPriority).reduce(
    (previous, current) => (STATUS_PRIORITIES[current] > STATUS_PRIORITIES[previous] ? current : previous),
    NOT_CONNECTED,
  );
};

export const getPriorityStatus = (status1, status2) => {
  if (status2 === NOT_CONNECTED) return NOT_CONNECTED;
  let newStatus = NOT_CONNECTED;
  let status1pri = STATUS_PRIORITIES[status1];
  let status2pri = STATUS_PRIORITIES[status2];
  if (typeof status1pri !== "number") status1pri = -1;
  if (typeof status2pri !== "number") status2pri = -1;
  if (status1pri < 0 && status2pri < 0) {
    return newStatus;
  }
  if (status1pri >= status2pri) {
    newStatus = status1;
  } else {
    newStatus = status2;
  }
  return newStatus;
};
