import HTTP from "@redux/http";

const SANLO_WEB_API_URL = process.env.REACT_APP_SANLO_WEB_API_URL;
const BASE = `${SANLO_WEB_API_URL}/api/fairground/console/webshops`;

class SectionsService {
  createSection(data, webshopId) {
    return HTTP.req.post(`${BASE}/${webshopId}/storefront/sections`, data);
  }

  updateSection(data, webshopId, sectionId) {
    return HTTP.req.put(`${BASE}/${webshopId}/storefront/sections/${sectionId}`, data);
  }

  deleteSection(data, webshopId, sectionId) {
    return HTTP.req.delete(`${BASE}/${webshopId}/storefront/sections/${sectionId}`, {
      data,
    });
  }

  createSectionFile(data, webshopId, sectionId) {
    return HTTP.req.post(`${BASE}/${webshopId}/storefront/sections/${sectionId}/file`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  updateSectionFile(data, webshopId, sectionId) {
    return HTTP.req.put(`${BASE}/${webshopId}/storefront/sections/${sectionId}/file`, data);
  }

  deleteSectionFile(data, webshopId, sectionId) {
    return HTTP.req.delete(`${BASE}/${webshopId}/storefront/sections/${sectionId}/file`, {
      data,
    });
  }

  createSectionThemeOverride(data, webshopId, sectionId) {
    return HTTP.req.put(`${BASE}/${webshopId}/storefront/sections/${sectionId}/theme`, data);
  }

  deleteSectionThemeOverride(webshopId, sectionId) {
    return HTTP.req.delete(`${BASE}/${webshopId}/storefront/sections/${sectionId}/theme`);
  }
}

export default new SectionsService();
