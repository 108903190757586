import SanloWebApiService from "./services";

import actions from "@redux/actions";

import appsFlyersActions, {
  APPSFLYER_GET_DRAFT_CREDENTIALS,
  APPSFLYER_SAVE_DRAFT_CREDENTIALS,
  APPSFLYER_SAVE_CREDENTIALS,
  APPSFLYER_TEST_CREDENTIALS,
  APPSFLYER_TEST_CREDENTIALS_ERRORS,
  APPSFLYER_GET_UA_SPEND,
  APPSFLYER_REFRESH_UA,
  APPSFLYER_UNLINK_INTEGRATION,
} from "./actions";

import { showToast } from "@utils/redux";

import { getHighestPriorityStatus } from "@utils/integrations";

const appsFlyerMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    switch (action.type) {
      case APPSFLYER_GET_DRAFT_CREDENTIALS.REQUEST:
        SanloWebApiService.appsFlyerGetDraftCredentials()
          .then((response) => response.data)
          .then((data) => {
            dispatch(appsFlyersActions.getDraftCredentials.response(data));
          })
          .catch((error) => {
            dispatch(appsFlyersActions.getDraftCredentials.error(error));
          });
        break;

      case APPSFLYER_SAVE_DRAFT_CREDENTIALS.REQUEST:
        SanloWebApiService.appsFlyerSaveDraftCredentials(action.credentials)
          .then(() => {
            dispatch(appsFlyersActions.saveDraftCredentials.response(action.credentials));
            if (action.credentials.apiToken) {
              showToast("Your AppsFlyer connection progress was saved! ");
            }
          })
          .catch((error) => {
            dispatch(appsFlyersActions.saveDraftCredentials.error(error));
          });
        break;

      case APPSFLYER_SAVE_CREDENTIALS.REQUEST:
        SanloWebApiService.appsFlyerSaveCredentials(action.credentials)
          .then((response) => response.data)
          .then((response) => {
            dispatch(appsFlyersActions.saveCredentials.response(response));
            dispatch(appsFlyersActions.getUASpend.request());
            dispatch(actions.integrations.base.getIntegrationsConnections.request());

            if (action.callback) action.callback();
            if (action.toggleStep) action.toggleStep(-1);

            // This can just check the highest status for showing the toast
            // we don't need to the fully parse integration state for this
            const status = getHighestPriorityStatus(response);
            if (status === "NO_PERMISSIONS") {
              showToast("AppsFlyer is connected but we're missing some data", "warning", "noPermissions");
            } else {
              showToast("You have successfully connected to your AppsFlyer account!");
            }
          })
          .catch((error) => {
            dispatch(appsFlyersActions.saveCredentials.error(error));
          });
        break;

      case APPSFLYER_REFRESH_UA.REQUEST:
        SanloWebApiService.appsFlyerRefreshCompanyUA()
          .then((response) => response.data)
          .then((response) => {
            dispatch(appsFlyersActions.refreshUA.response(response));
            dispatch(appsFlyersActions.getUASpend.request());
          })
          .catch((error) => {
            dispatch(appsFlyersActions.refreshUA.error(error));
          });
        break;

      case APPSFLYER_GET_UA_SPEND.REQUEST:
        SanloWebApiService.appsFlyerUASpend()
          .then((response) => {
            dispatch(appsFlyersActions.getUASpend.response(response.data || {}));
          })
          .catch((error) => {
            dispatch(appsFlyersActions.getUASpend.error(error));
          });
        break;

      case APPSFLYER_TEST_CREDENTIALS.REQUEST:
        SanloWebApiService.appsFlyerTestApiToken(action.apiToken)
          .then((response) => {
            dispatch(appsFlyersActions.testCredentials.response(response));
            if (action.callback) action.callback(1);
          })
          .catch((error) => {
            dispatch(appsFlyersActions.testCredentials.error(error));
          });
        break;

      case APPSFLYER_UNLINK_INTEGRATION.REQUEST:
        SanloWebApiService.appsFlyerUnlinkIntegration()
          .then(() => {
            dispatch(appsFlyersActions.unlinkIntegration.response());
            dispatch(actions.integrations.base.getIntegrationsConnections.request());
            if (action.callback) action.callback();
            showToast("You have successfully disconnected AppsFlyer!");
          })
          .catch((error) => {
            if (action.callback) action.callback();
            showToast("An error has occurred trying to disconnect Appsflyer. Please, try again.", "error");
            dispatch(appsFlyersActions.unlinkIntegration.error(error));
          });
        break;

      case APPSFLYER_TEST_CREDENTIALS_ERRORS.CLEAR:
        dispatch(appsFlyersActions.unlinkIntegration.request());
        break;

      default:
        return;
    }
  };

export default appsFlyerMiddleware;
