import React from "react";
import classnames from "classnames";
import styled from "styled-components";

import { UncontrolledTooltip } from "reactstrap";
import ErrorBoundary from "@components/ErrorBoundary";

const SanloTooltipHeading = styled.h1(({ theme }) => ({
  borderBottom: `1px solid ${theme.grayscale50}`,
  backgroundColor: theme.grayscale40,
  fontWeight: "500",
  fontSize: "14px",
  lineHeight: "16px",
  color: "white",
  padding: "0px 0px 10px 0px",
  margin: "0px 0px 10px 0px",
}));

const StyledTooltip = styled(UncontrolledTooltip)`
  position: relative;
  z-index: 1001;

  .tooltipInner {
    padding: 12px !important;
    box-shadow: 0px 0px 27px 7px rgba(0, 0, 0, 0.15);
    background-color: #2a2a2f !important;
    border-radius: 12px !important;
    color: #c5c5c5;
    text-align: left !important;

    .ce-info-tooltip {
      color: #faf8f8;
      text-decoration: underline;
    }

    .ce-info-tooltip:hover {
      color: #ff5c79;
    }
  }

  .jumbo {
    min-width: 300px;
    max-width: 480px !important;

    & > :last-child {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  .resetBottom > :last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
`;

export const SanloTooltip = ({
  id,
  ref = null,
  width,
  maxWidth,
  innerClassName,
  jumbo,
  target,
  text,
  heading,
  placement = "right",
  trigger = "hover",
  clickable = true,
  resetBottom = false,
  children,
  onMouseEnter = () => {},
  onMouseLeave = () => {},
  ...rest
}) => {
  // We only use dark theme at the moment
  // const theme = window.localStorage.getItem('theme');

  // 200ms is a fair amount of time to hover the tooltip.
  // the 100ms on show prevents tooltips being shown instantly if the user
  // mouses over a tooltip element while just moving the mouse

  const styles = {};
  if (maxWidth) styles.maxWidth = `${maxWidth}px`;
  if (width) styles.width = Number(width) ? `${Number(width)}px` : width;

  return (
    <ErrorBoundary name={`tooltip-error-boundary-${id}`}>
      <StyledTooltip
        id={id}
        ref={ref}
        innerClassName={classnames("tooltipInner", {
          [innerClassName]: Boolean(innerClassName),
          jumbo: jumbo,
          resetBottom: resetBottom,
        })}
        style={styles}
        delay={!clickable ? 0 : { show: 100, hide: 200 }}
        placement={placement}
        target={target}
        autohide={!clickable}
        hideArrow={true}
        trigger={trigger}
        flip={true}
        boundariesElement={"window"}
        {...rest}
      >
        <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          {heading && <SanloTooltipHeading>{heading}</SanloTooltipHeading>}
          {text && text}
          {children && children}
        </div>
      </StyledTooltip>
    </ErrorBoundary>
  );
};
