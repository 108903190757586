import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { checkLoading, getResponseStatus, getRequestStatus, getErrorStatus, setStatusDefaults } from "@utils/redux";
import { catalogThunks } from "@redux/webshops/catalog/catalogSlice";

import AssociationsService from "./associationsApi";

export const associationsThunks = {
  getAssociations: createAsyncThunk("webshops/getAssociations", async (webshopId, { rejectWithValue, getState }) => {
    try {
      const {
        webshops: {
          webshops: { selectedWebshop },
        },
      } = getState();
      const id = webshopId || selectedWebshop.id;
      const res = await AssociationsService.getAssociations(id);
      // return res.data;
      // return webshopId with the data
      return { webshopId: id, data: res.data };
    } catch (e) {
      return rejectWithValue({ error: e.response.data });
    }
  }),
  createCategory: createAsyncThunk("webshops/createCategory", async (payload, { rejectWithValue, getState }) => {
    try {
      const {
        webshops: {
          webshops: { selectedWebshop },
        },
      } = getState();
      const res = await AssociationsService.createCategory(payload, selectedWebshop.id);
      return res.data;
    } catch (e) {
      return rejectWithValue({ error: e.response.data });
    }
  }),
  deleteCategory: createAsyncThunk("webshops/deleteCategory", async (payload, { rejectWithValue, getState }) => {
    try {
      const {
        webshops: {
          webshops: { selectedWebshop },
        },
      } = getState();
      await AssociationsService.deleteCategory(payload, selectedWebshop.id);
      return payload;
    } catch (e) {
      return rejectWithValue({ error: e.response.data });
    }
  }),
  editCategory: createAsyncThunk("webshops/editCategory", async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { offerSeries, ...rest } = payload;
      const {
        webshops: {
          webshops: { selectedWebshop },
        },
      } = getState();
      const res = await AssociationsService.editCategory(rest, selectedWebshop.id);
      const getCatalog = offerSeries ? catalogThunks.getCatalogFromWebshopV2 : catalogThunks.getCatalogFromWebshop;
      dispatch(getCatalog(selectedWebshop.id));

      return res.data;
    } catch (e) {
      return rejectWithValue({ error: e.response.data });
    }
  }),
  createBadge: createAsyncThunk("webshops/createBadge", async (payload, { rejectWithValue, getState }) => {
    try {
      const {
        webshops: {
          webshops: { selectedWebshop },
        },
      } = getState();
      const res = await AssociationsService.createBadge(payload, selectedWebshop.id);
      return res.data;
    } catch (e) {
      return rejectWithValue({ error: e.response.data });
    }
  }),
  editBadge: createAsyncThunk("webshops/editBadge", async (payload, { rejectWithValue, getState }) => {
    try {
      const {
        webshops: {
          webshops: { selectedWebshop },
        },
      } = getState();
      const res = await AssociationsService.editBadge(payload, selectedWebshop.id);
      return res.data;
    } catch (e) {
      return rejectWithValue({ error: e.response.data });
    }
  }),
};

export const associationsSlice = createSlice({
  name: "associations",
  initialState: {
    segments: {
      list: [],
      bySegmentId: {},
    },
    associations: {
      data: null,
      requestStatus: setStatusDefaults(),
    },
    createCategory: {
      data: null,
      requestStatus: setStatusDefaults(),
    },
    deleteCategory: {
      data: null,
      requestStatus: setStatusDefaults(),
    },
    editCategory: {
      data: null,
      requestStatus: setStatusDefaults(),
    },
    createBadge: {
      data: null,
      requestStatus: setStatusDefaults(),
    },
    editBadge: {
      data: null,
      requestStatus: setStatusDefaults(),
    },
    associationsByWebshop: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      /*******************************************************************************
       * Get Associations From Webshop
       *******************************************************************************/
      .addCase(associationsThunks.getAssociations.pending, (state) => {
        state.associations.requestStatus = getRequestStatus();
      })
      .addCase(associationsThunks.getAssociations.fulfilled, (state, action) => {
        const { payload = {} } = action;
        state.associations.data = payload.data;
        const newSegments = payload.data.playerSegments;
        if (newSegments && newSegments.length) {
          state.segments.list = newSegments;
          payload.data.playerSegments.forEach((segment) => {
            if (!segment || !segment.segmentId) return;
            state.segments.bySegmentId[segment.segmentId] = segment;
          });
        }
        // Store the data per webshop id
        state.associationsByWebshop[payload.webshopId] = payload.data;
        state.associations.requestStatus = getResponseStatus();
      })
      .addCase(associationsThunks.getAssociations.rejected, (state) => {
        state.associations.requestStatus = getErrorStatus();
      })
      /*******************************************************************************
       * Create Category For Webshop
       *******************************************************************************/
      .addCase(associationsThunks.createCategory.pending, (state) => {
        state.createCategory.requestStatus = getRequestStatus();
      })
      .addCase(associationsThunks.createCategory.fulfilled, (state, action) => {
        const { payload = {} } = action;
        state.createCategory.data = payload;
        state.associations.data.categories = [...state.associations.data.categories, payload];
        state.createCategory.requestStatus = getResponseStatus();
      })
      .addCase(associationsThunks.createCategory.rejected, (state) => {
        state.createCategory.requestStatus = getErrorStatus();
      })
      /*******************************************************************************
       * Delete Category For Webshop
       *******************************************************************************/
      .addCase(associationsThunks.deleteCategory.pending, (state) => {
        state.deleteCategory.requestStatus = getRequestStatus();
      })
      .addCase(associationsThunks.deleteCategory.fulfilled, (state, action) => {
        const { payload = {} } = action;
        state.deleteCategory.data = payload;

        const categories = [...state.associations.data.categories];
        const removedCategoryIndex = categories.findIndex((category) => category.id === payload);
        if (removedCategoryIndex > -1) {
          categories.splice(removedCategoryIndex, 1);
        }

        state.associations.data.categories = categories;
        state.deleteCategory.requestStatus = getResponseStatus();
      })
      .addCase(associationsThunks.deleteCategory.rejected, (state) => {
        state.deleteCategory.requestStatus = getErrorStatus();
      })
      /*******************************************************************************
       * Edit Category For Webshop
       *******************************************************************************/
      .addCase(associationsThunks.editCategory.pending, (state) => {
        state.editCategory.requestStatus = getRequestStatus();
      })
      .addCase(associationsThunks.editCategory.fulfilled, (state, action) => {
        const { payload = {} } = action;
        state.editCategory.data = payload;

        const categories = [...state.associations.data.categories];
        const updatedCategoryIndex = categories.findIndex((category) => category.id === payload.id);
        categories[updatedCategoryIndex] = payload;
        state.associations.data.categories = categories;
        state.editCategory.requestStatus = getResponseStatus();
      })
      .addCase(associationsThunks.editCategory.rejected, (state) => {
        state.editCategory.requestStatus = getErrorStatus();
      })

      /*******************************************************************************
       * Create Badge For Webshop
       *******************************************************************************/
      .addCase(associationsThunks.createBadge.pending, (state) => {
        state.createBadge.requestStatus = getRequestStatus();
      })
      .addCase(associationsThunks.createBadge.fulfilled, (state, action) => {
        const { payload = {} } = action;
        state.createBadge.data = payload;
        state.associations.data.badges = [...state.associations.data.badges, payload];
        state.createBadge.requestStatus = getResponseStatus();
      })
      .addCase(associationsThunks.createBadge.rejected, (state) => {
        state.createBadge.requestStatus = getErrorStatus();
      })
      /*******************************************************************************
       * Edit Badge For Webshop
       *******************************************************************************/
      .addCase(associationsThunks.editBadge.pending, (state) => {
        state.editBadge.requestStatus = getRequestStatus();
      })
      .addCase(associationsThunks.editBadge.fulfilled, (state, action) => {
        const { payload = {} } = action;
        state.editBadge.data = payload;

        const badges = [...state.associations.data.badges];
        const updatedBadgeIndex = badges.findIndex((badge) => badge.id === payload.id);
        badges[updatedBadgeIndex] = payload;
        state.associations.data.badges = badges;
        state.editBadge.requestStatus = getResponseStatus();
      })
      .addCase(associationsThunks.editBadge.rejected, (state) => {
        state.editBadge.requestStatus = getErrorStatus();
      });
  },
});

export const associationsLoadingSelector = (state) => checkLoading(state.webshops.associations.associations);
export const categoriesSelector = (state) => state.webshops.associations.associations.data?.categories;
export const segmentsSelector = (state) => state.webshops.associations.segments;
export const badgesSelector = (state) => state.webshops.associations.associations.data?.badges;

export default associationsSlice.reducer;
