import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withSectionData } from "../withSectionData";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  HStack,
  Input,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  VStack,
  FormControl,
  Icon,
  IconButton,
  useClipboard,
} from "@chakra-ui/react";
import styled from "styled-components";
import { Reorder, useDragControls } from "framer-motion";

import { StylePicker } from "../../StylePicker";

import { createSectionLink, generatePayloads } from "./utils";
import { BackgroundStylePicker } from "../BackgroundStylePicker";
import { DragHandle } from "@components/builder/DragHandle";
import { PropertiesHeader, SectionWrapper, COLOR_TYPES } from "@utils/webshop/section";

import { ReactComponent as ChevronDownIcon } from "@assets/img/icons/common/chevron-down.svg";
import { useSectionUpdate } from "@src/utils/custom-hooks/useSectionUpdate";
import { toKebabCase } from "@src/utils/string";
import { ReactComponent as CopyIcon } from "@src/assets/img/icons/common/copy.svg";
import { useFlags } from "launchdarkly-react-client-sdk";

const StyledDragAndDrop = styled(Reorder.Group)`
  width: 100%;
`;

// Had trouble with the chakra wrapper, so I used styled-components
// TODO: Change to chakra
const StyledDragAndDropItem = styled(Reorder.Item)`
  /* margin-top: 0.5rem; */
  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`;

const ItemsPropertiesComponent = ({
  data,
  storefront,
  categories,
  catalog,
  webshop,
  theme,
  onFileCreate,
  onFileUpdate,
  onFileRemove,
  onThemeChange,
  onThemeReset,
  onChange,
}) => (
  <ItemsProperties
    data={data}
    storefront={storefront}
    categories={categories}
    catalog={catalog}
    webshop={webshop}
    theme={theme}
    onFileCreate={onFileCreate}
    onFileUpdate={onFileUpdate}
    onFileRemove={onFileRemove}
    onThemeChange={onThemeChange}
    onThemeReset={onThemeReset}
    onChange={onChange}
  />
);

ItemsPropertiesComponent.propTypes = {
  data: PropTypes.object,
  storefront: PropTypes.object,
  categories: PropTypes.array,
  catalog: PropTypes.object,
  webshop: PropTypes.object,
  theme: PropTypes.object,
  onFileCreate: PropTypes.func,
  onFileUpdate: PropTypes.func,
  onFileRemove: PropTypes.func,
  onThemeChange: PropTypes.func,
  onThemeReset: PropTypes.func,
  onChange: PropTypes.func,
};
const useItemsSectionUpdate = (webshopId, sectionId, data) => {
  const [onUpdate] = useSectionUpdate(webshopId, sectionId, data, generatePayloads);
  return { onChange: onUpdate };
};

export const ItemsPropertiesContainer = withSectionData(ItemsPropertiesComponent, useItemsSectionUpdate);

export const ItemsProperties = ({
  data = {},
  storefront = {},
  categories = [],
  catalog = {},
  webshop = {},
  theme = {},
  onChange = () => {},
  onFileCreate = () => {},
  onFileUpdate = () => {},
  onFileRemove = () => {},
  onThemeChange = () => {},
  onThemeReset = () => {},
}) => {
  const { offerSeries } = useFlags();
  const [title, setTitle] = useState(() => data?.title || "");
  const [itemIds, setItemIds] = useState([]);
  const [itemMap, setItemMap] = useState({});
  const navigate = useNavigate();
  const { setValue, onCopy, hasCopied } = useClipboard();

  // A list of categories to choose from and their items
  const [categoryOptions, setCategoryOptions] = useState([]);
  const itemsLength = (offerSeries ? catalog.unassignedItems?.data.length : catalog.data?.items.length) || 0;

  useEffect(() => {
    const url = createSectionLink(webshop.domain, title);
    if (url) setValue(url);
    return () => {
      setValue("");
    };
  }, [setValue, title, webshop.domain]);

  useEffect(() => {
    if (data?.items) {
      setItemIds(data?.items.map((item) => item.id));
      setItemMap(
        data?.items.reduce((acc, item) => {
          acc[item.id] = item;
          return acc;
        }, {}),
      );
    }
  }, [data]);

  useEffect(() => {
    // Set the category options
    const options = categories.map((category) => {
      const itemsCatalog = offerSeries ? catalog.unassignedItems?.data : catalog.data?.items;
      const items = itemsCatalog.filter((item) => {
        // Filter out items that no category
        if (!item.category) return false;
        // Filter out items that are not active
        if (!item.available) return false;
        // TODO: filter out items that have no quantity?
        // TODO: filter out items based on time availability?
        return item.category.id === category.id;
      });
      return {
        ...category,
        items,
        itemIds: items.map((item) => item.id),
      };
    });
    setCategoryOptions(options);
  }, [categories, catalog, offerSeries]);

  return (
    <Box>
      <PropertiesHeader />

      <SectionWrapper title="Styles" onReset={onThemeReset}>
        <StylePicker
          target="items_theme_primary"
          title="Primary"
          presets={theme.presets}
          initialColor={theme.primary}
          onColorChange={(colorObj) => {
            onThemeChange({
              ...colorObj,
              targetColor: COLOR_TYPES.PRIMARY,
            });
          }}
        />
        <StylePicker
          target="items_theme_secondary"
          title="Secondary"
          presets={theme.presets}
          initialColor={theme.secondary}
          onColorChange={(colorObj) => {
            onThemeChange({
              ...colorObj,
              targetColor: COLOR_TYPES.SECONDARY,
            });
          }}
        />
        <BackgroundStylePicker
          checkLegacyBg
          image
          target="items_theme_background"
          theme={theme}
          data={data}
          onThemeChange={onThemeChange}
          onFileCreate={onFileCreate}
          onFileUpdate={onFileUpdate}
          onFileRemove={onFileRemove}
        />
      </SectionWrapper>

      <SectionWrapper title="Title">
        <Box padding="12px">
          <FormControl>
            <Input
              value={title}
              placeholder="Catalog Section"
              onChange={(event) => {
                onChange({
                  title: event.target.value,
                });
                setTitle(event.target.value);
              }}
            />
          </FormControl>
        </Box>

        <StylePicker
          target={`items_${data.id}_title_color`}
          title="Color"
          presets={theme.presets}
          initialColor={theme.title}
          onColorChange={(colorObj) => {
            onThemeChange({
              ...colorObj,
              targetColor: COLOR_TYPES.TITLE,
            });
          }}
        />

        {title && (
          <Box p={"12px"}>
            <Text color={"textDefault.400"} mb="12px">
              Anchor link
            </Text>
            <HStack gap={"12px"} alignItems={"center"} p={"8px"} bg="dark.400" borderRadius={"10px"}>
              <Text flex={1} noOfLines={1} color={"textSubtext.400"}>
                {!hasCopied ? `#${toKebabCase(title)}` : "Copied!"}
              </Text>
              <IconButton
                aria-label="copy link"
                variant={"unstyled"}
                size={"sm"}
                icon={<Icon as={CopyIcon} />}
                onClick={onCopy}
              />
            </HStack>
          </Box>
        )}
      </SectionWrapper>

      <SectionWrapper title="Category">
        <Box padding="12px">
          {itemsLength === 0 && (
            <VStack borderRadius={"10px"} border={"1px solid"} borderColor={"dark.400"} p={"8px"} gap={"8px"}>
              <Text p={"8px 0px"}>Your catalog is empty.</Text>
              <Button
                borderRadius={"8px"}
                border={"1px solid"}
                borderColor={"#FAF8F81A"}
                height={"32px"}
                p={"8px 12px"}
                w={"full"}
                onClick={() => navigate(`/webshops/projects/${webshop.id}/catalog`)}
              >
                Add items &rarr;
              </Button>
            </VStack>
          )}
          {itemsLength > 0 && categories.length === 0 && <Text>No categories</Text>}
          {itemsLength > 0 && categories.length > 0 && (
            <Menu width="100%">
              {({ isOpen }) => (
                <>
                  <MenuButton
                    as={Button}
                    width="100%"
                    textAlign="left"
                    color="rgba(255, 255, 255, 0.5)"
                    rightIcon={<ChevronDownIcon />}
                    bg="rgba(255, 255, 255, 0.075) !important"
                    border={isOpen ? `1px solid var(--chakra-colors-brandRed-400)` : "1px solid transparent"}
                  >
                    Select
                  </MenuButton>
                  <MenuList>
                    {categoryOptions.map((category) => (
                      <MenuItem
                        key={category.id}
                        bg="theme.dark.700"
                        onClick={() => {
                          const data = {
                            items: category.items,
                            itemIds: category.itemIds,
                          };
                          if (!title && category.name) {
                            data.title = category.name;
                          }
                          onChange(data);
                        }}
                      >
                        {category.name} ({category.items.length})
                      </MenuItem>
                    ))}
                  </MenuList>
                </>
              )}
            </Menu>
          )}

          {Boolean(itemIds.length) && (
            <VStack
              width="100%"
              padding="8px"
              borderRadius="10px"
              border="var(--border-dark-300)"
              marginTop="12px"
              style={{ userSelect: "none" }} // Disable user selection
            >
              <StyledDragAndDrop
                values={itemIds}
                onReorder={(newOrder) => {
                  setItemIds(newOrder);
                  onChange({
                    itemIds: newOrder,
                  });
                }}
              >
                {itemIds.map((itemId, index) => {
                  return <ListItem key={`key_${itemId}__item_properties_list`} item={itemMap[itemId]} />;
                })}
              </StyledDragAndDrop>
            </VStack>
          )}
        </Box>
      </SectionWrapper>
    </Box>
  );
};

ItemsProperties.propTypes = {
  data: PropTypes.object,
  storefront: PropTypes.object,
  categories: PropTypes.array,
  catalog: PropTypes.object,
  webshop: PropTypes.object,
  theme: PropTypes.object,
  onChange: PropTypes.func,
  onFileCreate: PropTypes.func,
  onFileUpdate: PropTypes.func,
  onFileRemove: PropTypes.func,
  onThemeChange: PropTypes.func,
  onThemeReset: PropTypes.func,
};

const ListItem = ({ item }) => {
  const controls = useDragControls();

  return (
    <StyledDragAndDropItem value={item.id} dragListener={false} dragControls={controls}>
      <HStack
        key={item.id}
        bg="dark.600"
        p="0 0 0 12px"
        borderRadius="0.625rem"
        border="1px solid rgba(255, 255, 255, 0.1)"
        boxSizing="border-box"
        position="relative"
        justifyContent="space-between"
      >
        <Text width="80%" whiteSpace={"nowrap"} overflow={"hidden"} textOverflow={"ellipsis"}>
          {item.name}
        </Text>
        <DragHandle controls={controls} />
      </HStack>
    </StyledDragAndDropItem>
  );
};

ListItem.propTypes = {
  item: PropTypes.object,
};
