import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";

import { manualConnectionThunks, manualConnectionSelector } from "@redux/integrations/base/manualSlice";

import { StyledConnectionFlow } from "@components/integrations/StyledConnectionFlow";

import { FirebaseStep1 } from "./FirebaseStep1";

import FadeStepsContainer from "@common/animated/FadeStepsContainer";

import { trackGTMEvent, COMMON_ACTION, manualIntegrationTag } from "@utils/gtm";
import { PermissionCompleted } from "@components/integrations/connection-guide";

export const FirebaseConnectSetup = ({
  platform = {
    id: "firebase",
    name: "Firebase",
    platformId: "firebase",
  },
  stepNbr = 0,
  stepData = {},
  goToNextStep = () => {},
  goToStep = () => {},
  closeModal = () => {},
}) => {
  const dispatch = useDispatch();
  const { isSetupDone = false } = stepData;

  const { emailMap, allConnectionsMap, connectionStatusMap } = useSelector(manualConnectionSelector);
  const manualConnectionStatus = connectionStatusMap[platform.platformId];

  useEffect(() => {
    const connection = allConnectionsMap[platform.platformId];
    if (!connection) {
      dispatch(manualConnectionThunks.createConnection(platform.platformId));
    } else {
      dispatch(manualConnectionThunks.getEmail(platform.platformId));
    }
    // eslint-disable-next-line
  }, [allConnectionsMap]);

  useEffect(() => {
    trackEvent(COMMON_ACTION.COMPLETE);
    if (manualConnectionStatus === "error") {
      goToStep(1, {
        closeAfter: true,
      });
    }
    if (manualConnectionStatus === "complete") {
      goToNextStep();
    }
    // eslint-disable-next-line
  }, [manualConnectionStatus]);

  const trackEvent = (action) => {
    trackGTMEvent({
      event: manualIntegrationTag(platform, action),
    });
  };

  return (
    <StyledConnectionFlow
      className={classnames("fs-modal-content", {
        "done-step": isSetupDone,
        "align-left": isSetupDone,
      })}
    >
      <FadeStepsContainer
        step={stepNbr}
        stepComponents={[
          // Manual Connection Step 1
          <FirebaseStep1
            key="FirebaseStep1"
            email={emailMap[platform.platformId]}
            onBack={() => {
              trackEvent(COMMON_ACTION.GO_BACK);
              goToStep(1);
            }}
            onClose={() => {
              trackEvent(COMMON_ACTION.CLOSE);
              closeModal();
            }}
            onComplete={() => {
              dispatch(manualConnectionThunks.completeConnection(platform.platformId));
            }}
            trackEvent={trackEvent}
          />,

          // Complete
          <PermissionCompleted key="PermissionCompleted" handleDone={closeModal} />,
        ]}
      />
    </StyledConnectionFlow>
  );
};
