import { tagAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tagAnatomy.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  container: {
    bg: "rgba(33, 33, 38, 0.78)",
    color: "textWhite.400",
    borderRadius: "full",
    border: "1px solid transparent",
    borderColor: "dark.400",
    height: "24px",
  },
  label: {},
  closeButton: {},
});

const md = defineStyle({
  py: "4px",
  fontSize: "sm",
});

export const Tag = defineMultiStyleConfig({ baseStyle, sizes: { md }, defaultProps: { size: "md" } });
