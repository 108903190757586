import { TYPOGRAPHY_FONT_TYPES } from "@src/consts/webshops/builder";
import { useEffect } from "react";

export function useSetCustomFonts(typography = {}) {
  const { headline: headlineFont, regular: regularFont } = typography;

  useEffect(() => {
    if (!headlineFont && !regularFont) return;

    const fontLinks = [];
    const fontStyleElement = document.createElement("style");

    const handleFont = (font) => {
      if (font?.source === TYPOGRAPHY_FONT_TYPES.THIRD_PARTY) {
        const link = document.createElement("link");
        link.rel = "stylesheet";
        link.href = font.url;
        fontLinks.push(link);
        document.head.appendChild(link);
      } else if (font?.source === TYPOGRAPHY_FONT_TYPES.CUSTOM) {
        const customFontFace = `
          @font-face {
            font-family: '${font.name}';
            src: url('${font.url}');
          }
        `;
        fontStyleElement.appendChild(document.createTextNode(customFontFace));
      }
    };

    if (headlineFont) {
      handleFont(headlineFont);
    }

    if (regularFont) {
      handleFont(regularFont);
    }

    if (fontStyleElement.textContent) {
      document.head.appendChild(fontStyleElement);
    }

    return () => {
      fontLinks.forEach((link) => document.head.removeChild(link));
      if (document.head.contains(fontStyleElement)) {
        document.head.removeChild(fontStyleElement);
      }
    };
  }, [headlineFont, regularFont]);

  return { headlineFont, regularFont };
}
