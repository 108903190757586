import React from "react";
import moment from "moment";
import styled from "styled-components";
import classnames from "classnames";
import { useFlags } from "launchdarkly-react-client-sdk";

import { BodyRegular16, BodyRegularPlus, TitleLargePlus } from "@styled";
import TooltipRow from "@common/tooltips/data-tooltip/TooltipRow";

import { currencyFormat } from "@utils/currency";
import { parseDataSourceStatus } from "../../utils/dailyRevenueUtils";
import tooltipsCopies from "@utils/text/cashEarningsChart";

import { ReactComponent as PeriodArrowIcon } from "@assets/img/icons/common/arrow_left.svg";

import { SanloTooltip } from "@components/common/tooltips/sanlo-tooltip/SanloTooltip";

export const StyledPaydateTooltip = styled.div`
  overflow: hidden;
  width: 310px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 24px;
  border-radius: 12px;
  backdrop-filter: blur(64px);
  color: ${({ theme }) => theme.grayscale0};

  background: linear-gradient(101.25deg, #2dfab0 49.84%, #6ffa2d 100%);
  &.tt-paydate-current-period {
    background: linear-gradient(101.25deg, #ffd836 49.84%, #7df72e 100%);
  }

  .tt-paydate-paid-on {
    white-space: nowrap;
  }

  .tt-paydate-amount {
    margin-top: 4px;
    margin-bottom: 16px;
  }

  .tt-paydate-period-picker {
    display: flex;
    white-space: nowrap;

    :hover {
      cursor: pointer;
    }

    .previous-period {
      margin-right: 8px;
    }

    .future-period {
      margin-left: 8px;
      transform: rotate(180deg);
    }

    svg {
      fill: ${({ theme }) => theme.grayscale0};
    }
  }

  .tt-paydate-hint {
    background-color: ${({ theme }) => theme.grayscale40};
    margin: 20px -23px -19px -23px;
    border-radius: 0 0 12px 12px;
    padding: 12px 24px;
  }
`;

const joinHintContent = (hint) => {
  if (!hint) return;
  const { header, description } = hint;
  return (
    <div className="tt-hint-content">
      {header}
      {description}
    </div>
  );
};

export const PaydateTooltip = ({
  fetchPreviousPeriod,
  dayData,
  earningsData,
  today,
  chartBodyRef,
  chartData,
  viewedPeriod,
  barWidth,
  selectedOption,
  tooltipTarget,
}) => {
  const { connectBankAccountCopyVisible } = useFlags();
  const { payDatePeriod } = dayData;
  const { periodsDataSourceStatusMap = {} } = earningsData;

  if (!viewedPeriod) return null;

  let tooltipInfo = null;
  if (!selectedOption.isPlatform) {
    tooltipInfo =
      today > payDatePeriod.fiscalToDate
        ? tooltipsCopies.upcomingPayments.paidSoon(selectedOption)
        : tooltipsCopies.upcomingPayments.inProgress(selectedOption);
  } else {
    const dataSourceStatus = periodsDataSourceStatusMap[payDatePeriod.id];
    const periodStatus = parseDataSourceStatus(dataSourceStatus, false, connectBankAccountCopyVisible);
    const tooltipCopies = tooltipsCopies[selectedOption.id] || tooltipsCopies.generic;
    tooltipInfo = tooltipCopies.payout[periodStatus];
  }

  const { fiscalStartDate, fiscalToDate, totalPeriodEarnings } = payDatePeriod;

  const date = moment(dayData.date).format("MMMM Do");
  const start = moment(fiscalStartDate).format("MMM D");
  const end = moment(fiscalToDate).format("MMM D");

  let viewPeriodIndex = null;
  let paydatePeriodIndex = null;
  earningsData.forEach((period, index) => {
    if (period.periodId === viewedPeriod.id) viewPeriodIndex = index;
    if (period.periodId === payDatePeriod.id) paydatePeriodIndex = index;
  });

  let periodType = "previous";
  if (typeof paydatePeriodIndex === "number") {
    const periodDirection = paydatePeriodIndex - viewPeriodIndex;
    periodType = periodDirection < 0 ? "previous" : periodDirection < 0 ? "future" : "";
  }

  let paidOnCopy = `Paid on ${date}`;
  if (dayData.date > today) paidOnCopy = `To be paid on ${date}`;

  return (
    <SanloTooltip placement="top" target={tooltipTarget}>
      <StyledPaydateTooltip
        className={classnames({
          "tt-paydate-current-period": fiscalToDate > today,
        })}
      >
        <BodyRegular16 className="tt-paydate-paid-on">{paidOnCopy}</BodyRegular16>
        <TitleLargePlus className="tt-paydate-amount">{currencyFormat(totalPeriodEarnings)}</TitleLargePlus>
        <BodyRegularPlus
          className="tt-paydate-period-picker"
          onClick={(e) => {
            const periodData = chartData.periods.map[payDatePeriod.id];

            if (!periodData.isLoaded) {
              const periodsToLoad = [];
              for (let i = 0; i < chartData.periods.order.length; ++i) {
                const p = chartData.periods.order[i];
                if (p.isLoaded) {
                  periodsToLoad.unshift(p.periodId);
                  break;
                }
                if (p.fiscalStartDate > payDatePeriod.fiscalStartDate) {
                  periodsToLoad.unshift(p.periodId);
                }
              }

              fetchPreviousPeriod(periodsToLoad);
              return;
            }

            const node = chartBodyRef.current;
            // Set the scroll to the first day of the paydate period
            // Go through all the days until we come across the first
            // day that is part of the period for the paydate tooltip
            for (let i = 0; i < chartData.order.length; ++i) {
              const data = chartData.map[chartData.order[i]];
              if (data.period.id === payDatePeriod.id) {
                node.scrollLeft = i * barWidth;
                break;
              }
            }
          }}
        >
          {periodType === "previous" && <PeriodArrowIcon className="previous-period" />}
          View {`${start} - ${end}`} {periodType !== "" && "earnings"}
          {periodType === "future" && <PeriodArrowIcon className="future-period" />}
        </BodyRegularPlus>
        {tooltipInfo && (
          <div className="tt-paydate-hint">
            <TooltipRow iconLeft title="Learn More" hint={joinHintContent(tooltipInfo)} />
          </div>
        )}
      </StyledPaydateTooltip>
    </SanloTooltip>
  );
};
