import React from "react";
import styled from "styled-components";

import { ReactComponent as ArrowIcon } from "@assets/img/icons/common/accordion-arrow.svg";

export const AccordionArrow = styled(({ expanded, ...rest }) => <ArrowIcon {...rest} />)`
  transform: scale(1.5) rotate(180deg);
  transition: transform 0.2s ease;
  fill: ${({ theme }) => theme.textGray};
  margin-right: 16px;
  cursor: pointer;

  ${({ expanded }) =>
    expanded &&
    `
    transform: scale(1.5) rotate(0);
  `};
`;
