import { configureConsts, configureActions } from "@utils/redux";

export const GET_SUMMARY = configureConsts("GET_SUMMARY");
export const GET_ADVANCES = configureConsts("GET_ADVANCES");
export const NEW_CAPITAL_REQUEST = configureConsts("NEW_CAPITAL_REQUEST");
export const CAPITAL_REQUEST_START = configureConsts("CAPITAL_REQUEST_START");
export const CAPITAL_REQUEST_CLONE = configureConsts("CAPITAL_REQUEST_CLONE");
export const CAPITAL_REQUEST_DISMISS_STATUS = configureConsts("CAPITAL_REQUEST_DISMISS_STATUS");

const advancesActions = {
  getSummary: configureActions(GET_SUMMARY),

  getAdvances: configureActions(GET_ADVANCES),

  capitalRequestStart: configureActions(CAPITAL_REQUEST_START),

  newCapitalRequest: configureActions(NEW_CAPITAL_REQUEST, {
    request: (data) => ({
      type: NEW_CAPITAL_REQUEST.REQUEST,
      data,
    }),
  }),
  capitalRequestClone: configureActions(CAPITAL_REQUEST_CLONE, {
    request: () => ({
      type: CAPITAL_REQUEST_CLONE.REQUEST,
    }),
  }),
  capitalRequestDismissStatus: configureActions(CAPITAL_REQUEST_DISMISS_STATUS, {
    request: () => ({
      type: CAPITAL_REQUEST_DISMISS_STATUS.REQUEST,
    }),
  }),
};

export default advancesActions;
