import React, { useState, useRef } from "react";
import classnames from "classnames";

import { BodyRegularPlus, BodySmallPlus, StyledJumboInput, StyledJumboInputGroup } from "@styled";
import { StepActionsContainer } from "@common/animated/FadeStepsContainer";
import SetupGuide from "@common/modals/integrations-modal/SetupGuide";
import { StyledGuideList, StyledScrollableList } from "@components/common/modals/integrations-modal/StyledSetupGuide";

import { Loader } from "@components/common/loaders";
import RegularModal from "@common/modals/regular-modal/RegularModal";
import SanloChip from "@common/chips/SanloChip";
import { SanloButton, Link, Support } from "@inputs";
import { Callout } from "@components/integrations/connection-guide/Callout";
import { ErrorMessage } from "@components/integrations/connection-guide/ErrorMessage";
import { COMMON_ACTION, ADJUST_ACTION } from "@utils/gtm";

import useToggle from "@utils/custom-hooks/useToggle";
import symbols from "@utils/symbols";
import generalCopies from "@utils/text/general";

import { ReactComponent as CrossIcon } from "@assets/img/icons/common/modal-cross.svg";
import { ReactComponent as PlusCircle } from "@assets/img/icons/common/plus-circle.svg";
import { ReactComponent as ErrorIcon } from "@assets/img/icons/common/alertTriangle.svg";

export const AdjustStep2 = ({
  platform = {
    id: "adjust",
    name: "Adjust",
    platformId: "adjust",
  },
  connectPlatform = () => {},
  requestStatus = {},
  appTokens = [""],
  credentialsError = "",
  setAppTokens = () => {},
  trackEvent = () => {},
}) => {
  const contentRef = useRef(null);

  const [removeAppTokenModalVisible, toggleRemoveAppTokenModal] = useToggle(false);
  const [appTokenToDelete, setAppTokenToDelete] = useState(null);

  const handleAddAppToken = (listRef) => {
    if (appTokens[appTokens.length - 1]?.length !== 0) {
      setAppTokens([...appTokens, ""]);
    } else if (listRef) {
      const lastChild = listRef.current.childNodes.length - 1;
      const input = listRef.current.childNodes[lastChild].childNodes[0];
      input.focus();
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleAddAppToken(contentRef);
    }
  };

  const handleDeleteAppToken = (index) => {
    let appTokensClone = appTokens.slice(0);
    appTokensClone.splice(index ? index : appTokenToDelete, 1);
    setAppTokens(appTokensClone);
    toggleRemoveAppTokenModal(false);
  };

  const {
    step_guide_links: {
      adjust: { adjust_step_subscription, adjust_step_here, adjust_step_2_apps, adjust_step_2_app_token },
    },
  } = generalCopies;

  return (
    <StepActionsContainer>
      <div className="fs-modal-actions">
        <ErrorMessage error={credentialsError} />

        <div ref={contentRef}>
          <BodySmallPlus className="jumbo-input-label">{`App token(s)`}</BodySmallPlus>

          <StyledScrollableList
            margin="16px 0"
            borderRadius="10px"
            maxHeightScroll="375px"
            className={classnames({
              "scroll-wrap-content": appTokens.length > 4,
            })}
            data-test-id={`${platform.platformId}-app-token-list`}
          >
            {appTokens.map((appId, index) => (
              <StyledJumboInputGroup key={`app-token-input-${index}`} className="jumbo-input-group">
                <StyledJumboInput
                  onKeyPress={handleKeyPress}
                  autoFocus={true}
                  value={appId}
                  placeholder="Enter your App token"
                  type="text"
                  onChange={(event) => {
                    let clone = appTokens.slice(0);
                    clone[index] = event.target.value;
                    setAppTokens(clone);
                  }}
                  data-test-id={`${platform.platformId}-app-token-input-${index + 1}`}
                />
                {appTokens.length > 1 && (
                  <CrossIcon
                    className="input-remove"
                    onClick={() => {
                      if (appTokens.at(index).length !== 0) {
                        setAppTokenToDelete(index);
                        toggleRemoveAppTokenModal();
                      } else {
                        handleDeleteAppToken(index);
                      }
                    }}
                  />
                )}
              </StyledJumboInputGroup>
            ))}
          </StyledScrollableList>
        </div>

        <div
          className="fs-modal-link-button-wrapper"
          onClick={() => {
            trackEvent(ADJUST_ACTION.ADD_APP_TOKEN);
            handleAddAppToken(contentRef);
          }}
        >
          <PlusCircle className={classnames("fs-plus-circle-icon", `tag-sanlo-${platform.name}-add-app-id`)} />
          <BodyRegularPlus
            className={`fs-modal-link-button tag-sanlo-${platform.name}-add-app-id`}
            data-test-id={`${platform.platformId}-add-app-token`}
          >
            Add App token
          </BodyRegularPlus>
        </div>

        <SanloButton
          isJumbo
          isDisabled={appTokens.length >= 1 && appTokens[0].length === 0}
          onClick={() => {
            trackEvent(COMMON_ACTION.CONNECT);
            connectPlatform();
          }}
          label={
            requestStatus?.saveCredentials?.pending ? (
              <Loader className="button-loader" />
            ) : (
              `Connect to ${platform.name}`
            )
          }
          gtmEventTag={`tag-sanlo-${platform.name}-step2-connect-to-${platform.name}`}
          testId={`${platform.platformId}-app-token-submit-button`}
        />
      </div>

      <SetupGuide
        platformStep={`${platform.name}1`}
        step={{
          callout: (
            <Callout>
              You may need to have the KPI Service API enabled as part of your {platform.name}{" "}
              <Link
                data-test-id={`${platform.platformId}-step-2-subscription-link`}
                href={adjust_step_subscription}
                className={`modal-link setup-guide-link text-underline text-blue tag-sanlo-${platform.platformId}-subscription-link`}
                isExternalLink
                label="subscription"
                onClick={() => {
                  trackEvent(COMMON_ACTION.SUBSCRIPTION);
                }}
              />
              . Please see instructions{" "}
              <Link
                data-test-id={`${platform.platformId}-step-2-here-link`}
                href={adjust_step_here}
                className={`modal-link setup-guide-link text-underline text-blue tag-sanlo-${platform.platformId}-see-instructions-here-link`}
                isExternalLink
                label="here"
                onClick={() => {
                  trackEvent(COMMON_ACTION.HERE_LINK);
                }}
              />
              <br />
              <br />
              {symbols.LightBulb()} Access to the right set of data provides the most delightful experience for tracking
              User Acquisition Spend. Have questions about Adjust connection?{" "}
              <Support
                red
                className={`tag-sanlo-contact-us-${platform.platformId}-step-2`}
                onClick={() => {
                  trackEvent(COMMON_ACTION.CONTACT_US);
                }}
              />
            </Callout>
          ),
          title: "Copy your App token(s)",
          description: (
            <>
              <StyledGuideList>
                <li>
                  Navigate to the{" "}
                  <Link
                    data-test-id={`${platform.platformId}-step-2-apps-link`}
                    href={adjust_step_2_apps}
                    className={`modal-link setup-guide-link tag-sanlo-${platform.platformId}-apps-page-link"`}
                    isExternalLink
                    label="Apps"
                  />{" "}
                  page.
                </li>
                <li>
                  Go to{" "}
                  <Link
                    data-test-id={`${platform.platformId}-step-2-app-token-link`}
                    href={adjust_step_2_app_token}
                    className={`modal-link setup-guide-link tag-sanlo-${platform.platformId}-app-token-instructions-link"`}
                    isExternalLink
                    label="App token instruction"
                  />{" "}
                  to see how to find and copy App token(s).
                </li>
                <li>Paste this App token(s) on the left {symbols.PointLeft()}</li>
              </StyledGuideList>
            </>
          ),
        }}
      />

      <RegularModal
        width={500}
        isOpen={removeAppTokenModalVisible}
        handleToggle={() => toggleRemoveAppTokenModal()}
        title={`Remove App tokens?`}
        content={
          <>
            <SanloChip
              state="error"
              content={
                <>
                  <ErrorIcon className="chip-icon" />
                  <span>{`Are you sure you want to remove this App tokens? You will no longer be able to get insights, such as tracking your user acquisition spend and ad revenue.`}</span>
                </>
              }
            />
            <div className="modal-aside-buttons">
              <SanloButton
                isSecondary
                label="Cancel"
                onClick={() => {
                  trackEvent(ADJUST_ACTION.CANCEL_REMOVE_ADD_APP_TOKEN);
                  toggleRemoveAppTokenModal();
                }}
              />
              <SanloButton
                label="Remove"
                onClick={() => {
                  trackEvent(ADJUST_ACTION.REMOVE_ADD_APP_TOKEN);
                  handleDeleteAppToken();
                }}
              />
            </div>
          </>
        }
      />
    </StepActionsContainer>
  );
};
