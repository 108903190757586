import { defineStyleConfig } from "@chakra-ui/react";

export const Textarea = defineStyleConfig({
  baseStyle: {
    background: "dark.400",
    border: "2px solid transparent",
    fontSize: "sm",
  },
  variants: {
    builder: {
      h: "120px",
      fontWeight: 400,
      lineHeight: "16px",
      letterSpacing: "0.14px",
      resize: "none",
      borderRadius: "10px",
      _placeholder: {
        fontSize: "sm",
        color: "textSubtext.400",
      },
      _hover: {
        border: "2px solid",
        borderColor: "dark.100",
      },
      _focus: {
        border: "2px solid",
        borderColor: "brandRed.400",
      },
      _invalid: {
        bg: "brandRedAlpha.400",
        color: "brandRed.400",
      },
    },
  },
  defaultProps: {
    colorScheme: "dark",
  },
});
