import React from "react";

import RegularModal from "@components/common/modals/regular-modal/RegularModal";
import SanloChip from "@components/common/chips/SanloChip";
import { SanloButton } from "@inputs";

export const RemoveMMPModal = ({
  toggleRemoveMMPModal,
  removeMMPModalVisible,
  integration,
  handleRemoveMMPIntegration,
}) => (
  <RegularModal
    hasChip={true}
    isOpen={removeMMPModalVisible}
    handleToggle={toggleRemoveMMPModal}
    title="Unable to Connect"
    content={
      <>
        <SanloChip
          content={`You may have only one product analytics platform connected at a time.
            If you'd like to connect a different platform, please unlink ${integration?.name} connection
            first.`}
          state="error"
          showIcon
        />
        <div className="modal-aside-buttons">
          <SanloButton isSecondary label="Cancel" onClick={toggleRemoveMMPModal} />
          <SanloButton label={`Unlink ${integration?.name}`} onClick={handleRemoveMMPIntegration} />
        </div>
      </>
    }
  />
);
