import React from "react";
import { Box, Button, HStack, Icon, Image, Text } from "@chakra-ui/react";
import { ReactComponent as CheckCircle } from "@assets/img/icons/common/check_circle_outline.svg";
import { ReactComponent as AlertTriangle } from "@assets/img/icons/common/alertTriangle.svg";
import DefaultImg from "@assets/img/icons/adNetworkDefault.png";
import { AD_NETWORK_STATUS } from "@src/consts/ad-networks";
import { useDispatch } from "react-redux";
import { adNetworksThunks } from "@redux/integrations/ad-networks/adNetworksSlice";

// We could rename this later if we decide to redo the integrations stuff
const statusFactory = {
  [AD_NETWORK_STATUS.PULLING_DATA]: PullingDataStatus,
  [AD_NETWORK_STATUS.CONNECTED]: ConnectedStatus,
  [AD_NETWORK_STATUS.NO_PERMISSIONS]: NoPermissionsStatus,
  [AD_NETWORK_STATUS.CONNECTOR_ERROR]: ConnectorErrorStatus,
};

export function AdNetwork({ name, id, iconUrl, status, lastSynced, handleCredentialsRetry }) {
  const dispatch = useDispatch();

  function credentialsRetry() {
    dispatch(adNetworksThunks.getNetworkByConnectionId(id));
    handleCredentialsRetry();
  }

  if (!statusFactory[status]) return null;

  return React.createElement(statusFactory[status], { name, iconUrl, lastSynced, credentialsRetry }) ?? null;
}

function PullingDataStatus({ name, iconUrl }) {
  return (
    <Box as="article" borderRadius={"12px"} bg="dark.600" p="16px" data-test-id="ad-network-connection">
      <HStack gap="16px">
        <Image borderRadius={"8px"} w="56px" h="56px" src={iconUrl} fallbackSrc={DefaultImg} />
        <Box>
          <Text fontWeight={500} size={"regular"} color="textWhite.400">
            {name}
          </Text>
          <Text mt="4px" size="small" color="textSubtext.400">
            This may take up to 24 hours
          </Text>
        </Box>
      </HStack>
      <Button mt="16px" w="100%" variant={"solid"} isDisabled>
        Connecting...
      </Button>
    </Box>
  );
}

function ConnectedStatus({ name, iconUrl, lastSynced }) {
  return (
    <Box as="article" borderRadius={"12px"} bg="dark.600" p="16px" data-test-id="ad-network-connection">
      <HStack gap="16px">
        <Image borderRadius={"8px"} w="56px" h="56px" src={iconUrl} fallbackSrc={DefaultImg} />
        <Box>
          <Text textTransform="capitalize" fontWeight={500} size={"regular"} color="textWhite.400">
            {name}
          </Text>
          <Text mt="4px" size="small" color="textSubtext.400">
            Updated {lastSynced} days ago
          </Text>
        </Box>
      </HStack>
      <Button
        mt="16px"
        w="100%"
        variant={"solid"}
        bgColor={"rgba(44, 249, 175, 0.1)"}
        color={"brandGreen.400"}
        _disabled={{ opacity: 1 }}
        _hover={{
          backgroundColor: "rgba(44, 249, 175, 0.1)",
        }}
        leftIcon={<Icon as={CheckCircle} color={"brandGreen.400"} w="16px" h="16px" />}
        isDisabled
      >
        Connected
      </Button>
    </Box>
  );
}

function NoPermissionsStatus({ name, iconUrl, credentialsRetry }) {
  return (
    <Box as="article" borderRadius={"12px"} bg="dark.600" p="16px" data-test-id="ad-network-connection">
      <HStack gap="16px">
        <Image borderRadius={"8px"} w="56px" h="56px" src={iconUrl} fallbackSrc={DefaultImg} />
        <Box>
          <Text textTransform="capitalize" fontWeight={500} size={"regular"} color="textWhite.400">
            {name}
          </Text>
          <Text mt="4px" size="small" color="brandRed.400">
            <Icon as={AlertTriangle} fill="brandRed.400" w="16px" h="16px" /> Integration failed
          </Text>
        </Box>
      </HStack>
      <Button mt="16px" w="100%" variant={"solid"} onClick={credentialsRetry}>
        Try again
      </Button>
    </Box>
  );
}

function ConnectorErrorStatus({ name, iconUrl }) {
  return (
    <Box as="article" borderRadius={"12px"} bg="dark.600" p="16px" data-test-id="ad-network-connection">
      <HStack gap="16px">
        <Image borderRadius={"8px"} w="56px" h="56px" src={iconUrl} fallbackSrc={DefaultImg} />
        <Box>
          <Text textTransform="capitalize" fontWeight={500} size={"regular"} color="textWhite.400">
            {name}
          </Text>
          <Text mt="4px" size="small" color="brandRed.400">
            <Icon as={AlertTriangle} fill="brandRed.400" w="16px" h="16px" /> Connection broken
          </Text>
        </Box>
      </HStack>
      <Button mt="16px" w="100%" variant={"solid"} isDisabled>
        Attempting to reconnect...
      </Button>
    </Box>
  );
}
