import React, { useEffect, useRef } from "react";
import { bezierCommand, getPointsCoordinates, svgPath } from "./companyFinancesUtils";

const Sparklines = ({ id, values, width, height, smoothness = 0.15, color = "#2DFAB0", ...rest }) => {
  const svgRef = useRef();

  useEffect(() => {
    if (!values) return;
    const setupSvgContainer = () => {
      const svg = svgRef.current;
      if (svg) {
        const style = {
          // stroke: values[0] < values.at(-1) ?
          stroke: color,
        };
        svg.innerHTML = svgPath(getPointsCoordinates(values, width, height), bezierCommand, smoothness, style);
      }
    };
    setupSvgContainer();
  }, [width, height, color, smoothness, values]);

  if (!values) return null;

  return (
    <svg
      id={id}
      ref={svgRef}
      width={width}
      height={height}
      className="sparklines-svg"
      style={{
        overflow: "visible",
      }}
      {...rest}
    />
  );
};

export default Sparklines;
