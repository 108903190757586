import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import actions from "@redux/actions";

import { StyledConnectionFlow } from "@components/integrations/StyledConnectionFlow";

import { AppStoreStep1 } from "./AppStoreStep1";

export const AppStoreConnectSetup = ({
  platform = {
    id: "appstore",
    name: "App store",
    platformId: "appstore",
  },
  trackEvent = () => {},
  closeModal,
}) => {
  const dispatch = useDispatch();

  const integrationData = useSelector((state) => state.integrations[platform.id]);
  const saveCredentialsRequestStatus = integrationData.requestStatus.saveCredentials;

  const [appleId, setAppleId] = useState("");
  const [appSpecificPassword, setAppSpecificPassword] = useState("");

  return (
    <StyledConnectionFlow className="fs-modal-content">
      <AppStoreStep1
        appleId={appleId}
        setAppleId={setAppleId}
        appSpecificPassword={appSpecificPassword}
        setAppSpecificPassword={setAppSpecificPassword}
        saveCredentialsRequestStatus={saveCredentialsRequestStatus}
        credentialsError={
          saveCredentialsRequestStatus?.error ? `Please check your ${platform.name} credentials.` : null
        }
        connectToAppStore={() => {
          const credentials = {
            appleId: appleId,
            appSpecificPassword: appSpecificPassword,
          };
          dispatch(actions.integrations[platform.id].saveCredentials.request(credentials, closeModal));
        }}
        trackEvent={trackEvent}
      />
    </StyledConnectionFlow>
  );
};
