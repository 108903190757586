import { getErrorStatus, getRequestStatus, getResponseStatus, setStatusDefaults, stateSet } from "@utils/redux";
import { parseUploadedFiles } from "./utils";
import { BANKING_STATUS } from "./actions";

const initialState = {
  data: {
    // Match up the APIs
    status: {},
    uploadedFiles: {},
  },
  requestStatus: {
    status: setStatusDefaults(),
  },
};

const bankingReducer = (state = initialState, action) => {
  switch (action.type) {
    // STATUS

    case BANKING_STATUS.REQUEST: {
      return stateSet(state, {
        "requestStatus.status": getRequestStatus(),
      });
    }

    case BANKING_STATUS.RESPONSE: {
      return stateSet(state, {
        "requestStatus.status": getResponseStatus(),
        "data.status": action.response.data,
        "data.uploadedFiles": parseUploadedFiles(action.response.data),
      });
    }

    case BANKING_STATUS.ERROR: {
      return stateSet(state, {
        "requestStatus.status": getErrorStatus(),
      });
    }

    default:
      return state;
  }
};

export default bankingReducer;
