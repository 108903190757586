import { Button, FormControl, FormLabel, HStack, Icon, Image, Switch, Text, VStack } from "@chakra-ui/react";
import { ReactComponent as ChevronLeft } from "@assets/img/icons/common/chevron-left.svg";
import { AUTH_METHODS } from "@src/consts/webshops/settings";
import { ManualForm } from "./forms/ManualForm";
import { cloneElement } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { PasswordForm } from "./forms/PasswordForm";
import { GoogleForm } from "./forms/GoogleForm";
import { useSelector } from "react-redux";
import { authMethodsSelector } from "@redux/webshops/webshop/settings/auth-methods/authMethodsSlice";
import { FacebookForm } from "./forms/FacebookForm";
import { AppleForm } from "./forms/AppleForm";
import { useHandleAuthMethod } from "@pages/webshops/webshop/settings/hooks/useHandleAuthMethod";

const forms = {
  [AUTH_METHODS.PASSWORD]: <PasswordForm />,
  [AUTH_METHODS.MANUAL]: <ManualForm />,
  [AUTH_METHODS.GOOGLE]: <GoogleForm />,
  [AUTH_METHODS.FACEBOOK]: <FacebookForm />,
  [AUTH_METHODS.APPLE]: <AppleForm />,
};

export function AuthMethodForm({ handlePrevStep, onClose, selectedAuthMethod }) {
  const { handleAuthMethod, isLoading } = useHandleAuthMethod();
  const { authProvidersMap } = useSelector(authMethodsSelector);
  const authProvider = authProvidersMap[selectedAuthMethod?.name];
  const methods = useForm({
    values: {
      id: authProvider?.data?.id ?? null,
      authMethod: authProvider?.name,
      visible: authProvider?.data?.visible ?? false,
      ...authProvider?.data,
    },
  });
  const {
    handleSubmit,
    control,
    formState: { isDirty },
    resetField,
  } = methods;

  if (!selectedAuthMethod) return null;

  async function handleFormSubmit(data) {
    if (data && isDirty) {
      await handleAuthMethod(data);
      // we need to reset the field so the save button is disabled
      if (selectedAuthMethod?.data?.instructionImageUrl) {
        resetField("instructionImageUrl", {
          defaultValue: selectedAuthMethod?.data?.instructionImageUrl,
          keepDirty: false,
        });
      }
    }
  }

  return (
    <FormProvider {...methods}>
      <VStack gap={"24px"} alignItems={"stretch"} as="form" onSubmit={handleSubmit(handleFormSubmit)}>
        <HStack gap={"8px"} p="8px" pt="4px" onClick={handlePrevStep} cursor={"pointer"} w="fit-content">
          <Icon cursor={"pointer"} as={ChevronLeft} />
          <Text size={"small"} fontWeight={600} textColor={"textWhite.400"}>
            Back
          </Text>
        </HStack>
        <HStack justifyContent={"space-between"} maxW={"400px"}>
          <HStack gap={"8px"}>
            <Image w="32px" h="32px" fallbackSrc={selectedAuthMethod.iconUrl} src={selectedAuthMethod.iconUrl} />
            <Text color="textWhite.400" fontWeight={600} size={"regular"}>
              {selectedAuthMethod.label}
            </Text>
          </HStack>
          <FormControl maxW={"fit-content"}>
            <Controller
              control={control}
              name="visible"
              render={({ field: { onChange, value } }) => (
                <FormLabel
                  size="small"
                  fontWeight={400}
                  color={"textSubtext.400"}
                  display="flex"
                  alignItems="center"
                  mb="0"
                >
                  Active
                  <Switch ml="16px" size="sm" isChecked={value} onChange={(e) => onChange(e.target.checked)} />
                </FormLabel>
              )}
            ></Controller>
          </FormControl>
        </HStack>
        {forms[selectedAuthMethod.name] &&
          cloneElement(forms[selectedAuthMethod.name], { authMethod: selectedAuthMethod, onClose })}
        <HStack justifyContent={"space-between"}>
          <Button h="32px" variant={"primary"} onClick={onClose}>
            Close
          </Button>
          <Button type="submit" h="32px" isDisabled={!isDirty} isLoading={isLoading}>
            Save
          </Button>
        </HStack>
      </VStack>
    </FormProvider>
  );
}
