import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledInternalLink = styled(Link)`
  color: ${({ theme }) => theme.colorRed};

  &:hover {
    color: ${({ theme }) => theme.colorRed};
    text-decoration: ${(props) => props.textDecoration || "none"};
  }
`;

export const InternalLink = ({ ...rest }) => {
  return <StyledInternalLink {...rest} />;
};
