import React, { useEffect, useState } from "react";

import { BodySmall14, TitleHeadlinePlus, TitleRegularPlus } from "@components/styled";
import StyledOnboardingStep1 from "./StyledOnboardingStep1";
import GradientSlider from "@components/onboarding/gradient-slider/GradientSlider";

import { currencyFormat } from "@utils/currency";

const MAX = 100000;
const MIN = 1000;
const STEP = 1000;

const OnboardingStep1 = ({ funding, onFundingChange }) => {
  const currentValue = funding < MIN ? "Less than $1k" : funding > MAX ? "$100k+" : currencyFormat(funding);
  const previousValue = funding > MIN ? currencyFormat(funding - STEP) : funding === MIN ? "Less than $1k" : null;
  const nextValue = funding < MAX ? currencyFormat(funding + STEP) : funding === MAX ? "$100k+" : null;

  const [showSideValues, setShowSideValues] = useState(false);

  const handleSliderDrag = (value) => {
    setShowSideValues(true);
    onFundingChange(value);
  };

  useEffect(() => {
    let timer;
    if (showSideValues) {
      timer = setTimeout(() => {
        setShowSideValues(false);
      }, 800);
    }

    return () => clearTimeout(timer);
  }, [showSideValues]);

  return (
    <StyledOnboardingStep1 className="animated-container">
      <div className="os-funding-slider">
        <div className="os-funding-display">
          <div className="os-previous-cash os-gradient-cash" style={{ opacity: showSideValues ? 1 : 0 }}>
            <TitleRegularPlus>{previousValue}</TitleRegularPlus>
            <div className="fade-shadow left-side" />
          </div>
          <div className="os-gradient-cash os-current-cash">
            <TitleHeadlinePlus>{currentValue}</TitleHeadlinePlus>
          </div>
          <div className="os-next-cash os-gradient-cash" style={{ opacity: showSideValues ? 1 : 0 }}>
            <TitleRegularPlus>{nextValue}</TitleRegularPlus>
            <div className="fade-shadow right-side" />
          </div>
        </div>

        <div className="os-slider-container">
          <GradientSlider
            onSliderDrag={handleSliderDrag}
            value={funding}
            max={MAX + 1000}
            min={MIN - 1000}
            testId="onboarding-growth-capital-slider"
          />
        </div>

        <div className="os-slider-x-axis">
          <BodySmall14>$1k</BodySmall14>
          <BodySmall14>$100k+</BodySmall14>
        </div>
      </div>
    </StyledOnboardingStep1>
  );
};

export default OnboardingStep1;
