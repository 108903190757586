import { Button, HStack, Heading, Icon, VStack } from "@chakra-ui/react";
import { ReactComponent as PlusCircle } from "src/assets/img/icons/common/plus-circle.svg";
// import { ReactComponent as ImportFile } from "src/assets/img/icons/common/import-file.svg";

import PropTypes from "prop-types";

export const EmptyState = ({ label = "items", buttonLabel = "New item", openItemDrawer = () => {} }) => {
  return (
    <VStack height={"473px"} gap="12px" alignItems={"center"} justifyContent={"center"}>
      <Heading size="small" color="textDefault.400">
        You don’t have any {label} in your catalog
      </Heading>
      <HStack>
        <Button
          leftIcon={<Icon w={"24px"} h={"24px"} as={PlusCircle} />}
          variant={"tertiary"}
          fontSize={"18px"}
          fontWeight={400}
          onClick={openItemDrawer}
          data-test-id={`empty-catalog-add-${label}`}
        >
          {buttonLabel}
        </Button>

        {/* Wont be part of the first release */}
        {/* <Text size="large" color="textWhite.400">
          or
        </Text>
        <Button
          leftIcon={<Icon w={"24px"} h={"24px"} as={ImportFile} />}
          variant={"tertiary"}
          fontSize={"18px"}
          fontWeight={400}
        >
          Import catalog
        </Button> */}
      </HStack>
    </VStack>
  );
};

EmptyState.propTypes = {
  label: PropTypes.string,
  buttonLabel: PropTypes.string,
  openItemDrawer: PropTypes.func.isRequired,
};
