import React from "react";

import MediaDisplay from "@components/common/media/MediaDisplay";

import { alphaTrim } from "@utils/general";

export const GuideMedia = ({ platformName = "", media = [], mediaSlider = [] }) => {
  return (
    <MediaDisplay
      key={alphaTrim(media[0] || mediaSlider[0])}
      platformName={platformName}
      media={media}
      mediaSlider={mediaSlider}
      controls
      vertical={false}
    />
  );
};
