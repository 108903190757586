import styled from "styled-components";

import { TitleSmallPlusCSS } from "@styled";

export const StyledPageSectionHeader = styled.div`
  position: relative;
  ${TitleSmallPlusCSS}

  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;
