import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withTheme } from "styled-components";

import UploadContainer from "@components/common/upload/UploadContainer";
import DocumentsTable from "@components/documents/DocumentsTable";
import { StyledPageContainer, PageHeader } from "@components/page";

import { uploadThunks } from "@redux/uploads/uploadSlice";

import text from "@utils/text";

const DocumentsPage = () => {
  const dispatch = useDispatch();

  const { uploadedDBFiles, isUploading } = useSelector((state) => state.uploads.data);
  const { completed } = useSelector((state) => state.uploads.requestStatus.status);

  const [displayData, setDisplayData] = useState([]);

  useEffect(() => {
    dispatch(uploadThunks.getDocuments("BANK_STATEMENT"));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const show = uploadedDBFiles.length > 0 && completed && !isUploading;
    setDisplayData(show ? uploadedDBFiles : []);
  }, [uploadedDBFiles, isUploading, completed]);

  return (
    <StyledPageContainer isCardContainer>
      <PageHeader header={text.t("documents")} subheader={text.t("upload_drop_files")} />
      <UploadContainer />
      <DocumentsTable data={displayData} />
    </StyledPageContainer>
  );
};

export default withTheme(DocumentsPage);
