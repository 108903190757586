import React from "react";
import { Menu, MenuButton, MenuList, MenuItem, Text, Icon } from "@chakra-ui/react";
import { ReactComponent as AccordionArrow } from "@assets/img/icons/common/accordion-arrow.svg";

export const Dropdown = ({ id = "", viewOptions = [], selectedView = {}, onSelect = () => {} }) => {
  let selectedViewOption = {};
  viewOptions.forEach((viewOption) => {
    if (selectedView.label === viewOption.label) selectedViewOption = viewOption;
  });
  if (!selectedViewOption) selectedViewOption = viewOptions[0];

  return (
    <Menu variant={"dropdown"}>
      {({ isOpen }) => (
        <>
          <MenuButton
            data-test-id={id}
            as={Text}
            height={"16px"}
            size={"small"}
            letterSpacing={"0.01em"}
            cursor={"pointer"}
          >
            {selectedViewOption.label}
            <Icon
              as={AccordionArrow}
              width={"16px"}
              height={"16px"}
              ml={"4px"}
              opacity={"0.7"}
              fill={"textDefault.400"}
              transform={isOpen ? "rotate(0)" : "rotate(180deg)"}
              transition={"transform 0.2s ease"}
            />
          </MenuButton>
          <MenuList>
            {viewOptions.map((view) => {
              return (
                <MenuItem
                  as={Text}
                  key={`key-dropdown-${view.label}`}
                  color={view.label === selectedViewOption.label ? "dark.900" : "textDefault.400"}
                  backgroundColor={view.label === selectedViewOption.label ? "brandRed.400" : "transparent"}
                  onClick={() => {
                    onSelect(view);
                  }}
                >
                  {view.label}
                </MenuItem>
              );
            })}
          </MenuList>
        </>
      )}
    </Menu>
  );
};
