import { createSlice } from "@reduxjs/toolkit";

import { IN_APP_PURCHASES, PLATFORMS } from "@utils/consts";
import { NOT_CONNECTED } from "@utils/status";
import { showToast } from "@utils/redux";

import { manualConnectionThunks } from "../base/manualSlice";

export const steamSlice = createSlice({
  name: "steam",
  initialState: {
    id: "steam",
    name: "Steam",
    platformId: "steam",
    rawId: "steam",
    type: PLATFORMS,
    category: IN_APP_PURCHASES,
    isPlatform: true,
    manual: true,
    image: require("@assets/img/icons/logos/steam.png"),
    status: NOT_CONNECTED,
    connectionStatus: {
      isConnected: false,
      isPullingData: false,
      isNotConnected: true,
    },
    steps: [
      {
        title: "Invite Sanlo to your Steam account.",
        subtitle: "Grant permissions for Steam by following setup instructions below.",
        hideProgress: true,
      },
      {
        title: null,
        subtitle: null,
        hideProgress: true,
        isSetupDone: true,
      },
    ],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(manualConnectionThunks.getAll.fulfilled, (state, action) => {
        const { content = [] } = action.payload;
        content.forEach((manualConnection) => {
          const { integrationPlatform = {}, status = "" } = manualConnection;
          const { platformId = "" } = integrationPlatform;
          if (state.id === platformId) {
            state.status = status;
          }
        });
      })
      .addCase(manualConnectionThunks.createConnection.fulfilled, (state, action) => {
        const {
          status,
          integrationPlatform: { platformId = "" },
        } = action.payload;
        if (state.id === platformId) {
          state.status = status;
        }
      })
      .addCase(manualConnectionThunks.completeConnection.fulfilled, (state, action) => {
        const platformId = action.meta.arg;
        if (state.platformId !== platformId) return;
      })
      .addCase(manualConnectionThunks.completeConnection.rejected, (state, action) => {
        const platformId = action.meta.arg;
        if (state.platformId !== platformId) return;
        showToast("An error has occurred trying to connect Steam. Please, try again.", "error");
      })
      .addCase(manualConnectionThunks.deleteConnection.fulfilled, (state, action) => {
        const platformId = action.meta.arg;
        if (state.platformId !== platformId) return;
        state.status = NOT_CONNECTED;
        showToast("You have successfully disconnected Steam!");
      })
      .addCase(manualConnectionThunks.deleteConnection.rejected, (state, action) => {
        const platformId = action.meta.arg;
        if (state.platformId !== platformId) return;
        showToast("An error has occurred trying to disconnect Steam. Please, try again.", "error");
      });
  },
});

export const steamSelector = (state) => state.integrations.steam;

export default steamSlice.reducer;
