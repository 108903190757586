import { configureConsts, configureActions } from "@utils/redux";

export const APPSFLYER_GET_DRAFT_CREDENTIALS = configureConsts("APPSFLYER_GET_DRAFT_CREDENTIALS");
export const APPSFLYER_SAVE_DRAFT_CREDENTIALS = configureConsts("APPSFLYER_SAVE_DRAFT_CREDENTIALS");
export const APPSFLYER_SAVE_CREDENTIALS = configureConsts("APPSFLYER_SAVE_CREDENTIALS");
export const APPSFLYER_TEST_CREDENTIALS = configureConsts("APPSFLYER_TEST_CREDENTIALS");
export const APPSFLYER_TEST_CREDENTIALS_ERRORS = configureConsts("APPSFLYER_TEST_CREDENTIALS_ERRORS");
export const APPSFLYER_CLEAR_CREDENTIALS_STATUS = configureConsts("APPSFLYER_CLEAR_CREDENTIALS_STATUS");
export const APPSFLYER_GET_UA_SPEND = configureConsts("APPSFLYER_GET_UA_SPEND");
export const APPSFLYER_REFRESH_UA = configureConsts("APPSFLYER_REFRESH_UA");
export const APPSFLYER_UNLINK_INTEGRATION = configureConsts("APPSFLYER_UNLINK_INTEGRATION");

const appsFlyerActions = {
  refresh: configureActions(APPSFLYER_REFRESH_UA),

  testCredentials: configureActions(APPSFLYER_TEST_CREDENTIALS, {
    request: (apiToken, callback) => ({
      type: APPSFLYER_TEST_CREDENTIALS.REQUEST,
      apiToken,
      callback,
    }),
  }),

  getDraftCredentials: configureActions(APPSFLYER_GET_DRAFT_CREDENTIALS),

  saveDraftCredentials: configureActions(APPSFLYER_SAVE_DRAFT_CREDENTIALS, {
    request: (credentials) => ({
      type: APPSFLYER_SAVE_DRAFT_CREDENTIALS.REQUEST,
      credentials,
    }),
  }),

  saveCredentials: configureActions(APPSFLYER_SAVE_CREDENTIALS, {
    request: (credentials, callback, toggleStep) => ({
      type: APPSFLYER_SAVE_CREDENTIALS.REQUEST,
      credentials,
      callback,
      toggleStep,
    }),
  }),

  getUASpend: configureActions(APPSFLYER_GET_UA_SPEND),

  unlinkIntegration: configureActions(APPSFLYER_UNLINK_INTEGRATION, {
    request: (callback) => ({
      type: APPSFLYER_UNLINK_INTEGRATION.REQUEST,
      callback,
    }),
  }),

  clearTestCredentialsErrors: () => ({
    type: APPSFLYER_TEST_CREDENTIALS_ERRORS.CLEAR,
  }),

  clearCredentialsStatus: () => ({
    type: APPSFLYER_CLEAR_CREDENTIALS_STATUS.EVENT,
  }),
};

export default appsFlyerActions;
