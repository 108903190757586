import isEmpty from "lodash/isEmpty";

export const hasExpiredOffer = (offers = []) => {
  if (isEmpty(offers)) return false;
  try {
    return offers?.find((offer) => offer.state === "EXPIRED");
  } catch (e) {
    return false;
  }
};
