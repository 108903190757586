import { configureConsts, configureActions } from "@utils/redux";

export const ADJUST_GET_DRAFT_CREDENTIALS = configureConsts("ADJUST_GET_DRAFT_CREDENTIALS");
export const ADJUST_SAVE_DRAFT_CREDENTIALS = configureConsts("ADJUST_SAVE_DRAFT_CREDENTIALS");
export const ADJUST_SAVE_CREDENTIALS = configureConsts("ADJUST_SAVE_CREDENTIALS");
export const ADJUST_TEST_CREDENTIALS = configureConsts("ADJUST_TEST_CREDENTIALS");
export const ADJUST_TEST_CREDENTIALS_ERRORS = configureConsts("ADJUST_TEST_CREDENTIALS_ERRORS");
export const ADJUST_CLEAR_CREDENTIALS_STATUS = configureConsts("ADJUST_CLEAR_CREDENTIALS_STATUS");
export const ADJUST_GET_UA_SPEND = configureConsts("ADJUST_GET_UA_SPEND");
export const ADJUST_UNLINK_INTEGRATION = configureConsts("ADJUST_UNLINK_INTEGRATION");

const adjustActions = {
  testCredentials: configureActions(ADJUST_TEST_CREDENTIALS, {
    request: (credentials, callback) => ({
      type: ADJUST_TEST_CREDENTIALS.REQUEST,
      credentials,
      callback,
    }),
  }),

  getDraftCredentials: configureActions(ADJUST_GET_DRAFT_CREDENTIALS),

  saveDraftCredentials: configureActions(ADJUST_SAVE_DRAFT_CREDENTIALS, {
    request: (credentials) => ({
      type: ADJUST_SAVE_DRAFT_CREDENTIALS.REQUEST,
      credentials,
    }),
  }),

  saveCredentials: configureActions(ADJUST_SAVE_CREDENTIALS, {
    request: (credentials, callback, toggleStep) => ({
      type: ADJUST_SAVE_CREDENTIALS.REQUEST,
      credentials,
      callback,
      toggleStep,
    }),
  }),

  unlinkIntegration: configureActions(ADJUST_UNLINK_INTEGRATION, {
    request: (callback) => ({
      type: ADJUST_UNLINK_INTEGRATION.REQUEST,
      callback,
    }),
  }),

  getUASpend: configureActions(ADJUST_GET_UA_SPEND),

  clearTestCredentialsErrors: () => ({
    type: ADJUST_TEST_CREDENTIALS_ERRORS.CLEAR,
  }),

  clearCredentialsStatus: () => ({
    type: ADJUST_CLEAR_CREDENTIALS_STATUS.EVENT,
  }),
};

export default adjustActions;
