import React, { useState, useEffect, useRef } from "react";

import { BodySmallPlus } from "@styled";

import {
  StyledWrapper,
  StyledLabel,
  StyledInputWrapper,
  StyledInput,
  StyledInputButton,
  StyledError,
} from "./SanloInput.styled";

import { ReactComponent as ErrorIcon } from "@assets/img/icons/common/alertCircle.svg";
import { ReactComponent as LinkIcon } from "@assets/img/icons/common/link.svg";

export const SanloInput = ({
  type = "text",
  hasFocus = false,
  isJumbo = false,
  isDisabled = false,
  isRequired = false,
  isReadOnly = false,
  hasCopyButton = false,
  label = "",
  placeholder = "",
  value,
  setValue = () => {},
  errorMessage = "",
  hideErrorMessage = false, // Used to show the error message, some inputs may still want to be in an error state
  pattern,
  testId = "",
  onEnter = () => {},
  onFocus = () => {},
  style = {},
}) => {
  const inputRef = useRef();

  const [inputValue, setInputValue] = useState(value || "");

  useEffect(() => {
    if (inputRef?.current && hasFocus) {
      inputRef.current.focus();
    }
  }, [hasFocus]);

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      onEnter(inputValue);
    }
  };

  const handleChange = (e) => {
    setValue(e.target.value);
    setInputValue(e.target.value);
  };

  const handleCopy = () => {
    if (inputValue) {
      navigator.clipboard.writeText(inputValue);
      showCopyToast();
    }
  };

  const showCopyToast = () => {
    let toastBg = document.getElementById("toast-bg-fade");
    let toast = document.getElementById("bottom-toast");
    toastBg.className = "show";
    toast.className = "show";
    setTimeout(function () {
      toastBg.className = toastBg.className.replace("show", "");
    }, 3000);
    setTimeout(function () {
      toast.className = toast.className.replace("show", "");
    }, 3000);
  };

  return (
    <StyledWrapper style={style}>
      {label && <StyledLabel>{label}</StyledLabel>}

      <StyledInputWrapper>
        <StyledInput
          isJumbo={isJumbo}
          hasError={Boolean(errorMessage)}
          onKeyPress={onKeyPress}
          ref={inputRef}
          disabled={isDisabled}
          readOnly={isReadOnly}
          onChange={handleChange}
          onFocus={onFocus}
          type={type}
          required={isRequired}
          pattern={pattern}
          placeholder={placeholder}
          value={inputValue}
          data-test-id={testId}
        />

        {hasCopyButton && (
          <StyledInputButton isJumbo={isJumbo} onClick={handleCopy}>
            <LinkIcon />
            <BodySmallPlus>Copy</BodySmallPlus>
          </StyledInputButton>
        )}
      </StyledInputWrapper>

      {!hideErrorMessage && errorMessage && (
        <StyledError className="sanlo-input-error">
          <ErrorIcon />
          {errorMessage}
        </StyledError>
      )}
    </StyledWrapper>
  );
};
