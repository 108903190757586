import { useEffect, useRef } from "react";

export default function useShadowScroll(scrollFromContent = true) {
  const wrapperRef = useRef();
  const contentRef = useRef();
  const shadowBottomRef = useRef();
  const shadowTopRef = useRef();

  const handleShadowScroll = (event) => {
    const content = contentRef.current;
    const wrapper = wrapperRef.current;
    const shadowBottom = shadowBottomRef.current;
    const shadowTop = shadowTopRef.current;
    const contentScrollHeight = content.scrollHeight - wrapper.offsetHeight;

    const currentScroll = event.target.scrollTop / contentScrollHeight;
    shadowTop.style.opacity = currentScroll * 10;
    shadowBottom.style.opacity = (1 - currentScroll) * 10;
  };

  useEffect(() => {
    // Check if the scrolling behavior is taken from wrapper or content
    const content = scrollFromContent ? contentRef.current : wrapperRef.current;
    if (content) {
      content.addEventListener("scroll", handleShadowScroll);
      return () => {
        content.removeEventListener("scroll", handleShadowScroll);
      };
    }
  }, [scrollFromContent]);

  return [wrapperRef, contentRef, shadowBottomRef, shadowTopRef];
}
