import React from "react";
import { useSelector } from "react-redux";
import { seriesSelector } from "@redux/webshops/catalog/series/seriesSlice";
import { FormControl, Input } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { SubsectionTitle } from "@components/catalog";
import { NameFieldTooltip } from "@components/catalog/tooltip";

export const General = () => {
  const { selectedSeries = null } = useSelector(seriesSelector);
  const { register, formState } = useFormContext();
  const { errors } = formState;

  return (
    <FormControl>
      <SubsectionTitle isRequired tooltip={<NameFieldTooltip />}>
        Name
      </SubsectionTitle>

      <Input
        {...register("name", { required: true, disabled: !!selectedSeries?.publishedAt })}
        placeholder={"Pick a good name"}
        borderColor={errors?.name ? "brandRed.400" : "transparent"}
      />
    </FormControl>
  );
};
