import React from "react";

import { BodySmall14 } from "@styled";

import { StyledPieChartLegend } from "./PieChart.styled";

const PieChart = ({ data }) => {
  const { chunks } = data;

  return (
    <StyledPieChartLegend>
      <table className="details">
        <tbody>
          {Object.keys(chunks).map((chunkKey) => (
            <tr key={`alert_row_key_${chunkKey.toLowerCase().replace(/ /g, "_")}`}>
              <td className="pie_chart_legend_td">
                <div className="pie_chart_legend" style={{ background: chunks[chunkKey].color }} />
              </td>
              <td className="label">
                <BodySmall14>{chunks[chunkKey].label}</BodySmall14>
              </td>
              <td style={{ color: chunks[chunkKey].color || "white" }}>
                <BodySmall14>{chunks[chunkKey].display}</BodySmall14>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </StyledPieChartLegend>
  );
};

export default PieChart;
