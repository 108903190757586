import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import actions from "@redux/actions";

import PlatformPicker from "./PlatformPicker";
import FullScreenModal from "../fullscreen-modal/FullScreenModal";
import { RemoveMMPModal } from "@components/integrations/RemoveMMPModal";

import { unlinkMMP, checkPlatformConflict } from "@utils/defaults/platforms";

import { PLATFORM_PICKER_CATEGORIES } from "./utils";

const PlatformPickerContainer = ({ isOpen, setDisplayPlatformsModal, selectableCategories, onModalClose }) => {
  const dispatch = useDispatch();

  const integrations = useSelector((state) => state.integrations || {});

  const [selectedPlatform, setSelectedPlatform] = useState(integrations.appsflyer);
  const [selectedPlatformModalOpen, setSelectedPlatformModalOpen] = useState(false);

  const [clickedPlatform, setClickedPlatform] = useState({
    option: null,
    hasAction: null,
  });

  const [removeMMPModalVisible, toggleRemoveMMPModal] = useState(false);

  const handleConnectClick = (option, hasAction) => {
    if (checkPlatformConflict(option, integrations)) {
      onModalClose();
      toggleRemoveMMPModal(true);
      setClickedPlatform({ option, hasAction });
      return;
    }

    handlePlatformAction(option, hasAction);
  };

  const handlePlatformAction = (option, hasAction) => {
    if (hasAction) {
      if (actions.integrations[option.id]?.connect) {
        dispatch(actions.integrations[option.id].connect.request());
      } else {
        setDisplayPlatformsModal(false);
        setSelectedPlatform(option);
        setSelectedPlatformModalOpen(true);
      }
    }
  };

  return (
    <>
      {isOpen && (
        <PlatformPicker
          selectableCategories={selectableCategories}
          onModalClose={onModalClose}
          onOptionConnectClick={handleConnectClick}
          integrations={integrations}
          categories={PLATFORM_PICKER_CATEGORIES}
        />
      )}

      <RemoveMMPModal
        toggleRemoveMMPModal={toggleRemoveMMPModal}
        removeMMPModalVisible={removeMMPModalVisible}
        integration={checkPlatformConflict(selectedPlatform, integrations)}
        handleRemoveMMPIntegration={() => {
          unlinkMMP(checkPlatformConflict(clickedPlatform, integrations));
          toggleRemoveMMPModal(false);
          handlePlatformAction(clickedPlatform.option, clickedPlatform.hasAction);
        }}
      />

      <FullScreenModal
        saveProgress={selectedPlatform.canSaveProgress}
        modalContent={selectedPlatform}
        openModal={selectedPlatformModalOpen}
        closeModal={() => {
          setSelectedPlatformModalOpen(false);
        }}
      />
    </>
  );
};

export default PlatformPickerContainer;
