import React, { useEffect, useRef } from "react";

import { StyledSlider } from "../OnboardingExperienceStyles";

import symbols from "@utils/symbols";

const GradientSlider = ({ value, min, max, onSliderDrag, isGrowthOffer, interval = 1000, testId = "" }) => {
  const emptyProgressRef = useRef();
  const progressRef = useRef();
  const sliderButtonRef = useRef();

  const getProgressPercentage = () => {
    return ((value - min) * 100) / (max - min);
  };

  const handleDrag = (e) => {
    e = e || window.event;
    e.preventDefault();

    document.onmouseup = closeDragElement;
    document.onmousemove = handleElementDrag;
  };

  const handleElementDrag = (e) => {
    e = e || window.event;
    e.preventDefault();

    const { clientX } = e;

    const sliderButtonWidth = sliderButtonRef.current.offsetWidth;

    // offset from clientX = 0 to div start (margin)
    const sliderOffset = progressRef.current.getBoundingClientRect();
    const { left } = sliderOffset;

    const sliderEnd = emptyProgressRef.current.offsetWidth;

    let newPosition = ((clientX - left + sliderButtonWidth / 2) / sliderEnd) * 100;

    if (newPosition < 0) newPosition = 0;
    if (newPosition > 100) newPosition = 100;

    const newValue = Math.round((min + ((max - min) * newPosition) / 100) / interval) * interval;

    onSliderDrag(newValue);

    progressRef.current.style.width = newPosition + "%";
  };

  const closeDragElement = () => {
    document.onmouseup = null;
    document.onmousemove = null;
  };

  useEffect(() => {
    const sliderRef = sliderButtonRef.current;
    sliderRef.addEventListener("mousedown", handleDrag);
    return () => {
      sliderRef.removeEventListener("mousedown", handleDrag);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <StyledSlider onClick={handleElementDrag} isGrowthOffer={isGrowthOffer} data-test-id={testId}>
      <div
        ref={progressRef}
        className="completed-bar"
        style={{
          width: getProgressPercentage() + "%",
        }}
      >
        <div ref={sliderButtonRef} className="current-progress-indicator">
          <div className="current-progress-icon tag-sanlo-moneybag-slider">{symbols.MoneyBag()}</div>
        </div>
      </div>
      <div ref={emptyProgressRef} className="empty-bar" />
    </StyledSlider>
  );
};

export default GradientSlider;
