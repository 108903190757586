import SanloWebApiService from "./services";

import actions from "@redux/actions";

import appStoreActions, { APP_STORE_SAVE_CREDENTIALS, APP_STORE_UNLINK_INTEGRATION } from "./actions";

import { showToast } from "@utils/redux";

const appStoreMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    switch (action.type) {
      case APP_STORE_SAVE_CREDENTIALS.REQUEST:
        SanloWebApiService.appStoreSaveCredentials(action.credentials)
          .then((response) => response.data)
          .then((status) => {
            dispatch(appStoreActions.saveCredentials.response(status));
            dispatch(actions.earnings.appstoreGetEarnings.request());
            dispatch(actions.integrations.base.getIntegrationsConnections.request());
            if (action.callback) action.callback();
            showToast("You have successfully connected to your app store account! ");
          })
          .catch((error) => {
            dispatch(appStoreActions.saveCredentials.error(error));
          });
        break;

      case APP_STORE_UNLINK_INTEGRATION.REQUEST:
        SanloWebApiService.appStoreDeleteCredentials()
          .then((response) => {
            dispatch(appStoreActions.unlinkIntegration.response(response));
            dispatch(actions.integrations.base.getIntegrationsConnections.request());
            if (action.callback) action.callback();
          })
          .catch((error) => {
            if (action.error) action.error();
            dispatch(appStoreActions.unlinkIntegration.error(error));
          });
        break;

      default:
        return;
    }
  };

export default appStoreMiddleware;
