import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";

import { BodySmall14, BodySmallAlt, BodySmallPlus } from "@styled";
import { SanloTooltip } from "@common/tooltips/sanlo-tooltip/SanloTooltip";
import { StyledTransactionRow } from "./StyledTransactionsHistory";
import { StyledProgressTooltip } from "../StyledAdvanceCard";
import TooltipHeader from "../dynamic-progress-bar/tooltips/TooltipHeader";
import TooltipRow from "../dynamic-progress-bar/tooltips/TooltipRow";

import { LOGOS } from "@utils/general";
import { capitalizeString } from "@utils/format";
import { currencyFormat, hasConversion } from "@utils/currency";
import advCopy from "@utils/text/advance";
import text from "@utils/text";

import { ReactComponent as ToolTipIcon } from "@assets/img/icons/common/tooltip.svg";

const TransactionRow = ({ advance, transaction, tooltipId }) => {
  const { advanceCurrency, advanceType, cryptoAsRepaymentSource } = advance;
  const {
    payDate,
    amountInAdvanceCurrency,
    amountInOriginCurrency,
    bankDetails,
    transactionFrom,
    transactionDetails,
    typeOfTransaction,
  } = transaction;

  const platformMetadata = useSelector((state) => state.integrations.advances.data.lists.platforms[transactionFrom]);

  let status = (transaction.status || "").toLowerCase();
  let dateCopy = "";
  let platformLogo = "";
  let signedAmount = "";

  switch (typeOfTransaction) {
    case "DISBURSEMENT":
      dateCopy = "Disbursed";
      platformLogo = platformMetadata?.icon;
      signedAmount = "+";
      break;

    case "REPAYMENT":
      dateCopy = "Repaid";
      platformLogo = platformMetadata?.icon;
      signedAmount = "-";
      break;

    case "FEE":
      dateCopy = "Withdrawn";
      platformLogo = LOGOS.bank;
      signedAmount = "-";
      break;

    default:
      break;
  }

  dateCopy += ` on ${moment(payDate).format("MMM DD, YYYY")}`;
  signedAmount = signedAmount.concat(currencyFormat(amountInAdvanceCurrency, advanceCurrency));

  const getTransactionTooltip = (typeOfTransaction) => {
    if (typeOfTransaction === "FEE") {
      return <FeeTooltip advance={advance} status={status} transactionDetails={transactionDetails} />;
    } else {
      const platformName = advance.advanceType === "MERCHANT_CASH_ADVANCE" ? platformMetadata?.name : null;
      return (
        <TransactionTooltip
          advance={advance}
          transaction={transaction}
          status={status}
          platformName={platformName}
          platformLogo={platformLogo}
          transactionDetails={transactionDetails}
          repaymentAmount={amountInAdvanceCurrency}
          repaymentAmountOrigin={amountInOriginCurrency}
        />
      );
    }
  };

  return (
    <StyledTransactionRow status={status} className="transaction-row">
      {/* DATE */}
      <div className="row-content">
        <BodySmall14>{dateCopy}</BodySmall14>
      </div>

      {/* AMOUNT */}
      <div className="row-content">
        <div className="amount-container">
          <img src={platformLogo} alt="logo" />
          <BodySmallPlus>{signedAmount}</BodySmallPlus>
          {Boolean(transactionDetails) && (
            <>
              <ToolTipIcon id={tooltipId} className="transaction-tooltip" />
              <SanloTooltip
                clickable
                jumbo={advanceType === "MERCHANT_CASH_ADVANCE"}
                resetBottom
                target={tooltipId}
                text={getTransactionTooltip(typeOfTransaction)}
                placement={cryptoAsRepaymentSource ? "top" : "right"}
                width={cryptoAsRepaymentSource ? 360 : ""}
              />
            </>
          )}
        </div>
      </div>

      {/* BANK NANE AND ACCOUNT NUMBER */}
      <div className="row-content">
        <BodySmall14>
          {bankDetails?.bankName} ···· {bankDetails?.maskedBankAccountNumber}
        </BodySmall14>
      </div>

      {/* TRANSACTION STATUS */}
      <div className="row-content">
        <div className="status">
          <BodySmall14 id={tooltipId + "-status"} className="chip">
            {capitalizeString(status)}
          </BodySmall14>
          <SanloTooltip
            jumbo
            target={tooltipId + "-status"}
            text={<BodySmallAlt>{advCopy.transactionTooltips[status]()}</BodySmallAlt>}
            placement="top"
          />
        </div>
      </div>
    </StyledTransactionRow>
  );
};

const FeeTooltip = ({ advance, status, transactionDetails }) => {
  const { totalAmountInAdvanceCurrency, originToAdvanceRate } = transactionDetails;

  return (
    <StyledProgressTooltip state={status}>
      <div className={"tooltip-content"}>
        <TooltipRow
          label={text.t("wire_fee")}
          display={totalAmountInAdvanceCurrency}
          hint={<span>{text.t("wire_fee_hint")}</span>}
          color
        />
        {hasConversion(advance) && (
          <TooltipRow
            label={text.t("exchange_rate")}
            display={advCopy.exchangeDisplay(advance, {
              originToAdvanceRateOverride: originToAdvanceRate,
            })}
            hint={advCopy.exchangeDisclosures.fee()}
          />
        )}
      </div>
    </StyledProgressTooltip>
  );
};

const TransactionTooltip = ({
  advance,
  transaction,
  status,
  platformName,
  platformLogo,
  repaymentAmount,
  repaymentAmountOrigin,
  transactionDetails,
}) => {
  const { fromDate, toDate, totalAmountInAdvanceCurrency, totalAmountOriginCurrency, originToAdvanceRate } =
    transactionDetails;

  const {
    advanceType,
    advanceCurrency,
    capitalFeePercentage,
    totalAmount,
    numberOfRepayments,
    cryptoAsRepaymentSource,
  } = advance;

  const formatToMonth = (date) => moment(date).format("MMM Do");
  const earningsPeriodDisplay = `${formatToMonth(fromDate)} - ${formatToMonth(toDate)}`;

  if (advanceType === "NON_REVENUE_FIXED_TERMS_ADVANCE") {
    return (
      <StyledProgressTooltip state={status}>
        <div className="tooltip-content">
          <TooltipRow
            strong
            color
            label="Repaid Amount"
            hint={`Including ${capitalFeePercentage}% of capital fee`}
            display={advCopy.fixedTermCurrencyDisplay(totalAmount / numberOfRepayments, advanceCurrency)}
          />
        </div>
      </StyledProgressTooltip>
    );
  }

  return (
    <StyledProgressTooltip state={status}>
      {!cryptoAsRepaymentSource && <TooltipHeader logo={platformLogo} title={platformName} />}
      <div className={"tooltip-content"}>
        <TooltipRow label={text.t("earnings_period")} display={earningsPeriodDisplay} strong />
        {!cryptoAsRepaymentSource && (
          <TooltipRow
            label={text.t("total_earnings")}
            display={advCopy.currencyDisplay(advance, {
              inAdvance: totalAmountInAdvanceCurrency,
              inOrigin: totalAmountOriginCurrency,
            })}
            strong
          />
        )}
        <TooltipRow
          label={status === "pending" ? text.t("est_repayment_amount") : text.t("repayment_amount")}
          display={advCopy.currencyDisplay(
            advance,
            {
              inAdvance: repaymentAmount,
              inOrigin: repaymentAmountOrigin,
            },
            true,
          )}
          hint={<span>{advCopy.repaymentAmountHint(advance, transaction)}</span>}
          accordion
          color
          strong
        />

        {hasConversion(advance) && (
          <TooltipRow
            label={status === "pending" ? text.t("est_exchange_rate") : text.t("exchange_rate")}
            display={advCopy.exchangeDisplay(advance, {
              status,
              originToAdvanceRateOverride: originToAdvanceRate,
            })}
            hint={advCopy.exchangeDisclosures[status](advance)}
            accordion
            strong
          />
        )}
      </div>
    </StyledProgressTooltip>
  );
};

export default TransactionRow;
