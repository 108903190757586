import React from "react";

import EarnedRow from "../dashboard/card-components/EarnedRow";

import { currencyFormat } from "@utils/currency";
import text from "@utils/text";

const AdvanceHeader = ({ advance, status }) => {
  const { totalAmount, repaymentAmountLeft, advanceCurrency, advanceType, cryptoAsRepaymentSource, cryptoCurrency } =
    advance;
  const formattedRepaid = currencyFormat(repaymentAmountLeft, advanceCurrency);
  const formattedTotal = currencyFormat(totalAmount, advanceCurrency);

  let titleText = formattedRepaid;
  let subtitleText = `to be repaid of ${formattedTotal}`;
  let titleTooltipText = text.t("advance_title_tooltip_text", { cryptoAsRepaymentSource, cryptoCurrency });
  if (status === "repaid") {
    titleText = `${formattedTotal}`;
    subtitleText = `repaid`;
    titleTooltipText = "Total Growth Capital repaid including one-time fees.";
  }

  return (
    <EarnedRow
      amount={titleText}
      subtitle={subtitleText}
      tooltipText={advanceType === "NON_REVENUE_FIXED_TERMS_ADVANCE" ? null : titleTooltipText}
      cryptoAsRepaymentSource={cryptoAsRepaymentSource}
    />
  );
};

export default AdvanceHeader;
