import React from "react";
import Lottie from "lottie-react";
import styled from "styled-components";

import animationData from "./anim.json";

export const StyledFullscreenSpinner = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10000;

  .fullscreen-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 4rem;
    height: 4rem;
    svg,
    path,
    g {
      fill: ${({ theme }) => theme.colorRed} !important;
    }
  }
`;

export const FullscreenSpinner = () => {
  return (
    <StyledFullscreenSpinner>
      <Lottie className="fullscreen-spinner" animationData={animationData} />
    </StyledFullscreenSpinner>
  );
};

export default FullscreenSpinner;
