import React from "react";

import { ReactComponent as CopyIcon } from "@assets/img/icons/common/copy.svg";

import symbols from "@utils/symbols";
import {
  Box,
  Flex,
  Text,
  Link,
  Icon,
  Divider,
  Checkbox,
  Button,
  IconButton,
  useClipboard,
  VStack,
} from "@chakra-ui/react";
import { GuideMedia } from "@components/integrations/connection-guide";

export const SteamStep1 = ({
  platform = {
    id: "steam",
    name: "Steam",
    platformId: "steam",
  },
  onComplete = () => {},
}) => {
  const { onCopy } = useClipboard("data-team@sanlo.com");
  const [grantedAccess, setGrantedAccess] = React.useState(false);
  return (
    <Flex height={"full"} gap="32px">
      <Box maxW={"480px"}>
        <Flex gap={"16px"}>
          <Flex
            mt={"12px"}
            flexShrink={0}
            w="24px"
            h="24px"
            bg={"dark.200"}
            border={"1.5px solid"}
            borderColor={"textDefault.400"}
            justifyContent={"center"}
            alignItems={"center"}
            borderRadius={"full"}
          >
            <Text size="mini" fontSize={"xs"} color={"textWhite.400"}>
              1
            </Text>
          </Flex>
          <Flex flexDir={"column"} gap={"16px"}>
            <Text size={"regular"} fontWeight={500} color={"textWhite.400"}>
              Log into your{" "}
              <Link
                color={"brandRed.400"}
                _hover={{ color: "brandRed.400" }}
                href="https://partner.steamgames.com/?goto=%2Fpub%2Fusers%2F"
                isExternal
              >
                Steamworks
              </Link>{" "}
              account and complete the steps below:
            </Text>
            <Text size={"small"}>
              1.1 Go to{" "}
              <Text as="span" color="textWhite.400" fontWeight={700}>
                “Users & Permissions”
              </Text>{" "}
              tab and click{" "}
              <Text as="span" color="textWhite.400" fontWeight={700}>
                “Manage users”
              </Text>
              .
            </Text>
            <VStack alignItems="start" gap={"16px"}>
              <Text>
                1.2 Enter email address:{" "}
                <Text as="span" fontWeight={500} size="small" color="textWhite.400">
                  data-team@sanlo.com
                </Text>{" "}
                <IconButton
                  onClick={onCopy}
                  minW={0}
                  icon={<Icon as={CopyIcon} />}
                  verticalAlign={"bottom"}
                  variant={"unstyled"}
                  w="16px"
                  h={"16px"}
                />
                .
              </Text>
              <Text size="small" lineHeight={"20px"} letterSpacing={"0.14px"}>
                1.3 Check the boxes{" "}
                <Text as="span" fontWeight={500} size="small" color="textWhite.400">
                  “View Financials (Publisher-wide)”{" "}
                </Text>
                and{" "}
                <Text as="span" fontWeight={500} size="small" color="textWhite.400">
                  “View Financial Info”
                </Text>
                .
              </Text>
              <Text size={"small"}>
                1.4 Click{" "}
                <Text as="span" color={"textWhite.400"} fontWeight={500}>
                  “Invite User”
                </Text>
                .
              </Text>
            </VStack>
          </Flex>
        </Flex>
        <Flex gap={"16px"} mt="24px">
          <Flex
            mt={"12px"}
            flexShrink={0}
            w="24px"
            h="24px"
            bg={"dark.200"}
            border={"1.5px solid"}
            borderColor={"textDefault.400"}
            justifyContent={"center"}
            alignItems={"center"}
            borderRadius={"full"}
          >
            <Text size="mini" fontSize={"xs"} color={"textWhite.400"}>
              2
            </Text>
          </Flex>
          <Flex flexDir={"column"} gap={"16px"}>
            <Text size={"regular"} fontWeight={500} color={"textWhite.400"}>
              Check the box below and click “Complete” button to finish setup {symbols.pointDown}
            </Text>
            <Text size={"small"} lineHeight={"20px"}>
              2.1 Expect an email from Steamworks within two business days to{" "}
              <Text as="span" color="textWhite.400" fontWeight={700}>
                confirm Sanlo's access
              </Text>
              . An{" "}
              <Text as="span" color="textWhite.400" fontWeight={700}>
                admin will need to approve this{" "}
              </Text>
              for Sanlo to access the necessary data.
            </Text>
          </Flex>
        </Flex>
        <Divider borderBottomWidth={0} height={"1px"} bg="dark.200" />
        <Checkbox size={"sm"} isChecked={grantedAccess} onChange={(e) => setGrantedAccess(e.target.checked)}>
          <Text size={"small"} fontWeight={500}>
            I have invited and granted Sanlo access to my Steam data.
          </Text>
        </Checkbox>
        <Button variant={"primary"} width={"full"} mt="8px" isDisabled={!grantedAccess} onClick={onComplete}>
          Complete
        </Button>
        <Text size="small" mt="8px">
          For more details, see following document from Steam{" "}
          <Link
            href="https://partner.steamgames.com/doc/gettingstarted/managing_users"
            textDecorationLine="underline"
            fontWeight={500}
            isExternal
          >
            here
          </Link>
          .
        </Text>
      </Box>
      <Box p="12px" rounded={"12px"} bg="dark.400" h="fit-content">
        <GuideMedia
          mediaSlider={[
            require(`./assets/steam-guide-1.png`),
            require(`./assets/steam-guide-2.png`),
            require(`./assets/steam-guide-3.png`),
          ]}
          platformName={platform.name}
        />
      </Box>
    </Flex>
  );
};
