import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getResponseStatus, getRequestStatus, getErrorStatus, setStatusDefaults } from "@utils/redux";

import AuthService from "./authMethodsApi";

import { errorWrapper } from "@redux/http";

export const authMethodsThunks = {
  getAuthMethods: createAsyncThunk("webshops/auth/getAuthMethods", async ({ webshopId }, { rejectWithValue }) => {
    return errorWrapper(async () => {
      const res = await AuthService.getAuthMethods(webshopId);
      return res.data;
    }, rejectWithValue);
  }),
  addAuthMethod: createAsyncThunk("webshops/auth/addAuthMethod", async ({ data, webshopId }, { rejectWithValue }) => {
    return errorWrapper(async () => {
      const res = await AuthService.addAuthMethod(data, webshopId);
      return res.data;
    }, rejectWithValue);
  }),
  updateAuthMethod: createAsyncThunk(
    "webshops/auth/updateAuthMethod",
    async ({ data, webshopId }, { rejectWithValue }) => {
      return errorWrapper(async () => {
        //webhokUrl is not needed in the request and is deprecated
        const { id, webhookUrl, authCategory, ...rest } = data;
        const res = await AuthService.updateAuthMethod(rest, webshopId, id);
        return res.data;
      }, rejectWithValue);
    },
  ),
  deleteAuthMethod: createAsyncThunk(
    "webshops/auth/deleteAuthMethod",
    async ({ webshopId, authMethod }, { rejectWithValue }) => {
      return errorWrapper(async () => {
        await AuthService.deleteAuthMethod(webshopId, authMethod.data?.id);
        return authMethod;
      }, rejectWithValue);
    },
  ),
  updateAuthMethodFile: createAsyncThunk(
    "webshops/auth/updateAuthMethodFile",
    async ({ data, webshopId, authMethodId }, { rejectWithValue }) => {
      return errorWrapper(async () => {
        const res = await AuthService.updateAuthMethodFile(data, webshopId, authMethodId);
        return res.data;
      }, rejectWithValue);
    },
  ),
  deleteAuthMethodFile: createAsyncThunk(
    "webshops/auth/deleteAuthMethodFile",
    async ({ webshopId, authMethod }, { rejectWithValue }) => {
      return errorWrapper(async () => {
        await AuthService.deleteAuthMethodFile(webshopId, authMethod.id);
        return authMethod;
      }, rejectWithValue);
    },
  ),
};

export const authMethodsSlice = createSlice({
  name: "authMethods",
  initialState: {
    authProviders: [],
    authProvidersMap: {},
    getAuthMethods: {
      requestStatus: setStatusDefaults(),
    },
    addAuthMethod: {
      data: null,
      requestStatus: setStatusDefaults(),
    },
    updateAuthMethod: {
      data: null,
      requestStatus: setStatusDefaults(),
    },
    deleteAuthMethod: {
      requestStatus: setStatusDefaults(),
    },
    updateAuthMethodFile: {
      data: null,
      requestStatus: setStatusDefaults(),
    },
    deleteAuthMethodFile: {
      data: null,
      requestStatus: setStatusDefaults(),
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authMethodsThunks.getAuthMethods.fulfilled, (state, action) => {
        const { payload } = action;
        const { methods = [] } = payload;
        state.authProviders = methods;
        state.authProvidersMap = methods.reduce((acc, provider) => {
          acc[provider.name] = provider;
          return acc;
        }, {});
        state.getAuthMethods.requestStatus = getResponseStatus();
      })
      .addCase(authMethodsThunks.getAuthMethods.rejected, (state) => {
        state.authProviders = [];
        state.authProvidersMap = {};
        state.getAuthMethods.requestStatus = getErrorStatus();
      })
      .addCase(authMethodsThunks.getAuthMethods.pending, (state) => {})
      .addCase(authMethodsThunks.addAuthMethod.pending, (state) => {
        state.addAuthMethod.requestStatus = getRequestStatus();
      })
      .addCase(authMethodsThunks.addAuthMethod.fulfilled, (state, action) => {
        const { payload } = action;
        const index = state.authProviders.findIndex((provider) => provider.name === payload.authMethod);
        state.authProviders[index] = { ...state.authProviders[index], active: true, data: { ...payload } };
        state.authProvidersMap[payload.authMethod] = {
          ...state.authProvidersMap[payload.authMethod],
          active: true,
          data: payload,
        };
        state.addAuthMethod.requestStatus = getResponseStatus();
        state.addAuthMethod.data = payload;
      })
      .addCase(authMethodsThunks.addAuthMethod.rejected, (state, action) => {
        state.addAuthMethod.requestStatus = getErrorStatus();
      })
      .addCase(authMethodsThunks.updateAuthMethod.pending, (state) => {
        state.updateAuthMethod.requestStatus = getRequestStatus();
      })
      .addCase(authMethodsThunks.updateAuthMethod.fulfilled, (state, action) => {
        const { payload } = action;
        const index = state.authProviders.findIndex((provider) => provider.data?.id === payload.id);
        state.authProviders[index] = { ...state.authProviders[index], data: { ...payload } };
        state.authProvidersMap[payload.authMethod] = { ...state.authProviders[index], data: { ...payload } };
        state.updateAuthMethod.requestStatus = getResponseStatus();
        state.updateAuthMethod.data = payload;
      })
      .addCase(authMethodsThunks.updateAuthMethod.rejected, (state, action) => {
        state.updateAuthMethod.requestStatus = getErrorStatus();
      })
      .addCase(authMethodsThunks.deleteAuthMethod.pending, (state) => {
        state.deleteAuthMethod.requestStatus = getRequestStatus();
      })
      .addCase(authMethodsThunks.deleteAuthMethod.fulfilled, (state, action) => {
        const { payload } = action;
        state.authProviders = state.authProviders.map((provider) =>
          provider.data?.id === payload.data?.id ? { ...provider, active: false, data: null } : provider,
        );
        state.authProvidersMap[payload.name] = { ...payload, active: false, data: null };
        state.deleteAuthMethod.requestStatus = getResponseStatus();
      })
      .addCase(authMethodsThunks.deleteAuthMethod.rejected, (state, action) => {
        state.deleteAuthMethod.requestStatus = getErrorStatus();
      })
      .addCase(authMethodsThunks.updateAuthMethodFile.pending, (state) => {
        state.updateAuthMethodFile.requestStatus = getRequestStatus();
      })
      .addCase(authMethodsThunks.updateAuthMethodFile.fulfilled, (state, action) => {
        const { payload } = action;
        state.authProviders = state.authProviders.map((provider) =>
          provider.name === payload.authMethod ? { ...provider, data: payload } : provider,
        );
        state.authProvidersMap[payload.authMethod].data = payload;
        state.updateAuthMethodFile.requestStatus = getResponseStatus();
        state.updateAuthMethodFile.data = payload;
      })
      .addCase(authMethodsThunks.updateAuthMethodFile.rejected, (state, action) => {
        state.updateAuthMethodFile.requestStatus = getErrorStatus();
      })
      .addCase(authMethodsThunks.deleteAuthMethodFile.pending, (state) => {
        state.deleteAuthMethodFile.requestStatus = getRequestStatus();
      })
      .addCase(authMethodsThunks.deleteAuthMethodFile.fulfilled, (state, action) => {
        const { payload } = action;
        state.authProviders = state.authProviders.map((provider) =>
          provider.name === payload.authMethod ? { ...provider, file: null } : provider,
        );
        state.authProvidersMap[payload.authMethod] = { ...payload, file: null };
        state.deleteAuthMethodFile.requestStatus = getResponseStatus();
        state.deleteAuthMethodFile.data = payload;
      })
      .addCase(authMethodsThunks.deleteAuthMethodFile.rejected, (state, action) => {
        state.deleteAuthMethodFile.requestStatus = getErrorStatus();
      });
  },
});

export const authMethodsSelector = (state) => state.webshops.settings.authMethods;
export const getAuthMethodsSelector = (state) => state.webshops.settings.authMethods.getAuthMethods;

export default authMethodsSlice.reducer;
