import HTTP from "@redux/http";

const SANLO_WEB_API_URL = process.env.REACT_APP_SANLO_WEB_API_URL;
const BASE = `${SANLO_WEB_API_URL}/api/integrations/marketing/appsflyer`;

export default {
  appsFlyerUASpend: ({ fromDate, toDate }) => {
    return HTTP.req.get(`${BASE}/ua-spend`, { params: { fromDate, toDate } });
  },
};
