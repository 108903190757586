import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { integrationsConnectionsThunks } from "../base/baseSlice";
import AdNetworksService from "./adNetworksApi";
import { showToast } from "@utils/redux";
import { OAUTH_FLOW_ARCHETYPE_ID } from "@src/consts/ad-networks";

import { getErrorStatus, getRequestStatus, getResponseStatus, setStatusDefaults } from "@utils/redux";

export const adNetworksThunks = {
  getAvailableNetworks: createAsyncThunk("adNetworks/getAvailableNetworks", async () => {
    const res = await AdNetworksService.getAvailableNetworks();
    return res.data;
  }),
  createAdNetworkConnection: createAsyncThunk("adNetworks/createAdNetworkConnection", async (archetypeId) => {
    const res = await AdNetworksService.createAdNetworkConnection(archetypeId);
    return res.data;
  }),
  addCredentials: createAsyncThunk(
    "adNetworks/addCredentials",
    async ({ credentials = [], redirectUri = "" }, { getState, rejectWithValue }) => {
      const body = {
        archetypeId: getState().integrations.adNetworks.selectedAdNetwork.data.id,
        credentials: credentials.map(([key, value]) => ({ archetypeId: key, value })),
        redirectUri,
      };
      try {
        const res = await AdNetworksService.addCredentials(body);
        if (res.data.status === "NO_PERMISSIONS") {
          return rejectWithValue({
            rejectedCredentials: res.data.credentialArchetypes,
          });
        }
        return res.data;
      } catch (e) {
        return rejectWithValue({
          message: "There was an error...",
        });
      }
    },
  ),
  getNetworkByConnectionId: createAsyncThunk("adNetworks/getNetworkByConnectionId", async (connectionId) => {
    const res = await AdNetworksService.getNetworkByConnectionId(connectionId);
    return res.data;
  }),
  credentialsCallback: createAsyncThunk("adNetworks/credentialsCallback", async (credentials = {}, { dispatch }) => {
    const res = await AdNetworksService.credentialsCallback(credentials);
    dispatch(integrationsConnectionsThunks.getIntegrationsConnections());
    dispatch(adNetworksThunks.getAvailableNetworks());
    return res.data;
  }),
};

export const adNetworksSlice = createSlice({
  name: "adNetworks",
  initialState: {
    availableAdNetworks: {
      data: [],
      requestStatus: setStatusDefaults(),
    },
    selectedAdNetwork: {
      data: null,
      requestStatus: setStatusDefaults(),
    },
    connectedAdNetworks: [],
    connectionCreation: {
      requestStatus: setStatusDefaults(),
    },
    addCredentials: {
      requestStatus: setStatusDefaults(),
    },
    credentialsCallback: {
      requestStatus: setStatusDefaults(),
    },
  },
  reducers: {
    setSelectedAdNetwork: (state, action) => {
      state.selectedAdNetwork.data = action.payload;
      state.selectedAdNetwork.requestStatus = setStatusDefaults();
    },
    resetSelectedAdNetwork: (state) => {
      state.selectedAdNetwork.data = null;
      state.selectedAdNetwork.requestStatus = setStatusDefaults();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(adNetworksThunks.getAvailableNetworks.pending, (state) => {
      state.availableAdNetworks.requestStatus = getRequestStatus();
    });
    builder.addCase(adNetworksThunks.getAvailableNetworks.fulfilled, (state, action) => {
      state.availableAdNetworks.data = action.payload.availableConnectionArchetypes;
      state.availableAdNetworks.requestStatus = getResponseStatus();
    });
    builder.addCase(adNetworksThunks.getAvailableNetworks.rejected, (state) => {
      state.availableAdNetworks.requestStatus = getErrorStatus();
    });
    builder.addCase(adNetworksThunks.createAdNetworkConnection.pending, (state) => {
      state.connectionCreation.requestStatus = getRequestStatus();
    });
    builder.addCase(adNetworksThunks.createAdNetworkConnection.fulfilled, (state) => {
      state.connectionCreation.requestStatus = getResponseStatus();
    });
    builder.addCase(adNetworksThunks.createAdNetworkConnection.rejected, (state) => {
      state.connectionCreation.requestStatus = getErrorStatus();
    });
    builder.addCase(adNetworksThunks.addCredentials.pending, (state) => {
      state.addCredentials.requestStatus = getRequestStatus();
    });
    builder.addCase(adNetworksThunks.addCredentials.fulfilled, (state, action) => {
      state.addCredentials.requestStatus = getResponseStatus();
      const { archetypeId, redirectUrl } = action.payload;

      if (redirectUrl) {
        localStorage.setItem(OAUTH_FLOW_ARCHETYPE_ID, archetypeId);
        window.location.href = redirectUrl;
      }
    });
    builder.addCase(adNetworksThunks.addCredentials.rejected, (state) => {
      state.addCredentials.requestStatus = getErrorStatus();
      showToast("An error has occurred trying to connect. Please, try again.", "error");
    });
    builder.addCase(adNetworksThunks.getNetworkByConnectionId.pending, (state) => {
      state.selectedAdNetwork.requestStatus = getRequestStatus();
    });
    builder.addCase(adNetworksThunks.getNetworkByConnectionId.fulfilled, (state, action) => {
      state.selectedAdNetwork.data = action.payload;
      state.selectedAdNetwork.requestStatus = getResponseStatus();
    });
    builder.addCase(adNetworksThunks.getNetworkByConnectionId.rejected, (state) => {
      state.selectedAdNetwork.requestStatus = getErrorStatus();
    });
    builder.addCase(adNetworksThunks.credentialsCallback.pending, (state) => {
      state.credentialsCallback.requestStatus = getRequestStatus();
    });
    builder.addCase(adNetworksThunks.credentialsCallback.fulfilled, (state, action) => {
      state.credentialsCallback.requestStatus = getResponseStatus();
    });
    builder.addCase(adNetworksThunks.credentialsCallback.rejected, (state) => {
      state.credentialsCallback.requestStatus = getErrorStatus();
      showToast("An error has occurred trying to connect. Please, try again.", "error");
    });
  },
});

export const adNetworksSelector = (state) => state.integrations.adNetworks;
export const availableAdNetworksSelector = (state) => state.integrations.adNetworks.availableAdNetworks;

export const selectedAdNetworkSelector = (state) => state.integrations.adNetworks.selectedAdNetwork;

export const { setSelectedAdNetwork, resetSelectedAdNetwork } = adNetworksSlice.actions;

export default adNetworksSlice.reducer;
