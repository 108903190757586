import React from "react";
import { Box, HStack, Heading, VStack } from "@chakra-ui/react";

export const BaseCard = ({ title = "", id = "", scrollTarget = null, children }) => {
  return (
    <VStack
      w={"600px"}
      borderRadius={"12px"}
      bg={"dark.600"}
      gap={0}
      data-scroll-target={scrollTarget}
      data-test-id={id}
      as={"article"}
      aria-labelledby={title}
    >
      <HStack width={"100%"} borderBottom={"var(--border-dark-300)"} p={"16px"}>
        <Heading size={"small"} fontWeight={600} color={"textWhite.400"} id={title}>
          {title}
        </Heading>
      </HStack>
      <Box width={"100%"} p={"16px"}>
        {children}
      </Box>
    </VStack>
  );
};
