import { CONNECTED } from "@utils/status";

export const getMinAndMaxFromList = (values) => {
  const max = Math.max.apply(null, values);
  const min = Math.min.apply(null, values);
  return [min, max];
};

export const checkForUKBankAccount = (bankingData = []) => {
  for (let i = 0; i < bankingData.length; ++i) {
    const bankData = bankingData[i] || {};
    const { containsUkBankAccounts = false } = bankData;
    if (containsUkBankAccounts) return true;
  }
  return false;
};

export const getPointsCoordinates = (values, width, height) => {
  const [min, max] = getMinAndMaxFromList(values);
  let coordinates = [];

  if (values) {
    values.forEach((value, index) => {
      const normalizedPercentage = 1 - (value - min + 1) / (max + 2 - min);
      const yAxis = normalizedPercentage * height;
      const xAxis = (width / values.length) * index;

      const point = [xAxis, yAxis];
      coordinates.push(point);
    });
  }

  return coordinates;
};

export const svgPath = (points, command, smoothness, style) => {
  // build the d attributes by looping over the points
  const d = points.reduce(
    (acc, point, i, a) =>
      i === 0
        ? // if first point
          `M ${point[0]},${point[1]}`
        : `${acc} ${command(point, i, a, smoothness)}`,
    "",
  );

  return `
    <path
      d="${d}"
      fill="none"
      stroke="${style.stroke}"
    />`;
};

// const lineCommand = point => `L ${point[0]} ${point[1]}`;

const line = (pointA, pointB) => {
  const lengthX = pointB[0] - pointA[0];
  const lengthY = pointB[1] - pointA[1];

  return {
    length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
    angle: Math.atan2(lengthY, lengthX),
  };
};

const controlPoint = (current, previous, next, reverse, smoothness) => {
  // When 'current' is the first or last point of the array
  // 'previous' or 'next' doesnt exist.
  // replace with 'current'
  const p = previous || current;
  const n = next || current;

  // properties of the opposed line
  const o = line(p, n);

  // If is end-control-point, add PI to the angle to go backward
  const angle = o.angle + (reverse ? Math.PI : 0);
  const length = o.length * smoothness;

  // The control point position is relative to the current point
  const x = current[0] + Math.cos(angle) * length;
  const y = current[1] + Math.sin(angle) * length;

  return [x, y];
};

export const bezierCommand = (point, i, a, smoothness) => {
  // start control point
  const [cpsX, cpsY] = controlPoint(a[i - 1], a[i - 2], point, false, smoothness);

  // end control point
  const [cpeX, cpeY] = controlPoint(point, a[i - 1], a[i + 1], true, smoothness);

  return `C ${cpsX},${cpsY} ${cpeX},${cpeY} ${point[0]},${point[1]}`;
};

export const truncateAccountNumber = (accountNumber, addPrefix = true) => {
  if (typeof accountNumber === "object") return "";
  accountNumber = accountNumber || "";
  accountNumber = accountNumber.toString();
  // If the account number has less than 6 digits, don't show anything
  // This way at least 2 digits are shown
  if (accountNumber.length < 5) return "";
  return `**** ${accountNumber?.slice(-2)}`;
};

export const checkNoBalance = (data = [], status, newPlaidIntegration = false) => {
  // Determine if there is no balance combined across all accounts
  // We only want noBalance to return a true value if things are actually connected
  if (status !== CONNECTED) return false;
  let noBalance = true;
  data.forEach((balance) => {
    // Check if the balance is falsely or 0, "0.000" will turn in 0 and fail, which is expected
    const currentBalance = newPlaidIntegration ? balance.currentOverallBalanceCents : balance.currentBalance;
    if (!currentBalance || !parseFloat(currentBalance)) return;
    noBalance = false;
  });
  return noBalance;
};
