import React, { useState } from "react";
import { toast } from "react-toastify";

import SanloWebApiService from "@services/SanloWebApiService";
import {
  SanloModal,
  SanloModalTitle,
  SanloModalDescription,
  SanloModalDivider,
  SanloModalFooter,
  Highlight,
} from "@components/common/modals/SanloModal/SanloModal";
import { SanloToast } from "@common/toasts/sanlo-toast/SanloToast";

import { toastOptions } from "@utils/defaults/toast";

export const EmailSent = ({ email }) => {
  const [resent, setResent] = useState(false);

  const resendLink = () => {
    SanloWebApiService.sendChangePasswordLink(email).then(() => {
      setResent(true);
      toast.success(() => <SanloToast icon="success" msg={`Email sent to ${email}`} />, toastOptions);
    });
  };

  return (
    <SanloModal>
      <SanloModalTitle>Reset your password</SanloModalTitle>
      <SanloModalDescription>
        We've {resent ? "re-" : ""}sent a password reset link to <Highlight>{email}</Highlight> if there is a Sanlo
        account under this email address. Please check your inbox and spam folder.
      </SanloModalDescription>
      <SanloModalDivider />
      <SanloModalFooter>
        Haven't received it?{" "}
        <span onClick={resendLink} className="auth-label-button">
          Resend Link
        </span>
      </SanloModalFooter>
    </SanloModal>
  );
};
