import { HStack, Image, Text } from "@chakra-ui/react";
import { RECURRENCE_SERIES_PERIOD } from "@src/consts/webshops/catalog";
import { pricingTiersLabel } from "../utils";

export const SELECTOR_TYPES = {
  ITEMS: "items",
  SERIES: "series",
};

export const createTableColumns = (columnHelper = () => {}, priceTiersMap = {}, type = SELECTOR_TYPES.ITEMS) => {
  const commonColumns = {
    [SELECTOR_TYPES.ITEMS]: [
      columnHelper.accessor("name", {
        id: "name",
        header: () => "Name",
        cell: (info) => (
          <HStack>
            <Image borderRadius={"3px"} w="32px" h="32px" src={info.row.original.imageUrl} />
            <Text>{info.getValue()}</Text>,
          </HStack>
        ),
      }),
      columnHelper.accessor((row) => row.category?.name, {
        id: "category",
        header: () => "Category",
        cell: (info) => info.getValue() ?? "-",
      }),
      columnHelper.accessor((row) => pricingTiersLabel(priceTiersMap[row.pricingTierLevelId]), {
        id: "pricingTierLevelId",
        header: () => "Price",
        cell: (info) => info.getValue(),
      }),
    ],
    [SELECTOR_TYPES.SERIES]: [
      columnHelper.accessor("name", {
        id: "name",
        header: () => "Name",
        cell: (info) => (
          <HStack>
            <Image borderRadius={"3px"} w="32px" h="32px" src={info.row.original.items[0].imageUrl} />
            <Text>{info.getValue()}</Text>,
          </HStack>
        ),
      }),
      columnHelper.accessor("quantity", {
        id: "quantity",
        header: () => "Quantity",
        cell: (info) => <Text>{info.row.original.items.length}</Text>,
      }),
      columnHelper.accessor("recurrenceInterval", {
        id: "recurrenceInterval",
        header: () => "Recurrence",
        cell: (info) => {
          const recurrence = info.getValue();
          const recurrenceObj = RECURRENCE_SERIES_PERIOD.find(
            (period) => period.value === recurrence && recurrence !== null,
          );
          return <Text>{recurrenceObj?.label || ""}</Text>;
        },
      }),
    ],
  };

  return commonColumns[type] || [];
};

export function validatePriceTiersMap(props, propName, componentName) {
  const { type, data } = props;
  if (type === SELECTOR_TYPES.ITEMS && (!data || !data.priceTiersMap)) {
    return new Error(
      `Invalid prop ${propName} supplied to ${componentName}. When type is 'ITEMS', data.priceTiersMap is required.`,
    );
  }
  return null;
}

export function validateMultiSelect(props, propName, componentName) {
  const { type, multiSelect } = props;
  if (multiSelect && type !== SELECTOR_TYPES.ITEMS) {
    return new Error(
      `Invalid prop ${propName} supplied to ${componentName}. multiSelect can only be true if type is 'ITEMS'.`,
    );
  }
  return null;
}
