import { useLayoutEffect } from "react";
import { Box, Button, HStack, Heading, Image, Text, VStack } from "@chakra-ui/react";
import appStoreLogo from "@assets/img/icons/logos/app-store-logo.jpg";
import googlePlayLogo from "@assets/img/icons/logos/google-play-logo.jpg";
import { useMultiStepModal } from "@common/multi-step-modal/MultiStepModalContext";
import { GooglePlayInstructions } from "./google-steps/GooglePlayInstructionsStep";
import { APP_STORE, GOOGLE_PLAY } from "@src/consts/webshops/new-project";
import { useEnterKeyPress } from "@src/utils/custom-hooks/useEnterKeyPress";
import { Controller, useFormContext } from "react-hook-form";
import { AppStoreInstructions } from "./apple-steps/AppStoreInstructionsStep";
import { AppStoreApiKey } from "./apple-steps/AppStoreApiKeyStep";
import { AppStoreAppSelection } from "./apple-steps/AppStoreAppSelectionStep";

const stores = [
  {
    id: APP_STORE,
    name: "App Store",
    image: appStoreLogo,
    steps: [
      <AppStoreInstructions key="AppStoreInstructions" />,
      <AppStoreApiKey key="AppStoreApiKey" />,
      <AppStoreAppSelection key="AppStoreAppSelection" />,
    ],
  },
  {
    id: GOOGLE_PLAY,
    name: "Google Play",
    image: googlePlayLogo,
    steps: [<GooglePlayInstructions key="GooglePlayInstructions" />],
  },
];
export function StoreSelection({ handleNextStep }) {
  const { control, watch } = useFormContext();
  const { setShowProgress, setShowStepsLeft, setSteps } = useMultiStepModal();
  const [continueButton] = useEnterKeyPress();

  const selectedPlatform = watch("selectedPlatform");

  const handleOnContinue = () => {
    if (!selectedPlatform) return;
    handleNextStep();
  };

  useLayoutEffect(() => {
    setShowProgress(false);
    setShowStepsLeft(false);
  }, [setShowProgress, setShowStepsLeft]);

  return (
    <>
      <VStack alignItems={"center"} mx="auto" mt="40px">
        <Heading size="large" fontWeight={600}>
          Connect via App Store or Google Play
        </Heading>
        <Text>We will use this connection to generate your webshop.</Text>
      </VStack>
      <Box mt="72px" mx={"auto"}>
        <HStack gap="24px">
          <Controller
            name="selectedPlatform"
            control={control}
            render={({ field: { onChange } }) => (
              <>
                {stores.map((store) => (
                  <StoreContainer
                    isSelected={selectedPlatform === store.id}
                    key={store.id}
                    storeName={store.name}
                    storeImage={store.image}
                    onClick={() => {
                      onChange(store.id);
                      // we want to preserve only the first 2 steps
                      setSteps((prevSteps) => [...prevSteps.slice(0, 2), ...store.steps]);
                    }}
                  />
                ))}
              </>
            )}
          />
        </HStack>
        <Button
          ref={continueButton}
          isDisabled={!selectedPlatform}
          mt="24px"
          variant="primary"
          w="full"
          onClick={handleOnContinue}
        >
          Continue
        </Button>
      </Box>
    </>
  );
}

function StoreContainer({ storeName, storeImage, onClick, isSelected }) {
  return (
    <Box
      flexGrow={0}
      cursor={"pointer"}
      tabIndex={0}
      bg="dark.600"
      border={isSelected ? "2px solid transparent" : "1px solid transparent"}
      borderColor={isSelected ? "brandRed.400" : "dark.100"}
      pt="32px"
      px="56px"
      pb="24px"
      margin={isSelected ? "-1px" : "0"}
      borderRadius={"10px"}
      onClick={onClick}
      _hover={{ borderColor: "brandRed.400", borderWidth: "2px", margin: "-1px" }}
      _focusWithin={{ borderColor: "brandRed.400", borderWidth: "2px", margin: "-1px" }}
      _active={{ borderColor: "brandRed.400", borderWidth: "2px", margin: "-1px" }}
    >
      <Image alt="" src={storeImage} w={"88px"} h={"88px"} borderRadius={"10px"} />
      <Text userSelect={"none"} size={"regular"} textAlign={"center"} fontWeight={500} color="textWhite.400" mt="24px">
        {storeName}
      </Text>
    </Box>
  );
}
