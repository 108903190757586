import generalCopies from "./general";

/*
  A simple text handler to help centralize strings/copy within the app.
  This does not only return strings, it can also return small React components
  that are generally reusable and don't need to be a completely separate component.

  Common Usage is by using the "e" or "p" shorthand helpers:
    "e": short for exact, these keys match up exactly to the text return, though casing is not guaranteed
      text.e("this_is_exact_text") => "this is exact test" or "This is exact text" depending on the casing in the object

    "p": short for phrase, these keys match up to a phrase, and don't match the returned text exactly
      text.p("this_is_exact_text") => "This text is not exact, it could be a couple sentences or a paragraph"

  Some copy is more of a template, and may require parameters. These parameters should always be encapsulated
  in an object, so they can easily be passed through the text functions to the template.
    text.t("text_with_platform_name_in_the_middle", {
      platform: 'Google',
    }) => "Text with Google in the middle"
*/

class TextHandler {
  constructor() {
    // Unused for now
    this.language = "en";

    this.library = {
      exact: {},
      phrase: {},
      labels: {},
    };

    this.register(generalCopies);
  }

  register(dictionary = {}, key = "") {
    let collection = this.library;
    // Initialize new section if it doesn't exist
    if (key) {
      this.library = this.library[key] || {};
      collection = this.library[key];
    }
    // merge in new exact text...
    collection.exact = {
      ...collection.exact,
      ...dictionary.exact,
    };
    // merge in new phrase text...
    collection.phrase = {
      ...collection.phrase,
      ...dictionary.phrase,
    };
    // merge in labels text...
    collection.labels = {
      ...collection.labels,
      ...dictionary.labels,
    };
  }

  string(text_key, type, text_params = {}) {
    let text_collection = this.library[type];
    let final_key = text_key;
    if (text_key.indexOf(".") !== -1) {
      const split_key = text_key.split(".");
      // The last key is for the text, the others are collections to go through.
      final_key = split_key.pop();
      let i = 0;
      while (text_collection[split_key[i]]) {
        text_collection = text_collection[split_key[i]];
        i += 1;
      }
    }

    // If the text result is a function/template, call it with any
    // pass in paramters
    let text_result = text_collection[final_key];
    if (typeof text_result === "function") {
      text_result = text_result(text_params);
    }

    return text_result;
  }

  t(text_key, text_params) {
    let copy = null;
    // Check exact first
    copy = this.exact(text_key, text_params);
    if (copy) return copy;
    // Check labels second
    copy = this.labels(text_key, text_params);
    if (copy) return copy;
    // Check phrases third
    copy = this.phrase(text_key, text_params);
    if (copy) return copy;

    if (typeof copy !== "string") {
      console.warn(`No copy found for key: ${text_key}`);
    }
    return "";
  }

  exact(text_key, text_params) {
    return this.string(text_key, "exact", text_params);
  }
  e(text_key, text_params) {
    // Shorthand function
    return this.exact(text_key, text_params);
  }

  phrase(text_key, text_params) {
    return this.string(text_key, "phrase", text_params);
  }
  p(text_key, text_params) {
    // Shorthand function
    return this.phrase(text_key, text_params);
  }

  labels(text_key, text_params) {
    return this.string(text_key, "labels", text_params);
  }
  l(text_key, text_params) {
    // Shorthand function
    return this.labels(text_key, text_params);
  }
}

export default new TextHandler();
