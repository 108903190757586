import actions from "@redux/actions";

import { NAME_CONVERSION } from "@utils/redux";
import { CONNECTED } from "@utils/status";

export const getMMPData = (platforms, dispatch) => {
  platforms.forEach((platform) => {
    const { name, type, status } = platform;
    const id = NAME_CONVERSION[name] ? NAME_CONVERSION[name] : name;
    // Ignore all non-product analytics. These initially come through as game-analytics
    //  but we change it in the reducer
    if (type !== "game-analytics") return;
    // We are dealing with a sub-status of an MMP
    // TO-DO: Handle this and put its data in the base MMP
    if (name.indexOf("-") !== -1) return;
    // but only fetch UA Spend if it's actually connected
    if (status === CONNECTED) dispatch(actions.integrations[id].getUASpend.request());
  });
};
