import React, { useState } from "react";
import { useTheme } from "styled-components";

import { SanloTooltip } from "@components/common/tooltips/sanlo-tooltip/SanloTooltip";
import { Chart } from "@components/charts";
import { BarContainer, BarWrapper, Bar } from "./components";

import { getYAxisSteps } from "@utils/chart";
import { useTrackHover, FINANCIALS_ACTION, getMonthIndex } from "@utils/gtm";
import { hasTooltipContent } from "./utils";

export const BarChart = ({
  chartId = "",
  columnWidth = "",
  data = [],
  withNegYAxis = false,
  dailyView = false,
  verticalLine = {},
  addHoverEffect = false,
  placementTooltip = "top",
  hideBarIn,
  hideBarOut,
  TooltipContent = null,
  tooltipWidth = 225,
  yAxisShorthand,
  chartMaxWidth,
  yAxisWidth = "auto",
  zoomInScale = 0,
  barScaling = false,
}) => {
  const [idBarHover, setIdBarHover] = useState();
  const [addTracking, removeTracking] = useTrackHover();

  const theme = useTheme();

  // The highest value actually needs to be the highest of actual
  // and predicted combined for both inflow and outflow
  const highestValue = Math.max(
    ...data.map((item) =>
      Math.max(
        item.inflow.amount + (item.inflow.predictedAmount || 0),
        item.outflow.amount + (item.outflow.predictedAmount || 0),
      ),
    ),
  );

  const yAxisSteps = getYAxisSteps({
    highest: isFinite(highestValue) ? highestValue : 10,
    withNeg: withNegYAxis,
    shortHand: yAxisShorthand,
    zoomInScale,
  });
  const maxValue = yAxisSteps.highest;

  const inflowColor = theme.barChartInflow;
  const outflowColor = theme.barChartOutflow;

  return (
    <Chart
      chartId={chartId}
      data={data}
      yAxisSteps={yAxisSteps}
      withNegYAxis={withNegYAxis}
      verticalLine={verticalLine}
      yAxisML={"0"}
      chartMaxWidth={chartMaxWidth}
      yAxisWidth={yAxisWidth}
    >
      {data.map((item, index) => {
        const inProgressMonth = item.notCompleted || false;
        const isDaily = withNegYAxis && dailyView;
        // Bars should "always be shown" to cut down on checks
        let notHoverClass = idBarHover !== undefined && index !== idBarHover && addHoverEffect;
        const heightModifier = 100;

        // Determine the inflow bars
        const inflowBars = [];
        const outflowBars = [];

        const inflowAmount = item.inflow.amount;
        // const inflowAmount = 10000;
        if (inflowAmount) {
          inflowBars.push({
            id: `profit-bar-${index}`,
            amount: inflowAmount,
            height: (inflowAmount / maxValue) * heightModifier,
            styles: {
              zIndex: "2",
            },
            hide: hideBarIn,
            color: inflowColor,
          });
          // If its an in-progress month, add the gradient
          if (inProgressMonth) {
            inflowBars[0].styles.background = theme.barChartInflowGradient;
          }
        }
        // If there is a predicted amount, add it to the inflowBars array
        const inflowPredictedAmount = item.inflow.predictedAmount;
        if (inflowPredictedAmount) {
          inflowBars.push({
            id: `profit-prediction-bar-${index}`,
            isPredicted: true,
            amount: inflowPredictedAmount,
            height: (inflowPredictedAmount / maxValue) * heightModifier,
            styles: {
              zIndex: "1",
              background: theme.barChartInflowPredictedGradient,
            },
            hide: hideBarIn,
            color: inflowColor,
          });
        }
        // Determine the outflow bars
        const outflowAmount = item.outflow.amount;
        if (outflowAmount) {
          outflowBars.push({
            id: `losses-bar-${index}`,
            amount: outflowAmount,
            height: (outflowAmount / maxValue) * heightModifier,
            styles: {
              zIndex: "2",
            },
            hide: hideBarOut,
            color: outflowColor,
          });
          // If its an in-progress month, add the gradient
          if (inProgressMonth) {
            outflowBars[0].styles.background = theme.barChartOutflowGradient;
          }
        }
        // If there is a predicted amount, add it to the outflowBars array
        const outflowPredictedAmount = item.outflow.predictedAmount;
        if (outflowPredictedAmount) {
          outflowBars.push({
            id: `losses-prediction-bar-${index}`,
            isPredicted: true,
            amount: outflowPredictedAmount,
            height: (outflowPredictedAmount / maxValue) * heightModifier,
            styles: {
              zIndex: "1",
              background: theme.barChartOutflowPredictedGradient,
            },
            hide: hideBarOut,
            color: outflowColor,
          });
        }

        // Calculate the height of both inflow and outflow bars and the bar container that
        const inflowsHeight = inflowBars.reduce((acc, bar) => acc + bar.height, 0);
        const outflowsHeight = outflowBars.reduce((acc, bar) => acc + bar.height, 0);
        // barContainer holds and covers the entire of all inflow and outflow bars
        // Used mainly for hover and tooltips handling
        // Initialize to side by side
        const barContainer = {
          height: null,
          minHeight: null,
          top: null,
          width: null,
        };
        if (!withNegYAxis) {
          // Set the bar container height to the larger of the two
          barContainer.height = inflowsHeight > outflowsHeight ? inflowsHeight : outflowsHeight;
          barContainer.width = "50px";
        } else {
          // Bar container height is the sum of the heights of the bars
          barContainer.height = inflowsHeight + outflowsHeight;
          barContainer.width = "24px";
          barContainer.top = barContainer.height < 1 ? "45%" : `${50 - inflowsHeight}%`;
        }
        // Set the bar container min height to 30px if there are no inflow or outflow bars
        barContainer.minHeight = !inflowBars.length && !outflowBars.length ? "30px" : "none";

        // Used for the tooltip targeting, we won't have the tooltip show
        // when hovering the bottom half if there are no outflows and vice versa
        const onlyInflows = inflowBars.length && !outflowBars.length;
        const onlyOutflows = !inflowBars.length && outflowBars.length;
        const bothFlows = inflowBars.length && outflowBars.length;

        return (
          <BarContainer
            key={`${item.shortDate}-bar-container`}
            index={index}
            chartId={chartId}
            columnWidth={columnWidth}
            bothFlows={bothFlows}
            withNegYAxis={withNegYAxis}
            onMouseEnter={() => {
              setIdBarHover(index);
              addTracking({
                event: FINANCIALS_ACTION.HOVER_CHART,
                name: "Inflows & Outflows - Bar Chart",
                state: getMonthIndex(index),
              });
            }}
            onMouseLeave={() => {
              removeTracking();
              setIdBarHover();
            }}
          >
            <BarWrapper id={onlyInflows ? `target-${chartId}-${index}` : ""} withNegYAxis={withNegYAxis} isInflow>
              {inflowBars.map((bar, i) => (
                <Bar
                  id={`${bar.id}-${i}`}
                  key={`${item.shortDate}-inflow${i}`}
                  activities={item.inflow.activities}
                  hideBar={bar.hide}
                  height={bar.height}
                  color={bar.color}
                  notHoverClass={notHoverClass}
                  withNegYAxis={withNegYAxis}
                  isZero={!bar.amount}
                  isDaily={isDaily}
                  isPositive
                  styles={{
                    ...bar.styles,
                    position: barScaling ? "absolute" : i > 0 ? "absolute" : "relative",
                    borderRadius: "4px 4px 0px 0px",
                  }}
                  barScaling={barScaling}
                />
              ))}
            </BarWrapper>
            <BarWrapper id={onlyOutflows ? `target-${chartId}-${index}` : ""} withNegYAxis={withNegYAxis}>
              {outflowBars.map((bar, i) => (
                <Bar
                  id={`${bar.id}-${i}`}
                  key={`${item.shortDate}-outflow${i}`}
                  activities={item.outflow.activities}
                  hideBar={bar.hide}
                  height={bar.height}
                  color={bar.color}
                  notHoverClass={notHoverClass}
                  withNegYAxis={withNegYAxis}
                  isZero={!bar.amount}
                  isDaily={isDaily}
                  styles={{
                    ...bar.styles,
                    position: barScaling ? "absolute" : i > 0 ? "absolute" : "relative",
                    borderRadius: withNegYAxis ? "0px 0px 4px 4px" : "4px 4px 0px 0px",
                  }}
                  barScaling={barScaling}
                />
              ))}
            </BarWrapper>
            {TooltipContent && hasTooltipContent(item) && (
              <SanloTooltip
                width={tooltipWidth}
                target={`target-${chartId}-${index}`}
                placement={placementTooltip}
                id={`bar-tooltip-${index}`}
              >
                <TooltipContent
                  item={item}
                  inProgressMonth={inProgressMonth}
                  inflowColor={inflowColor}
                  outflowColor={outflowColor}
                  dailyView={dailyView}
                />
              </SanloTooltip>
            )}
          </BarContainer>
        );
      })}
    </Chart>
  );
};
