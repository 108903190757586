import React, { Fragment, useEffect, useRef, useState } from "react";
import moment from "moment";
import classnames from "classnames";

import { BodySmall14, BodySmallAlt, BodySmallPlus, TitleLargePlus, TitleSmall20 } from "@styled";
import { SanloTooltip } from "@common/tooltips/sanlo-tooltip/SanloTooltip";
import { DailyRevenueHeaderTooltip } from "../Tooltips/DailyRevenueHeaderTooltip";

import { getDate, isNoDataState } from "../../utils/dailyRevenueUtils";
import { currencyFormat } from "@utils/currency";
import { isPullingStatus, isNoLiveAppsStatus } from "@utils/status";
import symbols from "@utils/symbols";
import { useTrackHover, FINANCIALS_ACTION } from "@utils/gtm";
import { useLocation } from "react-router-dom";

import { ReactComponent as ToolTipIcon } from "@assets/img/icons/common/tooltip.svg";

export const DailyRevenueHeader = ({
  selectedOption = {},
  isLoading = true,
  viewedPeriod = {},
  viewedPeriodEarnings,
  fillColor,
  onPaidOnClick,
  chartData,
  onRefresh,
  isNothingConnected = false,
}) => {
  const { lastUpdated } = selectedOption;
  const [addTracking, removeTracking] = useTrackHover();
  const location = useLocation();

  const getlastUpdatedLabel = (lastUpdated) => {
    const label = lastUpdated ? `Updated ${moment.utc(lastUpdated).local().fromNow()}` : null;
    return label;
  };

  const [lastUpdatedLabel, setLastUpdatedLabel] = useState(getlastUpdatedLabel(lastUpdated));
  const lastUpdatedTimer = useRef(null);

  useEffect(() => {
    lastUpdatedTimer.current = setInterval(() => {
      setLastUpdatedLabel(getlastUpdatedLabel(lastUpdated));
    }, 60000);
    return () => {
      if (lastUpdatedTimer.current) {
        clearInterval(lastUpdatedTimer.current);
        lastUpdatedTimer.current = null;
      }
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setLastUpdatedLabel(getlastUpdatedLabel(lastUpdated));
  }, [lastUpdated]);

  // Platforms pulling data have no chart data
  // if ((!isConnectedStatus(selectedOption) && !isNothingConnected) || isNoLiveAppsStatus(selectedOption)) {
  if (isNoDataState(selectedOption, isNothingConnected)) {
    return (
      <div className="ce-header">
        <div className="ce-earnings is-pulling-data">
          <TitleLargePlus>~$0.00</TitleLargePlus>
        </div>
        {!selectedOption.isNone && (
          <BodySmallAlt className="ce-earnings-period">
            {`Total est. earned from ${selectedOption.name}
            ${
              isPullingStatus(selectedOption)
                ? "• Connecting..."
                : isNoLiveAppsStatus(selectedOption)
                ? "• Data sync completed"
                : ""
            }`}
          </BodySmallAlt>
        )}
      </div>
    );
  }

  if (!viewedPeriod) return null;

  const today = moment().startOf("day").format("YYYY-MM-DD");

  const { dataSourceStatus, fiscalStartDate, fiscalToDate, noEarningsToShow, payDate } = viewedPeriod;

  if (!chartData.order.length) return null;

  const fromDate = getDate(fiscalStartDate, "MMM DD");
  const toDate = getDate(fiscalToDate, "MMM DD");

  const displaySource = selectedOption.isAdNetwork ? selectedOption.source : selectedOption.name;
  let earnedCopy = `Total estimated earnings in ${fromDate} - ${toDate}`;
  if (displaySource) earnedCopy = `Total estimated earnings from ${displaySource} in ${fromDate} - ${toDate}`;

  const paidOnCopyDate = getDate(payDate, "MMMM Do");
  const paidOnCopy = (payDate > today ? "To be paid on " : "Paid on ") + paidOnCopyDate;
  const isPaidOnContained = Boolean(chartData.map[payDate]);

  // If the period has no day to day earnings to show, we just want to show the total
  let { earnings, total } = viewedPeriodEarnings;
  if (noEarningsToShow) earnings = total;

  // isLoading and dataSourceStatus make sense here, but I'm not sure why payDate is here
  // might be something legacy, at the moment this would exclude
  let showTooltip = !isLoading && Boolean(payDate) && dataSourceStatus !== "FUTURE";
  if (selectedOption.isAll) showTooltip = true;

  const handleMouseEnter = () => {
    addTracking({
      event: FINANCIALS_ACTION.HOVER_TOOLTIP,
      name: "Daily Revenue Header",
      pathname: location.pathname,
    });
  };

  const handleMouseLeave = () => {
    removeTracking();
  };

  return (
    <div className="ce-header">
      <div className="ce-earnings">
        <TitleLargePlus
          className={classnames("dr-shown-earnings", {
            "dr-loading": isLoading,
          })}
        >
          ~{currencyFormat(earnings)}
        </TitleLargePlus>
        {earnings !== total && !isLoading && (
          <TitleSmall20 className="ce-total-earnings">/ {currencyFormat(total)}</TitleSmall20>
        )}
        {showTooltip && (
          <Fragment>
            <ToolTipIcon
              fill={fillColor}
              className="tooltip-icon"
              id="tt_info_earnings"
              style={{
                marginLeft: "6px",
              }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            />
            <SanloTooltip
              target={"tt_info_earnings"}
              text={
                <DailyRevenueHeaderTooltip
                  selectedOption={selectedOption}
                  dataSourceStatus={viewedPeriod.dataSourceStatus}
                  trackData={{ name: "Daily Revenue Header" }}
                  pathname={location.pathname}
                  periodDate={{
                    periodStart: fromDate,
                    periodEnd: toDate,
                  }}
                />
              }
              clickable={true}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            />
          </Fragment>
        )}
      </div>

      <BodySmallAlt
        className={classnames("ce-earnings-period", {
          "dr-loading": isLoading,
        })}
      >
        {earnedCopy}
        {Boolean(payDate) && (
          <Fragment>
            {" • "}
            <span
              className={classnames("ce-paydate", {
                "is-contained": !chartData.isEmpty && isPaidOnContained,
                "is-current": !chartData.isEmpty && isPaidOnContained && fiscalToDate > today,
                "is-empty-data": chartData.isEmpty,
              })}
              onClick={() => {
                if (!isPaidOnContained) return;
                onPaidOnClick(viewedPeriod);
              }}
            >
              {symbols.FlyingMoney()}
              {paidOnCopy}
            </span>
          </Fragment>
        )}
      </BodySmallAlt>

      <div
        className={classnames("dr-header-refresh", {
          "dr-loading": isLoading,
        })}
      >
        <BodySmall14 className="dr-header-refresh-label">{lastUpdatedLabel}</BodySmall14>
        <BodySmallPlus
          className="dr-header-refresh-action"
          onClick={() => {
            onRefresh();
          }}
        >
          Refresh
        </BodySmallPlus>
      </div>
    </div>
  );
};
