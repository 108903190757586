import HTTP from "@redux/http";

const SANLO_WEB_API_URL = process.env.REACT_APP_SANLO_WEB_API_URL;
const BASE = `${SANLO_WEB_API_URL}/api/fairground/console/webshops`;

class TypographyService {
  getFonts(webshopId) {
    return HTTP.req.get(`${BASE}/${webshopId}/storefront/typography/fonts`);
  }

  updateSelectedFont(webshopId, data) {
    return HTTP.req.put(`${BASE}/${webshopId}/storefront/typography/fonts/select`, data);
  }

  resetSelectedFont(webshopId) {
    return HTTP.req.put(`${BASE}/${webshopId}/storefront/typography/reset`);
  }

  addCustomFont(webshopId, data) {
    return HTTP.req.post(`${BASE}/${webshopId}/storefront/typography/fonts`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
  addCustomFontBulk(webshopId, data) {
    return HTTP.req.post(`${BASE}/${webshopId}/storefront/typography/fonts/bulk`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
  removeCustomFont(webshopId, fontId) {
    return HTTP.req.delete(`${BASE}/${webshopId}/storefront/typography/fonts/${fontId}`);
  }
  updateTypographyProperties(webshopId, data) {
    return HTTP.req.put(`${BASE}/${webshopId}/storefront/typography/properties`, data);
  }
}

export default new TypographyService();
