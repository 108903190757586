import React from "react";

import { BodySmall14, BodyRegular16, TitleLargePlus } from "@styled";
import { StepActionsContainer } from "@common/animated/FadeStepsContainer";
import { SanloButton, Link, Support, CollapsableText } from "@inputs";

import symbols from "@utils/symbols";
import { COMMON_ACTION, INTEGRATION_ACTION, APPSFLYER_ACTION } from "@utils/gtm";

export const AppsFlyerStep3 = ({
  platform = {
    id: "appsflyer",
    name: "AppsFlyer",
    platformId: "appsflyer",
  },
  clientAppsFlyerManualConnectionEnabled = false,
  onContinue = () => {},
  trackEvent = () => {},
}) => {
  const MasterAPILink = () => {
    return (
      <Link
        className="fs-link"
        href="https://support.appsflyer.com/hc/en-us/articles/213223166-Master-API-user-acquisition-metrics-via-API/"
        onClick={() => trackEvent(APPSFLYER_ACTION.MASTER_API)}
      >
        Master API
      </Link>
    );
  };

  const PullAPILink = () => {
    return (
      <Link
        className="fs-link"
        href="https://support.appsflyer.com/hc/en-us/articles/360007530258-Using-Pull-API-raw-data/"
        onClick={() => trackEvent(APPSFLYER_ACTION.PULL_API_RAW)}
      >
        Pull API Raw Data
      </Link>
    );
  };

  const PrivacyPolicyLink = () => {
    return (
      <Link
        className="fs-link"
        href="https://www.sanlo.ai/privacy-policy/"
        onClick={() => trackEvent(COMMON_ACTION.PRIVACY_POLICY)}
      >
        Privacy Policy
      </Link>
    );
  };

  return (
    <StepActionsContainer>
      <div className="fs-modal-actions">
        <TitleLargePlus className="done-title">
          {symbols.Pizza()} {platform.name} is connected but we're missing some data
        </TitleLargePlus>

        <BodyRegular16 className="done-description">
          Nice work! You have successfully connected your {platform.name} account but it looks like we don't have access
          to some data. We'll reach out to you shortly with next steps.
        </BodyRegular16>

        <CollapsableText
          header="How can I fix it?"
          description={
            <>
              You can setup an access with manual instructions by clicking “Continue to Instructions” button. We never
              share your data, read our <PrivacyPolicyLink />.
            </>
          }
          handleToggle={() => trackEvent(INTEGRATION_ACTION.HOW_CAN_FIX)}
        />

        <CollapsableText
          header="Why did this happen?"
          description={
            <>
              This may happen in cases where you don't have one or both of the following features as part of your{" "}
              {platform.name}
              subscription - <MasterAPILink /> or <PullAPILink />.
            </>
          }
          handleToggle={() => trackEvent(INTEGRATION_ACTION.WHY_HAPPEN)}
        />

        <CollapsableText
          header="How does this affect my experience?"
          description="This will limit the functionality of tracking ad revenue and user acquisition spend on the Dashboard page until we resolve it for you."
          handleToggle={() => trackEvent(INTEGRATION_ACTION.HOW_AFFECT)}
        />

        <SanloButton
          isJumbo
          label={clientAppsFlyerManualConnectionEnabled ? "Continue to Instructions" : "Got It"}
          onClick={onContinue}
          testId={`${platform.platformId}-permissions-info-continue-button`}
        />

        <BodySmall14 className="contact-us">
          In the meantime, if you have any questions, please{" "}
          <Support onClick={() => trackEvent(COMMON_ACTION.CONTACT_US)} red text="contact us" />.
        </BodySmall14>
      </div>
    </StepActionsContainer>
  );
};
