import { configureConsts, configureActions } from "@utils/redux";

export const GET_INTEGRATIONS_CONNECTIONS = configureConsts("GET_INTEGRATIONS_CONNECTIONS");
export const UA_SPEND_DATA = configureConsts("UA_SPEND_DATA");
export const SEND_SUGGESTION = configureConsts("SEND_SUGGESTION");

const baseActions = {
  getIntegrationsConnections: configureActions(GET_INTEGRATIONS_CONNECTIONS),

  removeUASpendData: () => ({
    type: UA_SPEND_DATA.REMOVE,
  }),

  sendSuggestion: configureActions(SEND_SUGGESTION, {
    request: (data) => ({
      type: SEND_SUGGESTION.REQUEST,
      data,
    }),
  }),
};

export default baseActions;
