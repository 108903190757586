import React from "react";
import moment from "moment";

import { StyledXAxis, StyledStack, StyledStackDayBG, StyledStackDay } from "./XAxis";

import { getRangeOfDatesMoment } from "@utils/format";

const XAxisPullingDataState = ({ daysVisible }) => {
  const today = moment().startOf("day").format("YYYY-MM-DD");
  const todayDate = moment();
  const dateStart = moment(todayDate).subtract(daysVisible / 2, "days");
  const dateEnd = moment(todayDate).add(daysVisible / 2, "days");
  const periodRange = getRangeOfDatesMoment(dateStart, dateEnd);

  return (
    <StyledXAxis>
      {periodRange.map((date) => {
        const day = moment(date).format("YYYY-MM-DD");
        const dayNum = date.getDate();

        return (
          <StyledStack key={`key_x_axis_${day}`}>
            <StyledStackDayBG isCurrentPeriod>
              <StyledStackDay isToday={day === today} isPullingData={day === today}>
                {dayNum}
              </StyledStackDay>
            </StyledStackDayBG>
          </StyledStack>
        );
      })}
    </StyledXAxis>
  );
};

export default XAxisPullingDataState;
