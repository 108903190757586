import { useAuth0 } from "@auth0/auth0-react";
import { useFlags, useLDClient } from "launchdarkly-react-client-sdk";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import actions from "@redux/actions";

import MainContainer from "./MainContainer";

import PartnerLandingPage from "@pages/partner-landing-page/PartnerLandingPage";
import AuthenticationContainer from "@pages/authentication/AuthenticationContainer";
import SignUpFlow from "@pages/authentication/SignUpFlow";
import ErrorPage from "@pages/error-page/ErrorPage";
import OnboardingContainer from "@pages/onboarding/OnboardingContainer";

import { ResetPasswordExpired } from "@pages/authentication/flows/ResetPasswordExpired";
import { EmailAlreadyVerifiedError } from "@pages/authentication/flows/EmailAlreadyVerifiedError";
import { InvalidEmailError } from "@pages/authentication/flows/InvalidEmailError";
import { VerificationExpired } from "@pages/authentication/flows/VerificationExpired";
import { VerificationUnknownError } from "@pages/authentication/flows/VerificationUnknownError";
import { VerifyNewAccount } from "@pages/authentication/flows/VerifyNewAccount";

import FadeTransitionContainer from "./components/common/animated/FadeTransitionContainer";
import Support from "@components/support/Support";

import { getBrandingInfo, setBrandingInfo } from "@utils/branding";
import { getQueryParameters, parseAuth0Params } from "@utils/general";

import ErrorBoundary from "@components/ErrorBoundary";
import { PrePlaidModal } from "./components/common/modals/pre-plaid-modal";
import { isCypressUser } from "./utils/users";
import { SuperpowersContainer } from "@pages/onboarding/onboarding-v2/SuperpowersContainer";

function Authentication(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // prettier-ignore
  const {
    user,
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
  } = useAuth0();
  // Used by both Auth0 and the partner branding experience
  const queryParams = getQueryParameters();
  const authParams = parseAuth0Params(queryParams);

  // state for storing user object
  const [userObj, setUserObj] = useState(undefined);

  useEffect(() => {
    // At times, user object will not be available hence this check
    if (!userObj && authParams?.user) {
      setUserObj(authParams?.user);
    }
  }, [authParams.user, userObj]);

  const ldClient = useLDClient();
  let {
    onboardingExperienceVisible,
    clientIsTestUser = false,
    clientVerifyEmailEnabled = true,
    clientSuperpowersOnboardingFlowEnabled = false,
  } = useFlags();

  const userSummaryStatus = useSelector((state) => state.session.requestStatus.initialUserSummary);
  const hasCompletedOnboarding = useSelector((state) => state.session.company.onboarding.isComplete);
  // BRANDING AND SDK STUFF
  const partnerBranding = useSelector((state) => state.session.partnerBranding);
  const brandingStatus = partnerBranding.requestStatus;
  const auth0Updated = useSelector((state) => state.session.auth0Updated);

  const { isBranded, clientId } = getBrandingInfo();

  const sessionData = useSelector((state) => state.session);
  const { company, completedRegister, onboarding } = sessionData;
  const onboardingLoaded = onboarding.initialLoadComplete;

  const showOnboardingModal =
    !hasCompletedOnboarding && !onboarding.data.completedModalOnboarding && onboardingExperienceVisible;

  // These are flows that exist outside of the authenticated experience, we don't want these routes/flows
  // to check for authentication because it isn't necessary
  const outsideFlow = location.pathname === "/change-password" || location.pathname === "/error";
  const partnerFlow = location.pathname === "/partner";

  useEffect(() => {
    setBrandingInfo();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Request branding data if the query params are present and the request isn't done/pending
    if (isBranded && !brandingStatus.completed && !brandingStatus.pending) {
      dispatch(actions.session.partnerBranding.request());
    }
    // eslint-disable-next-line
  }, [partnerBranding]);

  useEffect(() => {
    (async function login() {
      // No longer waiting on calls to finish, there is no user/authentication, or any errors
      if (!isLoading && !user && !isAuthenticated && !authParams.error) {
        // Make sure we're in an authenticated flow inside the app
        if (!outsideFlow && !partnerFlow) {
          // We only need to do the auth0Updated call when coming from a partner site, not
          // in a branded experience in general
          if (isBranded && brandingStatus.completed && !auth0Updated) {
            const storedBrandingData = JSON.parse(localStorage.getItem("partnerBranding") || "{}");
            const noStoredBrandingData = !Object.keys(storedBrandingData).length;
            const logoMismatch = storedBrandingData.logo !== partnerBranding.data.logo;
            if (noStoredBrandingData || logoMismatch) {
              // Auth0Provider redirectUri cannot be altered once set, so we need to store the branding data
              // and then reload the app so the branding data will be present on load
              // only do this if the user is not logged in
              localStorage.setItem(
                "partnerBranding",
                JSON.stringify({
                  ...partnerBranding.data,
                  loaded: true, // ensure there is always at least one key, even if information fails
                }),
              );
              window.location.reload();
            } else {
              // If the branding data is present, we can send the signal to move on to the auth screen
              dispatch(actions.session.auth0Update.request());
            }
          }

          // completed will be true for success/error
          if (!isBranded || (isBranded && brandingStatus.completed && auth0Updated)) {
            await loginWithRedirect();
          }
        }
      }
    })();
    // eslint-disable-next-line
  }, [user, dispatch, isLoading, loginWithRedirect, isAuthenticated, partnerBranding, auth0Updated]);

  useEffect(() => {
    const login = async () => {
      if (user) {
        // This may generate a popup asking the user for the permissions
        // This will only happen on the local environment, as should be silent on the deployed ones
        let token;
        try {
          token = await getAccessTokenSilently();
        } catch (e) {
          token = await getAccessTokenWithPopup({ display: "page" });
        }
        dispatch(actions.session.auth0LoginResponse(token, user));
        dispatch(actions.integrations.base.getIntegrationsConnections.request());

        // Updates user for launch darkly
        await ldClient.identify({
          key: isCypressUser(user.email) ? process.env.REACT_APP_LD_CYPRESS_USER_KEY : user.sub,
          email: user.email,
          name: user.name,
        });
      }
    };
    login().catch((reason) => console.error("Error during login.", reason));
  }, [user, dispatch, getAccessTokenWithPopup, getAccessTokenSilently, ldClient]);

  if (outsideFlow) return null;

  if (partnerFlow && !isLoading) {
    if (!user) {
      return <PartnerLandingPage />;
    } else {
      navigate("/");
    }
  }

  if (authParams.error_description === "Access expired.") {
    return <ResetPasswordExpired />;
  }

  if (authParams.error === "access_denied") {
    return <InvalidEmailError />;
  }

  if (authParams.access_denied && authParams.invalid_email) {
    return <InvalidEmailError />;
  }

  if (userSummaryStatus.completed && onboardingLoaded) {
    const {
      success = true,
      verified = Boolean(user?.email_verified) || clientIsTestUser || !clientVerifyEmailEnabled,
      expired = false,
      unknownError = false,
    } = authParams;

    if (userSummaryStatus.error) {
      return (
        <ErrorPage
          ctaLabel="Log Out"
          description={
            <>
              We're sorry, something went wrong on our end. Please try to log out and then log in back or you can simply
              try to refresh the page. If you have any questions, please contact us at{" "}
              <Support email className="red-label" />.
            </>
          }
        />
      );
    }

    // A user can be authenticated but still have not verified their email or completed their profile
    if (isAuthenticated) {
      if (user) {
        if (!success) {
          if (expired) {
            return <VerificationExpired email={user?.email || userObj?.email} />;
          }
          if (verified) {
            return <EmailAlreadyVerifiedError email={user?.email || userObj?.email} />;
          }
          if (unknownError) {
            return <VerificationUnknownError email={user?.email || userObj?.email} />;
          }
        }
        if (success) {
          if (clientId) {
            localStorage.setItem(`${clientId}-started`, true);
          }
          if (!verified) {
            return <VerifyNewAccount email={user?.email || userObj?.email} />;
          }
          if (verified && !completedRegister) {
            return <SignUpFlow profileCompleted={completedRegister} />;
          }
        }
      }

      if (!showOnboardingModal) {
        return <MainContainer selectedTheme={props.themeSelected} />;
      }

      return (
        <ErrorBoundary name="onboarding-container">
          <FadeTransitionContainer
            absolutePosition={false}
            value={showOnboardingModal}
            firstComponent={
              clientSuperpowersOnboardingFlowEnabled ? (
                <SuperpowersContainer />
              ) : (
                <OnboardingContainer
                  dispatch={dispatch}
                  companyName={company.companyName}
                  hasCompletedOnboarding={hasCompletedOnboarding}
                  onboardingProgress={onboarding}
                />
              )
            }
            secondComponent={<MainContainer selectedTheme={props.themeSelected} />}
          />
          <PrePlaidModal />
        </ErrorBoundary>
      );
    }
  }

  return <AuthenticationContainer noLogo hasLogout />;
}

export default Authentication;
