import moment from "moment";
import { getRangeOfDatesMoment } from "@utils/format";

// export const addFakeGames = (numberOfGames, negativeGameEarnings, fiscalDates) => {
export const addFakeGames = (opts = {}) => {
  const { numberOfGames, negativeGameEarnings, negativeGameEarningsRandom, fiscalDates } = opts;

  const gamesArray = [];

  for (let i = 0; i < numberOfGames; ++i) {
    const game = {
      isFake: true,
      vendorId: 123456789,
      // "gameId": (new Date()).getTime() + '-' + i,
      gameName: `This is game ${i}!`,
      gameIcon: require("@assets/img/icons/adNetworkDefault.png"),
      earningsByDay: {},
      totalGameEarnings: 0,
      gameId: null,
      platformId: "fakePlatform",
    };

    const periodRange = getRangeOfDatesMoment(fiscalDates.fiscalStartDate, fiscalDates.fiscalToDate);
    for (let i = 0; i < periodRange.length; ++i) {
      const date = periodRange[i];
      const parsedDate = moment(date).format("YYYY-MM-DD");

      let gameDayEarnings = Math.random() * (200 - 100) + 100;
      if (negativeGameEarnings) {
        if (!negativeGameEarningsRandom || (negativeGameEarningsRandom && Math.random() < 0.5)) {
          gameDayEarnings *= -1;
        }
      }

      game.earningsByDay[parsedDate] = gameDayEarnings;
      game.totalGameEarnings += game.earningsByDay[parsedDate];
    }

    gamesArray.push(game);
  }

  return gamesArray;
};

export const generateFakeData = (opts = {}) => {
  const { numberOfGames = 3, negativeGameEarnings = false, negativeGameEarningsRandom = false } = opts;

  const data = {
    periodEarnings: [],
  };

  const today = moment();
  const nextMonthDate = moment(today).add(1, "M");
  const prevMonthDate = moment(today).subtract(1, "M");

  const begStart = prevMonthDate.startOf("month").format("YYYY-MM-DD");
  const begEnd = prevMonthDate.endOf("month").format("YYYY-MM-DD");
  const midStart = today.startOf("month").format("YYYY-MM-DD");
  const midEnd = today.endOf("month").format("YYYY-MM-DD");
  const endStart = nextMonthDate.startOf("month").format("YYYY-MM-DD");
  const endEnd = nextMonthDate.endOf("month").format("YYYY-MM-DD");

  const periodFiscalDates = [
    {
      fiscalStartDate: begStart,
      fiscalToDate: begEnd,
    },
    {
      fiscalStartDate: midStart,
      fiscalToDate: midEnd,
    },
    {
      fiscalStartDate: endStart,
      fiscalToDate: endEnd,
    },
  ];

  [0, 1, 2].forEach((periodNumber) => {
    const fiscalDates = periodFiscalDates[periodNumber];
    const period = {
      totalPeriodEarnings: Math.random() * (200 - 100) + 100,
      earningsByGame: addFakeGames({
        numberOfGames,
        negativeGameEarnings,
        negativeGameEarningsRandom,
        fiscalDates,
      }),
      ...fiscalDates,
      payDate: fiscalDates.fiscalToDate,
      periodId: `fake_period_${periodNumber}`,
      periodPayDates: [
        {
          dataSourceStatus: null,
          estimated: false,
          fiscalPeriodId: `fake_period_${periodNumber}`,
          payDate: fiscalDates.fiscalToDate,
          totalPeriodEarnings: Math.random() * (200 - 100) + 100,
          ...fiscalDates,
        },
      ],
    };

    data.periodEarnings.push(period);
  });

  return data;
};
