import React, { useState } from "react";

import { StepActionsContainer } from "@common/animated/FadeStepsContainer";
import { StyledPermissionGuideContainer } from "@components/common/modals/permission-modal/PermissionModal.styles";
import useShadowScroll from "@utils/custom-hooks/useShadowScroll";

import { StyledPermissionModal } from "@components/common/modals/permission-modal/PermissionModal.styles";
import { SanloButton, SanloCheckbox, CopyButton } from "@inputs";
import {
  Step,
  Navigation,
  GuideList,
  StepTitle,
  GuideLink,
  GuideMedia,
  StepDescription,
  GuideActions,
  GuideMoreDetails,
  StepInfo,
} from "@components/integrations/connection-guide";

import symbols from "@utils/symbols";

import { INTEGRATION_ACTION } from "@utils/gtm";

export const SingularStep1 = ({
  platform = {
    id: "singular",
    name: "Singular",
    platformId: "singular",
  },
  email = "",
  onClose = () => {},
  onComplete = () => {},
  trackEvent = () => {},
}) => {
  const [requiredAccess, setRequiredAccess] = useState(false);

  const [wrapperRef, contentRef, shadowBottomRef, shadowTopRef] = useShadowScroll(false);

  return (
    <StepActionsContainer>
      <StyledPermissionModal isOpen fade={false} scrollable={false}>
        <div className="leftGradModal" />
        <div className="rightGradModal" />
        <div className="fs-modal-container">
          <Navigation onClose={onClose} platformName={platform.name} />

          <div
            style={{
              height: "80%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <StyledPermissionGuideContainer>
              <div ref={shadowTopRef} className="shadow shadow--top" />
              <div ref={shadowBottomRef} className="shadow shadow--bottom" />

              <div className="step-wrapper" ref={wrapperRef}>
                <div ref={contentRef} className="step-container">
                  <Step>
                    <StepTitle stepNumber={1}>
                      Log into your{" "}
                      <GuideLink
                        label={platform.name}
                        href="https://console.firebase.google.com/u/0/project/fir-sharing-test/settings/iam?pli=1"
                      />{" "}
                      account and complete the steps below:
                    </StepTitle>

                    <StepDescription>
                      <GuideList>
                        <li>
                          1.1 To invite a new user, go to <b>{`“Settings -> Team Management”`}</b>, click{" "}
                          <b>”Create New User”</b>, and fill out new user details:
                          <ol>
                            <li>
                              Set a type of user: <b>Standard</b>
                            </li>
                            <li>
                              Enter email address: <b>{email}</b>{" "}
                              <CopyButton
                                text={email}
                                onClick={() => {
                                  trackEvent(INTEGRATION_ACTION.COPY_EMAIL);
                                }}
                              />
                            </li>
                          </ol>
                        </li>
                        <li>
                          1.2 Click <b>“Create User”</b> button.
                        </li>
                      </GuideList>
                    </StepDescription>
                  </Step>

                  <Step>
                    <StepTitle stepNumber={2}>
                      Check the box below and click "Complete" button to finish setup {symbols.PointDown()}
                    </StepTitle>
                  </Step>

                  <GuideActions>
                    <SanloCheckbox
                      checked={requiredAccess}
                      toggleCheckbox={() => {
                        setRequiredAccess(!requiredAccess);
                      }}
                      text={`I have invited and granted Sanlo access to my ${platform.name} access.`}
                      testId={`${platform.id}-granted-checkbox`}
                    />

                    <SanloButton
                      isJumbo
                      isDisabled={!requiredAccess}
                      label="Complete"
                      onClick={onComplete}
                      testId={`${platform.id}-granted-complete-button`}
                    />

                    <GuideMoreDetails
                      platform={platform}
                      href="https://support.google.com/firebase/answer/7000272?hl=en"
                      trackEvent={trackEvent}
                    />
                  </GuideActions>
                </div>
              </div>

              <StepInfo platform={platform}>
                <GuideMedia
                  mediaSlider={[
                    require(`./assets/${platform.id}-step1-guide1.png`),
                    require(`./assets/${platform.id}-step1-guide2.png`),
                    require(`./assets/${platform.id}-step1-guide3.png`),
                  ]}
                  platformName={platform.name}
                />
              </StepInfo>
            </StyledPermissionGuideContainer>
          </div>
        </div>

        <div id="toast-bg-fade" />
        <div id="bottom-toast">Copied to Clipboard</div>
      </StyledPermissionModal>
    </StepActionsContainer>
  );
};
