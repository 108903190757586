import { useDisclosure } from "@chakra-ui/react";
import { createContext, useContext, useEffect, useState } from "react";
import { MultiStepModal } from "./MultiStepModal";
import { FormProvider, useForm } from "react-hook-form";

export const MultiStepModalContext = createContext({});

export const MultiStepModalProvider = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const methods = useForm();
  const { handleSubmit, trigger, reset, unregister, watch } = methods;
  const [initialStep, setInitialStep] = useState(0);
  const [steps, setSteps] = useState([]);
  const [showProgress, setShowProgress] = useState(true);
  const [showStepsLeft, setShowStepsLeft] = useState(true);

  function handleShowProgress(value) {
    if (typeof value !== "boolean") {
      throw new Error("Value must be a boolean");
    }

    setShowProgress(value);
  }

  function handleShowStepsLeft(value) {
    if (typeof value !== "boolean") {
      throw new Error("Value must be a boolean");
    }

    setShowStepsLeft(value);
  }

  if (!Array.isArray(steps)) {
    throw new Error("Steps must be an array");
  }

  const onFormSubmit = async (onSubmit) => {
    // Trigger validations before submitting
    const isValid = await trigger();

    if (isValid) {
      handleSubmit(onSubmit)();
    }
  };

  useEffect(() => {
    /*
     * Why do we use a timeout here? When steps are mounted again (because of the change in isOpen), the form register the last step registered inputs.
     * This is a workaround to clean the form inputs when the modal is closed.
     */
    const timer = setTimeout(() => {
      if (!isOpen) {
        unregister([...Object.keys(watch())]);
        reset();
      }
    }, 150);

    return () => clearTimeout(timer);
  }, [isOpen, reset, unregister, watch]);

  const contextValue = {
    isOpen,
    onOpen,
    onClose,
    initialStep,
    setInitialStep,
    steps,
    setSteps,
    showProgress,
    setShowProgress: handleShowProgress,
    showStepsLeft,
    setShowStepsLeft: handleShowStepsLeft,
    onFormSubmit,
  };

  return (
    <MultiStepModalContext.Provider value={contextValue}>
      {children}
      <FormProvider {...methods}>
        <MultiStepModal />
      </FormProvider>
    </MultiStepModalContext.Provider>
  );
};

export function useMultiStepModal() {
  return useContext(MultiStepModalContext);
}
