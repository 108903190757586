import { defineStyleConfig } from "@chakra-ui/react";

export const Link = defineStyleConfig({
  baseStyle: {
    fontFamily: "body",
    fontWeight: 400,
  },
  variants: {
    primary: {
      background: "transparent",
      px: "16px",
      py: "4px",
      _hover: {
        textDecoration: "none",
      },
    },
  },
});
