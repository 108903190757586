import { checkboxAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const sizes = {
  sm: definePartsStyle({
    control: defineStyle({
      boxSize: "14px",
    }),
  }),
};

const baseStyle = definePartsStyle({
  // define the part you're going to style
  label: {
    fontFamily: "body",
    color: "textDefault.400",
  },
  control: {
    borderRadius: "3px",
    border: "1px solid",
    borderColor: "textDefault.400",
    _checked: {
      bg: "textWhite.400",
      borderColor: "textWhite.400",
      color: "black",
      _hover: {
        bg: "textWhite.400",
        borderColor: "textWhite.400",
      },
    },
  },
});

const skyBlueVariant = definePartsStyle({
  control: {
    borderColor: "textDefault.400",
    _focusVisible: {
      boxShadow: "none",
    },
    _checked: {
      bg: "brandSkyBlue.400",
      borderColor: "brandSkyBlue.400",
      color: "black",
      _hover: {
        bg: "brandSkyBlue.400",
        borderColor: "brandSkyBlue.400",
      },
    },
  },
});

const greenVariant = definePartsStyle({
  control: {
    _focusVisible: {
      boxShadow: "none",
    },
    borderColor: "textDefault.400",
    _checked: {
      bg: "brandGreen.400",
      borderColor: "brandGreen.400",
      color: "black",
      _hover: {
        bg: "brandGreen.400",
        borderColor: "brandGreen.400",
      },
    },
  },
});

const purpleVariant = definePartsStyle({
  control: {
    _focusVisible: {
      boxShadow: "none",
    },
    borderColor: "textDefault.400",
    _checked: {
      bg: "brandPurple.400",
      borderColor: "brandPurple.400",
      color: "black",
      _hover: {
        bg: "brandPurple.400",
        borderColor: "brandPurple.400",
      },
    },
  },
});

const redVariant = definePartsStyle({
  control: {
    _focusVisible: {
      boxShadow: "none",
    },
    borderColor: "textDefault.400",
    _checked: {
      bg: "brandRed.400",
      borderColor: "brandRed.400",
      color: "black",
      _hover: {
        bg: "brandRed.400",
        borderColor: "brandRed.400",
      },
    },
  },
});

const onboardingVariant = definePartsStyle({
  control: {
    _focusVisible: {
      boxShadow: "none",
    },
    borderColor: "textDefault.400",
    _checked: {
      bg: "linear-gradient(269deg, #BF6BFF -4.84%, #FF5C79 100.08%)",
      borderColor: "transparent",
      color: "black",
      _hover: {
        bg: "linear-gradient(269deg, #BF6BFF -4.84%, #FF5C79 100.08%)",
        borderColor: "linear-gradient(269deg, #BF6BFF -4.84%, #FF5C79 100.08%)",
      },
    },
  },
});

export const Checkbox = defineMultiStyleConfig({
  defaultProps: {
    iconColor: "dark",
    size: "sm",
  },
  baseStyle,
  sizes,
  variants: {
    skyBlue: skyBlueVariant,
    green: greenVariant,
    purple: purpleVariant,
    red: redVariant,
    onboarding: onboardingVariant,
  },
});
