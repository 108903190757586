import HTTP from "@redux/http";

const SANLO_WEB_API_URL = process.env.REACT_APP_SANLO_WEB_API_URL;
const BASE = `${SANLO_WEB_API_URL}/api/integrations/marketing/appsflyer`;

export default {
  appsFlyerGetDraftCredentials: () => {
    return HTTP.req.get(`${BASE}/credentials/draft`);
  },

  appsFlyerSaveDraftCredentials: (credentials) => {
    return HTTP.req.post(`${BASE}/save-credentials/draft`, credentials);
  },

  appsFlyerSaveCredentials: (credentials) => {
    return HTTP.req.post(`${BASE}/save-credentials`, credentials);
  },

  appsFlyerTestApiToken: (credentials) => {
    const apiToken = {
      apiToken: credentials.apiToken,
    };
    return HTTP.req.post(`${BASE}/api-token-test`, apiToken);
  },

  appsFlyerUnlinkIntegration: () => {
    return HTTP.req.delete(`${BASE}/delete-credentials`);
  },

  appsFlyerUASpend: () => {
    return HTTP.req.get(`${BASE}/ua-spend`);
  },

  appsFlyerRefreshCompanyUA: () => {
    return HTTP.req.post(`${BASE}/refresh-company-ua`);
  },
};
