import isEmpty from "lodash/isEmpty";

export const reduceInputValues = (inputs) => {
  const values = {};
  inputs.forEach((input) => {
    const key = input.name || input.label;
    let valid = input.validate ? input.validate(input.value) : !isEmpty(input.value);
    valid = Boolean(valid);
    values[key] = {
      value: input.value,
      valid,
      touched: false,
    };
  });
  return values;
};

export const checkInputsValid = (inputValues) => {
  return Object.values(inputValues).every((input) => input.valid);
};
