import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack } from "@chakra-ui/react";
import { debounce } from "lodash";

import PropTypes from "prop-types";

import { StylePicker } from "../../StylePicker";
import { BackgroundStylePicker } from "../BackgroundStylePicker";
import { ToggleInput } from "../../ToggleInput";

import { builderLinksSelector } from "@redux/webshops/builder/builderSlice";
import { sectionsThunks, storefrontSectionsSelector, updateFooter } from "@redux/webshops/builder/sectionsSlice";
import { linksThunks } from "@redux/webshops/builder/linksSlice";

import {
  PropertiesHeader,
  SectionWrapper,
  getSectionTheme,
  FILE_TYPES,
  generateFilePayloads,
  generateFileRemovePayloads,
  generateThemePayloads,
  generateThemeResetPayloads,
} from "@utils/webshop/section";

import { DEFAULT_DEBOUNCE_TIME } from "@utils/consts";
import { getValidator, validateURL } from "@utils/general";
import { extractLinks, LINK_TYPES, TERMS_LABEL, PRIVACY_LABEL } from "@utils/links";

import { ReorderGroup, ReorderItem, useReorderList } from "@utils/custom-hooks/useReorderList";

export const FooterPropertiesContainer = ({ webshop = {}, storefront = {} }) => {
  const dispatch = useDispatch();

  const links = useSelector(builderLinksSelector);
  const data = useSelector(storefrontSectionsSelector).footer;
  const theme = getSectionTheme(storefront, data);

  // eslint-disable-next-line
  const debouncedThemeUpdate = useCallback(
    debounce((data) => {
      dispatch(sectionsThunks.createSectionThemeOverride(data));
    }, DEFAULT_DEBOUNCE_TIME),
    [dispatch],
  );

  const onThemeChange = (themeObj) => {
    const payloads = generateThemePayloads({
      data,
      themeObj,
    });
    debouncedThemeUpdate({
      data: payloads.request,
      webshopId: webshop.id,
      sectionId: data.id,
    });
    dispatch(updateFooter(payloads.response));
  };

  const onThemeReset = () => {
    const payloads = generateThemeResetPayloads({
      data,
    });
    dispatch(
      sectionsThunks.deleteSectionThemeOverride({
        webshopId: webshop.id,
        sectionId: data.id,
      }),
    );
    dispatch(updateFooter(payloads.response));
  };

  const onFileCreate = async (fileObj) => {
    const payloads = await generateFilePayloads({
      data,
      fileObj,
    });
    dispatch(
      sectionsThunks.createSectionFile({
        data: payloads.request,
        webshopId: webshop.id,
        sectionId: data.id,
      }),
    );
    dispatch(updateFooter(payloads.response));
  };

  const onFileRemove = (fileObj) => {
    const payloads = generateFileRemovePayloads({
      data,
      fileObj,
    });
    dispatch(
      sectionsThunks.deleteSectionFile({
        data: payloads.request,
        webshopId: webshop.id,
        sectionId: data.id,
      }),
    );
    dispatch(updateFooter(payloads.response));
  };

  useEffect(() => {
    return () => {
      debouncedThemeUpdate.flush();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <FooterProperties
      links={links}
      data={data}
      theme={theme}
      onFileCreate={onFileCreate}
      onFileRemove={onFileRemove}
      onThemeChange={onThemeChange}
      onThemeReset={onThemeReset}
      onLinkAdd={(values) => {
        dispatch(
          linksThunks.createLink({
            link: {
              type: LINK_TYPES.TEXT,
              ...values,
              position: data.externalLinks.length,
            },
            webshopId: webshop.id,
            sectionId: data.id,
          }),
        );
      }}
      onLinkUpdate={(link) => {
        dispatch(
          linksThunks.updateLink({
            link,
            webshopId: webshop.id,
            sectionId: data.id,
          }),
        );
      }}
      onLinkDelete={(link) => {
        dispatch(
          linksThunks.deleteLink({
            linkId: link.id,
            webshopId: webshop.id,
            sectionId: data.id,
          }),
        );
      }}
      onSocialAdd={(social) => {
        dispatch(
          linksThunks.createLink({
            link: {
              type: LINK_TYPES.SOCIAL_PLATFORM,
              ...social,
            },
            webshopId: webshop.id,
          }),
        );
      }}
      onSocialUpdate={(social) => {
        dispatch(
          linksThunks.updateLink({
            link: social,
            webshopId: webshop.id,
          }),
        );
      }}
    />
  );
};

FooterPropertiesContainer.propTypes = {
  webshop: PropTypes.object,
  storefront: PropTypes.object,
};

export const FooterProperties = ({
  links = {},
  data = {},
  theme = {},
  onFileCreate = () => {},
  onFileRemove = () => {},
  onThemeChange = () => {},
  onThemeReset = () => {},
  onLinkAdd = () => {},
  onLinkUpdate = () => {},
  onLinkDelete = () => {},
}) => {
  const { termsOfService = {}, privacyPolicy = {}, linksList = [], socialsList = [] } = extractLinks(links, data);
  const [listOrder, setNewOrder] = useReorderList(linksList);
  const [draggedItem, setDraggedItem] = useState(null);

  return (
    <Box>
      <PropertiesHeader />

      <SectionWrapper title="Styles" onReset={onThemeReset}>
        <BackgroundStylePicker target="footer_theme_background" theme={theme} onThemeChange={onThemeChange} />
      </SectionWrapper>

      <SectionWrapper title="Logo">
        <StylePicker
          imageOnly
          noOverlay
          target="footer_theme_logo"
          title="Image"
          presets={theme.presets}
          initialImage={(data?.files || [])[0]?.url}
          onImageChange={(fileObj) => {
            onFileCreate({
              ...fileObj,
              target: FILE_TYPES.MAIN,
            });
          }}
          onImageRemove={() => {
            onFileRemove({
              target: FILE_TYPES.MAIN,
            });
          }}
        />
      </SectionWrapper>

      <SectionWrapper title="Legal">
        <VStack gap="0">
          <ToggleInput
            label={TERMS_LABEL}
            toggled={termsOfService?.visible}
            inputs={[
              {
                label: "Link URL",
                name: "linkUrl",
                value: termsOfService.url,
                ...getValidator(),
              },
            ]}
            onChange={(values = {}) => {
              if (termsOfService.id) {
                onLinkUpdate({
                  ...termsOfService,
                  ...values,
                  label: TERMS_LABEL,
                });
              } else {
                onLinkAdd({
                  ...values,
                  label: TERMS_LABEL,
                });
              }
            }}
          />
          <ToggleInput
            label={PRIVACY_LABEL}
            toggled={privacyPolicy?.visible}
            inputs={[
              {
                label: "Link URL",
                name: "linkUrl",
                value: privacyPolicy.url,
                ...getValidator(),
              },
            ]}
            onChange={(values = {}) => {
              if (privacyPolicy.id) {
                onLinkUpdate({
                  ...privacyPolicy,
                  ...values,
                  label: PRIVACY_LABEL,
                });
              } else {
                onLinkAdd({
                  ...values,
                  label: PRIVACY_LABEL,
                });
              }
            }}
          />
        </VStack>
      </SectionWrapper>

      <SectionWrapper title="Links">
        <VStack gap="0">
          <ReorderGroup axis="y" values={listOrder} onReorder={setNewOrder}>
            {listOrder.map((listItemId) => {
              const link = linksList.find((link) => link.id === listItemId);
              return (
                <ReorderItem
                  key={listItemId}
                  value={listItemId}
                  onDragStart={() => setDraggedItem(listItemId)}
                  onDragEnd={() => {
                    let position = listOrder.indexOf(draggedItem);
                    // Terms and Privacy cant be moved and always precede
                    position += 2;
                    onLinkUpdate({
                      ...link,
                      position: position,
                    });
                  }}
                >
                  <ToggleInput
                    key={`key_${link.id}`}
                    draggable
                    title="Custom Link"
                    label={link.label}
                    toggled={link.visible}
                    inputs={[
                      {
                        label: "Name",
                        name: "name",
                        value: link.label,
                        validate: (value) => {
                          let isValid = true;
                          if (!value) isValid = false;
                          if (value === TERMS_LABEL || value === PRIVACY_LABEL) isValid = false;
                          return isValid;
                        },
                        validateMsg: "Please enter a valid name",
                      },
                      {
                        label: "Link URL",
                        name: "linkUrl",
                        value: link.url,
                        validate: validateURL,
                        validateMsg: "Please enter a valid URL",
                      },
                    ]}
                    onChange={(values = {}) => {
                      onLinkUpdate({
                        ...link,
                        ...values,
                      });
                    }}
                    onDelete={() => {
                      onLinkDelete(link);
                    }}
                  />
                </ReorderItem>
              );
            })}
          </ReorderGroup>

          <ToggleInput
            key={`key-add-new-link`}
            addButton
            showToggle={false}
            title="Custom Link"
            label="Custom link"
            inputs={[
              {
                label: "Name",
                name: "name",
                value: "",
                validate: (value) => {
                  let isValid = true;
                  if (!value) isValid = false;
                  if (value === TERMS_LABEL || value === PRIVACY_LABEL) isValid = false;
                  return isValid;
                },
                validateMsg: "Please enter a valid name",
              },
              {
                label: "Link URL",
                name: "linkUrl",
                value: "",
                validate: validateURL,
                validateMsg: "Please enter a valid URL",
              },
            ]}
            onChange={(values = {}) => {
              onLinkAdd(values);
            }}
          />
        </VStack>
      </SectionWrapper>

      <SectionWrapper title="Socials">
        <VStack gap="0">
          {socialsList?.map((social, index) => (
            <ToggleInput
              key={`key_${social?.platform.id}`}
              label={social?.platform?.name}
              toggled={social.visible}
              inputs={[
                {
                  label: "Account URL",
                  name: "linkUrl",
                  value: social.linkUrl,
                  ...getValidator({
                    platform: social?.platform?.name,
                  }),
                },
              ]}
              onChange={(values = {}) => {
                if (social.id) {
                  onLinkUpdate({
                    id: social.id,
                    platformId: social.platform.id,
                    position: social.position || index,
                    ...social,
                    ...values,
                  });
                } else {
                  onLinkAdd({
                    platformId: social.platform.id,
                    position: social.position || index,
                    ...values,
                  });
                }
              }}
            />
          ))}
        </VStack>
      </SectionWrapper>
    </Box>
  );
};

FooterProperties.propTypes = {
  links: PropTypes.object,
  data: PropTypes.object,
  theme: PropTypes.object,
  onFileCreate: PropTypes.func,
  onFileRemove: PropTypes.func,
  onThemeChange: PropTypes.func,
  onThemeReset: PropTypes.func,
  onLinkAdd: PropTypes.func,
  onLinkUpdate: PropTypes.func,
  onLinkDelete: PropTypes.func,
};
