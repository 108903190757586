import React from "react";

import { COMMON_ACTION } from "@utils/gtm";
import { BodySmall14, BodyRegular16, TitleLargePlus } from "@styled";
import { SanloButton, Link, Support, CollapsableText } from "@inputs";

import symbols from "@utils/symbols";

export const AdjustStep3 = ({
  platform = {
    id: "adjust",
    name: "Adjust",
    platformId: "adjust",
  },
  onContinue = () => {},
  trackEvent = () => {},
}) => {
  const AdvancedPermissionsLink = () => {
    return (
      <Link className="fs-link" href="https://help.adjust.com/en/article/permission-dashboard#getting-started/">
        here
      </Link>
    );
  };

  const AdjustSubscriptionLink = () => {
    return (
      <Link className="fs-link" href="https://www.adjust.com/pricing/">
        {platform.name} subscription
      </Link>
    );
  };

  return (
    <div className="fs-modal-actions">
      <TitleLargePlus className="done-title">
        {symbols.Pizza()} {platform.name} is connected but we're missing some data
      </TitleLargePlus>

      <BodyRegular16 className="done-description">
        Nice work! You have successfully connected your {platform.name} account but it looks like we don't have access
        to some data. We'll reach out to you shortly with next steps.
      </BodyRegular16>

      <CollapsableText
        header="Why did this happen?"
        description={
          <>
            You may need to have the KPI Service API enabled as part of your <AdjustSubscriptionLink />. Please see
            instructions <AdvancedPermissionsLink />.
          </>
        }
      />

      <CollapsableText
        header="How does this affect my experience?"
        description="This will limit the functionality of tracking ad revenue and user acquisition spend on the Dashboard page until we resolve it for you."
      />

      <SanloButton
        isJumbo
        label="Got It"
        onClick={() => {
          trackEvent(`Step3 ${COMMON_ACTION.CONTINUE}`);
          onContinue();
        }}
        testId={`${platform.platformId}-permissions-info-continue-button`}
      />

      <BodySmall14 className="contact-us">
        In the meantime, if you have any questions, please <Support red text="contact us" />.
      </BodySmall14>
    </div>
  );
};
