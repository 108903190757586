import React from "react";
import { HStack } from "@chakra-ui/react";

import { BaseSection, SectionText, SectionIcon } from "@components/builder/SidebarTabs";

import { ReactComponent as AlignTop } from "@assets/img/icons/common/aligntop.svg";

export const HeaderSidebar = ({ selected = false, onClick = () => {} }) => {
  return (
    <BaseSection
      selected={selected}
      style={{
        border: "1px solid transparent",
        borderRadius: 0,
        borderTopRadius: "10px",
        borderColor: selected ? "brandPurple.400" : "dark.300",
        borderBottomColor: selected ? "brandPurple.400" : "dark.300",
      }}
    >
      <HStack padding="1rem" onClick={onClick}>
        <SectionIcon icon={AlignTop} selected={selected} />
        <SectionText>Header</SectionText>
      </HStack>
    </BaseSection>
  );
};
