import HTTP from "@redux/http";

const SANLO_WEB_API_URL = process.env.REACT_APP_SANLO_WEB_API_URL;
const BASE = `${SANLO_WEB_API_URL}/api/capital-offer`;

export default {
  getCapitalOffers: () => HTTP.req.get(`${BASE}`),
  acceptCapitalOffer: (data) => HTTP.req.post(`${BASE}/accept`, data),
  dismissCapitalOffer: (offerId) => HTTP.req.patch(`${BASE}/${offerId}/dismissal-status`),
};
