import styled from "styled-components";
import { ReactComponent as RightArrow } from "@assets/img/icons/common/arrow-right.svg";
import { ReactComponent as AlertCircle } from "@assets/img/icons/common/alertCircle.svg";
import { Card, CardContent } from "@common/cards/card";
import { currencyFormat } from "@utils/currency";
import { useTrackHover, FINANCIALS_ACTION } from "@utils/gtm";
import { useLocation } from "react-router-dom";
import { Box, Button, Flex, HStack, Icon, Text } from "@chakra-ui/react";
import {
  FINANCIAL_INTELLIGENCE_BALANCE_TYPE,
  FINANCIAL_INTELLIGENCE_CARD_ID,
} from "@src/consts/financial-intelligence";
import { Tooltip } from "@common/tooltip";

export function StatCard({
  id = "",
  currentBalance,
  title,
  status,
  tooltip,
  unhealthyTooltip,
  rightIcon,
  onClick,
  isLoading,
  ...rest
}) {
  const [addTracking, removeTracking] = useTrackHover();
  const location = useLocation();
  const isDashboard = location.pathname === "/dashboard";

  const showViewDetails =
    (!isDashboard ||
      id !== FINANCIAL_INTELLIGENCE_CARD_ID.AD_SPEND_RUN_RATE ||
      id !== FINANCIAL_INTELLIGENCE_CARD_ID.UPCOMING_CASH_OUT) &&
    onClick;

  const amount = currentBalance
    ? currencyFormat(currentBalance, {
        shorthand: 10000,
      })
    : "$-";

  const handleMouseEnter = () => {
    addTracking({
      event: FINANCIALS_ACTION.HOVER_TOOLTIP,
      name: title,
      pathname: location.pathname,
    });
  };

  const handleMouseLeave = () => {
    removeTracking();
  };

  return (
    <Stat data-test-id="stat-card" className={isLoading ? "loading-shine" : ""} isLoading={isLoading} {...rest}>
      {rest.comingSoon && (
        <Flex
          position={"absolute"}
          inset={0}
          justifyContent={"center"}
          alignItems={"center"}
          backdropFilter={"blur(8px)"}
        >
          {rest.comingSoon}
        </Flex>
      )}
      <Flex>
        <HStack>
          <Text>{title}</Text>
          {tooltip && (
            <Box as="span" ml="8px">
              <Tooltip
                content={tooltip.content}
                onOpen={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClose={handleMouseLeave}
                id={tooltip.id}
              >
                <Icon as={AlertCircle} mt="-4px" width={"16px"} height={"16px"} transform={"rotate(180deg)"} />
              </Tooltip>
            </Box>
          )}
        </HStack>
        {showViewDetails && (
          <Button
            variant={"link"}
            ml={"auto"}
            onClick={onClick ? onClick : () => {}}
            rightIcon={<Icon as={RightArrow} width={"16px"} height={"16px"} color={"textDefault.400"} />}
          >
            <Text size="small" fontWeight="medium">
              View details
            </Text>
          </Button>
        )}
      </Flex>
      <StatContainer>
        <Flex justifyContent={"space-between"} alignItems={"center"}>
          <Flex alignItems={"center"} gap={"8px"}>
            <Text
              status={status}
              color={
                status
                  ? status === FINANCIAL_INTELLIGENCE_BALANCE_TYPE.INFLOW
                    ? "brandGreen.400"
                    : "brandPurple.400"
                  : "textWhite.400"
              }
              fontWeight="semibold"
              fontSize="2xl"
              lineHeight="32px"
            >
              {status ? (status === FINANCIAL_INTELLIGENCE_BALANCE_TYPE.INFLOW ? "+" : "-") : null}
              {amount}
            </Text>
            {rightIcon && (
              <Box as="span" ml="8px">
                {rightIcon}
              </Box>
            )}
          </Flex>
          {unhealthyTooltip && (
            <Tooltip
              variant={"warnFinancialCard"}
              content={unhealthyTooltip.content}
              id={unhealthyTooltip.id}
              placement={"top"}
            >
              <Icon
                as={AlertCircle}
                mt="-4px"
                width={"16px"}
                height={"16px"}
                transform={"rotate(180deg)"}
                color={"brandYellow.400"}
              />
            </Tooltip>
          )}
        </Flex>
      </StatContainer>
    </Stat>
  );
}

const Stat = styled(({ isLoading, ...props }) => <Card {...props} />)`
  display: flex;
  flex: 1;
  width: 100%;
  margin-left: 0;
  flex-direction: column;
  padding: 24px;
  gap: 16px;

  & > * {
    visibility: ${({ isLoading }) => (isLoading ? "hidden" : "visible")};
  }

  &:active,
  &:focus {
    border-color: transparent;
  }
`;

const StatContainer = styled(CardContent)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
`;
