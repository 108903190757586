import React, { useEffect, useState } from "react";

import { Divider } from "@styled";
import { DisabledBlocker } from "@components/common/loaders";
import { StyledDropdown, StyledToggle, StyledArrow, StyledMenu, StyledOption } from "./SanloDropdown.styled";

import { alphaTrim } from "@utils/general";
import { trackGTMEvent } from "@utils/gtm";
import { parseOptions, findValidOption } from "./utils";
import isNull from "lodash/isNull";
import isEmpty from "lodash/isEmpty";

export const SanloDropdown = ({
  id = "", // Unique ID for this dropdown
  placeholder = "",
  selectInitialEvent = false, // Fire the select event for the first option
  defaultSelectedOption = null, // position of the array, indicating the default selected option
  options = [],
  initialOption = null,
  onSelect = () => {},
  disabled = false,
  testId = "",
  gtmOptionEvent = "",
  ...props
}) => {
  const [parsedOptions, setParsedOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [mainLabel, setMainLabel] = useState("");

  useEffect(() => {
    // Parse all options that come in to be the same schema
    let initOption = !selectedOption ? initialOption : null;
    const { options: newOptions = [], initialOption: newInitialOption = null } = parseOptions(options, initOption);
    if (newInitialOption) {
      setSelectedOption(newInitialOption);
    }
    setParsedOptions(newOptions);
    // eslint-disable-next-line
  }, [options, initialOption]);

  useEffect(() => {
    // After the options are parsed, if there is no placeholder
    // then an initial option needs to be selected
    if (!placeholder && !selectedOption) {
      if (!initialOption) {
        const firstOption = findValidOption(parsedOptions);
        if (firstOption) {
          setSelectedOption(firstOption);
          if (selectInitialEvent) {
            // Trigger the selection event, this can be useful for
            // components that fetch based off the option
            onSelect(firstOption);
          }
        }
        if (!isNull(defaultSelectedOption) && !isEmpty(parsedOptions)) {
          setSelectedOption(parsedOptions[defaultSelectedOption]);
          onSelect(parsedOptions[defaultSelectedOption]);
        }
      }
    }
    // eslint-disable-next-line
  }, [parsedOptions]);

  useEffect(() => {
    const selectedLabel = selectedOption?.label || "";
    setMainLabel(selectedLabel || placeholder);
  }, [placeholder, selectedOption]);

  const keyBase = (index = "", label = "") => {
    return `key_dropdown_${id}_${index}_${alphaTrim(label)}`;
  };

  return (
    <StyledDropdown disabled={disabled} {...props}>
      {disabled && <DisabledBlocker />}

      <StyledToggle>
        {mainLabel}
        <StyledArrow />
      </StyledToggle>

      <StyledMenu>
        {parsedOptions.map((option, index) => {
          const { label = "", type = "" } = option;

          if (type === "DIVIDER") {
            return <Divider key={`${keyBase(index, label)}_divider`} style={{ margin: "6px 0" }} />;
          }

          return (
            <StyledOption
              key={keyBase(index, label)}
              isSelected={selectedOption && option.label === selectedOption?.label}
              isDisabled={option.disabled}
              onClick={(e) => {
                // Check for the option being disabled or the
                // dropdown in general
                if (option.disabled || disabled) return;

                if (gtmOptionEvent) {
                  trackGTMEvent({
                    event: gtmOptionEvent,
                    name: `${option.optionIndex} - ${option.label}`,
                  });
                }

                setSelectedOption(option);
                onSelect(option);
              }}
            >
              {label}
            </StyledOption>
          );
        })}
      </StyledMenu>
    </StyledDropdown>
  );
};
