import React from "react";
import PropTypes from "prop-types";
import { withSectionData } from "../withSectionData";

import { Box, Icon, Text } from "@chakra-ui/react";
import { StylePicker } from "../../StylePicker";
import { BackgroundStylePicker } from "../BackgroundStylePicker";
import { PropertiesHeader, SectionWrapper, COLOR_TYPES } from "@utils/webshop/section";

import { ReactComponent as TooltipIcon } from "@assets/img/icons/common/tooltip.svg";

const NavigationPropertiesComponent = ({
  data,
  theme,
  onFileCreate,
  onFileUpdate,
  onFileRemove,
  onThemeChange,
  onThemeReset,
}) => (
  <NavigationProperties
    data={data}
    theme={theme}
    onFileCreate={onFileCreate}
    onFileUpdate={onFileUpdate}
    onFileRemove={onFileRemove}
    onThemeChange={onThemeChange}
    onThemeReset={onThemeReset}
  />
);

NavigationPropertiesComponent.propTypes = {
  data: PropTypes.object,
  theme: PropTypes.object,
  onFileCreate: PropTypes.func,
  onFileUpdate: PropTypes.func,
  onFileRemove: PropTypes.func,
  onThemeChange: PropTypes.func,
  onThemeReset: PropTypes.func,
};

export const NavigationPropertiesContainer = withSectionData(NavigationPropertiesComponent);

export const NavigationProperties = ({
  data = {},
  theme = {},
  onFileCreate = () => {},
  onFileUpdate = () => {},
  onFileRemove = () => {},
  onThemeChange = () => {},
  onThemeReset = () => {},
}) => {
  return (
    <Box>
      <PropertiesHeader />

      <SectionWrapper title="Styles" onReset={onThemeReset}>
        <StylePicker
          target="items_theme_primary"
          title="Text Color"
          presets={theme.presets}
          initialColor={theme.secondary}
          onColorChange={(colorObj) => {
            onThemeChange({
              ...colorObj,
              targetColor: COLOR_TYPES.SECONDARY,
            });
          }}
        />
        <StylePicker
          target="items_theme_secondary"
          title="Button"
          presets={theme.presets}
          initialColor={theme.primary}
          onColorChange={(colorObj) => {
            onThemeChange({
              ...colorObj,
              targetColor: COLOR_TYPES.PRIMARY,
            });
          }}
        />
        <BackgroundStylePicker
          image
          target="navigation_theme_background"
          theme={theme}
          data={data}
          onThemeChange={onThemeChange}
          onFileCreate={onFileCreate}
          onFileUpdate={onFileUpdate}
          onFileRemove={onFileRemove}
        />
      </SectionWrapper>

      <SectionWrapper
        title="Anchor links"
        icon={<Icon as={TooltipIcon} w={"16px"} h={"16px"} ml="4px" color={"textWhite.400"} />}
      >
        <Text fontSize="sm" color="textSubtext.400" px="12px">
          Anchor link urls can be found in the properties panel for each section you’ve created.
        </Text>
      </SectionWrapper>
    </Box>
  );
};

NavigationProperties.propTypes = {
  data: PropTypes.object,
  theme: PropTypes.object,
  onFileCreate: PropTypes.func,
  onFileUpdate: PropTypes.func,
  onFileRemove: PropTypes.func,
  onThemeChange: PropTypes.func,
  onThemeReset: PropTypes.func,
};
