import styled from "styled-components";

const StyledLink = styled.a.attrs((props) => ({
  rel: props.isExternalLink ? "noopener noreferrer" : "",
  target: props.isExternalLink ? "_blank" : "_same",
  onClick: props.onClick ? props.onClick : null,
}))`
  color: ${(props) => props.color || "inherit"};
  font-size: inherit;
  text-decoration: ${(props) => props.textDecoration || "none"};

  &:hover {
    color: currentColor;
    text-decoration: ${(props) => props.textDecoration || "none"};
  }

  ${({ red, theme }) =>
    red &&
    `
    color: ${theme.colorRed};
  `};
`;

export const Link = ({ label, children, ...rest }) => {
  return <StyledLink {...rest}>{label || children}</StyledLink>;
};
