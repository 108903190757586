import { FINANCIALS_ACTION, trackGTMEvent } from "@utils/gtm";
import { useDispatch, useSelector } from "react-redux";
import { financialsSelector } from "@redux/financials/financialsSlice";
import symbols from "@src/utils/symbols";
import { Text } from "@chakra-ui/react";
import { InternalLink } from "@common/link";
import Sparklines from "@components/dashboard/company-finances/Sparklines";
import { useLocation, useNavigate } from "react-router-dom";
import { StatCard } from "@common/stat-card";
import { financialsThunks } from "@src/redux/financials/financialsSlice";
import { useEffect } from "react";
import { CollapsableTooltipBody } from "@common/tooltip/Tooltip";
import { trackCollapseContent } from "@src/events/financial-intelligence/tooltipEvents";
import { FINANCIAL_INTELLIGENCE_CARD_ID } from "@src/consts/financial-intelligence";
import { ReconnectTooltip } from "@utils/financial-intelligence";
import { getBankingBlurData } from "@utils/blur";

export function BankingCard() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const integrations = useSelector((state) => state.integrations);
  const { isPartiallyHealthy } = getBankingBlurData(integrations.plaid.data.institutions);
  const { getClosingBalance } = useSelector(financialsSelector);
  const isUSAAccount = getClosingBalance.data?.currency === "USD";
  const currentBalance = isUSAAccount && Number(getClosingBalance.data?.currentOverallBalanceCents ?? 0) / 100;
  const historicalBalances = getClosingBalance.data?.historicalBalances?.map((balance) => balance / 100);
  const CURRENT_BANK_BALANCE = "Current bank balance";
  const isLoading = !getClosingBalance.data && getClosingBalance.requestStatus.pending;

  useEffect(() => {
    dispatch(financialsThunks.getClosingBalance());
  }, [dispatch]);

  const bankingCardInfo = {
    id: FINANCIAL_INTELLIGENCE_CARD_ID.CURRENT_BANK_BALANCE,
    title: `${symbols.bank} ${CURRENT_BANK_BALANCE}`,
    currentBalance,
    rightIcon: <Sparklines data-test-id="banking-card-spark-line" values={historicalBalances} width={36} height={24} />,
    onClick: () => {
      trackGTMEvent({
        event: FINANCIALS_ACTION.VIEW_DETAILS,
        card: CURRENT_BANK_BALANCE,
      });
      const newLocation = {
        pathname: "/financial-analytics/banking-details",
        state: {
          prevPath: location.pathname,
        },
      };

      navigate(newLocation);
    },
    tooltip: {
      id: "stat-card-current-bank-balance-tooltip",
      content: ({ isCollapseOpen, onToggle }) => (
        <>
          <Text size="mini">This is your total current cash balance across all connected bank accounts.</Text>
          <CollapsableTooltipBody
            isCollapseOpen={isCollapseOpen}
            onToggle={() => {
              trackCollapseContent({
                name: CURRENT_BANK_BALANCE,
                isOpen: isCollapseOpen,
              });
              onToggle();
            }}
          >
            <Text size="mini">
              For a complete picture of your finances, please visit the
              <InternalLink
                to="/integrations"
                data-test-id={"integration-page-link"}
                onClick={() =>
                  trackGTMEvent({
                    event: FINANCIALS_ACTION.INTEGRATION_PAGE_LINK,
                    tooltip: CURRENT_BANK_BALANCE,
                  })
                }
              >
                {" "}
                Integrations page{" "}
              </InternalLink>
              page and ensure you've connected all bank accounts associated with your business.
            </Text>
          </CollapsableTooltipBody>
        </>
      ),
    },
    unhealthyTooltip: isPartiallyHealthy
      ? {
          id: "unhealthy-current-bank-balance-tooltip",
          content: <ReconnectTooltip source={"banks"} />,
        }
      : null,
  };

  return <StatCard data-test-id="banking-card" isLoading={isLoading} {...bankingCardInfo} />;
}
