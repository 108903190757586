export const getRedirectUrl = (action, response) => {
  // Test users don't have a real connection, so response connection = null
  const { redirectTo, linkType } = action;

  let redirectUrl = response.linkUrl;
  if (redirectTo) {
    redirectUrl += `?redirectTo=${redirectTo}`;
  }
  if (linkType) {
    redirectUrl += `?linkingType=${linkType}`;
  }

  return redirectUrl;
};
