import React, { useState } from "react";
import styled from "styled-components";
import { trackGTMEvent, FINANCIALS_ACTION } from "@utils/gtm";

import { AccordionArrow } from "./AccordionArrow";

export const StyledAccordion = styled.div`
  position: relative;
  padding-top: 16px;
`;

const StyledHeader = styled.div`
  position: relative;
  padding: 8px 0;
`;

const StyledContent = styled.div`
  position: relative;
  height: 0;
  overflow: hidden;
  ${({ expanded }) =>
    expanded &&
    `
    height: auto;
  `};
`;

export const Accordion = ({ isExpanded = true, headerContent = "", children, trackData }) => {
  const [expanded, setExpanded] = useState(isExpanded);

  return (
    <StyledAccordion expanded={expanded}>
      <StyledHeader>
        <AccordionArrow
          expanded={expanded}
          onClick={() => {
            trackGTMEvent({
              event: FINANCIALS_ACTION.EXPAND_ACCORDION,
              name: trackData?.name || "",
              state: `${expanded ? "Close" : "Expand"}`,
            });
            setExpanded(!expanded);
          }}
        />
        {headerContent}
      </StyledHeader>
      <StyledContent expanded={expanded}>{children}</StyledContent>
    </StyledAccordion>
  );
};
