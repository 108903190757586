import isEmpty from "lodash/isEmpty";
import {
  CONNECTED,
  DRAFT_CONNECTION,
  PULLING_DATA,
  CONNECTOR_ERROR,
  NEEDS_RECONNECTION,
  VERIFYING_CONNECTION,
} from "../status";

export const getBankingBlurData = (institutions = []) => {
  const isEmptyOrAllDraft =
    isEmpty(institutions) || institutions.every((institution) => institution.status === DRAFT_CONNECTION);

  const isConnected = institutions.some((institution) => institution.status === CONNECTED);

  const isPullingData =
    !isEmptyOrAllDraft && !isConnected && institutions.some((institution) => institution.status === PULLING_DATA);

  const isBroken =
    !isEmptyOrAllDraft &&
    institutions.every((institution) => [NEEDS_RECONNECTION, CONNECTOR_ERROR].includes(institution.status));

  const errorOrReconnection =
    !isEmptyOrAllDraft &&
    institutions.some((institution) => [NEEDS_RECONNECTION, CONNECTOR_ERROR].includes(institution.status));

  const isPartiallyHealthy = isConnected && errorOrReconnection;

  return {
    isBlurred: !isConnected && (isEmptyOrAllDraft || isPullingData || isBroken || isPartiallyHealthy),
    isPullingData,
    isBroken,
    isPartiallyHealthy,
  };
};

export const getIntegrationBlurData = (integrations) => {
  let connected = [];
  let pullingData = [];
  let errorOrReconnection = [];

  for (const key in integrations) {
    if (integrations[key].type && integrations[key].type === "platforms") {
      if (!integrations[key].manual || (integrations[key].manual && integrations[key].manual === false)) {
        if (integrations[key].status && integrations[key].status === CONNECTED) {
          connected.push(integrations[key]);
        }
        if (integrations[key].status && [PULLING_DATA, VERIFYING_CONNECTION].includes(integrations[key].status)) {
          pullingData.push(integrations[key]);
        }
        if (integrations[key].status && [NEEDS_RECONNECTION, CONNECTOR_ERROR].includes(integrations[key].status)) {
          errorOrReconnection.push(integrations[key]);
        }
      }
    }
  }

  return {
    isConnected: !isEmpty(connected),
    isPullingData: !isEmpty(pullingData),
    isBroken: !isEmpty(errorOrReconnection),
    isPartiallyHealthy: !isEmpty(connected) && !isEmpty(errorOrReconnection),
  };
};

export const getBlurredData = (data = [], integrations = []) => {
  const { isPullingData, isBroken, isPartiallyHealthy } = getIntegrationBlurData(integrations);

  const isBlurred = isEmpty(data) || isPullingData || isBroken;
  return { isBlurred, isPullingData, isBroken, isPartiallyHealthy };
};
