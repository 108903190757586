import React from "react";

import { BodySmallAlt } from "@styled";
import PrimaryButton from "@common/buttons/primary-button/PrimaryButton";

const NeedsReconnectionBodyState = ({ selectedOption, onOptionConnectClick }) => {
  return (
    <div className="dr-message">
      <BodySmallAlt className="dr-message-text">
        Something went wrong with the {selectedOption.name} connection, please re-enter your credentials.
      </BodySmallAlt>

      <PrimaryButton
        className="dr-message-button yellow-button"
        label="Reconnect"
        onClick={(e) => {
          onOptionConnectClick(selectedOption);
        }}
      />
    </div>
  );
};

export default NeedsReconnectionBodyState;
