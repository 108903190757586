import React from "react";

import copyToClipboard from "@utils/CopyToClipboard";

import { ReactComponent as CopyIcon } from "@assets/img/icons/common/copy.svg";

export const CopyButton = ({ text = "", onClick = () => {} }) => {
  return (
    <span>
      <CopyIcon
        onClick={() => {
          copyToClipboard(text);
          onClick(text);
        }}
        aria-hidden={true}
        title="Copy Email"
        style={{
          cursor: "pointer",
        }}
      />
    </span>
  );
};
