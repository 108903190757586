export const confettiColors = ["FE94FF99", "2DFAB099", "FF5C7999", "8280FF99"];

export const confettiCanvasStyles = {
  position: "absolute",
  pointerEvents: "none",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
};
