import React, { useRef, useState } from "react";
import classnames from "classnames";

import { BodySmallAlt } from "@styled";
import { StyledDragAndDropZone } from "./DragAndDropZone.styled";

import text from "@utils/text";

import { trackGTMEvent, ONBOARDING_ACTIONS } from "@utils/gtm";
import { ReactComponent as FilePlus } from "@assets/img/icons/common/file-plus.svg";
import { ReactComponent as CloudUpload } from "@assets/img/icons/common/cloud-upload.svg";

const DragAndDropZone = ({ isUploading, openFileExplorer, addFiles }) => {
  const dragDropZone = useRef(null);

  const [dragging, setDragging] = useState(false);

  const toggleDragStyle = (value) => {
    setDragging(value);
    const dropZoneClassList = dragDropZone.current.classList;
    if (value) {
      dropZoneClassList.add("uploading-files");
    } else {
      dropZoneClassList.remove("uploading-files");
    }
  };

  const dragEnter = (e) => {
    e.preventDefault();
    toggleDragStyle(true);
  };

  const dragLeave = (e) => {
    e.preventDefault();
    toggleDragStyle(false);
  };

  const dragOver = (e) => {
    e.preventDefault();
  };

  const dropFile = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    addFiles(files);
    toggleDragStyle(false);
  };

  return (
    <StyledDragAndDropZone
      ref={dragDropZone}
      onDragEnter={dragEnter}
      onDragLeave={dragLeave}
      onDragOver={dragOver}
      onDrop={dropFile}
      className={classnames("upload-statements-box", {
        "uploading-files": isUploading,
      })}
    >
      {dragging ? (
        <CloudUpload className="cloud-upload-icon tag-sanlo-add-files-icon-cta-banking-statements" />
      ) : (
        <FilePlus
          data-test-id="upload-statements-svg"
          className="add-files-icon-cta tag-sanlo-add-files-icon-cta-banking-statements"
          onClick={() => {
            trackGTMEvent({
              event: ONBOARDING_ACTIONS.CONNECT_SOURCE_BANKING_UPLOAD_FILE,
            });
            openFileExplorer();
          }}
        />
      )}
      <BodySmallAlt className="drag-files-text">{text.t("drop_statements_here")}</BodySmallAlt>
    </StyledDragAndDropZone>
  );
};

export default DragAndDropZone;
