import React from "react";

import SanloWebApiService from "@services/SanloWebApiService";

import {
  SanloModal,
  SanloModalTitle,
  SanloModalContent,
  SanloModalFooter,
} from "@components/common/modals/SanloModal/SanloModal";
import { SanloButton, SanloInput } from "@inputs";

import { isValidEmail } from "@utils/format";

export const ResetPasswordRecovery = ({ email, setEmail, onNextStep }) => {
  const disabled = !isValidEmail(email);

  const sendResetPasswordEmail = () => {
    SanloWebApiService.sendChangePasswordLink(email).then(() => {
      onNextStep();
    });
  };

  return (
    <SanloModal>
      <SanloModalTitle>Reset your password</SanloModalTitle>
      <SanloModalContent>
        <SanloInput
          hasFocus
          type="email"
          label="Email"
          placeholder="Enter your email"
          value={email}
          setValue={setEmail}
        />
        <SanloButton
          isDisabled={disabled}
          onClick={sendResetPasswordEmail}
          label="Send Recovery Link"
          style={{
            margin: "24px 0",
          }}
        />
      </SanloModalContent>
      <SanloModalFooter center>
        Powered by <img src={require("@assets/img/logos/auth0-logo.png")} alt="" />
      </SanloModalFooter>
    </SanloModal>
  );
};
