import styled from "styled-components";

const colors = (theme, fill) => ({
  required: theme.colorOrange,
  optional: theme.colorSkyBlue,
  reconnect: theme.colorYellow,
  noPermissions: theme.colorYellow,
  brokenConnection: theme.colorRed,
  connected: theme.colorGreen,
  inProgress: fill ? `${theme.colorGreen}80` : theme.textGray,
});

const colorsV2 = (theme, fill) => ({
  connected: theme.colorGreen,
  inProgress: fill ? theme.colorSkyBlue : theme.colorElectricBlue,
});

const accordionColors = (theme, onboardingV2 = false, fill = false) => {
  return onboardingV2 ? colorsV2(theme, fill) : colors(theme, fill);
};

const StyledPlatformAccordion = styled.div`
  & {
    .gradient-border {
      background: ${({ theme }) => theme.grayscale30};
      border-radius: 10px;
      padding: 2px;

      &.is-expanded {
        background: linear-gradient(99.54deg, #cc6bea 2.08%, #fd6083 100%);

        &.gray-border {
          background: ${({ theme }) => theme.grayscale50};
        }
      }
    }

    .accordion-wrapper {
      background-color: ${({ theme }) => theme.grayscale30};
      border-radius: 10px;
      padding: 0 16px;

      .accordion-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        padding: 16px 0;
        border-bottom: 1px solid ${({ theme }) => theme.grayscale50};

        &.is-collapsed {
          border-bottom: none;
        }

        .header-left {
          .accordion-flag {
            fill: ${({ theme, status, onboardingV2 }) => accordionColors(theme, onboardingV2, true)[status]};
            margin-right: 20px;
          }

          .accordion-title {
            color: ${({ theme }) => theme.textSeashellWhite};
          }
        }

        .header-right {
          display: flex;
          align-items: center;

          .accordion-platform-type {
            padding: 4px 8px;
            border-radius: 20px;
            margin-right: 20px;
            color: ${({ theme, status, onboardingV2 }) => accordionColors(theme, onboardingV2, onboardingV2)[status]};
            background-color: ${({ theme, status, onboardingV2 }) => accordionColors(theme, onboardingV2)[status]}29;

            &.loading-placeholder {
              height: 24px;
              width: 100px;
            }
          }

          .accordion-icon {
            transform: scale(1.5) rotate(180deg);
            transition: transform 0.2s ease;
            fill: ${({ theme }) => theme.textGray};

            &.is-expanded {
              transform: scale(1.5) rotate(0);
            }
          }
        }
      }

      .accordion-content {
        overflow: hidden;
        transition: max-height 0.25s linear;

        .accordion-content-padding {
          padding: 16px 0;
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
`;
export default StyledPlatformAccordion;
