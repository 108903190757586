import React, { useEffect } from "react";
import { useRequestPlaidLink } from "@utils/integrations/banking/useRequestPlaidLink";
import isEmpty from "lodash/isEmpty";

import classnames from "classnames";

const WiseLink = ({ linkText = "Wise" }) => {
  return (
    <a
      rel="noopener noreferrer"
      target="_blank"
      className="tt-hint-link"
      href="https://wise.com/gb/currency-converter/"
    >
      {linkText}
    </a>
  );
};

const AppleSalesLink = ({ linkText = "Apple's sales and trends" }) => {
  return (
    <a
      rel="noopener noreferrer"
      target="_blank"
      href="https://help.apple.com/itc/bookssalesandtrends/#/itc49a5979d8"
      className="tt-hint-link"
    >
      {linkText}
    </a>
  );
};

const GoogleSalesLink = ({ linkText = "Google play earnings" }) => {
  return (
    <a
      rel="noopener noreferrer"
      target="_blank"
      href="https://support.google.com/googleplay/android-developer/answer/6135870?hl=en#zippy=%2Cearnings%2Cestimated-sales"
      className="tt-hint-link"
    >
      {linkText}
    </a>
  );
};

// Some small helpers to DRY up and help minimize the tooltip copies
// ToolTipHeader
const TTH = ({ children }) => {
  return (
    <div className="tt-hint-content">
      <div className="tt-hint-p">{children}</div>
    </div>
  );
};
// ToolTipRow
const TTR = ({ children, red }) => {
  return (
    <div
      className={classnames("tt-hint-p", {
        "tt-hint-highlighted-red": red,
      })}
    >
      {children}
    </div>
  );
};
// ToolTipContent
const TTC = ({ children }) => {
  return <div className="tt-hint-content">{children}</div>;
};
// HighLight
const HL = ({ children }) => {
  return <span className="tt-hint-highlighted">{children}</span>;
};

const BankingLink = () => {
  const [open, ready, plaidConfig, getPlaidLink] = useRequestPlaidLink();

  useEffect(() => {
    if (!isEmpty(plaidConfig.token) && ready) {
      open();
    }
  }, [plaidConfig, ready, open]);
  return (
    <span
      className="tt-hint-highlighted-red tt-link"
      onClick={() => {
        getPlaidLink();
      }}
    >
      Connect Bank Account
    </span>
  );
};

// NegativeEarningsNote
const NEN = "You may see negative earnings if the amount of refunds was higher than revenue.";

const wiseCurrencyWithPlatform = (platform) => (
  <>
    We use <u>Wise's</u> currency exchange rates from the day {platform.name} published Financial report to convert all
    the earnings into USD.
  </>
);

const wiseCurrency = () => (
  <>
    We use <u>Wise's</u> currency exchange rates from the last day of the fiscal period to convert each day's earnings
    into USD.
  </>
);

// TODO Account name and number
const tooltipsCopies = {
  appstore: {
    completedLessThan13DaysAgo: {
      header: (
        <TTH>
          This amount is an estimate. It excludes sales tax, platform fee, refunds, and uncollected customer payments.{" "}
          {NEN}
        </TTH>
      ),
      description: (
        <TTC>
          <TTR>
            <HL>Why it's estimated:</HL> The actual amount you receive from the app store may be different due to
            currency exchange rates, transaction processing and reporting timeframes.
          </TTR>
          <TTR>
            <HL>Exchange rates:</HL> We use <WiseLink />
            's currency exchange rates from the last day of the fiscal period to convert each day's earnings into USD.
          </TTR>
          <TTR>
            <HL>Source:</HL> <AppleSalesLink /> reports.
          </TTR>
        </TTC>
      ),
    },
    completedBetween13And32DaysAgo: {
      header: (
        <TTH>
          This amount is an estimate until you receive payment in your bank account. It excludes sales tax, platform
          fee, refunds, and uncollected customer payments. {NEN}
        </TTH>
      ),
      description: (
        <TTC>
          <TTR>
            <HL>Why it's estimated:</HL> The actual amount you receive from the app store may be different due to
            changes in the foreign currency exchange rates by the time of payment, and any applicable withholding tax.
          </TTR>
          <TTR>
            <HL>Exchange rates:</HL> We use <WiseLink />
            's currency exchange rates from the last day of the fiscal period to convert all the amounts into USD.
          </TTR>
          <TTR>
            <HL>Source:</HL> <AppleSalesLink linkText="Apple's payments and financial reports" />.
          </TTR>
        </TTC>
      ),
    },
    completedWithoutBank: {
      header: (
        <TTH>
          This amount is an estimate until you receive payment in your bank account. It excludes sales tax, platform
          fee, refunds, and uncollected customer payments. {NEN}
        </TTH>
      ),
      description: (
        <TTC>
          <TTR>
            <HL>Why it's estimated:</HL> The actual amount you receive from the app store may be different due to
            changes in the foreign currency exchange rates by the time of payment, and any applicable withholding tax.
          </TTR>
          <TTR>
            <BankingLink /> now to track actual amount paid.
          </TTR>
          <TTR>
            <HL>Exchange rates:</HL> We use <WiseLink />
            's currency exchange rates from the last day of the fiscal period to convert all the amounts into USD.
          </TTR>
          <TTR>
            <HL>Source:</HL> <AppleSalesLink linkText="Apple's payments and financial reports" />.
          </TTR>
        </TTC>
      ),
    },
    completedWithBank: {
      header: <TTH>This is a final amount paid for the fiscal period.</TTH>,
      description: (
        <TTC>
          <TTR>
            <HL>Exchange rates:</HL> We use <WiseLink />
            's currency exchange rates from the day you receive the payment to convert it into USD.
          </TTR>
          <TTR>
            <HL>Source:</HL> [Account Name] *1234
          </TTR>
        </TTC>
      ),
    },
    current: {
      header: (
        <TTH>
          This amount is an estimate. It excludes sales tax, platform fee, refunds, and uncollected customer payments.{" "}
          {NEN}
        </TTH>
      ),
      description: (
        <TTC>
          <TTR>
            <HL>Why it's estimated:</HL> The actual amount you receive from the app store may be different due to
            currency exchange rates, transaction processing and reporting timeframes.
          </TTR>
          <TTR>
            <HL>Exchange rates:</HL> We use update each day's earnings using <WiseLink />
            's currency exchange rates at the end of each day to convert all the amounts into USD.
          </TTR>
          <TTR>
            <HL>Source:</HL> <AppleSalesLink /> reports.
          </TTR>
        </TTC>
      ),
    },
    currentPayout: {
      header: (
        <TTH>
          This amount is an estimate. It excludes sales tax, platform fee, refunds, and uncollected customer payments.{" "}
          {NEN}
        </TTH>
      ),
      description: (
        <TTC>
          <TTR>
            <HL>Why it's estimated:</HL> The fiscal period hasn't ended yet and you are still likely to have more
            earnings. The actual amount you receive from the app store may be different due to currency exchange rates,
            transaction processing and reporting timeframes.
          </TTR>
          <TTR>
            <HL>Exchange rates:</HL> Update each day's earnings using <WiseLink />
            's currency exchange rates at the end of each day to convert all the amounts into USD.
          </TTR>
          <TTR>
            <HL>Source:</HL> <AppleSalesLink /> reports.
          </TTR>
        </TTC>
      ),
    },
  },
  googleplay: {
    currentDaily: {
      header: (
        <TTH>
          This amount is an estimate. It contains the amounts paid by your users excluding refunds, sales tax and Google
          platform fees. We estimate the sales tax and platform fees. {NEN}
        </TTH>
      ),
      description: (
        <TTC>
          <TTR>
            <HL>Why it's estimated:</HL> The actual amount you receive from google play may be different due to currency
            exchange rates, withholding taxes or chargebacks.{" "}
          </TTR>
          <TTR>
            <HL>Exchange rates:</HL> We update each day's earnings using <WiseLink />
            's currency exchange rates at the end of each day to convert all the amounts into USD.
          </TTR>
          <TTR>
            <HL>Source:</HL> <GoogleSalesLink linkText="Google play estimated sales" /> reports.
          </TTR>
        </TTC>
      ),
    },
    completed1To4DaysOfFollowingMonth: {
      header: (
        <TTH>
          This amount is an estimate. It contains the amounts paid by your users excluding refunds, sales tax and Google
          platform fees. We estimate the sales tax and platform fees. {NEN}
        </TTH>
      ),
      description: (
        <TTC>
          <TTR>
            <HL>Why it's estimated:</HL> The actual amount you receive from google play may be different due to currency
            exchange rates, withholding taxes or chargebacks.
          </TTR>
          <TTR>
            <HL>Exchange rates:</HL> We use <WiseLink />
            's currency exchange rates from the last day of the fiscal period to convert all the amounts into USD.
          </TTR>
          <TTR>
            <HL>Source:</HL> <GoogleSalesLink linkText="Google play estimated sales" /> reports.
          </TTR>
        </TTC>
      ),
    },
    completed5To15DaysOfFollowingMonth: {
      header: (
        <TTH>
          This amount is an estimate. It contains the amounts paid by your users excluding refunds, sales tax and Google
          platform fees. We estimate the sales tax and platform fees. {NEN}
        </TTH>
      ),
      description: (
        <TTC>
          <TTR>
            <HL>Why it's estimated:</HL> The actual amount you receive from google play may be different due to currency
            exchange rates, withholding taxes or chargebacks.
          </TTR>
          <TTR>
            <HL>Exchange rates:</HL> We use <WiseLink />
            's currency exchange rates from the last day of the fiscal period to convert all the amounts into USD.
          </TTR>
          <TTR>
            <HL>Source:</HL> <GoogleSalesLink /> report.
          </TTR>
        </TTC>
      ),
    },
    completedWithoutBank: {
      header: (
        <TTH>
          This amount is an estimate. It contains the amounts paid by your users excluding refunds, sales tax and Google
          platform fees. We estimate the sales tax and platform fees. {NEN}
        </TTH>
      ),
      description: (
        <TTC>
          <TTR>
            <HL>Why it's estimated:</HL> The actual amount you receive from google play may be different due to currency
            exchange rates, withholding taxes or chargebacks.
          </TTR>
          <TTR>
            <HL>Exchange rates:</HL> We use <WiseLink />
            's currency exchange rates from the last day of the fiscal period to convert all the amounts into USD.
          </TTR>
          <TTR>
            <HL>Source:</HL> <GoogleSalesLink /> report.
          </TTR>
        </TTC>
      ),
    },
    completedWithBank: {
      header: <TTH>This is a final amount paid for the fiscal period.</TTH>,
      description: (
        <TTC>
          <TTR>
            <HL>Exchange rates:</HL> We use <WiseLink />
            's currency exchange rates from the day you receive the payment to convert the amounts into USD.
          </TTR>
          <TTR>
            <HL>Source:</HL> Account Name *1245
          </TTR>
        </TTC>
      ),
    },
    currentPayout: {
      header: (
        <TTH>
          This amount is an estimate. It contains the amounts paid by your users excluding refunds, sales tax and Google
          platform fees. We estimate the sales tax and platform fees. {NEN}
        </TTH>
      ),
      description: (
        <TTC>
          <TTR>
            <HL>Why it's estimated:</HL> The fiscal period hasn't ended yet, so your earnings, may fluctuate up or down.
            The actual amount you receive from the google play may be different due to currency exchange rates,
            withholding taxes or chargebacks.
          </TTR>
          <TTR>
            <HL>Exchange rates:</HL> We update each day's earnings using <WiseLink />
            's currency exchange rate at the end of each day to convert all the amounts into USD.
          </TTR>
          <TTR>
            <HL>Source:</HL> <GoogleSalesLink linkText="Google play estimated sales" /> reports.
          </TTR>
        </TTC>
      ),
    },
    completed5To15DaysOfFollowingMonthPayout: {
      header: (
        <TTH>
          This amount is an estimate until you receive payment in your bank account. It excludes sales tax, platform
          fee, refunds, and uncollected customer payments. {NEN}
        </TTH>
      ),
      description: (
        <TTC>
          <TTR>
            <HL>Why it's estimated:</HL> The actual amount you receive from google play may be different due to changes
            in the foreign currency exchange rates by the time of payment, and any applicable withholding tax.
          </TTR>
          <TTR>
            <HL>Exchange rates:</HL> We use <WiseLink />
            's currency exchange rates from the last day of the fiscal period to convert all the amounts into USD.
          </TTR>
          <TTR>
            <HL>Source:</HL> <GoogleSalesLink /> report.
          </TTR>
        </TTC>
      ),
    },
    completedWithoutBankPayout: {
      header: (
        <TTH>
          This amount is an estimate until you receive payment in your bank account. It excludes sales tax, platform
          fee, refunds, and uncollected customer payments. {NEN}
        </TTH>
      ),
      description: (
        <TTC>
          <TTR>
            <HL>Why it's estimated:</HL> The actual amount you receive from google play may be different due to changes
            in the foreign currency exchange rates by the time of payment, and any applicable withholding tax.
          </TTR>
          <TTR>
            <BankingLink /> now to track actual amount paid.
          </TTR>
          <TTR>
            <HL>Exchange rates:</HL> We use <WiseLink />
            's currency exchange rates from the last day of the fiscal period to convert all the amounts into USD.
          </TTR>
          <TTR>
            <HL>Source:</HL> <GoogleSalesLink /> report.
          </TTR>
        </TTC>
      ),
    },
  },
  generic: {
    completedLessThan13DaysAgo: {
      header: (
        <TTH>
          This amount is an estimate. It excludes sales tax, platform fee, refunds, and uncollected customer payments.{" "}
          {NEN}
        </TTH>
      ),
      description: (
        <TTC>
          <TTR>
            <HL>Why it's estimated:</HL> The actual amount you receive may be different due to currency exchange rates,
            transaction processing and reporting timeframes.
          </TTR>
          <TTR>
            <HL>Exchange rates:</HL> We use <WiseLink />
            's currency exchange rates from the last day of the fiscal period to convert each day's earnings into USD.
          </TTR>
        </TTC>
      ),
    },
    completedBetween13And32DaysAgo: {
      header: (
        <TTH>
          This amount is an estimate until you receive payment in your bank account. It excludes sales tax, platform
          fee, refunds, and uncollected customer payments. {NEN}
        </TTH>
      ),
      description: (
        <TTC>
          <TTR>
            <HL>Why it's estimated:</HL> The actual amount you receive may be different due to changes in the foreign
            currency exchange rates by the time of payment, and any applicable withholding tax.
          </TTR>
          <TTR>
            <HL>Exchange rates:</HL> We use <WiseLink />
            's currency exchange rates from the last day of the fiscal period to convert all the amounts into USD.
          </TTR>
        </TTC>
      ),
    },
    completedWithoutBank: {
      header: (
        <TTH>
          This amount is an estimate until you receive payment in your bank account. It excludes sales tax, platform
          fee, refunds, and uncollected customer payments. {NEN}
        </TTH>
      ),
      description: (
        <TTC>
          <TTR>
            <HL>Why it's estimated:</HL> The actual amount you receive may be different due to changes in the foreign
            currency exchange rates by the time of payment, and any applicable withholding tax.
          </TTR>
          <TTR>
            <BankingLink /> now to track actual amount paid.
          </TTR>
          <TTR>
            <HL>Exchange rates:</HL> We use <WiseLink />
            's currency exchange rates from the last day of the fiscal period to convert all the amounts into USD.
          </TTR>
        </TTC>
      ),
    },
    completedWithBank: {
      header: <TTH>This is a final amount paid for the fiscal period.</TTH>,
      description: (
        <TTC>
          <TTR>
            <HL>Exchange rates:</HL> We use <WiseLink />
            's currency exchange rates from the day you receive the payment to convert it into USD.
          </TTR>
          <TTR>
            <HL>Source:</HL> [Account Name] *1234
          </TTR>
        </TTC>
      ),
    },
    current: {
      header: (
        <TTH>
          This amount is an estimate. It excludes sales tax, platform fee, refunds, and uncollected customer payments.{" "}
          {NEN}
        </TTH>
      ),
      description: (
        <TTC>
          <TTR>
            <HL>Why it's estimated:</HL> The actual amount you receive may be different due to currency exchange rates,
            transaction processing and reporting timeframes.
          </TTR>
          <TTR>
            <HL>Exchange rates:</HL> We use update each day's earnings using <WiseLink />
            's currency exchange rates at the end of each day to convert all the amounts into USD.
          </TTR>
        </TTC>
      ),
    },
    currentPayout: {
      header: (
        <TTH>
          This amount is total estimate from all platforms. It excludes sales tax, platform fee, refunds, and
          uncollected customer payments. {NEN}
        </TTH>
      ),
      description: (
        <TTC>
          <TTR>
            <HL>Why it's estimated:</HL> The actual amount you receive from each individual platform may be different
            due to currency exchange rates, transaction processing and reporting timeframes.
          </TTR>
          <TTR>
            <HL>Exchange rates:</HL> We use <WiseLink />
            's currency exchange rates from the last day of the fiscal period to convert each day's earnings into USD.
          </TTR>
        </TTC>
      ),
    },
  },
  upcomingPayments: {
    paidSoon: (platform) => {
      return {
        header: (
          <div className="tt-hint-content">
            <div className="tt-hint-p">
              This is the final amount expected to be paid to you from this source, for the period specified.
            </div>
          </div>
        ),
        description: (
          <div className="tt-hint-content">
            <div className="tt-hint-p">
              <span className="tt-hint-highlighted">Why it's estimated:</span> The actual amount and date on which you
              receive funds from {platform.name} may be different due to any applicable withholding tax by the time of
              payment.
            </div>
            {platform.payoutInformationUrl && (
              <div className="tt-hint-p">
                <span className="tt-hint-highlighted">Source: </span>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={platform.payoutInformationUrl}
                  className="tt-hint-link"
                >
                  {platform.name}'s earnings and financial reports
                </a>
              </div>
            )}
          </div>
        ),
      };
    },
    inProgress: (platform) => {
      return {
        header: (
          <div className="tt-hint-content">
            <div className="tt-hint-p">
              This is the estimated amount of net revenue accumulated so far on this platform, for the fiscal period in
              progress. As there are still days remaining in the current period, the amount is likely to change.
            </div>
          </div>
        ),
        description: (
          <div className="tt-hint-content">
            <div className="tt-hint-p">
              <span className="tt-hint-highlighted">Why it's estimated:</span> The fiscal period hasn't ended yet and
              you are still likely to have more earnings. The actual amount and date on which you receive funds from{" "}
              {platform.name} may be different due to transaction processing and reporting timeframes.
            </div>
            {platform.payoutInformationUrl && (
              <div className="tt-hint-p">
                <span className="tt-hint-highlighted">Source: </span>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={platform.payoutInformationUrl}
                  className="tt-hint-link"
                >
                  {platform.name}'s earnings and financial reports
                </a>
              </div>
            )}
          </div>
        ),
      };
    },
    payoutPaidSoon: {
      header: (
        <TTH>This is the final amount expected to be paid to you from this source, for the period specified.</TTH>
      ),
      description: (
        <TTC>
          <TTR>
            <HL>Exchange rates:</HL> {wiseCurrency()}
          </TTR>
        </TTC>
      ),
    },
    payoutInProgress: {
      header: (
        <TTH>
          This is the estimated amount of net revenue accumulated so far on this platform, for the fiscal period in
          progress. As there are still days remaining in the current period, the amount is likely to change.
        </TTH>
      ),
      description: (
        <TTC>
          <TTR>
            <HL>Why it's estimated:</HL>The final amount you receive will depend on various factors, such as local sales
            taxes, platform fees, refunds, currency exchange rates, etc. We estimate the amount based on current
            exchange rates and the typical fees and refunds for your business.
          </TTR>
          <TTR>
            <HL>Exchange rates:</HL> {wiseCurrency()}
          </TTR>
        </TTC>
      ),
    },
    expensePaidSoon: (platform) => {
      return {
        header: <TTH>This is the final amount due to this service provider, for the period specified.</TTH>,
        description: (
          <TTC>
            <TTR>
              <HL>Exchange rates:</HL> {wiseCurrencyWithPlatform(platform)}
            </TTR>
          </TTC>
        ),
      };
    },
    expenseInProgress: (platform) => {
      return {
        header: (
          <TTH>
            This is the estimated amount of ad spend charges incurred so far on this platform, for the fiscal period in
            progress. As there are still days remaining in the current period, the amount is likely to change.
          </TTH>
        ),
        description: (
          <TTC>
            <TTR>
              <HL>Why it's estimated:</HL>The final amount you are billed for will depend on various factors, such as
              platform fees, refunds, currency exchange rates, etc. We estimate the amount based on current exchange
              rates and the typical fees and refunds for your business.
            </TTR>
            <TTR>
              <HL>Exchange rates:</HL> {wiseCurrencyWithPlatform(platform)}
            </TTR>
          </TTC>
        ),
      };
    },
  },
};

export default {
  appstore: {
    dailyEarnings: {
      sales: tooltipsCopies.appstore.completedLessThan13DaysAgo,
      financial: tooltipsCopies.appstore.completedLessThan13DaysAgo,
      financial_no_bank: tooltipsCopies.appstore.completedLessThan13DaysAgo,
      bank: tooltipsCopies.appstore.completedLessThan13DaysAgo,
      ongoing: tooltipsCopies.appstore.current,
    },
    payout: {
      sales: tooltipsCopies.appstore.completedLessThan13DaysAgo,
      financial: tooltipsCopies.appstore.completedBetween13And32DaysAgo,
      financial_no_bank: tooltipsCopies.appstore.completedWithoutBank,
      bank: tooltipsCopies.appstore.completedWithBank,
      ongoing: tooltipsCopies.appstore.currentPayout,
    },
  },
  googleplay: {
    dailyEarnings: {
      sales: tooltipsCopies.googleplay.completed1To4DaysOfFollowingMonth,
      financial: tooltipsCopies.googleplay.completed1To4DaysOfFollowingMonth,
      financial_no_bank: tooltipsCopies.googleplay.completedWithoutBank,
      bank: tooltipsCopies.googleplay.completedWithoutBank, // Same as completed without bank
      ongoing: tooltipsCopies.googleplay.currentDaily,
    },
    payout: {
      sales: tooltipsCopies.googleplay.completed1To4DaysOfFollowingMonth,
      financial: tooltipsCopies.googleplay.completed5To15DaysOfFollowingMonthPayout,
      financial_no_bank: tooltipsCopies.googleplay.completedWithoutBankPayout,
      bank: tooltipsCopies.googleplay.completedWithBank,
      ongoing: tooltipsCopies.googleplay.currentPayout,
    },
  },
  generic: {
    dailyEarnings: {
      sales: tooltipsCopies.generic.completedLessThan13DaysAgo,
      financial: tooltipsCopies.generic.completedLessThan13DaysAgo,
      financial_no_bank: tooltipsCopies.generic.completedLessThan13DaysAgo,
      bank: tooltipsCopies.generic.completedLessThan13DaysAgo,
      ongoing: tooltipsCopies.generic.current,
    },
    payout: {
      sales: tooltipsCopies.generic.completedLessThan13DaysAgo,
      financial: tooltipsCopies.generic.completedBetween13And32DaysAgo,
      financial_no_bank: tooltipsCopies.generic.completedWithoutBank,
      bank: tooltipsCopies.generic.completedWithBank,
      ongoing: tooltipsCopies.generic.currentPayout,
    },
  },
  upcomingPayments: tooltipsCopies.upcomingPayments,
};
