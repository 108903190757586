import React from "react";
import { useTransition, animated } from "react-spring";

const FadeTransitionContainer = ({ firstComponent, secondComponent, value, absolutePosition = true }) => {
  const transitions = useTransition(value, {
    from: { position: "absolute", opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      duration: 500,
    },
  });

  return transitions(({ opacity }, item) =>
    item ? (
      <animated.div
        style={{
          position: absolutePosition ? "absolute" : "auto",
          opacity: opacity.to({ range: [0.0, 1.0], output: [0, 1] }),
        }}
      >
        {firstComponent}
      </animated.div>
    ) : (
      <animated.div
        style={{
          position: absolutePosition ? "absolute" : "auto",
          opacity: opacity.to({ range: [1.0, 0.0], output: [1, 0] }),
        }}
      >
        {secondComponent}
      </animated.div>
    ),
  );
};

export default FadeTransitionContainer;
