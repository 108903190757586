import React, { Fragment, useEffect, useRef, useState } from "react";
import { Flex, Icon, useTheme } from "@chakra-ui/react";
import classnames from "classnames";

import { BodySmallPlus, BodyMini12, BodyMiniPlus } from "@styled";
import {
  StyledDailyRevenueSelector,
  StyledSearch,
  StyledOptions,
  StyledOption,
  StyledOptionTitle,
  StyledSelectedOption,
  StyledCategoryHeader,
  StyledCategory,
} from "./DailyRevenueSelectorStyles";
import { SanloToggle } from "@components/common/checkboxes/sanlo-toggle/SanloToggle";
import { AccordionArrow } from "@common/accordions/AccordionArrow";

import {
  isConnectedStatus,
  isPullingStatus,
  isReconnectionStatus,
  isNotConnectedStatus,
  isErrorStatus,
  isNoPermissionStatus,
} from "@utils/status";
import symbols from "@utils/symbols";
import { filterOptions } from "../../utils/dailyRevenueUtils";

import { ReactComponent as AlertTriangle } from "@assets/img/icons/common/alertTriangle.svg";
import { ReactComponent as AlertCircle } from "@assets/img/icons/common/alertCircle.svg";
import { ReactComponent as AttentionDot } from "@assets/img/icons/common/attention.svg";

const transparentPixel = require("@assets/img/icons/transparent.png");

const SHOW_SEARCH_LIMIT = 10;

export const DailyRevenueSelector = ({
  isLoading = true,
  collapsed = false,
  fullOptions,
  selectedOption,
  onOptionSelect,
  onOptionConnectClick,
}) => {
  const theme = useTheme();
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [searchString, setSearchString] = useState("");

  const [mobileOpen, setMobileOpen] = useState(true);

  const searchInputRef = useRef(null);
  // Search is only available when there are more than 10 options
  const [showSearch, setShowSearch] = useState(fullOptions.all.length > SHOW_SEARCH_LIMIT);

  const mouseIn = useRef(false);

  const onWindowClick = (e) => {
    if (!mouseIn.current && optionsOpen) {
      setOptionsOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", onWindowClick);
    return () => {
      window.removeEventListener("click", onWindowClick);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!optionsOpen && searchString) {
      setSearchString("");
      searchInputRef.current.value = "";
    }
  }, [optionsOpen, searchString]);

  useEffect(() => {
    setShowSearch(fullOptions.all.length > SHOW_SEARCH_LIMIT);
  }, [fullOptions]);

  const mobilePlatformsLength = fullOptions.mobilePlatforms.filter((p) => isConnectedStatus(p)).length;

  const renderOptionTitle = (option) => {
    let { image, name, displayName, isAll } = option;
    name = displayName || name;

    const subtitleClass = classnames("ps-option-subtitle", {
      "color-red": isErrorStatus(option),
      "color-yellow": isReconnectionStatus(option) || isNoPermissionStatus(option),
    });

    return (
      <Fragment>
        {isAll && (
          <StyledOptionTitle isSelected={option.id === selectedOption.id} isDisabled={option.disabled}>
            {collapsed && <BodySmallPlus>All</BodySmallPlus>}
            {!collapsed && <BodySmallPlus>{`${name} (${fullOptions?.allViableIDs?.length})`}</BodySmallPlus>}
          </StyledOptionTitle>
        )}

        {image && !isAll && (
          // eslint-disable-next-line jsx-a11y/alt-text
          <img
            className="ps-option-icon"
            alt=""
            src={image || transparentPixel}
            onError={(e) => {
              if (e.currentTarget.src !== transparentPixel) {
                e.currentTarget.src = transparentPixel;
              }
            }}
          />
        )}

        {!collapsed && !isAll && (
          <StyledOptionTitle isSelected={option.id === selectedOption.id} isDisabled={option.disabled}>
            <BodySmallPlus>
              {(!isNotConnectedStatus(option) || displayName) && name}
              {isNotConnectedStatus(option) && !displayName && `Connect ${name}`}
            </BodySmallPlus>

            {isPullingStatus(option) && <BodyMini12 className={subtitleClass}>Connecting...</BodyMini12>}

            {isReconnectionStatus(option) && (
              <div className={subtitleClass}>
                <AlertCircle />
                <BodyMini12>Needs attention</BodyMini12>
              </div>
            )}

            {isErrorStatus(option) && (
              <div className={subtitleClass}>
                <AlertTriangle />
                <BodyMini12>Connection broken</BodyMini12>
              </div>
            )}

            {isNoPermissionStatus(option) && (
              <div className={subtitleClass}>
                <AlertCircle />
                <BodyMini12>Needs attention</BodyMini12>
              </div>
            )}
          </StyledOptionTitle>
        )}
      </Fragment>
    );
  };

  const renderOption = (option) => {
    const { id, disabled } = option;
    return (
      <Fragment key={`key-frag-drs-option-${id}`}>
        <StyledOption
          isCollpsed={collapsed}
          isSelected={id === selectedOption.id}
          isDisabled={disabled}
          isRed={isNotConnectedStatus(option) && !disabled}
          id={`drs-option-${id}`}
          key={`drs-option-${id}`}
          onClick={() => {
            if (disabled || selectedOption.id === id) return;
            if (isNotConnectedStatus(option)) {
              onOptionConnectClick(option);
            } else {
              onOptionSelect(option);
            }
            setOptionsOpen(false);
          }}
        >
          {renderOptionTitle(option)}
        </StyledOption>
      </Fragment>
    );
  };

  return (
    <StyledDailyRevenueSelector isCollapsed={collapsed}>
      <div className="ps-wrapper no-text-select">
        <StyledSelectedOption
          isLoading={isLoading}
          isFocused={optionsOpen}
          onClick={() => {
            setOptionsOpen(!optionsOpen);
          }}
        >
          {renderOptionTitle(selectedOption)}
          <AccordionArrow
            expanded={optionsOpen}
            style={{
              position: "absolute",
              right: collapsed ? "0.25rem" : "0.75rem",
              color: "rgba(255, 255, 255, 0.5)",
              height: "16px",
              marginRight: 0,
            }}
          />
        </StyledSelectedOption>

        <StyledOptions
          isVisible={optionsOpen}
          onMouseEnter={() => {
            mouseIn.current = true;
          }}
          onMouseLeave={() => {
            mouseIn.current = false;
          }}
        >
          {showSearch && !collapsed && (
            <StyledSearch>
              <div className="search-icon" />
              <input
                ref={searchInputRef}
                className="ps-search-input"
                placeholder="Search"
                onKeyUp={(e) => {
                  setSearchString(e.currentTarget.value.trim().toLowerCase());
                }}
              />
            </StyledSearch>
          )}

          {!collapsed && (
            <div className="ps-select-all">
              <BodyMiniPlus>Select all platforms</BodyMiniPlus>
              <SanloToggle
                checked={selectedOption.isAll}
                toggleCheckbox={() => {
                  if (selectedOption.isAll) {
                    onOptionSelect(fullOptions.noneOption);
                  } else {
                    onOptionSelect(fullOptions.allOption);
                  }
                  setOptionsOpen(false);
                }}
              />
            </div>
          )}

          {!collapsed && (
            <StyledCategoryHeader
              isOpen={mobileOpen}
              onClick={() => {
                setMobileOpen(!mobileOpen);
              }}
            >
              {symbols.MobilePhone()}
              <Flex>
                <BodySmallPlus style={{ whiteSpace: "nowrap" }}>{`Mobile (${mobilePlatformsLength})`}</BodySmallPlus>
                {!mobilePlatformsLength && (
                  <Icon as={AttentionDot} fill={theme.colors.brandRed[400]} h={"6px"} w={"6px"} ml={"4px"} />
                )}
              </Flex>

              <AccordionArrow
                expanded={mobileOpen}
                style={{
                  position: "absolute",
                  right: "1rem",
                  color: "rgba(255, 255, 255, 0.5)",
                  height: "12px",
                  marginRight: 0,
                }}
              />
            </StyledCategoryHeader>
          )}

          {((!collapsed && mobileOpen) || collapsed) && (
            <StyledCategory isCollapsed={collapsed}>
              {filterOptions(fullOptions.mobilePlatforms, searchString).map((option) => {
                return renderOption(option);
              })}
            </StyledCategory>
          )}
        </StyledOptions>
      </div>
    </StyledDailyRevenueSelector>
  );
};
