import styled from "styled-components";

export const StyledPlatformDisplay = styled.div`
  grid-area: platformdisplay;
  padding: 1.5rem 0 1.5rem 1.5rem;

  display: flex;
  flex-direction: column;

  .ce-info-tooltip {
    color: red;
  }

  .ce-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    padding-right: 1.5rem;

    .ce-earnings {
      display: flex;
      align-items: baseline;
      margin-bottom: 1rem;

      &.is-pulling-data {
        opacity: 0.5;
      }

      .dr-shown-earnings {
        border-radius: 0.75rem;
      }

      .ce-total-earnings {
        color: ${({ theme }) => theme.textDefault};
        margin-left: 8px;
      }

      .tooltip-icon {
        align-self: center;
        margin-bottom: 0;
        width: 18px;
        height: 18px;
        stroke: ${({ theme }) => theme.textGray};
        stroke-width: 0.2;
      }
    }

    .ce-earnings-period {
      color: ${({ theme }) => theme.textDefault};
      border-radius: 0.75rem;

      .ce-paydate {
        color: ${({ theme }) => theme.textGray};
        cursor: default;

        &.is-contained {
          color: ${({ theme }) => theme.colorGreen};
          cursor: pointer;
        }
        &.is-current {
          color: ${({ theme }) => theme.colorYellow};
          cursor: pointer;
        }
      }
    }

    .dr-header-refresh {
      position: absolute;
      top: 2rem;
      right: 1.5rem;
      border-radius: 0.5rem;

      .dr-header-refresh-label {
        display: inline-block;
        opacity: 0.7;
      }
      .dr-header-refresh-action {
        color: ${({ theme }) => theme.colorRed};
        padding-left: 0.5rem;
        cursor: pointer;
        display: inline-block;
      }
    }
  }
`;

export const StyledPeriodPicker = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 4px;
  padding-right: 1.5rem;

  .period-actual {
    display: flex;
    align-items: center;
    height: 24px;
    padding: 4px 8px;
    border-radius: 4px;
    margin: 0 8px;

    :hover {
      cursor: pointer;
      background: ${({ theme }) => theme.grayscale70};
    }

    &.disabled {
      opacity: 0.3;
      :hover {
        cursor: default;
        background: transparent;
      }
    }
  }

  .period-arrow {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      fill: ${({ theme }) => theme.rowCrossColor};
    }

    .prev-p {
      transform: rotate(270deg);
    }

    .next-p {
      transform: rotate(90deg);
    }

    :hover {
      cursor: pointer;
      background: ${({ theme }) => theme.grayscale70};
      svg {
        fill: ${({ theme }) => theme.textSeashellWhite};
      }
    }

    &.disabled {
      opacity: 0.3;

      svg {
        fill: ${({ theme }) => theme.textDefault};
      }
      :hover {
        cursor: default;
        background: transparent;
      }
    }
  }
`;

export const StyledChart = styled.div`
  --bar-width: 5%;
  --border-width: 10px;
  --loading-color: white;
  --x-axis-sub-item-height: 1.25rem;
  --y-axis-width: 3.5rem;
  --x-axis-height: calc((var(--x-axis-sub-item-height) * 3) + 0.5rem);
  --tooltip-y-offset: -3rem;

  position: relative;
  height: 21rem;
  width: 100%;

  grid-area: chart;
  display: grid;
  grid-template-columns: var(--y-axis-width) 1fr;
  grid-template-rows: var(--x-axis-height) 1fr 1rem;
  grid-template-areas:
    "loading body"
    "y_axis body"
    "deadspace body";

  .tooltip-wrapper {
    position: absolute;
    left: 0;
    z-index: 10;

    &.right {
      transform: translateX(-20%);
    }
    &.center {
      transform: translateX(-50%);
    }
    &.left {
      transform: translateX(-80%);
    }
  }

  .lead-month {
    position: absolute;
    left: var(--y-axis-width);
    top: 0;
    height: var(--x-axis-sub-item-height);
    line-height: var(--x-axis-sub-item-height);

    &.not-pay-date {
      top: var(--x-axis-sub-item-height);
    }
  }
`;

export const StyledChartBody = styled.div`
  grid-area: body;
  overflow-x: scroll;
  overflow-y: hidden;
  /* remove scrollbar on firefoz */
  /* scrollbar-width: none;  */

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: var(--x-axis-height) 1fr;
  grid-template-areas:
    "x_axis"
    "bars";
  padding-bottom: 1rem;

  cursor: grab;
  &:active {
    cursor: grabbing;
  }

  &.no-games-selected {
    overflow: hidden;
  }

  &.is-showing-message {
    overflow: hidden;
    cursor: default;
  }
`;

export const StyledYAxis = styled.div`
  grid-area: y_axis;
  position: relative;

  .item {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 0.75rem;
  }
`;

export const StyledChartBars = styled.div`
  grid-area: bars;
  white-space: nowrap;

  &.drd-nothing-connected {
    opacity: 0.3;
  }

  .bar {
    position: relative;
    display: inline-block;
    height: 100%;
    vertical-align: top;
    text-align: center;
    width: var(--bar-width);
    border-left: var(--border-width) solid transparent;
    border-right: var(--border-width) solid transparent;

    &.today::before {
      content: "";
      position: absolute;
      top: -0.5rem;
      left: 50%;
      bottom: 0;
      width: 1px;
      background-color: ${({ theme }) => theme.colorRed};
      z-index: 10;
    }

    .hovered-bkg {
      position: absolute;
      z-index: 0;
      top: -0.5rem;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(2, 2, 2, 0.2);
      border-bottom-left-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }

    .games-wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 100%;
      vertical-align: top;
      text-align: center;
    }

    .game {
      width: 1rem;
      min-height: 8px;
      background-color: #ffffff;

      &.rounded-top {
        // top most bar
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
      }

      &.rounded-bottom {
        // bottom most bar
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
      }

      &.remaining-space {
        background-color: transparent;
        flex: 1;
        margin-top: -0.5rem;
      }

      &.is-placeholder {
        background-color: ${({ theme }) => theme.grayscale70};
      }
    }

    &.hovered {
      filter: brightness(0.7);
    }
  }
`;
