import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFlags } from "launchdarkly-react-client-sdk";

import actions from "@redux/actions";

import SuggestIntegration from "./SuggestIntegration";

const SuggestIntegrationContainer = ({ suggestionType = "UNKNOWN", ...rest }) => {
  const dispatch = useDispatch();

  const { platformSuggestions: platformSuggestionsVisible } = useFlags();

  const integrationsBase = useSelector((state) => state.integrations.base);
  const suggestionStatus = integrationsBase.requestStatus.sendSuggestion;
  const lastSuggestion = integrationsBase.lastSuggestion;

  const onSuggestionSend = (data) => {
    dispatch(actions.integrations.base.sendSuggestion.request(data));
  };

  if (!platformSuggestionsVisible) return null;

  return (
    <SuggestIntegration
      suggestionType={suggestionType}
      suggestionStatus={suggestionStatus}
      onSuggestionSend={onSuggestionSend}
      lastSuggestion={lastSuggestion}
      {...rest}
    />
  );
};

export default SuggestIntegrationContainer;
