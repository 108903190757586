import styled from "styled-components";

export const StyledPageCard = styled.div`
  position: relative;
  overflow: hidden;
  background-color: rgb(33, 33, 38);
  border-radius: 12px;
  padding: 24px;
  box-sizing: border-box;
  min-height: 384px;

  ${({ isLoading }) =>
    isLoading &&
    `
    & > * {
      visibility: hidden;
    }
  `};

  ${({ half }) =>
    half &&
    `
    display: inline-block;
    vertical-align: top;
    width: 539px;
    height: 384px;

    &:nth-child(even) {
      margin-left: 16px;
    }
  `};
`;
