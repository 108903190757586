const UPLOAD_ERRORS_CONVERSION = {
  "ManualCompanyBankingConnection has a file with the same objectName":
    "File has already been uploaded, please remove and re-confirm",
  "Invalid Content-Type": "This file type is not supported",
};

export const getFileNameResponse = (action, response = {}) => {
  // TODO: add some safety checks
  const newResponse = {};
  if (action.data.fileName) newResponse.fileName = action.data.fileName;
  if (action.fileName) newResponse.fileName = action.fileName;
  if (response.data) newResponse.data = response.data;
  return newResponse;
};

export const parseUploadError = (errorResponse = {}) => {
  const { message = "" } = errorResponse;
  const newResponse = {
    ...errorResponse,
    message: UPLOAD_ERRORS_CONVERSION[message] || message,
  };
  if (!newResponse.message) delete newResponse.message;
  return newResponse;
};

export const parseUploadedFiles = (data = {}) => {
  let { manualConnectionState = {} } = data;
  // If manualConnectionState actually comes through as null, it
  // wont be set to an empty object by default.
  if (!manualConnectionState) manualConnectionState = {};
  const {
    // status,
    files = [],
  } = manualConnectionState;

  const uploadedFiles = {};
  files.forEach((file) => {
    const { status, fileName } = file;
    if (status !== "UPLOADED_CLEAN") return;
    uploadedFiles[fileName] = file;
  });

  return uploadedFiles;
};

export const updatedParsedUploadedFiles = (data = []) => {
  const uploadedFiles = {};
  data.forEach((file) => {
    const { status, fileName } = file;
    if (status !== "UPLOADED_CLEAN") return;
    uploadedFiles[fileName] = file;
  });

  return uploadedFiles;
};

export const clearFileFromState = (state, fileName) => {
  delete state.data.signedURL[fileName];
  delete state.requestStatus.signedURL[fileName];
  delete state.data.useSignedURL[fileName];
  delete state.requestStatus.useSignedURL[fileName];
  delete state.data.fileUploaded[fileName];
  delete state.requestStatus.fileUploaded[fileName];
  delete state.data.successfulFiles[fileName];
  delete state.data.errorFiles[fileName];

  state.data.fileList = state.data.fileList.filter((file) => file !== fileName);

  return state;
};

export const checkFilesForPending = (files) => {
  const fileNames = Object.keys(files);
  for (let i = 0; i < fileNames.length; ++i) {
    const fileRequestStatus = files[fileNames[i]];
    const { pending = false } = fileRequestStatus;
    if (pending) return true;
  }
  return false;
};

export const checkForUploading = (state) => {
  // All of the files in signedURL, useSignedURL, and fileUploaded
  // need to be checking for pending status.
  // finishedUploading needs to be checked as well
  const signedURLPending = checkFilesForPending(state.requestStatus.signedURL);
  const useSignedURLPending = checkFilesForPending(state.requestStatus.useSignedURL);
  const fileUploadedPending = checkFilesForPending(state.requestStatus.fileUploaded);
  const finishedUploadingPending = state.requestStatus.finishedUploading.pending;
  // If any one of those is true, we are still going through the file uploading process
  return signedURLPending || useSignedURLPending || fileUploadedPending || finishedUploadingPending;
};

export const checkIsLastFile = (state, fileName) => {
  const fileList = state.data.fileList;
  const fileUploaded = state.data.fileUploaded;
  const errorFiles = state.data.errorFiles;

  // Get a list of all files that aren't the current one
  const filteredFileList = fileList.filter((file) => file !== fileName);

  let isLast = true;
  filteredFileList.forEach((file) => {
    const fileUploadedData = fileUploaded[file];
    // Ignore files that has already errored, they wont have fileUpload data
    if (errorFiles[file]) return;
    // If there is file data missing for another file in the list, this isn't the last file
    if (!fileUploadedData) isLast = false;
  });

  return isLast;
};

export const checkUseSignedURLComplete = (state) => {
  const verifyUseSigned = state.data.verifyUseSigned;
  let value = true;

  Object.keys(verifyUseSigned).forEach((fileName) => {
    if (!verifyUseSigned[fileName].complete) {
      value = false;
    }
  });

  return value;
};
