import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AD_NETWORKS_OAUTH_SLUG } from "@src/consts/ad-networks";
import { adNetworksSelector, adNetworksThunks } from "@redux/integrations/ad-networks/adNetworksSlice";
import { Box, Button, Divider, Flex, HStack, Icon, Image, Text, VStack } from "@chakra-ui/react";
import { StyledLoader } from "@common/inputs/SanloButton/SanloButton";
import { Spinner } from "reactstrap";
import { trackGTMEvent, ADD_NETWORKS_ACTION, COMMON_ACTION } from "@utils/gtm";

import { ReactComponent as ArrowLeft } from "@src/assets/img/icons/common/arrow_left.svg";
import DefaultImg from "@assets/img/icons/adNetworkDefault.png";

const NETWORKS = {
  [AD_NETWORKS_OAUTH_SLUG.facebook]: { name: "Facebook", color: "#4267B2" },
  [AD_NETWORKS_OAUTH_SLUG.google]: { name: "Google", color: "#EA4335" },
};

export function AdNetworkOAuth({ handlePrevStep }) {
  const {
    selectedAdNetwork,
    addCredentials: { requestStatus },
  } = useSelector(adNetworksSelector);
  const dispatch = useDispatch();

  const isLoading = selectedAdNetwork.requestStatus.pending;

  if (isLoading) {
    return (
      <Flex h="424px" justifyContent={"center"} alignItems={"center"} color={"brandRed.400"}>
        <Spinner />
      </Flex>
    );
  }
  return (
    <VStack alignItems={"start"} justifyContent={"stretch"}>
      <Box w={"100%"} bg="dark.600" borderRadius={"10px"}>
        <HStack gap="16px" p="16px">
          <Image src={selectedAdNetwork.data?.iconUrl} fallbackSrc={DefaultImg} w="24px" h="24px" borderRadius="6px" />
          <Text textTransform="capitalize" size="large" fontWeight={500} textColor="textWhite.400">
            {selectedAdNetwork.data?.name}
          </Text>
        </HStack>
        <Divider my={0} />
        <VStack padding={"16px"}>
          <Button
            spinner={<StyledLoader />}
            width={"100%"}
            padding={"12px 16px"}
            borderRadius={"10px"}
            backgroundColor={NETWORKS[selectedAdNetwork.data?.slug].color}
            onClick={() => {
              trackGTMEvent({
                event: `${ADD_NETWORKS_ACTION.MODAL} - ${COMMON_ACTION.CONNECT} - ${selectedAdNetwork.data.name} - Click`,
              });
              dispatch(adNetworksThunks.addCredentials({ credentials: [], redirectUri: window.location.href }));
            }}
            _hover={{}}
            isLoading={requestStatus.pending}
          >
            Connect via {NETWORKS[selectedAdNetwork.data?.slug].name}
          </Button>
        </VStack>
      </Box>
      <Box w={"100%"} mt={"307px"}>
        <Button
          onClick={handlePrevStep}
          flexShrink={0}
          w={"100%"}
          size={"lg"}
          leftIcon={<Icon fill={"white"} as={ArrowLeft} w="16px" height="16px" />}
        >
          Back
        </Button>
      </Box>
    </VStack>
  );
}
