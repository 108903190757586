import React from "react";
import { ReactComponent as Info } from "@assets/img/icons/common/alertCircle.svg";
import { ReactComponent as Error } from "@assets/img/icons/common/alertTriangle.svg";

const icons = {
  info: <Info />,
  error: <Error />,
};

const SanloIcon = ({ icon, color, ...props }) => {
  return React.cloneElement(icons[icon], {
    fill: color,
    ...props,
  });
};

export default SanloIcon;
