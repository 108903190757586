import HTTP from "@redux/http";

const SANLO_WEB_API_URL = process.env.REACT_APP_SANLO_WEB_API_URL;
const BASE = `${SANLO_WEB_API_URL}/api/fairground/console/webshops`;

class SeriesService {
  createSeries(series, webshopId) {
    return HTTP.req.post(`${BASE}/${webshopId}/catalog/series`, series);
  }

  updateSeries(series, webshopId) {
    return HTTP.req.put(`${BASE}/${webshopId}/catalog/series/${series.id}`, series);
  }

  deleteSeries(seriesId, webshopId) {
    return HTTP.req.delete(`${BASE}/${webshopId}/catalog/series/${seriesId}`);
  }
}

export default new SeriesService();
