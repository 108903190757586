import React, { useState } from "react";
import { HStack } from "@chakra-ui/react";
import { Reorder, useDragControls } from "framer-motion";

import { DragHandle } from "@components/builder/DragHandle";

import { BaseSection, SectionText, SectionIcon, SectionIconButton } from "./SidebarTabs";

import { ReactComponent as OpenBook } from "@assets/img/icons/common/openbook.svg";
import { ReactComponent as Trash } from "@assets/img/icons/common/trash.svg";
import { ReactComponent as Link } from "@assets/img/icons/common/link.svg";

import { BUILDER_SECTION_TYPES, BUILDER_SELECTABLE_SECTION_TYPES } from "@src/consts/webshops/builder";

export const SectionSidebarTab = ({
  sectionId = "",
  section = {},
  title = "",
  selected = false,
  onClick = () => {},
  onDeleteClick = () => {},
  onMouseDown = () => {},
  onMouseUp = () => {},
}) => {
  const controls = useDragControls();

  const [isHovered, setIsHovered] = useState(false);
  const [isBeingDrag, setIsBeingDrag] = useState(false);

  const defaultTitle = BUILDER_SELECTABLE_SECTION_TYPES.find((bSection) => bSection.type === section.type)?.label;
  const isNavigation = section.type === BUILDER_SECTION_TYPES.NAVIGATION;

  return (
    <Reorder.Item
      key={`key-sidebar-section-comp-${sectionId}`}
      value={sectionId}
      dragListener={false}
      dragControls={controls}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
      onDragStart={() => setIsBeingDrag(true)}
      onDragEnd={() => setIsBeingDrag(false)}
    >
      <BaseSection
        selected={selected}
        style={{
          borderRadius: 0,
          borderRightColor: selected ? "brandPurple.400" : "dark.300",
          borderLeftColor: selected ? "brandPurple.400" : "dark.300",
          borderBottomColor: "dark.300",
          borderTop: isBeingDrag ? "1px solid var(--dark-300)" : "initial",
        }}
      >
        <HStack _notposition="relative" p="4px 0 4px 12px" justifyContent="space-between" onClick={onClick}>
          <HStack alignItems="center" justifyContent="flex-start" w="100%" overflow="hidden">
            {isHovered ? (
              <SectionIconButton
                icon={Trash}
                selected={true}
                onClick={(e) => {
                  e.stopPropagation();
                  onDeleteClick();
                }}
              />
            ) : (
              <SectionIcon icon={isNavigation ? Link : OpenBook} selected={selected} />
            )}
            <SectionText>{title || defaultTitle}</SectionText>
          </HStack>
          <DragHandle
            controls={controls}
            onMouseDown={(e) => {
              e.stopPropagation();
              onMouseDown();
            }}
            onMouseUp={(e) => {
              e.stopPropagation();
              onMouseUp();
            }}
          />
        </HStack>
      </BaseSection>
    </Reorder.Item>
  );
};
