import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { withTheme } from "styled-components";
import { useFlags } from "launchdarkly-react-client-sdk";

import { SanloButton } from "@inputs";
import { BodySmall14 } from "@components/styled";
import IntegrationCategory from "@components/integrations/IntegrationCategory";
import BankingCategory from "@components/integrations/BankingCategory";
import FullScreenModal from "@components/common/modals/fullscreen-modal/FullScreenModal";
import RegularModal from "@components/common/modals/regular-modal/RegularModal";
import SanloChip from "@components/common/chips/SanloChip";
import { RemoveMMPModal } from "@components/integrations/RemoveMMPModal";
import { StyledPageContainer, PageHeader } from "@components/page";
import ErrorBoundary from "@components/ErrorBoundary";
import { AdNetworks } from "@components/integrations/ad-networks";

import { categories, unlinkMMP, checkPlatformConflict } from "@utils/defaults/platforms";
import useToggle from "@utils/custom-hooks/useToggle";
import symbols from "@utils/symbols";
import { AD_NETWORKS, BANKING } from "@utils/consts";

import actions from "@redux/actions";
import { integrationsConnectionsThunks } from "@redux/integrations/base/baseSlice";

const StyledCardsContainer = styled.div`
  max-width: 752px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const IntegrationsPage = (props) => {
  const { clientAdNetworksEnabled, clientAdjustShow } = useFlags();
  const dispatch = useDispatch();
  const integrations = useSelector((state) => state.integrations);

  useEffect(() => {
    dispatch(integrationsConnectionsThunks.getIntegrationsConnections());
  }, [dispatch]);

  const [selectedPlatform, setSelectedPlatform] = useState(integrations.appsflyer);

  const [connectModalVisible, toggleConnectModal] = useToggle(false);
  const [removeMMPModalVisible, toggleRemoveMMPModal] = useToggle(false);
  const [unlinkModalVisible, toggleUnlinkModal] = useToggle(false);
  const [unlinkErrorModalVisible, toggleUnlinkErrorModal] = useToggle(false);

  // TODO: this needs to go away at some point
  // This sort of information should come from the store
  const availableCategories = {
    adNetworks: true,
    banking: true,
    platforms: true,
    productAnalytics: true,
  };
  // TODO: this needs to go away at some point
  // This sort of information should come from the store
  const availablePlatforms = {
    googleplay: true,
    appstore: true,
    steam: true,
    appsflyer: true,
    adjust: clientAdjustShow,
    firebase: true,
    singular: true,
    banking: true,
    adNetworks: true,
  };

  const handleToggleConnectModal = (selectedPlatform) => {
    setSelectedPlatform(selectedPlatform);
    if (checkPlatformConflict(selectedPlatform, integrations)) {
      toggleRemoveMMPModal(true);
      return;
    }
    toggleConnectModal(true);
  };

  const handleToggleDisconnectModal = (selectedPlatform) => {
    setSelectedPlatform(selectedPlatform);
    toggleUnlinkModal(true);
  };

  const handleRemoveMMPIntegration = () => {
    unlinkMMP(checkPlatformConflict(selectedPlatform, integrations));
    toggleRemoveMMPModal(false);
    toggleConnectModal(true);
  };

  return (
    <ErrorBoundary name="integrations-page-container">
      <StyledPageContainer isCardContainer>
        <PageHeader header="Integrations" subheader="Connect the apps you already use to get the most out of Sanlo." />

        <StyledCardsContainer>
          {categories.map((category, index) => {
            if (!availableCategories[category.id]) return null;

            if (category.id === AD_NETWORKS && availablePlatforms.adNetworks) {
              if (!clientAdNetworksEnabled) return null;

              return <AdNetworks key={index} />;
            }

            if (category.id === BANKING && availablePlatforms.banking) {
              return <BankingCategory key={index} category={category} />;
            }
            return (
              <IntegrationCategory
                key={index}
                availablePlatforms={availablePlatforms}
                category={category}
                handleToggleConnectModal={handleToggleConnectModal}
                handleToggleDisconnectModal={handleToggleDisconnectModal}
              />
            );
          })}
        </StyledCardsContainer>
      </StyledPageContainer>

      {/* Setup modal */}
      <ConnectIntegrationModal
        connectModalVisible={connectModalVisible}
        selectedPlatform={selectedPlatform}
        toggleConnectModal={toggleConnectModal}
      />

      {/* Remove MMP Modal */}
      <RemoveMMPModal
        toggleRemoveMMPModal={toggleRemoveMMPModal}
        removeMMPModalVisible={removeMMPModalVisible}
        integration={checkPlatformConflict(selectedPlatform, integrations)}
        handleRemoveMMPIntegration={handleRemoveMMPIntegration}
      />

      {/* Unlink Integration */}
      <UnlinkIntegrationModal
        unlinkErrorModalVisible={unlinkErrorModalVisible}
        integration={selectedPlatform}
        unlinkModalVisible={unlinkModalVisible}
        toggleUnlinkModal={toggleUnlinkModal}
        toggleUnlinkErrorModal={toggleUnlinkErrorModal}
      />
    </ErrorBoundary>
  );
};

const UnlinkIntegrationModal = ({
  integration,
  unlinkModalVisible,
  toggleUnlinkModal,
  toggleUnlinkErrorModal,
  disconnectErrorModalVisible,
}) => {
  const dispatch = useDispatch();
  const { id, name, manual = false } = integration;

  const handleDisconnectError = () => {
    toggleUnlinkErrorModal(true);
    toggleUnlinkModal(false);
  };

  const apiMessage = `Are you sure you want to unlink ${name}? You will lose all data associated with the integration`;
  const manualMessage = `Please note, you will need to update user permissions in ${name} to fully remove
    Sanlo's access to the account. Once you click “Unlink” here, Sanlo will stop accessing data from your ${name} account.`;

  return (
    <>
      <RegularModal
        title={`Unlinking ${name}`}
        isOpen={unlinkModalVisible}
        handleToggle={toggleUnlinkModal}
        hasChip={true}
        content={
          <>
            <SanloChip content={manual ? manualMessage : apiMessage} state="error" showIcon />
            <div className="modal-aside-buttons">
              <SanloButton
                isSecondary
                label="Cancel"
                onClick={toggleUnlinkModal}
                testId={`integration-${integration.platformId}-unlink-cancel`}
              />
              <SanloButton
                isLoading={integration.requestStatus?.unlinkIntegration?.pending}
                label={`Unlink ${name}`}
                onClick={() => {
                  unlinkMMP(integration);
                  toggleUnlinkModal();
                }}
                testId={`integration-${integration.platformId}-unlink-confirm`}
              />
            </div>
          </>
        }
      />

      <RegularModal
        isOpen={disconnectErrorModalVisible}
        handleToggle={toggleUnlinkErrorModal}
        title={`${symbols.frowningFace} Something went wrong...`}
        content={
          <>
            <BodySmall14 className="modal-description">
              Something went wrong on our end. We're sorry, please try again or reload the page.
            </BodySmall14>
            <SanloButton
              className="modal-button"
              isLoading={integration.requestStatus?.unlinkIntegration?.pending}
              label="Try again"
              onClick={() => {
                if (actions.integrations[id].unlinkIntegration) {
                  dispatch(actions.integrations[id].unlinkIntegration.request(handleDisconnectError));
                }
              }}
            />
          </>
        }
      />
    </>
  );
};

const ConnectIntegrationModal = ({ selectedPlatform, connectModalVisible, toggleConnectModal }) => (
  <FullScreenModal
    saveProgress={selectedPlatform.canSaveProgress}
    modalContent={selectedPlatform}
    openModal={connectModalVisible}
    closeModal={() => {
      toggleConnectModal(false);
    }}
  />
);

export default withTheme(IntegrationsPage);
