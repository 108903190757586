import { ReactComponent as AccordionArrow } from "@assets/img/icons/common/accordion-arrow.svg";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  PopoverBody,
  useDisclosure,
  Collapse,
  Text,
  Icon,
} from "@chakra-ui/react";
import React from "react";

export function Tooltip({ children, content, ...rest }) {
  const { isOpen: isCollapseOpen, onToggle } = useDisclosure(false);

  return (
    <Popover trigger="hover" placement={"right-start"} offset={[-20, 10]} {...rest}>
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent>
        <PopoverBody>{runIfFn(content, { isCollapseOpen, onToggle })}</PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export function CollapsableTooltipBody({
  children,
  label = "Don’t see all your data?",
  labelColor = "brandRed.400",
  isCollapseOpen,
  onToggle,
}) {
  return (
    <>
      <Text cursor={"pointer"} onClick={onToggle} size={"mini"} fontWeight={"medium"} color={labelColor} my="10px">
        {label}
        <Icon
          ml={"4px"}
          as={AccordionArrow}
          transform={isCollapseOpen ? "rotate(0deg)" : "rotate(180deg)"}
          transition={"transform 0.2s ease"}
          w="16px"
          h="16px"
          fill={labelColor}
        />
      </Text>
      <Collapse in={isCollapseOpen} animateOpacity>
        {children}
      </Collapse>
    </>
  );
}

function runIfFn(valueOrFn, ...args) {
  return isFunction(valueOrFn) ? valueOrFn(...args) : valueOrFn;
}

const isFunction = (value) => typeof value === "function";
