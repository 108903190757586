import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getRequestStatus, getErrorStatus, setStatusDefaults, getResponseStatus } from "@utils/redux";
import { DASHBOARD_ACTION, trackGTMEvent } from "@utils/gtm";
import actions from "@redux/actions";
import { showToast } from "@utils/redux";

import FinanceService from "./financeApi";

const initialState = {
  requestStatus: setStatusDefaults(),
  dismissModal: {
    requestStatus: setStatusDefaults(),
  },
};

export const financeThunks = {
  requestFinancialIntelligenceAccess: createAsyncThunk(
    "finance/requestFinancialIntelligenceAccess",
    async (_, { dispatch }) => {
      const res = await FinanceService.requestFinancialIntelligenceAccess();
      dispatch(actions.session.userSummary.request());
      return res.data;
    },
  ),
};

export const financeSlice = createSlice({
  name: "finance",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(financeThunks.requestFinancialIntelligenceAccess.pending, (state) => {
        state.requestStatus = getRequestStatus();
      })
      .addCase(financeThunks.requestFinancialIntelligenceAccess.fulfilled, (state, action) => {
        state.requestStatus = getResponseStatus();
        trackGTMEvent({
          event: DASHBOARD_ACTION.FINANCIALS_THANKS_MSG,
        });
      })
      .addCase(financeThunks.requestFinancialIntelligenceAccess.rejected, (state) => {
        state.requestStatus = getErrorStatus();
        showToast("An error has occurred trying to apply Early Access. Please, try again.", "error");
      });
  },
});

export const financeSelector = (state) => state.finance;

export default financeSlice.reducer;
