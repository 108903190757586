import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { cashActivitySelector, changeAMonth } from "@redux/financials/cashActivitySlice";
import { Tooltip } from "@common/tooltip";
import { Box, Flex, Icon, Heading, Text, VStack } from "@chakra-ui/react";
import { formatAmountDisplay } from "./utils";

import { ReactComponent as AlertCircle } from "@assets/img/icons/common/alertCircle.svg";
import { ReactComponent as ChevronLeft } from "@assets/img/icons/common/chevron-left.svg";
import { ReactComponent as ChevronRight } from "@assets/img/icons/common/chevron-right.svg";

export const CardHeader = () => {
  const dispatch = useDispatch();
  const {
    getCashActivity: {
      currentCashActivity: {
        data: { timeframe: { label = "", disabledNextMonth = false } = {} },
      },
      summaryMetrics: { date = "", amountInCents = 0 } = {},
    },
  } = useSelector(cashActivitySelector);

  const { amount, prefix, color } = formatAmountDisplay(amountInCents);

  const handleChangeMonth = (type) => {
    dispatch(changeAMonth({ type }));
  };

  return (
    <Flex
      w={"792px"}
      justifyContent={"space-between"}
      alignContent={"flex-start"}
      mb={"24px"}
      data-test-id={"activity-cash-flows-card-header"}
    >
      <VStack spacing={"0"} alignItems={"flex-start"}>
        <Flex alignItems={"center"}>
          <Heading size={"regular"} fontWeight={"600"} color={color}>
            {prefix}
            {amount.dollars}.{amount.cents}
          </Heading>
          <Box as="span" ml={"8px"}>
            <Tooltip
              placement={"top"}
              offset={[0, 35]}
              content={
                <Text size={"smallAlt"}>
                  This is the expected change in cash balance for the next three calendar months (including the current
                  month), based on the expected cash inflows and outflows during that period.
                </Text>
              }
              id={"activity-cash-flows-card-header-tooltip"}
            >
              <Icon as={AlertCircle} width={"20px"} height={"20px"} transform={"rotate(180deg)"} />
            </Tooltip>
          </Box>
        </Flex>
        <Text size={"small"} color={"textSubtext.400"}>
          Expected change in cash balance from today through {date}
        </Text>
      </VStack>

      <Flex mt={"8px"}>
        <Icon
          id={"activity-cash-flows-card-header-prev-month-button"}
          as={ChevronLeft}
          width={"16px"}
          height={"15px"}
          cursor={"pointer"}
          onClick={() => {
            handleChangeMonth("prev");
          }}
        />
        <Text
          id={"activity-cash-flows-card-header-label"}
          width={"120px"}
          textAlign={"center"}
          size={"small"}
          fontWeight={"500"}
          mr={"8px"}
          ml={"8px"}
          userSelect={"none"}
        >
          {label}
        </Text>
        <Icon
          id={"activity-cash-flows-card-header-next-month-button"}
          as={ChevronRight}
          width={"16px"}
          height={"15px"}
          cursor={!disabledNextMonth ? "pointer" : "not-allowed"}
          color={!disabledNextMonth ? "textWhite.400" : "textSubtext.400"}
          onClick={() => {
            if (!disabledNextMonth) handleChangeMonth("next");
          }}
        />
      </Flex>
    </Flex>
  );
};
