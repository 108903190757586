import mixpanel from "mixpanel-browser";

export const getTag = ({ action, location, eventTag }) =>
  eventTag ? `${eventTag}` : `tag-sanlo-${location}-${action}`;

const addToDataLayer = (data) => {
  if (!window || !window.dataLayer) return;
  window.dataLayer.push(data);
};

/**
 * Add to dataLayer objet an event click
 * @typedef {Object} props all information you wish to send
 * @property {string} event - event trigger, is required
 * @property {*} - all information you wish to send
 *
 * @example {event, location, name, email} = props
 *
 */

export const trackPageViewEvent = (props = {}) => {
  const { pathname = "", onunload = false } = props;

  const event_name = "Page View Session";
  // Get the current path stored in localStorage, localStorage
  // items persist and are accessible in the beforeunload event
  const currentPath = localStorage.getItem("currentPath");

  if (onunload) {
    // Ensure clearing the currentPath, we dont want it persisting across sessions
    localStorage.removeItem("currentPath");
    // Ignore the event if the currentPath is null
    if (!currentPath) return;
    // Set the general mixpanel config to use sendBeacon
    // This will also affect any other events happening as well
    mixpanel.set_config({ api_transport: "sendBeacon" });
    return trackGTMEvent({
      event: event_name,
      currentPath,
    });
  }

  if (!currentPath) {
    // Initial app load, set the currentPath and start the page view event
    localStorage.setItem("currentPath", pathname);
    return trackGTMEvent({
      timeEvent: event_name,
    });
  }

  if (pathname && currentPath !== pathname) {
    // The currentPath is different than the pathname, so we have a new page view
    // Update the currentPath and send the page view event
    localStorage.setItem("currentPath", pathname);
    trackGTMEvent(
      {
        event: event_name,
        currentPath,
      },
      {
        cb: () => {
          // Start tracking the new page view
          trackGTMEvent({
            timeEvent: event_name,
          });
        },
      },
    );
  }
};

export const trackGTMEvent = (props = {}, opts = {}) => {
  try {
    const { event, timeEvent } = props;
    const { mixpanel: toMixpanel = true, gtm: toGTM = false, beacon = false, cb = () => {} } = opts;

    // we use currentPath to track the page view event because its more accurate in the unload event
    // so we pass it here as well for consistency
    const currentPath = localStorage.getItem("currentPath");
    if (currentPath) props.currentPath = currentPath;

    let eventName = event || timeEvent;

    if (!eventName) {
      console.warn("Tried to track data with no event: ", props);
      return;
    }

    // Events come in with a structure that looks like GTM
    // So it can be sent directly
    if (toGTM) {
      addToDataLayer({
        ...props,
      });
    }

    // MixPanel events need the event pulled out and sent separately
    if (toMixpanel) {
      const mixPanelOpts = {};
      if (beacon) mixPanelOpts.transport = "sendBeacon";
      if (timeEvent) {
        mixpanel.time_event(eventName);
      } else {
        mixpanel.track(eventName, props, mixPanelOpts, () => {
          if (cb) cb();
        });
      }
    }
  } catch (e) {
    // Error sending or handling the event
    // console.log(e);
  }
};

export const getMonthIndex = (index) => {
  let month = ["N-5", "N-4", "N-3", "N-2", "N-1", "N"];
  return month[index];
};
