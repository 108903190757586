import { addQueryParams, getQueryParameters } from "@utils/general";

export const setBrandingInfo = () => {
  // Always set the clientId if its in the queryParams, that means its new and fresh
  const queryParams = getQueryParameters();
  if (queryParams.clientId) localStorage.setItem("clientId", queryParams.clientId);
  if (queryParams.customerId) localStorage.setItem("customerId", queryParams.customerId);
};

export const removeBrandingInfo = () => {
  localStorage.removeItem("clientId");
  localStorage.removeItem("partnerBranding");
  localStorage.removeItem("customerId");
};

export const getBrandingInfo = () => {
  const queryParams = getQueryParameters();

  const storedClientId = localStorage.getItem("clientId");
  const clientId = queryParams.clientId || storedClientId || "";

  const storedCustomerId = localStorage.getItem("customerId");
  const customerId = queryParams.customerId || storedCustomerId || "";

  const isBranded = Boolean(clientId);
  // ...but does not mean we are coming straight from a partner site
  // This will be true when the user is going from the app to auth0 signup
  // Its only needed
  const fromPartnerSite = Boolean(queryParams.clientId);
  const fromMarketing = Boolean(queryParams.marketing);

  return {
    clientId,
    customerId,
    isBranded,
    fromPartnerSite,
    fromMarketing,
  };
};

export const attributePayload = (payload = {}, opts = {}) => {
  payload = payload || {};
  // Add the customerId to the payload
  const { customer = false } = opts;
  const { clientId, customerId } = getBrandingInfo();
  if (clientId) payload.clientId = clientId;
  if (customer) payload.externalUserId = customerId;
  return payload;
};

export const attributeUrl = (requestUrl = "") => {
  const { clientId } = getBrandingInfo();
  return addQueryParams(requestUrl, {
    attributedPartnerClientId: clientId,
  });
};
