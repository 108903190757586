import * as moment from "moment";
import { StyledProgressTooltip } from "../../StyledAdvanceCard";
import React, { Fragment } from "react";
import TooltipHeader from "./TooltipHeader";
import { hasConversion } from "@utils/currency";
import advCopy from "@utils/text/advance";
import TooltipRow from "./TooltipRow";
import text from "@utils/text";

const UpcomingTooltip = ({ platform, advance, advancePlatforms, state }) => {
  const { id, upcomingRepayments } = platform;

  const name = (advancePlatforms[id] || {}).name;
  const {
    totalAmountInAdvanceCurrency,
    repaymentAmountInAdvanceCurrency,
    totalAmountInOriginCurrency,
    repaymentAmountInOriginCurrency,
    fromDate,
    toDate,
    estimatedRepaymentDate,
  } = upcomingRepayments;

  const { cryptoAsRepaymentSource } = advance;

  const dateFormat = "MMM Do";
  const earningsPeriod = `${moment(fromDate).format(dateFormat)} - ${moment(toDate).format(dateFormat)}`;
  const repaymentDate = moment(estimatedRepaymentDate).format(dateFormat);

  return (
    <StyledProgressTooltip state={state}>
      {!cryptoAsRepaymentSource && <TooltipHeader logo={advancePlatforms[id].icon} title={name} />}
      <div className={"tooltip-content " + state}>
        <TooltipRow label={text.t("earnings_period")} display={earningsPeriod} />
        <TooltipRow
          label={text.t("total_earnings")}
          display={advCopy.currencyDisplay(advance, {
            inAdvance: totalAmountInAdvanceCurrency,
            inOrigin: totalAmountInOriginCurrency,
          })}
        />
        <TooltipRow
          label={text.t("est_repayment_amount")}
          display={advCopy.currencyDisplay(
            advance,
            {
              inAdvance: repaymentAmountInAdvanceCurrency,
              inOrigin: repaymentAmountInOriginCurrency,
            },
            true,
          )}
          hint={advCopy.upcomingRepaymentHint(advance, state)}
          accordion
          color
        />
        <TooltipRow
          label={text.t("est_repayment_date")}
          display={repaymentDate}
          hint={text.t("est_repayment_date_hint", {
            name,
          })}
        />
      </div>

      {hasConversion(advance) && (
        <Fragment>
          <div className="divider" />
          <div className={"tooltip-content"}>
            <TooltipRow
              label={text.t("exchange_rate")}
              display={advCopy.exchangeDisplay(advance)}
              hint={advCopy.exchangeDisclosures.repaid()}
              accordion
            />
          </div>
        </Fragment>
      )}
    </StyledProgressTooltip>
  );
};

export default UpcomingTooltip;
