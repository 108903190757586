import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getResponseStatus, getRequestStatus, getErrorStatus, setStatusDefaults } from "@utils/redux";
import moment from "moment";

import GameMetricsService from "./gameMetricsApi";

const initialState = {
  getGameMetrics: {
    data: {
      metrics: [{}, {}, {}, {}],
      metadata: {
        availableApps: [],
      },
    },
    requestStatus: setStatusDefaults(),
    initialLoadComplete: false,
    appliedFilters: {
      appIds: [],
      fromDate: `${moment().subtract(1, "days").format("YYYY-MM-DD")}`,
      toDate: `${moment().subtract(1, "days").format("YYYY-MM-DD")}`,
    },
    lastUpdated: new Date().getTime(),
  },
};

export const gameMetricsThunks = {
  getGameMetrics: createAsyncThunk("gameMetrics/getGameMetrics", async (filters, { getState }) => {
    const appliedFilters = getState().gameMetrics.getGameMetrics.appliedFilters;
    const option = filters ? filters : appliedFilters;
    const res = await GameMetricsService.getAppMetrics(option);
    return {
      option,
      data: res.data,
    };
  }),
};

export const gameMetricsSlice = createSlice({
  name: "financials",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(gameMetricsThunks.getGameMetrics.pending, (state) => {
        state.getGameMetrics.requestStatus = getRequestStatus();
      })
      .addCase(gameMetricsThunks.getGameMetrics.fulfilled, (state, action) => {
        const { payload = {} } = action;
        const { data, option } = payload;
        state.getGameMetrics.initialLoadComplete = true;
        state.getGameMetrics.requestStatus = getResponseStatus();
        state.getGameMetrics.data = data;
        state.getGameMetrics.appliedFilters = option;
        state.getGameMetrics.lastUpdated = new Date().getTime();
      })
      .addCase(gameMetricsThunks.getGameMetrics.rejected, (state) => {
        state.getGameMetrics.initialLoadComplete = true;
        state.getGameMetrics.requestStatus = getErrorStatus();
      });
  },
});

export const gameMetricsSelector = (state) => state.gameMetrics;

export default gameMetricsSlice.reducer;
