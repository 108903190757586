export const getFormattedForm = (series = {}) => {
  const formatTime = (time) => (time ? new Date(time + "Z") : "");

  const {
    startTime,
    endTime,
    id = "",
    name = "",
    available = false,
    recurrenceInterval = null,
    endingStrategy = "LOOP",
    items = [],
    publishedAt = null,
  } = series;

  const isFormDisabled = !!publishedAt;

  return {
    startTime: formatTime(startTime),
    endTime: formatTime(endTime),
    id,
    name,
    available,
    recurrenceInterval,
    endingStrategy,
    items,
    publishedAt,
    isFormDisabled,
  };
};
