import styled from "styled-components";

const StyledLabelButton = styled.div`
  & {
    width: 100%;
    display: flex;
    justify-content: center;
    color: ${({ theme }) => theme.colorRed};
    cursor: pointer;
    margin-top: 16px;
  }
`;

export default StyledLabelButton;
