import HTTP from "@redux/http";

const SANLO_WEB_API_URL = process.env.REACT_APP_SANLO_WEB_API_URL;
const BASE = `${SANLO_WEB_API_URL}/api/fairground/console/webshops`;

export const ACCESS_TYPE = "WEBSHOPS";

class WebshopsService {
  accessRequest() {
    return HTTP.req.post(`${SANLO_WEB_API_URL}/api/access-request`, {
      type: ACCESS_TYPE,
    });
  }
  getWebshops() {
    return HTTP.req.get(BASE);
  }
  createWebshops(data) {
    return HTTP.req.post(`${BASE}/v2`, data);
  }
  deleteWebshop(id) {
    return HTTP.req.delete(`${BASE}/${id}`);
  }
  getAppStoreApps(data) {
    return HTTP.req.post(`${BASE}/apps/list`, data);
  }
}

export default new WebshopsService();
