import React from "react";
import styled from "styled-components";
export function Card({ onClick, isBluried = false, children, ...rest }) {
  return (
    <CardContainer onClick={onClick} {...rest}>
      {children}
    </CardContainer>
  );
}

const CardContainer = styled.article`
  box-sizing: border-box;
  position: relative;
  width: 16.375rem;
  min-height: 108px;
  height: 100%;
  max-height: 8.6875rem;
  border-radius: 0.75rem;
  background-color: ${({ theme }) => theme.grayscale20};
  padding: 1.125rem 1.5rem;
  margin-left: 1rem;
  transition: all 0.15s ease;
  flex-shrink: 0;
  border: 2px solid ${({ theme }) => theme.grayscale20};

  &:first-child {
    margin-left: 0px;
  }

  ${({ onClick, theme }) =>
    onClick &&
    `
    cursor: pointer;
    &:hover {
      background-color: ${theme.grayscale40};
      border: 2px solid ${theme.grayscale40};
    }
    &:active,
    &:focus {
      border: 2px solid ${theme.colorRed};
    }
`};
`;

export const CardContent = styled.div`
  filter: ${(props) => (props.isBluried ? "blur(8px)" : "none")};
`;

const Overview = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1.125rem 1.5rem;
`;

export const CardOverview = ({ isBluried, children }) => (isBluried ? <Overview>{children}</Overview> : null);
