import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { cashActivitySelector, cashActivityThunks } from "@redux/financials/cashActivitySlice";
import { financialsSelector } from "@redux/financials/financialsSlice";
import { stopListening } from "@redux/financials/middleware/utils";

import { Box, Flex, Heading } from "@chakra-ui/react";
import { TimeSwitch, CardHeader, CashFlow, CashEndOfDate, Categories } from "./components";
import useChartVerticalLine from "@utils/custom-hooks/useChartVerticalLine";
import { CASH_ACTIVITY_TIME_OPTIONS } from "@src/consts/financial-intelligence";
import { BlurryCard } from "@common/blurry-card";
import { getCashActivityBlurryMessage } from "@src/consts/blur";
import { getBlurredData, getBankingBlurData } from "@utils/blur";

export const CashActivity = () => {
  const dispatch = useDispatch();
  const verticalLine = useChartVerticalLine();

  const {
    getCashActivity: {
      currentCashActivity: { data, allChecked, activityCategories, endOfDateBalanceData },
      isDailyView,
      barScaleSelected,
      initialLoadComplete = false,
      requestStatus,
    },
  } = useSelector(cashActivitySelector);

  const { getUpcomingCashIn } = useSelector(financialsSelector);
  const integrations = useSelector((state) => state.integrations);
  const platform = getUpcomingCashIn?.data?.platforms ?? [];

  const bankingBlur = getBankingBlurData(integrations.plaid.data.institutions);

  const platformBlur = getBlurredData(platform, integrations);

  useEffect(() => {
    if (!initialLoadComplete && !requestStatus.pending) {
      dispatch(cashActivityThunks.getCashActivity());
      dispatch(
        cashActivityThunks.getCashActivity({
          grouping: CASH_ACTIVITY_TIME_OPTIONS.DAILY.grouping,
        }),
      );
    }

    return () => {
      stopListening();
    };
    // eslint-disable-next-line
  }, []);

  const { activities = [] } = data;

  return (
    <CashActivityPresentation
      initialLoadComplete={initialLoadComplete}
      allChecked={allChecked}
      activityCategories={activityCategories}
      verticalLine={verticalLine}
      isDaily={isDailyView}
      barScaleSelected={barScaleSelected}
      activities={activities}
      endOfDateBalanceData={endOfDateBalanceData}
      bankingBlur={bankingBlur}
      platformBlur={platformBlur}
    />
  );
};

export const CashActivityPresentation = ({
  initialLoadComplete = false,
  allChecked = false,
  activityCategories = [],
  verticalLine = {},
  isDaily = false,
  barScaleSelected = {},
  activities = [],
  endOfDateBalanceData = [],
  bankingBlur = {},
  platformBlur = {},
}) => {
  const isBlurred = bankingBlur.isBlurred && platformBlur.isBlurred;
  const isPullingData = bankingBlur.isPullingData && platformBlur.isPullingData;
  const isBroken = bankingBlur.isBroken && platformBlur.isBroken;

  const chartProps = {
    // Column widths need to stay same for vertical line to stay aligned
    // 25px gives daily view bar 1px border but pushes chart too far right
    // columnWidth: isDaily ? "25px" : "",
    verticalLine,
    isDaily,
    barScaleSelected,
  };

  // Return a loading shine container if initial load is not complete
  if (!initialLoadComplete) {
    return (
      <Box mb={"48px"}>
        <Flex justifyContent={"space-between"} alignItems={"center"} mb="24px">
          <Heading size={"small"} fontWeight={"600"} textAlign={"left"} data-test-id={"cash-flows-overview-header"}>
            Cash flows overview
          </Heading>
        </Flex>
        <Flex
          height={"754px"}
          overflow={"hidden"}
          backgroundColor={"dark.600"}
          borderRadius={"12px"}
          className="loading-shine"
          data-test-id={"cash-flows-overview-card"}
        />
      </Box>
    );
  }

  return (
    <Box mb={"48px"}>
      <Flex justifyContent={"space-between"} alignItems={"center"} mb="24px">
        <Heading size={"small"} fontWeight={"600"} textAlign={"left"} data-test-id={"cash-flows-overview-header"}>
          Cash flows overview
        </Heading>
        {!isBlurred && <TimeSwitch />}
      </Flex>
      <BlurryCard
        isBlurred={isBlurred}
        imgSrc={require("@assets/img/blur/cashActivityBlur.png")}
        message={getCashActivityBlurryMessage({ isPullingData, isBroken })}
      >
        <Flex
          overflow={"hidden"}
          backgroundColor={"dark.600"}
          borderRadius={"12px"}
          data-test-id={"cash-flows-overview-card"}
        >
          <Box
            flex={"254px 0 0"}
            p={"24px 16px 24px 16px"}
            borderRight="1px solid"
            borderColor="dark.400"
            position={"relative"}
          >
            <Categories allChecked={allChecked} activityCategories={activityCategories} />
          </Box>
          <Box flex={1} p={"24px"}>
            <CardHeader />
            <CashFlow {...chartProps} cashFlowData={activities} />
            <CashEndOfDate {...chartProps} data={endOfDateBalanceData} />
          </Box>
        </Flex>
      </BlurryCard>
    </Box>
  );
};
