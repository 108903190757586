import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import IntegrationsConnectionsService from "./baseApi";

import { getErrorStatus, getRequestStatus, getResponseStatus, setStatusDefaults } from "@utils/redux";
import { AD_NETWORKS, PLATFORMS, PRODUCT_ANALYTICS } from "@src/consts/integrations";
import { snakeToCamelCase } from "@src/utils/string";

export const integrationsConnectionsThunks = {
  getIntegrationsConnections: createAsyncThunk("integrationsConnections/getIntegrationsConnections", async () => {
    const res = await IntegrationsConnectionsService.getIntegrationsConnections();
    return res.data;
  }),
};

const categories = [PLATFORMS, PRODUCT_ANALYTICS, AD_NETWORKS];

export const integrationsConnectionsSlice = createSlice({
  name: "integrationsConnections",
  initialState: {
    connections: {
      platforms: [],
      productAnalytics: [],
      adNetworks: [],
      requestStatus: setStatusDefaults(),
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(integrationsConnectionsThunks.getIntegrationsConnections.pending, (state) => {
      state.connections.requestStatus = getRequestStatus();
    });
    builder.addCase(integrationsConnectionsThunks.getIntegrationsConnections.fulfilled, (state, action) => {
      const integrations = action.payload;

      // Clear the connections
      categories.forEach((category) => {
        const categoryName = snakeToCamelCase(category);
        state.connections[categoryName] = [];
      });

      for (const integration of integrations) {
        const integrationCategory = integration.integrationCategory;
        const isManual = integration.manual;
        categories.forEach((category) => {
          if (category === integrationCategory) {
            if (category === AD_NETWORKS && isManual) return;
            const categoryName = snakeToCamelCase(category);
            state.connections[categoryName].push(integration);
          }
        });
      }
      state.connections.requestStatus = getResponseStatus();
    });
    builder.addCase(integrationsConnectionsThunks.getIntegrationsConnections.rejected, (state) => {
      state.connections.requestStatus = getErrorStatus();
    });
  },
});

export const integrationsConnectionsSelector = (state) => state.integrations.integrationsConnections.connections;

export const adNetworkConnectionsSelector = (state) =>
  state.integrations.integrationsConnections.connections.adNetworks;

export default integrationsConnectionsSlice.reducer;
