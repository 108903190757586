import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getResponseStatus, getRequestStatus, getErrorStatus, setStatusDefaults } from "@utils/redux";

import FinancialsService from "./financialsApi";

import {
  parseFlowOverviewChartData,
  parseSummaryMetrics,
  parseCashActivityCategories,
  sortUniqueMonths,
  getMonthRange,
  parseEndOfDateBalanceData,
  toggleCashActivity,
  filterActivities,
  getCurrentCashActivity,
} from "./utils/cashActivity";
import { CASH_ACTIVITY_TIME_OPTIONS, CASH_ACTIVITY_ZOOM_SCALE } from "@src/consts/financial-intelligence";
import { copy } from "@utils/general";
import moment from "moment";
import isEmpty from "lodash/isEmpty";

const initialState = {
  getCashActivity: {
    monthly: {
      allChecked: true,
      activityCategories: {
        cashIn: [],
        cashOut: [],
      },
      activities: [],
      endOfDateBalanceData: [],
      data: {},
      pivot: moment().format("YYYY-MM"),
    },
    daily: {
      allChecked: true,
      activityCategories: {
        cashIn: [],
        cashOut: [],
      },
      activities: [],
      endOfDateBalanceData: [],
      data: {},
      pivot: moment().format("YYYY-MM"),
    },
    // used to know when to make a new request
    metadata: {
      toDate: "",
      fromDate: "",
    },
    currentCashActivity: {
      data: {},
      allChecked: true,
      activityCategories: {
        cashIn: [],
        cashOut: [],
      },
      endOfDateBalanceData: [],
    },
    summaryMetrics: {},
    selectedTime: CASH_ACTIVITY_TIME_OPTIONS.MONTHLY,
    isDailyView: false,
    barScaleSelected: CASH_ACTIVITY_ZOOM_SCALE[0],
    requestStatus: setStatusDefaults(),
    initialLoadComplete: false,
  },
};

export const cashActivityThunks = {
  getCashActivity: createAsyncThunk(
    "cashActivity/getCashActivity",
    async ({ fromDate, toDate, grouping = CASH_ACTIVITY_TIME_OPTIONS.MONTHLY.grouping } = {}) => {
      const res = await FinancialsService.getCashActivity({ fromDate, toDate, grouping });
      return res.data;
    },
  ),
};

export const cashActivitySlice = createSlice({
  name: "cashActivity",
  initialState,
  reducers: {
    changeBarScaleSelected: (state, { payload: { scaleSelected } }) => {
      state.getCashActivity.barScaleSelected = scaleSelected;
    },
    changeCashActivityTime: (state, { payload: { time } }) => {
      state.getCashActivity.selectedTime = time;
      state.getCashActivity.currentCashActivity = getCurrentCashActivity(state.getCashActivity[time.grouping]);
      state.getCashActivity.isDailyView = time.grouping === CASH_ACTIVITY_TIME_OPTIONS.DAILY.grouping;
    },
    changeAMonth: (state, { payload: { type = "next" } }) => {
      const currentGrouping = state.getCashActivity[state.getCashActivity.selectedTime.grouping];
      const month = moment(currentGrouping.pivot);
      const newMonth = (type === "next" ? month.add(1, "months") : month.subtract(1, "months")).format("YYYY-MM");
      currentGrouping.pivot = newMonth;
      const filteredActivities = filterActivities({
        activities: currentGrouping.activities,
        timeGrouping: state.getCashActivity.selectedTime.grouping,
        month: newMonth,
      });
      // Update the End of Date Balance Chart Data
      currentGrouping.endOfDateBalanceData = parseEndOfDateBalanceData({
        activities: filteredActivities,
        timeGrouping: state.getCashActivity.selectedTime.grouping,
      });
      // Update the Cash Flow Chart Data
      currentGrouping.data = parseFlowOverviewChartData({
        activities: filteredActivities,
        activityCategories: currentGrouping.activityCategories,
        metadata: state.getCashActivity.metadata,
        timeGrouping: state.getCashActivity.selectedTime.grouping,
      });
      state.getCashActivity.currentCashActivity = getCurrentCashActivity(currentGrouping);
    },
    toggleCashActivityAll: (state) => {
      const timeGrouping = state.getCashActivity.selectedTime.grouping;
      const monthlyGrouping = CASH_ACTIVITY_TIME_OPTIONS.MONTHLY.grouping;
      const dailyGrouping = CASH_ACTIVITY_TIME_OPTIONS.DAILY.grouping;
      const monthlyActivities = copy(state.getCashActivity[monthlyGrouping]);
      const dailyActivities = copy(state.getCashActivity[dailyGrouping]);

      const { monthly, daily } = toggleCashActivity({
        monthlyActivities,
        dailyActivities,
        all: !monthlyActivities.allChecked && !dailyActivities.allChecked,
      });

      // MONTHLY
      if (typeof monthly.allChecked === "boolean") {
        state.getCashActivity[monthlyGrouping].allChecked = monthly.allChecked;
      }
      state.getCashActivity[monthlyGrouping].data = monthly.data;
      state.getCashActivity[monthlyGrouping].activityCategories = monthly.activityCategories;

      // DAILY
      if (typeof daily.allChecked === "boolean") {
        state.getCashActivity[dailyGrouping].allChecked = daily.allChecked;
      }
      state.getCashActivity[dailyGrouping].data = daily.data;
      state.getCashActivity[dailyGrouping].activityCategories = daily.activityCategories;

      state.getCashActivity.currentCashActivity = getCurrentCashActivity(state.getCashActivity[timeGrouping]);
    },
    toggleCashActivityCategory: (state, { payload: { categoryId } }) => {
      const timeGrouping = state.getCashActivity.selectedTime.grouping;
      const monthlyGrouping = CASH_ACTIVITY_TIME_OPTIONS.MONTHLY.grouping;
      const dailyGrouping = CASH_ACTIVITY_TIME_OPTIONS.DAILY.grouping;
      const monthlyActivities = copy(state.getCashActivity[monthlyGrouping]);
      const dailyActivities = copy(state.getCashActivity[dailyGrouping]);

      const { monthly, daily } = toggleCashActivity({
        monthlyActivities,
        dailyActivities,
        categoryId,
      });

      // MONTHLY
      if (typeof monthly.allChecked === "boolean") {
        state.getCashActivity[monthlyGrouping].allChecked = monthly.allChecked;
      }
      state.getCashActivity[monthlyGrouping].data = monthly.data;
      state.getCashActivity[monthlyGrouping].activityCategories = monthly.activityCategories;

      // DAILY
      if (typeof daily.allChecked === "boolean") {
        state.getCashActivity[dailyGrouping].allChecked = daily.allChecked;
      }
      state.getCashActivity[dailyGrouping].data = daily.data;
      state.getCashActivity[dailyGrouping].activityCategories = daily.activityCategories;

      state.getCashActivity.currentCashActivity = getCurrentCashActivity(state.getCashActivity[timeGrouping]);
    },
    toggleCashActivityCategoryActivity: (state, { payload: { activityId, cashFlowTypeId } }) => {
      const timeGrouping = state.getCashActivity.selectedTime.grouping;
      const monthlyGrouping = CASH_ACTIVITY_TIME_OPTIONS.MONTHLY.grouping;
      const dailyGrouping = CASH_ACTIVITY_TIME_OPTIONS.DAILY.grouping;
      const monthlyActivities = copy(state.getCashActivity[monthlyGrouping]);
      const dailyActivities = copy(state.getCashActivity[dailyGrouping]);

      const { monthly, daily } = toggleCashActivity({
        monthlyActivities,
        dailyActivities,
        activityId,
        cashFlowTypeId,
      });

      // MONTHLY
      if (typeof monthly.allChecked === "boolean") {
        state.getCashActivity[monthlyGrouping].allChecked = monthly.allChecked;
      }
      state.getCashActivity[monthlyGrouping].data = monthly.data;
      state.getCashActivity[monthlyGrouping].activityCategories = monthly.activityCategories;

      // DAILY
      if (typeof daily.allChecked === "boolean") {
        state.getCashActivity[dailyGrouping].allChecked = daily.allChecked;
      }
      state.getCashActivity[dailyGrouping].data = daily.data;
      state.getCashActivity[dailyGrouping].activityCategories = daily.activityCategories;

      state.getCashActivity.currentCashActivity = getCurrentCashActivity(state.getCashActivity[timeGrouping]);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(cashActivityThunks.getCashActivity.pending, (state) => {
        state.getCashActivity.requestStatus = getRequestStatus();
      })
      .addCase(cashActivityThunks.getCashActivity.fulfilled, (state, action) => {
        const { payload = {} } = action;
        let { activities = [], activityCategories = {}, metadata = {} } = payload;

        const grouping = metadata.grouping.toLowerCase() || CASH_ACTIVITY_TIME_OPTIONS.MONTHLY.grouping;
        const currentGrouping = state.getCashActivity[grouping];

        // const allActivities = [...currentGrouping.activities, ...activities];
        // Right now we get back all activities no matter what, even when calling older periods
        // So we don't need to merge the activities right now.
        // TODO: eventually only call for the activities we need and then we can merge them
        const allActivities = activities;

        const parsedActivityCategories = parseCashActivityCategories(activityCategories);
        currentGrouping.activities = sortUniqueMonths(allActivities);
        currentGrouping.activityCategories = parsedActivityCategories;

        if (grouping === CASH_ACTIVITY_TIME_OPTIONS.MONTHLY.grouping) {
          state.getCashActivity.metadata = getMonthRange(allActivities);
        }

        const filteredActivities = filterActivities({
          activities: allActivities,
          timeGrouping: grouping,
          month: currentGrouping.pivot,
        });
        // Update the End of Date Balance Chart Data
        currentGrouping.endOfDateBalanceData = parseEndOfDateBalanceData({
          activities: filteredActivities,
          timeGrouping: grouping,
        });
        // Update the Cash Flow Chart Data
        currentGrouping.data = parseFlowOverviewChartData({
          activities: filteredActivities,
          activityCategories: parsedActivityCategories,
          metadata: state.getCashActivity.metadata,
          timeGrouping: grouping,
        });

        state.getCashActivity.currentCashActivity = getCurrentCashActivity(
          state.getCashActivity[state.getCashActivity.selectedTime.grouping],
        );
        // Update the request status
        state.getCashActivity.initialLoadComplete = true;
        state.getCashActivity.requestStatus = getResponseStatus();
      })
      .addCase(cashActivityThunks.getCashActivity.rejected, (state) => {
        state.getCashActivity.initialLoadComplete = true;
        state.getCashActivity.requestStatus = getErrorStatus();
      })
      .addMatcher(
        (action) => action.type.endsWith("getCashActivity/fulfilled"),
        (state) => {
          const dailyActivities = state.getCashActivity.daily.activities;
          const monthlyActivities = state.getCashActivity.monthly.activities;
          if (!isEmpty(dailyActivities) && !isEmpty(monthlyActivities)) {
            // Get Summary Metrics
            state.getCashActivity.summaryMetrics = parseSummaryMetrics(dailyActivities, monthlyActivities);
          }
        },
      );
  },
});

export const cashActivitySelector = (state) => state.cashActivity;

export const {
  changeBarScaleSelected,
  changeCashActivityTime,
  changeAMonth,
  toggleCashActivityAll,
  toggleCashActivityCategory,
  toggleCashActivityCategoryActivity,
} = cashActivitySlice.actions;

export default cashActivitySlice.reducer;
