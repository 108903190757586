import { useLayoutEffect } from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Heading,
  Input,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";
import { InstructionItem, InstructionsList } from "@common/guided-instructions/GuidedInstructions";
import { GuideMedia } from "@components/integrations/connection-guide";
import symbols from "@src/utils/symbols";
import googleInstructions from "../google-instructions-media/google-instructions.jpg";
import { useMultiStepModal } from "@common/multi-step-modal/MultiStepModalContext";
import { useSelector } from "react-redux";
import { googleAuthLinkSelector } from "@redux/webshops/webshopsSlice";
import { useLocalStorage } from "@src/utils/custom-hooks/useLocalStorage";
import { useFormContext } from "react-hook-form";
import { NEW_PROJECT } from "@src/consts/webshops/new-project";
import { useEnterKeyPress } from "@src/utils/custom-hooks/useEnterKeyPress";

export function GooglePlayInstructions({ handleNextStep }) {
  const { register, watch, formState } = useFormContext();
  const { setShowProgress, setShowStepsLeft, onFormSubmit } = useMultiStepModal();
  const [, setProjectState] = useLocalStorage(NEW_PROJECT);
  const googleAuthLink = useSelector(googleAuthLinkSelector);
  const appId = watch("appId");
  const [continueButton] = useEnterKeyPress();

  useLayoutEffect(() => {
    setShowProgress(true);
    setShowStepsLeft(true);
  }, [setShowProgress, setShowStepsLeft]);

  const handleLastStep = () => {
    onFormSubmit((data) => {
      setProjectState(data);
      window.open(googleAuthLink, "_self");
    });
  };

  return (
    <>
      <VStack mt="40px" gap="104px">
        <Heading size="large" fontWeight={600}>
          Connect via Google Play
        </Heading>
        <HStack gap="24px" alignItems={"start"}>
          <Box maxW="420px">
            <InstructionsList>
              <InstructionItem>
                <Text size={"regular"} fontWeight={500} color={"textWhite.400"}>
                  Log into your{" "}
                  <Link
                    textDecoration={"underline"}
                    color={"brandRed.400"}
                    _hover={{ color: "brandRed.400" }}
                    href="https://play.google.com/console/u/0/developers/5495096324872410117/app-list"
                    isExternal
                  >
                    Google Play Console
                  </Link>{" "}
                  account
                </Text>
                <Text size="small">
                  1.1 Go to the{" "}
                  <Text as="span" color={"textWhite.400"} fontWeight={500}>
                    dashboard
                  </Text>{" "}
                  of the app you wish to use.
                </Text>
                <Text size="small">
                  1.2 Locate the{" "}
                  <Text as="span" color={"textWhite.400"} fontWeight={500}>
                    app ID
                  </Text>{" "}
                  under your apps name. {symbols.pointRight}
                </Text>
                <Text size="small">1.3 Copy app ID to your clipboard.</Text>
              </InstructionItem>
              <InstructionItem>
                <Text size={"regular"} fontWeight={500} color={"textWhite.400"}>
                  Paste app ID below. {symbols.pointDown}
                </Text>
              </InstructionItem>
            </InstructionsList>
            <Divider />
            <FormControl>
              <FormLabel fontSize={"sm"}>
                App ID
                <Input {...register("appId", { required: true })} mt="8px" />
              </FormLabel>
              {formState.errors?.appId && <FormHelperText color="brandRed.400">App ID is required</FormHelperText>}
            </FormControl>
            <Button
              ref={continueButton}
              isDisabled={!appId?.length}
              onClick={handleLastStep}
              variant={"primary"}
              mt="24px"
              w="full"
            >
              Continue
            </Button>
          </Box>
          <Box maxW={"512px"} p="12px" rounded={"12px"} bg="dark.400" h="fit-content">
            <GuideMedia mediaSlider={[googleInstructions]} />
          </Box>
        </HStack>
      </VStack>
    </>
  );
}
