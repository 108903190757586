import React, { useRef } from "react";
import moment from "moment";
import styled from "styled-components";

import { BodyMini12CSS, BodySmallPlusCSS } from "@styled";
import { SanloTooltip } from "@components/common/tooltips/sanlo-tooltip/SanloTooltip";
import { DailyEarningsTooltip } from "../Tooltips/DailyRevenueTooltips";
import { PaydateTooltip } from "../Tooltips/PaydateTooltip";

import { alphaTrim } from "@utils/general";
import symbols from "@utils/symbols";

const XAxis = ({
  selectedOption,
  chartData,
  isLoading,
  viewedPeriod,
  selectedOptionGameData,
  visualRange,
  chartBodyRef,
  earningsData,
  barWidth,
  setHoveredDay,
  fetchPreviousPeriod,
}) => {
  const today = moment().startOf("day").format("YYYY-MM-DD");

  const leadMonth = useRef({
    month: null,
    isPayDate: null,
  });

  return (
    <StyledXAxis isLoading={isLoading}>
      {leadMonth.current.month && (
        <StyledFloatingMonthAbbr isNotPayDate={!leadMonth.current.isPayDate}>
          {leadMonth.current.month}
        </StyledFloatingMonthAbbr>
      )}

      {chartData.order.map((day, index) => {
        const data = chartData.map[day];
        const { dayNum, monthAbbr, isMonthStart, isPayDate, period, payDatePeriod = {} } = data;

        // !isMonthState: month starts already have the month abbr over them
        // day === visualRange.start: Is the current day the first day visually on the screen
        // dayNum > 2: month start abbrs are usually longer than one day, this will hide it for the 2nd as well
        if (!isMonthStart && day === visualRange.start && dayNum > 2) {
          leadMonth.current = {
            month: monthAbbr,
            isPayDate,
          };
        } else if (isMonthStart && day === visualRange.start && dayNum <= 2) {
          leadMonth.current = {};
        }

        // The tooltip is either a normal day tooltop or a paydate tooltip, not both
        // and the tooltip is triggered on the whole stack
        const tooltipId = `key_${index}_x_axis_earnings_tooltip_${alphaTrim(day)}`;
        return (
          <StyledStack key={`key_x_axis_${day}`} id={tooltipId}>
            <StyledTooltipTrigger
              onMouseEnter={(e) => {
                setHoveredDay(data.date);
              }}
              onMouseLeave={(e) => {
                setHoveredDay(null);
              }}
            >
              {!isPayDate && day < today && (
                <SanloTooltip
                  target={tooltipId}
                  placement="top"
                  text={
                    <DailyEarningsTooltip
                      dayData={data}
                      selectedOptionGameData={selectedOptionGameData}
                      dataSourceStatus={viewedPeriod?.dataSourceStatus}
                      selectedOption={selectedOption}
                    />
                  }
                />
              )}
              {isPayDate && (
                <PaydateTooltip
                  dayData={data}
                  chartData={chartData}
                  selectedOption={selectedOption}
                  today={today}
                  viewedPeriod={viewedPeriod}
                  tooltipTarget={tooltipId}
                  chartBodyRef={chartBodyRef}
                  earningsData={earningsData}
                  barWidth={barWidth}
                  fetchPreviousPeriod={fetchPreviousPeriod}
                />
              )}
            </StyledTooltipTrigger>

            {isPayDate && <StyledStackItem isPayDayEmoji>{symbols.FlyingMoney()}</StyledStackItem>}

            {isMonthStart && (
              <StyledStackItem isMonthAbbr isMonthAbbrCenter={isPayDate} isLoading={isLoading}>
                {monthAbbr}
              </StyledStackItem>
            )}

            {isPayDate && dayNum === 2 && (
              // Empty subitem to push the paydate up a level if its the 2nd day. Most most abbrs take
              // up about 2 days worth of space so the paydate needs to be shifted up to avoid conflicts
              <StyledStackItem />
            )}

            <StyledStackDayBG
              id={tooltipId}
              isCurrentPeriod={viewedPeriod && viewedPeriod.id === period.id}
              isPeriodStart={data.isFiscalStart}
              isPeriodMiddle={!data.isFiscalStart && !data.isFiscalEnd}
              isPeriodEnd={data.isFiscalEnd}
            >
              <StyledStackDay
                isPayDate={isPayDate}
                isToday={day === today}
                isPaydateCurrentPeriod={payDatePeriod.fiscalToDate > today}
                isEmptyData={chartData.isEmpty}
                isLoading={isLoading}
              >
                {dayNum}
              </StyledStackDay>
            </StyledStackDayBG>
          </StyledStack>
        );
      })}
    </StyledXAxis>
  );
};

export default XAxis;

const StyledFloatingMonthAbbr = styled.div`
  ${BodySmallPlusCSS};
  position: absolute;
  left: var(--y-axis-width);
  top: 0;
  height: var(--x-axis-sub-item-height);
  line-height: var(--x-axis-sub-item-height);

  ${({ isNotPayDate }) =>
    isNotPayDate &&
    `
    top: var(--x-axis-sub-item-height);
  `};
`;

export const StyledXAxis = styled.div`
  grid-area: x_axis;
  white-space: nowrap;
  padding-bottom: 0.5rem;
`;

export const StyledStack = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-end;
  height: calc(var(--x-axis-sub-item-height) * 3);
  vertical-align: top;
  width: var(--bar-width);
  text-align: center;
`;

export const StyledStackItem = styled.div`
  height: var(--x-axis-sub-item-height);
  line-height: var(--x-axis-sub-item-height);

  ${({ isMonthAbbr }) =>
    isMonthAbbr &&
    `
    ${BodySmallPlusCSS};
  `};

  ${({ isMonthAbbrCenter }) =>
    isMonthAbbrCenter &&
    `
    text-align: center;
  `};

  ${({ isMonthAbbr, isMonthAbbrCenter }) =>
    isMonthAbbr &&
    !isMonthAbbrCenter &&
    `
    transform: translateX(30%);
  `};

  ${({ isPayDayEmoji }) =>
    isPayDayEmoji &&
    `
    font-size: 0.8rem;
  `};
`;

export const StyledStackDayBG = styled.div`
  height: var(--x-axis-sub-item-height);
  line-height: var(--x-axis-sub-item-height);
  box-sizing: border-box;
  border-top: 1px solid ${({ theme }) => theme.grayscale60};
  border-bottom: 1px solid ${({ theme }) => theme.grayscale60};

  ${({ isCurrentPeriod, theme }) =>
    isCurrentPeriod &&
    `
    background-color: ${theme.grayscale60};
  `};

  ${({ isPeriodStart, theme }) =>
    isPeriodStart &&
    `
    border-left: 1px solid ${theme.grayscale60};
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  `};

  ${({ isPeriodEnd, theme }) =>
    isPeriodEnd &&
    `
    border-right: 1px solid ${theme.grayscale60};
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  `};
`;

export const StyledStackDay = styled.div`
  ${BodyMini12CSS};
  height: var(--x-axis-sub-item-height);
  line-height: var(--x-axis-sub-item-height);
  display: block;
  text-align: center;
  border-radius: 100%;
  background-color: transparent;
  transition: 0.25s ease all;
  width: var(--x-axis-sub-item-height);
  margin: 0 auto;
  height: 100%;

  ${({ isPayDate, theme }) =>
    isPayDate &&
    `
    color: ${theme.grayscale0};
    background-color: ${theme.colorGreen};
  `};

  ${({ isToday, theme }) =>
    isToday &&
    `
    color: ${theme.grayscale0};
    background-color: ${theme.colorRed};
  `};

  ${({ isToday, isPullingData, theme }) =>
    isToday &&
    isPullingData &&
    `
    background-color: ${theme.grayFontColor};
  `};

  ${({ isPaydateCurrentPeriod, theme }) =>
    isPaydateCurrentPeriod &&
    `
    color: ${theme.grayscale0};
    background-color: ${theme.colorYellow};
  `};

  ${({ isPayDate, isEmptyData, theme }) =>
    isPayDate &&
    isEmptyData &&
    `
    color: ${theme.grayscale0};
    background-color: ${theme.grayFontColor};
  `};

  ${({ isLoading }) =>
    isLoading &&
    `
    color: transparent !important;
    background-color: transparent !important;
  `};
`;

const StyledTooltipTrigger = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
`;
